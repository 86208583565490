import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateCurrency extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            currencyName: "",
            conversionRate: "",
            currencySymbol: "",

        }
    }

    componentDidMount(){
        
    }
    
    
    

    createCurrency = () => {
        let {
            currencyName,
            conversionRate,
            currencySymbol,
        } = this.state

        if(currencyName === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter currency"
            })
        }else if(conversionRate === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter conversion rate"
            })
        }else if(currencySymbol === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter currency symbol"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCurrency(currencyName, conversionRate, currencySymbol)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: '/editcurrency',
                        state: { currencyId: response._id, newCurrencyEntry: true }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }

    checkFloatingConversionRate = (val) => {
        let value = val
        if(!isNaN(value) || value === ""){
            this.setState({
                conversionRate: value
            })
        }
    }
   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create New Currency Conversion</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createCurrency()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Currency </div>
                                <div className="value"><input type="text" style={{ width: 250 }} className="fieldheightdecr" value={this.state.currencyName} onChange={(e)=>this.setState({ currencyName: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Conversion Rate</div>
                                <div className="value">
                                    <div className="float-left createdText border-0">1 <span style={{ minWidth: 100 }} className="display-block">{this.state.currencyName}</span>&nbsp;=&nbsp;</div>
                                    <input 
                                        type="text" 
                                        className="float-left w-auto" 
                                        value={this.state.conversionRate}
                                        onChange={(e)=>this.checkFloatingConversionRate(e.target.value)}
                                    />
                                    <div className="createdText float-left border-0">&nbsp;INR</div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Currency Symbol</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        style={{width: 250}}
                                        value={this.state.currencySymbol}
                                        onChange={(e)=>this.setState({ currencySymbol: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createCurrency()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}