import React from 'react';
import { Link } from 'react-router-dom';

const pages = [
    {
        "name": "Expenses",
        "to": "/expenses",
        "class": "innerHeaderList",
        "isExpenses": true,
        "hide": false
    }
]



export default class ProcurementHeader extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            tabs: [],
            displayTabs: []
        }
    }

    componentDidMount(){
        this.checkPermissions()
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if(localPermissions !== null){
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if(permissions !== undefined && permissions.length > 0){
                let updatedRecord = pages.map(obj => {
                    let permit = permissions.find(lo => lo.name === obj.name)
                    if(permit !== undefined)
                        return obj
                });
                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            }else{
                this.setState({
                    tabs: pages
                },()=>{
                    this.setDisplayTabs()
                })
            }
        }
    }

    setDisplayTabs = () => {
        let {
            isExpenses
            
        } = this.props;

        let dispTabs = []

        this.state.tabs.map((data, i) => {
            if(data !== undefined){

                let isActive = ""

                if(data.name === "Expenses" && isExpenses === true)
                    isActive = "active"

                dispTabs.push(
                    <Link 
                        key={data.name} 
                        to={data.to} 
                        className={data.hide ? "d-none" : data.class+" "+isActive}
                    >
                        {data.name} 
                    </Link>
                )
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }

    render() {
        return(
            <div className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                            <div className="innerHeader">
                                { this.state.displayTabs }
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
