import React from 'react';
import { Header, StockHeader } from './../../../components'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as APIManager from './../../../APIManager/APIManager'

import Helper from './../../../helper/helper'


import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css'


import { AiOutlineExclamation, AiOutlineCalendar } from "react-icons/ai";

import AddStorageLocation from '../../../components/AutoGenerateComponent/addStorageLocation';
import { convertDateToMMDDYYYY } from '../../../constants/DateFormatter';


export default class CreateLot extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            productGroupList: [],
            productList: [],

            status: "",
            productGroup: "",
            product: "",
            quantity: "",
            quantityInSL: "",
            available: "",
            costperunit: "",
            totalcost: "",
            availablefrom: "",

            countstorageLocationNumber: [0],
            addstorageLocationFields: [],
            count: 0,
            storageLocationFieldValues: [],
            generateNewstorageLocation: true,

        }
    }

    componentDidMount(){
        this.getProductGroup()
        this.setStorageLocationField()
    }

    
    setStorageLocationField = () => {
        this.setState({
            addstorageLocationFields: this.state.addstorageLocationFields.concat(
                [
                    <AddStorageLocation 
                        key={this.state.count}
                        componentNum={this.state.count}
                        valueOfName={""}
                        valueOfRate={""}
                        valueOfId={""}
                        onBlur={this.measureInputBlur}
                        onEdit={this.measureInputEdit}
                        onRemove={this.measureInputRemove}
                    />
                ]
            )
        })
    }
    
    measureInputEdit = (name, rate, num) => {

        let array = [...this.state.countstorageLocationNumber];
        let index = array.indexOf(num)
        let measureVal = [...this.state.storageLocationFieldValues]
        
        if (typeof measureVal[index] === 'undefined' && this.state.generateNewstorageLocation === true && name !== "" && rate !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewstorageLocation: false,
            }, () => {
                
                this.setState({
                    countstorageLocationNumber: this.state.countstorageLocationNumber.concat(
                        [this.state.count]
                    ),
                    addstorageLocationFields: this.state.addstorageLocationFields.concat(
                        [
                            <AddStorageLocation 
                                key={this.state.count}
                                componentNum={this.state.count}
                                valueOfName={""}
                                valueOfRate={""}
                                valueOfId={""}
                                onBlur={this.measureInputBlur}
                                onEdit={this.measureInputEdit}
                                onRemove={this.measureInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    measureInputBlur = (id, name, rate, num) => {
         let array = [...this.state.countstorageLocationNumber];
         let index = array.indexOf(num)
         let measureVal = [...this.state.storageLocationFieldValues]   
 
         if (typeof measureVal[index] !== 'undefined') {
             if (name !== "" && rate !== "") {
                 let obj = {
                     "storageLocationId": id,
                     "storageLocationName": name,
                     "quantity": rate
                 }
                 let storageLocationFieldValues = [ ...this.state.storageLocationFieldValues ];
                 storageLocationFieldValues[index] = obj;
 
                 this.setState({
                     storageLocationFieldValues,
                    // generateNewstorageLocation: false,
                 },()=>{
                    this.countStorageLocationQuantity()
                })
             }
         }else{
             if (name !== "" && rate !== "") {
                 let obj = {
                    "storageLocationId": id,
                    "storageLocationName": name,
                    "quantity": rate
                 }
                 this.setState({
                     storageLocationFieldValues: this.state.storageLocationFieldValues.concat([ obj ]),
                     generateNewstorageLocation: true,
                 },()=>{
                    this.countStorageLocationQuantity()
                 })
             }
         }
    }

    countStorageLocationQuantity = () => {
        let slFields = this.state.storageLocationFieldValues;
        let countTotalQuantity = 0;
        if(slFields.length > 0){
            let new_SL_Fields = [];
            slFields.forEach((val) => {
                if(val !== null){
                    new_SL_Fields.push(val);
                }
            });
            if(new_SL_Fields.length > 0){
                for(let i=0;i<new_SL_Fields.length;i++){
                    countTotalQuantity += new_SL_Fields[i].quantity
                }
            }
        }
        this.setState({
            quantityInSL: countTotalQuantity
        })
    }

    measureInputRemove = (number) => {

        let array = [...this.state.countstorageLocationNumber];
        let fields = [...this.state.addstorageLocationFields];
        let data = [...this.state.storageLocationFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countstorageLocationNumber: array,
                addstorageLocationFields: fields,
                storageLocationFieldValues: data
            });
        }
        
    }

    getProductGroup = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        fetch(APIManager.WEBSERVICES_URL+'stock/getProductGroup',{
            method: "POST",
            headers: myHeaders
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status === "success"){ 
                that.setState({
                    productGroupList: result.data.productGroup,
                })
            }else if(result.status === "userDataError"){
                that.logout()
            }else{

            }
        },(error) => {
            console.log(error)
        })
    }

    getItemsByGroup = (_id) => {
        if(_id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetProductByVendor(_id, this.state.vendorId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    productList: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    countTotalCost = () => {
        let {
            costperunit,
            quantity
        } = this.state;

        if(costperunit !== "" && quantity !== ""){
            let cost = parseInt(costperunit) * parseInt(quantity)
            this.setState({
                totalcost: cost
            })
        }
    }

    saveItem = () => {
        let {
            status,
            productGroup,
            product,
            quantity,
            quantityInSL,
            available,
            costperunit,
            totalcost,
            availablefrom,
            storageLocationFieldValues
        } = this.state;

        if(status === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select status"
            })
        }else if(product === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select stock item"
            })
        }else if(quantity === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter total qauntity"
            })
        }else if(costperunit === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter cost per unit"
            })
        }else if(availablefrom === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select available date"
            })
        }else if(parseInt(quantityInSL) > parseInt(quantity)){
            this.setState({
                success: false,
                error: true,
                errormessage: "No of quantity in storage location must be equal to total qauntity."
            })
        }else{
            let new_SL_Fields = [];
            storageLocationFieldValues.forEach((item) => {
                if(item !== null){
                    var existing = new_SL_Fields.filter(function(v, i) {
                        return v.storageLocationId === item.storageLocationId;
                    });
                    if (existing.length) {
                        var existingIndex = new_SL_Fields.indexOf(existing[0]);
                        new_SL_Fields[existingIndex].quantity = parseInt(new_SL_Fields[existingIndex].quantity) + parseInt(item.quantity);
                    } else {
                        if (typeof item.quantity === 'string')
                        item.quantity = [item.quantity];
                        new_SL_Fields.push(item);
                    }
                }
            });

            this.setState({ isLoading: true, error: false })
            APIManager.callCreateStockLot(status, productGroup, product, quantity, available, costperunit, totalcost, JSON.stringify(new_SL_Fields), convertDateToMMDDYYYY(availablefrom))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    localStorage.setItem("createStockLot", true)
                    localStorage.setItem("stockLotId",response._id)
                    localStorage.setItem("stockLotNumber",response.number.toUpperCase())
                    this.props.history.push('/editlot')
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isStockLots={true} />
               
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Add lot</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Status *</div>
                                <div className="value">
                                    <select name="status" onChange={(e)=>this.setState({ status: e.target.value })}>
                                        <option value="">Select</option>
                                        <option value="requested">Requested</option>
                                        <option value="planned">Planned</option>
                                        <option value="received">Received</option>
                                    </select>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Product group</div>
                                <div className="value">
                                   

                                    <Select2    
                                            data={
                                                this.state.productGroupList.map((data) => (
                                                    { id: data._id, text: data.name }
                                                ))
                                            }
                                            value={this.state.productGroup}
                                            options={{placeholder: 'Select Group'}}
                                            onSelect={(e)=> this.setState({ productGroup: e.target.value },()=>{
                                                this.getItemsByGroup(e.target.value)
                                            })}
                                        />

                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Stock item *</div>
                                <div className="value">
                                   

                                    <Select2    
                                            data={
                                                this.state.productList.map((data) => (
                                                    { id: data._id, text: data.itemCode.toUpperCase()+' '+data.partDescription }
                                                ))
                                            }
                                            value={this.state.product}
                                            options={{placeholder: 'Select product'}}
                                            onSelect={(e)=> this.setState({ product: e.target.value })}
                                        />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
           

                            <div className="add_inner">
                                <div className="title">Total quantity *</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.quantity}
                                        onChange={(e)=>
                                            this.setState({ 
                                                quantity: parseInt(e.target.value) ? parseInt(e.target.value) : "", 
                                                available: parseInt(e.target.value) ? parseInt(e.target.value) : "" 
                                            },()=>{
                                                this.countTotalCost()
                                            }
                                        )}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Available</div>
                                <div className="value">
                                    <input 
                                        value={this.state.available}
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Cost per unit *</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.costperunit}
                                        onChange={
                                            (e)=>this.setState({ costperunit: parseInt(e.target.value) ? parseInt(e.target.value) : "" },()=>{
                                                this.countTotalCost()
                                            })
                                        }
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Total cost *</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.totalcost}
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Available from</div>
                                <div className="value">
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => this.setState({ availablefrom: date })}
                                        className="fieldheightdecr"
                                        selected={this.state.availablefrom}
                                        minDate={new Date()}
                                    />
                                    <AiOutlineCalendar className="datepickerIcon"/>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner contactinfo">
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Storage location</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1">
                                            <div className="float-left width40">Storage location</div>
                                            <div className="float-left width60">Quantity</div>
                                            <div className="clear"></div>
                                        </div>
                                        {
                                            this.state.addstorageLocationFields
                                        }
                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div> 
                        <div className="clearfix"></div>
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
