import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Tabs extends Component {
    render() {
        let { name, uniqueId, link } = this.props;
        return (
            <div className="content innerpage">
                <Link className={`tickets ${uniqueId}`} to={link}>
                    <i></i><span>{name}</span>
                </Link>
            </div>
        );
    }
}

export default Tabs;