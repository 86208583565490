import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as APIManager from './../../../../APIManager/APIManager'

class TaxDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            taxDetailsFieldValues: [],
        };
    }

    
    componentDidMount(){
        this.getTaxDetails()
    }

    getTaxDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetTaxDetails(this.state._id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                taxDetailsFieldValues: response[0].taxDetails !== "undefined" ? response[0].taxDetails : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    render() {
        return (

            <div className="add_inner">
                <div className="value">
                    <table>
                        <thead>
                            
                                <th>Type</th>
                                <th>%</th>
                                <th>Applicable on</th>
                        </thead>
                        <tbody>
                        {
                            this.state.taxDetailsFieldValues.map((data, i)=>(
                                <tr key={"TaxDetails"+i}>
                                    <td>{data.type}</td>
                                    <td>{data.rate} %</td>
                                    <td>{data.applicableOn}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <Link to="/edittaxdetails"><div className="setEditButton">Edit</div></Link>
                </div>

                <div className="clear"></div>
            </div>
            
        );
    }
}

export default TaxDetails;