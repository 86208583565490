import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { Header, ProcurementHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import * as Bi from 'react-icons/bi'
import Strings from './../../../constants/Strings'
import { ImArrowDown2 } from "react-icons/im";
import { FiPlus, FiX } from "react-icons/fi";
import { AiOutlineCalendar } from "react-icons/ai"
import { RiFlag2Line } from "react-icons/ri"
import * as Fi from 'react-icons/fi'

import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';


import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';


class PurchaseOrders extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            orderList: [],

            search_number: "",
            search_status: "",
            search_minCreated: "",
            search_maxCreated: "",
            search_minExpectedDate: "",
            search_maxExpectedDate: "",
            search_vendorNumber: "",
            search_vendorName: "",
            search_minTotal: "",
            search_maxTotal: "",
            search_minTotalIncludingTax: "",
            search_maxTotalIncludingTax: "",
            search_minArrivalDate: "",
            search_maxArrivalDate: "",
            search_orderId: "",
            search_minOrderDate: "",
            search_maxOrderDate: "",
            search_invoiceId: "",
            search_minInvoiceDate: "",
            search_maxInvoiceDate: "",
            search_minPaymentDate: "",
            search_maxPaymentDate: "",
            search_minShippedOn: "",
            search_maxShippedOn: "",
            search_PoFreeText: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,

            subTotal: 0,
            grandTotal: 0,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }
//******************* ascending descending order function ********************************
sortCustomer = (fieldName, fieldType, onload) => {
        
    let sortVal = this.state.sortVal;
    if(!onload){
        if (this.state.fieldName === fieldName) {
            sortVal = !this.state.sortVal;
        }
    }
    this.setState({
        fieldName: fieldName,
        fieldType: fieldType,
        sortVal: sortVal
    }, () => {
        const sortedOrders = this.state.orderList.sort((a, b) => {

            if (fieldType === "Date") {
                const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return sortVal ? 1 : -1;
                } else if (!dateB) {
                    return sortVal ? -1 : 1;
                }
                if (dateA.getFullYear() !== dateB.getFullYear()) {
                    return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                }
                if (dateA.getMonth() !== dateB.getMonth()) {
                    return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                }
                if (dateA.getDate() !== dateB.getDate()) {
                    return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                }
                return 0;
            }
            else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                // console.log('NotDate success');
                const numA = parseFloat(a[fieldName]);
                const numB = parseFloat(b[fieldName]);
                if (sortVal) {
                    return numA > numB ? 1 : -1;
                } else {
                    return numA < numB ? 1 : -1;
                }
            } else {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            }

        });
        this.setState({ orderList: sortedOrders }, () => {
            this.setFilterData()
        });
    });
    
};
//******************* ascending descending order function end ********************************
    _handleKeyDown = () => {
        this.getPurchaseOrderList();
        this.setFilterData();
    }

    getPurchaseOrderList = () => {
        let {
            search_number,
            search_status,
            search_minCreated,
            search_maxCreated,
            search_minExpectedDate,
            search_maxExpectedDate,
            search_vendorNumber,
            search_vendorName,
            search_minTotal,
            search_maxTotal,
            search_minTotalIncludingTax,
            search_maxTotalIncludingTax,
            search_minArrivalDate,
            search_maxArrivalDate,
            search_orderId,
            search_minOrderDate,
            search_maxOrderDate,
            search_invoiceId,
            search_minInvoiceDate,
            search_maxInvoiceDate,
            search_minPaymentDate,
            search_maxPaymentDate,
            search_minShippedOn,
            search_maxShippedOn,
            search_PoFreeText,
            start,
            length,
        } = this.state;

        this.setState({ isLoading: true, error: false })


        APIManager.callPurchaseOrderList(
            start,
            length,
            search_number,
            search_status,
            convertDateToMMDDYYYYWithoutZero(search_minCreated),
            convertDateToMMDDYYYYWithoutZero(search_maxCreated),
            convertDateToMMDDYYYY(search_minExpectedDate),
            convertDateToMMDDYYYY(search_maxExpectedDate),
            search_vendorNumber,
            search_vendorName,
            search_minTotal,
            search_maxTotal,
            search_minTotalIncludingTax,
            search_maxTotalIncludingTax,
            convertDateToMMDDYYYY(search_minArrivalDate),
            convertDateToMMDDYYYY(search_maxArrivalDate),
            search_orderId,
            convertDateToMMDDYYYY(search_minOrderDate),
            convertDateToMMDDYYYY(search_maxOrderDate),
            search_invoiceId,
            convertDateToMMDDYYYY(search_minInvoiceDate),
            convertDateToMMDDYYYY(search_maxInvoiceDate),
            convertDateToMMDDYYYY(search_minPaymentDate),
            convertDateToMMDDYYYY(search_maxPaymentDate),
            convertDateToMMDDYYYY(search_minShippedOn),
            convertDateToMMDDYYYY(search_maxShippedOn),
            search_PoFreeText
        )
            .then(response => {

                let subTotal = 0;
                let grandTotal = 0;
                for (let i = 0; i < response.data.length; i++) {
                    subTotal += response.data[i].grandSubTotal
                    grandTotal += response.data[i].grandTotal
                }

                this.setState({
                    isLoading: false,
                    orderList: response.data,
                    subTotal: subTotal.toFixed(2),
                    grandTotal: grandTotal.toFixed(2),
                    totalRecords: response.totalRecords
                } , () => {
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });

        // this.setFilterData()
    }



    addPurchaseOrder = () => {
        let slug = "Procurement_PurchaseOrders"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/addpo"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editPurchaseOrder = (_id, number) => {
        localStorage.setItem("purchase_order_id", _id)
        localStorage.setItem("purchase_order_number", number)

        this.props.history.push({
            pathname: "/editpo",
            state: { purchase_order_id: _id, purchase_order_number: number }

        })
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0
        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.setFilterData()
            this.getPurchaseOrderList()
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    setFilterData = () => {
        let filterDataArray = {
            'search_number': this.state.search_number ? this.state.search_number : "",
            'search_status': this.state.search_status,
            'search_minCreated': this.state.search_minCreated !== null ? this.state.search_minCreated : "",
            'search_maxCreated': this.state.search_maxCreated !== null ? this.state.search_maxCreated : "",
            'search_minExpectedDate': this.state.search_minExpectedDate !== null ? this.state.search_minExpectedDate : "",
            'search_maxExpectedDate': this.state.search_maxExpectedDate !== null ? this.state.search_maxExpectedDate : "",
            'search_vendorNumber': this.state.search_vendorNumber,
            'search_vendorName': this.state.search_vendorName,
            'search_minTotal': this.state.search_minTotal,
            'search_maxTotal': this.state.search_maxTotal,
            'search_minTotalIncludingTax': this.state.search_minTotalIncludingTax,
            'search_maxTotalIncludingTax': this.state.search_maxTotalIncludingTax,
            'search_minArrivalDate': this.state.search_minArrivalDate !== null ? this.state.search_minArrivalDate : "",
            'search_maxArrivalDate': this.state.search_maxArrivalDate !== null ? this.state.search_maxArrivalDate : "",
            'search_orderId': this.state.search_orderId,
            'search_minOrderDate': this.state.search_minOrderDate !== null ? this.state.search_minOrderDate : "",
            'search_maxOrderDate': this.state.search_maxOrderDate !== null ? this.state.search_maxOrderDate : "",
            'search_invoiceId': this.state.search_invoiceId,
            'search_minInvoiceDate': this.state.search_minInvoiceDate !== null ? this.state.search_minInvoiceDate : "",
            'search_maxInvoiceDate': this.state.search_maxInvoiceDate !== null ? this.state.search_maxInvoiceDate : "",
            'search_minPaymentDate': this.state.search_minPaymentDate !== null ? this.state.search_minPaymentDate : "",
            'search_maxPaymentDate': this.state.search_maxPaymentDate !== null ? this.state.search_maxPaymentDate : "",
            'search_minShippedOn': this.state.search_minShippedOn,
            'search_maxShippedOn': this.state.search_maxShippedOn,
            'search_PoFreeText': this.state.search_PoFreeText,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,

            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
        }
        localStorage.setItem("procurementPurchaseorder", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("procurementPurchaseorder"))
        if (filterData !== null) {
            this.setState({
                search_number: filterData.search_number,
                search_status: filterData.search_status,
                search_minCreated: filterData.search_minCreated ? new Date(filterData.search_minCreated) : "",
                search_maxCreated: filterData.search_maxCreated ? new Date(filterData.search_maxCreated) : "",
                search_minExpectedDate: filterData.search_minExpectedDate ? new Date(filterData.search_minExpectedDate) : "",
                search_maxExpectedDate: filterData.search_maxExpectedDate ? new Date(filterData.search_maxExpectedDate) : "",
                search_vendorNumber: filterData.search_vendorNumber,
                search_vendorName: filterData.search_vendorName,
                search_minTotal: filterData.search_minTotal,
                search_maxTotal: filterData.search_maxTotal,
                search_minTotalIncludingTax: filterData.search_minTotalIncludingTax,
                search_maxTotalIncludingTax: filterData.search_maxTotalIncludingTax,
                search_minArrivalDate: filterData.search_minArrivalDate ? new Date(filterData.search_minArrivalDate) : "",
                search_maxArrivalDate: filterData.search_maxArrivalDate ? new Date(filterData.search_maxArrivalDate) : "",
                search_orderId: filterData.search_orderId,
                search_minOrderDate: filterData.search_minOrderDate ? new Date(filterData.search_minOrderDate) : "",
                search_maxOrderDate: filterData.search_maxOrderDate ? new Date(filterData.search_maxOrderDate) : "",
                search_invoiceId: filterData.search_invoiceId,
                search_minInvoiceDate: filterData.search_minInvoiceDate ? new Date(filterData.search_minInvoiceDate) : "",
                search_maxInvoiceDate: filterData.search_maxInvoiceDate ? new Date(filterData.search_maxInvoiceDate) : "",
                search_minPaymentDate: filterData.search_minPaymentDate ? new Date(filterData.search_minPaymentDate) : "",
                search_maxPaymentDate: filterData.search_maxPaymentDate ? new Date(filterData.search_maxPaymentDate) : "",
                search_minShippedOn: filterData.search_minShippedOn ? new Date(filterData.search_minShippedOn) : "",
                search_maxShippedOn: filterData.search_maxShippedOn ? new Date(filterData.search_maxShippedOn) : "",
                search_PoFreeText: filterData.search_PoFreeText,

                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,

                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,
            }, () => {
                this.getPurchaseOrderList()
            })
        } else {
            this.getPurchaseOrderList()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("procurementPurchaseorder")
        this.setState({
            start: 0,
            length: 25,
            activePage: 1,
            search_number: "",
            search_status: "",
            search_minCreated: "",
            search_maxCreated: "",
            search_minExpectedDate: "",
            search_maxExpectedDate: "",
            search_vendorNumber: "",
            search_vendorName: "",
            search_minTotal: "",
            search_maxTotal: "",
            search_minTotalIncludingTax: "",
            search_maxTotalIncludingTax: "",
            search_minArrivalDate: "",
            search_maxArrivalDate: "",
            search_orderId: "",
            search_minOrderDate: "",
            search_maxOrderDate: "",
            search_invoiceId: "",
            search_minInvoiceDate: "",
            search_maxInvoiceDate: "",
            search_minPaymentDate: "",
            search_maxPaymentDate: "",
            search_minShippedOn: "",
            search_maxShippedOn: "",
            search_PoFreeText: "",
        }, () => {
            this.getPurchaseOrderList()
        })
    }


    exportPOPDF = () => {
        let responseData = this.state.orderList;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Purchase Orders";

        const headers = [[
            "Number",
            "Status",
            "Vendor Number",
            "Vendor Name",
            "Subtotal",
            "Grand total",
            "Created",
            "Expected Delivery Date",
        ]];

        const data = responseData.map((elt, i) => [
            elt.number,
            elt.status,
            elt.vendorNumber,
            elt.vendorName,
            elt.grandSubTotal,
            elt.grandTotal,
            convertDateStringMMDDtoDDMM(elt.created),
            convertDateStringMMDDtoDDMM(elt.expectedDate),
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("purchaseorders.pdf")
    }


    exportPOCSV = () => {
        let responseData = this.state.orderList;
        const title = "Purchase orders";
        const headers = [
            "Number",
            "Status",
            "Vendor Number",
            "Vendor Name",
            "Subtotal",
            "Grand total",
            "Created",
            "Expected Delivery Date",
        ];

        const data = responseData.map((elt, i) => [
            elt.number,
            elt.status,
            elt.vendorNumber,
            elt.vendorName,
            elt.grandSubTotal,
            elt.grandTotal,
            convertDateStringMMDDtoDDMM(elt.created),
            convertDateStringMMDDtoDDMM(elt.expectedDate)
        ]);


        const options = {
            filename: 'purchaseorders',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isPurchaseOrders={true} />

                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">{Strings.Procurement.PurchaseOrders}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportPOPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportPOCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton" style={{ width: 35 }}><div onClick={() => this.addPurchaseOrder()}><FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('status', 'notDate', false)}><span>status</span> {this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('vendorNumber', 'notDate', false)}><span>Vendor no. </span>{this.state.fieldName === "vendorNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "vendorNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('vendorName', 'notDate', false)}><span>Vendor name</span>{this.state.fieldName === "vendorName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "vendorName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('grandSubTotal', 'notDate', false)}><span>Subtotal </span>{this.state.fieldName === "grandSubTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandSubTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('grandTotal', 'notDate', false)}><span>Total </span>{this.state.fieldName === "grandTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('created', 'Date', false)}><span>Created </span>{this.state.fieldName === "created" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "created" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('expectedDate', 'Date', false)}><span>Expected Delivery Date </span>{this.state.fieldName === "expectedDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "expectedDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader tableHeadButton text-right"><div onClick={() => this.addPurchaseOrder()}><FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableAddButton" style={{ width: 35 }}>{/*<FiSettings />*/}</th>
                                    <th className="tableElemContain" style={{ width: 110 }}  >
                                        <input type="text" value={this.state.search_number} onChange={(e) => this.setState({ search_number: e.target.value }, () => { this._handleKeyDown() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 100 }}>
                                        <select value={this.state.search_status} onChange={(e) => this.setState({ search_status: e.target.value }, () => { this._handleKeyDown() })}>
                                            <option value=""></option>
                                            <option value="new po">New PO</option>
                                            {/* <option value="rfq">RFQ</option> */}
                                            <option value="ordered">Ordered</option>
                                            <option value="shipped">Shipped</option>
                                            <option value="partially received">Partially received</option>
                                            <option value="received">Received</option>
                                            <option value="archived">Archived</option>
                                            <option value="cancelled">Cancelled</option>
                                        </select>
                                    </th>
                                    <th className="tableElemContain"><input type="text" value={this.state.search_vendorNumber} onChange={(e) => this.setState({ search_vendorNumber: e.target.value }, () => { this._handleKeyDown() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.search_vendorName} onChange={(e) => this.setState({ search_vendorName: e.target.value }, () => { this._handleKeyDown() })} /></th>

                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.search_minTotal} placeholder="min" onChange={(e) => this.setState({ search_minTotal: e.target.value }, () => { this._handleKeyDown() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.search_maxTotal} placeholder="max" onChange={(e) => this.setState({ search_maxTotal: e.target.value }, () => { this._handleKeyDown() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.search_minTotalIncludingTax} placeholder="min" onChange={(e) => this.setState({ search_minTotalIncludingTax: e.target.value }, () => { this._handleKeyDown() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.search_maxTotalIncludingTax} placeholder="max" onChange={(e) => this.setState({ search_maxTotalIncludingTax: e.target.value }, () => { this._handleKeyDown() })} />
                                    </th>

                                    <th className="tableElemContain" style={{ width: 120 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.search_maxCreated}
                                                selected={this.state.search_minCreated}
                                                onSelect={(date) => this.setState({ search_minCreated: date }, () => { this._handleKeyDown() })}
                                                onChange={(date) => this.setState({ search_minCreated: date }, () => { this._handleKeyDown() })}
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative">
                                            <DatePicker
                                                minDate={this.state.search_minCreated}
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.search_maxCreated}
                                                onChange={(date) => this.setState({ search_maxCreated: date }, () => { this._handleKeyDown() })}
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>

                                    <th className="tableElemContain" style={{ width: 120 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.search_maxExpectedDate}
                                                selected={this.state.search_minExpectedDate}
                                                onChange={(date) => this.setState({ search_minExpectedDate: date }, () => { this._handleKeyDown() })}
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.search_minExpectedDate}
                                                selected={this.state.search_maxExpectedDate}
                                                onChange={(date) => this.setState({ search_maxExpectedDate: date }, () => { this._handleKeyDown() })}
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>



                                    {/* <th className="tableAddButton text-right" onClick={()=>{ this._handleKeyDown()}}><FiSearch /></th> */}
                                    <th className="tableAddButton text-right" onClick={() => { this.clearFilter() }}><FiX /></th>
                                </tr>
                                <tr>
                                    <th style={{ width: 35 }}></th>
                                    <th><b>Total:</b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <td><b>{(this.state.subTotal).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                    <td><b>{(this.state.grandTotal).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={10} />
                                    :
                                    <tbody>
                                        {
                                            this.state.orderList.map((data, i) => (
                                                <tr key={data._id} onClick={() => this.editPurchaseOrder(data._id, data.number)}>
                                                    <td style={{ width: 35 }}>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td>{data.status}</td>
                                                    <td className="text-uppercase">{data.vendorNumber}</td>
                                                    <td>{data.vendorName}</td>
                                                    <td>{data.grandSubTotal} <Bi.BiRupee /></td>
                                                    <td>{data.grandTotal} <Bi.BiRupee /></td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.expectedDate)}</td>
                                                    {/* <td></td> */}
                                                    <td className="text-right color-red">
                                                        {
                                                            data.is_important_note &&
                                                            <RiFlag2Line />
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default PurchaseOrders;