import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import * as Im from 'react-icons/im'
import * as Fi from 'react-icons/fi'
import * as Ai from "react-icons/ai"
import * as Bi from "react-icons/bi"
import * as ExportData from './exportData'
import * as APIManager from './../../../APIManager/APIManager'
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';

class QualityCheck extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            qcmDataList: [],

            number: "",
            purchaseOrderNumber: "",
            vendorName: "",
            minRecievedQty: "",
            maxRecievedQty: "",
            minApprovedQty: "",
            maxApprovedQty: "",
            minRejectedQty: "",
            maxRejectedQty: "",
            minModifiedDate: "",
            maxModifiedDate: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

            fieldName: '',
            fieldType: '',
            sortVal: false,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.qcmDataList.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ qcmDataList: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************

    getAllInvoiceByQCM = () => {
        this.setState({ isLoading: true, error: false })
        let {
            number,
            purchaseOrderNumber,
            vendorName,
            minRecievedQty,
            maxRecievedQty,
            minApprovedQty,
            maxApprovedQty,
            minRejectedQty,
            maxRejectedQty,
            minModifiedDate,
            maxModifiedDate,
            minInvoiceDate,
            maxInvoiceDate,
            start,
            length
        } = this.state
        APIManager.callGetPOInvoiceByQCM(start, length, number, purchaseOrderNumber, vendorName, minRecievedQty, maxRecievedQty, minApprovedQty, maxApprovedQty, minRejectedQty, maxRejectedQty, minModifiedDate, maxModifiedDate, minInvoiceDate, maxInvoiceDate)
            .then(response => {
                // console.log(response)
                this.setState({
                    qcmDataList: response.data,
                    totalRecords: response.totalRecords,
                    isLoading: false
                }, () => {
                    this.setFilterData();

                    if (this.state.fieldName) {
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    setFilterData = () => {
        let filterDataArray = {
            'number': this.state.number,
            'purchaseOrderNumber': this.state.purchaseOrderNumber,
            'vendorName': this.state.vendorName,
            'minRecievedQty': this.state.minRecievedQty,
            'maxRecievedQty': this.state.maxRecievedQty,
            'minApprovedQty': this.state.minApprovedQty,
            'maxApprovedQty': this.state.maxApprovedQty,
            'minRejectedQty': this.state.minRejectedQty,
            'maxRejectedQty': this.state.maxRejectedQty,
            'minModifiedDate': this.state.minModifiedDate,
            'maxModifiedDate': this.state.maxModifiedDate,
            'minInvoiceDate': this.state.minInvoiceDate,
            'maxInvoiceDate': this.state.maxInvoiceDate,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,

            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
        }
        localStorage.setItem("QualityCheakAk", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("QualityCheakAk"))
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                start: filterData.start,
                length: filterData.length,

                number: filterData.number,
                purchaseOrderNumber: filterData.purchaseOrderNumber,
                vendorName: filterData.vendorName,
                minRecievedQty: filterData.minRecievedQty,
                maxRecievedQty: filterData.maxRecievedQty,
                minApprovedQty: filterData.minApprovedQty,
                maxApprovedQty: filterData.maxApprovedQty,
                minRejectedQty: filterData.minRejectedQty,
                maxRejectedQty: filterData.maxRejectedQty,
                minModifiedDate: filterData.minModifiedDate,
                maxModifiedDate: filterData.maxModifiedDate,
                minInvoiceDate: filterData.minInvoiceDate,
                maxInvoiceDate: filterData.maxInvoiceDate,

                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getAllInvoiceByQCM()
            })
        } else {
            this.getAllInvoiceByQCM()
        }
    }

    createNewPoInvoice = () => {
        let slug = "Procurement_Invoices"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createpoinvoice",
                state: { poId: "", isFromPO: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editQCM = (id) => {
        this.props.history.push({
            pathname: "/editinvoiceqc",
            state: { invoiceId: id, isNewEntry: false }
        })
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllInvoiceByQCM()
        });
    }

    clearAllFilters = () => {
        this.setState({
            number: "",
            purchaseOrderNumber: "",
            vendorName: "",
            minRecievedQty: "",
            maxRecievedQty: "",
            minApprovedQty: "",
            maxApprovedQty: "",
            minRejectedQty: "",
            maxRejectedQty: "",
            minModifiedDate: "",
            maxModifiedDate: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            start: 0,
            length: 25,
        }, () => {
            this.getAllInvoiceByQCM()
        })
    }

    exportOptions = (type) => {
        let { qcmDataList } = this.state
        if (type === "pdf") {
            ExportData.exportInvoicePDF(qcmDataList);
        } else {
            ExportData.exportInvoiceCSV(qcmDataList);
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isQualityCheck={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">Invoices for Quality Check</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportOptions('pdf')}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportOptions('csv')}>CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton"></th>

                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate')}> <span>Invoice No.</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('purchaseOrderNumber', 'notDate')}> <span>Purchase Order</span> {this.state.fieldName === "purchaseOrderNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "purchaseOrderNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('vendorName', 'notDate')}> <span>Vendor</span> {this.state.fieldName === "vendorName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "vendorName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('receivedQuantity', 'notDate')}> <span>Received Qty </span>{this.state.fieldName === "receivedQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "receivedQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('approvedQuantity', 'notDate')}> <span>Approved Qty </span>{this.state.fieldName === "approvedQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "approvedQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('rejectedQuantity', 'notDate')}> <span>Rejected Qty </span>{this.state.fieldName === "rejectedQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "rejectedQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('invoiceDate', 'Date')}> <span>Invoice Date </span>{this.state.fieldName === "invoiceDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('modifiedDate', 'Date')}> <span>Modified Date </span>{this.state.fieldName === "modifiedDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "modifiedDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    <th className="tableHeader tableHeadButton"></th>
                                </tr>
                                <tr>

                                    <th></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllInvoiceByQCM() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.purchaseOrderNumber} onChange={(e) => this.setState({ purchaseOrderNumber: e.target.value }, () => { this.getAllInvoiceByQCM() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.vendorName} onChange={(e) => this.setState({ vendorName: e.target.value }, () => { this.getAllInvoiceByQCM() })} /></th>

                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minRecievedQty} placeholder="min" onChange={(e) => this.setState({ minRecievedQty: e.target.value }, () => { this.getAllInvoiceByQCM() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxRecievedQty} placeholder="max" onChange={(e) => this.setState({ maxRecievedQty: e.target.value }, () => { this.getAllInvoiceByQCM() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minApprovedQty} placeholder="min" onChange={(e) => this.setState({ minApprovedQty: e.target.value }, () => { this.getAllInvoiceByQCM() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxApprovedQty} placeholder="max" onChange={(e) => this.setState({ maxApprovedQty: e.target.value }, () => { this.getAllInvoiceByQCM() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minRejectedQty} placeholder="min" onChange={(e) => this.setState({ minRejectedQty: e.target.value }, () => { this.getAllInvoiceByQCM() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxRejectedQty} placeholder="max" onChange={(e) => this.setState({ maxRejectedQty: e.target.value }, () => { this.getAllInvoiceByQCM() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxInvoiceDate}
                                                selected={this.state.minInvoiceDate}
                                                onChange={(date) => this.setState({ minInvoiceDate: date }, () => { this.getAllInvoiceByQCM() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minInvoiceDate}
                                                selected={this.state.maxInvoiceDate}
                                                onChange={(date) => this.setState({ maxInvoiceDate: date }, () => { this.getAllInvoiceByQCM() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxModifiedDate}
                                                selected={this.state.minModifiedDate}
                                                onChange={(date) => this.setState({ minModifiedDate: date }, () => { this.getAllInvoiceByQCM() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minModifiedDate}
                                                selected={this.state.maxModifiedDate}
                                                onChange={(date) => this.setState({ maxModifiedDate: date }, () => { this.getAllInvoiceByQCM() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.clearAllFilters()}><Fi.FiX /></div></th>
                                </tr>


                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                    :
                                    <tbody>
                                        {
                                            this.state.qcmDataList.map((data, i) => (
                                                <tr key={data._id} onClick={() => this.editQCM(data._id)}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td className="text-uppercase">{data.purchaseOrderNumber}</td>
                                                    <td className="text-capitalize">{data.vendorName}</td>
                                                    <td>{data.receivedQuantity}</td>
                                                    <td>{data.approvedQuantity}</td>
                                                    <td>{data.rejectedQuantity}</td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.invoiceDate)}</td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.modifiedDate)}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default QualityCheck;