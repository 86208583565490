import React, { Component } from 'react';
import { Header } from './../../components';
import * as Im from "react-icons/im";
import * as Ai from "react-icons/ai";
import * as Fi from 'react-icons/fi';
import * as Fa from 'react-icons/fa';
import * as Bi from 'react-icons/bi';
import * as APIManager from './../../APIManager/APIManager'
import ViewBookingItems from './ViewBookingItems';
import { convertDateStringToDateAndTimeObject, convertDateTimeStringMMDDtoDDMM } from '../../constants/DateFormatter';

class ViewProductionPlan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            MO_Id: this.props.location.state.MO_Id,

            number: "loading...",
            productGroup: "loading...",
            productGroupName: "loading...",
            product: "loading...",
            productName: "loading...",
            uom: "loading...",
            quantity: "loading...",
            itemDetails: [],

            assignedTo: "loading...",
            targetLotNumber: "loading...",
            targetLotId: "loading...",

            billOfMaterials: "loading...",
            BOM: [],
            routings: [],
            created: "loading...",

            
            BOM_Details: [],
            BOM_name: "loading...",

            neededItems: [],

            status: "loading...",

            dueDate: "",
            startDate: "",
            finishDate: "",
            isFinishedProduct: true,

            
            totalCost: 0,
            costPer1Pc: 0,
            costOfMaterials: 0,
            appliedOverheadCost: 0,

            Routing___List: [],
            temDetails: [],
            productionOperations: [],
            responseNotes: [],
        }

        this.bookThisItem = this.bookThisItem.bind(this)
        this.cancelThisItem = this.cancelThisItem.bind(this)

    }
    goBack = () => {
        this.props.history.goBack();
    }

    componentDidMount(){
        this.getSingleMO(false)
    }

    getSingleMO = (defaultWorkerFlag) => {
        this.setState({ isLoading: true, error: false })

        APIManager.callSingleMO(this.state.MO_Id,defaultWorkerFlag)
        .then(response => {
            this.setState({

                isLoading: false,
                number: response.data.number,
                productGroup: response.data.productGroup,
                product: response.data.product,
                quantity: response.data.quantity,

                assignedTo: response.data.assignedTo,
                targetLotNumber: response.data.targetLotNumber,
                targetLotId: response.data.targetLotId,

                billOfMaterials: response.data.billOfMaterials[0].bomId,
                BOM: response.data.billOfMaterials,
                routings: response.data.routingDetails,
                created: response.data.created,

                status: response.data.status,

                dueDate: response.data.dueDate !== "" ? response.data.dueDate : "",
                startDate: response.data.startDate !== "" ? response.data.startDate : "",
                finishDate: response.data.finishDate !== "" ? response.data.finishDate : "",
                isFinishedProduct: response.data.is_MoStart === true ? false : true,
                
                totalCost: response.data.totalCost,
                costPer1Pc: response.data.costPer1Pc,
                costOfMaterials: response.data.costOfMaterials,
                appliedOverheadCost: response.data.appliedOverheadCost,

                responseNotes: response.data.notes
                
            },()=>{

                 this.getSingleProductGroup()
                 this.getSingleProduct()
                 this.getSingleBOM()
                 this.getProductionOperations()
                // this.getMOWriteOff()
            })

        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    }

    getProductionOperations = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callProductionOperations(this.state.MO_Id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productionOperations: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }

    bookThisItem = (product, productGroup, needed, available, expectedAvailable) => {
        let totalAvailable = parseInt(available) + parseInt(expectedAvailable)
        if(totalAvailable >= needed){
            localStorage.setItem("itemId", product)
            localStorage.setItem("itemGroupId", productGroup)
            localStorage.setItem("itemNeeded", needed)
            this.props.history.push("/addbooking")
        }else{
            this.setState({
                success: false,
                error: true,
                errormessage: "There is no stock available for this item"
            })
        }
    }

    cancelThisItem = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCancelSingleMOItem(this.state.MO_Id,id)
        .then(response => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.getBookingListForMo()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }

    bookAllParts = () => {
        if(this.state.bookAllDisabled !== true){
            this.setState({ isLoading: true, error: false })
            APIManager.callBookAllMOItem(this.state.MO_Id, JSON.stringify(this.state.neededItems))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.getBookingListForMo()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }


    releaseAllParts = () => {
        if(this.state.releaseAllDisabled !== true){
            this.setState({ isLoading: true, error: false })
            APIManager.callCancelAllMOItem(this.state.MO_Id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.getBookingListForMo()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }

    getSingleProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProductGroup(this.state.productGroup)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupName: response.number.toUpperCase() +" "+response.name
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }
    getSingleProduct = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase() +" "+response.itemData.partDescription,
                uom: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

  
    getSingleBOM = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleBOM(this.state.billOfMaterials)
        .then(response => {
            this.setState({
                BOM_Details: response,
                BOM_name: response.name,
            },()=>{
                this.getBookingListForMo()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }


    getBookingListForMo = () => {
        this.setState({ isLoading: true, error: false, itemDetails: [] })

        APIManager.callGetBookingForMO(this.state.MO_Id)
        .then(response => {

            let itemArray = response.data.itemArray
            let isBookDisabled = false;
            let countTotalNeeded = 0;
            let neededItems = []
            for(let i = 0; i < itemArray.length; i ++){
                countTotalNeeded += itemArray[i].needed
                if(itemArray[i].needed === 0){
                    isBookDisabled = true
                }
                let obj = {
                    part: itemArray[i]._id,
                    needed: itemArray[i].needed
                }
                neededItems.push(obj)
            }

            this.setState({
                isLoading: false,
                bookAllDisabled: isBookDisabled,
                releaseAllDisabled: countTotalNeeded === 0 ? false : true,
                itemDetails: response.data.itemArray,
                neededItems: neededItems,
                afterBookingData: response.data.afterBooking
            })

        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    } 

    finishProduction = () => {
        let slug = "MyProductionPlan"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
        
            let {
                MO_Id,
            } = this.state
            
            let finishDate = new Date()

            this.setState({ isLoading: true, error: false })
            APIManager.callStopMOProduction(MO_Id, convertDateStringToDateAndTimeObject(finishDate))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    fieldNotEditable: true
                },()=>{
                    this.getSingleMO()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    exportPDF = () => {
        window.open('productionplanpdf?moId='+this.state.MO_Id)
    }

    createNote = () => {
        this.props.history.push({
            pathname: "createmppnote",
            state: { MO_Id: this.state.MO_Id, MO_No: this.state.number}
        })
    }


    editMPPNote = (id, note) => {
        this.props.history.push({
            pathname: "/editmppnote",
            state: { 
                MO_Id: this.state.MO_Id, 
                MO_No: this.state.number, 
                Note_Id: id, 
                newNoteEntry: false,
                Note: note 
            }
        })
    }
    
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isMyProductionPlan={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-9 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title">Manufacturing order {this.state.number.toUpperCase()}</div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.exportPDF()}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            
                            {
                                this.state.isFinishedProduct ?
                                    <div className="add_btnSave finish__button__mpp disabled">
                                        <div className="button">Finish</div>
                                    </div>
                                :
                                    <div className="add_btnSave finish__button__mpp">
                                        <div className="button" onClick={()=>this.finishProduction()}>Finish</div>
                                    </div>
                            }
                            <div className="clear"></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Number * :</div>
                                        <div className="float-left width60 createdText">{this.state.number.toUpperCase()}</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Product group :</div>
                                        <div className="float-left width60 createdText">{this.state.productGroupName}</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Product :</div>
                                        <div className="float-left width60 createdText"><span className="clickable__source">{(this.state.productName).substr(0, 40)}...</span></div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Quantity * :</div>
                                        <div className="float-left width60 createdText">{this.state.quantity} {this.state.uom}</div>
                                        <div className="clear"></div>
                                    </div>
                                    {/* <div>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Customer orders * :</div>
                                        <div className="float-left width60 createdText"><span className="clickable__source">CO00001</span> 100 pcs</div>
                                        <div className="clear"></div>
                                    </div> */}
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Bill of materials :</div>
                                        <div className="float-left width60 createdText">{this.state.BOM_name}</div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Status * :</div>
                                        <div className="float-left width60 createdText text-capitalize">{this.state.status}</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Start :</div>
                                        <div className="float-left width60 createdText">{convertDateTimeStringMMDDtoDDMM(this.state.startDate)}</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className='mb-1'>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Finish :</div>
                                        <div className="float-left width60 createdText">{convertDateTimeStringMMDDtoDDMM(this.state.finishDate)}</div>
                                        <div className="clear"></div>
                                    </div>
                                    {/* <div>
                                        <div className="float-left width40 text-right pr-2 createdTextTitle m0">Target lot :</div>
                                        <div className="float-left width60 add_inner">
                                            <div className="createdText">#L00022</div>
                                            <div>
                                                <div className="mt-1 mb-1">
                                                    <div className="float-left width50">Storage location</div>
                                                    <div className="float-left width50">Quantity</div>
                                                    <div className="clear"></div>
                                                </div>
                                                <div className="extrafieldforContact">
                                                    <div className="float-left width50 p-1 text-capitalize">General</div>
                                                    <div className="float-left width50 p-1">0 pcs</div>
                                                    <div className="clear"></div>
                                                </div>
                                                
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div>&nbsp;</div>
                            </div>
                        </div>

                         <div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="page-small-title">Parts</div>
                                </div>
                            </div>

                            {/* <div className="p-0">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                                    <div className="head-import-buttons float-left">
                                        <div className={this.state.bookAllDisabled ? "button co__footer__button disabled" : "button co__footer__button"} onClick={()=>this.bookAllParts()}>Book all parts</div>
                                        <div className={this.state.releaseAllDisabled ? "button co__footer__button disabled" : "button co__footer__button"} onClick={()=>this.releaseAllParts()}>Release all booked parts</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div> */}

                            <div className="tableContent" style={{ overflowX : "auto" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="tableHeader" style={{ width: 175 }}>Stock Item</th>
                                            <th className="tableHeader" style={{ width: 100 }}>Needed</th>
                                            <th className="tableHeader" style={{ width: 100 }}>Total cost</th>
                                            <th className="tableHeader" style={{ width: 100 }}>Cost per unit</th>
                                            <th className="tableHeader" style={{ width: 100 }}>In stock</th>
                                            <th className="tableHeader" style={{ width: 100 }}>Available</th>
                                            <th className="tableHeader" style={{ width: 100 }}>Booked</th>
                                            <th className="tableHeader" style={{ width: 140 }}>Expected, available</th>
                                            <th className="tableHeader" style={{ width: 110 }}>After booking</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.itemDetails.length > 0 &&
                                        this.state.itemDetails.map((data, i) => (
                                            <ViewBookingItems
                                                key={'booking_list__'+i}
                                                number={'booking_list_'+i}
                                                itemDetails={data}
                                                bomDetails={this.state.BOM_Details.parts[i]}
                                                afterBookingData={this.state.afterBookingData[i]}
                                            />
                                        ))
                                     }
                                     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-small-title">Operations</div>
                        </div>
                    </div>

                    <div className="tableContent" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr> 
                                    <th className="tableHeader">Operation</th>
                                    <th className="tableHeader">Workstation</th>
                                    <th className="tableHeader">Planned start</th>
                                    <th className="tableHeader">Planned finish</th>
                                    <th className="tableHeader">Worker</th>
                                    <th className="tableHeader">Actual start</th>
                                    <th className="tableHeader">Actual finish</th> 
                                    <th className="tableHeader">Quantity</th> 
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.productionOperations.map((data, i) => ( 
                                        <tr key={"operations"+i}>
                                            <td>{data.workStationGroupName}</td>
                                            <td>{data.workStationName}</td>
                                            <td>{convertDateTimeStringMMDDtoDDMM(data.startDate)}</td>
                                            <td>{convertDateTimeStringMMDDtoDDMM(data.endDate)}</td>
                                            <td>{data.worker}</td>
                                            <td>{data.actualStart ? convertDateTimeStringMMDDtoDDMM(data.actualStart) : ""}</td>
                                            <td>{data.actualFinish ? convertDateTimeStringMMDDtoDDMM(data.actualFinish) : ""}</td>
                                            <td>{data.quantity} {this.state.uom}</td>
                                            {/* <td className="text-right clr-secondary start_stop_button___mpp"><Fi.FiPlayCircle /></td>
                                            <td className="text-right clr-secondary start_stop_button___mpp"><Fi.FiPauseCircle /></td>
                                            <td className="text-right clr-secondary start_stop_button___mpp"><Fi.FiStopCircle /></td>    */}
                                        </tr>
                                    ))
                                } 
                             </tbody>
                        </table>
                    </div>
                </div>

                    <div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-small-title">Notes</div>
                            </div>
                        </div>
                    </div>
                        
                    <div className="tableContent" style={{ overflowX : "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th className="tableHeader"><span>Created</span>  <Fa.FaAngleUp /></th>
                                <th className="tableHeader"><span>Modified</span></th>
                                <th className="tableHeader"><span>Note</span></th>
                                <th className="tableHeader text-right"><div onClick={()=>this.createNote()}><Fi.FiPlus /></div></th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                this.state.responseNotes !== null &&
                                this.state.responseNotes.map((data, i) => (
                                    <tr key={data.id} onClick={()=>this.editMPPNote(data.id,data.note)}>
                                        <td>
                                            <b>{data.creatorName}</b> <br />
                                            <span>{data.created}</span>
                                        </td>
                                        <td>
                                            <b>{data.modifierName}</b> <br />
                                            <span>{data.modified}</span>
                                        </td>
                                        <td className="preline">{data.note}</td>
                                        {/* <td><FiEdit2 className="editCusomerBtn" onClick={this.editItemNote.bind(this,data.id,data.note)}/></td> */}
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ViewProductionPlan;