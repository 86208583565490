import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../components'
import DatePicker from "react-datepicker";
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import * as Im from 'react-icons/im'
import Loading from './../../../../helper/loading'
import ProductGroup from './ProductGroup';
import { convertDateStringMMDDtoDDMM, convertDateStringtoDateObject, convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class UpdatePOInvoice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            poGroupList: [],
            poGroupData: [],
            poGroupRemainingData: [],
            purchaseOrderData: [],
            addressListVendor:[],

            debitNoteId: this.props.location.state.debitNoteId,
            isNewEntry: this.props.location.state.isNewEntry,
            number: "",
            _id:"",

            invoiceId: "",
            vendorId: "",
            purchaseOederInvoiceId: "",
            invoiceDate: "",
            created: "",

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            pdfType: "",
            is_e_invoice_possible: "",
            eInvoiceFlag: false,
            eWayBillFlag: "",
            invoice_id:"",

            vendroNumber: "",
            invoiceType: "",
            vendorName: "",
            currencyData:"",
            applicableTax: "",
            invoiceAddress: "",
            deliveryAddress:"",
            applicableTaxType: [],
            remainingScope: [],
            currencyDetails: [],

            fieldNotEditable: true,
            is_eInvoiceSetup: false,




        }
    }

    componentDidMount() {
        this.getSingleDebitNote()
    }

    getSingleDebitNote = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleDebitNote(this.state.debitNoteId)
            .then(response => {
                this.setState({
                    isLoading: false,
                    _id: response.data._id,
                    number: response.data.number,
                    purchaseOederInvoiceId: response.data.purchaseOederInvoiceId,
                    invoice_id: response.data.purchaseOederInvoiceId,
                    invoiceId: response.data.invoiceNumber,
                    invoiceAddress: response.data.invoiceAddress,
                    deliveryAddress: response.data.deliveryAddress,
                    invoiceType: response.data.invoiceType,
                    poGroupData: response.data.productGroup,
                    invoiceDate: convertDateStringMMDDtoDDMM(response.data.invoiceDate),
                    created: convertDateStringMMDDtoDDMM(response.data.created),
                    is_eInvoiceSetup: response.data.is_eInvoiceSetup ? response.data.is_eInvoiceSetup : false,
                    is_e_invoice_possible: response.data.eInvoiceDetail ? true : false,
                    eInvoiceFlag: response.data.eInvoiceFlag ? response.data.eInvoiceFlag : false,
                }, () => {
                    this.getSingleVendorDetail(response.data.vendor)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }
    getSingleVendorDetail = (id) => {

        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(id)
            .then(response => {
                let number = response.number ? response.number.toUpperCase() : ""
                let name = response.name

                this.setState({
                    isLoading: false,
                    vendorId: id,
                    vendroNumber: number,
                    vendorName: name,
                    currencyData: response.conversionDetail,
                    applicableTaxType: response.taxApplicable,
                    currencyDetails: response.conversionDetail ? response.conversionDetail : []
                }, () => {
                    this.setGroupData()
                    this.getShippngAddressOfVender(id)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    getShippngAddressOfVender = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callVendorShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressListVendor: response,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        } 
    }

    setGroupData = () => {
        let data = this.state.poGroupData
        let group = []

        for (let i = 0; i < data.length; i++) {
            group.push(
                <ProductGroup
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    currencyDetails={this.state.currencyDetails}
                    key={"groupData" + i}
                    data={data[i]}
                    uom={data[i].uom}
                    vendorId={this.state.vendorId}
                    callBack={this.setUpdatedGroupData}
                    scopeDetails={this.state.remainingScope[i] ? this.state.remainingScope[i] : null}
                />
            )
        }
        this.setState({
            poGroupList: group,
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let poGroupData = [...this.state.poGroupData]
        poGroupData[counter] = value;
        this.setState({
            poGroupData
        }, () => {
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.poGroupData
        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        for (let i = 0; i < data.length; i++) {
            subTotal += parseFloat(data[i].subTotal)
            grandTotal += parseFloat(data[i].total)
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        }, () => {
            let tds = this.state.tds_percentage
            if (this.state.grandTotal >= 5000000 && tds === "") {
                this.setState({
                    is_tds_applicable: true,
                    is_tds_locked: true,
                    tds_percentage: 0.1,
                }, () => {
                    this.changeTDSPercentage(this.state.tds_percentage)
                })
            } else if (this.state.grandTotal >= 5000000 && tds !== "") {
                this.setState({
                    is_tds_locked: true,
                }, () => {
                    this.changeTDSPercentage(this.state.tds_percentage)
                })
            } else {
                this.setState({
                    is_tds_locked: false,
                }, () => {
                    this.changeTDSPercentage(this.state.tds_percentage)
                })
            }
        })
    }

    updateDebitNote = () => {
        if (!this.state.fieldNotEditable) {
            let {
                subTotal,
                grandTotal,
                poGroupData,
                debitNoteId,
                purchaseOederInvoiceId,
                deliveryAddress,
            } = this.state

            let countgrandTotal = Math.round(parseFloat(grandTotal))

            if (countgrandTotal === 0) {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter debit quanity"
                }, () => {
                    this.scrollToTop()
                })
            } else {
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateDebitNote(debitNoteId, purchaseOederInvoiceId, JSON.stringify(poGroupData), subTotal, grandTotal,deliveryAddress)
                    .then(response => {
                        this.setState({
                            isLoading: false,
                            success: true,
                            fieldNotEditable: true,
                            successmessage: "Invoice updated successfully"
                        }, () => {
                            this.scrollToTop()
                        })
                    })
                    .catch(errors => {
                        this.setState({
                            isLoading: false,
                            error: true,
                            errormessage: errors
                        })
                    });
            }
        } else {
            let slug = "Procurement_DebitNote"
            let permission = "update"
            if (APIManager.checkUserPermission(slug, permission)) {
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            } else {
                alert("You don't have permission to " + permission + " this record")
            }
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    deleteEntry = () => {
        let slug = "Procurement_DebitNote"
        let permission = "delete"
        if (APIManager.checkUserPermission(slug, permission)) {
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteDebitNote(this.state.debitNoteId)
                    .then(response => {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.goBack()
                        })
                    })
                    .catch(errors => {
                        this.setState({
                            isLoading: false,
                            error: true,
                            errormessage: errors
                        })
                    });
            }
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    changeTDSPercentage = (val) => {
        let per = parseFloat(val)
        this.setState({
            tds_percentage: (per >= 0 && per <= 100) || per === "" ? per : ''
        }, () => {
            let grandTotal = this.state.currencyDetails.conversionRate ? this.state.grandTotalInOther : this.state.grandTotal
            if (this.state.tds_percentage) {
                let tds_per = this.state.tds_percentage

                let tds_val = (grandTotal * tds_per / 100).toFixed(2)

                this.setState({
                    tds_value: parseFloat(tds_val),
                    after_tds_grandtotal: parseFloat(grandTotal) + parseFloat(tds_val),
                    tds_grandtotal_ininr: parseFloat(this.state.grandTotal) + parseFloat(tds_val),
                    tds_grandtotal_inother: parseFloat(this.state.grandTotalInOther) + parseFloat(tds_val)
                })
            }
        })
    }

    eInvoiceDetailPage = () => {
        let slug = "Procurement_DebitNote"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/dnvieweinvoice",
                state: { debitNoteId: this.state.debitNoteId }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    
    downloadPDFInOtherCurrency = () => {
        console.log('@@@@@ this.state.currencyData.conversionRate'  ,this.state.currencyData.conversionRate ? 'success' : 'fail');
        if(this.state.pdfType === "originalinother"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceother')
        }else if(this.state.pdfType === "duplicateinother"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceother')
        }else if(this.state.pdfType === "triplicateinother"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceother')
        }else if(this.state.pdfType === "extrainother"){
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceother')
        }else if(this.state.pdfType === "originalininr"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceotherinr')
        }else if(this.state.pdfType === "duplicateininr"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceotherinr')
        }else if(this.state.pdfType === "triplicateininr"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceotherinr')
        }else{
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("_id", this.state._id)
            window.open('dninvoiceotherinr')

        }
    }
    downloadPDF = () => {
        console.log('@@@@@ this.state.currencyData.conversionRate'  ,this.state.currencyData.conversionRate ? 'success' : 'fail');
        localStorage.setItem("pdfType", this.state.pdfType)
        console.log("pdfType", this.state.pdfType);
        localStorage.setItem("_id", this.state._id)
        window.open('dninvoicepdf')
    }
    
    generateEWayBillPDF = () => {
        window.open('cnewaybillpdf?_id='+this.state._id)
    }
    
    downloadPDFInOtherCurrency1 = () => {
        localStorage.setItem("_id", this.state._id)
        window.open('dninvoiceother')
    }
    goBack = () => {
        if (this.state.isNewEntry) {
            this.props.history.go(-2)
        } else {
            this.props.history.goBack()
        }
    }



    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isDebitNote={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="page-title">Edit Debit Note {this.state.number.toUpperCase()}</div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="head-import-buttons float-right">

                                    {
                                        this.state.currencyData.conversionRate ?
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e) => this.setState({ pdfType: e.target.value })}>
                                                    <option value="originalinother">Original in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="duplicateinother">Duplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="triplicateinother">Triplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="extrainother">Extra in {this.state.currencyData.currencySymbol}</option>
                                                </select>
                                                <div className="button" onClick={() => this.downloadPDFInOtherCurrency()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && this.state.eInvoiceFlag === false) &&
                                                    <div className="button" onClick={() => this.eInvoiceDetailPage()}>Generate E-Invoice</div>
                                                }

                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && !this.state.eWayBillFlag)) &&
                                                    <div className="button" onClick={() => this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && this.state.eWayBillFlag)) &&
                                                    <div className="button" onClick={() => this.generateEWayBillPDF()}>Download E-Way PDF</div>
                                                }
                                                <div className="clear"></div>
                                            </div>
                                            :
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e) => this.setState({ pdfType: e.target.value })}>
                                                    <option value="original">Original</option>
                                                    <option value="duplicate">Duplicate</option>
                                                    <option value="triplicate">Triplicate</option>
                                                    <option value="extra">Extra</option>
                                                </select>

                                                <div className="button" onClick={() => this.downloadPDF()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && this.state.eInvoiceFlag === false) &&
                                                    <div className="button" onClick={() => this.eInvoiceDetailPage()}>Generate E-Invoice</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && !this.state.eWayBillFlag)) &&
                                                    <div className="button" onClick={() => this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && this.state.eWayBillFlag)) &&
                                                    <div className="button" onClick={() => this.generateEWayBillPDF()}>Download E-Way PDF</div>
                                                }

                                                <div className="clear"></div>
                                            </div>
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.updateDebitNote()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={() => this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.invoiceId}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Vendor</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.vendroNumber.toUpperCase()} {this.state.vendorName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Address</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceAddress.toUpperCase()} {this.state.vendorName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">

                                <div className="add_inner">
                                    <div className="title">Invoice Type</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceType}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceDate}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery Address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.addressListVendor.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.deliveryAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ deliveryAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>



                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="main-container mt-5 invoice_product_group position-relative">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Received Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <th className='pg-tab-head'>Subtotal</th>
                                        }

                                        {
                                            this.state.applicableTaxType.map((data, i) => (
                                                <th className='pg-tab-head' key={'appTax' + i}>{data}</th>
                                            ))
                                        }
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <th className='pg-tab-head'>Eligible Tax*</th>
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Debit Qty</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.poGroupList}

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <td><b>{this.state.subTotal} <Bi.BiRupee /></b></td>
                                        }
                                        {
                                            this.state.applicableTaxType.map((data, i) => (
                                                <td key={'appTaxBlankField' + i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <td></td>
                                        }
                                        {
                                            this.state.currencyDetails.currencyName !== "" ?
                                                <td><b>{this.state.currencyDetails.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                                :
                                                <td><b>{this.state.grandTotal} <Bi.BiRupee /></b></td>

                                        }

                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.updateDebitNote()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={() => this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default UpdatePOInvoice;