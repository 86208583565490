import React from 'react';
import { Header, StockHeader } from './../../../components'

import Helper from './../../../helper/helper'

import {
    AiOutlineExclamation
} from "react-icons/ai";
import { WEBSERVICES_URL } from '../../../APIManager/APIManager';

export default class CreateProductGroup extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

        }
    }

    componentDidMount(){
        
    }
    
    createGroup = () => {
        let that = this;
        let name = this.name.value;
        if(name === ""){
            this.setState({
                error: true,
                errormessage: "Please enter name",
            })
        }else{
           
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("name", name);

            fetch(WEBSERVICES_URL+'stock/createProductGroup',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        localStorage.setItem("createNewProGrpEdit", true)
                        localStorage.setItem("prodGrpId",result.data._id)
                        localStorage.setItem("prodGrpNum",result.data.number)
                        localStorage.setItem("prodGrpName",result.data.name)
                        that.props.history.push('/editproductgroup')
                    } else if(result.status === "userDataError"){
                        that.logout()
                    } else{
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            ) 
        }
    }
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <StockHeader isStockSettings={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a product group</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">


                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createGroup()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value"><input type="text" className="fieldheightdecr" ref={(c) => this.name = c} name="name" autoComplete='off'></input></div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createGroup()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}