import React from 'react';
import { Link } from 'react-router-dom';

import { Header, CRMHeader } from './../../../components'
import { validateNumber } from './../../../constants/Validate'

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';
import * as APIManager from './../../../APIManager/APIManager'

import Loading from './../../../helper/loading'
import * as Ai from 'react-icons/ai'


import {
    FiX,
    FiPlus
} from "react-icons/fi";

import {
    AiOutlineCalendar,
    AiOutlineExclamation
} from "react-icons/ai";

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import ContactComponent from './../../../components/AutoGenerateComponent/addContactInformation'
import { convertDateToDDMMYYYY } from '../../../constants/DateFormatter';

export default class EditCustomerCompany extends React.Component {

    constructor(props) {
        super(props);
        let today = new Date();

        this.state = {
            isLoading: true,

            companyId: this.props.location.state.cmpId,
            title: this.props.location.state.cmpIdNum + " " + this.props.location.state.cmpName,
            date: today,
            email: "",
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            currencyData: [],
            currency: "",

            singleAccountManagerVal: "",   
            accountManagerExtraField: [],
            accountManagerCounter: 0,
            accountManagerList: [],
            selectedAccountManager: [],
            selectedAccountManagerId: [],

            countContactFieldNumber: [],
            contactFieldCounter: 0,
            contactExtraFields: [],
            contactInformation: [],
            generateNewContact: false,

            contactPerson: [],
            contactNotes: [],

            contactStarted: "",
            nextContact: "",
            creditLimit: "",

            name: "",
            nickname: "",
            status: "",
            panno: "",
            gstnumber: "",
            deliveryterms: "",

            taxApplicable: [],
            companyStatusData: [],
            companyIndustryData: [],
            industryType: [],

            paymentValue: "",
            paymentDaysFrom: "",
            paymentStatus: "",
            paymentTerm: {
                "paymentValue": "",
                "daysFrom": "",
                "paymentStatus": "",
            },
            userDetails: [],

            fieldNotEditable: true,
            createNewEdit: this.props.location.state.createNewEdit
            
        }
    }


    componentDidMount() {
        let user = localStorage.getItem("userdata")
        let userDetails = JSON.parse(user)

        this.setState({
            userDetails: userDetails,
            email: userDetails.email,
        })

        this.getCustomerStatus()
        this.getUserList()
        this.getContactPerson()
        this.getCompanyIndustry()
        this.getAllCurrency()

        window.addEventListener('scroll', () => {
            if (window.scrollY <= 350) {
                this.setState({
                    sHide: "block"
                })
            } else {
                this.setState({
                    sHide: "none"
                })
            }
        });
    }

    getAllCurrency = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCurrency()
            .then(response => {
                this.setState({
                    isLoading: false,
                    currencyData: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyIndustryData: response.companyIndustry !== undefined ? response.companyIndustry : null
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getCustomerStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerStatus()
        .then(response => {
            this.setState({
                isLoading: false,
                companyStatusData: response
            }, () => {
                this.getCompanyDetails()
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    updatePaymentTermObject = (type) => {
        let paymentTerm = this.state.paymentTerm

        if (type === 'paymentValue')
            paymentTerm.paymentValue = this.state.paymentValue;

        if (type === 'paymentDaysFrom')
            paymentTerm.daysFrom = this.state.paymentDaysFrom;

        if (type === 'paymentStatus')
            paymentTerm.paymentStatus = this.state.paymentStatus;

        this.setState({
            paymentTerm
        })
    }


    getContactPerson = () => {
        this.setState({ isLoading: true, error: false })
        let cmpId = this.state.companyId
        APIManager.callContactPerson(cmpId)
            .then(response => {
                this.setState({
                    isLoading: false,
                    contactPerson: response ? response : []
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    getCompanyDetails = () => {
        this.setState({ isLoading: true, error: false })
        let cmpId = this.state.companyId
        APIManager.callCustomerSingleDetail(cmpId)
            .then(response => {
                let cs = response.companyData.contactStarted;
                let nc = response.companyData.nextContact;
                let contactStarted = ""
                let nextContact = ""
                if (cs) {
                    let spD = cs.split("/");
                    contactStarted = new Date(spD[1] + "/" + spD[0] + "/" + spD[2]);
                }
                if (nc) {
                    let nextCon = nc.split("/");
                    nextContact = new Date(nextCon[1] + "/" + nextCon[0] + "/" + nextCon[2]);
                }

                this.setState({
                    isLoading: false,
                    contactStarted: contactStarted,
                    nextContact: nextContact,
                    paymentValue: response.companyData.paymentTerms ? response.companyData.paymentTerms.paymentValue : "",
                    paymentDaysFrom: response.companyData.paymentTerms ? response.companyData.paymentTerms.daysFrom : "",
                    paymentStatus: response.companyData.paymentTerms ? response.companyData.paymentTerms.paymentStatus : "",
                    paymentTerm: response.companyData.paymentTerms ? response.companyData.paymentTerms : {},
                    name: response.companyData.companyName,
                    nickname: response.companyData.nickName,
                    status: response.companyData.status,
                    panno: response.companyData.panNo,
                    gstnumber: response.companyData.gstNo,
                    deliveryterms: response.companyData.deliveryTerms,
                    taxApplicable: response.companyData.taxApplicable,
                    industryType: response.companyData.industryType,
                    contactNotes: response.companyNotes,
                    creditLimit: response.companyData.creditLimit ? response.companyData.creditLimit : "",
                    currency: response.companyData.currency ? response.companyData.currency : ""
                }, () => {
                    this.setContactInfo(response.companyData.contactInformation)
                    this.setTaxApplicable(response.companyData.taxApplicable)
                    this.setAccountManager(response.companyData.accountManager)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    updateCompany = () => {

        if (this.state.fieldNotEditable !== true) {
            let cmpId = this.state.companyId

            let {
                name,
                nickname,
                status,
                panno,
                gstnumber,
                contactInformation,
                contactStarted,
                nextContact,
                paymentTerm,
                deliveryterms,
                creditLimit,
                taxApplicable,
                selectedAccountManager,
                industryType,
                currency
            } = this.state

            /* Remove Null From State */
            let contactInfo = [];
            contactInformation.forEach((val) => {
                if(val !== null){
                    contactInfo.push(val);
                }
            });
            /* Remove Null */

            if (name === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter a valid company name (max. 100 characters)"
                }, () => {
                    this.scrollToTop()
                })
            } else if (nickname === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter a valid nickname (max. 50 characters)"
                }, () => {
                    this.scrollToTop()
                })
            } else if (industryType.length === 0) {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select industry type"
                }, () => {
                    this.scrollToTop()
                })
            } else if (contactStarted === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter contact started date"
                }, () => {
                    this.scrollToTop()
                })
            } else if (selectedAccountManager === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select an account manager"
                }, () => {
                    this.scrollToTop()
                })
            } else if (taxApplicable.length === 0) {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select applicable tax"
                }, () => {
                    this.scrollToTop()
                })
            } else {
                let uniqueTaxArray = taxApplicable.filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                })

                this.setState({ isLoading: true, error: false })

                APIManager.callUpdateCompany(cmpId, name, nickname, status, panno, gstnumber, JSON.stringify(contactInfo), convertDateToDDMMYYYY(contactStarted), convertDateToDDMMYYYY(nextContact), "", JSON.stringify(paymentTerm), "", deliveryterms, creditLimit, JSON.stringify(uniqueTaxArray), JSON.stringify(selectedAccountManager), JSON.stringify(industryType), currency)
                    .then(response => {
                        this.setState({
                            isLoading: false,
                            error: false,
                            successmessage: response.message,
                            success: true,
                            fieldNotEditable: true,
                        }, () => {
                            this.scrollToTop()
                        })
                    })
                    .catch(errors => {
                        this.setState({
                            isLoading: false,
                            error: true,
                            errormessage: errors
                        })
                    });
            }
        } else {
            let slug = "CRM_Customers"
            let permission = "update"
            if (APIManager.checkUserPermission(slug, permission)) {
                this.setState({
                    fieldNotEditable: false,
                })
            } else {
                alert("You don't have permission to " + permission + " this record")
            }

        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }



    deleteEntry = () => {

        let slug = "CRM_Customers"
        let permission = "delete"
        if (APIManager.checkUserPermission(slug, permission)) {
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                let cmpId = this.state.companyId
                APIManager.callDeleteCompany(cmpId)
                    .then(response => {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.goBack()
                        })
                    })
                    .catch(errors => {
                        this.setState({
                            isLoading: false,
                            error: true,
                            errormessage: errors
                        })
                    });
            }
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }


    setContactInfo = (data) => {
        let localFieldNumber = []
        let localExtraField = []

        for (let i = 0; i < data.length; i++) {
            localFieldNumber.push(i)
            localExtraField.push(
                <ContactComponent
                    key={i}
                    componentNum={i}
                    value={data[i].value}
                    type={data[i].type}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            )
        }
        this.setState({
            countContactFieldNumber: localFieldNumber,
            contactFieldCounter: data.length,
            contactExtraFields: localExtraField,
            contactInformation: data,
        }, () => {
            this.setNewField(data.length)
        })
    }

    setNewField = (num) => {
        this.setState({
            countContactFieldNumber: this.state.countContactFieldNumber.concat([num + 1]),
            contactFieldCounter: num + 1,
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent
                    key={num + 1}
                    componentNum={num + 1}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ]),
            generateNewContact: true,
        })
    }



    setAccountManager = (data) => {

        if (data) {
            let selectedAccountManager = []
            let selectedAccountManagerId = []
            let accountManagerExtraField = []
            let accountManagerCounter = 0;
            for (let i = 0; i < data.length; i++) {
                accountManagerCounter++;
                let name = data[i].userName;

                let value = data[i].userId;

                let accManObj = {
                    id: data[i].userId,
                    permission: data[i].permission
                }

                selectedAccountManager.push(accManObj)
                selectedAccountManagerId.push(value)
                accountManagerExtraField.push(
                    <div className="extrafieldforContact"
                        key={
                            "amkey" +Math.random()+ this.state.accountManagerCounter
                        }>
                        <div className="float-left width40 accountManagerNm">
                            {name}
                        </div>
                        <div className="float-left width50 fixedHeight30">
                            <div className="checkboxInputs">
                                <input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("read", value, e)
                                        }
                                    }
                                    defaultChecked={data[i].permission.read}
                                /></div>
                            <div className="checkboxInputs"><input type="checkbox"
                                style={
                                    { width: 14 }
                                }
                                onChange={
                                    (e) => {
                                        this.updateManagerPermission("create", value, e)
                                    }
                                }
                                defaultChecked={data[i].permission.create}
                            /></div>
                            <div className="checkboxInputs"><input type="checkbox"
                                style={
                                    { width: 14 }
                                }
                                onChange={
                                    (e) => {
                                        this.updateManagerPermission("update", value, e)
                                    }
                                }
                                defaultChecked={data[i].permission.update}
                            /></div>
                            <div className="checkboxInputs"><input type="checkbox"
                                style={
                                    { width: 14 }
                                }
                                onChange={
                                    (e) => {
                                        this.updateManagerPermission("delete", value, e)
                                    }
                                }
                                defaultChecked={data[i].permission.delete}
                            /></div>
                        </div>
                        <div className="float-left width10 removeButton">
                            <FiX className="extraFieldCloseButton" onClick={
                                () => this.removeAccountMangerField(accManObj)
                            } />
                        </div>
                        <div className="clear"></div>
                    </div>
                )
            }
            this.setState({
                selectedAccountManager: selectedAccountManager,
                selectedAccountManagerId: selectedAccountManagerId,
                accountManagerExtraField: accountManagerExtraField,
                accountManagerCounter: accountManagerCounter,
            },()=>{
                console.log(selectedAccountManagerId)
                console.log(selectedAccountManager)
            })
        }
    }


    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllAccountManagers()
            .then(response => {
                this.setState({
                    isLoading: false,
                    accountManagerList: response.data.length > 0 ? response.data : []
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    contactInfoInputVal = (val, number) => {
        let that = this;
        let value = val.trim().replaceAll("_", "").trim();

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]

        if (typeof CIVal[index] === 'undefined' && this.state.generateNewContact === true && value.length > 0) {
            this.setState({
                contactFieldCounter: this.state.contactFieldCounter + 1,
                generateNewContact: false,
            }, () => {
                this.setState({
                    countContactFieldNumber: this.state.countContactFieldNumber.concat(
                        [this.state.contactFieldCounter]
                    ),
                    contactExtraFields: this.state.contactExtraFields.concat(
                        [
                            <ContactComponent
                                key={
                                    this.state.contactFieldCounter
                                }
                                value={""}
                                type={"Phone"}
                                fun_checkInputBlur={
                                    this.contactInfoInputBlur
                                }
                                componentNum={
                                    this.state.contactFieldCounter
                                }
                                fun_checkContactInfoInput={
                                    that.contactInfoInputVal
                                }
                                fun_removeContactInfoField={
                                    this.contactInfoFieldRemove
                                }
                            />
                        ]
                    ),
                })
            })

        }
    }


    contactInfoInputBlur = (val, type, number) => {

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]

        if (typeof CIVal[index] !== 'undefined') {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                let contactInformation = [...this.state.contactInformation];
                contactInformation[index] = obj;

                this.setState({
                    contactInformation,
                    generateNewContact: true,
                })
            }
        } else {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                this.setState({
                    contactInformation: this.state.contactInformation.concat([obj]),
                    generateNewContact: true,
                })
            }
        }
    }

    contactInfoFieldRemove = (number) => {

        let array = [...this.state.countContactFieldNumber];
        let fields = [...this.state.contactExtraFields];
        let data = [...this.state.contactInformation]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;

            this.setState({
                countContactFieldNumber: array,
                contactExtraFields: fields,
                contactInformation: data
            });
        }
    }

    removeAccountMangerField = (val) => {
        let id = [...this.state.selectedAccountManagerId];
        let vl = [...this.state.selectedAccountManager];
        let fields = [...this.state.accountManagerExtraField];

        let index = id.indexOf(val.id)
        if (index !== -1) {
            vl.splice(index, 1)
            fields.splice(index, 1)
            id.splice(index, 1)
            this.setState({
                selectedAccountManager: vl,
                accountManagerExtraField: fields,
                selectedAccountManagerId: id,
            })
        }
    }


    updateManagerPermission = (permission, id, elem) => {
        let user = [...this.state.selectedAccountManager]
        let obj = this.search(id, user);

        let permIndex = obj.permission;
        permIndex[permission] = elem.target.checked;


        let accManObj = {
            id: obj.id,
            permission: permIndex
        }

        let index = user.indexOf(obj)

        user[index] = accManObj;

        this.setState({
            selectedAccountManager: user
        },()=>{
            console.log(JSON.stringify(user))
        })
    }

    search(nameKey, myArray) {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].id === nameKey) {
                return myArray[i];
            }
        }
    }

    addNewAccountMangerField = (event) => {
        let eventIndex = event.target.selectedIndex
        let name = event.target[eventIndex].innerText;

        let value = this.state.singleAccountManagerVal;

        let vl = [...this.state.selectedAccountManager];

        let obj = this.search(value, vl);

        if (typeof obj === 'undefined') {

            let accManObj = {
                id: value,
                permission: {
                    read: false,
                    create: false,
                    update: false,
                    delete: false
                }
            }

            this.setState({
                selectedAccountManager: this.state.selectedAccountManager.concat([accManObj]),
                selectedAccountManagerId: this.state.selectedAccountManagerId.concat([value]),
                accountManagerExtraField: this.state.accountManagerExtraField.concat(
                    [
                        <div className="extrafieldforContact"
                            key={
                                "amkey" +Math.random()+ this.state.accountManagerCounter
                            }>
                            <div className="float-left width40 accountManagerNm">
                                {name}
                            </div>
                            <div className="float-left width50 fixedHeight30">
                                <div className="checkboxInputs">
                                    <input type="checkbox"
                                        style={
                                            { width: 14 }
                                        }
                                        onChange={
                                            (e) => {
                                                this.updateManagerPermission("read", value, e)
                                            }
                                        }
                                    /></div>
                                <div className="checkboxInputs"><input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("create", value, e)
                                        }
                                    } /></div>
                                <div className="checkboxInputs"><input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("update", value, e)
                                        }
                                    } /></div>
                                <div className="checkboxInputs"><input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("delete", value, e)
                                        }
                                    } /></div>
                            </div>
                            <div className="float-left width10 removeButton">
                                <FiX className="extraFieldCloseButton" onClick={
                                    () => this.removeAccountMangerField(accManObj)
                                } />
                            </div>
                            <div className="clear"></div>
                        </div>
                    ]
                )
            }, () => {
                this.setState({
                    accountManagerCounter: this.state.accountManagerCounter + 1
                }, () => {
                    this.setState({ singleAccountManagerVal: ""})
                })
            })
        } else {
            this.setState({ singleAccountManagerVal: ""})
        }
    }



    updateTaxApplicable = (name, check) => {
        let taxApplicable = [...this.state.taxApplicable]

        if (check === true) {
            if (name === "notax") {
                for (let i = 0; i < taxApplicable.length; i++) {
                    document.getElementById(taxApplicable[i]).checked = false;
                }
                let allTax = [name]
                this.setState({
                    taxApplicable: allTax,
                })
            } else {
                if (name === "exemptedtax") {
                    for (let i = 0; i < taxApplicable.length; i++) {
                        document.getElementById(taxApplicable[i]).checked = false;
                    }
                    document.getElementById("IGST").checked = true;
                    this.setState({
                        taxApplicable: ['IGST', 'exemptedtax'],
                        currency: ""
                    })

                } else {
                    if (taxApplicable.length === 2) {
                        document.getElementById(taxApplicable[0]).checked = false;
                        document.getElementById(taxApplicable[1]).checked = false;
                        let allTax = [name]
                        this.setState({
                            taxApplicable: allTax,
                            currency: ""
                        })
                    } else {
                        if (taxApplicable[0] === 'notax') {
                            document.getElementById(taxApplicable[0]).checked = false;
                            let allTax = [name]
                            this.setState({
                                taxApplicable: allTax,
                                currency: ""
                            })
                        } else {
                            if (taxApplicable[0] !== "CGST" && taxApplicable[0] !== "SGST") {
                                if (name === "CGST" || name === "SGST") {
                                    for (let i = 0; i < taxApplicable.length; i++) {
                                        document.getElementById(taxApplicable[i]).checked = false;
                                    }
                                    let allTax = [name]
                                    this.setState({
                                        taxApplicable: allTax,
                                        currency: ""
                                    })
                                } else {
                                    this.setState({
                                        taxApplicable: this.state.taxApplicable.concat([
                                            name
                                        ]),
                                        currency: ""
                                    })
                                }
                            } else {
                                this.setState({
                                    taxApplicable: this.state.taxApplicable.concat([
                                        name
                                    ]),
                                    currency: ""
                                })
                            }

                        }

                    }
                }

            }
        } else {
            if (name === "IGST") {
                for (let i = 0; i < taxApplicable.length; i++) {
                    document.getElementById(taxApplicable[i]).checked = false;
                }
                this.setState({
                    taxApplicable: [],
                    currency: ""
                })
            } else {
                let index = taxApplicable.indexOf(name)
                if (index > -1) {
                    taxApplicable.splice(index, 1);
                    this.setState({
                        taxApplicable,
                        currency: ""
                    })
                }
            }
        }
    }


    updateCurrencyData = (value) => {
        let taxApplicable = [...this.state.taxApplicable]
        for (let i = 0; i < taxApplicable.length; i++) {
            document.getElementById(taxApplicable[i]).checked = false;
        }
        this.setState({
            currency: value,
            taxApplicable: ["notax"]
        }, () => {
            document.getElementById("notax").checked = true
        })
    }

    setTaxApplicable = (data) => {
        if (data) {
            document.getElementById("SGST").checked = data.includes("SGST");
            document.getElementById("CGST").checked = data.includes("CGST");
            document.getElementById("IGST").checked = data.includes("IGST");
            document.getElementById("notax").checked = data.includes("notax");
            document.getElementById("exemptedtax").checked = data.includes("exemptedtax");
        }
    }


    goBack = () => {
        if (this.state.createNewEdit) {
            localStorage.removeItem("createNewEdit")
            this.props.history.go(-2)
        } else {
            this.props.history.goBack()
        }
    }

    setContactDate = (dt) => {
        this.setState({ contactStarted: dt })
    }

    setNextContact = (dt) => {
        let slug = "CRM_Customers"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.setState({ nextContact: dt }, () => {
                if (this.state.fieldNotEditable) {
                    this.setState({
                        fieldNotEditable: false
                    }, () => {
                        this.updateCompany()
                    })
                }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }

    }

    createContactPerson = () => {
        let slug = "CRM_Customers"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push("/createcontactperson")
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editCustomerContact = (id) => {
        localStorage.setItem("customerContactId", id)
        this.props.history.push('/editcontactperson')
    }

    editCustomerNotes = (id, note) => {
        localStorage.setItem("customerNoteId", id)
        localStorage.setItem("customerNote", note)
        this.props.history.push('/editcontactnote')
    }

    addNewStatus = () => {
        this.props.history.push('/createcustomerstatus')
    }
    addNewIndustryType = () => {
        this.props.history.push('/editIndustryType')
    }

   

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Company <span className="text-capitalize">{this.state.title}</span> details</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">

                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack"
                                onClick={
                                    () => this.goBack()
                                }>Back</div>
                            <div className="add_btnSave"
                                onClick={
                                    () => this.updateCompany()
                                }>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack"
                                onClick={
                                    () => this.deleteEntry()
                                }>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Name*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            value={this.state.name}
                                            onChange={(e) => this.setState({ name: e.target.value })}
                                            name="name"></input>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Nickname*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            value={this.state.nickname}
                                            onChange={(e) => this.setState({ nickname: e.target.value })}
                                            name="name"></input>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Company Status</div>
                                    <div className="value">
                                        <select
                                            value={this.state.status}
                                            name="status"
                                            onChange={(e) => {
                                                if (e.target.value === 'addNew') {
                                                    this.addNewStatus()
                                                } else {
                                                    this.setState({
                                                        status: e.target.value
                                                    })
                                                }
                                            }
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>Add new status</option>
                                            {
                                                this.state.companyStatusData !== null &&
                                                this.state.companyStatusData.map((data, i) => (
                                                    <option value={data._id} key={i + data.name}>{data.value}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">PAN No.</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr text-uppercase"
                                            autoComplete="off"
                                            value={this.state.panno}
                                            onChange={(e) => this.setState({ panno: e.target.value })}
                                            name="regno"
                                        ></input>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">GST No.</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr text-uppercase"
                                            autoComplete="off"
                                            value={this.state.gstnumber}
                                            onChange={(e) => this.setState({ gstnumber: e.target.value })}
                                            name="taxnumber"></input>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Industry type</div>
                                    <div className="value" style={{ height: 150 }}>
                                        <select
                                            value={this.state.industryType}
                                            style={{ height: 150 }}
                                            onChange={(e) => {
                                                if (e.target.value === 'addNew') {
                                                    this.addNewIndustryType()
                                                } else {
                                                    let value = Array.from(e.target.selectedOptions, option => option.value);
                                                    this.setState({ industryType: value })
                                                }
                                            }}
                                            multiple
                                        >
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic", cursor: 'pointer' }}>Add new Industry Type</option>
                                            {
                                                this.state.companyIndustryData !== null &&
                                                this.state.companyIndustryData.map((data, i) => (
                                                    <option value={data.industrytype} key={i + data.industrytype}>{data.industrytype}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner contactinfo">

                                    {/* Contact Information fields */}

                                    <div>
                                        <div className="addNewCustomerContactTitle">
                                            <div className="title2">Contact information</div>
                                        </div>
                                        <div className="addNewCustomerContactField">

                                            <div className="mt-1 mb-1">
                                                <div className="float-left width40">Type</div>
                                                <div className="float-left width60">Value</div>
                                                <div className="clear"></div>
                                            </div>


                                            {
                                                this.state.contactExtraFields
                                            }


                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Contact started</div>
                                    <div className="value">

                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.contactStarted
                                            }
                                            onChange={
                                                date => this.setContactDate(date)
                                            }
                                            className="fieldheightdecr" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner zIndex1001">
                                    <div className="title">Next contact</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.nextContact
                                            }
                                            onChange={
                                                date => this.setNextContact(date)
                                            }
                                            className="fieldheightdecr" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Payment terms</div>
                                    <div className="value">

                                        <div>
                                            <div className="float-left createdText border-0">Payment from</div>
                                            <input
                                                type="text"
                                                className="float-left width10"
                                                value={this.state.paymentDaysFrom}
                                                onChange={(e) => {
                                                    this.setState({ paymentDaysFrom: e.target.value >= 0 ? e.target.value : 0 }, () => {
                                                        this.updatePaymentTermObject('paymentDaysFrom')
                                                    })
                                                }}
                                            />
                                            <div className="float-left createdText text-center border-0">days of</div>
                                            <div className="float-left">
                                                <select
                                                    value={this.state.paymentStatus}
                                                    onChange={(e) => {
                                                        if (e.target.value !== "") {
                                                            this.setState({ paymentStatus: e.target.value }, () => {
                                                                this.updatePaymentTermObject('paymentStatus')
                                                            })
                                                        }
                                                    }}>
                                                    <option value="">Select</option>
                                                    <option value="from_invoice">Invoice</option>
                                                    <option value="from_confirmation">Confirmation</option>
                                                    <option value="from_arrival">Arrival</option>
                                                </select>
                                            </div>

                                            <div className="clear"></div>
                                        </div>

                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Delivery terms</div>
                                    <div className="value">
                                        <select
                                            value={this.state.deliveryterms}
                                            onChange={(e) => this.setState({ deliveryterms: e.target.value })}
                                            name="deliveryterms"
                                        >
                                            <option value="">Select</option>
                                            <option value="ex-works">Ex-works</option>
                                            <option value="c and f">C and F</option>
                                            <option value="cif">CIF</option>
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Currency</div>
                                    <div className='value'>
                                        <Select2
                                            data={
                                                this.state.currencyData.map((data) => (
                                                    { id: data._id, text: data.currencyName }
                                                ))
                                            }
                                            value={this.state.currency}
                                            options={{ placeholder: 'Select Currency' }}
                                            onSelect={(e) => this.updateCurrencyData(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Credit limit</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            value={this.state.creditLimit}
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value)) {
                                                    this.setState({
                                                        creditLimit: e.target.value
                                                    })
                                                }
                                            }}
                                            name="creditLimit"></input>
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner contactinfo">

                                    {/* Tax Applicable fields */}
                                    <div>
                                        <div className="addNewCustomerContactTitle">
                                            <div className="title2">Tax applicable*</div>
                                        </div>
                                        <div className="addNewCustomerContactField">
                                            <div className="mt-1 mb-1 mr-1">
                                                <div className="float-left width40">Type</div>
                                                <div className="float-left width60 text-center">Applicable</div>
                                                <div className="float-left width20 text-center"></div>
                                                <div className="clear"></div>
                                            </div>



                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    CGST
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="CGST"
                                                            onChange={(e) => this.updateTaxApplicable("CGST", e.target.checked)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    SGST
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="SGST"
                                                            onChange={(e) => this.updateTaxApplicable("SGST", e.target.checked)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    IGST
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="IGST"
                                                            onChange={(e) => { this.updateTaxApplicable("IGST", e.target.checked) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="float-left width20 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <div className='float-left mt-1'>Exempted</div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                style={{ width: 14 }}
                                                                id="exemptedtax"
                                                                onChange={(e) => { this.updateTaxApplicable("exemptedtax", e.target.checked) }}
                                                            />
                                                        </div>
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    No Tax
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="notax"
                                                            onChange={(e) => { this.updateTaxApplicable("notax", e.target.checked) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>



                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>

                                </div>

                                <div className="add_inner contactinfo">
                                    {/* Account Manager fields */}
                                    {
                                        this.state.userDetails.role !== "subUser" &&
                                        <div>
                                            <div className="addNewCustomerContactTitle">
                                                <div className="title2 accManagerTitle">Account Manager</div>
                                            </div>
                                            <div className="addNewCustomerContactField">
                                                <div className="mt-1 mb-1 mr-1">
                                                    <div className="float-left width40">
                                                        {/* <select
                                                            ref={
                                                                (c) => this.accountmanager = c
                                                            }
                                                            name="accountmanager"
                                                            className="text-capitalize"
                                                            onChange={
                                                                (event) => this.addNewAccountMangerField(event, this.accountmanager)
                                                            }>
                                                            <option value="">Select</option>
                                                            {
                                                                this.state.accountManagerList !== null && this.state.accountManagerList.map((data) => (
                                                                    <option value={
                                                                        data._id
                                                                    }
                                                                        key={
                                                                            data._id
                                                                        }>
                                                                        {
                                                                            data.username ? data.username : data.full_name
                                                                        }</option>
                                                                ))
                                                            } </select> */}

                                                        <Select2
                                                            data={
                                                                this.state.accountManagerList.map((data) => (
                                                                    { id: data._id, text: data.username ? data.username : data.full_name }
                                                                ))
                                                            }
                                                            value={this.state.singleAccountManagerVal}
                                                            options={{ placeholder: 'Select Account Manager' }}
                                                            onSelect={(e) => {
                                                                    this.setState({
                                                                        singleAccountManagerVal: e.target.value
                                                                    },()=>{
                                                                        this.addNewAccountMangerField(e)
                                                                    })    
                                                                }
                                                            }
                                                        />

                                                    </div>
                                                    <div className="float-left width50">
                                                        <div className="userPermissionChecks">Read</div>
                                                        <div className="userPermissionChecks">Create</div>
                                                        <div className="userPermissionChecks">Update</div>
                                                        <div className="userPermissionChecks">Delete</div>
                                                    </div>
                                                    <div className="clear"></div>
                                                </div>
                                                {
                                                    this.state.accountManagerExtraField
                                                }

                                                <div className="clear"></div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    }
                                    <div className="clear"></div>
                                </div>


                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack"
                                onClick={
                                    () => this.goBack()
                                }>Back</div>
                            <div className="add_btnSave"
                                onClick={
                                    () => this.updateCompany()
                                }>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack"
                                onClick={
                                    () => this.deleteEntry()
                                }>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>


                    <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                        <div className="footerTitle">Contacts</div>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader w-50"><span>Name</span></th>
                                    <th className="tableHeader"><span>Position</span></th>
                                    <th className="tableHeader"><span>Phone</span></th>
                                    <th className="tableHeader"><span>Skype</span></th>
                                    <th className="tableHeader"><span>Email</span></th>
                                    <th className="tableAddButton text-right"><div onClick={() => this.createContactPerson()} className="addContactButton"><FiPlus /></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contactPerson !== null &&
                                    this.state.contactPerson.map((data, i) => (
                                        <tr key={data._id} onClick={this.editCustomerContact.bind(this, data._id)}>
                                            <td>{data.name}</td>
                                            <td>{data.position}</td>
                                            <td>{data.Phone}</td>
                                            <td>{data.Skype}</td>
                                            <td className="text-lowercase">{data.Email}</td>
                                            {/* <td className="text-right"><FiEdit2 className="editCusomerBtn" onClick={this.editCustomerContact.bind(this,data._id)}/></td> */}
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                        <div className="footerTitle">Notes</div>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader"><span>Created</span></th>
                                    <th className="tableHeader"><span>Modified</span></th>
                                    <th className="tableHeader w-50">Notes</th>
                                    <th className="tableAddButton text-right"><Link to="/createcontactnote" className="addContactButton"><FiPlus /></Link></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.contactNotes.length !== 0 &&
                                    this.state.contactNotes.notes.map((data, i) => (
                                        <tr key={data.id} onClick={this.editCustomerNotes.bind(this, data.id, data.note)}>
                                            <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br /><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                            <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br /><span style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                            <td className="texttop preline">{data.note}</td>
                                            {/* <td className="text-right"><FiEdit2 className="editCusomerBtn" onClick={this.editCustomerNotes.bind(this,data.id,data.note)}/></td> */}
                                            <td></td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

               
            </div>
        )
    }
}