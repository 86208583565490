import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateStatus extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            statusName: "",
            statusValue: "",

        }
    }

    componentDidMount(){
        
    }

    createStatus = () => {
        let {
            statusName,
            statusValue,
        } = this.state

        if(statusName === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter name"
            })
        }else if(statusValue === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter value"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateStatus(statusName, statusValue)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: '/editcustomerstatus',
                        state: { statusId: response._id, newStatusEntry: true }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }

  
   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create New Company Status</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createStatus()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value"><input type="text" style={{ width: 250 }} className="fieldheightdecr" value={this.state.statusName} onChange={(e)=>this.setState({ statusName: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>

                    

                            <div className="add_inner">
                                <div className="title">Value</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        style={{width: 250}}
                                        value={this.state.statusValue}
                                        onChange={(e)=>this.setState({ statusValue: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createStatus()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}