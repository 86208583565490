import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Helper from '../../../helper/helper'
import Loading from '../../../helper/loading'

import {
    AiOutlineExclamation
} from "react-icons/ai";
import { WEBSERVICES_URL } from '../../../APIManager/APIManager';

export default class CreateVendorNotes extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            title: "",
            id: "",
            note: "",
        }
      
    }

    componentDidMount(){
        let num = localStorage.getItem("vendorNumber")
        let name = localStorage.getItem("vendorName")
        let id = localStorage.getItem("vendorId")
        this.setState({ title: num+ " " +name, id: id})
    }

    createNotes = () => {
        let note = this.state.note;
        
        const that = this;

        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

           

            let formdata = new FormData();
            formdata.append("id", this.state.id);
            formdata.append("note", note);
            
            fetch(WEBSERVICES_URL+'procurement/vendors/addVendorNotes',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        localStorage.setItem("createNewVendorNote", true)
                        localStorage.setItem("vendorNoteId", result.data)
                        localStorage.setItem("vendorNote", that.state.note)
                        this.props.history.push('/editvendornote')
                   }else if(result.status === "userDataError"){
                        that.setState({
                            loading: false
                        },()=>{     
                            that.logout()
                        })
                   }else{
                        that.setState({
                            loading: false,
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            )
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCustomerOrder={true} />
                <ProcurementHeader isVendor={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Create a note</div>
                        </div>
                    </div>
                </div>
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Vendor </div>
                                <div className="value companyname text-capitalize">{this.state.title}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        onChange={(e)=>this.setState({ note: e.target.value})} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            {
                this.state.isLoading &&
                <Loading />
            }
            </div>
        )
    }
}