import React from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import * as LS from './../../../constants/SetLocalstorage'
import { convertDateTimeStringMMDDtoDDMM } from '../../../constants/DateFormatter';
export default class BOMInfo extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            fieldNotEditable: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            productGroupList: [],
            productList: [],
            lotNotes: [],

            BOM_id: this.props.location.state.BOM_id,

            productGroup: LS.getLocalForm("__LocalData_create__MO", "productGroup"),
            product: LS.getLocalForm("__LocalData_create__MO", "product"),
            quantity: LS.getLocalForm("__LocalData_create__MO", "quantity"),
            dueDate: LS.getLocalForm("__LocalData_create__MO", "dueDate") !== "" ? new Date(LS.getLocalForm("__LocalData_create__MO", "dueDate")) : "",
            startDate: "",
            finishDate: "",
            assignedUser: LS.getLocalForm("__LocalData_create__MO", "assignedTo"),

            setBOM: LS.getLocalForm("__LocalData_create__MO", "setBOM"),
            setRouting: LS.getLocalForm("__LocalData_create__MO", "setRouting"),
            bomDetails: LS.getLocalForm("__LocalData_create__MO", "bomDetails"),
            selectedBOMnumber: LS.getLocalForm("__LocalData_create__MO", "selectedBOMnumber") ? LS.getLocalForm("__LocalData_create__MO", "selectedBOMnumber") : 0,
            selectedRoutingnumber: LS.getLocalForm("__LocalData_create__MO", "selectedRoutingnumber") ? LS.getLocalForm("__LocalData_create__MO", "selectedRoutingnumber") : 0,

            productName: "",
            uom: "",
            BOMNumber: "",
            BOMName: "",
            routingName: "",
            ApproximateCost: "",

            Routing___List: [],
            BOMparts: [],


        }
    }

    componentDidMount(){
        this.setState({
            startDate: this.state.setBOM[0].startDate,
            finishDate: this.state.setBOM[0].endDate,
            routingName: this.state.setRouting[0].routingName
        },()=>{
            this.getSingleProduct()
            this.getSingleBOM()
            this.getEndDate()
        })
        
    }

    getSingleProduct = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase() +" "+response.itemData.partDescription,
                uom: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getSingleBOM = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleBOM(this.state.BOM_id)
        .then(response => {
            this.setState({
                BOMName: response.name,
                BOMNumber: response.number.toUpperCase(),
                BOMparts: response.parts,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

   


    goBack = () => {
        if(localStorage.getItem("createStockLot") !== null){
            localStorage.removeItem("createStockLot")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    updateSelectedRouting = (i) => {
        this.setState({
            selectedRoutingnumber: i
        },()=>{
            LS.setLocalForm("__LocalData_create__MO", "selectedRoutingnumber", i) 
        })
    }

    getEndDate = () => {
        let {
            startDate,
            bomDetails,
            quantity,
            selectedRoutingnumber
        } = this.state;

        startDate = new Date(startDate)

        var hours = startDate.getHours();
        var minutes = startDate.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        let newStartDate =  startDate.getMonth()+1  +"/"+ startDate.getDate() + "/" + startDate.getFullYear() + " " + strTime;

        this.setState({ isLoading: true, error: false })
        APIManager.callGetEndDateMO(newStartDate, bomDetails, quantity, "")
        .then(response => {
            
            let routingListData = response.data[this.state.selectedBOMnumber].routingInfo
            
            let listingData = []
           // for(let i = 0;i<routingListData.length; i++){
                let routingList = routingListData
                listingData.push(<tr className="font-weight-bold" key={"routings1"}>
                    <td><input type="radio" name="selectRouting" defaultChecked={true}/></td>
                    <td>{routingList.routingName}</td>
                    <td>{routingList.totalCost}</td>
                    <td></td>
                    <td>{routingList.totalCost}</td>
                    <td>{convertDateTimeStringMMDDtoDDMM(routingList.routingStartDate)}</td>
                    <td>{convertDateTimeStringMMDDtoDDMM(routingList.routingEndDate)}</td>
                    <td className="text-right clr-secondary"></td>
                </tr>)

                let workstation = routingList.workStationDetails
                
                if(workstation.length > 0){
                    for(let j = 0; j<workstation.length; j++){
                        listingData.push( <tr key={"routing"+j}>
                            <td></td>
                            <td>{workstation[j].workStationGroupName}</td>
                            <td></td>
                            <td>{workstation[j].workStationName}</td>
                            <td>{workstation[j].manufacturingOverhead}</td>
                            <td>{convertDateTimeStringMMDDtoDDMM(workstation[j].startDate)}</td>
                            <td>{convertDateTimeStringMMDDtoDDMM(workstation[j].endDate)}</td>
                            <td className="text-right clr-secondary">{/*<Fi.FiEdit2 />*/}</td>
                        </tr>)
                    }
                }
           // }

            this.setState({
                isLoading: false,
                success: false,
                error: false,
                Routing___List: listingData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
          
    }

    modifyInfo = () => {
        this.props.history.push({
            pathname: "/modifyoperation",
            state: {}
        })
    }
    
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isManufacturingOrders={true} />
               
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">BOM Information</div>
                            </div>
                        </div>
                    </div>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title text-left">Product :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{this.state.productName.length > 40 ? this.state.productName.substring(0, 40)+'...' : this.state.productName}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Quantity :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{this.state.quantity} {this.state.uom}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Number :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{this.state.BOMNumber}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Description :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{this.state.BOMName.length > 40 ? this.state.BOMName.substring(0, 40)+'...' : this.state.BOMName}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Routing :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{this.state.routingName.length > 40 ? this.state.routingName.substring(0, 40)+'...' : this.state.routingName} </div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Approximate cost :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{this.state.setBOM[this.state.selectedBOMnumber].approximationCost}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Earliest start date :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{convertDateTimeStringMMDDtoDDMM(this.state.startDate)}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_inner">
                            <div className="title text-left">Earliest end date :</div>
                            <div className="value">
                                <div className="createdText text-capitalize">{convertDateTimeStringMMDDtoDDMM(this.state.finishDate)}</div>
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="clear"></div>


                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Parts</div>
                        </div>
                    </div>
                    
                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader">Stock item</th>
                                    <th className="tableHeader">Quantity</th>
                                    <th className="tableHeader">Total cost</th>
                                    <th className="tableHeader">Cost per unit</th>
                                    {/* <th className="tableHeader">Lot</th>
                                    <th className="tableHeader">Status</th>
                                    <th className="tableHeader">Storage location</th>
                                    <th className="tableHeader">Available from</th> */}
                                    <th className="tableHeader text-right"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.BOMparts.map((data, i) => (
                                        <tr className="font-weight-bold" key={"BOMinfo_"+i}>
                                            <td>
                                                {data.itemCode} {data.partDescription}
                                            </td>
                                            <td>{data.quantity*this.state.quantity} {data.uom}</td>
                                            <td>{(data.averageCost/data.quantity) * (data.quantity*this.state.quantity)}</td>
                                            <td>{data.averageCost/data.quantity}</td>
                                            {/* <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td> */}
                                            <td className="text-right clr-secondary">{/* <Fi.FiEdit2 /> */}</td>
                                        </tr>
                                    ))
                                }
                                {/* <tr className="font-weight-bold">
                                    <td style={{ width: 300 }}>
                                        AO0001 Table top
                                    </td>
                                    <td>1,000 pcs</td>
                                    <td>$ 5,000.00</td>
                                    <td>$ 5.00</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right clr-secondary"><Fi.FiEdit2 /></td>
                                </tr>
                                <tr className="clr-danger">
                                    <td></td>
                                    <td>1,000 pcs</td>
                                    <td>$ 5,000.00</td>
                                    <td>$ 5.00</td>
                                    <td>Not booked</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right clr-secondary"></td>
                                </tr>
                                <tr className="font-weight-bold">
                                    <td>
                                       AO0001 Table leg
                                    </td>
                                    <td>1,000 pcs</td>
                                    <td>$ 5,000.00</td>
                                    <td>$ 5.00</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right clr-secondary"><Fi.FiEdit2 /></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>1,000 pcs</td>
                                    <td>$ 5,000.00</td>
                                    <td>$ 5.00</td>
                                    <td>LO0002</td>
                                    <td>Received</td>
                                    <td>General</td>
                                    <td>29/10/2021</td>
                                    <td className="text-right clr-secondary"></td>
                                </tr>
                                <tr className="clr-danger">
                                    <td></td>
                                    <td>1,000 pcs</td>
                                    <td>$ 5,000.00</td>
                                    <td>$ 5.00</td>
                                    <td>Not booked</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right clr-secondary"></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Routings</div>
                        </div>
                    </div>
                    
                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 20 }}></th>
                                    <th className="tableHeader">Routing</th>
                                    <th className="tableHeader">Total cost</th>
                                    <th className="tableHeader">Workstation</th>
                                    <th className="tableHeader">Manufacturing overhead</th>
                                    <th className="tableHeader">Start</th>
                                    <th className="tableHeader">Finish</th>
                                    <th className="tableHeader text-right"></th>
                                </tr>
                            </thead>
                            <tbody>

                            
                                {
                                    this.state.Routing___List
                                }
                               
                                {/* <tr className="font-weight-bold">
                                    <td><input type="radio" name="selectRouting" /></td>
                                    <td><b>#R-00001 Wooden table routing ||</b></td>
                                    <td><b>1755.00 US$</b></td>
                                    <td></td>
                                    <td><b>1500.00 US$</b></td>
                                    <td><b>8/20/2021 16:55</b></td>
                                    <td><b>8/23/2021 16:55</b></td>
                                    
                                    <td className="text-right clr-secondary"></td>
                                </tr>
                                

                                <tr>
                                    <td></td>
                                    <td>Assembling B</td>
                                    <td></td>
                                    <td>#WCT-00001-2 Woodenworks(2)</td>
                                    <td>1500.00 US$</td>
                                    <td>8/20/2021 16:55</td>
                                    <td>8/23/2021 16:55</td>
                                  
                                    <td className="text-right clr-secondary"><Fi.FiEdit2 /></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Paintings B</td>
                                    <td></td>
                                    <td>#WCT-00001-2 Woodenworks(1)</td>
                                    <td>1500.00 US$</td>
                                    <td>8/20/2021 16:55</td>
                                    <td>8/23/2021 16:55</td>
                                   
                                    <td className="text-right clr-secondary"><Fi.FiEdit2 /></td>
                                </tr> */}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

