import React from 'react';


import DatePicker from "react-datepicker";
import './../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'

import { 
    FiX,
} from "react-icons/fi";


export default class AddIdleTime extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            startTime: "",
        }

    }

    componentDidMount(){
       let sTime = this.props.startTime
       
        if(sTime !== ""){
            let date = sTime
            date = date.split("-")
            date = date[1]+"-"+date[0]+"-"+date[2]
            this.setState({
                startTime: new Date(date)
            }) 
        }


        // if(sTime !== ""){
        //     let newDate = sTime.split(" ")
        //     let date = newDate[0]
        //     date = date.split("-")
        //     date = date[1]+"-"+date[0]+"-"+date[2]+" "+newDate[1]
        //     this.setState({
        //         startTime: new Date(date)
        //     }) 
        // }

        // if(eTime !== ""){
        //     let newDate = eTime.split(" ")
        //     let date = newDate[0]
        //     date = date.split("-")
        //     date = date[1]+"-"+date[0]+"-"+date[2]+" "+newDate[1]
        //     this.setState({
        //         endTime: new Date(date)
        //     }) 
        // }
    }
   

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
            
        } = this.props
        return(
            <tr>
                <td className="position-relative">
                    <DatePicker 
                        selected={this.state.startTime}
                        onChange={date => this.setState({ startTime: date },()=>{
                            onEdit(this.state.startTime, componentNum)
                            onBlur(this.state.startTime, componentNum)
                        })}
                        maxDate={this.state.endTime}
                        dateFormat="dd/MM/yyyy"
                    />
                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                </td>
                
                <td>
                    {
                        this.state.startTime !== "" && this.state.endTime !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}