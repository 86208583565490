import React, { Component } from 'react';
import * as APIManager from './../../APIManager/APIManager'

import { 
    AiOutlineClose,
    AiOutlineExclamation,
    AiOutlineCalendar,
} from 'react-icons/ai'

import DatePicker from "react-datepicker";

import AssignUserField from './AssignUserField';


class AddTask extends Component {

    constructor(props){
        super(props)
        this.state = {

            editTask: this.props.editTask,
            closetask: this.props.closetask,

            userRole: JSON.parse(localStorage.getItem("userdata")).role,

            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            subject: "",
            duedate: "",
            description: "",

            countFieldNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,
        }
    }

    componentDidMount = () => {
        this.addNewField() 
    }

    addNewField = () => {
        this.setState({
            count: this.state.count + 1,
            generateNewType: true,
        }, () => {
            this.setState({
                countFieldNumber: this.state.countFieldNumber.concat(
                    [this.state.count]
                ),
                addTypeFields: this.state.addTypeFields.concat(
                    [
                        <AssignUserField 
                            key={this.state.count}
                            componentNum={this.state.count}
                            tablefield={""}
                            onBlur={this.assignFieldBlur}
                            onEdit={this.assignFieldEdit}
                            onRemove={this.assignFieldRemove}
                        />
                    ]
                ),
            })
        })
    }

    assignFieldEdit = (fields, num) => {

        let array = [...this.state.countFieldNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
    

        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && fields !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countFieldNumber: this.state.countFieldNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <AssignUserField 
                                key={this.state.count}
                                componentNum={this.state.count}
                                tablefield={""}
                                onBlur={this.assignFieldBlur}
                                onEdit={this.assignFieldEdit}
                                onRemove={this.assignFieldRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    assignFieldBlur = (fields, num) => {
         let array = [...this.state.countFieldNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
            
             if (fields !== "") {
                 let obj = fields
                 
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (fields !== "") {
                 let obj = fields
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    assignFieldRemove = (number) => {
    
        let array = [...this.state.countFieldNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countFieldNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }


    saveTask = () => {
        let {
            subject,
            duedate,
            description,
            typeFieldValues,
        } = this.state;

        /* Remove Null From State */
        let taskAssignee = [];
        typeFieldValues.forEach((val) => {
            if(val !== null){
                taskAssignee.push(val);
            }
        });
        /* Remove Null */

        if(taskAssignee.length === 0){
            taskAssignee.push(JSON.parse(localStorage.getItem("userdata"))._id)
        }

        if(subject === ""){
            this.setState({
                error: true,
                errormessage: "Please enter subject",
            })
        }else if(taskAssignee.length === 0){
            this.setState({
                error: true,
                errormessage: "Please select assignee",
            })
        }else if(duedate === ""){
            this.setState({
                error: true,
                errormessage: "Please select duedate",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateTask(subject,JSON.stringify(taskAssignee),description,duedate,false)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.state.closetask()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }        
    }
   

    render() {
        let {
            closetask
        } = this.props
        return (
            <div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="page-title ml-4">Create a task</div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="page-title text-right mr-4">
                            <div className="d-inline-block" onClick={closetask}><AiOutlineClose /></div>
                        </div>
                    </div>
                </div>

                {
                    this.state.error &&
                    <div className="editscreenerror">
                        <div className="errornotification">
                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                        </div>
                    </div>
                }
                {
                    this.state.success &&
                    <div className="editscreenerror">
                        <div className="successnotification">
                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                        </div>
                    </div>
                }

                <div className="tableContent">
                    <div className="mt-5">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Subject*</div>
                                <div className="value">
                                    <input type="text" value={this.state.subject} onChange={(e)=>this.setState({ subject: e.target.value })} className="fieldheightdecr" autoComplete="off" />
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Assigned to</div>
                                <div className="value">
                                    <table>
                                        <tbody>
                                            {this.state.addTypeFields}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Due date</div>
                                <div className="value">
                                    <DatePicker 
                                        selected={this.state.duedate}
                                        onChange={date => this.setState({ duedate: date })}
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput
                                        minDate={new Date()}
                                    />
                                    <AiOutlineCalendar className="datepickerIcon cursor-na" /> 
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner">
                                <div className="title">Description</div>
                                <div className="value">
                                    {/* <input type="text" value={this.state.partDesc} onChange={(e)=>this.setState({ partDesc: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" /> */}
                                    <textarea
                                        autoComplete="off" 
                                        className="fieldheightdecr item_free_text_area"
                                        value={this.state.description}
                                        onChange={(e)=>this.setState({ description: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={closetask}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.saveTask()}>Save</div>
                        <div className="clearfix"></div>
                    </div>
                </div>

            </div>
        );
    }
}

export default AddTask;

