import React, { Component } from 'react';
import './../../../lib/oem-table/index.css'
import Pagination from "react-js-pagination";

import * as APIManager from './../../../APIManager/APIManager';
import * as Fi from "react-icons/fi";

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css'
import TableLoading from './../../../helper/TableLoading'

import {
    AiOutlineCalendar,
} from "react-icons/ai"
import { convertDateStringMMDDtoDDMM, convertDateStringtoDateObject, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

class ItemTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            history: this.props.history,
            getItemList: this.props.getItemList,
            OrderItemList: [],

            number: "",
            customerNumber: "",
            customerName: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            productStatus: "",
            partNo: "",
            partDescription: "",
            minQuantity: "",
            maxQuantity: "",
            minTotal: "",
            maxTotal: "",
            minSubTotal: "",
            maxSubTotal: "",
            minDeliveredQuantity: "",
            maxDeliveredQuantity: "",
            minRemainingQuantity: "",
            maxRemainingQuantity: "",
            productGroupName: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

            totalQty: 0,
        }
    }


    componentDidMount() {
        this.getFilterData()
    }

    getCustomerOrderItems = () => {
        this.setFilterData()

        let {
            start,
            length,
            number,
            customerNumber,
            customerName,
            minCreated,
            maxCreated,
            minDeliveryDate,
            maxDeliveryDate,
            productStatus,
            partNo,
            partDescription,
            minQuantity,
            maxQuantity,
            minTotal,
            maxTotal,
            minSubTotal,
            maxSubTotal,
            minDeliveredQuantity,
            maxDeliveredQuantity,
            minRemainingQuantity,
            maxRemainingQuantity,
            productGroupName,
            fieldName
        } = this.state

        this.setState({ isLoading: true, error: false })

        APIManager.callOrderItemList(
            start,
            length,
            number,
            customerNumber,
            customerName,
            convertDateToMMDDYYYY(minCreated),
            convertDateToMMDDYYYY(maxCreated),
            convertDateToMMDDYYYY(minDeliveryDate),
            convertDateToMMDDYYYY(maxDeliveryDate),
            productStatus,
            partNo,
            partDescription,
            minQuantity,
            maxQuantity,
            minTotal,
            maxTotal,
            minSubTotal,
            maxSubTotal,
            minDeliveredQuantity,
            maxDeliveredQuantity,
            minRemainingQuantity,
            maxRemainingQuantity,
            productGroupName,
            fieldName)
            .then(response => {
                let qty = 0;
                response.data.map((data) => {
                    if (!isNaN(parseFloat(data.quantity))) {
                        qty += parseFloat(data.quantity)
                    } else {
                        console.log("Number ", data.number)
                        console.log("Value ", data.quantity)
                    }
                })

                this.setState({
                    isLoading: false,
                    OrderItemList: response.data,
                    totalRecords: response.totalRecords,
                    totalQty: qty
                }, () => {
                    this.state.getItemList(response.data) 
                    
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }
    // ascending descending order function by akash
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.OrderItemList.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ OrderItemList: sortedOrders }, () => {
                this.setFilterData()
            });
        });  
    };
  
    search = () => {
        // console.log('search called');
        localStorage.removeItem("quotationOrderItems")
        this.setState({ activePage: 1, isLoading: true }, () => {
            this.getCustomerOrderItems()
            this.setFilterData()
        })
    }
    // ascending descending order function end
    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getCustomerOrderItems()
        });
    }

    sortBy(key, sort) {
        let arrayCopy = [...this.state.OrderItemList];
        arrayCopy.sort(this.compareBy(key, sort));
        this.setState({ OrderItemList: arrayCopy });
    }

    compareBy(key, sort) {
        let reverse = sort ? 1 : -1;
        return function (a, b) {
            if (a[key] < b[key]) return -1 * reverse;
            if (a[key] > b[key]) return 1 * reverse;
            return 0;
        };
    }

    setFilterData = () => {

        let filterDataArray = {
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
            'number': this.state.number,
            'customerNumber': this.state.customerNumber,
            'customerName': this.state.customerName,
            'minCreated': this.state.minCreated !== null ? this.state.minCreated : "",
            'maxCreated': this.state.maxCreated !== null ? this.state.maxCreated : "",
            'minDeliveryDate': this.state.minDeliveryDate !== null ? this.state.minDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",
            'productStatus': this.state.productStatus,
            'partNo': this.state.partNo,
            'partDescription': this.state.partDescription,
            'minQuantity': this.state.minQuantity,
            'maxQuantity': this.state.maxQuantity,
            'minTotal': this.state.minTotal,
            'maxTotal': this.state.maxTotal,
            'minSubTotal': this.state.minSubTotal,
            'maxSubTotal': this.state.maxSubTotal,
            'minDeliveredQuantity': this.state.minDeliveredQuantity,
            'maxDeliveredQuantity': this.state.maxDeliveredQuantity,
            'minRemainingQuantity': this.state.minRemainingQuantity,
            'maxRemainingQuantity': this.state.maxRemainingQuantity,
            'productGroupName': this.state.productGroupName,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("quotationOrderItems", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {

        let filterData = JSON.parse(localStorage.getItem("quotationOrderItems"))
        if (filterData !== null) {

            this.setState({
                activePage: filterData.activePage ? filterData.activePage : "",
                start: filterData.start ? filterData.start : "",
                length: filterData.length ? filterData.length : "",
                number: filterData.number ? filterData.number : "",
                customerNumber: filterData.customerNumber ? filterData.customerNumber : "",
                customerName: filterData.customerName ? filterData.customerName : "",
                minCreated: filterData.minCreated ? convertDateStringtoDateObject(filterData.minCreated) : "",
                maxCreated: filterData.maxCreated ? convertDateStringtoDateObject(filterData.maxCreated) : "",
                minDeliveryDate: filterData.minDeliveryDate ? convertDateStringtoDateObject(filterData.minDeliveryDate) : "",
                maxDeliveryDate: filterData.maxDeliveryDate ? convertDateStringtoDateObject(filterData.maxDeliveryDate) : "",
                productStatus: filterData.productStatus ? filterData.productStatus : "",
                partNo: filterData.partNo ? filterData.partNo : "",
                partDescription: filterData.partDescription ? filterData.partDescription : "",
                minQuantity: filterData.minQuantity ? filterData.minQuantity : "",
                maxQuantity: filterData.maxQuantity ? filterData.maxQuantity : "",
                minTotal: filterData.minTotal ? filterData.minTotal : "",
                maxTotal: filterData.maxTotal ? filterData.maxTotal : "",
                minSubTotal: filterData.minSubTotal ? filterData.minSubTotal : "",
                maxSubTotal: filterData.maxSubTotal ? filterData.maxSubTotal : "",
                minDeliveredQuantity: filterData.minDeliveredQuantity ? filterData.minDeliveredQuantity : "",
                maxDeliveredQuantity: filterData.maxDeliveredQuantity ? filterData.maxDeliveredQuantity : "",
                minRemainingQuantity: filterData.minRemainingQuantity ? filterData.minRemainingQuantity : "",
                maxRemainingQuantity: filterData.maxRemainingQuantity ? filterData.maxRemainingQuantity : "",
                productGroupName: filterData.productGroupName ? filterData.productGroupName : "",
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getCustomerOrderItems()
            })
        } else {
            this.getCustomerOrderItems()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("quotationOrderItems")

        this.setState({
            number: "",
            customerNumber: "",
            customerName: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            productStatus: "",
            partNo: "",
            partDescription: "",
            minQuantity: "",
            maxQuantity: "",
            productGroupName: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getCustomerOrderItems()
        })
    }

    viewIndianDateFormatString = (dt) => {

        return convertDateStringMMDDtoDDMM(dt)

        // let newDate = "";
        // if(dt){
        //     let d = dt.split('/')
        //     newDate = d[1]+'/'+d[0]+'/'+d[2]
        // }
        // return newDate;
    }
    addCustomerOrder = () => {
        let slug = "CRM_Quotations"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createneworder"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editCustomerOrder = (id, num) => {
        localStorage.setItem("customerOrderId", id)
        localStorage.setItem("orderType", "customerOrder")
        localStorage.setItem("customerOrderNumber", num)

        let slug = "CRM_Quotations"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/editcustomerorder",
                state: { customerOrderId: id, orderType: 'customerOrder', customerOrderNumber: num }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    render() {
        return (
            <div className='__kDoEM__table'>
                <table>
                    <thead>
                        <tr>
                            <th className="tableHeader" style={{ width: 10 }} ><div onClick={() => this.addCustomerOrder()}><Fi.FiPlus /></div></th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('number', 'notDate', false)}><span>Number </span>{this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('partNo', 'notDate', false)}><span>Part no. </span>{this.state.fieldName === "partNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 80 }} onClick={() => this.sortCustomer('partDescription', 'notDate', false)}><span>Part Description </span>{this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('quantity', 'notDate', false)}><span>Quantity</span> {this.state.fieldName === "quantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "quantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('deliveredQuantity', 'notDate', false)}><span>Invoiced </span>{this.state.fieldName === "deliveredQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "deliveredQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('remainingQuantity', 'notDate', false)}><span>Remaining </span>{this.state.fieldName === "remainingQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "remainingQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('subtotal', 'notDate', false)}><span>Subtotal </span>{this.state.fieldName === "subtotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "subtotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('total', 'notDate', false)}><span>Total </span>{this.state.fieldName === "total" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "total" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('customerNumber', 'notDate', false)}><span>Customer Number </span>{this.state.fieldName === "customerNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "customerNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('customerName', 'notDate', false)}><span>Customer Name </span>{this.state.fieldName === "customerName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "customerName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('status', 'notDate', false)}><span>Status </span>{this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('created', 'Date', false)}><span>Created </span>{this.state.fieldName === "created" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "created" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('productDeliveryDate', 'Date', false)}><span>Delivery Date </span>{this.state.fieldName === "productDeliveryDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "productDeliveryDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader" style={{ width: 10 }}><div onClick={() => this.addCustomerOrder()}><Fi.FiPlus /></div></th>
                        </tr>
                        <tr>

                            <th>{/*<Fi.FiSettings />*/}</th>
                            <th style={{ width: 45 }}><input type='text' value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getCustomerOrderItems() })} /></th>
                            <th><input type='text' value={this.state.partNo} onChange={(e) => this.setState({ partNo: e.target.value }, () => { this.getCustomerOrderItems() })} /></th>
                            <th><input type='text' value={this.state.partDescription} onChange={(e) => this.setState({ partDescription: e.target.value }, () => { this.getCustomerOrderItems() })} /></th>
                            <th className="items ElemContain">
                                <input type="text" style={{ width: 50 }} value={this.state.minQuantity} placeholder="min" onChange={(e) => this.setState({ minQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />&nbsp;-&nbsp;
                                <input type="text" style={{ width: 50 }} value={this.state.maxQuantity} placeholder="max" onChange={(e) => this.setState({ maxQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />
                            </th>
                            <th>
                                <input type="text" style={{ width: 50 }} value={this.state.minDeliveredQuantity} placeholder="min" onChange={(e) => this.setState({ minDeliveredQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />&nbsp;-&nbsp;
                                <input type="text" style={{ width: 50 }} value={this.state.maxDeliveredQuantity} placeholder="max" onChange={(e) => this.setState({ maxDeliveredQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />
                            </th>
                            <th>
                                <input type="text" style={{ width: 50 }} value={this.state.minRemainingQuantity} placeholder="min" onChange={(e) => this.setState({ minRemainingQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />&nbsp;-&nbsp;
                                <input type="text" style={{ width: 50 }} value={this.state.maxRemainingQuantity} placeholder="max" onChange={(e) => this.setState({ maxRemainingQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />
                            </th>
                            <th>
                                <input type="text" style={{ width: 50 }} value={this.state.minSubTotal} placeholder="min" onChange={(e) => this.setState({ minSubTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />&nbsp;-&nbsp;
                                <input type="text" style={{ width: 50 }} value={this.state.maxSubTotal} placeholder="max" onChange={(e) => this.setState({ maxSubTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />
                            </th>
                            <th>
                                <input type="text" style={{ width: 50 }} value={this.state.minTotal} placeholder="min" onChange={(e) => this.setState({ minTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />&nbsp;-&nbsp;
                                <input type="text" style={{ width: 50 }} value={this.state.maxTotal} placeholder="max" onChange={(e) => this.setState({ maxTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getCustomerOrderItems() })} />
                            </th>
                            <th><input type='text' value={this.state.customerNumber} onChange={(e) => this.setState({ customerNumber: e.target.value }, () => { this.getCustomerOrderItems() })} /></th>
                            <th><input type='text' value={this.state.customerName} onChange={(e) => this.setState({ customerName: e.target.value }, () => { this.getCustomerOrderItems() })} /></th>
                            <th>
                                <select value={this.state.productStatus} onChange={(e) => this.setState({ productStatus: e.target.value }, () => this.getCustomerOrderItems())}>
                                    <option value=""></option>
                                    <option value="quotation">Quotation</option>
                                    <option value="waiting for confirmation">Waiting for confirmation</option>
                                    <option value="confirmed">Confirmed</option>
                                    <option value="waiting for production">Waiting for Production</option>
                                    <option value="in production">In Production</option>
                                    <option value="ready for shipment">Ready for Shipment</option>
                                    <option value="partially invoiced">Partially Invoiced</option>
                                    <option value="partially shipped">Partially Shipped</option>
                                    <option value="invoiced">Invoiced</option>
                                    <option value="shipped">Shipped</option>
                                    <option value="delivered">Delivered</option>
                                    <option value="archived">Archived</option>
                                    <option value="cancelled">Cancelled</option>
                                </select>
                            </th>
                            <th style={{ width: 110 }}>
                                <div className="value position-relative">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            this.state.minCreated
                                        }
                                        onChange={
                                            date => this.setState({ minCreated: date }, () => {
                                                this.getCustomerOrderItems()
                                            })
                                        }
                                        className="filterdateoption" />
                                    <AiOutlineCalendar className="datepickerIcon" />
                                </div>

                                <div className="value position-relative">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        minDate={this.state.minCreated}
                                        selected={
                                            this.state.maxCreated
                                        }
                                        onChange={
                                            date => this.setState({ maxCreated: date }, () => {
                                                this.getCustomerOrderItems()
                                            })
                                        }
                                        className="filterdateoption" />
                                    <AiOutlineCalendar className="datepickerIcon" />
                                </div>
                            </th>
                            <th style={{ width: 110 }}>
                                <div className="value position-relative">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            this.state.minDeliveryDate
                                        }
                                        onChange={
                                            date => this.setState({ minDeliveryDate: date }, () => {
                                                this.getCustomerOrderItems()
                                            })
                                        }
                                        className="filterdateoption" />
                                    <AiOutlineCalendar className="datepickerIcon" />
                                </div>

                                <div className="value position-relative">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        minDate={this.state.minDeliveryDate}
                                        selected={
                                            this.state.maxDeliveryDate
                                        }
                                        onChange={
                                            date => this.setState({ maxDeliveryDate: date }, () => {
                                                this.getCustomerOrderItems()
                                            })
                                        }
                                        className="filterdateoption" />
                                    <AiOutlineCalendar className="datepickerIcon" />
                                </div>
                            </th>
                            <th onClick={() => this.clearFilter()}><Fi.FiX /> </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th className='font-weight-bold'>Total:</th>
                            <th></th>
                            <th></th>
                            <th className='font-weight-bold pl-2'>{this.state.totalQty}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        this.state.isLoading ?
                            <TableLoading tr={8} td={15} />
                            :
                            <tbody>
                                {
                                    this.state.OrderItemList.map((data, i) => (
                                        <tr key={data._id + this.state.activePage + i} onClick={() => this.editCustomerOrder(data._id, data.number.toUpperCase())}>
                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                            <td className="text-uppercase">{data.number}</td>
                                            <td>{data.partNo}</td>
                                            <td>{data.partDescription}</td>
                                            <td>{data.quantity} {data.uomName}</td>
                                            <td>{data.deliveredQuantity} {data.uomName}</td>
                                            <td>{data.remainingQuantity} {data.uomName}</td>
                                            <td>{data.subtotal}</td>
                                            <td>{data.total}</td>
                                            <td className="text-uppercase">{data.customerNumber}</td>
                                            <td>{data.customerName}</td>
                                            <td>{data.status}</td>
                                            <td>{this.viewIndianDateFormatString(data.created)}</td>
                                            <td>{this.viewIndianDateFormatString(data.productDeliveryDate)}</td>
                                            <td>{/*<Fi.FiBarChart />*/}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    }
                </table>
                <div>
                    <div className="float-left mt-10px">
                        <div className="tableElemContain" style={{ width: 80 }}>
                            <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                <option value="25">25 rows</option>
                                <option value="50">50 rows</option>
                                <option value="100">100 rows</option>
                                <option value="">All rows</option>
                            </select>
                        </div>
                    </div>
                    <div className="float-right mt-10px">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                            totalItemsCount={this.state.totalRecords}
                            pageRangeDisplayed={10}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

export default ItemTable;