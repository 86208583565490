import React, { Component } from 'react';
import { Header, AccountingHeader } from './../../../components'
import Loading from '../../../components/Loading';
import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import * as APIManager from './../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'
import * as Ai from 'react-icons/ai'
import * as Im from 'react-icons/im'
import * as Bi from 'react-icons/bi'
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';

class Expenses extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            start: 0,
            length: 25,
            totalRecords: 0,
            activePage: 1,

            expenseCategoryData: [],
            listing: [],
            userName: "",
            expenseCategory: "",
            expenseNumber: "",
            paymentType: "",
            minSubTotal: "",
            maxSubTotal: "",
            minTotal: "",
            maxTotal: "",
            minPaid: "",
            maxPaid: "",
            minBalance: "",
            maxBalance: "",
            minBillDate: "",
            maxBillDate: "",
            minCreated: "",
            maxCreated: "",
            stateOfSupply: "",

            subTotal: 0,
            total: 0
        }

        this.timer = null;
    }

    componentDidMount() {
        this.getFilterData()
    }

    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
            .then(response => {
                let category = response.expenseCategory ? response.expenseCategory : []
                this.setState({
                    isLoading: false,
                    expenseCategoryData: category
                },()=>{
                    this.getExpenseList()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                },()=>{
                    this.getExpenseList()
                })
            });
    }

    getExpenseList = () => {
        let {
            start, length, userName, expenseCategory, expenseNumber, paymentType, minSubTotal, maxSubTotal, minTotal, maxTotal, minPaid, maxPaid, minBalance, maxBalance, minBillDate, maxBillDate, minCreated, maxCreated, stateOfSupply
        } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllExpenseList(start, length, userName, expenseCategory, expenseNumber, paymentType, minSubTotal, maxSubTotal, minTotal, maxTotal, minPaid, maxPaid, minBalance, maxBalance, convertDateToMMDDYYYY(minBillDate), convertDateToMMDDYYYY(maxBillDate), convertDateToMMDDYYYYWithoutZero(minCreated), convertDateToMMDDYYYYWithoutZero(maxCreated), stateOfSupply)
        .then(response => {
            let subTotal = 0
            let total = 0
            for (let i = 0; i < response.data.length; i++) {
                subTotal += parseFloat(response.data[i].subTotal)
                total += parseFloat(response.data[i].total)
            }
            this.setState({
                listing: response.data,
                subTotal: subTotal.toFixed(2),
                total: total.toFixed(2),
                totalRecords: response.totalRecords,
                isLoading: false
            },()=>{
                this.setFilterData()
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
            })
        });
    }


    filterRecords = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.getExpenseList()
        }, 800);
    }

    setFilterData = () => {

        let filterDataArray = {
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
            'userName': this.state.userName,
            'expenseCategory': this.state.expenseCategory,
            'expenseNumber': this.state.expenseNumber,
            'paymentType': this.state.paymentType,
            'minSubTotal': this.state.minSubTotal,
            'maxSubTotal': this.state.maxSubTotal,
            'minTotal': this.state.minTotal,
            'maxTotal': this.state.maxTotal,
            'minPaid': this.state.minPaid,
            'maxPaid': this.state.maxPaid,
            'minBalance': this.state.minBalance,
            'maxBalance': this.state.maxBalance,
            'minBillDate': this.state.minBillDate !== null ?this.state.minBillDate: "",
            'maxBillDate': this.state.maxBillDate !== null ?this.state.maxBillDate: "",
            'minCreated': this.state.minCreated !== null ?this.state.minCreated: "",
            'maxCreated': this.state.maxCreated !== null ?this.state.maxCreated: "",
            'stateOfSupply': this.state.stateOfSupply
        }

        localStorage.setItem("accounting", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("accounting"))
        if (filterData !== null) {
            this.setState({
                isLoading: true,
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,
                userName: filterData.userName,
                expenseCategory: filterData.expenseCategory,
                expenseNumber: filterData.expenseNumber,
                paymentType: filterData.paymentType,
                minSubTotal: filterData.minSubTotal,
                maxSubTotal: filterData.maxSubTotal,
                minTotal: filterData.minTotal,
                maxTotal: filterData.maxTotal,
                minPaid: filterData.minPaid,
                maxPaid: filterData.maxPaid,
                minBalance: filterData.minBalance,
                maxBalance: filterData.maxBalance,
                minBillDate: filterData.minBillDate  ? new Date(filterData.minBillDate) : "",
                maxBillDate: filterData.maxBillDate  ? new Date(filterData.maxBillDate) : "",
                minCreated: filterData.minCreated  ? new Date(filterData.minCreated) : "",
                maxCreated: filterData.maxCreated  ? new Date(filterData.maxCreated) : "",
                stateOfSupply: filterData.stateOfSupply
            }, () => {
                this.getExpenseCategories()

            })
        } else {
            this.getExpenseCategories()

        }
    }

    clearFilter = () => {
        localStorage.removeItem("accounting")
        this.setState({
            listing: [],
            userName: "",
            expenseCategory: "",
            expenseNumber: "",
            paymentType: "",
            minSubTotal: "",
            maxSubTotal: "",
            minTotal: "",
            maxTotal: "",
            minPaid: "",
            maxPaid: "",
            minBalance: "",
            maxBalance: "",
            minBillDate: "",
            maxBillDate: "",
            minCreated: "",
            maxCreated: "",
            stateOfSupply: "",
            subTotal: 0,
            total: 0,
            activePage: 1
        }, () => {
            this.getExpenseList()
        })
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0
        this.setState({
            isLoading: true,
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getExpenseList()
        });
    }

    addExpenses = () => {
        let slug = "Accounting_expenses"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createexpense",
                state: { coId: "", isFromCO: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }



    exportPDF = () => {
        let responseData = this.state.listing;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Expenses";

        const headers = [[
            "Number",
            "Account Manager",
            "Category",
            "Bill date",
            "Subtotal",
            "Total",
            "Paid",
            "State of supply",
            "Created"
        ]];

        const data = responseData.map((elt, i) => [
            elt.expenseNumber,
            elt.userName,
            elt.expenseCategory,
            convertDateStringMMDDtoDDMM(elt.billDate),
            elt.subTotal,
            elt.total,
            elt.paid,
            elt.stateOfSupply,
            convertDateStringMMDDtoDDMM(elt.created)
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("expenses.pdf")
    }
    exportCSV = () => {
        let responseData = this.state.listing;
        const title = "Expenses";
        const headers = [
            "Number",
            "Account Manager",
            "Category",
            "Bill date",
            "Subtotal",
            "Total",
            "Paid",
            "State of supply",
            "Created"
        ];

        const data = responseData.map((elt, i) => [
            elt.expenseNumber,
            elt.userName,
            elt.expenseCategory,
            convertDateStringMMDDtoDDMM(elt.billDate),
            elt.subTotal,
            elt.total,
            elt.paid,
            elt.stateOfSupply,
            convertDateStringMMDDtoDDMM(elt.created)
        ]);


        const options = {
            filename: 'expenses',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
    }

    editExpense = (id) => {
        this.props.history.push({
            pathname: "/editexpense",
            state: { expenseId: id, isNewEntry: false }
        })
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isAccounting={true} />
                <AccountingHeader isExpenses={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 col-sm-4 col-xs-12">
                                <div className="page-title">Expenses</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportPDF()}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportCSV()}>CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>

                       


                        <table>
                            <thead>
                                <tr>
                                    <th className="tableAddButton" style={{ width: 30 }}><div onClick={() => this.addExpenses()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader">Number</th>
                                    <th className="tableHeader">Account Manager</th>
                                    <th className="tableHeader">Category</th>
                                    <th className="tableHeader">Bill date</th>
                                    <th className="tableHeader">Subtotal</th>
                                    <th className="tableHeader">Total</th>
                                    <th className="tableHeader">Paid</th>
                                    <th className="tableHeader">State of supply</th>
                                    <th className="tableHeader">Created</th>
                                    <th className="tableAddButton"><div onClick={() => this.addExpenses()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableAddButton"></th>
                                    <th className="tableElemContain" style={{ width: 80 }} ><input type="text" value={this.state.expenseNumber} onChange={(e) => this.setState({ expenseNumber: e.target.value }, () => { this.filterRecords() })} /></th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value }, () => { this.filterRecords() })} /></th>
                                    <th className="tableElemContain">
                                        <select className="text-capitalize" style={{ minWidth: 100 }} value={this.state.expenseCategory} onChange={(e) => this.setState({ expenseCategory: e.target.value }, () => { this.filterRecords() })}>
                                            <option value=""></option>
                                            {
                                                this.state.expenseCategoryData.map((data, i) => (
                                                    <option value={data.expenseCategory} key={"expenseCat" + i}>{data.expenseCategory}</option>
                                                ))
                                            }
                                        </select>
                                    </th>
                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minBillDate
                                                }
                                                onChange={
                                                    date => this.setState({ minBillDate: date }, () => {
                                                        this.filterRecords()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minBillDate}
                                                selected={
                                                    this.state.maxBillDate
                                                }
                                                onChange={
                                                    date => this.setState({ maxBillDate: date }, () => {
                                                        this.filterRecords()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain" style={{ width: 120 }}>
                                        <input type="text" value={this.state.minSubTotal} placeholder="min" onChange={(e) => this.setState({ minSubTotal: e.target.value >= 0 ? e.target.value : "" }, () => { this.filterRecords() })} />
                                        <input type="text" className='mt-1' value={this.state.maxSubTotal} placeholder="max" onChange={(e) => this.setState({ maxSubTotal: e.target.value >= 0 ? e.target.value : "" }, () => { this.filterRecords() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 120 }}>
                                        <input type="text" value={this.state.minTotal} placeholder="min" onChange={(e) => this.setState({ minTotal: e.target.value >= 0 ? e.target.value : "" }, () => { this.filterRecords() })} />
                                        <input type="text" className='mt-1' value={this.state.maxTotal} placeholder="max" onChange={(e) => this.setState({ maxTotal: e.target.value >= 0 ? e.target.value : "" }, () => { this.filterRecords() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 120 }}>
                                        <input type="text" value={this.state.minPaid} placeholder="min" onChange={(e) => this.setState({ minPaid: e.target.value >= 0 ? e.target.value : "" }, () => { this.filterRecords() })} />
                                        <input type="text" className='mt-1' value={this.state.maxPaid} placeholder="max" onChange={(e) => this.setState({ maxPaid: e.target.value >= 0 ? e.target.value : "" }, () => { this.filterRecords() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" style={{ width: 80 }} value={this.state.stateOfSupply} onChange={(e) => this.setState({ stateOfSupply: e.target.value }, () => { this.filterRecords() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minCreated
                                                }
                                                onChange={
                                                    date => this.setState({ minCreated: date }, () => {
                                                        this.filterRecords()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minCreated}
                                                selected={
                                                    this.state.maxCreated
                                                }
                                                onChange={
                                                    date => this.setState({ maxCreated: date }, () => {
                                                        this.filterRecords()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableAddButton" onClick={() => this.clearFilter()}><Fi.FiX /></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className="tableElemContain"><b>Total:</b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className="tableElemContain"><b>{(this.state.subTotal).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></th>
                                    <th className="tableElemContain"><b>{(this.state.total).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                :
                                <tbody>
                                    {
                                        this.state.listing.map((data, i) => (
                                            <tr key={"expenseList" + i} onClick={() => this.editExpense(data._id)}>
                                                <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                <td>{data.expenseNumber}</td>
                                                <td>{data.userName}</td>
                                                <td>{data.expenseCategory}</td>
                                                <td>{convertDateStringMMDDtoDDMM(data.billDate)}</td>
                                                <td>{data.subTotal} <Bi.BiRupee /></td>
                                                <td>{data.total} <Bi.BiRupee /></td>
                                                <td>{data.paid} <Bi.BiRupee /></td>
                                                <td>{data.stateOfSupply}</td>
                                                <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            }

                        {/* <FilterTable 
                            responseData={this.state.listing}
                            columnData={[
                                {
                                    dataField: 'expenseNumber',
                                    text: "Number",
                                    sort: true,
                                },{
                                    dataField: 'userName',
                                    text: "Account Manager",
                                    sort: true,
                                },{
                                    dataField: 'expenseCategory',
                                    text: "Expense Category",
                                    sort: true,
                                    filterType: 'textFilter'
                                },{
                                    dataField: 'billDate',
                                    text: "Bill Date",
                                    sort: true,
                                },
                            ]}
                        /> */}
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default Expenses;