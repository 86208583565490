import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import Helper from './../../../helper/helper'

import {
    AiOutlineExclamation
} from "react-icons/ai";

import TableFields from './../../../components/AutoGenerateComponent/addFilterTableFields'

export default class CreateFilterTable extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseProductGroup: [],
            productGroupId: "",
            tableName: "",
            desc: "",

            countFieldNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,

            copyTable: [],

        }
    }

    componentDidMount(){
        this.getCustomTableStructure()
        this.getProductGroupList()
        this.addNewField()    
    }

    getCustomTableStructure = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllFilterTable()
        .then(response => {
            this.setState({ 
                isLoading: false,
                copyTable: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }
    
    statusInputEdit = (fields, num) => {

        let array = [...this.state.countFieldNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
    

        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && fields !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countFieldNumber: this.state.countFieldNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <TableFields 
                                key={this.state.count}
                                componentNum={this.state.count}
                                tablefield={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (fields, num) => {
         let array = [...this.state.countFieldNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
            
             if (fields !== "") {
                 let obj = fields
                 
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (fields !== "") {
                 let obj = fields
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    statusInputRemove = (number) => {
    
        let array = [...this.state.countFieldNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countFieldNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }


    getProductGroupList = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();

        fetch(APIManager.WEBSERVICES_URL+'stock/getProductGroup',{
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
        .then(res => res.json())
        .then((result) => {
            if(result.status === "success"){ 
                that.setState({
                    responseProductGroup: result.data.productGroup,
                })
            }else if(result.status === "userDataError"){
                that.logout()
           }else{

           }
        },(error) => {
            console.log(error)
        }
        )
    }
    
    createTable = () => {

        let that = this;
        let val = this.state.typeFieldValues
        let removeNull = val.filter(function (el) {
            return el != null;
        }); 
        
        removeNull = removeNull.map(x => typeof x === 'string' ? x.toLowerCase() : x)
        let filteredVal = [...new Set(removeNull)];
        
        let group = this.state.productGroupId
        let tableName = this.state.tableName
        let desc = this.state.desc;

        if(tableName === ""){
            this.setState({
                error: true,
                errormessage: "Please enter table name",
            })
        }else if(group === ""){
            this.setState({
                error: true,
                errormessage: "Please select product group",
            })
        } else if(filteredVal.length === 0){
            this.setState({
                error: true,
                errormessage: "Please add table fields",
            })
        }else{
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("tableName", tableName);
            formdata.append("fields", JSON.stringify(filteredVal));
            formdata.append("description", desc);
            formdata.append("productGroupId", group);

            fetch(APIManager.WEBSERVICES_URL+'stock/filterTable/createFilterTable',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        that.goBack()
                    } else if(result.status === "userDataError"){
                        that.logout()
                    } else{
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            ) 
        }
    }

    getTableContent = (e) => {
        if(e.target.value !== ""){
            this.setState({ 
                isLoading: true, 
                error: false,
                addTypeFields: [],
                countFieldNumber: [],
                typeFieldValues: [],
                count: 0,
            })
            APIManager.callSingleFilterTableData(e.target.value)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    tableFields: response,
                },()=>{
                    this.setFields(response[0].fields)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }else{
            this.setState({
                addTypeFields: [],
                countFieldNumber: [],
                typeFieldValues: [],
                count: 0,
            },()=>{
                this.addNewField()
            })  
        }
    }
  
    setFields = (fields) => {
        
        let createFieldArray = [];
        let countFieldArray = [];
        let addFieldValues = [];

        fields.map((data, i) => (
            createFieldArray.push(
                <TableFields
                    key={i}
                    componentNum={i}
                    tablefield={data}
                    onBlur={this.statusInputBlur}
                    onEdit={this.statusInputEdit}
                    onRemove={this.statusInputRemove}
                />
            ),
            countFieldArray.push(i),
            addFieldValues.push(data)
        ))

        this.setState({
            addTypeFields: createFieldArray,
            countFieldNumber: countFieldArray,
            typeFieldValues: addFieldValues,
            count: fields.length,
        },()=>{
            this.addNewField()
        })  
    }

    addNewField = () => {
        this.setState({
            count: this.state.count + 1,
            generateNewType: true,
        }, () => {
            this.setState({
                countFieldNumber: this.state.countFieldNumber.concat(
                    [this.state.count]
                ),
                addTypeFields: this.state.addTypeFields.concat(
                    [
                        <TableFields 
                            key={this.state.count}
                            componentNum={this.state.count}
                            tablefield={""}
                            onBlur={this.statusInputBlur}
                            onEdit={this.statusInputEdit}
                            onRemove={this.statusInputRemove}
                        />
                    ]
                ),
            })
        })
    }

    addNewProductGroup = () => {
        this.props.history.push('/createproductgroup')    
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isFilterTable={true}/>
                <div className="pagecontent">
                    <div className="headerArea">
                        <div className="pageTitle heading editPageTitle">Create a table</div>
                    </div>
                    <div className="clearfix"></div> 
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createTable()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="mb-5 mt-5">
                        <div className="add_partside">
                        
                            <div className="add_inner">
                                <div className="title">Table Name*</div>
                                <div className="value">
                                    <input type="text" value={this.state.tableName} onChange={(e)=>this.setState({ tableName: e.target.value })} className="fieldheightdecr" autoComplete="off" />
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Copy Table</div>
                                <div className="value">
                                    <select onChange={(e)=>this.getTableContent(e)}>
                                        <option value="">Select</option>
                                        {
                                           this.state.copyTable.map((data) => (
                                               <option value={data._id} key={data._id}>{data.tableName}</option>
                                           ))                                       
                                        }
                                    </select>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Description</div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        onChange={(e)=>this.setState({ desc: e.target.value})}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="add_partside">
                        <div className="add_inner">
                                <div className="title">Product Group</div>
                                <div className="value">
                                    <select name="status" onChange={(e)=>
                                        e.target.value === 'addNew' ? 
                                            this.addNewProductGroup() 
                                        :
                                            this.setState({ productGroupId: e.target.value })
                                    }>
                                        <option value="">Select</option>
                                        <option value="addNew" style={{fontWeight: 'bold', fontStyle: "italic"}}>+Add new product</option>
                                        {
                                            this.state.responseProductGroup !== null &&
                                            this.state.responseProductGroup.map((data, i) => (
                                                <option key={data._id} value={data._id}>{data.name}</option>   
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Fields*</div>
                                <div className="value">
                                <table className="">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.addTypeFields
                                        }
                                    </tbody>
                                </table>   
                                </div>
                                
                                <div className="clearfix"></div>
                            </div>
                        </div> 
                        <div className="clear"></div>
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createTable()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        )
    }
}