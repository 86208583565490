import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../../components'
import Loading from './../../../../helper/loading'
import * as Im from "react-icons/im";
import * as Ai from "react-icons/ai";
import * as APIManager from './../../../../APIManager/APIManager'

import GroupItem from './GroupItem';

class CreateRoutingOfItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            itemName: "",
            itemId: "",

            productObj: {
                'workStationGroup': '',
                'workStationsArray': '',
                'otherDescription': '',
                'setupTime': '',
                'cycleTime': '',
                'fixedCost': '',
                'variableCost': '',
                'quantity': '',
                'department': ''
            },

            workstationGroupFields: [],
            workstationGroupKeys: [],
            workstationGroupCounter: 0,
            workstationGroupData: [],

            BOM_List: [],
            Routing_List: [],

            isDetailedView: true,
            connectedBOM: "",
        }
        
    }

    componentDidMount(){
        let itemNameNumber = this.props.location.state.itemNameNumber
        let itemId = this.props.location.state.itemId

        this.setState({
            itemName: itemNameNumber,
            itemId: itemId
        },()=>{
            this.getBOMbyItem(itemId)
            this.getAllRoutings()
            this.setGroupItem()
        })   
    }

    getBOMbyItem = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callBOMByProduct(id,'false')
        .then(response => {
            this.setState({
                isLoading: false,
                BOM_List: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    getAllRoutings = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllRouting("","","","","","","","","","","","","")
        .then(response => {
            this.setState({ 
                isLoading: false,
                Routing_List: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        }); 
    }

    setGroupData = (data) => {
        if(data !== ""){
            this.setState({ 
                workstationGroupFields: [],
                workstationGroupKeys: [],
                workstationGroupCounter: 0,
                workstationGroupData: JSON.parse(data)
            },()=>{
                this.setWorkstationGroupData()
            })
        }
    }

    getRoutingById = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleRouting(id)
        .then(response => {
            this.setState({
                isLoading: false,
                workstationGroupData: response.workStationDetail
            },()=>{
                this.setDataWithDetailView()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    setDataWithDetailView = () => {
        this.setState({
            workstationGroupFields: [],
            workstationGroupKeys: [],
            workstationGroupCounter: 0,
        },()=>{
            this.setWorkstationGroupData()
        })
    }

    setWorkstationGroupData = () => {
        let prodGrp = this.state.workstationGroupData
        let groupLength = prodGrp.length;
        
        if(groupLength > 0){

            let groupField = [];
            let groupKey = [];

            for(let i=0;i<prodGrp.length;i++){
               
                if(prodGrp[i] !== null){
                    groupField.push(
                        <GroupItem
                            key={'proGroup'+i}
                            onChangeProduct={this.addNewGroupFields}
                            onBlurProduct={this.addGroupFieldValues}
                            onRemoveProduct={this.removeGroupProduct}
                            isDetailedView={this.state.isDetailedView}
                            counter={i}
                            groupData={prodGrp[i]}
                        />
                    )
                    groupKey.push(i)
                }
            }

            this.setState({
                workstationGroupFields: groupField,
                workstationGroupKeys: groupKey,
                workstationGroupCounter: groupLength
            },()=>{
                this.setNewBlankField(this.state.workstationGroupCounter)
            })
        }else{
            this.setGroupItem()
        }
    }

    setNewBlankField = (counter) => {
        let dummyObj =  {
            'workStationGroup': '',
            'workStationsArray': '',
            'otherDescription': '',
            'setupTime': '',
            'cycleTime': '',
            'fixedCost': '',
            'variableCost': '',
            'quantity': '',
            'department': ''
        }

        this.setState({
            workstationGroupFields: this.state.workstationGroupFields.concat([
                <GroupItem
                    key={'proGroup'+counter}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    isDetailedView={this.state.isDetailedView}
                    counter={counter}
                    groupData={dummyObj}
                />
            ]),            
        })
    }

    setGroupItem = () => {
        let dummyObj =  {
            'workStationGroup': '',
            'workStationsArray': '',
            'otherDescription': '',
            'setupTime': '',
            'cycleTime': '',
            'fixedCost': '',
            'variableCost': '',
            'quantity': '',
            'department': ''
        }

        this.setState({
            workstationGroupFields: this.state.workstationGroupFields.concat([
                <GroupItem
                    key={'proGroup'}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    isDetailedView={this.state.isDetailedView}
                    counter={this.state.workstationGroupCounter}
                    groupData={dummyObj}
                />
            ]),            
        })
    }

    addNewGroupFields = (counter) => {
        let dummyObj =  {
            'workStationGroup': '',
            'workStationsArray': '',
            'otherDescription': '',
            'setupTime': '',
            'cycleTime': '',
            'fixedCost': '',
            'variableCost': '',
            'quantity': '',
            'department': ''
        }

        let array = [...this.state.workstationGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                workstationGroupFields: this.state.workstationGroupFields.concat([
                    <GroupItem
                        key={'proGroup'+counter+1} 
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        isDetailedView={this.state.isDetailedView}
                        counter={counter+1}
                        groupData={dummyObj}
                    />
                ]),
                workstationGroupKeys: this.state.workstationGroupKeys.concat([this.state.workstationGroupCounter]),
                workstationGroupCounter: counter+1,
            })
        }
    }
    addGroupFieldValues = (value, counter) => {
        let workstationGroupData = [...this.state.workstationGroupData]    
        workstationGroupData[counter] = value;  
        this.setState({
            workstationGroupData
        })
    }
    removeGroupProduct = (counter) => {
        let workstationGroupData = [...this.state.workstationGroupData]    
        let workstationGroupFields = [...this.state.workstationGroupFields]
        workstationGroupData[counter] = null;
        workstationGroupFields[counter] = null;
        
        this.setState({
            workstationGroupData,
            workstationGroupFields
        })
    }

    createRoute = () => {
        let {
            itemId,
            connectedBOM,
            isDetailedView,
            workstationGroupData
        } = this.state

        /* Remove Null From State */
        let workStationDetail = [];
        workstationGroupData.forEach((val) => {
            if(val !== null){
                workStationDetail.push(val);
            }
        });
        /* Remove Null */

        if(connectedBOM === ""){
            this.setState({
                error: true,
                errormessage: "Please select connected BOM",
                success: false,
            })
        }else if(workStationDetail.length === 0){
            this.setState({
                error: true,
                errormessage: "Please select workstation group",
                success: false,
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateRouting(itemId, connectedBOM, isDetailedView , JSON.stringify(workStationDetail))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editrouting",
                        state: { RoutingId: response._id, newRoutingEntry: true}
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }
  

    goBack = () => {
        this.props.history.goBack()        
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isRoutings={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title">Create a Routing</div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button">PDF <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>    
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createRoute()}>Save</div>
                            <div className="clear"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product</div>
                                    <div className="value">
                                    <div className="createdText text-capitalize"><span className="clickable__source">{this.state.itemName}</span></div>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Name *</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-capitalize"
                                            value={this.state.itemName + " Routing"}
                                            readOnly
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Connected BOM</div>
                                    <div className="__formTextVal">
                                        {
                                            this.state.BOM_List.map((data, i) =>(
                                                <div className="text-capitalize" key={data._id}>
                                                    <input 
                                                        type="radio" 
                                                        className="__formCheckboxElem" 
                                                        name="connectedBOM"
                                                        onClick={()=>this.setState({connectedBOM: data._id })}
                                                    /> <span className="clickable__source">
                                                        {data.name.length > 20 ? data.name.substring(0, 20)+'...' : data.name}
                                                        </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Copy routing</div>
                                    <div className="value">
                                        <select className="text-capitalize" onChange={(e)=>this.getRoutingById(e.target.value)}>
                                            <option value="">Select</option>
                                            {
                                                this.state.Routing_List.map((data, i) => (
                                                    <option value={data._id} key={data._id+i}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">Detailed view</div>
                                        <div className="__formTextVal">
                                            <input 
                                                type="checkbox" 
                                                className="__formCheckboxElem" 
                                                checked={this.state.isDetailedView}
                                                onChange={()=>this.setState({ isDetailedView: !this.state.isDetailedView },()=>{
                                                    this.setDataWithDetailView()
                                                })}
                                            />
                                        </div>
                                    <div className="clear"></div>
                                </div> */}
                               
                            </div>
                            
                            <div className="clear"></div>
                            <div className="main-container mt-20 order_product_group">
                            <h6 className="font-weight-bold">Parts</h6>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='pg-tab-head'>Workstation group</th>
                                            <th className='pg-tab-head'>Workstation priority</th>
                                            <th className='pg-tab-head'>Operation description</th>
                                            <th className='pg-tab-head'>Setup time</th>
                                            <th className='pg-tab-head'>Cycle time</th>
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Fixed cost</th>
                                            }
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Variable cost</th>
                                            }
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Quantity</th>
                                            }
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Department</th>
                                            }
                                            <th className='pg-tab-head'></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.workstationGroupFields}
                                        
                                    </tbody>
                                </table>
                            </div>
                            
                            
                           
                
                            
                       
                        </div>
                                    


                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createRoute()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>

                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        );
    }
}

export default CreateRoutingOfItem;