import React, { Component } from 'react';
import Loading from '../../../../helper/loading';
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'

class GenerateDocument extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            pumpDocumentData: [],
            _id: this.props.id,
            selectedFile: this.props.file,
            documentFile: this.props.file,
            fileDisplayName: "",
            documentType: this.props.name,
            note: this.props.note,
            onDocDelete:this.props.onDocDelete,
            onSubmitSuccess:this.props.onSubmitSuccess,
            onUpdateSuccess: this.props.onUpdateSuccess,
            isOldData: false,
            isUpdateFile: this.props.isOldData,
            pumpRegisterId: this.props.pumpRegisterId,
        }
    }

    componentDidMount(){
        this.setDisplayName(this.state.selectedFile)
        this.callGetPumpDocumentType()
    }

    callGetPumpDocumentType = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPumpDocumentType()
        .then(response => {
            let res = response.data.pumpDocumentName !== undefined ? response.data.pumpDocumentName : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].pumpDocumentName, text: res[i].pumpDocumentName }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                pumpDocumentData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }


    onFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0]
        },()=>{
            this.uploadDocument()
        })
    }

  
    uploadDocument = () => {
            let {
                _id,
                documentType,
                pumpRegisterId,
                selectedFile,
                note
            } = this.state

            if(_id !== ""){
                if(selectedFile === ""){
                    alert("Please select file to upload")
                }else{
                    this.setState({ isLoading: true, error: false })
                    APIManager.callUpdatePumpDocument(selectedFile, documentType, note, _id, pumpRegisterId)
                    .then(response => {
                        this.setState({ 
                            isLoading: false,
                            isUpdateFile: false,
                            selectedFile: response.data.documentFile,
                            documentFile: response.data.documentFile,
                            note: response.data.note,
                        },()=>{
                            this.setDisplayName(response.documentFile)
                        })
                    })
                    .catch(errors => {
                        this.setState({ 
                            isLoading: false,
                            error: true,
                            errormessage: errors 
                        })
                    });
                }
            }else{
                if(selectedFile === ""){
                    alert("Please select file to upload")
                }else{
                    this.setState({ isLoading: true, error: false })
                    APIManager.callCreatePumpDocument(selectedFile,documentType,note,pumpRegisterId)
                    .then(response => {
                        this.setState({ 
                            isLoading: false,
                            isUpdateFile: false,
                        },()=>{
                            this.state.onSubmitSuccess()
                        })
                    })
                    .catch(errors => {
                        this.setState({ 
                            isLoading: false,
                            error: true,
                            errormessage: errors 
                        })
                    });
                }
            }
    }

    setDisplayName = (selectedFile) => {
        if(selectedFile){
            let splitData = selectedFile.split("__")
            let splitData_last_index = splitData[1]
            let finalName = "";
            if(splitData_last_index){
                finalName = splitData_last_index.split("_").join(" ")
            }else{
                finalName = selectedFile.substring(selectedFile.lastIndexOf('/') + 1).split("_").join(" ")
            }
            this.setState({
                fileDisplayName: finalName
            })
        }
    }

    
    deleteEntry = () => {
        let slug = "CRM_Quotations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this file?");
            if (r === true) {
                let {
                    _id,
                    pumpRegisterId
                } = this.state
                this.setState({ isLoading: true, error: false })
                APIManager.callDeletePumpDocument(_id, pumpRegisterId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.state.onDocDelete()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }   
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    viewUpdateFileOption = () => {
        this.setState({
            isUpdateFile: !this.state.isUpdateFile
        })
    }

    viewSelectedFile = () => {
        let file = this.state.documentFile
        window.open(file)
       // pdfWindow.document.write(
       //     "<iframe width='100%' height='100%' src='" +encodeURI(file)+ "'></iframe>"
       // )
    }

    render() {
        return (
            <tr className='pump-register-buttons'>
                <td className='width20'>
                    <Select2    
                        data={this.state.pumpDocumentData}
                        value={this.state.documentType}
                        options={{placeholder: 'Select'}}
                        onSelect={(e)=> this.setState({ documentType: e.target.value })}
                    />
                </td>
                <td className='width30'>
                    {
                        (this.state.documentFile && !this.state.isUpdateFile) &&
                            <div>
                                <div onClick={()=>this.viewSelectedFile()} className='pump-register-doc-saved-file float-left text-transform-none'>{this.state.fileDisplayName ? this.state.fileDisplayName : 'File Preview'}</div>
                                <div onClick={()=>this.viewUpdateFileOption()} className='pump-register-doc-saved-file add_btnBack float-right'>Change File</div>
                                <div className='clear'></div>
                            </div>

                    }
                    {
                         this.state.isUpdateFile &&
                            <div>
                                <div className='width90 float-left'>
                                    <input 
                                        type="file" 
                                        className='input-file' 
                                        accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={this.onFileChange}
                                        disabled={this.state.isOldData}
                                    />
                                </div>
                                {
                                    this.state.documentFile &&
                                    <div className='float-right pt-2' onClick={()=>this.viewUpdateFileOption()}><Fi.FiX /></div>
                                }
                                <div className='clear'></div>
                            </div>
                    }    
                </td>

                
                <td className='width60'>
                    <textarea 
                        placeholder='Description' 
                        value={this.state.note} 
                        className='text-area' 
                        onChange={(e)=>this.setState({ note: e.target.value })}>
                    </textarea>
                </td>
                <td>
                    {
                        this.state.documentFile &&
                            <div className='add_btnBack' onClick={()=>this.uploadDocument()}>Update</div>
                    }
                    {
                        !this.state.documentFile &&
                        <div className='add_btnBack' onClick={()=>this.uploadDocument()}>Save</div>
                    }
                </td>
                <td>
                    {
                        this.state.documentFile &&
                        <div className='doc_delete_button' onClick={()=>this.deleteEntry()}><Fi.FiX /></div>
                    }
                </td>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </tr>
        );
    }
}

export default GenerateDocument;