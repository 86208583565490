
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import { convertDateStringMMDDtoDDMM } from "../../../constants/DateFormatter";

export const exportCSV = (responseData) => {
    const title = "Shipments";

    const headers = [
        "Number", 
        "Customer Order", 
        "Delivery Date",
        "Status", 
        "Created", 
        "Delivery address", 
        "Waybill note",
        "Picking list note"
    ];

    const data = responseData.map((data, i)=> [
        data.number.toUpperCase(), 
        data.customerOrderNumber.toUpperCase(), 
        convertDateStringMMDDtoDDMM(data.deliveryDate), 
        data.status,
        convertDateStringMMDDtoDDMM(data.created),
        data.shippingAddress,
        data.waybillNotes, 
        data.pickingListNotes
    ]);


    const options = { 
        filename: 'Shipments',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers,
    };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);
}



export const exportPDF = (responseData) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
   
    const title = "Shipments";

    const headers = [[
        "Number", 
        "Customer Order", 
        "Delivery Date",
        "Status", 
        "Created", 
        "Delivery address", 
        "Waybill note",
        "Picking list note"
    ]];

    const data = responseData.map((data)=> [
        data.number.toUpperCase(), 
        data.customerOrderNumber.toUpperCase(), 
        convertDateStringMMDDtoDDMM(data.deliveryDate), 
        data.status,
        convertDateStringMMDDtoDDMM(data.created),
        data.shippingAddress,
        data.waybillNotes, 
        data.pickingListNotes
    ]);

    let content = {
        startY: 50,
        head: headers,
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("shipments.pdf")
}
