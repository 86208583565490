import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';
import * as Fi from 'react-icons/fi'
import * as Ai from 'react-icons/ai'

import DefaultDepartmentsAndWorkers from '../../../components/AutoGenerateComponent/addDepartmentsAndWorkers';
import AddIdleTime from '../../../components/AutoGenerateComponent/addIdleTime';


class EditWorkstation extends Component {

    constructor(props){
        super(props)
               
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            workstationGroups: [],
            _id: "",
            number: "",
            name: "",
            type: "",
            checkHourlyRate: false,
            hourlyrate: "",
            productivity: "",

            countDeptAndWorkersNumber: [],
            addTypeFields: [],
            count: 0,
            deptAndWorkers: [],
            generateNewType: true,


            countIdleTimeNumber: [],
            addIdleTimeFields: [],
            countIdleTime: 0,
            idleTimeFieldValues: [],
            generateNewIdleTime: false,

            workstationNotes: [],

            isNewEntry: this.props.location.state.newWorkstationEntry,
            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let id = this.props.location.state.workstationId
       
        this.setState({
            _id: id
        },()=>{
            this.getSingleWorkstation()
        })   
    }

    setAllIdleTime = (status) => {
        let numArray = []
        let fields = []
        for(let i=0;i<status.length;i++){
            numArray.push(i)
            fields.push(
                <AddIdleTime 
                    key={i}
                    componentNum={i}
                    startTime={status[i]}
                    onBlur={this.statusInputBlurIdleTime}
                    onEdit={this.statusInputEditIdleTime}
                    onRemove={this.statusInputRemoveIdleTime}
                />
            )
        }
        this.setState({
            addIdleTimeFields: fields,
            countIdleTimeNumber: numArray,
            countIdleTime: status.length
        },()=>{
            this.addNewFieldIdleTime(status.length)
        })
        
    }

    addNewFieldIdleTime = (len) => {
        this.setState({
            addIdleTimeFields: this.state.addIdleTimeFields.concat(
                [
                    <AddIdleTime 
                        key={len}
                        componentNum={len}
                        startTime={""}
                        onBlur={this.statusInputBlurIdleTime}
                        onEdit={this.statusInputEditIdleTime}
                        onRemove={this.statusInputRemoveIdleTime}
                    />
                ]
            ),
            countIdleTime: len,
        },()=>{
            this.setState({
                countIdleTimeNumber: this.state.countIdleTimeNumber.concat([len]),
                generateNewIdleTime: true,
            })
        })
    }

    statusInputEditIdleTime = (startTime, num) => {

        let array = [...this.state.countIdleTimeNumber];
        let index = array.indexOf(num)
        let idleTimeVal = [...this.state.idleTimeFieldValues]     
        
        
        if (typeof idleTimeVal[index] === 'undefined' && this.state.generateNewIdleTime === true && startTime !== "") {
            this.setState({
                countIdleTime: this.state.countIdleTime + 1,
                generateNewIdleTime: false,
            }, () => {
                
                this.setState({
                    countIdleTimeNumber: this.state.countIdleTimeNumber.concat(
                        [this.state.countIdleTime]
                    ),
                    addIdleTimeFields: this.state.addIdleTimeFields.concat(
                        [
                            <AddIdleTime 
                                key={this.state.countIdleTime}
                                componentNum={this.state.countIdleTime}
                                startTime={""}
                                onBlur={this.statusInputBlurIdleTime}
                                onEdit={this.statusInputEditIdleTime}
                                onRemove={this.statusInputRemoveIdleTime}
                            />
                        ]
                    ),
                })
            })
        }
    }

    finalDayTime = (date) => {
        var dateTime = new Date(date),
        month = '' + (dateTime.getMonth() + 1),
        day = '' + dateTime.getDate(),
        year = dateTime.getFullYear();
        // if (month.length < 2) 
        //     month = '0' + month;
        // if (day.length < 2) 
        //     day = '0' + day;
       
        let finalEndTime = day+"-"+month+"-"+year
        return finalEndTime
    }

    statusInputBlurIdleTime = (startTime, num) => {
 
        let array = [...this.state.countIdleTimeNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.idleTimeFieldValues]   

        if (typeof statusVal[index] !== 'undefined') {
            if (startTime !== "") {

                let obj = this.finalDayTime(startTime)
                let idleTimeFieldValues = [ ...this.state.idleTimeFieldValues ];
                idleTimeFieldValues[index] = obj;

                this.setState({
                    idleTimeFieldValues,
                    generateNewIdleTime: true,
                })
            }
        }else{
            if (startTime !== "") {
                let obj = this.finalDayTime(startTime)
                this.setState({
                    idleTimeFieldValues: this.state.idleTimeFieldValues.concat([ obj ]),
                    generateNewIdleTime: true,
                })
            }
        }
    }

    statusInputRemoveIdleTime = (number) => {
        let array = [...this.state.countIdleTimeNumber];
        let fields = [...this.state.addIdleTimeFields];
        let data = [...this.state.idleTimeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countIdleTimeNumber: array,
                addIdleTimeFields: fields,
                idleTimeFieldValues: data
            });
        }
        
    }

    getSingleWorkstation = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleWorkstation(this.state._id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                number: response.data.number,
                name: response.data.name,
                deptAndWorkers: response.data.defaultWorker,
                type: response.data.workStationGroupNumber.toUpperCase()+' '+response.data.workStationGroupName,
                checkHourlyRate: response.data.is_hourlyRate,
                hourlyrate: response.data.hourlyRateValue,
                productivity: response.data.productivity,
                idleTimeFieldValues: response.data.idleTime,
                workstationNotes: response.data.note
            },()=>{
                if(this.state.deptAndWorkers.length > 0){
                    this.setAllWorkers(this.state.deptAndWorkers)
                }else{
                    this.addNewField(0)
                }

                if(this.state.idleTimeFieldValues.length > 0){
                    this.setAllIdleTime(this.state.idleTimeFieldValues)
                }else{            
                    this.addNewFieldIdleTime(0)
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    setAllWorkers = (status) => {
        let numArray = []
        let fields = []
        for(let i=0;i<status.length;i++){
            numArray.push(i)
            fields.push(
                <DefaultDepartmentsAndWorkers 
                    key={i}
                    componentNum={i}
                    defaultDandW={status[i].deptId}
                    userId={status[i].userId}
                    defaultUser={status[i].isDefault}
                    onBlur={this.statusInputBlur}
                    onEdit={this.statusInputEdit}
                    onRemove={this.statusInputRemove}
                />
            )
        }
        this.setState({
            addTypeFields: fields,
            countDeptAndWorkersNumber: numArray,
            count: status.length
        },()=>{
            this.addNewField(status.length)
        })
        
    }

    addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <DefaultDepartmentsAndWorkers 
                        key={len}
                        componentNum={len}
                        defaultDandW={""}
                        userId={""}
                        defaultUser={false}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countDeptAndWorkersNumber: this.state.countDeptAndWorkersNumber.concat([len]),
            })
        })
    }

    statusInputEdit = (defualtDandW, num) => {
        let array = [...this.state.countDeptAndWorkersNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.deptAndWorkers]     
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && defualtDandW !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countDeptAndWorkersNumber: this.state.countDeptAndWorkersNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <DefaultDepartmentsAndWorkers 
                                key={this.state.count}
                                componentNum={this.state.count}
                                defaultDandW={""}
                                userId={""}
                                defaultUser={false}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />                            
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (defualtDandW, userId, isDefault, num) => {
    
         let array = [...this.state.countDeptAndWorkersNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.deptAndWorkers]   
 
         if (typeof statusVal[index] !== 'undefined') {
             if (defualtDandW !== "") {

                 let obj = {
                    "deptId": defualtDandW,
                    "userId": userId,
                    "isDefault": isDefault
                }

                 let deptAndWorkers = [ ...this.state.deptAndWorkers ];
                 deptAndWorkers[index] = obj;

                 this.setState({
                     deptAndWorkers: deptAndWorkers,
                     generateNewType: true,
                 })

             }
         }else{
             if (defualtDandW !== "") {

                let obj = {
                    "deptId": defualtDandW,
                    "userId": userId,
                    "isDefault": isDefault
                }

                 this.setState({
                     deptAndWorkers: this.state.deptAndWorkers.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }

    statusInputRemove = (number) => {

        let array = [...this.state.countDeptAndWorkersNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.deptAndWorkers]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countDeptAndWorkersNumber: array,
                addTypeFields: fields,
                deptAndWorkers: data
            });
        }
        
    }
    
    updateWorkstation = () => {
        if(this.state.fieldNotEditable !== true) {
            let {
                _id,
                type,
                checkHourlyRate,
                hourlyrate,
                productivity,
                idleTimeFieldValues,
                deptAndWorkers,
                name
            } = this.state


            /* Remove Null From State */
            let idleTime = [];
            idleTimeFieldValues.forEach((val) => {
                if(val !== null){
                    idleTime.push(val);
                }
            });
            /* Remove Null */

             /* Remove Null From State */
             let workers = [];
             deptAndWorkers.forEach((val) => {
                 if(val !== null){
                    workers.push(val);
                 }
             });
             /* Remove Null */

             workers = [...new Set(workers)];
             // Remove Repeated workers

            if(name === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter name"
                },()=>{
                    this.scrollToTop()
                })
            }else if(type === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(checkHourlyRate === true && hourlyrate === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter hourly rate"
                },()=>{
                    this.scrollToTop()
                })
            }else if(workers.length === 0){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select workstation department"
                },()=>{
                    this.scrollToTop()
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateWorkstation(_id,name,checkHourlyRate,hourlyrate,JSON.stringify(workers),JSON.stringify(idleTime),productivity)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        error: false,
                        fieldNotEditable: true,
                        successmessage: "Workstation updated successfully"
                    },()=>{
                        this.scrollToTop()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    },()=>{
                        this.scrollToTop()
                    })
                });
            }

        }else{
            let slug = "ProductionPlanning_Workstations"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({ 
                    fieldNotEditable: false
                })  
            }else{
                alert("You don't have permission to "+permission+" this record")
            }

        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
   
    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteWorkstation(this.state._id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    addNewNote = () => {
        this.props.history.push({
            pathname: "/createwsnote",
            state: { workstationId: this.state._id, workstationNumber: this.state.number, workstationName: this.state.name, newWorkstationNoteEntry: false }
        })
    }

    editNote = (note, id) => {
        this.props.history.push({
            pathname: "/editwsnote",
            state: { workstationId: this.state._id, workstationNumber: this.state.number, workstationName: this.state.name, workstationNote: note, workstationNoteId: id, newWorkstationNoteEntry: false }
        })
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }
    
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstations={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Workstation <span className="text-uppercase">{this.state.number}</span> <span className="text-capitalize">{this.state.name}</span> details</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateWorkstation()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Number*</div>
                                    <div className="value">
                                        <input type="text" className="fieldheightdecr text-uppercase" autoComplete="off" value={this.state.number} onChange={(e)=>this.setState({ number: e.target.value })} readOnly />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Name*</div>
                                    <div className="value">
                                        <input type="text" className="fieldheightdecr text-capitalize" autoComplete="off" value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Group</div>
                                    <div className="value">
                                        <div className='createdText text-capitalize'>{this.state.type}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Hourly rate</div>
                                    <div className="__formTextVal">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem" 
                                            checked={this.state.checkHourlyRate} 
                                            onChange={()=>
                                                this.setState({ 
                                                    checkHourlyRate: !this.state.checkHourlyRate,
                                                    hourlyrate: "",
                                                })
                                            } 
                                        /> Yes
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                {
                                    this.state.checkHourlyRate &&
                                        <div className="add_inner">
                                            <div className="title"></div>
                                            <div className="value">
                                                <input type="text" value={this.state.hourlyrate} onChange={(e)=>this.setState({ hourlyrate: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} style={{ width: "40%"}} />
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                }
                                {/* 
                                
                                <div className="add_inner">
                                    <div className="title">Productivity*</div>
                                    <div className="value">
                                        <input type="text" className="fieldheightdecr text-capitalize" autoComplete="off" value={this.state.productivity} onChange={(e)=>this.setState({ productivity: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                
                                */}

                                <div className="add_inner">
                                    <div className="title">Default department/worker</div>
                                    <div className="value">
                                        <table>
                                            <tbody>
                                                {this.state.addTypeFields}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Idle time</div>
                                    <div className="value">

                                        <table style={{ width: 220 }}>
                                            <tbody>
                                                {this.state.addIdleTimeFields}
                                            </tbody>
                                        </table>

                                        
                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                              
                                
                            </div>
                            <div className="clearfix"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateWorkstation()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <div className="footerTitle">Notes</div>
                        <table>
                            <tbody>
                            <tr>
                                <th className="tableHeader"><span>Created</span></th>
                                <th className="tableHeader"><span>Modified</span></th>
                                <th className="tableHeader w-50">Notes</th>
                                <th className="tableAddButton text-right"><div onClick={()=>this.addNewNote()} className="addContactButton"><Fi.FiPlus /></div></th>
                            </tr>
                            {
                                this.state.workstationNotes !== null &&
                                this.state.workstationNotes.map((data, i) => (
                                    <tr key={data.id} onClick={()=>this.editNote(data.note, data.id)}>
                                        <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br/><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                        <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br/><span  style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                        <td className="texttop preline">{data.note}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default EditWorkstation;