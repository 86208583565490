import React from 'react';
import RichTextEditor from 'react-rte';

import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import Loading from './../../../../helper/loading'
import './../../../../../style.css'
import * as Ai from "react-icons/ai";
import * as APIManager from './../../../../APIManager/APIManager'
import ProductGroupForOtherCurrency from './GroupWithoutTax/ProductGroup';
import ProductGroup from './GroupWithTax/ProductGroup';
import AddTermsAndConditions from '../../../../components/AutoGenerateComponent/AddTermsAndConditions';

import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';


export default class CreateCustomerOrders extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            goBack: this.props.goBack,
            history: this.props.history,

            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            setProductGroup: false,

            customerList: [],
            orderStatusList: [],
            deliveryTermsList: [],
            shippingAddressList: [],
            contactPersonList: [],

            accountManagerDetail: [],
            adminDetails: [],

            accountManager: "",

            customer: "",
            status: "quotation",
            deliverydate: "",
            actualDelivery: "",
            poDeliveryDate: "",
            deliveryterms: "Ex-works",
            reference: "",
            shippingaddress: "",
            internalNotes: "",
            rfqNo: "",
            rfqDate: "",
            quotationValidityEnd: "",
            poNo: "",
            poDate: "",
            statusValue: { value: 'quotation', label: 'Quotation' },
            contactPerson: "",


            quotationMessageEditor: RichTextEditor.createEmptyValue(),
            quotationMessage: "",

            quotationMessageFooterEditor: RichTextEditor.createEmptyValue(),
            quotationMessageFooter: "",

            countTermsNumber: [],
            TermsCounter: 0,
            termsFields: [],
            termsAndConditions: [],
            generateNewTerm: true,
            currencyData: [],

            docPermissionsManagersField: [],
            docPermissionsManagersValue: [],
        }
        this.termsFieldRemove = this.termsFieldRemove.bind(this)
        this.termsFieldChange = this.termsFieldChange.bind(this)
    }

    componentDidMount(){
        this.getCustomers()
        this.getOrderStatus()
        this.setDefaultTerms()
        this.getQuotationMessages()

        localStorage.removeItem('productGroupData')
        localStorage.removeItem("totalOfProductCost")
        localStorage.removeItem("totalDiscountPrice")
        localStorage.removeItem("totalDiscountPercentage")
    }

    setDefaultTerms = () => {
        APIManager.callAllTermsCondition("","customerOrder","")
        .then(response => {
            let termsCondition = []
            for(let i = 0; i < response.length; i++)
            {
                for(let j = 0; j < response[i].option.length; j++){
                    if(response[i].option[j].is_default !== undefined && response[i].option[j].is_default === true){
                       let obj = {
                            "terms": response[i]._id,
                            "options": response[i].option[j].terms
                       } 
                       termsCondition.push(obj)
                    }
                }
            }
            this.setTermsConditions(termsCondition)
            this.setState({ 
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setTermsConditions = (resp) => {
        let counterArray = []
        let termFields = [];
        for(let i=0;i<resp.length;i++){
            counterArray.push(i)
            termFields.push(
                <AddTermsAndConditions 
                    key={i}
                    componentNum={i}
                    term={resp[i].terms}
                    condition={resp[i].options}
                    termsFor={"customerOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            )
        }

        this.setState({
            termsFields: termFields,
            countTermsNumber: counterArray,
            TermsCounter: resp.length,
            termsAndConditions: resp
        },()=>{
            this.setTerms()
        })
    }

    setTerms = () => {
        let counter = this.state.TermsCounter;
        this.setState({
            termsFields: this.state.termsFields.concat([
                <AddTermsAndConditions 
                    key={counter}
                    componentNum={counter}
                    term={""}
                    condition={""}
                    termsFor={"customerOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            ]),
            countTermsNumber: this.state.countTermsNumber.concat([ counter ])
        },()=>{
            this.setState({
                TermsCounter: counter + 1
            })
        })
    }


    termsFieldChange = (term,condition,number) => {

        let array = [...this.state.countTermsNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.termsAndConditions]        

        if (typeof CIVal[index] !== 'undefined') {
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                let termsAndConditions = [ ...this.state.termsAndConditions ];
                termsAndConditions[index] = obj;

                this.setState({
                    termsAndConditions,
                })
            }
        }else{
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                this.setState({
                    termsAndConditions: this.state.termsAndConditions.concat([ obj ])
                },()=>{
                    this.setTerms()
                })
            }
        }
    }

    termsFieldRemove = (number) => {
        let array = [...this.state.countTermsNumber];
        let fields = [...this.state.termsFields];
        let data = [...this.state.termsAndConditions]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            // fields.splice(index, 1)
            this.setState({
                countTermsNumber: array,
                termsFields: fields,
                termsAndConditions: data
            });
        }
    }


    getQuotationMessages = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetDefaultQuotationMessages()
        .then(response => {
            this.setState({
                isLoading: false,
                quotationMessage: response.headerText,
                quotationMessageFooter: response.footerText,
                quotationMessageEditor: response.headerText ? RichTextEditor.createValueFromString(response.headerText, 'html') : RichTextEditor.createEmptyValue(),
                quotationMessageFooterEditor: response.footerText ? RichTextEditor.createValueFromString(response.footerText, 'html') : RichTextEditor.createEmptyValue()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getCustomers = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllCompanyByAccManager()
        .then(response => {
            this.setState({ 
                isLoading: false,
                customerList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getContactPerson = (cmpId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callContactPerson(cmpId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                contactPersonList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getCustomerShippingAddress = (customer) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerShippingAddress(customer)
        .then(response => {
            this.setState({ 
                isLoading: false,
                shippingAddressList: response,
            })

            if(response.length > 0) {
                this.setState({
                    shippingaddress: response[0]
                })
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    getOrderStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callOrderStatus()
        .then(response => {
            let orderStatus = response.status !== undefined ?  response.status : null
            if(orderStatus !== null){
                this.setState({
                    isLoading: false,
                    orderStatusList: orderStatus
                })
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getDeliveryTerms = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDeliveryTermsList()
        .then(response => {
            this.setState({
                isLoading: false,
                deliveryTermsList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }



    createOrder = () => {
        let {
            customer,
            status,
            deliveryterms,
            reference,
            shippingaddress,
            internalNotes,
            quotationMessage,
            quotationMessageFooter,
            rfqNo,
            rfqDate,
            quotationValidityEnd,
            poNo,
            poDate,
            termsAndConditions,
            contactPerson,
            accountManager,
            docPermissionsManagersValue,
            poDeliveryDate
        } = this.state

        let productGroupDataRaw = JSON.parse(localStorage.getItem('productGroupData'))
        let totalOfRawCostFinal = localStorage.getItem("totalOfRawCostFinal")
        let totalOfRawCostInOther = localStorage.getItem("totalOfRawCostInOther") ? localStorage.getItem("totalOfRawCostInOther") : ""
        let totalDiscountPrice = localStorage.getItem("totalDiscountPrice")
        let totalDiscountPercentage = localStorage.getItem("totalDiscountPercentage")

        if(productGroupDataRaw === null){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please Fill all fields first" 
            })
        }else{

        
        /* Remove Null From State */
         let productGroupData = [];
        productGroupDataRaw.forEach((val) => {
            if(val !== null){
                productGroupData.push(val);
            }
        });
        /* Remove Null */

        /* GET OLDEST DATE FROM PRODUCT GROUP - CODE START */
            
        let groupDateArray = []
        let dt = "";
        let newDate = "";
        
        //let currentDate = new Date();
        //currentDate = currentDate.setDate(currentDate.getDate() + 30)

        let currentDate = this.state.deliverydate
        if(!currentDate){
            let cDate = new Date(new Date());
            currentDate = new Date(cDate.setDate(cDate.getDate() + 30));
        }

        var dd = String(currentDate.getDate()).padStart(2, '0');
        var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = currentDate.getFullYear();

        currentDate = mm + '/' + dd + '/' + yyyy;

        productGroupData.map((data) => {
            dt = (data.deliverDate) ? data.deliverDate : currentDate
            newDate = dt
            groupDateArray.push(newDate)
        })

        let oldestDate = groupDateArray.reduce(function (pre, cur) {
            return Date.parse(pre) > Date.parse(cur) ? pre : cur;
        });

        /* GET OLDEST DATE FROM PRODUCT GROUP - CODE END */

        let deliverydate = oldestDate

        if(customer === "" || status === ""){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please fill all fields" 
            })
        }else if(productGroupData === "null" || productGroupData === null){
           
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please add product details" 
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCustomerQuotation(customer,status,deliverydate,deliveryterms,reference,shippingaddress,internalNotes,JSON.stringify(productGroupData),totalOfRawCostFinal,totalDiscountPercentage,totalDiscountPrice,quotationMessage,quotationMessageFooter,rfqNo,convertDateToMMDDYYYY(rfqDate),convertDateToMMDDYYYY(quotationValidityEnd),poNo,convertDateToMMDDYYYY(poDate),JSON.stringify(termsAndConditions),contactPerson,accountManager,JSON.stringify(docPermissionsManagersValue),totalOfRawCostInOther,convertDateToMMDDYYYY(poDeliveryDate))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    localStorage.removeItem('productGroupData')
                    localStorage.removeItem("totalOfProductCost")
                    localStorage.removeItem("totalOfRawCostFinal")
                    localStorage.removeItem("totalOfRawCostInOther")
                    localStorage.removeItem("totalDiscountPrice")
                    localStorage.removeItem("totalDiscountPercentage")
                    localStorage.setItem("createNewCustomerOrder", true)
                    localStorage.setItem("customerOrderId",response._id)
                    localStorage.setItem("orderType","customerOrder")
                    localStorage.setItem("customerOrderNumber",response.number.toUpperCase())
                    
                    this.props.history.push({
                        pathname: "/editcustomerorder",
                        state: { customerOrderId: response._id, orderType: 'customerOrder', customerOrderNumber: response.number.toUpperCase() }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }
    }

    onQuotationMessageChange = (value) => {
        this.setState({
            quotationMessageEditor: value,
            quotationMessage: value.toString('html')
        });
    }

    onquotationMessageFooterChange = (value) => {
        this.setState({
            quotationMessageFooterEditor: value,
            quotationMessageFooter: value.toString('html')
        });
    }


    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false, setProductGroup: false, })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {

            if(!response.companyData.taxApplicable.includes("notax")){
                localStorage.setItem('applicableTax',response.companyData.taxApplicable)
            }else{
                localStorage.removeItem('applicableTax')
            }
            localStorage.removeItem('productGroupData')
            localStorage.removeItem("totalOfProductCost")
            localStorage.removeItem("totalOfRawCostFinal")
            localStorage.removeItem("totalDiscountPrice")
            localStorage.removeItem("totalDiscountPercentage")

            this.setState({
                accountManagerDetail: response.companyData.accountManager,
                accountManager: response.companyData.accountManager.length > 0 ? response.companyData.accountManager[0].userId : '',
                isLoading: false,
                setProductGroup: true,
                docPermissionsManagersField: [],
                docPermissionsManagersValue: [],
                currencyData: response.companyData.currencyDetails
            },()=>{
                this.setState({
                    accountManagerDetail: this.state.accountManagerDetail.concat([ response.companyData.adminDetails ])
                })
                this.getContactPerson(id)
                //this.setDocPermissions(response.companyData.accountManager)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors,
                setProductGroup: true 
            })
        });
    }

    setDocPermissions = (accManagers) => {
        let userData = localStorage.getItem("userdata")
        let userRole = JSON.parse(userData).role
        let userId = JSON.parse(userData)._id

        let docPermAccManagerField = []
        let docPermAccManagerValue = []
        for(let i = 0;i<accManagers.length;i++){
            if(userRole.toLowerCase() !== "admin"){
                docPermAccManagerField.push(
                    <div className='docPermission__class' key={"accDocPer"+i}>
                        <div className='float-left width40 createdText border-0 text-capitalize'>{accManagers[i].userName}</div>
                        <div className='float-left width40 text-center createdText border-0'>
                            <input 
                                type="checkbox" 
                                disabled={accManagers[i].userId ===  userId ? true : false}
                                defaultChecked={true}
                                onChange={(e)=>this.updatePermission(e.target.checked,accManagers[i].userId, accManagers[i].userName )}
                            ></input>
                        </div>
                        <div className='clear'></div>
                    </div>
                )
                let obj = {
                    accountManager: accManagers[i].userId,
                    username: accManagers[i].userName,
                    permission: true
                }
                docPermAccManagerValue.push(obj)
            }else{
                docPermAccManagerField.push(
                    <div className='docPermission__class' key={"accDocPer"+i}>
                        <div className='float-left width40 createdText border-0 text-capitalize'>{accManagers[i].userName}</div>
                        <div className='float-left width40 text-center createdText border-0'>
                            <input 
                                type="checkbox" 
                                defaultChecked={true}
                                onChange={(e)=>this.updatePermission(e.target.checked,accManagers[i].userId, accManagers[i].userName )}
                            ></input>
                        </div>
                        <div className='clear'></div>
                    </div>
                )
                let obj = {
                    accountManager: accManagers[i].userId,
                    username: accManagers[i].userName,
                    permission: true
                }
                docPermAccManagerValue.push(obj)
            }
        }
        this.setState({
            docPermissionsManagersField: docPermAccManagerField,
            docPermissionsManagersValue: docPermAccManagerValue,
        })
    }

    updatePermission = (isCheked, userId, userName) => {
        let arr = [...this.state.docPermissionsManagersValue]
        let obj = {
            accountManager: userId,
            username: userName,
            permission: isCheked
        }

        const i = arr.findIndex(ele => ele.accountManager === userId);
        if (i > -1) {
            arr[i].permission = isCheked;
            this.setState({
                docPermissionsManagersValue: arr
            })
        }else{
            this.setState({
                docPermissionsManagersValue: this.state.docPermissionsManagersValue.concat([obj])
            })
        }
    }

    render(){
        let {
            goBack
        } = this.props;
        return(
            <div className="main-container">
                <div>
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Create a new quotation</div>
                            </div>
                        </div>
                    </div>
            
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }

                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createOrder()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Customer*</div>
                                    <div className="value">
                                        <Select2
                                            data={
                                                this.state.customerList.map((data) => (
                                                    { id: data._id, text: data.companyId.toUpperCase() +" "+ data.nickName }
                                                ))
                                            }
                                            value={this.state.customer}
                                            options={{placeholder: 'Select company'}}
                                            onSelect={(e)=> this.setState({ customer: e.target.value },()=>{ 
                                                this.getCustomerDetails(e.target.value)
                                                this.getCustomerShippingAddress(e.target.value)
                                            })}
                                        />

                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Contact person</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.contactPersonList.map((data) => (
                                                    { id: data._id, text: data.name }
                                                ))
                                            }
                                            value={this.state.contactPerson}
                                            options={{placeholder: 'Select person'}}
                                            onSelect={(e)=> this.setState({ contactPerson: e.target.value })}
                                        />
                                    </div>                                                  
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Status*</div>
                                    <div className="value">
                                        <select value={this.state.status} onChange={(e)=>this.setState({ status: e.target.value })}>
                                            <option value="">Select</option>
                                            <option value="quotation">Quotation</option>
                                            <option value="waiting for confirmation">Waiting for confirmation</option>
                                            <option value="confirmed">Confirmed</option>
                                            <option value="waiting for production">Waiting for Production</option>
                                            <option value="in production">In Production</option>
                                            <option value="ready for shipment">Ready for Shipment</option>
                                            <option value="partially invoiced">Partially Invoiced</option>
                                            <option value="partially shipped">Partially Shipped</option>
                                            <option value="invoiced">Invoiced</option>
                                            <option value="shipped">Shipped</option>
                                            <option value="delivered">Delivered</option>
                                            <option value="archived">Archived</option>
                                            <option value="cancelled">Cancelled</option>
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                
                                

                                <div className="add_inner">
                                    <div className="title">Shipping address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.shippingAddressList.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.shippingaddress}
                                            options={{placeholder: 'Select address'}}
                                            onSelect={(e)=> this.setState({ shippingaddress: e.target.value })}
                                        />
                                    </div>                                                  
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">PO No.</div>
                                    <div className="value"><input type="text" value={this.state.poNo} onChange={(e)=>this.setState({ poNo: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">PO Date</div>
                                    <div className="value">
                                        <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            this.state.poDate
                                        }
                                        onChange={
                                            date => this.setState({ poDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery (As per PO)</div>
                                    <div className="value">
                                        <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            this.state.poDeliveryDate
                                        }
                                        onChange={
                                            date => this.setState({ poDeliveryDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                               
                                
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Account Manager</div>
                                    <div className="value">
                                        <Select2    
                                            className="width90 text-capitalize"
                                            data={
                                                this.state.accountManagerDetail.map((data) => (
                                                    { id: data.userId, text: data.userName }
                                                ))
                                            }
                                            value={this.state.accountManager}
                                            options={{placeholder: 'Select person'}}
                                            onSelect={(e)=> this.setState({ accountManager: e.target.value })}
                                        />
                                    </div>                                                  
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Reference</div>
                                    <div className="value"><input type="text" value={this.state.reference} onChange={(e)=>this.setState({ reference: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">RFQ no.</div>
                                    <div className="value"><input type="text" value={this.state.rfqNo} onChange={(e)=>this.setState({ rfqNo: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">RFQ date</div>
                                    <div className="value">
                                        <DatePicker
                                        dateFormat="dd/MM/yyyy" 
                                        maxDate={new Date()}
                                        selected={
                                            this.state.rfqDate
                                        }
                                        onChange={
                                            date => this.setState({ rfqDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Estimated Delivery</div>
                                    <div className="value">
                                        <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        selected={
                                            this.state.deliverydate
                                        }
                                        onChange={
                                            date => this.setState({ deliverydate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Actual Delivery</div>
                                    <div className="value">
                                        <DatePicker 
                                        disabled
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        selected={
                                            this.state.actualDelivery
                                        }
                                        onChange={
                                            date => this.setState({ actualDelivery: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Valid till</div>
                                    <div className="value">
                                        <DatePicker
                                        dateFormat="dd/MM/yyyy" 
                                        minDate={new Date()}
                                        selected={
                                            this.state.quotationValidityEnd
                                        }
                                        onChange={
                                            date => this.setState({ quotationValidityEnd: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">Doc(s) Permissions</div>
                                    <div className="value">
                                        <div className='createdText border-0'>
                                            <div className='float-left width40'><b>Account Manager</b></div>
                                            <div className='float-left width40 text-center'><b>Permission</b></div>
                                            <div className='clear'></div>
                                        </div>
                                    </div>
                                    <div className="value mb-1">
            
                                        { this.state.docPermissionsManagersField }
                                        
                                    </div>
                                </div> */}
                            </div>
                            <div className="clear"></div>
                            <div className="add_outer_full_part">
                                <div className="title">Quotation Message</div>
                                <div>
                                    <RichTextEditor
                                        value={this.state.quotationMessageEditor}
                                        onChange={this.onQuotationMessageChange} 
                                        rootStyle={{ minHeight: 170 }}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                    
                        {
                            this.state.setProductGroup &&
                            <div>
                                {
                                this.state.currencyData.conversionRate ?
                                    <ProductGroupForOtherCurrency 
                                        currencyData={this.state.currencyData}
                                    />
                                :
                                    <ProductGroup />
                                }
                            </div>
                            
                        }

                        {/* TERMS & CONDTIONS FIELD */}
                        <div className="__terms_and_conditions">
                            <div className="__terms_left_header">
                                <div className="__terms_content_title">{'Terms & conditions'}</div>
                            </div>
                            <div className="__terms_right_content">
                                { this.state.termsFields }
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className='__terms_and_conditions'>
                            <div className="add_outer_full_part">
                                <div className="title">Quotation footer</div>
                                <div>
                                    <RichTextEditor
                                        value={this.state.quotationMessageFooterEditor}
                                        onChange={this.onquotationMessageFooterChange} 
                                        rootStyle={{ minHeight: 170 }}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createOrder()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        )
    }
}