import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import * as Im from 'react-icons/im'
import * as Fi from 'react-icons/fi'
import * as Ai from "react-icons/ai"
import * as Bi from "react-icons/bi"
import * as ExportData from './exportData'
import * as APIManager from './../../../APIManager/APIManager'
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';

class Invoices extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            invoicesList: [],

            number: "",
            invoiceNo: "",
            minTotal: "",
            maxTotal: "",
            minSubTotal: "",
            maxSubTotal: "",
            purchaseOrderNumber: "",
            vendorName: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            minDueDate: "",
            maxDueDate: "",
            minCreatedDate: "",
            maxCreatedDate: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,

            subTotal: 0,
            total: 0,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }
     //******************* ascending descending order function ********************************
     sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.invoicesList.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ invoicesList: sortedOrders }, () => {
                this.setFilterData()
            });
        });
        
    };
    //******************* ascending descending order function end ********************************

    getAllInvoices = () => {
        let {
            start,
            length,
            number,
            invoiceNo,
            minTotal,
            maxTotal,
            minSubTotal,
            maxSubTotal,
            purchaseOrderNumber,
            vendorName,
            minInvoiceDate,
            maxInvoiceDate,
            minDueDate,
            maxDueDate,
            minCreatedDate,
            maxCreatedDate
        } = this.state
        APIManager.callGetPOInvoices(start, length, number, invoiceNo, minTotal, maxTotal, minSubTotal, maxSubTotal, purchaseOrderNumber, vendorName, convertDateToMMDDYYYY(minInvoiceDate), convertDateToMMDDYYYY(maxInvoiceDate), convertDateToMMDDYYYY(minDueDate), convertDateToMMDDYYYY(maxDueDate), convertDateToMMDDYYYYWithoutZero(minCreatedDate), convertDateToMMDDYYYYWithoutZero(maxCreatedDate))
            .then(response => {
                let subTotal = 0;
                let total = 0;
                for (let i = 0; i < response.data.length; i++) {
                    subTotal += response.data[i].grandSubTotal
                    total += response.data[i].grandTotal
                }
                this.setState({
                    isLoading: false,
                    invoicesList: response.data,
                    totalRecords: response.totalRecords,
                    subTotal: subTotal.toFixed(2),
                    total: total.toFixed(2)
                }, ()=> {
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });

            this.setFilterData()
    }


  

    createNewPoInvoice = () => {
        let slug = "Procurement_Invoices"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createpoinvoice",
                state: { poId: "", isFromPO: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editInvoice = (id) => {
        this.props.history.push({
            pathname: "/editpoinvoice",
            state: { invoiceId: id, isNewEntry: false }
        })
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.setFilterData()
            this.getAllInvoices()
        });
    }

    setFilterData = () => {
        let filterDataArray = {
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
            'number': this.state.number,
            'invoiceNo': this.state.invoiceNo,
            'minTotal': this.state.minTotal,
            'maxTotal': this.state.maxTotal,
            'minSubTotal': this.state.minSubTotal,
            'maxSubTotal': this.state.maxSubTotal,
            'purchaseOrderNumber': this.state.purchaseOrderNumber,
            'vendorName': this.state.vendorName,
            'minInvoiceDate': this.state.minInvoiceDate ? this.state.minInvoiceDate : "",
            'maxInvoiceDate': this.state.maxInvoiceDate ? this.state.maxInvoiceDate : "",
            'minDueDate': this.state.minDueDate ? this.state.minDueDate : "",
            'maxDueDate': this.state.maxDueDate ? this.state.maxDueDate : "",
            'minCreatedDate': this.state.minCreatedDate ? this.state.minCreatedDate : "",
            'maxCreatedDate': this.state.maxCreatedDate ? this.state.maxCreatedDate : "",
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }
        localStorage.setItem("procurementInvoice", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("procurementInvoice"))
        if (filterData !== null) {
            this.setState({
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,
                number: filterData.number,
                invoiceNo: filterData.invoiceNo,
                minTotal: filterData.minTotal,
                maxTotal: filterData.maxTotal,
                minSubTotal: filterData.minSubTotal,
                maxSubTotal: filterData.maxSubTotal,
                purchaseOrderNumber: filterData.purchaseOrderNumber,
                vendorName: filterData.vendorName,
                minInvoiceDate: filterData.minInvoiceDate  ? new Date(filterData.minInvoiceDate) : "",
                maxInvoiceDate: filterData.maxInvoiceDate  ? new Date(filterData.maxInvoiceDate) : "",
                minDueDate: filterData.minDueDate ? new Date(filterData.minDueDate) : "",
                maxDueDate: filterData.maxDueDate  ? new Date(filterData.maxDueDate) : "",
                minCreatedDate: filterData.minCreatedDate  ? new Date(filterData.minCreatedDate) : "",
                maxCreatedDate: filterData.maxCreatedDate  ? new Date(filterData.maxCreatedDate) : "",
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getAllInvoices()

            })
        } else {
            this.getAllInvoices()


        }
    }


    clearAllFilters = () => {
        localStorage.removeItem("procurementInvoice")
        this.setState({
            number: "",
            invoiceNo: "",
            minTotal: "",
            maxTotal: "",
            minSubTotal: "",
            maxSubTotal: "",
            purchaseOrderNumber: "",
            vendorName: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            minDueDate: "",
            maxDueDate: "",
            minCreatedDate: "",
            maxCreatedDate: "",
            start: 0,
            length: 25,
        }, () => {
            this.getAllInvoices()
        })
    }

    exportOptions = (type) => {
        let {
            number,
            invoiceNo,
            minTotal,
            maxTotal,
            minSubTotal,
            maxSubTotal,
            purchaseOrderNumber,
            vendorName,
            minInvoiceDate,
            maxInvoiceDate,
            minDueDate,
            maxDueDate,
            minCreatedDate,
            maxCreatedDate
        } = this.state
        APIManager.callGetPOInvoices("", "", number, invoiceNo, minTotal, maxTotal, minSubTotal, maxSubTotal, purchaseOrderNumber, vendorName, convertDateToMMDDYYYY(minInvoiceDate), convertDateToMMDDYYYY(maxInvoiceDate), convertDateToMMDDYYYY(minDueDate), convertDateToMMDDYYYY(maxDueDate), convertDateToMMDDYYYYWithoutZero(minCreatedDate), convertDateToMMDDYYYYWithoutZero(maxCreatedDate))
            .then(response => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if (type === "pdf") {
                        ExportData.exportInvoicePDF(this.state.invoicesList);
                    } else {
                        ExportData.exportInvoiceCSV(this.state.invoicesList);
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render () {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isInvoices={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">{Strings.Procurement.Invoices}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportOptions('pdf')}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportOptions('csv')}>CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.createNewPoInvoice()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate' ,false)}><span>Number  </span>{this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('purchaseOrderNumber', 'notDate', false)}><span>Purchase Order </span> {this.state.fieldName === "purchaseOrderNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "purchaseOrderNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('vendorName', 'notDate' ,false)}><span>Vendor </span> {this.state.fieldName === "vendorName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "vendorName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('grandSubTotal', 'notDate', false)}><span>Subtotal </span> {this.state.fieldName === "grandSubTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandSubTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('grandTotal', 'notDate', false)}><span>Total </span> {this.state.fieldName === "grandTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('invoiceId', 'notDate', false)}><span>Invoice No </span> {this.state.fieldName === "invoiceId" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceId" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('invoiceDate', 'Date', false)}><span>Invoice Date  </span>{this.state.fieldName === "invoiceDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('dueDate', 'Date', false)}><span>Due Date </span> {this.state.fieldName === "dueDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "dueDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('created', 'Date' ,false)}><span>Created </span> {this.state.fieldName === "created" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "created" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                   
                            
                                    {/* <th className="tableHeader"><span>Number</span></th>
                                    <th className="tableHeader"><span>Purchase Order</span></th>
                                    <th className="tableHeader"><span>Vendor</span></th>
                                    <th className="tableHeader"><span>Subtotal</span></th>
                                    <th className="tableHeader"><span>Total</span></th>
                                    <th className="tableHeader"><span>Invoice No</span></th>
                                    <th className="tableHeader"><span>Invoice Date</span></th>
                                    <th className="tableHeader"><span>Due Date</span></th>
                                    <th className="tableHeader"><span>Created</span></th> */}
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.createNewPoInvoice()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllInvoices() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.purchaseOrderNumber} onChange={(e) => this.setState({ purchaseOrderNumber: e.target.value }, () => { this.getAllInvoices() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.vendorName} onChange={(e) => this.setState({ vendorName: e.target.value }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minSubTotal} placeholder="min" onChange={(e) => this.setState({ minSubTotal: e.target.value }, () => { this.getAllInvoices() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxSubTotal} placeholder="max" onChange={(e) => this.setState({ maxSubTotal: e.target.value }, () => { this.getAllInvoices() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minTotal} placeholder="min" onChange={(e) => this.setState({ minTotal: e.target.value }, () => { this.getAllInvoices() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxTotal} placeholder="max" onChange={(e) => this.setState({ maxTotal: e.target.value }, () => { this.getAllInvoices() })} />
                                    </th>
                                    <th className='tableElemContain'><input type="text" value={this.state.invoiceNo} onChange={(e) => this.setState({ invoiceNo: e.target.value }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxInvoiceDate}
                                                selected={this.state.minInvoiceDate}
                                                onChange={(date) => this.setState({ minInvoiceDate: date }, () => { this.getAllInvoices() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minInvoiceDate}
                                                selected={this.state.maxInvoiceDate}
                                                onChange={(date) => this.setState({ maxInvoiceDate: date }, () => { this.getAllInvoices() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxDueDate}
                                                selected={this.state.minDueDate}
                                                onChange={(date) => this.setState({ minDueDate: date }, () => { this.getAllInvoices() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minDueDate}
                                                selected={this.state.maxDueDate}
                                                onChange={(date) => this.setState({ maxDueDate: date }, () => { this.getAllInvoices() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxCreatedDate}
                                                selected={this.state.minCreatedDate}
                                                onChange={(date) => this.setState({ minCreatedDate: date }, () => { this.getAllInvoices() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minCreatedDate}
                                                selected={this.state.maxCreatedDate}
                                                onChange={(date) => this.setState({ maxCreatedDate: date }, () => { this.getAllInvoices() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.clearAllFilters()}><Fi.FiX /></div></th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><b>Total:</b></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>{(this.state.subTotal).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                    <td><b>{(this.state.total).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                :
                                <tbody>
                                {
                                    this.state.invoicesList.map((data, i) => (
                                        <tr key={data._id} onClick={() => this.editInvoice(data._id)}>
                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                            <td className="text-uppercase">{data.number}</td>
                                            <td className="text-uppercase">{data.purchaseOrderNumber}</td>
                                            <td className="text-capitalize">{data.vendorName}</td>
                                            <td>{data.grandSubTotal.toFixed(2)} <Bi.BiRupee /></td>
                                            <td>{data.grandTotal.toFixed(2)} <Bi.BiRupee /></td>
                                            <td>{data.invoiceId}</td>
                                            <td>{convertDateStringMMDDtoDDMM(data.invoiceDate)}</td>
                                            <td>{convertDateStringMMDDtoDDMM(data.dueDate)}</td>
                                            <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Invoices;