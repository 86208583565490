import React, { Component } from 'react';
import GroupItem from './GroupItem';
import * as Bi from 'react-icons/bi'
class ProductGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
             //Group Item Field and Count here

             productObj: {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
            },

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: this.props.productGroupData,
            totalOfProductCost: 0.00,
            totalOfProductCostFinal: 0.00,
            totalOfRawCostFinal: 0.00,
            totalNoOfProduct: 0,
            vendorApplicableTax: this.props.vendorApplicableTax,
            vendor: this.props.vendor,
            setDataFromGroup: this.props.setDataFromGroup,
            history: this.props.history,
            status: this.props.status,

            currency: "",
            conversionDetail: "",
        }

        this.addNewGroupFields = this.addNewGroupFields.bind(this)
        this.addGroupFieldValues = this.addGroupFieldValues.bind(this)
    }

    componentDidMount(){
        this.setProductGroupData()
    }

    setProductGroupData = () => {
        let prodGrp = this.state.productGroupData
        let groupLength = prodGrp.length;
        
        if(groupLength > 0){

            let groupField = [];
            let groupKey = [];

            for(let i=0;i<prodGrp.length;i++){
                if(prodGrp[i] !== null){
                    groupField.push(
                        <GroupItem
                            key={'proGroup'+i}
                            onChangeProduct={this.addNewGroupFields}
                            onBlurProduct={this.addGroupFieldValues}
                            onRemoveProduct={this.removeGroupProduct}
                            counter={i}
                            groupData={prodGrp[i]}
                            taxTypes={this.state.vendorApplicableTax}
                            vendorId={this.state.vendor}
                            history={this.state.history}
                        />
                    )
                    groupKey.push(i)
                }
            }

            this.setState({
                productGroupFields: groupField,
                productGroupKeys: groupKey,
                productGroupCounter: groupLength
            },()=>{
                if(this.state.status === "new po" || this.state.status === "rfq"){
                    this.setNewBlankField(this.state.productGroupCounter)
                }
                this.countTotal()
            })
        }else{
            if(this.state.status === "new po" || this.state.status === "rfq"){
                this.setNewBlankField(this.state.productGroupCounter)
            }
        }
    }

    setNewBlankField = (counter) => {
        let dummyObj =  {
            'productGroup': '',
            'item': '',
            'freeText': '',
            'quantity': '',
            'pricePerUoM': '',
            'discount': '',
            'subTotal': '',
            'productTotal': '',
            'expectedDate': '',
        }

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <GroupItem
                    key={'proGroup'+counter}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={counter}
                    groupData={dummyObj}
                    taxTypes={this.state.vendorApplicableTax}
                    vendorId={this.state.vendor}
                    history={this.state.history}
                />
            ]),            
        })
    }



    addNewGroupFields = (counter) => {
        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)

        if(index === -1){
    
            let dummyObj =  {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
            }

            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <GroupItem
                        key={'proGroup'+counter+1} 
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={counter+1}
                        groupData={dummyObj}
                        taxTypes={this.state.vendorApplicableTax}
                        vendorId={this.state.vendor}
                        history={this.props.history}
                    />
                ]),
            },()=>{
                this.setState({
                    productGroupKeys: this.state.productGroupKeys.concat([counter]),
                    productGroupCounter: counter+1,
                })
            })
        }
    }

    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        },()=>{
            this.countTotal()
        })
    }

    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
       
        this.setState({
            productGroupData,
            productGroupFields
        },()=> {
            this.countTotal()
        })

    }

    countTotal = () => {
        let arr = this.state.productGroupData;
        let totalCost = 0;
        let totalQuantity = 0;
        let rawTotal = 0;
        for(let i=0;i<arr.length;i++){
            if(arr[i] !== null){
                totalCost += arr[i].subTotal !== "" ? parseFloat(arr[i].subTotal) : 0
                totalQuantity += arr[i].quantity !== "" ? parseFloat(arr[i].quantity) : 0 
                rawTotal += arr[i].productTotal !== "" ? parseFloat(arr[i].productTotal) : 0
            }
        }
        this.setState({
            totalOfProductCost: totalCost.toFixed(2),
            totalOfProductCostFinal: totalCost.toFixed(2),
            totalOfRawCostFinal: Math.round(rawTotal).toFixed(2),
            totalNoOfProduct: totalQuantity,
        },()=>{
            this.state.setDataFromGroup(this.state.totalOfProductCostFinal,this.state.totalOfRawCostFinal,arr)
        })
    }

    render() {
        return (
            <div className="main-container mt-20 order_product_group">
                <table>
                    <thead>
                        <tr>
                            <th className='pg-tab-head'></th>
                            <th className='pg-tab-head'>Product group</th>
                            <th className='pg-tab-head'>Item</th>
                            <th className='pg-tab-head'>Req. Qty</th>
                            <th className='pg-tab-head'>Ordered Qty</th>
                            <th className='pg-tab-head'>Price/UoM</th>
                            <th className='pg-tab-head'>Discount</th>
                            <th className='pg-tab-head'>Subtotal</th>
                            {
                                this.state.vendorApplicableTax.length > 0 &&
                                this.state.vendorApplicableTax.map((data)=>(
                                    <th className='pg-tab-head' key={'tab-index-'+data}>{data}</th>
                                ))
                            }
                            <th className='pg-tab-head'>Total</th>
                            <th className='pg-tab-head'>Target Lot</th>
                            <th className='pg-tab-head'>Expected delivery date</th>
                            <th className='pg-tab-head'>Arrival date</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.productGroupFields
                        }
                        <tr>
                            <td></td>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalNoOfProduct.toFixed(2)}</b></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalOfProductCostFinal} <Bi.BiRupee /></b></td>
                            {
                                this.state.vendorApplicableTax.length > 0 &&
                                this.state.vendorApplicableTax.map((data)=>(
                                    <td key={'tab-index-'+data+data}></td>
                                ))
                            }
                            <td><b>{this.state.totalOfRawCostFinal} <Bi.BiRupee /></b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProductGroup;