import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import * as APIManager from './../../../APIManager/APIManager'

import Select2 from './../../../lib/select2/select2'
import './../../../lib/select2/select2.css'

class CreateSubUser extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            showPassword: false, 

            userList: [],
            selectedUser: "",
            name: "",
            email: "",
            phone: "",
            designation: "",
            password: "",
            userPermissionData: [
                {
                    "slug": "CRM",
                    "group": "CRM",
                    "name": "CRM",
                    "hide": false
                },
                {
                    "slug": "CRM_Quotations",
                    "group": "CRM",
                    "name": "Quotations",
                    "link": "/quotations",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "CRM_CustomerOrders",
                    "group": "CRM",
                    "name": "Customer orders",
                    "link": "/customerorders",
                    "read": false,
                    "update": false,
                    "hide": false
                },
                {
                    "slug": "CRM_Customers",
                    "group": "CRM",
                    "name": "Customers",
                    "link": "/customers",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "CRM_TodaysContacts",
                    "group": "CRM",
                    "name": "Today's contacts",
                    "link": "/todayscontacts",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "CRM_Invoices",
                    "group": "CRM",
                    "name": "Invoices",
                    "link": "/invoices",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "CRM_E_Invoices",
                    "group": "CRM",
                    "name": "E-Invoices",
                    "link": "/einvoices",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "CRM_CreditNotes",
                    "group": "CRM",
                    "name": "Credit Note",
                    "link": "/creditnotes",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "CRM_PumpRegister",
                    "group": "CRM",
                    "name": "Pump Register",
                    "link": "/pumpregister",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false,
                    "isPersonalTab": true,
                    "accountId": "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
                },
               
                {
                    "slug": "ProductionPlanning",
                    "group": "Production Planning",
                    "name": "Production Planning",
                    "hide": false
                },
                {
                    "slug": "MyProductionPlan",
                    "group": "My Production Plan",
                    "name": "My Production Plan",
                    "link": "/myproductionplan",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "ProductionOperations",
                    "group": "Production Planning",
                    "name": "Production operations",
                    "link": "/prooperations",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "ProductionPlanning_ManufacturingOrders",
                    "group": "Production Planning",
                    "name": "Manufacturing orders",
                    "link": "/manufacturingorders",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "ProductionPlanning_Workstations",
                    "group": "Production Planning",
                    "name": "Workstations",
                    "link": "/workstations",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "ProductionPlanning_Workstationsgroups",
                    "group": "Production Planning",
                    "name": "Workstation groups",
                    "link": "/workstationgroups",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "ProductionPlanning_BOM",
                    "group": "Production Planning",
                    "name": "BOM",
                    "link": "/bom",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "ProductionPlanning_Routings",
                    "group": "Production Planning",
                    "name": "Routings",
                    "link": "/routings",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                
                {
                    "slug": "Stock",
                    "group": "Stock",
                    "name": "Stock",
                    "hide": false
                },
                {
                    "slug": "Stock_Items",
                    "group": "Stock",
                    "name": "Items",
                    "link": "/items",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Stock_StockSettings",
                    "group": "Stock",
                    "name": "Stock settings",
                    "link": "/stocksettings",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Stock_StockLots",
                    "group": "Stock",
                    "name": "Stock lots",
                    "link": "/stocklots",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Stock_Shipments",
                    "group": "Stock",
                    "name": "Shipments",
                    "link": "/shipments",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
               
             
                {
                    "slug": "Stock_WriteOffs",
                    "group": "Stock",
                    "name": "Write-offs",
                    "link": "/writeoffs",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Stock_StockMovement",
                    "group": "Stock",
                    "name": "Stock movement",
                    "link": "/stockmovement",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Stock_FilterTable",
                    "group": "Stock",
                    "name": "Filter table",
                    "link": "/filtertable",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Procurement",
                    "group": "Procurement",
                    "name": "Procurement",
                    "hide": false
                },
                {
                    "slug": "Procurement_PurchaseOrders",
                    "group": "Procurement",
                    "name": "Purchase orders",
                    "link": "/purchaseorders",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Procurement_Vendors",
                    "group": "Procurement",
                    "name": "Vendors",
                    "link": "/vendors",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Procurement_Invoices",
                    "group": "Procurement",
                    "name": "Invoices",
                    "link": "/poinvoices",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Procurement_QualityCheck",
                    "group": "Procurement",
                    "name": "Quality Check",
                    "link": "/poqc",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Procurement_DebitNote",
                    "group": "Procurement",
                    "name": "Debit Note",
                    "link": "/poqc",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
             
             
                {
                    "slug": "Accounting",
                    "group": "Accounting",
                    "name": "Accounting",
                    "hide": false
                },
                
                {
                    "slug": "Accounting_expenses",
                    "group": "Accounting",
                    "name": "Expenses",
                    "link": "/expenses",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Settings",
                    "group": "Settings",
                    "name": "Settings",
                    "hide": false
                },
                {
                    "slug": "Settings_Accounting",
                    "group": "Settings",
                    "name": "Accounting master",
                    "link": "/accountingmaster",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Settings_HumanResources",
                    "group": "Settings",
                    "name": "Human resources",
                    "link": "/hr",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Settings_Profile",
                    "group": "Settings",
                    "name": "Profile",
                    "link": "/profile",
                    "read": false,
                    "create": false,
                    "update": false,
                    "hide": false
                },
                {
                    "slug": "Settings_TermsAndConditions",
                    "group": "Settings",
                    "name": "Terms & Conditions",
                    "link": "/terms",
                    "read": false,
                    "create": false,
                    "update": false,
                    "delete": false,
                    "hide": false
                },
                {
                    "slug": "Settings_PumpMaster",
                    "group": "Settings",
                    "name": "Pump Master",
                    "link": "/pumpmaster",
                    "read": false,
                    "update": false,
                    "hide": false,
                    "isPersonalTab": true,
                    "accountId": "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
                },
                {
                    "slug": "Dashboard",
                    "group": "Dashboard",
                    "name": "Dashboard",
                    "hide": false
                },
                {
                    "slug": "Dashboard_totalInquiries",
                    "group": "Dashboard",
                    "name": "Inquiries",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_totalQuotation",
                    "group": "Dashboard",
                    "name": "Quotations",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_totalTurnover",
                    "group": "Dashboard",
                    "name": "Expected Turnover",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_confirmedOrder",
                    "group": "Dashboard",
                    "name": "Confirmed CO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_inproduction",
                    "group": "Dashboard",
                    "name": "In Production",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_deliveredOrder",
                    "group": "Dashboard",
                    "name": "Delivered CO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_waitingForConfirm",
                    "group": "Dashboard",
                    "name": "Waiting for confirm",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_waitingForProduction",
                    "group": "Dashboard",
                    "name": "Waiting for production",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_COReadyToShip",
                    "group": "Dashboard",
                    "name": "CO ready to ship",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_partiallyInvoiced",
                    "group": "Dashboard",
                    "name": "Partially Invoiced",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_invoiced",
                    "group": "Dashboard",
                    "name": "Invoiced",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_partiallyShipped",
                    "group": "Dashboard",
                    "name": "Partially Shipped",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_shipped",
                    "group": "Dashboard",
                    "name": "Shipped",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_archivedOrder",
                    "group": "Dashboard",
                    "name": "Archived CO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_cancelledCO",
                    "group": "Dashboard",
                    "name": "Cancelled CO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_lateCo",
                    "group": "Dashboard",
                    "name": "Late CO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_lateMo",
                    "group": "Dashboard",
                    "name": "Late MO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_latePo",
                    "group": "Dashboard",
                    "name": "Late PO",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_PendingItem",
                    "group": "Dashboard",
                    "name": "Pending Item",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_MissingItem",
                    "group": "Dashboard",
                    "name": "Missing Items",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_ItemBelowReorderPoint",
                    "group": "Dashboard",
                    "name": "Item below reorder point",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_7DaysLateInvoices",
                    "group": "Dashboard",
                    "name": "7 Days Late Invoices",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_MOInProgress",
                    "group": "Dashboard",
                    "name": "MO in progress",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_MOReadyToStart",
                    "group": "Dashboard",
                    "name": "MO ready to start",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_OEE",
                    "group": "Dashboard",
                    "name": "OEE",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_TEEP",
                    "group": "Dashboard",
                    "name": "TEEP",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_Sales",
                    "group": "Dashboard",
                    "name": "Sales",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_Stock",
                    "group": "Dashboard",
                    "name": "Stock",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_CashFlow",
                    "group": "Dashboard",
                    "name": "Cash flow",
                    "link": "/dashboard",
                    "read": false,
                    "hide": true
                },
                {
                    "slug": "Dashboard_PurchaseOnTime",
                    "group": "Dashboard",
                    "name": "Purchase on time",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_ManufacturingOnTime",
                    "group": "Dashboard",
                    "name": "Manufacturing on time",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                },
                {
                    "slug": "Dashboard_DeliveriesOnTime",
                    "group": "Dashboard",
                    "name": "Deliveries on time",
                    "link": "/dashboard",
                    "read": false,
                    "hide": false
                }
                
            ],

            departmentData: [],
            departments: [],

            checkPerPieceSalary: false,
            checkPerHourSalary: true,
            checkPerMonthSalary: false,

            workerPaymentType: "perhour",
            workerPaymentValue:  "",

            userAccountId: "",

        }
    }
    
    componentDidMount(){
        let user = localStorage.getItem('userdata')
        if(user){
            let accountId = JSON.parse(localStorage.getItem('userdata')).account_id
            this.setState({
                userAccountId: accountId
            })
        }

        this.getUserList()
        this.getDepartment()
    }

   
    
    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            console.log(response)
            let userData = []
            response.data.map((data) => {
                let obj = {
                    text: data.email,
                    id: data._id
                }
                userData.push(obj)
            })
            this.setState({
                isLoading: false,
                userList: userData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getDepartment = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment("", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                departmentData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }


    getSingleUserDetail = (id) => {
        if(id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetSingleSubUser(id)
            .then(response => {
                console.log(response.data.permissions)
                this.setState({
                    isLoading: false
                },()=>{
                    this.setUserPermissions(response.data.permissions)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                })
            });
        }else{
            this.setState({
                userPermissionData: [
                    {
                        "slug": "CRM",
                        "group": "CRM",
                        "name": "CRM",
                        "hide": false
                    },
                    {
                        "slug": "CRM_Quotations",
                        "group": "CRM",
                        "name": "Quotations",
                        "link": "/quotations",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_CustomerOrders",
                        "group": "CRM",
                        "name": "Customer orders",
                        "link": "/customerorders",
                        "read": false,
                        "update": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_Customers",
                        "group": "CRM",
                        "name": "Customers",
                        "link": "/customers",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_TodaysContacts",
                        "group": "CRM",
                        "name": "Today's contacts",
                        "link": "/todayscontacts",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_Invoices",
                        "group": "CRM",
                        "name": "Invoices",
                        "link": "/invoices",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_E_Invoices",
                        "group": "CRM",
                        "name": "E-Invoices",
                        "link": "/einvoices",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_CreditNotes",
                        "group": "CRM",
                        "name": "Credit Note",
                        "link": "/creditnotes",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "CRM_PumpRegister",
                        "group": "CRM",
                        "name": "Pump Register",
                        "link": "/pumpregister",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false,
                        "isPersonalTab": true,
                        "accountId": "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
                    },
                   
                    {
                        "slug": "ProductionPlanning",
                        "group": "Production Planning",
                        "name": "Production Planning",
                        "hide": false
                    },
                    {
                        "slug": "MyProductionPlan",
                        "group": "My Production Plan",
                        "name": "My Production Plan",
                        "link": "/myproductionplan",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "ProductionOperations",
                        "group": "Production Planning",
                        "name": "Production operations",
                        "link": "/prooperations",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "ProductionPlanning_ManufacturingOrders",
                        "group": "Production Planning",
                        "name": "Manufacturing orders",
                        "link": "/manufacturingorders",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "ProductionPlanning_Workstations",
                        "group": "Production Planning",
                        "name": "Workstations",
                        "link": "/workstations",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "ProductionPlanning_Workstationsgroups",
                        "group": "Production Planning",
                        "name": "Workstation groups",
                        "link": "/workstationgroups",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "ProductionPlanning_BOM",
                        "group": "Production Planning",
                        "name": "BOM",
                        "link": "/bom",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "ProductionPlanning_Routings",
                        "group": "Production Planning",
                        "name": "Routings",
                        "link": "/routings",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    
                    {
                        "slug": "Stock",
                        "group": "Stock",
                        "name": "Stock",
                        "hide": false
                    },
                    {
                        "slug": "Stock_Items",
                        "group": "Stock",
                        "name": "Items",
                        "link": "/items",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Stock_StockSettings",
                        "group": "Stock",
                        "name": "Stock settings",
                        "link": "/stocksettings",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Stock_StockLots",
                        "group": "Stock",
                        "name": "Stock lots",
                        "link": "/stocklots",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Stock_Shipments",
                        "group": "Stock",
                        "name": "Shipments",
                        "link": "/shipments",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },

                    
                    {
                        "slug": "Stock_WriteOffs",
                        "group": "Stock",
                        "name": "Write-offs",
                        "link": "/writeoffs",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Stock_StockMovement",
                        "group": "Stock",
                        "name": "Stock movement",
                        "link": "/stockmovement",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Stock_FilterTable",
                        "group": "Stock",
                        "name": "Filter table",
                        "link": "/filtertable",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Procurement",
                        "group": "Procurement",
                        "name": "Procurement",
                        "hide": false
                    },
                    {
                        "slug": "Procurement_PurchaseOrders",
                        "group": "Procurement",
                        "name": "Purchase orders",
                        "link": "/purchaseorders",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Procurement_Vendors",
                        "group": "Procurement",
                        "name": "Vendors",
                        "link": "/vendors",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                   
                    {
                        "slug": "Procurement_Invoices",
                        "group": "Procurement",
                        "name": "Invoices",
                        "link": "/poinvoices",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Procurement_QualityCheck",
                        "group": "Procurement",
                        "name": "Quality Check",
                        "link": "/poqc",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Procurement_DebitNote",
                        "group": "Procurement",
                        "name": "Debit Note",
                        "link": "/poqc",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Accounting",
                        "group": "Accounting",
                        "name": "Accounting",
                        "hide": false
                    },
                    
                    {
                        "slug": "Accounting_expenses",
                        "group": "Accounting",
                        "name": "Expenses",
                        "link": "/expenses",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Settings",
                        "group": "Settings",
                        "name": "Settings",
                        "hide": false
                    },
                    {
                        "slug": "Settings_Accounting",
                        "group": "Settings",
                        "name": "Accounting master",
                        "link": "/accountingmaster",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Settings_HumanResources",
                        "group": "Settings",
                        "name": "Human resources",
                        "link": "/hr",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Settings_Profile",
                        "group": "Settings",
                        "name": "Profile",
                        "link": "/profile",
                        "read": false,
                        "create": false,
                        "update": false,
                        "hide": false
                    },
                    {
                        "slug": "Settings_TermsAndConditions",
                        "group": "Settings",
                        "name": "Terms & Conditions",
                        "link": "/terms",
                        "read": false,
                        "create": false,
                        "update": false,
                        "delete": false,
                        "hide": false
                    },
                    {
                        "slug": "Settings_PumpMaster",
                        "group": "Settings",
                        "name": "Pump Master",
                        "link": "/pumpmaster",
                        "read": false,
                        "update": false,
                        "hide": false,
                        "isPersonalTab": true,
                        "accountId": "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
                    },

                    {
                        "slug": "Dashboard",
                        "group": "Dashboard",
                        "name": "Dashboard",
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_totalInquiries",
                        "group": "Dashboard",
                        "name": "Inquiries",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_totalQuotation",
                        "group": "Dashboard",
                        "name": "Quotations",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_totalTurnover",
                        "group": "Dashboard",
                        "name": "Expected Turnover",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_confirmedOrder",
                        "group": "Dashboard",
                        "name": "Confirmed CO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_inproduction",
                        "group": "Dashboard",
                        "name": "In Production",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_deliveredOrder",
                        "group": "Dashboard",
                        "name": "Delivered CO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_waitingForConfirm",
                        "group": "Dashboard",
                        "name": "Waiting for confirm",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_waitingForProduction",
                        "group": "Dashboard",
                        "name": "Waiting for production",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_COReadyToShip",
                        "group": "Dashboard",
                        "name": "CO ready to ship",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_partiallyInvoiced",
                        "group": "Dashboard",
                        "name": "Partially Invoiced",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_invoiced",
                        "group": "Dashboard",
                        "name": "Invoiced",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_partiallyShipped",
                        "group": "Dashboard",
                        "name": "Partially Shipped",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_shipped",
                        "group": "Dashboard",
                        "name": "Shipped",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_archivedOrder",
                        "group": "Dashboard",
                        "name": "Archived CO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_cancelledCO",
                        "group": "Dashboard",
                        "name": "Cancelled CO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_lateCo",
                        "group": "Dashboard",
                        "name": "Late CO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_lateMo",
                        "group": "Dashboard",
                        "name": "Late MO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_latePo",
                        "group": "Dashboard",
                        "name": "Late PO",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_PendingItem",
                        "group": "Dashboard",
                        "name": "Pending Item",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_MissingItem",
                        "group": "Dashboard",
                        "name": "Missing Items",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_ItemBelowReorderPoint",
                        "group": "Dashboard",
                        "name": "Item below reorder point",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_7DaysLateInvoices",
                        "group": "Dashboard",
                        "name": "7 Days Late Invoices",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_MOInProgress",
                        "group": "Dashboard",
                        "name": "MO in progress",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_MOReadyToStart",
                        "group": "Dashboard",
                        "name": "MO ready to start",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_OEE",
                        "group": "Dashboard",
                        "name": "OEE",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_TEEP",
                        "group": "Dashboard",
                        "name": "TEEP",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_Sales",
                        "group": "Dashboard",
                        "name": "Sales",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_Stock",
                        "group": "Dashboard",
                        "name": "Stock",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_CashFlow",
                        "group": "Dashboard",
                        "name": "Cash flow",
                        "link": "/dashboard",
                        "read": false,
                        "hide": true
                    },
                    {
                        "slug": "Dashboard_PurchaseOnTime",
                        "group": "Dashboard",
                        "name": "Purchase on time",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_ManufacturingOnTime",
                        "group": "Dashboard",
                        "name": "Manufacturing on time",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    },
                    {
                        "slug": "Dashboard_DeliveriesOnTime",
                        "group": "Dashboard",
                        "name": "Deliveries on time",
                        "link": "/dashboard",
                        "read": false,
                        "hide": false
                    }
                   
                ]
            })
        }
    }


    setUserPermissions = (permissions) => {
        if(permissions !== undefined && permissions.length > 0){
            let userPermissionData = [...this.state.userPermissionData]
            let updatedRecord = userPermissionData.map(obj => permissions.find(lo => lo.slug === obj.slug) || obj);
            this.setState({
                userPermissionData: updatedRecord
            },()=>{
                console.log(updatedRecord)
            })
        }
    }

    updatePermission = (type, data, i) => {
        let userPermissionData = [...this.state.userPermissionData]
        
        if(type === 'read')
            userPermissionData[i].read = !data 

        if(userPermissionData[i].read){
            if(type === 'create')
                userPermissionData[i].create = !data 

            if(type === 'update')
                userPermissionData[i].update = !data 

            if(type === 'delete')
                userPermissionData[i].delete = !data 
        }else{
            if(userPermissionData[i].create !== undefined) userPermissionData[i].create = false
            if(userPermissionData[i].update !== undefined) userPermissionData[i].update = false
            if(userPermissionData[i].delete !== undefined) userPermissionData[i].delete = false
        }
        
        this.setState({
            userPermissionData
        })
    }

   

    toggleDepartment = (i) => {
        let departments = [...this.state.departments]
        let departmentData = [...this.state.departmentData] 
    
        let deptId = departmentData[i]._id;
        let deptData = departments
        if(departments.includes(deptId)){
            const index = deptData.indexOf(deptId);
            deptData.splice(index, 1);
            this.setState({
                departments: deptData
            })
        }else{
            deptData.push(deptId)
            this.setState({
                departments: deptData
            })
        }
    }

    toggleWorkerSalary = (value) => {
        this.setState({ 
            workerPaymentType: value,
        })

        if(value === 'perpiece');
            this.setState({ 
                checkPerPieceSalary: true,
                checkPerHourSalary: false,  
                checkPerMonthSalary: false
            })

        if(value === 'perhour')
            this.setState({ 
                checkPerHourSalary:  true,
                checkPerPieceSalary: false,  
                checkPerMonthSalary: false
            })

        if(value === 'monthly')
            this.setState({ 
                checkPerMonthSalary: true,
                checkPerHourSalary:  false,
                checkPerPieceSalary: false,  
            })
    }

    createNewSubUser = () => {

        let userPermissionData = [...this.state.userPermissionData]

        var updatedPermission = userPermissionData.filter(function (el) {
            return el.read === true || el.create === true || el.update === true || el.delete === true;
        });

        let { email,password,name, phone, designation, departments, workerPaymentType, workerPaymentValue } = this.state;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(email === "" || password === "" || name === "" || designation === ""){
            this.setState({
                error: true,
                errormessage: "Please fill all fields",
            })
        }else if(!re.test(String(email).toLowerCase())){
            this.setState({
                error: true,
                errormessage: "Please enter valid email address",
            })
        }else if(password.length < 6){
            this.setState({
                error: true,
                errormessage: "Password length must be greater than 5 character",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateSubUser(name, email, phone, password, designation, JSON.stringify(updatedPermission), JSON.stringify(departments),  workerPaymentType, workerPaymentValue )
            .then(response => {
                this.setState({
                    isLoading: false,
                    success: true,
                    successmessage: response.message
                },()=>{
                    localStorage.setItem("createNewSubUser", true)
                    localStorage.setItem("subuserId",response.data._id)
                    localStorage.setItem("subuserEmail",response.data.email)
                    this.props.history.push("/updatesubuser")
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    onKeyPressed(e){
        this.setState({ showPassword: true })
    }

    onKeyReleased(e){
        this.setState({ showPassword: false })
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isHR={true} />

                <div className="main-container">
        
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create sub user</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="tableContent inner-page-table">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createNewSubUser()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Name </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ name: e.target.value })}></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Designation </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ designation: e.target.value })}></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Phone </div>
                                    <div className="value input__type_number"><input type="text" value={this.state.phone} className="fieldheightdecr" onChange={(e)=>this.setState({ phone: e.target.value })}></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Email </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ email: e.target.value })}></input></div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Password</div>
                                    <div className="value position-relative">
                                        <input type={this.state.showPassword ? "text" : "password"} className="fieldheightdecr" onChange={(e)=>this.setState({ password: e.target.value })}></input>
                                        <div
                                            tabIndex="0"
                                            className="password-eye-user-setting" 
                                            onClick={()=>{ this.setState({ showPassword: !this.state.showPassword})}}
                                        >
                                        {
                                            this.state.showPassword ?
                                                <Ai.AiFillEyeInvisible />
                                            :
                                                <Ai.AiFillEye/>
                                        }
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Copy user's rights</div>
                                    <div className="value user-rights-select">
                                        <Select2
                                            data={this.state.userList}
                                            value={this.state.selectedUser}
                                            options={{placeholder: 'Select user'}}
                                            onSelect={(e)=> this.setState({ selectedUser: e.target.value },()=>{ this.getSingleUserDetail(e.target.value) })}
                                        />

                                        {/* <select 
                                            onChange={(e)=>this.getSingleUserDetail(e.target.value)}
                                        >
                                            <option value="">Select user</option>
                                            {
                                                this.state.userList.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.email}</option>
                                                ))
                                            }
                                        </select> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            <div className="permission__div">
                                <div className="float-left width10 text-right permission_resources_txt">Resources</div>
                                <div className="float-left width80">
                                    <div className="tableContent" style={{ overflowX : "auto" }}>
                                        <table>
                                            <thead>
                                                <tr className="no-bg">
                                                    <th className="tableHeader">Resource</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Read</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Create</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Update</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.userPermissionData.map((data, i) => (
                                                    ((data.hide === undefined || data.hide === false || data.hide === "false") && (data.isPersonalTab === undefined || (data.isPersonalTab === true && data.accountId === this.state.userAccountId))) &&
                                                    <tr key={"user_permissions__"+i}>
                                                        <td>{data.name}</td>
                                                        <td className="text-center">{data.read === undefined ? "" : <input type="checkbox" checked={data.read} onChange={()=>this.updatePermission('read', data.read, i)} /> }</td>
                                                        <td className="text-center">{data.create === undefined ? "" : <input type="checkbox" checked={data.read ? data.create : false} disabled={data.read ? false : true} onChange={()=>this.updatePermission('create', data.create, i)} /> }</td>
                                                        <td className="text-center">{data.update === undefined ? "" : <input type="checkbox" checked={data.read ? data.update : false} disabled={data.read ? false : true} onChange={()=>this.updatePermission('update', data.update, i)} /> }</td>
                                                        <td className="text-center">{data.delete === undefined ? "" : <input type="checkbox" checked={data.read ? data.delete : false} disabled={data.read ? false : true} onChange={()=>this.updatePermission('delete', data.delete, i)} /> }</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div>
                                <div className="add_partside mt-3">
                                    <div className="add_inner">
                                        <div className="title">Departments </div>
                                        <div className="value">
                                            
                                            {
                                                this.state.departmentData !== null &&
                                                this.state.departmentData.map((data, i) => (
                                                    <div key={data._id}>
                                                        <div className="float-left">
                                                            <input 
                                                                type="checkbox" 
                                                                className="__input_checkbox"
                                                                value={data._id}
                                                                onChange={()=>this.toggleDepartment(i)}
                                                            ></input>
                                                        </div>
                                                        <div className="float-left createdText border-0 ml-1">#<span className="text-uppercase">{data.number}</span> <span className="text-capitalize">{data.name}</span></div>
                                                        <div className="clear"></div>
                                                    </div> 
                                                ))
                                            }
                        
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div>
                                <div className="add_partside mt-3">
                                    <div className="add_inner">
                                        <div className="title">Salary </div>
                                        <div className="value">
                                            
                            
                                            <div>
                                                <div className="float-left">
                                                    <input 
                                                        type="radio" 
                                                        className="__input_checkbox"
                                                        name="user_payment"
                                                        checked={this.state.checkPerHourSalary}
                                                        onChange={()=>this.toggleWorkerSalary('perhour')}
                                                    ></input>
                                                </div>
                                                <div className="float-left createdText border-0 ml-1">Per hour</div>
                                                <div className="clear"></div>
                                            </div> 
                                            <div>
                                                <div className="float-left">
                                                    <input 
                                                        type="radio" 
                                                        className="__input_checkbox"
                                                        name="user_payment"
                                                        checked={this.state.checkPerPieceSalary}
                                                        onChange={()=>this.toggleWorkerSalary('perpiece')}
                                                    ></input>
                                                </div>
                                                <div className="float-left createdText border-0 ml-1">Per piece</div>
                                                <div className="clear"></div>
                                            </div> 
                                            <div>
                                                <div className="float-left">
                                                    <input 
                                                        type="radio" 
                                                        className="__input_checkbox"
                                                        name="user_payment"
                                                        checked={this.state.checkPerMonthSalary}
                                                        onChange={()=>this.toggleWorkerSalary('monthly')}
                                                    ></input>
                                                </div>
                                                <div className="float-left createdText border-0 ml-1">Monthly</div>
                                                <div className="clear"></div>
                                            </div> 
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title"></div>
                                        <div className="value">
                                            <input
                                                type="text"
                                                value={this.state.workerPaymentValue}
                                                onChange={(e)=>this.setState({ workerPaymentValue: parseInt(e.target.value) ? parseInt(e.target.value) : ""})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>


                        

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createNewSubUser()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateSubUser;