import React from 'react';

import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'
//import './../../../../style.css'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateWorkstationNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            note: "",
            workstationId: "",
            workstationNum: "",
            workstationName: "",
           
        }
      
    }

    componentDidMount(){
        let id = this.props.location.state.workstationId
        let num = this.props.location.state.workstationNumber
        let name = this.props.location.state.workstationName

        this.setState({
            workstationId: id,
            workstationNum: num,
            workstationName: name,
        })
    }

    createNotes = () => {
        let { workstationId, note } = this.state
        if(note === ""){
            this.setState({
                errormessage: "Please add note",
                error: true,
                success: false,
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateWorkstationNote(workstationId, note)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editwsnote",
                        state: { workstationId: this.state.workstationId, workstationNumber: this.state.workstationNum, workstationName: this.state.workstationName, workstationNote: note, workstationNoteId: response.data, newWorkstationNoteEntry: true }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstations={true} />
                
                    <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a note</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Workstation </div>
                                <div className="value companyname"><span className="text-uppercase">{this.state.workstationNum}</span> <span className="text-capitalize">{this.state.workstationName}</span></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
            </div>
        )
    }
}