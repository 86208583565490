import React, { Component } from 'react';
import toast from 'react-simple-toasts';

import Loading from './../../components/Loading'
import * as APIManager from './../../APIManager/APIManager'
import { 
    validateEmail,
    validateBlank
} from './../../constants/Validate'

class ForgotPassword extends Component{

    constructor(props){ 
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            email: "",
        }
        this.handleEmail = this.handleEmail.bind(this)
    }

    componentDidMount(){
        
    }

    handleEmail(e) {
        this.setState({ email: e.target.value})
    }

    isValidate() {
        const { email } = this.state;
        if(!validateBlank(email)){
            this.setState({
                error: true,
                errormessage: "Please enter email address"
            })
            return false;
        }else if(!validateEmail(email)){
            this.setState({
                error: true,
                errormessage: "Please enter valid email address"
            })
            return false;
        }else{
            return true;
        }
    }

    handleForgotRequest() {
        const {email } = this.state;
        if(this.isValidate()){
            this.setState({ isLoading: true, error: false })
            APIManager.callForgotPassword(email)
            .then(response => {
                this.setState({ isLoading: false })
                toast("Reset password link sent successfully please check your inbox")
                this.goBack()
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }


    render(){
        return(
            <div className="form-bg-primary authBg page">
                <div className="loging-list">
                    <div className="container">
                        <div className="loging-menu-card">
                            <div className="form-heading">
                                <h1 className="form-title">Forgot Password</h1>
                                <p>If you have forgotten your password, simply enter your corresponding email address and we will send you a link to reset your password.</p>
                            </div>
                            {
                                this.state.error ?
                                <div className="errornotification">
                                    <div className="notiflink registernotiflink">{this.state.errormessage}</div>
                                </div>
                                :
                                <br />
                            }
                            <div className="form">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" onChange={this.handleEmail} />
                                </div>
                                <button type="submit" className="btn sign-btn w-100" onClick={this.handleForgotRequest.bind(this)}>Send</button>
                            </div>
                            <br />
                            <div onClick={()=>this.goBack()} className="gobackbtn pointer">Go back</div>
                        </div>
                    </div>
                </div>
                { this.state.isLoading && <Loading />}
            </div>
        )
    }
}

export default ForgotPassword;
