import React, { Component } from 'react';
import * as Ti from 'react-icons/ti'
import * as APIManager from './../../../APIManager/APIManager'

class EditRejectList extends Component {

    constructor(props){
        super(props)
        this.state = {
            data: this.props.data,
            storageLocation: "",
            stockLot: "",
        }
    }

    componentDidMount(){
        this.getSingleStockLot(this.state.data.stockLot)
        this.getSingleStorageLocation(this.state.data.storageLocation)
    }

    getSingleStockLot = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStockLot(id)
        .then(response => {
            this.setState({
                stockLot: response.number,
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getSingleStorageLocation = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStorageLocation(id)
        .then(response => {
            this.setState({
                storageLocation: response.name,
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    render() {
        let {
            data,
            uom,
            releaseStock
        } = this.props
        return (
            <tr>
                <td>{this.state.stockLot}</td>
                <td>{data.status}</td>
                <td>{data.date}</td>
                <td>{this.state.storageLocation}</td>
                <td>{data.costPerUnit}</td>
                <td>{data.quantity} {uom}</td>
                <td>{data.booked} {uom}</td>
                <td className="text-center" onClick={()=>releaseStock(data.id)}><Ti.TiArrowBack /></td>
            </tr>
        );
    }
}

export default EditRejectList;