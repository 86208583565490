
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import { convertDateStringMMDDtoDDMM } from "../../../constants/DateFormatter";

export const exportCSV = (responseData) => {
    const title = "Write offs";

    const headers = [
        "Number", 
        "Part No", 
        "Part Description",
        "Group Number", 
        "Group Name", 
        "Quantity", 
        "cost",
        "Created",
        "Status", 
    ];

    const data = responseData.map((data, i)=> [
        data.number.toUpperCase(), 
        data.partNo, 
        data.partDescription, 
        data.groupNumber.toUpperCase(),
        data.groupName,
        data.quantity,
        data.cost, 
        convertDateStringMMDDtoDDMM(data.created),
        data.status
    ]);


    const options = { 
        filename: 'Write offs',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers,
    };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);
}



export const exportPDF = (responseData) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
   
    const title = "Write offs";

    const headers = [[
        "Number", 
        "Part No", 
        "Part Description",
        "Group Number", 
        "Group Name", 
        "Quantity", 
        "cost",
        "Created",
        "Status", 
    ]];

    const data = responseData.map((data)=> [
        data.number.toUpperCase(), 
        data.partNo, 
        data.partDescription, 
        data.groupNumber.toUpperCase(),
        data.groupName,
        data.quantity,
        data.cost, 
        convertDateStringMMDDtoDDMM(data.created),
        data.status
    ]);

    let content = {
        startY: 50,
        head: headers,
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("write-offs.pdf")
}
