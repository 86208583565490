import React from 'react';
import { Header, CRMHeader } from './../../../components'
import Loading from '../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateCustomerOrderNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            id: this.props.location.state.orderId,
            customer_order_number: this.props.location.state.orderNumber,

            note: "",
            isImp: false,
        }
    }

    componentDidMount(){
    }

    createNotes = () => {
        let { 
            note,
            id,
            isImp,
            customer_order_number 
        } = this.state


        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCustomerOrderNote( id, note, isImp )
            .then(response => {
                localStorage.setItem("createNewCONote", true)
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editconote",
                        state: { 
                            id: response, 
                            note: note, 
                            orderId: this.state.id,
                            orderNumber: customer_order_number,
                            is_important_co_note: isImp 
                        }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomerOrder={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Add a new note</div>
                        </div>
                    </div>
                </div>
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Important</div>
                                <div className="value">
                                    <input 
                                        type="checkbox" 
                                        className="__input_checkbox"
                                        value={this.state.isImp}
                                        onChange={()=>this.setState({ isImp: !this.state.isImp })}
                                    ></input>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        onChange={(e)=>this.setState({ note: e.target.value})} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}