import React, { Component } from 'react';
import { PDFViewer, Page, Text, View, Image, Font, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import renderHTML from 'react-render-html';

import Denmark from './../../../../assets/Fonts/DENMARK.ttf'
import CaustenExtraBold from './../../../../assets/Fonts/CaustenExtraBold.ttf'
import CalibriBold from './../../../../assets/Fonts/CalibriBold.ttf'
import CalibriRegular from './../../../../assets/Fonts/CalibriRegular.ttf'

import * as APIManager from './../../../../APIManager/APIManager'

import { BrowserView, MobileView } from 'react-device-detect';

import { AKAM } from './../../../../assets/Images'

// https://react-pdf.org/
// https://react-pdf.org/repl
// https://react-pdf.org/repl?example=page-numbers
// https://www.npmjs.com/package/react-pdf-html

Font.register({
    family: 'Denmark',
    src: Denmark,    
});


Font.register({
    family: 'CaustenExtraBold',
    src: CaustenExtraBold
})

Font.register({
    family: 'CalibriBold',
    src: CalibriBold
})

Font.register({
    family: 'CalibriRegular',
    src: CalibriRegular
})

class generatePDF extends Component {

    constructor(props){
        super(props)
        this.state = {

            isLoading: false,
            error: false,
            errormessage: "loading...", 

            buyer_company: "",
            buyer_address: "",
            buyer_phoneNo: "",
            buyer_email: "",
            buyer_panNo: "",
            buyer_GSTNo: "",
            account_name: "",
            account_phone: "",
            account_email: "",
            contactPerson_Name: "",
            contactPerson_PhoneNo: "",
            contactPerson_Email: "",
            deliveryDate: "",
            rfqNo: "",
            rfqDate: "",
            poDate: "",
            poNo: "",
            reference: "",
            status: "",
            quotationNumber: "",
            statusDateText: "",
            statusValidityText: "",
            statusDateValue: "",
            statusValidityValue: "",
            terms: [],
            taxApplicable: [],
            taxInfo: [],
            itemData: [],
            itemValue: [],
            grandBasicTotal: "",
            grandTotal: "",
            rupeeSymbol: "INR ",

            printTerms: [],
            printItemDetails: [],
            printBasicTotal: 0,
            printTaxValues: [],
            revisionNumber: "",
            revisionId: "",

            isReadyToLoad: false,
            headerFont: '',
            footerColor: '',

            companyDetails: []
        }
    }

    componentDidMount(){
        let orderId = localStorage.getItem("customerOrderId")
        let revisionId = localStorage.getItem("revisionId")

        this.setHeaderFont()
        this.setState({
            revisionId: revisionId
        },()=>{
            this.getOrderQuotationDetails(orderId,revisionId)   
        })
       
    }

    setHeaderFont = () => {
        let userDetails = localStorage.getItem("userdata")
        let accId = JSON.parse(userDetails).account_id

        const ALFA = '791d2fa8-2f82-48d5-b14c-0e7197fe150f'
        const CHEMORO = '98376e27-4595-4a63-a042-5ac6b6a63ae0'

        if(accId === ALFA){
            this.setState({ headerFont: 'Denmark', footerColor: '#1d7387' },()=>{
                console.log('Font: Denmark')
            })
        }else if(accId === CHEMORO){
            this.setState({ headerFont: 'CaustenExtraBold', footerColor: '#1b315f' },()=>{
                console.log('Font: CaustenExtraBold')
            })
        }else{
            this.setState({ headerFont: 'CalibriBold', footerColor: '#000000' },()=>{
                console.log('Font: CalibriBold')
            })
        }
    }


    getOrderQuotationDetails = (id,revisionId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetOrderQuotationDetails(id,revisionId)
        .then(response => {
            this.setState({
                isLoading: false,
                buyer_company: response.buyer_company,

                buyer_panNo: response.buyer_panNo,
                buyer_GSTNo: response.buyer_GSTNo,
                buyer_address: response.buyer_address,
                buyer_phoneNo: response.buyer_phoneNo,
                buyer_email: response.buyer_email,

                account_name: response.account_name,
                account_phone: response.account_phone,
                account_email: response.account_email,

                contactPerson_Name: response.contactPerson_Name,
                contactPerson_PhoneNo: response.contactPerson_PhoneNo,
                contactPerson_Email: response.contactPerson_Email,

                quotationNumber: response.quotationNumber,

                rfqNo: response.rfqNo,
                rfqDate: response.rfqDate,

                poDate: response.poDate,
                poNo: response.poNo,
                revisionNumber: response.revisionNumber ? response.revisionNumber : "",
                reference: response.reference,

                status: localStorage.getItem("pdf_status_for_co"),
                statusDateText: response.statusDateText,
                statusValidityText: response.statusValidityText,
                statusDateValue: response.statusDateValue,
                statusValidityValue: this.setValidityDate(response.statusValidityValue, response.statusDateValue),
                deliveryDate: this.setDeliveryDate(response.deliveryDate),
                taxInfo: response.taxInfo,
                taxApplicable: response.taxApplicable,

                itemData: response.itemData,
                itemValue: response.itemValue,
                
                grandBasicTotal: this.formattedNumber(response.grandBasicTotal),
                grandTotal: this.formattedNumber(Math.round(response.grandTotal)),
                
                terms: response.terms !== undefined ? response.terms : [],

                companyDetails: response.companyDetails,

                rupeeSymbol: response.rupeeSymbol,
            },()=>{
                this.setItemDetails()
                this.setTermsDetail()
                this.printApplicableTax()
                this.setState({
                    isReadyToLoad: true,
                })
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Something went wrong, please check your login status and try again" 
            })
        });
    }

    setDeliveryDate = (date) => {
        if(date){
            let dt = new Date(date)
            let dd = dt.getDate();
            let mm = dt.getMonth() + 1;
            let yyyy = dt.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return dd + '-' + mm + '-' + yyyy;
        }else{
            return ""
        }
    }

    setValidityDate = (date, validityStart) => {
        
        if(date){
            let dt = new Date(date)
            let dd = dt.getDate();
            let mm = dt.getMonth() + 1;
            let yyyy = dt.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return dd + '-' + mm + '-' + yyyy;
        }else{
            let setDate = validityStart.split('-')
            let createNew = setDate[1]+'/'+setDate[0]+'/'+setDate[2]
            let newDate = new Date(createNew).setDate(new Date(createNew).getDate() + 30)
            let dt = new Date(newDate)

            let dd = dt.getDate();
            let mm = dt.getMonth() + 1;
            let yyyy = dt.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            return dd + '-' + mm + '-' + yyyy;
        }
    }


    printApplicableTax = () => {
        let {
            taxApplicable,
            taxInfo
        } = this.state;

        let dummyTaxArray = []

        for(let i = 0;i<taxApplicable.length;i++){
            dummyTaxArray.push(
                <View key={"tax_applicable"+i} style={styles.grand_total_content}><Text style={styles.grand_total_data}>{taxApplicable[i]}</Text><Text style={styles.grand_total_data}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.formattedNumber(taxInfo[i])}</Text></View>
            )
        }
        this.setState({
            printTaxValues: dummyTaxArray
        })
        
    }

    setItemDetails = () => {
        let {
            itemData,
            itemValue
        } = this.state;

        let itemLocalArray = [];
        let countBasicTotal = 0

        for(let i=0;i<itemData.length;i++){

            itemLocalArray.push(
                <View key={"item_data_"+i} style={styles.table_body}>
                    <View style={styles.table_body_content_one}>
                        <Text style={[styles.table_body_text,styles.table_body_1]}>{i+1}</Text>
                        <View style={[styles.table_body_text,styles.table_body_2]}>
                            <Text>Item Code: {itemData[i].itemCode !== "undefined" ? itemData[i].itemCode : ""}</Text>
                            <Text>HS Code: {itemData[i].hsCode !== "undefined" ? itemData[i].hsCode : ""}</Text>
                            <Text style={styles.bold_text}>{itemData[i].item}</Text>
                            <View style={styles.table_body_item_free_text}>
                                <Html style={{fontSize: 11}}>{this.createHTMLElement(itemData[i].productFreeText.replace(/<br>\\*/g,"</div><div>"))}</Html>
                            </View>
                        </View>
                        <Text style={[styles.table_body_text,styles.table_body_3]}>{itemData[i].quantity} {itemData[i].UoM}</Text>
                        {/* <Text style={[styles.table_body_text,styles.table_body_4]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.formattedNumber(itemData[i].pricePerUoM)}</Text>
                        <Text style={[styles.table_body_text,styles.table_body_5]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.formattedNumber(subTotal)}</Text> */}
                    </View>
                    
                </View>
            )
        }
        this.setState({
            printItemDetails: itemLocalArray
        })
    }

    setTermsDetail = () => {
        let { terms } = this.state;
        let termsArray = []
        for(let i = 0;i<terms.length; i++){
            let termsArrayInner = []
            termsArrayInner.push(<Text key={"quota_terms_"+i} style={styles.term_title}>{terms[i].terms}</Text>)
            for(let j=0;j<terms[i].options.length;j++){
                termsArrayInner.push(<Text key={"terms_option"+j}  style={styles.term_value}>- {terms[i].options[j]}</Text>)
            }
            termsArray.push(<View key={"terms_"+i} style={{ marginTop: 5, }}>{termsArrayInner}</View>)
        }
        this.setState({
            printTerms: termsArray
        })
    }

    // Convert number into comma value
    formattedNumber = (num) => {
        let number = parseFloat(num)
        if(number !== "" || number !== 0){
            return number.toFixed(2).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return number
        }
    } 

    createHTMLElement = (data) => {
        const html = `
            <html>
                <body>
                    <style>
                        body{
                            margin: 0; 
                            font-family: CalibriRegular;
                        } 
                        p{ 
                            margin: 0;
                        }
                    </style>
                    <div>${data}</div>
                </body>
            </html>`
        return html;
    }

    render() {
        return(
            <>
            <BrowserView>
            {
                this.state.isReadyToLoad ?
                <PDFViewer style={styles.container}>
                    <Document>
                        <Page style={styles.body}>
                            {/* Fixed Header Start */}
                            <View style={styles.header} fixed>
                                <View style={styles.headerLeft}>
                                    <View style={styles.headerLeftContent}>
                                        <Text style={[styles.company_name,{fontFamily: this.state.headerFont}]}>{this.state.companyDetails.companyName}</Text>
                                        <Text style={styles.company_addr}>{this.state.companyDetails.address_zone} {this.state.companyDetails.address_city}</Text>
                                    </View>
                                </View>
                                <View style={styles.headerRight}>
                                    <Image src={AKAM} style={styles.headerlogo} /> 
                                    {
                                        this.state.companyDetails.companyLogo !== "" &&
                                        <Image source={this.state.companyDetails.companyLogo} crossorigin="anonymous" cache={false} style={styles.headerlogo} /> 
                                    }
                                </View>
                            </View>
                            {/* Fixed Header End */}

                            {/* Main Content Start */}
                            <View style={styles.maincontainer}>
                                {/* Main Content Head */}
                                <View style={styles.__ct_head}>
                                    <View style={styles.__ct_head_left}>
                                        <Text style={[styles.__ct_head_text,styles.bold_text]}>Buyer</Text>
                                        <Text style={[styles.__ct_head_text,styles.bold_text]}>{this.state.buyer_company}</Text>
                                        <Text style={[styles.__ct_head_text,styles.textCapitalize]}>{this.state.buyer_address}</Text>
                                        <Text style={styles.__ct_head_text}>{this.state.buyer_phoneNo}</Text>
                                        <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.buyer_email}</Text>
                                        <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_panNo ? 'PAN No.: ' +this.state.buyer_panNo.toUpperCase() : ''}</Text>
                                        <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_GSTNo ? 'GST No.: ' +this.state.buyer_GSTNo.toUpperCase() : ''}</Text>
                                    </View>
                                    <View style={styles.__ct_head_right}>
                                        {/* <View style={styles.__ct_head_contact_person}>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>Contact Person</Text>
                                            <Text style={[styles.__ct_head_text,styles.bold_text,styles.textCapitalize]}>{this.state.contactPerson_Name}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.contactPerson_Email}</Text>
                                            <Text style={styles.__ct_head_text}>{this.state.contactPerson_PhoneNo}</Text>
                                        </View> */}
                                        <View>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>From Account</Text>
                                            <Text style={[styles.__ct_head_text,styles.bold_text,styles.textCapitalize]}>{this.state.account_name}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.account_email}</Text>
                                            <Text style={styles.__ct_head_text}>{this.state.account_phone}</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                {/* Main content record details */}
                                <View style={styles.__ct_detail}>
                                    <View style={styles.__ct_detail_left}>
                                        <Text style={[styles.bold_text,{ textTransform: "capitalize"}]}>{this.state.status} {this.state.quotationNumber.toUpperCase()}</Text>
                                        {
                                            (this.state.revisionNumber !== "R0000" && this.state.revisionId !== "") &&
                                            <Text style={{ textTransform: "capitalize", fontSize: 12}}>Revision {this.state.revisionNumber}</Text>
                                        }
                                    </View>
                                    <View style={styles.__ct_detail_right}>
                                        <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>{this.state.statusDateText}: </Text><Text>{this.state.statusDateValue}</Text></View>
                                        <View style={{flexDirection: "row"}}><Text style={{ width: 100}}>{this.state.statusValidityText}: </Text><Text>{this.state.statusValidityValue}</Text></View>
                                    </View>
                                </View>

                                {/* Record Reference detail */}
                                
                                <View style={styles.__ct_record_ref_detail}>
                                    <View style={{ flex: 0.65 }}>
                                        <View style={{flexDirection: "row", marginBottom: 5}}>
                                            <Text style={{ width: 140}}>Reference: </Text><Text style={styles.textCapitalize}>{this.state.reference}</Text>
                                        </View>
                                        {
                                            this.state.status !== "Quotation" ?
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>PO No.: </Text><Text>{this.state.poNo}</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>PO Date: </Text><Text>{this.state.poDate}</Text></View>
                                            </View>
                                            :
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>RFQ No.: </Text><Text>{this.state.rfqNo}</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>RFQ Date: </Text><Text>{this.state.rfqDate}</Text></View>
                                            </View>
                                        }
                                    </View>
                                    <View style={[styles.delivery__date,{ flex: 0.35}]}>
                                        {
                                             this.state.status !== "Quotation" &&
                                             <Text style={[styles.bold_text,styles.delivery__date__text]}>Delivery date: {this.state.deliveryDate}</Text>
                                        }
                                    </View>
                                </View>

                                

                                {/* Item Table */}
                                <View style={styles.table_header}>
                                    <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2]}>Item</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_3]}>Quantity</Text>
                                    {/* <Text style={[styles.table_header_text,styles.table_header_4]}>Price/Unit</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_5]}>Price/Item</Text> */}
                                </View>

                                {this.state.printItemDetails}

                                {/* <View style={styles.grand_total_section}>
                                    <View style={styles.grand_total_content}><Text style={styles.grand_total_data}>Basic Total</Text><Text style={styles.grand_total_data}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text> {this.state.printBasicTotal}</Text></View>
                                    {this.state.printTaxValues}
                                    <View style={[styles.grand_total_content,styles.bold_text]}><Text style={[styles.grand_total_data,styles.text_grand_total]}>Grand Total</Text><Text style={[styles.grand_total_data,styles.text_grand_total]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.state.grandTotal}</Text></View>
                                </View> */}

                                {/* Terms & Conditions */}
                                {
                                    this.state.printTerms.length > 0 &&
                                    <View style={{ marginTop: 10 }}>
                                        <Text style={styles.terms_header}>{'Terms & Conditions'}</Text>
                                        { this.state.printTerms }
                                    </View>
                                }

                                 
                                
                            </View>
                            {/* Main Content End */}


                            <View style={styles.footer} fixed>
                                <View style={styles.footerTopSection}>
                                    <Text style={styles.footerQuotationNumber}>{this.state.status} {this.state.quotationNumber.toUpperCase()}</Text>
                                    <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                        `Page ${pageNumber} of ${totalPages}`
                                    )} />
                                </View> 
                                <View>
                                    <Text style={[styles.footer_company_name,{fontFamily: this.state.headerFont, color: this.state.footerColor}]}>{this.state.companyDetails.companyName}</Text>
                                    <View style={styles.footer_details}>
                                        <View style={styles.footer_detail_data1}>
                                            <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line2}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_state}, {this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data2}>
                                            <Text style={styles.footer_detail_text}>Regd. No.: {this.state.companyDetails.regdNo}</Text>
                                            <Text style={styles.footer_detail_text}>GSTIN: {this.state.companyDetails.gstin}</Text>
                                            <Text style={styles.footer_detail_text}>ECC: {this.state.companyDetails.ecc}</Text>
                                            <Text style={styles.footer_detail_text}>PAN: {this.state.companyDetails.panNo}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data3}>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.bank_name}</Text>
                                            <Text style={styles.footer_detail_text}>A/c No.: {this.state.companyDetails.bank_accno}</Text>
                                            <Text style={styles.footer_detail_text}>Branch: {this.state.companyDetails.bank_branch}</Text>
                                            <Text style={styles.footer_detail_text}>RTGS/NEFT: {this.state.companyDetails.bank_rtgsneft}</Text>
                                            <Text style={styles.footer_detail_text}>Swift Code: {this.state.companyDetails.bank_swiftcode}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data4}>
                                            
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.telephone ? 't: +91 ' +this.state.companyDetails.telephone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.phone ? 'm: +91 ' +this.state.companyDetails.phone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                e: {this.state.companyDetails.email}
                                            </Text>
                                                                                
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website ? 'w | ' +this.state.companyDetails.website : '' }
                                            </Text>

                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website2 ? 'w | ' +this.state.companyDetails.website2 : '' }
                                            </Text>
                            
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
                :
                <div className="__pdf_error_message">
                    {this.state.errormessage}
                </div>
            }
            </BrowserView>
            <MobileView>
                <div className="__pdf_error_message">
                    {/* This browser doesn't support PDF view at the moment. */}
                    <PDFDownloadLink document={
                        <Document>
                        <Page style={styles.body}>
                            {/* Fixed Header Start */}
                            <View style={styles.header} fixed>
                                <View style={styles.headerLeft}>
                                    <View style={styles.headerLeftContent}>
                                        <Text style={[styles.company_name,{fontFamily: this.state.headerFont}]}>{this.state.companyDetails.companyName}</Text>
                                        <Text style={styles.company_addr}>{this.state.companyDetails.address_zone} {this.state.companyDetails.address_city}</Text>
                                    </View>
                                </View>
                                <View style={styles.headerRight}>
                                    <Image src={AKAM} style={styles.headerlogo} /> 
                                    {
                                        this.state.companyDetails.companyLogo !== "" &&
                                        <Image source={this.state.companyDetails.companyLogo} crossorigin="anonymous" cache={false} style={styles.headerlogo} /> 
                                    }
                                </View>
                            </View>
                            {/* Fixed Header End */}

                            {/* Main Content Start */}
                            <View style={styles.maincontainer}>
                                {/* Main Content Head */}
                                <View style={styles.__ct_head}>
                                    <View style={styles.__ct_head_left}>
                                        <Text style={[styles.__ct_head_text,styles.bold_text]}>Buyer</Text>
                                        <Text style={[styles.__ct_head_text,styles.bold_text]}>{this.state.buyer_company}</Text>
                                        <Text style={[styles.__ct_head_text,styles.textCapitalize]}>{this.state.buyer_address}</Text>
                                        <Text style={styles.__ct_head_text}>{this.state.buyer_phoneNo}</Text>
                                        <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.buyer_email}</Text>
                                        <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_panNo ? 'PAN No.: ' +this.state.buyer_panNo.toUpperCase() : ''}</Text>
                                        <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_GSTNo ? 'GST No.: ' +this.state.buyer_GSTNo.toUpperCase() : ''}</Text>
                                    </View>
                                    <View style={styles.__ct_head_right}>
                                        {/* <View style={styles.__ct_head_contact_person}>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>Contact Person</Text>
                                            <Text style={[styles.__ct_head_text,styles.bold_text,styles.textCapitalize]}>{this.state.contactPerson_Name}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.contactPerson_Email}</Text>
                                            <Text style={styles.__ct_head_text}>{this.state.contactPerson_PhoneNo}</Text>
                                        </View> */}
                                        <View>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>From Account</Text>
                                            <Text style={[styles.__ct_head_text,styles.bold_text,styles.textCapitalize]}>{this.state.account_name}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.account_email}</Text>
                                            <Text style={styles.__ct_head_text}>{this.state.account_phone}</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                {/* Main content record details */}
                                <View style={styles.__ct_detail}>
                                    <View style={styles.__ct_detail_left}>
                                        <Text style={[styles.bold_text,{ textTransform: "capitalize"}]}>{this.state.status} {this.state.quotationNumber.toUpperCase()}</Text>
                                    </View>
                                    <View style={styles.__ct_detail_right}>
                                        <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>{this.state.statusDateText}: </Text><Text>{this.state.statusDateValue}</Text></View>
                                        <View style={{flexDirection: "row"}}><Text style={{ width: 100}}>{this.state.statusValidityText}: </Text><Text>{this.state.statusValidityValue}</Text></View>
                                    </View>
                                </View>

                                {/* Record Reference detail */}
                                <View style={styles.__ct_record_ref_detail}>
                                    <View style={{ flex: 0.65 }}>
                                        <View style={{flexDirection: "row", marginBottom: 5}}>
                                            <Text style={{ width: 140}}>Reference: </Text><Text style={styles.textCapitalize}>{this.state.reference}</Text>
                                        </View>
                                        {
                                            this.state.status !== "Quotation" ?
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>PO No.: </Text><Text>{this.state.poNo}</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>PO Date: </Text><Text>{this.state.poDate}</Text></View>
                                            </View>
                                            :
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>RFQ No.: </Text><Text>{this.state.rfqNo}</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 140}}>RFQ Date: </Text><Text>{this.state.rfqDate}</Text></View>
                                            </View>
                                        }
                                    </View>
                                    <View style={[styles.delivery__date,{ flex: 0.35}]}>
                                        {
                                             this.state.status !== "Quotation" &&
                                             <Text style={[styles.bold_text,styles.delivery__date__text]}>Delivery date: {this.state.deliveryDate}</Text>
                                        }
                                    </View>
                                </View>

                               

                                {/* Item Table */}
                                <View style={styles.table_header}>
                                    <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2]}>Item</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_3]}>Quantity</Text>
                                    {/* <Text style={[styles.table_header_text,styles.table_header_4]}>Price Per UoM</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_5]}>Subtotal</Text> */}
                                </View>

                                {this.state.printItemDetails}

                                {/* <View style={styles.grand_total_section}>
                                    <View style={styles.grand_total_content}><Text style={styles.grand_total_data}>Basic Total</Text><Text style={styles.grand_total_data}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text> {this.state.printBasicTotal}</Text></View>
                                    {this.state.printTaxValues}
                                    <View style={[styles.grand_total_content,styles.bold_text]}><Text style={[styles.grand_total_data,styles.text_grand_total]}>Grand Total</Text><Text style={[styles.grand_total_data,styles.text_grand_total]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.state.grandTotal}</Text></View>
                                </View> */}

                                {/* Terms & Conditions */}
                                {
                                    this.state.printTerms.length > 0 &&
                                    <View style={{ marginTop: 10 }}>
                                        <Text style={styles.terms_header}>{'Terms & Conditions'}</Text>
                                        { this.state.printTerms }
                                    </View>
                                }
                                
                                

                            </View>
                            {/* Main Content End */}


                            <View style={styles.footer} fixed>
                                <View style={styles.footerTopSection}>
                                    <Text style={styles.footerQuotationNumber}>{this.state.status} {this.state.quotationNumber.toUpperCase()}</Text>
                                    <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                        `Page ${pageNumber} of ${totalPages}`
                                    )} />
                                </View> 
                                <View>
                                    <Text style={[styles.footer_company_name,{fontFamily: this.state.headerFont, color: this.state.footerColor}]}>{this.state.companyDetails.companyName}</Text>
                                    <View style={styles.footer_details}>
                                        <View style={styles.footer_detail_data1}>
                                            <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line2}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_state}, {this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data2}>
                                            <Text style={styles.footer_detail_text}>Regd. No.: {this.state.companyDetails.regdNo}</Text>
                                            <Text style={styles.footer_detail_text}>GSTIN: {this.state.companyDetails.gstin}</Text>
                                            <Text style={styles.footer_detail_text}>ECC: {this.state.companyDetails.ecc}</Text>
                                            <Text style={styles.footer_detail_text}>PAN: {this.state.companyDetails.panNo}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data3}>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.bank_name}</Text>
                                            <Text style={styles.footer_detail_text}>A/c No.: {this.state.companyDetails.bank_accno}</Text>
                                            <Text style={styles.footer_detail_text}>Branch: {this.state.companyDetails.bank_branch}</Text>
                                            <Text style={styles.footer_detail_text}>RTGS/NEFT: {this.state.companyDetails.bank_rtgsneft}</Text>
                                            <Text style={styles.footer_detail_text}>Swift Code: {this.state.companyDetails.bank_swiftcode}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data4}>
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.telephone ? 't: +91 ' +this.state.companyDetails.telephone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.phone ? 'm: +91 ' +this.state.companyDetails.phone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                e: {this.state.companyDetails.email}
                                            </Text>
                                                                                
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website ? 'w | ' +this.state.companyDetails.website : '' }
                                            </Text>

                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website2 ? 'w | ' +this.state.companyDetails.website2 : '' }
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                    </Document>
                    } fileName="Customer order.pdf">
                    {({ _blob, _url, loading, _error }) => (loading ? 'Loading...' : 'View PDF!')}
                    </PDFDownloadLink>
                </div>
            </MobileView>
        </>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: window.innerHeight,
    },
    body: {
      paddingTop: 10,
      paddingBottom: 130,
      paddingHorizontal: 35,
      fontSize: 12,
      fontFamily: 'CalibriRegular',
    },
    textCapitalize: {
        textTransform: 'capitalize'
    },
    textLowercase: {
        textTransform: 'lowercase'
    },
    __ct_head: {
        flexDirection: "row",
        justifyContent: "space-around",
        fontSize: 11,
    },
    __ct_head_left: {
        flexWrap: 'wrap',
        flex: 0.65,
        marginRight: 10,
    },
    __ct_head_right: {
        flexWrap: 'wrap',
        flex: 0.35,  
    },
    __ct_head_text: {
        marginBottom: 3,
    },
    __ct_head_text_bold: {
       fontSize: 12
    },
    __ct_head_contact_person: {
        paddingBottom: 10,
    },
    bold_text: {
        fontFamily: 'CalibriBold'
    },
    delivery__date: {
        alignItems: "flex-start",
        justifyContent: "center"
    },
    delivery__date__text: {
        fontSize: 14
    },
    __ct_detail: {
        flexDirection: "row",
        position: 'relative',
        borderBottomWidth: 2,
        paddingTop: 10,
        paddingBottom: 5,
        justifyContent: "space-between"
    },
    __ct_detail_left: {
        justifyContent: 'flex-end',
        flex: 0.65,
        fontSize: 15,
        marginRight: 10,
    },
    __ct_detail_right: {
        flex: 0.35,
        fontSize: 11,
    },
    __ct_record_ref_detail: {
        fontSize: 11,
        marginTop: 5,
        borderBottomWidth: 1,
        flexDirection: "row"
    },
    table_header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        marginBottom: 10,
        paddingBottom: 2,
        paddingTop: 5,
    }, 
    table_header_text: {
        fontSize: 11,
        fontFamily: 'CalibriBold'
    },
    rsSymbolFont: {
        fontFamily: 'CalibriBold'
    },
    table_header_1: {
        flex: 0.10,
    },
    table_header_2: {
        flex: 0.70,
        textAlign: "left",
    },
    table_header_3: {
        flex: 0.20,
        textAlign: "center",
    },
    
    table_body: {
        borderBottomWidth: 1,
        marginBottom: 10,
    },
    table_body_content_one: {
        flexDirection: "row"
    },
    table_body_text: {
        fontSize: 11,
    },
    table_body_1: {
        flex: 0.10,
    },
    table_body_2: {
        flex: 0.70,
    },
    table_body_3: {
        flex: 0.20,
        textAlign: "center",
    },
   
    table_body_content_two: {
        flexDirection: "row",
        marginTop: 5,
    },
    table_body_item_free_text: {
        marginTop: 5,
    },
    table_body_item_calculations: {
        flex: 0.4,
    },
    calculation_content: {
        flexDirection: "row",
        paddingVertical: 4,
        borderTopWidth: 1,
    },
    calculation_data: {
        flex: 0.5,
        textAlign: "right",
    },

    grand_total_section: {
        marginTop: 5,
    },
    grand_total_content: {
        flexDirection: "row",
        justifyContent: "flex-end",
        flex: 0.4,
    },
    grand_total_data: {
        flex: 0.2,
        textAlign: "right",
        marginBottom: 5,
    },
    text_grand_total: {
        borderTopWidth: 1,
        paddingTop: 5,
    },

    terms_header: {
        fontSize: 12,
        fontFamily: 'CalibriBold'
    },
    term_title: {
        fontSize: 12,
        fontFamily: 'CalibriBold'
    },
    term_value: {
        fontSize: 11
    },







    header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        paddingBottom: 5,
        marginBottom: 5,
    },
    headerLeft: {
       flex: 0.5,
       position: "relative",
    },
    headerLeftContent: {
        position: "absolute",
        bottom: -5,
    },
    headerRight: {
        flexDirection: "row",
        justifyContent: "space-between",
        flex: 0.5,
    },
    company_name: {
        fontSize: 13,
        textTransform: "uppercase"
    },  
    company_addr: {
        fontSize: 10,
    },

    headerlogo: {
        width: 80,
        height: "auto",  
    },
    footer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 20,
        backgroundColor: "#ffffff"
    },
    footerQuotationNumber: {
        fontSize: 11,
        textAlign: 'left',
        textTransform: "capitalize"
    },
    footerPageNumber: {
        fontSize: 11,
        right: 0,
        top: 4,
        position: 'absolute',
        textAlign: 'right'
    },
    footerTopSection: {
        position: 'relative',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginHorizontal: 35,
        paddingTop: 4,
    },
    footer_company_name: {
        fontSize: 11,
        fontWeight: "500",
        marginTop: 5,
        marginBottom: 5,
        marginHorizontal: 35,
        textTransform: "uppercase"
    },
    footer_details: {
        flexDirection: "row",
        marginHorizontal: 35,
    },
    footer_detail_text: {
        fontSize: 9,
    },
    footer_detail_data1: {
        flex: 0.23
    },
    footer_detail_data2: {
        flex: 0.30
    },
    footer_detail_data3: {
        flex: 0.26
    },
    footer_detail_data4: {
        flex: 0.20
    },
});

export default generatePDF;