import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditStockLotNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",   

            stockLotId: "",
            stockLotNum: "",
            note: "",
            noteId: "",
            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let id = localStorage.getItem("stockLotId")
        let number = localStorage.getItem("stockLotNumber")
        let noteId = localStorage.getItem("stockLotNoteId")
        let note = localStorage.getItem("stockLotNote")

        this.setState({
            stockLotId: id,
            stockLotNum: number,
            note: note,
            noteId: noteId,
        })
    }

    updateNotes = () => {
        if(this.state.fieldNotEditable !== true){
        let {
            stockLotId,
            note,
            noteId,
        } = this.state;

        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateStockLotNote(stockLotId, noteId, note)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    success: true,
                    fieldNotEditable: true,
                    successmessage: "Note Updated Successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }else{
        this.setState({
            fieldNotEditable: false,
        })
    }
    }


    deleteNotes = () => {
        let {
            stockLotId,
            noteId
        } = this.state;
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
           
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteStockLotNote(stockLotId, noteId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }
    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("stockLotNewRecord") !== null){
            localStorage.removeItem("stockLotNewRecord")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isStockLots={true} />

                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Edit a note</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteNotes()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Lot</div>
                                <div className="value companyname text-capitalize">{this.state.stockLotNum}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                            <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>{this.setState({ note: e.target.value })}}
                                    />
                                </div> 
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteNotes()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}