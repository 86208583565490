import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';
import * as APIManager from './../../../APIManager/APIManager'
import StateWiseGSTCode from './../../Accouting/ContainerAddData/StateWiseGSTCode.json'
import { validateEmail } from '../../../constants/Validate';

class EditParty extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            success: false,
            successmessage: "",
            error: false,
            errormessage: "",

            partyId: this.props.location.state.partyId,
            isNewEntry: this.props.location.state.isNewEntry,

            expenseCategoryData: [],
            expenseCategory: "",
            gstNumber: "",
            companyName: "",
            address: "",
            fullName: "",
            mobileNo: "",
            email: "",


            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.getExpenseCategories()
    }

   

    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
        .then(response => {
            let category = response.expenseCategory ? response.expenseCategory : []
            this.setState({
                isLoading: false,
                expenseCategoryData: category
            },()=>{
                this.getSingleParty()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.getSingleParty()
            })
        });
    }

    getSingleParty = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleGSTDetail(this.state.partyId)
        .then(response => {
            this.setState({
                isLoading: false,
                expenseCategory: response.data.expenseCategory,
                gstNumber: response.data.gstNumber,
                companyName: response.data.companyName,
                address: response.data.address,
                fullName: response.data.fullName,
                mobileNo: response.data.mobileNo,
                email: response.data.email
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    deleteEntry = () => {
        let slug = "Accounting_expenses"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                let { partyId } = this.state
                APIManager.callDeleteGSTDetail(partyId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    getGSTDetails = () => {
        let { gstNumber } = this.state;
        if(gstNumber){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(this.state.gstNumber)
            .then(response => {
                let address = response.data.address ? response.data.address : ''
                this.setState({
                    isLoading: false,
                    error: false,
                    address: address,
                    companyName: response.data.legalname ? response.data.legalname : response.data.tradename
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    success: false,
                    errormessage: "Please enter valid GST Number"
                })
            });
        }
    }


    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    updateParty = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                partyId,
                expenseCategory,
                gstNumber,
                companyName,
                address,
                fullName,
                mobileNo,
                email
            } = this.state

            if(gstNumber === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter and search GST detail"
                })
            }else if(companyName === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter valid GST number"
                })
            }else if(expenseCategory === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select expense category"
                })
            }else if(email !== "" && !validateEmail(email)){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter valid email address"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateGSTDetail(partyId,expenseCategory,gstNumber.toUpperCase(),companyName,address,fullName,mobileNo,email)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Party Updated Successfully"
                    },()=>{
                        this.scrollToTop()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            let slug = "Settings_Accounting"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isAccountingMaster={true} />

                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Edit Party</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateParty()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                
                                <div className="add_inner">
                                    <div className="title">GST Number</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.gstNumber}
                                            disabled
                                            className='text-uppercase'
                                        />
                                        {/* <div className='gstdetail_search_icon' onClick={()=>this.getGSTDetails()}><Ai.AiOutlineSearch /></div> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>    
                                <div className="add_inner">
                                    <div className="title">Company Name</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.companyName}
                                            disabled
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Address</div>
                                    <div className="value">
                                        <textarea 
                                            autoComplete="off" 
                                            className="expense_desc_textarea" 
                                            value={this.state.address}
                                            disabled
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div> 
                            <div className="add_partside">             
                               
                                <div className="add_inner">
                                    <div className="title">Expense Category</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.expenseCategoryData.map((data)=>(
                                                {id: data.expenseCategory, text: data.expenseCategory}
                                            ))}
                                            value={this.state.expenseCategory}
                                            options={{placeholder: 'Select Category'}}
                                            onSelect={(e)=> this.setState({ expenseCategory: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Full Name</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.fullName}
                                            onChange={(e)=>this.setState({ fullName: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">Mobile number</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.mobileNo}
                                            onChange={(e)=>this.setState({ mobileNo: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">Email address</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.email}
                                            onChange={(e)=>this.setState({ email: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>    
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            } 
                        </div>
                           
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateParty()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default EditParty; 