import React, { Component } from 'react';
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as Fi from 'react-icons/fi'
import * as Ai from 'react-icons/ai'

class generatePumpLog extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            pumpPumpLogData: [
                { id: 'Dispatch', text: 'Dispatch' },
                { id: 'Installation', text: 'Installation' },
                { id: 'Services', text: 'Services' }
            ],

            pumpLogType: this.props.pumpLogType,
            pumpLogFile: this.props.pumpLogFile,
            newPumpLogSrc: "",
            pumpLogDate: this.props.pumpLogDate ? new Date(this.props.pumpLogDate) : '',
            pumpLogNote: this.props.pumpLogNote,

            pumpLogEdit: this.props.onEdit,
            pumpLogBlur: this.props.onBlur,
            componentNum: this.props.componentNum

        }
    }

   

   

    onPumpLogChange = event => {
        let {
            pumpLogType,
            pumpLogNote,
            componentNum
        } = this.state

        let that = this;
        if (event.target.files && event.target.files[0]) {
            this.setState({ newPumpLogSrc: event.target.files[0] });  

            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ pumpLogFile: e.target.result },()=>{
                    that.state.pumpLogEdit(pumpLogType, that.state.pumpLogFile, componentNum)
                    that.state.pumpLogBlur(pumpLogType, that.state.pumpLogFile, that.state.pumpLogDate, pumpLogNote, componentNum)
                })
            }
            reader.readAsDataURL(event.target.files[0]);


        }
    }


    viewSelectedFile = () => {
        let file = this.state.pumpLogFile
        let pdfWindow = window.open("")
        pdfWindow.pumpLog.write(
            "<iframe width='100%' height='100%' src='" +encodeURI(file)+ "'></iframe>"
        )
    }

    render() {
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return (
            <tr>
                <td style={{ width: 200 }}>
                    <Select2    
                        data={this.state.pumpPumpLogData}
                        value={this.state.pumpLogType}
                        options={{placeholder: 'Select'}}
                        onSelect={(e)=> this.setState({ pumpLogType: e.target.value },()=>{
                            onEdit(this.state.pumpLogType, this.state.pumpLogFile, componentNum)
                            onBlur(this.state.pumpLogType, this.state.pumpLogFile, this.state.pumpLogDate, this.state.pumpLogNote, componentNum)
                        })}
                    />
                </td>
                <td style={{ width: 200 }}>
                    <div className='position-relative'>
                        <DatePicker 
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            selected={
                                this.state.pumpLogDate
                            }
                            onChange={
                                date => this.setState({ pumpLogDate: date },()=>{
                                    onEdit(this.state.pumpLogType, this.state.pumpLogFile, componentNum)
                                    onBlur(this.state.pumpLogType, this.state.pumpLogFile, this.state.pumpLogDate, this.state.pumpLogNote, componentNum)
                                })
                            }
                            className="filterdateoption input-box"
                        />
                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                    </div>
                </td>
                
                <td style={ this.state.pumpLogFile ? { width: 320 } : { width: 220 }}>
                    <input 
                        type="file" 
                        className={ this.state.pumpLogFile ? 'input-file doc_file_width_updated' :  'input-file' }
                        accept="image/png, image/gif, image/jpeg, application/pdf,application/msword, application/vnd.openxmlformats-officepumpLog.wordprocessingml.pumpLog, .csv, application/vnd.openxmlformats-officepumpLog.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onPumpLogChange}
                    />
                    {
                        this.state.pumpLogFile &&
                        <div className='doc-saved-file' onClick={()=>this.viewSelectedFile()}>Download</div>
                    }
                </td>
                <td>
                    <textarea 
                        className='text-area' 
                        value={this.state.pumpLogNote}
                        onChange={(e)=>this.setState({ pumpLogNote: e.target.value},()=>{
                            onEdit(this.state.pumpLogType, this.state.pumpLogFile, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.pumpLogType, this.state.pumpLogFile, this.state.pumpLogDate, this.state.pumpLogNote, componentNum)
                        }
                    ></textarea>
                </td>
                <td style={{ width: 40 }}>
                    {
                        this.state.pumpLogType !== "" &&
                            <Fi.FiX className="extraFieldCloseButton" onClick={()=>onRemove(componentNum)} />
                    }
                </td>
            </tr>
        );
    }
}

export default generatePumpLog;