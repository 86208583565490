import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'

class Statistics extends Component {
    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isStatistics={true} />
                <div className="main-container row bg-white">
                    <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                        <div className="page-title">{Strings.ProductionPlanning.Statistics}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Statistics;