import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import * as Fi from 'react-icons/fi'

import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css'
import Loading from '../../../components/Loading';
import {
    ImArrowDown2,
} from "react-icons/im";


import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';

import {
    FiPlus,
    FiX
} from "react-icons/fi";
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

class Invoices extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            invoicesList: [],
            number: "",
            customerOrderNumber: "",
            customerNumber: "",
            customerName: "",
            invoiceType: "",
            invoiceNo: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            minDueDate: "",
            maxDueDate: "",
            minCreatedDate: "",
            maxCreatedDate: "",
            minTotal: "",
            maxTotal: "",
            minSubTotal: "",
            maxSubTotal: "",
            createdBy: "",
            poNo: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,
            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
            subTotal: 0,
            total: 0,
        }

        this.timer = null;
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.invoicesList.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
            });
            this.setState({ invoicesList: sortedOrders }, () => {
                this.setFilterData()
            });
        });
    };
    //******************* ascending descending order function end ********************************

    search = () => {
        localStorage.removeItem("invoiceTableFilter")
        this.setState({ activePage: 1, isLoading: true }, () => {
            this.getAllInvoices()
            // this.setFilterData()
        })
    }
    getAllInvoices = () => {

        let {
            start,
            length,
            number,
            customerOrderNumber,
            customerNumber,
            customerName,
            invoiceType,
            invoiceNo,
            minInvoiceDate,
            maxInvoiceDate,
            minDueDate,
            maxDueDate,
            minCreatedDate,
            maxCreatedDate,
            minTotal,
            maxTotal,
            minSubTotal,
            maxSubTotal,
            createdBy,
            poNo,
            fieldName
        } = this.state

        const otherThanExportInvoice = false

        this.setState({ isLoading: true })
        APIManager.callGetCOInvoices(start, length, number, invoiceType, invoiceNo, minTotal, maxTotal, customerOrderNumber, customerNumber, customerName, convertDateToMMDDYYYY(minInvoiceDate), convertDateToMMDDYYYY(maxInvoiceDate), convertDateToMMDDYYYY(minDueDate), convertDateToMMDDYYYY(maxDueDate), convertDateToMMDDYYYY(minCreatedDate), convertDateToMMDDYYYY(maxCreatedDate), createdBy, minSubTotal, maxSubTotal, poNo, otherThanExportInvoice, fieldName)
            .then(response => {
                // console.log('------->', response.data);
                let subTotal = 0;
                let total = 0;
                for (let i = 0; i < response.data.length; i++) {
                    subTotal += response.data[i].grandSubTotal
                    total += response.data[i].grandTotal
                }
                this.setState({
                    isLoading: false,
                    subTotal: subTotal.toFixed(2),
                    total: total.toFixed(2),
                    invoicesList: response.data,
                    totalRecords: typeof (response.totalRecords) === "object" ? 0 : response.totalRecords
                }, () => {
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }else{
                        this.setFilterData()
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    setFilterData = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            let filterDataArray = {
                'activePage': this.state.activePage,
                'length': this.state.length,
                'start': this.state.start,
                'number': this.state.number,
                'customerOrderNumber': this.state.customerOrderNumber,
                'customerNumber': this.state.customerNumber,
                'customerName': this.state.customerName,
                'invoiceType': this.state.invoiceType,
                'invoiceNo': this.state.invoiceNo,
                'minInvoiceDate': this.state.minInvoiceDate !== null ? this.state.minInvoiceDate : "",
                'maxInvoiceDate': this.state.maxInvoiceDate !== null ? this.state.maxInvoiceDate : "",
                'minDueDate': this.state.minDueDate !== null ? this.state.minDueDate : "",
                'maxDueDate': this.state.maxDueDate !== null ? this.state.maxDueDate : "",
                'minCreatedDate': this.state.minCreatedDate !== null ? this.state.minCreatedDate : "",
                'maxCreatedDate': this.state.maxCreatedDate !== null ? this.state.maxCreatedDate : "",
                'minTotal': this.state.minTotal,
                'maxTotal': this.state.maxTotal,
                'minSubTotal': this.state.minSubTotal,
                'maxSubTotal': this.state.maxSubTotal,
                'createdBy': this.state.createdBy,
                'poNo': this.state.poNo,
                'fieldName': this.state.fieldName,
                'fieldType': this.state.fieldType,
                'sortVal': this.state.sortVal,
            }
            localStorage.setItem("invoiceTableFilter", JSON.stringify(filterDataArray))
            // this.getAllInvoices()

        }, 800);
    }

    getFilterData = () => {

        let filterData = JSON.parse(localStorage.getItem("invoiceTableFilter"))
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                start: filterData.start,
                length: filterData.length,
                number: filterData.number,
                customerOrderNumber: filterData.customerOrderNumber,
                customerNumber: filterData.customerNumber,
                customerName: filterData.customerName,
                invoiceType: filterData.invoiceType,
                invoiceNo: filterData.invoiceNo,
                minInvoiceDate: filterData.minInvoiceDate ? new Date(filterData.minInvoiceDate) : "",
                maxInvoiceDate: filterData.maxInvoiceDate ? new Date(filterData.maxInvoiceDate) : "",
                minDueDate: filterData.minDueDate ? new Date(filterData.minDueDate) : "",
                maxDueDate: filterData.maxDueDate ? new Date(filterData.maxDueDate) : "",
                minCreatedDate: filterData.minCreatedDate ? new Date(filterData.minCreatedDate) : "",
                maxCreatedDate: filterData.maxCreatedDate ? new Date(filterData.maxCreatedDate) : "",
                minTotal: filterData.minTotal,
                maxTotal: filterData.maxTotal,
                minSubTotal: filterData.minSubTotal,
                maxSubTotal: filterData.maxSubTotal,
                createdBy: filterData.createdBy,
                poNo: filterData.poNo,
                length: filterData.length,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,

            }, () => {
                this.getAllInvoices()
            })
        } else {
            this.getAllInvoices()
        }
    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            isLoading: true,
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.setFilterData()
            this.getAllInvoices()
        });
    }

    addInvoices = () => {
        let slug = "CRM_Invoices"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createcoinvoice",
                state: { coId: "", isFromCO: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editInvoices = (id) => {
        let slug = "CRM_Invoices"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/editcoinvoice",
                state: { invoiceId: id, isNewEntry: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }


    clearFilter = () => {
        localStorage.removeItem("invoiceTableFilter")
        this.setState({
            isLoading: false,
            activePage: 1,
            number: "",
            start: 0,
            length: 25,
            customerOrderNumber: "",
            customerNumber: "",
            customerName: "",
            invoiceType: "",
            invoiceNo: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            minDueDate: "",
            maxDueDate: "",
            minCreatedDate: "",
            maxCreatedDate: "",
            minTotal: "",
            maxTotal: "",
            createdBy: "",
            minSubTotal: "",
            maxSubTotal: "",
            poNo: "",
        }, () => {
            this.getAllInvoices()
        })
    }


    exportInvoicePDF = () => {
        let responseData = this.state.invoicesList;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Customer Order - Invoices";

        const headers = [[
            "Number",
            "Customer Order",
            "Customer Number",
            "Customer Name",
            "PO Number",
            "Type",
            "Subtotal",
            "Grand total",
            "Invoice date",
            "Due date",
            "Created",
            "Created by"
        ]];



        const data = responseData.map((elt, i) => [
            elt.number,
            elt.customerOrderNumber,
            elt.customerNumber,
            elt.customerName,
            elt.poNo,
            elt.invoiceType,
            elt.grandSubTotal,
            elt.grandTotal,
            convertDateStringMMDDtoDDMM(elt.invoiceDate),
            convertDateStringMMDDtoDDMM(elt.dueDate),
            convertDateStringMMDDtoDDMM(elt.created),
            elt.creator
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("coinvoices.pdf")
    }


    exportInvoiceCSV = () => {
        let responseData = this.state.invoicesList;
        const title = "Customer Order - Invoices";
        const headers = [
            "Number",
            "Customer Order",
            "Customer Number",
            "Customer Name",
            "PO Number",
            "Type",
            "Subtotal",
            "Grand total",
            "Invoice date",
            "Due date",
            "Created",
            "Created by"
        ];

        const data = responseData.map((elt, i) => [
            elt.number,
            elt.customerOrderNumber,
            elt.customerNumber,
            elt.customerName,
            elt.poNo,
            elt.invoiceType,
            elt.grandSubTotal,
            elt.grandTotal,
            convertDateStringMMDDtoDDMM(elt.invoiceDate),
            convertDateStringMMDDtoDDMM(elt.dueDate),
            convertDateStringMMDDtoDDMM(elt.created),
            elt.creator
        ]);


        const options = {
            filename: 'coinvoices',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    viewIndianDateFormatString = (dt) => {

        return convertDateStringMMDDtoDDMM(dt)

        // let newDate = "";
        // if(dt){
        //     let d = dt.split('/')
        //     newDate = d[1]+'/'+d[0]+'/'+d[2]
        // }
        // return newDate;
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isInvoices={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 col-sm-4 col-xs-12">
                                <div className="page-title">{Strings.CRM.Invoices}</div>
                            </div>

                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportInvoicePDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportInvoiceCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table " style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableAddButton" style={{ width: 10 }}><div onClick={() => this.addInvoices()}><FiPlus /></div></th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('number', 'notDate', false)}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('customerOrderNumber', 'notDate', false)}><span>Customer order </span>{this.state.fieldName === "customerOrderNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "customerOrderNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('customerNumber', 'notDate', false)}><span>Customer number</span> {this.state.fieldName === "customerNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "customerNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('customerName', 'notDate', false)}><span>Customer name</span> {this.state.fieldName === "customerName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "customerName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('poNo', 'notDate', false)}><span>PO number</span> {this.state.fieldName === "poNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "poNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('invoiceType', 'notDate', false)}><span>Type </span>{this.state.fieldName === "invoiceType" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceType" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('grandSubTotal', 'notDate', false)}><span>Subtotal</span> {this.state.fieldName === "grandSubTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandSubTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('grandTotal', 'notDate', false)}><span>Total</span> {this.state.fieldName === "grandTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('invoiceDate', 'Date', false)}><span>Invoice date</span> {this.state.fieldName === "invoiceDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('dueDate', 'Date', false)}><span>Due Date </span>{this.state.fieldName === "dueDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "dueDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('created', 'Date', false)}><span>Created</span> {this.state.fieldName === "created" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "created" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('creator', 'notDate', false)}><span>Created by</span> {this.state.fieldName === "creator" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "creator" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableAddButton" style={{ width: 10 }} ><div onClick={() => this.addInvoices()}><FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableAddButton">{/* <FiSettings /> */}</th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.number} onChange={(e) => this.setState({ number: e.target.value, start: 0, activePage: 1 }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.customerOrderNumber} onChange={(e) => this.setState({ customerOrderNumber: e.target.value, start: 0, activePage: 1 }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.customerNumber} onChange={(e) => this.setState({ customerNumber: e.target.value, start: 0, activePage: 1 }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.customerName} onChange={(e) => this.setState({ customerName: e.target.value, start: 0, activePage: 1 }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.poNo} onChange={(e) => this.setState({ poNo: e.target.value, start: 0, activePage: 1 }, () => { this.getAllInvoices() })} /></th>
                                    <th className="tableElemContain">
                                        <select style={{ width: 80 }} value={this.state.invoiceType} onChange={(e) => this.setState({ invoiceType: e.target.value, start: 0, activePage: 1 }, () => { this.getAllInvoices() })}>
                                            <option value=""></option>
                                            <option value="credit">Credit</option>
                                            <option value="deposit">Deposit</option>
                                            <option value="tax">Tax</option>
                                            <option value="retail">Retail</option>
                                            <option value="sez">SEZ</option>
                                            <option value="export">Export</option>
                                        </select>
                                    </th>
                                    <th className="tableElemContain" style={{ width: 100 }}>
                                        <input type="text" placeholder="min" value={this.state.minSubTotal} onChange={(e) => this.setState({ minSubTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "", start: 0, activePage: 1 }, () => { this.getAllInvoices() })} />
                                        <input type="text" className="mt-1" placeholder="max" value={this.state.maxSubTotal} onChange={(e) => this.setState({ maxSubTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "", start: 0, activePage: 1 }, () => { this.getAllInvoices() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 100 }}>
                                        <input type="text" placeholder="min" value={this.state.minTotal} onChange={(e) => this.setState({ minTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "", start: 0, activePage: 1 }, () => { this.setFilterData() })} />
                                        <input type="text" className="mt-1" placeholder="max" value={this.state.maxTotal} onChange={(e) => this.setState({ maxTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "", start: 0, activePage: 1 }, () => { this.getAllInvoices() })} />
                                    </th>

                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minInvoiceDate
                                                }
                                                onChange={
                                                    date => this.setState({ minInvoiceDate: date, start: 0, activePage: 1 }, () => {
                                                        this.getAllInvoices()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minInvoiceDate}
                                                selected={
                                                    this.state.maxInvoiceDate
                                                }
                                                onChange={
                                                    date => this.setState({ maxInvoiceDate: date, start: 0, activePage: 1 }, () => {
                                                        this.getAllInvoices()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>

                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minDueDate
                                                }
                                                onChange={
                                                    date => this.setState({ minDueDate: date, start: 0, activePage: 1 }, () => {
                                                        this.getAllInvoices()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minDueDate}
                                                selected={
                                                    this.state.maxDueDate
                                                }
                                                onChange={
                                                    date => this.setState({ maxDueDate: date, start: 0, activePage: 1 }, () => {
                                                        this.getAllInvoices()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>

                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minCreatedDate
                                                }
                                                onChange={
                                                    date => this.setState({ minCreatedDate: date, start: 0, activePage: 1 }, () => {
                                                        this.getAllInvoices()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minCreatedDate}
                                                selected={
                                                    this.state.maxCreatedDate
                                                }
                                                onChange={
                                                    date => this.setState({ maxCreatedDate: date, start: 0, activePage: 1 }, () => {
                                                        this.getAllInvoices()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain"><input type="text" style={{ width: 80 }} value={this.state.createdBy} onChange={(e) => this.setState({ createdBy: e.target.value, start: 0, activePage: 1 }, () => { this.setFilterData() })} /></th>


                                    <th className="tableAddButton" onClick={() => this.clearFilter()} ><FiX /></th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><b>Total:</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><b>{(this.state.subTotal).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                    <td><b>{(this.state.total).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={14} />
                                    :
                                    <tbody>
                                        {
                                            this.state.invoicesList.map((data, i) => (
                                                <tr key={'invoice__list__' + i} onClick={() => this.editInvoices(data._id)}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td>{data.number}</td>
                                                    <td>{data.customerOrderNumber}</td>
                                                    <td>{data.customerNumber.toUpperCase()}</td>
                                                    <td>{data.customerName}</td>
                                                    <td>{data.poNo}</td>
                                                    <td>{data.invoiceType}</td>
                                                    <td>{data.grandSubTotal} <Bi.BiRupee /></td>
                                                    <td>{data.grandTotal} <Bi.BiRupee /></td>
                                                    <td>{this.viewIndianDateFormatString(data.invoiceDate)}</td>
                                                    <td>{this.viewIndianDateFormatString(data.dueDate)}</td>
                                                    <td>{this.viewIndianDateFormatString(data.created)}</td>
                                                    <td>{data.creator}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Invoices;