import React, { Component } from 'react';
import * as Fi from 'react-icons/fi'
import { validateNumber } from '../../../../constants/Validate';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import Select2 from '../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css'


class GroupItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            error: false,
            errormessage: "",

            onBlurCall: this.props.onBlurProduct,
            count: this.props.counter,
            apiResponseData: this.props.groupData,
            isDetailedView: this.props.isDetailedView,

            workstationGroupList: [],
            workstationsData: [],
            workStationsArray: [],
            workStationsArrayList: [],
            departmentData: [],
            userData: [],

            productObj : {
                'workStationGroup': '',
                'workStationsArray': '',
                'otherDescription': '',
                'setupTime': '',
                'cycleTime': '',
                'fixedCost': '',
                'variableCost': '',
                'quantity': '',
                'department': ''
            },

            workstationGroup: "",
            description: "",
            setupTime: "",
            cycleTime: "",
            fixedCost: "",
            variableCost: "",
            quantity: "",
            department: "",
        }
    }

    componentDidMount(){
        this.getAllWorkStationGroup()
    }

    getAllWorkStationGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllWorkstationGroup("", "", "", "", "", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                workstationGroupList: response.data,
            },()=>{
                this.setProductValues()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    getDepartmentByWS = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetDepartmentByWS(id)
        .then(response => {
            if(response.data.length === 0){
                this.setState({
                    isLoading: false,
                    departmentData: response.data,
                    error: true,
                    errormessage: response.message
                })
            }else{
                this.setState({ 
                    error: false,
                    isLoading: false,
                    departmentData: response.data
                })
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({
                isLoading: false,
                userData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getAllWorkstations = (type) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllWorkstation("","","","","","",type,"","")
        .then(response => {
            this.setState({ 
                isLoading: false,
                workStationsArrayList: [],
                workstationsData: response.data
            },()=>{
                this.listWorkStationData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }


    listWorkStationData = () => {
        let workStationsArray = [...this.state.workStationsArray]
        let listing = []
        let obj = []
        this.state.workstationsData.reverse().map((data, i) => {
            let index = workStationsArray.map((el) => el.workstationId).indexOf(data._id);
            let value = this.state[i] ? this.state[i] : i+1
            let objVal = { "workstationId" : data._id, "priority" : value }
            obj.push(objVal)
            if(index > -1){
                listing.push(<div key={data._id} className="m-1">{data.name} <input type="text" className='input-box' style={{ width: "50%" }} defaultValue={value} onChange={(e)=>this.updatePriority(e.target.value,data._id,'priority')} /> </div>)
            }else{
                listing.push(<div key={data._id} className="m-1">{data.name} <input type="text" className='input-box' style={{ width: "50%" }} defaultValue={value} onChange={(e)=>this.updatePriority(e.target.value,data._id,'priority')} /> </div>)
            }
        })
        this.setState({
            workStationsArrayList: listing
        },()=>{
            this.generateObject('workStationsArray', obj)
        })
    }

    updatePriority = (val,id,priority) => {
        let workStationsArray = [...this.state.workStationsArray]
        let obj = { "workstationId" : id, "priority" : parseInt(val) }
        this.setState({
            [priority]: parseInt(val) ? parseInt(val) : ""
        },()=>{
            if(val !== ""){
                let index = workStationsArray.map((el) => el.workstationId).indexOf(id);
                if(index > -1){
                    workStationsArray[index] = obj
                    this.setState({ 
                        workStationsArray 
                    },()=>{
                        this.generateObject('workStationsArray', this.state.workStationsArray)
                    })
                }else{
                    workStationsArray.push(obj)
                    this.setState({ 
                        workStationsArray 
                    },()=>{
                        this.generateObject('workStationsArray', this.state.workStationsArray)
                    })
                }
            }else{
                let index = workStationsArray.map((el) => el.workstationId).indexOf(id);
                if(index > -1){
                    workStationsArray.splice(index, 1)
                    this.setState({ 
                        workStationsArray 
                    },()=>{
                        this.generateObject('workStationsArray', this.state.workStationsArray)
                    })
                }
            }
        })
    }

    setProductValues = () => {
        let apiData = this.state.apiResponseData;
        this.setState({
            workstationGroup: apiData.workStationGroup,
            description: apiData.otherDescription,
            setupTime: apiData.setupTime,
            cycleTime: apiData.cycleTime,
            workStationsArray: apiData.workStationsArray,
            fixedCost:  apiData.fixedCost,
            variableCost:  apiData.variableCost,
            quantity:  apiData.quantity,
            department:  apiData.department,
            productObj: apiData
        },()=>{
            if(apiData.workStationGroup !== ""){
                this.getAllWorkstations(apiData.workStationGroup)
                this.getDepartmentByWS(apiData.workStationGroup)
            }
        })
    }

    generateObject = (type, value) => {        
        let productObj = this.state.productObj

        if(type === 'workstationGroup')
            productObj.workStationGroup = value;
        if(type === 'workStationsArray')
            productObj.workStationsArray = value;
        if(type === 'description')
            productObj.otherDescription = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if(type === 'setupTime')
            productObj.setupTime = parseInt(value);
        if(type === 'cycleTime')
            productObj.cycleTime = parseInt(value);
        if(type === 'fixedCost')
            productObj.fixedCost = parseInt(value);
        if(type === 'variableCost')
            productObj.variableCost = parseInt(value);
        if(type === 'quantity')
            productObj.quantity = parseInt(value);
        if(type === 'department')
            productObj.department = value;
   
        this.setState({
            productObj
        },()=>{
            console.log(productObj)
            this.state.onBlurCall(this.state.productObj, this.state.count)
        })
    }

    render() {
        let {
            onChangeProduct,
            onBlurProduct,
            onRemoveProduct,
            counter
        } = this.props
        return (
            <>
            {
                this.state.error &&
                <tr>
                    <td colSpan={10}>
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            }
            <tr>
                <td style={{ width: 150 }}>
                    {/* <select
                        value={this.state.workstationGroup} 
                        className='select-box text-capitalize'
                        onChange={(e)=>{
                            if(e.target.value !== ""){
                                this.setState({ workstationGroup: e.target.value },()=>{
                                    this.getAllWorkstations(e.target.value)
                                    this.getDepartmentByWS(e.target.value)
                                    this.generateObject('workstationGroup', e.target.value) 
                                    onChangeProduct(counter)
                                    onBlurProduct(this.state.productObj, counter)
                                })
                            }
                        }}
                    >
                        <option value=''>Select group</option>
                        {
                            this.state.workstationGroupList.map((data) => (
                                <option key={data._id} value={data._id}>{data.number.toUpperCase()} {data.name}</option>
                            ))
                        }
                    </select> */}

                    
                    <Select2    
                        data={
                            this.state.workstationGroupList.map((data) => (
                                { id: data._id, text: data.number.toUpperCase()+' '+data.name }
                            ))
                        }
                        value={this.state.workstationGroup}
                        options={{placeholder: 'Select Group'}}
                        onSelect={(e)=> this.setState({ workstationGroup: e.target.value },()=>{
                            this.getAllWorkstations(e.target.value)
                            this.getDepartmentByWS(e.target.value)
                            this.generateObject('workstationGroup', e.target.value) 
                            onChangeProduct(counter)
                            onBlurProduct(this.state.productObj, counter)
                        })}
                    />

                </td>

                <td style={{ width: 200 }}>
                     {
                        this.state.workStationsArrayList
                    }
                </td>
                
                <td style={{width: 250}}>
                    <textarea 
                        value={this.state.description}
                        className='textarea' 
                        onChange={(e)=>this.setState({ description: e.target.value },()=>{
                            this.generateObject('description', e.target.value)
                        })
                        }
                        onBlur={()=>onBlurProduct(this.state.productObj, counter)}
                    /> 
                </td>
                <td>
                    <div>
                        <input 
                            type='text' 
                            className='input-box' 
                            style={{ width: "50%" }}
                            value={this.state.setupTime}
                            onChange={(e)=>this.setState({ setupTime: parseInt(e.target.value) ? parseInt(e.target.value) : ""})}
                            onBlur={(e)=>{
                                this.generateObject('setupTime', e.target.value)
                            }}
                        ></input> min. 
                    </div>
                </td>
                <td>
                    <div>
                        <input 
                            type='text' 
                            className='input-box' 
                            style={{ width: "50%" }}
                            value={this.state.cycleTime}
                            onChange={(e)=>this.setState({ cycleTime: parseInt(e.target.value) ? parseInt(e.target.value) : ""})}
                            onBlur={(e)=>{
                                this.generateObject('cycleTime', e.target.value)
                            }}
                        ></input> min. 
                    </div>
                </td>

                {
                    this.state.isDetailedView &&
                    <td>
                        <div>
                            <input 
                                type='text' 
                                className='input-box' 
                                value={this.state.fixedCost}
                                onChange={(e)=>
                                    {
                                        if(validateNumber(e.target.value))
                                        this.setState({ fixedCost: e.target.value })
                                    }
                                }
                                onBlur={(e)=>{
                                    this.generateObject('fixedCost', e.target.value)
                                }}
                            ></input> 
                        </div>
                    </td>
                }
                {
                    this.state.isDetailedView &&
                    <td>
                        <div>
                            <input 
                                type='text' 
                                className='input-box' 
                                value={this.state.variableCost}
                                onChange={(e)=>this.setState({ variableCost: parseInt(e.target.value) ? parseInt(e.target.value) : ""})}
                                onBlur={(e)=>{
                                    this.generateObject('variableCost', e.target.value)
                                }}
                                readOnly
                            ></input> 
                        </div>
                    </td>
                }
                {
                    this.state.isDetailedView &&
                    <td>
                        <div>
                            <input 
                                type='text' 
                                className='input-box' 
                                value={this.state.quantity}
                                onChange={(e)=>this.setState({ quantity: parseInt(e.target.value) ? parseInt(e.target.value) : ""})}
                                onBlur={(e)=>{
                                    this.generateObject('quantity', e.target.value)
                                }}
                            ></input> 
                        </div>
                    </td>
                }
                {
                    this.state.isDetailedView &&
                    <td>
                        <div>
                            <select 
                                className='select-box text-capitalize'
                                value={this.state.department} 
                                onChange={(e)=>{
                                    if(e.target.value !== ""){
                                        this.setState({ department: e.target.value },()=>{
                                            this.generateObject('department', e.target.value) 
                                        })
                                    }
                                }}
                            >
                                <option value=''>Select</option>
                                {
                                    this.state.departmentData !== null &&
                                    this.state.departmentData.map((data, i) => (
                                        <option key={i+data.deptId} value={data.deptId}>{data.deptName}</option>
                                    ))
                                }

                                {
                                    this.state.userData !== null &&
                                    this.state.userData.map((data, i) => (
                                        <option key={i+data._id} value={data._id}>{data.username ? data.username : data.full_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </td>
                }
        
        
                <td style={{ width: 50 }}>
                    {
                        this.state.workstationGroup !== '' &&
                        <Fi.FiX className="extraFieldCloseButton" onClick={onRemoveProduct.bind(this,counter)} />
                    }
                </td>
            </tr>
            </>
        );
    }
}

export default GroupItem;