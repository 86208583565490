import React, { Component } from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import { ImArrowDown2 } from "react-icons/im";
import { FiPlus, FiX } from "react-icons/fi";
import { AiOutlineCalendar } from 'react-icons/ai'
import * as Fi from 'react-icons/fi'


import * as ExportData from './exportData'


import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';

import Pagination from "react-js-pagination";


import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';


class Shipments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

            responseData: [],

            number: "",
            customerOrder: "",
            status: "",
            deliveryAddress: "",
            waybillNotes: "",
            pickingListNotes: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseData.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) { 
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ responseData: sortedOrders }, () => {
                this.setFilterData()
            });
        });
        
    };
    //******************* ascending descending order function end ********************************

    setFilterData = () => {
        let filterDataArray = {
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
            'number': this.state.number,
            'customerOrder': this.state.customerOrder,
            'status': this.state.status,
            'deliveryAddress': this.state.deliveryAddress,
            'waybillNotes': this.state.waybillNotes,
            'pickingListNotes': this.state.pickingListNotes,
            'minCreated': this.state.minCreated ? this.state.minCreated : "",
            'maxCreated': this.state.maxCreated ? this.state.maxCreated : "",
            'minDeliveryDate': this.state.minDeliveryDate ? this.state.minDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate ? this.state.maxDeliveryDate : "",
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }
        localStorage.setItem("stockShipments", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("stockShipments"))
        if (filterData !== null) {
            this.setState({
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,
                number: filterData.number,
                customerOrder: filterData.customerOrder,
                status: filterData.status,
                deliveryAddress: filterData.deliveryAddress,
                waybillNotes: filterData.waybillNotes,
                pickingListNotes: filterData.pickingListNotes,
                minCreated: filterData.minCreated ? new Date(filterData.minCreated) : "",
                maxCreated: filterData.maxCreated ? new Date(filterData.maxCreated) : "",
                minDeliveryDate: filterData.minDeliveryDate ? new Date(filterData.minDeliveryDate) : "",
                maxDeliveryDate: filterData.maxDeliveryDate ? new Date(filterData.maxDeliveryDate) : "",
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getAllShipments()
            })
        } else {
            this.getAllShipments()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("stockShipments")
        this.setState({
            start: 0,
            length: 25,
            activePage: 1,
            number: "",
            customerOrder: "",
            status: "",
            deliveryAddress: "",
            waybillNotes: "",
            pickingListNotes: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
        }, () => {
            this.getAllShipments()
        })
    }

    getAllShipments = () => {
        let { number, customerOrder, status, deliveryAddress, waybillNotes, pickingListNotes, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, start, length } = this.state;
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllShipment(number, customerOrder, status, deliveryAddress, waybillNotes, pickingListNotes, convertDateToMMDDYYYY(minCreated), convertDateToMMDDYYYY(maxCreated), convertDateToMMDDYYYY(minDeliveryDate), convertDateToMMDDYYYY(maxDeliveryDate), start, length,)
            .then(response => {
                this.setState({
                    responseData: response.data,
                    totalRecords: response.totalRecords,
                    isLoading: false
                }, () => {
                    this.setFilterData()
                    
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors.message
                })
            });


    }


    addNewShipment = () => {
        let slug = "Stock_Shipments"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createshipment",
                state: { customerOrderId: "" }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editShipment = (_id) => {
        this.props.history.push({
            pathname: "/editshipment",
            state: { shipmentId: _id, newShipmentEntry: false }
        })
    }



    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllShipments()
        });
    }


    exportAsCSV = () => {
        ExportData.exportCSV(this.state.responseData)
    }

    exportAsPDF = () => {
        ExportData.exportPDF(this.state.responseData)
    }


    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isShipments={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">{Strings.Stock.Shipments}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addNewShipment()}><FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate' , false)}> <span>Number</span>{this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('customerOrderNumber', 'notDate' , false)}> <span>Customer Order</span>{this.state.fieldName === "customerOrderNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "customerOrderNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('deliveryDate', 'Date' , false)}> <span>Delivery date</span>{this.state.fieldName === "deliveryDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "deliveryDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('status', 'notDate' , false)}> <span>Status</span>{this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('created', 'Date' , false)}> <span>Created</span>{this.state.fieldName === "created" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "created" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('shippingAddress', 'notDate' , false)}> <span>Delivery address</span>{this.state.fieldName === "shippingAddress" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "shippingAddress" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('waybillNotes', 'notDate' , false)}> <span>Waybill notes</span>{this.state.fieldName === "waybillNotes" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "waybillNotes" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('pickingListNotes', 'notDate' , false)}> <span>Picking list notes</span>{this.state.fieldName === "pickingListNotes" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "pickingListNotes" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    {/* <th className="tableHeader tableHeadButton"><FiChevronsDown /></th> */}
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addNewShipment()}><FiPlus /></div></th>
                                    {/* <th className="tableHeader tableHeadButton"><FiPrinter /></th> */}
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className="tableElemContain">
                                        <input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllShipments() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" value={this.state.customerOrder} onChange={(e) => this.setState({ customerOrder: e.target.value }, () => { this.getAllShipments() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minDeliveryDate
                                                }
                                                onChange={
                                                    date => this.setState({ minDeliveryDate: date }, () => {
                                                        this.getAllShipments()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.maxDeliveryDate}
                                                minDate={this.state.minDeliveryDate}
                                                onChange={
                                                    date => this.setState({ maxDeliveryDate: date }, () => {
                                                        this.getAllShipments()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain">
                                        <select style={{ width: 80 }} value={this.state.status} onChange={(e) => this.setState({ status: e.target.value }, () => { this.getAllShipments() })}>
                                            <option value=""></option>
                                            <option value="new">New</option>
                                            <option value="ready for shipment">Ready for shipment</option>
                                            <option value="shipped">Shipped</option>
                                        </select>
                                    </th>
                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minCreated
                                                }
                                                onChange={
                                                    date => this.setState({ minCreated: date }, () => {
                                                        this.getAllShipments()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.maxCreated}
                                                minDate={this.state.minCreated}
                                                onChange={
                                                    date => this.setState({ maxCreated: date }, () => {
                                                        this.getAllShipments()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" value={this.state.deliveryAddress} onChange={(e) => this.setState({ deliveryAddress: e.target.value }, () => { this.getAllShipments() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" value={this.state.waybillNotes} onChange={(e) => this.setState({ waybillNotes: e.target.value }, () => { this.getAllShipments() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" value={this.state.pickingListNotes} onChange={(e) => this.setState({ pickingListNotes: e.target.value }, () => { this.getAllShipments() })} />
                                    </th>
                                    <th className="tableAddButton" onClick={() => this.clearFilter()}><FiX /></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={10} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseData.map((data, i) => (
                                                <tr key={data._id + i + 1} onClick={() => this.editShipment(data._id)}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td>{data.number.toUpperCase()}</td>
                                                    <td>{data.customerOrderNumber.toUpperCase()}</td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.deliveryDate)}</td>
                                                    <td>{data.status}</td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                                    <td>{data.shippingAddress}</td>
                                                    <td>{data.waybillNotes}</td>
                                                    <td>{data.pickingListNotes}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>

                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default Shipments;