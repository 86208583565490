
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import { convertDateStringMMDDtoDDMM } from "../../../constants/DateFormatter";


export const exportPumpPDF = (responseData, chooseColumnList) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    
    const title = "Pumps";

    let headerData = []
    chooseColumnList.map((data, i)=> [
        data.visible && 
        headerData.push(data.name)
    ])
    const headers = [ headerData ]

    let allData = []
    for(let i=0;i<responseData.length;i++){
        let setData = []
        let elt = responseData[i]
        if(chooseColumnList[0].visible) setData.push(elt.number.toUpperCase())
        if(chooseColumnList[1].visible) setData.push(elt.customerNumber.toUpperCase())
        if(chooseColumnList[2].visible) setData.push(elt.clientName)
        if(chooseColumnList[3].visible) setData.push(elt.project)
        if(chooseColumnList[4].visible) setData.push(elt.industryType)
        if(chooseColumnList[5].visible) setData.push(elt.country)
        if(chooseColumnList[6].visible) setData.push(elt.city)
        if(chooseColumnList[7].visible) setData.push(elt.pumpTagNo)
        if(chooseColumnList[8].visible) setData.push(elt.application)
        if(chooseColumnList[9].visible) setData.push(elt.fluid)
        if(chooseColumnList[10].visible) setData.push(elt.solid)
        if(chooseColumnList[11].visible) setData.push(elt.temp)
        if(chooseColumnList[12].visible) setData.push(elt.pumpSerialNo)
        if(chooseColumnList[13].visible) setData.push(elt.pumpModelNo)
        if(chooseColumnList[14].visible) setData.push(elt.mainMoc)
        if(chooseColumnList[15].visible) setData.push(elt.impellerMoc)
        if(chooseColumnList[16].visible) setData.push(elt.impellerType)
        if(chooseColumnList[17].visible) setData.push(elt.impellerSize)
        if(chooseColumnList[18].visible) setData.push(elt.mechSeal)
        if(chooseColumnList[19].visible) setData.push(elt.head)
        if(chooseColumnList[20].visible) setData.push(elt.flow)
        if(chooseColumnList[21].visible) setData.push(elt.motorRating)
        if(chooseColumnList[22].visible) setData.push(convertDateStringMMDDtoDDMM(elt.dateOfDispatch))
        if(chooseColumnList[23].visible) setData.push(convertDateStringMMDDtoDDMM(elt.dateOfInstallation))
        if(chooseColumnList[24].visible) setData.push(convertDateStringMMDDtoDDMM(elt.deliveryDateAsPerPO))
        allData.push(setData)
    }
    const data = allData;

    let content = {
        startY: 50,
        head: headers,
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("registered-pump.pdf")
}


export const exportPumpCSV = (responseData, chooseColumnList) => {
    const title = "Pumps";
    
    let headerData = []
    chooseColumnList.map((data, i)=> [
        data.visible && 
        headerData.push(data.name)
    ])
    const headers = headerData

    let allData = []
    for(let i=0;i<responseData.length;i++){
        let setData = []
        let elt = responseData[i]
        if(chooseColumnList[0].visible) setData.push(elt.number.toUpperCase())
        if(chooseColumnList[1].visible) setData.push(elt.customerNumber.toUpperCase())
        if(chooseColumnList[2].visible) setData.push(elt.clientName)
        if(chooseColumnList[3].visible) setData.push(elt.project)
        if(chooseColumnList[4].visible) setData.push(elt.industryType)
        if(chooseColumnList[5].visible) setData.push(elt.country)
        if(chooseColumnList[6].visible) setData.push(elt.city)
        if(chooseColumnList[7].visible) setData.push(elt.pumpTagNo)
        if(chooseColumnList[8].visible) setData.push(elt.application)
        if(chooseColumnList[9].visible) setData.push(elt.fluid)
        if(chooseColumnList[10].visible) setData.push(elt.solid)
        if(chooseColumnList[11].visible) setData.push(elt.temp)
        if(chooseColumnList[12].visible) setData.push(elt.pumpSerialNo)
        if(chooseColumnList[13].visible) setData.push(elt.pumpModelNo)
        if(chooseColumnList[14].visible) setData.push(elt.mainMoc)
        if(chooseColumnList[15].visible) setData.push(elt.impellerMoc)
        if(chooseColumnList[16].visible) setData.push(elt.impellerType)
        if(chooseColumnList[17].visible) setData.push(elt.impellerSize)
        if(chooseColumnList[18].visible) setData.push(elt.mechSeal)
        if(chooseColumnList[19].visible) setData.push(elt.head)
        if(chooseColumnList[20].visible) setData.push(elt.flow)
        if(chooseColumnList[21].visible) setData.push(elt.motorRating)
        if(chooseColumnList[22].visible) setData.push(convertDateStringMMDDtoDDMM(elt.dateOfDispatch))
        if(chooseColumnList[23].visible) setData.push(convertDateStringMMDDtoDDMM(elt.dateOfInstallation))
        if(chooseColumnList[24].visible) setData.push(convertDateStringMMDDtoDDMM(elt.deliveryDateAsPerPO))
        allData.push(setData)
    }
    const data = allData;

    const options = { 
        filename: 'registered-pumps',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers,
    };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);
}
