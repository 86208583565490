import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'

import colors from './../WorkstationGroups/color_codes.json'
import CustomHolidays from '../../../components/AutoGenerateComponent/addCustomHolidays';

class createWorkstationGroup extends Component {

    constructor(props){
        super(props)
        var currentdate = new Date(); 
        var date = (currentdate.getMonth()+1)  + "/"  + currentdate.getDate() + "/" + currentdate.getFullYear()
                
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            name: "",
            noofinstances: "",
            checkHourlyRate: false,
            hourlyrate: "",
            checkCustomWorkingHours: false,


            monday_checked: true,
            tuesday_checked: true,
            wednesday_checked: true,
            thursday_checked: true,
            friday_checked: true,
            saturday_checked: true,
            sunday_checked: true,

            currentdate: date,
            mon_starttime: "10:00 AM",
            mon_endtime: "7:00 PM",
            tue_starttime: "10:00 AM",
            tue_endtime: "7:00 PM",
            wed_starttime: "10:00 AM",
            wed_endtime: "7:00 PM",
            thu_starttime: "10:00 AM",
            thu_endtime: "7:00 PM",
            fri_starttime: "10:00 AM",
            fri_endtime: "7:00 PM",
            sat_starttime: "10:00 AM",
            sat_endtime: "7:00 PM",
            sun_starttime: "10:00 AM",
            sun_endtime: "7:00 PM",
            customWorkingHours: [],
            checkCustomHolidays: false,
            colorCode: "",

            countCustomHolidaysNumber: [0],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,
        }
    }

    componentDidMount(){
        this.setCustomWorkingHours()
        this.firstHolidayInput()
    }

    getCompanyWorkingHours = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCompanyWorkingHours()
        .then(response => {
            this.setState({ 
                isLoading: false,
                customWorkingHours: response.workingHours !== "undefined" ? response.workingHours : []
            },()=>{
                this.setWorkingHours()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });  
    }

    setWorkingHours = () => {
        let cwh = this.state.customWorkingHours
        if(cwh.length > 0){
            this.setState({
                sun_starttime: cwh[0].startTime,
                sun_endtime: cwh[0].endTime,
                sunday_checked: cwh[0].isWorkingDay,
                mon_starttime: cwh[1].startTime,
                mon_endtime: cwh[1].endTime,
                monday_checked: cwh[1].isWorkingDay,
                tue_starttime: cwh[2].startTime,
                tue_endtime: cwh[2].endTime,
                tuesday_checked: cwh[2].isWorkingDay,
                wed_starttime: cwh[3].startTime,
                wed_endtime: cwh[3].endTime,
                wednesday_checked: cwh[3].isWorkingDay,
                thu_starttime: cwh[4].startTime,
                thu_endtime: cwh[4].endTime,
                thursday_checked: cwh[4].isWorkingDay,
                fri_starttime: cwh[5].startTime,
                fri_endtime: cwh[5].endTime,
                friday_checked: cwh[5].isWorkingDay,
                sat_starttime: cwh[6].startTime,
                sat_endtime: cwh[6].endTime,
                saturday_checked: cwh[6].isWorkingDay
            })
        }
    }

    firstHolidayInput = () => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <CustomHolidays 
                        key={this.state.count}
                        componentNum={this.state.count}
                        holidays={""}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            )
        })
    }
    
    statusInputEdit = (holidays, num) => {
        let array = [...this.state.countCustomHolidaysNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
        
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && holidays !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countCustomHolidaysNumber: this.state.countCustomHolidaysNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <CustomHolidays 
                                key={this.state.count}
                                componentNum={this.state.count}
                                holidays={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (holidays, num) => {
    
         let array = [...this.state.countCustomHolidaysNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
             if (holidays !== "") {
                let currentdate = new Date(holidays); 
                let date = currentdate.getDate() + "-" + (currentdate.getMonth()+1)  + "-"  + currentdate.getFullYear()
                   
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = date;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (holidays !== "") {
                let currentdate = new Date(holidays); 
                let date = currentdate.getDate() + "-" + (currentdate.getMonth()+1)  + "-"  + currentdate.getFullYear()
                 
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ date ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    statusInputRemove = (number) => {

        let array = [...this.state.countCustomHolidaysNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countCustomHolidaysNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }

    setDaysWiseCustomHours = (date, stat) => {
        if(date !== null){
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            this.setState({
                [stat]: strTime
            },()=>{
                this.setCustomWorkingHours()
            })
        }
    }


    setCustomWorkingHours = () => {
        this.setState({
            customWorkingHours: [
                {
                    "day": "Sunday",
                    "startTime": this.state.sun_starttime,
                    "endTime": this.state.sun_endtime,
                    "isWorkingDay": this.state.sunday_checked
                }, {
                    "day": "Monday",
                    "startTime": this.state.mon_starttime,
                    "endTime": this.state.mon_endtime,
                    "isWorkingDay": this.state.monday_checked
                }, {
                    "day": "Tuesday",
                    "startTime": this.state.tue_starttime,
                    "endTime": this.state.tue_endtime,
                    "isWorkingDay": this.state.tuesday_checked
                }, {
                    "day": "Wednesday",
                    "startTime": this.state.wed_starttime,
                    "endTime": this.state.wed_endtime,
                    "isWorkingDay": this.state.wednesday_checked
                }, {
                    "day": "Thursday",
                    "startTime": this.state.thu_starttime,
                    "endTime": this.state.thu_endtime,
                    "isWorkingDay": this.state.thursday_checked
                }, {
                    "day": "Friday",
                    "startTime": this.state.fri_starttime,
                    "endTime": this.state.fri_endtime,
                    "isWorkingDay": this.state.friday_checked
                }, {
                    "day": "Saturday",
                    "startTime": this.state.sat_starttime,
                    "endTime": this.state.sat_endtime,
                    "isWorkingDay": this.state.saturday_checked
                }
            ]
        })
    }

    
    saveGroup = () => {
        let {
            name,
            noofinstances,
            checkHourlyRate,
            hourlyrate,
            checkCustomWorkingHours,
            customWorkingHours,
            checkCustomHolidays,
            typeFieldValues,
            colorCode
        } = this.state


         /* Remove Null From State */
         let holidays = [];
         typeFieldValues.forEach((val) => {
             if(val !== null){
                holidays.push(val);
             }
         });
         /* Remove Null */

        let colorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/

        if(name === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter name"
            })
        }else if(noofinstances === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter number of instances"
            })
        }else if(checkHourlyRate === true && hourlyrate === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter hourly rate"
            }) 
        }else if(colorCode === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter/select color"
            }) 
        }else if(!colorRegex.test(colorCode)){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please valid color code"
            }) 
        }else{

            this.setState({ isLoading: true, error: false })
            APIManager.callCreateWorkstationGroup(name, noofinstances, checkHourlyRate, hourlyrate, checkCustomWorkingHours, customWorkingHours, checkCustomHolidays, typeFieldValues, colorCode)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    localStorage.setItem("createNewWorkstationgroup", true)
                    localStorage.setItem("workstationGroupId", response.data._id)
                    this.props.history.push("/editwsg")
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }
    
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstationGroups={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a workstation group</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveGroup()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Name</div>
                                    <div className="value">
                                        <input type="text" className="fieldheightdecr" autoComplete="off" name="name" value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Number of instances</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr" 
                                            autoComplete="off"
                                            value={this.state.noofinstances} 
                                            onChange={(e) => {
                                                let no = e.target.value
                                                if(no <= 20)
                                                    this.setState({ noofinstances: parseInt(no) ? parseInt(no) : "" })
                                            }} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Hourly rate</div>
                                    <div className="__formTextVal">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem" 
                                            checked={this.state.checkHourlyRate} 
                                            onChange={()=>
                                                this.setState({ 
                                                    checkHourlyRate: !this.state.checkHourlyRate,
                                                    hourlyrate: "",
                                                })
                                            } 
                                        /> Yes
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                {
                                    this.state.checkHourlyRate &&
                                        <div className="add_inner">
                                            <div className="title"></div>
                                            <div className="value">
                                                <input type="text" value={this.state.hourlyrate} onChange={(e)=>this.setState({ hourlyrate: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} style={{ width: "40%"}} />
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                }
                                
                                <div className="add_inner">
                                    <div className="title">Custom working hours</div>
                                    <div className="__formTextVal">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem" 
                                            checked={this.state.checkCustomWorkingHours} 
                                            onChange={()=>
                                                this.setState({ 
                                                    checkCustomWorkingHours: !this.state.checkCustomWorkingHours
                                                },()=>{
                                                    if(this.state.checkCustomWorkingHours){
                                                        this.getCompanyWorkingHours()
                                                    }
                                                })
                                            } 
                                        /> Yes
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                {
                                    this.state.checkCustomWorkingHours &&
                                        <div className="add_inner">
                                            <div className="title"></div>
                                            <div className="value">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.sunday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                sunday_checked: !this.state.sunday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Sunday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.sun_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'sun_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.sunday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.sun_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'sun_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.sunday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.monday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                monday_checked: !this.state.monday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Monday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.mon_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'mon_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.monday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.mon_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'mon_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.monday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.tuesday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                tuesday_checked: !this.state.tuesday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Tuesday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.tue_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'tue_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.tuesday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.tue_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'tue_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.tuesday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.wednesday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                wednesday_checked: !this.state.wednesday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Wednesday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.wed_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'wed_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.wednesday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.wed_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'wed_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.wednesday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.thursday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                thursday_checked: !this.state.thursday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Thursday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.thu_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'thu_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.thursday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.thu_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'thu_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.thursday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.friday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                friday_checked: !this.state.friday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Friday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.fri_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'fri_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.friday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.fri_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'fri_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.friday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ width: 120 }}>
                                                                <div className="position-relative __working_hours_check_wsg">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        className="__formCheckboxElem mt-1 h-auto" 
                                                                        checked={this.state.saturday_checked} 
                                                                        onChange={()=>
                                                                            this.setState({ 
                                                                                saturday_checked: !this.state.saturday_checked
                                                                             },()=>{
                                                                                this.setCustomWorkingHours()
                                                                            })
                                                                        } 
                                                                    /> <span>Saturday</span>
                                                                </div>
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.sat_starttime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'sat_starttime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.saturday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                            <td className="position-relative" style={{ width: 120 }}>
                                                                <DatePicker 
                                                                    selected={new Date(this.state.currentdate +" "+this.state.sat_endtime)}
                                                                    onChange={date => this.setDaysWiseCustomHours(date,'sat_endtime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    dateFormat="h:mm aa"
                                                                    disabled={!this.state.saturday_checked}
                                                                />
                                                                <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                            </td>
                                                        </tr>

                                                       
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                }

                                <div className="add_inner">
                                    <div className="title">Custom holidays</div>
                                    <div className="__formTextVal">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem" 
                                            checked={this.state.checkCustomHolidays} 
                                            onChange={()=>
                                                this.setState({ 
                                                    checkCustomHolidays: !this.state.checkCustomHolidays,
                                                    countCustomHolidaysNumber: [0],
                                                    addTypeFields: [],
                                                    count: 0,
                                                    typeFieldValues: [],
                                                    generateNewType: true,
                                                },()=>{
                                                    this.firstHolidayInput()
                                                })
                                            } 
                                        /> Yes
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {
                                    this.state.checkCustomHolidays &&
                                    <div className="add_inner">
                                        <div className="title"></div>
                                        <div className="value">
                                            <table style={{ width: 220 }}>
                                                <tbody>
                                                    {
                                                        this.state.addTypeFields
                                                    }
                                                </tbody>
                                            </table> 
                                        </div> 
                                        <div className="clear"></div>
                                    </div> 
                                }
                                <div className="add_inner">
                                    <div className="title">Colors</div>
                                    <div className="value">
                                        {
                                            colors.map((data) => (
                                                <div key={data.name} style={{ backgroundColor: data.code }} className={this.state.colorCode === data.code ? "color__code_for_group selected" :  "color__code_for_group" } onClick={()=>this.setState({ colorCode: data.code })}></div>
                                            ))
                                        }
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title"></div>
                                    <div className="value">
                                        <input type="text" style={{ width: "40%"}} value={this.state.colorCode} onChange={(e)=>this.setState({ colorCode: e.target.value })} /> 
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                            </div>
                            <div className="clearfix"></div>
                        </div>

                        
                            
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveGroup()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default createWorkstationGroup;