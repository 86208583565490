import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FiLogOut } from 'react-icons/fi'
import * as LS from './../../constants/SetLocalstorage'
import * as APIManager from './../../APIManager/APIManager'
import TaskManager from '../TaskManager';
import * as Ai from 'react-icons/ai'
// import { Offline, Online } from "react-detect-offline";


const pages = [
    {
        "name": "Dashboard",
        "to": "/dashboard",
        "title": "Dashboard",
        "class": "menu dashboard",
        "isDashboard": true,
        "hide": false
    }, {
        "name": "CRM",
        "to": "/quotations",
        "title": "CRM",
        "class": "menu co",
        "isCRM": true,
        "hide": false
    }, {
        "name": "My Production Plan",
        "to": "/myproductionplan",
        "title": "My Production Plan",
        "class": "menu mpp",
        "isMyProductionPlan": true,
        "hide": false
    }, {
        "name": "Production Planning",
        "to": "/manufacturingorders",
        "title": "Production Planning",
        "class": "menu mo",
        "isProductionPlanning": true,
        "hide": false
    }, {
        "name": "Stock",
        "to": "/items",
        "title": "Stock",
        "class": "menu stock",
        "isStock": true,
        "hide": false
    }, {
        "name": "Procurement",
        "to": "/purchaseorders",
        "title": "Procurement",
        "class": "menu procure",
        "isProcurement": true,
        "hide": false
    }, {
        "name": "Accounting",
        "to": "/expenses",
        "title": "Accounting",
        "class": "menu account",
        "isAccounting": true,
        "hide": false
    }, {
        "name": "Settings",
        "to": "/systemsettings",
        "title": "Settings",
        "class": "menu setting",
        "isSettings": true,
        "hide": false
    }
]



class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userEmail: "loading",
            tabs: [],
            displayTabs: [],
            height: window.innerHeight,
            width: 0,
            scrollHide: "",
            tHide: "",
            sHide: ""
        }
       
    }

    componentDidMount() {

    
        let resp = localStorage.getItem('userdata')
        if (resp) {
            let email = JSON.parse(LS.getLS('userdata')).email
            this.setState({
                userEmail: email
            }, () => {
                let permissions = JSON.parse(localStorage.getItem("permissions"))
                if (permissions.length > 0) {
                    if (permissions.length === 1) {
                        let isWorker = permissions.find(o => o.slug === 'ProductionOperations');
                        if (isWorker === undefined) {
                            this.getUserDetails()
                        } else {
                            this.props.logout.replace('/productionoperations')
                        }
                    } else {
                        this.getUserDetails()
                    }
                } else {
                    this.getUserDetails()
                }
            })
        } else {
            this.props.logout.push('login')
        }

        window.addEventListener('scroll', () => {
            
            if (window.scrollY <= 523) {
                this.setState({
                    tHide: "none",
                    sHide: "block"
                })
            } else {
                this.setState({
                    sHide: "none",
                    tHide: "block"
                })
            }

        });


    }

    getUserDetails = () => {
        let resp = localStorage.getItem('userdata')
        let response = JSON.parse(resp)
        if (response.role === "admin") {
            this.setState({
                tabs: pages
            }, () => {
                this.setDisplayTabs()
            })
        } else {
            this.setUserDetails()
            this.checkPermissions()
        }
    }

    setUserDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProfile()
            .then(response => {
                console.log(response);
                localStorage.setItem("permissions", JSON.stringify(response.permissions))
                this.setState({
                    isLoading: false,
                })
            })
            .catch(errors => {
                console.log('errors ', errors);
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if (localPermissions !== null) {
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if (permissions !== undefined && permissions.length > 0) {
                let updatedRecord = pages.map(obj => {
                    let permit = permissions.find(lo => lo.group === obj.name)
                    if (permit !== undefined) {
                        obj.to = permit.link
                        return obj
                    }
                });
            
                this.setState({
                    tabs: updatedRecord
                }, () => {
                    this.setDisplayTabs()
                })
            } else {
                let updatedRecord = pages.map(obj => {
                    if (obj.permission === false) {
                        return obj
                    }
                });
                this.setState({
                    tabs: updatedRecord
                }, () => {
                    this.setDisplayTabs()
                })
            }
        } else {
            this.props.logout.push('login')
        }
    }

    setDisplayTabs = () => {
        let {
            isDashboard,
            isCRM,
            isMyProductionPlan,
            isProductionPlanning,
            isStock,
            isProcurement,
            isAccounting,
            isSettings
        } = this.props

        let dispTabs = []

        this.state.tabs.map((data, i) => {
            if (data !== undefined) {

                let isActive = ""

                if (data.name === "Dashboard" && isDashboard === true)
                    isActive = "active"

                if (data.name === "CRM" && isCRM === true)
                    isActive = "active"

                if (data.name === "My Production Plan" && isMyProductionPlan === true)
                    isActive = "active"

                if (data.name === "Production Planning" && isProductionPlanning === true)
                    isActive = "active"

                if (data.name === "Stock" && isStock === true)
                    isActive = "active"

                if (data.name === "Procurement" && isProcurement === true)
                    isActive = "active"

                if (data.name === "Accounting" && isAccounting === true)
                    isActive = "active"

                if (data.name === "Settings" && isSettings === true)
                    isActive = "active"

                dispTabs.push(
                    <Link
                        key={data.name}
                        to={data.to}
                        title={data.title}
                        className={data.hide ? "d-none" : data.class + " " + isActive}
                    ></Link>
                )
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }



    scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth"
        })

    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

    }

   

    logout = (history) => {
        localStorage.clear();
        history.replace('/login')
    }



    render() {
        let {
            goBack,
            logout,
            isHelpCenter
        } = this.props
        return (
            <div className="header bg-white">
                {/* <Offline><div className="__offline_message">You are currently offline. Please check your internet connection.</div></Offline> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 col-sm-8 col-md-9 col-xs-12">
                            <div className="menus">
                                <div title="Back" className="menu back" onClick={goBack}></div>
                                <Link to="/home" title="Start Page" className="menu home"></Link>
                                {/* <Link to="/dashboard" title="Dashboard" className={isDashboard ? "menu dashboard active" : "menu dashboard"}></Link> */}
                                {
                                    this.state.displayTabs
                                }

                                {/* <Link to="/demodata" title={"Demo Data & Videos"} className={isDemoData ? "menu demodata active" : "menu demodata"}></Link> */}
                                {/* <Link to="/freeuse" title="Free Use" className={isFreeUse ? "menu freeuse active" : "menu freeuse"}></Link> */}
                                <Link to="/helpcenter" title="Help Center" className={isHelpCenter ? "menu helpcenter active" : "menu helpcenter" }></Link>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-4 col-md-3 col-xs-12">
                            <div className="text-right">
                                <div className="header-right-tab user-loging pr-0">{this.state.userEmail} <div className="inline" onClick={() => this.logout(logout)}><FiLogOut className="user-logout" /></div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <TaskManager
                    history={logout}
                />


                <div className="scrollView">
                    <Ai.AiFillDownCircle className={this.state.height <= 523 ? "scrollDown" : "scrollDownShow"} onClick={() => this.scrollToBottom()} style={{ display: this.state.sHide }} />
                    <Ai.AiFillUpCircle className={this.state.height >= 523 ? "scrollDown" : "scrollDownShow"} onClick={() => this.scrollToTop()} style={{ display: this.state.tHide }} />

                </div>

            </div>
        );
    }
}

export default Header;