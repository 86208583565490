import React, { Component } from 'react';
import * as APIManager from './../../APIManager/APIManager'
import { FiX } from "react-icons/fi";
class AddTermsAndConditions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            term: "",
            condition: this.props.condition,
            termsFor: this.props.termsFor,
            allterms: [],
            termOptions: [],
        }
    }

    componentDidMount(){
        this.getAllTerms()
    }

    getAllTerms = () => {
        APIManager.callAllTermsCondition("", this.state.termsFor, "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                allterms: response,
                term: this.props.term
            },()=>{
                if(this.state.term !== ""){
                    this.singleTermsDetail(this.state.term)
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    singleTermsDetail = (termId) => {
        if(termId !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetSingleTermDetail(termId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    termOptions: response.option,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    render() {
        let {
            fun_termsFieldChange,
            fun_termsFieldRemove,
            componentNum,
        } = this.props
        return (
            <div>
                <div className="__terms_value_div __terms_options">
                    <select 
                        value={this.state.term}
                        onChange={(e)=>
                            this.setState({ 
                                term: e.target.value,
                                condition: "", 
                            },()=> { 
                                this.singleTermsDetail(e.target.value) 
                                fun_termsFieldChange(this.state.term, "", componentNum)
                            })
                        }    
                    >
                        <option value="">Select terms</option>
                        {
                            this.state.allterms !== null &&
                            this.state.allterms.map((data, i) => (
                                <option key={'__term'+data._id} value={data._id}>{data.terms}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="__terms_value_div">
                    <select onChange={(e)=>
                        this.setState({ 
                            condition: e.target.value 
                        },()=>{
                            fun_termsFieldChange(this.state.term, this.state.condition, componentNum)
                        })
                    } value={this.state.condition}>
                        <option value="">Select condition</option>
                        {
                            this.state.termOptions !== null &&
                            this.state.termOptions.map((data, i) => {
                                let val = ""
                                if(data.fields !== undefined){
                                    val = data.fields
                                }else if(data.terms !== undefined){
                                    val = data.terms
                                }else{
                                    val = data
                                }
                                return <option key={'__options'+data+i} value={val}>{val}</option>
                            })
                        }
                    </select>
                </div>
                {
                    this.state.condition !== "" &&
                    <div className="__terms_remove_btn">
                        <FiX className="extraFieldCloseButton" onClick={fun_termsFieldRemove.bind(this,componentNum)} />
                    </div>
                }
                <div className="clear"></div>
            </div>
        );
    }
}

export default AddTermsAndConditions;