import React from 'react';
import { Link } from 'react-router-dom';


const pages = [
    {
        "to": "/manufacturingorders",
        "class": "innerHeaderList",
        "name": "Manufacturing orders",
        "isManufacturingOrders": true,
        "hide": false
    }, {
        "to": "/productionschedule",
        "class": "innerHeaderList",
        "name": "Production schedule",
        "isProductionSchedule": true,
        "hide": true
    }, {
        "to": "/workstations",
        "class": "innerHeaderList",
        "name": "Workstations",
        "isWorkstations": true,
        "hide": false
    }, {
        "to": "/workstationgroups",
        "class": "innerHeaderList",
        "name": "Workstation groups",
        "isWorkstationGroups": true,
        "hide": false
    }, {
        "to": "/bom",
        "class": "innerHeaderList",
        "name": "BOM",
        "isBOM": true,
        "hide": false
    }, {
        "to": "/routings",
        "class": "innerHeaderList",
        "name": "Routings",
        "isRoutings": true,
        "hide": false
    }, {
        "to": "/prooperations",
        "class": "innerHeaderList",
        "name": "Production operations",
        "isProOperations": true,
        "hide": false
    }, {
        "to": "/planningstatistics",
        "class": "innerHeaderList",
        "name": "Statistics",
        "isStatistics": true,
        "hide": true
    }
]

// , {
//     "to": "/rejecteditems",
//     "class": "innerHeaderList",
//     "name": "Rejected items",
//     "isRejectedItems": true,
//     "hide": false
// }

export default class ProductionPlanningHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tabs: [],
            displayTabs: []
        }
    }

    componentDidMount() {
        this.checkPermissions()
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if (localPermissions !== null) {
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if (permissions !== undefined && permissions.length > 0) {
                let updatedRecord = pages.map(obj => {
                    let permit = permissions.find(lo => lo.name === obj.name)
                    if (permit !== undefined)
                        return obj
                });
                this.setState({
                    tabs: updatedRecord
                }, () => {
                    this.setDisplayTabs()
                })
            } else {
                this.setState({
                    tabs: pages
                }, () => {
                    this.setDisplayTabs()
                })
            }
        }
    }

    setDisplayTabs = () => {
        let {
            isManufacturingOrders,
            isProductionSchedule,
            isWorkstations,
            isWorkstationGroups,
            isBOM,
            isRoutings,
            isStatistics,
            isProOperations,
            isRejectedItems
        } = this.props;

        let dispTabs = []

        this.state.tabs.map((data, i) => {
            if (data !== undefined) {

                let isActive = ""

                if (data.name === "Manufacturing orders" && isManufacturingOrders === true)
                    isActive = "active"

                if (data.name === "Production schedule" && isProductionSchedule === true)
                    isActive = "active"

                if (data.name === "Workstations" && isWorkstations === true)
                    isActive = "active"

                if (data.name === "Workstation groups" && isWorkstationGroups === true)
                    isActive = "active"

                if (data.name === "BOM" && isBOM === true)
                    isActive = "active"

                if (data.name === "Routings" && isRoutings === true)
                    isActive = "active"

                if (data.name === "Production operations" && isProOperations === true)
                    isActive = "active"

                if (data.name === "Statistics" && isStatistics === true)
                    isActive = "active"

                if (data.name === "Rejected items" && isRejectedItems === true)
                    isActive = "active"

                dispTabs.push(
                    <Link
                        key={data.name}
                        to={data.to}
                        className={data.hide ? "d-none" : data.class + " " + isActive}
                    >
                        {data.name}
                    </Link>
                )
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }


    render() {
        return (
            <div className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                            <div className="innerHeader">
                                {this.state.displayTabs}
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
