let helper = {
    // Color
    clr_primary: "#003557",
    clr_primarylight: "#256a97",
    clr_secondary: "#0079b4",
    clr_secondarydark: "#5c77f1",
    clr_secondarylight: "#2fb8de",
    clr_gray: "#c1c1c1",
    clr_graylight: "#eceff2",
    clr_white: "#ffffff", 
    clr_black: "#000000",
    clr_danger: "#e21e15",
    clr_success: "#64d762",
    
    // Keys
    version: "1.0.0",

}

module.exports = helper;