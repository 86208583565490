import React, { Component } from 'react';
import { PDFViewer, Page, Text, View, Image, Font, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { AKAM } from './../../../../assets/Images'

import * as Images from './../../../../assets/Images'

import * as APIManager from './../../../../APIManager/APIManager'

class index extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            companyName: '',
            companyAddress: '',
            companyName: '',
            contactDetails: '',
            email: '',
            website: '',
            website2: '',
            phone: '',
            panNo: '',
            regNumber: '',
            telephone: '',
            regdNo: '',
            gstin: '',
            ecc: '',
            bank_name: '',
            bank_accno: '',
            bank_branch: '',
            bank_rtgsneft: '',
            bank_swiftcode: '',
            companyLogo: '',
            logoSrc: '',
            address_line1: '',
            address_line2: '',
            address_zone: '',
            address_city: '',
            address_state: '',
            address_country: '',
            address_pin: ''
        }
    }

    componentDidMount(){
        this.getCompanyDetails()
    }

    getCompanyDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCompanyDetails()
        .then(response => {

            this.setState({
                isLoading: false,
                companyName: response.data.companyName ? response.data.companyName : "",
                contactDetails: response.data.contactName ? response.data.contactName : "",
                email: response.data.email ? response.data.email : "",
                website: response.data.website ? response.data.website : "",
                website2: response.data.website2 ? response.data.website2 : "",
                phone: response.data.phone ? response.data.phone : "",
                panNo: response.data.panNo ? response.data.panNo : "",
                regNumber: response.data.registrationNumber ? response.data.registrationNumber : "",
                telephone: response.data.telephone ? response.data.telephone : "",
                regdNo: response.data.regdNo ? response.data.regdNo : "",
                gstin: response.data.gstin ? response.data.gstin : "",
                ecc: response.data.ecc ? response.data.ecc : "",
                bank_name: response.data.bank_name ? response.data.bank_name : "",
                bank_accno: response.data.bank_accno ? response.data.bank_accno : "",
                bank_branch: response.data.bank_branch ? response.data.bank_branch : "",
                bank_rtgsneft: response.data.bank_rtgsneft ? response.data.bank_rtgsneft : "",
                bank_swiftcode: response.data.bank_swiftcode ? response.data.bank_swiftcode : "",
                companyLogo: response.data.companyLogo ? response.data.companyLogo : Images.PlaceholderLogo,
                logoSrc: response.data.companyLogo ? response.data.companyLogo : Images.PlaceholderLogo,
                address_line1: response.data.address_line1 ? response.data.address_line1 : "",
                address_line2: response.data.address_line2 ? response.data.address_line2 : "",
                address_zone: response.data.address_zone ? response.data.address_zone : "",
                address_city: response.data.address_city ? response.data.address_city : "",
                address_state: response.data.address_state ? response.data.address_state : "",
                address_country: response.data.address_country ? response.data.address_country : "",
                address_pin: response.data.address_pin ? response.data.address_pin : ""
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors,
                setProductGroup: true 
            })
        });
    }

    onFileChange = event => {
        let that = this;
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ logoSrc: e.target.result })
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    

    render() {
        return (
            <div className="add_inner">
                <div className="value">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <input 
                                    type='text'
                                    placeholder='Company name'
                                    value={this.state.companyName}
                                    onChange={(e)=>this.setState({ companyName: e.target.value })}
                                />
                                <input 
                                    type='text'
                                    placeholder='Company address'
                                    value={this.state.companyAddress}
                                    onChange={(e)=>this.setState({ companyAddress: e.target.value })}
                                />
                                <input
                                    className='input-file add_inner'
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={this.onFileChange}
                                />
                            </div>
                            <div className='col-lg-6'>
                                <PDFViewer style={styles.container}>
                                    <Document>
                                        <Page style={{ paddingTop: 10, paddingBottom: 130, paddingHorizontal: 35, fontSize: 12, fontFamily: 'CalibriRegular',}}>
                                            {/* Fixed Header Start */}
                                            <View style={styles.header} fixed>
                                                <View style={styles.headerLeft}>
                                                    <View style={styles.headerLeftContent}>
                                                        <Text style={styles.company_name}>{this.state.companyName}</Text>
                                                        <Text style={styles.company_addr}>{this.state.companyAddress}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.headerRight}>
                                                    <Image src={this.state.logoSrc} style={styles.headerlogo} /> 
                                                </View>
                                            </View>
                                            {/* Fixed Header End */}

                                            {/* Fixed Footer Start */}
                                            <View style={styles.footer} fixed>
                                                <View style={styles.footerTopSection}>
                                                    <Text style={styles.footerQuotationNumber}>PDF Type/Status & No.(Dynamic)</Text>
                                                    <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                                        `Page ${pageNumber} of ${totalPages}`
                                                    )} />
                                                </View> 
                                                <View>
                                                    <Text style={[styles.footer_company_name,{fontFamily: this.state.headerFont, color: this.state.footerColor}]}>{this.state.companyName}</Text>
                                                    <View style={styles.footer_details}>
                                                        <View style={styles.footer_detail_data1}>
                                                            <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                                            <Text style={styles.footer_detail_text}>Address Line 1</Text>
                                                            <Text style={styles.footer_detail_text}>Address Line 2</Text>
                                                            <Text style={styles.footer_detail_text}>City</Text>
                                                            <Text style={styles.footer_detail_text}>State Country Pin</Text>
                                                        </View>
                                                        <View style={styles.footer_detail_data2}>
                                                            <Text style={styles.footer_detail_text}>Regd. No.: 1234</Text>
                                                            <Text style={styles.footer_detail_text}>GSTIN: 123545</Text>
                                                            <Text style={styles.footer_detail_text}>ECC: 298399</Text>
                                                            <Text style={styles.footer_detail_text}>PAN: 1222</Text>
                                                        </View>
                                                        <View style={styles.footer_detail_data3}>
                                                            <Text style={styles.footer_detail_text}>Bank Name</Text>
                                                            <Text style={styles.footer_detail_text}>A/c No.</Text>
                                                            <Text style={styles.footer_detail_text}>Branch</Text>
                                                            <Text style={styles.footer_detail_text}>RTGS/NEFT</Text>
                                                            <Text style={styles.footer_detail_text}>Swift Code</Text>
                                                        </View>
                                                        <View style={styles.footer_detail_data4}>
                                                            
                                                            <Text style={styles.footer_detail_text}>
                                                                t: +91 00000 00000
                                                            </Text>
                                                        
                                                            <Text style={styles.footer_detail_text}>
                                                                m: +91 00000 00000
                                                            </Text>
                                                        
                                                            <Text style={styles.footer_detail_text}>
                                                                e: test@gmail.com
                                                            </Text>
                                                                                                
                                                            <Text style={styles.footer_detail_text}>
                                                                w | www.websitename.com
                                                            </Text>

                                                            <Text style={styles.footer_detail_text}>
                                                                w |  www.websitename2.com
                                                            </Text>
                                            
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                            {/* Fixed Footer End */}
                                        </Page>
                                    </Document>
                                </PDFViewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: window.innerHeight,
    },
    header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        paddingBottom: 5,
        marginBottom: 5,
    },
    headerLeft: {
       flex: 0.90,
       position: "relative",
    },
    headerLeftContent: {
        position: "absolute",
        bottom: -5,
    },
    headerRight: {
        flexDirection: "row",
        justifyContent: "space-between",
        flex: 0.10,
    },
    company_name: {
        fontSize: 13,
        textTransform: "uppercase"
    },  
    company_addr: {
        fontSize: 10,
    },
    headerlogo: {
        width: 80,
        height: "auto",  
    },
    footer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 20,
        backgroundColor: "#ffffff"
    },
    footerQuotationNumber: {
        fontSize: 11,
        textAlign: 'left',
        textTransform: "capitalize"
    },
    footerPageNumber: {
        fontSize: 11,
        right: 0,
        top: 4,
        position: 'absolute',
        textAlign: 'right'
    },
    footerTopSection: {
        position: 'relative',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginHorizontal: 35,
        paddingTop: 4
    },
    footer_company_name: {
        fontSize: 11,
        fontWeight: "500",
        marginTop: 5,
        marginBottom: 5,
        marginHorizontal: 35,
        textTransform: "uppercase"
    },
    footer_details: {
        flexDirection: "row",
        marginHorizontal: 35,
    },
    footer_detail_text: {
        fontSize: 9,
    },
    footer_detail_data1: {
        flex: 0.23
    },
    footer_detail_data2: {
        flex: 0.30
    },
    footer_detail_data3: {
        flex: 0.26
    },
    footer_detail_data4: {
        flex: 0.20
    },
})

export default index;