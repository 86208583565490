import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import CreateCustomerOrders from './create_new_order'
import * as Ai from 'react-icons/ai'


class CreateNewOrder extends Component {


    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isQuotations={true}/>
                <CreateCustomerOrders history={this.props.history} goBack={()=>this.goBack()} />

            </div>
        );
    }
}

export default CreateNewOrder;