import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Pagination from "react-js-pagination";
import * as Fi from 'react-icons/fi'
import * as APIManager from './../../../APIManager/APIManager'
import TableLoading from '../../../helper/TableLoading';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import {
    ImArrowDown2,
} from "react-icons/im";


class WorkstationGroups extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            number: "",
            name: "",
            minValue: "",
            maxValue: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,

            responseData: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
         
            const sortedOrders = this.state.responseData.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    console.log(dateA, dateB);
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ responseData: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************


    getAllWorkStationGroup = () => {

        let {
            number,
            name,
            minValue,
            maxValue,
            length,
            start
        } = this.state

        this.setState({ isLoading: true, error: false })
        APIManager.callAllWorkstationGroup(number, name, minValue, maxValue, length, start)
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseData: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()

                    if (this.state.fieldName) {
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });

    }

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'name': this.state.name,
            'minValue': this.state.minValue,
            'maxValue': this.state.maxValue,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
            'length': this.state.length,
            'start': this.state.start,
            'activePage': this.state.activePage,
        }

        localStorage.setItem("productionPlaningWorkstationsgroup", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlaningWorkstationsgroup"))
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                number: filterData.number,
                name: filterData.name,
                minValue: filterData.minValue,
                maxValue: filterData.maxValue,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
                length: filterData.length,
                start: filterData.start
            }, () => {
                this.getAllWorkStationGroup()
            })
        } else {
            this.getAllWorkStationGroup()
        }
    }




    addWorkstationGroup = () => {
        let slug = "ProductionPlanning_Workstationsgroups"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createwsg"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editWorkStationGroup = (id) => {

        localStorage.setItem("workstationGroupId", id)
        this.props.history.push({
            pathname: "/editwsg"
        })


    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            isLoading: true,
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllWorkStationGroup()
        });
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlaningWorkstationsgroup")
        this.setState({
            number: "",
            name: "",
            minValue: "",
            maxValue: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getAllWorkStationGroup()
        })
    }


    exportAsPDF = () => {
        let responseData = this.state.responseData;
        console.log(responseData);

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Production Planning - Workstations Groups";

        const headers = [[
            "Number",
            "Name",
            "Number Of Instances ",
        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.name,
            elt.numberOfInstances,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("workstation group.pdf")
    }

    exportAsCSV = () => {
        let responseData = this.state.responseData;
        const title = "Production Planning - Workstations Group";
        const headers = [[
            "Number",
            "Name",
            "Number Of Instances ",
        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.name,
            elt.numberOfInstances,
        ]);
        const options = {
            filename: 'workstation group',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                {/* {
                    this.state.isLoading &&
                    <Loading />
                } */}
                <Header logout={this.props.history} goBack={() => this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstationGroups={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.ProductionPlanning.WorkstationGroups}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <    ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 15 }}><div onClick={() => this.addWorkstationGroup()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)} style={{ width: 150 }}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('name', 'notDate', false)}><span>Name</span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('numberOfInstances', 'notDate', false)} style={{ width: 150 }}><span>Number of instances</span> {this.state.fieldName === "numberOfInstances" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "numberOfInstances" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    {/* <th className="tableHeader" style={{ width: 150 }}><span>Number</span></th>
                                    <th className="tableHeader"><span>Name</span></th>
                                    <th className="tableHeader" style={{ width: 150 }}><span>Number of instances</span></th> */}
                                    {/* <th style={{width: 35}}></th> */}
                                    <th className="tableHeader tableHeadButton" style={{ width: 35 }}><div onClick={() => this.addWorkstationGroup()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllWorkStationGroup() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => { this.getAllWorkStationGroup() })} /></th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minValue} onChange={(e) => this.setState({ minValue: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllWorkStationGroup() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxValue} onChange={(e) => this.setState({ maxValue: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllWorkStationGroup() })} />
                                    </th>
                                    {/* <th className="tableElemContain tableAddButton"><div className="cursor-pointer"></div></th> */}
                                    <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><Fi.FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={5} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseData.map((data, i) => (
                                                <tr onClick={() => this.editWorkStationGroup(data._id)} key={data._id}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.numberOfInstances}</td>
                                                    <td>{/*<Fi.FiBarChart className="editCusomerBtn" />*/}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkstationGroups;