import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../../components' 
import Select2 from './../../../../../lib/select2/select2';
import './../../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../../lib/react-datepicker.min.css';
import * as Ai from 'react-icons/ai'
import * as APIManager from './../../../../../APIManager/APIManager'
import Loading from './../../../../../helper/loading'
import StateWiseGSTCode from './StateWiseGSTCode.json'
import { convertDateToDDMMYYYY } from '../../../../../constants/DateFormatter';

const subTypeOptions = {
    Outward: {
        Supply: [
            { id: 'INV', text: 'Tax Invoice'},
            { id: 'BIL', text: 'Bill of Supply'}
        ],
        Export: [
            { id: 'INV', text: 'Tax Invoice'},
            { id: 'BIL', text: 'Bill of Supply'}
        ],
        JobWork: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        SKD: [
            { id: 'INV', text: 'Tax Invoice'},
            { id: 'BIL', text: 'Bill of Supply'},
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        Recipient: [
            { id: 'CHL', text: 'Delivery Challan'},
            { id: 'OTH', text: 'Others'}
        ],
        ForOwnUse: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        Exhibition: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        LineSales: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        Others: [
            { id: 'CHL', text: 'Delivery Challan'},
            { id: 'OTH', text: 'Others'}
        ],
    },
    Inward: {
        Supply: [
            { id: 'INV', text: 'Tax Invoice'},
            { id: 'BIL', text: 'Bill of Supply'}
        ],
        Import: [
            { id: 'BOE', text: 'Bill of Entry'}
        ],
        SKD: [
            { id: 'INV', text: 'Tax Invoice'},
            { id: 'BIL', text: 'Bill of Supply'},
            { id: 'BOE', text: 'Bill of Entry'},
            { id: 'CHL', text: 'Delivery Challan'}
        ], 
        JobWorkReturn: [
            { id: 'CHL', text: 'Delivery Challan'}
        ], 
        SalesReturn: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        Exhibition: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        ForOwnUse: [
            { id: 'CHL', text: 'Delivery Challan'}
        ],
        Others: [
            { id: 'CHL', text: 'Delivery Challan'},
            { id: 'OTH', text: 'Others'}
        ]
    }
}

class view_e_way_bill extends Component {

    constructor(props){
        super(props)
        this.state = {

            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            debitNoteId: this.props.location.state.debitNoteId,
            CO_InvoiceId: "",
            eInvoiceDetail: [],
            customerId: "",
            number: "",
            IRN: "",

            supplyType: 'O',
            subType: '',
            subDesc: '',
            documentTypeOptions: [],
            documentType: '',
            transactionType: '',
            documentNo: '',
            documentDate: new Date(),

            fromName: '',
            fromGST: '',
            fromState: '',
            fromAddress: '', 
            fromAddr1: '',
            fromLoc: '',   
            fromPincode: '',
            toName: '',
            toGST: '',
            toState: '',
            toAddress: '',
            toAddr1: '',
            toAddr2: '',
            toLoc: '',
            toPincode: '',
            transporterName: '',
            transpoterID: '',
            approxDistance: '',
            transporterDocNo: '',
            vehicleType: '',
            vehicleNo: '',
            transportMode: '',
            transDate: '',

            fieldNotEditable: true,
            generatedEWayBill: false
        }
    }

    componentDidMount(){
        this.getSingleInvoice()
    }

    getSingleInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleDebitNote(this.state.debitNoteId)
        .then(response => {
            console.log('callGetSingleDebitNotecallGetSingleDebitNote', response);
            let addr_new = (response.data.deliveryAddress).split("_ ")
            let eInvoiceDetail = response.data.eInvoiceDetail

            this.setState({
                eInvoiceDetail: eInvoiceDetail,

                generatedEWayBill: response.data.eWayBillFlag ? response.data.eWayBillFlag : false,
                IRN: response.data.Irn,
                customerId: response.data.vendor,
                number: response.data.number,
                documentNo: response.data.number,
                CO_InvoiceId: response.data.customerOrderInvoiceId,

                fromName: eInvoiceDetail.BuyerDtls.LglNm,
                fromAddress: eInvoiceDetail.BuyerDtls.Addr1 +' '+ eInvoiceDetail.BuyerDtls.Loc,  
                fromAddr1: eInvoiceDetail.BuyerDtls.Addr1,
                fromLoc: eInvoiceDetail.BuyerDtls.Loc,  
                fromPincode: eInvoiceDetail.BuyerDtls.Pin,
                fromGST: eInvoiceDetail.BuyerDtls.Gstin,
                fromState: eInvoiceDetail.BuyerDtls.Gstin.substr(0,2),

                toAddress: response.data.invoiceAddress,
                toAddr1: addr_new[0],
                toAddr2: addr_new[1],
                toLoc: addr_new[3],
                toPincode: addr_new[5] ?  addr_new[5] : '',
                isLoading: false
            },()=>{
                this.getCustomerDetails(response.data.vendor)
          
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(id)
        .then(response => {
            console.log('--------------------------------', response);
            this.setState({
                isLoading: false,
                toName: response.name,
                toGST: response.gstNo,
                toState: response.gstNo.substr(0,2)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    checkGSTDetails = (gst) => {
        if(gst && gst.length >= 15) {
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(gst)
            .then(response => {
                let transName = ''
                if(response.data.tradename){
                    transName = response.data.tradename
                }else if(response.data.legalname){
                    transName = response.data.legalname
                }else{
                    transName = ''
                }
                this.setState({
                    isLoading: false,
                    error: false,
                    transporterName: transName
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    success: false,
                    errormessage: "Please enter valid GST Number"
                },()=>{
                    this.scrollToTop()
                })
            });
        }
    }

    getGSTDetails = (gst, of) => {
        
        if(gst && gst.length >= 15) {
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(gst)
            .then(response => {
                this.setState({
                    isLoading: false,
                    error: false,
                },()=>{
                    if(of === "fromGST"){
                        this.setState({
                            fromState: gst.substring(0, 2)
                        })
                    }
                    if(of === "toGST"){
                        this.setState({
                            toState: gst.substring(0, 2)
                        })
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    success: false,
                    errormessage: "Please enter valid GST Number"
                },()=>{
                    this.scrollToTop()
                })
            });
        }
    }


    generateEWayBill = () => {

        let { 
            supplyType,
            subType,
            subDesc,
            documentType,
            transactionType,
            documentNo,
            documentDate,
            IRN,
            fromName,
            fromGST,
            fromState,
            fromAddress,   
            fromAddr1,
            fromLoc, 
            fromPincode,
            toName,
            toGST,
            toState,
            toAddress,
            toAddr1,
            toAddr2,
            toLoc,
            toPincode,
            transporterName,
            transpoterID,
            approxDistance,
            transporterDocNo,
            vehicleType,
            vehicleNo,
            transportMode,
            transDate,
            fieldNotEditable,
            eInvoiceDetail
        } = this.state

        if(!fieldNotEditable){ 
            if(subType === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select sub type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(transactionType === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select transaction type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(documentType === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select document type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(documentDate === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select document date"
                },()=>{
                    this.scrollToTop()
                })
            }else if(fromName === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter name of billing from"
                },()=>{
                    this.scrollToTop()
                })
            }else if(fromGST === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter GST number for billing from"
                },()=>{
                    this.scrollToTop()
                })
            }else if(fromState === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select state of billing from"
                },()=>{
                    this.scrollToTop()
                })
            }else if(fromPincode === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter pincode for billing from"
                },()=>{
                    this.scrollToTop()
                })
            }else if(fromAddress === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter address for billing from"
                },()=>{
                    this.scrollToTop()
                })
            }else if(toName === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter name of billing to"
                },()=>{
                    this.scrollToTop()
                })
            }else if(toGST === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter gst number for billing to"
                },()=>{
                    this.scrollToTop()
                })
            }else if(toState === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select state of billing to"
                },()=>{
                    this.scrollToTop()
                })
            }else if(toPincode === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter pincode for billing to"
                },()=>{
                    this.scrollToTop()
                })
            }else if(toAddress === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter address for billing to"
                },()=>{
                    this.scrollToTop()
                })
            }else if(transpoterID === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter transporter ID"
                },()=>{
                    this.scrollToTop()
                })
            }else if(transporterName === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter transporter name"
                },()=>{
                    this.scrollToTop()
                })
            }else if(approxDistance === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please add approx distance(in km)"
                },()=>{
                    this.scrollToTop()
                })
            }else if(transportMode === "1" && vehicleType === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select vehicle type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(transportMode === "1" && vehicleNo === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter vehicle number"
                },()=>{
                    this.scrollToTop()
                })
            }else if((transportMode !== "" && transportMode !== "1") && transporterDocNo === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter transporter document number"
                },()=>{
                    this.scrollToTop()
                })
            }else if((transportMode !== "" && transportMode !== "1") && transDate === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select transporter document date"
                },()=>{
                    this.scrollToTop()
                })
            }else{

                let valDetails = eInvoiceDetail.ValDtls

                let itemObject = []

                for(let i=0;i<eInvoiceDetail.ItemList.length;i++){
                    let iObj = {
                        "productName": eInvoiceDetail.ItemList[i].PrdDesc,
                        "productDesc": eInvoiceDetail.ItemList[i].PrdDesc,
                        "hsnCode": Number(eInvoiceDetail.ItemList[i].HsnCd),
                        "quantity": eInvoiceDetail.ItemList[i].Qty,
                        "qtyUnit": eInvoiceDetail.ItemList[i].Unit,
                        "cgstRate": eInvoiceDetail.ItemList[i].CgstAmt > 0 ? eInvoiceDetail.ItemList[i].GstRt/2 : 0,
                        "sgstRate": eInvoiceDetail.ItemList[i].SgstAmt > 0 ? eInvoiceDetail.ItemList[i].GstRt/2 : 0,
                        "igstRate": eInvoiceDetail.ItemList[i].IgstAmt > 0 ? eInvoiceDetail.ItemList[i].GstRt : 0,
                        "cessRate": eInvoiceDetail.ItemList[i].CesAmt,
                        "cessNonadvol": eInvoiceDetail.ItemList[i].CesNonAdvlAmt,
                        "taxableAmount": eInvoiceDetail.ItemList[i].TotItemVal
                    }
                    itemObject.push(iObj)
                }

                let jsonObj = {
                    "supplyType": supplyType,
                    "subSupplyType": subType,
                    "subSupplyDesc": subDesc,
                    "docType": documentType,
                    "docNo": documentNo,
                    "docDate": convertDateToDDMMYYYY(documentDate),
                    "fromGstin": fromGST.toUpperCase(),
                    "fromTrdName": fromName,
                    "fromAddr1": fromAddr1.toUpperCase(),
                    "fromPlace": fromLoc,
                    "fromPincode": Number(fromPincode),
                    "actFromStateCode": Number(fromState),
                    "fromStateCode": Number(fromState),
                    "toGstin": toGST.toUpperCase(),
                    "toTrdName": toName,
                    "toAddr1": toAddr1  ,
                    "toAddr2": toAddr2,
                    "toPlace": toLoc,
                    "toPincode": Number(toPincode),
                    "actToStateCode": Number(toState),
                    "toStateCode": Number(toState),
                    "transactionType": Number(transactionType),

                    "otherValue": valDetails.OthChrg,
                    "totalValue": valDetails.AssVal,
                    "cgstValue": valDetails.CgstVal,
                    "sgstValue": valDetails.SgstVal,
                    "igstValue": valDetails.IgstVal,
                    "cessValue": valDetails.CesVal,
                    "cessNonAdvolValue": valDetails.StCesVal,
                    "totInvValue": valDetails.TotInvVal,
                    
                    "itemList": itemObject,

                    "transporterId": transpoterID.toUpperCase(),
                    "transporterName": transporterName,
                    "transDocNo": transporterDocNo,
                    "transMode": transportMode,
                    "transDistance": approxDistance.toString(),
                    "transDocDate": transDate,
                    "vehicleNo": vehicleNo,
                    "vehicleType": vehicleType
                }

                Object.keys(jsonObj).forEach((k) => jsonObj[k] == "" && delete jsonObj[k]);

                console.log( 'json of generate Eway drill',jsonObj)

                // this.callGenerateBillAPI(jsonObj)
            }

        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    callGenerateBillAPI = (jsonObj) => {
        let {
            CO_InvoiceId,
            customerId,
            creditNoteId
        } = this.state


        /* This will use in Credit E-Invoice only */
        let creditEInvoice = true

        this.setState({ isLoading: true, error: false })
        APIManager.callSaveEWayBill(CO_InvoiceId, customerId, jsonObj, creditEInvoice, creditNoteId)
        .then(response => {
            this.setState({
                isLoading: false,
                error: false,
                success: true,
                fieldNotEditable: true,
                generatedEWayBill: true,
                successmessage: response.message
            },()=>{
                this.scrollToTop()
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                success: false,
                errormessage: errors
            },()=>{
                this.scrollToTop()
            })
        });
    }

    allowedDistance = (val) => {
        if((val > 0 && val <= 4000) || val === ""){
            this.setState({ approxDistance: val })
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    setDocumentTypeOptions = () => {
        let {
            supplyType,
            subType
        } = this.state

        if(subType){
            if(supplyType === "O"){
                if(subType === "1"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.Supply,
                        documentType: 'INV',
                    })
                }else if(subType === "3"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.Export,
                        documentType: 'BIL',
                    })
                }else if(subType === "4"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.JobWork,
                        documentType: 'CHL',
                    })
                }else if(subType === "9"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.SKD,
                        documentType: 'CHL',
                    })
                }else if(subType === "11"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.Recipient,
                        documentType: 'CHL',
                    })
                }else if(subType === "5"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.ForOwnUse,
                        documentType: 'CHL',
                    })
                }else if(subType === "12"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.Exhibition,
                        documentType: 'CHL',
                    })
                }else if(subType === "10"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.LineSales,
                        documentType: 'CHL',
                    })
                }else if(subType === "8"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Outward.Others,
                        documentType: 'CHL',
                    })
                }
            }else{
                if(subType === "1"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.Supply,
                        documentType: 'INV',
                    })
                }else if(subType === "2"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.Import,
                        documentType: 'BOE',
                    })
                }else if(subType === "9"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.SKD,
                        documentType: 'BOE',
                    })
                }else if(subType === "6"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.JobWorkReturn,
                        documentType: 'CHL',
                    })
                }else if(subType === "7"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.SalesReturn,
                        documentType: 'CHL',
                    })
                }else if(subType === "12"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.Exhibition,
                        documentType: 'CHL',
                    })
                }else if(subType === "5"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.ForOwnUse,
                        documentType: 'CHL',
                    })
                }else if(subType === "8"){
                    this.setState({
                        documentTypeOptions: subTypeOptions.Inward.Others,
                        documentType: 'CHL',
                    })
                }
            }
        }
    }

    setSubTypeText = () => {
        let { subType } = this.state

        if(subType === "1"){
            this.setState({ subDesc: "Supply" })
        }
        if(subType === "2"){
            this.setState({ subDesc: "Import" })
        }
        if(subType === "3"){
            this.setState({ subDesc: "Export" })
        }
        if(subType === "4"){
            this.setState({ subDesc: "Job Work" })
        }
        if(subType === "5"){
            this.setState({ subDesc: "For Own Use" })
        }
        if(subType === "6"){
            this.setState({ subDesc: "Job Work Returns" })
        }
        if(subType === "7"){
            this.setState({ subDesc: "Sales Return" })
        }
        if(subType === "8"){
            this.setState({ subDesc: "Others" })
        }
        if(subType === "9"){
            this.setState({ subDesc: "SKD/CKD/Lots" })
        }
        if(subType === "10"){
            this.setState({ subDesc: "Line Sales" })
        }
        if(subType === "11"){
            this.setState({ subDesc: "Recipient Not Known" })
        }
        if(subType === "12"){
            this.setState({ subDesc: "Exhibition Of Fairs" })
        }
    }

    setTransporationMode = (val) => {
        if(val !== "Not Selected"){
            this.setState({
                transportMode: val,
                vehicleType: '',
                vehicleNo: '',
                transporterDocNo: '',
                transDate: ''
            })
        }else{
            this.setState({
                transportMode: '',
                vehicleType: '',
                vehicleNo: '',
                transporterDocNo: '',
                transDate: ''
            })
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isDebitNote={true} />
                <div className="main-container">
            
                 <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Generate E-Way Bill {this.state.number}</div>
                        </div>
                    </div>
                </div>
                {
                    this.state.error &&
                    <div className="editscreenerror">
                        <div className="errornotification">
                            <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                        </div>
                    </div>
                }
                {
                    this.state.success &&
                    <div className="editscreenerror">
                        <div className="successnotification">
                            <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                        </div>
                    </div>
                }

                <div className="tableContent">
                <div className="addSaveButton">
                    <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                    {
                        !this.state.generatedEWayBill &&
                        <div className="add_btnSave" onClick={()=>this.generateEWayBill()}>{this.state.fieldNotEditable ? 'Edit' : 'Generate'}</div>
                    }
                    <div className="clear"></div>
                </div>

                <div className='position-relative'>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Supply Type</div>
                            <div className="value">
                                <Select2    
                                    data={[
                                        {id: 'O', text: 'Outward'},
                                        {id: 'I', text: 'Inward'}
                                    ]}
                                    value={this.state.supplyType}
                                    options={{placeholder: 'Select Supply Type'}}
                                    onSelect={(e)=> this.setState({ 
                                        supplyType: e.target.value,
                                        subType: '',
                                        documentTypeOptions: [],
                                        documentType: '',
                                        transactionType: ''
                                    })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Sub Type</div>
                            <div className="value">
                                {
                                    this.state.supplyType === 'O' &&
                                    <Select2    
                                        data={[
                                            {id: '1', text: 'Supply'},
                                            {id: '3', text: 'Export'},
                                            {id: '4', text: 'Job Work'},
                                            {id: '9', text: 'SKD/CKD/Lots'},
                                            {id: '11', text: 'Recipient Not Known'},
                                            {id: '5', text: 'For Own Use'},
                                            {id: '12', text: 'Exhibition or Fairs'},
                                            {id: '10', text: 'Line Sales'},
                                            {id: '8', text: 'Others'}
                                        ]}
                                        value={this.state.subType}
                                        options={{placeholder: 'Select Sub Type'}}
                                        onSelect={(e)=> this.setState({ subType: e.target.value },()=>{
                                            this.setSubTypeText()
                                            this.setDocumentTypeOptions()
                                        })}
                                    />
                                }
                                {
                                    this.state.supplyType === 'I' &&
                                    <Select2    
                                        data={[
                                            {id: '1', text: 'Supply'},
                                            {id: '2', text: 'Import'},
                                            {id: '9', text: 'SKD/CKD/Lots'},
                                            {id: '6', text: 'Job work Returns'},
                                            {id: '7', text: 'Sales Return'},
                                            {id: '12', text: 'Exhibition or Fairs'},
                                            {id: '5', text: 'For Own Use'},
                                            {id: '8', text: 'Others'}
                                        ]}
                                        value={this.state.subType}
                                        options={{placeholder: 'Select Sub Type'}}
                                        onSelect={(e)=> this.setState({ subType: e.target.value },()=>{
                                            this.setSubTypeText()
                                            this.setDocumentTypeOptions()
                                        })}
                                    />
                                }
                                
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Transaction Type</div>
                            <div className="value">
                                <Select2    
                                    data={[
                                        {id: '1', text: 'Regular'},
                                        {id: '2', text: 'Bill To - Ship To'},
                                        {id: '3', text: 'Bill From - Dispatch From'},
                                        {id: '4', text: 'Combination of 2 and 3'}
                                    ]}
                                    value={this.state.transactionType}
                                    options={{placeholder: 'Select Transaction Type'}}
                                    onSelect={(e)=> this.setState({ transactionType: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                       
                    </div>
                    <div className="add_partside">
                    <div className="add_inner">
                            <div className="title">Document Type</div>
                            <div className="value">
                                <Select2    
                                    data={this.state.documentTypeOptions}
                                    value={this.state.documentType}
                                    options={{placeholder: 'Select Document Type'}}
                                    onSelect={(e)=> this.setState({ documentType: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>

                        <div className="add_inner">
                            <div className="title">Document No</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.documentNo}
                                    onChange={(e) => this.setState({ documentNo: e.target.value })}
                                    disabled={true}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Document Date</div>
                            <div className="value">
                                <DatePicker 
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={
                                        this.state.documentDate
                                    }
                                    onChange={
                                        date => this.setState({ documentDate: date })
                                    }
                                    className="filterdateoption"/>
                                <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                    <div className='clear'></div>
                    {
                        this.state.fieldNotEditable &&
                        <div className="disableFieldCover"></div>
                    }
                </div>

                <div className='sub-page-title'>Bill From:</div>
                
                <div className='position-relative'>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Name</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.fromName}
                                    onChange={(e) => this.setState({ fromName: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>

                        <div className="add_inner">
                            <div className="title">GSTIN</div>
                            <div className="value">
                                <input
                                    className='text-uppercase'
                                    type="text"
                                    value={this.state.fromGST}
                                    onChange={(e) => this.setState({ fromGST: e.target.value },()=>{ 
                                        this.getGSTDetails(this.state.fromGST,'fromGST')
                                    })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">State</div>
                            <div className="value">
                                <Select2    
                                    data={
                                        StateWiseGSTCode.map((data)=>(
                                            { id: data.gstCode, text: data.stateName }
                                        ))
                                    }
                                    value={this.state.fromState}
                                    options={{placeholder: 'Select State'}}
                                    onSelect={(e)=> this.setState({ fromState: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Pincode</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.fromPincode}
                                    onChange={(e) => this.setState({ fromPincode: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Address</div>
                            <div className="value">
                                <textarea
                                    className="fieldheightdecr" 
                                    value={this.state.fromAddress}
                                    onChange={(e) => this.setState({ fromAddress: e.target.value })}
                                ></textarea>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                    <div className='clear'></div>
                    {
                        this.state.fieldNotEditable &&
                        <div className="disableFieldCover"></div>
                    }
                </div>


                <div className='sub-page-title'>Bill To:</div>
                
                <div className='position-relative'>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Name</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.toName}
                                    onChange={(e) => this.setState({ toName: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">GSTIN</div>
                            <div className="value">
                                <input
                                    className='text-uppercase'
                                    type="text"
                                    value={this.state.toGST}
                                    onChange={(e) => this.setState({ toGST: e.target.value },()=>{
                                        this.getGSTDetails(this.state.toGST,'toGST')
                                    })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">State</div>
                            <div className="value">
                                <Select2    
                                    data={
                                        StateWiseGSTCode.map((data)=>(
                                            { id: data.gstCode, text: data.stateName }
                                        ))
                                    }
                                    value={this.state.toState}
                                    options={{placeholder: 'Select State'}}
                                    onSelect={(e)=> this.setState({ toState: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>

                        <div className="add_inner">
                            <div className="title">Pincode</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.toPincode}
                                    onChange={(e) => this.setState({ toPincode: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Address</div>
                            <div className="value">
                                <textarea
                                    className="fieldheightdecr" 
                                    value={this.state.toAddress}
                                    onChange={(e) => this.setState({ toAddress: e.target.value })}
                                ></textarea>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                    <div className='clear'></div>
                    {
                        this.state.fieldNotEditable &&
                        <div className="disableFieldCover"></div>
                    }
                </div>
                
                <div className='sub-page-title mt-2'>Transportation Details:</div>
                
                <div className='position-relative'>
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Transporter ID</div>
                            <div className="value">
                                <input
                                    type="text"
                                    className='text-uppercase'
                                    value={this.state.transpoterID}
                                    onChange={(e) => this.setState({ transpoterID: e.target.value },()=>{
                                        this.checkGSTDetails(this.state.transpoterID)
                                    })}

                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Transporter Name</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.transporterName}
                                    onChange={(e) => this.setState({ transporterName: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Approx Distance (in KM)</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.approxDistance}
                                    onChange={(e) => this.allowedDistance(e.target.value)}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Transportation Mode</div>
                            <div className="value">
                                <Select2    
                                    data={[
                                        {id: '1', text: 'Road'},
                                        {id: '2', text: 'Rail'},
                                        {id: '3', text: 'Air'},
                                        {id: '4', text: 'Ship or Ship Cum Road/Rail'},
                                        {id: null, text: 'Not Selected'}
                                    ]}
                                    value={this.state.transportMode}
                                    options={{placeholder: 'Select Transport Mode'}}
                                    onSelect={(e)=> this.setTransporationMode(e.target.value)}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>

                   

                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title">Vehicle Type</div>
                            <div className="value">
                                <Select2    
                                    data={[
                                        {id: 'R', text: 'Regular'},
                                        {id: 'O', text: 'Over Dimensional Cargo'}
                                    ]}
                                    value={this.state.vehicleType}
                                    options={{placeholder: 'Select Vehicle Type'}}
                                    onSelect={(e)=> this.setState({ vehicleType: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Vehicle No.</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.vehicleNo}
                                    onChange={(e) => this.setState({ vehicleNo: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Transporter Doc No.</div>
                            <div className="value">
                                <input
                                    type="text"
                                    value={this.state.transporterDocNo}
                                    onChange={(e) => this.setState({ transporterDocNo: e.target.value })}
                                />
                            </div>
                            <div className='clear'></div>
                        </div>
                        <div className="add_inner">
                            <div className="title">Transporter Doc Date</div>
                            <div className="value">
                                <DatePicker 
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    selected={
                                        this.state.transDate
                                    }
                                    onChange={
                                        date => this.setState({ transDate: date })
                                    }
                                    className="filterdateoption"/>
                                <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                            </div>
                            <div className='clear'></div>
                        </div>
                    </div>
                    <div className='clear'></div>
                    {
                        this.state.fieldNotEditable &&
                        <div className="disableFieldCover"></div>
                    }
                </div>
                <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        {
                            !this.state.generatedEWayBill &&
                            <div className="add_btnSave" onClick={()=>this.generateEWayBill()}>{this.state.fieldNotEditable ? 'Edit' : 'Generate'}</div>
                        }
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default view_e_way_bill;