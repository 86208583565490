import React, { Component } from 'react';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'
import { Link } from 'react-router-dom';

class CustomerStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            companyStatusData: [],
            history: this.props.history
        }
    }
    componentDidMount() {
        this.getCustomerStatus()
    }


    getCustomerStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerStatus()
        .then(response => {
            this.setState({ 
                isLoading: false,
                companyStatusData: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });  
    }

    editStatus = (id) => {
        this.state.history.push({
            pathname: '/editcustomerstatus',
            state: { statusId: id, newStatusEntry: false }
        })
    }


    render() {
        return (
            <>
            <div className="stocksetting dataDetails">
                <div style={{ overflowX: "auto" }}>
                    <table>
                        <thead>
                            <tr>
                                <th className="tableHeader tableHeadButton" style={{ width: 40 }}><Link to="/createcustomerstatus"><Fi.FiPlus /></Link></th>
                                <th className="tableHeader"><span>Name</span></th>
                                <th className="tableHeader"><span>Value</span></th>
                                <th className="tableHeader tableHeadButton" style={{ width: 20 }}><Link to="/createcurrency"><Fi.FiPlus /></Link></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.companyStatusData !== null &&
                                this.state.companyStatusData.map((data, i) => {
                                    
                                    if(data.name){
                                        return <tr key={data._id} onClick={() => this.editStatus(data._id)}>
                                            <td style={{ width: 40 }}>{i+1}</td>
                                            <td>{data.name}</td>
                                            <td>{data.value}</td>
                                            <td style={{ width: 40 }}></td>
                                        </tr>
                                    }
                                })
                            }


                        </tbody>
                    </table>
                </div>
            </div>
            </>
        );
    }
}

export default CustomerStatus;