import React from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'

import Pagination from "react-js-pagination";

import * as Fi from "react-icons/fi";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';


import Popup from 'reactjs-popup';
import {
    ImArrowDown2,
    ImArrowUp2
} from "react-icons/im";


import {
    AiOutlineExclamation 
} from "react-icons/ai";


import {
    FiPlus,
    FiX,
} from "react-icons/fi";

import Loading from '../../../components/Loading';
import TableLoading from '../../../helper/TableLoading';

export default class Items extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseItems: [],
            responseUnitOfMeasure: [],

            itemCode: "",
            partDescription: "",
            groupNumber: "",
            groupName: "",
            UoM: "",
            minValue: "",
            maxValue: "",
            minCost: "",
            maxCost: "",
            minRawCost: "",
            maxRawCost: "",
            note: "",

            minInStock: "",
            maxInStock: "",
            minAvailable: "",
            maxAvailable: "",
            minExpectedAvailable: "",
            maxExpectedAvailable: "",
            minBooked: "",
            maxBooked: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,

            selectedFile: null,
            showImportPopUp: false,
            isUploadStarting: false,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
        this.timer = null;
    }

    componentDidMount() {
        this.getFilterData()
        // this.getItemList()
        // this.getUnitOfMeasurement()
    }

//******************* ascending descending order function ********************************
sortCustomer = (fieldName, fieldType, onload) => {
        
    let sortVal = this.state.sortVal;
    if(!onload){
        if (this.state.fieldName === fieldName) {
            sortVal = !this.state.sortVal;
        }
    }
    this.setState({
        fieldName: fieldName,
        fieldType: fieldType,
        sortVal: sortVal
    }, () => {
        const sortedOrders = this.state.responseItems.sort((a, b) => {

            if (fieldType === "Date") {
                const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return sortVal ? 1 : -1;
                } else if (!dateB) {
                    return sortVal ? -1 : 1;
                }
                // console.log(dateA, dateB);
                if (dateA.getFullYear() !== dateB.getFullYear()) {
                    return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                }
                if (dateA.getMonth() !== dateB.getMonth()) {
                    return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                }
                if (dateA.getDate() !== dateB.getDate()) {
                    return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                }
                return 0;
            }
            else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                // console.log('NotDate success');
                const numA = parseFloat(a[fieldName]);
                const numB = parseFloat(b[fieldName]);
                if (sortVal) {
                    return numA > numB ? 1 : -1;
                } else {
                    return numA < numB ? 1 : -1;
                }
            } else {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            }

        });
        this.setState({ responseItems: sortedOrders }, () => {
            this.setFilterData()
        });
    });
    
};
//******************* ascending descending order function end ********************************

    getItemList = () => {
        let {
            itemCode,
            partDescription,
            groupNumber,
            groupName,
            UoM,
            minValue,
            maxValue,
            note,
            start,
            length,
            minInStock,
            maxInStock,
            minAvailable,
            maxAvailable,
            minExpectedAvailable,
            maxExpectedAvailable,
            minBooked,
            maxBooked,
            minCost,
            maxCost,
            minRawCost,
            maxRawCost,
            fieldName
        } = this.state

        this.setState({ isLoading: true, error: false }, () => {
            APIManager.callGetItemList(
                itemCode, partDescription, groupNumber, groupName, UoM, minValue, maxValue, note, start, length, minInStock, maxInStock, minAvailable, maxAvailable, minExpectedAvailable, maxExpectedAvailable, minBooked, maxBooked, minCost, maxCost, minRawCost, maxRawCost, fieldName
            )
                .then(response => {
                    this.setState({
                        isLoading: false,
                        responseItems: response.data,
                        totalRecords: response.totalRecords
                    }, () => {
                        if(this.state.fieldName){
                            this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                        }
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        })

    }

    setFilterData = () => {

        let filterDataArray = {
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
            'itemCode': this.state.itemCode,
            'partDescription': this.state.partDescription,
            'groupNumber': this.state.groupNumber,
            'groupName': this.state.groupName,
            'UoM': this.state.UoM,
            'minValue': this.state.minValue,
            'maxValue': this.state.maxValue,
            'note': this.state.note,
            'minInStock': this.state.minInStock,
            'maxInStock': this.state.maxInStock,
            'minAvailable': this.state.minAvailable,
            'maxAvailable': this.state.maxAvailable,
            'minExpectedAvailable': this.state.minExpectedAvailable,
            'maxExpectedAvailable': this.state.maxExpectedAvailable,
            'minBooked': this.state.minBooked,
            'maxBooked': this.state.maxBooked,
            'minCost': this.state.minCost,
            'maxCost': this.state.maxCost,
            'minRawCost': this.state.minRawCost,
            'maxRawCost': this.state.maxRawCost,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("stockItmes", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("stockItmes"))
        if (filterData !== null) {
            this.setState({
                activePage: filterData.activePage,
                start: filterData.start,
                length: filterData.length,
                itemCode: filterData.itemCode,
                partDescription: filterData.partDescription,
                groupNumber: filterData.groupNumber,
                groupName: filterData.groupName,
                UoM: filterData.UoM,
                minValue: filterData.minValue,
                maxValue: filterData.maxValue,
                note: filterData.note,
                minInStock: filterData.minInStock,
                maxInStock: filterData.maxInStock,
                minAvailable: filterData.minAvailable,
                maxAvailable: filterData.maxAvailable,
                minExpectedAvailable: filterData.minExpectedAvailable,
                maxExpectedAvailable: filterData.maxExpectedAvailable,
                minBooked: filterData.minBooked,
                maxBooked: filterData.maxBooked,
                minCost: filterData.minCost,
                maxCost: filterData.maxCost,
                minRawCost: filterData.minRawCost,
                maxRawCost: filterData.maxRawCost,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getUnitOfMeasurement()
            })
        } else {
            this.getUnitOfMeasurement()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("stockItmes")
        this.setState({
            itemCode: "",
            partDescription: "",
            groupNumber: "",
            groupName: "",
            UoM: "",
            minValue: "",
            maxValue: "",
            minCost: "",
            maxCost: "",
            minRawCost: "",
            maxRawCost: "",
            note: "",
            minInStock: "",
            maxInStock: "",
            minAvailable: "",
            maxAvailable: "",
            minExpectedAvailable: "",
            maxExpectedAvailable: "",
            minBooked: "",
            maxBooked: "",
            start: 0,
            length: 25,
            activePage: 1
        }, () => {
            this.getItemList()
        })
    }

    getUnitOfMeasurement = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllUnitOfMeasurement()
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseUnitOfMeasure: response
                }, () => {
                    this.getItemList()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                }, () => {
                    this.getItemList()
                })
            });

    }
    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.setFilterData()
            this.getItemList()
        });
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }



    addItem = () => {
        let slug = "Stock_Items"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createitem"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editItem = (_id) => {
        localStorage.setItem("itemId", _id)
        this.props.history.push({
            pathname: "/edititem"
        })
    }


    goBack = () => {
        this.props.history.goBack();
    }

    exportAsPDF = () => {
        let responseData = this.state.responseItems;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Items";

        const headers = [[
            "Part No",
            "Part Description",
            "Product Group",
            "Unit Of Measurement",
            "Is_Procured(Yes/no)",
            "Free Text",
            "Default Storage Locaton",
            "Min Quantity",
            "Max Quantity",
            "Item Code ",
            "cando",
            "hsCode",
            "Tax",
            "Is_SellablePrice (yes/no)",
            "Selling Price"
        ]];

        const data = responseData.map((elt, i) => [
            elt.partNo,
            elt.partDescription,
            elt.groupNumber,
            elt.UoM,
            elt.is_procured ? 'yes' : 'no',
            elt.freeText,
            elt.defaultStorageLocation,
            elt.minQuantity,
            elt.maxQuantity,
            elt.itemCode, 
            elt.cadNumber,
            elt.hsCode,
            elt.tax,
            elt.is_sellablePrice ? 'yes' : 'no',
            elt.sellingPrice,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("items.pdf")
    }


    exportAsCSV = () => {
        let responseData = this.state.responseItems;
        const title = "Items";
        const headers = [
            "Part No",
            "Part Description",
            "Product Group",
            "Unit Of Measurement",
            "Is_Procured(Yes/no)",
            "Free Text",
            "Default Storage Locaton",
            "Min Quantity",
            "Max Quantity",
            "Item Code ",
            "cando",
            "hsCode",
            "Tax",
            "Is_SellablePrice (yes/no)",
            "Selling Price"
        ];

        const data = responseData.map((elt, i) => [
            elt.partNo,
            elt.partDescription,
            elt.groupNumber,
            elt.UoM,
            elt.is_procured ? 'yes' : 'no',
            elt.freeText,
            elt.defaultStorageLocation,
            elt.minQuantity,
            elt.maxQuantity,
            elt.itemCode, 
            elt.cadNumber,
            elt.hsCode,
            elt.tax,
            elt.is_sellablePrice ? 'yes' : 'no',
            elt.sellingPrice,
        ]);


        const options = {
            filename: 'items',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    downloadSampleCSV = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDownloadSampleCSV()
            .then(response => {
                console.log(APIManager.BASE_URL + '/public' + response)
                //Build a URL from the file
                const fileURL = APIManager.BASE_URL + '/public' + response;
                //Open the URL on new Window      
                this.setState({
                    isLoading: false,
                }, () => {
                    this.openInNewTab(fileURL)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

   

    filterDelayData = () => {

        this.setFilterData()
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.getItemList()
        }, 800);
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    onFileUpload = () => {
        let {
            selectedFile
        } = this.state

        if (selectedFile) {
            this.setState({ isLoading: true, error: false, isUploadStarting: true })
            APIManager.callUploadItemCSV(selectedFile)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        success: true,
                        isUploadStarting: false,
                        successmessage: "Item(s) imported successfully."
                    }, () => {
                        this.getItemList()
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        isUploadStarting: false,
                        errormessage: errors
                    })
                });
        } else {
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select file to upload"
            })
        }
    }

    onClosePopup = () => {
        this.setState({
            error: false,
            success: false,
            selectedFile: null
        })
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isItems={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.Stock.Items}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <ImArrowDown2 /></div>
                                    <Popup modal arrow={true} trigger={<div className="button">Import from CSV <ImArrowUp2 /></div>} position="center" onClose={() => this.onClosePopup()}>
                                        <div className='head-import-buttons selectFile__Popup__Item'>
                                            <div className="pagecontent">
                                                {
                                                    this.state.error &&
                                                    <div className="mb-2">
                                                        <div className="errornotification">
                                                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.success &&
                                                    <div className="mb-2">
                                                        <div className="successnotification">
                                                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                onChange={this.onFileChange}
                                            ></input>
                                            {
                                                this.state.isUploadStarting ?
                                                    <div className="button mt-2">Upload <ImArrowUp2 /></div>
                                                    :
                                                    <div className="button mt-2" onClick={() => this.onFileUpload()}>Upload <ImArrowUp2 /></div>
                                            }
                                        </div>
                                    </Popup>
                                    <div className="button" onClick={() => this.downloadSampleCSV('csv')}>Sample CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 20 }} ><div onClick={() => this.addItem()}><FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('itemCode', 'notDate')}><span>Article Code</span> {this.state.fieldName === "itemCode" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "itemCode" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partDescription', 'notDate')}><span>Part description </span> {this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupNumber', 'notDate')}><span>Group number</span> {this.state.fieldName === "groupNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupName', 'notDate')}><span>Group name</span> {this.state.fieldName === "groupName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('inStock', 'notDate')}><span>In stock </span> {this.state.fieldName === "inStock" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "inStock" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('available', 'notDate')}><span>Available </span> {this.state.fieldName === "available" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "available" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('booked', 'notDate')}><span>Booked</span> {this.state.fieldName === "booked" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "booked" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('expectedAvailable', 'notDate')}><span>Expected, available</span> {this.state.fieldName === "expectedAvailable" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "expectedAvailable" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('minQuantity', 'notDate')}><span>Reorder point</span> {this.state.fieldName === "minQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "minQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('UoM', 'notDate')}><span>UoM</span> {this.state.fieldName === "UoM" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "UoM" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('rawCost', 'notDate')}><span>Raw cost </span> {this.state.fieldName === "rawCost" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "rawCost" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('sellingPrice', 'notDate')}><span>Cost</span> {this.state.fieldName === "sellingPrice" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "sellingPrice" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                           
                                    {/* 
                                    <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><FiChevronsDown /></th>} position="bottom right">
                                        <div className="selectColumns">
                                            <ul>
                                                <li><label htmlFor="itemCode"><span>Part No</span> <input type="checkbox" id="itemCode" /></label></li> 
                                                <li><label htmlFor="partdesc"><span>Part Description</span> <input type="checkbox" id="partdesc" /></label></li> 
                                                <li><label htmlFor="groupnum"><span>Group number</span> <input type="checkbox" id="groupnum" /></label></li> 
                                                <li><label htmlFor="groupname"><span>Group name</span> <input type="checkbox" id="groupname" /></label></li> 
                                                <li><label htmlFor="instock"><span>In stock</span> <input type="checkbox" id="instock" /></label></li> 
                                                <li><label htmlFor="available"><span>Available</span> <input type="checkbox" id="available" /></label></li> 
                                                <li><label htmlFor="booked"><span>Booked</span> <input type="checkbox" id="booked" /></label></li> 
                                                <li><label htmlFor="expectedtotal"><span>Expected, total</span> <input type="checkbox" id="expectedtotal" /></label></li> 
                                                <li><label htmlFor="expectedavailable"><span>Expected, available</span> <input type="checkbox" id="expectedavailable" /></label></li> 
                                                <li><label htmlFor="expectedbooked"><span>Expected, booked</span> <input type="checkbox" id="expectedbooked" /></label></li> 
                                                <li><label htmlFor="workinprogress"><span>Work in progress</span> <input type="checkbox" id="workinprogress" /></label></li> 
                                                <li><label htmlFor="reorderpoint"><span>Reorder Point</span> <input type="checkbox" id="reorderpoint" /></label></li> 
                                                <li><label htmlFor="cost"><span>Cost</span> <input type="checkbox" id="cost" /></label></li> 
                                                <li><label htmlFor="sellingprice"><span>Selling price</span> <input type="checkbox" id="sellingprice" /></label></li> 
                                                <li><label htmlFor="uom"><span>UoM</span> <input type="checkbox" id="uom" /></label></li> 
                                                <li><label htmlFor="isprocured"><span>Is procured item</span> <input type="checkbox" id="isprocured" /></label></li> 
                                                <li><label htmlFor="leadtime"><span>Lead time</span> <input type="checkbox" id="leadtime" /></label></li> 
                                                <li><label htmlFor="vendornum"><span>Vendor number</span> <input type="checkbox" id="vendornum" /></label></li> 
                                                <li><label htmlFor="vendorname"><span>Vendor name</span> <input type="checkbox" id="vendorname" /></label></li> 
                                                <li><label htmlFor="vendorpart"><span>Vendor part no.</span> <input type="checkbox" id="vendorpart" /></label></li> 
                                                <li><label htmlFor="defaultsl"><span>Default storage location</span> <input type="checkbox" id="defaultsl" /></label></li> 
                                                <li><label htmlFor="virtualstock"><span>Virtual stock</span> <input type="checkbox" id="virtualstock" /></label></li> 
                                            </ul>
                                        </div>
                                    </Popup>
                                    */}
                                    <th className="tableHeader tableHeadButton" style={{ width: 20 }} ><div onClick={() => this.addItem()}><FiPlus /></div></th>
                                    {/* <th className="tableHeader tableHeadButton"><FiPrinter /></th> */}
                                    {/* <th className="tableHeader tableHeadButton"><RiPaintBrushLine /></th> */}
                                </tr>

                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type="text" value={this.state.itemCode} onChange={(e) => this.setState({ itemCode: e.target.value }, () => { this.filterDelayData() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.partDescription} onChange={(e) => this.setState({ partDescription: e.target.value }, () => { this.filterDelayData() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.groupNumber} onChange={(e) => this.setState({ groupNumber: e.target.value }, () => { this.filterDelayData() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.groupName} onChange={(e) => this.setState({ groupName: e.target.value }, () => { this.filterDelayData() })} /></th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minInStock} onChange={(e) => this.setState({ minInStock: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxInStock} onChange={(e) => this.setState({ maxInStock: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minAvailable} onChange={(e) => this.setState({ minAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxAvailable} onChange={(e) => this.setState({ maxAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minBooked} onChange={(e) => this.setState({ minBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxBooked} onChange={(e) => this.setState({ maxBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minExpectedAvailable} onChange={(e) => this.setState({ minExpectedAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxExpectedAvailable} onChange={(e) => this.setState({ maxExpectedAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minValue} onChange={(e) => this.setState({ minValue: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxValue} onChange={(e) => this.setState({ maxValue: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="tableElemContain">
                                        <select value={this.state.UoM} style={{ minWidth: 100 }} onChange={(e) => { this.setState({ UoM: e.target.value }, () => { this.filterDelayData() }) }}>
                                            <option value=""></option>
                                            {
                                                this.state.responseUnitOfMeasure.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minRawCost} onChange={(e) => this.setState({ minRawCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxRawCost} onChange={(e) => this.setState({ maxRawCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minCost} onChange={(e) => this.setState({ minCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxCost} onChange={(e) => this.setState({ maxCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    {/* <th className="tableElemContain tableAddButton"><div className="cursor-pointer"><FiSearch /></div></th> */}
                                    <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><FiX /></div></th>
                                    {/* <th className="tableElemContain tableAddButton"><MdCheckBoxOutlineBlank /></th> */}
                                    {/* <th></th> */}

                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={14} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseItems !== null &&
                                            this.state.responseItems.map((data, i) => (
                                                <tr key={data._id} onClick={this.editItem.bind(this, data._id)}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td style={{ width: 80 }} className="text-uppercase">{data.itemCode}</td>
                                                    <td>{data.partDescription}</td>
                                                    <td style={{ width: 90 }} className="text-uppercase">{data.groupNumber}</td>
                                                    <td>{data.groupName}</td>
                                                    <td>{data.inStock}</td>
                                                    <td>{data.available}</td>
                                                    <td>{data.booked}</td>
                                                    <td>{data.expectedAvailable}</td>
                                                    <td>{data.minQuantity}</td>
                                                    <td>{data.UoM}</td>
                                                    <td>{data.rawCost}</td>
                                                    <td>{data.sellingPrice}</td>

                                                    <td></td>
                                                    {/* <td><FiBarChart className="editCusomerBtn" /></td> */}
                                                    {/* <td><FiPrinter className="editCusomerBtn" /></td> */}
                                                    {/* <td><MdCheckBoxOutlineBlank className="editCusomerBtn" /></td> */}

                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>

                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>



                    </div>
                </div>
            </div>
        )
    }
}