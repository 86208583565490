import React, { Component } from 'react';
import { PDFViewer, Page, Text, View, Image, Font, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import renderHTML from 'react-render-html';
import Denmark from './../../../../../assets/Fonts/DENMARK.ttf'
import CaustenExtraBold from './../../../../../assets/Fonts/CaustenExtraBold.ttf'
import CalibriBold from './../../../../../assets/Fonts/CalibriBold.ttf'
import CalibriRegular from './../../../../../assets/Fonts/CalibriRegular.ttf'
import * as APIManager from './../../../../../APIManager/APIManager'
import { BrowserView, MobileView } from 'react-device-detect';
import Html from 'react-pdf-html';
import { AKAMEng } from './../../../../../assets/Images'


Font.register({
    family: 'Denmark',
    src: Denmark,    
});

Font.register({
    family: 'CaustenExtraBold',
    src: CaustenExtraBold
})

Font.register({
    family: 'CalibriBold',
    src: CalibriBold
})

Font.register({
    family: 'CalibriRegular',
    src: CalibriRegular
})

class generateOtherCurrInvoicePDF extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "loading...", 
            isReadyToLoad: false,

            coInvoiceId: "",
            companyDetails: [],
            invoiceNumber: "",
            buyer_company: "",
            buyer_address: "",
            buyer_phoneNo: "",
            buyer_email: "",
            buyer_panNo: "",
            buyer_GSTNo: "",
            account_name: "",
            account_phone: "",
            account_email: "",
            contactPerson_Name: "",
            contactPerson_PhoneNo: "",
            contactPerson_Email: "",
            
            invoiceDate: "",
            invoiceType: "",
            dueDate: "",

            status: "",

            rfqNo: "",
            rfqDate: "",
            poDate: "",
            poNo: "",

            deliveryAddress: "",
            invoiceAddress: "",

            invoiceMessageHeader: "",
            invoiceMessageFooter: "",

            itemData: [],
            itemValue: [],

            printItemDetails: [],
            printBasicTotal: 0,

            grandBasicTotal: "",
            grandTotal: "",
            rupeeSymbol: "",

            printTerms: [],

            userAccountId: "",
            pdfTypeText: "",

            headerFont: '',
            footerColor: '',

        }
    }

    componentDidMount(){
        let id = localStorage.getItem("CO_InvoiceId")

        let userDetails = localStorage.getItem("userdata")
        let accId = JSON.parse(userDetails).account_id

        this.setState({
            coInvoiceId: id,
            userAccountId: accId,
        },()=>{
            this.getInvoicePDFData()
            this.setPdfTypeText()
        })

        this.setHeaderFont()
    }

    setHeaderFont = () => {
        let userDetails = localStorage.getItem("userdata")
        let accId = JSON.parse(userDetails).account_id

        const ALFA = '791d2fa8-2f82-48d5-b14c-0e7197fe150f'
        const CHEMORO = '98376e27-4595-4a63-a042-5ac6b6a63ae0'

        if(accId === ALFA){
            this.setState({ headerFont: 'Denmark', footerColor: '#1d7387' },()=>{
                console.log('Font: Denmark')
            })
        }else if(accId === CHEMORO){
            this.setState({ headerFont: 'CaustenExtraBold', footerColor: '#1b315f' },()=>{
                console.log('Font: CaustenExtraBold')
            })
        }else{
            this.setState({ headerFont: 'CalibriBold', footerColor: '#000000' },()=>{
                console.log('Font: CalibriBold')
            })
        }
    }

    setPdfTypeText = () => {
        let pdfType = localStorage.getItem("pdfType")
        let pdfTypeText = ""
        if(pdfType === "original"){
            pdfTypeText = "(Original for Buyer)"
        }else if(pdfType === "duplicate"){
            pdfTypeText = "(Duplicate for Transporter)"
        }else if(pdfType === "triplicate"){
            pdfTypeText = "(Triplicate for Supplier)"
        }else{
            pdfTypeText = ""
        }
        this.setState({
            pdfTypeText: pdfTypeText
        })
    }

    getInvoicePDFData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCOInvoicePDF(this.state.coInvoiceId)
        .then(response => {
            this.setState({
                isLoading: false,
                companyDetails: response.companyDetails,
                invoiceNumber: response.invoiceNumber,

                buyer_company: response.buyer_company,
                buyer_panNo: response.buyer_panNo,
                buyer_GSTNo: response.buyer_GSTNo,
                buyer_address: response.invoiceAddress,
                buyer_phoneNo: response.buyer_phoneNo,
                buyer_email: response.buyer_email,

                account_name: response.account_name,
                account_phone: response.account_phone,
                account_email: response.account_email,

                contactPerson_Name: response.contactPerson_Name,
                contactPerson_PhoneNo: response.contactPerson_PhoneNo,
                contactPerson_Email: response.contactPerson_Email,

                invoiceDate: this.formateDate(response.invoiceDate),
                invoiceType: response.invoiceType,
                dueDate: this.formateDate(response.dueDate),

                status: response.status,
                rfqNo: response.rfqNo,
                rfqDate: this.formateDate(response.rfqDate),
                poDate: this.formateDate(response.poDate),
                poNo: response.poNo,

                deliveryAddress: response.deliveryAddress,
                invoiceAddress: response.invoiceAddress,

                invoiceMessageHeader: response.invoiceMessageHeader,
                invoiceMessageFooter: response.invoiceMessageFooter,

                itemData: response.itemData,
                itemValue: response.itemValue,

                grandTotal: this.formattedNumber(Math.round(response.totalInOtherCurrency)),

                rupeeSymbol: response.currency ? response.currency : response.rupeeSymbol,

                terms: response.terms ? response.terms : [],

            },()=>{
                this.setItemDetails()
            })
        }).catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Something went wrong, please check your login status and try again" 
            })
        });
    }

    formateDate = (date) => {
        if(date){
            let dt = date.split("/")
            let newDate = dt[1]+"/"+dt[0]+"/"+dt[2]
            return newDate
        }else{
            return date
        }
    }

    createHTMLElement = (data) => {
        const html = `
            <html>
                <body>
                    <style>
                        body{
                            margin: 0; 
                            font-family: CalibriRegular;
                        } 
                        p{ 
                            margin: 0;
                        }
                    </style>
                    <div>${data}</div>
                </body>
            </html>`
        return html;
    }
    
    setItemDetails = () => {
        let {
            itemData,
            itemValue
        } = this.state;

        let itemLocalArray = [];
        let countBasicTotal = 0
        let itemCounter = 1

        for(let i=0;i<itemData.length;i++){
            if(itemData[i].rejectedQuantity !== 0){
                countBasicTotal += parseFloat(itemValue[i].rawTotalInOtherCurrency)
                let pricePerUoM = itemData[i].pricePerUoMInOtherCurrency

                let newCountDiscount = 0;
                if(itemValue[i].discount)
                    newCountDiscount = (pricePerUoM - (pricePerUoM * itemValue[i].discount / 100)).toFixed(2)
                else 
                    newCountDiscount = pricePerUoM

                let newSubTotal = newCountDiscount * itemData[i].rejectedQuantity

                itemLocalArray.push(
                    <View key={"item_data_"+i} style={styles.table_body}>
                        <View style={styles.table_body_content_one}>
                            <Text style={[styles.table_body_text,styles.table_body_1]}>{itemCounter}</Text>
                            <View style={[styles.table_body_text,styles.table_body_2]}>                            
                                <Text style={styles.bold_text}>{itemData[i].itemName}</Text>
                                <Text>HS Code: {itemData[i].hsCode !== "undefined" ? itemData[i].hsCode : ""}</Text>
                                {
                                    itemData[i].freeText !== "" &&
                                    <Html style={{fontSize: 11}}>{this.createHTMLElement(itemData[i].freeText.replace(/<br>\\*/g,"</div><div>"))}</Html>
                                }
                            </View>
                            <Text style={[styles.table_body_text,styles.table_body_3]}>{itemData[i].rejectedQuantity} {itemData[i].UoM}</Text>
                            <Text style={[styles.table_body_text,styles.table_body_4]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.formattedNumber(newCountDiscount)}</Text>
                            <Text style={[styles.table_body_text,styles.table_body_5]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.formattedNumber(newSubTotal)}</Text>
                        </View>
                    </View>
                )
                itemCounter++
            }
        }
        this.setState({
            printItemDetails: itemLocalArray,
            printBasicTotal: this.formattedNumber(countBasicTotal),   
        },()=>{
            this.setTermsDetail()
        })
    }

    setTermsDetail = () => {
        let { terms } = this.state;
        let termsArray = []
        for(let i = 0;i<terms.length; i++){
            if(terms[i].showTermInCoInvoice){
                let termsArrayInner = []
                termsArrayInner.push(<Text key={"quota_terms_"+i} style={styles.term_title}> {terms[i].terms}</Text>)
                for(let j=0;j<terms[i].options.length;j++){
                    termsArrayInner.push(<Text key={"terms_option"+j} style={styles.term_value}> - {terms[i].options[j]}</Text>)
                }
                termsArray.push(<Text key={"terms_"+i} style={{ marginTop: 5 }}>{termsArrayInner}</Text>)
            }
        }

        if(this.state.userAccountId === APIManager.ALFA_ACCOUNT_ID){ // For Alfa Pump Only
            termsArray.push(<Text style={styles.term_value}> <Text style={styles.term_title}>Interest</Text> at 24% per annum will be charged on bill amount not paid within stipulated. Our responsibilities ceases as the goods mentioned in this bill have left our premises. Subject to Ahmedabad jurisdiction.</Text>)
        }

        this.setState({
            printTerms: termsArray,
            isReadyToLoad: true
        })
    }



    formattedNumber = (num) => {
        let number = parseFloat(num)
        if(number !== "" || number !== 0){
            return number.toFixed(2).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return number
        }
    } 

    render() {
        return (
            <>
            <BrowserView>
            {
                this.state.isReadyToLoad ?
                    <PDFViewer style={styles.container}>
                        <Document>
                            <Page style={styles.body}>
                                {/* Fixed Header Start */}
                                <View style={styles.header} fixed>
                                    <View style={styles.headerLeft}>
                                        <View style={styles.headerLeftContent}>
                                            <Text style={styles.company_name}>{this.state.invoiceType === "export" ? "Invoice" : this.state.invoiceType+' Invoice'} </Text>
                                            <Text style={styles.company_addr}>{this.state.pdfTypeText}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.headerCenter}>
                                        <Image src={AKAMEng} style={styles.headerlogo} /> 
                                    </View>
                                    <View style={styles.headerRight}>
                                        {
                                            this.state.companyDetails.companyLogo !== "" &&
                                            <Image source={this.state.companyDetails.companyLogo} crossorigin="anonymous" cache={false} style={styles.headerlogo} /> 
                                        }
                                    </View>
                                </View>
                                {/* Fixed Header End */}

                                {/* Main Content Start */}
                                <View style={styles.maincontainer}>
                                    <View style={styles.__ct_head}>
                                        <View style={styles.__ct_head_left}>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>Buyer</Text>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>{this.state.buyer_company}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textCapitalize]}>{this.state.buyer_address.split("_ ").join(" ")}</Text>
                                            <Text style={styles.__ct_head_text}>{this.state.buyer_phoneNo}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.buyer_email}</Text>
                                            <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_panNo ? 'PAN No.: ' +this.state.buyer_panNo.toUpperCase() : ''}</Text>
                                            <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_GSTNo ? 'GST No.: ' +this.state.buyer_GSTNo.toUpperCase() : ''}</Text>
                                        </View>
                                        <View style={styles.__ct_head_right}>
                                            <View style={styles.__ct_head_contact_person}>
                                                <Text style={[styles.__ct_head_text,styles.bold_text]}>Delivery address</Text>
                                                <Text style={[styles.__ct_head_text,styles.bold_text]}>{this.state.buyer_company}</Text>
                                                <Text style={[styles.__ct_head_text,styles.textCapitalize]}>{this.state.deliveryAddress.split("_ ").join(" ")}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/* Main content record details */}
                                    <View style={styles.__ct_detail}>
                                        <View style={styles.__ct_detail_left}>
                                            <Text style={[styles.bold_text,{ textTransform: "capitalize"}]}>Invoice {this.state.invoiceNumber.toUpperCase()}</Text>
                                        </View>
                                        <View style={styles.__ct_detail_right}>
                                            <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>Invoice Date: </Text><Text>{this.state.invoiceDate}</Text></View>
                                            <View style={{flexDirection: "row"}}><Text style={{ width: 100}}>Due date: </Text><Text>{this.state.dueDate}</Text></View>
                                        </View>
                                    </View>

                                    {/* Record Reference detail */}
                                    <View style={styles.__ct_record_ref_detail}>
                                        <View style={{ flex: 0.66 }}>
                                            <View style={{flexDirection: "row", marginBottom: 5}}>
                                                <Text style={{ width: 140}}>Reference: </Text><Text style={styles.textCapitalize}>{this.state.reference}</Text>
                                            </View>
                                            {
                                                this.state.status !== "Quotation" ?
                                                <View>
                                                    <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>PO No.: </Text><Text>{this.state.poNo}</Text></View>
                                                    <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>PO Date: </Text><Text>{this.state.poDate}</Text></View>
                                                </View>
                                                :
                                                <View>
                                                    <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>RFQ No.: </Text><Text>{this.state.rfqNo}</Text></View>
                                                    <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>RFQ Date: </Text><Text>{this.state.rfqDate}</Text></View>
                                                </View>
                                            }
                                        </View>
                                        {
                                            this.state.userAccountId === APIManager.ALFA_ACCOUNT_ID &&
                                            <View style={[styles.delivery__date,{ flex: 0.34}]}>
                                                <View style={{flexDirection: "row", marginBottom: 5}}>
                                                    <Text style={{ width: 140}}>HS Codes:</Text>
                                                </View>
                            
                                                <View>
                                                    <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>Pumps: </Text><Text>84137091</Text></View>
                                                    <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>Pump parts: </Text><Text>84139120</Text></View>
                                                </View>
                                            </View>
                                        }
                                       
                                    </View>

                                    

                                    {/* Invoice Header Message */}
                                    {
                                        (this.state.invoiceMessageHeader !== "" && this.state.invoiceMessageHeader !== "<p><br></p>") &&
                                        <View style={{ paddingTop: 10, fontSize: 12 }}>
                                            <Text>{renderHTML(this.state.invoiceMessageHeader)}</Text>
                                        </View>
                                    }

                                    {/* Item Table */}
                                    <View style={styles.table_header}>
                                        <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                        <Text style={[styles.table_header_text,styles.table_header_2]}>Item</Text>
                                        <Text style={[styles.table_header_text,styles.table_header_3]}>Quantity</Text>
                                        <Text style={[styles.table_header_text,styles.table_header_4]}>Price/Unit</Text>
                                        <Text style={[styles.table_header_text,styles.table_header_5]}>Price/Item</Text>
                                    </View>

                                    {this.state.printItemDetails}

                                    <View style={{ flexDirection: "row"}}>
                                        <View style={{ flex: 0.6}}>
                                            {/* Terms & Conditions */}
                                            {
                                                this.state.printTerms.length > 0 &&
                                                <View style={{ borderWidth: 1, marginRight: 5, padding: 5 }}>
                                                    <Text style={styles.terms_header}>{'Terms & Conditions'}</Text>
                                                    <Text>
                                                        { this.state.printTerms }
                                                    </Text>
                                                </View>
                                            }

                                            {/* Invoice Footer Message */}
                                            {
                                                (this.state.invoiceMessageFooter !== "" && this.state.invoiceMessageFooter !== "<p><br></p>") &&
                                                <View style={{ paddingVertical: 10, fontSize: 12 }}>
                                                    <Text>{renderHTML(this.state.invoiceMessageFooter)}</Text>
                                                </View>
                                            }
                                        </View>
                                        <View style={[styles.grand_total_section,{ flex: 0.4}]}>
                                            <View style={[styles.grand_total_content,styles.bold_text]}><Text style={[styles.grand_total_data,styles.text_grand_total]}>Grand Total</Text><Text style={[styles.grand_total_data,styles.text_grand_total]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.state.grandTotal}</Text></View>
                                            <View style={{ marginLeft: 49 }}>
                                                <Text style={[styles.authorisedCompanyName,{fontFamily: this.state.headerFont}]}>{this.state.companyDetails.companyName}</Text>
                                                <Text style={styles.authorisedTextData}>Authorised Signatory</Text>
                                            </View>
                                        </View>
                                    </View>

                                    


                                </View>
                                {/* Main Content Start */}




                                {/* Fixed Footer Start */}
                                <View style={styles.footer} fixed>
                                    <View style={styles.footerTopSection}>
                                        <Text style={styles.footerQuotationNumber}>{this.state.invoiceType === "export" ? "Invoice" : this.state.invoiceType+' Invoice'} {this.state.invoiceNumber.toUpperCase()}</Text>
                                        <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                            `Page ${pageNumber} of ${totalPages}`
                                        )} />
                                    </View> 
                                    <View>
                                        <Text style={[styles.footer_company_name,{fontFamily: this.state.headerFont, color: this.state.footerColor}]}>{this.state.companyDetails.companyName}</Text>
                                        <View style={styles.footer_details}>
                                            <View style={styles.footer_detail_data1}>
                                                <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                                <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}</Text>
                                                <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line2}</Text>
                                                <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}</Text>
                                                <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_state}, {this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                            </View>
                                            <View style={styles.footer_detail_data2}>
                                                <Text style={styles.footer_detail_text}>Regd. No.: {this.state.companyDetails.regdNo}</Text>
                                                <Text style={styles.footer_detail_text}>GSTIN: {this.state.companyDetails.gstin}</Text>
                                                <Text style={styles.footer_detail_text}>ECC: {this.state.companyDetails.ecc}</Text>
                                                <Text style={styles.footer_detail_text}>PAN: {this.state.companyDetails.panNo}</Text>
                                            </View>
                                            <View style={styles.footer_detail_data3}>
                                                <Text style={styles.footer_detail_text}>{this.state.companyDetails.bank_name}</Text>
                                                <Text style={styles.footer_detail_text}>A/c No.: {this.state.companyDetails.bank_accno}</Text>
                                                <Text style={styles.footer_detail_text}>Branch: {this.state.companyDetails.bank_branch}</Text>
                                                <Text style={styles.footer_detail_text}>RTGS/NEFT: {this.state.companyDetails.bank_rtgsneft}</Text>
                                                <Text style={styles.footer_detail_text}>Swift Code: {this.state.companyDetails.bank_swiftcode}</Text>
                                            </View>
                                            <View style={styles.footer_detail_data4}>
                                                
                                                <Text style={styles.footer_detail_text}>
                                                    { this.state.companyDetails.telephone ? 't: +91 ' +this.state.companyDetails.telephone : '' }
                                                </Text>
                                            
                                                <Text style={styles.footer_detail_text}>
                                                    { this.state.companyDetails.phone ? 'm: +91 ' +this.state.companyDetails.phone : '' }
                                                </Text>
                                            
                                                <Text style={styles.footer_detail_text}>
                                                    e: {this.state.companyDetails.email}
                                                </Text>
                                                                                    
                                                <Text style={styles.footer_detail_text}>
                                                    { this.state.companyDetails.website ? 'w | ' +this.state.companyDetails.website : '' }
                                                </Text>

                                                <Text style={styles.footer_detail_text}>
                                                    { this.state.companyDetails.website2 ? 'w | ' +this.state.companyDetails.website2 : '' }
                                                </Text>
                                
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                {/* Fixed Footer End */}
                            </Page>
                        </Document>
                    </PDFViewer>
                :
                    <div className="__pdf_error_message">
                        {this.state.errormessage}
                    </div>
            }
            </BrowserView>
            <MobileView>
                <div className="__pdf_error_message">
                    {/* This browser doesn't support PDF view at the moment. */}
                    <PDFDownloadLink document={
                        <Document>
                        <Page style={styles.body}>
                            {/* Fixed Header Start */}
                            <View style={styles.header} fixed>
                                <View style={styles.headerLeft}>
                                    <View style={styles.headerLeftContent}>
                                        <Text style={styles.company_name}>{this.state.invoiceType === "export" ? "Invoice" : this.state.invoiceType+' Invoice'} </Text>
                                        <Text style={styles.company_addr}>{this.state.pdfTypeText}</Text>
                                    </View>
                                </View>
                                <View style={styles.headerCenter}>
                                    <Image src={AKAMEng} style={styles.headerlogo} /> 
                                </View>
                                <View style={styles.headerRight}>
                                    {
                                        this.state.companyDetails.companyLogo !== "" &&
                                        <Image source={this.state.companyDetails.companyLogo} crossorigin="anonymous" cache={false} style={styles.headerlogo} /> 
                                    }
                                </View>
                            </View>
                            {/* Fixed Header End */}

                            {/* Main Content Start */}
                            <View style={styles.maincontainer}>
                                <View style={styles.__ct_head}>
                                    <View style={styles.__ct_head_left}>
                                        <Text style={[styles.__ct_head_text,styles.bold_text]}>Buyer</Text>
                                        <Text style={[styles.__ct_head_text,styles.bold_text]}>{this.state.buyer_company}</Text>
                                        <Text style={[styles.__ct_head_text,styles.textCapitalize]}>{this.state.buyer_address.split("_ ").join(" ")}</Text>
                                        <Text style={styles.__ct_head_text}>{this.state.buyer_phoneNo}</Text>
                                        <Text style={[styles.__ct_head_text,styles.textLowercase]}>{this.state.buyer_email}</Text>
                                        <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_panNo ? 'PAN No.: ' +this.state.buyer_panNo.toUpperCase() : ''}</Text>
                                        <Text style={[styles.__ct_head_text,{textTransform: 'capitalize'}]}>{this.state.buyer_GSTNo ? 'GST No.: ' +this.state.buyer_GSTNo.toUpperCase() : ''}</Text>
                                    </View>
                                    <View style={styles.__ct_head_right}>
                                        <View style={styles.__ct_head_contact_person}>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>Delivery address</Text>
                                            <Text style={[styles.__ct_head_text,styles.bold_text]}>{this.state.buyer_company}</Text>
                                            <Text style={[styles.__ct_head_text,styles.textCapitalize]}>{this.state.deliveryAddress.split("_ ").join(" ")}</Text>
                                        </View>
                                    </View>
                                </View>

                                {/* Main content record details */}
                                <View style={styles.__ct_detail}>
                                    <View style={styles.__ct_detail_left}>
                                        <Text style={[styles.bold_text,{ textTransform: "capitalize"}]}>Invoice {this.state.invoiceNumber.toUpperCase()}</Text>
                                    </View>
                                    <View style={styles.__ct_detail_right}>
                                        <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>Invoice Date: </Text><Text>{this.state.invoiceDate}</Text></View>
                                        <View style={{flexDirection: "row"}}><Text style={{ width: 100}}>Due date: </Text><Text>{this.state.dueDate}</Text></View>
                                    </View>
                                </View>

                                {/* Record Reference detail */}
                                <View style={styles.__ct_record_ref_detail}>
                                    <View style={{ flex: 0.66 }}>
                                        <View style={{flexDirection: "row", marginBottom: 5}}>
                                            <Text style={{ width: 140}}>Reference: </Text><Text style={styles.textCapitalize}>{this.state.reference}</Text>
                                        </View>
                                        {
                                            this.state.status !== "Quotation" ?
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>PO No.: </Text><Text>{this.state.poNo}</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>PO Date: </Text><Text>{this.state.poDate}</Text></View>
                                            </View>
                                            :
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>RFQ No.: </Text><Text>{this.state.rfqNo}</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>RFQ Date: </Text><Text>{this.state.rfqDate}</Text></View>
                                            </View>
                                        }
                                    </View>
                                    {
                                        this.state.userAccountId === APIManager.ALFA_ACCOUNT_ID &&
                                        <View style={[styles.delivery__date,{ flex: 0.34}]}>
                                            <View style={{flexDirection: "row", marginBottom: 5}}>
                                                <Text style={{ width: 140}}>HS Codes:</Text>
                                            </View>
                        
                                            <View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>Pumps: </Text><Text>84137091</Text></View>
                                                <View style={{flexDirection: "row", marginBottom: 5}}><Text style={{ width: 100}}>Pump parts: </Text><Text>84139120</Text></View>
                                            </View>
                                        </View>
                                    }
                                </View>

                                

                                {/* Invoice Header Message */}
                                {
                                    (this.state.invoiceMessageHeader !== "" && this.state.invoiceMessageHeader !== "<p><br></p>") &&
                                    <View style={{ paddingVertical: 10, fontSize: 12 }}>
                                        <Text>{renderHTML(this.state.invoiceMessageHeader)}</Text>
                                    </View>
                                }

                                {/* Item Table */}
                                <View style={styles.table_header}>
                                    <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2]}>Item</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_3]}>Quantity</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_4]}>Price/Unit</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_5]}>Price/Item</Text>
                                </View>

                                {this.state.printItemDetails}

                                <View style={{ flexDirection: "row"}}>
                                    <View style={{ flex: 0.6}}>
                                        {/* Terms & Conditions */}
                                        {
                                            this.state.printTerms.length > 0 &&
                                            <View style={{ borderWidth: 1, marginRight: 5, padding: 5 }}>
                                                <Text style={styles.terms_header}>{'Terms & Conditions'}</Text>
                                                <Text>
                                                    { this.state.printTerms }
                                                </Text>
                                            </View>
                                        }
                                        {/* Invoice Footer Message */}
                                        {
                                            (this.state.invoiceMessageFooter !== "" && this.state.invoiceMessageFooter !== "<p><br></p>") &&
                                            <View style={{ paddingVertical: 10, fontSize: 12 }}>
                                                <Text>{renderHTML(this.state.invoiceMessageFooter)}</Text>
                                            </View>
                                        }

                                    </View>
                                    <View style={[styles.grand_total_section,{ flex: 0.4}]}>
                                        <View style={[styles.grand_total_content,styles.bold_text]}><Text style={[styles.grand_total_data,styles.text_grand_total]}>Grand Total</Text><Text style={[styles.grand_total_data,styles.text_grand_total]}><Text style={styles.rsSymbolFont}>{this.state.rupeeSymbol} </Text>{this.state.grandTotal}</Text></View>
                                        <View style={{ marginLeft: 49 }}>
                                            <Text style={[styles.authorisedCompanyName,{fontFamily: this.state.headerFont}]}>{this.state.companyDetails.companyName}</Text>
                                            <Text style={styles.authorisedTextData}>Authorised Signatory</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                
                            </View>
                            {/* Main Content Start */}


                            {/* Fixed Footer Start */}
                            <View style={styles.footer} fixed>
                                <View style={styles.footerTopSection}>
                                    <Text style={styles.footerQuotationNumber}>{this.state.invoiceType === "export" ? "Invoice" : this.state.invoiceType+' Invoice'} {this.state.invoiceNumber.toUpperCase()}</Text>
                                    <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                        `Page ${pageNumber} of ${totalPages}`
                                    )} />
                                </View> 
                                <View>
                                    <Text style={[styles.footer_company_name,{fontFamily: this.state.headerFont, color: this.state.footerColor}]}>{this.state.companyDetails.companyName}</Text>
                                    <View style={styles.footer_details}>
                                        <View style={styles.footer_detail_data1}>
                                            <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line2}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_state}, {this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data2}>
                                            <Text style={styles.footer_detail_text}>Regd. No.: {this.state.companyDetails.regdNo}</Text>
                                            <Text style={styles.footer_detail_text}>GSTIN: {this.state.companyDetails.gstin}</Text>
                                            <Text style={styles.footer_detail_text}>ECC: {this.state.companyDetails.ecc}</Text>
                                            <Text style={styles.footer_detail_text}>PAN: {this.state.companyDetails.panNo}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data3}>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.bank_name}</Text>
                                            <Text style={styles.footer_detail_text}>A/c No.: {this.state.companyDetails.bank_accno}</Text>
                                            <Text style={styles.footer_detail_text}>Branch: {this.state.companyDetails.bank_branch}</Text>
                                            <Text style={styles.footer_detail_text}>RTGS/NEFT: {this.state.companyDetails.bank_rtgsneft}</Text>
                                            <Text style={styles.footer_detail_text}>Swift Code: {this.state.companyDetails.bank_swiftcode}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data4}>
                                            
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.telephone ? 't: +91 ' +this.state.companyDetails.telephone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.phone ? 'm: +91 ' +this.state.companyDetails.phone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                e: {this.state.companyDetails.email}
                                            </Text>
                                                                                
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website ? 'w | ' +this.state.companyDetails.website : '' }
                                            </Text>

                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website2 ? 'w | ' +this.state.companyDetails.website2 : '' }
                                            </Text>
                            
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {/* Fixed Footer End */}
                        </Page>
                    </Document>
                    } fileName={this.state.invoiceType+" invoice "+this.state.invoiceNumber.toUpperCase()+".pdf"}>
                    {({ _blob, _url, loading, _error }) => (loading ? 'Loading...' : 'View PDF!')}
                    </PDFDownloadLink>
                </div>
            </MobileView>
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: window.innerHeight,
    },
    body: {
      paddingTop: 10,
      paddingBottom: 130,
      paddingHorizontal: 35,
      fontSize: 12,
      fontFamily: 'CalibriRegular',
    },
    textCapitalize: {
        textTransform: 'capitalize'
    },
    textLowercase: {
        textTransform: 'lowercase'
    },
    __ct_head: {
        flexDirection: "row",
        justifyContent: "space-around",
        fontSize: 11,
    },
    __ct_head_left: {
        flexWrap: 'wrap',
        flex: 0.50,
        marginRight: 10,
    },
    __ct_head_right: {
        flexWrap: 'wrap',
        flex: 0.50,  
    },
    __ct_head_text: {
        marginBottom: 3,
    },
    __ct_head_text_bold: {
       fontSize: 12
    },
    __ct_head_contact_person: {
        paddingBottom: 10,
    },
    bold_text: {
        fontFamily: 'CalibriBold'
    },
    delivery__date: {
        alignItems: "flex-start",
        justifyContent: "flex-end"
    },
    delivery__date__text: {
        fontSize: 14
    },
    __ct_detail: {
        flexDirection: "row",
        position: 'relative',
        borderBottomWidth: 2,
        paddingTop: 10,
        paddingBottom: 5,
        justifyContent: "space-between"
    },
    __ct_detail_left: {
        justifyContent: 'flex-end',
        flex: 0.65,
        fontSize: 15,
        marginRight: 10,
    },
    __ct_detail_right: {
        flex: 0.35,
        fontSize: 11,
    },
    __ct_record_ref_detail: {
        fontSize: 11,
        marginTop: 5,
        borderBottomWidth: 1,
        flexDirection: "row"
    },
    table_header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        marginBottom: 10,
        paddingBottom: 5,
        marginTop: 10,
    }, 
    table_header_text: {
        fontSize: 11,
        fontFamily: 'CalibriBold'
    },
    rsSymbolFont: {
        fontFamily: 'CalibriBold'
    },
    table_header_1: {
        flex: 0.05,
    },
    table_header_2: {
        flex: 0.45,
        textAlign: "center",
    },
    table_header_3: {
        flex: 0.1,
        textAlign: "center",
    },
    table_header_4: {
        flex: 0.2,
        textAlign: "right",
    },
    table_header_5: {
        flex: 0.2,
        textAlign: "right",
    },
    table_body: {
        borderBottomWidth: 1,
        marginBottom: 10,
    },
    table_body_content_one: {
        flexDirection: "row",
        marginBottom: 5,
    },
    table_body_text: {
        fontSize: 11,
    },
    table_body_1: {
        flex: 0.05,
    },
    table_body_2: {
        flex: 0.45,
    },
    table_body_3: {
        flex: 0.1,
        textAlign: "center",
    },
    table_body_4: {
        flex: 0.2,
        textAlign: "right",
    },
    table_body_5: {
        flex: 0.2,
        textAlign: "right",
    },
    table_body_content_two: {
        flexDirection: "row",
        marginTop: 5,
    },
    table_body_item_free_text: {
        flex: 0.6,
        marginRight: 10,
        marginLeft: 26,
    },
    table_body_item_calculations: {
        flex: 0.4,
    },
    calculation_content: {
        flexDirection: "row",
        paddingVertical: 4,
        borderTopWidth: 1,
    },
    calculation_data: {
        flex: 0.5,
        textAlign: "right",
    },

    grand_total_content: {
        flexDirection: "row",
        justifyContent: "flex-end",
        flex: 1,
    },
    grand_total_data: {
        flex: 1,
        textAlign: "right",
        marginBottom: 5,
    },
    text_grand_total: {
        borderBottomWidth: 1,
        paddingBottom: 5,
    },

    terms_header: {
        fontSize: 10,
        fontFamily: 'CalibriBold'
    },
    term_title: {
        fontSize: 9,
        fontFamily: 'CalibriBold',
    },
    term_value: {
        fontSize: 9,
        marginRight: 10,
    },
    header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        paddingBottom: 5,
        marginBottom: 5,
    },
    headerLeft: {
       flex: 0.4,
       position: "relative",
    },
    headerLeftContent: {
        position: "absolute",
        bottom: -5,
    },
    headerCenter: {
        flex: 0.2,
        alignItems: "center"
    },
    headerRight: {
        flexDirection: "row",
        justifyContent: "flex-end",
        flex: 0.4,
    },
    company_name: {
        fontSize: 15,
        fontFamily: 'CalibriBold',
        textTransform: "capitalize"
    },  
    company_addr: {
        fontSize: 10,
        paddingVertical: 2,
    },

    headerlogo: {
        width: 80,
        height: "auto",  
    },
    footer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 20,
        backgroundColor: "#ffffff"
    },
    footerQuotationNumber: {
        fontSize: 11,
        textAlign: 'left',
        textTransform: "capitalize"
    },
    footerPageNumber: {
        fontSize: 11,
        right: 0,
        top: 4,
        position: 'absolute',
        textAlign: 'right'
    },
    footerTopSection: {
        position: 'relative',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginHorizontal: 35,
        paddingTop: 4,
    },
    footer_company_name: {
        fontSize: 11,
        fontWeight: "500",
        marginTop: 5,
        marginBottom: 5,
        marginHorizontal: 35,
        textTransform: "uppercase"
    },

    authorisedCompanyName: {
        fontSize: 11,
        fontWeight: "500",
        textTransform: "uppercase",
        textAlign: "left",
        marginBottom: 60,
        marginTop: 10
    },

    authorisedTextData: {
        fontSize: 11,
        textAlign: "left",
    },
    footer_details: {
        flexDirection: "row",
        marginHorizontal: 35,
    },
    footer_detail_text: {
        fontSize: 9,
    },
    footer_detail_data1: {
        flex: 0.23
    },
    footer_detail_data2: {
        flex: 0.30
    },
    footer_detail_data3: {
        flex: 0.26
    },
    footer_detail_data4: {
        flex: 0.20
    },
});

export default generateOtherCurrInvoicePDF;