import React, { Component } from 'react';

import Pagination from "react-js-pagination";
import Loading from './../../../helper/loading'
import Popup from 'reactjs-popup';

import * as APIManager from './../../../APIManager/APIManager';
import * as Fi from "react-icons/fi";
import * as Ri from "react-icons/ri"
import * as Bi from "react-icons/bi"

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css'

import {
    AiOutlineCalendar,
} from "react-icons/ai"
import TableLoading from '../../../helper/TableLoading';
import FilterTable from '../../../helper/FilterTable';
import { convertDateStringtoDateObject, convertDateToDDMMYYYY, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';


class OrderTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            history: this.props.history,
            getQuotationList: this.props.getQuotationList,

            customerOrderList: [],
            managersList: [],

            number: "",
            customerNumber: "",
            customerName: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            status: "",
            productStatus: "",
            invoiceStatus: "",
            paymentStatus: "",
            manager: "",
            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
            subTotal: 0,
            total: 0,
            minSubTotal: "",
            maxSubTotal: "",
            minTotal: "",
            maxTotal: "",
            minModified: "",
            maxModified: "",
            minQuotationValid: "",
            maxQuotationValid: "",
            reference: "",

            minPODate: "",
            maxPODate: "",
            minPODeliveryDate: "",
            maxPODeliveryDate: "",
            minActualDelivery: "",
            maxActualDelivery: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,

            firstVisibleIndex: 0,
            chooseColumnList: [
                {
                    id: "0",
                    name: "Number",
                    visible: true,
                    field: "number",
                    type: "notDate",
                    fixed: false
                }, {
                    id: "1",
                    name: "Customer number",
                    visible: true,
                    field: "customerNumber",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "2",
                    name: "Customer name",
                    visible: true,
                    field: "customerName",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "3",
                    name: "Status",
                    visible: true,
                    field: "status",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "4",
                    name: "Manager",
                    visible: true,
                    field: "managers",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "5",
                    name: "Subtotal",
                    visible: true,
                    field: "subTotal",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: parseInt,
                    }
                }, {
                    id: "6",
                    name: "Total",
                    visible: true,
                    field: "total",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: parseInt,
                    }
                },
                {
                    id: "7",
                    name: "PO Date",
                    visible: false,
                    field: "poDate",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: parseInt,
                    }
                },
                {
                    id: "8",
                    name: "Delivery as per PO",
                    visible: true,
                    field: "poDeliveryDate",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: parseInt,
                    }
                },
                {
                    id: "9",
                    name: "Estimated Delivery",
                    visible: true,
                    field: "deliveryDate",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: parseInt,
                    }
                },
                {
                    id: "10",
                    name: "Actual Delivery",
                    visible: true,
                    field: "actualDeliveryDate",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                },
                {
                    id: "11",
                    name: "Modified",
                    visible: false,
                    field: "modifiedDate",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "12",
                    name: "Created",
                    visible: false,
                    field: "created",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "13",
                    name: "Quotation valid",
                    visible: false,
                    field: "quotationValidityEnd",
                    type: 'Date',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }, {
                    id: "14",
                    name: "Reference",
                    visible: false,
                    field: "reference",
                    type: 'notDate',
                    fixed: false,
                    sortby: {
                        reverse: false,
                        primer: (a) => a.toUpperCase(),
                    }
                }
            ],
      
        }
        this.timer = null;
        this.onSelectStatus = this.onSelectStatus.bind(this)
        this.sortField = this.sortField.bind(this)
    }

    componentDidMount() {
        let checkColumns = localStorage.getItem("quotationSelected__Columns")
        if (checkColumns) {
            let col = JSON.parse(checkColumns)
            let firstVisibleIndex = 0;
            for (let i = 0; i < col.length; i++) {
                if (col[i].visible === true) {
                    firstVisibleIndex = i;
                    break;
                }
            }
            this.setState({
                chooseColumnList: col,
                firstVisibleIndex: firstVisibleIndex
            }, () => {
                this.getManagers()
            })
        } else {
            localStorage.setItem("quotationSelected__Columns", JSON.stringify(this.state.chooseColumnList))
            this.getManagers()
            
        }
    }

    //******************* ascending descending order function Dynamic Code ************************** ********************************


    handleSort = (data, onload) => {
        let fieldName = data.field
        let fieldType = data.type
        // console.log(type, field);
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.customerOrderList.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ customerOrderList: sortedOrders }, () => {
                this.saveDataOfSorting()
            });
        });
    }

    //******************* ascending descending order function ********************************




    getManagers = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
            .then(response => {
                this.setState({
                    isLoading: false,
                    managersList: response.data
                }, () => {
                    this.getFilterData()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                }, () => {
                    this.getFilterData()
                })
            });
    }

    saveDataOfSorting = () => {
        let filterDataArray = {
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
            'number': this.state.number,
            'customerNumber': this.state.customerNumber,
            'customerName': this.state.customerName,
            'minCreated': this.state.minCreated !== null ? this.state.minCreated : "",
            'maxCreated': this.state.maxCreated !== null ? this.state.maxCreated : "",
            'minDeliveryDate': this.state.minDeliveryDate !== null ? this.state.minDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",

            'minPODate': this.state.minPODate !== null ? this.state.minPODate : "",
            'maxPODate': this.state.maxPODate !== null ? this.state.maxPODate : "",
            'minPODeliveryDate': this.state.minPODeliveryDate !== null ? this.state.minPODeliveryDate : "",
            'maxPODeliveryDate': this.state.maxPODeliveryDate !== null ? this.state.maxPODeliveryDate : "",
            'minActualDelivery': this.state.minActualDelivery !== null ? this.state.minActualDelivery : "",
            'maxActualDelivery': this.state.maxActualDelivery !== null ? this.state.maxActualDelivery : "",

            'status': this.state.status,
            'productStatus': this.state.productStatus,
            'paymentStatus': this.state.paymentStatus,
            'invoiceStatus': this.state.invoiceStatus,
            'manager': this.state.manager,
            'minSubTotal': this.state.minSubTotal,
            'maxSubTotal': this.state.maxSubTotal,
            'minTotal': this.state.minTotal,
            'maxTotal': this.state.maxTotal,
            'minModified': this.state.minModified !== null ? this.state.minModified : "",
            'maxModified': this.state.maxModified !== null ? this.state.maxModified : "",
            'minQuotationValid': this.state.minQuotationValid !== null ? this.state.minQuotationValid : "",
            'maxQuotationValid': this.state.maxQuotationValid !== null ? this.state.maxQuotationValid : "",
            'reference': this.state.reference,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("quotationOrderTable", JSON.stringify(filterDataArray))
    }

    filterOrders = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.getCustomerOrders()
        }, 800);

        let filterDataArray = {
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
            'number': this.state.number,
            'customerNumber': this.state.customerNumber,
            'customerName': this.state.customerName,
            'minCreated': this.state.minCreated !== null ? this.state.minCreated : "",
            'maxCreated': this.state.maxCreated !== null ? this.state.maxCreated : "",
            'minDeliveryDate': this.state.minDeliveryDate !== null ? this.state.minDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",

            'minPODate': this.state.minPODate !== null ? this.state.minPODate : "",
            'maxPODate': this.state.maxPODate !== null ? this.state.maxPODate : "",
            'minPODeliveryDate': this.state.minPODeliveryDate !== null ? this.state.minPODeliveryDate : "",
            'maxPODeliveryDate': this.state.maxPODeliveryDate !== null ? this.state.maxPODeliveryDate : "",
            'minActualDelivery': this.state.minActualDelivery !== null ? this.state.minActualDelivery : "",
            'maxActualDelivery': this.state.maxActualDelivery !== null ? this.state.maxActualDelivery : "",

            'status': this.state.status,
            'productStatus': this.state.productStatus,
            'paymentStatus': this.state.paymentStatus,
            'invoiceStatus': this.state.invoiceStatus,
            'manager': this.state.manager,
            'minSubTotal': this.state.minSubTotal,
            'maxSubTotal': this.state.maxSubTotal,
            'minTotal': this.state.minTotal,
            'maxTotal': this.state.maxTotal,
            'minModified': this.state.minModified !== null ? this.state.minModified : "",
            'maxModified': this.state.maxModified !== null ? this.state.maxModified : "",
            'minQuotationValid': this.state.minQuotationValid !== null ? this.state.minQuotationValid : "",
            'maxQuotationValid': this.state.maxQuotationValid !== null ? this.state.maxQuotationValid : "",
            'reference': this.state.reference,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("quotationOrderTable", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {

        let filterData = JSON.parse(localStorage.getItem("quotationOrderTable"))
        if (filterData !== null) {
            this.setState({
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,
                number: filterData.number,
                customerNumber: filterData.customerNumber,
                customerName: filterData.customerName,
                minCreated: convertDateStringtoDateObject(filterData.minCreated),
                maxCreated: convertDateStringtoDateObject(filterData.maxCreated),
                minDeliveryDate: convertDateStringtoDateObject(filterData.minDeliveryDate),
                maxDeliveryDate: convertDateStringtoDateObject(filterData.maxDeliveryDate),

                minPODate: convertDateStringtoDateObject(filterData.minPODate),
                maxPODate: convertDateStringtoDateObject(filterData.maxPODate),
                minPODeliveryDate: convertDateStringtoDateObject(filterData.minPODeliveryDate),
                maxPODeliveryDate: convertDateStringtoDateObject(filterData.maxPODeliveryDate),
                minActualDelivery: convertDateStringtoDateObject(filterData.minActualDelivery),
                maxActualDelivery: convertDateStringtoDateObject(filterData.maxActualDelivery),

                status: filterData.status,
                productStatus: filterData.productStatus,
                paymentStatus: filterData.paymentStatus,
                invoiceStatus: filterData.invoiceStatus,
                manager: filterData.manager,
                minSubTotal: filterData.minSubTotal,
                maxSubTotal: filterData.maxSubTotal,
                minTotal: filterData.minTotal,
                maxTotal: filterData.maxTotal,
                minModified: convertDateStringtoDateObject(filterData.minModified),
                maxModified: convertDateStringtoDateObject(filterData.maxModified),
                minQuotationValid: convertDateStringtoDateObject(filterData.minQuotationValid),
                maxQuotationValid: convertDateStringtoDateObject(filterData.maxQuotationValid),
                reference: filterData.reference,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getCustomerOrders()
            })
        } else {
            this.getCustomerOrders()
        }
    }

 
    onSelectStatus(status) {
        this.setState({
            status: status
        }, () => {
            this.getCustomerOrders()
        })
    }

    getCustomerOrders = () => {
        let { number, customerNumber, customerName, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, status, productStatus, start, length, invoiceStatus, paymentStatus, manager, minSubTotal, maxSubTotal, minTotal, maxTotal, minModified, maxModified, minQuotationValid, maxQuotationValid, reference, minPODate, maxPODate, minPODeliveryDate, maxPODeliveryDate, minActualDelivery, maxActualDelivery } = this.state

        this.setState({ isLoading: true, error: false })

        APIManager.callCustomerOrderQuotationList(number, customerNumber, customerName, convertDateToMMDDYYYYWithoutZero(minCreated), convertDateToMMDDYYYYWithoutZero(maxCreated), convertDateToMMDDYYYY(minDeliveryDate), convertDateToMMDDYYYY(maxDeliveryDate), status, productStatus, start, length, invoiceStatus, paymentStatus, manager, minSubTotal, maxSubTotal, minTotal, maxTotal, convertDateToMMDDYYYY(minModified), convertDateToMMDDYYYY(maxModified), convertDateToMMDDYYYY(minQuotationValid), convertDateToMMDDYYYY(maxQuotationValid), reference, convertDateToMMDDYYYY(minPODate), convertDateToMMDDYYYY(maxPODate), convertDateToMMDDYYYY(minPODeliveryDate), convertDateToMMDDYYYY(maxPODeliveryDate), convertDateToMMDDYYYY(minActualDelivery), convertDateToMMDDYYYY(maxActualDelivery))
            .then(response => {
                let subTotal = 0;
                let total = 0;
                for (let i = 0; i < response.data.length; i++) {
                    subTotal += response.data[i].subTotal
                    total += response.data[i].total
                }
                this.setState({
                    customerOrderList: response.data,
                    totalRecords: response.totalRecords,
                    subTotal: subTotal.toFixed(2),
                    total: total.toFixed(2),
                    isLoading: false,
                }, () => {
                    this.state.getQuotationList(this.state.customerOrderList)
                    if(this.state.fieldName){
                        let data = {
                            field: this.state.fieldName,
                            type: this.state.fieldType
                        }
                        this.handleSort(data, true)
                    }
                 
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if (errors.status === "userDataError") {
                        this.logout()
                    }
                })
            });
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.filterOrders()
        });
    }

    updateColumn = (field, isVisible) => {
        let colums = this.state.chooseColumnList
        let col = [...this.state.chooseColumnList]
        var foundIndex = colums.findIndex(x => x.field === field);
        col[foundIndex].visible = !isVisible;

        let firstVisibleIndex = 0;
        for (let i = 0; i < col.length; i++) {
            if (col[i].visible === true) {
                firstVisibleIndex = i;
                break;
            }
        }

        this.setState({
            chooseColumnList: col,
            firstVisibleIndex: firstVisibleIndex
        }, () => {
            localStorage.setItem("quotationSelected__Columns", JSON.stringify(col))
        })
    }

    clearFilter = () => {
        localStorage.removeItem("quotationOrderTable")
        this.setState({
            number: "",
            customerNumber: "",
            customerName: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            status: "",
            productStatus: "",
            paymentStatus: "",
            invoiceStatus: "",
            manager: "",
            minSubTotal: "",
            maxSubTotal: "",
            minTotal: "",
            maxTotal: "",
            minModified: "",
            maxModified: "",
            minQuotationValid: "",
            maxQuotationValid: "",
            reference: "",
            minPODate: "",
            maxPODate: "",
            minPODeliveryDate: "",
            maxPODeliveryDate: "",
            minActualDelivery: "",
            maxActualDelivery: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getCustomerOrders()
        })
    }

    addCustomerOrder = () => {
        let slug = "CRM_Quotations"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createneworder"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editCustomerOrder = (id, num) => {
        localStorage.setItem("customerOrderId", id)
        localStorage.setItem("orderType", "customerOrder")
        localStorage.setItem("customerOrderNumber", num)

        this.props.history.push({
            pathname: "/editcustomerorder",
            state: { customerOrderId: id, orderType: 'customerOrder', customerOrderNumber: num }
        })

    }

    sortField = (i, field, sortby) => {
        let { customerOrderList, chooseColumnList } = this.state
        if (sortby) {
            chooseColumnList[i].sortby = { primer: sortby.primer, reverse: !sortby.reverse }

            let primer = sortby.primer
            let reverse = sortby.reverse

            const key = primer ?
                function (x) {
                    return primer(x[field])
                } :
                function (x) {
                    return x[field]
                };
            reverse = !reverse ? 1 : -1;

            let newSortedList = function (a, b) {
                return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
            }

            customerOrderList.sort(newSortedList)

            this.setState({
                customerOrderList,
                chooseColumnList
            })
        }
    }


    render() {
        return (
            <div className='__kDoEM__table'>
                <table>
                    <thead>
                        <tr>
                            <th><div onClick={() => this.addCustomerOrder()}><Fi.FiPlus /></div></th>
                            {
                                this.state.chooseColumnList.map((data, i) => {
                                    if (data.visible === true)
                                        return <th className="tableHeader" key={'tableHeader' + i} onClick={() => this.handleSort(data, false)}><span>{data.name}{this.state.fieldName === data.field && (this.state.sortVal ? <span><Fi.FiArrowUp /></span> : <span><Fi.FiArrowDown /></span>)} </span></th>
                                        

                                })
                            }

                            {/* { data.sortby && <span onClick={()=>this.sortField(i, data.field, data.sortby)}>{data.field.reverse ? <Bi.BiCaretDown /> : <Bi.BiCaretUp />}</span>} */}
                            <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><Fi.FiChevronsDown /></th>} position="bottom right">
                                <div className="selectColumns">
                                    <ul>
                                        {
                                            this.state.chooseColumnList.map((data, i) => {
                                                if (data.fixed)
                                                    return <li key={'chooseColumn' + i}><label htmlFor={data.field}><span>{data.name}</span> <input type="checkbox" checked={data.visible} disabled></input> </label></li>
                                                else
                                                    return <li key={'chooseColumn' + i}><label htmlFor={data.field}><span>{data.name}</span> <input type="checkbox" checked={data.visible} onChange={(e) => this.updateColumn(data.field, data.visible)}></input> </label></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </Popup>
                            <th></th>
                            <th className="text-center"><div onClick={() => this.addCustomerOrder()}><Fi.FiPlus /></div></th>
                        </tr>
                        <tr>
                            <th></th>
                            {
                                this.state.chooseColumnList[0].visible &&
                                <th style={{ width: 45 }}><input type='text' value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => this.filterOrders())} /></th>
                            }
                            {
                                this.state.chooseColumnList[1].visible &&
                                <th style={{ width: 45 }}><input type='text' value={this.state.customerNumber} onChange={(e) => this.setState({ customerNumber: e.target.value }, () => this.filterOrders())} /></th>
                            }
                            {
                                this.state.chooseColumnList[2].visible &&
                                <th><input type='text' value={this.state.customerName} onChange={(e) => this.setState({ customerName: e.target.value }, () => this.filterOrders())} /></th>
                            }
                            {
                                this.state.chooseColumnList[3].visible &&
                                <th>
                                    <select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value }, () => this.filterOrders())}>
                                        <option value=""></option>
                                        <option value="quotation">Quotation</option>
                                        <option value="waiting for confirmation">Waiting for confirmation</option>
                                        <option value="confirmed">Confirmed</option>
                                        <option value="waiting for production">Waiting for Production</option>
                                        <option value="in production">In Production</option>
                                        <option value="ready for shipment">Ready for Shipment</option>
                                        <option value="partially invoiced">Partially Invoiced</option>
                                        <option value="partially shipped">Partially Shipped</option>
                                        <option value="invoiced">Invoiced</option>
                                        <option value="shipped">Shipped</option>
                                        <option value="delivered">Delivered</option>
                                        <option value="archived">Archived</option>
                                        <option value="cancelled">Cancelled</option>
                                    </select>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[4].visible &&
                                <th>
                                    <select className="text-capitalize" style={{ minWidth: 100 }} value={this.state.manager} onChange={(e) => this.setState({ manager: e.target.value }, () => { this.filterOrders() })}>
                                        <option value=""></option>
                                        {
                                            this.state.managersList !== null &&
                                            this.state.managersList.map((data) => (
                                                <option value={data._id} key={data._id}>{data.username ? data.username : data.full_name}</option>
                                            ))
                                        }
                                    </select>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[5].visible &&
                                <th style={{ minWidth: 140 }}>
                                    <input type="text" style={{ width: 50 }} value={this.state.minSubTotal} placeholder="min" onChange={(e) => this.setState({ minSubTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterOrders() })} />&nbsp;-&nbsp;
                                    <input type="text" style={{ width: 50 }} value={this.state.maxSubTotal} placeholder="max" onChange={(e) => this.setState({ maxSubTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterOrders() })} />
                                </th>
                            }
                            {
                                this.state.chooseColumnList[6].visible &&
                                <th style={{ minWidth: 140 }}>
                                    <input type="text" style={{ width: 50 }} value={this.state.minTotal} placeholder="min" onChange={(e) => this.setState({ minTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterOrders() })} />&nbsp;-&nbsp;
                                    <input type="text" style={{ width: 50 }} value={this.state.maxTotal} placeholder="max" onChange={(e) => this.setState({ maxTotal: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterOrders() })} />

                                </th>
                            }
                            {
                                this.state.chooseColumnList[7].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minPODate
                                            }
                                            onChange={
                                                date => this.setState({ minPODate: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minPODate}
                                            selected={
                                                this.state.maxPODate
                                            }
                                            onChange={
                                                date => this.setState({ maxPODate: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[8].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minPODeliveryDate
                                            }
                                            onChange={
                                                date => this.setState({ minPODeliveryDate: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minPODeliveryDate}
                                            selected={
                                                this.state.maxPODeliveryDate
                                            }
                                            onChange={
                                                date => this.setState({ maxPODeliveryDate: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[9].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minDeliveryDate
                                            }
                                            onChange={
                                                date => this.setState({ minDeliveryDate: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minDeliveryDate}
                                            selected={
                                                this.state.maxDeliveryDate
                                            }
                                            onChange={
                                                date => this.setState({ maxDeliveryDate: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[10].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minActualDelivery
                                            }
                                            onChange={
                                                date => this.setState({ minActualDelivery: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minActualDelivery}
                                            selected={
                                                this.state.maxActualDelivery
                                            }
                                            onChange={
                                                date => this.setState({ maxActualDelivery: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[11].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minModified
                                            }
                                            onChange={
                                                date => this.setState({ minModified: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minModified}
                                            selected={
                                                this.state.maxModified
                                            }
                                            onChange={
                                                date => this.setState({ maxModified: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[12].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minCreated
                                            }
                                            onChange={
                                                date => this.setState({ minCreated: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minCreated}
                                            selected={
                                                this.state.maxCreated
                                            }
                                            onChange={
                                                date => this.setState({ maxCreated: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }

                            {
                                this.state.chooseColumnList[13].visible &&
                                <th>
                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.minQuotationValid
                                            }
                                            onChange={
                                                date => this.setState({ minQuotationValid: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>

                                    <div className="value position-relative">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.minQuotationValid}
                                            selected={
                                                this.state.maxQuotationValid
                                            }
                                            onChange={
                                                date => this.setState({ maxQuotationValid: date }, () => {
                                                    this.filterOrders()
                                                })
                                            }
                                            className="filterdateoption" />
                                        <AiOutlineCalendar className="datepickerIcon" />
                                    </div>
                                </th>
                            }
                            {
                                this.state.chooseColumnList[14].visible &&
                                <th><input type='text' value={this.state.reference} onChange={(e) => this.setState({ reference: e.target.value }, () => this.filterOrders())} /></th>

                            }
                            <th></th>
                            <th></th>
                            <th onClick={() => this.clearFilter()} className="text-center"><Fi.FiX /> </th>
                        </tr>
                        <tr>
                            <td></td>
                            {
                                this.state.chooseColumnList.map((data, i) => {
                                    if (data.visible) {
                                        if (i === 5) {
                                            return <td key={'countTotal' + i}><b>{(this.state.subTotal).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                        } else if (i === 6) {
                                            return <td key={'countTotal' + i}><b>{(this.state.total).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")} <Bi.BiRupee /></b></td>
                                        } else if (i === this.state.firstVisibleIndex) {
                                            return <td key={'countTotal' + i}><b>Total:</b></td>
                                        } else {
                                            return <td key={'countTotal' + i}></td>
                                        }
                                    }
                                })
                            }

                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    {
                        this.state.isLoading ?
                            <TableLoading
                                tr={8}
                                td={
                                    this.state.chooseColumnList.filter(function (data) {
                                        return data.visible == true;
                                    }).length + 4
                                }
                            />
                            :
                            <tbody>
                                {
                                    this.state.customerOrderList.map((data, i) => (
                                        <tr key={data._id} onClick={() => this.editCustomerOrder(data._id, data.number.toUpperCase())}>
                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                            {
                                                this.state.chooseColumnList.map((d, j) => {
                                                    if (d.visible === true) {
                                                        if (d.name === "PO Date" || d.name === "Delivery as per PO" || d.name === "Modified" || d.name === "Created" || d.name === "Estimated Delivery" || d.name === "Actual Delivery" || d.name === "Quotation valid") {
                                                            let dateString = data[d.field] ? data[d.field] : '';
                                                            let dateParts = dateString ? dateString.toString().split("/") : '';
                                                            let dateObject = ''
                                                            if(dateParts.length > 1){
                                                                dateObject = dateParts ? dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2] : '';
                                                            }else{
                                                                dateObject = dateString
                                                            }
                                                            return <td key={'selectedCol' + j}>{dateObject}</td>
                                                        } else {
                                                            return <td key={'selectedCol' + j}>{data[d.field] ? data[d.field].toString() : ''}</td>
                                                        }
                                                    }
                                                })
                                            }

                                            <td></td>
                                            <td></td>
                                            <td className="text-center">
                                                {
                                                    data.is_important_note &&
                                                    <Ri.RiFlag2Line className="color-red" />
                                                }
                                            </td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                    }

                </table>

                <div>
                    <div className="float-left mt-10px">
                        <div className="tableElemContain" style={{ width: 80 }}>
                            <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                <option value="25">25 rows</option>
                                <option value="50">50 rows</option>
                                <option value="100">100 rows</option>
                                <option value="">All rows</option>
                            </select>
                        </div>
                    </div>
                    <div className="float-right mt-10px">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                            totalItemsCount={this.state.totalRecords}
                            pageRangeDisplayed={10}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

export default OrderTable;