import React, { Component } from 'react';
import GroupItem from './AddProductDetail';

class ProductGroupWithOtherCurrency extends Component {

    constructor(props){
        super(props)
        this.state = {

            productObj: {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
                'requiredQuantity': '',
                'vendorUoM': '',
                'exchangeRate': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
            },


            discountPercentage: "",
            discount: "",
            total: "",
            taxableFees: "",
            taxPercentage: "",
            tax: "",
            additionalFees: "",
            grandTotal: "",

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: [],
            totalOfProductCost: 0.00,
            totalOfProductCostFinal: 0.00,
            totalOfRawCostFinal: 0.00,
            totalOfRawCostInOther: 0.00,
            totalNoOfProduct: 0,
            vendor: this.props.vendor,
            conversionDetail: this.props.conversionDetail,
            setDataFromGroup: this.props.setDataFromGroup,
        }
    }

    componentDidMount(){
        this.setGroupItem(this.state.conversionDetail)
    }

    /* Group Item All Countings */
    setGroupItem = (conversionDetail) => {
        let dummyObj =  {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
                'requiredQuantity': '',
                'vendorUoM': '',
                'exchangeRate': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
        } 

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <GroupItem
                    key={'proGroup'}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={this.state.productGroupCounter}
                    groupData={dummyObj}
                    conversionDetail={conversionDetail}
                    vendorId={this.state.vendor}
                />
            ]),            
        })
    }

    addNewGroupFields = (counter) => {
        let dummyObj =  {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
                'requiredQuantity': '',
                'vendorUoM': '',
                'exchangeRate': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
        }

        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <GroupItem
                        key={'proGroup'+this.state.productGroupCounter} 
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter+1}
                        groupData={dummyObj}
                        conversionDetail={this.state.conversionDetail}
                        vendorId={this.state.vendor}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: this.state.productGroupCounter+1,
            })
        }
    }
    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        },()=>{
            this.countTotal()
        })
    }
    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        },()=> {
            this.countTotal()
        })
    }

    countTotal = () => {
        let arr = this.state.productGroupData;
        let totalCost = 0;
        let totalQuantity = 0;
        let rawTotal = 0;
        let rawTotalInOther = 0
        for(let i=0;i<arr.length;i++){
            if(arr[i] !== null){
                totalCost += arr[i].subTotal !== "" ? parseFloat(arr[i].subTotal) : 0
                totalQuantity += arr[i].quantity !== "" ? parseFloat(arr[i].quantity) : 0 
                rawTotal += arr[i].productTotal !== "" ? parseFloat(arr[i].productTotal) : 0
                rawTotalInOther += arr[i].rawTotalInOtherCurrency !== "" ? parseFloat(arr[i].rawTotalInOtherCurrency) : 0
            }
        }
        this.setState({
            totalOfProductCost: totalCost.toFixed(2),
            totalOfProductCostFinal: totalCost.toFixed(2),
            totalOfRawCostFinal: Math.round(rawTotal).toFixed(2),
            totalNoOfProduct: totalQuantity,
            totalOfRawCostInOther: rawTotalInOther.toFixed(2)
        },()=>{
            this.state.setDataFromGroup(this.state.totalOfProductCostFinal,this.state.totalOfRawCostFinal,this.state.totalOfRawCostInOther,arr)
        })
    }


    render() {
        return (
            <div className="main-container mt-20 order_product_group">
                <table>
                    <thead>
                        <tr>
                            <th className='pg-tab-head'></th>
                            <th className='pg-tab-head' style={{ width: 200 }}>Product group</th>
                            <th className='pg-tab-head' style={{ width: 200 }}>Item</th>
                            <th className='pg-tab-head'>Req. Qty</th>
                            <th className='pg-tab-head'>Ordered Qty</th>
                            <th className='pg-tab-head'>Price/UoM</th>
                            <th className='pg-tab-head'>Ex. Rate</th>
                            <th className='pg-tab-head'>Price in {this.state.conversionDetail.currencyName.toUpperCase()}</th>
                            <th className='pg-tab-head'>Discount</th>
                            <th className='pg-tab-head'>Total</th>
                            <th className='pg-tab-head'>Expected delivery date</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.productGroupFields
                        }
                        <tr>
                            <td></td>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalNoOfProduct}</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalOfRawCostInOther} {this.state.conversionDetail.currencySymbol}</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProductGroupWithOtherCurrency;