import React from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Pagination from "react-js-pagination";
import * as APIManager from './../../../APIManager/APIManager'
import Helper from './../../../helper/helper'
import {
    FiPlus,
    FiX,
    FiEye
} from "react-icons/fi";
import * as Fi from "react-icons/fi";

import { AiOutlineDelete } from 'react-icons/ai';

export default class FilterTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageLoading: false,

            tableListing: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

            tableName: "",
            productGroupName: "",
            description: "",
            fields: "",
        };
    }

    componentDidMount() {
        this.getTables()
    }


    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType) => {
        let sortVal = true;
        if (this.state.fieldName === fieldName) {
            sortVal = !this.state.sortVal;
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        });
        const sortedOrders = this.state.tableListing.sort((a, b) => {
            if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                // console.log('NotDate success');
                const numA = parseFloat(a[fieldName]);
                const numB = parseFloat(b[fieldName]);
                if (sortVal) {
                    return numA > numB ? 1 : -1;
                } else {
                    return numA < numB ? 1 : -1;
                }
            } else {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            }

        });
        this.setState({ tableListing: sortedOrders });
    };
    //******************* ascending descending order function end ********************************

    clearFilter = () => {
        this.setState({
            tableName: "",
            productGroupName: "",
            description: "",
            fields: "",
            start: 0,
            length: 25,
            activePage: 1,
        }, () => {
            this.getTables()
        })
    }
    handlePageChange(pageNumber) {


        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getTables()
        });
    }


    getTables = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let {
            tableName,
            description,
            productGroupName,
            fields
        } = this.state

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("tableName", tableName);
        formdata.append("description", description);
        formdata.append("productGroupName", productGroupName);
        formdata.append("fields", fields);

        fetch(APIManager.WEBSERVICES_URL + 'stock/filterTable/getAllFilterTable', {
            method: "post",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        tableListing: result.data,
                        totalRecords: result.totalRecords
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {
                    that.setState({
                        error: true,
                        errormessage: result.message
                    })
                }

            }, (error) => {
                console.log(error)
            }
            )
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    addFilterTable = () => {
        let slug = "Stock_FilterTable"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createfiltertable"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    viewFilterTable = (id) => {
        localStorage.setItem("customTableId", id)
        this.props.history.push({
            pathname: "/customtable"
        })

    }


    deleteFilterTable = (id) => {
        let slug = "Stock_FilterTable"
        let permission = "delete"
        if (APIManager.checkUserPermission(slug, permission)) {
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {

                this.setState({ loading: true })
                const that = this;


                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", id)

                fetch(APIManager.WEBSERVICES_URL + 'stock/filterTable/deleteFilterTable', {
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                        if (result.status === "success") {
                            that.setState({ loading: false }, () => {
                                that.getTables()
                            })
                        } else if (result.status === "userDataError") {
                            that.setState({ loading: false }, () => {
                                that.logout()
                            })
                        } else {
                            that.setState({
                                loading: false,
                                error: true,
                                errormessage: result.message
                            })
                        }
                    }, (error) => {
                        console.log(error)
                    }
                    )
            }
        } else {
            alert("You don't have permission to " + permission + " this record")
        }

    }





    goBack = () => {
        this.props.history.goBack();
    }


    _importStorageData = () => {
        this.props.history.push('/importstorage')
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getProductGroupList()
        }
    }

    _handleKeyDownStorageLoc = (e) => {
        if (e.key === 'Enter') {
            this.getStorageLocations()
        }
    }

    render() {
        return (
            <div className="page absoluteWhite">
                {
                    this.state.pageLoading &&
                    <div className="pageLoading"></div>
                }
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isFilterTable={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{Strings.Stock.FilterTable}</div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent __kDoEM__table">
                        <div className="stocksetting">
                            <div style={{ overflowX: "auto" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="tableHeader tableHeadButton" style={{ width: 40 }}><div onClick={() => this.addFilterTable()}><FiPlus /></div></th>
                                            <th className="tableHeader" onClick={() => this.sortCustomer('tableName', 'notDate')}><span>Table name</span> {this.state.fieldName === "tableName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "tableName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            <th className="tableHeader" onClick={() => this.sortCustomer('productGroupName', 'notDate')}><span>Product group</span> {this.state.fieldName === "productGroupName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "productGroupName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            <th className="tableHeader" onClick={() => this.sortCustomer('fields', 'notDate')}><span>Fields</span> {this.state.fieldName === "fields" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "fields" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            <th className="tableHeader" onClick={() => this.sortCustomer('description', 'notDate')}><span>Description</span> {this.state.fieldName === "description" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "description" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            <th className="tableHeader"></th>
                                            <th className="tableHeader tableHeadButton"><div onClick={() => this.addFilterTable()}><FiPlus /></div></th>
                                        </tr>
                                        <tr>
                                            <th style={{ width: 40 }} className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                            <th className="tableElemContain"><input type="text" value={this.state.tableName} onChange={(e) => this.setState({ tableName: e.target.value }, () => { this.getTables() })} /></th>
                                            <th className="tableElemContain"><input type="text" value={this.state.productGroupName} onChange={(e) => this.setState({ productGroupName: e.target.value }, () => { this.getTables() })} /></th>
                                            <th className="tableElemContain"><input type="text" value={this.state.fields} onChange={(e) => this.setState({ fields: e.target.value }, () => { this.getTables() })} /></th>
                                            <th className="tableElemContain"><input type="text" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value }, () => { this.getTables() })} /></th>
                                            <th className="tableElemContain tableAddButton"><div>{/*<FiSearch />*/}</div></th>
                                            <th className="tableElemContain tableAddButton" onClick={() => this.clearFilter()}><div><FiX /></div></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            this.state.tableListing !== null &&
                                            this.state.tableListing.map((data, i) => (
                                                <tr key={i}>
                                                    <td style={{ width: 40 }}>{i + 1}</td>
                                                    <td style={{ width: 150 }}>{data.tableName}</td>
                                                    <td style={{ width: 125 }}>{data.productGroupName}</td>
                                                    <td>{data.fields.toString()}</td>
                                                    <td>{data.description}</td>
                                                    <td style={{ width: 40 }}><div onClick={this.viewFilterTable.bind(this, data._id)}><FiEye className="editCusomerBtn" /></div></td>
                                                    <td style={{ width: 40 }}><div onClick={this.deleteFilterTable.bind(this, data._id)}><AiOutlineDelete className="editCusomerBtn" /></div></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div>
                                    <div className="float-left mt-10px">
                                        <div className="tableElemContain" style={{ width: 80 }}>
                                            <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                                <option value="25">25 rows</option>
                                                <option value="50">50 rows</option>
                                                <option value="100">100 rows</option>
                                                <option value="">All rows</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="float-right mt-10px">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                            totalItemsCount={this.state.totalRecords}
                                            pageRangeDisplayed={10}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}