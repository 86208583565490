import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import Loading from './../../../../helper/loading'
import RichTextEditor from 'react-rte';

// import ProductGroupForInr from './ProductGroupForInr';
// import ProductGroupForOther from './ProductGroupForOther';
import ProductGroup from './ProductGroup'
import { convertDateToMMDDYYYY, convertDateStringFormatToMMDDYYObject } from '../../../../constants/DateFormatter';

class CreateCreditNote extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            coGroupList: [],
            coGroupData: [],
            coProductGroup: [],
            CoInvoice: [],
            addressList: [],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            isFromCO: this.props.location.state.isFromCO,
            customerOrder: this.props.location.state.isFromCO ? this.props.location.state.coId : "",

            customerId: "",
            invoice_id: "",
            invoiceType: "credit",
            invoiceDate: "",

            customerName: "",
            applicableTax: "",
            applicableTaxType: [],

            invoiceMessageHeaderEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageHeader: "",
            invoiceMessageFooterEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageFooter: "",

            currencyData: [],
            sezCustomer: false,
            applicableTaxTypeWithAllData: [],

        }
        this.setUpdatedGroupData = this.setUpdatedGroupData.bind(this)
    }

    componentDidMount(){
       this.getAllRemainingInvoice()
    }

    getAllRemainingInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetRemainingCOInvoice()
        .then(response => {
            this.setState({ 
                isLoading: false,
                CoInvoice: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getSingleInvoice = (CO_InvoiceId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOSingleInvoice(CO_InvoiceId)
        .then(response => {

            let orderGroupData = []
            let productGroup =  response.data.productGroup

            console.log(productGroup, "Kiran")

            for(let i = 0; i<productGroup.length;i++){
                productGroup[i].conversionDetail = []
                orderGroupData.push(productGroup[i])
            }

            this.setState({ 
                isLoading: false,
                invoiceMessageHeader: response.data.invoiceMessageHeader ? response.data.invoiceMessageHeader : "",
                invoiceMessageFooter: response.data.invoiceMessageFooter ? response.data.invoiceMessageFooter : "",
                invoiceMessageHeaderEditor: response.data.invoiceMessageHeader ? RichTextEditor.createValueFromString(response.data.invoiceMessageHeader, 'html') : RichTextEditor.createEmptyValue(),
                invoiceMessageFooterEditor: response.data.invoiceMessageFooter ? RichTextEditor.createValueFromString(response.data.invoiceMessageFooter, 'html') : RichTextEditor.createEmptyValue(),
                coGroupData: orderGroupData,
                invoiceDate: response.data.invoiceDate,
                customerOrder: response.data.customerOrderId
            },()=>{
                this.getOrderDetails(response.data.customerOrderId)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getOrderDetails = (customerOrder) => {
        if(customerOrder){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerOrderDetail(customerOrder)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    customerId: response.customer,
                    coProductGroup: response.productGroup
                },()=>{
                    this.getCustomerDetails(response.customer)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            })
        }
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {
            let dt = new Date()
            if(response.companyData.paymentTerms){
                if(response.companyData.paymentTerms.paymentStatus === "from_invoice"){
                    let days = response.companyData.paymentTerms.daysFrom !== "" ? response.companyData.paymentTerms.daysFrom : 0
                    let newDate = new Date().setDate(new Date().getDate() + parseInt(days))
                    dt = new Date(newDate)
                }
            }
            let taxType = []
            let taxResponseData = response.companyData.taxApplicable
            let sezCustomer = false
            let invoiceType = ""
            if(!taxResponseData.includes("notax")){
                if(taxResponseData.includes("exemptedtax")){
                    sezCustomer = true
                    invoiceType = "sez"
                    taxType = ["IGST"]
                }else{
                    taxType = response.companyData.taxApplicable
                    invoiceType = "tax"
                }
            }else{
                invoiceType = "export"
            }

            this.setState({
                isLoading: false,
                customerName: response.companyData.companyId.toUpperCase()+' '+response.companyData.companyName,
                currencyData: response.companyData.currencyDetails,
                applicableTax: "",
                applicableTaxType: taxType,
                applicableTaxTypeWithAllData: taxResponseData,
                sezCustomer: sezCustomer,
            },()=>{
                this.setGroupData()
                this.getCustomerAddress(id)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

 

    setGroupData = () => {
        let data = this.state.coGroupData

        let group = []
        for(let i=0;i<data.length;i++){
            group.push(
                <ProductGroup 
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    applicableTaxTypeWithAllData={this.state.applicableTaxTypeWithAllData}
                    currencyData={this.state.currencyData}
                    key={"groupData"+i}
                    data={data[i]}
                    freeText={this.state.coProductGroup[i].productFreeText}
                    itemCode={this.state.coProductGroup[i].itemCode}
                    uom={data[i].uom}
                    customerId={this.state.customerId}
                    callBack={this.setUpdatedGroupData}
                />
            )
        }
        this.setState({
            coGroupList: group,
            coGroupData: data
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let coGroupData = [...this.state.coGroupData]  
        coGroupData[counter] = value;  
        this.setState({
            coGroupData
        },()=>{
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.coGroupData

        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for(let i=0;i<data.length;i++){
            qty += data[i].deliveredQuantity ? parseFloat(data[i].deliveredQuantity) : 0
            subTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            if(this.state.invoiceType === "sez"){
                grandTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            }else{
                grandTotal += data[i].total ? parseFloat(data[i].total) : 0
            }
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        })
    }

    getCustomerAddress = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressList: response,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        }
    }

    onHeaderMessageChange = (value) => {
        this.setState({
            invoiceMessageHeaderEditor: value,
            invoiceMessageHeader: value.toString('html')
        });
    }

    onFooterMessageChange = (value) => {
        this.setState({
            invoiceMessageFooterEditor: value,
            invoiceMessageFooter: value.toString('html')
        });
    }

    CreateCreditNote = () => {
        let {
            invoice_id,
            coGroupData,
            subTotal,
            grandTotal,
            invoiceMessageHeader,
            invoiceMessageFooter,
        } = this.state

        if(parseFloat(grandTotal) === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please update credit note item details"
            },()=>{
                this.scrollToTop()
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCreditNote(invoice_id, JSON.stringify(coGroupData), subTotal, grandTotal, invoiceMessageHeader, invoiceMessageFooter)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editcreditnote",
                        state: { creditNoteId: response.data._id, isNewEntry: true }
                    }) 
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }



    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCreditNote={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Create Credit Invoice</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.CreateCreditNote()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice*</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.CoInvoice.map((data) => (
                                                    { id: data._id, text: data.number}
                                                ))
                                            }
                                            value={this.state.invoice_id}
                                            options={{placeholder: 'Select CO'}}
                                            onSelect={(e)=> this.setState({ invoice_id: e.target.value },()=> { this.getSingleInvoice(e.target.value) })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                               
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice Type</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceType} Invoice</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                               
                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceDate}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                
                               
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="add_outer_full_part">
                            <div className="title">Invoice Header</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageHeaderEditor}
                                    onChange={this.onHeaderMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                       

                        <div className="main-container mt-5 invoice_product_group">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Delivered Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                                <th className='pg-tab-head'>Subtotal</th>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <th className='pg-tab-head' key={'appTax'+i}>
                                                    {data}
                                                    {
                                                        this.state.applicableTaxTypeWithAllData.includes("exemptedtax") &&
                                                        <small>(Exempted)</small>
                                                    }
                                                </th>
                                            ))
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Credit Qty</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    
                                    {this.state.coGroupList}

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{this.state.totalQty}</b></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            <td><b><Bi.BiRupee /> {this.state.subTotal} </b></td>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <td key={'appTaxBlankField'+i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyData.conversionRate ?
                                                <td><b>{this.state.currencyData.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                            :
                                                <td><b><Bi.BiRupee /> {this.state.grandTotal} </b></td>

                                        }
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                     

                        <div className="add_outer_full_part mt-1">
                            <div className="title">Invoice Footer</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageFooterEditor}
                                    onChange={this.onFooterMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                      

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.CreateCreditNote()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default CreateCreditNote;