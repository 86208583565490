import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import Strings from './../../../constants/Strings'

export default class DataMaintenance extends React.Component{
    goBack = () => {
        this.props.history.goBack();
    }
    render(){
        return(
            <div className="page absoluteWhite">
                 <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isDataMaintenance={true} />
               

                <div className="main-container">

<div className="page-header">
    <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="page-title">{Strings.Settings.DataMaintenance}</div>
        </div>
    </div>
</div>

                                 

                    <div className="tableContent">
                        <div className="demoTitle mt-0">Back-up</div>
                        <div className="otherDemo">This function allows to download all data to have a copy of it on your computer. It is advised to do it at least once a week.</div>
                        <div className="otherDemo">Stop all users' activity before backing up.</div>
                        <div className="demoHighlight">Create a back-up</div>


                        <div className="demoTitle">Restore</div>
                        <div className="otherDemo">Upload a backup file to restore the database.</div>
                        <div className="otherDemo">Please note that the current database will be erased and replaced with the data from the file. Also, all users will be signed out.</div>
                        <div className="otherDemo">It is advised to download a new backup file before upload of older backup.</div>
                        <div className="demoHighlight">Upload a back-up</div>

                        <div className="demoTitle">Empty the database</div>
                        <div className="otherDemo">After you have tried the software with the demo data, you can delete it.</div>
                        <div className="otherDemo">Please note that all data will be deleted (except users and settings).</div>
                        <div className="demoHighlight">Empty the database</div>

                        <div className="demoTitle">Delete orders and stock only</div>
                        <div className="otherDemo">Delete all transactions: manufacturing, purchase, and customer orders, invoices, shipments, stock levels (quantities), etc.</div>
                        <div className="otherDemo">Only the base setup will remain: items, BOMs, routings, purchase terms, vendors, customers, workstations, settings, chart of accounts, etc.</div>
                        <div className="demoHighlight">Delete all orders and stock</div>

                    </div>
                </div>
                </div>
        )
    }
}