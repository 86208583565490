import React, { Component } from 'react';
import * as Bi from 'react-icons/bi'
import Tickets from './Tickets'
import Strings from './../../constants/Strings'
import DatePicker from "react-datepicker";
import "./../../lib/react-datepicker.min.css";
import * as Ai from 'react-icons/ai'
import * as APIManager from './../../APIManager/APIManager'
import { convertDateToMMDDYYYY } from '../../constants/DateFormatter';


class DragDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: null,
            item: 20,
            height: window.innerHeight,
            width: window.innerWidth,
            history: this.props.logout ? this.props.logout : null,
            dashboardData: [],
            pages: [
                {
                    slug: "Dashboard_totalInquiries",
                    name: "Inquiries",
                    key: "1",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_totalQuotation",
                    name: "Quotations",
                    key: "2",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_totalTurnover",
                    name: "Expected Turnover",
                    key: "32",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                },
                {
                    slug: "Dashboard_confirmedOrder",
                    name: "Confirmed CO",
                    key: "3",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_inproduction",
                    name: "In Production",
                    key: "4",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_deliveredOrder",
                    name: "Delivered CO",
                    key: "5",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_waitingForConfirm",
                    name: "Waiting for confirm",
                    key: "6",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_waitingForProduction",
                    name: "Waiting for production",
                    key: "7",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_COReadyToShip",
                    name: "CO ready to ship",
                    key: "8",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_partiallyInvoiced",
                    name: "Partially Invoiced",
                    key: "9",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_invoiced",
                    name: "Invoiced",
                    key: "10",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_partiallyShipped",
                    name: "Partially Shipped",
                    key: "11",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_shipped",
                    name: "Shipped",
                    key: "12",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_archivedOrder",
                    name: "Archived CO",
                    key: "13",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_cancelledCO",
                    name: "Cancelled CO",
                    key: "14",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_lateCo",
                    name: "Late CO",
                    key: "15",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_lateMo",
                    name: "Late MO",
                    key: "16",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_latePo",
                    name: "Late PO",
                    key: "17",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_PendingItem",
                    name: "Pending Item",
                    key: "18",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_MissingItem",
                    name: "Missing Items",
                    key: "19",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_ItemBelowReorderPoint",
                    name: "Item below reorder point",
                    key: "20",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_7DaysLateInvoices",
                    name: "7 Days Late Invoices",
                    key: "21",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_MOInProgress",
                    name: "MO in progress",
                    key: "22",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_MOReadyToStart",
                    name: "MO ready to start",
                    key: "23",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_OEE",
                    name: "OEE",
                    key: "24",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_TEEP",
                    name: "TEEP",
                    key: "25",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_Sales",
                    name: "Sales",
                    key: "26",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_Stock",
                    name: "Stock",
                    key: "27",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_CashFlow",
                    name: "Cash flow",
                    key: "28",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_PurchaseOnTime",
                    name: "Purchase on time",
                    key: "29",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: false
                },
                {
                    slug: "Dashboard_ManufacturingOnTime",
                    name: "Manufacturing on time",
                    key: "30",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                },
                {
                    slug: "Dashboard_DeliveriesOnTime",
                    name: "Deliveries on time",
                    key: "31",
                    value: "",
                    value2: "",
                    stripe: "light",
                    countPer: "",
                    hide: true
                }
            ],
            tabs: [],
            minFilterDate: "",
            maxFilterDate: "",
            timeOutFunc: null,
        
        };      
    }

    componentDidMount() {
        this.checkPermissions_onload()
        this.getUserDetails()
    }

    getUserDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProfile()
        .then(response => {
            this.setState({
                isLoading: false,
                minFilterDate: response.data.filterStart ? new Date(response.data.filterStart) : '',
                maxFilterDate: response.data.filterFinish ? new Date(response.data.filterFinish) : ''
            }, () => {
                this.getDashboardData()
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    getDashboardData = () => {
        let {
            minFilterDate,
            maxFilterDate
        } = this.state

        let maxDate = maxFilterDate
        if (minFilterDate !== "" && maxFilterDate === "") {
            maxDate = new Date()
        }

        this.setState({ isLoading: true, error: false })
        APIManager.callDashboardData(convertDateToMMDDYYYY(minFilterDate), convertDateToMMDDYYYY(maxDate))
            .then(response => {
            
                let updatedPages = [];
                if (response.data.dashboardSequence && response.data.dashboardSequence.length > 0) {
                    updatedPages = response.data.dashboardSequence;
                } else {

                    for (let i = 0; i < this.state.pages.length; i++) {

                        if (this.state.pages[i].slug === "Dashboard_totalInquiries") {
                            updatedPages.push({
                                slug: "Dashboard_totalInquiries",
                                name: "Inquiries",
                                key: "1",
                                value: response.data.totalInquiries,
                                value2: response.data.totalInquiriesCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalInquiries === 0 ? "0%" : "100%",
                                hide: false
                            })
                        }

                        if (this.state.pages[i].slug === "Dashboard_totalQuotation") {
                            updatedPages.push({
                                slug: "Dashboard_totalQuotation",
                                name: "Quotations",
                                key: "2",
                                value: response.data.totalQuotation,
                                value2: response.data.totalQuotationCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalQuotationPercentage ? response.data.totalQuotationPercentage + '%' : '0%',
                                hide: false
                            })
                        }

                        if (this.state.pages[i].slug === "Dashboard_totalTurnover") {
                            updatedPages.push({
                                slug: "Dashboard_totalTurnover",
                                name: "Expected Turnover",
                                key: "32",
                                value: response.data.totalTurnoverNo,
                                value2: response.data.totalTurnoverCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.turnOverPercentage ? response.data.turnOverPercentage + '%' : '0%',
                                hide: false
                            })
                        }


                        if (this.state.pages[i].slug === "Dashboard_confirmedOrder") {
                            updatedPages.push({
                                slug: "Dashboard_confirmedOrder",
                                name: "Confirmed CO",
                                key: "3",
                                value: response.data.confirmedCo,
                                value2: response.data.totalConfirmedCoCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalConfirmedCoPercentage ? response.data.totalConfirmedCoPercentage + '%' : '0%',
                                hide: false
                            })
                        }

                        if (this.state.pages[i].slug === "Dashboard_inproduction") {
                            updatedPages.push(
                                {
                                    slug: "Dashboard_inproduction",
                                    name: "In Production",
                                    key: "4",
                                    value: response.data.coInproduction,
                                    value2: response.data.totalCoInProductionCost.toFixed(2),
                                    stripe: "light",
                                    countPer: response.data.totalCoInProductionPercentage ? response.data.totalCoInProductionPercentage + '%' : '0%',
                                    hide: false
                                })
                        }
                        if (this.state.pages[i].slug === "Dashboard_deliveredOrder") {
                            updatedPages.push({
                                slug: "Dashboard_deliveredOrder",
                                name: "Delivered CO",
                                key: "5",
                                value: response.data.coDelivered,
                                value2: response.data.totalCoDeliveredCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalCoDeliveredPercentage ? response.data.totalCoDeliveredPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_waitingForConfirm") {
                            updatedPages.push({
                                slug: "Dashboard_waitingForConfirm",
                                name: "Waiting for confirm",
                                key: "6",
                                value: response.data.waitingForConfirmation,
                                value2: response.data.totalWaitingForConfirmationCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalWaitingForCOnfirmationPercentage ? response.data.totalWaitingForCOnfirmationPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_waitingForProduction") {
                            updatedPages.push({
                                slug: "Dashboard_waitingForProduction",
                                name: "Waiting for production",
                                key: "7",
                                value: response.data.waitingForProduction,
                                value2: response.data.waitingForProductionCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.waitingForProductionPercentage ? response.data.waitingForProductionPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_COReadyToShip") {
                            updatedPages.push({
                                slug: "Dashboard_COReadyToShip",
                                name: "CO ready to ship",
                                key: "8",
                                value: response.data.coreadytoship,
                                value2: response.data.totalReadyForShipmentCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalReadyForShipmentPercentage ? response.data.totalReadyForShipmentPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_partiallyInvoiced") {
                            updatedPages.push({
                                slug: "Dashboard_partiallyInvoiced",
                                name: "Partially Invoiced",
                                key: "9",
                                value: response.data.partiallyInvoiced,
                                value2: response.data.totalpartiallyInvoicedCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalpartiallyInvoicePercentage ? response.data.totalpartiallyInvoicePercentage + '%' : '0%',
                                hide: false
                            })
                        }


                        if (this.state.pages[i].slug === "Dashboard_invoiced") {
                            updatedPages.push({
                                slug: "Dashboard_invoiced",
                                name: "Invoiced",
                                key: "10",
                                value: response.data.invoiced,
                                value2: response.data.totalInvoicedCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalInvoicePercentage ? response.data.totalInvoicePercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_partiallyShipped") {
                            updatedPages.push({
                                slug: "Dashboard_partiallyShipped",
                                name: "Partially Shipped",
                                key: "11",
                                value: response.data.partiallyShipped,
                                value2: response.data.totalPartiallyShippedCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalpartiallyShippedPercentage ? response.data.totalpartiallyShippedPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_shipped") {
                            updatedPages.push({
                                slug: "Dashboard_shipped",
                                name: "Shipped",
                                key: "12",
                                value: response.data.shipped,
                                value2: response.data.totalShippedCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalshippedPercentage ? response.data.totalshippedPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_archivedOrder") {
                            updatedPages.push({
                                slug: "Dashboard_archivedOrder",
                                name: "Archived CO",
                                key: "13",
                                value: response.data.coArchived,
                                value2: response.data.totalCoArchivedCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalCoArchivedPercentage ? response.data.totalCoArchivedPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_cancelledCO") {
                            updatedPages.push({
                                slug: "Dashboard_cancelledCO",
                                name: "Cancelled CO",
                                key: "14",
                                value: response.data.cancelled,
                                value2: response.data.totalcancelledCost.toFixed(2),
                                stripe: "light",
                                countPer: response.data.totalcancelledPercentage ? response.data.totalcancelledPercentage + '%' : '0%',
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_lateCo") {
                            updatedPages.push({
                                slug: "Dashboard_lateCo",
                                name: "Late CO",
                                key: "15",
                                value: response.data.lateCO,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }

                        if (this.state.pages[i].slug === "Dashboard_lateMo") {
                            updatedPages.push({
                                slug: "Dashboard_lateMo",
                                name: "Late MO",
                                key: "16",
                                value: response.data.lateMo,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_latePo") {
                            updatedPages.push({
                                slug: "Dashboard_latePo",
                                name: "Late PO",
                                key: "17",
                                value: response.data.latePO,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_PendingItem") {
                            updatedPages.push({
                                slug: "Dashboard_PendingItem",
                                name: "Pending Item",
                                key: "18",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_MissingItem") {
                            updatedPages.push({
                                slug: "Dashboard_MissingItem",
                                name: "Missing Items",
                                key: "19",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_ItemBelowReorderPoint") {
                            updatedPages.push({
                                slug: "Dashboard_ItemBelowReorderPoint",
                                name: "Item below reorder point",
                                key: "20",
                                value: response.data.itemBelowReorder,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_7DaysLateInvoices") {
                            updatedPages.push({
                                slug: "Dashboard_7DaysLateInvoices",
                                name: "7 Days Late Invoices",
                                key: "21",
                                value: response.data.lateInvoice,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_MOInProgress") {
                            updatedPages.push({
                                slug: "Dashboard_MOInProgress",
                                name: "MO in progress",
                                key: "22",
                                value: response.data.moInProgress,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_MOReadyToStart") {
                            updatedPages.push({
                                slug: "Dashboard_MOReadyToStart",
                                name: "MO ready to start",
                                key: "23",
                                value: response.data.moReadyToStart,
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_OEE") {
                            updatedPages.push({
                                slug: "Dashboard_OEE",
                                name: "OEE",
                                key: "24",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_TEEP") {
                            updatedPages.push({
                                slug: "Dashboard_TEEP",
                                name: "TEEP",
                                key: "25",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_Sales") {
                            updatedPages.push({
                                slug: "Dashboard_Sales",
                                name: "Sales",
                                key: "26",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_Stock") {
                            updatedPages.push({
                                slug: "Dashboard_Stock",
                                name: "Stock",
                                key: "27",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }

                        if (this.state.pages[i].slug === "Dashboard_CashFlow") {
                            updatedPages.push({
                                slug: "Dashboard_CashFlow",
                                name: "Cash flow",
                                key: "28",
                                value: "",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_PurchaseOnTime") {
                            updatedPages.push({
                                slug: "Dashboard_PurchaseOnTime",
                                name: "Purchase on time",
                                key: "29",
                                value: response.data.purchaseOnTime ? response.data.purchaseOnTime + "%" : "0",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: false
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_ManufacturingOnTime") {
                            updatedPages.push({
                                slug: "Dashboard_ManufacturingOnTime",
                                name: "Manufacturing on time",
                                key: "30",
                                value: response.data.manufacturingOnTime ? response.data.manufacturingOnTime + "%" : "0",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                        if (this.state.pages[i].slug === "Dashboard_DeliveriesOnTime") {
                            updatedPages.push({
                                slug: "Dashboard_DeliveriesOnTime",
                                name: "Deliveries on time",
                                key: "31",
                                value: response.data.deliveryOnTime ? response.data.deliveryOnTime + "%" : "0",
                                value2: "",
                                stripe: "light",
                                countPer: "",
                                hide: true
                            })
                        }
                    }
                }

                let minDate = ""
                let maxDate = ""

                if(this.state.minFilterDate){
                    minDate = this.state.minFilterDate
                }else if(response.data.startDate){
                    minDate = response.data.startDate
                }else{
                    minDate = ""
                }

                if(this.state.maxFilterDate){
                    maxDate = this.state.maxFilterDate
                }else if(response.data.finishDate){
                    maxDate = response.data.finishDate
                }else{
                    maxDate = ""
                }

                this.setState({
                    isLoading: false,
                    pages: updatedPages,
                    dashboardData: response.data,
                    minFilterDate: minDate ? new Date(minDate) : "",
                    maxFilterDate: maxDate ? new Date(maxDate) : "",
                }, () => {
                    this.checkPermissions()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                },()=>{
                    if(errors.status === "userDataError"){
                        this.logout()
                    }
                })
            });
    }

    logout = () => {
        if(this.state.history !== null){
            localStorage.clear();
            this.state.history.replace('/login')
        }
    }

 
    componentWillMount() {
        this.setState({ height: window.innerHeight });
    }

    updatePagesCall = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDashboardUpdateData(JSON.stringify(this.state.pages))
            .then(response => {
                console.log(response)
                this.setState({
                    isLoading: false,
                    error: false,
                    tabs: response.data
                },()=>{
                    this.makePages()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

   
    checkPermissions_onload = () => {
        let localPermissions = localStorage.getItem("permissions")
        if (localPermissions !== null) {
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if (permissions !== undefined && permissions.length > 0) {

                let updatedRecord = this.state.pages.map(obj => {
                    if (!obj.hide) {
                        let permit = permissions.find(lo => lo.slug === obj.slug)
                        if (permit !== undefined) return obj
                    }
                });
                this.setState({
                    tabs: updatedRecord
                }, () => {
                    this.makePages_onload()
                })
            } else {
                this.setState({
                    tabs: this.state.pages
                }, () => {
                    this.makePages_onload()
                })
            }
        } else {
            this.makePages_onload()
        }
    }

    makePages_onload = () => {
        let boxes = this.state.tabs.map((data) => {
            if (data && data.hide !== true) {
                return <Tickets
                    box={data}
                    totalPer={data.countPer}
                    key={data.key}
                    id={data.key}
                    header={data.name}
                    value={data.value}
                    value2={data.value2}
                    stripe={data.stripe}
                    draggable="true"
                    onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver}
                    onDrop={this.handleDrop}
                />
            }
        })

        this.setState({
            layout: boxes
        })
    };

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if (localPermissions !== null) {
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if (permissions !== undefined && permissions.length > 0) {

                let updatedRecord = this.state.pages.map(obj => {
                    if (!obj.hide) {
                        let permit = permissions.find(lo => lo.slug === obj.slug)
                        if (permit !== undefined) return obj
                    }
                });
                this.setState({
                    tabs: updatedRecord
                }, () => {
                    this.makePages()
                })
            } else {
                this.setState({
                    tabs: this.state.pages
                }, () => {
                    this.makePages()
                })
            }
        } else {
            this.makePages()
        }
    }

    updateDashboard = () => {
        clearTimeout(this.state.timeOutFunc);
        this.setState({
            timeOutFunc: setTimeout(
                function () {
                    this.getDashboardData()
                }.bind(this), 5000
            )
        })
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeOutFunc);
    }


    swapPages = (fromBox, toBox) => {
        let pages = this.state.pages.slice();

        let fromIndex = -1;
        let toIndex = -1;

        for (let i = 0; i < pages.length; i++) {
            if (pages[i].key === fromBox.key) {
                fromIndex = i;
            }
            if (pages[i].key === toBox.key) {
                toIndex = i;
            }
        }

        if (fromIndex != -1 && toIndex != -1) {
            let { fromId, ...fromRest } = pages[fromIndex];
            let { toId, ...toRest } = pages[toIndex];
            pages[fromIndex] = { key: fromBox.key, ...toRest };
            pages[toIndex] = { key: toBox.key, ...fromRest };

            this.setState({ pages: pages }, () => {
                this.updatePagesCall()
            });
        }
    };


    handleDragStart = data => event => {

        let fromBox = JSON.stringify({ key: data.key });
        event.dataTransfer.setData("dragContent", fromBox);
    };


    handleDragOver = data => event => {
        event.preventDefault();
        return false;
    };


    handleDrop = data => event => {

        event.preventDefault();

        let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
        let toBox = { key: data.key };

        this.swapPages(fromBox, toBox);
        return false;
    };

    makePages = () => {
        let boxes = this.state.tabs.map((data) => {
            if (data && data.hide !== true) {
                return <Tickets
                    box={data}
                    totalPer={data.countPer}
                    key={data.key}
                    id={data.key}
                    header={data.name}
                    value={data.value}
                    value2={data.value2}
                    stripe={data.stripe}
                    draggable="true"
                    onDragStart={this.handleDragStart}
                    onDragOver={this.handleDragOver}
                    onDrop={this.handleDrop}
                />
            }
        })

        this.setState({
            layout: boxes
        },()=>{
            this.updateDashboard()
        })
    };

    render() {
        return <div 
        ref={this.myRef}
        onScroll={this.onScroll}

        className="home-main-container">
            <div className='row'>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="float-left page-title mb-0 ml-2">{Strings.Dashboard.title}</div>
                    <div className="float-right">
                        <div className="dashboard-date-filter position-relative">
                            <div className='dashboard-date-filter-title'>Start Date</div>
                            <div className='position-relative'>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.minFilterDate}
                                    maxDate={new Date()}
                                    onChange={
                                        date => this.setState({ minFilterDate: date }, () => {
                                            let maxFilterD = this.state.maxFilterDate
                                            if (maxFilterD !== "" && date > maxFilterD) {
                                                this.setState({ maxFilterDate: new Date() })
                                            }
                                            this.getDashboardData()
                                        })
                                    }
                                    className="filterdateoption"
                                />
                                <Ai.AiOutlineCalendar className="datepickerIcon" />
                            </div>
                        </div>

                        <div className="dashboard-date-filter">
                            <div className='dashboard-date-filter-title'>End Date</div>
                            <div className='position-relative'>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    minDate={this.state.minFilterDate}
                                    selected={this.state.maxFilterDate}
                                    onChange={
                                        date => this.setState({ maxFilterDate: date }, () => {
                                            this.getDashboardData()
                                        })
                                    }
                                    className="filterdateoption"
                                />
                                <Ai.AiOutlineCalendar className="datepickerIcon" />
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="clearfix"></div>


            <div className="pagesGroup">{this.state.layout}</div>
            <div className="clearfix"></div>

        </div>
    }
}

export default DragDashboard;
