import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as Ai from 'react-icons/ai'
import FlatList from 'flatlist-react';
import Loading from '../../../helper/loading';

import * as APIManager from './../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'

import { Link } from 'react-router-dom';

import * as LS from './../../../constants/SetLocalstorage'
import WorkingHours from './WorkingHours';
import Holidays from './Holidays';
import TaxDetails from './TaxDetails';
import CustomerStatus from './CustomerStatus';
import ItemPriceCalc from './ItemPriceCalc';
import PDFFormat from './PDFFormat'

const settings = [
    { text: "Company details", id: "Company_details" },
    { text: "Working hours", id: "Working_hours" },
    { text: "Holidays", id: "Holidays" },
    { text: "Additional currencies", id: "Additional_currencies" },
    { text: "Customer status", id: "Customer_status" },
    { text: "Company industry", id: "company_industry" },
    { text: "Write-off type", id: "write_off_type" },
    { text: "Order status", id: "Order_status" },
    { text: "Delivery terms", id: "Delivery_terms" },
    { text: "Quotation Messages", id: "Quotation_messages" },
    { text: "Item price calculations", id: "Item_price_calculations" },
    // { text: "PDF Letter head", id: "PDF_Letter_Head" },
    { text: "Setup E-Invoice", id: "Setup_e_invoice" }
]

export default class SystemSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "",
            companyStatusData: [],
            companyIndustryData: [],
            writeOffData: [],
            companyOrderStatus: [],
            currencyData: [],
            deliveryTermsList: [],

            errorCompanyDetails: "",
            companyName: "",
            contactDetails: "",
            email: "",
            website: "",
            phone: "",
            panNo: "",
            gstNo: "",

            quotationHeader: "",
            quotationFooter: "",

            completionDetails: [],
            showCompeletionWarning: false,
            
            start_einvoce: false,
            start_ewaybill: false,
            company_Name: "",
            gst_In: "",
            user_Name: "",
            user_Password: "",
            fieldNotEditable: true,


            success: false,
            error: false,
            successmessage: "",
            errormessage: "",



        };
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount() {
        const getSelectedIndex = localStorage.getItem('system_setting_selected_index')
        if (getSelectedIndex === "" || getSelectedIndex === null) {
            this.setState({
                selectedIndex: 'Company_details',
            })
        } else {
            this.setState({
                selectedIndex: getSelectedIndex,
            })
        }

        this.getCompanyIndustry()
        this.getOrderStatus()
        this.getCompanyDetail()
        this.getWriteOffType()
        this.getAllCurrency()
        this.getQuotationMessages()
        this.checkIncompleteFields()
        this.eInvoiceDetails()
        this.getDeliveryTerms()
    }

    checkIncompleteFields = () => {
        let completionLocalData = localStorage.getItem("completionDetails")
        if (completionLocalData) {
            if (completionLocalData !== 'undefined' && completionLocalData !== undefined) {
                let data = JSON.parse(completionLocalData)
                if (data !== 'undefined' && data !== undefined) {
                    if (data.inCompleteFields.length > 0) {
                        this.setState({
                            completionDetails: data,
                            showCompeletionWarning: true
                        })
                    } else {
                        this.setState({
                            completionDetails: data,
                            showCompeletionWarning: false
                        })
                    }
                }
            }
        }
    }

    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
            .then(response => {
                let companyIndustryData = response.companyIndustry ? response.companyIndustry : []
                this.setState({
                    isLoading: false,
                    companyIndustryData: companyIndustryData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getDeliveryTerms = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDeliveryTermsList()
        .then(response => {
            if(response.deliveryTerms){
                this.setState({
                    isLoading: false,
                    deliveryTermsList: response.deliveryTerms
                })
            }else{
                this.setState({
                    isLoading: false,
                    deliveryTermsList: []
                })
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }


    getOrderStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callOrderStatus()
            .then(response => {
                let orderStatus = response.status !== undefined ? response.status : null
                if (orderStatus !== null) {
                    this.setState({
                        isLoading: false,
                        companyOrderStatus: orderStatus
                    })
                }
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getQuotationMessages = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetDefaultQuotationMessages()
            .then(response => {
                this.setState({
                    isLoading: false,
                    quotationHeader: response.headerText,
                    quotationFooter: response.footerText
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    getAllCurrency = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCurrency()
            .then(response => {
                this.setState({
                    currencyData: response
                })

            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getWriteOffType = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetWriteOffType()
            .then(response => {
                this.setState({
                    isLoading: false,
                    writeOffData: response.type !== undefined ? response.type : []
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getCompanyDetail = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCompanyDetails()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyName: response.data.companyName,
                    contactDetails: response.data.contactName,
                    email: response.data.email,
                    website: response.data.website,
                    phone: response.data.phone,
                    panNo: response.data.panNo,
                    gstNo: response.data.gstin,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors,
                    setProductGroup: true
                })
            });
    }

    editCompanyDetails = () => {
        let userData = LS.getLS('userdata')
        if (userData !== undefined) {
            let ud = JSON.parse(userData)
            if (ud.role === 'admin') {
                this.props.history.push('editcompanydetails')
            } else {
                this.setState({
                    errorCompanyDetails: "You are not authorized person to update company details"
                })
            }
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    renderSettings = (settings, index) => {
        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts'} onClick={() => {
                this.setState({
                    selectedIndex: index,
                }, () => {
                    localStorage.setItem('system_setting_selected_index', index);
                });
            }}
            >
                {settings.text}
            </div>
        );
    }

    editCurrency = (id) => {
        this.props.history.push({
            pathname: '/editcurrency',
            state: { currencyId: id, newCurrencyEntry: false }
        })
    }

    saveEinvoice = () => {
        if(!this.state.fieldNotEditable){
            let { company_Name, gst_In, user_Name, user_Password, start_einvoce, start_ewaybill } = this.state
            if(start_einvoce && (company_Name.length === 0 || gst_In.length === 0 || user_Name.length === 0 || user_Password.length === 0)){
                this.setState({
                    error: true,
                    isLoading: false,
                    errormessage: "Please fill out all required fields."
                },()=>{
                    this.errMessage()
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.startEInvoice(company_Name, gst_In, user_Name, user_Password, start_einvoce, start_ewaybill)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "E-Invoice updated successfully"
                    },() => {
                        this.errMessage()
                    })
                } )
                .catch(errors => {
                    this.setState({
                        error: true,
                        isLoading: false,
                        errormessage: errors
                    }, ()=> {
                        this.errMessage()
                    })
                });
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
       
    }

    eInvoiceDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.getEInvoice()
        .then(response => {

            console.log("Response of E-Invoice ", response)
            this.setState({
                isLoading: false,
                start_einvoce: response.data.is_eInvoice,
                start_ewaybill: response.data.is_eWayBill,
                company_Name: response.data.companyName,
                gst_In: response.data.gstNumber,
                user_Name: response.data.client_userName,
                user_Password: response.data.client_password ? response.data.client_password : '',
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                errormessage: errors
            })
        });
    }

    errMessage = () => {
        setTimeout(() => {
            this.setState({
                error : false,
                success :false
            })
        }, 3000);
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />

                {
                    this.state.showCompeletionWarning &&
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <div>Please complete required settings of <strong>{this.state.completionDetails.inCompleteFields ? this.state.completionDetails.inCompleteFields.toString() : ''}</strong></div>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => {
                            this.setState({
                                showCompeletionWarning: false
                            }, () => {
                                localStorage.removeItem("completionDetails")
                            })
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                }

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{Strings.Settings.SystemSettings}</div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent">
                        <div className="datamenus">

                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>

                        {
                            this.state.selectedIndex === "Regional_settings" ?
                                <div className="dataDetails">
                                    <div className="detailsHead">Timezone *</div> <div className="detailsValue">America/New_York</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Date format *</div> <div className="detailsValue">dd/MM/yyyy</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">First day of week *</div> <div className="detailsValue">Sunday</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Decimal seperator *</div> <div className="detailsValue">.(dot)</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Thousands seperator *</div> <div className="detailsValue">,(comma)</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">CSV seperator *</div> <div className="detailsValue">,(comma)</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Currency sign *</div> <div className="detailsValue">$</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Currency format *</div> <div className="detailsValue">[Sign][Price]</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Generic name of an undefined place in the stock *</div> <div className="detailsValue">General</div>
                                    <div className="clear"></div>
                                    <Link to="/editregionalsettings"><div className="setEditButton">Edit</div></Link>
                                </div>
                                :
                            this.state.selectedIndex === "Company_details" ?
                                <div className="dataDetails">
                                    <div className="__editCompanyDetailErrorMessage">{this.state.errorCompanyDetails}</div>
                                    <div className="detailsHead">Company Name :</div> <div className="detailsValue text-capitalize">{this.state.companyName}</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Contact details :</div> <div className="detailsValue text-capitalize">{this.state.contactDetails}</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">E-mail :</div> <div className="detailsValue">{this.state.email}</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Website :</div> <div className="detailsValue">{this.state.website}</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Phone :</div> <div className="detailsValue">{this.state.phone}</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">GSTIN :</div> <div className="detailsValue">{this.state.gstNo}</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">PAN Number :</div> <div className="detailsValue">{this.state.panNo}</div>
                                    <div className="clear"></div>
                                    <div onClick={() => this.editCompanyDetails()} className="setEditButton">Edit</div>
                                </div>
                                :
                            this.state.selectedIndex === "Tax_details" ?
                                <TaxDetails />
                                :
                            this.state.selectedIndex === "Numbering_formats" ?
                                <div className="dataDetails">
                                    <div className="detailsHead">Customer orders *</div>
                                    <div className="detailsValue">CO00005</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Customers *</div>
                                    <div className="detailsValue">CU00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Invoices *</div>
                                    <div className="detailsValue">I00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Pro-forma invoices *</div>
                                    <div className="detailsValue">PI00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Quotations *</div>
                                    <div className="detailsValue">Q00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Order confirmations *</div>
                                    <div className="detailsValue">OC00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Manufacturing orders *</div>
                                    <div className="detailsValue">MO00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Workstations *</div>
                                    <div className="detailsValue">C00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Workstation groups *</div>
                                    <div className="detailsValue">WCT00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">BOM *</div>
                                    <div className="detailsValue">BO00001</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Routings *</div>
                                    <div className="detailsValue">R00001</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Items *</div>
                                    <div className="detailsValue">A00004</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Lots *</div>
                                    <div className="detailsValue">L00005</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Shipments *</div>
                                    <div className="detailsValue">S00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Product group *</div>
                                    <div className="detailsValue">AG00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Bookings *</div>
                                    <div className="detailsValue">WO00008</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Purchase orders *</div>
                                    <div className="detailsValue">PO00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Bills (receipts) *</div>
                                    <div className="detailsValue">PB00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Vendors *</div>
                                    <div className="detailsValue">V00002</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Customer returns (RMAs) *</div>
                                    <div className="detailsValue">RMA00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Procurement forecasts *</div>
                                    <div className="detailsValue">PF00000</div>
                                    <div className="clear"></div>
                                    <div className="detailsHead">Service orders *</div>
                                    <div className="detailsValue">SO00000</div>
                                    <div className="clear"></div>
                                    <Link to="/editnumberingformats"><div className="setEditButton">Edit</div></Link>
                                </div>
                                :
                            this.state.selectedIndex === "Working_hours" ?
                                <WorkingHours checkIncompleteFields={this.checkIncompleteFields} />
                                :
                            this.state.selectedIndex === "Holidays" ?
                                <Holidays />
                                :
                            this.state.selectedIndex === "Additional_currencies" ?
                                <div className="stocksetting dataDetails">
                                    <div style={{ overflowX: "auto" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="tableHeader tableHeadButton" style={{ width: 40 }}><Link to="/createcurrency"><Fi.FiPlus /></Link></th>
                                                    <th className="tableHeader"><span>Currency</span></th>
                                                    <th className="tableHeader"><span>Conversion rate</span></th>
                                                    <th className="tableHeader"><span>Symbol</span></th>
                                                    <th className="tableHeader tableHeadButton" style={{ width: 20 }}><Link to="/createcurrency"><Fi.FiPlus /></Link></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.currencyData !== null &&
                                                    this.state.currencyData.map((data, i) => (
                                                        <tr key={data._id} onClick={() => this.editCurrency(data._id)}>
                                                            <td style={{ width: 40 }}>{i + 1}</td>
                                                            <td>{data.currencyName}</td>
                                                            <td>{data.conversionRate} INR</td>
                                                            <td>{data.currencySymbol}</td>
                                                            <td style={{ width: 40 }}></td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                            this.state.selectedIndex === "Customer_status" ?
                                <CustomerStatus history={this.props.history} />
                                :
                            this.state.selectedIndex === "company_industry" ?
                                <div className="dataDetails">
                                    {
                                        this.state.companyIndustryData !== null &&
                                        this.state.companyIndustryData.map((data, i) => (
                                            <div key={i + data.industrytype}>
                                                <div className="detailsHead">{data.industrytype}</div>
                                                <div className="clear"></div>
                                            </div>
                                        ))
                                    }

                                    <Link to="/editindustrytype"><div className="setEditButton">Edit</div></Link>
                                </div>
                                :
                            this.state.selectedIndex === "write_off_type" ?
                                <div className="dataDetails">
                                    {
                                        this.state.writeOffData.length > 0 &&
                                        this.state.writeOffData.map((data, i) => (
                                            <div key={i + data.type}>
                                                <div className="detailsHead">{data.type}</div>
                                                <div className="clear"></div>
                                            </div>
                                        ))
                                    }
                                    <Link to="/editwriteofftype"><div className="setEditButton">Edit</div></Link>
                                </div>
                                :
                            this.state.selectedIndex === "Delivery_terms" ?
                                <div className="dataDetails">
                                    {
                                        this.state.deliveryTermsList.map((data, i)=> (
                                            <div>
                                                <div className="detailsHead width10">{i+1}.</div> <div className="detailsValue">{data.Term}</div>
                                                <div className="clear"></div>
                                            </div>
                                        ))
                                    }
                                    <Link to="/editdeliveryterms"><div className="setEditButton">Edit</div></Link>
                                </div>
                                :
                            this.state.selectedIndex === "Order_status" ?
                                <div className="dataDetails">
                                    {
                                        this.state.companyOrderStatus !== null &&
                                        this.state.companyOrderStatus.map((data, i) => (
                                            <div key={i + data.status}>
                                                <div className="detailsHead">{data.status}</div>
                                                <div className="clear"></div>
                                            </div>
                                        ))
                                    }
                                    <Link to="/editorderstatus"><div className="setEditButton">Edit</div></Link>
                                </div>
                            :
                            this.state.selectedIndex === "Quotation_messages" ?
                                <div className="dataDetails">
                                    <div>
                                        <div className='font-weight-bold'>Header Message</div>
                                        <div className='mt-2 message-border'>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.quotationHeader }} />
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <div className='font-weight-bold'>Footer Message</div>
                                        <div className='mt-2 message-border'>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.quotationFooter }} />
                                        </div>
                                    </div>
                                    <Link to="/editquotmessages"><div className="setEditButton">Edit</div></Link>
                                </div>
                                :
                            this.state.selectedIndex === "Item_price_calculations" ?
                                <div>
                                    <h5 className='font-weight-bold mb-2'>Item Price calculations</h5>
                                    <ItemPriceCalc checkIncompleteFields={this.checkIncompleteFields} />
                                </div>
                                : 
                            this.state.selectedIndex === "PDF_Letter_Head" ?
                                <div>
                                    <h5 className='font-weight-bold mb-2'>PDF Header</h5>
                                    <PDFFormat />
                                </div>
                                :
                            this.state.selectedIndex === "Setup_e_invoice" ?
                                <div className="add_inner">
                                    <h5 className='font-weight-bold mb-2'>Setup E-Invoice</h5>
                                    <div className="value">
                                        <div className="pagecontent">
                                        {
                                            this.state.error &&
                                            <div>
                                                <div className="errornotification">
                                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.success &&
                                            <div>
                                                <div className="successnotification">
                                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                                </div>
                                            </div>
                                        }
                                        </div>
                                        <div className="position-relative  width40">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <span>Start E-Invoice</span>
                                                    <input 
                                                        type="checkbox" 
                                                        className="__formCheckboxElem mt-2 ml-2 h-auto"
                                                        checked={this.state.start_einvoce}
                                                        onChange={() =>
                                                        this.setState({
                                                            start_einvoce: !this.state.start_einvoce
                                                        })
                                                    } />
                                                </div>
                                                <div className="col-lg-6">
                                                    <span>Start E-Way bill</span>
                                                    <input 
                                                        type="checkbox" 
                                                        className="__formCheckboxElem mt-2 ml-2 h-auto"
                                                        checked={this.state.start_ewaybill}
                                                        disabled={!this.state.start_einvoce}
                                                        onChange={() =>
                                                        this.setState({
                                                            start_ewaybill: !this.state.start_ewaybill
                                                        })
                                                    } />
                                                </div>
                                            </div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Company Name</td>
                                                        <td style={{ width: 200 }}>
                                                            <div className="position-relative __working_hours_check_wsg">
                                                                <input
                                                                    type="text"
                                                                    value={this.state.company_Name}
                                                                    disabled={!this.state.start_einvoce}
                                                                    onChange={(e) => {
                                                                    this.setState({ 
                                                                        company_Name: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                
                                                    <tr style={{ background: "none" }}>
                                                        <td>GSTIN</td>
                                                        <td style={{ width: 200 }}>
                                                            <div className="position-relative __working_hours_check_wsg">
                                                                <input
                                                                    type="text"
                                                                    value={this.state.gst_In}
                                                                    disabled={!this.state.start_einvoce}
                                                                    onChange={(e) => {
                                                                    this.setState({ 
                                                                        gst_In: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            this.state.fieldNotEditable &&
                                            <div className="disableFieldCover"></div>
                                        }
                                    </div>
                                    <div className="value mt-4 mb-4">
                                        <h5 className='font-weight-bold mb-2'>GST Government Portal Credential</h5>
                                        <div className="position-relative  width40">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Username</td>
                                                        <td style={{ width: 200 }}>
                                                            <div className="position-relative __working_hours_check_wsg">
                                                                <input
                                                                    type="text"
                                                                    value={this.state.user_Name}
                                                                    disabled={!this.state.start_einvoce}
                                                                    onChange={(e) => {
                                                                        this.setState({ user_Name: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ background: "none" }}>
                                                        <td>Password</td>
                                                        <td style={{ width: 200 }}>
                                                            <div className="position-relative __working_hours_check_wsg">
                                                                <input
                                                                    type="password"
                                                                    value={this.state.user_Password}
                                                                    disabled={!this.state.start_einvoce}
                                                                    onChange={(e) => {
                                                                        this.setState({ user_Password: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {
                                                this.state.fieldNotEditable &&
                                                <div className="disableFieldCover"></div>
                                            }
                                        </div>
                                        <div className="mt-2">
                                            <div className="add_btnSave" onClick={() => this.saveEinvoice()}>{this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>

                                    
                                </div>
                                :
                                null
                        }
                        <div className="clear"></div>
                    </div>
                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        )
    }
}