import React, { Component } from 'react';
import * as APIManager from './../../../../APIManager/APIManager'
import { Link } from 'react-router-dom';

class Holidays extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            holidayFieldValues: [],
        }
    }

    componentDidMount(){
        this.getHolidaysList()
    }

    getHolidaysList = () => {

        this.setState({ isLoading: true, error: false })
        APIManager.callGetHolidaysList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                holidayFieldValues: response.holidayDetails
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });  
        
    }

    getFormattedDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
        const dateObj = new Date(date);
        const month = monthNames[dateObj.getMonth()];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = dateObj.getFullYear();
        const output = month  + '\n'+ day  + ', ' + year;

       return output; 
    }

    render() {
        return (
             <div className="add_inner">
                
                <div className="value">
                    <table>
                        <thead>
                            <tr>
                                <th>Holiday Name</th>
                                <th>Holiday Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.holidayFieldValues.map((data, i) => {
                                    if(new Date(data.holidaydate) >= new Date()){
                                    return  <tr key={"data_holiday"+i}>
                                                <td>{data.holidayname}</td>
                                                <td>{this.getFormattedDate(data.holidaydate)}</td>
                                            </tr>
                                    }
                                })
                            }
                        </tbody>
                    </table> 
                    <Link to="/editholidays"><div className="setEditButton">Edit</div></Link>
                </div> 
                <div className="clear"></div>
            </div> 
        );
    }
}

export default Holidays;