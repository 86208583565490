import React, { Component } from 'react';
import { Header, AccountingHeader } from './../../../../components'
import * as Ai from 'react-icons/ai'
import * as Fi from 'react-icons/fi'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import Loading from '../../../../components/Loading';
import StateWiseGSTCode from './../StateWiseGSTCode.json'
import ExpenseGroup from './ExpenseGroup';
import ExpenseGroupGST from './ExpenseGroupGST';
import Switch from 'react-input-switch';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';
import NewFile from '../../../../components/AutoGenerateComponent/addNewFile';


class EditExpenses extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            success: false,
            successmessage: "",
            error: false,
            errormessage: "",


            expenseId: this.props.location.state.expenseId,
            isNewEntry: this.props.location.state.isNewEntry,

            userList: [],
            expenseCategoryData: [],
            paymentTypeData: [],
            gstPartyData: [],
            isGst: false,
            party: "",
            user: "",
            billdate: "",
            expenseCategory: "",
            stateofsupply: "",
            description: "",
            paymenttype: "",
            referenceNo: "",
            number: "",

            subTotal: 0,
            total: 0,
            productGroupData: [],

            payableAmount: 0,
            paidAmount: "",
            remainingAmount: 0,

            expenseFile: [],
            countNewFileNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,

            showGroupData: false,
            fieldNotEditable: true,
        }
        this.setTotalAmountData = this.setTotalAmountData.bind(this)
    }

    componentDidMount(){
        this.getUserList()
        this.getExpenseCategories()
        this.getBankAccounts()
        this.getSingleExpense()
        this.getParty()
    }

    getSingleExpense = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleExpenseDetail(this.state.expenseId)
        .then(response => {
            console.log("Response ", response)
            this.setState({ 
                user: response.userId,
                billdate: response.billDate ? new Date(response.billDate) : "",
                expenseCategory: response.expenseCategory,
                stateofsupply: response.stateOfSupply,
                description: response.description,
                paymenttype: response.paymentType,
                referenceNo: response.referenceNo,

                subTotal: response.subTotal,
                total: response.total,
                productGroupData: response.expenseDetail,

                payableAmount: response.total,
                paidAmount: response.paid,
                remainingAmount: response.balance,

                isGst: response.is_gst ? response.is_gst : false,
                party: response.party ? response.party : "",

                number: response.expenseNumber,
                expenseFile: response.expenseFile ? response.expenseFile : [],
                
                countNewFileNumber: [],
                addTypeFields: [],
                count: 0,
                typeFieldValues: [],
                generateNewType: true,
                
                showGroupData: true,
                isLoading: false,
            },()=>{
                this.addNewField(0)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

   

    getParty = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllGSTDetail("","","","","","","")
        .then(response => {
            this.setState({
                isLoading: false,
                gstPartyData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                userList: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
        .then(response => {
            let category = response.expenseCategory ? response.expenseCategory : []
            this.setState({
                isLoading: false,
                expenseCategoryData: category
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    clearDataOnToggle = () => {
        this.setState({
            showGroupData: false,
            subTotal: 0,
            total: 0,
            productGroupData: [],
            payableAmount: 0,
            paidAmount: "",
            remainingAmount: 0,
            stateofsupply: "",
            party: "",
        },()=>{
            this.setState({ showGroupData: true })
        })
    }

    viewSelectedFile = (file) => {
        if(file){
            let pdfWindow = window.open("")
            pdfWindow.document.write(
                "<iframe width='100%' height='100%' src='" +encodeURI(file)+ "'></iframe>"
            )
        }
    }

  

    setDisplayName = (selectedFile) => {
        if(selectedFile){
            let splitData = selectedFile.split("__")
            let splitData_last_index = splitData[1]
            let finalName = "";
            if(splitData_last_index){
                finalName = splitData_last_index.split("_").join(" ")
            }else{
                finalName = selectedFile.substring(selectedFile.lastIndexOf('/') + 1).split("_").join(" ")
            }
            return finalName;
        }
    }

    getBankAccounts = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetBankAccounts()
        .then(response => {
            let paymentTypeData = []
            if(response.bankAccountDetails){
                for(let i = 0; i<response.bankAccountDetails.length;i++){
                    let record = response.bankAccountDetails[i]
                    let accStr1 = "****"
                    let accStr2 = (record.bankNumber).substr(record.bankNumber.length - 4)
                    let obj = {
                        id: record.id,
                        text: record.bankName +" "+ accStr1 + " " + accStr2
                    }
                    paymentTypeData.push(obj)
                }
            }

            this.setState({
                isLoading: false,
                paymentTypeData: paymentTypeData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    setTotalAmountData = (grandTotal,subTotal,productGroupData) => {
        let value = grandTotal !== "" ? grandTotal : 0
        let paid = this.state.paidAmount !== "" ? this.state.paidAmount : 0
        this.setState({
            payableAmount: parseFloat(value).toFixed(2),
            remainingAmount: (parseFloat(value) - parseFloat(paid)).toFixed(2),
            total: value,
            subTotal: subTotal,
            productGroupData: productGroupData,
        })
    }

    setPaidAmount = (val) => {
        let value = val >= 0 ? val : ""
        if(value === "" || value <= parseFloat(this.state.payableAmount))
        this.setState({
            paidAmount: value,
            remainingAmount: value !== "" ? (parseFloat(this.state.payableAmount) - parseFloat(value)).toFixed(2) : 0,
        })    
    }

    goBack = () => {
        this.props.history.goBack();
    }

    updateExpense = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                expenseId,
                user,
                expenseCategory,
                billdate,
                stateofsupply,
                description,
                productGroupData,
                subTotal,
                total,
                paymenttype,
                paidAmount,
                remainingAmount,
                referenceNo,
                isGst,
                party
            } = this.state

            let val = this.state.typeFieldValues;  
            let filteredVal = val.filter(function (el) {
                return el != null;
            });
            
            if(isGst && party === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select party first"
                })
            }else if(user === ''){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select user"
                },()=>{
                    this.scrollToTop()
                })
            }else if(billdate === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select bill date"
                },()=>{
                    this.scrollToTop()
                })
            }else if(parseFloat(total) === 0.00){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please add expense details and amount"
                },()=>{
                    this.scrollToTop()
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateExpenseDetail(expenseId,user,expenseCategory,convertDateToMMDDYYYY(billdate),stateofsupply,referenceNo,JSON.stringify(productGroupData),subTotal,total,paymenttype,paidAmount,remainingAmount,description,isGst,party)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Expense Updated Successfully"
                    },()=>{
                        if(filteredVal.length > 0){
                            this.createAllFiles(filteredVal, expenseId)
                        }else{
                            this.getSingleExpense()
                            this.scrollToTop()
                        }
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    },()=>{
                        this.scrollToTop()
                    })
                });
            }
        }else{
            let slug = "Accounting_expenses"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    createAllFiles = (files, id) => {
        this.setState({ isLoading: true, error: false })
        Promise.all(files.map((data)=>{
            return APIManager.callCreateExpenseFile(id,data.newFile)
        })).then(resp => {
            console.log(resp)
            this.getSingleExpense()
        }) 
    } 

    getSingleParty = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleGSTDetail(this.state.party)
        .then(response => {
            this.setState({
                isLoading: false,
                expenseCategory: response.data.expenseCategory,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    deleteEntry = () => {
        let slug = "Accounting_expenses"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                let { expenseId } = this.state
                APIManager.callDeleteExpenseDetail(expenseId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    

     /* FILE UPLOAD ALL FUNCTIONALITY */

     setTypeFields = (status) => {
        let numArray = []
        let fields = []
        for(let i=0;i<status.length;i++){
            numArray.push(i)
            fields.push(
                <NewFile 
                    key={i}
                    componentNum={i}
                    newFile={status[i].documentFile}
                    onBlur={this.statusInputBlur}
                    onEdit={this.statusInputEdit}
                    onRemove={this.statusInputRemove}
                />
            )
        }
        this.setState({
            addTypeFields: fields,
            countNewFileNumber: numArray,
            count: status.length
        },()=>{
            this.addNewField(status.length)
        })
    }

     addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <NewFile 
                        key={len}
                        componentNum={len}
                        newFile={""}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countNewFileNumber: this.state.countNewFileNumber.concat([len]),
            })
        })
    }
    
    statusInputEdit = (newFile, num) => {
        let array = [...this.state.countNewFileNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
        
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && newFile !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                this.setState({
                    countNewFileNumber: this.state.countNewFileNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <NewFile 
                                key={this.state.count}
                                componentNum={this.state.count}
                                newFile={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (newFile, num) => {
    
         let array = [...this.state.countNewFileNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
             if (newFile !== "") {
                 let obj = {
                     "newFile": newFile,
                 }
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (newFile !== "") {
                 let obj = {
                     "newFile": newFile,
                 }
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    statusInputRemove = (number) => {
        let array = [...this.state.countNewFileNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countNewFileNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }

    deleteThisFile = (id) => {
        var r = window.confirm("Are you sure you want to delete this file?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            let { expenseId } = this.state
            APIManager.callDeleteExpenseFile(id,expenseId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.getSingleExpense()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }
    /* FILE UPLOAD ALL FUNCTIONALITY */


    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isAccounting={true} />
                <AccountingHeader isExpenses={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Edit Expense {this.state.number}</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateExpense()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                            <div className="add_inner">
                                    <div className="title">GST</div>
                                    <div className="value switch" style={{ width: 24 }}>
                                        <Switch
                                            value={this.state.isGst ? 1 : 0}
                                            onChange={()=>this.setState({ isGst: !this.state.isGst },()=>{ this.clearDataOnToggle() })}
                                            styles={{
                                                track: {
                                                    backgroundColor: '#c1c1c1'
                                                },
                                                trackChecked: {
                                                    backgroundColor: 'green'
                                                },
                                                button: {
                                                    backgroundColor: '#fff',
                                                    width: 10
                                                },
                                                buttonChecked: {
                                                    backgroundColor: '#fff',
                                                    width: 10
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                {
                                    this.state.isGst &&
                                    <div className="add_inner">
                                        <div className="title">Party</div>
                                        <div className="value">
                                            <Select2    
                                                data={this.state.gstPartyData.map((data)=>(
                                                    { id: data._id, text: data.gstNumber+' '+data.companyName}
                                                ))}
                                                value={this.state.party}
                                                options={{placeholder: 'Select Party'}}
                                                onSelect={(e)=> this.setState({ party: e.target.value },()=>{this.getSingleParty()})}
                                            />
                                        </div>
                                        <div className="clear"></div>
                                    </div> 
                                }
                                
                                
                                <div className="add_inner">
                                    <div className="title">Expense Category</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.expenseCategoryData.map((data)=>(
                                                {id: data.expenseCategory, text: data.expenseCategory}
                                            ))}
                                            value={this.state.expenseCategory}
                                            options={{placeholder: 'Select Category'}}
                                            onSelect={(e)=> this.setState({ expenseCategory: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Account Manager</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.userList.map((data)=>(
                                                { id: data._id, text: data.username ? data.username : data.full_name}
                                            ))}
                                            value={this.state.user}
                                            options={{placeholder: 'Select User'}}
                                            onSelect={(e)=> this.setState({ user: e.target.value })}
                                            disabled={this.state.isSubUser}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                
                            </div> 
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Bill Date*</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={this.state.billdate}
                                            onChange={
                                                date => this.setState({ billdate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                {
                                    this.state.isGst &&
                                    <div className="add_inner">
                                        <div className="title">State of supply</div>
                                        <div className="value">
                                            <Select2    
                                                data={
                                                    StateWiseGSTCode.map((data)=>(
                                                        { id: data.gstCode +' '+data.stateCode, text: data.gstCode +' '+data.stateName }
                                                    ))
                                                }
                                                value={this.state.stateofsupply}
                                                options={{placeholder: 'Select State'}}
                                                onSelect={(e)=> this.setState({ stateofsupply: e.target.value })}
                                            />
                                        </div>
                                        <div className="clear"></div>
                                    </div> 
                                }
                                <div className="add_inner">
                                    <div className="title">Description</div>
                                    <div className="value">
                                        <textarea 
                                            autoComplete="off" 
                                            className="expense_desc_textarea" 
                                            value={this.state.description}
                                            onChange={(e)=>this.setState({ description: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div>
                            <div className="clear"></div>
                            {
                                (this.state.showGroupData && this.state.isGst) &&
                                <ExpenseGroupGST
                                    callBackFunction={this.setTotalAmountData}
                                    productGroupData={this.state.productGroupData}
                                    isGst={this.state.isGst}
                                />
                            }
                            {
                                (this.state.showGroupData && !this.state.isGst) &&
                                <ExpenseGroup 
                                    callBackFunction={this.setTotalAmountData}
                                    productGroupData={this.state.productGroupData}
                                    isGst={this.state.isGst}
                                />
                            }
                            
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Payment type</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.paymentTypeData}
                                            value={this.state.paymenttype}
                                            options={{placeholder: 'Select Type'}}
                                            onSelect={(e)=> this.setState({ paymenttype: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Reference No.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.referenceNo} onChange={(e)=>this.setState({ referenceNo: e.target.value})} />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Total</div>
                                    <div className="value">
                                        <input type="text" value={this.state.payableAmount} readOnly/>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Paid</div>
                                    <div className="value">
                                        <input type="text" value={this.state.paidAmount} onChange={(e)=>this.setPaidAmount(e.target.value)} />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner mt-2">
                                    <div className="title font-weight-bold">Remaining</div>
                                    <div className="value font-weight-bold createdText border-0">
                                        {this.state.remainingAmount}
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        <div className='position-relative'>
                            <div className='add_partside'>
                                <div className="add_inner">
                                    <div className="title">Document(s)</div>
                                    <div className="value">
                                        {/* <input 
                                            className='input-file add_inner'
                                            type="file"
                                            onChange={this.onFileChange}
                                            accept="application/msword, application/vnd.ms-excel, application/pdf, image/*"
                                        ></input> */}
                                        <table>
                                            <tbody>
                                                {
                                                    Array.isArray(this.state.expenseFile) ?
                                                    this.state.expenseFile.map((data)=>(
                                                        <tr key={data._id}>
                                                            <td><div onClick={()=>this.viewSelectedFile(data.documentFile)} className="doc-saved-file pl-2 m-0">{this.setDisplayName(data.documentFile)}</div></td>
                                                            <td>
                                                                <Fi.FiX className="extraFieldCloseButton" onClick={()=>this.deleteThisFile(data._id)} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    null
                                                }
                                                {
                                                    this.state.addTypeFields
                                                }
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateExpense()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        
                    </div>
                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        );
    }
}

export default EditExpenses; 