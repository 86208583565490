import React from 'react';
import { Link } from 'react-router-dom'
import RichTextEditor from 'react-rte';
import { Header, CRMHeader } from './../../../../components'
import Loading from '../../../../helper/loading'
import * as APIManager from '../../../../APIManager/APIManager'
import renderHTML from 'react-render-html';
import * as Fi from 'react-icons/fi'
import * as Ai from "react-icons/ai";
import * as Im from "react-icons/im";
import EditDocuments from '../EditOrder/EditDocuments';
import { convertDateStringMMDDtoDDMM } from '../../../../constants/DateFormatter';

export default class ViewCustomerOrder extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            goBack: this.props.goBack,
            history: this.props.history,

            orderId: "",
            orderNumber: "",

            isLoading: false,
            error: false,
            success: false,
            setProductGroup: false,
            errormessage: "",
            successmessage: "",

            customerName: "",
            revisionName: "",
            accountManager: "",
            poDeliveryDate: "",
            actualDelivery: "",
            number: "",
            customer: "",
            status: "",
            updatedStatus: "",
            deliverydate: "",
            deliveryterms: "",
            reference: "",
            shippingaddress: "",
            internalNotes: "",
            Created: "",
            rfqDate: "",
            quotationValidityEnd: "",
            rfqNo: "",
            poNo: "",
            poDate: "",
            contactPerson: "",
            quotationMessageEditor: RichTextEditor.createEmptyValue(),
            quotationMessage: "",
            quotationMessageFooterEditor: RichTextEditor.createEmptyValue(),
            quotationMessageFooter: "",

            productGroup: [],
            total: 0,
            discountValue: 0,
            discountPercentage: 0,
            totalQty: 0,

            customerOrderNotes: [],
            termsFields: [],
            termsConditions: [],
            coAllDocuments: [],
            CoShipments: [],

            docPermissionsManagersField: [],
            docPermissionsManagersValue: [],
            isDocumentPermit: false,
            documentFields: [],

            fieldNotEditable: true,
           
        }
    }

    componentDidMount() {
        let orderId = localStorage.getItem("customerOrderId")
        let orderNumber = localStorage.getItem("customerOrderNumber")

        this.setState({
            orderId,
            orderNumber
        }, () => {
            this.getOrderDetails(orderId)
            this.getShipments()
        })

        

    }



    getOrderDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerOrderDetail(id)
            .then(response => {
                let countQty = 0;
                for (let i = 0; i < response.productGroup.length; i++) {
                    countQty += parseFloat(response.productGroup[i].quantity)
                }

                this.setState({
                    isLoading: false,
                    number: response.number.toUpperCase(),
                    customer: response.customer,
                    status: response.status,
                    updatedStatus: response.status,
                    deliverydate: response.deliveryDate,
                    deliveryterms: response.deliveryTerms,
                    reference: response.reference,
                    shippingaddress: response.shippingAddress,
                    internalNotes: response.internalNotes,
                    Created: response.created,
                    rfqDate: response.rfqDate,
                    quotationValidityEnd: response.quotationValidityEnd,
                    rfqNo: response.rfqNo,
                    poNo: response.poNo ? response.poNo : '',
                    poDate: response.poDate,
                    contactPerson: response.contactPerson,
                    customerOrderNotes: response.notes,
                    accountManager: response.accountManager ? response.accountManager : '',
                    revisionName: response.revisionName ? response.revisionName : '',
                    quotationMessageEditor: response.quotationMessage ? RichTextEditor.createValueFromString(response.quotationMessage, 'html') : RichTextEditor.createEmptyValue(),
                    quotationMessageFooterEditor: response.quotationMessageFooter ? RichTextEditor.createValueFromString(response.quotationMessageFooter, 'html') : RichTextEditor.createEmptyValue(),
                    productGroup: response.productGroup,
                    poDeliveryDate: response.poDeliveryDate ? response.poDeliveryDate : "",
                    actualDelivery: response.actualDeliveryDate ? response.actualDeliveryDate : "",
                    total: response.total,
                    discountValue: response.discountValue,
                    discountPercentage: response.discountPercentage,
                    termsConditions: response.termsCondition,
                    totalQty: countQty
                }, () => {
                    this.getSingleCustomerDetail(response.customer)
                    this.getCODocuments()
                    if (response.termsCondition !== undefined) {
                        this.setTermsConditions(response.termsCondition)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    createCustomerNote = () => {
        this.props.history.push({
            pathname: "/createconote",
            state: {
                orderId: this.state.orderId,
                orderNumber: this.state.orderNumber
            }
        })
    }

    editCustomerOrderNote = (data) => {
        this.props.history.push({
            pathname: "/editconote",
            state: {
                id: data.id,
                note: data.note,
                is_important_co_note: data.is_important,
                orderId: this.state.orderId,
                orderNumber: this.state.orderNumber
            }
        })
    }

    getShipments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOShipmentList(this.state.orderId)
            .then(response => {
                this.setState({
                    isLoading: false,
                    error: false,
                    CoShipments: response.data ? response.data : []
                })

            }).catch(errors => {
                this.setState({
                    isLoading: false
                })
            });
    }

    getCODocuments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCODocuments(this.state.orderId)
            .then(response => {
                let fields = []
                if (response) {
                    for (let i = 0; i < response.length; i++) {
                        fields.push(
                            <EditDocuments
                                key={'docs' + i}
                                file={response[i].documentFile}
                                id={response[i]._id}
                                description={response[i].description}
                                onDocDelete={this.deleteDocumentSuccess}
                                onSubmitSuccess={""}
                                onUpdateSuccess={this.updateDocumentSuccess}
                                isOldData={false}
                                customerOrderId={this.state.orderId}
                            />
                        )
                    }
                }
                this.setState({
                    isLoading: false,
                    documentFields: fields
                }, () => {
                    this.setState({
                        documentFields: this.state.documentFields.concat([
                            <EditDocuments
                                key={'docs'}
                                file={""}
                                id={""}
                                description={""}
                                onDocDelete={this.deleteDocumentSuccess}
                                onSubmitSuccess={this.onDocUploadSuccess}
                                onUpdateSuccess={""}
                                isOldData={true}
                                customerOrderId={this.state.orderId}
                            />
                        ])
                    })
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                })
            });

    }

    formatDate = (dt) => {
        
        return convertDateStringMMDDtoDDMM(dt)

        // let newDate = "";
        // if(dt){
        //     let d = dt.split('/')
        //     newDate = d[1]+'/'+d[0]+'/'+d[2]
        // }
        // return newDate;
    }

    onDocUploadSuccess = () => {
        this.getCODocuments()
    }

    updateDocumentSuccess = () => {
        this.setState({
            documentFields: []
        }, () => {
            this.getCODocuments()
        })
    }

    deleteDocumentSuccess = () => {
        this.getCODocuments()
    }

    setDisplayName = (selectedFile) => {
        if (selectedFile) {
            let splitData = selectedFile.split("__")
            let splitData_last_index = splitData[1]
            let finalName = "";
            if (splitData_last_index) {
                finalName = splitData_last_index.split("_").join(" ")
            } else {
                finalName = selectedFile.substring(selectedFile.lastIndexOf('/') + 1).split("_").join(" ")
            }
            return finalName
        }
    }

    setTermsConditions = (resp) => {
        let termFields = [];
        for (let i = 0; i < resp.length; i++) {
            termFields.push(
                <div key={'termsAll' + i}>
                    <div className="__terms_value_div __terms_options __terms_values_new_css">
                        {resp[i].name}
                    </div>
                    <div className="__terms_value_div __terms_values_new_css text-justify">
                        {resp[i].options}
                    </div>

                    <div className="clear"></div>
                </div>
            )
        }
        this.setState({
            termsFields: termFields
        })
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    updateOrder = () => {

        let {
            orderId,
            customer,
            status,
            deliveryterms,
            quotationMessage,
            quotationMessageFooter,
            reference,
            shippingaddress,
            internalNotes,
            number,
            rfqDate,
            quotationValidityEnd,
            rfqNo,
            poNo,
            poDate,
            termsConditions,
            contactPerson,
            accountManager,
            productGroup,
            total,
            discountValue,
            discountPercentage,
            deliverydate
        } = this.state

        this.setState({ isLoading: true, error: false })

        APIManager.callUpdateCustomerOrder(orderId, number, customer, status, deliverydate, deliveryterms, reference, shippingaddress, internalNotes, JSON.stringify(productGroup), total, discountPercentage, discountValue, quotationMessage, quotationMessageFooter, rfqDate, quotationValidityEnd, rfqNo, poNo, poDate, JSON.stringify(termsConditions), contactPerson, accountManager)
            .then(response => {
                this.setState({
                    isLoading: false,
                    error: false,
                    success: true,
                    successmessage: response.message,
                    updatedStatus: status
                }, () => {
                    this.scrollToTop()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteCustomerOrder(this.state.orderId)
                .then(response => {
                    localStorage.removeItem('productGroupData')
                    localStorage.removeItem("totalOfProductCost")
                    localStorage.removeItem("totalDiscountPrice")
                    localStorage.removeItem("totalDiscountPercentage")
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }

    editThisCustomer = () => {
        localStorage.setItem("cmpId", this.state.customer)
        this.props.history.push({
            pathname: "/editcustomercompany"
        })
    }

    downloadPDF = (status) => {
        let st = status
        if (status.toLowerCase() === "quotation") {
            st = status + ' no.'
        }
        localStorage.setItem("pdf_status_for_co", st)
        window.open('customerviewpdf')
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    getSingleCustomerDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
            .then(response => {
                localStorage.setItem('applicableTax', response.companyData.taxApplicable)
                localStorage.setItem("cmpIdNum", response.companyData.companyId.toUpperCase())
                localStorage.setItem("cmpName", response.companyData.companyName)
                this.setState({
                    isLoading: false,
                    customerName: response.companyData.companyId.toUpperCase() + " " + response.companyData.companyName,
                    setProductGroup: true,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors,
                    setProductGroup: true
                })
            });
    }

    editShipment = (_id) => {
        let slug = "Stock_Shipments"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/editshipment",
                state: { shipmentId: _id, newShipmentEntry: false }
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    createShipment = () => {
        let slug = "Stock_Shipments"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createshipment",
                state: { customerOrderId: this.state.orderId }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }


    viewSelectedFile = (file) => {
        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" + encodeURI(file) + "'></iframe>"
        )
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isCustomerOrder={true} />
                <div className="main-container">
                    <div>
                        <div className="page-header mb-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="page-title">Customer order {this.state.orderNumber} details</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="head-import-buttons float-right">
                                        <div className="button" onClick={() => this.downloadPDF(this.state.status)}>PDF <Im.ImArrowDown2 /></div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }

                        <div className="tableContent">
                            <div className="addSaveButton mb-20">
                                <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                                <div className="clear"></div>
                            </div>
                            <div className="position-relative">
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Number*</div>
                                        <div className="value width30 _Bbaspace3px">
                                            <div className="createdText disabled">
                                                {this.state.number}
                                            </div>
                                        </div>
                                        <div className="value width40">
                                            <div className="createdText disabled">
                                                {this.state.revisionName}
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Customer*</div>
                                        <div className="value text-capitalize">
                                            <div className="float-left width90">
                                                <div className="createdText disabled">
                                                    {this.state.customerName}
                                                </div>
                                            </div>
                                            <div className='width10 d-inline-block text-center secondary-color mt-1' onClick={() => this.editThisCustomer()}><Im.ImLink /></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Status*</div>
                                        <div className="value">
                                            <select
                                                value={this.state.status}
                                                onChange={(e) => {
                                                    let slug = "CRM_CustomerOrders"
                                                    let permission = "update"
                                                    if (APIManager.checkUserPermission(slug, permission)) {
                                                        this.setState({ status: e.target.value }, () => { this.updateOrder() })
                                                    } else {
                                                        alert("You don't have permission to " + permission + " this record")
                                                    }
                                                }
                                                }
                                            >
                                                {/* <option value="">Select</option>
                                                <option value="confirmed">Confirmed</option>
                                                <option value="partially invoiced">Partially Invoiced</option>
                                                <option value="waiting for production">Waiting for production</option>
                                                <option value="in production">In production</option>
                                                <option value="performa invoice">Performa invoice</option>
                                                <option value="ready for shipment">Ready for shipment</option>
                                                <option value="delivered">Delivered</option> */}

                                                <option value="">Select</option>
                                                <option value="waiting for confirmation">Waiting for confirmation</option>
                                                <option value="confirmed">Confirmed</option>
                                                <option value="waiting for production">Waiting for Production</option>
                                                <option value="in production">In Production</option>
                                                <option value="ready for shipment">Ready for Shipment</option>
                                                <option value="partially invoiced">Partially Invoiced</option>
                                                <option value="partially shipped">Partially Shipped</option>
                                                <option value="invoiced">Invoiced</option>
                                                <option value="shipped">Shipped</option>
                                                <option value="delivered">Delivered</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">PO No.</div>
                                        <div className="value"><div className="createdText disabled">{this.state.poNo}</div></div>
                                        <div className="clear"></div>
                                    </div>

                                   

                                   



                                    <div className="add_inner">
                                        <div className="title">Shipping address</div>
                                        <div className="value">
                                            <div className="createdTextArea disabled text-capitalize">
                                                {this.state.shippingaddress.split('_').join(' ')}
                                            </div>

                                        </div>
                                        <div className="clear"></div>
                                    </div>



                                </div>
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Created</div>
                                        <div className="value">
                                            <div className="createdText disabled">{this.formatDate(this.state.Created)}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">PO Date</div>
                                        <div className="value">
                                            <div className="createdText disabled">{this.formatDate(this.state.poDate)}</div>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Delivery (As per PO)</div>
                                        <div className="value">
                                            <div className="createdText disabled">
                                                {this.formatDate(this.state.poDeliveryDate)}
                                            </div>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Estimated Delivery</div>
                                        <div className="value">
                                            <div className="createdText disabled">
                                                {this.formatDate(this.state.deliverydate)}
                                            </div>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Actual Delivery</div>
                                        <div className="value">
                                            <div className="createdText disabled">
                                                {this.formatDate(this.state.actualDelivery)}
                                            </div>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                   

                                </div>
                                <div className="clear"></div>


                            </div>

                            <div className="position-relative">
                                {
                                    this.state.setProductGroup &&
                                    <div className="main-container mt-20 order_product_group">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className='pg-tab-head'>No.</th>
                                                    <th className='pg-tab-head'>Product group</th>
                                                    <th className='pg-tab-head'>Product</th>
                                                    <th className='pg-tab-head text-center' style={{ width: 150 }}>Quantity</th>
                                                    <th className='pg-tab-head' style={{ width: 150 }}>Delivery date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.productGroup.map((data, i) => (
                                                        <tr key={'productGroupData' + i}>
                                                            <td className='ml-1'>{i + 1}</td>
                                                            <td className='text-capitalize'>
                                                                {data.productGroupName}

                                                            </td>
                                                            <td className='text-capitalize'>
                                                                <div className='mb-1'>
                                                                    {data.itemCode} {data.item}
                                                                </div>
                                                                <textarea
                                                                    readOnly
                                                                    className='text-area'
                                                                    defaultValue={data.productFreeText ? data.productFreeText.split('<br>').join('\n') : data.freeText}
                                                                />
                                                            </td>
                                                            <td className='text-center'>
                                                                {data.quantity}
                                                            </td>
                                                            <td>
                                                                {this.formatDate(data.deliverDate)}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td></td>
                                                    <td>Total</td>
                                                    <td></td>
                                                    <td className='text-center'>{this.state.totalQty}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                            {/* TERMS & CONDTIONS FIELD */}
                            <div className="__terms_and_conditions position-relative">
                                <div className="__terms_left_header">
                                    <div className="__terms_content_title">{'Terms & conditions'}</div>
                                </div>
                                <div className="__terms_right_content">
                                    {this.state.termsFields}
                                </div>


                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover bg-transparent"></div>
                                }
                                <div className="clear"></div>
                            </div>



                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>

                                <div className="clear"></div>
                            </div>
                        </div>


                        <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                            <div className="footerTitle">Documents</div>
                            <div className="__terms_right_content">
                                <table>
                                    <tbody>
                                        {
                                            this.state.documentFields
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <table>
                                <thead>
                                    <tr>
                                        <th className="tableHeader"><span>Number</span></th>
                                        <th className="tableHeader"><span>Description</span></th>
                                        <th className="tableHeader w-50">File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.coAllDocuments
                                    }
                                </tbody>
                            </table> */}
                        </div>

                        <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                            <div className="footerTitle">Shipments</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Invoice</th>
                                        <th>Delivery</th>
                                        <th>Status</th>
                                        <th>Waybill note</th>
                                        <th>Picking list note</th>
                                        <th className="tableAddButton text-right"><div onClick={()=>this.createShipment()} className="addContactButton"><Fi.FiPlus /></div></th>
                                    </tr>
                                </thead>
                                <tbody>

                                {
                                    this.state.CoShipments !== null &&
                                    this.state.CoShipments.map((data, i) => (
                                        <tr key={'po-invoices'+i} onClick={()=>this.editShipment(data._id)}>
                                            <td className='text-uppercase'>{data.number}</td>
                                            <td className='text-uppercase'>{data.invoiceNumber}</td>
                                            <td>{data.deliveryDate}</td>
                                            <td>{data.status}</td>
                                            <td>{data.waybillNotes}</td>
                                            <td>{data.pickingListNotes}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>
                        </div>
                        <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                            <div className="footerTitle">Notes</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th className="tableHeader"><span>Created</span></th>
                                        <th className="tableHeader"><span>Modified</span></th>
                                        <th className="tableHeader w-50">Notes</th>
                                        <th className="tableAddButton text-right"><div onClick={() => this.createCustomerNote()} className="addContactButton"><Fi.FiPlus /></div></th>
                                    </tr>
                                    {
                                        this.state.customerOrderNotes !== null &&
                                        this.state.customerOrderNotes.map((data, i) => (
                                            <tr key={data.id} className={data.is_important ? "__imp_note" : ""} onClick={() => this.editCustomerOrderNote(data)}>
                                                <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br /><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                                <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br /><span style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                                <td className="texttop preline">{data.note}</td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>

                    </div>


                    {
                        this.state.isLoading &&
                        <Loading />
                    }

                </div>

            </div>

        )
    }
}