import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';

import * as Ai from 'react-icons/ai'

import DefaultDepartmentsAndWorkers from '../../../components/AutoGenerateComponent/addDepartmentsAndWorkers';
import AddIdleTime from '../../../components/AutoGenerateComponent/addIdleTime';


class CreateWorkstation extends Component {

    constructor(props){
        super(props)
               
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            workstationGroups: [],
            type: "",
            checkHourlyRate: false,
            hourlyrate: "",
            productivity: "",
            idletimemin: "",
            idletimemax: "",

            countDeptAndWorkersNumber: [],
            addTypeFields: [],
            count: 0,
            deptAndWorkers: [],
            generateNewType: true,

            countIdleTimeNumber: [],
            addIdleTimeFields: [],
            countIdleTime: 0,
            idleTimeFieldValues: [],
            generateNewIdleTime: false,
        }
    }

    componentDidMount(){
       this.getAllWorkStationGroup()
       this.addNewField(0)  
       this.addNewFieldIdleTime(0)    
    }

    getAllWorkStationGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllWorkstationGroup("", "", "", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                workstationGroups: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    addNewFieldIdleTime = (len) => {
        this.setState({
            addIdleTimeFields: this.state.addIdleTimeFields.concat(
                [
                    <AddIdleTime 
                        key={len}
                        componentNum={len}
                        startTime={""}
                        onBlur={this.statusInputBlurIdleTime}
                        onEdit={this.statusInputEditIdleTime}
                        onRemove={this.statusInputRemoveIdleTime}
                    />
                ]
            ),
            countIdleTime: len,
        },()=>{
            this.setState({
                countIdleTimeNumber: this.state.countIdleTimeNumber.concat([len]),
                generateNewIdleTime: true,
            })
        })
    }

    statusInputEditIdleTime = (startTime, num) => {

        let array = [...this.state.countIdleTimeNumber];
        let index = array.indexOf(num)
        let idleTimeVal = [...this.state.idleTimeFieldValues]     
        
        
        if (typeof idleTimeVal[index] === 'undefined' && this.state.generateNewIdleTime === true && startTime !== "") {
            this.setState({
                countIdleTime: this.state.countIdleTime + 1,
                generateNewIdleTime: false,
            }, () => {
                
                this.setState({
                    countIdleTimeNumber: this.state.countIdleTimeNumber.concat(
                        [this.state.countIdleTime]
                    ),
                    addIdleTimeFields: this.state.addIdleTimeFields.concat(
                        [
                            <AddIdleTime 
                                key={this.state.countIdleTime}
                                componentNum={this.state.countIdleTime}
                                startTime={""}
                                onBlur={this.statusInputBlurIdleTime}
                                onEdit={this.statusInputEditIdleTime}
                                onRemove={this.statusInputRemoveIdleTime}
                            />
                        ]
                    ),
                })
            })
        }
    }

  

    finalDayTime = (date) => {
        var dateTime = new Date(date),
        month = '' + (dateTime.getMonth() + 1),
        day = '' + dateTime.getDate(),
        year = dateTime.getFullYear();
        // if (month.length < 2) 
        //     month = '0' + month;
        // if (day.length < 2) 
        //     day = '0' + day;
        
        let finalEndTime = day+"-"+month+"-"+year
        return finalEndTime
    }

    statusInputBlurIdleTime = (startTime, num) => {
 
        let array = [...this.state.countIdleTimeNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.idleTimeFieldValues]   

        if (typeof statusVal[index] !== 'undefined') {
            if (startTime !== "") {

                let obj = this.finalDayTime(startTime)
                let idleTimeFieldValues = [ ...this.state.idleTimeFieldValues ];
                idleTimeFieldValues[index] = obj;

                this.setState({
                    idleTimeFieldValues,
                    generateNewIdleTime: true,
                })
            }
        }else{
            if (startTime !== "") {
                let obj = this.finalDayTime(startTime)
                this.setState({
                    idleTimeFieldValues: this.state.idleTimeFieldValues.concat([ obj ]),
                    generateNewIdleTime: true,
                })
            }
        }
    }

    statusInputRemoveIdleTime = (number) => {
        let array = [...this.state.countIdleTimeNumber];
        let fields = [...this.state.addIdleTimeFields];
        let data = [...this.state.idleTimeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countIdleTimeNumber: array,
                addIdleTimeFields: fields,
                idleTimeFieldValues: data
            });
        }
        
    }


    addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <DefaultDepartmentsAndWorkers 
                        key={len}
                        componentNum={len}
                        defaultDandW={""}
                        userId={""}
                        defaultUser={false}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countDeptAndWorkersNumber: this.state.countDeptAndWorkersNumber.concat([len]),
            })
        })
    }

    statusInputEdit = (defualtDandW, num) => {
        let array = [...this.state.countDeptAndWorkersNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.deptAndWorkers]     
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && defualtDandW !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countDeptAndWorkersNumber: this.state.countDeptAndWorkersNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <DefaultDepartmentsAndWorkers 
                                key={this.state.count}
                                componentNum={this.state.count}
                                defaultDandW={""}
                                userId={""}
                                defaultUser={false}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />

                            
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (defualtDandW, userId, isDefault, num) => {
         let array = [...this.state.countDeptAndWorkersNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.deptAndWorkers]   

         if (typeof statusVal[index] !== 'undefined') {
             if (defualtDandW !== "") {
                let obj = {
                    "deptId": defualtDandW,
                    "userId": userId,
                    "isDefault": isDefault
                }

                 let deptAndWorkers = [ ...this.state.deptAndWorkers ];
                 deptAndWorkers[index] = obj;

                 this.setState({
                     deptAndWorkers: deptAndWorkers,
                     generateNewType: true,
                 })
             }
         }else{
             if (defualtDandW !== "") {
                let obj = {
                    "deptId": defualtDandW,
                    "userId": userId,
                    "isDefault": isDefault
                }

                this.setState({
                     deptAndWorkers: this.state.deptAndWorkers.concat([ obj ]),
                     generateNewType: true,
                })
             }
         }
     }

    statusInputRemove = (number) => {

        let array = [...this.state.countDeptAndWorkersNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.deptAndWorkers]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countDeptAndWorkersNumber: array,
                addTypeFields: fields,
                deptAndWorkers: data
            });
        }
        
    }
    
    createWorkstation = () => {
        
            let {
                type,
                checkHourlyRate,
                hourlyrate,
                productivity,
                idleTimeFieldValues,
                deptAndWorkers
            } = this.state

            /* Remove Null From State */
            let idleTime = [];
            idleTimeFieldValues.forEach((val) => {
                if(val !== null){
                    idleTime.push(val);
                }
            });
            /* Remove Null */

             /* Remove Null From State */
             let workers = [];
             deptAndWorkers.forEach((val) => {
                 if(val !== null){
                    workers.push(val);
                 }
             });
             /* Remove Null */

             workers = [...new Set(workers)];
             // Remove Repeated workers

            if(type === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select type"
                })
            }else if(checkHourlyRate === true && hourlyrate === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter hourly rate"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callCreateWorkstation(type,checkHourlyRate,hourlyrate,productivity,JSON.stringify(workers),JSON.stringify(idleTime))
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.props.history.push({
                            pathname: '/editws',
                            state: { workstationId: response.data._id, newWorkstationEntry: true }
                        })
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteWorkstation(this.state._id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }
    
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstations={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a workstation</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createWorkstation()}>Save</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Type*</div>
                                    <div className="value">
                                        <select className="text-capitalize" value={this.state.type} onChange={(e)=>this.setState({ type: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                this.state.workstationGroups !== null && 
                                                this.state.workstationGroups.map((data,i) => (
                                                    <option value={data._id} key={i+data._id}>{data.number.toUpperCase()} {data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Hourly rate</div>
                                    <div className="__formTextVal">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem" 
                                            checked={this.state.checkHourlyRate} 
                                            onChange={()=>
                                                this.setState({ 
                                                    checkHourlyRate: !this.state.checkHourlyRate,
                                                    hourlyrate: "",
                                                })
                                            } 
                                        /> Yes
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                {
                                    this.state.checkHourlyRate &&
                                        <div className="add_inner">
                                            <div className="title"></div>
                                            <div className="value">
                                                <input type="text" value={this.state.hourlyrate} onChange={(e)=>this.setState({ hourlyrate: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} style={{ width: "40%"}} />
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                }
                                {/* <div className="add_inner">
                                    <div className="title">Productivity*</div>
                                    <div className="value">
                                        <input type="text" className="fieldheightdecr text-capitalize" autoComplete="off" value={this.state.productivity} onChange={(e)=>this.setState({ productivity: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div> */}

                                <div className="add_inner">
                                    <div className="title">Default department/worker</div>
                                    <div className="value">
                                        <table>
                                            <tbody>
                                                {this.state.addTypeFields}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Idle time</div>
                                    <div className="value">
                                        <table style={{ width: 220 }}>
                                            <tbody>
                                                {this.state.addIdleTimeFields}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                              
                                
                            </div>
                            <div className="clearfix"></div>
                            
                        </div>

                        
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createWorkstation()}>Save</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default CreateWorkstation;