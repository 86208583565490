import React from 'react';


import { 
    FiX,
} from "react-icons/fi";


export default class OrderStatus extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            orderStatus: this.props.orderStatus,
        }

    }

    componentDidMount(){
       
    }
   
   

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
            
        } = this.props
        return(
            <tr>
                <td>
                    <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.orderStatus}
                        onChange={(e)=>this.setState({ orderStatus: e.target.value},()=>{
                            onEdit(this.state.orderStatus, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.orderStatus, componentNum)
                        }
                    />
                </td>
                
                <td>
                    {
                        this.state.orderStatus !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}