import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

import AddTermsFields from '../../../components/AutoGenerateComponent/addTermsFields';

export default class CreateNewTerms extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            termName: "",
            termsFor: ["customerOrder"],
            isTermCustomer: true,
            isTermVendor: false,

            countFieldNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,

            showTermInCoInvoice: false,
            showTermInPoInvoice: false
        }
    }

    componentDidMount(){
        this.addNewField()    
    }

    
    statusInputEdit = (fields,num) => {

        let array = [...this.state.countFieldNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
    

        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && fields !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countFieldNumber: this.state.countFieldNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <AddTermsFields 
                                key={this.state.count}
                                componentNum={this.state.count}
                                tablefield={""}
                                isDefault={false}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (fields,isDefault,num) => {
         let array = [...this.state.countFieldNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
            
             if (fields !== "") {
                let obj = {
                    "terms": fields,
                    "is_default": isDefault
                }
                 
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (fields !== "") {
                let obj = {
                    "terms": fields,
                    "is_default": isDefault
                }
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    statusInputRemove = (number) => {
        let array = [...this.state.countFieldNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countFieldNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
    }

    createTermsConditions = () => {
        let {
            showTermInCoInvoice,
            showTermInPoInvoice
        } = this.state
        let termTitle = this.state.termName
        let fieldValue = this.state.typeFieldValues
        let termsFor = this.state.termsFor
        /* Remove Null From State */
        let values = [];
        fieldValue.forEach((val) => {
            if(val !== null){
                values.push(val);
            }
        });
        /* Remove Null */

        if(termTitle === ""){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please add term title" 
            })
        }else if(termsFor.length === 0){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please select term type" 
            })
        }else if(values.length === 0){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please add options for the term" 
            })
        }else{
            this.setState({ isLoading: true, error: false })

            APIManager.callCreateNewTerm(termTitle,JSON.stringify(termsFor),JSON.stringify(values),showTermInCoInvoice,showTermInPoInvoice)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }


    setFields = (fields) => {
        
        let createFieldArray = [];
        let countFieldArray = [];
        let addFieldValues = [];

        fields.map((data, i) => (
            createFieldArray.push(
                <AddTermsFields
                    key={i}
                    componentNum={i}
                    tablefield={data}
                    onBlur={this.statusInputBlur}
                    onEdit={this.statusInputEdit}
                    onRemove={this.statusInputRemove}
                />
            ),
            countFieldArray.push(i),
            addFieldValues.push(data)
        ))

        this.setState({
            addTypeFields: createFieldArray,
            countFieldNumber: countFieldArray,
            typeFieldValues: addFieldValues,
            count: fields.length,
        },()=>{
            this.addNewField()
        })  
    }

    addNewField = () => {
        this.setState({
            count: this.state.count + 1,
            generateNewType: true,
        }, () => {
            this.setState({
                countFieldNumber: this.state.countFieldNumber.concat(
                    [this.state.count]
                ),
                addTypeFields: this.state.addTypeFields.concat(
                    [
                        <AddTermsFields 
                            key={this.state.count}
                            componentNum={this.state.count}
                            tablefield={""}
                            isDefault={false}
                            onBlur={this.statusInputBlur}
                            onEdit={this.statusInputEdit}
                            onRemove={this.statusInputRemove}
                        />
                    ]
                ),
            })
        })
    }

    addNewProductGroup = () => {
        this.props.history.push('/createproductgroup')    
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    changeTermType = (type) => {
        let termsFor = [...this.state.termsFor]
        let index = termsFor.indexOf(type)
        if(index > -1){
            termsFor.splice(index, 1)
            this.setState({
                termsFor
            })
        }else{
            termsFor.push(type)
            this.setState({
                termsFor
            })
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">

                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isTerms={true} />
                <div className="main-container">
                
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create terms</div>
                            </div>
                        </div>
                    </div>
                        
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createTermsConditions()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="mb-5 mt-5">
                        <div className="add_partside">
                        
                            <div className="add_inner">
                                <div className="title">Term*</div>
                                <div className="value">
                                    <input type="text" value={this.state.termName} onChange={(e)=>this.setState({ termName: e.target.value })} className="fieldheightdecr" autoComplete="off" />
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Term for*</div>
                                <div className="value">
                                    <div className="cursor-pointer">
                                        <div className="term_radio_input">
                                            <input 
                                                type="checkbox"
                                                checked={this.state.isTermCustomer} 
                                                onChange={()=>this.setState({ 
                                                    isTermCustomer: !this.state.isTermCustomer,
                                                    showTermInCoInvoice: false,
                                                },()=>{
                                                    this.changeTermType('customerOrder')
                                                })}
                                            />
                                        </div>
                                        <div className="term_label">Customer Order</div>
                                        <div className="term_radio_input">
                                            <input 
                                                type="checkbox"
                                                disabled={!this.state.isTermCustomer}
                                                checked={this.state.showTermInCoInvoice} 
                                                onChange={()=>this.setState({ showTermInCoInvoice: !this.state.showTermInCoInvoice})}
                                            />
                                        </div>
                                        <div className="term_label">Show in CO Invoice</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="cursor-pointer">
                                        <div className="term_radio_input">
                                            <input 
                                                type="checkbox"
                                                checked={this.state.isTermVendor} 
                                                onChange={()=>this.setState({ 
                                                    isTermVendor: !this.state.isTermVendor,
                                                    showTermInPoInvoice: false
                                                },()=>{
                                                    this.changeTermType('purchaseOrder')
                                                })}
                                            />
                                        </div>
                                        <div className="term_label">Purchase Order</div>
                                        <div className="term_radio_input">
                                            <input 
                                                type="checkbox"
                                                disabled={!this.state.isTermVendor}
                                                checked={this.state.showTermInPoInvoice} 
                                                onChange={()=>this.setState({ showTermInPoInvoice: !this.state.showTermInPoInvoice})}
                                            />
                                        </div>
                                        <div className="term_label">Show in PO Invoice</div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            
                        </div>
                        <div className="add_partside">
                        
                            <div className="add_inner">
                                <div className="title">Options*</div>
                                <div className="value mt-1">
                                <table className="__terms_options_table">
                                    <thead>
                                        <tr>
                                            <th>Terms</th>
                                            <th className="text-center">Set as default</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.addTypeFields
                                        }
                                    </tbody>
                                </table>   
                                </div>
                                
                                <div className="clearfix"></div>
                            </div>
                        </div> 
                        <div className="clear"></div>
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createTermsConditions()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                </div>

            </div>
        )
    }
}