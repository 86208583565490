import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import * as APIManager from './../../../APIManager/APIManager'
import permissions  from './permissions.json'

class UpdateSubUser extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseData: [],
            userId: "",
            showEmail: "",
            name: "",
            email: "",
            phone: "",
            designation: "",
            userPermissionData: permissions,


            departmentData: [],
            departments: [],


            checkPerPieceSalary: false,
            checkPerHourSalary: true,
            checkPerMonthSalary: false,
            
            workerPaymentType: "",
            workerPaymentValue:  "",

            fieldNotEditable: true,

            userAccountId: "",
        }
    }

    componentDidMount(){

        let user = localStorage.getItem('userdata')
        if(user){
            let accountId = JSON.parse(localStorage.getItem('userdata')).account_id
            this.setState({
                userAccountId: accountId
            })
        }

        let userId = localStorage.getItem("subuserId")
        let userEmail = localStorage.getItem("subuserEmail")

        this.setState({
            userId: userId,
            showEmail: userEmail
        },()=>{
            this.getSingleUserDetail()
        })
    }

    getDepartment = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment("", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                departmentData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }



    getSingleUserDetail = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleSubUser(this.state.userId)
        .then(response => {
            this.setState({
                isLoading: false,
                name: response.data.user_name,
                email: response.data.user_email,
                phone: response.data.mobileNo ? response.data.mobileNo : "",
                designation: response.data.user_designation,
                departments: response.data.departments ? response.data.departments : [],
                workerPaymentType: response.data.workerPaymentType ?  response.data.workerPaymentType : "perhour",
                workerPaymentValue: response.data.workerPaymentValue ? response.data.workerPaymentValue : ""
            },()=>{
                this.setUserPermissions(response.data.permissions)
                this.getDepartment()
                this.toggleWorkerSalary(this.state.workerPaymentType)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    toggleDepartment = (i) => {
        let departments = [...this.state.departments]
        let departmentData = [...this.state.departmentData] 
    
        let deptId = departmentData[i]._id;
        let deptData = departments
        if(departments.includes(deptId)){
            const index = deptData.indexOf(deptId);
            deptData.splice(index, 1);
            this.setState({
                departments: deptData
            })
        }else{
            deptData.push(deptId)
            this.setState({
                departments: deptData
            })
        }
    }

    setUserPermissions = (permissions) => {

        if(permissions !== undefined && permissions.length > 0){
            let userPermissionData = [...this.state.userPermissionData]
            let updatedRecord = userPermissionData.map(obj => permissions.find(lo => lo.slug === obj.slug) || obj);
            this.setState({
                userPermissionData: updatedRecord
            })
        }
    }

    updateSubUser = () => {

        if(this.state.fieldNotEditable !== true){

        let userPermissionData = [...this.state.userPermissionData]

        var updatedPermission = userPermissionData.filter(function (el) {
        return el.read === true ||
                el.create === true ||
                el.update === true ||
                el.delete === true;
        });

        let { userId,email,name,designation, departments, workerPaymentType, workerPaymentValue, phone } = this.state;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(email === "" || name === "" || designation === ""){
            this.setState({
                error: true,
                errormessage: "Please fill all fields",
            },()=>{
                this.scrollToTop()
            })
        }else if(!re.test(String(email).toLowerCase())){
            this.setState({
                error: true,
                errormessage: "Please enter valid email address",
            },()=>{
                this.scrollToTop()
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateSubUser(userId,name,email,phone,designation,JSON.stringify(updatedPermission), JSON.stringify(departments),workerPaymentType, workerPaymentValue)
            
            .then(response => {
                this.setState({
                    isLoading: false,
                    error: false,
                    success: true,
                    fieldNotEditable: true,
                    successmessage: "User data updated successfully",
                },()=>{
                    this.scrollToTop()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }

    }else{
       

        let slug = "Settings_HumanResources"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({
                fieldNotEditable: false
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    

    deleteEntry = () => {
        let slug = "Settings_HumanResources"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this user?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteSubUser(this.state.userId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    updatePermission = (type, data, i) => {
        let userPermissionData = [...this.state.userPermissionData]
        
        if(type === 'read')
            userPermissionData[i].read = !data 

        if(userPermissionData[i].read){
            if(type === 'create')
                userPermissionData[i].create = !data 

            if(type === 'update')
                userPermissionData[i].update = !data 

            if(type === 'delete')
                userPermissionData[i].delete = !data 
        }else{
            if(userPermissionData[i].create !== undefined) userPermissionData[i].create = false
            if(userPermissionData[i].update !== undefined) userPermissionData[i].update = false
            if(userPermissionData[i].delete !== undefined) userPermissionData[i].delete = false
        }
        

        this.setState({
            userPermissionData
        })
    }

    toggleWorkerSalary = (value) => {
        this.setState({ 
            workerPaymentType: value,
        })

        if(value === 'perpiece');
            this.setState({ 
                checkPerPieceSalary: true,
                checkPerHourSalary: false,  
                checkPerMonthSalary: false
            })

        if(value === 'perhour')
            this.setState({ 
                checkPerHourSalary:  true,
                checkPerPieceSalary: false,  
                checkPerMonthSalary: false
            })

        if(value === 'monthly')
            this.setState({ 
                checkPerMonthSalary: true,
                checkPerHourSalary:  false,
                checkPerPieceSalary: false,  
            })
    }
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewSubUser") !== null){
            localStorage.removeItem("createNewSubUser")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isHR={true} />

                <div className="main-container">
        
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">User {this.state.showEmail}</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="tableContent inner-page-table">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateSubUser()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Name </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ name: e.target.value })} value={this.state.name}></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Designation </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ designation: e.target.value })} value={this.state.designation}></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Phone </div>
                                    <div className="value input__type_number"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ phone: e.target.value })} value={this.state.phone}></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Email </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" onChange={(e)=>this.setState({ email: e.target.value })} value={this.state.email} readOnly></input></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            <div className="permission__div">
                                <div className="float-left width10 text-right permission_resources_txt">Resources</div>
                                <div className="float-left width80">
                                    <div className="tableContent" style={{ overflowX : "auto" }}>
                                        <table>
                                            <thead>
                                                <tr className="no-bg">
                                                    <th className="tableHeader">Resource</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Read</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Create</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Update</th>
                                                    <th className="tableHeader text-center" style={{ width: 80 }}>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.userPermissionData.map((data, i) => (
                                                    ((data.hide === undefined || data.hide === false || data.hide === "false") && (data.isPersonalTab === undefined || (data.isPersonalTab === true && data.accountId === this.state.userAccountId))) &&
                                                    <tr key={"user_permissions__"+i}>
                                                        <td>{data.name}</td>
                                                        <td className="text-center">{data.read === undefined ? "" : <input type="checkbox" checked={data.read} onChange={()=>this.updatePermission('read', data.read, i)} /> }</td>
                                                        <td className="text-center">{data.create === undefined ? "" : <input type="checkbox" checked={data.read ? data.create : false} disabled={data.read ? false : true} onChange={()=>this.updatePermission('create', data.create, i)} /> }</td>
                                                        <td className="text-center">{data.update === undefined ? "" : <input type="checkbox" checked={data.read ? data.update : false} disabled={data.read ? false : true} onChange={()=>this.updatePermission('update', data.update, i)} /> }</td>
                                                        <td className="text-center">{data.delete === undefined ? "" : <input type="checkbox" checked={data.read ? data.delete : false} disabled={data.read ? false : true} onChange={()=>this.updatePermission('delete', data.delete, i)} /> }</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div>
                                <div className="add_partside mt-3">
                                    <div className="add_inner">
                                        <div className="title">Departments </div>
                                        <div className="value">
                                            {
                                                this.state.departmentData !== null &&
                                                this.state.departmentData.map((data, i) => (
                                                    <div key={data._id}>
                                                        <div className="float-left">
                                                            <input 
                                                                type="checkbox" 
                                                                className="__input_checkbox"
                                                                value={data._id}
                                                                defaultChecked={this.state.departments.includes(data._id)}
                                                                onChange={()=>this.toggleDepartment(i)}
                                                            ></input>
                                                        </div>
                                                        <div className="float-left createdText border-0 ml-1">#<span className="text-uppercase">{data.number}</span> <span className="text-capitalize">{data.name}</span></div>
                                                        <div className="clear"></div>
                                                    </div> 
                                                ))
                                            }
                        
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div>
                                <div className="add_partside mt-3">
                                    <div className="add_inner">
                                        <div className="title">Salary </div>
                                        <div className="value">
                                            
                            
                                            <div>
                                                <div className="float-left">
                                                    <input 
                                                        type="radio" 
                                                        className="__input_checkbox"
                                                        name="user_payment"
                                                        checked={this.state.checkPerHourSalary}
                                                        onChange={()=>this.toggleWorkerSalary('perhour')}
                                                    ></input>
                                                </div>
                                                <div className="float-left createdText border-0 ml-1">Per hour</div>
                                                <div className="clear"></div>
                                            </div> 
                                            <div>
                                                <div className="float-left">
                                                    <input 
                                                        type="radio" 
                                                        className="__input_checkbox"
                                                        name="user_payment"
                                                        checked={this.state.checkPerPieceSalary}
                                                        onChange={()=>this.toggleWorkerSalary('perpiece')}
                                                    ></input>
                                                </div>
                                                <div className="float-left createdText border-0 ml-1">Per piece</div>
                                                <div className="clear"></div>
                                            </div> 
                                            <div>
                                                <div className="float-left">
                                                    <input 
                                                        type="radio" 
                                                        className="__input_checkbox"
                                                        name="user_payment"
                                                        checked={this.state.checkPerMonthSalary}
                                                        onChange={()=>this.toggleWorkerSalary('monthly')}
                                                    ></input>
                                                </div>
                                                <div className="float-left createdText border-0 ml-1">Monthly</div>
                                                <div className="clear"></div>
                                            </div> 
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title"></div>
                                        <div className="value">
                                            <input
                                                type="text"
                                                value={this.state.workerPaymentValue}
                                                onChange={(e)=>this.setState({ workerPaymentValue: parseInt(e.target.value) ? parseInt(e.target.value) : ""})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateSubUser()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateSubUser;