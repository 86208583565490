import React from 'react';

import { Header, CRMHeader } from './../../../components'
import Helper from './../../../helper/helper'
import Loading from './../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'
//import './../../../../style.css'

import ContactComponent from './../../../components/AutoGenerateComponent/addContactInformation'


import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditContactPerson extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countContactFieldNumber: [],
            contactFieldCounter: 0,
            contactExtraFields: [],
            contactInformation: [],
            generateNewContact: true,

            contactId: "",
            companyNum: "",
            companyName: "",

            fieldNotEditable: true,
        }
        this.contactInfoFieldRemove = this.contactInfoFieldRemove.bind(this)
    }

    componentDidMount(){
        let cmpId = localStorage.getItem("customerContactId")
        let cmpIdNum = localStorage.getItem("cmpIdNum")
        let cmpName = localStorage.getItem("cmpName")

        this.setState({
            contactId: cmpId,
            companyNum: cmpIdNum,
            companyName: cmpName,
        })
        this.getDataOfContactPerson()

    }

    getDataOfContactPerson = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token
        let customerContactId = localStorage.getItem("customerContactId")


        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("id", customerContactId);


        fetch(APIManager.WEBSERVICES_URL+'crm/customer/getSingleCompanyContacts',{
                method: "post",
                headers: myHeaders,
                body: formdata
            })
            .then(res => res.json())
            .then((result) => {
                if(result.status === "success"){
                    let res = result.data;

                    this.name.value = res.name
                    this.position.value = res.position

                    this.setContactInfo(res.contactInformation)
                   
                }else if(result.status === "userDataError"){
                    that.logout()
                }else{

                }
            },(error) => {
                console.log(error)
            }
        )
    }

    setContactInfo = (data) => {
        
        for(let i=0;i<data.length;i++){ 
            this.setState({
                countContactFieldNumber: this.state.countContactFieldNumber.concat([
                    i
                ]),
                contactFieldCounter: data.length,
                contactExtraFields: this.state.contactExtraFields.concat([
                    <ContactComponent 
                        key={i}
                        componentNum={i}
                        value={data[i].value}
                        type={data[i].type}
                        fun_checkInputBlur={
                            this.contactInfoInputBlur
                        }
                        fun_checkContactInfoInput={
                            this.contactInfoInputVal
                        }
                        fun_removeContactInfoField={
                            this.contactInfoFieldRemove
                        }
                    />
                ])
            })
        }


        this.setState({
            contactInformation: data,
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent 
                    key={data.length+1}
                    componentNum={data.length+1}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ]),
            contactFieldCounter: this.state.contactFieldCounter + 1,
            generateNewContact: true,
        },()=>{
            this.setState({
                countContactFieldNumber: this.state.countContactFieldNumber.concat([
                    this.state.contactFieldCounter
                ]),
            })
        })
    }


    updateContactPerson = () => {

        if(this.state.fieldNotEditable !== true){
        let name = this.name.value;
        let position = this.position.value;

        const that = this;

        if(name === ""){
            this.setState({
                error: true,
                errormessage: "Please enter name",
            })
        }else{
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("id", this.state.contactId);
            formdata.append("name", name);
            formdata.append("position", position);
            formdata.append("contactInformation", JSON.stringify(this.state.contactInformation));

            
            fetch(APIManager.WEBSERVICES_URL+'crm/customer/updateCompanyContact',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                    if(result.status === "success"){
                        that.goBack();
                    }else if(result.status === "userDataError"){
                        that.logout()
                    }else{
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                    }
                },(error) => {
                   console.log(error)
                }
            )
        }
    }else{
        
            let slug = "CRM_Customers"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        
    }
    }



    deleteContactPerson = () => {
        let slug = "CRM_Customers"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
            
                this.setState({ isLoading: true })
                const that = this;

                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state.contactId)
                
                fetch(APIManager.WEBSERVICES_URL+'crm/customer/deleteContact',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                        if(result.status === "success"){
                            that.setState({ isLoading: false },()=> {
                                that.goBack()
                            })
                        }else if(result.status === "userDataError"){
                            that.setState({ isLoading: false },()=> {
                                that.logout()
                            })
                        }else{
                            that.setState({
                                isLoading: false,
                                error: true,
                                errormessage: result.message
                            })
                        }
                    },(error) => {
                        console.log(error)
                    }
                )
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }


    contactInfoInputVal = (val, number) => {
        let that = this;

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]  
        
        
        if (typeof CIVal[index] === 'undefined' && this.state.generateNewContact === true && val.length > 0) {
            this.setState({
                contactFieldCounter: this.state.contactFieldCounter + 1,
                generateNewContact: false,
            }, () => {
                
                this.setState({
                    countContactFieldNumber: this.state.countContactFieldNumber.concat(
                        [this.state.contactFieldCounter]
                    ),
                    contactExtraFields: this.state.contactExtraFields.concat(
                        [
                            <ContactComponent 
                                key={
                                    this.state.contactFieldCounter
                                }
                                value={""}
                                type={"Phone"}
                                fun_checkInputBlur={
                                    this.contactInfoInputBlur
                                }
                                componentNum={
                                    this.state.contactFieldCounter
                                }
                                fun_checkContactInfoInput={
                                    that.contactInfoInputVal
                                }
                                fun_removeContactInfoField={
                                    this.contactInfoFieldRemove
                                }
                            />
                        ]
                    ),
                })
            })
        }
    }



    contactInfoFieldRemove = (number) => {

        let array = [...this.state.countContactFieldNumber];
        let fields = [...this.state.contactExtraFields];
        let data = [...this.state.contactInformation]
        let index = array.indexOf(number)


        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;

            this.setState({
                countContactFieldNumber: array,
                contactExtraFields: fields,
                contactInformation: data
            });
        }
    }
    contactInfoInputBlur = (val, type, number) => {

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]        

        if (typeof CIVal[index] !== 'undefined') {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                let contactInformation = [ ...this.state.contactInformation ];
                contactInformation[index] = obj;

                this.setState({
                    contactInformation,
                    generateNewContact: false,
                })
            }
        }else{
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                this.setState({
                    contactInformation: this.state.contactInformation.concat([ obj ]),
                    generateNewContact: true,
                })
            }
        }
    }

   
    render(){
        return(
            <div className="page absoluteWhite">
               <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true}/>
                
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Edit a contact person</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateContactPerson()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteContactPerson()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Company </div>
                                <div className="value companyname">{this.state.companyNum.toUpperCase()} {this.state.companyName}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Name * </div>
                                <div className="value"><input type="text" className="fieldheightdecr" ref={(c) => this.name = c} name="name"></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Position</div>
                                <div className="value"><input type="text" className="fieldheightdecr" ref={(c) => this.position = c} name="position"></input></div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner contactinfo">

                                {/* Contact Information fields */}
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Contact information</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1">
                                            <div className="float-left width40">Type</div>
                                            <div className="float-left width60">value</div>
                                            <div className="clear"></div>
                                        </div>

                                        {
                                            this.state.contactExtraFields
                                        }
                                        
                                        
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>

                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateContactPerson()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteContactPerson()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
            </div>
        )
    }
}