import React from 'react';

import * as APIManager from './../../APIManager/APIManager'
import { FiX } from "react-icons/fi";


export default class DefaultDepartmentsAndWorkers extends React.Component{

    constructor(props){
        super(props);   
        this.state = {
            defaultDandW: this.props.defaultDandW,
            userId: this.props.userId,
            defaultUser: this.props.defaultUser,
            departmentData: [],
            userData : [],
            allUsersList: []
        }
    }

    componentDidMount(){
        this.getAllDepartments()
        this.getUserList()
        this.getUsersByDepartment(this.state.defaultDandW)
    }

    getAllDepartments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment("", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                departmentData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({
                isLoading: false,
                userData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getUsersByDepartment = (_id) => {
        if(_id){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetUsersByDepartment(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    allUsersList: response.data
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }else{
            this.setState({ 
                userId: "",
                defaultUser: false,
            })
        }
    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td className="position-relative" style={{ width: 200 }}>
                   
                    <select 
                        className="text-capitalize"
                        value={this.state.defaultDandW}
                        onChange={e => {
                            this.setState({ defaultDandW: e.target.value, userId: "", defaultUser: false },()=>{
                                this.getUsersByDepartment(e.target.value)
                                onEdit(this.state.defaultDandW, componentNum)
                                onBlur(this.state.defaultDandW, this.state.userId, this.state.defaultUser, componentNum)
                            })
                        }}
                    >
                        <option value="">Select</option>
                        {
                            this.state.departmentData !== null &&
                            this.state.departmentData.map((data, i) => (
                                <option key={i+data._id} value={data._id}>{data.number.toUpperCase()} {data.name}</option>
                            ))
                        }

                        {/* {
                            this.state.userData !== null &&
                            this.state.userData.map((data, i) => (
                                <option key={i+data._id} value={data._id}>{data.username ? data.username : data.full_name}</option>
                            ))
                        } */}


                    </select>
                                                          
                </td>
                <td  style={{ width: 200 }}>
                <select 
                        className="text-capitalize"
                        value={this.state.userId}
                        onChange={e => {
                            this.setState({ userId: e.target.value,  },()=>{
                                if(this.state.userId){ 
                                    this.setState({ defaultUser: true},()=>{
                                        onBlur(this.state.defaultDandW, this.state.userId, this.state.defaultUser, componentNum)
                                    }) 
                                }else{ 
                                    this.setState({ defaultUser: false},()=>{
                                        onBlur(this.state.defaultDandW, this.state.userId, this.state.defaultUser, componentNum)
                                    }) 
                                }
                                
                          })
                        }}
                    >
                        <option value="">Select</option>
                        {
                            this.state.allUsersList !== null &&
                            this.state.allUsersList.map((data, i) => (
                                <option key={i+data._id} value={data._id}>{data.username}</option>
                            ))
                        }

                    </select>
                </td>
                <td>
                    <input type="checkbox" checked={this.state.defaultUser} disabled />
                </td>
                <td>
                    {
                        this.state.defaultDandW !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}