import React, { Component } from 'react';

import Select2 from './../../../../../lib/select2/select2';
import './../../../../../lib/select2/select2.css'

import DatePicker from "react-datepicker";
import './../../../../../lib/react-datepicker.min.css';
import * as Ai from 'react-icons/ai';
import * as Bi from 'react-icons/bi'
import * as Fi from 'react-icons/fi'
import { validateNumber, validatePercentage } from './../../../../../constants/Validate'
import * as APIManager from './../../../../../APIManager/APIManager'

class AddProductDetail extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            onBlurCall: this.props.onBlurProduct,
            count: this.props.counter,
            currencyData: this.props.currencyData,

            productGroupList: this.props.productGroupList,
            productList: [],
            productData: [],

            productObj: {
                'productGroup': '',
                'product': '',
                'productFreeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subtotal': '',
                'deliverDate': '',
                'rawtotal': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
                'exchangeRate': '',
            },

            productgroup: "",
            product: "",
            productfreetext: "",
            quantity: "",
            priceperunit: "",
            priceperunitInINR: "",
            currencyRate: "",
            priceperunitInOtherCurrency: "",
            discount: "",
            subtotal: "",
            subtotalInOtherCurrency: "",
            rawtotal: "",
            rawtotalInOtherCurrency: "",
            deliverydate: "",
            minSellingPrice: 0,

            itemTax: 0,
            totalTax: 0,
            applicableTax: [],

            uom: "",
        }
    }

    componentDidMount(){
        let applicableTaxLocal = localStorage.getItem("applicableTax");
        if(applicableTaxLocal !== null && applicableTaxLocal !== ""){
            this.setState({
                applicableTax: applicableTaxLocal.split(',')
            })
        }
    }

    getItemsByGroup = (_id) => {
        this.setState({  
            isLoading: true, 
            error: false, 
        })
        APIManager.callProductGroupItemsWithSellingPrice(_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productfreetext: "", 
                product: "",
                priceperunit: "",
                priceperunitInINR: "", 
                priceperunitInOtherCurrency: "",
                uom: "",
                productList: response
            },()=>{
                this.generateObject('productGroup', _id)
                this.generateObject('product', '')
                this.generateObject('productFreeText', '')
                this.generateObject('pricePerUoM', '')
                this.state.onBlurCall(this.state.productObj, this.state.count)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemsByGroup = (_id) => {
        this.setState({  
            isLoading: true, 
            error: false, 
        })
        APIManager.callProductGroupItemsWithSellingPriceForCreate(_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productfreetext: "", 
                product: "",
                priceperunit: "",
                priceperunitInINR: "", 
                priceperunitInOtherCurrency: "",
                uom: "",
                productList: response
            },()=>{
                this.generateObject('productGroup', _id)
                this.generateObject('product', '')
                this.generateObject('productFreeText', '')
                this.generateObject('pricePerUoM', '')
                this.state.onBlurCall(this.state.productObj, this.state.count)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            let sellingPrice = response.itemData.sellingPrice;
            let priceInInr = sellingPrice;
            let pricePerUoM = sellingPrice;
            let priceInOtherCurrency = "";

            if(this.state.currencyData.conversionRate){
                priceInOtherCurrency = parseFloat(sellingPrice) / parseFloat(this.state.currencyData.conversionRate)
            }

            this.setState({
                isLoading: false,
                totalTax: response.itemData.tax,
                currencyRate :this.props.currencyData.conversionRate,
                priceperunit: pricePerUoM ? pricePerUoM.toFixed(2) : "",
                priceperunitInINR: priceInInr ? priceInInr.toFixed(2) : "", 
                priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : "",
                productfreetext: response.itemData.freeText,
                uom: response.itemData.uomName ? response.itemData.uomName : "",
                minSellingPrice: response.itemData.minSellingPrice ? response.itemData.minSellingPrice : 0
            },() => {
                this.generateObject('productFreeText', response.itemData.freeText)
                this.generateObject('pricePerUoM', response.itemData.sellingPrice)
                this.generateObject('pricePerUoMInOtherCurrency', priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : "")
                this.generateObject('exchangeRate', this.state.currencyRate)
                this.countSubTotal()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    countSubTotal = () => {
        let { quantity, priceperunit, discount, priceperunitInOtherCurrency  } = this.state;
        if(quantity !== ""){
            if((priceperunit !== "" && !isNaN(priceperunit) !== false) || (priceperunitInOtherCurrency !== "" && !isNaN(priceperunitInOtherCurrency) !== false)){
                let total = parseFloat(quantity*priceperunit)
                let totalInOtherCurrency = parseFloat(quantity*priceperunitInOtherCurrency)

                if(discount !== "" && validateNumber(discount)){
                    let withDiscountTotal = parseFloat(total - ( total*discount/100 ))
                    let withDiscountRawTotal = parseFloat(totalInOtherCurrency - ( totalInOtherCurrency*discount/100 ))
                    this.setState({
                        subtotal: withDiscountTotal.toFixed(2),
                        subtotalInOtherCurrency: withDiscountRawTotal.toFixed(2),
                        rawtotal: withDiscountTotal.toFixed(2),
                        rawtotalInOtherCurrency: withDiscountRawTotal.toFixed(2),
                    },()=>{
                        this.generateObject('subtotal', withDiscountTotal.toFixed(2))
                        this.generateObject('rawtotal', withDiscountTotal.toFixed(2))
                        this.generateObject('subTotalInOtherCurrency', withDiscountRawTotal.toFixed(2))
                        this.generateObject('rawTotalInOtherCurrency', withDiscountRawTotal.toFixed(2))
                    })
                }else{
                    this.setState({
                        subtotal: total.toFixed(2),
                        subtotalInOtherCurrency: totalInOtherCurrency.toFixed(2),
                        rawtotal: total.toFixed(2),
                        rawtotalInOtherCurrency: totalInOtherCurrency.toFixed(2),
                    },()=>{
                        this.generateObject('subtotal', total.toFixed(2))
                        this.generateObject('rawtotal', total.toFixed(2))
                        this.generateObject('subTotalInOtherCurrency', totalInOtherCurrency.toFixed(2))
                        this.generateObject('rawTotalInOtherCurrency', totalInOtherCurrency.toFixed(2))
                    })
                }
            }
        }else{
            this.setState({
                subtotal: "",
                subtotalInOtherCurrency: "",
                rawtotal: "",
                rawtotalInOtherCurrency: "",
            },()=>{
                this.generateObject('subtotal', 0)
                this.generateObject('rawtotal', 0)
            })
        }
    }


    generateObject = (type, value) => {        
        let productObj = this.state.productObj
    
        if(type === 'productGroup')
            productObj.productGroup = value;
        if(type === 'product')
            productObj.product = value;
        if(type === 'productFreeText')
            productObj.productFreeText = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if(type === 'quantity')
            productObj.quantity = value;
        if(type === 'pricePerUoM')
            productObj.pricePerUoM = value;
        if(type === 'discount')
            productObj.discount = value ? parseFloat(value).toFixed(2) : '';
        if(type === 'subtotal')
            productObj.subtotal = value;
        if(type === 'deliverDate'){
            let dt = new Date(value);
            let dd = dt.getDate();
            let mm = dt.getMonth() + 1;
            let yyyy = dt.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let date = mm + '/' + dd + '/' + yyyy;
            productObj.deliverDate = date;
        }
        if(type === 'rawtotal')
            productObj.rawtotal = value;

        if(type === 'pricePerUoMInOtherCurrency')
            productObj.pricePerUoMInOtherCurrency = value;

        if(type === 'subTotalInOtherCurrency')
            productObj.subTotalInOtherCurrency = value;

        if(type === 'rawTotalInOtherCurrency')
            productObj.rawTotalInOtherCurrency = value;
        
        if(type === 'exchangeRate')
            productObj.exchangeRate = value

        this.setState({
            productObj
        },()=>{
            this.state.onBlurCall(this.state.productObj, this.state.count)
        })
    }

    updateCurrencyRate = (val) => {
        if(validateNumber(val)){
            if(val !== "" && parseFloat(val) !== 0){
                let priceInOtherCurrency = parseFloat(this.state.priceperunit) / parseFloat(val);
                this.setState({ 
                    currencyRate: val,
                    priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : 0 
                },()=>{ 
                    this.countSubTotal() 
                })
            }else{
                let priceInOtherCurrency = parseFloat(this.state.priceperunit) / 1;
                this.setState({ 
                    currencyRate: val,
                    priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : 0 
                },()=>{ 
                    this.countSubTotal() 
                })
            }
        } 
    }
    

    render() {
        let {
            onChangeProduct,
            onBlurProduct,
            onRemoveProduct,
            counter
        } = this.props
        return (
            // <tr className={this.state.priceperunit.toString().indexOf("-") > -1 ? '_order__danger__border subgroupdata' : 'subgroupdata'}>
            // <tr className={(((this.state.rawtotalInOtherCurrency * this.state.currencyRate) / this.state.quantity) < this.state.minSellingPrice) || this.state.priceperunit < this.state.minSellingPrice ? '_order__danger__border subgroupdata' : 'subgroupdata'}>
            <tr className={(((this.state.rawtotalInOtherCurrency * this.state.currencyRate) / this.state.quantity) < this.state.minSellingPrice) ? '_order__danger__border subgroupdata' : 'subgroupdata'}>
                <td className='text-center' style={{ width: 50 }}>{counter+1}</td>
                <td>
                    <Select2    
                        data={
                            this.state.productGroupList.map((data) => (
                                { id: data._id, text: data.name }
                            ))
                        }
                        value={this.state.productGroup}
                        options={{placeholder: 'Select group'}}
                        onSelect={(e)=> this.setState({ productGroup: e.target.value },()=>{
                            this.getItemsByGroup(e.target.value);
                        })}
                    />
                </td>
                <td style={{ maxWidth: 200 }}>
                    <Select2    
                        className="mb-1"
                        data={
                            this.state.productList.map((data) => (
                                { id: data._id, text: data.itemCode+' '+data.partDescription }
                            ))
                        }
                        value={this.state.product}
                        options={{placeholder: 'Select product'}}
                        onSelect={(e)=> this.setState({ product: e.target.value },()=>{
                            this.getItemDetail(e.target.value)
                            onChangeProduct(counter);
                            this.generateObject('product', e.target.value)
                            onBlurProduct(this.state.productObj, counter)
                        })}
                    />

                    <textarea 
                        placeholder='Free text' 
                        value={this.state.productfreetext}
                        className='textarea mt-1' 
                        onChange={(e)=>{
                            this.setState({ 
                                productfreetext: e.target.value
                            },()=>{
                                onChangeProduct(counter);
                                this.generateObject('productFreeText', e.target.value)
                            })
                        }}
                        onBlur={()=>onBlurProduct(this.state.productObj, counter)}
                    />
                </td>
                <td style={{width: 100}}>
                    <input 
                        type='text' 
                        className='input-box width50' 
                        value={this.state.quantity} 
                        onChange={(e)=>{
                                if(validateNumber(e.target.value)){
                                    this.setState({ 
                                        quantity: e.target.value
                                    },()=>{ 
                                        this.countSubTotal() 
                                    })
                                }
                            } 
                        }
                        onBlur={(e)=>{
                            this.generateObject('quantity', e.target.value)
                        }}
                    ></input> {this.state.uom}
                </td>
                <td>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box width80' 
                            value={this.state.priceperunit}
                            onChange={
                                (e)=>{
                                    if(validateNumber(e.target.value)){
                                        let priceInOtherCurrency = parseFloat(e.target.value) / parseFloat(this.state.currencyRate);
                                        this.setState({
                                            priceperunit: e.target.value,
                                            priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : 0
                                        },()=>{
                                            this.countSubTotal()
                                        })
                                    }
                                }
                            }
                            onBlur={(e)=>{
                                this.generateObject('pricePerUoM', this.state.priceperunit)
                                this.generateObject('pricePerUoMInOtherCurrency', this.state.priceperunitInOtherCurrency)
                            }}
                        ></input> <Bi.BiRupee />
                    </div>
                </td>
                <td>
                    <input 
                        type='text' 
                        className='input-box width80' 
                        value={this.state.currencyRate} 
                        onChange={(e)=> this.updateCurrencyRate(e.target.value)}
                        onBlur={(e)=>{
                            this.generateObject('exchangeRate', this.state.currencyRate)
                        }}
                    ></input>
                </td>
                <td>
                    <div className='position-relative mt-1'>
                        <input 
                            type='text' 
                            className='input-box width80' 
                            value={this.state.priceperunitInOtherCurrency} 
                            onChange={(e)=>{
                                if(validateNumber(e.target.value))
                                this.setState({ 
                                    priceperunitInOtherCurrency: e.target.value 
                                },()=>{ this.countSubTotal() })} 
                            }
                            onBlur={(e)=>{
                                this.generateObject('pricePerUoMInOtherCurrency', this.state.priceperunitInOtherCurrency)
                            }}
                        ></input> {this.state.currencyData.currencySymbol}
                    </div>
                </td>
               
                <td style={{width: 80}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box width80' 
                            value={this.state.discount} 
                            maxLength={5}
                            onChange={(e)=>{
                                if(validatePercentage(e.target.value))
                                this.setState({ discount: e.target.value },()=>{ this.countSubTotal() })} 
                            }
                            onBlur={(e)=>{
                                this.generateObject('discount', e.target.value)
                                onBlurProduct(this.state.productObj, counter)
                            }}
                        ></input>
                    </div>
                </td>

              

                <td style={{width: 140}}>
                    <div className='position-relative mt-1'>
                        <input 
                            type='text' 
                            className='input-box width80' 
                            value={this.state.rawtotalInOtherCurrency}
                            disabled
                        ></input> {this.state.currencyData.currencySymbol}
                    </div>
                </td>
                
                
                <td style={{width: 110}}>
                    <div className='position-relative'>
                        <DatePicker 
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            selected={
                                this.state.deliverydate
                            }
                            onChange={
                                date => this.setState({ deliverydate: date },()=>{
                                    this.generateObject('deliverDate', date)
                                    onBlurProduct(this.state.productObj, counter)
                                })
                            }
                            className="input-box"/>
                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                    </div>
                </td>
                <td>
                    {
                        (this.state.product !== "" || this.state.productfreetext !== "") &&
                        <Fi.FiX className="extraFieldCloseButton" onClick={onRemoveProduct.bind(this,counter)} />
                    }
                </td>
            </tr>
        );
    }
}

export default AddProductDetail;