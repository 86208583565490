import React, { Component } from 'react'
import { Header, SettingsHeader } from './../../components'
import Strings from './../../constants/Strings'
import * as Ai from 'react-icons/ai'
import Select2 from './../../lib/select2/select2'
import RichTextEditor from 'react-rte'
import * as APIManager from './../../APIManager/APIManager'
import toast from 'react-simple-toasts'

import Category from './categories.json'




export default class createsupport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      
      error: false,
      success: false,
      errormessage: "",
      successmessage: "",

      allCategoriesList: [],

      categoryList: [],
      subCatogryList: [],


      supportMessageEditor: RichTextEditor.createEmptyValue(),


      // req.body.email
      // req.body.companyName
      // req.body.userName

      selectedCategory: "",
      selectedSubCategory: "",
      subject: "",
      supportMessage: "",
      documentFile: [],


    }
  }

  componentDidMount(){
    let userData = JSON.parse(localStorage.getItem('userdata'))
    console.log('user data', userData)

    if(userData.is_admin){
      this.setState({
        allCategoriesList: Category
      },()=>{
        this.setAdminCategory()
      })
    }else{
      let permissions = JSON.parse(localStorage.getItem('permissions'))
      this.setState({
        allCategoriesList: permissions
      },()=>{
        this.setSubUserCategory()
      })
    }
  }


  setSubUserCategory = () => {
    let categoryList = []
    let { allCategoriesList } = this.state;
    
    console.log(allCategoriesList)

    const unique = Array.from(new Map(allCategoriesList.map(item => [item.group, item])).values());
    unique.map((data, i) => {
        let obj = { id: data.group, text: data.group}
        categoryList.push(obj)
    })
    this.setState({
      categoryList
    })
  }
  
  setAdminCategory = () => {
    let categoryList = []
    this.state.allCategoriesList.map((data, i) => {
      if(data.read === undefined){
        let obj = { id: data.name, text: data.name}
        categoryList.push(obj)
      }
    })
    this.setState({
      categoryList
    })
  }

  setAdminSubCategory = (group) => {
    let userData = JSON.parse(localStorage.getItem('userdata'))
    let subCatogryList = []
    this.state.allCategoriesList.map((data, i) => {
      if(data.group === group && (data.read === true || data.read === false)){
        if(data.isPersonalTab && data.accountId === userData.account_id){
          let obj = { id: data.name, text: data.name}
          subCatogryList.push(obj)
        }
        if(!data.isPersonalTab){
          let obj = { id: data.name, text: data.name}
          subCatogryList.push(obj)
        }
      }
    })
    this.setState({
      subCatogryList
    })
  }


  fileChange = (e) => {
    this.setState({ documentFile: e.target.files })
  }

  onTicketMessageChange = (value) => {
    this.setState({
        supportMessageEditor: value,
        supportMessage: value.toString('html')
    });
  }


  sendTicket = () => {
    this.setState({ isLoading: true, error: false })
    let {
      selectedCategory,
      selectedSubCategory,
      subject,
      supportMessage,
      documentFile
  }  = this.state

  if(selectedCategory === ""){
      this.setState({
        error: true,
        success: false,
        errormessage: "Please select category"
      })
  }else if(selectedSubCategory === ""){
    this.setState({
      error: true,
      success: false,
      errormessage: "Please select sub-category"
    })
  }else if(subject === ""){
    this.setState({
      error: true,
      success: false,
      errormessage: "Please enter subject"
    })
  }else if(supportMessage === ""){
    this.setState({
      error: true,
      success: false,
      errormessage: "Please enter message/descriotion"
    })
  }else{
    APIManager.createTicket(selectedCategory,selectedSubCategory,subject,supportMessage,documentFile)
      .then(response => {
        toast("You query submitted successfully, we will get back to you soon. Thanks")
        this.setState({
          isLoading: false,
          error: false,
        },()=>{
          this.goBack()
        })
      })
      .catch(errors => {
        this.setState({
          isLoading: false,
          error: true,
          errormessage: errors
        })
      });
    }

  }


  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <div className="page absoluteWhite">
        <Header logout={this.props.history} goBack={() => this.goBack()} isHelpCenter={true} />
      
        <div className="main-container">

          <div className="page-header">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-title">Create a new ticket</div>
              </div>
            </div>
          </div>

          <div className="pagecontent">
            {
              this.state.error &&
              <div className="editscreenerror">
                <div className="errornotification">
                  <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                </div>
              </div>
            }
            {
              this.state.success &&
              <div className="editscreenerror">
                <div className="successnotification">
                  <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                </div>
              </div>
            }
          </div>

          <div className="addSaveButton">
            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
            <div className="add_btnSave" onClick={() => this.sendTicket()}>Submit</div>
            {/* <div className="add_btnBack" onClick={() => this.deleteEntry()}>Delete</div> */}
            <div className="clear"></div>
          </div>
          <div className="position-relative">
            <div className="add_partside">
              <div className="add_inner">
                <div className="title">Category</div>
                <div className="value user-rights-select">
                  <Select2
                    data={this.state.categoryList}
                    value={this.state.selectedCategory}
                    options={{ placeholder: 'Select category' }}
                    onSelect={(e) => this.setState({ selectedCategory: e.target.value }, () => { this.setAdminSubCategory(e.target.value) })}
                  />
                </div>
                <div className="clear"></div>
              </div>

              <div className="add_inner">
                <div className="title">Sub Category</div>
                <div className="value user-rights-select">
                  <Select2
                    data={this.state.subCatogryList}
                    value={this.state.selectedSubCategory}
                    options={{ placeholder: 'Select sub category' }}
                    onSelect={(e) => this.setState({ selectedSubCategory: e.target.value })}
                  />
                </div>
                <div className="clear"></div>
              </div>
              <div className="add_inner">
                <div className="title">Subject </div>
                <div className="value"><input type="text" className="fieldheightdecr" onChange={(e) => this.setState({ subject: e.target.value })} value={this.state.subject}></input></div>
                <div className="clear"></div>
              </div>
              <div className="add_inner">
                <div className="title">Message </div>
                <div className="value supportTicket">
                    {/* <RichTextEditor
                        value={this.state.supportMessageEditor}
                        onChange={this.onTicketMessageChange} 
                        rootStyle={{ minHeight: 270 }}
                      /> */}
                      <textarea className="textarea textarea-height-80" value={this.state.supportMessage} onChange={(e)=>this.setState({ supportMessage: e.target.value })}></textarea>
                </div>
                <div className="clear"></div>
              </div>
              <div className="add_inner">
                  <div className="title">File </div>
                  <div className="value">
                    <input 
                      type="file" 
                      className="fieldheightdecr input-file" 
                      name="file" 
                      onChange={(e) => this.fileChange(e)}
                      accept="image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
                      multiple
                    >
                    </input>
                  </div>
                <div className="clear"></div>
              </div>
            </div>

            <div className="add_partside">
              <div className="add_inner">
                <h5 className='ticket-instructions-title'>Follow below steps/instructions before submit the support ticket</h5>
                <ul className='ticket-instructions'>
                  {/* <li>Need to understand the flow then check the <b>Implementation</b> tab or visit youtube channel: <a href="http://www.shorturl.at/lmFPU" target="_blank"><i><b>OEMup ↑ V1.0.0</b></i></a></li> */}
                  <li>Need to understand the flow then visit our youtube channel: <a href="http://www.shorturl.at/lmFPU" target="_blank"><i><b>OEMup ↑ V1.0.0</b></i></a></li>
                  <li>Clear browser cache & cookies then refresh page and check if you still have any query.</li>
                  <li>If you have query regarding subscriptions and/or renewal, email us at <a href="mailto:admin@oemup.app">admin@oemup.app</a></li>
                  <li>If something breaks with any operation note down the steps and take/share screenshots so that your query get resolve faster.</li>
                  <li>Write your query short and clear and once get resolved close the ticket.</li>
                </ul>
              </div>
            </div>

            <div className="clear"></div>

            <div className="addSaveButton">
              <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
              <div className="add_btnSave" onClick={() => this.sendTicket()}>Submit</div>
              {/* <div className="add_btnBack" onClick={() => this.deleteEntry()}>Delete</div> */}
              <div className="clear"></div>
            </div>
          </div>


        </div>
      </div>
    )
  }
}
