import React, { Component } from 'react';
import Loading from '../../../../helper/loading';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'

class EditDocuments extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            _id: this.props.id,
            selectedFile: this.props.file,
            documentFile: this.props.file,
            fileDisplayName: "",
            description: this.props.description,
            onDocDelete:this.props.onDocDelete,
            onSubmitSuccess:this.props.onSubmitSuccess,
            onUpdateSuccess: this.props.onUpdateSuccess,
            isOldData: false,
            isUpdateFile: this.props.isOldData,
            orderId: this.props.customerOrderId,
        }
    }

    componentDidMount(){
        this.setDisplayName(this.state.selectedFile)
    }

    onFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0]
        },()=>{
            this.uploadDocument()
        })
    }

  
    uploadDocument = () => {
            let {
                _id,
                orderId,
                selectedFile,
                description
            } = this.state

            if(_id !== ""){
                if(selectedFile === ""){
                    alert("Please select file to upload")
                }else{
                    this.setState({ isLoading: true, error: false })
                    APIManager.callUpdateCustomerDocs(_id, orderId, selectedFile, description)
                    .then(response => {
                        this.setState({ 
                            isLoading: false,
                            isUpdateFile: false,
                            selectedFile: response.documentFile,
                            documentFile: response.documentFile,
                            description: response.description,
                        },()=>{
                            this.setDisplayName(response.documentFile)
                        })
                    })
                    .catch(errors => {
                        this.setState({ 
                            isLoading: false,
                            error: true,
                            errormessage: errors 
                        })
                    });
                }
            }else{
                if(selectedFile === ""){
                    alert("Please select file to upload")
                }else{
                    this.setState({ isLoading: true, error: false })
                    APIManager.callCreateCustomerDocs(orderId, selectedFile, description)
                    .then(response => {
                        this.setState({ 
                            isLoading: false,
                            isUpdateFile: false,
                        },()=>{
                            this.state.onSubmitSuccess()
                        })
                    })
                    .catch(errors => {
                        this.setState({ 
                            isLoading: false,
                            error: true,
                            errormessage: errors 
                        })
                    });
                }
            }
    }

    setDisplayName = (selectedFile) => {
        if(selectedFile){
            let splitData = selectedFile.split("__")
            let splitData_last_index = splitData[1]
            let finalName = "";
            if(splitData_last_index){
                finalName = splitData_last_index.split("_").join(" ")
            }else{
                finalName = selectedFile.substring(selectedFile.lastIndexOf('/') + 1).split("_").join(" ")
            }
            this.setState({
                fileDisplayName: finalName
            })
        }
    }

    
    deleteEntry = () => {
        let slug = "CRM_Quotations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                let {
                    _id,
                    orderId
                } = this.state
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteCustomerDocs(_id, orderId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.state.onDocDelete()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }   
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    viewUpdateFileOption = () => {
        this.setState({
            isUpdateFile: !this.state.isUpdateFile
        })
    }

    viewSelectedFile = () => {
        let file = this.state.documentFile
        window.open(file)
       // pdfWindow.document.write(
       //     "<iframe width='100%' height='100%' src='" +encodeURI(file)+ "'></iframe>"
       // )
    }

    render() {
        return (
            <tr>
                <td className='width30'>
                    {
                        (this.state.documentFile && !this.state.isUpdateFile) &&
                            <div>
                                <div onClick={()=>this.viewSelectedFile()} className='doc-saved-file float-left text-transform-none'>{this.state.fileDisplayName ? this.state.fileDisplayName : 'File Preview'}</div>
                                <div onClick={()=>this.viewUpdateFileOption()} className='doc-saved-file add_btnBack float-right'>Change File</div>
                                <div className='clear'></div>
                            </div>

                    }
                    {
                         this.state.isUpdateFile &&
                            <div>
                                <div className='width90 float-left'>
                                    <input 
                                        type="file" 
                                        className='input-file' 
                                        accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={this.onFileChange}
                                        disabled={this.state.isOldData}
                                    />
                                </div>
                                {
                                    this.state.documentFile &&
                                    <div className='float-right pt-2' onClick={()=>this.viewUpdateFileOption()}><Fi.FiX /></div>
                                }
                                <div className='clear'></div>
                            </div>
                    }    
                </td>

                
                <td className='width60'>
                    <textarea 
                        placeholder='Description' 
                        value={this.state.description} 
                        className='text-area' 
                        onChange={(e)=>this.setState({ description: e.target.value })}>
                    </textarea>
                </td>
                <td>
                    {
                        this.state.documentFile &&
                            <div className='add_btnBack' onClick={()=>this.uploadDocument()}>Update</div>
                    }
                    {
                        !this.state.documentFile &&
                        <div className='add_btnBack' onClick={()=>this.uploadDocument()}>Save</div>
                    }
                </td>
                <td>
                    {
                        this.state.documentFile &&
                        <div className='doc_delete_button' onClick={()=>this.deleteEntry()}><Fi.FiX /></div>
                    }
                </td>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </tr>
        );
    }
}

export default EditDocuments;