import React, { Component } from 'react';
import * as APIManager from './../../../APIManager/APIManager'

class BookingItemListing extends Component {

    constructor(props){
        super(props)
        this.state = {
            data: this.props.data,
            itemDetails: this.props.itemDetails,
            afterBookingData: this.props.afterBookingData,
 
            item: "",
            needed: "",
            inStock: "",
            available: "",
            booked: "",
            expectedAvailable: "",
            afterBooking: "",
            uom: "",

            isNegative: false
        }
    }

    componentDidMount(){
        this.setAllValues()
    }

    setAllValues = () => {
        this.setState({
            needed: this.state.itemDetails.needed,
            inStock: this.state.itemDetails.inStock,
            expectedAvailable: this.state.itemDetails.expectedAvailable,
            available: this.state.itemDetails.available,
            booked: this.state.itemDetails.booked,

            afterBooking: this.state.afterBookingData.afterBooking,
        },()=>{

            let afterBooking = this.state.inStock-this.state.needed;
            
            if(afterBooking < 0){
                this.setState({
                    isNegative: true
                })
            }

            this.getSingleItem(this.state.data.product)
        })
    }

    getSingleItem = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({
                item: response.itemData.itemCode +" "+ response.itemData.partDescription,
                uom: response.itemData.uomName,
                isLoading: false,
                error: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    render() {
        let { bookManually, cancelBooking, number } = this.props
        return ( 
            <tr key={number}>
                <td>{this.state.item}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.needed} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.inStock} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.available} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.booked} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.expectedAvailable} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.inStock-this.state.needed} {this.state.uom}</td>
                {
                    this.state.needed !== 0 ?
                        <td style={{ width: 250 }}><div className="__co__booking_btn" onClick={bookManually.bind(this,this.state.data.product, this.state.data.productGroup, this.state.needed,this.state.available,this.state.expectedAvailable)}>Book manually</div></td>
                    :
                        <td style={{ width: 250 }}><div className="__co__booking_btn __co_booking_disabled_btn">Book manually</div></td>

                }
                <td style={{ width: 250 }}>
                {
                    this.state.booked !== 0 ?
                        <div className="__co_cancel_booking_btn" onClick={cancelBooking.bind(this,this.state.data.product)}>Cancel booking</div>
                    :
                        <div className="__co_cancel_booking_btn __co_booking_disabled_btn">Cancel booking</div>
                }
                </td>
            </tr>
        );
    }
}

export default BookingItemListing;