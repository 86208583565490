import React from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'
import * as Bi from 'react-icons/bi'
import Pagination from "react-js-pagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import Loading from '../../../components/Loading';
import TableLoading from '../../../helper/TableLoading';
import * as Fi from "react-icons/fi";
import {
    ImArrowDown2,
    ImArrowUp2
} from "react-icons/im";

export default class CriticalOnHand extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            responseItems: [],
            responseUnitOfMeasure: [],

            partNo: "",
            partDescription: "",
            groupNumber: "",
            groupName: "",
            UoM: "",
            minValue: "",
            maxValue: "",
            note: "",

            minInStock: "",
            maxInStock: "",
            minAvailable: "",
            maxAvailable: "",
            minExpectedAvailable: "",
            maxExpectedAvailable: "",
            minBooked: "",
            maxBooked: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
        this.getUnitOfMeasurement()
    }
    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseItems.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ responseItems: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("criticalOnHand_FilterData"))
        if (filterData !== null) {
            this.setState({
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage
            }, () => {
                this.getItemList()
            })
        } else {
            this.getItemList()
        }
    }

    setFilterData = () => {
        let filterDataArray = {
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage
        }
        localStorage.setItem("criticalOnHand_FilterData", JSON.stringify(filterDataArray))
    }


    getItemList = () => {
        let {
            partNo,
            partDescription,
            groupNumber,
            groupName,
            UoM,
            minValue,
            maxValue,
            note,
            start,
            length,
            minInStock,
            maxInStock,
            minAvailable,
            maxAvailable,
            minExpectedAvailable,
            maxExpectedAvailable,
            minBooked,
            maxBooked
        } = this.state

        this.setState({ isLoading: true, error: false })
        APIManager.callGetCriticalOnHandItem(
            partNo, partDescription, groupNumber, groupName, UoM, minValue, maxValue, note, start, length, minInStock, maxInStock, minAvailable, maxAvailable, minExpectedAvailable, maxExpectedAvailable, minBooked, maxBooked
        )
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseItems: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()

                    if (this.state.fieldName) {
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }



    getUnitOfMeasurement = () => {
        this.setState({ error: false })
        APIManager.callGetAllUnitOfMeasurement()
            .then(response => {
                this.setState({
                    responseUnitOfMeasure: response
                })
            })
            .catch(errors => {
                this.setState({
                    error: false,
                    errormessage: errors
                })
            });

    }
    handlePageChange(pageNumber) {


        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getItemList()
        });
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }



    addItem = () => {
        let slug = "Stock_Items"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createitem"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editItem = (_id) => {

        localStorage.setItem("itemId", _id)
        let slug = "Stock_Items"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/edititem"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }

    exportAsPDF = () => {
        let responseData = this.state.responseItems;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Critical on Hands";

        const headers = [[
            'Article Code',
            'Part Description',
            'Group Number',
            'Group Name',
            'In Stock',
            'Available',
            'Booked',
            'Expected, Available',
            'Reorder Point',
            'Cost'
        ]];

        const data = responseData.map((data, i) => [
            data.itemCode,
            data.partDescription,
            data.groupNumber,
            data.groupName,
            data.inStock ,
            data.available, 
            data.booked,
            data.expectedAvailable,
            data.minQuantity,
            data.sellingPrice
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Critical on Hands.pdf")
    }


    exportAsCSV = () => {
        let responseData = this.state.responseItems;
        const title = "Items";
        const headers = [[
            'Article Code',
            'Part Description',
            'Group Number',
            'Group Name',
            'In Stock',
            'Available',
            'Booked',
            'Expected Available',
            'Reorder Point',
            'Cost'
        ]];

        const data = responseData.map((data, i) => [
            data.itemCode,
            data.partDescription,
            data.groupNumber,
            data.groupName,
            data.inStock ,
            data.available, 
            data.booked,
            data.expectedAvailable,
            data.minQuantity,
            data.sellingPrice
        ]);


        const options = {
            filename: 'Critical on Hands',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    downloadSampleCSV = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDownloadSampleCSV()
            .then(response => {
                //Build a URL from the file
                const fileURL = APIManager.BASE_URL + '/public' + response;
                //Open the URL on new Window      
                this.setState({
                    isLoading: false,
                }, () => {
                    this.openInNewTab(fileURL)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isCriticalOnHand={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.Stock.CriticalOnHand}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="button">Import from CSV <ImArrowUp2 /></div>
                                    <div className="button" onClick={() => this.downloadSampleCSV('csv')}>Sample CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader"><span>No</span></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('itemCode', 'notDate')}><span>Article Code</span> {this.state.fieldName === "itemCode" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "itemCode" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partDescription', 'notDate')}><span>Part description</span> {this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupNumber', 'notDate')} style={{ width: 110 }}><span>Group number</span>{this.state.fieldName === "groupNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupName', 'notDate')}><span>Group name</span> {this.state.fieldName === "groupName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('inStock', 'notDate')}><span>In stock</span> {this.state.fieldName === "inStock" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "inStock" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('available', 'notDate')}><span>Available</span> {this.state.fieldName === "available" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "available" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('booked', 'notDate')}><span>Booked</span> {this.state.fieldName === "booked" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "booked" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('expectedAvailable', 'notDate')}><span>Expected, available</span> {this.state.fieldName === "expectedAvailable" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "expectedAvailable" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('minQuantity', 'notDate')}><span>Reorder point</span> {this.state.fieldName === "minQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "minQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('sellingPrice', 'notDate')}><span>Cost</span> {this.state.fieldName === "sellingPrice" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "sellingPrice" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                </tr>

                                {/* <tr>
                                <th className="tableElemContain"></th>
                                <th className="tableElemContain"><input type="text" value={this.state.partNo} onChange={(e)=>this.setState({ partNo: e.target.value},()=> { this.getItemList()})} /></th>
                                <th className="tableElemContain"><input type="text" value={this.state.partDescription} onChange={(e)=>this.setState({ partDescription: e.target.value},()=> { this.getItemList()})} /></th>
                                <th className="tableElemContain"><input type="text" value={this.state.groupNumber} onChange={(e)=>this.setState({ groupNumber: e.target.value},()=> { this.getItemList()})} /></th>
                                <th className="tableElemContain"><input type="text" value={this.state.groupName} onChange={(e)=>this.setState({ groupName: e.target.value},()=> { this.getItemList()})} /></th>
                                <th className="items ElemContain">
                                    <input type="text" style={{width: 50}} placeholder="min" onChange={(e)=>this.setState({ minInStock: parseInt(e.target.value) ? parseInt(e.target.value) : "" },()=> { this.getItemList() })} />&nbsp;-&nbsp; 
                                    <input type="text" style={{width: 50}} placeholder="max" onChange={(e)=>this.setState({ maxInStock: parseInt(e.target.value) ? parseInt(e.target.value) : "" },()=> { this.getItemList() })}/>
                                </th>
                                <th className="items ElemContain">
                                    <input type="text" style={{width: 50}} placeholder="min" onChange={(e)=>this.setState({ minAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, ()=> { this.getItemList() })} />&nbsp;-&nbsp; 
                                    <input type="text" style={{width: 50}} placeholder="max" onChange={(e)=>this.setState({ maxAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, ()=> { this.getItemList() })} />
                                </th>
                                <th className="items ElemContain">
                                    <input type="text" style={{width: 50}} placeholder="min" onChange={(e)=>this.setState({ minBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, ()=> { this.getItemList() })} />&nbsp;-&nbsp; 
                                    <input type="text" style={{width: 50}} placeholder="max" onChange={(e)=>this.setState({ maxBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, ()=> { this.getItemList() })} />
                                </th>
                                <th className="items ElemContain">
                                    <input type="text" style={{width: 50}} placeholder="min" onChange={(e)=>this.setState({ minExpectedAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, ()=> { this.getItemList() })} />&nbsp;-&nbsp; 
                                    <input type="text" style={{width: 50}} placeholder="max" onChange={(e)=>this.setState({ maxExpectedAvailable: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, ()=> { this.getItemList() })} />
                                </th>
                                <th className="items ElemContain">
                                    <input type="text" style={{width: 50}} placeholder="min" value={this.state.minValue} onChange={(e)=>this.setState({ minValue: parseInt(e.target.value) ? parseInt(e.target.value) : ""},()=> { this.getItemList()})} />&nbsp;-&nbsp;
                                    <input type="text" style={{width: 50}} placeholder="max" value={this.state.maxValue} onChange={(e)=>this.setState({ maxValue: parseInt(e.target.value) ? parseInt(e.target.value) : ""},()=> { this.getItemList()})} />
                                </th>
                                <th className="tableElemContain">
                                    <select style={{minWidth: 100}}>
                                        <option value=""></option>
                                        {
                                            this.state.responseUnitOfMeasure.map((data, i) => (
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))
                                        }
                                    </select>
                                </th>
                                <th className="items ElemContain" style={{ width: 150 }}>
                                    <input type="text" style={{width: 65}} placeholder="min"/>&nbsp;-&nbsp; 
                                    <input type="text" style={{width: 65}} placeholder="max"/>
                                </th>
                                <th className="tableElemContain tableAddButton"><div className="cursor-pointer"><FiX /></div></th>
                                
                            </tr>  */}
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseItems !== null &&
                                            this.state.responseItems.map((data, i) => (
                                                <tr key={data._id} onClick={this.editItem.bind(this, data._id)}>
                                                    <td>{this.state.activePage === 1 ? i + 1 : this.state.start + 1 + i}</td>
                                                    <td style={{ width: 100 }} className="text-uppercase">{data.itemCode}</td>
                                                    <td>{data.partDescription}</td>
                                                    <td style={{ width: 90 }} className="text-uppercase">{data.groupNumber}</td>
                                                    <td>{data.groupName}</td>
                                                    <td style={{ color: "red", width: 100 }}>{data.inStock} {data.UoM}</td>
                                                    <td>{data.available} {data.UoM}</td>
                                                    <td>{data.booked} {data.UoM}</td>
                                                    <td>{data.expectedAvailable} {data.UoM}</td>
                                                    <td>{data.minQuantity} {data.UoM}</td>
                                                    <td>{data.sellingPrice} <Bi.BiRupee /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}