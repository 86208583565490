import React from 'react';
import { Header, StockHeader } from './../../../components'

import Helper from './../../../helper/helper'

import { validateEmail } from '../../../constants/Validate';

import {
    AiOutlineExclamation
} from "react-icons/ai";
import { WEBSERVICES_URL } from '../../../APIManager/APIManager';

export default class CreateVendorContact extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            title: "",

            id: "",
            name: "",
            phone: "",
            email: "",
            fax: "",
            address: "",
            addinfo: "",
        }
    }

    componentDidMount(){
       
        let number = localStorage.getItem("vendorNumber")
        let name = localStorage.getItem("vendorName")

        let id = localStorage.getItem("purchaseTermId")
        this.setState({ 
            title: number.toUpperCase() +" "+name,
            id: id
        })
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    createVendorContact = () => {
        let Name = this.state.name
        let Phone = this.state.phone
        let Email = this.state.email
        let Fax = this.state.fax
        let Address = this.state.address
        let Addinfo = this.state.addinfo

        if(Name === ""){
            this.setState({
                error: true,
                errormessage: "Please enter name",
            })
        }else if(!validateEmail(Email)){
            this.setState({
                error: true,
                errormessage: "Please enter valid email address"
            })
            return false;
        }else{
            let that = this;
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token
    
            let myHeaders = new Headers();
            myHeaders.append("token", token);
    
            let formdata = new FormData();
            formdata.append("id", this.state.id);

            formdata.append("name",Name);
            formdata.append("phone",Phone);
            formdata.append("email",Email);
            formdata.append("fax",Fax);
            formdata.append("address",Address);
            formdata.append("additionalInformation",Addinfo);
    
            fetch(WEBSERVICES_URL+'stock/item/purchaseTerm/addPurchaseTermContacts',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata
                })
                .then(res => res.json())
                .then((result) => {
                    if(result.status === "success"){
                        localStorage.setItem("createNewPurchaseTermsContact", true)
                        localStorage.setItem("purchaseTermContactId", result.data)
                        this.props.history.push('/editpurchasetermcontact')
                    }else if(result.status === "userDataError"){
                        that.logout()
                    }else{
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                    }
                },(error) => {
                    console.log(error)
                }
            )
        }
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isItems={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Add contact to vendor <span className="text-capitalize">{this.state.title}</span></div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createVendorContact()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Vendor </div>
                                <div className="value companyname text-capitalize">{this.state.title}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Name * </div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Phone</div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.phone} onChange={(e)=>this.setState({ phone: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">E-mail</div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.email} onChange={(e)=>this.setState({ email: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Fax</div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.fax} onChange={(e)=>this.setState({ fax: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Address</div>
                                <div className="value"><textarea className="fieldheightdecr" onChange={(e)=>this.setState({ address: e.target.value })}/></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Additional information</div>
                                <div className="value"><textarea className="fieldheightdecr" onChange={(e)=>this.setState({ addinfo: e.target.value })}/></div>
                                <div className="clear"></div>
                            </div>

                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createVendorContact()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}