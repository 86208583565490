
export const getLS = nm => {
    let ls = localStorage.getItem(nm)
    if(ls === null)
        return "";
    else
        return ls
}

export const setLS = (nm, vl) => {
    let ls = localStorage.setItem(nm, vl)
    if(ls)
        return true
    else
        return false
}

export const removeLS = (vl) => {
    localStorage.removeItem(vl)
    return ""
}

export const setLocalForm = (form, field, value) => {

    let group = localStorage.getItem(form)

    if(group !== null){
        let dummyObj = JSON.parse(group)
        dummyObj[field] = value

        let ls = localStorage.setItem(form, JSON.stringify(dummyObj))
        
        if(ls)
            return true
        else
            return false
    }else{
        let dummyObj = {
            "productGroup": "",
            "product": "",
            "quantity": "",
            "dueDate": "",
            "startDate": "",
            "finishDate": "",
            "assignedTo": ""
        }

        dummyObj[field] = value

        let ls = localStorage.setItem(form, JSON.stringify(dummyObj))
        
        if(ls)
            return true
        else
            return false
    }
}


export const getLocalForm = (form, field) => {
   let group = localStorage.getItem(form)
   if(group !== null){
        group = JSON.parse(group)
        if(group[field] === ""){
            return ""
        }else{
            return group[field]
        }
   }else{
       return ""
   }
}


export const setLocalFormItem = (form, field, value) => {

    let group = localStorage.getItem(form)

    if(group !== null){
        let dummyObj = JSON.parse(group)
        dummyObj[field] = value

        let ls = localStorage.setItem(form, JSON.stringify(dummyObj))
        
        if(ls)
            return true
        else
            return false
    }else{
        let dummyObj = {
            "partDesc": "",
            "hsCode": "",
            "itemCode": "",
            "productGroup": "",
            "measurement": "",
            "isProcure": "",
            "isSellable": "",
            "sellingPrice": "",
            "tax": "",
            "storageLocation": "",
            "minQuantity": "",
            "maxQuantity": "",
            "freetext": ""
        }

        dummyObj[field] = value

        let ls = localStorage.setItem(form, JSON.stringify(dummyObj))
        
        if(ls)
            return true
        else
            return false
    }
}

export const getLocalFormItem = (form, field) => {
    let group = localStorage.getItem(form)
    if(group !== null){
         group = JSON.parse(group)
         if(group[field] === ""){
             return ""
         }else{
             return group[field]
         }
    }else{
        return ""
    }
 }


export const setLocalFormCustomer = (form, field, value) => {

    let group = localStorage.getItem(form)

    if(group !== null){
        let dummyObj = JSON.parse(group)
        dummyObj[field] = value

        let ls = localStorage.setItem(form, JSON.stringify(dummyObj))
        
        if(ls)
            return true
        else
            return false
    }else{
        let dummyObj = {
            "cmpName":"",
            "cmpNickName":"",
            "cmpStatus":"",
            "cmpPanNo":"",
            "gstNumber":"",
            "industrytype":"",
            "contactStarted":"",
            "nextContact":"",
            "paymentDaysFrom":"",
            "paymentStatus":"",
            "cmpDeliveryTerms":"",
            "creditLimitVal":"",
            "selectedAccountManager": ""
        }

        dummyObj[field] = value

        let ls = localStorage.setItem(form, JSON.stringify(dummyObj))
        
        if(ls)
            return true
        else
            return false
    }
}

export const getLocalFormCustomer = (form, field) => {
    let group = localStorage.getItem(form)
    if(group !== null){
         group = JSON.parse(group)
         if(group[field] === ""){
             return ""
         }else{
             return group[field]
         }
    }else{
        return ""
    }
}