import React, { Component } from 'react';
import * as APIManager from './../../../../APIManager/APIManager'

import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'

class ItemPriceCalc extends Component {

    constructor(props){
        super(props)
       

        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",
            fieldNotEditable: true,

            checkIncompleteFields: this.props.checkIncompleteFields,

            grossProfit: 0,
            maxDiscount: 0
        }
    }

    componentDidMount(){
        this.getPercentages()
    }

    getPercentages = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetItemPriceCalculation()
        .then(response => {
            this.setState({ 
                isLoading: false,
                grossProfit: response.grossProfit,
                maxDiscount: response.maxDiscount
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });  
    }

    


    updatePercentages = () => {
        if(this.state.fieldNotEditable !== true){
            this.setState({ isLoading: true, error: false })
            APIManager.callSetItemPriceCalculation(this.state.grossProfit, this.state.maxDiscount)
            .then(response => {
                localStorage.setItem('completionDetails',JSON.stringify(response.completionDetails))
                this.setState({ 
                    isLoading: false,
                    fieldNotEditable: true,
                    success: true,
                    successmessage: "Percentages updated successfully"
                },()=>{
                    this.state.checkIncompleteFields()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });  
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }


    render() {
        return (
            <div className="add_inner">
                <div className="value">
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div>
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div>
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="position-relative width30">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Gross Profit
                                </td>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="text" 
                                            value={this.state.grossProfit}
                                            onChange={(e)=>{
                                                if(e.target.value === "" || (e.target.value >= 0 && e.target.value < 100)){
                                                    this.setState({ grossProfit: e.target.value})
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td>
                                    %
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Max Discount
                                </td>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="text" 
                                            value={this.state.maxDiscount}
                                            onChange={(e)=>{
                                                if(e.target.value === "" || (e.target.value >= 0 && e.target.value < 100)){
                                                    this.setState({ maxDiscount: e.target.value})
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                <td>
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {
                        this.state.fieldNotEditable &&
                        <div className="disableFieldCover"></div>
                    }
                    </div>
                    <div className="mt-2">
                        <div className="add_btnSave" onClick={()=>this.updatePercentages()}>{this.state.fieldNotEditable ? "Edit" : "Save"}</div>                        
                        <div className="clear"></div>
                    </div>
                </div>
                
                <div className="clear"></div>
            </div>
        );
    }
}

export default ItemPriceCalc;