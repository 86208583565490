import React from 'react';


import { Header, CRMHeader } from './../../../components'


import {
    AiOutlineExclamation
} from "react-icons/ai";



export default class EditProductGroup extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            searchval: "",

        }
    }

    componentDidMount(){
       
    }
    
    find = () => {
        if(this.state.searchval !== ""){
            localStorage.setItem("advanceSearchNote", this.state.searchval)
            this.goBack()
        }else{
            this.goBack()
        }
    }
    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true}/>
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Advanced search</div>
                            </div>
                        </div>
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="tableContent">
                        
                        <div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Find in notes </div>
                                    <div className="value"><input type="text" className="fieldheightdecr" value={this.state.searchval} onChange={(e)=>this.setState({ searchval: e.target.value })}></input></div>
                                    <div className="clear"></div>
                                </div>
                                
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnSave" onClick={()=>this.find()}>Find</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}