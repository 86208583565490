import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import * as Ai from "react-icons/ai";

import BookingItemListing from './BookingItemListing';

class BookCustomerOrderItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            customer_order_number: "",
            customer_order_id: "",

            customer: "",
            deliverydate: "",
            reference: "",
            internalNotes: "",

            productGroupData: [],
            itemDetails: [],
            afterBookingData: [],
            uom: "",

            totalNeeded: 0,
            totalAvailable: 0,
            totalExpectedAvailable: 0,
            disabledBookAllBtn: false,

            checkCreateMo: false,
            checkCreatePo: false,

        }
        this.bookThisItem = this.bookThisItem.bind(this)
        this.cancelThisItem = this.cancelThisItem.bind(this)
    }


    componentDidMount(){
        let orderId = localStorage.getItem("customerOrderId")
        let orderNumber = localStorage.getItem("customerOrderNumber")
        this.setState({
            customer_order_number: orderNumber,
            customer_order_id: orderId, 
        },()=>{
            this.getBookingList(orderId)
        })
    }

    getBookingList = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetBookingList(id)
        .then(response => {

            this.setState({ 
                isLoading: false,
                deliverydate: response.customerOrderDetail.deliveryDate,
                reference: response.customerOrderDetail.reference,
                internalNotes: response.customerOrderDetail.internalNotes,

                productGroupData: response.customerOrderDetail.productGroup,
                itemDetails: response.itemArray,
                afterBookingData: response.afterBooking
            },()=>{
                this.getCustomerName(response.customerOrderDetail.customer)
                this.countAllDataProductGroup()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Stock is not available, please create PO or MO" 
            })
        });
    }

    countAllDataProductGroup = () => {
        let { itemDetails } = this.state;
        let needed = 0;
        let available = 0;
        let expectedAvailable = 0;
        let isDisabled = false

        if(itemDetails.length > 0){
            for(let i=0;i<itemDetails.length;i++){
                needed += itemDetails[i].needed
                available += itemDetails[i].available
                expectedAvailable += itemDetails[i].expectedAvailable

                if(itemDetails[i].needed === 0){
                    isDisabled = true;
                }
            }
            this.setState({
                disabledBookAllBtn: isDisabled
            })
        }
        

        this.setState({
            totalNeeded: needed,
            totalAvailable: available,
            totalExpectedAvailable: expectedAvailable,
        })
    }

    getCustomerName = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                customer: response.companyData.companyName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    bookThisItem = (product, productGroup, needed, available, expectedAvailable) => {
        let totalAvailable = parseInt(available) + parseInt(expectedAvailable)
        if(totalAvailable >= needed){
            localStorage.setItem("itemId", product)
            localStorage.setItem("itemGroupId", productGroup)
            localStorage.setItem("itemNeeded", needed)
            this.props.history.push("/addbooking")
        }else{
            this.setState({
                success: false,
                error: true,
                errormessage: "There is no stock available for this item"
            })
        }
    }

    cancelThisItem = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCancelSingle(this.state.customer_order_id,id)
        .then(response => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.goBack()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    bookAllItems = () => {
        let totalItem = this.state.totalExpectedAvailable + this.state.totalAvailable
        let totalNeeded = this.state.totalNeeded

        if(totalNeeded === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "All items are already booked"
            })
        }else if(totalItem >= totalNeeded){
            var r = window.confirm("Are you sure you want to book all item?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callBookingAll(this.state.customer_order_id)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            this.setState({
                success: false,
                error: true,
                errormessage: "There are one or more item's stock is not available"
            })
        }
    }

    cancelAllItems = () => {
        var r = window.confirm("Are you sure you want to cancel all item?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callCancelAllBooking(this.state.customer_order_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }
  

    goBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomerOrder={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Book goods for customer order <span className="text-uppercase">{this.state.customer_order_number}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="__co__booking__main_content">
                       
                        <div>
                            <div>
                                <div className="pb-1">
                                    <div className="float-left col-lg-1 p-0">Customer:</div>
                                    <div className="float-left text-capitalize">{this.state.customer_order_number} {this.state.customer}</div>
                                    <div className="clear"></div>
                                </div>

                                <div className="pb-1">
                                    <div className="float-left col-lg-1 p-0">Due date:</div>
                                    <div className="float-left">{this.state.deliverydate}</div>
                                    <div className="clear"></div>
                                </div>

                                <div className="pb-1">
                                    <div className="float-left col-lg-1 p-0">Reference:</div>
                                    <div className="float-left">{this.state.reference}</div>
                                    <div className="clear"></div>
                                </div>

                                <div className="pb-1">
                                    <div className="float-left col-lg-1 p-0">Internal notes:</div>
                                    <div className="float-left">{this.state.internalNotes}</div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="tableContent __co__booking_item_list" style={{ overflowX : "auto" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 175 }}>Item</th>
                                            <th style={{ width: 100 }}>Needed</th>
                                            <th style={{ width: 100 }}>In stock</th>
                                            <th style={{ width: 100 }}>Available</th>
                                            <th style={{ width: 100 }}>Booked</th>
                                            <th style={{ width: 140 }}>Expected, available</th>
                                            <th style={{ width: 110 }}>After booking</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.productGroupData.map((data, i) => (
                                            <BookingItemListing
                                                key={'booking_list__'+i}
                                                number={'booking_list_'+i}
                                                data={data}
                                                itemDetails={this.state.itemDetails[i]}
                                                afterBookingData={this.state.afterBookingData[i]}
                                                bookManually={this.bookThisItem}
                                                cancelBooking={this.cancelThisItem}
                                            />
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="pb-1 __co__booking_item_list">
                                <div className="__co_book_checkboxe">
                                    <input 
                                        type="checkbox" 
                                        id="createmo" 
                                        name="createmo" 
                                        checked={this.state.checkCreateMo} 
                                        onChange={()=>
                                            {
                                                this.setState({ 
                                                    checkCreateMo: !this.state.checkCreateMo
                                                },()=>{
                                                    this.setState({
                                                        checkCreatePo: this.state.checkCreateMo === false ? false : this.state.checkCreatePo
                                                    })
                                                })
                                            } 
                                        }
                                    />
                                    <label htmlFor="createmo">Create MO's for missing products</label>
                                    <div className="clear"></div>
                                </div>
                                <div className="__co_book_checkboxe position-relative">
                                    <input type="checkbox" id="createpo" name="createpo" checked={this.state.checkCreatePo} onChange={()=>this.setState({ checkCreatePo: !this.state.checkCreatePo})} />
                                    <label htmlFor="createpo">Create PO's for missing products</label>
                                    <div className="clear"></div>
                                    {
                                        this.state.checkCreateMo !== true &&
                                            <div className="disableFieldCover"></div>
                                    }
                                </div>
                            </div> */}
                        </div>
                        
                        
                        
                        <div className="mt-2">
                            <div className="__co__booking_back_btn __co_booking_bottom_button float-left" onClick={()=>this.goBack()}>Back</div>
                               <div>
                                    {
                                        this.state.disabledBookAllBtn ?
                                            <div className="__co__booking_btn __co_booking_bottom_button float-left __co_booking_disabled_btn">Book all items</div>
                                        :
                                            <div className="__co__booking_btn __co_booking_bottom_button float-left" onClick={()=>this.bookAllItems()}>Book all items</div>
                                    }
                                    {
                                        this.state.totalNeeded === 0 ?
                                            <div className="__co_cancel_booking_btn __co_booking_bottom_button float-left" onClick={()=>this.cancelAllItems()}>Cancel all bookings</div>
                                        :
                                            <div className="__co_cancel_booking_btn __co_booking_bottom_button float-left __co_booking_disabled_btn">Cancel all bookings</div>
                                    }
                                </div>
                           
                           <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BookCustomerOrderItem;


