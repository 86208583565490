import React, { Component } from 'react';
import AddExpenseDetails from './AddExpenseDetails';
import * as APIManager from './../../../../APIManager/APIManager'

class ExpenseGroupGST extends Component {

    constructor(props){
        super(props)
        this.state = {

            callBackFunction: this.props.callBackFunction,

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: [],
            isGst: this.props.isGst,
            expenseCategoryData: [],

            subtotal: "",
            grandtotal: ""
        }    
        this.addNewGroupFields = this.addNewGroupFields.bind(this)
        this.addGroupFieldValues = this.addGroupFieldValues.bind(this)
    }

    componentDidMount() {
        this.getExpenseCategories()
    }

    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
        .then(response => {
            let category = response.expenseCategory ? response.expenseCategory : []
            this.setState({
                isLoading: false,
                expenseCategoryData: category
            },()=>{
                this.setGroupFields()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.setGroupFields()
            })
        });
    }

    setGroupFields = () => {
        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <AddExpenseDetails
                    isGst={this.state.isGst}
                    key={'proGroup'} 
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={this.state.productGroupCounter}
                    expenseCategoryData={this.state.expenseCategoryData}
                />
            ]),
        })
    }

    addNewGroupFields = (counter) => {
        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <AddExpenseDetails    
                        isGst={this.state.isGst}                 
                        key={'proGroup'+this.state.productGroupCounter}
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter+1}
                        expenseCategoryData={this.state.expenseCategoryData}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: this.state.productGroupCounter+1,
            },()=>{
                this.setCounterText()
            })
        }
    }

    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        },()=>{
            localStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
            this.setCounterText()
        })
    }

    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        },()=>{
            localStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
        })
    }

    setCounterText = () => {
        var slides = document.getElementsByClassName("subgroupdata");
        for (var i = 0; i < slides.length; i++) {
            slides[i].firstChild.innerHTML = '<td>'+(i+1)+'</td>';
        }
    }

    countTotal = () => {
        let arr = this.state.productGroupData;
        let subtotal = 0;
        let grandtotal = 0;
        for(let i=0;i<arr.length;i++){
            if(arr[i] !== null){
                subtotal += arr[i].amount !== "" ? parseFloat(arr[i].amount) : 0
                grandtotal += arr[i].total !== "" ? parseFloat(arr[i].total) : 0 
            }
        }
        this.setState({
            subtotal: subtotal.toFixed(2),
            grandtotal: grandtotal.toFixed(2)
        },()=>{
            this.state.callBackFunction(this.state.grandtotal,this.state.subtotal,this.state.productGroupData)
        })
    }

    render() {
        return (
            <div className="main-container mt-20 order_product_group">
                <table>
                    <thead>
                        <tr>
                            <td></td> 
                            <td style={{ width: 150 }}>Category</td>
                            <td style={{ width: 200 }}>Description Of Service</td>
                            <td>HSN Code</td>
                            <td>Amount</td>
                            {
                                this.state.isGst &&
                                <>
                                <td style={{ width: 70 }}>Tax(%)</td>
                                <td>SGST</td>
                                <td>CGST</td>
                                <td>IGST</td>
                                <td>Eligible Tax</td>
                                </>
                            }
                           
                            <td>Total</td>
                            <td>Date of expense</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                           this.state.productGroupFields
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td><b>Total</b></td>
                            <td></td>
                            <td><b>{this.state.subtotal}</b></td>
                            {
                                this.state.isGst &&
                                <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                </>
                            }
                            <td><b>{this.state.grandtotal}</b></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ExpenseGroupGST;