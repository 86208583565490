import React from 'react';


import * as Fi from "react-icons/fi";


export default class GenerateNewComponent extends React.Component{

    constructor(props){
        super(props);   
        this.state = {
            newComponent: this.props.newComponent,
        }
    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td>
                    <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.newComponent}
                        onChange={(e)=>this.setState({ newComponent: e.target.value},()=>{
                            onEdit(this.state.newComponent, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.newComponent, componentNum)
                        }
                    />
                </td>
                <td>
                    {
                        this.state.newComponent !== "" &&
                            <Fi.FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}