import React, { Component } from 'react';
import * as APIManager from './../../APIManager/APIManager'

import { 
    AiOutlineClose,
    AiOutlineExclamation,
    AiOutlineCalendar
} from 'react-icons/ai'

import {
    FiPlus
} from 'react-icons/fi'

import DatePicker from "react-datepicker";

import AssignUserField from './AssignUserField';

class AddTask extends Component {

    constructor(props){
        super(props)
        this.state = {
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            closetask: this.props.closetask,
            editTaskId: this.props.editTaskId,

            userRole: JSON.parse(localStorage.getItem("userdata")).role,
            userId: JSON.parse(localStorage.getItem("userdata"))._id,

            subject: "",
            duedate: "",
            description: "",
            author: "",
            notes: [],
            isDone: false,
            assignedName: "",
            assignedId: "",

            disabled: false,

            countFieldNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,
        }
    }

    componentDidMount = () => {
        this.getSingleTask()
    }

    getSingleTask = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleTask(this.state.editTaskId)
        .then(response => {
            
            this.setState({ 
                isLoading: false,
                subject: response.subject,
                duedate: new Date(response.dueDate),
                description: response.description,
                author: response.author,
                notes: response.notes,
                isDone: response.is_done,
                assignedName: response.assignedName,
                assignedId: response.assignedId,
                disabled: this.state.userId === response.creatorId ? false : true
            },()=>{
                if(this.state.userId !== response.creatorId){
                    this.readThisTask()
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });   
    }

    readThisTask = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callUpdateIsReadTask(this.state.editTaskId)
        .then(response => {
            this.setState({ 
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    // setAllFields = (data) => {
    //     if(data.length > 0){
    //         let localFieldNumber = []
    //         let localExtraField = []

    //         for(let i=0;i<data.length;i++){
    //             localFieldNumber.push(i)
    //             localExtraField.push(
    //                 <AssignUserField 
    //                     key={i}
    //                     componentNum={i}
    //                     tablefield={data[i]}
    //                     onBlur={this.assignFieldBlur}
    //                     onEdit={this.assignFieldEdit}
    //                     onRemove={this.assignFieldRemove}
    //                 />
    //             )
    //         }

    //         this.setState({
    //             countFieldNumber: localFieldNumber,
    //             contactFieldCounter: data.length,
    //             addTypeFields: localExtraField,
    //             typeFieldValues: data,
    //             count: data.length
    //         },()=>{
    //             this.setNewField(data.length)
    //         })
    //     }else{
    //         this.addNewField()
    //     }
    // }

    // setNewField = (len) => {
    //     this.setState({
    //         count: len + 1,
    //         generateNewType: true,
    //     }, () => {
    //         this.setState({
    //             countFieldNumber: this.state.countFieldNumber.concat(
    //                 [this.state.count]
    //             ),
    //             addTypeFields: this.state.addTypeFields.concat(
    //                 [
    //                     <AssignUserField 
    //                         key={this.state.count}
    //                         componentNum={this.state.count}
    //                         tablefield={""}
    //                         onBlur={this.assignFieldBlur}
    //                         onEdit={this.assignFieldEdit}
    //                         onRemove={this.assignFieldRemove}
    //                     />
    //                 ]
    //             ),
    //         })
    //     })
    // }

    // assignFieldEdit = (fields, num) => {

    //     let array = [...this.state.countFieldNumber];
    //     let index = array.indexOf(num)
    //     let statusVal = [...this.state.typeFieldValues]     
    

    //     if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && fields !== "") {
    //         this.setState({
    //             count: this.state.count + 1,
    //             generateNewType: false,
    //         }, () => {
                
    //             this.setState({
    //                 countFieldNumber: this.state.countFieldNumber.concat(
    //                     [this.state.count]
    //                 ),
    //                 addTypeFields: this.state.addTypeFields.concat(
    //                     [
    //                         <AssignUserField 
    //                             key={this.state.count}
    //                             componentNum={this.state.count}
    //                             tablefield={""}
    //                             onBlur={this.assignFieldBlur}
    //                             onEdit={this.assignFieldEdit}
    //                             onRemove={this.assignFieldRemove}
    //                         />
    //                     ]
    //                 ),
    //             })
    //         })
    //     }
    // }

    // assignFieldBlur = (fields, num) => {
    //      let array = [...this.state.countFieldNumber];
    //      let index = array.indexOf(num)
    //      let statusVal = [...this.state.typeFieldValues]   
 
    //      if (typeof statusVal[index] !== 'undefined') {
            
    //          if (fields !== "") {
    //              let obj = fields
                 
    //              let typeFieldValues = [ ...this.state.typeFieldValues ];
    //              typeFieldValues[index] = obj;
 
    //              this.setState({
    //                  typeFieldValues,
    //                  generateNewType: true,
    //              })
    //          }
    //      }else{
    //          if (fields !== "") {
    //              let obj = fields
    //              this.setState({
    //                  typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
    //                  generateNewType: true,
    //              })
    //          }
    //      }
    //  }
 

    // assignFieldRemove = (number) => {
    
    //     let array = [...this.state.countFieldNumber];
    //     let fields = [...this.state.addTypeFields];
    //     let data = [...this.state.typeFieldValues]
    //     let index = array.indexOf(number)

    //     if (index !== -1) {
    //         array[index] = null
    //         fields[index] = null;
    //         data[index] = null;
            
    //         this.setState({
    //             countFieldNumber: array,
    //             addTypeFields: fields,
    //             typeFieldValues: data
    //         });
    //     }
        
    // }


    updateTask = () => {
        let {
            editTaskId,
            subject,
            duedate,
            description,
            typeFieldValues,
            isDone,
            assignedId
        } = this.state;

        if(subject === ""){
            this.setState({
                error: true,
                errormessage: "Please enter subject",
            })
        }else if(duedate === ""){
            this.setState({
                error: true,
                errormessage: "Please select duedate",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateTask(editTaskId,subject,assignedId,description,duedate,isDone)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    successmessage: "Task updated successfully",
                    success: true,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }        
    }

    deleteTask = () => {
        this.setState({ isLoading: true, error: false })
        var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
            APIManager.callDeleteTask(this.state.editTaskId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.state.closetask()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }
    }

   

    render() {
        let {
            closetask,
            addNote,
            editNote
        } = this.props
        return (
            <div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="page-title ml-4">Edit a task</div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="page-title text-right mr-4">
                            <div className="d-inline-block" onClick={closetask}><AiOutlineClose /></div>
                        </div>
                    </div>
                </div>

                {
                    this.state.error &&
                    <div className="editscreenerror">
                        <div className="errornotification">
                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                        </div>
                    </div>
                }
                {
                    this.state.success &&
                    <div className="editscreenerror">
                        <div className="successnotification">
                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                        </div>
                    </div>
                }

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={closetask}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateTask()}>Save</div>
                        {
                            this.state.disabled !== true &&
                            <div className="add_btnBack" onClick={()=>this.deleteTask()}>Delete</div>
                        }
                        <div className="clearfix"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Subject*</div>
                                <div className="value">
                                    <input type="text" disabled={this.state.disabled} value={this.state.subject} onChange={(e)=>this.setState({ subject: e.target.value })} className="fieldheightdecr" autoComplete="off" />
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Author</div>
                                <div className="value">
                                    <div className="__static_fixed_text">{this.state.author}</div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Assigned to</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-capitalize">{this.state.assignedName}</div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            

                            {/* {
                                this.state.userRole === "admin" &&
                                <div className="add_inner">
                                    <div className="title">Assigned to</div>
                                    <div className="value">
                                        <table>
                                            <tbody>
                                                {this.state.addTypeFields}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            } */}

                            

                            <div className="add_inner">
                                <div className="title">Due date</div>
                                <div className="value">
                                    <DatePicker 
                                        selected={this.state.duedate}
                                        onChange={date => this.setState({ duedate: date })}
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput
                                        minDate={new Date()}
                                        disabled={this.state.disabled}
                                    />
                                    <AiOutlineCalendar className="datepickerIcon cursor-na" /> 
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Done</div>
                                <div className="value">
                                    <input 
                                        type="checkbox"
                                        className="_other_input_auto_witdh"
                                        checked={this.state.isDone}
                                        onChange={()=> this.setState({ isDone: !this.state.isDone})}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner">
                                <div className="title">Description</div>
                                <div className="value">
                                    {/* <input type="text" value={this.state.partDesc} onChange={(e)=>this.setState({ partDesc: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" /> */}
                                    <textarea
                                        autoComplete="off" 
                                        className="fieldheightdecr item_free_text_area"
                                        value={this.state.description}
                                        onChange={(e)=>this.setState({ description: e.target.value })}
                                        disabled={this.state.disabled}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                      
                        <div className="clearfix"></div>
                        
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={closetask}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateTask()}>Save</div>
                        {
                            this.state.disabled !== true &&
                            <div className="add_btnBack" onClick={()=>this.deleteTask()}>Delete</div>
                        }
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                    <div className="footerTitle">Notes</div>
                        <table>
                            <tbody>
                            <tr>
                                <th className="tableHeader"><span>Created</span></th>
                                <th className="tableHeader"><span>Modified</span></th>
                                <th className="tableHeader w-50">Notes</th>
                                <th className="tableAddButton text-right" onClick={addNote}><FiPlus /></th>
                            </tr>
                            {
                                this.state.notes !== null &&
                                this.state.notes.map((data, i) => (
                                    <tr key={data.id} className={data.is_important ? "__imp_note": ""} onClick={editNote.bind(this,data.id,data.note)}>
                                        <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br/><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                        <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br/><span  style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                        <td className="texttop preline">{data.note}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
            </div>
        );
    }
}

export default AddTask;