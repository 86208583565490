//Find API base URL 

// export const BASE_URL = 'https://api.oemup.app'
// https://oemup.app/
// export const UPLOAD_PUBLIC = 'https://api.oemup.app/public/'
// export const WEBSERVICES_URL = 'https://api.oemup.app/api/'
// export const ReCAPTCHAKey = '6Le9EFYdAAAAAH85WFawUKXWk1vIxGrNiGNWLKwN'

// export const BASE_URL = 'http://3.110.207.222:701/'
// export const WEBSERVICES_URL = 'http://3.110.207.222:701/api/'
// export const UPLOAD_PUBLIC = 'http://3.110.207.222:701/public/'
// export const ReCAPTCHAKey = '6Lesk8UhAAAAACsMFfm7V1gfpO_SRFOjDkel47p
//ALFA Account ID : 791d2fa8 - 2f82 - 48d5 - b14c - 0e7197fe150f
//Kira Account ID Stagging : 751ee5c9 - 1be4 - 4011 - 8054 - 1a542496928a
export const BASE_URL = 'https://oemup.app:9001/'
export const WEBSERVICES_URL = 'https://oemup.app:9001/api/'
export const UPLOAD_PUBLIC = 'https://oemup.app:9001/public/'
export const ReCAPTCHAKey = '6Lesk8UhAAAAACsMFfm7V1gfpO_SRFOjDkel47pI'
export const ALFA_ACCOUNT_ID = '791d2fa8-2f82-48d5-b14c-0e7197fe150f'


const URL_LOGIN = `${WEBSERVICES_URL}login`
const URL_REGISTER = `${WEBSERVICES_URL}register`
const URL_FORGOTPASSWORD = `${WEBSERVICES_URL}forgotPassword`
const URL_RESETPASSWORD = `${WEBSERVICES_URL}forgotPasswordChange`
const URL_GET_SUB_USERS_LIST = `${WEBSERVICES_URL}getSubusersList`
const URL_GET_ACCOUNT_MANAGET_LIST = `${WEBSERVICES_URL}setting/getAccountManagerList`
const URL_GET_SUB_USERS_LIST_WITH_FILTER = `${WEBSERVICES_URL}getSubusersListWithFilter`
const URL_GET_COMPANY = `${WEBSERVICES_URL}crm/customer/getCompany`
const URL_GET_CONTACT_PERSON = `${WEBSERVICES_URL}crm/customer/getContactPerson`
const URL_GET_SINGLE_CONTACT_PERSON = `${WEBSERVICES_URL}crm/customer/getSingleCompanyContacts`

const URL_GET_DASHBOARD_DATA = `${WEBSERVICES_URL}dashboard/dashboard`
const URL_UPDATE_DASHBOARD_DATA = `${WEBSERVICES_URL}dashboard/updateDashboardSequence`
const URL_GET_PLAN = `${WEBSERVICES_URL}getPlan`
const URL_GET_COMPANY_CONTACT = `${WEBSERVICES_URL}crm/customer/getCompanyContacts`
const URL_DELETE_COMPANY = `${WEBSERVICES_URL}crm/customer/deleteCompany`
const URL_GET_PRODUCT_GROUP = `${WEBSERVICES_URL}stock/getProductGroup`
const URL_GET_SELLABLE_ITEM_GROUP = `${WEBSERVICES_URL}stock/item/getProductGroupBySellableItem`
const URL_GET_PRODUCT_BY_GROUP = `${WEBSERVICES_URL}crm/customer/customerOrder/getProductByGroup`
const URL_GET_PRODUCT_BY_GROUP_FOR_CREATE = `${WEBSERVICES_URL}crm/customer/customerOrder/getProductByGroupForCreate`
const URL_GET_PRODUCT_BY_GROUP_AND_SELLING_RPICE = `${WEBSERVICES_URL}crm/customer/customerOrder/getItemWithSellingPrice`
const URL_GET_PRODUCT_BY_GROUP_AND_SELLING_RPICE_FOR_CREATE = `${WEBSERVICES_URL}crm/customer/customerOrder/getItemWithSellingPriceForCreate`
const URL_GET_GROUP_BY_VENDOR = `${WEBSERVICES_URL}procurement/purchaseorder/getGroupByVendor`
const URL_GET_PRODUCT_BY_VENDOR = `${WEBSERVICES_URL}procurement/purchaseorder/getProductByVendor`
const URL_GET_PRODUCT_BY_VENDOR_FOR_CREATE = `${WEBSERVICES_URL}procurement/purchaseorder/getProductByVendorForCreate`
const URL_GET_VENDOR_PRODUCT_PRICE = `${WEBSERVICES_URL}stock/item/purchaseTermData`
const URL_GET_ITEM = `${WEBSERVICES_URL}stock/getItem`
const URL_GET_CRITICAL_ON_HAND_ITEM = `${WEBSERVICES_URL}stock/getCriticalOnHandItem`
const URL_GET_COMPANY_INDUSTRY = `${WEBSERVICES_URL}company/getCompanyIndustry`
const URL_GET_SINGLE_VENDOR = `${WEBSERVICES_URL}procurement/vendors/getSingleVendor`

const URL_GET_SINGLE_FILTER_TABLE = `${WEBSERVICES_URL}stock/filterTable/getSingleFilterTable`

const URL_GET_VENDOR = `${WEBSERVICES_URL}procurement/vendors/getVendor`
const URL_CREATE_PURCHASE_TERMS = `${WEBSERVICES_URL}stock/item/createPurchaseTerm`
const URL_PURCHASE_TERMS_LIST = `${WEBSERVICES_URL}stock/item/getPurchaseTerm`
const URL_PURCHASE_TERMS_DETAIL = `${WEBSERVICES_URL}stock/item/getSinglePurchaseTerm`
const URL_UPDATE_PURCHASE_TERMS = `${WEBSERVICES_URL}stock/item/updatePurchaseTerm`
const URL_DELETE_PURCHASE_TERMS = `${WEBSERVICES_URL}stock/item/deletePurchaseTerm`
const URL_PURCHASE_TERM_CONTACTS = `${WEBSERVICES_URL}stock/item/purchaseTerm/getAllPurchaseTermContacts`
const URL_GET_ORDER_STATUS = `${WEBSERVICES_URL}setting/systemsetting/getOrderStatus`
const URL_CREATE_ORDER_STATUS = `${WEBSERVICES_URL}setting/systemsetting/createOrderStatus`
const URL_GET_SHIPPING_ADDRESS = `${WEBSERVICES_URL}crm/customer/customerOrder/getShippingAddress`
const URL_GET_SHIPPING_ADDRESS_DEBT = `${WEBSERVICES_URL}procurement/purchaseorder/getShippingAddressOfVendor`
const URL_CREATE_CUSTOMER_ORDER = `${WEBSERVICES_URL}crm/customer/customerOrder/createCustomerOrder`
const URL_UPDATE_CUSTOMER_ORDER = `${WEBSERVICES_URL}crm/customer/customerOrder/updateCustomerOrder`
const URL_GET_QUOTATION_DEFAULT_MESSAGES = `${WEBSERVICES_URL}setting/quotationMessage/getQuotationText`
const URL_SET_QUOTATION_DEFAULT_MESSAGES = `${WEBSERVICES_URL}setting/quotationMessage/addQuotationText`

const URL_CREATE_CUSTOMER_QUOTATION = `${WEBSERVICES_URL}crm/customer/customerOrder/createCustomerQuotation`
const URL_UPDATE_CUSTOMER_QUOTATION = `${WEBSERVICES_URL}crm/customer/customerOrder/updateCustomerQuotation`

const URL_GET_CUSTOMER_ORDERS = `${WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrder`
const URL_GET_ALL_CUSTOMER_FOR_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/getAllCustomerOrder`
const URL_GET_CUSTOMER_ORDERS_QUOTATION = `${WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrderQuotation`
const URL_GET_CUSTOMER_EXCEPT_QUOTATION_ORDERS = `${WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrderExpectQuotation`
const URL_GET_SINGLE_CUSTOMER_ORDER = `${WEBSERVICES_URL}crm/customer/customerOrder/getSingleCustomerOrder`
const URL_CUSTOMER_ORDER_QUOTATION = `${WEBSERVICES_URL}crm/customer/customerOrder/customerOrderQuotation`
const URL_PURCHASE_ORDER_PDF = `${WEBSERVICES_URL}procurement/purchaseorder/createPDFForPurchaseOrder`
const URL_DELETE_CUSTOMER_ORDER = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteCustomerOrder`
const URL_DOWNLOAD_CUSTOMER_ORDER_DETAILS = `${WEBSERVICES_URL}crm/customer/customerOrder/createPDFForOrder`
const URL_HTML_CONTENT_FOR_ORDER_PDF = `${WEBSERVICES_URL}crm/customer/customerOrder/pdfForOrder`
const URL_DOWNLOAD_EXCELFILE_FOR_ITEM = `${WEBSERVICES_URL}stock/item/downloadExcelFileForItem`
const URL_DOWNLOAD_EXCELFILE_FOR_VENDOR = `${WEBSERVICES_URL}procurement/vendors/downloadExcelFileForVendor`
const URL_DOWNLOAD_EXCELFILE_FOR_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/downloadExcelFileForPumpRegister`
const URL_UPLOAD_EXCELFILE_FOR_ITEM = `${WEBSERVICES_URL}stock/item/importExcelItem`
const URL_UPLOAD_EXCELFILE_FOR_VENDOR = `${WEBSERVICES_URL}procurement/vendors/importExcelForVendor`
const URL_UPLOAD_EXCELFILE_FOR_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/importExcelPumpRegister`
const URL_UPLOAD_EXCELFILE_FOR_CO_ITEM = `${WEBSERVICES_URL}crm/customer/customerOrder/importCustomerOrderItemWithExcel`
const URL_FILTER_TABLE = `${WEBSERVICES_URL}stock/filterTable/getAllFilterTable`
const URL_GET_SINGLE_CUSTOM_TABLE = `${WEBSERVICES_URL}stock/filterTable/getSingleFilterTable`
const URL_GET_REVISION_LIST = `${WEBSERVICES_URL}crm/customer/customerOrder/getOrderRevisionList`
const URL_REVISION_DETAIL = `${WEBSERVICES_URL}crm/customer/customerOrder/getOrderRevisionData`
const URL_GET_SINGLE_ITEM_DATA = `${WEBSERVICES_URL}stock/getSingleItem`
const URL_GET_SINGLE_CUSTOMER = `${WEBSERVICES_URL}crm/customer/getSingleCompany`
const URL_GET_CUSTOMER_ORDER_ITEM = `${WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrderItem`
const URL_CREATE_NEW_TERMS = `${WEBSERVICES_URL}setting/createTermsCondition`
const URL_GET_ALL_TERMS = `${WEBSERVICES_URL}setting/getAllTermsCondition`
const URL_GET_SINGLE_TERM = `${WEBSERVICES_URL}setting/getSingleTermsCondition`
const URL_DELETE_TERM = `${WEBSERVICES_URL}setting/deleteTermsCondition`
const URL_UPDATE_TERM = `${WEBSERVICES_URL}setting/updateTermsCondition`
const URL_GET_COMPANY_DETAIL = `${WEBSERVICES_URL}setting/getCompanyDetails`
const URL_SET_COMPANY_DETAIL = `${WEBSERVICES_URL}setting/setCompanyDetails`
const URL_GET_PROFILE_DETAIL = `${WEBSERVICES_URL}setting/systemSetting/getSingleProfile`
const URL_GET_SINGLE_PRODUCT_GROUP = `${WEBSERVICES_URL}stock/getSingleProductGroup`

const URL_SET_PROFILE = `${WEBSERVICES_URL}setting/systemSetting/updateProfile`
const URL_CREATE_PURCHASE_ORDER = `${WEBSERVICES_URL}procurement/purchaseorder/createPurchaseOrder`
const URL_UPDATE_PURCHASE_ORDER = `${WEBSERVICES_URL}procurement/purchaseorder/updatePurchaseOrder`
const URL_SINGLE_PURCHASE_ORDER = `${WEBSERVICES_URL}procurement/purchaseorder/getSinglePurchaseOrder`

const URL_GET_INVOICE_REMAINING_ITEMS = `${WEBSERVICES_URL}procurement/purchaseorder/getRemainingQuantity`

const URL_PURCHASE_ORDER_LIST = `${WEBSERVICES_URL}procurement/purchaseorder/getPurchaseOrder`
const URL_DELETE_PURCHASE_ORDER = `${WEBSERVICES_URL}procurement/purchaseorder/deletePurchaseOrder`
const URL_CREATE_PO_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/addPurchaseOrderNotes`
const URL_UPDATE_PO_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/updatePurchaseOrderNotes`
const URL_DELETE_PO_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/deletePurchaseOrderNotes`
const URL_CREATE_CO_NOTE = `${WEBSERVICES_URL}crm/customer/customerOrder/addCustomerOrderNotes`
const URL_UPDATE_CO_NOTE = `${WEBSERVICES_URL}crm/customer/customerOrder/updateCustomerOrderNotes`
const URL_DELETE_CO_NOTE = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteCustomerOrderNotes`
const URL_CREATE_PO_PAYMENT = `${WEBSERVICES_URL}procurement/purchaseorder/addPurchaseOrderPayments`
const URL_UPDATE_PO_PAYMENT = `${WEBSERVICES_URL}procurement/purchaseorder/updatePurchaseOrderPayments`
const URL_DELETE_PO_PAYMENT = `${WEBSERVICES_URL}procurement/purchaseorder/deletePurchaseOrderPayments`
const URL_GET_SINGLE_PAYMENT = `${WEBSERVICES_URL}procurement/purchaseorder/getSinglePurchaseOrderPayments`
const URL_PO_PAYMENT_LIST = `${WEBSERVICES_URL}procurement/purchaseorder/getPurchaseOrderPayments`
const URL_PO_INVOICE_LIST = `${WEBSERVICES_URL}procurement/purchaseorder/getAllInvoiceOfPO`
const URL_CREATE_VENDOR = `${WEBSERVICES_URL}procurement/vendors/createVendor`
const URL_UPDATE_VENDOR = `${WEBSERVICES_URL}procurement/vendors/updateVendor`
const URL_CREATE_TASK = `${WEBSERVICES_URL}task/createTaskManagement`
const URL_UPDATE_TASK = `${WEBSERVICES_URL}task/updateTaskManagement`
const URL_UPDATE_IS_READ_TASK = `${WEBSERVICES_URL}task/updateIsRead`
const URL_GET_SINGLE_TASK = `${WEBSERVICES_URL}task/getSingleTaskManagement`
const URL_GET_TASKS = `${WEBSERVICES_URL}task/getTaskManagement`
const URL_DELETE_TASKS = `${WEBSERVICES_URL}task/deleteAllTaskManagement`
const URL_DELETE_SINGLE_TASK = `${WEBSERVICES_URL}task/deleteTaskManagement`
const URL_ADD_TASK_NOTE = `${WEBSERVICES_URL}task/addTaskManagementNotes`
const URL_EDIT_TASK_NOTE = `${WEBSERVICES_URL}task/updateTaskManagementNotes`
const URL_DELETE_TASK_NOTE = `${WEBSERVICES_URL}task/deleteTaskManagementNotes`
const URL_CREATE_STOCK_LOT = `${WEBSERVICES_URL}stock/stockLots/createStockLots`
const URL_UPDATE_STOCK_LOT = `${WEBSERVICES_URL}stock/stockLots/updateStockLots`
const URL_GET_SINGLE_STOCK_LOT = `${WEBSERVICES_URL}stock/stockLots/getSingleStockLots`
const URL_DELETE_STOCK_LOT = `${WEBSERVICES_URL}stock/stockLots/deleteStockLots`
const URL_GET_STOCK_LOT_LIST = `${WEBSERVICES_URL}stock/stockLots/getStockLotData`
const URL_GET_SINGLE_STORAGE_LOCATION = `${WEBSERVICES_URL}stock/stocksetting/getSingleStorageLocation`
const URL_GET_LOT_BY_STOCK_ITEM = `${WEBSERVICES_URL}stock/stockLots/getLotByStockItem`
const URL_GET_ALL_STORAGE_LOCATION = `${WEBSERVICES_URL}stock/stocksetting/getAllStorageLocation`

const URL_MOVE_STOCK_ITEM = `${WEBSERVICES_URL}stock/stockLots/moveStockItem`

const URL_GET_ALL_UNIT_OF_MEASUREMENT = `${WEBSERVICES_URL}stock/stocksetting/getAllUnitOfMeasurement`

const URL_GET_BOOKING_LIST = `${WEBSERVICES_URL}customerOrder/stockBooking/getBookingList`

const URL_GET_SINGLE_UNITOFMEASUREMENT = `${WEBSERVICES_URL}stock/stocksetting/getSingleUnitOfMeasurement`

const URL_CREATE_WRITEOFF = `${WEBSERVICES_URL}customerOrder/stockBooking/createWriteOff`
const URL_GET_SINGLE_WRITEOFF = `${WEBSERVICES_URL}customerOrder/stockBooking/getSingleWriteOff`
const URL_DELETE_WRITEOFF = `${WEBSERVICES_URL}customerOrder/stockBooking/deleteWriteOff`
const URL_RELEASE_STOCK_ITEM = `${WEBSERVICES_URL}customerOrder/stockBooking/releaseStockItem`

const URL_GET_WRITEOFF_LIST = `${WEBSERVICES_URL}customerOrder/stockBooking/getWriteOff`

const URL_CREATE_WRITEOFF_TYPE = `${WEBSERVICES_URL}setting/writeOffType/createWriteOffType`
const URL_GET_WRITEOFF_TYPE = `${WEBSERVICES_URL}setting/writeOffType/getWriteOffType`

const URL_CREATE_SUB_USER = `${WEBSERVICES_URL}setting/createSubuser`
const URL_UPDATE_SUB_USER = `${WEBSERVICES_URL}setting/updateSubUser`
const URL_GET_SINGLE_SUB_USER = `${WEBSERVICES_URL}setting/getSingleSubUser`
const URL_DELETE_SUB_USER = `${WEBSERVICES_URL}setting/deleteSubUser`

const URL_CANCEL_SINGLE_BOOKING = `${WEBSERVICES_URL}customerOrder/stockBooking/cancelSingleBooking`
const URL_BOOK_ALL_BOOKING = `${WEBSERVICES_URL}customerOrder/stockBooking/bookAllBooking`
const URL_CANCEL_ALL_BOOKING = `${WEBSERVICES_URL}customerOrder/stockBooking/cancelAllBooking`

const URL_CREATE_WORKSTATION_GROUP = `${WEBSERVICES_URL}productionPlanning/workStationGroup/createWorkStationGroup`
const URL_UPDATE_WORKSTATION_GROUP = `${WEBSERVICES_URL}productionPlanning/workStationGroup/updateWorkStationGroup`
const URL_GET_SINGLE_WORKSTATION_GROUP = `${WEBSERVICES_URL}productionPlanning/workStationGroup/getSingleWorkStationGroup`
const URL_GET_ALL_WORKSTATION_GROUP = `${WEBSERVICES_URL}productionPlanning/workStationGroup/getAllWorkStationGroup`
const URL_DELETE_WORKSTATION_GROUP = `${WEBSERVICES_URL}productionPlanning/workStationGroup/deleteWorkStationGroup`

const URL_CREATE_DEPARTMENT = `${WEBSERVICES_URL}settings/humanresources/department/createDepartment`
const URL_UPDATE_DEPARTMENT = `${WEBSERVICES_URL}settings/humanresources/department/updateDepartment`
const URL_GET_SINGLE_DEPARTMENT = `${WEBSERVICES_URL}settings/humanresources/department/getSingleDepartment`
const URL_GET_ALL_DEPARTMENT = `${WEBSERVICES_URL}settings/humanresources/department/getAllDepartment`
const URL_GET_DEPARTMENT_BY_WORKSTATION = `${WEBSERVICES_URL}productionPlanning/getDepartmentByWorkstation`

const URL_DELETE_DEPARTMENT = `${WEBSERVICES_URL}settings/humanresources/department/deleteDepartment`

const URL_GET_ALL_USERS_OF_DEPARTMENT = `${WEBSERVICES_URL}stock/item/document/getAllUserOfDepartment`

const URL_CREATE_WORKSTATION = `${WEBSERVICES_URL}productionPlanning/workStation/createWorkStation`
const URL_UPDATE_WORKSTATION = `${WEBSERVICES_URL}productionPlanning/workStation/updateWorkStation`
const URL_GET_SINGLE_WORKSTATION = `${WEBSERVICES_URL}productionPlanning/workStation/getSingleWorkStation`
const URL_GET_ALL_WORKSTATION = `${WEBSERVICES_URL}productionPlanning/workStation/getAllWorkStation`
const URL_DELETE_WORKSTATION = `${WEBSERVICES_URL}productionPlanning/workStation/deleteWorkStation`

const URL_CREATE_WORKSTATION_NOTE = `${WEBSERVICES_URL}productionPlanning/workStation/addWorkStationNotes`
const URL_UPDATE_WORKSTATION_NOTE = `${WEBSERVICES_URL}productionPlanning/workStation/updateWorkStationNotes`
const URL_DELETE_WORKSTATION_NOTE = `${WEBSERVICES_URL}productionPlanning/workStation/deleteWorkStationNotes`

const URL_CREATE_BOM = `${WEBSERVICES_URL}productionPlanning/createBom`
const URL_UPDATE_BOM = `${WEBSERVICES_URL}productionPlanning/updateBom`
const URL_GET_SINGLE_BOM = `${WEBSERVICES_URL}productionPlanning/getSingleBom`
const URL_GET_BOM_EXCEL_DETAIL = `${WEBSERVICES_URL}productionPlanning/bom/bomExcelDetail`
const URL_GET_ALL_BOM = `${WEBSERVICES_URL}productionPlanning/getAllBom`
const URL_DELETE_BOM = `${WEBSERVICES_URL}productionPlanning/deleteBom`

const URL_GET_BOM_BY_PRODUCT = `${WEBSERVICES_URL}productionPlanning/getBomByProduct`
const URL_GET_ROUTING_BY_PRODUCT = `${WEBSERVICES_URL}productionPlanning/getRoutingByProduct`

const URL_CREATE_ROUTING = `${WEBSERVICES_URL}productionPlanning/createRouting`
const URL_UPDATE_ROUTING = `${WEBSERVICES_URL}productionPlanning/updateRouting`
const URL_GET_SINGLE_ROUTING = `${WEBSERVICES_URL}productionPlanning/getSingleRouting`
const URL_GET_ALL_ROUTING = `${WEBSERVICES_URL}productionPlanning/getAllRouting`
const URL_DELETE_ROUTING = `${WEBSERVICES_URL}productionPlanning/deleteRouting`

const URL_GET_END_DATE = `${WEBSERVICES_URL}productionPlanning/getEndDate`
const URL_GET_EMPLOYEE_PRODUCTION = `${WEBSERVICES_URL}productionPlanning/getProductionListByUser`

const URL_CREATE_CURRENCY = `${WEBSERVICES_URL}setting/currency/createCurrency`
const URL_UPDATE_CURRENCY = `${WEBSERVICES_URL}setting/currency/updateCurrency`

const URL_GET_SINGLE_CURRENCY = `${WEBSERVICES_URL}setting/currency/getSingleCurrency`
const URL_GET_ALL_CURRENCY = `${WEBSERVICES_URL}setting/currency/getAllCurrency`
const URL_DELETE_CURRENCY = `${WEBSERVICES_URL}setting/currency/deleteCurrency`



const URL_GET_SINGLE_CUSTOMER_STATUS = `${WEBSERVICES_URL}company/getSingleCompanyStatus`
const URL_GET_COMPANY_STATUS = `${WEBSERVICES_URL}company/getCompanyStatus`
const URL_DELETE_CUSTOMER_STATUS = `${WEBSERVICES_URL}company/deleteCompanyStatus`
const URL_CREATE_CUSTOMER_STATUS = `${WEBSERVICES_URL}company/createCompanyStatus`
const URL_UPDATE_CUSTOMER_STATUS = `${WEBSERVICES_URL}company/updateCompanyStatus`


const URL_GET_TAX_DETAILS = `${WEBSERVICES_URL}setting/taxDetails/getSingleTaxDetails`
const URL_SET_TAX_DETAILS = `${WEBSERVICES_URL}setting/taxDetails/createTaxDetails`

const URL_GET_COMPANY_WORKING_HOURS = `${WEBSERVICES_URL}setting/workingHours/getCompanyHour`
const URL_SET_COMPANY_WORKING_HOURS = `${WEBSERVICES_URL}setting/workingHours/createCompanyHour`

const URL_CREATE_HOLIDAYS_LIST = `${WEBSERVICES_URL}setting/holidays/createHolidayDetails`
const URL_GET_HOLIDAYS_LIST = `${WEBSERVICES_URL}setting/holidays/getHolidayDetails`

const URL_CREATE_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/createManufacturingOrder`
const URL_GET_SINGLE_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/getSingleManufacturingOrder`
const URL_GET_PRODUCTION_PDF = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/moPDFDetail`




const URL_GET_ALL_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/getManufacturingOrder`
const URL_GET_BOOKING_LIST_FOR_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/getBookingListForMO`
const URL_DELETE_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/deleteManufacturingOrder`
const URL_UPDATE_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/updateManufacturingOrder`
const URL_GET_MO_WRITEOF = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/getMOWriteoffs`
const URL_FINISH_MO_PRODUCTION = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/stopManufacturingOrder`

const URL_BOOK_ALL_MO_ITEM = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/bookAllBookingForMO`
const URL_CANCEL_ALL_MO_ITEM = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/cancelAllBookingForMO`
const URL_CANCEL_SINGLE_MO_ITEM = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/cancelSingleBookingForMO`
const URL_GET_FINAL_OPERATION = `${WEBSERVICES_URL}productionPlanning/getFinalOperation`

const URL_GET_PRODUCTION_PLANNING = `${WEBSERVICES_URL}productionPlanning/getListForProductionPlan`
const URL_GET_DATE_FOR_PP = `${WEBSERVICES_URL}productionPlanning/getDateForProductionPlan`

const URL_CREATE_SHIPMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/createShipment`
const URL_GET_SINGLE_SHIPMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/getSingleShipment`
const URL_UPDATE_SHIPMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/updateShipment`
const URL_GET_ALL_SHIPMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/getAllShipment`
const URL_DELETE_SHIPMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteShipment`
const URL_PICK_ALL_SHIPMENT_ITEM = `${WEBSERVICES_URL}crm/customer/customerOrder/pickAllItems`

const URL_CO_SHIPMENT_INVOICES = `${WEBSERVICES_URL}crm/customer/customerOrder/getCOShipmentInvoice`

const URL_GET_SHIPMENT_BY_ORDER = `${WEBSERVICES_URL}crm/customer/customerOrder/shipmentList`

const URL_CREATE_MO_NOTE = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/notes/addManufacturingOrderNotes`
const URL_UPDATE_MO_NOTE = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/notes/updateManufacturingOrderNotes`
const URL_DELETE_MO_NOTE = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/notes/deleteManufacturingOrderNotes`

const URL_START_MO = `${WEBSERVICES_URL}productionPlanning/startMo`
const URL_PLAY_OR_PAUSE_MO = `${WEBSERVICES_URL}productionPlanning/playPauseMo`
const URL_CALCULATE_QUANTITY = `${WEBSERVICES_URL}productionPlanning/calculateRemainingQuantity`
const URL_FINISH_MO = `${WEBSERVICES_URL}productionPlanning/finishMo`

const URL_ADD_MODEL_NO = `${WEBSERVICES_URL}setting/pumpMaster/addPumpModel`
const URL_GET_MODEL_NO = `${WEBSERVICES_URL}setting/pumpMaster/getPumpModel`

const URL_ADD_MAIN_MOC = `${WEBSERVICES_URL}setting/pumpMaster/addMainMoc`
const URL_GET_MAIN_MOC = `${WEBSERVICES_URL}setting/pumpMaster/getMainMoc`

const URL_ADD_IMPELLER_MOC = `${WEBSERVICES_URL}setting/pumpMaster/addImpellerMoc`
const URL_GET_IMPELLER_MOC = `${WEBSERVICES_URL}setting/pumpMaster/getImpellerMoc`

const URL_ADD_IMPELLER_TYPE = `${WEBSERVICES_URL}setting/pumpMaster/addImpellerType`
const URL_GET_IMPELLER_TYPE = `${WEBSERVICES_URL}setting/pumpMaster/getImpellerType`

const URL_ADD_MECH_SEAL = `${WEBSERVICES_URL}setting/pumpMaster/addMechSeal`
const URL_GET_MECH_SEAL = `${WEBSERVICES_URL}setting/pumpMaster/getMechSeal`

const URL_ADD_MOTOR_RATING = `${WEBSERVICES_URL}setting/pumpMaster/addMotorRating`
const URL_GET_MOTOR_RATING = `${WEBSERVICES_URL}setting/pumpMaster/getMotorRating`

const URL_ADD_PUMP_DOCUMENT_TYPE = `${WEBSERVICES_URL}setting/pumpMaster/addPumpDocumentName`
const URL_GET_PUMP_DOCUMENT_TYPE = `${WEBSERVICES_URL}setting/pumpMaster/getPumpDocumentName`

const URL_GET_ALL_CITIES_BY_COUNTRY = `${WEBSERVICES_URL}crm/pumpRegister/getAllCitiesByCountry`
const URL_GET_COMPANY_FOR_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/getClientCompany`

const URL_CREATE_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/createPumpRegister`
const URL_UPDATE_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/updatePumpRegister`
const URL_GET_SINGLE_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/getSinglePumpRegister`
const URL_DELETE_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/deletePumpRegister`
const URL_GEL_ALL_PUMP_REGISTER = `${WEBSERVICES_URL}crm/pumpRegister/getPumpRegister`

const URL_CREATE_PO_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/createPOInvoice`
const URL_GET_PO_GROUP_DATA = `${WEBSERVICES_URL}procurement/purchaseorder/getPOGroupData`
const URL_GET_SINGLE_PO_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/getSinglePOInvoice`
const URL_GET_PO_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/getPOInvoice`
const URL_DELETE_PO_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/deletePOInvoice`
const URL_UPDATE_PO_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/updatePOInvocie`

const URL_CREATE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/createCOInvoice`
const URL_GET_CO_GROUP_DATA = `${WEBSERVICES_URL}crm/customer/customerOrder/getCOGroupData`
const URL_GET_SINGLE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/getSingleCOInvoice`
const URL_GET_SINGLE_E_INVOICE = `${WEBSERVICES_URL}generateEInvoice/getEInvoiceDetail`
const URL_SAVE_E_INVOICE = `${WEBSERVICES_URL}generateEInvoice/createEInvoice`
const URL_SAVE_E_WAY_BILL = `${WEBSERVICES_URL}generateEInvoice/createEwayBillByIrn`
const URL_GET_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/getCOInvoice`
const URL_DELETE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteCOInvoice`
const URL_UPDATE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/updateCOInvoice`
const URL_GET_CO_INVOICES = `${WEBSERVICES_URL}crm/customer/customerOrder/getAllInvoiceOfCO`
const URL_GET_CO_SHIPMENTS = `${WEBSERVICES_URL}crm/customer/customerOrder/getAllCOShipment`
//*********DEBIT NOTE API ************ */

const URL_GET_DN_INVOICE_PDF = `${WEBSERVICES_URL}procurement/purchaseorder/debitInvoicePDF`
// const URL_CREATE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/createCOInvoice`
// const URL_GET_CO_GROUP_DATA = `${WEBSERVICES_URL}crm/customer/customerOrder/getCOGroupData`
// const URL_GET_SINGLE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/getSingleCOInvoice`
const URL_GET_DEBIT_SINGLE_E_INVOICE = `${WEBSERVICES_URL}generateEInvoice/getDebitEInvoiceDetail`
const URL_SAVE_E_INVOICE_DEBIT = `${WEBSERVICES_URL}generateEInvoice/createDebitEInvoice`
// const URL_SAVE_E_WAY_BILL = `${WEBSERVICES_URL}generateEInvoice/createEwayBillByIrn`
// const URL_GET_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/getCOInvoice`
// const URL_DELETE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteCOInvoice`
// const URL_UPDATE_CO_INVOICE = `${WEBSERVICES_URL}crm/customer/customerOrder/updateCOInvoice`
// const URL_GET_CO_INVOICES = `${WEBSERVICES_URL}crm/customer/customerOrder/getAllInvoiceOfCO`
// const URL_GET_CO_SHIPMENTS = `${WEBSERVICES_URL}crm/customer/customerOrder/getAllCOShipment`


const URL_CREATE_CREDIT_NOTE = `${WEBSERVICES_URL}crm/customerOrderInvoice/createCreditNote`
const URL_GET_SINGLE_CREDIT_NOTE = `${WEBSERVICES_URL}crm/customerOrderInvoice/getSingleCreditNote`
const URL_GET_ALL_CREDIT_NOTE = `${WEBSERVICES_URL}crm/customerOrderInvoice/getAllCreditNote`
const URL_DELETE_CREDIT_NOTE = `${WEBSERVICES_URL}crm/customerOrderInvoice/deleteCreditNote`
const URL_UPDATE_CREDIT_NOTE = `${WEBSERVICES_URL}crm/customerOrderInvoice/updateCreditNote`
const URL_GET_CREDIT_NOTE_BY_CO = `${WEBSERVICES_URL}crm/customerOrderInvoice/getCreditNoteByCO`
const URL_GET_REMAINING_CO_INVOICE = `${WEBSERVICES_URL}crm/customerOrderInvoice/getRemainingCOInvoice`

const URL_CREATE_CUSTOMER_DOCUMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/createDocuments`
const URL_UPDATE_CUSTOMER_DOCUMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/updateDocuments`
const URL_DELETE_CUSTOMER_DOCUMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteDocument`
const URL_GET_CUSTOMER_DOCUMENTS = `${WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrderDocuments`

const URL_CREATE_CO_PAYMENTS = `${WEBSERVICES_URL}crm/customer/customerOrder/addCustomerOrderPayments`
const URL_UPDATE_CO_PAYMENTS = `${WEBSERVICES_URL}crm/customer/customerOrder/updateCustomerOrderPayments`
const URL_GET_SINGLE_CO_PAYMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/getSingleCustomerOrderPayments`
const URL_GET_CO_PAYMENT_LIST = `${WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrderPayments`
const URL_DELETE_CO_PAYMENT = `${WEBSERVICES_URL}crm/customer/customerOrder/deleteCustomerOrderPayments`
const URL_GET_CO_INVOICE_PDF = `${WEBSERVICES_URL}crm/customer/customerOrder/coInvoicePDF`

/* Credit Note URLs */

const URL_GET_CN_INVOICE_PDF = `${WEBSERVICES_URL}crm/customerOrderInvoice/creditInvoicePDF`


const URL_CREATE_ITEM_DOCUMENT = `${WEBSERVICES_URL}stock/item/document/createItemDocument`
const URL_UPDATE_ITEM_DOCUMENT = `${WEBSERVICES_URL}stock/item/document/updateItemDocument`
const URL_GET_SINGLE_ITEM_DOCUMENT = `${WEBSERVICES_URL}stock/item/document/getSingleItemDocument`
const URL_GET_ALL_ITEM_DOCUMENT = `${WEBSERVICES_URL}stock/item/document/getAllItemDocument`
const URL_DELETE_ITEM_DOCUMENT = `${WEBSERVICES_URL}stock/item/document/deleteItemDocument`

const URL_GET_EXPENSE_CATEGORIES = `${WEBSERVICES_URL}setting/accountMaster/getExpenseCategory`
const URL_ADD_EXPENSE_CATEGORIES = `${WEBSERVICES_URL}setting/accountMaster/addExpenseCategory`
const URL_GET_BANK_ACCOUNTS = `${WEBSERVICES_URL}setting/accountMaster/getBankAccountDetail`
const URL_ADD_BANK_ACCOUNTS = `${WEBSERVICES_URL}setting/accountMaster/addBankAccountDetail`

const URL_ACCOUNT_DETAIL = `${WEBSERVICES_URL}setting/account/accountDetail`

const URL_CREATE_EXPENSE_DETAIL = `${WEBSERVICES_URL}accounting/expense/createExpenseDetail`
const URL_UPDATE_EXPENSE_DETAIL = `${WEBSERVICES_URL}accounting/expense/updateExpenseDetail`
const URL_GET_SINGLE_EXPENSE_DETAIL = `${WEBSERVICES_URL}accounting/expense/getSingleExpenseDetail`
const URL_DELETE_EXPENSE_DETAIL = `${WEBSERVICES_URL}accounting/expense/deleteExpenseDetail`
const URL_GET_ALL_EXPENSE_DETAIL = `${WEBSERVICES_URL}accounting/expense/getAllExpenseDetail`

const URL_SET_ITEM_PRICE_CALCULATION = `${WEBSERVICES_URL}setting/itemPriceCalculation/createItemPriceCalculation`
const URL_GET_ITEM_PRICE_CALCULATION = `${WEBSERVICES_URL}setting/itemPriceCalculation/getItemPriceCalculation`

const GET_GST_DETAIL_BY_NUMBER = `${WEBSERVICES_URL}setting/gstDetails/getGstDetailsByNumber`
const CREATE_GST_DETAIL = `${WEBSERVICES_URL}setting/gstDetails/createGstDetail`
const UPDATE_GST_DETAIL = `${WEBSERVICES_URL}setting/gstDetails/updateGstDetail`
const GET_SINGLE_GST_DETAIL = `${WEBSERVICES_URL}setting/gstDetails/getSingleGstDetail`
const GET_ALL_GST_DETAIL = `${WEBSERVICES_URL}setting/gstDetails/getGstDetail`
const DELETE_GST_DETAIL = `${WEBSERVICES_URL}setting/gstDetails/deleteGstDetail`

const URL_ADD_NOTES = `${WEBSERVICES_URL}crm/customer/addNotes`
const URL_CREATE_COMPANY_CONTACT = `${WEBSERVICES_URL}crm/customer/createCompanyContact`
const URL_CREATE_COMPANY = `${WEBSERVICES_URL}crm/customer/createCompany`
const URL_UPDATE_NOTE = `${WEBSERVICES_URL}crm/customer/updateNote`
const URL_DELETE_NOTE = `${WEBSERVICES_URL}crm/customer/deleteNote`
const URL_UPDATE_COMPANY_CONTACT = `${WEBSERVICES_URL}crm/customer/updateCompanyContact`
const URL_DELETE_CONTACT = `${WEBSERVICES_URL}crm/customer/deleteContact`
const URL_UPDATE_COMPANY = `${WEBSERVICES_URL}crm/customer/updateCompany`
const URL_ADD_VENDOR_CONTACT = `${WEBSERVICES_URL}procurement/vendors/addVendorContacts`
const URL_ADD_VENDOR_NOTE = `${WEBSERVICES_URL}procurement/vendors/addVendorNotes`
const URL_GET_SINGLE_VENDOR_CONTACT = `${WEBSERVICES_URL}procurement/vendors/getSingleVendorContacts`
const URL_UPDATE_VENDOR_CONTACT = `${WEBSERVICES_URL}procurement/vendors/updateVendorContacts`
const URL_DELETE_VENDOR_CONTACT = `${WEBSERVICES_URL}procurement/vendors/deleteVendorContacts`
const URL_UPDATE_VENDOR_NOTES = `${WEBSERVICES_URL}procurement/vendors/updateVendorNotes`
const URL_DELETE_VENDOR_NOTES = `${WEBSERVICES_URL}procurement/vendors/deleteVendorNotes`
const URL_DELETE_VENDOR = `${WEBSERVICES_URL}procurement/vendors/deleteVendor`
const URL_CREATE_COMPANY_STATUS = `${WEBSERVICES_URL}company/createCompanyStatus`
const URL_CREATE_COMAPNY_INDUSTRY = `${WEBSERVICES_URL}company/createCompanyIndustry`
const URL_CREATE_FILTER_TABLE_VALUE = `${WEBSERVICES_URL}stock/filterTableValue/createFilterTableValue`
const URL_CREATE_FILTER_TABLE = `${WEBSERVICES_URL}stock/filterTable/createFilterTable`
const URL_ADD_ITEMS_NOTES = `${WEBSERVICES_URL}stock/addItemNotes`
const URL_CREATE_PART_NO = `${WEBSERVICES_URL}stock/createPartNo`
const URL_CREATE_ITEM = `${WEBSERVICES_URL}stock/createItem`
const URL_CREATE_PRODUCT_GROUP = `${WEBSERVICES_URL}stock/createProductGroup`
const URL_ADD_PURCHASE_TERM_CONTACT = `${WEBSERVICES_URL}stock/item/purchaseTerm/addPurchaseTermContacts`
const URL_CREATE_STORAGE_LOCATION = `${WEBSERVICES_URL}stock/stocksetting/createStorageLocation`
const URL_CREATE_UNIT_OF_MEASUREMENT = `${WEBSERVICES_URL}stock/stocksetting/createUnitOfMeasurement`
const URL_UPDATE_ITEM_NOTE = `${WEBSERVICES_URL}stock/updateItemNote`
const URL_DELETE_ITEM_NOTE = `${WEBSERVICES_URL}stock/deleteItemNote`
const URL_GET_FILTER_TABLE_VALUE = `${WEBSERVICES_URL}stock/filterTableValue/getSingleFilterTableValue`
const URL_DELETE_FILTER_TABLE_VALUE = `${WEBSERVICES_URL}stock/filterTableValue/deleteFilterTableValue`
const URL_UPDATE_FILTER_TABLE_VALUE = `${WEBSERVICES_URL}stock/filterTableValue/updateFilterTableValue`
const URL_UPDATE_ITEM = `${WEBSERVICES_URL}stock/updateItem`
const URL_DELETE_ITEM = `${WEBSERVICES_URL}stock/deleteItem`
const URL_UPDATE_PRODUCT_GROUP = `${WEBSERVICES_URL}stock/updateProductGroup`
const URL_DELETE_PRODUCT_GROUP = `${WEBSERVICES_URL}stock/deleteProductGroup`
const URL_GET_SINGLE_PURCHASE_TERM_CONTACT = `${WEBSERVICES_URL}stock/item/purchaseTerm/getSinglePurchaseTermContacts`
const URL_UPDATE_PURCHASE_TERM_CONTACT = `${WEBSERVICES_URL}stock/item/purchaseTerm/updatePurchaseTermContacts`
const URL_DELETE_PURCHASE_TERM_CONTACT = `${WEBSERVICES_URL}stock/item/purchaseTerm/deletePurchaseTermContacts`
const URL_UPDATE_STORAGE_LOCATION = `${WEBSERVICES_URL}stock/stocksetting/updateStorageLocation`
const URL_DELETE_STORAGE_LOCATION = `${WEBSERVICES_URL}stock/stocksetting/deleteStorageLocation`
const URL_UPDATE_UOM = `${WEBSERVICES_URL}stock/stocksetting/updateUnitOfMeasurement`
const URL_DELETE_UOM = `${WEBSERVICES_URL}stock/stocksetting/deleteUnitOfMeasurement`
const URL_DELETE_FILTER_TABLE = `${WEBSERVICES_URL}stock/filterTable/deleteFilterTable`
const URL_GET_ALL_COMPANY_BY_ACC_MANAGER = `${WEBSERVICES_URL}crm/customer/getCompanyByAccountManager`
const URL_ALL_PRODUCT_GROUP_WITHOUT_PERM = `${WEBSERVICES_URL}stock/getFreeProductGroup`
const URL_ALL_ITEM_WITHOUT_PERM = `${WEBSERVICES_URL}stock/getFreeItem`
const URL_ALL_VENDOR_WITHOUT_PERM = `${WEBSERVICES_URL}procurement/vendors/getFreeVendor`
const URL_PRODUCTION_PLANNING_OPERATION = `${WEBSERVICES_URL}productionPlanning/getListForProductionPlanWithOperation`
const URL_WAITING_LIST_MO = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/getWaitingOperations`
const TOGGLE_WAITING_LIST_OP = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/toggleWaitingListOperations`
const URL_PO_REVISIONS = `${WEBSERVICES_URL}procurement/purchaseorder/getPurchaseOrderRevisionList`
const URL_PO_REVISION_DETAIL = `${WEBSERVICES_URL}procurement/purchaseorder/getOrderRevisionData`
const URL_CALL_UPDATE_DEFAULT_WORKER = `${WEBSERVICES_URL}productionPlanning/manufacturingOrder/updateDefaultWorker`


const URL_CREATE_TICKET = `${WEBSERVICES_URL}supportTicket/createSupportTicket`
const URL_GET_SINGLE_TICKET = `${WEBSERVICES_URL}supportTicket/getTicketDetailsById`
const URL_GET_ALL_TICKET = `${WEBSERVICES_URL}supportTicket/getAllTickets`
const URL_UPDATE_TICKET = `${WEBSERVICES_URL}supportTicket/updateTicketByClient`
const URL_CLOSE_TICKET = `${WEBSERVICES_URL}supportTicket/changeTicketStatus`

const URL_PROFILE_DELETE = `${WEBSERVICES_URL}setting/supportTicket/generateTicket`

const URL_START_E_INVOICE = `${WEBSERVICES_URL}setting/systemSetting/setupEInvoice`
const URL_GET_E_INVOICE = `${WEBSERVICES_URL}setting/systemSetting/getEInvoiceSetupDetail`
const URL_BACKUP_DATA = `${WEBSERVICES_URL}setting/backupProfileData`

// const URL_CREATE_PUMP_DOCUMENT = `${WEBSERVICES_URL}crm/pumpRegister/createDocument`
// const URL_UPDATE_PUMP_DOCUMENT = `${WEBSERVICES_URL}crm/pumpRegister/UpdateDocuments`

const URL_CREATE_PUMP_DOCUMENT = `${WEBSERVICES_URL}crm/pumpRegister/createDocument`
const URL_UPDATE_PUMP_DOCUMENT = `${WEBSERVICES_URL}crm/pumpRegister/updateDocument`
const URL_DELETE_PUMP_DOCUMENT = `${WEBSERVICES_URL}crm/pumpRegister/deletePumpRegisterDocument`
const URL_GET_ALL_PUMP_DOCUMENT = `${WEBSERVICES_URL}crm/pumpRegister/getPumpRegisterDocuments`

const URL_CREATE_PUMP_LOG = `${WEBSERVICES_URL}crm/pumpRegister/createPumpLog`
const URL_UPDATE_PUMP_LOG = `${WEBSERVICES_URL}crm/pumpRegister/updatePumpLogDocument`
const URL_DELETE_PUMP_LOG = `${WEBSERVICES_URL}crm/pumpRegister/deletePumpLogDocument`
const URL_ALL_PUMP_LOG = `${WEBSERVICES_URL}crm/pumpRegister/getPumpLogDocuments`

const URL_CREATE_DELIVERY_TERMS = `${WEBSERVICES_URL}setting/systemSetting/createDeliveryTerms`
const URL_GET_DELIVERY_TERMS = `${WEBSERVICES_URL}setting/systemSetting/getDeliveryTerms`

// const URL_GET_DELIVERY_TERMS = `${WEBSERVICES_URL}setting/getDeliveryTerms`

const URL_CREATE_EXPENSE_FILE = `${WEBSERVICES_URL}accounting/expense/createExpenseDocument`
const URL_UPDATE_EXPENSE_FILE = `${WEBSERVICES_URL}accounting/expense/updateExpenseDocument`
const URL_DELETE_EXPENSE_FILE = `${WEBSERVICES_URL}accounting/expense/deleteExpenseDocument`

const URL_UPDATE_QC_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/updatePOInvoiceByQCM`

const URL_CREATE_DEBIT_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/createDebitNote`
const URL_UPDATE_DEBIT_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/updateDebitNote`
const URL_DELETE_DEBIT_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/deleteDebitNote`
const URL_SINGLE_DEBIT_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/getSingleDebitNote`
const URL_ALL_DEBIT_NOTE = `${WEBSERVICES_URL}procurement/purchaseorder/getAllDebitNote`

const URL_GET_ALL_REJECTED_INVOICES = `${WEBSERVICES_URL}procurement/purchaseorder/getAllRejectedInvoice`
const URL_GET_SINGLE_REJECTED_INVOICE = `${WEBSERVICES_URL}procurement/purchaseorder/getSingleRejectedInvoice`

const URL_GET_PO_INVOICE_BY_QCM = `${WEBSERVICES_URL}procurement/purchaseorder/getPOInvoiceByQCM`
const URL_GET_DEBIT_NOTE_BY_PO = `${WEBSERVICES_URL}procurement/purchaseorder/getDebitNoteByPO`

export const checkUserPermission = (slug, permission) => {
  let userData = localStorage.getItem("userdata")
  if (JSON.parse(userData).role === "subUser") {
    let userPermission = JSON.parse(localStorage.getItem("permissions"))
    let up = userPermission.some(item => item.slug === slug)
    if (up) {
      if (permission === "create") {
        let res = userPermission.filter(function (item) {
          return item.slug === slug;
        });
        return res[0].create
      } else {
        let res = userPermission.filter(function (item) {
          return item.slug === slug;
        });
        return res[0].update
      }
    } else {
      return false
    }
  } else {
    return true
  }
}

export const callLogin = (email, password) => {
  return new Promise((resolve, reject) => {
    let formdata = new FormData()
    formdata.append('email', email)
    formdata.append('password', password)
    fetch(URL_LOGIN, {
      method: 'POST',
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callRegister = (email, password, companyName, referralCode) => {
  return new Promise((resolve, reject) => {
    let formdata = new FormData()
    formdata.append('email', email)
    formdata.append('password', password)
    formdata.append('companyName', companyName)
    formdata.append('referralCode', referralCode)
    fetch(URL_REGISTER, {
      method: 'POST',
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callForgotPassword = (email) => {
  return new Promise((resolve, reject) => {
    let formdata = new FormData()
    formdata.append('email', email)
    fetch(URL_FORGOTPASSWORD, {
      method: 'POST',
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callResetPassword = (pass, token) => {
  return new Promise((resolve, reject) => {
    let formdata = new FormData()
    formdata.append("new_password", pass)
    formdata.append("login_token", token)
    fetch(URL_RESETPASSWORD, {
      method: 'POST',
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetSingleProductGroup = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_PRODUCT_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllFilterTable = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_FILTER_TABLE, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callAccountDetails = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_ACCOUNT_DETAIL, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCustomerList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_COMPANY, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callClientForPumpRegister = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_COMPANY_FOR_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCustomerListWithFilter = (start, length, nextContactStart, nextContactEnd, contactStart, contactEnd, paymentTerms, deliveryTerms, number, name, status, nickName, panNo, gstNo, minCreditLimit, maxCreditLimit, manager, Phone, Fax, email, Web, Skype, Address, other, searchnote, industrytype, fieldName,) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("start", start);
    formdata.append("length", length);
    formdata.append("nextContactStart", nextContactStart)
    formdata.append("nextContactEnd", nextContactEnd)
    formdata.append("minContactStart", contactStart)
    formdata.append("maxContactStart", contactEnd)
    formdata.append("paymentTerms", paymentTerms)
    formdata.append("deliveryTerms", deliveryTerms)
    formdata.append("number", number)
    formdata.append("name", name)
    formdata.append("status", status)
    formdata.append("nickName", nickName)
    formdata.append("panNo", panNo)
    formdata.append("gstNo", gstNo)
    formdata.append("minCreditLimit", minCreditLimit)
    formdata.append("maxCreditLimit", maxCreditLimit)
    formdata.append("manager", manager)
    formdata.append("Phone", Phone)
    formdata.append("Fax", Fax)
    formdata.append("Email", email)
    formdata.append("Web", Web)
    formdata.append("Skype", Skype)
    formdata.append("Address", Address)
    formdata.append("other", other)
    formdata.append("note", searchnote)
    formdata.append("industryType", industrytype)
    formdata.append("fieldName", fieldName)
    fetch(URL_GET_COMPANY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callOrderItemList = (start, length, number, customerNumber, customerName, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, productStatus, partNo, partDescription, minQuantity, maxQuantity, minTotal, maxTotal, minSubTotal, maxSubTotal, minDeliveredQuantity, maxDeliveredQuantity, minRemainingQuantity, maxRemainingQuantity, productGroupName, fieldName, fieldType) => {
  console.log('--------', minTotal);

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("start", start);
    formdata.append("length", length);
    formdata.append("number", number);
    formdata.append("customerNumber", customerNumber);
    formdata.append("customerName", customerName);
    formdata.append("minCreated", minCreated);
    formdata.append("maxCreated", maxCreated);
    formdata.append("minDeliveryDate", minDeliveryDate);
    formdata.append("maxDeliveryDate", maxDeliveryDate);
    formdata.append("status", productStatus);
    formdata.append("partNo", partNo);
    formdata.append("partDescription", partDescription);
    formdata.append("minQuantity", minQuantity);
    formdata.append("maxQuantity", maxQuantity);
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("minSubTotal", minSubTotal)
    formdata.append("maxSubTotal", maxSubTotal)
    formdata.append("minDeliveredQuantity", minDeliveredQuantity)
    formdata.append("maxDeliveredQuantity", maxDeliveredQuantity)
    formdata.append("minRemainingQuantity", minRemainingQuantity)
    formdata.append("maxRemainingQuantity", maxRemainingQuantity)
    formdata.append("productGroupName", productGroupName);
    formdata.append("fieldName", fieldName);
    formdata.append("fieldType", '');


    fetch(URL_GET_CUSTOMER_ORDER_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCustomerOrderListExceptQuotation = (number, customerNumber, customerName, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, status, productStatus, start, length, invoiceStatus, paymentStatus, manager, email, phone, address, minPODate, maxPODate, minPODeliveryDate, maxPODeliveryDate, minActualDelivery, maxActualDelivery, minModified, maxModified) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("customerNumber", customerNumber)
    formdata.append("customerName", customerName)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("minDeliveryDate", minDeliveryDate)
    formdata.append("maxDeliveryDate", maxDeliveryDate)
    formdata.append("status", status)
    formdata.append("productStatus", productStatus)
    formdata.append("invoiceStatus", invoiceStatus)
    formdata.append("paymentStatus", paymentStatus)
    formdata.append("manager", manager)
    formdata.append("email", email)
    formdata.append("phone", phone)
    formdata.append("address", address)
    formdata.append("minPoDate", minPODate)
    formdata.append("maxPoDate", maxPODate)
    formdata.append("minPoDeliveryDate", minPODeliveryDate)
    formdata.append("maxPoDeliveryDate", maxPODeliveryDate)
    formdata.append("minActualDelivery", minActualDelivery)
    formdata.append("maxActualDelivery", maxActualDelivery)
    formdata.append("minModified", minModified)
    formdata.append("maxModified", maxModified)

    fetch(URL_GET_CUSTOMER_EXCEPT_QUOTATION_ORDERS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callCustomerOrderList = (number, customerNumber, customerName, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, status, productStatus, start, length, invoiceStatus, paymentStatus, manager) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("customerNumber", customerNumber)
    formdata.append("customerName", customerName)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("minDeliveryDate", minDeliveryDate)
    formdata.append("maxDeliveryDate", maxDeliveryDate)
    formdata.append("status", status)
    formdata.append("productStatus", productStatus)
    formdata.append("invoiceStatus", invoiceStatus)
    formdata.append("paymentStatus", paymentStatus)
    formdata.append("manager", manager)

    fetch(URL_GET_CUSTOMER_ORDERS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callAllCustomerForInvoice = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ALL_CUSTOMER_FOR_INVOICE, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}

export const callCustomerOrderQuotationList = (number, customerNumber, customerName, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, status, productStatus, start, length, invoiceStatus, paymentStatus, manager, minSubTotal, maxSubTotal, minTotal, maxTotal, minModified, maxModified, minQuotationValid, maxQuotationValid, reference, minPODate, maxPODate, minPODeliveryDate, maxPODeliveryDate, minActualDelivery, maxActualDelivery) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("customerNumber", customerNumber)
    formdata.append("customerName", customerName)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("minDeliveryDate", minDeliveryDate)
    formdata.append("maxDeliveryDate", maxDeliveryDate)
    formdata.append("status", status)
    formdata.append("productStatus", productStatus)
    formdata.append("invoiceStatus", invoiceStatus)
    formdata.append("paymentStatus", paymentStatus)
    formdata.append("manager", manager)
    formdata.append("minSubTotal", minSubTotal)
    formdata.append("maxSubTotal", maxSubTotal)
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("minModified", minModified)
    formdata.append("maxModified", maxModified)
    formdata.append("minQuotationValid", minQuotationValid)
    formdata.append("maxQuotationValid", maxQuotationValid)
    formdata.append("reference", reference)
    formdata.append("minPoDate", minPODate)
    formdata.append("maxPoDate", maxPODate)
    formdata.append("minPoDeliveryDate", minPODeliveryDate)
    formdata.append("maxPoDeliveryDate", maxPODeliveryDate)
    formdata.append("minActualDelivery", minActualDelivery)
    formdata.append("maxActualDelivery", maxActualDelivery)

    fetch(URL_GET_CUSTOMER_ORDERS_QUOTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}



export const callSetCompanyDetails = (companyName, contactDetails, email, website, website2, phone, telephone, regNumber, panNo, regdNo, gstin, ecc, bank_name, bank_accno, bank_branch, bank_rtgsneft, bank_swiftcode, companyLogo, address_line1, address_line2, address_zone, address_city, address_state, address_country, address_pin) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyName", companyName)
    formdata.append("contactDetails", contactDetails)
    formdata.append("email", email)
    formdata.append("website", website)
    formdata.append("website2", website2)
    formdata.append("phone", phone)
    formdata.append("telephone", telephone)
    formdata.append("regNumber", regNumber)
    formdata.append("panNo", panNo)
    formdata.append("regdNo", regdNo)
    formdata.append("gstin", gstin)
    formdata.append("ecc", ecc)
    formdata.append("bank_name", bank_name)
    formdata.append("bank_accno", bank_accno)
    formdata.append("bank_branch", bank_branch)
    formdata.append("bank_rtgsneft", bank_rtgsneft)
    formdata.append("bank_swiftcode", bank_swiftcode)
    formdata.append("companyLogo", companyLogo)
    formdata.append("address_line1", address_line1)
    formdata.append("address_line2", address_line2)
    formdata.append("address_zone", address_zone)
    formdata.append("address_city", address_city)
    formdata.append("address_state", address_state)
    formdata.append("address_country", address_country)
    formdata.append("address_pin", address_pin)

    fetch(URL_SET_COMPANY_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetCompanyDetails = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_COMPANY_DETAIL, {
      method: 'GET',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetPlans = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_PLAN, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetSingleProfile = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_PROFILE_DETAIL, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateProfile = (fullName, username, mobileNumber, oldPassword, newPassword) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let userId = JSON.parse(localStorage.getItem('userdata'))._id
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", userId)
    formdata.append("fullName", fullName)
    formdata.append("username", username)
    formdata.append("mobileNumber", mobileNumber)
    formdata.append("oldPassword", oldPassword)
    formdata.append("newPassword", newPassword)

    fetch(URL_SET_PROFILE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}





export const callCreateSubUser = (name, email, phone, password, user_designation, userPermissionData, departments, workerPaymentType, workerPaymentValue) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("full_name", name);
    formdata.append("mobileNumber", phone);
    formdata.append("user_designation", user_designation);
    formdata.append("userPermissionData", userPermissionData);
    formdata.append("departments", departments);
    formdata.append("workerPaymentType", workerPaymentType);
    formdata.append("workerPaymentValue", workerPaymentValue);

    fetch(URL_CREATE_SUB_USER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateMONote = (manufactureOrderId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", manufactureOrderId);
    formdata.append("note", note);
    formdata.append("is_important", false);

    fetch(URL_CREATE_MO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateMONote = (manufactureOrderId, id, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", manufactureOrderId);
    formdata.append("id", id);
    formdata.append("note", note);
    formdata.append("is_important", false);

    fetch(URL_UPDATE_MO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callDeleteMONote = (manufactureOrderId, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", manufactureOrderId);
    formdata.append("id", id);

    fetch(URL_DELETE_MO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateSubUser = (userId, name, email, phone, user_designation, userPermissionData, departments, workerPaymentType, workerPaymentValue) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("userId", userId);
    formdata.append("email", email);
    formdata.append("mobileNumber", phone);
    formdata.append("full_name", name);
    formdata.append("user_designation", user_designation);
    formdata.append("userPermissionData", userPermissionData);
    formdata.append("departments", departments);
    formdata.append("workerPaymentType", workerPaymentType);
    formdata.append("workerPaymentValue", workerPaymentValue);

    fetch(URL_UPDATE_SUB_USER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteSubUser = (userId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("userId", userId);

    fetch(URL_DELETE_SUB_USER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteMo = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);

    fetch(URL_DELETE_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetBookingForMO = (MO_id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", MO_id);

    fetch(URL_GET_BOOKING_LIST_FOR_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleSubUser = (userId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("userId", userId);

    fetch(URL_GET_SINGLE_SUB_USER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetVendor = (number, name, Email, Skype, Address, Phone, note, minOnTime, maxOnTime, minAverageDelay, maxAverageDelay, url, minTotalCost, maxTotalCost, start, length) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("Phone", Phone);
    formdata.append("Skype", Skype);
    formdata.append("Address", Address);
    formdata.append("number", number);
    formdata.append("name", name);
    formdata.append("Email", Email);
    formdata.append("note", note);
    formdata.append("minOnTime", minOnTime);
    formdata.append("maxOnTime", maxOnTime);
    formdata.append("minAverageDelay", minAverageDelay);
    formdata.append("maxAverageDelay", maxAverageDelay);
    formdata.append("url", url);
    formdata.append("minTotalCost", minTotalCost);
    formdata.append("maxTotalCost", maxTotalCost);

    fetch(URL_GET_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callAddPurchaseOrder = (vendor, contactPerson, status, reference, rfqNo, rfqDate, dueDate, quotationNo, quotationDate, orderId, orderDate, invoiceId, invoiceDate, paymentDate, shippedOn, arrivalDate, freeText, freeTextFooter, expectedDate, grandSubTotal, grandTotal, termsCondition, productGroupData, totalOfRawCostInOther) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()

    formdata.append("vendor", vendor)
    formdata.append("contactPerson", contactPerson)
    formdata.append("status", status)
    formdata.append("reference", reference)
    formdata.append("rfqNo", rfqNo)
    formdata.append("rfqDate", rfqDate)
    formdata.append("dueDate", dueDate)
    formdata.append("quotationNo", quotationNo)
    formdata.append("quotationDate", quotationDate)
    formdata.append("orderId", orderId)
    formdata.append("orderDate", orderDate)
    formdata.append("invoiceId", invoiceId)
    formdata.append("invoiceDate", invoiceDate)
    formdata.append("paymentDate", paymentDate)
    formdata.append("shippedOn", shippedOn)
    formdata.append("arrivalDate", arrivalDate)
    formdata.append("freeText", freeText)
    formdata.append("freeTextFooter", freeTextFooter)
    formdata.append("expectedDate", expectedDate)
    formdata.append("grandSubTotal", grandSubTotal)
    formdata.append("grandTotal", grandTotal)
    formdata.append("termsCondition", termsCondition)
    formdata.append("productGroupData", productGroupData)
    formdata.append("totalOfRawCostInOther", totalOfRawCostInOther)


    fetch(URL_CREATE_PURCHASE_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdatePurchaseOrder = (id, number, vendor, contactPerson, status, reference, rfqNo, rfqDate, dueDate, quotationNo, quotationDate, orderId, orderDate, invoiceId, invoiceDate, paymentDate, shippedOn, arrivalDate, expectedDate, grandSubTotal, grandTotal, termsCondition, productGroupData, purchaseOrderMessage, purchaseOrderFooterMessage, totalOfRawCostInOther) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("number", number);
    formdata.append("vendor", vendor)
    formdata.append("contactPerson", contactPerson)
    formdata.append("status", status)
    formdata.append("reference", reference)
    formdata.append("rfqNo", rfqNo)
    formdata.append("rfqDate", rfqDate)
    formdata.append("dueDate", dueDate)
    formdata.append("quotationNo", quotationNo)
    formdata.append("quotationDate", quotationDate)
    formdata.append("orderId", orderId)
    formdata.append("orderDate", orderDate)
    formdata.append("invoiceId", invoiceId)
    formdata.append("invoiceDate", invoiceDate)
    formdata.append("paymentDate", paymentDate)
    formdata.append("shippedOn", shippedOn)
    formdata.append("arrivalDate", arrivalDate)
    formdata.append("freeText", purchaseOrderMessage)
    formdata.append("freeTextFooter", purchaseOrderFooterMessage)
    formdata.append("expectedDate", expectedDate)
    formdata.append("grandSubTotal", grandSubTotal)
    formdata.append("grandTotal", grandTotal)
    formdata.append("termsCondition", termsCondition)
    formdata.append("productGroupData", productGroupData)
    formdata.append("totalOfRawCostInOther", totalOfRawCostInOther)

    fetch(URL_UPDATE_PURCHASE_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSinglePurchaseOrder = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_SINGLE_PURCHASE_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllInvoiceRemainingItems = (purchaseOrderId) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("purchaseOrderId", purchaseOrderId)

    fetch(URL_GET_INVOICE_REMAINING_ITEMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeletePurchaseOrder = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_PURCHASE_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callPurchaseOrderList = (start, length, number, status, minCreated, maxCreated, minExpectedDate, maxExpectedDate, vendorNumber, vendorName, minTotal, maxTotal, minTotalIncludingTax, maxTotalIncludingTax, minArrivalDate, maxArrivalDate, orderId, minOrderDate, maxOrderDate, invoiceId, minInvoiceDate, maxInvoiceDate, minPaymentDate, maxPaymentDate, minShippedOn, maxShippedOn, PoFreeText) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("status", status)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("minExpectedDate", minExpectedDate)
    formdata.append("maxExpectedDate", maxExpectedDate)
    formdata.append("vendorNumber", vendorNumber)
    formdata.append("vendorName", vendorName)
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("minTotalIncludingTax", minTotalIncludingTax)
    formdata.append("maxTotalIncludingTax", maxTotalIncludingTax)
    formdata.append("minArrivalDate", minArrivalDate)
    formdata.append("maxArrivalDate", maxArrivalDate)
    formdata.append("orderId", orderId)
    formdata.append("minOrderDate", minOrderDate)
    formdata.append("maxOrderDate", maxOrderDate)
    formdata.append("invoiceId", invoiceId)
    formdata.append("minInvoiceDate", minInvoiceDate)
    formdata.append("maxInvoiceDate", maxInvoiceDate)
    formdata.append("minPaymentDate", minPaymentDate)
    formdata.append("maxPaymentDate", maxPaymentDate)
    formdata.append("minShippedOn", minShippedOn)
    formdata.append("maxShippedOn", maxShippedOn)
    formdata.append("PoFreeText", PoFreeText)

    fetch(URL_PURCHASE_ORDER_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetAllRejectedInvoice = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ALL_REJECTED_INVOICES, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSingleRejectedInvoice = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers()
    myHeaders.append("token", token)

    let formdata = new FormData()
    formdata.append("poInvoiceId", id)

    fetch(URL_GET_SINGLE_REJECTED_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetPOInvoiceByQCM = (start, length, number, purchaseOrderNumber, vendorName, minRecievedQty, maxRecievedQty, minApprovedQty, maxApprovedQty, minRejectedQty, maxRejectedQty, minModifiedDate, maxModifiedDate, minInvoiceDate, maxInvoiceDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers()
    myHeaders.append("token", token)

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("purchaseOrderNumber", purchaseOrderNumber)
    formdata.append("vendorName", vendorName)
    formdata.append("minRecievedQty", minRecievedQty)
    formdata.append("maxRecievedQty", maxRecievedQty)
    formdata.append("minApprovedQty", minApprovedQty)
    formdata.append("maxApprovedQty", maxApprovedQty)
    formdata.append("minRejectedQty", minRejectedQty)
    formdata.append("maxRejectedQty", maxRejectedQty)
    formdata.append("minModifiedDate", minModifiedDate)
    formdata.append("maxModifiedDate", maxModifiedDate)
    formdata.append("minInvoiceDate", minInvoiceDate)
    formdata.append("maxInvoiceDate", maxInvoiceDate)

    fetch(URL_GET_PO_INVOICE_BY_QCM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetDebitNoteByPO = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers()
    myHeaders.append("token", token)

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_DEBIT_NOTE_BY_PO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreatePurchaseOrderNote = (id, note, isImp) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("purchaseOrderId", id)
    formdata.append("note", note)
    formdata.append("is_important", isImp)

    fetch(URL_CREATE_PO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateCustomerOrderNote = (id, note, isImp) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", id)
    formdata.append("note", note)
    formdata.append("is_important", isImp)

    fetch(URL_CREATE_CO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdatePurchaseOrderNote = (po_id, id, note, isImp) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("purchaseOrderId", po_id)
    formdata.append("id", id)
    formdata.append("note", note)
    formdata.append("is_important", isImp)

    fetch(URL_UPDATE_PO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateCustomerOrderNote = (co_id, id, note, isImp) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", co_id)
    formdata.append("id", id)
    formdata.append("note", note)
    formdata.append("is_important", isImp)

    fetch(URL_UPDATE_CO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeletePurchaseOrderNote = (po_id, id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("purchaseOrderId", po_id)
    formdata.append("id", id)

    fetch(URL_DELETE_PO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeleteCustomerOrderNote = (co_id, id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", co_id)
    formdata.append("id", id)

    fetch(URL_DELETE_CO_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreatePO_Payment = (poInvoiceId, sum, storedPaid, date, notes) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("poInvoiceId", poInvoiceId)
    formdata.append("sum", sum)
    formdata.append("paid", storedPaid)
    formdata.append("date", date)
    formdata.append("notes", notes)

    fetch(URL_CREATE_PO_PAYMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdatePO_Payment = (invoice_id, id, sum, date, notes) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("poInvoiceId", invoice_id)
    formdata.append("id", id)
    formdata.append("sum", sum)
    formdata.append("paid", sum)
    formdata.append("date", date)
    formdata.append("notes", notes)

    fetch(URL_UPDATE_PO_PAYMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callDeletePO_Payment = (invoice_id, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("poInvoiceId", invoice_id)
    formdata.append("id", id)

    fetch(URL_DELETE_PO_PAYMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callSinglePO_Payment = (poInvoiceId, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("poInvoiceId", poInvoiceId)
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_PAYMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callListPO_Payment = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("purchaseOrderId", id)

    fetch(URL_PO_PAYMENT_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callListPO_Invoices = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("purchaseOrderId", id)

    fetch(URL_PO_INVOICE_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callGetSingleTermDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_TERM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeleteTerm = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_TERM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callContactList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_CONTACT_PERSON, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleContactPerson = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_GET_SINGLE_CONTACT_PERSON, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callContactListWithFilter = (start, length, Con_Number, Con_Name, Con_Status, Con_Phone, Con_Email, Con_ContactsName, Con_ContactsPosition, Con_ContactsEmail, Con_ContactsPhone, fieldName) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", Con_Number)
    formdata.append("name", Con_Name)
    formdata.append("status", Con_Status)
    formdata.append("phone", Con_Phone)
    formdata.append("email", Con_Email)
    formdata.append("contactName", Con_ContactsName)
    formdata.append("contactPosition", Con_ContactsPosition)
    formdata.append("contactEmail", Con_ContactsEmail)
    formdata.append("contactPhone", Con_ContactsPhone)
    formdata.append("fieldName", fieldName)

    fetch(URL_GET_CONTACT_PERSON, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateNewTerm = (termTitle, termsFor, values, showTermInCoInvoice, showTermInPoInvoice) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('terms', termTitle)
    formdata.append('termsFor', termsFor)
    formdata.append('options', values)
    formdata.append('showTermInCoInvoice', showTermInCoInvoice)
    formdata.append('showTermInPoInvoice', showTermInPoInvoice)

    fetch(URL_CREATE_NEW_TERMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateTerm = (id, termTitle, termsFor, values, showTermInCoInvoice, showTermInPoInvoice) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('id', id)
    formdata.append('terms', termTitle)
    formdata.append('termsFor', termsFor)
    formdata.append('options', values)
    formdata.append('showTermInCoInvoice', showTermInCoInvoice)
    formdata.append('showTermInPoInvoice', showTermInPoInvoice)

    fetch(URL_UPDATE_TERM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllTermsCondition = (terms, termsFor, is_default) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('terms', terms)
    formdata.append('termsFor', termsFor)
    formdata.append('is_default', is_default)

    fetch(URL_GET_ALL_TERMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callProductGroupList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_PRODUCT_GROUP, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSellableProductGroup = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_SELLABLE_ITEM_GROUP, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetVendorProductPrice = (itemId, vendorId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemId", itemId)
    formdata.append("vendorId", vendorId)

    fetch(URL_GET_VENDOR_PRODUCT_PRICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetProductByVendor = (productGroupId, vendorId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("vendorId", vendorId)
    formdata.append("productGroupId", productGroupId)

    fetch(URL_GET_PRODUCT_BY_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetProductByVendorForCreate = (productGroupId, vendorId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("vendorId", vendorId)
    formdata.append("productGroupId", productGroupId)

    fetch(URL_GET_PRODUCT_BY_VENDOR_FOR_CREATE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetGroupByVendor = (vendorId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("vendorId", vendorId)

    fetch(URL_GET_GROUP_BY_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetProductionListByUser = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_EMPLOYEE_PRODUCTION, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callStartMO = (manufactureOrderId, operationId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", manufactureOrderId)
    formdata.append("operationId", operationId)

    fetch(URL_START_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callPlayPauseMO = (id, operationId, actionFlag) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("operationId", operationId)
    formdata.append("actionFlag", actionFlag)

    fetch(URL_PLAY_OR_PAUSE_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCalculateQTY = (id, quantity, note, rejectedQuantity) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("quantity", quantity)
    formdata.append("rejectedQuantity", rejectedQuantity)

    if (note !== "")
      formdata.append("note", note)

    fetch(URL_CALCULATE_QUANTITY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callFinishMO = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_FINISH_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSingleFilterTableData = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_CUSTOM_TABLE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSingleItem = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_ITEM_DATA, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCustomerSingleDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_CUSTOMER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDashboardData = (minDate, maxDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("startDate", minDate)
    formdata.append("finishDate", maxDate)

    fetch(URL_GET_DASHBOARD_DATA, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callDashboardUpdateData = (dashboardSequence) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    let formdata = new FormData()
    formdata.append("dashboardSequence", dashboardSequence)
    // formdata.append("finishDate", maxDate)

    fetch(URL_UPDATE_DASHBOARD_DATA, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => { //console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callOrderRevisionDetail = (id, revId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("revision_id", revId)

    fetch(URL_REVISION_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callRevisionsList = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_REVISION_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data.revisionData)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callProductGroupItemsList = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", id)

    fetch(URL_GET_PRODUCT_BY_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callProductGroupItemsListForCreate = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", id)

    fetch(URL_GET_PRODUCT_BY_GROUP_FOR_CREATE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callProductGroupItemsWithSellingPrice = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", id)

    fetch(URL_GET_PRODUCT_BY_GROUP_AND_SELLING_RPICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callProductGroupItemsWithSellingPriceForCreate = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", id)

    fetch(URL_GET_PRODUCT_BY_GROUP_AND_SELLING_RPICE_FOR_CREATE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callCustomerShippingAddress = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SHIPPING_ADDRESS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callVendorShippingAddress = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SHIPPING_ADDRESS_DEBT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callItemList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("start", 0)
    formdata.append("minRawCost", "")
    formdata.append("maxRawCost", "")
    formdata.append("fieldName", "")

    fetch(URL_GET_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetItemList = (itemCode, partDescription, groupNumber, groupName, UoM, minValue, maxValue, note, start, length, minInStock, maxInStock, minAvailable, maxAvailable, minExpectedAvailable, maxExpectedAvailable, minBooked, maxBooked, minCost, maxCost, minRawCost, maxRawCost, fieldName) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    if (start !== "")
      formdata.append("start", start)
    if (length !== "")
      formdata.append("length", length)
    if (itemCode !== "")
      formdata.append("itemCode", itemCode)
    if (partDescription !== "")
      formdata.append("partDescription", partDescription)
    if (groupNumber !== "")
      formdata.append("groupNumber", groupNumber)
    if (groupName !== "")
      formdata.append("groupName", groupName)
    if (UoM !== "")
      formdata.append("UoM", UoM)
    if (minValue !== "")
      formdata.append("minQuantity", minValue)
    if (maxValue !== "")
      formdata.append("maxQuantity", maxValue)
    if (note !== "")
      formdata.append("note", note)
    if (minInStock !== "")
      formdata.append("minInStock", minInStock)
    if (maxInStock !== "")
      formdata.append("maxInStock", maxInStock)
    if (minAvailable !== "")
      formdata.append("minAvailable", minAvailable)
    if (maxAvailable !== "")
      formdata.append("maxAvailable", maxAvailable)
    if (minExpectedAvailable !== "")
      formdata.append("minExpectedAvailable", minExpectedAvailable)
    if (maxExpectedAvailable !== "")
      formdata.append("maxExpectedAvailable", maxExpectedAvailable)
    if (minBooked !== "")
      formdata.append("minBooked", minBooked)
    if (maxBooked !== "")
      formdata.append("maxBooked", maxBooked)
    if (minCost !== "")
      formdata.append("minCost", minCost)
    if (maxCost !== "")
      formdata.append("maxCost", maxCost)

    formdata.append("minRawCost", minRawCost)
    formdata.append("maxRawCost", maxRawCost)
    formdata.append("fieldName", fieldName)

    fetch(URL_GET_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetCriticalOnHandItem = (partNo, partDescription, groupNumber, groupName, UoM, minValue, maxValue, note, start, length, minInStock, maxInStock, minAvailable, maxAvailable, minExpectedAvailable, maxExpectedAvailable, minBooked, maxBooked) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();

    formdata.append("start", start)
    formdata.append("length", length)


    if (partNo !== "")
      formdata.append("partNo", partNo)
    if (partDescription !== "")
      formdata.append("partDescription", partDescription)
    if (groupNumber !== "")
      formdata.append("groupNumber", groupNumber)
    if (groupName !== "")
      formdata.append("groupName", groupName)
    if (UoM !== "")
      formdata.append("UoM", UoM)
    if (minValue !== "")
      formdata.append("minQuantity", minValue)
    if (maxValue !== "")
      formdata.append("maxQuantity", maxValue)
    if (note !== "")
      formdata.append("note", note)
    if (minInStock !== "")
      formdata.append("minInStock", minInStock)
    if (maxInStock !== "")
      formdata.append("maxInStock", maxInStock)
    if (minAvailable !== "")
      formdata.append("minAvailable", minAvailable)
    if (maxAvailable !== "")
      formdata.append("maxAvailable", maxAvailable)
    if (minExpectedAvailable !== "")
      formdata.append("minExpectedAvailable", minExpectedAvailable)
    if (maxExpectedAvailable !== "")
      formdata.append("maxExpectedAvailable", maxExpectedAvailable)
    if (minBooked !== "")
      formdata.append("minBooked", minBooked)
    if (maxBooked !== "")
      formdata.append("maxBooked", maxBooked)


    fetch(URL_GET_CRITICAL_ON_HAND_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateCustomerQuotation = (customer, status, deliverydate, deliveryterms, reference, shippingaddress, internalNotes, productGroupData, totalOfProductCost, discountPercentage, discountValue, quotationMessage, quotationMessageFooter, rfqNo, rfqDate, quotationValidityEnd, poNo, poDate, termsAndConditions, contactPerson, accountManager, documentPermission, totalOfRawCostInOther, poDeliveryDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customer", customer)
    formdata.append("status", status)
    formdata.append("deliveryDate", deliverydate)
    formdata.append("deliveryTerms", deliveryterms)
    formdata.append("reference", reference)
    formdata.append("shippingAddress", shippingaddress)
    formdata.append("internalNotes", internalNotes)
    formdata.append("productGroupData", productGroupData)
    formdata.append("total", totalOfProductCost)
    formdata.append("discountPercentage", discountPercentage)
    formdata.append("discountValue", discountValue)
    formdata.append("quotationMessage", quotationMessage)
    formdata.append("quotationMessageFooter", quotationMessageFooter)
    formdata.append("rfqNo", rfqNo)
    formdata.append("rfqDate", rfqDate)
    formdata.append("poNo", poNo)
    formdata.append("poDate", poDate)
    formdata.append("termsCondition", termsAndConditions)
    formdata.append("contactPerson", contactPerson)
    formdata.append("quotationValidityEnd", quotationValidityEnd)
    formdata.append("accountManager", accountManager)
    formdata.append("documentPermission", documentPermission)
    formdata.append("totalInOtherCurrency", totalOfRawCostInOther)
    formdata.append("poDeliveryDate", poDeliveryDate)


    fetch(URL_CREATE_CUSTOMER_QUOTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateCustomerOrder = (customer, status, deliverydate, deliveryterms, reference, shippingaddress, internalNotes, productGroupData, totalOfProductCost, discountPercentage, discountValue, quotationMessage, quotationMessageFooter, rfqNo, rfqDate, quotationValidityEnd, poNo, poDate, termsAndConditions, contactPerson, accountManager) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customer", customer)
    formdata.append("status", status)
    formdata.append("deliveryDate", deliverydate)
    formdata.append("deliveryTerms", deliveryterms)
    formdata.append("reference", reference)
    formdata.append("shippingAddress", shippingaddress)
    formdata.append("internalNotes", internalNotes)
    formdata.append("productGroupData", productGroupData)
    formdata.append("total", totalOfProductCost)
    formdata.append("discountPercentage", discountPercentage)
    formdata.append("discountValue", discountValue)
    formdata.append("quotationMessage", quotationMessage)
    formdata.append("quotationMessageFooter", quotationMessageFooter)
    formdata.append("rfqNo", rfqNo)
    formdata.append("rfqDate", rfqDate)
    formdata.append("poNo", poNo)
    formdata.append("poDate", poDate)
    formdata.append("termsCondition", termsAndConditions)
    formdata.append("contactPerson", contactPerson)
    formdata.append("quotationValidityEnd", quotationValidityEnd)
    formdata.append("accountManager", accountManager)

    fetch(URL_CREATE_CUSTOMER_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callUpdateCustomerQuotation = (id, number, customer, status, deliverydate, deliveryterms, reference, shippingaddress, internalNotes, productGroupData, totalOfProductCost, discountPercentage, discountValue, quotationMessage, quotationMessageFooter, rfqDate, quotationValidityEnd, rfqNo, poNo, poDate, termsAndConditions, contactPerson, accountManager, documentPermission, totalOfRawCostInOther, poDeliveryDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'


  console.log(documentPermission)

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("number", number)
    formdata.append("customer", customer)
    formdata.append("status", status)
    formdata.append("deliveryDate", deliverydate)
    formdata.append("deliveryTerms", deliveryterms)
    formdata.append("reference", reference)
    formdata.append("shippingAddress", shippingaddress)
    formdata.append("internalNotes", internalNotes)
    formdata.append("productGroupData", productGroupData)
    formdata.append("total", totalOfProductCost)
    formdata.append("discountPercentage", discountPercentage)
    formdata.append("discountValue", discountValue)
    formdata.append("quotationMessage", quotationMessage)
    formdata.append("quotationMessageFooter", quotationMessageFooter)
    formdata.append("rfqNo", rfqNo)
    formdata.append("rfqDate", rfqDate)
    formdata.append("quotationValidityEnd", quotationValidityEnd)
    formdata.append("poNo", poNo)
    formdata.append("poDate", poDate)
    formdata.append("termsCondition", termsAndConditions)
    formdata.append("contactPerson", contactPerson)
    formdata.append("accountManager", accountManager)
    formdata.append("documentPermission", documentPermission)
    formdata.append("totalInOtherCurrency", totalOfRawCostInOther)
    formdata.append("poDeliveryDate", poDeliveryDate)

    fetch(URL_UPDATE_CUSTOMER_QUOTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateCustomerOrder = (id, number, customer, status, deliverydate, deliveryterms, reference, shippingaddress, internalNotes, productGroupData, totalOfProductCost, discountPercentage, discountValue, quotationMessage, quotationMessageFooter, rfqDate, quotationValidityEnd, rfqNo, poNo, poDate, termsAndConditions, contactPerson, accountManager) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("number", number)
    formdata.append("customer", customer)
    formdata.append("status", status)
    formdata.append("deliveryDate", deliverydate)
    formdata.append("deliveryTerms", deliveryterms)
    formdata.append("reference", reference)
    formdata.append("shippingAddress", shippingaddress)
    formdata.append("internalNotes", internalNotes)
    formdata.append("productGroupData", productGroupData)
    formdata.append("total", totalOfProductCost)
    formdata.append("discountPercentage", discountPercentage)
    formdata.append("discountValue", discountValue)
    formdata.append("quotationMessage", quotationMessage)
    formdata.append("quotationMessageFooter", quotationMessageFooter)
    formdata.append("rfqNo", rfqNo)
    formdata.append("rfqDate", rfqDate)
    formdata.append("quotationValidityEnd", quotationValidityEnd)
    formdata.append("poNo", poNo)
    formdata.append("poDate", poDate)
    formdata.append("termsCondition", termsAndConditions)
    formdata.append("contactPerson", contactPerson)
    formdata.append("accountManager", accountManager)

    fetch(URL_UPDATE_CUSTOMER_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllAccountManagers = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ACCOUNT_MANAGET_LIST, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSubUserList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_SUB_USERS_LIST, {
      method: 'GET',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => { //console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSubUserListWithFilter = (username, email, designation, departments, start, length) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("username", username)
    formdata.append("email", email)
    formdata.append("designation", designation)
    formdata.append("departments", departments)
    formdata.append("start", start)
    formdata.append("length", length)

    fetch(URL_GET_SUB_USERS_LIST_WITH_FILTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callDownloadPDFForOrder = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DOWNLOAD_CUSTOMER_ORDER_DETAILS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callHTMLContentPDFOrder = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_HTML_CONTENT_FOR_ORDER_PDF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDownloadPumpRegisterSampleCSV = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_DOWNLOAD_EXCELFILE_FOR_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDownloadSampleCSV = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_DOWNLOAD_EXCELFILE_FOR_ITEM, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDownloadSampleVendorCSV = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_DOWNLOAD_EXCELFILE_FOR_VENDOR, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUploadPumpRegisterCSV = (file) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("xlsFile", file)


    fetch(URL_UPLOAD_EXCELFILE_FOR_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUploadItemCSV = (file) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("xlsFile", file)


    fetch(URL_UPLOAD_EXCELFILE_FOR_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUploadCOItems = (id, customerOrderItem) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("customerOrderItem", customerOrderItem)

    fetch(URL_UPLOAD_EXCELFILE_FOR_CO_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUploadVendorCSV = (file) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("xlsFile", file)


    fetch(URL_UPLOAD_EXCELFILE_FOR_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}






export const callFilterTableDetail = (id, filterFlag) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("filterFlag", filterFlag)

    fetch(URL_GET_SINGLE_FILTER_TABLE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callVendorDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log('vendor details',  response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCustomerOrderDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_CUSTOMER_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCOShipmentInvoices = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", id)

    fetch(URL_CO_SHIPMENT_INVOICES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callShipmentListByOrderId = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_GET_SHIPMENT_BY_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetBookingList = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_BOOKING_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetSingleUnitofmeasurement = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_UNITOFMEASUREMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetOrderQuotationDetails = (id, revisionId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("revisionId", revisionId)

    fetch(URL_CUSTOMER_ORDER_QUOTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetPurchaseOrderPDFDetails = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_PURCHASE_ORDER_PDF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callContactPerson = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyId", id)

    fetch(URL_GET_COMPANY_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callVendorList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_GET_VENDOR, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateDeliveryTerms = (deliveryTerms) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("deliveryTerms", JSON.stringify(deliveryTerms))

    fetch(URL_CREATE_DELIVERY_TERMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeliveryTermsList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_DELIVERY_TERMS, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateExpenseFile = (expenseId, expenseFile) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formData = new FormData()
    formData.append("expenseId", expenseId)
    formData.append("expenseFile", expenseFile)

    fetch(URL_CREATE_EXPENSE_FILE, {
      method: 'POST',
      headers: myHeaders,
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callEditExpenseFile = (_id, expenseId, expenseFile) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formData = new FormData()
    formData.append("_id", _id)
    formData.append("expenseId", expenseId)
    formData.append("expenseFile", expenseFile)

    fetch(URL_UPDATE_EXPENSE_FILE, {
      method: 'POST',
      headers: myHeaders,
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteExpenseFile = (_id, expenseId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formData = new FormData()
    formData.append("_id", _id)
    formData.append("expenseId", expenseId)

    fetch(URL_DELETE_EXPENSE_FILE, {
      method: 'POST',
      headers: myHeaders,
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callOrderStatus = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_GET_ORDER_STATUS, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetDefaultQuotationMessages = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_GET_QUOTATION_DEFAULT_MESSAGES, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSetDefaultQuotationMessages = (header, footer) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("headerText", header)
    formdata.append("footerText", footer)

    fetch(URL_SET_QUOTATION_DEFAULT_MESSAGES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateOrderStatus = (orderStatus) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("orderStatus", JSON.stringify(orderStatus))

    fetch(URL_CREATE_ORDER_STATUS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.message)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCompanyIndustry = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_GET_COMPANY_INDUSTRY, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetHolidaysList = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_GET_HOLIDAYS_LIST, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callEditHolidaysList = (holidayDetails) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("holidayDetails", holidayDetails)

    fetch(URL_CREATE_HOLIDAYS_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreatePurchaseItem = (product, vendor, vendorPartNo, priority, leadTime, priceperUoM, minQuantity, is_vendorSpecicUnitOfMeasurement, vendorUoM, is_indivisibleUoM, conversionRate, hsCode, tax, taxCredit) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("product", product)
    formdata.append("vendor", vendor)
    formdata.append("vendorPartNo", vendorPartNo)
    formdata.append("priority", priority)
    formdata.append("leadTime", leadTime)
    formdata.append("priceperUoM", priceperUoM)
    formdata.append("minQuantity", minQuantity)
    formdata.append("is_vendorSpecicUnitOfMeasurement", is_vendorSpecicUnitOfMeasurement)
    formdata.append("vendorUoM", vendorUoM)
    formdata.append("is_indivisibleUoM", is_indivisibleUoM)
    formdata.append("conversionRate", conversionRate)
    formdata.append("hsCode", hsCode)
    formdata.append("tax", tax)
    formdata.append("taxCredit", taxCredit)

    fetch(URL_CREATE_PURCHASE_TERMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdatePurchaseItem = (id, vendor, vendorPartNo, priority, leadTime, priceperUoM, minQuantity, is_vendorSpecicUnitOfMeasurement, vendorUoM, is_indivisibleUoM, conversionRate, hsCode, tax, taxCredit) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("vendor", vendor)
    formdata.append("vendorPartNo", vendorPartNo)
    formdata.append("priority", priority)
    formdata.append("leadTime", leadTime)
    formdata.append("priceperUoM", priceperUoM)
    formdata.append("minQuantity", minQuantity)
    formdata.append("is_vendorSpecicUnitOfMeasurement", is_vendorSpecicUnitOfMeasurement)
    formdata.append("vendorUoM", vendorUoM)
    formdata.append("is_indivisibleUoM", is_indivisibleUoM)
    formdata.append("conversionRate", conversionRate)
    formdata.append("hsCode", hsCode)
    formdata.append("tax", tax)
    formdata.append("taxCredit", taxCredit)

    fetch(URL_UPDATE_PURCHASE_TERMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeletePurchaseTerms = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_PURCHASE_TERMS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callPurchaseTermsList = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productId", id)

    fetch(URL_PURCHASE_TERMS_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callPurchaseTermsDetails = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_PURCHASE_TERMS_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callVendorContactList = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formData = new FormData();
    formData.append("id", id)

    fetch(URL_PURCHASE_TERM_CONTACTS, {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


/* DELETE RECORD/ENTRY APIs */


export const callDeleteCompany = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formData = new FormData();
    formData.append("id", id)

    fetch(URL_DELETE_COMPANY, {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callDeleteCustomerOrder = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formData = new FormData();
    formData.append("id", id)

    fetch(URL_DELETE_CUSTOMER_ORDER, {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateTask = (subject, assignedId, description, dueDate, is_done) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("subject", subject)
    formdata.append("assignedId", assignedId)
    formdata.append("description", description)
    formdata.append("dueDate", dueDate)
    formdata.append("is_done", is_done)

    fetch(URL_CREATE_TASK, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateVendor = (name, contactInformation, defaultLeadTime, paymentPeriods, deliveryTerms, paymentTerms, taxApplicable, panNo, gstNo, currency) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name);
    formdata.append("contactInformation", JSON.stringify(contactInformation));
    formdata.append("defaultLeadTime", defaultLeadTime);
    formdata.append("paymentPeriods", paymentPeriods);
    formdata.append("deliveryTerms", deliveryTerms);
    formdata.append("paymentTerms", JSON.stringify(paymentTerms));
    formdata.append("taxApplicable", JSON.stringify(taxApplicable))
    formdata.append("panNo", panNo)
    formdata.append("gstNo", gstNo)
    formdata.append("currency", currency)

    fetch(URL_CREATE_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateVendor = (id, name, contactInformation, defaultLeadTime, paymentPeriods, deliveryTerms, paymentTerms, taxApplicable, panNo, gstNo, currency) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("name", name);
    formdata.append("contactInformation", JSON.stringify(contactInformation));
    formdata.append("defaultLeadTime", defaultLeadTime);
    formdata.append("paymentPeriods", paymentPeriods);
    formdata.append("deliveryTerms", deliveryTerms);
    formdata.append("paymentTerms", JSON.stringify(paymentTerms));
    formdata.append("taxApplicable", JSON.stringify(taxApplicable))
    formdata.append("panNo", panNo)
    formdata.append("gstNo", gstNo)
    formdata.append("currency", currency)

    fetch(URL_UPDATE_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateTask = (id, subject, assignedId, description, dueDate, is_done) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("subject", subject)
    formdata.append("assignedId", assignedId)
    formdata.append("description", description)
    formdata.append("dueDate", dueDate)
    formdata.append("is_done", is_done)

    fetch(URL_UPDATE_TASK, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateIsReadTask = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_UPDATE_IS_READ_TASK, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callSingleTask = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_TASK, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callAllTasks = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_TASKS, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
  })
}
export const callDeleteAllTasks = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_DELETE_TASKS, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callDeleteTask = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_SINGLE_TASK, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callAddTaskNote = (taskManagementId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("taskManagementId", taskManagementId)
    formdata.append("note", note)

    fetch(URL_ADD_TASK_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callEditTaskNote = (taskManagementId, id, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("taskManagementId", taskManagementId)
    formdata.append("note", note)
    formdata.append("id", id)

    fetch(URL_EDIT_TASK_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callDeleteTaskNote = (taskManagementId, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("taskManagementId", taskManagementId)
    formdata.append("id", id)

    fetch(URL_DELETE_TASK_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateStockLot = (status, productGroup, stockItem, totalQuantity, available, costPerUnit, totalCost, storageLocation, availableFrom) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("status", status)
    formdata.append("productGroup", productGroup)
    formdata.append("stockItem", stockItem)
    formdata.append("totalQuantity", totalQuantity)
    formdata.append("available", available)
    formdata.append("costPerUnit", costPerUnit)
    formdata.append("totalCost", totalCost)
    formdata.append("storageLocation", storageLocation)
    formdata.append("availableFrom", availableFrom)

    fetch(URL_CREATE_STOCK_LOT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateStockLot = (id, status, productGroup, stockItem, totalQuantity, available, costPerUnit, totalCost, storageLocation, availableFrom) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("status", status)
    formdata.append("productGroup", productGroup)
    formdata.append("stockItem", stockItem)
    formdata.append("totalQuantity", totalQuantity)
    formdata.append("available", available)
    formdata.append("costPerUnit", costPerUnit)
    formdata.append("totalCost", totalCost)
    formdata.append("storageLocation", storageLocation)
    formdata.append("availableFrom", availableFrom)

    fetch(URL_UPDATE_STOCK_LOT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSingleStockLot = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_STOCK_LOT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSingleStorageLocation = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_STORAGE_LOCATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}





export const callGetLotByStockItem = (productGroup, stockItem) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", productGroup)
    formdata.append("stockItem", stockItem)

    fetch(URL_GET_LOT_BY_STOCK_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteStockLot = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_STOCK_LOT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callMoveStockItem = (stockLotId, id, storageLocationId, quantity) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("stockLotId", stockLotId)
    formdata.append("id", id)
    formdata.append("storageLocationId", storageLocationId)
    formdata.append("quantity", quantity)

    fetch(URL_MOVE_STOCK_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetAllStorageLocation = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ALL_STORAGE_LOCATION, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetAllUnitOfMeasurement = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ALL_UNIT_OF_MEASUREMENT, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callStockLotsList = (
  number,
  minCreated,
  maxCreated,
  storageLocationId,
  storageLocation,
  partNo,
  partDescription,
  mincostPerUnit,
  maxcostPerUnit,
  minQuantity,
  maxQuantity,
  minavailable,
  maxavailable,
  minavailableFrom,
  maxavailableFrom,
  UoM,
  status,
  start,
  length,
  minbooked,
  maxbooked
) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("number", number)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("storageLocationId", storageLocationId)
    formdata.append("storageLocation", storageLocation)
    formdata.append("partNo", partNo)
    formdata.append("partDescription", partDescription)
    formdata.append("mincostPerUnit", mincostPerUnit)
    formdata.append("maxcostPerUnit", maxcostPerUnit)
    formdata.append("minQuantity", minQuantity)
    formdata.append("maxQuantity", maxQuantity)
    formdata.append("minavailable", minavailable)
    formdata.append("maxavailable", maxavailable)
    formdata.append("minavailableFrom", minavailableFrom)
    formdata.append("maxavailableFrom", maxavailableFrom)
    formdata.append("UoM", UoM)
    formdata.append("status", status)
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("minbooked", minbooked)
    formdata.append("maxbooked", maxbooked)

    fetch(URL_GET_STOCK_LOT_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



const URL_CREATE_STOCKLOT_NOTE = `${WEBSERVICES_URL}stock/stockLots/addStockLotNotes`
const URL_UPDATE_STOCKLOT_NOTE = `${WEBSERVICES_URL}stock/stockLots/updateStockLotNotes`
const URL_DELETE_STOCKLOT_NOTE = `${WEBSERVICES_URL}stock/stockLots/deleteStockLotNotes`


export const callCreateStockLotNote = (stockLotId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("stockLotId", stockLotId)
    formdata.append("note", note)

    fetch(URL_CREATE_STOCKLOT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateStockLotNote = (stockLotId, id, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("stockLotId", stockLotId)
    formdata.append("id", id)
    formdata.append("note", note)

    fetch(URL_UPDATE_STOCKLOT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteStockLotNote = (stockLotId, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("stockLotId", stockLotId)
    formdata.append("id", id)

    fetch(URL_DELETE_STOCKLOT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeleteWriteOff = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_WRITEOFF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetSingleWriteOff = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_WRITEOFF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callReleaseStockItem = (writeOffId, id, releaseStockItem) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("writeOffId", writeOffId)
    formdata.append("id", id)
    formdata.append("releaseStockItem", releaseStockItem)

    fetch(URL_RELEASE_STOCK_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateWriteOff = (productGroup, stockItem, stockDetail, note, type, writrOffSource, orderId, getOrderType) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", productGroup)
    formdata.append("stockItem", stockItem)
    formdata.append("stockDetail", stockDetail)
    formdata.append("note", note)
    formdata.append("type", type)
    formdata.append("writrOffSource", writrOffSource)

    if (getOrderType === "customerOrder")
      formdata.append("customerOrderId", orderId)
    else
      formdata.append("manufactureOrderId", orderId)


    fetch(URL_CREATE_WRITEOFF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetWriteoffList = (length, start, number, partNo, partDescription, groupNumber, groupName, minQuantity, maxQuantity, mincost, maxcost, minCreated, maxCreated, status, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("length", length)
    formdata.append("start", start)
    formdata.append("number", number)
    formdata.append("partNo", partNo)
    formdata.append("partDescription", partDescription)
    formdata.append("groupNumber", groupNumber)
    formdata.append("groupName", groupName)
    formdata.append("minQuantity", minQuantity)
    formdata.append("maxQuantity", maxQuantity)
    formdata.append("mincost", mincost)
    formdata.append("maxcost", maxcost)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("status", status)
    formdata.append("note", note)

    fetch(URL_GET_WRITEOFF_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}






export const callGetWriteOffType = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_WRITEOFF_TYPE, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateWriteOffType = (type) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {

    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("type", type)

    fetch(URL_CREATE_WRITEOFF_TYPE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCancelSingle = (customerOrderId, itemId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)
    formdata.append("itemId", itemId)

    fetch(URL_CANCEL_SINGLE_BOOKING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callBookingAll = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_BOOK_ALL_BOOKING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCancelAllBooking = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_CANCEL_ALL_BOOKING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateWorkstationGroup = (name, numberOfInstances, is_hourlyRate, hourlyRateValue, is_customWorkingHours, customWorkingHours, is_customHoliday, customHoliday, colorValue) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name)
    formdata.append("numberOfInstances", numberOfInstances)
    formdata.append("is_hourlyRate", is_hourlyRate)
    formdata.append("hourlyRateValue", hourlyRateValue)
    formdata.append("is_customWorkingHours", is_customWorkingHours)
    formdata.append("customWorkingHours", JSON.stringify(customWorkingHours))
    formdata.append("is_customHoliday", is_customHoliday)
    formdata.append("customHoliday", JSON.stringify(customHoliday))
    formdata.append("colorValue", colorValue)

    fetch(URL_CREATE_WORKSTATION_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateWorkstationGroup = (id, name, numberOfInstances, is_hourlyRate, hourlyRateValue, is_customWorkingHours, customWorkingHours, is_customHoliday, customHoliday, colorValue) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("name", name)
    formdata.append("numberOfInstances", numberOfInstances)
    formdata.append("is_hourlyRate", is_hourlyRate)
    formdata.append("hourlyRateValue", hourlyRateValue)
    formdata.append("is_customWorkingHours", is_customWorkingHours)
    formdata.append("customWorkingHours", JSON.stringify(customWorkingHours))
    formdata.append("is_customHoliday", is_customHoliday)
    formdata.append("customHoliday", JSON.stringify(customHoliday))
    formdata.append("colorValue", colorValue)

    fetch(URL_UPDATE_WORKSTATION_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSingleWorkstationGroup = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_WORKSTATION_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callAllWorkstationGroup = (number, name, minInstance, maxInstance, length, start) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("number", number)
    formdata.append("name", name)
    formdata.append("minInstance", minInstance)
    formdata.append("maxInstance", maxInstance)
    formdata.append("length", length)
    formdata.append("start", start)

    fetch(URL_GET_ALL_WORKSTATION_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => { // console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteWorkstationGroup = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_WORKSTATION_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateDepartment = (name, is_calculatedAutomatically, is_setManually, averageHourlyValue) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name)
    formdata.append("is_calculatedAutomatically", is_calculatedAutomatically)
    formdata.append("is_setManually", is_setManually)
    formdata.append("averageHourlyValue", averageHourlyValue)

    fetch(URL_CREATE_DEPARTMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateDepartment = (id, name, is_calculatedAutomatically, is_setManually, averageHourlyValue) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("name", name)
    formdata.append("is_calculatedAutomatically", is_calculatedAutomatically)
    formdata.append("is_setManually", is_setManually)
    formdata.append("averageHourlyValue", averageHourlyValue)

    fetch(URL_UPDATE_DEPARTMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleDepartment = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_DEPARTMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetAllDepartment = (number, name) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("number", number)
    formdata.append("name", name)

    fetch(URL_GET_ALL_DEPARTMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetDepartmentByWS = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_DEPARTMENT_BY_WORKSTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log("Dept By WS Id : " + id, response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetUsersByDepartment = (departmentId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("departmentId", departmentId)

    fetch(URL_GET_ALL_USERS_OF_DEPARTMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateDefaultWorker = (moId, id, userId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("moId", moId)
    formdata.append("id", id)
    formdata.append("userId", userId)

    fetch(URL_CALL_UPDATE_DEFAULT_WORKER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeleteDepartment = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_DEPARTMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateWorkstation = (workStationGroupId, is_hourlyRate, hourlyRateValue, productivity, defaultWorker, idleTime) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("workStationGroupId", workStationGroupId);
    formdata.append("is_hourlyRate", is_hourlyRate);
    formdata.append("hourlyRateValue", hourlyRateValue);
    formdata.append("productivity", productivity);
    formdata.append("defaultWorker", defaultWorker);
    formdata.append("idleTime", idleTime);

    fetch(URL_CREATE_WORKSTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateWorkstation = (id, name, is_hourlyRate, hourlyRateValue, defaultWorker, idleTime, productivity) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("is_hourlyRate", is_hourlyRate);
    formdata.append("hourlyRateValue", hourlyRateValue);
    formdata.append("productivity", productivity);
    formdata.append("defaultWorker", defaultWorker);
    formdata.append("idleTime", idleTime);
    formdata.append("name", name)

    fetch(URL_UPDATE_WORKSTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callGetSingleWorkstation = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);

    fetch(URL_GET_SINGLE_WORKSTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callGetAllWorkstation = (number, name, minHourlyRate, maxHourlyRate, minProductivity, maxProductivity, type, start, length) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("number", number);
    formdata.append("name", name);
    formdata.append("minHourlyRate", minHourlyRate);
    formdata.append("maxHourlyRate", maxHourlyRate);
    formdata.append("minProductivity", minProductivity);
    formdata.append("maxProductivity", maxProductivity);
    formdata.append("type", type);
    formdata.append("start", start);
    formdata.append("length", length);


    fetch(URL_GET_ALL_WORKSTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callDeleteWorkstation = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);

    fetch(URL_DELETE_WORKSTATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callCreateWorkstationNote = (workStationId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("workStationId", workStationId);
    formdata.append("note", note);

    fetch(URL_CREATE_WORKSTATION_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateWorkstationNote = (id, workStationId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("workStationId", workStationId);
    formdata.append("note", note);

    fetch(URL_UPDATE_WORKSTATION_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callDeleteWorkstationNote = (id, workStationId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("workStationId", workStationId);

    fetch(URL_DELETE_WORKSTATION_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateBOM = (product, routingId, parts) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('product', product)
    formdata.append('routingId', routingId)
    formdata.append('parts', parts)

    fetch(URL_CREATE_BOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateBOM = (id, product, routingId, parts) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('id', id)
    formdata.append('product', product)
    formdata.append('routingId', routingId)
    formdata.append('parts', parts)

    fetch(URL_UPDATE_BOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callGetSingleBOM = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('id', id)

    fetch(URL_GET_SINGLE_BOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetBOMExcelDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('id', id)

    fetch(URL_GET_BOM_EXCEL_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllBOM = (start, length, number, articleCode, itemDescription, minRawCost, maxRawCost, minApproxCost, maxApproxCost, aggregateFlag) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("articleCode", articleCode)
    formdata.append("itemDescription", itemDescription)

    formdata.append("minRawCost", minRawCost)
    formdata.append("maxRawCost", maxRawCost)

    formdata.append("minApproxCost", minApproxCost)
    formdata.append("maxApproxCost", maxApproxCost)
    formdata.append("aggregateFlag", aggregateFlag)


    fetch(URL_GET_ALL_BOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callDeleteBOM = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('id', id)

    fetch(URL_DELETE_BOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callBOMByProduct = (id, checkRouting) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('productId', id)
    formdata.append('checkRouting', checkRouting)

    fetch(URL_GET_BOM_BY_PRODUCT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callRoutingByProduct = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append('productId', id)

    fetch(URL_GET_ROUTING_BY_PRODUCT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateRouting = (product, bomId, is_detailedView, workStationDetail) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("product", product)
    formdata.append("bomId", bomId)
    formdata.append("is_detailedView", is_detailedView)
    formdata.append("workStationDetail", workStationDetail)

    fetch(URL_CREATE_ROUTING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateRouting = (id, product, bomId, name, number, is_detailedView, workStationDetail) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("product", product)
    formdata.append("bomId", bomId)
    formdata.append("name", name)
    formdata.append("number", number)
    formdata.append("is_detailedView", is_detailedView)
    formdata.append("workStationDetail", workStationDetail)

    fetch(URL_UPDATE_ROUTING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleRouting = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_ROUTING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetAllRouting = (number, name, articleCode, bomNumber, bomName,
  // workStationGroupNumber,workStationGroupName,otherDescription,minSetUpTime,maxSetUpTime,minCycleTime,maxCycleTime,
  start, length) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("number", number)
    formdata.append("name", name)
    formdata.append("articleCode", articleCode)
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("bomNumber", bomNumber)
    formdata.append("bomName", bomName)
    // formdata.append("workStationGroupNumber",workStationGroupNumber)
    // formdata.append("workStationGroupName",workStationGroupName)
    // formdata.append("otherDescription",otherDescription)
    // formdata.append("minSetUpTime",minSetUpTime)
    // formdata.append("maxSetUpTime",maxSetUpTime)
    // formdata.append("minCycleTime",minCycleTime)
    // formdata.append("maxCycleTime",maxCycleTime)

    fetch(URL_GET_ALL_ROUTING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteRouting = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_ROUTING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetEndDateMO = (startDate, bomDetails, quantity, moId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("startDate", startDate)
    formdata.append("bomDetails", JSON.stringify(bomDetails))
    formdata.append("quantity", quantity)
    formdata.append("moId", moId)

    fetch(URL_GET_END_DATE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateCurrency = (currencyName, conversionRate, currencySymbol) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("currencyName", currencyName)
    formdata.append("conversionRate", conversionRate)
    formdata.append("currencySymbol", currencySymbol)

    fetch(URL_CREATE_CURRENCY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateCurrency = (id, currencyName, conversionRate, currencySymbol) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("currencyName", currencyName)
    formdata.append("conversionRate", conversionRate)
    formdata.append("currencySymbol", currencySymbol)

    fetch(URL_UPDATE_CURRENCY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateStatus = (name, value) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name)
    formdata.append("value", value)

    fetch(URL_CREATE_CUSTOMER_STATUS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateStatus = (id, name, value) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("name", name)
    formdata.append("value", value)

    fetch(URL_UPDATE_CUSTOMER_STATUS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleCurrency = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_CURRENCY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetSingleStatus = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_CUSTOMER_STATUS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}




export const callGetTaxDetails = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_TAX_DETAILS, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetCompanyWorkingHours = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_COMPANY_WORKING_HOURS, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSetCompanyWorkingHours = (defaultWorkingHours) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("defaultWorkingHours", defaultWorkingHours)

    fetch(URL_SET_COMPANY_WORKING_HOURS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSetTaxDetails = (taxDetails) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("taxDetails", taxDetails)

    fetch(URL_SET_TAX_DETAILS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllCurrency = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ALL_CURRENCY, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}






export const callCustomerStatus = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    fetch(URL_GET_COMPANY_STATUS, {
      method: 'POST',
      headers: myHeaders,
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}





export const callDeleteCurrency = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_CURRENCY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteCustomerStatus = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_CUSTOMER_STATUS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}





export const callCreateMO = (productGroup, product, quantity, startDate, dueDate, assignedTo, billOfMaterials, routingDetails, department) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("productGroup", productGroup)
    formdata.append("product", product)
    formdata.append("quantity", quantity)
    formdata.append("startDate", startDate)
    formdata.append("department", department)
    // formdata.append("dueDate",dueDate)
    formdata.append("assignedTo", assignedTo)
    formdata.append("billOfMaterials", billOfMaterials)
    formdata.append("routingDetails", routingDetails)
    // formdata.append("finishDate",dueDate)

    fetch(URL_CREATE_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callBookAllMOItem = (id, neededItems) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", id)
    formdata.append("neededArr", neededItems)

    fetch(URL_BOOK_ALL_MO_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callCancelAllMOItem = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", id)

    fetch(URL_CANCEL_ALL_MO_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callCancelSingleMOItem = (id, itemId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", id)
    formdata.append("itemId", itemId)

    fetch(URL_CANCEL_SINGLE_MO_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callProductionOperations = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", id)

    fetch(URL_GET_FINAL_OPERATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callGetEventEndDate = (id, startDate) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("operationId", id)
    formdata.append("startDate", startDate)

    fetch(URL_GET_DATE_FOR_PP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })

}


export const callGetAllProductionPlanning = (start, length, number, partNo, partDescription, minQuantity, maxQuantity, status, minStartDate, maxStartDate, minEndDate, maxEndDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("partNo", partNo)
    formdata.append("partDescription", partDescription)
    formdata.append("minQuantity", minQuantity)
    formdata.append("maxQuantity", maxQuantity)
    formdata.append("status", status)
    formdata.append("minStartDate", minStartDate)
    formdata.append("maxStartDate", maxStartDate)
    formdata.append("minEndDate", minEndDate)
    formdata.append("maxEndDate", maxEndDate)

    fetch(URL_GET_PRODUCTION_PLANNING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}

export const callUpdateMO = (id, productGroup, product, quantity, startDate, dueDate, finishDate, assignedTo, billOfMaterials, routingDetails, flagEndDateAPICall, department) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("productGroup", productGroup)
    formdata.append("product", product)
    formdata.append("quantity", quantity)
    formdata.append("startDate", startDate)
    formdata.append("dueDate", dueDate)
    formdata.append("finishDate", finishDate)
    formdata.append("assignedTo", assignedTo)
    formdata.append("billOfMaterials", billOfMaterials)
    formdata.append("routingDetails", routingDetails)
    formdata.append("callGetEndDateFlag", !flagEndDateAPICall)
    formdata.append("department", department)

    fetch(URL_UPDATE_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}

export const callGetMOWriteOff = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("manufactureOrderId", id)

    fetch(URL_GET_MO_WRITEOF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}

export const callStopMOProduction = (id, stoppedDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("stoppedDate", stoppedDate)

    fetch(URL_FINISH_MO_PRODUCTION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callSingleMO = (id, defaultWorkerFlag) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("defaultWorkerFlag", defaultWorkerFlag)

    fetch(URL_GET_SINGLE_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}

export const callProductionPDF = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_PRODUCTION_PDF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}

export const callGetAllMO = (start, length, number, groupName, partNo, partDescription, minQuantity, maxQuantity, status, partStatus, minStartDate, maxStartDate, minEndDate, maxEndDate, targetLotNumber, assignedTo) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("groupName", groupName)
    formdata.append("partNo", partNo)
    formdata.append("partDescription", partDescription)
    formdata.append("minQuantity", minQuantity)
    formdata.append("maxQuantity", maxQuantity)
    formdata.append("status", status)
    formdata.append("partStatus", partStatus)
    formdata.append("minStartDate", minStartDate)
    formdata.append("maxStartDate", maxStartDate)
    formdata.append("minEndDate", minEndDate)
    formdata.append("maxEndDate", maxEndDate)
    formdata.append("targetLotNumber", targetLotNumber)
    formdata.append("assignedTo", assignedTo)


    fetch(URL_GET_ALL_MO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}



export const callCreateShipment = (deliveryDate, customerOrderId, shippingAddress, waybillNotes, wayBillDocument, pickingListNotes, trackingNumber, invoiceId, status) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("deliveryDate", deliveryDate)
    formdata.append("customerOrderId", customerOrderId)
    formdata.append("shippingAddress", shippingAddress)
    formdata.append("waybillNotes", waybillNotes)
    formdata.append("pickingListNotes", pickingListNotes)
    formdata.append("trackingNumber", trackingNumber)
    formdata.append("wayBillDocument", wayBillDocument)
    formdata.append("invoiceId", invoiceId)
    formdata.append("status", status)

    fetch(URL_CREATE_SHIPMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callGetSingleShipment = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_SHIPMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callUpdateShipment = (id, deliveryDate, shippingAddress, waybillNotes, wayBillDocument, pickingListNotes, trackingNumber, invoiceId, status) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("deliveryDate", deliveryDate)
    formdata.append("shippingAddress", shippingAddress)
    formdata.append("waybillNotes", waybillNotes)
    formdata.append("pickingListNotes", pickingListNotes)
    formdata.append("trackingNumber", trackingNumber)
    formdata.append("wayBillDocument", wayBillDocument)
    formdata.append("invoiceId", invoiceId)
    formdata.append("status", status)

    fetch(URL_UPDATE_SHIPMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callGetAllShipment = (number, customerOrder, status, deliveryAddress, waybillNotes, pickingListNotes, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, start, length,) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("number", number)
    formdata.append("customerOrder", customerOrder)
    formdata.append("status", status)
    formdata.append("deliveryAddress", deliveryAddress)
    formdata.append("waybillNotes", waybillNotes)
    formdata.append("pickingListNotes", pickingListNotes)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("minDeliveryDate", minDeliveryDate)
    formdata.append("maxDeliveryDate", maxDeliveryDate)
    formdata.append("start", start)
    formdata.append("length", length)

    fetch(URL_GET_ALL_SHIPMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callDeleteShipment = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_SHIPMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}
export const callPickAllShipmentItem = (id) => {

  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_PICK_ALL_SHIPMENT_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
  })
}


export const callGetModelNo = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_MODEL_NO, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetMainMOC = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_MAIN_MOC, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callGetImpellerMOC = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_IMPELLER_MOC, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetImpellerType = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_IMPELLER_TYPE, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetMechSeal = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_MECH_SEAL, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetMotorRating = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_MOTOR_RATING, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetPumpDocumentType = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_PUMP_DOCUMENT_TYPE, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


//---- ------ ---- ----- ----- --- ------ ----- 


export const callAddModelNo = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("pumpModelNo", data)

    fetch(URL_ADD_MODEL_NO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddMainMOC = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("mainMoc", data)

    fetch(URL_ADD_MAIN_MOC, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callAddImpellerMOC = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("impellerMoc", data)

    fetch(URL_ADD_IMPELLER_MOC, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callAddImpellerType = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("impellerType", data)

    fetch(URL_ADD_IMPELLER_TYPE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddMechSeal = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("mechSeal", data)

    fetch(URL_ADD_MECH_SEAL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddMotorRating = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("motorRating", data)

    fetch(URL_ADD_MOTOR_RATING, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddPumpDocumentType = (data) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("pumpDocumentName", data)

    fetch(URL_ADD_PUMP_DOCUMENT_TYPE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata

    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllCitiesByCountry = (countryCode) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("countryCode", countryCode)

    fetch(URL_GET_ALL_CITIES_BY_COUNTRY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata

    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreatePumpDocument = (documents, name, note, pumpRegisterId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("documentFile", documents)
    formdata.append("name", name)
    formdata.append("note", note)
    formdata.append("pumpRegisterId", pumpRegisterId)

    fetch(URL_CREATE_PUMP_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreatePumpLogs = (pumpRegisterId, pumpLogFile, note, type, date) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("pumpRegisterId", pumpRegisterId)
    formdata.append("pumpLogFile", pumpLogFile)
    formdata.append("note", note)
    formdata.append("type", type)
    formdata.append("date", date)

    fetch(URL_CREATE_PUMP_LOG, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdatePumpLogs = (_id, pumpRegisterId, pumpLogFile, note, type, date) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("_id", _id)
    formdata.append("pumpRegisterId", pumpRegisterId)
    formdata.append("pumpLogFile", pumpLogFile)
    formdata.append("note", note)
    formdata.append("type", type)
    formdata.append("date", date)

    fetch(URL_UPDATE_PUMP_LOG, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeletePumpLogs = (_id, pumpRegisterId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("_id", _id)
    formdata.append("pumpRegisterId", pumpRegisterId)

    fetch(URL_DELETE_PUMP_LOG, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callAllPumpLogsDoc = (pumpRegisterId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("pumpRegisterId", pumpRegisterId)

    fetch(URL_ALL_PUMP_LOG, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreatePump = (customer, project, industryType, country, city, tagNo, application, fluid, solid, temp, serialNo, modelNo, mainMOC, impellerMOC, impellerType, impellerSize, mechSeal, head, flow, motorRating, dateOfDispatch, dateOfInstallation, deliveryDateAsPerPO) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("clientName", customer)
    formdata.append("project", project)
    formdata.append("industryType", industryType)
    formdata.append("city", city)
    formdata.append("country", country)
    formdata.append("pumpTagNo", tagNo)
    formdata.append("application", application)
    formdata.append("fluid", fluid)
    formdata.append("solid", solid)
    formdata.append("temp", temp)
    formdata.append("pumpSerialNo", serialNo)
    formdata.append("pumpModelNo", modelNo)
    formdata.append("mainMoc", mainMOC)
    formdata.append("impellerMoc", impellerMOC)
    formdata.append("impellerType", impellerType)
    formdata.append("impellerSize", impellerSize)
    formdata.append("mechSeal", mechSeal)
    formdata.append("head", head)
    formdata.append("flow", flow)
    formdata.append("motorRating", motorRating)
    formdata.append("dateOfDispatch", dateOfDispatch)
    formdata.append("dateOfInstallation", dateOfInstallation)
    formdata.append("deliveryDateAsPerPO", deliveryDateAsPerPO)
    

    fetch(URL_CREATE_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSinglePumpDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_SINGLE_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeletePump = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdatePump = (id, customer, project, industryType, country, city, tagNo, application, fluid, solid, temp, serialNo, modelNo, mainMOC, impellerMOC, impellerType, impellerSize, mechSeal, head, flow, motorRating, dateOfDispatch, dateOfInstallation, deliveryDateAsPerPO) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("clientName", customer)
    formdata.append("project", project)
    formdata.append("industryType", industryType)
    formdata.append("city", city)
    formdata.append("country", country)
    formdata.append("pumpTagNo", tagNo)
    formdata.append("application", application)
    formdata.append("fluid", fluid)
    formdata.append("solid", solid)
    formdata.append("temp", temp)
    formdata.append("pumpSerialNo", serialNo)
    formdata.append("pumpModelNo", modelNo)
    formdata.append("mainMoc", mainMOC)
    formdata.append("impellerMoc", impellerMOC)
    formdata.append("impellerType", impellerType)
    formdata.append("impellerSize", impellerSize)
    formdata.append("mechSeal", mechSeal)
    formdata.append("head", head)
    formdata.append("flow", flow)
    formdata.append("motorRating", motorRating)
    formdata.append("dateOfDispatch", dateOfDispatch)
    formdata.append("dateOfInstallation", dateOfInstallation)
    formdata.append("deliveryDateAsPerPO", deliveryDateAsPerPO)
    

    fetch(URL_UPDATE_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdatePumpDocument = (documentFile, name, note, _id, pumpRegisterId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("documentFile", documentFile)
    formdata.append("name", name)
    formdata.append("note", note)
    formdata.append("_id", _id)
    formdata.append("pumpRegisterId", pumpRegisterId)

    fetch(URL_UPDATE_PUMP_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeletePumpDocument = (_id, pumpRegisterId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("pumpRegisterId", pumpRegisterId)
    formdata.append("_id", _id)

    fetch(URL_DELETE_PUMP_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllPumpDocuments = (pumpRegisterId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("pumpRegisterId", pumpRegisterId)

    fetch(URL_GET_ALL_PUMP_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllRegisteredPump = (start, length, number, customerNumber, clientName, project, industryType, city, country, pumpTagNo, application, fluid, solid, temp, pumpSerialNo, itemId, pumpModelNo, mainMoc, impellerMoc, impellerType, impellerSize, mechSeal, head, flow, motorRating, minDateOfDispatch, maxDateOfDispatch, minDateOfInstallation, maxDateOfInstallation) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("customerNumber", customerNumber)
    formdata.append("clientName", clientName)
    formdata.append("project", project)
    formdata.append("industryType", industryType)
    formdata.append("city", city)
    formdata.append("country", country)
    formdata.append("pumpTagNo", pumpTagNo)
    formdata.append("application", application)
    formdata.append("fluid", fluid)
    formdata.append("solid", solid)
    formdata.append("temp", temp)
    formdata.append("pumpSerialNo", pumpSerialNo)
    formdata.append("itemId", itemId)
    formdata.append("pumpModelNo", pumpModelNo)
    formdata.append("mainMoc", mainMoc)
    formdata.append("impellerMoc", impellerMoc)
    formdata.append("impellerType", impellerType)
    formdata.append("impellerSize", impellerSize)
    formdata.append("mechSeal", mechSeal)
    formdata.append("head", head)
    formdata.append("flow", flow)
    formdata.append("motorRating", motorRating)
    formdata.append("minDateOfDispatch", minDateOfDispatch)
    formdata.append("maxDateOfDispatch", maxDateOfDispatch)
    formdata.append("minDateOfInstallation", minDateOfInstallation)
    formdata.append("maxDateOfInstallation", maxDateOfInstallation)

    fetch(URL_GEL_ALL_PUMP_REGISTER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}





export const callCreatePOInvoice = (purchaseOrderId, vendor,invoiceId, invoiceAddress, invoiceDate, dueDate, productGroupData, grandSubTotal, grandTotal, is_tdsApplicable, tdsPercentage, tdsValue, grandTotalWithTDS, grandTotalWithTDSInOther) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("purchaseOrderId", purchaseOrderId);
    formdata.append("vendor", vendor);
    formdata.append("invoiceId", invoiceId);
    formdata.append("invoiceDate", invoiceDate);
    formdata.append("dueDate", dueDate);
    formdata.append("invoiceAddress", invoiceAddress);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);
    formdata.append("is_tdsApplicable", is_tdsApplicable);
    formdata.append("tdsPercentage", tdsPercentage);
    formdata.append("tdsValue", tdsValue);
    formdata.append("grandTotalWithTDS", grandTotalWithTDS);
    formdata.append("grandTotalWithTDSInOther", grandTotalWithTDSInOther);

    fetch(URL_CREATE_PO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetPOInvoices = (start, length, number, invoiceNo, minTotal, maxTotal, minSubTotal, maxSubTotal, purchaseOrderNumber, vendorName, minInvoiceDate, maxInvoiceDate, minDueDate, maxDueDate, minCreatedDate, maxCreatedDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("invoiceNo", invoiceNo)
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("minSubTotal", minSubTotal)
    formdata.append("maxSubTotal", maxSubTotal)
    formdata.append("purchaseOrderNumber", purchaseOrderNumber)
    formdata.append("vendorName", vendorName)
    formdata.append("minInvoiceDate", minInvoiceDate)
    formdata.append("maxInvoiceDate", maxInvoiceDate)
    formdata.append("minDueDate", minDueDate)
    formdata.append("maxDueDate", maxDueDate)
    formdata.append("minCreatedDate", minCreatedDate)
    formdata.append("maxCreatedDate", maxCreatedDate)

    fetch(URL_GET_PO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetPOSingleInvoice = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_GET_SINGLE_PO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetPOGroupData = (purchaseOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("purchaseOrderId", purchaseOrderId);

    fetch(URL_GET_PO_GROUP_DATA, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeletePOInvoice = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_DELETE_PO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callUpdatePOInvoice = (id, purchaseOrderId, vendor, invoiceId,invoiceAddress, invoiceDate, dueDate, productGroupData, grandSubTotal, grandTotal) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("purchaseOrderId", purchaseOrderId);
    formdata.append("vendor", vendor);
    formdata.append("invoiceId", invoiceId);
    formdata.append("invoiceAddress", invoiceAddress);
    formdata.append("invoiceDate", invoiceDate);
    formdata.append("dueDate", dueDate);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);

    fetch(URL_UPDATE_PO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateInvoiceQC = (invoiceId, purchaseOrderId, productGroupData, grandSubTotal, grandTotal) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("invoiceId", invoiceId);
    formdata.append("purchaseOrderId", purchaseOrderId);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);

    fetch(URL_UPDATE_QC_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}






export const callCreateDebitNote = (invoiceId,deliveryAddress, productGroupData, grandSubTotal, grandTotal,  ) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let userId = JSON.parse(localStorage.getItem('userdata'))._id
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("invoiceId", invoiceId)
    formdata.append("deliveryAddress", deliveryAddress)
    formdata.append("productGroupData", productGroupData)
    formdata.append("grandSubTotal", grandSubTotal)
    formdata.append("grandTotal", grandTotal)
   

    fetch(URL_CREATE_DEBIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetSingleDebitNote = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let userId = JSON.parse(localStorage.getItem('userdata'))._id
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_SINGLE_DEBIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllDebitNote = (start, length, number, invoiceNo, purchaseOrderNumber, vendorName, minRejectedQty, maxRejectedQty, minSubTotal, maxSubTotal, minTotal, maxTotal, minInvoiceDate, maxInvoiceDate, minModifiedDate, maxModifiedDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let userId = JSON.parse(localStorage.getItem('userdata'))._id
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("invoiceNo", invoiceNo)
    formdata.append("purchaseOrderNumber", purchaseOrderNumber)
    formdata.append("vendorName", vendorName)
    formdata.append("minRejectedQty", minRejectedQty)
    formdata.append("maxRejectedQty", maxRejectedQty)
    formdata.append("minSubTotal", minSubTotal)
    formdata.append("maxSubTotal", maxSubTotal)
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("minInvoiceDate", minInvoiceDate)
    formdata.append("maxInvoiceDate", maxInvoiceDate)
    formdata.append("minModifiedDate", minModifiedDate)
    formdata.append("maxModifiedDate", maxModifiedDate)

    fetch(URL_ALL_DEBIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteDebitNote = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let userId = JSON.parse(localStorage.getItem('userdata'))._id
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_DEBIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateDebitNote = (id, invoiceId, productGroupData, grandSubTotal, grandTotal,deliveryAddress) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let userId = JSON.parse(localStorage.getItem('userdata'))._id
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("invoiceId", invoiceId)
    formdata.append("productGroupData", productGroupData)
    formdata.append("grandSubTotal", grandSubTotal)
    formdata.append("grandTotal", grandTotal)
    formdata.append("deliveryAddress", deliveryAddress)

    fetch(URL_UPDATE_DEBIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateCustomerDocs = (customerOrderId, documentFile, description) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)
    formdata.append("documentFile", documentFile)
    formdata.append("description", description)

    fetch(URL_CREATE_CUSTOMER_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateCustomerDocs = (_id, customerOrderId, documentFile, description) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("_id", _id)
    formdata.append("customerOrderId", customerOrderId)
    formdata.append("documentFile", documentFile)
    formdata.append("description", description)

    fetch(URL_UPDATE_CUSTOMER_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeleteCustomerDocs = (_id, customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("_id", _id)
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_DELETE_CUSTOMER_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}






export const callCreateCOInvoice = (customerOrderId, customer, invoiceId, deliveryAddress, invoiceAddress, invoiceType, invoiceDate, dueDate, productGroupData, grandSubTotal, grandTotal, invoiceMessageHeader, invoiceMessageFooter, totalInOtherCurrency, is_tcsApplicable, tcsPercentage, tcsValue, grandTotalWithTCS, grandTotalWithTCSInOtherCurrency) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("customerOrderId", customerOrderId);
    formdata.append("customer", customer);
    formdata.append("invoiceId", invoiceId);
    formdata.append("invoiceDate", invoiceDate);
    formdata.append("dueDate", dueDate);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);
    formdata.append("deliveryAddress", deliveryAddress);
    formdata.append("invoiceAddress", invoiceAddress);
    formdata.append("invoiceType", invoiceType);
    formdata.append("invoiceMessageHeader", invoiceMessageHeader);
    formdata.append("invoiceMessageFooter", invoiceMessageFooter);
    formdata.append("totalInOtherCurrency", totalInOtherCurrency);
    formdata.append("is_tcsApplicable", is_tcsApplicable);
    formdata.append("tcsPercentage", tcsPercentage);
    formdata.append("tcsValue", tcsValue);
    formdata.append("grandTotalWithTCS", grandTotalWithTCS);
    formdata.append("grandTotalWithTCSInOtherCurrency", grandTotalWithTCSInOtherCurrency);

    fetch(URL_CREATE_CO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetCOInvoices = (start, length, number, invoiceType, invoiceNo, minTotal, maxTotal, customerOrderNumber, customerNumber, customerName, minInvoiceDate, maxInvoiceDate, minDueDate, maxDueDate, minCreatedDate, maxCreatedDate, createdBy, minSubTotal, maxSubTotal, poNo, otherThanExportInvoice, fieldName) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("number", number)
    formdata.append("invoiceNo", invoiceNo)
    formdata.append("invoiceType", invoiceType)
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("customerOrderNumber", customerOrderNumber)
    formdata.append("customerNumber", customerNumber)
    formdata.append("customerName", customerName)
    formdata.append("minInvoiceDate", minInvoiceDate)
    formdata.append("maxInvoiceDate", maxInvoiceDate)
    formdata.append("minDueDate", minDueDate)
    formdata.append("maxDueDate", maxDueDate)
    formdata.append("minCreatedDate", minCreatedDate)
    formdata.append("maxCreatedDate", maxCreatedDate)
    formdata.append("createdBy", createdBy)
    formdata.append("minSubTotal", minSubTotal)
    formdata.append("maxSubTotal", maxSubTotal)
    formdata.append("poNo", poNo)
    formdata.append("otherThanExportInvoice", otherThanExportInvoice)
    formdata.append("fieldName", fieldName)


    fetch(URL_GET_CO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetCOSingleInvoice = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_GET_SINGLE_CO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetEInvoiceDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_GET_SINGLE_E_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetDebitEInvoiceDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_GET_DEBIT_SINGLE_E_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSaveEWayBill = (invoiceId, customerId, eWayBillObj, creditEInvoice, creditNoteId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("invoiceId", invoiceId);
    formdata.append("customerId", customerId);
    formdata.append("eWayBillObj", JSON.stringify(eWayBillObj));
    formdata.append("creditEInvoice", creditEInvoice);
    formdata.append("creditNoteId", creditNoteId);

    fetch(URL_SAVE_E_WAY_BILL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSaveEInvoiceDetail = (customerCity, customerPin, customerStateCode, customerGstNo, documentType, supplyType, invoiceId, customerId, invoiceObj, creditEInvoice, creditNoteId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);


    let formdata = new FormData();
    formdata.append("customerCity", customerCity);
    formdata.append("customerPin", customerPin);
    formdata.append("customerStateCode", customerStateCode);
    formdata.append("customerGstNo", customerGstNo);
    formdata.append("documentType", documentType);
    formdata.append("supplyType", supplyType);
    formdata.append("invoiceId", invoiceId);
    formdata.append("customerId", customerId);
    formdata.append("invoiceObj", JSON.stringify(invoiceObj));
    formdata.append("creditEInvoice", creditEInvoice);
    formdata.append("creditNoteId", creditNoteId);

    fetch(URL_SAVE_E_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callSaveDebitEInvoiceDetail = (customerCity, customerPin, customerStateCode, customerGstNo, documentType, supplyType, invoiceId, customerId, invoiceObj, creditEInvoice, creditNoteId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);


    let formdata = new FormData();
    formdata.append("customerCity", customerCity);
    formdata.append("customerPin", customerPin);
    formdata.append("customerStateCode", customerStateCode);
    formdata.append("customerGstNo", customerGstNo);
    formdata.append("documentType", documentType);
    formdata.append("supplyType", supplyType);
    formdata.append("invoiceId", invoiceId);
    formdata.append("customerId", customerId);
    formdata.append("invoiceObj", JSON.stringify(invoiceObj));
    formdata.append("creditEInvoice", creditEInvoice);
    formdata.append("creditNoteId", creditNoteId);

    fetch(URL_SAVE_E_INVOICE_DEBIT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetCOGroupData = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("customerOrderId", customerOrderId);

    fetch(URL_GET_CO_GROUP_DATA, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteCOInvoice = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);

    fetch(URL_DELETE_CO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callUpdateCOInvoice = (id, invoiceId, deliveryAddress, invoiceAddress, invoiceType, invoiceDate, dueDate, productGroupData, grandSubTotal, grandTotal, invoiceMessageHeader, invoiceMessageFooter, totalInOtherCurrency, is_tcsApplicable, tcsPercentage, tcsValue, grandTotalWithTCS, grandTotalWithTCSInOtherCurrency) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("deliveryAddress", deliveryAddress);
    formdata.append("invoiceAddress", invoiceAddress);
    formdata.append("invoiceType", invoiceType)
    formdata.append("invoiceId", invoiceId);
    formdata.append("invoiceDate", invoiceDate);
    formdata.append("dueDate", dueDate);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);
    formdata.append("invoiceMessageHeader", invoiceMessageHeader);
    formdata.append("invoiceMessageFooter", invoiceMessageFooter);
    formdata.append("totalInOtherCurrency", totalInOtherCurrency);
    formdata.append("is_tcsApplicable", is_tcsApplicable);
    formdata.append("tcsPercentage", tcsPercentage);
    formdata.append("tcsValue", tcsValue);
    formdata.append("grandTotalWithTCS", grandTotalWithTCS);
    formdata.append("grandTotalWithTCSInOtherCurrency", grandTotalWithTCSInOtherCurrency);

    fetch(URL_UPDATE_CO_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetAllCODocuments = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_GET_CUSTOMER_DOCUMENTS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCreateCOPayment = (invoice_id, sum, storedPaid, date, notes) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("coInvoiceId", invoice_id)
    formdata.append("sum", sum)
    formdata.append("paid", storedPaid)
    formdata.append("date", date)
    formdata.append("notes", notes)

    fetch(URL_CREATE_CO_PAYMENTS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateCOPayment = (coInvoiceId, id, sum, date, notes) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("coInvoiceId", coInvoiceId)
    formdata.append("id", id)
    formdata.append("sum", sum)
    formdata.append("paid", sum)
    formdata.append("date", date)
    formdata.append("notes", notes)

    fetch(URL_UPDATE_CO_PAYMENTS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetCOPaymentList = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_GET_CO_PAYMENT_LIST, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetCOShipmentList = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_GET_CO_SHIPMENTS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateCreditNote = (invoiceId, productGroupData, grandSubTotal, grandTotal, invoiceMessageHeader, invoiceMessageFooter) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("invoiceId", invoiceId);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);
    formdata.append("invoiceMessageHeader", invoiceMessageHeader);
    formdata.append("invoiceMessageFooter", invoiceMessageFooter);

    fetch(URL_CREATE_CREDIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callGetSingleCreditNote = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);

    fetch(URL_GET_SINGLE_CREDIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllCreditNote = (start, length, number, coInvoiceNumber, coNumber, customerName, minRejectedQty, maxRejectedQty, minSubTotal, maxSubTotal, minTotal, maxTotal, minInvoiceDate, maxInvoiceDate, minModifiedDate, maxModifiedDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start);
    formdata.append("length", length);
    formdata.append("number", number);
    formdata.append("minSubTotal", minSubTotal);
    formdata.append("maxSubTotal", maxSubTotal);
    formdata.append("minTotal", minTotal);
    formdata.append("maxTotal", maxTotal);
    formdata.append("coInvoiceNumber", coInvoiceNumber);
    formdata.append("coNumber", coNumber);
    formdata.append("customerName", customerName);
    formdata.append("minRejectedQty", minRejectedQty);
    formdata.append("maxRejectedQty", maxRejectedQty);
    formdata.append("minModifiedDate", minModifiedDate);
    formdata.append("maxModifiedDate", maxModifiedDate);
    formdata.append("minInvoiceDate", minInvoiceDate);
    formdata.append("maxInvoiceDate", maxInvoiceDate);

    fetch(URL_GET_ALL_CREDIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteCreditNote = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);

    fetch(URL_DELETE_CREDIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callUpdateCreditNote = (id, productGroupData, grandSubTotal, grandTotal, invoiceMessageHeader, invoiceMessageFooter) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("productGroupData", productGroupData);
    formdata.append("grandSubTotal", grandSubTotal);
    formdata.append("grandTotal", grandTotal);
    formdata.append("invoiceMessageHeader", invoiceMessageHeader);
    formdata.append("invoiceMessageFooter", invoiceMessageFooter);

    fetch(URL_UPDATE_CREDIT_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callGetCreditNoteByCO = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);

    fetch(URL_GET_CREDIT_NOTE_BY_CO, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetRemainingCOInvoice = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_REMAINING_CO_INVOICE, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetCOInvoicesList = (customerOrderId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("customerOrderId", customerOrderId)

    fetch(URL_GET_CO_INVOICES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleCOPayment = (id, coInvoiceId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("coInvoiceId", coInvoiceId)

    fetch(URL_GET_SINGLE_CO_PAYMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callDeleteCOPayment = (id, coInvoiceId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("coInvoiceId", coInvoiceId)

    fetch(URL_DELETE_CO_PAYMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCOInvoicePDF = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_CO_INVOICE_PDF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callCNInvoicePDF = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_CN_INVOICE_PDF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDNInvoicePDF = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_GET_DN_INVOICE_PDF, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log("@@@@@@@@@@@@@@############",response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateItemDoc = (itemId, departmentUser, description, documentFile) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemId", itemId);
    formdata.append("departmentUser", departmentUser);
    formdata.append("description", description);
    formdata.append("documentFile", documentFile);

    fetch(URL_CREATE_ITEM_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateItemDoc = (itemId, itemDocId, departmentUser, description, documentFile) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemId", itemId);
    formdata.append("itemDocId", itemDocId);
    formdata.append("departmentUser", departmentUser);
    formdata.append("description", description);
    formdata.append("documentFile", documentFile);

    fetch(URL_UPDATE_ITEM_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleItemDoc = (itemDocId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemDocId", itemDocId);

    fetch(URL_GET_SINGLE_ITEM_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllItemDocs = (itemId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemId", itemId);

    fetch(URL_GET_ALL_ITEM_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteSingleItemDoc = (itemDocId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemDocId", itemDocId);

    fetch(URL_DELETE_ITEM_DOCUMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetExpenseCategories = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_EXPENSE_CATEGORIES, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callSetItemPriceCalculation = (grossProfit, maxDiscount) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("grossProfit", grossProfit)
    formdata.append("maxDiscount", maxDiscount)

    fetch(URL_SET_ITEM_PRICE_CALCULATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetItemPriceCalculation = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ITEM_PRICE_CALCULATION, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callSetExpenseCategories = (expenseCategory) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("expenseCategory", expenseCategory)

    fetch(URL_ADD_EXPENSE_CATEGORIES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetBankAccounts = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_BANK_ACCOUNTS, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callSetBankAccounts = (bankAccountDetails) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("bankAccountDetails", bankAccountDetails)

    fetch(URL_ADD_BANK_ACCOUNTS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateExpenseDetail = (userId, expenseCategory, billDate, stateOfSupply, referenceNo, expenseDetail, subTotal, total, paymentType, paid, balance, description, is_gst, party) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("userId", userId)
    formdata.append("expenseCategory", expenseCategory)
    formdata.append("billDate", billDate)
    formdata.append("stateOfSupply", stateOfSupply)
    formdata.append("referenceNo", referenceNo)
    formdata.append("expenseDetail", expenseDetail)
    formdata.append("subTotal", subTotal)
    formdata.append("total", total)
    formdata.append("paymentType", paymentType)
    formdata.append("paid", paid)
    formdata.append("balance", balance)
    formdata.append("description", description)
    formdata.append("is_gst", is_gst)
    formdata.append("party", party)

    fetch(URL_CREATE_EXPENSE_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateExpenseDetail = (expenseId, userId, expenseCategory, billDate, stateOfSupply, referenceNo, expenseDetail, subTotal, total, paymentType, paid, balance, description, is_gst, party) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("expenseId", expenseId)
    formdata.append("userId", userId)
    formdata.append("expenseCategory", expenseCategory)
    formdata.append("billDate", billDate)
    formdata.append("stateOfSupply", stateOfSupply)
    formdata.append("referenceNo", referenceNo)
    formdata.append("expenseDetail", expenseDetail)
    formdata.append("subTotal", subTotal)
    formdata.append("total", total)
    formdata.append("paymentType", paymentType)
    formdata.append("paid", paid)
    formdata.append("balance", balance)
    formdata.append("description", description)
    formdata.append("is_gst", is_gst)
    formdata.append("party", party)

    fetch(URL_UPDATE_EXPENSE_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callSingleExpenseDetail = (expenseId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("expenseId", expenseId)

    fetch(URL_GET_SINGLE_EXPENSE_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callDeleteExpenseDetail = (expenseId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("expenseId", expenseId)

    fetch(URL_DELETE_EXPENSE_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callGetAllExpenseList = (start, length, userName, expenseCategory, expenseNumber, paymentType, minSubTotal, maxSubTotal, minTotal, maxTotal, minPaid, maxPaid, minBalance, maxBalance, minBillDate, maxBillDate, minCreated, maxCreated, stateOfSupply) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("start", start)
    formdata.append("length", length)
    formdata.append("userName", userName)
    formdata.append("expenseCategory", expenseCategory)
    formdata.append("expenseNumber", expenseNumber)
    formdata.append("paymentType", paymentType)
    formdata.append("minSubTotal", minSubTotal)
    formdata.append("maxSubTotal", maxSubTotal)
    formdata.append("minTotal", minTotal)
    formdata.append("maxTotal", maxTotal)
    formdata.append("minPaid", minPaid)
    formdata.append("maxPaid", maxPaid)
    formdata.append("minBalance", minBalance)
    formdata.append("maxBalance", maxBalance)
    formdata.append("minBillDate", minBillDate)
    formdata.append("maxBillDate", maxBillDate)
    formdata.append("minCreated", minCreated)
    formdata.append("maxCreated", maxCreated)
    formdata.append("stateOfSupply", stateOfSupply)

    fetch(URL_GET_ALL_EXPENSE_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetGstDetailByNumber = (gstnumber) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("gstnumber", gstnumber.toUpperCase())

    fetch(GET_GST_DETAIL_BY_NUMBER, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateGSTDetail = (expenseCategory, gstNumber, companyName, address, fullName, mobileNo, email) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("expenseCategory", expenseCategory)
    formdata.append("gstNumber", gstNumber.toUpperCase())
    formdata.append("companyName", companyName)
    formdata.append("address", address)
    formdata.append("fullName", fullName)
    formdata.append("mobileNo", mobileNo)
    formdata.append("email", email)

    fetch(CREATE_GST_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callUpdateGSTDetail = (id, expenseCategory, gstNumber, companyName, address, fullName, mobileNo, email) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("expenseCategory", expenseCategory)
    formdata.append("gstNumber", gstNumber.toUpperCase())
    formdata.append("companyName", companyName)
    formdata.append("address", address)
    formdata.append("fullName", fullName)
    formdata.append("mobileNo", mobileNo)
    formdata.append("email", email)

    fetch(UPDATE_GST_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleGSTDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(GET_SINGLE_GST_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetAllGSTDetail = (expenseCategory, gstNumber, companyName, address, fullName, mobileNo, email) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("expenseCategory", expenseCategory)
    formdata.append("gstNumber", gstNumber.toUpperCase())
    formdata.append("companyName", companyName)
    formdata.append("address", address)
    formdata.append("fullName", fullName)
    formdata.append("mobileNo", mobileNo)
    formdata.append("email", email)

    fetch(GET_ALL_GST_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteGSTDetail = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(DELETE_GST_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}





export const callAddNotes = (companyId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", companyId);
    formdata.append("note", note);

    fetch(URL_ADD_NOTES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateCompanyContact = (companyId, companyNum, name, position, contactInformation) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyId", companyId);
    formdata.append("companySlug", companyNum);
    formdata.append("name", name);
    formdata.append("position", position);
    formdata.append("contactInformation", JSON.stringify(contactInformation));

    fetch(URL_CREATE_COMPANY_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateCompany = (cmpName, cmpNickName, cmpStatus, cmpPanNo, gstNumber, contactInformation, contactStarted, nextContact, paymentTermsId, paymentTerm, deliveryTermsId, cmpDeliveryTerms, creditLimitVal, uniqueTaxArray, accountManager, industrytype, currency) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyName", cmpName);
    formdata.append("nickName", cmpNickName);
    formdata.append("status", cmpStatus);
    formdata.append("panNo", cmpPanNo);
    formdata.append("gstNo", gstNumber.toUpperCase());
    formdata.append("contactInformation", JSON.stringify(contactInformation));
    formdata.append("contactStarted", contactStarted);
    formdata.append("nextContact", nextContact);
    formdata.append("paymentTermsId", paymentTermsId);
    formdata.append("paymentTerms", JSON.stringify(paymentTerm));
    formdata.append("deliveryTermsId", deliveryTermsId);
    formdata.append("deliveryTerms", cmpDeliveryTerms);
    formdata.append("creditLimit", creditLimitVal);
    formdata.append("taxApplicable", JSON.stringify(uniqueTaxArray));
    formdata.append("accountManager", JSON.stringify(accountManager));
    formdata.append("industryType", JSON.stringify(industrytype))
    formdata.append("currency", currency)

    fetch(URL_CREATE_COMPANY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateNote = (companyId, customerNoteId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyId", companyId)
    formdata.append("id", customerNoteId)
    formdata.append("note", note)

    fetch(URL_UPDATE_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteNote = (companyId, customerNoteId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyId", companyId)
    formdata.append("id", customerNoteId)

    fetch(URL_DELETE_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateCompanyContact = (contactId, name, position, contactInformation) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", contactId);
    formdata.append("name", name);
    formdata.append("position", position);
    formdata.append("contactInformation", JSON.stringify(contactInformation));

    fetch(URL_UPDATE_COMPANY_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteContact = (contactId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", contactId)

    fetch(URL_DELETE_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateCompany = (cmpId, companyName, nickName, status, panNo, gstNo, contactInformation, contactStarted, nextContact, paymentTermsId, paymentTerms, deliveryTermsId, deliveryTerms, creditLimit, uniqueTaxArray, accountManager, industryType, currency) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", cmpId);
    formdata.append("companyName", companyName);
    formdata.append("nickName", nickName);
    formdata.append("status", status);
    formdata.append("panNo", panNo);
    formdata.append("gstNo", gstNo);
    formdata.append("contactInformation", contactInformation);
    formdata.append("contactStarted", contactStarted);
    formdata.append("nextContact", nextContact);
    formdata.append("paymentTermsId", paymentTermsId);
    formdata.append("paymentTerms", paymentTerms);
    formdata.append("deliveryTermsId", deliveryTermsId);
    formdata.append("deliveryTerms", deliveryTerms);
    formdata.append("creditLimit", creditLimit);
    formdata.append("taxApplicable", uniqueTaxArray);
    formdata.append("accountManager", accountManager);
    formdata.append("industryType", industryType)
    formdata.append("currency", currency)

    fetch(URL_UPDATE_COMPANY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddVendorContacts = (name, phone, email, fax, address, addinfo) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name);
    formdata.append("phone", phone);
    formdata.append("email", email);
    formdata.append("fax", fax);
    formdata.append("address", address);
    formdata.append("additionalInformation", addinfo);

    fetch(URL_ADD_VENDOR_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddVendorNotes = (id, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("note", note);

    fetch(URL_ADD_VENDOR_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleVendorContacts = (id, contactId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("contactId", contactId);

    fetch(URL_GET_SINGLE_VENDOR_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateVendorContacts = (name, phone, email, fax, address, addinfo) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name);
    formdata.append("phone", phone);
    formdata.append("email", email);
    formdata.append("fax", fax);
    formdata.append("address", address);
    formdata.append("additionalInformation", addinfo);

    fetch(URL_UPDATE_VENDOR_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const calldeleteVendorContacts = (id, contactId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("contactId", contactId);

    fetch(URL_DELETE_VENDOR_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateVendorNotes = (id, noteId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("noteId", noteId)
    formdata.append("note", note)

    fetch(URL_UPDATE_VENDOR_NOTES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteVendorNotes = (id, noteId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("noteId", noteId)

    fetch(URL_DELETE_VENDOR_NOTES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteVendor = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_VENDOR, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateCompanyStatus = (value) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyStatus", JSON.stringify(value))

    fetch(URL_CREATE_COMPANY_STATUS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateCompanyIndustry = (value) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("companyIndustry", JSON.stringify(value))

    fetch(URL_CREATE_COMAPNY_INDUSTRY, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateFilterTableValue = (id, obj) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("tableId", id)
    formdata.append("values", JSON.stringify(obj))

    fetch(URL_CREATE_FILTER_TABLE_VALUE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateFilterTable = (tableName, value, desc, group) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("tableName", tableName);
    formdata.append("fields", JSON.stringify(value));
    formdata.append("description", desc);
    formdata.append("productGroupId", group);

    fetch(URL_CREATE_FILTER_TABLE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddItemNotes = (itemId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", itemId);
    formdata.append("note", note);

    fetch(URL_ADD_ITEMS_NOTES, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreatePartNo = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_CREATE_PART_NO, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callCreateProductGroup = (name) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name);

    fetch(URL_CREATE_PRODUCT_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAddPurchaseTermContacts = (id, Name, Phone, Email, Fax, Address, Addinfo) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("name", Name);
    formdata.append("phone", Phone);
    formdata.append("email", Email);
    formdata.append("fax", Fax);
    formdata.append("address", Address);
    formdata.append("additionalInformation", Addinfo);

    fetch(URL_ADD_PURCHASE_TERM_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateStorageLocation = (name) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name);

    fetch(URL_CREATE_STORAGE_LOCATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callCreateUnitOfMeasurement = (name, value) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", name);
    formdata.append("unitConversions", JSON.stringify(value))

    fetch(URL_CREATE_UNIT_OF_MEASUREMENT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateItemNote = (itemId, itemNoteId, note) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemId", itemId)
    formdata.append("id", itemNoteId)
    formdata.append("note", note)

    fetch(URL_UPDATE_ITEM_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteItemNote = (itemId, itemNote) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("itemId", itemId)
    formdata.append("note", itemNote)

    fetch(URL_DELETE_ITEM_NOTE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSingleFilterTableValue = (tableId, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("tableId", tableId)
    formdata.append("id", id)

    fetch(URL_GET_FILTER_TABLE_VALUE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteFilterTableValue = (tableId, id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("tableId", tableId)
    formdata.append("id", id)

    fetch(URL_DELETE_FILTER_TABLE_VALUE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateFilterTableValue = (tableId, id, obj) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("tableId", tableId)
    formdata.append("id", id)
    formdata.append("value", JSON.stringify(obj))

    fetch(URL_UPDATE_FILTER_TABLE_VALUE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const callCreateItem = (partNo, Desc, itemCode, hsCode, cadNumber, Group, Measure, selling, is_sellablePrice, tax, location, minQuantity, maxQuantity, procure, freeText) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("partNo", partNo);
    formdata.append("partDescription", Desc);
    formdata.append("itemCode", itemCode);
    formdata.append("hsCode", hsCode);
    formdata.append("cadNumber", cadNumber);
    formdata.append("productGroup", Group);
    formdata.append("unitOfMeasurement", Measure);
    formdata.append("sellingPrice", selling);
    formdata.append("is_sellablePrice", is_sellablePrice);
    formdata.append("tax", tax);
    formdata.append("defaultStorageLocation", location);
    formdata.append("minQuantity", minQuantity);
    formdata.append("maxQuantity", maxQuantity);
    formdata.append("is_procured", procure);
    formdata.append("freeText", freeText);


    fetch(URL_CREATE_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateItem = (id, partNo, Desc, itemCode, hsCode, cadNumber, Group, Measure, selling, is_sellablePrice, tax, location, minQuantity, maxQuantity, procure, freeText) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("partNo", partNo)
    formdata.append("partDescription", Desc);
    formdata.append("itemCode", itemCode);
    formdata.append("hsCode", hsCode);
    formdata.append("cadNumber", cadNumber)
    formdata.append("productGroup", Group);
    formdata.append("unitOfMeasurement", Measure);
    formdata.append("sellingPrice", selling);
    formdata.append("is_sellablePrice", is_sellablePrice);
    formdata.append("tax", tax);
    formdata.append("defaultStorageLocation", location);
    formdata.append("minQuantity", minQuantity);
    formdata.append("maxQuantity", maxQuantity);
    formdata.append("is_procured", procure);
    formdata.append("freeText", freeText)

    fetch(URL_UPDATE_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteItem = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_ITEM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateProductGroup = (id, name, number) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("name", name);
    formdata.append("number", number);

    fetch(URL_UPDATE_PRODUCT_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteProductGroup = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_PRODUCT_GROUP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callGetSinglePurchaseTermContacts = (id, contactId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("contactId", contactId);

    fetch(URL_GET_SINGLE_PURCHASE_TERM_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdatePurchaseTermContacts = (Name, Phone, Email, Fax, Address, Addinfo) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("name", Name);
    formdata.append("phone", Phone);
    formdata.append("email", Email);
    formdata.append("fax", Fax);
    formdata.append("address", Address);
    formdata.append("additionalInformation", Addinfo);

    fetch(URL_UPDATE_PURCHASE_TERM_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeletePurchaseTermContacts = (id, contactId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("contactId", contactId);

    fetch(URL_DELETE_PURCHASE_TERM_CONTACT, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateStorageLocation = (id, name) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("name", name);

    fetch(URL_UPDATE_STORAGE_LOCATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteStorageLocation = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_STORAGE_LOCATION, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callUpdateUnitOfMeasurement = (id, name, value) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id);
    formdata.append("name", name);
    formdata.append("unitConversions", JSON.stringify(value))

    fetch(URL_UPDATE_UOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteUnitOfMeasurement = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_UOM, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callDeleteFilterTable = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_DELETE_FILTER_TABLE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetPORevisions = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)

    fetch(URL_PO_REVISIONS, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetPORevisionDetail = (id, revision_id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("id", id)
    formdata.append("revision_id", revision_id)

    fetch(URL_PO_REVISION_DETAIL, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callAllCompanyByAccManager = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_ALL_COMPANY_BY_ACC_MANAGER, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response.data)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllProductGroupWithoutPermission = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_ALL_PRODUCT_GROUP_WITHOUT_PERM, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllItemWithoutPermission = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_ALL_ITEM_WITHOUT_PERM, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const callAllVendorWithoutPermission = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_ALL_VENDOR_WITHOUT_PERM, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callProductionPlanningOperations = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_PRODUCTION_PLANNING_OPERATION, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const callGetWaitingListMO = (id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_WAITING_LIST_MO, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const callMoveMoToWaitingList = (id, operationAction, startDate) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("operationId", id)
    formdata.append("operationAction", operationAction)
    if (operationAction === "0")
      formdata.append("startDate", startDate)

    fetch(TOGGLE_WAITING_LIST_OP, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const createTicket = (category, subCategory, subject, description, documentFile) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    let formdata = new FormData()
    formdata.append("category", category)
    formdata.append("subCategory", subCategory)
    formdata.append("subject", subject)
    formdata.append("description", description)
    for (let i = 0; i < documentFile.length; i++) {
      formdata.append("documentFile", documentFile[i]);
    }

    fetch(URL_CREATE_TICKET, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const getSingleTicket = (ticketId) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("ticketId", ticketId)

    fetch(URL_GET_SINGLE_TICKET, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const getAllTickets = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()

    fetch(URL_GET_ALL_TICKET, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}
export const getUpdateTicket = (_id, description, documentFile) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("ticketId", _id)
    formdata.append("description", description)
    for (let i = 0; i < documentFile.length; i++) {
      formdata.append("documentFile", documentFile[i]);
    }

    fetch(URL_UPDATE_TICKET, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const getCloseTicket = (_id) => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    let formdata = new FormData()
    formdata.append("ticketId", _id)

    fetch(URL_CLOSE_TICKET, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const profileDelete = () => {

  // console.log("creater ticket ==>",selectedCategory,subject ,message ,file);
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    let formdata = new FormData()
    // formdata.append("category", selectedCategory)
    // formdata.append("subject", subject)
    // formdata.append("message", message)
    // formdata.append("documentFile", file)

    fetch(URL_PROFILE_DELETE, {
      method: 'POST',
      headers: myHeaders,
      // body: formdata
    })
      .then(response => response.json())
      .then(response => { //console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}


export const getEInvoice = () => {
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'

  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);

    fetch(URL_GET_E_INVOICE, {
      method: 'POST',
      headers: myHeaders
    })
      .then(response => response.json())
      .then(response => { //console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}



export const startEInvoice = (company_Name, gst_In, user_Name, user_Password, start_einvoce, start_ewaybill) => {

  // console.log("creater ticket ==>",selectedCategory,subject ,message ,file);
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'


  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    let formdata = new FormData()
    formdata.append("companyName", company_Name)
    formdata.append("gstNumber", gst_In.toUpperCase())
    formdata.append("client_userName", user_Name)
    formdata.append("client_password", user_Password)
    formdata.append("is_eInvoice", start_einvoce)
    formdata.append("is_eWayBill", start_ewaybill)


    fetch(URL_START_E_INVOICE, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => { //console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}

export const backupData = () => {

  // console.log("creater ticket ==>",selectedCategory,subject ,message ,file);
  let token = JSON.parse(localStorage.getItem('userdata'))
  token = token ? token.login_token : 'abcd1234'
  let account_id = token ? token.account_id : 'abcd1234'


  return new Promise((resolve, reject) => {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    let formdata = new FormData()
    formdata.append("account_id", account_id)

    fetch(URL_BACKUP_DATA, {
      method: 'POST',
      headers: myHeaders,
      body: formdata
    })
      .then(response => response.json())
      .then(response => { //console.log(response);
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response.message)
        }
      })
  })
}





