import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as Fi from 'react-icons/fi'
import * as Bi from 'react-icons/bi'
import * as APIManager from './../../../APIManager/APIManager'

import Pagination from "react-js-pagination";
import TableLoading from '../../../helper/TableLoading';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import {
    ImArrowDown2,
} from "react-icons/im";

class BOM extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            aggregateFlag: true,

            number: "",
            articleCode: "",
            itemDescription: "",
            minRawCost: "",
            maxRawCost: "",
            minApproxCost: "",
            maxApproxCost: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,

            count: 0,
            responseData: [],
            recordList: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
        this.timer = null
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
         
            const sortedOrders = this.state.responseData.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    console.log(dateA, dateB);
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ responseData: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************

    _getKeyDownData = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.getAllBOM()
        }, 800);
    }

    getAllBOM = () => {
        let {
            start,
            length,
            number,
            articleCode,
            itemDescription,
            minRawCost,
            maxRawCost,
            minApproxCost,
            maxApproxCost,
            aggregateFlag
        } = this.state

        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllBOM(start, length, number, articleCode, itemDescription, minRawCost, maxRawCost, minApproxCost, maxApproxCost, aggregateFlag)
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseData: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()
                    this.createListing()

                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });


    }

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'articleCode': this.state.articleCode,
            'itemDescription': this.state.itemDescription,
            'minRawCost': this.state.minRawCost,
            'maxRawCost': this.state.maxRawCost,
            'minApproxCost': this.state.minApproxCost,
            'maxApproxCost': this.state.maxApproxCost,
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("productionPlaningBom", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlaningBom"))

        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                start: filterData.start,
                length: filterData.length,
                number: filterData.number,
                articleCode: filterData.articleCode,
                itemDescription: filterData.itemDescription,

                minRawCost: filterData.minRawCost,
                maxRawCost: filterData.maxRawCost,

                minApproxCost: filterData.minApproxCost,
                maxApproxCost: filterData.maxApproxCost,

                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getAllBOM()
            })
        } else {

            this.getAllBOM()
        }
    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllBOM()
        });
    }



    createListing = () => {
        let recordData = []
        let records = this.state.responseData
        for (let i = 0; i < records.length; i++) {
            recordData.push(
                <tr onClick={() => this.editBOM(records[i]._id)} key={records[i]._id + i}>
                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                    <td className="text-uppercase">{records[i].number}</td>
                    <td>{records[i].articleCode}</td>
                    <td>{records[i].itemDescription}</td>
                    <td>{records[i].rawCost ? records[i].rawCost.toFixed(2) : 0} <Bi.BiRupee /></td>
                    <td>{records[i].totalCost ? records[i].totalCost.toFixed(2) : 0} <Bi.BiRupee /></td>
                    <td></td>
                </tr>
            )
        }
        this.setState({
            recordList: recordData
        })
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlaningBom")
        this.setState({
            number: "",
            articleCode: "",
            itemDescription: "",
            minRawCost: "",
            maxRawCost: "",
            minApproxCost: "",
            maxApproxCost: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getAllBOM()
        })
    }


    addBOM = () => {
        let slug = "ProductionPlanning_BOM"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createbom"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editBOM = (id) => {
        this.props.history.push({
            pathname: "/editbom",
            state: { BOMId: id, newBOMEntry: false }
        })

    }

    exportAsPDF = () => {
        let responseData = this.state.responseData;
   console.log(responseData);
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Production Planning - BOM ";

        const headers = [[
            "Number",
            "Article Code",
            "Name",
            "Raw Cost",
            "Approximate Cost",

        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.articleCode,
            elt.itemDescription,
            elt.rawCost,
            elt.totalCost
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("BOM.pdf")
    }

    exportAsCSV = () => {
        let responseData = this.state.responseData;
        const title = "Production Planning - BOM";
     
        const headers = [[
            "Number",
            "Article Code",
            "Name",
            "Raw Cost",
            "Approximate Cost",

        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.articleCode,
            elt.itemDescription,
            elt.rawCost,
            elt.totalCost
        ]);
        const options = {
            filename: 'BOM',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }

    goBack = () => {
        this.props.history.goBack();
    }

    getIndexNumber = (i, j) => {
        return i + j
    }


    render() {
        return (
            <div className="page absoluteWhite">

                <Header logout={this.props.history} goBack={() => this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isBOM={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.ProductionPlanning.BOM}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <    ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 15 }}><div onClick={() => this.addBOM()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)} style={{ width: 150 }}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('articleCode', 'notDate', false)} style={{ width: 150 }}><span>Article Code </span> {this.state.fieldName === "articleCode" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "articleCode" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('itemDescription', 'notDate', false)}><span>Name </span> {this.state.fieldName === "itemDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "itemDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('rawCost', 'notDate', false)} style={{ width: 150 }}><span>Raw cost </span> {this.state.fieldName === "rawCost" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "rawCost" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('totalCost', 'notDate', false)} style={{ width: 150 }}><span>Approximate cost </span> {this.state.fieldName === "totalCost" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "totalCost" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader tableHeadButton" style={{ width: 35 }}><div onClick={() => this.addBOM()}><Fi.FiPlus /></div></th>
                                </tr>

                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this._getKeyDownData() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.articleCode} onChange={(e) => this.setState({ articleCode: e.target.value }, () => { this._getKeyDownData() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.itemDescription} onChange={(e) => this.setState({ itemDescription: e.target.value }, () => { this._getKeyDownData() })} /></th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minRawCost} onChange={(e) => this.setState({ minRawCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this._getKeyDownData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxRawCost} onChange={(e) => this.setState({ maxRawCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this._getKeyDownData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minApproxCost} onChange={(e) => this.setState({ minApproxCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this._getKeyDownData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxApproxCost} onChange={(e) => this.setState({ maxApproxCost: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this._getKeyDownData() })} />
                                    </th>
                                    <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><Fi.FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={7} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseData.map((data, i) => (
                                                <tr onClick={() => this.editBOM(data._id)} key={'BOM' + i}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td>{data.articleCode}</td>
                                                    <td>{data.itemDescription}</td>
                                                    <td>{data.rawCost ? data.rawCost.toFixed(2) : 0} <Bi.BiRupee /></td>
                                                    <td>{data.totalCost ? data.totalCost.toFixed(2) : 0} <Bi.BiRupee /></td>
                                                    <td></td>
                                                </tr>
                                            ))

                                            // this.state.recordList
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BOM;