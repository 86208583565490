import React from 'react';
import { Header, StockHeader } from './../../../components'

import * as APIManager from './../../../APIManager/APIManager'

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';

import { validateNumber } from './../../../constants/Validate'
import {
    AiOutlineExclamation
} from "react-icons/ai";

import { FiPercent } from "react-icons/fi";

import { Link } from 'react-router-dom';
import * as Fi from 'react-icons/fi'
import * as Bi from 'react-icons/bi'


import { FaAngleUp } from "react-icons/fa";
import { BiRupee } from 'react-icons/bi';

export default class EditItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseItems: [],
            responseNotes: null,
            responseProductGroup: [],
            responseUnitOfMeasure: [],
            responseStorageLocation: [],
            responsePurchaseTerms: [],
            responseItemDocs: [],

            _id: "",
            partNo: "",
            partDesc: "",
            itemCode: "",
            hsCode: "",
            cadNumber: "",
            productGroupId: "",
            measurementId: "",
            isProcurePro: false,
            is_sellablePrice: false,
            isProcureProCondition: false,
            sellingPrice: "",
            rawCost: "",
            storageLocationId: "",
            minQuantity: "",
            maxQuantity: "",
            freeText: "",
            tax: "",

            minSellingPriceWithMaxDiscount: 0,
            minSellingPrice: 0,
            grossProfit: 0,
            maxDiscount: 0,

            BOM_List: [],
            Routing_List: [],

            fieldNotEditable: true,
          
        }
       
        window.addEventListener("resize", this.update);

    }

    componentDidMount() {
        let id = localStorage.getItem("itemId")

        this.setState({
            _id: id,
        }, () => {
            this.getProductGroupList()
            this.getUnitOfMeasure()
            this.getStorageLocations()
           // this.getItemsList()
            this.getPurchaseTermsList()
            this.getSingleItemData(id)
            this.getItemDocuments(id)
            this.getPercentages()
        })

       
    }

    showLoadingEffect = () => {
        this.setState({
            pageLoading: true,
        }, () => {
            setTimeout(() => {
                this.setState({ pageLoading: false, })
            }, 1000)
        })
    }


    getPercentages = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetItemPriceCalculation()
            .then(response => {
                this.setState({
                    isLoading: false,
                    grossProfit: response.grossProfit,
                    maxDiscount: response.maxDiscount
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false
                })
            });
    }

    getItemDocuments = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllItemDocs(id)
            .then(response => {
                let responseData = response.data ? response.data : []
                let responseVal = []
                for (let i = 0; i < responseData.length; i++) {
                    let data = responseData[i]
                    responseVal.push(
                        <tr key={"itemDoc" + i}>
                            <td onClick={() => this.editItemDocument(data._id)}>{data.created}</td>
                            <td className='doc-saved-file pl-2 m-0' onClick={() => this.viewSelectedFile(data.documentFile)}>{this.setDisplayName(data.documentFile)}</td>
                            <td onClick={() => this.editItemDocument(data._id)}>{data.description}</td>
                            <td onClick={() => this.editItemDocument(data._id)}><Bi.BiPencil /></td>
                        </tr>
                    )
                }

                this.setState({
                    isLoading: false,
                    responseItemDocs: responseVal,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getItemsList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callItemList()
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseItems: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getPurchaseTermsList = () => {

        this.setState({ isLoading: true, error: false })
        APIManager.callPurchaseTermsList(this.state._id)
            .then(response => {
                this.setState({ isLoading: false, responsePurchaseTerms: response })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    getProductGroupList = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();

        fetch(APIManager.WEBSERVICES_URL + 'stock/getProductGroup', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseProductGroup: result.data.productGroup,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }

    getUnitOfMeasure = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        fetch(APIManager.WEBSERVICES_URL + 'stock/stocksetting/getAllUnitOfMeasurement', {
            method: "POST",
            headers: myHeaders,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseUnitOfMeasure: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }

    getStorageLocations = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();

        fetch(APIManager.WEBSERVICES_URL + 'stock/stocksetting/getAllStorageLocation', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseStorageLocation: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }


    getSingleItemData = (_id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(_id)
            .then(response => {
                this.setState({
                    isLoading: false,
                    partNo: response.itemData.partNo,
                    partDesc: response.itemData.partDescription,
                    itemCode: response.itemData.itemCode !== 'undefined' ? response.itemData.itemCode : "",
                    hsCode: response.itemData.hsCode !== 'undefined' ? response.itemData.hsCode : "",
                    cadNumber: response.itemData.cadNumber !== 'undefined' ? response.itemData.cadNumber : "",
                    is_sellablePrice: response.itemData.is_sellablePrice !== 'undefined' ? response.itemData.is_sellablePrice : false,
                    productGroupId: response.itemData.productGroup,
                    measurementId: response.itemData.unitOfMeasurement,
                    isProcurePro: response.itemData.is_procured,
                    isProcureProCondition: response.itemData.is_procured,
                    sellingPrice: response.itemData.sellingPrice !== null ? response.itemData.sellingPrice : "",
                    rawCost: response.itemData.rawCost ? response.itemData.rawCost.toFixed(2) : "",
                    minSellingPrice: response.itemData.minSellingPrice ? response.itemData.minSellingPrice : "",
                    tax: response.itemData.tax !== null ? response.itemData.tax : "",
                    storageLocationId: response.itemData.defaultStorageLocation,
                    minQuantity: response.itemData.minQuantity !== null ? response.itemData.minQuantity : "",
                    maxQuantity: response.itemData.maxQuantity !== null ? response.itemData.maxQuantity : "",
                    freeText: response.itemData.freeText,
                    responseNotes: response.itemNotes && response.itemNotes.length !== 0 ? response.itemNotes.notes : null
                }, () => {

                    this.getSingleUoM(this.state.measurementId)
                    this.checkMinSellingPrice(this.state.sellingPrice)
                    localStorage.setItem("itmId", _id)
                    localStorage.setItem("itmIdNum", response.itemData.partNo)
                    localStorage.setItem("itmName", response.itemData.partDescription)


                    if (!this.state.isProcurePro) {
                        this.getBOMbyItem(_id)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }



    getSingleUoM = (id) => {
        if(id){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetSingleUnitofmeasurement(id)
            .then(response => {
                this.setState({
                    isLoading: false
                }, () => {
                    localStorage.setItem("itmUoM", response.name)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
        }
    }

    getBOMbyItem = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callBOMByProduct(id, 'false')
            .then(response => {
                this.setState({
                    isLoading: false,
                    BOM_List: response
                }, () => {
                    this.getRoutingbyItem(id)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                }, () => {
                    this.getRoutingbyItem(id)
                })
            });
    }



    getRoutingbyItem = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callRoutingByProduct(id)
            .then(response => {
                this.setState({
                    isLoading: false,
                    Routing_List: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    copyContent = (_id) => {
        if (_id === "") {
            this.setState({
                partDesc: "",
                itemCode: "",
                hsCode: "",
                cadNumber: "",
                productGroupId: "",
                measurementId: "",
                isProcurePro: false,
                sellingPrice: "",
                tax: "",
                storageLocationId: "",
                minQuantity: "",
                maxQuantity: "",
                freeText: "",
            })
        } else {
            this.setState({ isLoading: true, error: false })
            APIManager.callSingleItem(_id)
                .then(response => {

                    this.setState({
                        isLoading: false,
                        partDesc: response.itemData.partDescription,
                        itemCode: response.itemData.itemCode !== 'undefined' ? response.itemData.itemCode : "",
                        hsCode: response.itemData.hsCode !== 'undefined' ? response.itemData.hsCode : "",
                        cadNumber: response.itemData.cadNumber !== 'undefined' ? response.itemData.cadNumber : "",
                        productGroupId: response.itemData.productGroup,
                        measurementId: response.itemData.unitOfMeasurement,
                        sellingPrice: response.itemData.sellingPrice !== null ? response.itemData.sellingPrice : "",
                        tax: response.itemData.tax !== null ? response.itemData.tax : "",
                        storageLocationId: response.itemData.defaultStorageLocation,
                        minQuantity: response.itemData.minQuantity !== null ? response.itemData.minQuantity : "",
                        maxQuantity: response.itemData.maxQuantity !== null ? response.itemData.maxQuantity : "",
                        freeText: response.itemData.freeText
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }

    updateItem = () => {

        if (this.state.fieldNotEditable !== true) {
            let that = this;
            let partNo = this.state.partNo
            let Desc = this.state.partDesc
            let hsCode = this.state.hsCode
            let cadNumber = this.state.cadNumber
            let itemCode = this.state.itemCode
            let Group = this.state.productGroupId
            let Measure = this.state.measurementId
            let procure = this.state.isProcurePro
            let selling = this.state.sellingPrice
            let is_sellablePrice = this.state.is_sellablePrice
            let tax = this.state.tax
            let location = this.state.storageLocationId
            let minQuantity = this.state.minQuantity
            let maxQuantity = this.state.maxQuantity
            let freeText = this.state.freeText

            if (partNo === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter part no",
                }, () => {
                    this.scrollToTop()
                })
            } else if (Desc === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter description",
                }, () => {
                    this.scrollToTop()
                })
            } else if (itemCode === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter article code",
                }, () => {
                    this.scrollToTop()
                })
            } else if (hsCode === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter HS Code",
                }, () => {
                    this.scrollToTop()
                })
            } else if (is_sellablePrice === true && tax === "") {
                this.setState({
                    error: true,
                    errormessage: "Please enter tax",
                })
            } else if (is_sellablePrice === true && selling === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter price",
                }, () => {
                    this.scrollToTop()
                })
            } else if (Measure === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select unit of measurement",
                }, () => {
                    this.scrollToTop()
                })
            } else if (minQuantity === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter min quantity",
                }, () => {
                    this.scrollToTop()
                })
            } else if (parseFloat(minQuantity) > (maxQuantity)) {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Maximum quantity should be greater than or equal to minimum quantity",
                }, () => {
                    this.scrollToTop()
                })
            } else if (this.state.BOM_List.length > 0 && procure === true) {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "BOM already existed for this Item, cannot be updated as procured item",
                }, () => {
                    this.scrollToTop()
                })
            } else if (this.state.responsePurchaseTerms.length > 0 && procure === false) {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Purchase term already existed, cannot be updated procured field",
                }, () => {
                    this.scrollToTop()
                })
            } else {
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state._id)
                formdata.append("partNo", partNo)
                formdata.append("partDescription", Desc);
                formdata.append("itemCode", itemCode);
                formdata.append("hsCode", hsCode);
                formdata.append("cadNumber", cadNumber)
                formdata.append("productGroup", Group);
                formdata.append("unitOfMeasurement", Measure);
                formdata.append("sellingPrice", selling);
                formdata.append("is_sellablePrice", is_sellablePrice);
                formdata.append("tax", tax);
                formdata.append("defaultStorageLocation", location);
                formdata.append("minQuantity", minQuantity);
                formdata.append("maxQuantity", maxQuantity);
                formdata.append("is_procured", procure);
                formdata.append("freeText", freeText)

                fetch(APIManager.WEBSERVICES_URL + 'stock/updateItem', {
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                        if (result.status === "success") {
                            that.setState({
                                error: false,
                                errormessage: "",
                                success: true,
                                successmessage: result.message,
                                isProcureProCondition: procure,
                                fieldNotEditable: true,
                            }, () => {
                                this.getSingleUoM(Measure)
                            })
                        } else if (result.status === "userDataError") {
                            that.logout()
                        } else {
                            that.setState({
                                error: true,
                                errormessage: result.message
                            })
                        }
                    }, (error) => {
                        console.log(error)
                    }
                    )
            }
        } else {
            let slug = "Stock_Items"
            let permission = "update"
            if (APIManager.checkUserPermission(slug, permission)) {
                this.setState({ fieldNotEditable: false })
            } else {
                alert("You don't have permission to " + permission + " this record")
            }

        }
    }

    generateSellingPrice = () => {

        let {
            minSellingPrice,
            maxDiscount,
            is_sellablePrice
        } = this.state

        if (is_sellablePrice) {
            minSellingPrice = minSellingPrice ? minSellingPrice : 0
            if (maxDiscount !== 0) {
                let price = (100 - maxDiscount) / 100;
                let sellingPrice = minSellingPrice / price
                sellingPrice = sellingPrice.toFixed(2)

                this.setState({
                    sellingPrice: parseFloat(sellingPrice)
                })
            } else {
                this.setState({
                    sellingPrice: parseFloat(minSellingPrice)
                })
            }
        }
    }

    checkMinSellingPrice = (val) => {
        let {
            minSellingPrice,
            maxDiscount,
            is_sellablePrice
        } = this.state
        if (is_sellablePrice) {
            // let maxD = maxDiscount !== 0 ? maxDiscount : 1
            let maxD = maxDiscount

            let price = (100 - maxD) / 100;
            let sellingPrice = minSellingPrice / price

            this.setState({
                sellingPrice: val,
                minSellingPriceWithMaxDiscount: sellingPrice.toFixed(2)
            })
        }
    }

    createPurchaseTerms = () => {
        let slug = "Stock_Items"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push('/createpurchaseterms')
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    deleteItem = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {

            this.setState({ loading: true })
            const that = this;

            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("id", this.state._id)

            fetch(APIManager.WEBSERVICES_URL + 'stock/deleteItem', {
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                    if (result.status === "success") {
                        that.setState({ loading: false }, () => {
                            that.goBack()
                        })

                    } else if (result.status === "userDataError") {
                        that.setState({ loading: false }, () => {
                            that.logout()
                        })
                    } else {
                        that.setState({
                            loading: false,
                            error: true,
                            errormessage: result.message
                        })
                    }

                }, (error) => {
                    console.log(error)
                }
                )
        }
    }

    addNewProductGroup = () => {
        this.props.history.push('/createproductgroup')
    }

    addNewMeasurement = () => {
        this.props.history.push('/createunitofmeasure')
    }

    addNewLocation = () => {
        this.props.history.push('/createstoragelocation')
    }


    editItemNote = (id, note) => {
        localStorage.setItem("itemNoteId", id)
        localStorage.setItem("itemNote", note)
        this.props.history.push('/edititemnotes')
    }

    editPurchaseTerms = (id, name) => {
        localStorage.setItem("purchaseTermId", id)
        localStorage.setItem("purchaseVendor", name)
        this.props.history.push('/editpurchaseterms')
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    addBOM = () => {
        let slug = "Stock_Items"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createitembom",
                state: { itemNameNumber: this.state.itemCode.toUpperCase() + " " + this.state.partDesc, itemId: this.state._id }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }

    }

    editBOM = (id) => {
        this.props.history.push({
            pathname: "/editbom",
            state: { BOMId: id, newBOMEntry: false }
        })
    }



    addRouting = () => {

        let slug = "Stock_Items"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createitemrouting",
                state: { itemNameNumber: this.state.itemCode.toUpperCase() + " " + this.state.partDesc, itemId: this.state._id }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }

    }

    editRouting = (id) => {
        this.props.history.push({
            pathname: "/editrouting",
            state: { RoutingId: id, newRoutingEntry: false }
        })
    }

    setDisplayName = (selectedFile) => {
        if (selectedFile) {
            let splitData = selectedFile.split("__")
            let splitData_last_index = splitData[1]
            let finalName = "";
            if (splitData_last_index) {
                finalName = splitData_last_index.split("_").join(" ")
            } else {
                finalName = selectedFile.substring(selectedFile.lastIndexOf('/') + 1).split("_").join(" ")
            }
            return finalName;
        }
    }

    viewSelectedFile = (file) => {
        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" + encodeURI(file) + "'></iframe>"
        )
    }

    editItemDocument = (_id) => {
        let slug = "Stock_Items"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/edititemdoc",
                state: { itemId: this.state._id, itemDocId: _id, isNewEntry: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    createItemDocument = () => {
        let slug = "Stock_Items"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createitemdoc",
                state: { itemNameNumber: this.state.partNo.toUpperCase() + " " + this.state.partDesc, itemId: this.state._id }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    goBack = () => {
        if (localStorage.getItem("createNewItemEdit") !== null) {
            localStorage.removeItem("createNewItemEdit")
            this.props.history.go(-2)
        } else {
            this.props.history.goBack()
        }
    }


    
    render() {
        return (
            <div className="page absoluteWhite">
                {
                    this.state.pageLoading &&
                    <div className="pageLoading"></div>
                }
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isItems={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title text-capitalize">Item {this.state.itemCode} {this.state.partDesc.length > 20 ? this.state.partDesc.substring(0, 20) + '...' : this.state.partDesc} details</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.updateItem()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={() => this.deleteItem()}>Delete</div>
                            {/* <div className="add_btnSave">Reports</div> */}
                            <div className="clearfix"></div>
                        </div>

                        <div className="mb-2 mt-2 position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Part No*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.partNo} onChange={(e) => this.setState({ partNo: e.target.value })} className="fieldheightdecr text-uppercase" autoComplete="off" name="name" disabled />
                                    </div>
                                    <div className="clearfix"></div>
                                </div>

                                {/* <div className="add_inner">
                                    <div className="title">Copy item</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.responseItems.map((data)=>(
                                                { id: data._id, text: data.itemCode}
                                            ))}
                                            options={{placeholder: 'Select Item'}}
                                            onSelect={(e)=> this.copyContent(e.target.value)}
                                        />

                                    </div>
                                    <div className="clear"></div>
                                </div> */}

                                <div className="add_inner">
                                    <div className="title">CAD No</div>
                                    <div className="value">
                                        <input type="text" value={this.state.cadNumber} onChange={(e) => this.setState({ cadNumber: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Article code*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.itemCode} onChange={(e) => this.setState({ itemCode: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">HS code*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.hsCode} onChange={(e) => this.setState({ hsCode: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Part description*</div>
                                    <div className="value">
                                        {/* <input type="text" value={this.state.partDesc} onChange={(e)=>this.setState({ partDesc: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" /> */}
                                        <textarea
                                            autoComplete="off"
                                            className="fieldheightdecr item_free_text_area"
                                            value={this.state.partDesc}
                                            onChange={(e) => this.setState({ partDesc: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">This is a sellable item</div>
                                    <div className="value">
                                        <input
                                            type="checkbox"
                                            style={{ width: 14 }}
                                            checked={this.state.is_sellablePrice}
                                            onChange={() => {
                                                this.setState({
                                                    is_sellablePrice: !this.state.is_sellablePrice,
                                                    sellingPrice: "",
                                                })
                                            }
                                            }
                                        />
                                        {
                                            this.state.is_sellablePrice ?
                                                <div className='generatePriceBtn' onClick={() => this.generateSellingPrice()}>Generate Price</div>
                                                :
                                                <div className='generatePriceBtn disabled'>Generate Price</div>
                                        }
                                    </div>
                                    <div className="clear"></div>
                                </div>



                                <div className="add_inner">
                                    <div className="title">Raw Cost</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.rawCost}
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            disabled={true}
                                            style={{ width: "40%" }} /> <BiRupee />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Min Cost</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.minSellingPrice}
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            disabled={true}
                                            style={{ width: "40%" }} /> <BiRupee />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Selling price</div>
                                    <div className={this.state.sellingPrice < this.state.minSellingPriceWithMaxDiscount ? "value danger-input-border" : "value"}>
                                        <input
                                            type="text"
                                            value={this.state.sellingPrice}
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value)) {
                                                    this.checkMinSellingPrice(e.target.value)
                                                }
                                            }
                                            }
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            disabled={!this.state.is_sellablePrice}
                                            style={{ width: "40%" }} /> <BiRupee />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product Group</div>
                                    <div className="value">
                                        <select className="text-capitalize" name="status" value={this.state.productGroupId} onChange={(e) =>
                                            e.target.value === 'addNew' ?
                                                this.addNewProductGroup()
                                                :
                                                this.setState({ productGroupId: e.target.value })
                                        }>
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>Add new product</option>

                                            {
                                                this.state.responseProductGroup !== null &&
                                                this.state.responseProductGroup.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Default storage location</div>
                                    <div className="value">
                                        <select name="status" value={this.state.storageLocationId} onChange={(e) =>
                                            e.target.value === 'addNew' ?
                                                this.addNewLocation()
                                                :
                                                this.setState({ storageLocationId: e.target.value })
                                        }>
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>+Add new location</option>
                                            {
                                                this.state.responseStorageLocation !== null &&
                                                this.state.responseStorageLocation.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Unit of measurement*</div>
                                    <div className="value">
                                        <select name="status" value={this.state.measurementId}
                                            onChange={(e) =>
                                                e.target.value === 'addNew' ?
                                                    this.addNewMeasurement()
                                                    :
                                                    this.setState({ measurementId: e.target.value })
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>Add new measurement</option>

                                            {
                                                this.state.responseUnitOfMeasure !== null &&
                                                this.state.responseUnitOfMeasure.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Minimum quantity*</div>
                                    <div className="value">
                                        <input type="text" pattern="[0-9]*" value={this.state.minQuantity} onChange={(e) => this.setState({ minQuantity: e.target.value >= 0 ? e.target.value : "" })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Maximum quantity*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.maxQuantity} onChange={(e) => this.setState({ maxQuantity: e.target.value >= 0 ? e.target.value : "" })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Free text</div>
                                    <div className="value">
                                        <textarea
                                            autoComplete="off"
                                            className="fieldheightdecr item_free_text_area"
                                            value={this.state.freeText}
                                            onChange={(e) => this.setState({ freeText: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">This is a procured item</div>
                                    <div className="value">
                                        <input
                                            type="checkbox"
                                            style={{ width: 14 }}
                                            checked={this.state.isProcurePro}
                                            onChange={() => this.setState({ isProcurePro: !this.state.isProcurePro })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Tax</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.tax}
                                            onChange={(e) => {
                                                if (e.target.value === "" || (e.target.value >= 0 && e.target.value <= 100)) {
                                                    this.setState({ tax: e.target.value })
                                                }
                                            }
                                            }
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            name="name"
                                            style={{ width: "40%" }}
                                        /> <FiPercent />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.updateItem()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={() => this.deleteItem()}>Delete</div>
                            {/* <div className="add_btnSave">Reports</div> */}
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Documents</div>
                        </div>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="width20"><span>Created</span> </th>
                                    <th className="width20 pl-2"><span>File</span></th>
                                    <th className="width60"><span>description</span></th>
                                    <th className="text-right" onClick={() => this.createItemDocument()}><Fi.FiPlus /></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.responseItemDocs
                                }

                            </tbody>
                        </table>
                    </div>

                    {/* PURCHASE TERMS */}
                    {
                        this.state.isProcureProCondition ?
                            <div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="page-title">Purchase terms</div>
                                    </div>
                                </div>

                                <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="tableHeader"><span>Vendor</span> <FaAngleUp /></th>
                                                <th className="tableHeader"><span>Priority</span></th>
                                                <th className="tableHeader"><span>Vendor part no.</span></th>
                                                <th className="tableHeader"><span>Lead time</span></th>
                                                <th className="tableHeader"><span>Price per UoM</span></th>
                                                <th className="tableHeader"><span>Min. quantity</span></th>
                                                <th className="tableHeader" onClick={() => this.createPurchaseTerms()}><Fi.FiPlus className="editCusomerBtn" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.responsePurchaseTerms !== null &&
                                                this.state.responsePurchaseTerms.map((data, i) => (
                                                    <tr key={data._id} onClick={() => this.editPurchaseTerms(data._id, data.vendor)}>
                                                        <td>{data.vendor}</td>
                                                        <td>{data.priority}</td>
                                                        <td>{data.vendorPartNo}</td>
                                                        <td>{data.leadTime}</td>
                                                        {
                                                            data.priceperUoM !== null ?
                                                                <td><BiRupee className="editCusomerBtn" />{data.priceperUoM.toFixed(2)}</td>
                                                                :
                                                                <td></td>
                                                        }
                                                        <td>{data.minQuantity}</td>
                                                        {/* <td><FiEdit2 className="editCusomerBtn" onClick={()=>this.editPurchaseTerms(data._id)} /></td> */}
                                                        <td></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            :

                            <div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="page-title">BOM</div>
                                    </div>
                                </div>
                                <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="tableHeader" style={{ width: 110 }}><span>Number</span> <FaAngleUp /></th>
                                                <th className="tableHeader"><span>Name</span></th>
                                                <th className="tableHeader" style={{ width: 130 }}><span>Approximate cost</span></th>
                                                {
                                                    this.state.BOM_List.length >= 1 ?
                                                        <th></th>
                                                        :
                                                        <th className="tableHeader text-right" style={{ width: 20 }}><div onClick={() => this.addBOM()}><Fi.FiPlus className="editCusomerBtn" /></div></th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.BOM_List !== "" &&
                                                this.state.BOM_List.map((data, i) => (
                                                    <tr key={data._id} onClick={() => this.editBOM(data._id)}>
                                                        <td>{data.number}</td>
                                                        <td>{data.name}</td>
                                                        <td>{data.totalCost.toFixed(2)} <Bi.BiRupee /></td>
                                                        <td></td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="page-title">Routings</div>
                                    </div>
                                </div>

                                <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="tableHeader"><span>Number</span></th>
                                                <th className="tableHeader"><span>Name</span>  <FaAngleUp /></th>
                                                <th className="tableHeader"><span>Duration</span></th>
                                                <th className="tableHeader"><span>Cost</span></th>
                                                {
                                                    this.state.Routing_List.length >= 1 ?
                                                        <th></th>
                                                        :
                                                        <th className="tableHeader text-right"><div onClick={() => this.addRouting()}><Fi.FiPlus className="editCusomerBtn" /></div></th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.Routing_List !== "" &&
                                                this.state.Routing_List.map((data, i) => (
                                                    <tr key={data._id} onClick={() => this.editRouting(data._id)}>
                                                        <td>{data.number}</td>
                                                        <td>{data.name}</td>
                                                        <td>{data.duration}</td>
                                                        <td>{data.totalCost} <Bi.BiRupee /></td>
                                                        <td></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                    }





                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Notes</div>
                        </div>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader"><span>Created</span>  <FaAngleUp /></th>
                                    <th className="tableHeader"><span>Modified</span></th>
                                    <th className="tableHeader"><span>Note</span></th>
                                    <th className="tableHeader text-right"><Link to="/createitemnotes"><Fi.FiPlus /></Link></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.responseNotes !== null &&
                                    this.state.responseNotes.map((data, i) => (
                                        <tr key={data.id} onClick={this.editItemNote.bind(this, data.id, data.note)}>
                                            <td>
                                                <b>{data.creatorName}</b> <br />
                                                <span>{data.created}</span>
                                            </td>
                                            <td>
                                                <b>{data.modifierName}</b> <br />
                                                <span>{data.modified}</span>
                                            </td>
                                            <td className="preline">{data.note}</td>
                                            {/* <td><FiEdit2 className="editCusomerBtn" onClick={this.editItemNote.bind(this,data.id,data.note)}/></td> */}
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

              
            </div>
        )
    }
}