import React, { Component } from 'react';

import { Header, SettingsHeader } from './../../../components'
import { validatePassword } from './../../../constants/Validate'
import { AiOutlineExclamation } from "react-icons/ai";

import * as APIManager from './../../../APIManager/APIManager'
class EditUserProfile extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            fullName: "",
            username: "",
            phone: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        }
    }

    componentDidMount(){
        this.getDetails()
    }

    getDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProfile()
        .then(response => {
            this.setState({ 
                isLoading: false,
                fullName: response.data.full_name,
                username: response.data.username,
                phone: response.data.mobileNumber,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    validateFields = () => {
        let {
            fullName,
            username,
            phone,
            oldPassword,
            newPassword,
            confirmPassword
        } = this.state

        //const usernameRegex = /^[a-z0-9_.]+$/

        if(fullName === ""){
            this.setState({
                error: true,
                errormessage: "Please enter full name"
            })
        }else if(username === ""){
            this.setState({
                error: true,
                errormessage: "Please enter username"
            })
        }
        // else if(!usernameRegex.test(username)){
        //     this.setState({
        //         error: true,
        //         errormessage: "Please enter valid username"
        //     })
        // }
        else if(phone === ""){
            this.setState({
                error: true,
                errormessage: "Please enter phone number"
            })
        }else{ 
            if(oldPassword === ""){
                this.setState({
                    newPassword: "",
                    confirmPassword: "",
                    error: false,
                },()=> this.updateProfile())
            }else if(oldPassword !== "" && newPassword === ""){
                this.setState({
                    error: true,
                    errormessage: "Please enter new password"
                })
            }else if(!validatePassword(newPassword)){
                this.setState({
                    error: true,
                    errormessage: "Password must have 6 characters & Upper, Lower and special characters"
                })
            }else if(oldPassword === newPassword){
                this.setState({
                    error: true,
                    errormessage: "New password cannot be same as old password"
                })
            }else if(newPassword !== confirmPassword){
                this.setState({
                    error: true,
                    errormessage: "New password & confirm password must be same"
                })
            }else{
                this.setState({
                    error: false,
                },()=> this.updateProfile())
            }
        }
    }

    updateProfile = () => {
        let {
            fullName,
            username,
            phone,
            oldPassword,
            newPassword
        } = this.state

        this.setState({ isLoading: true, error: false })
        APIManager.callUpdateProfile(fullName,username,phone,oldPassword,newPassword)
        .then(response => {
            this.setState({ 
                isLoading: false,
                success: true,
                successmessage: response.message
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isUserProfile={true} />

                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Edit Profile</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                   
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.validateFields()}>Update</div>
                        <div className="clear"></div>
                    </div>

                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Full Name* </div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.fullName}
                                        onChange={(e)=>this.setState({ fullName: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Username* </div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.username}
                                        onChange={(e)=>this.setState({ username: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Phone</div>
                                <div className="value input__type_number">
                                    <input 
                                        type="text" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.phone}
                                        onChange={(e)=>this.setState({ phone: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>            
                        </div>

                        <div className="add_partside">
                            
                            <div className="add_inner">
                                <div className="title">Old Password</div>
                                <div className="value">
                                    <input 
                                        type="password" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.oldPassword}
                                        onChange={(e)=>this.setState({ oldPassword: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">New Password</div>
                                <div className="value">
                                    <input 
                                        type="password" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.newPassword}
                                        onChange={(e)=>this.setState({ newPassword: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Retype Password</div>
                                <div className="value">
                                    <input 
                                        type="password" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.confirmPassword}
                                        onChange={(e)=>this.setState({ confirmPassword: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">                        
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.validateFields()}>Update</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default EditUserProfile;