import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../components'
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import Loading from './../../../../helper/loading'
import ProductGroup from './ProductGroup';
import { convertDateStringtoDateObject, convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class UpdateInvoiceByQC extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            poGroupList: [],
            poGroupData: [],
            poGroupRemainingData: [],
            purchaseOrderData: [],

            PO_InvoiceId: this.props.location.state.invoiceId,
            isNewEntry: this.props.location.state.isNewEntry,
            number: "",

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            purchaseOrder: "",
            poNumber: "",
            vendorId: "",
            invoiceDate: "",
            invoiceId: "",
            dueDate: "",
            vendroNumber: "",
            vendorName: "",
            applicableTax: "",
            applicableTaxType: [],
            remainingScope: [],
            currencyDetails: [],
    
            fieldNotEditable: true,


            is_tds_applicable: false,
            is_tds_locked: false,
            tds_percentage: "",
            tds_value: "",
            after_tds_grandtotal: "",
            tds_grandtotal_ininr: "",
            tds_grandtotal_inother: "",
        }
    }

    componentDidMount(){
        this.getSingleInvoice()
    }

    getSingleInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPOSingleInvoice(this.state.PO_InvoiceId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                purchaseOrder: response.data.purchaseOrderId,
                number: response.data.number,
                invoiceId: response.data.invoiceId,
                poGroupData: response.data.productGroup,
                invoiceDate: convertDateStringtoDateObject(response.data.invoiceDate),
                dueDate: response.data.dueDate ? convertDateStringtoDateObject(response.data.dueDate) : "",

                is_tds_applicable: response.data.is_tdsApplicable,
                is_tds_locked: false,
                tds_percentage: response.data.tdsPercentage,
                tds_value: response.data.tdsValue,
                after_tds_grandtotal: "",
                tds_grandtotal_ininr: response.data.grandTotalWithTDS,
                tds_grandtotal_inother: response.data.grandTotalWithTDSInOtherCurrency,
            },()=>{
                this.getSinglePurchaseOrderData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }


    getSinglePurchaseOrderData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSinglePurchaseOrder(this.state.purchaseOrder)
        .then(response => {
            let poData = [...this.state.poGroupData]
            for(let i = 0;i<poData.length;i++){
                poData[i].remainingQuantity = response.data.remainingScope ? response.data.remainingScope[i].scope : 0
            }
            this.setState({ 
                isLoading: false,
                poNumber: response.data.number,
                remainingScope: response.data.remainingScope ? response.data.remainingScope : [],
                poGroupData: poData
            },()=>{
                this.getSingleVendorDetail(response.data.vendor)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }


    getSingleVendorDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(id)
        .then(response => {

            let number = response.number ? response.number.toUpperCase() : ""
            let name = response.name

            this.setState({ 
                isLoading: false,
                vendorId: id,
                vendroNumber: number,
                vendorName: name,
                applicableTaxType: response.taxApplicable,
                currencyDetails: response.conversionDetail ? response.conversionDetail : []
            },()=>{
                this.setGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    getRemainingCount = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllInvoiceRemainingItems(this.state.purchaseOrder)
        .then(response => {
            this.setState({ 
                isLoading: false,
                poGroupRemainingData: response.data
            },()=>{
                this.setGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }
  

    setGroupData = () => {
        let data = this.state.poGroupData
        let group = []

        for(let i=0;i<data.length;i++){
            group.push(
                <ProductGroup 
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    currencyDetails={this.state.currencyDetails}
                    key={"groupData"+i}
                    data={data[i]}
                    uom={data[i].uom}
                    vendorId={this.state.vendorId}
                    callBack={this.setUpdatedGroupData}
                    scopeDetails={this.state.remainingScope[i] ? this.state.remainingScope[i] : null}
                />
            )
        }
        this.setState({
            poGroupList: group,
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let poGroupData = [...this.state.poGroupData]    
        poGroupData[counter] = value;  
        this.setState({
            poGroupData
        },()=>{
            console.log("poGroupData ", poGroupData)
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.poGroupData
        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for(let i=0;i<data.length;i++){
            qty += parseFloat(data[i].orderedQuantity)
            subTotal += parseFloat(data[i].subTotal)
            grandTotal += parseFloat(data[i].total)
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        },()=>{
            let tds = this.state.tds_percentage
            if(this.state.grandTotal >= 5000000 && tds === ""){
                this.setState({
                    is_tds_applicable: true,
                    is_tds_locked: true,
                    tds_percentage: 0.1,
                },()=>{
                    this.changeTDSPercentage(this.state.tds_percentage)
                })
            }else if(this.state.grandTotal >= 5000000 && tds !== ""){
                this.setState({
                    is_tds_locked: true,
                },()=>{
                    this.changeTDSPercentage(this.state.tds_percentage)
                })
            }else{
                this.setState({
                    is_tds_locked: false,
                },()=>{
                    this.changeTDSPercentage(this.state.tds_percentage)
                })
            }
        })
    }

    updateInvoice = () => {
        if(!this.state.fieldNotEditable){
            let {
                subTotal,
                grandTotal,
                poGroupData,
                purchaseOrder,
                PO_InvoiceId
            } = this.state

            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateInvoiceQC(PO_InvoiceId,purchaseOrder,JSON.stringify(poGroupData),subTotal,grandTotal)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    success: true,
                    fieldNotEditable: true,
                    successmessage: "QC updated successfully"
                },()=>{
                    this.scrollToTop()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                },()=>{
                    this.scrollToTop()
                })
            });  
            
        }else{
            let slug = "Procurement_QualityCheck"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    deleteEntry = () => {
        let slug = "Procurement_QualityCheck"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeletePOInvoice(this.state.PO_InvoiceId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    changeTDSPercentage = (val) => {
        let per = parseFloat(val)
        this.setState({
            tds_percentage: (per >= 0 && per <= 100 ) || per === "" ? per : ''
        },()=>{
            let grandTotal = this.state.currencyDetails.conversionRate ? this.state.grandTotalInOther : this.state.grandTotal
            if(this.state.tds_percentage){
                let tds_per = this.state.tds_percentage

                let tds_val = (grandTotal * tds_per / 100).toFixed(2)

                this.setState({
                    tds_value: parseFloat(tds_val),
                    after_tds_grandtotal: parseFloat(grandTotal)+parseFloat(tds_val),
                    tds_grandtotal_ininr: parseFloat(this.state.grandTotal)+parseFloat(tds_val),
                    tds_grandtotal_inother: parseFloat(this.state.grandTotalInOther)+parseFloat(tds_val)
                })
            }
        })
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isQualityCheck={true}/>
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Quality Check Invoice No. {this.state.number}</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateInvoice()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            {/* <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div> */}
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Number</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.number}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Purchase Order*</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.poNumber.toUpperCase()} {this.state.vendorName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Vendor</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.vendroNumber.toUpperCase()} {this.state.vendorName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice ID</div>
                                    <div className="value">
                                        <input type="text" disabled value={this.state.invoiceId} onChange={(e)=>this.setState({ invoiceId: e.target.value})} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <DatePicker 
                                            disabled
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.invoiceDate
                                            }
                                            onChange={
                                                date => this.setState({ invoiceDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Due Date</div>
                                    <div className="value">
                                        <DatePicker 
                                            disabled
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={
                                                this.state.dueDate
                                            }
                                            onChange={
                                                date => this.setState({ dueDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                               
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="main-container mt-5 invoice_product_group position-relative">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Ordered Qty</th>
                                        <th className='pg-tab-head'>Remaining Qty</th>
                                        <th className='pg-tab-head'>Received Qty</th>
                                        <th className='pg-tab-head'>Approved Qty</th>
                                        <th className='pg-tab-head'>Rejected Qty</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    
                                    {this.state.poGroupList}

                                    
                                </tbody>
                            </table>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        {/* <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TDS Applicable</div>
                                    <div className="value">
                                        <input 
                                            type="checkbox" 
                                            disabled={this.state.is_tds_locked}
                                            checked={this.state.is_tds_applicable}
                                            onChange={()=>this.setState({ 
                                                is_tds_applicable: !this.state.is_tds_applicable,
                                                tds_percentage: "",
                                                tds_value: "",
                                                after_tds_grandtotal: "" 
                                            })} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">TDS Percentage</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.tds_percentage}
                                            disabled={!this.state.is_tds_applicable} 
                                            onChange={(e)=>this.changeTDSPercentage(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TDS Value</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.tds_value}
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Grand Total</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.currencyDetails.conversionRate ? this.state.tds_grandtotal_inother : this.state.tds_grandtotal_ininr}
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                       */}

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateInvoice()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            {/* <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div> */}
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default UpdateInvoiceByQC;