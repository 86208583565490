import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Helper from '../../../helper/helper';

import {
    AiOutlineExclamation
} from "react-icons/ai";

import { 
    validateEmail,
    validateBlank
 } from './../../../constants/Validate'

export default class UpdateVendorContact extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            title: "",

            id: "",
            contactId: "",
            name: "",
            phone: "",
            email: "",
            fax: "",
            address: "",
            addinfo: "",

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let num = localStorage.getItem("vendorNumber").toUpperCase()
        let name = localStorage.getItem("vendorName")
        let id = localStorage.getItem("vendorId")
        let contactId = localStorage.getItem("vendorContactId")
        this.setState({ 
            title: num+ " " +name, 
            id: id,
            contactId: contactId
        },()=>{
            this.getContactDetails()
        })
    }

    getContactDetails = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("id", this.state.id);
        formdata.append("contactId", this.state.contactId);

        fetch(APIManager.WEBSERVICES_URL+'procurement/vendors/getSingleVendorContacts',{
                method: "post",
                headers: myHeaders,
                body: formdata
            })
            .then(res => res.json())
            .then((result) => {
                if(result.status === "success"){
                     that.setState({
                        name: result.data.name,
                        phone: result.data.phone,
                        email: result.data.email,
                        fax: result.data.fax,
                        address: result.data.address,
                        addinfo: result.data.additionalInformation
                     })

                }else if(result.status === "userDataError"){
                    that.logout()
                }else{

                }
            },(error) => {
                console.log(error)
            }
        )
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewVendorContact") !== null){
            localStorage.removeItem("createNewVendorContact")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }


    updateVendorContact = () => {

        if(this.state.fieldNotEditable !== true){
        
        let {
            name,
            phone,
            email,
            fax,
            address,
            addinfo,
        } = this.state;

        if(name === ""){
            this.setState({
                error: true,
                errormessage: "Please enter name",
            })
        }else if(phone === ""){
            this.setState({
                error: true,
                errormessage: "Please enter phone number",
            })
        }else  if(email === ""){
            this.setState({
                error: true,
                errormessage: "Please enter email",
            })
        }else if(validateBlank(email) && !validateEmail(email)){
            this.setState({
                error: true,
                errormessage: "Please enter valid email address"
            })
        }else{

            let that = this;
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token
    
            let myHeaders = new Headers();
            myHeaders.append("token", token);
    
            let formdata = new FormData();
            formdata.append("id", this.state.id);
            formdata.append("contactId", this.state.contactId);

            formdata.append("name",name);
            formdata.append("phone",phone);
            formdata.append("email",email);
            formdata.append("fax",fax);
            formdata.append("address",address);
            formdata.append("additionalInformation",addinfo);
    
            fetch(APIManager.WEBSERVICES_URL+'procurement/vendors/updateVendorContacts',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata
                })
                .then(res => res.json())
                .then((result) => {
                    if(result.status === "success"){
                        this.setState({
                            error: false,
                            success: true,
                            successmessage: result.message,
                            fieldNotEditable: true,
                        })
                    }else if(result.status === "userDataError"){
                        that.logout()
                    }else{
                        this.setState({
                            error: true,
                            errormessage: result.message,
                        })
                    }
                },(error) => {
                    console.log(error)
                }
            )
        }
        }else{
            let slug = "Procurement_Vendors"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    deleteContact = () => {
        let slug = "Procurement_Vendors"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                let that = this;
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state.id);
                formdata.append("contactId", this.state.contactId);

                fetch(APIManager.WEBSERVICES_URL+'procurement/vendors/deleteVendorContacts',{
                        method: "post",
                        headers: myHeaders,
                        body: formdata
                    })
                    .then(res => res.json())
                    .then((result) => {
                        if(result.status === "success"){
                            that.goBack()
                        }else if(result.status === "userDataError"){
                            that.logout()
                        }else{

                        }
                    },(error) => {
                        console.log(error)
                    }
                )
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    
    }

   
    render(){
        return(
            <div className="page absoluteWhite">
               <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isVendors={true} />
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Contact of vendor {this.state.title}</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                   
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateVendorContact()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteContact()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Vendor </div>
                                <div className="value companyname">{this.state.title}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Name * </div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Phone *</div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.phone} onChange={(e)=>this.setState({ phone: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">E-mail *</div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.email} onChange={(e)=>this.setState({ email: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Fax</div>
                                <div className="value"><input type="text" autoComplete="off" className="fieldheightdecr" value={this.state.fax} onChange={(e)=>this.setState({ fax: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Address</div>
                                <div className="value"><textarea value={this.state.address} className="fieldheightdecr" onChange={(e)=>this.setState({ address: e.target.value })}/></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Additional information</div>
                                <div className="value"><textarea className="fieldheightdecr" value={this.state.addinfo} onChange={(e)=>this.setState({ addinfo: e.target.value })}/></div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateVendorContact()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteContact()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}