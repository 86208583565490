import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import { Link } from 'react-router-dom';
import FlatList from 'flatlist-react';
import * as APIManager from './../../../APIManager/APIManager'

const settings = [
    { text: "Model No.", id: "ModelNo" },
    { text: "Main MOC", id: "MainMoc" },
    { text: "Impeller MOC", id: "ImpellerMoc" },
    { text: "Impeller Type", id: "ImpellerType" },
    { text: "Mech Seal", id: "MechSeal" },
    { text: "Motor Rating", id: "MotorRating" },
    { text: "Document Types", id: "PumpDocumentName" },
]

class PumpMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "MainMoc",
            selectedIndexText: "Main MOC",

            impellerMOCData: [],
            impellerTypeData: [],
            modelNoData: [],
            mainMOCData: [],
            mechSealData: [],
            motorRatingData: [],
            pumpDocumentData: [],

        };
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount(){
        let getSelectedIndex = localStorage.getItem('pump_master_selected_index')
        let getSelectedIndexText = localStorage.getItem('pump_master_selected_index_text')
        if(getSelectedIndex === "" || getSelectedIndex === null){
            this.setState({
                selectedIndex: 'ModelNo',
                selectedIndexText: "Model No."
            })
        }else{
            this.setState({
                selectedIndex: getSelectedIndex,
                selectedIndexText: getSelectedIndexText
            })
        }
        this.getAllRecords()
        this.checkAlfaUser()
    }
    checkAlfaUser = () => {
        let alfaUserAccountId = "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
        let user = localStorage.getItem('userdata') 
        if(user){
            let accountId = JSON.parse(localStorage.getItem('userdata')).account_id
            if(alfaUserAccountId !== accountId){
                this.props.history.push({
                    pathname: "/home"
                })
            }
        }
    }

    getAllRecords = () => {
        this.callGetModelNo()
        this.callGetImpellerMOC()
        this.callGetImpellerType()
        this.callGetMainMOC()
        this.callGetMechSeal()
        this.callGetMotorRating()
        this.callGetPumpDocumentType()
    }

    callGetModelNo = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetModelNo()
        .then(response => {
            this.setState({ 
                isLoading: false,
                modelNoData: response.data.pumpModelNo !== undefined ? response.data.pumpModelNo : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    callGetImpellerMOC = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerMOC()
        .then(response => {
            this.setState({ 
                isLoading: false,
                impellerMOCData: response.data.impellerMoc !== undefined ? response.data.impellerMoc : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }
    callGetImpellerType = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerType()
        .then(response => {
            this.setState({ 
                isLoading: false,
                impellerTypeData: response.data.impellerType !== undefined ? response.data.impellerType : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }
    callGetMainMOC = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMainMOC()
        .then(response => {
            this.setState({ 
                isLoading: false,
                mainMOCData: response.data.mainMoc !== undefined ? response.data.mainMoc : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }
    callGetMechSeal = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMechSeal()
        .then(response => {
            this.setState({ 
                isLoading: false,
                mechSealData: response.data.mechSeal !== undefined ? response.data.mechSeal : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }
    callGetMotorRating = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMotorRating()
        .then(response => {
            this.setState({ 
                isLoading: false,
                motorRatingData: response.data.motorRating !== undefined ? response.data.motorRating : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }
    callGetPumpDocumentType = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPumpDocumentType()
        .then(response => {
            this.setState({ 
                isLoading: false,
                pumpDocumentData: response.data.pumpDocumentName !== undefined ? response.data.pumpDocumentName : []
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }


    renderSettings = (settings, index) => {
        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts' } onClick={() => {
                this.setState({
                    selectedIndex: index,
                    selectedIndexText: settings.text
                },()=>{
                    localStorage.setItem('pump_master_selected_index', index);
                    localStorage.setItem('pump_master_selected_index_text', settings.text);
                });
              }}
          >
                {settings.text}
            </div>
        );
    }

    editMaster = (path) => {
        let slug = "Settings_PumpMaster"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: path
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isPumpMaster={true} />

                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{this.state.selectedIndexText}</div>
                            </div>
                        </div>
                    </div>               
                    <div className="tableContent">
                        <div className="datamenus">
                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>

                        {
                            this.state.selectedIndex === "ModelNo" ?
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                        <table>
                                            <tbody>
                                                {
                                                    this.state.modelNoData.map((data, i) => (
                                                        <tr key={'modelno'+i}>
                                                            <td>{data.pumpModelNo}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div onClick={()=>this.editMaster('/updatemodelno')} className="setEditButton">Edit</div>
                                </div>
                            :
                            this.state.selectedIndex === "MainMoc" ?
                                
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                    <table>
                                        <tbody>
                                            {
                                                this.state.mainMOCData.map((data, i) => (
                                                    <tr key={'mainmoc'+i}>
                                                        <td>{data.mainMoc}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    <div onClick={()=>this.editMaster('/updatemainmoc')} className="setEditButton">Edit</div>
                                </div>
                            :
                            this.state.selectedIndex === "ImpellerMoc" ?
                                
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                    <table>
                                        <tbody>
                                            {
                                                this.state.impellerMOCData.map((data, i) => (
                                                    <tr key={'impellerMOC'+i}>
                                                        <td>{data.impellerMoc}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    <div onClick={()=>this.editMaster('/updateimpellermoc')} className="setEditButton">Edit</div>
                                </div>
                            :
                            this.state.selectedIndex === "ImpellerType" ?
                                
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                   <table>
                                        <tbody>
                                            {
                                                this.state.impellerTypeData.map((data, i) => (
                                                    <tr key={'impellerType'+i}>
                                                        <td>{data.impellerType}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    <div onClick={()=>this.editMaster('/updateimpellertype')} className="setEditButton">Edit</div>
                                </div>
                            :
                            this.state.selectedIndex === "MechSeal" ?
                                
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                    <table>
                                        <tbody>
                                            {
                                                this.state.mechSealData.map((data, i) => (
                                                    <tr key={'mechSeal'+i}>
                                                        <td>{data.mechSeal}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    <div onClick={()=>this.editMaster('/updatemechseal')} className="setEditButton">Edit</div>
                                    
                                </div>
                            :
                            this.state.selectedIndex === "MotorRating" ?
                                
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                    <table>
                                        <tbody>
                                            {
                                                this.state.motorRatingData.map((data, i) => (
                                                    <tr key={'motorRating'+i}>
                                                        <td>{data.motorRating}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    <div onClick={()=>this.editMaster('/updatemotorrating')} className="setEditButton">Edit</div>
                                </div>
                            :
                            this.state.selectedIndex === "PumpDocumentName" ?
                                
                                <div className="dataDetails">
                                    <div className="pumpTableMaster_data">
                                    <table>
                                        <tbody>
                                            {
                                                this.state.pumpDocumentData.map((data, i) => (
                                                    <tr key={'pumpDocument'+i}>
                                                        <td>{data.pumpDocumentName}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    <Link to="/updatedocumentstype"><div onClick={()=>this.editMaster('/updatedocumentstype')} className="setEditButton">Edit</div></Link>

                                </div>
                            :
                            null
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default PumpMaster;