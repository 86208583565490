import React, { Component } from 'react';

import DatePicker from "react-datepicker";
import './../../lib/react-datepicker.min.css'

import * as APIManager from './../../APIManager/APIManager'

import Pagination from "react-js-pagination";
import Popup from 'reactjs-popup';

import * as Ai from "react-icons/ai"
import * as Fi from "react-icons/fi";
import TableLoading from '../../helper/TableLoading';
import { convertDateTimeStringMMDDtoDDMM } from '../../constants/DateFormatter';

class Table extends Component {

    constructor(props) {
        super(props)
        this.state = {

            updateTableRecords: this.props.updateTableRecords,

            number: "",
            partNo: "",
            partDescription: "",
            minQuantity: "",
            maxQuantity: "",
            status: "",
            minStartDate: "",
            maxStartDate: "",
            minEndDate: "",
            maxEndDate: "",

            responseData: [],

            totalRecords: 0,
            activePage: 1,
            start: 0,
            length: 25,
            
            fieldName: '',
            fieldType: '',
            sortVal: false,
        }
    }

    componentDidMount() {
        // this.getProductionPlanning()
        this.getFilterData()
    }
    
 //******************* ascending descending order function ********************************
 sortCustomer = (fieldName, fieldType, onload) => {
        
    let sortVal = this.state.sortVal;
    if(!onload){
        if (this.state.fieldName === fieldName) {
            sortVal = !this.state.sortVal;
        }
    }
    this.setState({
        fieldName: fieldName,
        fieldType: fieldType,
        sortVal: sortVal
    }, () => {
        const sortedOrders = this.state.responseData.sort((a, b) => {

            if (fieldType === "Date") {
                const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return sortVal ? 1 : -1;
                } else if (!dateB) {
                    return sortVal ? -1 : 1;
                }
                if (dateA.getFullYear() !== dateB.getFullYear()) {
                    return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                }
                if (dateA.getMonth() !== dateB.getMonth()) {
                    return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                }
                if (dateA.getDate() !== dateB.getDate()) {
                    return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                }
                return 0;
            }
            else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                // console.log('NotDate success');
                const numA = parseFloat(a[fieldName]);
                const numB = parseFloat(b[fieldName]);
                if (sortVal) {
                    return numA > numB ? 1 : -1;
                } else {
                    return numA < numB ? 1 : -1;
                }
            } else {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            }

        });
        this.setState({ responseData: sortedOrders }, () => {
            this.setFilterData()
        });
    });
    
};
//******************* ascending descending order function end ********************************


    getProductionPlanning = () => {
        let {
            start, length, number, partNo, partDescription, minQuantity, maxQuantity, status, minStartDate, maxStartDate, minEndDate, maxEndDate
        } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllProductionPlanning(start, length, number, partNo, partDescription, minQuantity, maxQuantity, status, minStartDate, maxStartDate, minEndDate, maxEndDate)
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseData: response.data,
                    totalRecords: response.totalRecords,
                }, () => {
                    this.setFilterData()
                    this.state.updateTableRecords(response.data)
                    
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });

    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0
        this.setState({
            isLoading: true,
            activePage: pageNumber,
            start: newStart
        }, () => {
             this.getProductionPlanning() 
        });
    }

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'partNo': this.state.partNo,
            'partDescription': this.state.partDescription,
            'minQuantity': this.state.minQuantity,
            'maxQuantity': this.state.maxQuantity,
            'status': this.state.status,
            'minStartDate': this.state.minStartDate !== null ? this.state.minStartDate:"",
            'maxStartDate': this.state.maxStartDate  !== null ? this.state.maxStartDate:"",
            'minEndDate': this.state.minEndDate  !== null ? this.state.minEndDate:"",
            'maxEndDate': this.state.maxEndDate  !== null ? this.state.maxEndDate:"",
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("productionPlan", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlan"))

        
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                number: filterData.number,
                partNo: filterData.partNo,
                partDescription: filterData.partDescription,
                minQuantity: filterData.minQuantity,
                maxQuantity: filterData.maxQuantity,
                status: filterData.status,
                minStartDate: filterData.minStartDate  ? new Date(filterData.minStartDate) : "",
                maxStartDate: filterData.maxStartDate  ? new Date(filterData.maxStartDate) : "",
                minEndDate: filterData.minEndDate ? new Date(filterData.minEndDate) : "",
                maxEndDate: filterData.maxEndDate  ? new Date(filterData.maxEndDate) : "",
                activePage: filterData.activePage,
                start: filterData.start,
                length: filterData.length,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getProductionPlanning()
            })
        } else {
            this.getProductionPlanning()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlan")
        this.setState({
            number: "",
            partNo: "",
            partDescription: "",
            minQuantity: "",
            maxQuantity: "",
            status: "",
            minStartDate: "",
            maxStartDate: "",
            minEndDate: "",
            maxEndDate: "",
            length: 25,
            start: 1,
            activePage: 1
        }, () => {
            this.getProductionPlanning()
        })
    }

    viewProductionPlan = (id) => {
        this.props.history.push({
            pathname: "/viewproductionplan",
            state: { MO_Id: id },
        })
    }

    setDateTimeInIndianFormat = (dt) => {

        return convertDateTimeStringMMDDtoDDMM(dt)

        // let newDate = "";
        // if(dt){
        //     let splitDate = dt.split(' ')
        //     console.log(splitDate)
        //     let d = splitDate[0].split('/')
        //     newDate = d[1]+'/'+d[0]+'/'+d[2]+' '+splitDate[1]+ ' '+splitDate[2]
        // }
        // return newDate;
    }

    render() {
        return (
            <div className="tableContent mh400 __kDoEM__table " style={{ overflowX: "auto" }}>
                <table>
                    <thead>
                        <tr className="no-bg">
                            <th className="tableHeader tableHeadButton"></th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('number', 'notDate' , false)}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('partNumber', 'notDate' , false)}><span>Part No.</span> {this.state.fieldName === "partNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('partDescription', 'notDate' , false)}><span>Part description</span> {this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('quantity', 'notDate' , false)}><span>Quantity</span> {this.state.fieldName === "quantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "quantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('status', 'notDate' , false)}><span>Status</span> {this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('startDate', 'Date' , false)}><span>Start</span> {this.state.fieldName === "startDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "nustartDatember" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                            <th className="tableHeader"  onClick={() => this.sortCustomer('finishDate', 'Date' , false)}><span>Finish</span> {this.state.fieldName === "finishDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "finishDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                           
                            {/* <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><Fi.FiChevronsDown /></th>} position="bottom right">
                                <div className="selectColumns">
                                    <ul>
                                        <li><label htmlFor="partno"><span>Part No</span> <input type="checkbox" id="partno" /></label></li>
                                    </ul>
                                </div>
                            </Popup> */}
                            <th></th>
                            <th></th>
                        </tr>

                        <tr>
                            <th className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                            <th className="tableElemContain"><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getProductionPlanning() })} /></th>
                            <th className="tableElemContain"><input type="text" value={this.state.partNo} onChange={(e) => this.setState({ partNo: e.target.value }, () => { this.getProductionPlanning() })} /></th>
                            <th className="tableElemContain"><input type="text" value={this.state.partDescription} onChange={(e) => this.setState({ partDescription: e.target.value }, () => { this.getProductionPlanning() })} /></th>
                            <th className="items ElemContain">
                                <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minQuantity} onChange={(e) => this.setState({ minQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getProductionPlanning() })} />&nbsp;-&nbsp;
                                <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxQuantity} onChange={(e) => this.setState({ maxQuantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getProductionPlanning() })} />
                            </th>

                            <th className="tableElemContain">
                                <select style={{ minWidth: 100 }} value={this.state.status} onChange={(e) => this.setState({ status: e.target.value }, () => { this.getProductionPlanning() })}>
                                    <option value=""></option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="started">Started</option>
                                    <option value="paused">Paused</option>
                                    <option value="finished">Finished</option>
                                </select>
                            </th>
                            <th className="tableElemContain">
                                <div className="value">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.minStartDate}
                                        onChange={date => this.setState({ minStartDate: date }, () => { this.getProductionPlanning() })}
                                        className="filterdateoption" /> <span className="dash">-</span>
                                    <Ai.AiOutlineCalendar className="datepickerIcon" />
                                </div>

                                <div className="value">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        minDate={this.state.minStartDate}
                                        selected={this.state.maxStartDate}
                                        onChange={date => this.setState({ maxStartDate: date }, () => { this.getProductionPlanning() })}
                                        className="filterdateoption" />
                                    <Ai.AiOutlineCalendar className="datepickerIcon" />
                                </div>
                            </th>
                            <th className="tableElemContain">
                                <div className="value">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.minEndDate}
                                        onChange={date => this.setState({ minEndDate: date }, () => { this.getProductionPlanning() })}
                                        className="filterdateoption" /> <span className="dash">-</span>
                                    <Ai.AiOutlineCalendar className="datepickerIcon" />
                                </div>

                                <div className="value">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        minDate={this.state.minEndDate}
                                        selected={this.state.maxEndDate}
                                        onChange={date => this.setState({ maxEndDate: date }, () => { this.getProductionPlanning() })}
                                        className="filterdateoption" />
                                    <Ai.AiOutlineCalendar className="datepickerIcon" />
                                </div>
                            </th>


                            <th className="tableElemContain tableAddButton"><div className="cursor-pointer"></div></th>
                            <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><Fi.FiX /></div></th>

                        </tr>
                    </thead>

                    {
                        this.state.isLoading ?
                            <TableLoading tr={5} td={10} />
                        :
                        <tbody>
                            {
                                this.state.responseData.map((data, i) => (
                                    <tr onClick={() => this.viewProductionPlan(data._id)} key={"pp-table" + i}>
                                        <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                        <td>{data.number.toUpperCase()}</td>
                                        <td>{data.partNumber ? data.partNumber.toUpperCase() : ""}</td>
                                        <td>{data.partDescription}</td>
                                        <td>{data.quantity}</td>
                                        <td>{data.status}</td>
                                        <td>{this.setDateTimeInIndianFormat(data.startDate)}</td>
                                        <td>{this.setDateTimeInIndianFormat(data.finishDate)}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    }
                </table>
                <div>
                    <div className="float-left mt-10px">
                        <div className="tableElemContain" style={{ width: 80 }}>
                            <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                <option value="25">25 rows</option>
                                <option value="50">50 rows</option>
                                <option value="100">100 rows</option>
                                <option value="">All rows</option>
                            </select>
                        </div>
                    </div>
                    <div className="float-right mt-10px">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                            totalItemsCount={this.state.totalRecords}
                            pageRangeDisplayed={10}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

export default Table;