import React, { Component } from 'react';

class shipmentWriteOffs extends Component {

    constructor(props){
        super(props)
        this.state = {
            groupData: this.props.groupData,
            pickQty: ""
        }
    }

    

 
    render() {
        return (

            <tr>
                <td>{this.state.groupData.stockItem}</td>
                <td>{this.state.groupData.quantity}</td>
                <td>{this.state.groupData.lot}</td>
                <td>{this.state.groupData.status}</td>
                <td>{this.state.groupData.storageLocationName}</td>
                <td>
                    {this.state.groupData.quantity}
                    {/* <input 
                        type='text' 
                        className='input-box' 
                        onChange={(e)=>{
                            if(e.target.value <= parseInt(this.state.groupData.quantity)){
                                this.setState({
                                    pickQty: parseInt(e.target.value) ? parseInt(e.target.value) : ""
                                })
                            }else{

                            }
                        }}
                        value={this.state.pickQty}
                    /> */}
                </td>
            </tr>

            // <>
            //     {
            //         this.state.groupData.map((data,i)=>(
            //             <tr key={"shippedItem"+i}>
            //                 <td>{data.stockItem}</td>
            //                 <td>{data.quantity}</td>
            //                 <td>{data.lot}</td>
            //                 <td>{data.status}</td>
            //                 <td>{data.storageLocationName}</td>
            //                 <td>
            //                 <input 
            //                         type='text' 
            //                         className='input-box' 
            //                         onChange={(e)=>{
            //                             if(parseInt(e.target.value) <= parseInt(data.quantity))
            //                             this.setState({
            //                                 pickQty: e.target.value
            //                             })
            //                         }}
            //                         value={this.state.pickQty}
            //                     />
            //                 </td>
            //             </tr>
            //         ))
            //     }
            // </>
        );
    }
}
export default shipmentWriteOffs;