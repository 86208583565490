import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import * as Im from 'react-icons/im'
import Loading from './../../../../helper/loading'
import RichTextEditor from 'react-rte';

// import ProductGroupForInr from './ProductGroupForInr';
// import ProductGroupForOther from './ProductGroupForOther';
import ProductGroup from './ProductGroup'

class EditCreditNote extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            coGroupList: [],
            coGroupData: [],
            coProductGroup: [],
            CoInvoice: [],
            addressList: [],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            creditNoteId: this.props.location.state.creditNoteId,
            isNewEntry: this.props.location.state.isNewEntry,

            number: "",

            customerId: "",
            invoice_id: "",
            invoiceNumber: "",
            invoiceType: "credit",
            invoiceDate: "",

            customerName: "",
            applicableTax: "",
            applicableTaxType: [],

            invoiceMessageHeaderEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageHeader: "",
            invoiceMessageFooterEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageFooter: "",

            currencyData: [],
            sezCustomer: false,
            applicableTaxTypeWithAllData: [],

            is_eInvoiceSetup: false,
            eInvoiceFlag: false,
            eWayBillFlag: false,

            fieldNotEditable: true,

        }
        this.setUpdatedGroupData = this.setUpdatedGroupData.bind(this)
    }

    componentDidMount(){
       this.callGetSingleCreditNote()
       console.log('this.state.this.state.is_e_invoice_possible',this.state.is_e_invoice_possible)
    }



    callGetSingleCreditNote = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleCreditNote(this.state.creditNoteId)
        .then(response => {
            console.log("Repors ter ", response)
            this.setState({
                isLoading: false, 
                error: false,
                number: response.data.number,
                invoiceNumber: response.data.invoiceNumber,
                customerName: response.data.customerName,
                invoiceDate: response.data.invoiceDate,
                coGroupData: response.data.productGroup,
                invoice_id: response.data.customerOrderInvoiceId,
                invoiceMessageHeader: response.data.invoiceMessageHeader ? response.data.invoiceMessageHeader : "",
                invoiceMessageFooter: response.data.invoiceMessageFooter ? response.data.invoiceMessageFooter : "",
                invoiceMessageHeaderEditor: response.data.invoiceMessageHeader ? RichTextEditor.createValueFromString(response.data.invoiceMessageHeader, 'html') : RichTextEditor.createEmptyValue(),
                invoiceMessageFooterEditor: response.data.invoiceMessageFooter ? RichTextEditor.createValueFromString(response.data.invoiceMessageFooter, 'html') : RichTextEditor.createEmptyValue(),
                is_e_invoice_possible: response.data.eInvoiceDetail ? true : false,
                eInvoiceFlag: response.data.eInvoiceFlag ? response.data.eInvoiceFlag : false,
                eWayBillFlag: response.data.eWayBillFlag ? response.data.eWayBillFlag : false,
                is_eInvoiceSetup: response.data.is_eInvoiceSetup ? response.data.is_eInvoiceSetup : false,
            },()=>{
                this.getOrderDetails(response.data.customerOrderId)
                
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getOrderDetails = (customerOrder) => {
        if(customerOrder){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerOrderDetail(customerOrder)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    customerId: response.customer,
                    coProductGroup: response.productGroup
                },()=>{
                    this.getCustomerDetails(response.customer)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            })
        }
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {
            let dt = new Date()
            if(response.companyData.paymentTerms){
                if(response.companyData.paymentTerms.paymentStatus === "from_invoice"){
                    let days = response.companyData.paymentTerms.daysFrom !== "" ? response.companyData.paymentTerms.daysFrom : 0
                    let newDate = new Date().setDate(new Date().getDate() + parseInt(days))
                    dt = new Date(newDate)
                }
            }
            let taxType = []
            let taxResponseData = response.companyData.taxApplicable
            let sezCustomer = false
            let invoiceType = ""
            if(!taxResponseData.includes("notax")){
                if(taxResponseData.includes("exemptedtax")){
                    sezCustomer = true
                    invoiceType = "sez"
                    taxType = ["IGST"]
                }else{
                    taxType = response.companyData.taxApplicable
                    invoiceType = "tax"
                }
            }else{
                invoiceType = "export"
            }

            this.setState({
                isLoading: false,
                customerName: response.companyData.companyId.toUpperCase()+' '+response.companyData.companyName,
                currencyData: response.companyData.currencyDetails,
                applicableTax: "",
                applicableTaxType: taxType,
                applicableTaxTypeWithAllData: taxResponseData,
                sezCustomer: sezCustomer,
            },()=>{
                this.setGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

 

    setGroupData = () => {
        let data = this.state.coGroupData

        let group = []
        for(let i=0;i<data.length;i++){
            group.push(
                <ProductGroup 
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    applicableTaxTypeWithAllData={this.state.applicableTaxTypeWithAllData}
                    currencyData={this.state.currencyData}
                    key={"groupData"+i}
                    data={data[i]}
                    freeText={this.state.coProductGroup[i].productFreeText}
                    itemCode={this.state.coProductGroup[i].itemCode}
                    uom={data[i].uom}
                    customerId={this.state.customerId}
                    callBack={this.setUpdatedGroupData}
                />
            )
        }
        this.setState({
            coGroupList: group,
            coGroupData: data
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let coGroupData = [...this.state.coGroupData]  
        coGroupData[counter] = value;  
        this.setState({
            coGroupData
        },()=>{
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.coGroupData

        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for(let i=0;i<data.length;i++){
            qty += data[i].deliveredQuantity ? parseFloat(data[i].deliveredQuantity) : 0
            subTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            if(this.state.invoiceType === "sez"){
                grandTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            }else{
                grandTotal += data[i].total ? parseFloat(data[i].total) : 0
            }
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        })
    }


    deleteEntry = () => {
        let slug = "CRM_CreditNote"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteCreditNote(this.state.creditNoteId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    onHeaderMessageChange = (value) => {
        this.setState({
            invoiceMessageHeaderEditor: value,
            invoiceMessageHeader: value.toString('html')
        });
    }

    onFooterMessageChange = (value) => {
        this.setState({
            invoiceMessageFooterEditor: value,
            invoiceMessageFooter: value.toString('html')
        });
    }

    updateCreditNote = () => {
        if(!this.state.fieldNotEditable){
            let {
                creditNoteId,
                coGroupData,
                subTotal,
                grandTotal,
                invoiceMessageHeader,
                invoiceMessageFooter,
            } = this.state

            if(parseFloat(grandTotal) === 0){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please update credit note item details"
                },()=>{
                    this.scrollToTop()
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateCreditNote(creditNoteId, JSON.stringify(coGroupData), subTotal, grandTotal, invoiceMessageHeader, invoiceMessageFooter)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Credit Note Updated Successfully"
                    },()=>{
                        this.scrollToTop()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
            }
        }else{
            let slug = "CRM_CreditNote"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    downloadPDF = () => {
        localStorage.setItem("pdfType", this.state.pdfType)
        localStorage.setItem("invoice_id", this.state.invoice_id)
        window.open('cninvoicepdf')
    }

    generateEWayBillPDF = () => {
        window.open('cnewaybillpdf?invoice_id='+this.state.invoice_id)
    }
    
    downloadPDFInOtherCurrency1 = () => {
        localStorage.setItem("invoice_id", this.state.invoice_id)
        window.open('cninvoiceother')
    }

    downloadPDFOtherCurrencyInInr1 = () => {
        localStorage.setItem("invoice_id", this.state.invoice_id)
        window.open('cninvoiceotherinr')
    }

    downloadPDFInOtherCurrency = () => {
        if(this.state.pdfType === "originalinother"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "duplicateinother"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "triplicateinother"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "extrainother"){
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "originalininr"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }else if(this.state.pdfType === "duplicateininr"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }else if(this.state.pdfType === "triplicateininr"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }else{
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }
    }


    eInvoiceDetailPage = () => {
        let slug = "CRM_E_Invoices"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/cnvieweinvoice",
                state: { creditNoteId: this.state.creditNoteId }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    eWayBillDetailPage = () => {
        let slug = "CRM_E_Invoices"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/cnviewewaybill",
                state: { creditNoteId: this.state.creditNoteId }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCreditNote={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="page-title">Edit Credit Invoice No. {this.state.number.toUpperCase()}</div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {
                                        this.state.currencyData.conversionRate ?
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e)=>this.setState({ pdfType: e.target.value})}>
                                                    <option value="originalinother">Original in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="duplicateinother">Duplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="triplicateinother">Triplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="extrainother">Extra in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="originalininr">Original in &#8377;</option>
                                                    <option value="duplicateininr">Duplicate in &#8377;</option>
                                                    <option value="triplicateininr">Triplicate in &#8377;</option>
                                                    <option value="extraininr">Extra in &#8377;</option>
                                                </select>
                                                <div className="button" onClick={()=>this.downloadPDFInOtherCurrency()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && this.state.eInvoiceFlag === false) &&
                                                        <div className="button" onClick={()=>this.eInvoiceDetailPage()}>Generate E-Invoice</div>   
                                                }

                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && !this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.generateEWayBillPDF()}>Download E-Way PDF</div>
                                                }
                                                <div className="clear"></div>                                                
                                            </div>
                                        :
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e)=>this.setState({ pdfType: e.target.value})}>
                                                    <option value="original">Original</option>
                                                    <option value="duplicate">Duplicate</option>
                                                    <option value="triplicate">Triplicate</option>
                                                    <option value="extra">Extra</option>
                                                </select>
                                                
                                                <div className="button" onClick={()=>this.downloadPDF()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && this.state.eInvoiceFlag === false) &&
                                                        <div className="button" onClick={()=>this.eInvoiceDetailPage()}>Generate E-Invoice</div>   
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && !this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.generateEWayBillPDF()}>Download E-Way PDF</div>
                                                }

                                                <div className="clear"></div>    
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateCreditNote()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice*</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.invoiceNumber.toUpperCase()}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                               
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice Type</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceType} Invoice</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                               
                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <div className="createdText clickable__source text-capitalize">{this.state.invoiceDate}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>  
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="add_outer_full_part position-relative">
                            <div className="title">Invoice Header</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageHeaderEditor}
                                    onChange={this.onHeaderMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                       

                        <div className="main-container mt-5 invoice_product_group position-relative">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Delivered Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                                <th className='pg-tab-head'>Subtotal</th>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <th className='pg-tab-head' key={'appTax'+i}>
                                                    {data}
                                                    {
                                                        this.state.applicableTaxTypeWithAllData.includes("exemptedtax") &&
                                                        <small>(Exempted)</small>
                                                    }
                                                </th>
                                            ))
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Credit Qty</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    
                                    {this.state.coGroupList}

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{this.state.totalQty}</b></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            <td><b><Bi.BiRupee /> {this.state.subTotal} </b></td>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <td key={'appTaxBlankField'+i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyData.conversionRate ?
                                                <td><b>{this.state.currencyData.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                            :
                                                <td><b><Bi.BiRupee /> {this.state.grandTotal} </b></td>

                                        }
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>


                        <div className="add_outer_full_part mt-1 position-relative">
                            <div className="title">Invoice Footer</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageFooterEditor}
                                    onChange={this.onFooterMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                      

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateCreditNote()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default EditCreditNote;