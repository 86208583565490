import React, { PureComponent } from 'react';
import './../../../App.css'

class Loading extends PureComponent{
    render(){
        return(
            <div className="pageLoading" />
        )
    }
}

export default Loading;
