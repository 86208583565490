import React from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'

import { AiOutlineExclamation, AiOutlineCalendar } from "react-icons/ai";

import BookingItemListing from './BookingItemListing';
import EditMOOperations from './editMOOperations';
import { convertDateStringToDateAndTimeObject, convertDateStringMMDDtoDDMM, convertDateTimeStringMMDDtoDDMM } from '../../../constants/DateFormatter';


export default class EditManufacturingOrder extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            fieldNotEditable: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",


            userData: [],
            departmentData: [],
            department: "",

            MO_Id: this.props.location.state.MO_Id,
            isNewEntry: this.props.location.state.newBOMEntry,

            number: "",
            productGroup: "",
            productGroupName: "",
            product: "",
            productName: "",
            uom: "",
            quantity: "",
            oldQuantity: "",
            dueDate: "",
            assignedTo: "",
            targetLotNumber: "",
            targetLotId: "",
            billOfMaterials: "",
            BOM_name: "",
            created: "",
            status: "",
            startDate: "",
            finishDate: "",
            totalCost: "",
            costPer1Pc: "",
            costOfMaterials: "",
            appliedOverheadCost: "",
            isFinishedProduct: false,

            BOM_Details: [],
            BOM: [],
            routings: [],
            neededItems: [],

            Routing___List: [],

            itemDetails: [],
            afterBookingData: [],

            bookAllDisabled: false,
            releaseAllDisabled: false,
            flagEndDateAPICall: false,


            is_MoFinished: false,
            is_MoPaused: false,
            is_MoStart: false,
        }

        this.bookThisItem = this.bookThisItem.bind(this)
        this.cancelThisItem = this.cancelThisItem.bind(this)
        this.getSingleMoCallAfterOperationUpdate = this.getSingleMoCallAfterOperationUpdate.bind(this)
    }

    componentDidMount(){
        localStorage.setItem("manufacturingOrderId",this.state.MO_Id)
        localStorage.setItem("orderType","manufacturingOrder")

        this.getAllDepartments()
        this.getSingleMO(false)
    }

    getSingleMoCallAfterOperationUpdate = () => {
        this.getSingleMO(true)
    }
    
    getSingleMO = (defaultWorkerFlag) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleMO(this.state.MO_Id, defaultWorkerFlag)
        .then(response => {
            this.setState({

                isLoading: false,
                number: response.data.number,
                productGroup: response.data.productGroup,
                product: response.data.product,
                quantity: response.data.quantity,
                oldQuantity: response.data.quantity,

                assignedTo: response.data.assignedTo,
                targetLotNumber: response.data.targetLotNumber,
                targetLotId: response.data.targetLotId,

                billOfMaterials: response.data.billOfMaterials[0].bomId,
                BOM: response.data.billOfMaterials,
                routings: response.data.routingDetails,
                created: response.data.created,

                status: response.data.status,

                dueDate: response.data.dueDate !== "" ? new Date(response.data.dueDate) : "",
                startDate: response.data.startDate !== "" ? new Date(response.data.startDate) : "",
                finishDate: response.data.finishDate !== "" ? new Date(response.data.finishDate) : "",
                isFinishedProduct: response.data.actualFinish !== "" ? true : false,
                
                totalCost: response.data.totalCost.toFixed(2),
                costPer1Pc: response.data.costPer1Pc.toFixed(2),
                costOfMaterials: response.data.costOfMaterials.toFixed(2),
                appliedOverheadCost: response.data.appliedOverheadCost.toFixed(2),

                department: response.data.department,


                Routing___List: [],


                is_MoFinished: response.data.is_MoFinished,
                is_MoPaused: response.data.is_MoPaused,
                is_MoStart: response.data.is_MoStart,
                
            },()=>{
                this.getSingleProductGroup()
                this.getSingleProduct()
                this.getSingleBOM()
                this.setOperation()
                this.getMOWriteOff()
                this.getUsersByDepartment(this.state.department)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    }

    getAllDepartments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment("", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                departmentData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    getUsersByDepartment = (_id) => {
        if(_id){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetUsersByDepartment(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    userData: response.data
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }

    getBookingListForMo = () => {
        this.setState({ isLoading: true, error: false, itemDetails: [] })

        APIManager.callGetBookingForMO(this.state.MO_Id)
        .then(response => {
            
            console.log("Response ", response)
            let itemArray = response.data.itemArray
            let isBookDisabled = false;
            let countTotalNeeded = 0;
            let neededItems = []

            for(let i = 0; i < itemArray.length; i ++){
                countTotalNeeded += itemArray[i].needed
                if(itemArray[i].needed === 0){
                    isBookDisabled = true
                }
                let obj = {
                    part: itemArray[i]._id,
                    needed: itemArray[i].needed
                }
                neededItems.push(obj)
            }

            this.setState({
                isLoading: false,
                bookAllDisabled: isBookDisabled,
                releaseAllDisabled: countTotalNeeded === 0 ? false : true,
                itemDetails: response.data.itemArray,
                neededItems: neededItems,
                afterBookingData: response.data.afterBooking
            },()=>{
                if(this.state.finishDate === "" && countTotalNeeded === 0){
                    this.getEndDate()
                } 
                if(this.state.finishDate !== "" && countTotalNeeded !== 0){
                    this.setState({
                        finishDate: "",
                        dueDate: ""
                    },()=>{
                       // this.updateMODates()
                    })
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    }


    getMOWriteOff = () => {
        
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMOWriteOff(this.state.MO_Id)
        .then(response => {
            this.setState({ 
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }



    bookThisItem = (product, productGroup, needed, available, expectedAvailable) => {
        let slug = "ProductionPlanning_ManufacturingOrders"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
           // let totalAvailable = parseInt(available) + parseInt(expectedAvailable)
           // if(totalAvailable >= needed){
                localStorage.setItem("itemId", product)
                localStorage.setItem("itemGroupId", productGroup)
                localStorage.setItem("itemNeeded", needed)
                this.props.history.push("/addbooking")
            // }else{
            //     this.setState({
            //         success: false,
            //         error: true,
            //         errormessage: "There is no stock available for this item"
            //     },()=>{
            //         this.scrollToTop()
            //     })
            // }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    cancelThisItem = (id) => {
        let slug = "ProductionPlanning_ManufacturingOrders"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
        
            this.setState({ isLoading: true, error: false })
            APIManager.callCancelSingleMOItem(this.state.MO_Id,id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.getBookingListForMo()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    bookAllParts = () => {
        if(this.state.bookAllDisabled !== true){

            let slug = "ProductionPlanning_ManufacturingOrders"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({ isLoading: true, error: false })
                APIManager.callBookAllMOItem(this.state.MO_Id, JSON.stringify(this.state.neededItems))
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.getBookingListForMo()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors.message 
                    })
                });
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }


    releaseAllParts = () => {
        if(this.state.releaseAllDisabled !== true){
            let slug = "ProductionPlanning_ManufacturingOrders"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({ isLoading: true, error: false })
                APIManager.callCancelAllMOItem(this.state.MO_Id)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.getBookingListForMo()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors.message 
                    })
                });
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

  
    setOperation = () => {
        let routings = this.state.routings
        let routingList = routings[0]

        let operationList = []
        operationList.push(<tr className="font-weight-bold" key={"routings0"}>
            <td><input type="radio" name="selectRouting" checked={true} readOnly /></td>
            <td>{routingList.routingName}</td>
            <td>{routingList.totalCost} <Bi.BiRupee /></td>
            <td></td>
            <td>{routingList.totalCost} <Bi.BiRupee /></td>
            <td>{convertDateTimeStringMMDDtoDDMM(routingList.routingStartDate)}</td>
            <td>{convertDateTimeStringMMDDtoDDMM(routingList.routingEndDate)}</td>
            <td className="text-right clr-secondary"></td>
            <td className="text-right clr-secondary"></td>
        </tr>)

        let workstation = routingList.workStationDetails

        if(workstation.length > 0){
            for(let j = 0; j<workstation.length; j++){
                operationList.push(
                    <EditMOOperations 
                        key={j+"Operations"}
                        workstationData={workstation[j]}
                        moId={this.state.MO_Id}
                        getSingleMOCall={this.getSingleMoCallAfterOperationUpdate}
                    />
                )
            }
        }

        this.setState({
            isLoading: false,
            success: false,
            error: false,
            Routing___List: operationList
        })
    }

    getEndDate = () => {
       
        let {
            startDate,
            BOM,
            quantity,
            MO_Id
        } = this.state;

        let today = new Date()
        let newStartDate = ""

        if(startDate < today){
            newStartDate = convertDateStringToDateAndTimeObject(today)
        }else{
            newStartDate = convertDateStringToDateAndTimeObject(startDate)
        }
        
        this.setState({ isLoading: true, error: false })
        APIManager.callGetEndDateMO(newStartDate, BOM, quantity, MO_Id)
        .then(response => {
            console.log("callGetEndDateMO ", response )    

            let routingList = response.data[0].routingInfo
    
            let operationList = []
            operationList.push(<tr className="font-weight-bold" key={"routings0"}>
                <td><input type="radio" name="selectRouting" checked={true} readOnly /></td>
                <td>{routingList.routingName}</td>
                <td>{routingList.totalCost} <Bi.BiRupee /></td>
                <td></td>
                <td>{routingList.totalCost} <Bi.BiRupee /></td>
                <td>{routingList.routingStartDate}</td>
                <td>{routingList.routingEndDate}</td>
                <td className="text-right clr-secondary"></td>
                <td className="text-right clr-secondary"></td>
            </tr>)
    
            let workstation = routingList.workStationDetails
    
            if(workstation.length > 0){
                for(let j = 0; j<workstation.length; j++){
                    operationList.push(
                        <EditMOOperations 
                            key={j+"Operations"}
                            workstationData={workstation[j]}
                            moId={this.state.MO_Id}
                            getSingleMOCall={this.getSingleMoCallAfterOperationUpdate}
                        />
                    )
                }
            }
    
            this.setState({
                isLoading: false,
                success: false,
                error: false,

                Routing___List: operationList,

                dueDate: response.data[0].bomFinishDate !== "" ? new Date(response.data[0].bomFinishDate) : "",
                startDate: response.data[0].bomStartDate !== "" ? new Date(response.data[0].bomStartDate) : "",
                finishDate: response.data[0].bomFinishDate !== "" ? new Date(response.data[0].bomFinishDate) : "",

            },()=>{
                this.setNewBOMInfo(response.data)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });

    }


    setNewBOMInfo = (resp) => {
        if(resp){
            let data = resp[0]
            let setBOM = []
            let setRouting = []
            let obj = {
                "bomId": data._id,
                "approximationCost": data.totalCost,
                "startDate": data.bomStartDate,
                "endDate": data.bomFinishDate
            }
            setBOM.push(obj)

            
            let routingObj = {
                "routingId": data.routingInfo.id,
                "routingName": data.routingInfo.routingName,
                "workStationDetails": data.routingInfo.workStationDetails
            }

            setRouting.push(routingObj)

            this.setState({
                BOM: setBOM,
                routings: setRouting,
                startDate: data.bomStartDate !== "" ? new Date(data.bomStartDate) : "",
                dueDate: data.bomFinishDate !== "" ? new Date(data.bomFinishDate) : "",
                finishDate: data.bomFinishDate !== "" ? new Date(data.bomFinishDate) : ""
            },()=>{
                this.updateMODates()
            })
        }
    }

    getSingleProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProductGroup(this.state.productGroup)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupName: response.number.toUpperCase() +" "+response.name
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }

    getSingleProduct = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase() +" "+response.itemData.partDescription,
                uom: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }
    
    getSingleBOM = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleBOM(this.state.billOfMaterials)
        .then(response => {
            this.setState({
                BOM_Details: response,
                BOM_name: response.name,
            },()=>{
                this.getBookingListForMo()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({
                isLoading: false,
                userData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message 
            })
        });
    }
    
    
  
   

    editStockLots = (_id,number) => {
        localStorage.setItem("stockLotId",_id)
        localStorage.setItem("stockLotNumber",number.toUpperCase())
       
        let slug = "Stock_StockLots"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/editlot"
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this order?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteMo(this.state.MO_Id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }


    editItem = (_id) => {
        localStorage.setItem("itemId",_id)
        let slug = "Stock_Items"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/edititem"
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    editBOM = () => {
        let slug = "ProductionPlanning_BOM"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/editbom",
                state: { BOMId: this.state.billOfMaterials, newBOMEntry: false }
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    updateMODates = () => {
        let {
            MO_Id,
            product,
            productGroup,
            quantity,
            startDate,
            dueDate,
            finishDate,
            BOM,
            routings,
            assignedTo,
            releaseAllDisabled,
            department
        } = this.state

        if(quantity === ""){
            this.setState({
                error: true,
                errormessage: "Please enter quantity",
                success: false
            },()=>{
                this.scrollToTop()
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateMO(MO_Id,productGroup,product,quantity,convertDateStringToDateAndTimeObject(startDate),convertDateStringToDateAndTimeObject(dueDate),convertDateStringToDateAndTimeObject(finishDate),assignedTo,JSON.stringify(BOM),JSON.stringify(routings),releaseAllDisabled,department)
            .then(response => {
                this.setState({
                    isLoading: false,
                },()=>{
                  //  this.getSingleMO()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }

    updateMO = () => {

        if(this.state.fieldNotEditable){
        
            let slug = "ProductionPlanning_ManufacturingOrders"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({ fieldNotEditable: false })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }else{
            let {
                MO_Id,
                product,
                productGroup,
                quantity,
                startDate,
                dueDate,
                finishDate,
                BOM,
                routings,
                assignedTo,
                releaseAllDisabled,
                department
            } = this.state

            if(quantity === ""){
                this.setState({
                    error: true,
                    errormessage: "Please enter quantity",
                    success: false
                },()=>{
                    this.scrollToTop()
                })
            }else if(assignedTo === ""){
                this.setState({
                    error: true,
                    errormessage: "Please select user",
                    success: false
                },()=>{
                    this.scrollToTop()
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateMO(MO_Id,productGroup,product,quantity,convertDateStringToDateAndTimeObject(startDate),convertDateStringToDateAndTimeObject(dueDate),convertDateStringToDateAndTimeObject(finishDate),assignedTo,JSON.stringify(BOM),JSON.stringify(routings),releaseAllDisabled,department)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        fieldNotEditable: true
                    },()=>{
                        this.getSingleMO(false)
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors.message 
                    })
                });
            }    
        }
    }



    viewProductionPlan = () => {
        this.props.history.push({
            pathname: "/viewproductionplan",
            state: { MO_Id: this.state.MO_Id },
        })
    }


    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  
    
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isManufacturingOrders={true} />
               
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title">Manufacturing order {this.state.number.toUpperCase()}</div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.viewProductionPlan()}>Go to production</div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateMO()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Number *</div>
                                <div className="value">
                                    <input 
                                        value={this.state.number}
                                        type="text" 
                                        className="fieldheightdecr text-uppercase" 
                                        autoComplete="off" 
                                        name="name" 
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            
                            <div className="add_inner">
                                <div className="title">Product group</div>
                                <div className="value">
                                    <div className="createdText text-capitalize">{this.state.productGroupName}</div>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Product</div>
                                <div className="value">
                                    <div className="createdText text-capitalize" onClick={()=>this.editItem(this.state.product)}><span className="clickable__source">{(this.state.productName).substr(0, 40)}...</span></div>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Quantity *</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.quantity}
                                        onChange={(e)=> {
                                            this.setState({ quantity: e.target.value >= 0 ? e.target.value : "" })
                                        }}
                                        disabled={this.state.is_MoStart || this.state.is_MoFinished ? true : false}
                                    /> {this.state.uom}
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner">
                                <div className="title">Due date</div>
                                <div className="value">
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        minDate={new Date()}
                                        selected={this.state.dueDate}
                                        onChange={date => this.setState({ dueDate: date })}
                                        className="filterdateoption"
                                        readOnly
                                    />
                                    <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                    <div className="title">Select Department</div>
                                    <div className="value">
                                        <select 
                                            className="text-capitalize"
                                            value={this.state.department}
                                            onChange={e => {
                                                this.setState({ department: e.target.value },()=>{
                                                    this.getUsersByDepartment(e.target.value)
                                                })
                                            }}
                                        >
                                            <option value="">Select</option>
                                            {
                                                this.state.departmentData !== null &&
                                                this.state.departmentData.map((data, i) => (
                                                    <option key={i+data._id} value={data._id}>{data.number.toUpperCase()} {data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>    
                                <div className="add_inner">
                                    <div className="title">Assigned to *</div>
                                    <div className="value">
                                        <select 
                                            value={this.state.assignedTo} 
                                            className='text-capitalize'
                                            onChange={(e)=>this.setState({ assignedTo: e.target.value })}
                                        >
                                            <option value="">Select</option>
                                            {
                                                this.state.userData.map((data, i) => (
                                                    <option key={i+data._id} value={data._id}>{data.username ? data.username : data.full_name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                            {/* <div className="add_inner">
                                <div className="title">Assigned to *</div>
                                <div className="value">
                                    <select className="text-capitalize" value={this.state.assignedTo} onChange={(e)=>this.setState({ assignedTo: e.target.value })}>
                                        <option value="">Select</option>
                                            {
                                                this.state.userData.map((data, i) => (
                                                    <option key={i+data._id} value={data._id}>{data.username ? data.username : data.full_name}</option>
                                                ))
                                            }
                                    </select>
                                </div>
                                <div className="clear"></div>
                            </div> */}

                            <div className="add_inner">
                                <div className="title">Target lot</div>
                                <div className="value">
                                    <div className="createdText text-capitalize" onClick={()=>this.editStockLots(this.state.targetLotId, this.state.targetLotNumber)}><span className="clickable__source">{this.state.targetLotNumber}</span></div>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Bill of materials</div>
                                <div className="value">
                                    <div className="createdText text-capitalize" onClick={()=>this.editBOM()}><span className="clickable__source">{this.state.BOM_name}</span></div>
                                </div>
                                <div className="clear"></div>
                            </div>

                        </div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Created</div>
                                <div className="value">
                                    <div className="createdText">{convertDateStringMMDDtoDDMM(this.state.created)}</div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Status</div>
                                <div className="value">
                                    <div className="createdText text-capitalize">{this.state.status}</div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Start</div>
                                <div className="value">
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        onChange={(date) => this.setState({ startDate: date })}
                                        className="fieldheightdecr"
                                        selected={this.state.startDate}
                                        minDate={new Date()}
                                        readOnly
                                    />
                                    <AiOutlineCalendar className="datepickerIcon"/>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Finish</div>
                                <div className="value">
                                    
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        className="fieldheightdecr"
                                        selected={this.state.finishDate}
                                        readOnly
                                    />
                                    <AiOutlineCalendar className="datepickerIcon"/>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Total cost</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.totalCost}
                                        readOnly
                                    /> <Bi.BiRupee />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Cost per 1 pcs</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.costPer1Pc}
                                        readOnly
                                    /> <Bi.BiRupee />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Cost of materials</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.costOfMaterials}
                                        readOnly
                                    /> <Bi.BiRupee />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Manufacturing overhead</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.appliedOverheadCost}
                                        readOnly
                                    /> <Bi.BiRupee />
                                </div>
                                <div className="clear"></div>
                            </div>

                            
                            
                        </div> 
                        <div className="clearfix"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className='tableContent'>
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateMO()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Materials</div>
                        </div>
                    </div>

                    <div className="p-0">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                            <div className="head-import-buttons float-left">
                                <div className={this.state.bookAllDisabled ? "button co__footer__button disabled" : "button co__footer__button"} onClick={()=>this.bookAllParts()}>Book all parts</div>
                                <div className={this.state.releaseAllDisabled ? "button co__footer__button disabled" : "button co__footer__button"} onClick={()=>this.releaseAllParts()}>Release all booked parts</div>
                                <div className="clear"></div>
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: 20 }}></th>
                                    <th className="tableHeader" style={{ width: 175 }}>Stock Item</th>
                                    <th className="tableHeader" style={{ width: 100 }}>Needed</th>
                                    <th className="tableHeader" style={{ width: 100 }}>Total cost</th>
                                    <th className="tableHeader" style={{ width: 100 }}>Cost per unit</th>
                                    <th className="tableHeader" style={{ width: 100 }}>In stock</th>
                                    <th className="tableHeader" style={{ width: 100 }}>Available</th>
                                    <th className="tableHeader" style={{ width: 100 }}>Booked</th>
                                    <th className="tableHeader" style={{ width: 140 }}>Expected, available</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.itemDetails.length > 0 &&
                                this.state.itemDetails.map((data, i) => (
                                    <BookingItemListing
                                        key={'booking_list__'+i}
                                        number={'booking_list_'+i}
                                        itemDetails={data}
                                        bomDetails={this.state.BOM_Details.parts[i]}
                                        afterBookingData={this.state.afterBookingData[i]}
                                        bookManually={this.bookThisItem}
                                        cancelBooking={this.cancelThisItem}
                                    />
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Operations</div>
                        </div>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    
                                    <th style={{ width: 20 }}></th>
                                    <th className="tableHeader">Operation</th>
                                    <th className="tableHeader">Total cost</th>
                                    <th className="tableHeader">Workstation</th>
                                    <th className="tableHeader">Manufacturing overhead</th>
                                    <th className="tableHeader">Planned Start</th>
                                    <th className="tableHeader">Planned Finish</th>
                                    <th className="tableHeader">Worker</th>
                                    <th className="tableHeader text-right"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.Routing___List
                                }
                                {/* <tr>
                                    <td>Assembling</td>
                                    <td>#WCT-000001-2 Woodworks(2)</td>
                                    <td>10/12/2021 10:00</td>
                                    <td>23/12/2021 10:00</td>
                                    <td>500,00 US$</td>
                                    <td>
                                        <select className='select-box'>
                                            <option>Select</option>
                                        </select>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>257,50 US$</td>
                                    <td className="text-right clr-secondary"><Fi.FiEdit2 /></td>
                                </tr>
                                <tr>
                                    <td>Painting</td>
                                    <td>#WCT-000001-1 Paintroom(2)</td>
                                    <td>10/12/2021 10:00</td>
                                    <td>23/12/2021 10:00</td>
                                    <td>500,00 US$</td>
                                    <td>
                                        <select className='select-box'>
                                            <option>Select</option>
                                        </select>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>257,50 US$</td>
                                    <td className="text-right clr-secondary"><Fi.FiEdit2 /></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>


                
            </div>
            </div>
        )
    }
}
