import React, { Component } from 'react';
import toast from 'react-simple-toasts';

import * as Ai from 'react-icons/ai'
import Loading from './../../components/Loading'
import * as APIManager from './../../APIManager/APIManager'
import { 
    validateBlank,
    validatePassword
} from './../../constants/Validate'

class ResetPassword extends Component{

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            password: "",
            confirmPassword: "",
            showPassword1: false,
            showPassword2: false,
        }
        this.handlePassword = this.handlePassword.bind(this)
        this.handleConfPassword = this.handleConfPassword.bind(this)
    }

    componentDidMount(){
        let getUrl = window.location.href;
        let tkn = getUrl.split('resetpass/')[1];
        if(typeof tkn !== 'undefined') {
            localStorage.setItem("resetToken", tkn);
            this.props.history.replace('/resetpass')
        }
    }

    handlePassword(e) {
        this.setState({ password: e.target.value})
    }

    handleConfPassword(e) {
        this.setState({ confirmPassword: e.target.value})
    }

    isValidate() {
        const { password, confirmPassword } = this.state;
        if(!validateBlank(password)){
            this.setState({
                error: true,
                errormessage: "Please enter password"
            })
            return false;
        }else if(!validatePassword(password)){
            this.setState({
                error: true,
                errormessage: "Password must have 6 characters & Upper, Lower and special characters"
            })
            return false;
        }else if(password !== confirmPassword){
            this.setState({
                error: true,
                errormessage: "Password and confirm password must be same"
            })
            return false;
        }else{
            return true;
        }
    }

    handleResetPassword = () => {
        let token = localStorage.getItem('resetToken')
        const { password } = this.state;
        if(this.isValidate()){
            this.setState({ isLoading: true, error: false })
            APIManager.callResetPassword(password, token)
            .then(response => {
                this.setState({ isLoading: false })
                toast("Password updated successfully")
                localStorage.removeItem("resetToken");
                this.goBack()
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    goBack = () => {
        this.props.history.replace('/login')
    }

    render(){
        return(
            <div className="form-bg-primary authBg page">
                <div className="loging-list">
                    <div className="container">
                        <div className="loging-menu-card">
                            <div className="form-heading">
                                <h1 className="form-title">Reset Password</h1>
                            </div>
                            {
                                this.state.error ?
                                <div className="errornotification">
                                    <div className="notiflink registernotiflink">{this.state.errormessage}</div>
                                </div>
                                :
                                <br />
                            }
                            <div className="form">
                                <div className="form-group">
                                    <label htmlFor="newpassword">New Password</label>
                                    <div className='position-relative'>
                                    <input type={this.state.showPassword1 ? "text" : "password"} className="form-control" onChange={this.handlePassword} />
                                    {
                                        this.state.showPassword1 ?
                                        <div className='passwordEye'>
                                            <Ai.AiFillEye size={20} onClick={()=>this.setState({ showPassword1: !this.state.showPassword1 })} />
                                        </div>
                                        :
                                        <div className='passwordEye'>
                                            <Ai.AiFillEyeInvisible size={20} onClick={()=>this.setState({ showPassword1: !this.state.showPassword1 })} />
                                        </div>
                                    }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmpassword">Confirm Password</label>
                                    <div className='position-relative'>
                                    <input type={this.state.showPassword2 ? "text" : "password"} className="form-control" onChange={this.handleConfPassword} />
                                    {
                                        this.state.showPassword2 ?
                                        <div className='passwordEye'>
                                            <Ai.AiFillEye size={20} onClick={()=>this.setState({ showPassword2: !this.state.showPassword2 })} />
                                        </div>
                                        :
                                        <div className='passwordEye'>
                                            <Ai.AiFillEyeInvisible size={20} onClick={()=>this.setState({ showPassword2: !this.state.showPassword2 })} />
                                        </div>
                                    }
                                    </div>
                                </div>
                                <button type="submit" className="btn sign-btn w-100" onClick={()=>this.handleResetPassword()}>Submit</button>
                            </div>
                            <br />
                            <div onClick={()=>this.goBack()} className="gobackbtn pointer">Go back</div>
                        </div>
                    </div>
                </div>
                { this.state.isLoading && <Loading />}
            </div>
        )
    }
}

export default ResetPassword;
