import React from 'react';
import { Header, SettingsHeader } from './../../../components'


export default class EditNumberingFormats extends React.Component{

    goBack = () => {
        this.props.history.goBack();
    }
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />


                <div className="main-container">

<div className="page-header">
    <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="page-title">Numbering formats</div>
        </div>
    </div>
</div>

                <div className="pagecontent">

                </div>

                <div className="tableContent">
                    <div>
                        <div className="editSetLeftContent">

                        </div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave">Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Customer orders *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Customers *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Invoices *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Pro-forma invoices *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Quotations *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Order confirmations *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Manufacturing orders *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Workstations *
                        </div>
                        <div className="editSetRightContent">
                                                       <input type="text" className="editSetText" />

                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Workstation groups *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        BOM *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>



                        <div className="editSetLeftContent">
                        Routings *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>



                        <div className="editSetLeftContent">
                        Items *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Lots *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Shipments *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>
                        <div className="editSetLeftContent">
                        Product group *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Bookings *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Purchase orders *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Bills (receipts) *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Vendors *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>
                        

                        


                        <div className="editSetLeftContent">
                        Customer returns (RMAs) *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Procurement forecasts *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Service orders *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">

                        </div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave">Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    </div>
                </div>
                </div>
        )
    }
}