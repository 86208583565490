import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import * as Im from 'react-icons/im'
import Loading from './../../../../helper/loading'
import ProductGroup from './ProductGroup';
import RichTextEditor from 'react-rte';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class EditInvoice extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            CO_InvoiceId: this.props.location.state.invoiceId,
            isNewEntry: this.props.location.state.isNewEntry,
            number: "",

            coGroupList: [],
            coGroupData: [],
            addressList: [],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            customerOrderNumber: [],
            customerOrder: "",
            customerId: "",
            invoiceAddress: "",
            deliveryAddress: "",
            invoiceType: "",
            invoiceId: "",
            invoiceDate: "",
            dueDate: "",

            customerName: "",
            applicableTax: "",
            applicableTaxType: [],
        
            remainingScope: [],

            invoiceMessageHeaderEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageHeader: "",
            invoiceMessageFooterEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageFooter: "",

            currencyData: [],
            sezCustomer: false,
            applicableTaxTypeWithAllData: [],

            pdfType: "",

            fieldNotEditable: true,

            is_tcs_applicable: false,
            is_tcs_locked: false,
            tcs_percentage: "",
            tcs_value: "",
            after_tcs_grandtotal: "",
            tcs_grandtotal_ininr: "",
            tcs_grandtotal_inother: "",
            is_e_invoice_possible: false,
            is_eInvoiceSetup: false,
            eInvoiceFlag: false,
            eWayBillFlag: false,
        }
    }

    componentDidMount(){
        this.getSingleInvoice()
    }

    getSingleInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOSingleInvoice(this.state.CO_InvoiceId)
        .then(response => {

            let orderGroupData = []
            let productGroup =  response.data.productGroup

            for(let i = 0; i<productGroup.length;i++){
                productGroup[i].conversionDetail = []
                orderGroupData.push(productGroup[i])
            }

            this.setState({ 
                customerOrder: response.data.customerOrderId,
                number: response.data.number,
                invoiceAddress: response.data.invoiceAddress,
                deliveryAddress: response.data.deliveryAddress,
                invoiceType: response.data.invoiceType,
                invoiceId: response.data.invoiceId,
                invoiceDate: response.data.invoiceDate ? new Date(response.data.invoiceDate) : "",
                dueDate: response.data.dueDate ? new Date(response.data.dueDate) : "",
                coGroupData: orderGroupData,
                invoiceMessageHeader: response.data.invoiceMessageHeader ? response.data.invoiceMessageHeader : "",
                invoiceMessageFooter: response.data.invoiceMessageFooter ? response.data.invoiceMessageFooter : "",
                invoiceMessageHeaderEditor: response.data.invoiceMessageHeader ? RichTextEditor.createValueFromString(response.data.invoiceMessageHeader, 'html') : RichTextEditor.createEmptyValue(),
                invoiceMessageFooterEditor: response.data.invoiceMessageFooter ? RichTextEditor.createValueFromString(response.data.invoiceMessageFooter, 'html') : RichTextEditor.createEmptyValue(),
                
                is_tcs_applicable: response.data.is_tcsApplicable,
                is_tcs_locked: false,
                tcs_percentage: response.data.tcsPercentage,
                tcs_value: response.data.tcsValue,
                after_tcs_grandtotal: "",
                tcs_grandtotal_ininr: this.state.is_tcs_applicable ? response.data.grandTotalWithTCS : 0,
                tcs_grandtotal_inother: this.state.is_tcs_applicable ? response.data.grandTotalWithTCSInOtherCurrency : 0,
                
                is_e_invoice_possible: response.data.eInvoiceDetail ? true : false,
                eInvoiceFlag: response.data.eInvoiceFlag ? response.data.eInvoiceFlag : false,
                eWayBillFlag: response.data.eWayBillFlag ? response.data.eWayBillFlag : false,
                is_eInvoiceSetup: response.data.is_eInvoiceSetup ? response.data.is_eInvoiceSetup : false,
                isLoading: false,
            },()=>{
                this.getOrderDetails(response.data.customerOrderId)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getOrderDetails = (id) => {
        if(id){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerOrderDetail(id)
            .then(response => {
                let coData = [...this.state.coGroupData]
                for(let i = 0; i<coData.length; i++){
                    if(response.remainingScope.length !== 0){
                        coData[i].remainingQuantity = response.remainingScope[i].scope !== undefined && response.remainingScope[i].scope
                    }
                }
                this.setState({ 
                    isLoading: false,
                    customerId: response.customer,
                    customerOrderNumber: response.number,
                    remainingScope: response.remainingScope ? response.remainingScope : [],
                    coGroupData: coData
                },()=>{
                    this.getCustomerDetails(response.customer)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            })
        }
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {

            let taxType = []
            let taxResponseData = response.companyData.taxApplicable
            let sezCustomer = false
            if(!taxResponseData.includes("notax")){
                if(taxResponseData.includes("exemptedtax")){
                    sezCustomer = true
                    taxType = ["IGST"]
                }else{
                    taxType = response.companyData.taxApplicable
                }
            }

            let pdfType = "" 
            if(response.companyData.currencyDetails.conversionRate){
                pdfType = "originalinother"
            }else{
                pdfType = "original"
            }
            this.setState({
                isLoading: false,
                customerName: response.companyData.companyId.toUpperCase()+' '+response.companyData.companyName,
                currencyData: response.companyData.currencyDetails,
                applicableTaxType: taxType,
                applicableTaxTypeWithAllData: taxResponseData,
                sezCustomer: sezCustomer,
                pdfType: pdfType
            },()=>{
                this.setGroupData()
                this.getCustomerAddress(id)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    setGroupData = () => {
        let data = this.state.coGroupData
        let group = []
        for(let i=0;i<data.length;i++){
            group.push(
                <ProductGroup 
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    applicableTaxTypeWithAllData={this.state.applicableTaxTypeWithAllData}
                    currencyData={this.state.currencyData}
                    key={"groupData"+i}
                    data={data[i]}
                    uom={data[i].uom}
                    customerId={this.state.customerId}
                    callBack={this.setUpdatedGroupData}
                    scopeDetails={this.state.remainingScope[i] ? this.state.remainingScope[i] : null}
                />
            )
        }
        this.setState({
            coGroupList: group,
            coGroupData: data
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let coGroupData = [...this.state.coGroupData]    
        coGroupData[counter] = value;  
        this.setState({
            coGroupData
        },()=>{
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.coGroupData
        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for(let i=0;i<data.length;i++){
            qty += parseFloat(data[i].orderedQuantity)
            subTotal += parseFloat(data[i].subTotal)

            if(this.state.invoiceType === "sez"){
                grandTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            }else{
                grandTotal += data[i].total ? parseFloat(data[i].total) : 0
            }
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        },()=>{
            let tcs = this.state.tcs_percentage
            if(this.state.grandTotal >= 5000000 && tcs === ""){
                this.setState({
                    is_tcs_applicable: true,
                    is_tcs_locked: true,
                    tcs_percentage: 0.1,
                },()=>{
                    this.changeTCSPercentage(this.state.tcs_percentage)
                })
            }else if(this.state.grandTotal >= 5000000 && tcs !== ""){
                this.setState({
                    is_tcs_locked: true,
                },()=>{
                    this.changeTCSPercentage(this.state.tcs_percentage)
                })
            }else{
                this.setState({
                    is_tcs_locked: false,
                },()=>{
                    this.changeTCSPercentage(this.state.tcs_percentage)
                })
            }
        })
    }

    getCustomerAddress = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressList: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        }
    }

    onHeaderMessageChange = (value) => {
        this.setState({
            invoiceMessageHeaderEditor: value,
            invoiceMessageHeader: value.toString('html')
        });
    }

    onFooterMessageChange = (value) => {
        this.setState({
            invoiceMessageFooterEditor: value,
            invoiceMessageFooter: value.toString('html')
        });
    }

    updateInvoice = () => {
        if(!this.state.fieldNotEditable){
        let {
            CO_InvoiceId,
            invoiceAddress,
            deliveryAddress,
            invoiceType,
            invoiceId,
            invoiceDate,
            dueDate,
            coGroupData,
            subTotal,
            grandTotal,
            invoiceMessageHeader,
            invoiceMessageFooter,
            grandTotalInOther,
            is_tcs_applicable,
            tcs_percentage,
            tcs_value,
            tcs_grandtotal_ininr,
            tcs_grandtotal_inother
        } = this.state

        if(invoiceType === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select invoice type"
            },()=>{
                this.scrollToTop()
            })
        }else if(invoiceDate === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter invoice date"
            },()=>{
                this.scrollToTop()
            })
        }else if(dueDate === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter due date"
            },()=>{
                this.scrollToTop()
            })
        }else if(parseFloat(grandTotal) === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please update delivered item details"
            },()=>{
                this.scrollToTop()
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateCOInvoice(CO_InvoiceId,invoiceId,deliveryAddress,invoiceAddress,invoiceType,convertDateToMMDDYYYY(invoiceDate),convertDateToMMDDYYYY(dueDate),JSON.stringify(coGroupData),subTotal,grandTotal,invoiceMessageHeader,invoiceMessageFooter,grandTotalInOther,is_tcs_applicable, tcs_percentage, tcs_value, tcs_grandtotal_ininr, tcs_grandtotal_inother)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    success: true,
                    fieldNotEditable: true,
                    successmessage: "Invoice Updated Successfully"
                },()=>{
                    this.scrollToTop()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }else{
        let slug = "CRM_Invoices"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({
                fieldNotEditable: false,
                success: false,
                error: false,
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
}



    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    deleteEntry = () => {
        let slug = "CRM_Invoices"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteCOInvoice(this.state.CO_InvoiceId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    downloadPDF = () => {
        if(this.state.invoiceType === "sez"){
            localStorage.setItem("pdfType", this.state.pdfType)
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('sezinvoice')
        }else{
            localStorage.setItem("pdfType", this.state.pdfType)
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoicepdf')
        }
    }

    generateEWayBillPDF = () => {
        window.open('ewbpdf?invoiceId='+this.state.CO_InvoiceId)
    }
    
    downloadPDFInOtherCurrency1 = () => {
        localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
        window.open('coinvoiceother')
    }

    downloadPDFOtherCurrencyInInr1 = () => {
        localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
        window.open('coinvoiceotherinr')
    }

    downloadPDFInOtherCurrency = () => {
        if(this.state.pdfType === "originalinother"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceother')
        }else if(this.state.pdfType === "duplicateinother"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceother')
        }else if(this.state.pdfType === "triplicateinother"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceother')
        }else if(this.state.pdfType === "extrainother"){
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceother')
        }else if(this.state.pdfType === "originalininr"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceotherinr')
        }else if(this.state.pdfType === "duplicateininr"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceotherinr')
        }else if(this.state.pdfType === "triplicateininr"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceotherinr')
        }else{
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
            window.open('coinvoiceotherinr')
        }
    }

    printLabel = () => {
        localStorage.setItem("CO_InvoiceId", this.state.CO_InvoiceId)
        window.open('printlabel')
    }


    changeTCSPercentage = (val) => {
        let per = val
        this.setState({
            tcs_percentage: (per >= 0 && per <= 100 ) || per === "" ? per : ''
        },()=>{
            let grandTotal = this.state.currencyData.conversionRate ? this.state.grandTotalInOther : this.state.grandTotal
            if(this.state.tcs_percentage > 0){

                let tcs_per = this.state.tcs_percentage

                let tcs_val = (grandTotal * tcs_per / 100).toFixed(2)

                this.setState({
                    tcs_value: parseFloat(tcs_val),
                    after_tcs_grandtotal: parseFloat(grandTotal)+parseFloat(tcs_val),
                    tcs_grandtotal_ininr: parseFloat(this.state.grandTotal)+parseFloat(tcs_val),
                    tcs_grandtotal_inother: parseFloat(this.state.grandTotalInOther)+parseFloat(tcs_val)
                })
            }else{
                this.setState({
                    tcs_value: 0,
                    after_tcs_grandtotal: 0,
                    tcs_grandtotal_ininr: 0,
                    tcs_grandtotal_inother: 0
                })
            }
        })
    }

    eInvoiceDetailPage = () => {
        let slug = "CRM_E_Invoices"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/vieweinvoice",
                state: { invoiceId: this.state.CO_InvoiceId }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    eWayBillDetailPage = () => {
        let slug = "CRM_E_Invoices"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/viewewaybill",
                state: { invoiceId: this.state.CO_InvoiceId }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isInvoices={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12">
                                <div className="page-title">Edit Invoice {this.state.number}</div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-4 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {
                                        this.state.currencyData.conversionRate ?
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e)=>this.setState({ pdfType: e.target.value})}>
                                                    <option value="originalinother">Original in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="duplicateinother">Duplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="triplicateinother">Triplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="extrainother">Extra in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="originalininr">Original in &#8377;</option>
                                                    <option value="duplicateininr">Duplicate in &#8377;</option>
                                                    <option value="triplicateininr">Triplicate in &#8377;</option>
                                                    <option value="extraininr">Extra in &#8377;</option>
                                                </select>
                                                {/* <div className="button" onClick={()=>this.downloadPDFInOtherCurrency1()}>PDF in {this.state.currencyData.currencySymbol}<Im.ImArrowDown2 /></div>
                                                <div className="button" onClick={()=>this.downloadPDFOtherCurrencyInInr1()}>PDF in<Bi.BiRupee /><Im.ImArrowDown2 /></div> */}
                                                <div className="button" onClick={()=>this.downloadPDFInOtherCurrency()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                <div className="button" onClick={()=>this.printLabel()}>Print Label <Im.ImArrowDown2 /></div>
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && this.state.eInvoiceFlag === false) &&
                                                        <div className="button" onClick={()=>this.eInvoiceDetailPage()}>Generate E-Invoice</div>   
                                                }

                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && !this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.generateEWayBillPDF()}>Download E-Way PDF</div>
                                                }
                                                <div className="clear"></div>                                                
                                            </div>
                                        :
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e)=>this.setState({ pdfType: e.target.value})}>
                                                    <option value="original">Original</option>
                                                    <option value="duplicate">Duplicate</option>
                                                    <option value="triplicate">Triplicate</option>
                                                    <option value="extra">Extra</option>
                                                </select>
                                                
                                                <div className="button" onClick={()=>this.downloadPDF()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                <div className="button" onClick={()=>this.printLabel()}>Print Label <Im.ImArrowDown2 /></div>
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && this.state.eInvoiceFlag === false) &&
                                                        <div className="button" onClick={()=>this.eInvoiceDetailPage()}>Generate E-Invoice</div>   
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && !this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                                {
                                                    ((this.state.is_eInvoiceSetup && this.state.is_e_invoice_possible) && (this.state.eInvoiceFlag && this.state.eWayBillFlag)) &&
                                                        <div className="button" onClick={()=>this.generateEWayBillPDF()}>Download E-Way PDF</div>
                                                }

                                                <div className="clear"></div>    
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateInvoice()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Number</div>
                                    <div className="value">
                                        <div className="createdText">{this.state.number}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer Order*</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerOrderNumber} {this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.addressList.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.invoiceAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ invoiceAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice Type</div>
                                    <div className="value">
                                        <select value={this.state.invoiceType} onChange={(e)=>this.setState({ invoiceType: e.target.value })}>
                                            <option value="">Select</option>
                                            <option value="credit" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Credit Invoice</option>
                                            <option value="deposit" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Deposit Invoice</option>
                                            <option value="tax" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Tax Invoice</option>
                                            <option value="retail" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Retail Invoice</option>
                                            <option value="sez" disabled={this.state.currencyData.conversionRate || !this.state.sezCustomer ? true : false}>SEZ Invoice</option>
                                            <option value="export" disabled={this.state.currencyData.conversionRate || !this.state.sezCustomer ? false : true}>Export Invoice</option>
                                      
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.invoiceDate
                                            }
                                            onChange={
                                                date => this.setState({ invoiceDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Due Date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.invoiceDate}
                                            selected={
                                                this.state.dueDate
                                            }
                                            onChange={
                                                date => this.setState({ dueDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery Address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.addressList.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.deliveryAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ deliveryAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        <div className="add_outer_full_part position-relative">
                            <div className="title">Invoice Header</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageHeaderEditor}
                                    onChange={this.onHeaderMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        <div className="main-container mt-5 invoice_product_group position-relative">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Ordered Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                                <th className='pg-tab-head'>Subtotal</th>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <th className='pg-tab-head' key={'appTax'+i}>
                                                    {data}
                                                    {
                                                        this.state.applicableTaxTypeWithAllData.includes("exemptedtax") &&
                                                        <small>(Exempted)</small>
                                                    }
                                                </th>
                                            ))
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Remaining Qty</th>
                                        <th className='pg-tab-head'>Delivered Qty</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    
                                    {this.state.coGroupList}

                                    {/* <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{this.state.totalQty}</b></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>{this.state.subTotal} <Bi.BiRupee /></b></td>
                                        {
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <td key={'appTaxBlankField'+i}></td>
                                            ))
                                        }
                                        <td><b>{this.state.grandTotal} <Bi.BiRupee /></b></td>
                                        <td></td>
                                        <td></td>
                                    </tr> */}
                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{this.state.totalQty}</b></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            <td><b><Bi.BiRupee /> {this.state.subTotal}</b></td>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <td key={'appTaxBlankField'+i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyData.conversionRate ?
                                                <td><b>{this.state.currencyData.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                            :
                                                <td><b><Bi.BiRupee /> {this.state.grandTotal}</b></td>
                                        }
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TCS Applicable</div>
                                    <div className="value">
                                        <input 
                                            type="checkbox" 
                                            disabled={this.state.is_tcs_locked}
                                            checked={this.state.is_tcs_applicable}
                                            onChange={()=>this.setState({ 
                                                is_tcs_applicable: !this.state.is_tcs_applicable,
                                                tcs_percentage: "",
                                                tcs_value: "",
                                                after_tcs_grandtotal: "",
                                                tcs_grandtotal_inother: "",
                                                tcs_grandtotal_ininr: "", 
                                            })} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">TCS Percentage</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.tcs_percentage}
                                            disabled={!this.state.is_tcs_applicable} 
                                            onChange={(e)=>this.changeTCSPercentage(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TCS Value</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.tcs_value}
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Grand Total</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={ this.state.is_tcs_applicable ? this.state.currencyData.conversionRate ? this.state.tcs_grandtotal_inother : this.state.tcs_grandtotal_ininr : '0'}
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="add_outer_full_part mt-1 position-relative">
                            <div className="title">Invoice Footer</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageFooterEditor}
                                    onChange={this.onFooterMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateInvoice()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default EditInvoice;