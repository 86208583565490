import React from 'react';
import { FiPlus } from "react-icons/fi";
import { Header, SettingsHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'

export default class TermsAndConditions extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseData: null,
        }
    }

    componentDidMount(){
        this.getAllTerms()
    }
   
    getAllTerms = () => {
        APIManager.callAllTermsCondition("","","")
        .then(response => {
            this.setState({ 
                isLoading: false,
                responseData: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    addTermsConditions = () => {
        let slug = "Settings_TermsAndConditions"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createnewterms"
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    editTerms = (id) => {
        localStorage.setItem("TermAndConditionId", id)
        this.props.history.push({
            pathname: "/updateterms"
        })
        
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }


    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isTerms={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{Strings.Settings.Terms}</div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                            <tr>
                                <th className="tableAddButton text-center" style={{width: 30}}><div onClick={()=>this.addTermsConditions()} className="addContactButton"><FiPlus /></div></th>
                                <th className="tableHeader"><span>Terms</span></th>
                                <th className="tableHeader"><span>Options</span></th>
                                <th className="tableHeader"><span>Terms for</span></th>
                                <th className="tableAddButton text-right"><div onClick={()=>this.addTermsConditions()} className="addContactButton"><FiPlus /></div></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.responseData !== null &&
                                this.state.responseData.map((data, i) => (
                                    <tr key={data._id} onClick={this.editTerms.bind(this,data._id)}>
                                        <td>{i+1}</td>
                                        <td>{data.terms}</td>
                                        <td>{
                                            data.option.map((d, j) => {
                                                let val = ""
                                                if(d.fields !== undefined){
                                                    val = d.fields
                                                }else if(d.terms !== undefined){
                                                    val = d.terms
                                                }else{
                                                    val = d
                                                }
                                                return <div key={data._id+i+j}>{j+1}. {val}</div>
                                            })
                                        }</td>
                                        <td>{
                                            data.termsFor.toString().split("Order").join(" Order")
                                        }</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        )
    }
}