import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';
import * as APIManager from './../../../APIManager/APIManager'
import { validateEmail } from '../../../constants/Validate';
import Loading from '../../../components/Loading';

class CreateParty extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            success: false,
            successmessage: "",
            error: false,
            errormessage: "",

            expenseCategoryData: [],
            expenseCategory: "",
            gstNumber: "",
            companyName: "",
            address: "",
            fullName: "",
            mobileNo: "",
            email: ""
        }
    }

    componentDidMount(){
        this.getExpenseCategories()
    }

   

    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
        .then(response => {
            let category = response.expenseCategory ? response.expenseCategory : []
            this.setState({
                isLoading: false,
                expenseCategoryData: category
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getGSTDetails = () => {
        let { gstNumber } = this.state;
        
        if(gstNumber && gstNumber.length >= 15){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(this.state.gstNumber)
            .then(response => {
                let address = response.data.address ? response.data.address : ''
                this.setState({
                    isLoading: false,
                    error: false,
                    address: address,
                    companyName: response.data.tradename ? response.data.tradename : response.data.legalname
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    success: false,
                    errormessage: "Please enter valid GST Number"
                })
            });
        }else{
            this.setState({
                address: "",
                companyName: ""
            })
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }

    createParty = () => {
        let {
            expenseCategory,
            gstNumber,
            companyName,
            address,
            fullName,
            mobileNo,
            email
        } = this.state

        if(gstNumber === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter and search GST detail"
            })
        }else if(companyName === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter valid GST number"
            })
        }else if(expenseCategory === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select expense category"
            })
        }else if(email !== "" && !validateEmail(email)){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter valid email address"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateGSTDetail(expenseCategory,gstNumber,companyName,address,fullName,mobileNo,email)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editparty",
                        state: { partyId: response.data._id, isNewEntry: true }
                    }) 
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isAccountingMaster={true} />

                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Add New Party</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createParty()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                
                                <div className="add_inner">
                                    <div className="title">GST Number</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.gstNumber}
                                            onChange={(e)=>this.setState({ gstNumber: e.target.value},()=>{ this.getGSTDetails() })} 
                                            className="text-uppercase"
                                        />
                                        <div className='gstdetail_search_icon' onClick={()=>this.getGSTDetails()}><Ai.AiOutlineSearch /></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>    
                                <div className="add_inner">
                                    <div className="title">Company Name</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.companyName}
                                            disabled
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Address</div>
                                    <div className="value">
                                        <textarea 
                                            autoComplete="off" 
                                            className="expense_desc_textarea" 
                                            value={this.state.address}
                                            disabled
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div> 
                            <div className="add_partside">             
                               
                                <div className="add_inner">
                                    <div className="title">Expense Category</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.expenseCategoryData.map((data)=>(
                                                {id: data.expenseCategory, text: data.expenseCategory}
                                            ))}
                                            value={this.state.expenseCategory}
                                            options={{placeholder: 'Select Category'}}
                                            onSelect={(e)=> this.setState({ expenseCategory: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Full Name</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.fullName}
                                            onChange={(e)=>this.setState({ fullName: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">Mobile number</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.mobileNo}
                                            onChange={(e)=>this.setState({ mobileNo: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">Email address</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.email}
                                            onChange={(e)=>this.setState({ email: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>    
                            </div>
                            <div className="clear"></div>
                              
                        </div>
                           
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createParty()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default CreateParty; 