import React from 'react';
import * as APIManager from './../../APIManager/APIManager'

import { 
    FiX,
} from "react-icons/fi";


export default class AddStorageLocation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            valueOfName: this.props.valueOfName,
            valueOfRate: this.props.valueOfRate,
            valueOfId: this.props.valueOfId,
            responseStorageLocation: []
        } 
    }

    componentDidMount(){
       this.getStorageLocations()
    }
   
    getStorageLocations = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllStorageLocation()
        .then(response => {
            this.setState({ 
                isLoading: false,
                responseStorageLocation: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        }); 
    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
            
        } = this.props
        return(
            <div className="extrafieldforContact">
                <div className="float-left width40">

                    <select 
                        onChange={(e)=>this.setState({ 
                            valueOfId: e.target.value.split("@")[0],
                            valueOfName: e.target.value.split("@")[1],
                            valueOfRate: "", 
                        },()=>{
                            onBlur(this.state.valueOfId, this.state.valueOfName, this.state.valueOfRate, componentNum)
                        })} 
                        value={this.state.valueOfId+"@"+this.state.valueOfName}
                        className="text-capitalize"
                    >
                        <option value="">Select</option>
                        {
                            this.state.responseStorageLocation !== null &&
                            this.state.responseStorageLocation.map((data, i) => (
                                <option value={data._id+"@"+data.name} key={data._id}>{data.name}</option>
                            ))
                        }   
                    </select>
                </div>
                <div className="float-left width60">
                    <input 
                        type="text" 
                        value={this.state.valueOfRate}
                        onChange={(e)=>this.setState({ valueOfRate: parseInt(e.target.value) ? parseInt(e.target.value) : ""},()=>{
                            onEdit(this.state.valueOfId, this.state.valueOfName, this.state.valueOfRate, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.valueOfId, this.state.valueOfName, this.state.valueOfRate, componentNum)
                        }
                    />
                    {
                        this.state.valueOfName !== "" && this.state.valueOfRate !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </div>
                <div className="clear"></div>
            </div>
        )
    }
}