import React from 'react';
import { Header, CRMHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'
import ContactComponent from './../../../components/AutoGenerateComponent/addContactInformation'
import * as Ai from 'react-icons/ai'

export default class CreateContactPerson extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countContactFieldNumber: [0],
            contactFieldCounter: 0,
            contactExtraFields: [],
            contactInformation: [],
            generateNewContact: true,

            companyId: "",
            companyNum: "",
            companyName: "",
            name: "",
            position: ""
        }
        this.contactInfoFieldRemove = this.contactInfoFieldRemove.bind(this)
        this.contactInfoInputBlur = this.contactInfoInputBlur.bind(this)
    }

    componentDidMount(){
        let cmpId = localStorage.getItem("cmpId")
        let cmpIdNum = localStorage.getItem("cmpIdNum")
        let cmpName = localStorage.getItem("cmpName")

        this.setState({
            companyId: cmpId,
            companyNum: cmpIdNum,
            companyName: cmpName,
        })
        this.setFirstContactField()

    }

    createContactPerson = () => {
        let {
            name,
            position,
            companyId,
            companyNum,
            contactInformation
        } = this.state
        if(name === ""){
            this.setState({
                error: true,
                errormessage: "Please enter name",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCompanyContact(companyId,companyNum,name,position,contactInformation)
            .then(response => {
                this.setState({ 
                    isLoading: false
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                })
            }); 
        }
    }
        

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
    setFirstContactField = () => {
        this.setState({
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent
                    componentNum={0}
                    key={0}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ])
        })
    }

    contactInfoInputVal = (val, number) => {
        let that = this;
        let valLength = val.trim().replaceAll("_","").trim();

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]  
        
        if (typeof CIVal[index] === 'undefined' && this.state.generateNewContact === true && valLength.length > 0) {
            this.setState({
                contactFieldCounter: this.state.contactFieldCounter + 1,
                generateNewContact: false,
            }, () => {
                this.setState({
                    countContactFieldNumber: this.state.countContactFieldNumber.concat(
                        [this.state.contactFieldCounter]
                    ),
                    contactExtraFields: this.state.contactExtraFields.concat(
                        [
                            <ContactComponent 
                                key={
                                    this.state.contactFieldCounter
                                }
                                value={""}
                                type={"Phone"}
                                fun_checkInputBlur={
                                    this.contactInfoInputBlur
                                }
                                componentNum={
                                    this.state.contactFieldCounter
                                }
                                fun_checkContactInfoInput={
                                    that.contactInfoInputVal
                                }
                                fun_removeContactInfoField={
                                    this.contactInfoFieldRemove
                                }
                            />
                        ]
                    ),
                })
            })
        }
    }



    contactInfoInputBlur = (val, type, number) => {

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]        


        if (typeof CIVal[index] !== 'undefined') {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                let contactInformation = [ ...this.state.contactInformation ];
                contactInformation[index] = obj;

                this.setState({
                    contactInformation,
                    generateNewContact: true,
                })
            }
        }else{
            if (val !== "") {
                let obj = {
                    "type": type === "" ? "Phone" : type,
                    "value": val
                }
                this.setState({
                    contactInformation: this.state.contactInformation.concat([ obj ]),
                    generateNewContact: true,
                })
            }
        }
    }

    contactInfoFieldRemove(number) {
        let array = [...this.state.countContactFieldNumber];
        let fields = [...this.state.contactExtraFields];
        let data = [...this.state.contactInformation]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            this.setState({
                countContactFieldNumber: array,
                contactExtraFields: fields,
                contactInformation: data
            });
        }
    }

   
    render(){
        return(
            <div className="page absoluteWhite">
               <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true}/>
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a contact person</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createContactPerson()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Company </div>
                                <div className="value companyname text-capitalize">{this.state.companyNum} {this.state.companyName}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Name * </div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.name}
                                        onChange={(e)=>this.setState({ name: e.target.value })}
                                        name="name"
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Position</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.position}
                                        onChange={(e)=>this.setState({ position: e.target.value })}
                                        name="position" 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner contactinfo">

                                {/* Contact Information fields */}
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Contact information</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1">
                                            <div className="float-left width40">Type</div>
                                            <div className="float-left width60">value</div>
                                            <div className="clear"></div>
                                        </div>

                                        {
                                            this.state.contactExtraFields
                                        }

                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>

                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createContactPerson()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
            </div>
        )
    }
}