import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Helper from '../../../helper/helper'
import Loading from './../../../helper/loading'

import {
    AiOutlineExclamation
} from "react-icons/ai";
import { WEBSERVICES_URL } from '../../../APIManager/APIManager';

export default class EditVendorNotes extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",   

            title: "",
            id: "",
            noteId: "",
            note: "",

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let num = localStorage.getItem("vendorNumber")
        let name = localStorage.getItem("vendorName")
        let id = localStorage.getItem("vendorId")
        let noteId = localStorage.getItem("vendorNoteId")
        let note = localStorage.getItem("vendorNote")

        this.setState({ 
            title: num+ " " +name, 
            id: id,
            note: note,
            noteId: noteId
        })
    }

    updateNotes = () => {
        if(this.state.fieldNotEditable !== true){
            let note = this.state.note;
            const that = this;

            if(note === ""){
                this.setState({
                    error: true,
                    errormessage: "Please enter note",
                })
            }else{
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state.id)
                formdata.append("noteId", this.state.noteId)
                formdata.append("note", note)
                
                fetch(WEBSERVICES_URL+'procurement/vendors/updateVendorNotes',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                            that.setState({ loading: false },()=> {
                                that.goBack()
                            })
                    }else if(result.status === "userDataError"){
                            that.setState({ loading: false },()=> {
                                that.logout()
                            })
                    }else{
                            that.setState({
                                loading: false,
                                error: true,
                                errormessage: result.message
                            })
                    }

                    },(error) => {
                        console.log(error)
                    }
                )
            }
        }else{
            this.setState({ fieldNotEditable: false })
        }
    }


    deleteNotes = () => {

        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
           
            this.setState({ loading: true })
            const that = this;

       
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("id", this.state.id)
            formdata.append("noteId", this.state.noteId)
            
            fetch(WEBSERVICES_URL+'procurement/vendors/deleteVendorNotes',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        that.setState({ loading: false },()=> {
                            that.goBack()
                        })

                   }else if(result.status === "userDataError"){
                        that.setState({ loading: false },()=> {
                            that.logout()
                        })
                   }else{
                        that.setState({
                            loading: false,
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            )
        }
    }
    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewVendorNote") !== null){
            localStorage.removeItem("createNewVendorNote")
            localStorage.removeItem("vendorNote")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCustomerOrder={true} />
                <ProcurementHeader isVendor={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Edit a note</div>
                        </div>
                    </div>
                </div>
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteNotes()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Vendor </div>
                                <div className="value companyname text-capitalize">{this.state.title}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                            <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })}
                                    />
                                </div> 
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteNotes()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}