import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditStatus extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            statusName: "",
            statusValue: "",

            _id: this.props.location.state.statusId,
            isNewEntry: this.props.location.state.newStatusEntry,
            fieldNotEditable: true,

        }
    }

    componentDidMount(){
        this.getSingleStatus()
    }
    
    
    getSingleStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleStatus(this.state._id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                statusName: response.name,
                statusValue: response.value,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }
    

    updateStatus = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                _id,
                statusName,
                statusValue
            } = this.state

            if(statusName === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter status name"
                })
            }else if(statusValue === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter status value"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateStatus(_id,statusName, statusValue)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Status Updated Successfully."
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        errormessage: errors 
                    })
                });
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteCustomerStatus(this.state._id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    checkFloatingConversionRate = (value) => {
        if(!isNaN(value) || value === ""){
            this.setState({
                conversionRate: value
            })
        }
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Edit Customer Status</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">


                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateStatus()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value"><input type="text" style={{ width: 250 }} className="fieldheightdecr" value={this.state.statusName} onChange={(e)=>this.setState({ statusName: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner">
                                <div className="title">Value</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        style={{width: 250}}
                                        value={this.state.statusValue}
                                        onChange={(e)=>this.setState({ statusValue: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateStatus()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}