import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as LS from './../../../constants/SetLocalstorage'
import * as APIManager from './../../../APIManager/APIManager'

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';

import { validateNumber } from './../../../constants/Validate'


import {
    AiOutlineExclamation
} from "react-icons/ai";
import { BiRupee } from 'react-icons/bi';
import { FiPercent } from 'react-icons/fi';


export default class AddItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseItems: [],
            responseProductGroup: [],
            responseUnitOfMeasure: [],
            responseStorageLocation: [],

            partNo: "",
            partDesc: LS.getLocalFormItem("__LocalData_create__item", "partDesc"),
            hsCode: LS.getLocalFormItem("__LocalData_create__item", "hsCode"),
            cadNumber: LS.getLocalFormItem("__LocalData_create__item", "cadNumber"),
            itemCode: LS.getLocalFormItem("__LocalData_create__item", "itemCode"),
            productGroupId: LS.getLocalFormItem("__LocalData_create__item", "productGroup"),
            measurementId: LS.getLocalFormItem("__LocalData_create__item", "measurement"),
            isProcurePro: LS.getLocalFormItem("__LocalData_create__item", "isProcure"),
            is_sellablePrice: LS.getLocalFormItem("__LocalData_create__item", "isSellable"),
            sellingPrice: LS.getLocalFormItem("__LocalData_create__item", "sellingPrice"),
            tax: LS.getLocalFormItem("__LocalData_create__item", "tax"),
            storageLocationId: LS.getLocalFormItem("__LocalData_create__item", "storageLocation"),
            minQuantity: LS.getLocalFormItem("__LocalData_create__item", "minQuantity"),
            maxQuantity: LS.getLocalFormItem("__LocalData_create__item", "maxQuantity"),
            freeText: LS.getLocalFormItem("__LocalData_create__item", "freetext"),
           

        }
        

    }

    componentDidMount() {
        this.getPartNo()
        this.getItemsList()
        this.getProductGroupList()
        this.getUnitOfMeasure()
        this.getStorageLocations()

    }

    getPartNo = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();


        fetch(APIManager.WEBSERVICES_URL + 'stock/createPartNo', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        partNo: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }




    getItemsList = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("start", 0)

        fetch(APIManager.WEBSERVICES_URL + 'stock/getItem', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseItems: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }

    getProductGroupList = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();


        fetch(APIManager.WEBSERVICES_URL + 'stock/getProductGroup', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseProductGroup: result.data.productGroup,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }

    getUnitOfMeasure = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        fetch(APIManager.WEBSERVICES_URL + 'stock/stocksetting/getAllUnitOfMeasurement', {
            method: "POST",
            headers: myHeaders,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseUnitOfMeasure: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }

    getStorageLocations = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();

        fetch(APIManager.WEBSERVICES_URL + 'stock/stocksetting/getAllStorageLocation', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseStorageLocation: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            })
    }


    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    saveItem = () => {
        let that = this;
        let partNo = this.state.partNo
        let Desc = this.state.partDesc
        let hsCode = this.state.hsCode
        let cadNumber = this.state.cadNumber
        let itemCode = this.state.itemCode
        let Group = this.state.productGroupId
        let Measure = this.state.measurementId
        let procure = this.state.isProcurePro
        let selling = this.state.sellingPrice
        let is_sellablePrice = this.state.is_sellablePrice
        let tax = this.state.tax
        let location = this.state.storageLocationId
        let minQuantity = this.state.minQuantity
        let maxQuantity = this.state.maxQuantity
        let freeText = this.state.freeText

        if (partNo === "") {
            this.setState({
                error: true,
                errormessage: "Please enter part no",
            })
        } else if (Desc === "") {
            this.setState({
                error: true,
                errormessage: "Please enter description",
            })
        } else if (itemCode === "") {
            this.setState({
                error: true,
                errormessage: "Please enter item code",
            })
        } else if (hsCode === "") {
            this.setState({
                error: true,
                errormessage: "Please enter HS Code",
            })
        } else if (is_sellablePrice === true && tax === "") {
            this.setState({
                error: true,
                errormessage: "Please enter tax",
            })
        } else if (is_sellablePrice === true && selling === "") {
            this.setState({
                error: true,
                errormessage: "Please enter selling price",
            })
        } else if (Measure === "") {
            this.setState({
                error: true,
                errormessage: "Please select unit of measurement",
            })
        } else if (minQuantity === "") {
            this.setState({
                error: true,
                errormessage: "Please enter min quantity",
            })
        } else if (parseFloat(minQuantity) > (maxQuantity)) {
            this.setState({
                success: false,
                error: true,
                errormessage: "Maximum quantity should be greater than or equal to minimum quantity",
            }, () => {
                this.scrollToTop()
            })
        } else {
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("partNo", partNo);
            formdata.append("partDescription", Desc);
            formdata.append("itemCode", itemCode);
            formdata.append("hsCode", hsCode);
            formdata.append("cadNumber", cadNumber);
            formdata.append("productGroup", Group);
            formdata.append("unitOfMeasurement", Measure);
            formdata.append("sellingPrice", selling);
            formdata.append("is_sellablePrice", is_sellablePrice);
            formdata.append("tax", tax);
            formdata.append("defaultStorageLocation", location);
            formdata.append("minQuantity", minQuantity);
            formdata.append("maxQuantity", maxQuantity);
            formdata.append("is_procured", procure);
            formdata.append("freeText", freeText);

            fetch(APIManager.WEBSERVICES_URL + 'stock/createItem', {
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                    if (result.status === "success") {
                        localStorage.setItem("createNewItemEdit", true)
                        localStorage.setItem("itemId", result.data._id)
                        LS.removeLS("__LocalData_create__item")
                        that.props.history.push('/edititem')
                    } else if (result.status === "userDataError") {
                        that.logout()
                    } else {
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                    }

                }, (error) => {
                    console.log(error)
                }
                )
        }
    }

    copyContent = (id) => {
        this.setState({
            partDesc: "",
            itemCode: "",
            hsCode: "",
            cadNumber: "",
            productGroupId: "",
            measurementId: "",
            isProcurePro: false,
            is_sellablePrice: false,
            sellingPrice: "",
            tax: "",
            storageLocationId: "",
            minQuantity: "",
            maxQuantity: "",
            freeText: "",
        })
        if (id !== "") {
            this.setState({ isLoading: true, error: false })
            APIManager.callSingleItem(id)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        partDesc: response.itemData.partDescription,
                        itemCode: response.itemData.itemCode !== 'undefined' ? response.itemData.itemCode : "",
                        hsCode: response.itemData.hsCode !== 'undefined' ? response.itemData.hsCode : "",
                        cadNumber: response.itemData.cadNumber !== 'undefined' ? response.itemData.cadNumber : "",

                        //is_sellablePrice: response.itemData.is_sellablePrice !== 'undefined' ? response.itemData.is_sellablePrice : false,
                        productGroupId: response.itemData.productGroup,
                        measurementId: response.itemData.unitOfMeasurement,
                        isProcurePro: response.itemData.is_procured,
                        //sellingPrice: response.itemData.sellingPrice,
                        tax: response.itemData.tax,
                        storageLocationId: response.itemData.defaultStorageLocation,
                        minQuantity: response.itemData.minQuantity,
                        maxQuantity: response.itemData.maxQuantity,
                        freeText: response.itemData.freeText
                    }, () => {
                        localStorage.setItem("itmId", id)
                        localStorage.setItem("itmIdNum", response.itemData.partNo)
                        localStorage.setItem("itmName", response.itemData.partDescription)

                        LS.setLocalFormItem("__LocalData_create__item", "partDesc", this.state.partDesc)
                        LS.setLocalFormItem("__LocalData_create__item", "hsCode", this.state.hsCode)
                        LS.setLocalFormItem("__LocalData_create__item", "cadNumber", this.state.cadNumber)
                        LS.setLocalFormItem("__LocalData_create__item", "itemCode", this.state.itemCode)
                        LS.setLocalFormItem("__LocalData_create__item", "productGroup", this.state.productGroupId)
                        LS.setLocalFormItem("__LocalData_create__item", "measurement", this.state.measurementId)
                        LS.setLocalFormItem("__LocalData_create__item", "isProcure", this.state.isProcurePro)
                        LS.setLocalFormItem("__LocalData_create__item", "storageLocation", this.state.storageLocationId)
                        LS.setLocalFormItem("__LocalData_create__item", "minQuantity", this.state.minQuantity)
                        LS.setLocalFormItem("__LocalData_create__item", "maxQuantity", this.state.maxQuantity)
                        LS.setLocalFormItem("__LocalData_create__item", "freetext", this.state.freeText)
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }

    addNewProductGroup = () => {
        this.props.history.push('/createproductgroup')
    }

    addNewMeasurement = () => {
        this.props.history.push('/createunitofmeasure')
    }

    addNewLocation = () => {
        this.props.history.push('/createstoragelocation')
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

   
    goBack = () => {
        LS.removeLS("__LocalData_create__item")
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isItems={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create an item</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">


                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveItem()}>Save</div>
                            <div className="add_btnBack" onClick={() => this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Part No*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.partNo} onChange={(e) => this.setState({ partNo: e.target.value })} className="fieldheightdecr text-uppercase" autoComplete="off" name="name" disabled />
                                    </div>
                                    <div className="clearfix"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Copy item</div>
                                    <div className="value">
                                        {/* <select name="status" onChange={(e) => this.copyContent(e.target.value)}>
                                            <option value="">Select</option>
                                            {
                                                this.state.responseItems !== null &&
                                                this.state.responseItems.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.itemCode}</option>
                                                ))
                                            }
                                        </select> */}

                                        <Select2    
                                            data={this.state.responseItems.map((data)=>(
                                                { id: data._id, text: data.itemCode}
                                            ))}
                                            options={{placeholder: 'Select Item'}}
                                            onSelect={(e)=> this.copyContent(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">CAD No</div>
                                    <div className="value">
                                        <input type="text" value={this.state.cadNumber} onChange={(e) => this.setState({ cadNumber: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "cadNumber", this.state.cadNumber) })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Article code*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.itemCode} onChange={(e) => this.setState({ itemCode: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "itemCode", this.state.itemCode) })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">HS code*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.hsCode} onChange={(e) => this.setState({ hsCode: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "hsCode", this.state.hsCode) })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Part description*</div>
                                    <div className="value">
                                        {/* <input type="text" value={this.state.partDesc} onChange={(e)=>this.setState({ partDesc: e.target.value })} className="fieldheightdecr" autoComplete="off" name="name" /> */}
                                        <textarea
                                            autoComplete="off"
                                            className="fieldheightdecr item_free_text_area"
                                            value={this.state.partDesc}
                                            onChange={(e) => this.setState({ partDesc: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "partDesc", this.state.partDesc) })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">This is a sellable item</div>
                                    <div className="value">
                                        <input
                                            type="checkbox"
                                            style={{ width: 14 }}
                                            checked={this.state.is_sellablePrice}
                                            onChange={() => {
                                                this.setState({
                                                    is_sellablePrice: !this.state.is_sellablePrice,
                                                    sellingPrice: "",
                                                }, () => {
                                                    LS.setLocalFormItem("__LocalData_create__item", "isSellable", this.state.is_sellablePrice)
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Tax</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.tax}
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value)) {
                                                    this.setState({ tax: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "tax", this.state.tax) })
                                                }
                                            }
                                            }
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            name="name"
                                            style={{ width: "40%" }}
                                        /> <FiPercent />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Selling price</div>
                                    <div className="value">
                                        <input type="text"
                                            value={this.state.sellingPrice}
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value)) {
                                                    this.setState({ sellingPrice: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "sellingPrice", this.state.sellingPrice) })
                                                }
                                            }
                                            }

                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            disabled={!this.state.is_sellablePrice}
                                            style={{ width: "40%" }} /> <BiRupee />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product Group</div>
                                    <div className="value">
                                        <select name="status"
                                            value={this.state.productGroupId}
                                            onChange={(e) =>
                                                e.target.value === 'addNew' ?
                                                    this.addNewProductGroup()
                                                    :
                                                    this.setState({ productGroupId: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "productGroup", this.state.productGroupId) })
                                            }>
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>+Add new product</option>
                                            {
                                                this.state.responseProductGroup !== null &&
                                                this.state.responseProductGroup.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Default storage location</div>
                                    <div className="value">
                                        <select name="status" value={this.state.storageLocationId} onChange={(e) =>
                                            e.target.value === 'addNew' ?
                                                this.addNewLocation()
                                                :
                                                this.setState({ storageLocationId: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "storageLocation", this.state.storageLocationId) })
                                        }>
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>+Add new location</option>

                                            {
                                                this.state.responseStorageLocation !== null &&
                                                this.state.responseStorageLocation.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Unit of measurement*</div>
                                    <div className="value">
                                        <select name="status" value={this.state.measurementId} onChange={(e) =>
                                            e.target.value === 'addNew' ?
                                                this.addNewMeasurement()
                                                :
                                                this.setState({ measurementId: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "measurement", this.state.measurementId) })
                                        }>
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>+Add new measurement</option>
                                            {
                                                this.state.responseUnitOfMeasure !== null &&
                                                this.state.responseUnitOfMeasure.map((data, i) => (
                                                    <option key={data._id} value={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Minimum quantity*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.minQuantity} onChange={(e) => this.setState({ minQuantity: e.target.value >= 0 ? e.target.value : "" }, () => { LS.setLocalFormItem("__LocalData_create__item", "minQuantity", this.state.minQuantity) })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Maximum quantity</div>
                                    <div className="value">
                                        <input type="text" value={this.state.maxQuantity} onChange={(e) => this.setState({ maxQuantity: e.target.value >= 0 ? e.target.value : "" }, () => { LS.setLocalFormItem("__LocalData_create__item", "maxQuantity", this.state.maxQuantity) })} className="fieldheightdecr" autoComplete="off" name="name" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Free text</div>
                                    <div className="value">
                                        <textarea
                                            autoComplete="off"
                                            className="fieldheightdecr item_free_text_area"
                                            value={this.state.freeText}
                                            onChange={(e) => this.setState({ freeText: e.target.value }, () => { LS.setLocalFormItem("__LocalData_create__item", "freetext", this.state.freeText) })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">This is a procured item</div>
                                    <div className="value">
                                        <input
                                            type="checkbox"
                                            style={{ width: 14 }}
                                            checked={this.state.isProcurePro}
                                            onChange={() => {
                                                this.setState({ isProcurePro: !this.state.isProcurePro }, () => {
                                                    LS.setLocalFormItem("__LocalData_create__item", "isProcure", this.state.isProcurePro)
                                                })

                                            }
                                            }
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clearfix"></div>

                        </div>
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveItem()}>Save</div>
                            <div className="add_btnBack" onClick={() => this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
}