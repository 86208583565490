import React from 'react';

import { 
    FiX,
} from "react-icons/fi";

import autosize from 'autosize';

export default class ContactInformation extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            addr_line_1: "",
            addr_line_2: "",
            addr_zone: "",
            addr_city: "",
            addr_state: "",
            addr_pin: "",
            addr_country: "",
            inputValue: this.props.value,
            inputType: this.props.type,
        }
    }

    componentDidMount(){
        if(this.state.inputType === "Address" || this.state.inputType === "ShippingAddress"){
            let addr = this.state.inputValue;
            
            let addr_new = addr.split("_ ")

            this.setState({
                addr_line_1: addr_new[0],
                addr_line_2: addr_new[1],
                addr_zone: addr_new[2],
                addr_city: addr_new[3],
                addr_state: addr_new[4],
                addr_pin: addr_new[5],
                addr_country: addr_new[6], 
            })
        }
        autosize(this.contactinfovalue)

        
    }
   
    checkType = (e) => {
        this.setState({
            addr_line_1: "",
            addr_line_2: "",
            addr_zone: "",
            addr_city: "",
            addr_state: "",
            addr_pin: "",
            addr_country: "",
            inputValue: "",
            inputType: e.target.value
        })
    }

    render(){
        let {
            fun_checkContactInfoInput,
            fun_removeContactInfoField,
            componentNum,
            fun_checkInputBlur
        } = this.props
        return(
            <div className="extrafieldforContact">
                <div className="float-left width40">
                    <select onChange={(e)=>this.checkType(e)} value={this.state.inputType}>
                        <option value="Phone">Phone</option>
                        <option value="Mobile">Mobile</option>
                        <option value="Fax">Fax</option>
                        <option value="Skype">Skype</option>
                        <option value="Email">E-mail</option>
                        <option value="Link">Link</option>
                        <option value="Web">Web</option>
                        <option value="Address">Address</option>
                        <option value="ShippingAddress">Shipping address</option>
                        <option value="FreeTextAddress">Free-text address</option>
                        <option value="FreeTextShippingAddress">Free-text shipping address</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="float-left width60">

                    {
                        this.state.inputType === "Address" || this.state.inputType === "ShippingAddress" ?
                        <div className="address_fields">
                            <input 
                                type="text" 
                                placeholder="Line 1" 
                                value={this.state.addr_line_1}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_line_1: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />

                            <input 
                                type="text" 
                                placeholder="Line 2"
                                value={this.state.addr_line_2}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_line_2: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />
                            <input 
                                type="text" 
                                placeholder="Zone" 
                                value={this.state.addr_zone}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_zone: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />
                            <input 
                                type="text" 
                                placeholder="City" 
                                value={this.state.addr_city}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_city: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />
                            <input 
                                type="text" 
                                placeholder="State" 
                                value={this.state.addr_state}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_state: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />
                            <input 
                                type="text" 
                                placeholder="Pin" 
                                value={this.state.addr_pin}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_pin: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />
                            <input 
                                type="text" 
                                placeholder="Country" 
                                value={this.state.addr_country}
                                autoComplete="off"
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            addr_country: e.target.value
                                        },()=>{
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkContactInfoInput(addr, componentNum)
                                        })
                                    }
                                }
                                onBlur={
                                    (e)=>{
                                        if(e.target.value !== ""){
                                            let addr = this.state.addr_line_1+"_ "+this.state.addr_line_2+"_ "+this.state.addr_zone+"_ "+this.state.addr_city+"_ "+this.state.addr_state+"_ "+this.state.addr_pin+"_ "+this.state.addr_country
                                            fun_checkInputBlur(addr, this.state.inputType, componentNum)
                                        }
                                    }
                                }
                            />
                            {
                                (this.state.addr_line_1 !== "" || this.state.addr_line_2 !== "" || this.state.addr_zone !== "" || this.state.addr_city !== "" || this.state.addr_state !== "" || this.state.addr_pin !== "" || this.state.addr_country !== "") &&
                                    <FiX className="extraFieldCloseButton" onClick={fun_removeContactInfoField.bind(this,componentNum)} />
                            }
                        </div>
                        :
                            this.state.inputType === "FreeTextAddress" || this.state.inputType === "FreeTextShippingAddress" ?
                            <div className="address_fields">
                            <textarea
                                onChange={
                                    (e)=>{
                                        this.setState({
                                            inputValue: e.target.value
                                        },()=>{
                                            fun_checkContactInfoInput(this.state.inputValue, componentNum)
                                        })
                                    }
                                }
                                onBlur={()=>fun_checkInputBlur(this.state.inputValue, this.state.inputType, componentNum)}
                                className="contact_info_textarea" 
                                ref={(c) => this.contactinfovalue = c} 
                                name="contactinfovalue" 
                                value={this.state.inputValue}
                                autoComplete="off"
                            >
                            </textarea>
                            {
                                this.state.inputValue !== "" &&
                                    <FiX className="extraFieldCloseButton" onClick={fun_removeContactInfoField.bind(this,componentNum)} />
                            }
                        </div>

                        :
                        <div>
                        <input 
                            type="text" 
                            className="fieldheightdecr" 
                            ref={(c) => this.contactinfovalue = c} 
                            name="contactinfovalue" 
                            style={{height: 30}}
                            value={this.state.inputValue}
                            autoComplete="off"
                            onChange={
                                (e)=>{
                                    this.setState({
                                        inputValue: e.target.value
                                    },()=>{
                                        fun_checkContactInfoInput(this.state.inputValue, componentNum)
                                    })
                                }
                            }
                            onBlur={()=>fun_checkInputBlur(this.state.inputValue, this.state.inputType, componentNum)}
                        /> 
                        {
                            this.state.inputValue !== "" &&
                                <FiX className="extraFieldCloseButton" onClick={fun_removeContactInfoField.bind(this,componentNum)} />
                        }
                        </div>

                    }

                    
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </div>
        )
    }
}