import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'
import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateItemDocument extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            itemId: "",
            itemNum: "",
            itemName: "",
           
            departmentData: [],
            allUsersList: [],
            selectedUsers: [],

            documentFile: "",

            selectedFile: "",
            selectedDepartmentUsers: [],
            description: "",
        }
    }

    componentDidMount(){
        let itmId = localStorage.getItem("itmId")
        let itmIdNum = localStorage.getItem("itmIdNum")
        let itmName = localStorage.getItem("itmName")

        this.setState({
            itemId: itmId,
            itemNum: itmIdNum.toUpperCase(),
            itemName: itmName,
        },()=>{
            this.getDepartment()
        })
    }

    getDepartment = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment("", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                departmentData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    getUsersByDepartment = (_id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetUsersByDepartment(_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                allUsersList: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }


    toggleUsers = (i) => {
        let selectedUsers = [...this.state.selectedUsers]
        let selectedDepartmentUsers = [...this.state.selectedDepartmentUsers]
        let allUsersList = [...this.state.allUsersList] 
    
        let userId = allUsersList[i]._id
        let userName = allUsersList[i].username
        let userData = selectedUsers
        let userDeptData = selectedDepartmentUsers
        if(selectedUsers.includes(userId)){
            const index = userData.indexOf(userId);
            userData.splice(index, 1)
            userDeptData.splice(index, 1)
            this.setState({
                selectedUsers: userData,
                selectedDepartmentUsers: userDeptData
            })
        }else{
            let obj = {
                "id": userId,
                "name": userName
            }
            userData.push(userId)
            userDeptData.push(obj)
            this.setState({
                selectedUsers: userData,
                selectedDepartmentUsers: userDeptData
            })
        }
    }

    onFileChange = event => {
        let that = this
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ 
                    selectedFile: event.target.files[0],
                    documentFile: e.target.result 
                })   
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    viewSelectedFile = () => {
        let file = this.state.documentFile
        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" +encodeURI(file)+ "'></iframe>"
        )
    }

    createItemDoc = () => {
        let {
            itemId,
            selectedFile,
            selectedDepartmentUsers,
            description,
        } = this.state
        if(selectedFile === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select file to upload"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateItemDoc(itemId,JSON.stringify(selectedDepartmentUsers),description,selectedFile)
            .then(response => {
                this.setState({ 
                    isLoading: false
                },()=>{
                    this.props.history.push({
                        pathname: "/edititemdoc",
                        state: { itemId: itemId, itemDocId: response.data._id, isNewEntry: true }
                    }) 
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isItems={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Add a document for {this.state.itemNum} {this.state.itemName.length > 20 ? this.state.itemName.substring(0, 20)+'...' : this.state.itemName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createItemDoc()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title mt-1">Select File </div>
                                <div className="value">
                                    <div className="float-left width50">
                                        <input 
                                            type="file" 
                                            className='input-file' 
                                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/png, image/gif, image/jpeg"
                                            onChange={this.onFileChange}
                                        />
                                    </div>
                                    {
                                        this.state.selectedFile &&
                                        <div className='width40 d-inline-block text-left secondary-color mt-2 ml-2 cursor-pointer' onClick={()=>this.viewSelectedFile()}>
                                            Selected File
                                        </div>
                                    }
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Select Department </div>
                                <div className="value">
                                    <Select2    
                                        data={
                                            this.state.departmentData.map((data) => (
                                                { id: data._id, text: data.number.toUpperCase()+" "+data.name }
                                            ))
                                        }
                                        value={this.state.department}
                                        options={{placeholder: 'Select Department'}}
                                        onSelect={(e)=> this.setState({ department: e.target.value },()=>{
                                            this.getUsersByDepartment(e.target.value)
                                        })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Select Users </div>
                                <div className="value">
                                {
                                    this.state.allUsersList !== null &&
                                    this.state.allUsersList.map((data, i) => (
                                        <div key={data._id}>
                                            <div className="float-left">
                                                <input 
                                                    type="checkbox" 
                                                    className="__input_checkbox"
                                                    value={data._id}
                                                    defaultChecked={this.state.selectedUsers.includes(data._id)}
                                                    onChange={()=>this.toggleUsers(i)}
                                                ></input>
                                            </div>
                                            <div className="float-left createdText border-0 ml-1"><span className="text-capitalize">{data.username}</span></div>
                                            <div className="clear"></div>
                                        </div> 
                                    ))
                                }
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Description</div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.description}
                                        onChange={(e)=>this.setState({ description: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createItemDoc()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}