import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../../components'
import Loading from './../../../../helper/loading'
import Select2 from '../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css'
import * as Im from "react-icons/im";

import * as Ai from "react-icons/ai";
import * as APIManager from './../../../../APIManager/APIManager'

import GroupItem from './GroupItem';

class CreateBOMOfItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            BOM_List: [],
            productGroupList: [],
            selectedBOM: "",

            itemName: "",
            itemId: "",
            routingId: "",

            productObj: {
                'productGroup': '',
                'part': '',
                'notes': '',
                'quantity': '',
                'cadNo': ''
            },

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: [],
        }
    }

    componentDidMount(){
        let itemNameNumber = this.props.location.state.itemNameNumber
        let itemId = this.props.location.state.itemId

        this.setState({
            itemName: itemNameNumber,
            itemId: itemId
        },()=>{
            this.getAllBOM()
            this.getProductGroup()
        })
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callProductGroupList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response.productGroup
            },()=>{
                this.setGroupItem()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    getAllBOM = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllBOM("","","","","","","","","",false)
        .then(response => {
            this.setState({ 
                isLoading: false,
                BOM_List: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    getSingleBOMDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleBOM(id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupFields: [],
                productGroupKeys: [],
                productGroupCounter: 0,
                productGroupData: response.parts,
            },()=>{
                this.setProductGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setProductGroupData = () => {
        let prodGrp = this.state.productGroupData
        let groupLength = prodGrp.length;
        
        if(groupLength > 0){

            let groupField = [];
            let groupKey = [];

            for(let i=0;i<prodGrp.length;i++){
               
                if(prodGrp[i] !== null){
                    groupField.push(
                        <GroupItem
                            key={'proGroup'+i}
                            onChangeProduct={this.addNewGroupFields}
                            onBlurProduct={this.addGroupFieldValues}
                            onRemoveProduct={this.removeGroupProduct}
                            counter={i}
                            groupData={prodGrp[i]}
                            itemOfBOM={this.state.itemId}
                            productGroupList={this.state.productGroupList}
                        />
                    )
                    groupKey.push(i)
                }
            }

            this.setState({
                productGroupFields: groupField,
                productGroupKeys: groupKey,
                productGroupCounter: groupLength
            },()=>{
                this.setNewBlankField(this.state.productGroupCounter)
            })
        }else{
            this.setGroupItem()
        }
    }

    setNewBlankField = (counter) => {
        let dummyObj =  {
            'productGroup': '',
            'part': '',
            'notes': '',
            'quantity': '',
            'cadNo': ''
        }

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <GroupItem
                    key={'proGroup'+counter}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={counter}
                    groupData={dummyObj}
                    itemOfBOM={this.state.itemId}
                    productGroupList={this.state.productGroupList}
                />
            ]),            
        })
    }

    setGroupItem = () => {
        let dummyObj =  {
            'productGroup': '',
            'part': '',
            'notes': '',
            'quantity': '',
            'cadNo': ''
        }

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <GroupItem
                    key={'proGroup'}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={this.state.productGroupCounter}
                    groupData={dummyObj}
                    itemOfBOM={this.state.itemId}
                    productGroupList={this.state.productGroupList}
                />
            ]),            
        })
    }

    addNewGroupFields = (counter) => {
        let dummyObj =  {
            'productGroup': '',
            'part': '',
            'notes': '',
            'quantity': '',
            'cadNo': ''
        }

        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <GroupItem
                        key={'proGroup'+counter+1} 
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={counter+1}
                        groupData={dummyObj}
                        itemOfBOM={this.state.itemId}
                        productGroupList={this.state.productGroupList}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: counter+1,
            })
        }
    }
    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        })
    }
    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        })
    }
  
    goBack = () => {
        this.props.history.goBack()
    }


    createBOM = () => {
        let {
            itemId,
            productGroupData
        } = this.state;

        /* Remove Null From State */
        let parts = [];
        productGroupData.forEach((val) => {
            if(val !== null){
                parts.push(val);
            }
        });
        /* Remove Null */


        if(parts.length === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select parts for BOM"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateBOM(itemId, "", JSON.stringify(parts))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editbom",
                        state: { BOMId: response._id, newBOMEntry: true}
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

  

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isBOM={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title">Create a BOM for <span className="text-capitalize">{this.state.itemName.length > 50 ? this.state.itemName.substring(0, 50)+'...' : this.state.itemName}</span></div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.downloadPDF()}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>    
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createBOM()}>Save</div>
                            <div className="clear"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product</div>
                                    <div className="value">
                                    <div className="createdText text-capitalize"><span className="clickable__source">{this.state.itemName.length > 50 ? this.state.itemName.substring(0, 50)+'...' : this.state.itemName}</span></div>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Name *</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-capitalize"
                                            value={this.state.itemName + " BOM"}
                                            readOnly
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Copy BOM</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.BOM_List.map((data) => (
                                                    { id: data._id, text: data.name }
                                                ))
                                            }
                                            value={this.state.selectedBOM}
                                            options={{placeholder: 'Select BOM'}}
                                            onSelect={(e)=> this.setState({ selectedBOM: e.target.value },()=>{
                                                this.getSingleBOMDetail(e.target.value)
                                            })}
                                        />
                                        {/* <select className="text-capitalize" onChange={(e)=>{
                                                if(e.target.value !== ""){
                                                    this.getSingleBOMDetail(e.target.value)
                                                }
                                            }}>
                                                <option value="">Select</option>
                                                {
                                                    this.state.BOM_List.map((data, i) => (
                                                        <option key={data._id} value={data._id}>{data.name}</option>
                                                    ))
                                                }
                                        </select> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Routings</div>
                                        <div className="value">
                                            <div className="__static_fixed_text"></div>
                                        </div>
                                    <div className="clear"></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">Parts</div>
                                    <div className="clear"></div>
                                </div> */}
                            </div>
                            
                            <div className="clear"></div>

                            <div className="main-container mt-20 order_product_group">
                                <div>
                                    <div className="sub-page-title">Parts</div>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className='pg-tab-head'>Product group</th>
                                            <th className='pg-tab-head'>CAD No.</th>
                                            <th className='pg-tab-head'>Article Code</th>
                                            <th className='pg-tab-head'>Part Description</th>
                                            <th className='pg-tab-head'>Quantity</th>
                                            <th className='pg-tab-head'>UoM</th>
                                            <th className='pg-tab-head'>Price/UoM</th>
                                            <th className='pg-tab-head'>Notes</th>
                                            <th className='pg-tab-head'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.productGroupFields
                                        }
                                    </tbody>
                                </table>
                            </div>
                            
                            
                           
                
                            
                       
                        </div>
                                    


                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createBOM()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>

                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        );
    }
}

export default CreateBOMOfItem;