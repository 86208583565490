import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import FlatList from 'flatlist-react';
import * as APIManager from './../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'

const settings = [
    { text: "Bank Accounts", id: "bank_accounts" },
    { text: "Expense Categories", id: "expense_categories" },
    { text: "Party", id: "party" }
]

export default class AccountingMaster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "",

            quotationHeader: "",
            quotationFooter: "",

            expenseCategories: [],
            bankAccounts: [],
            gstParty: []

        }
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount() {
        const getSelectedIndex = localStorage.getItem('accounting_master_selected_index')
        if (getSelectedIndex === "" || getSelectedIndex === null) {
            this.setState({
                selectedIndex: 'bank_accounts',
            })
        } else {
            this.setState({
                selectedIndex: getSelectedIndex,
            })
        }

        this.getExpenseCategories()
        this.getBankAccounts()
        this.getParty()
    }


    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
            .then(response => {
                let category = response.expenseCategory ? response.expenseCategory : []
                this.setState({
                    isLoading: false,
                    expenseCategories: category
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getBankAccounts = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetBankAccounts()
            .then(response => {
                let bankAccounts = response.bankAccountDetails ? response.bankAccountDetails : []
                this.setState({
                    isLoading: false,
                    bankAccounts: bankAccounts
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getParty = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllGSTDetail("", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    isLoading: false,
                    gstParty: response.data
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    renderSettings = (settings, index) => {
        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts'} onClick={() => {
                this.setState({
                    selectedIndex: index,
                }, () => {
                    localStorage.setItem('accounting_master_selected_index', index);
                });
            }}
            >
                {settings.text}
            </div>
        );
    }

    editRecord = (path) => {
        let slug = "Settings_Accounting"
        let permission = "update"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: path
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    createParty = () => {
        let slug = "Settings_Accounting"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createparty"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editParty = (id) => {
        this.props.history.push({
            pathname: "/editparty",
            state: { partyId: id, isNewEntry: false }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isSettings={true} />
                <SettingsHeader isAccountingMaster={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Accouting Master</div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent">
                        <div className="datamenus">

                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>

                        {
                            this.state.selectedIndex === "expense_categories" ?
                                <div className="dataDetails">
                                    <h5 className="font-weight-bold mb-2">Categories</h5>
                                    <div className="clear"></div>
                                    {
                                        this.state.expenseCategories.map((data, i) => (
                                            <div key={"expensecat" + i}>
                                                <div className="detailsValue">{data.expenseCategory}</div>
                                                <div className="clear"></div>
                                            </div>
                                        ))
                                    }

                                    {/* <Link to="/editexpensecategories"><div  className="setEditButton">Edit</div></Link> */}
                                    <div onClick={() => this.editRecord('editexpensecategories')}><div className="setEditButton">Edit</div></div>
                                </div>
                                :
                                this.state.selectedIndex === "bank_accounts" ?
                                    <div className="dataDetails">
                                        <h5 className="font-weight-bold mb-2">Bank Accounts</h5>
                                        <div className="clear"></div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Account Number</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.bankAccounts.map((data, i) => (
                                                        <tr key={"bankAccounts" + i} onClick={() => this.editRecord('editbankaccounts')}>
                                                            <td>{data.bankName}</td>
                                                            <td>{data.bankNumber}</td>
                                                            <td>
                                                                {
                                                                    data.openingBalance > 0 ?
                                                                        (data.openingBalance).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")
                                                                        :
                                                                        data.openingBalance < 0 ?
                                                                            '-' + data.openingBalance.toString().split("-")[1].split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")
                                                                            :
                                                                            data.openingBalance
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div onClick={() => this.editRecord('editbankaccounts')}><div className="setEditButton">Edit</div></div>
                                    </div>
                                    :
                                    this.state.selectedIndex === "party" ?
                                        <div className="dataDetails">
                                            <h5 className="font-weight-bold mb-2">Party</h5>
                                            <div className="clear"></div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="tableHeader tableHeadButton" onClick={() => this.createParty()}><Fi.FiPlus /></th>
                                                        <th>Party Name</th>
                                                        <th>GST Number</th>
                                                        <th>Full Name</th>
                                                        <th>Mobile</th>
                                                        <th>Email</th>
                                                        <th>Expense Category</th>
                                                        <th className="tableHeader tableHeadButton" onClick={() => this.createParty()}><Fi.FiPlus /></th>
                                                    </tr>


                                                    <tr>
                                                        <th className="tableHeader tableHeadButton" style={{ width: 30 }}></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => { this.getAllRouting() })} /></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.bomNumber} onChange={(e) => this.setState({ bomNumber: e.target.value }, () => { this.getAllRouting() })} /></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.bomName} onChange={(e) => this.setState({ bomName: e.target.value }, () => { this.getAllRouting() })} /></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.workStationGroupNumber} onChange={(e) => this.setState({ workStationGroupNumber: e.target.value }, () => { this.getAllRouting() })} /></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.workStationGroupName} onChange={(e) => this.setState({ workStationGroupName: e.target.value }, () => { this.getAllRouting() })} /></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.workStationGroupName} onChange={(e) => this.setState({ workStationGroupName: e.target.value }, () => { this.getAllRouting() })} /></th>
                                                        <th className="tableHeader tableHeadButton" style={{ width: 30 }}></th>

                                                    </tr>


                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.gstParty.map((data, i) => (
                                                            <tr key={"gstParty" + i} onClick={() => this.editParty(data._id)}>
                                                                <td style={{ width: 30 }}>{i + 1}</td>
                                                                <td>{data.companyName}</td>
                                                                <td>{data.gstNumber}</td>
                                                                <td>{data.fullName}</td>
                                                                <td>{data.mobileNo}</td>
                                                                <td className='text-lowercase'>{data.email}</td>
                                                                <td>{data.expenseCategory}</td>
                                                                <td style={{ width: 30 }}></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        null
                        }

                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}