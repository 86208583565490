import React, { Component } from 'react';
import { Header, AccountingHeader } from './../../../../components'
import * as Ai from 'react-icons/ai'
import * as Bs from 'react-icons/bs'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import StateWiseGSTCode from './../StateWiseGSTCode.json'
import ExpenseGroup from './ExpenseGroup';
import ExpenseGroupGST from './ExpenseGroupGST'
import Switch from 'react-input-switch';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

import NewFile from '../../../../components/AutoGenerateComponent/addNewFile';

class AddExpenses extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            success: false,
            successmessage: "",
            error: false,
            errormessage: "",

            userList: [],
            expenseCategoryData: [],
            paymentTypeData: [],
            gstPartyData: [],
            isGst: false,
            party: "",
            user: "",
            isSubUser: false,
            billdate: "",
            expenseCategory: "",
            stateofsupply: "",
            description: "",
            paymenttype: "",
            referenceNo: "",

            subTotal: 0,
            total: 0,
            productGroupData: [],
            docSrc: "",

            payableAmount: 0,
            paidAmount: "",
            remainingAmount: 0,

            countNewFileNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,
        }
    }

    componentDidMount(){
        this.getUserList()
        this.getExpenseCategories()
        this.getBankAccounts()
        this.getParty()
        this.addNewField(0)
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                userList: response.data
            },()=>{
                let userData =  JSON.parse(localStorage.getItem('userdata'))
                if(userData.role !== "admin"){
                    this.setState({ user: userData._id, isSubUser: true })
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getParty = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllGSTDetail("","","","","","","")
        .then(response => {
            this.setState({
                isLoading: false,
                gstPartyData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
        .then(response => {
            let category = response.expenseCategory ? response.expenseCategory : []
            this.setState({
                isLoading: false,
                expenseCategoryData: category
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    addNewParty = () => {
        let slug = "Settings_Accounting"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createparty"
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    getBankAccounts = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetBankAccounts()
        .then(response => {
            let paymentTypeData = []
            if(response.bankAccountDetails){
                for(let i = 0; i<response.bankAccountDetails.length;i++){
                    let record = response.bankAccountDetails[i]
                    let accStr1 = "****"
                    let accStr2 = (record.bankNumber).substr(record.bankNumber.length - 4)
                    let obj = {
                        id: record.id,
                        text: record.bankName +" "+ accStr1 + " " + accStr2
                    }
                    paymentTypeData.push(obj)
                }
            }

            this.setState({
                isLoading: false,
                paymentTypeData: paymentTypeData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    clearDataOnToggle = () => {
        this.setState({
            subTotal: 0,
            total: 0,
            productGroupData: [],
            payableAmount: 0,
            paidAmount: "",
            remainingAmount: 0,
            stateofsupply: "",
            party: ""
        })
    }

   
    setTotalAmountData = (grandTotal,subTotal,productGroupData) => {
        let value = grandTotal !== "" ? grandTotal : 0
        let paid = this.state.paidAmount !== "" ? this.state.paidAmount : 0
        this.setState({
            payableAmount: parseFloat(value).toFixed(2),
            remainingAmount: (parseFloat(value) - parseFloat(paid)).toFixed(2),
            total: value,
            subTotal: subTotal,
            productGroupData: productGroupData,
        })
    }

    getSingleParty = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleGSTDetail(this.state.party)
        .then(response => {
            let gstStateCode = response.data.gstNumber.substring(0,2);

            let gstStateDetails = StateWiseGSTCode.find(obj => {
                return obj.gstCode === gstStateCode
            })

            this.setState({
                isLoading: false,
                stateofsupply: gstStateDetails.gstCode+' '+gstStateDetails.stateCode,
                expenseCategory: response.data.expenseCategory,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    setPaidAmount = (val) => {
        let value = val >= 0 ? val : ""
        if(value === "" || value <= parseFloat(this.state.payableAmount))
        this.setState({
            paidAmount: value,
            remainingAmount: value !== "" ? (parseFloat(this.state.payableAmount) - parseFloat(value)).toFixed(2) : 0,
        })
    }


    /* FILE UPLOAD ALL FUNCTIONALITY */

    addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <NewFile 
                        key={len}
                        componentNum={len}
                        newFile={""}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countNewFileNumber: this.state.countNewFileNumber.concat([len]),
            })
        })
    }
    
    statusInputEdit = (newFile, num) => {
        let array = [...this.state.countNewFileNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
        
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && newFile !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countNewFileNumber: this.state.countNewFileNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <NewFile 
                                key={this.state.count}
                                componentNum={this.state.count}
                                newFile={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (newFile, num) => {
    
         let array = [...this.state.countNewFileNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
             if (newFile !== "") {
                 let obj = {
                     "newFile": newFile,
                 }
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (newFile !== "") {
                 let obj = {
                     "newFile": newFile,
                 }
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    statusInputRemove = (number) => {
        let array = [...this.state.countNewFileNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countNewFileNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }
    /* FILE UPLOAD ALL FUNCTIONALITY */

    goBack = () => {
        this.props.history.goBack();
    }

    createExpense = () => {
        let {
            user,
            expenseCategory,
            billdate,
            stateofsupply,
            description,
            productGroupData,
            subTotal,
            total,
            paymenttype,
            paidAmount,
            remainingAmount,
            referenceNo,
            isGst,
            party
        } = this.state

        let val = this.state.typeFieldValues;  
        let filteredVal = val.filter(function (el) {
            return el != null;
        });

        if(isGst && party === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select party first"
            })
        }else if(user === ''){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select user"
            })
        }else if(billdate === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select bill date"
            })
        }else if(total === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please add expense details and amount"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateExpenseDetail(user,expenseCategory,convertDateToMMDDYYYY(billdate),stateofsupply,referenceNo,JSON.stringify(productGroupData),subTotal,total,paymenttype,paidAmount,remainingAmount,description,isGst,party)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    if(filteredVal.length > 0){
                        this.createAllFiles(filteredVal, response._id)
                    }else{
                        this.props.history.push({
                            pathname: "/editexpense",
                            state: { expenseId: response._id, isNewEntry: true }
                        }) 
                    }
                   
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    createAllFiles = (files, id) => {
        this.setState({ isLoading: true, error: false })
        Promise.all(files.map((data)=>{
            return APIManager.callCreateExpenseFile(id,data.newFile)
        })).then(resp => {
            this.props.history.push({
                pathname: "/editexpense",
                state: { expenseId: id, isNewEntry: true }
            })
        }) 
    } 


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isAccounting={true} />
                <AccountingHeader isExpenses={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Add New Expense</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createExpense()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">GST</div>
                                    <div className="value switch" style={{ width: 24 }}>
                                        <Switch
                                            value={this.state.isGst ? 1 : 0}
                                            onChange={()=>this.setState({ isGst: !this.state.isGst },()=>{ this.clearDataOnToggle() })}
                                            styles={{
                                                track: {
                                                    backgroundColor: '#c1c1c1'
                                                },
                                                trackChecked: {
                                                    backgroundColor: 'green'
                                                },
                                                button: {
                                                    backgroundColor: '#fff',
                                                    width: 10
                                                },
                                                buttonChecked: {
                                                    backgroundColor: '#fff',
                                                    width: 10
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                {
                                    this.state.isGst &&
                                    <div className="add_inner">
                                        <div className="title">Party</div>
                                        <div className="value">
                                            {/* <input type="text" value={this.state.party} onChange={(e)=>this.setState({ party: e.target.value})} /> */}
                                            <div className='float-left width90'>
                                                <Select2    
                                                    data={this.state.gstPartyData.map((data)=>(
                                                        { id: data._id, text: data.gstNumber+' '+data.companyName}
                                                    ))}
                                                    value={this.state.party}
                                                    options={{placeholder: 'Select Party'}}
                                                    onSelect={(e)=> this.setState({ party: e.target.value },()=>{this.getSingleParty()})}
                                                />
                                            </div>
                                            <div className='width10 d-inline-block text-center secondary-color addPartyIcon' title="Add new party" onClick={()=>this.addNewParty()}>
                                                <Bs.BsPersonPlusFill />
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div> 
                                }
                                
                                
                                <div className="add_inner">
                                    <div className="title">Expense Category</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.expenseCategoryData.map((data)=>(
                                                {id: data.expenseCategory, text: data.expenseCategory}
                                            ))}
                                            value={this.state.expenseCategory}
                                            options={{placeholder: 'Select Category'}}
                                            onSelect={(e)=> this.setState({ expenseCategory: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Account Manager</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.userList.map((data)=>(
                                                { id: data._id, text: data.username ? data.username : data.full_name}
                                            ))}
                                            value={this.state.user}
                                            options={{placeholder: 'Select User'}}
                                            onSelect={(e)=> this.setState({ user: e.target.value })}
                                            disabled={this.state.isSubUser}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                
                            </div> 
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Bill Date*</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={this.state.billdate}
                                            onChange={
                                                date => this.setState({ billdate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                {
                                    this.state.isGst &&
                                    <div className="add_inner">
                                        <div className="title">State of supply</div>
                                        <div className="value">
                                            <Select2    
                                                data={
                                                    StateWiseGSTCode.map((data)=>(
                                                        { id: data.gstCode+' '+data.stateCode, text: data.gstCode +' '+ data.stateName }
                                                    ))
                                                }
                                                value={this.state.stateofsupply}
                                                options={{placeholder: 'Select State'}}
                                                onSelect={(e)=> this.setState({ stateofsupply: e.target.value })}
                                            />
                                        </div>
                                        <div className="clear"></div>
                                    </div> 
                                }
                                <div className="add_inner">
                                    <div className="title">Description</div>
                                    <div className="value">
                                        <textarea 
                                            autoComplete="off" 
                                            className="expense_desc_textarea" 
                                            onChange={(e)=>this.setState({ description: e.target.value})} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div>
                            <div className="clear"></div>

                            {
                                this.state.isGst ?
                                    <ExpenseGroupGST 
                                        callBackFunction={this.setTotalAmountData}
                                        isGst={this.state.isGst}
                                    />
                                :
                                    <ExpenseGroup 
                                        callBackFunction={this.setTotalAmountData}
                                        isGst={this.state.isGst}
                                    />
                            }
                            
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Payment type</div>
                                    <div className="value">
                                        <Select2    
                                            data={this.state.paymentTypeData}
                                            value={this.state.paymenttype}
                                            options={{placeholder: 'Select Type'}}
                                            onSelect={(e)=> this.setState({ paymenttype: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Reference No.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.referenceNo} onChange={(e)=>this.setState({ referenceNo: e.target.value})} />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Total</div>
                                    <div className="value">
                                        <input type="text" value={this.state.payableAmount} readOnly/>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Paid</div>
                                    <div className="value">
                                        <input type="text" value={this.state.paidAmount} onChange={(e)=>this.setPaidAmount(e.target.value)} />
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner mt-2">
                                    <div className="title font-weight-bold">Remaining</div>
                                    <div className="value font-weight-bold createdText border-0">
                                        {this.state.remainingAmount}
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className='position-relative'>
                            <div className='add_partside'>
                                <div className="add_inner">
                                    <div className="title">Add document</div>
                                    <div className="value">
                                        {/* <input 
                                            className='input-file add_inner'
                                            type="file"
                                            onChange={this.onFileChange}
                                            accept="application/msword, application/vnd.ms-excel, application/pdf, image/*"
                                        ></input> */}
                                        <table>
                                            <tbody>
                                            {
                                                this.state.addTypeFields
                                            }
                                            </tbody>
                                        </table>
                                       
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>

                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createExpense()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default AddExpenses; 