import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'

import ExpenseCategories from './../../../components/AutoGenerateComponent/addExpenseCategories'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditExpenseCategories extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countExpenseCategoriesNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,


            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.getExpenseCategories();
    }
    
    getExpenseCategories = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetExpenseCategories()
        .then(response => {
            let category = response.expenseCategory ? response.expenseCategory : []
            this.setState({
                isLoading: false,
                expenseCategories: category,
                typeFieldValues: category
            },()=>{
                this.setTypeFields(category)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.addNewField(0)
            })
        });
    }

    
    setTypeFields = (category) => {
        if(category.length > 0){
            let numArray = []
            let fields = []
            for(let i=0;i<category.length;i++){
                numArray.push(i)
                fields.push(
                    <ExpenseCategories 
                        key={i}
                        componentNum={i}
                        expenseCategories={category[i].expenseCategory}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                )
            }
            this.setState({
                addTypeFields: fields,
                countExpenseCategoriesNumber: numArray,
                count: category.length
            },()=>{
                this.addNewField(category.length)
            })
        }else{
            this.addNewField(0)
        }
    }

    addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <ExpenseCategories 
                        key={len}
                        componentNum={len}
                        expenseCategories={""}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countExpenseCategoriesNumber: this.state.countExpenseCategoriesNumber.concat([len]),
            })
        })
    }
    
    statusInputEdit = (expenseCategories, num) => {
        let array = [...this.state.countExpenseCategoriesNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
        
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && expenseCategories !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countExpenseCategoriesNumber: this.state.countExpenseCategoriesNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <ExpenseCategories 
                                key={this.state.count}
                                componentNum={this.state.count}
                                expenseCategories={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (expenseCategories, num) => {
    
         let array = [...this.state.countExpenseCategoriesNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   

         if (typeof statusVal[index] !== 'undefined') {
             if (expenseCategories !== "") {
                 let obj = {
                     "expenseCategory": expenseCategories,
                 }
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (expenseCategories !== "") {
                 let obj = {
                     "expenseCategory": expenseCategories,
                 }
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }

     }
 

    statusInputRemove = (number) => {

        let array = [...this.state.countExpenseCategoriesNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countExpenseCategoriesNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }

    updateCategories = () => {
        if(this.state.fieldNotEditable !== true){
            let val = this.state.typeFieldValues;  
            let filteredVal = val.filter(function (el) {
                return el != null;
            }); 

            if(filteredVal.length === 0){
                this.setState({
                    error: true,
                    errormessage: "Please add expense categories",
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callSetExpenseCategories(JSON.stringify(filteredVal))
                .then(response => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Expense categories updated successfully"
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        success: false,
                        successmessage: "Expense categories not updated, please retry later."
                    })
                });
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isAccountingMaster={true} />
                
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Expense Categories</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                   
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateCategories()}>{ this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                        <div className="clear"></div>
                    </div>
                    
                    <div className="add_partside position-relative">
                        <div className="add_inner">
                            <div className="title"></div>
                            <div className="value">
                                <table className="">
                                    <tbody>
                                        {
                                            this.state.addTypeFields
                                        }
                                    </tbody>
                                </table>    
                            </div>
                            <div className="clear"></div>
                        </div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
                    <div className="clear"></div>
            
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateCategories()}>{ this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}