import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateStockLotNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            stockLotId: "",
            stockLotNum: "",
            note: "",
        }
      
    }

    componentDidMount(){
        let id = localStorage.getItem("stockLotId")
        let number = localStorage.getItem("stockLotNumber")

        this.setState({ 
            stockLotId: id,
            stockLotNum: number,
            loading: false,
        })
    }

    createNotes = () => {
        let {
            note,
            stockLotId
        } = this.state

        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateStockLotNote(stockLotId, note)
            .then(response => {
                localStorage.setItem("stockLotNoteId",response.data)
                localStorage.setItem("stockLotNewRecord",true)
                localStorage.setItem("stockLotNote", note)
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push('/editslnote')
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isStockLots={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a note</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Lot</div>
                                <div className="value companyname text-capitalize">{this.state.stockLotNum}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}