
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';

export const exportInvoicePDF = (responseData) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
   
    const title = "Vendors";

    

    const headers = [[
        "Number",
        "Name",
        "On time",
        "Average Delay",
        "Phone",
        "Skype",
        "URL",
        "Address",
        "Total cost"
    ]];

    const data = responseData.map((elt, i)=> [
        elt.number,
        elt.name,
        elt.onTime ? elt.onTime+ ' %' : '',
        elt.averageDelay ? elt.averageDelay+ ' %': '',
        elt.Phone,
        elt.Skype,
        elt.Web,
        elt.Address ? elt.Address.split('_').join(' ') : '',
        elt.totalCost
    ]);

    let content = {
    startY: 50,
    head: headers,
    body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("vendors.pdf")
}

export const exportInvoiceCSV = (responseData) => {
    const title = "Vendors";
    const headers = [
        "Number",
        "Name",
        "On time",
        "Average Delay",
        "Phone",
        "Skype",
        "URL",
        "Address",
        "Total cost"
    ];

    const data = responseData.map((elt, i) => [
        elt.number,
        elt.name,
        elt.onTime ? elt.onTime+ ' %' : '',
        elt.averageDelay ? elt.averageDelay+ ' %': '',
        elt.Phone ? elt.Phone : '',
        elt.Skype ? elt.Skype : '',
        elt.Web ? elt.Web : '',
        elt.Address ? elt.Address.split('_').join(' ') : '',
        elt.totalCost
    ]);


    const options = { 
        filename: 'vendors',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers,
    };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);
}


