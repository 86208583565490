import React, { Component } from 'react';
import { PDFViewer, Page, Text, View, Image, Font, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import renderHTML from 'react-render-html';
import Denmark from './../../../../assets/Fonts/DENMARK.ttf'
import CalibriBold from './../../../../assets/Fonts/CalibriBold.ttf'
import CalibriRegular from './../../../../assets/Fonts/CalibriRegular.ttf'
import * as APIManager from './../../../../APIManager/APIManager'
import { BrowserView, MobileView } from 'react-device-detect';
import Html from 'react-pdf-html';

class generateLabelPDF extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "loading...", 
            isReadyToLoad: false,

            coInvoiceId: "",
            companyDetails: [],
            invoiceNumber: "",
            buyer_company: "",
            buyer_address: "",
            buyer_phoneNo: "",
            buyer_email: "",
            buyer_panNo: "",
            buyer_GSTNo: "",
            account_name: "",
            account_phone: "",
            account_email: "",
            contactPerson_Name: "",
            contactPerson_PhoneNo: "",
            contactPerson_Email: "",
            
            invoiceDate: "",
            invoiceType: "",
            dueDate: "",

            status: "",

            rfqNo: "",
            rfqDate: "",
            poDate: "",
            poNo: "",

            deliveryAddress: "",
            invoiceAddress: "",

            invoiceMessageHeader: "",
            invoiceMessageFooter: "",

            itemData: [],
            itemValue: [],

            printItemDetails: [],
            printBasicTotal: 0,
            printTaxValues: [],

            grandBasicTotal: "",
            grandTotal: "",
            rupeeSymbol: "",

            taxApplicable: [],
            taxInfo: [],

            printTerms: [],

            terms: [],

            userAccountId: "",
            pdfTypeText: "",

            qrCodeImage: "",

            responseData: []
        }
    }

    componentDidMount(){

        let id = localStorage.getItem("CO_InvoiceId")
        let userDetails = localStorage.getItem("userdata")
        let accId = JSON.parse(userDetails).account_id
        this.setState({
            coInvoiceId: id,
            userAccountId: accId,
        },()=>{
            this.getInvoicePDFData()
        })
    }

 

    getInvoicePDFData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCOInvoicePDF(this.state.coInvoiceId)
        .then(response => {
            this.setState({
                isLoading: false,
                responseData: response,
                companyDetails: response.companyDetails,
                invoiceNumber: response.invoiceNumber,

                buyer_company: response.buyer_company,
                buyer_panNo: response.buyer_panNo,
                buyer_GSTNo: response.buyer_GSTNo,
                buyer_address: response.invoiceAddress,
                buyer_phoneNo: response.buyer_phoneNo,
                buyer_email: response.buyer_email,

                account_name: response.account_name,
                account_phone: response.account_phone,
                account_email: response.account_email,

                contactPerson_Name: response.contactPerson_Name,
                contactPerson_PhoneNo: response.contactPerson_PhoneNo,
                contactPerson_Email: response.contactPerson_Email,

                status: response.status,
                rfqNo: response.rfqNo,
                poNo: response.poNo,

                deliveryAddress: response.deliveryAddress,
                invoiceAddress: response.invoiceAddress,

                invoiceMessageHeader: response.invoiceMessageHeader,
                invoiceMessageFooter: response.invoiceMessageFooter,

                itemData: response.itemData,
                itemValue: response.itemValue,

            
                rupeeSymbol: response.rupeeSymbol,

                isReadyToLoad: true,
            })
        }).catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Something went wrong, please check your login status and try again" 
            })
        });
    }


    render() {
        return (
            <>
            <BrowserView>
            {
                this.state.isReadyToLoad ?
                    <PDFViewer style={styles.container}>
                        <Document>
                            <Page style={styles.body}>
                                <View style={styles.mainView}>
                                    <View style={styles.mainText}>
                                        <Text style={styles.bold_text}>{this.state.buyer_company}</Text>
                                        <Text style={{ fontSize: 20, flex: 1, textTransform: 'capitalize' }}>{this.state.buyer_address}</Text>
                                        {
                                            this.state.buyer_phoneNo !== "" &&
                                            <Text style={{ fontSize: 20 }}>m | {this.state.buyer_phoneNo}</Text>
                                        }
                                    </View>
                                </View>
                                <View style={styles.fromView}>
                                    <View style={styles.fromText}>
                                        <Text style={[styles.bold_text, styles.footer_company]}><Text style={{ fontSize: 10, fontFamily: 'CalibriRegular',}}>from: </Text>{this.state.companyDetails.companyName}</Text>
                                        <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}, {this.state.companyDetails.address_line2}</Text>
                                        <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}, {this.state.companyDetails.address_state}</Text>
                                        <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                        <Text style={styles.footer_detail_text}>
                                            { this.state.companyDetails.phone ? 'm | +91 ' +this.state.companyDetails.phone : '' }
                                        </Text>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                :
                    <div className="__pdf_error_message">
                        {this.state.errormessage}
                    </div>
            }
            </BrowserView>
            <MobileView>
                <div className="__pdf_error_message">
                    {/* This browser doesn't support PDF view at the moment. */}
                    <PDFDownloadLink document={
                        <Document>
                            <Page style={styles.body}>
                                <View style={styles.mainView}>
                                    <View style={styles.mainText}>
                                        <Text style={styles.bold_text}>{this.state.buyer_company}</Text>
                                        <Text style={{ fontSize: 20, flex: 1, textTransform: 'capitalize' }}>{this.state.buyer_address}</Text>
                                        {
                                            this.state.buyer_phoneNo !== "" &&
                                            <Text style={{ fontSize: 20 }}>m | {this.state.buyer_phoneNo}</Text>
                                        }
                                    </View>
                                </View>
                                <View style={styles.fromView}>
                                    <View style={styles.fromText}>
                                        <Text style={[styles.bold_text, styles.footer_company]}><Text style={{ fontSize: 10, fontFamily: 'CalibriRegular',}}>from: </Text>{this.state.companyDetails.companyName}</Text>
                                        <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}, {this.state.companyDetails.address_line2}</Text>
                                        <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}, {this.state.companyDetails.address_state}</Text>
                                        <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                        <Text style={styles.footer_detail_text}>
                                            { this.state.companyDetails.phone ? 'm | +91 ' +this.state.companyDetails.phone : '' }
                                        </Text>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    } fileName={this.state.invoiceType+" label "+this.state.invoiceNumber.toUpperCase()+".pdf"}>
                    {({ _blob, _url, loading, _error }) => (loading ? 'Loading...' : 'View PDF!')}
                    </PDFDownloadLink>
                </div>
            </MobileView>
            </>
        );
    }
}


Font.register({
    family: 'Denmark',
    src: Denmark,    
});

Font.register({
    family: 'CalibriBold',
    src: CalibriBold
})

Font.register({
    family: 'CalibriRegular',
    src: CalibriRegular
})

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: window.innerHeight,
    },
    body: {
      paddingTop: 10,
      paddingHorizontal: 30,
      fontSize: 12,
      fontFamily: 'CalibriRegular',
    },
    mainView: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    fromView: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    mainText: { 
        alignItems: 'center',
        marginTop: 20, 
        fontSize: 20, 
    },

    fromText: {
        marginTop: 10, 
        textAlign: "right", 
        alignItems: 'flex-end'
    },

    bold_text: {
        fontFamily: 'CalibriBold'
    },
    footer_company: {
        fontSize: 13,
        fontFamily: 'Denmark'
    },
    footer_detail_text: {
        fontSize: 10,
        textAlign: "right"
    },
});

export default generateLabelPDF;