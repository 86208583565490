
const TableLoading = (props) => {
    let tableData = [];

    for(let i=0;i<props.tr;i++){
        let trStart = [];
        for(let j=0;j<props.td;j++){
            trStart.push(<td key={'tds'+j}><span></span></td>)
        }
        tableData.push(<tr className='table-loading-effect' key={'trs'+i}>{trStart}</tr>)
    }
    return(
        <tbody>
            {tableData}
        </tbody>
    )
}

export default TableLoading