import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './style.css';
import './responsive.css';
import './pageTransition.css';
import './theme/lib/react-datepicker.min.css';

import {
    Splash,
    Login,
    Register,
    ForgotPassword,
    ResetPassword,
    VerifyEmail,
    Home,
    Dashboard,
    PageNotFound,
    customerOrderPDF,
    generateQuotationPDF,
    ProductionPlanPDF,

    customerOrderOtherCurrencyPDF,
    customerQuotationOtherCurrencyPDF,

    customerViewPDF,
    purchaseOrderPDF,
    generatePDFForOtherCurrency,
    generateInvoicePDF,
    generateOtherCurrInvoicePDF,
    generateOtherCurrInvoicePDFinInr,
    generateInvoiceWithSez,

    CustomerOrders,
    Quotations,
    CashFlow,
    Customers,
    PumpRegister,
    PumpMaster,
    CreateNewPump,
    EditPump,
    UpdateModelNo,
    UpdateMainMOC,
    UpdateImpellerMOC,
    UpdateImpellerType,
    UpdateMechSeal,
    UpdateMotorRating,
    UpdatePumpDocumentsType,
    NoteSearch,
    CreateCustomerOrderPayment,
    EditCustomerOrderPayment,
    Invoices,
    CreditNote,
    EInvoices,
    ViewEInvoice,
    ViewEWayBill,
    generateLabelPDF,
    generateEWayBillPDF,
    CREDITNOTE_generateInvoicePDF,
    CREDITNOTE_generateOtherCurrInvoicePDF,
    CREDITNOTE_generateOtherCurrInvoicePDFinInr,
    CREDITNOTE_generateEWayBillPDF,
    CREDITNOTE_ViewEInvoice,
    CREDITNOTE_ViewEWayBill,
    DEBIT_generateInvoicePDF,
    DEBITNOTE_generateOtherCurrInvoicePDF,
    DEBITNOTE_generateOtherCurrInvoicePDFinInr,
    DEBITNOTE_generateEWayBillPDF,
    DEBITNOTE_ViewEInvoice,
    DEBITNOTE_ViewEWayBill,
    SalesManagement,
    Statistics,
    TodaysContacts,
    MyProductionPlan,
    EmployeeProductionPlan,
    ViewProductionPlan,
    ManufacturingOrders,
    BOMInfo,
    ModifyOperation,
    BOM,
    ProductionSchedule,
    Routings,
    RejectedItems,
    RejectedItemsEdit,
    ProPlaStatistics,
    ProOperations,
    WorkstationGroups,
    Workstations,
    CreateWorkstation,
    EditWorkstation,
    CreateWorkstationNote,
    EditWorkstationNote,
    createWorkstationGroup,
    editWorkstationGroup,
    CriticalOnHand,
    FilterTable,
    CustomTable,
    Inventory,
    Items,
    Shipments,
    StockLots,
    StockMovement,
    StockSettings,
    MoveStockItem,
    WriteOffs,
    ProCriticalOnHand,
    Forecasting,
    ProInvoices,
    PurchaseOrders,
    AddPurchaseOrder,
    EditPurchaseOrder,
    CreatePOInvoice,
    UpdatePOInvoice,
    UpdateInvoiceByQC,
    CreateDebitNote,
    UpdateDebitNote,
    Requirements,
    ProStatistics,
    Vendors,
    AccountAndBilling,
    DataMaintenance,
    HR,
    SubUserList,
    TermsAndConditions,
    CreateNewTerms,
    UpdateTerms,
    EditTicket,
    SystemSettings,
    AccountingMaster,
    DemoData,
    FreeUse,
    HelpCenter,

    CreateContactNote,
    CreateContactPerson,
    CreateNewCompany,
    CreateNewOrder,
    EditCustomerOrder,
    ViewCustomerOrder,
    CustomerOrderRevision,
    EditContactNote,
    EditContactPerson,
    EditCustomerCompany,
    CreatePurchaseOrderPayment,
    EditPurchaseOrderPayment,

    CreateCustomTableValue,
    CreateFilterTable,
    CreateItemNotes,
    CreateItem,
    CreateProductGroup,
    CreatePurchaseTerms,
    CreatePurchaseTermsContact,
    CreateStorageLocation,
    CreateUnitOfMeasure,
    CreateLot,
    EditLot,
    importStorage,
    EditItemNotes,
    EditCustomTable,
    CreateItemDocument,
    EditItemDocument,
    EditItem,
    EditProductGroup,
    EditPurchaseTermContact,
    EditPuchaseTerms,
    EditStorageLocation,
    EditUnitOfMeasure,

    AddVendor,
    CreateVendorContact,
    CreateVendorNote,
    EditVendorContact,
    EditVendorNote,
    EditVendor,
    CreatePurchaseOrderNote,
    EditPurchaseOrderNote,
    QualityCheck,
    DebitNote,

    EditAddtionalCurrencies,
    EditCompanyDetails,
    EditSubscriptionPlan,
    CreateCustomerStatus,
    EditCustomerStatus,

    EditDeliveryTerms,
    EditIndustryType,
    EditWriteOffType,
    EditNumberingFormats,
    EditRegionalSettings,
    EditTaxDetails,
    EditOrderStatus,
    EditUserProfile,
    EditHolidays,
    EditQuotationMessage,
    EditExpenseCategories,
    EditBankAccounts,
    CreateInvoice,
    CreateCreditNote,
    EditCreditNote,
    EditInvoice,
    CreateCoNote,
    EditCoNote,

    CreateStockLotNote,
    EditStockLotNote,

    BookCustomerOrderItem,
    AddBooking,
    EditBooking,
    ReleaseStock,
    CreateWriteOff,
    EditWriteOff,

    CreateManufacturingOrder,
    EditManufacturingOrder,

    CreateSubUser,
    UpdateSubUser,

    CreateDepartment,
    EditDepartment,

    CreateParty,
    EditParty,

    CreateBOMOfItem,
    EditBOM,
    CreateRoutingOfItem,
    EditRouting,
    CreateBOM,
    CreateRouting,

    EditCurrency,
    CreateCurrency,

    CreateShipment,
    EditShipment,

    CreateMPPNote,
    EditMPPNote,

    Expenses,
    AddExpenses,
    EditExpenses,
    CreateSupport

} from './theme/container/index'

class App extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            prevDepth: 0,
        }
    }

    componentDidMount(){
        this.setLocation()
    }

    componentWillReceiveProps(){
        this.setLocation()
    }

    setLocation = () => {
        let getUrl = window.location.href;
        let getPath = getUrl.split('/')[1]
        let loc = {
            hash: "",
            pathname: getPath,
            search: "",
            state: undefined
        }
        this.setState({
            prevDepth: this.getPathDepth(loc)
        })
    }

    getPathDepth = (loc) => {
        let pathArr = loc.pathname.split('/');
        pathArr = pathArr.filter(n => n !== '');
        return pathArr.length;
    }
      
    render() {

        const timeout = { enter: 800, exit: 400 }

        return (
            <BrowserRouter>
                <Route
                    render={({ location }) => (
                        <TransitionGroup component="div">
                            <CSSTransition 
                                key={location.pathname.split('/')[1]} 
                                timeout={timeout} 
                                mountOnEnter={false} 
                                unmountOnExit={true}
                                classNames="pageSlider" 
                            >
                                <div className={ this.getPathDepth(location) - this.state.prevDepth >= 0 ? 'left' : 'right' }>
                                    <Switch location={location}>
                                        <Route exact path="/" component={Splash} />
                                        <Route path="/login" component={Login} />
                                        <Route path="/register" component={Register} />
                                        <Route path="/forgotpass" component={ForgotPassword} />
                                        <Route path="/resetpass" component={ResetPassword} />
                                        <Route path="/verifyEmail" component={VerifyEmail} />
                                        
                                        <Route path="/customerorderpdf" component={customerOrderPDF} />
                                        <Route path="/generatequotationpdf" component={generateQuotationPDF} />
                                        <Route path="/productionplanpdf" component={ProductionPlanPDF} />

                                        <Route path="/copdf" component={customerOrderOtherCurrencyPDF} />
                                        <Route path="/quotationpdf" component={customerQuotationOtherCurrencyPDF} />
    
    
                                        <Route path="/customerviewpdf" component={customerViewPDF} />
                                        <Route path="/purchaseorderpdf" component={purchaseOrderPDF} />
                                        <Route path="/purchaseorderother" component={generatePDFForOtherCurrency} />
                                        <Route path="/coinvoicepdf" component={generateInvoicePDF} />
                                        <Route path="/coinvoiceother" component={generateOtherCurrInvoicePDF} />
                                        <Route path="/coinvoiceotherinr" component={generateOtherCurrInvoicePDFinInr} />
                                        <Route path="/sezinvoice" component={generateInvoiceWithSez} />
                                        
                                        <Route path="/home" component={Home} />
                                        
                                        <Route path="/dashboard" component={Dashboard} />
                                        
                                        <Route path="/customerorders" component={CustomerOrders} />
                                        <Route path="/quotations" component={Quotations} />
                                        <Route path="/cashflow" component={CashFlow} />
                                        <Route path="/customers" component={Customers} />
                                        <Route path="/pumpregister" component={PumpRegister} />
                                        <Route path="/pumpmaster" component={PumpMaster} />
                                        <Route path="/createnewpump" component={CreateNewPump} />
                                        <Route path="/editpump" component={EditPump} />

                                        <Route path="/updatemodelno" component={UpdateModelNo} />
                                        <Route path="/updatemainmoc" component={UpdateMainMOC} />
                                        <Route path="/updateimpellermoc" component={UpdateImpellerMOC} />
                                        <Route path="/updateimpellertype" component={UpdateImpellerType} />
                                        <Route path="/updatemechseal" component={UpdateMechSeal} />
                                        <Route path="/updatemotorrating" component={UpdateMotorRating} />
                                        <Route path="/updatedocumentstype" component={UpdatePumpDocumentsType} />

                                        <Route path="/notesearch" component={NoteSearch} />

                                        <Route path="/createcopayment" component={CreateCustomerOrderPayment} />
                                        <Route path="/editcopayment" component={EditCustomerOrderPayment} />

                                        <Route path="/invoices" component={Invoices} />
                                        <Route path="/creditnotes" component={CreditNote} />
                                        
                                        <Route path="/einvoices" component={EInvoices} />
                                        <Route path="/vieweinvoice" component={ViewEInvoice} />
                                        <Route path="/viewewaybill" component={ViewEWayBill} />
                                        <Route path="/printlabel" component={generateLabelPDF} />
                                        <Route path="/ewbpdf" component={generateEWayBillPDF} />

                                        <Route path="/cninvoicepdf" component={CREDITNOTE_generateInvoicePDF} />
                                        <Route path="/cninvoiceother" component={CREDITNOTE_generateOtherCurrInvoicePDF} />
                                        <Route path="/cninvoiceotherinr" component={CREDITNOTE_generateOtherCurrInvoicePDFinInr} />
                                        <Route path="/cnewaybillpdf" component={CREDITNOTE_generateEWayBillPDF} />
                                        <Route path="/cnvieweinvoice" component={CREDITNOTE_ViewEInvoice} />
                                        <Route path="/cnviewewaybill" component={CREDITNOTE_ViewEWayBill} />

                                        <Route path="/dninvoicepdf" component={DEBIT_generateInvoicePDF} />
                                        <Route path="/dninvoiceother" component={DEBITNOTE_generateOtherCurrInvoicePDF} />
                                        <Route path="/dninvoiceotherinr" component={DEBITNOTE_generateOtherCurrInvoicePDFinInr} />
                                        <Route path="/dnewaybillpdf" component={DEBITNOTE_generateEWayBillPDF} />
                                        <Route path="/dnvieweinvoice" component={DEBITNOTE_ViewEInvoice} />
                                        <Route path="/dnviewewaybill" component={DEBITNOTE_ViewEWayBill} />
                                        
                                        <Route path="/salesmanagement" component={SalesManagement} />
                                        <Route path="/statistics" component={Statistics} />
                                        <Route path="/todayscontacts" component={TodaysContacts} />
                                        
                                        <Route path="/myproductionplan" component={MyProductionPlan} />
                                        <Route path="/viewproductionplan" component={ViewProductionPlan} />
                                        <Route path="/productionoperations" component={EmployeeProductionPlan} />
                                        
                                        <Route path="/manufacturingorders" component={ManufacturingOrders} />
                                        <Route path="/bominfo" component={BOMInfo} />
                                        <Route path="/modifyoperation" component={ModifyOperation} />
                                        <Route path="/bom" component={BOM} />
                                        <Route path="/productionschedule" component={ProductionSchedule} />
                                        <Route path="/routings" component={Routings} />
                                        <Route path="/rejecteditems" component={RejectedItems} />
                                        <Route path="/rejecteditemsedit" component={RejectedItemsEdit} />
                                        
                                        <Route path="/planningstatistics" component={ProPlaStatistics} />
                                        <Route path="/prooperations" component={ProOperations} />
                                        <Route path="/workstationgroups" component={WorkstationGroups} />
                                        <Route path="/workstations" component={Workstations} />
                                        <Route path="/createws" component={CreateWorkstation} />
                                        <Route path="/editws" component={EditWorkstation} />
                                        <Route path="/createwsnote" component={CreateWorkstationNote} />
                                        <Route path="/editwsnote" component={EditWorkstationNote} />
                                        <Route path="/createwsg" component={createWorkstationGroup} />
                                        <Route path="/editwsg" component={editWorkstationGroup} />

                                        <Route path="/criticalonhand" component={CriticalOnHand} />
                                        <Route path="/filtertable" component={FilterTable} />
                                        <Route path="/customtable" component={CustomTable} />
                                        <Route path="/inventory" component={Inventory} />
                                        <Route path="/items" component={Items} />
                                        <Route path="/shipments" component={Shipments} />
                                        <Route path="/stocklots" component={StockLots} />
                                        <Route path="/stockmovement" component={StockMovement} />
                                        <Route path="/stocksettings" component={StockSettings} />
                                        <Route path="/writeoffs" component={WriteOffs} />
                                        <Route path="/movestock" component={MoveStockItem} />

                                        <Route path="/procriticalonhand" component={ProCriticalOnHand} />
                                        <Route path="/forecasting" component={Forecasting} />
                                        <Route path="/poinvoices" component={ProInvoices} />
                                        <Route path="/purchaseorders" component={PurchaseOrders} />
                                        <Route path="/requirements" component={Requirements} />
                                        <Route path="/prostatistics" component={ProStatistics} />
                                        <Route path="/vendors" component={Vendors} />
                                        <Route path="/poqc" component={QualityCheck} />
                                        <Route path="/podebitnote" component={DebitNote} />
                                        
                                        

                                        <Route path="/addpo" component={AddPurchaseOrder} />
                                        <Route path="/editpo" component={EditPurchaseOrder} />

                                        <Route path="/createpoinvoice" component={CreatePOInvoice} />
                                        <Route path="/editpoinvoice" component={UpdatePOInvoice} />

                                        <Route path="/editinvoiceqc" component={UpdateInvoiceByQC} />
                                        <Route path="/createdebitnote" component={CreateDebitNote} />
                                        <Route path="/editdebitnote" component={UpdateDebitNote} />                                    

                                        <Route path="/accountandbilling" component={AccountAndBilling} />
                                        <Route path="/datamaintenance" component={DataMaintenance} />
                                        <Route path="/hr" component={HR} />
                                        <Route path="/subuserlist" component={SubUserList} />
                                        <Route path="/terms" component={TermsAndConditions} />
                                        <Route path="/createnewterms" component={CreateNewTerms} />
                                        <Route path="/updateterms" component={UpdateTerms} />
                                        <Route path="/editticket" component={EditTicket} />
                                        <Route path="/systemsettings" component={SystemSettings} />
                                        <Route path="/accountingmaster" component={AccountingMaster} />
                                        
                                        <Route path="/demodata" component={DemoData} />
                                        <Route path="/freeuse" component={FreeUse} />
                                        <Route path="/helpcenter" component={HelpCenter} />

                                        <Route path="/createcontactnote" component={CreateContactNote} />
                                        <Route path="/createcontactperson" component={CreateContactPerson} />
                                        <Route path="/createnewcompany" component={CreateNewCompany} />
                                        <Route path="/createneworder" component={CreateNewOrder} />
                                        <Route path="/editcustomerorder" component={EditCustomerOrder} />
                                        <Route path="/viewcustomerorder" component={ViewCustomerOrder} />
                                        <Route path="/orderrevision" component={CustomerOrderRevision} />
                                        <Route path="/editcontactnote" component={EditContactNote} />
                                        <Route path="/editcontactperson" component={EditContactPerson} />
                                        <Route path="/editcustomercompany" component={EditCustomerCompany} />

                                        <Route path="/createcustomtablevalue" component={CreateCustomTableValue} />
                                        <Route path="/createfiltertable" component={CreateFilterTable} />
                                        <Route path="/createitemnotes" component={CreateItemNotes} />
                                        <Route path="/createitem" component={CreateItem} />
                                        <Route path="/createproductgroup" component={CreateProductGroup} />
                                        <Route path="/createpurchaseterms" component={CreatePurchaseTerms} />
                                        <Route path="/createptc" component={CreatePurchaseTermsContact} />
                                        <Route path="/createstoragelocation" component={CreateStorageLocation} />
                                        <Route path="/createunitofmeasure" component={CreateUnitOfMeasure} />
                                        <Route path="/createlot" component={CreateLot} />
                                        <Route path="/editlot" component={EditLot} />
                                        <Route path="/importstorage" component={importStorage} />
                                        <Route path="/edititemnotes" component={EditItemNotes} />
                                        <Route path="/editcustomtable" component={EditCustomTable} />

                                        <Route path="/createitemdoc" component={CreateItemDocument} />
                                        <Route path="/edititemdoc" component={EditItemDocument} />

                                        <Route path="/edititem" component={EditItem} />
                                        <Route path="/editproductgroup" component={EditProductGroup} />
                                        <Route path="/editpurchasetermcontact" component={EditPurchaseTermContact} />
                                        <Route path="/editpurchaseterms" component={EditPuchaseTerms} />
                                        <Route path="/editstoragelocation" component={EditStorageLocation} />
                                        <Route path="/editunitofmeasure" component={EditUnitOfMeasure} />

                                        <Route path="/createvendor" component={AddVendor} />
                                        <Route path="/createvendorcontact" component={CreateVendorContact} />
                                        <Route path="/createvendornote" component={CreateVendorNote} />
                                        <Route path="/editvendorcontact" component={EditVendorContact} />
                                        <Route path="/editvendornote" component={EditVendorNote} />
                                        <Route path="/editvendor" component={EditVendor} />
                                        <Route path="/createponote" component={CreatePurchaseOrderNote} />
                                        <Route path="/editponote" component={EditPurchaseOrderNote} />


                                        <Route path="/createpopay" component={CreatePurchaseOrderPayment} />
                                        <Route path="/editpopay" component={EditPurchaseOrderPayment} />

                                        <Route path="/editadditionalcur" component={EditAddtionalCurrencies} />
                                        <Route path="/editcompanydetails" component={EditCompanyDetails} />
                                        <Route path="/editsubscriptionplan" component={EditSubscriptionPlan} />
                                        <Route path="/editcustomerstatus" component={EditCustomerStatus} />
                                        <Route path="/createcustomerstatus" component={CreateCustomerStatus} />

                                        <Route path="/editdeliveryterms" component={EditDeliveryTerms} />
                                        <Route path="/editindustrytype" component={EditIndustryType} />
                                        <Route path="/editwriteofftype" component={EditWriteOffType} />
                                        <Route path="/editnumberingformats" component={EditNumberingFormats} />
                                        <Route path="/editregionalsettings" component={EditRegionalSettings} />
                                        <Route path="/edittaxdetails" component={EditTaxDetails} />
                                        <Route path="/editorderstatus" component={EditOrderStatus} />
                                        <Route path="/profile" component={EditUserProfile} />
                                        <Route path="/editholidays" component={EditHolidays} />
                                        <Route path="/editquotmessages" component={EditQuotationMessage} />
                                        <Route path="/editexpensecategories" component={EditExpenseCategories} />
                                        <Route path="/editbankaccounts" component={EditBankAccounts} />

                                        <Route path="/createconote" component={CreateCoNote} />
                                        <Route path="/editconote" component={EditCoNote} />
                                        <Route path="/createcoinvoice" component={CreateInvoice} />
                                        <Route path="/createcreditnote" component={CreateCreditNote} />
                                        <Route path="/editcreditnote" component={EditCreditNote} />
                                        
                                        <Route path="/editcoinvoice" component={EditInvoice} />

                                        <Route path="/createslnote" component={CreateStockLotNote} />
                                        <Route path="/editslnote" component={EditStockLotNote} />

                                        <Route path="/bookcoitem" component={BookCustomerOrderItem} />
                                        <Route path="/addbooking" component={AddBooking} />
                                        <Route path="/editbooking" component={EditBooking} />
                                        <Route path="/releasestock" component={ReleaseStock} />
                                        <Route path="/createwriteoff" component={CreateWriteOff} />
                                        <Route path="/editwriteoff" component={EditWriteOff} />

                                        <Route path="/createmo" component={CreateManufacturingOrder} />
                                        <Route path="/editmo" component={EditManufacturingOrder} />

                                        <Route path="/createsubuser" component={CreateSubUser} />
                                        <Route path="/updatesubuser" component={UpdateSubUser} />

                                        <Route path="/createdepartment" component={CreateDepartment} />
                                        <Route path="/editdepartment" component={EditDepartment} />

                                        <Route path="/createparty" component={CreateParty} />
                                        <Route path="/editparty" component={EditParty} />

                                        <Route path="/createitembom" component={CreateBOMOfItem} />
                                        <Route path="/editbom" component={EditBOM} />

                                        <Route path="/createitemrouting" component={CreateRoutingOfItem} />
                                        <Route path="/editrouting" component={EditRouting} />

                                        <Route path="/createbom" component={CreateBOM} />
                                        <Route path="/createrouting" component={CreateRouting} />


                                        <Route path="/editcurrency" component={EditCurrency} />
                                        <Route path="/createcurrency" component={CreateCurrency} />

                                        <Route path="/createshipment" component={CreateShipment} />
                                        <Route path="/editshipment" component={EditShipment} />

                                        <Route path="/createmppnote" component={CreateMPPNote} />
                                        <Route path="/editmppnote" component={EditMPPNote} />

                                        <Route path="/expenses" component={Expenses} />
                                        <Route path="/createexpense" component={AddExpenses} />
                                        <Route path="/editexpense" component={EditExpenses} />
                                        <Route path="/createsupport" component={CreateSupport} />

                                        <Route component={PageNotFound} />
                                    </Switch>
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    )}
                />
            </BrowserRouter>
        );
    }
}

export default App;