import React from 'react';
import { Link } from 'react-router-dom';

const pages = [
    {
        "name": "Quotations",
        "slug": "CRM_Quotations",
        "to": "/quotations",
        "class": "innerHeaderList",
        "isCustomerOrder": true,
        "hide": false
    },
    {
        "name": "Customer orders",
        "slug": "CRM_CustomerOrders",
        "to": "/customerorders",
        "class": "innerHeaderList",
        "isCustomerOrder": true,
        "hide": false
    },{
        "name": "Customers",
        "slug": "CRM_Customers",
        "to": "/customers",
        "class": "innerHeaderList",
        "isCustomers": true,
        "hide": false
    },{
        "name": "Today's contacts",
        "slug": "CRM_TodaysContacts",
        "to": "/todayscontacts",
        "class": "innerHeaderList",
        "isTodaysContacts": true,
        "hide": false
    },{
        "name": "Pump Register",
        "slug": "CRM_PumpRegister",
        "to": "/pumpregister",
        "class": "innerHeaderList",
        "isPumpRegister": true,
        "hide": false,
        "isPersonalTab": true,
        "accountId": "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
    },{
        "name": "Invoices",
        "slug": "CRM_Invoices",
        "to": "/invoices",
        "class": "innerHeaderList",
        "isInvoices": true,
        "hide": false
    },{
        "name": "Credit Note",
        "slug": "CRM_CreditNotes",
        "to": "/creditnotes",
        "class": "innerHeaderList",
        "isInvoices": true,
        "hide": false
    },{
        "name": "E-Invoices",
        "slug": "CRM_E_Invoices",
        "to": "/einvoices",
        "class": "innerHeaderList",
        "isInvoices": true,
        "hide": true
    },{
        "name": "Cash flow forecast",
        "slug": "",
        "to": "/cashflow",
        "class": "innerHeaderList",
        "isCashflow": true,
        "hide": true
    },{
        "name": "Statistics",
        "slug": "",
        "to": "/statistics",
        "class": "innerHeaderList",
        "isStatistics": true,
        "hide": true
    },{
        "name": "Sales management",
        "slug": "",
        "to": "/salesmanagement",
        "class": "innerHeaderList",
        "isSalesManagement": true,
        "hide": true
    }
]

export default class CRMHeader extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            tabs: [],
            displayTabs: [],
            history: this.props.logout
        }
    }

    componentDidMount(){
        this.checkPermissions()
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if(localPermissions !== null){
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if(permissions !== undefined && permissions.length > 0){
                let updatedRecord = pages.map(obj => {
                    let permit = permissions.find(lo => lo.slug === obj.slug)
                    if(permit !== undefined){
                        obj.to = permit.link
                        return obj
                    }
                });
                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            }else{

                let user = localStorage.getItem('userdata')
                let accountId = ""
                if(user){
                    accountId = JSON.parse(localStorage.getItem('userdata')).account_id
                }

                let updatedRecord = pages.map(obj => {
                    if(obj.isPersonalTab === undefined || (obj.isPersonalTab !== undefined && obj.accountId === accountId)){
                        return obj
                    }
                })

                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            } 
        }
    }

    setDisplayTabs = () => {
        let {
            isQuotations,
            isSalesManagement,
            isStatistics,
            isTodaysContacts,
            isInvoices,
            isCreditNote,
            isEInvoices,
            isCustomers,
            isCustomerOrder,
            isCashflow,
            isPumpRegister
        } = this.props;

        let dispTabs = []

        this.state.tabs.map((data, i) => {
            if(data !== undefined){

                let isActive = ""

                if(data.name === "Quotations" && isQuotations === true)
                    isActive = "active"

                if(data.name === "Customer orders" && isCustomerOrder === true)
                    isActive = "active"

                if(data.name === "Customers" && isCustomers === true)
                    isActive = "active"

                if(data.name === "Today's contacts" && isTodaysContacts === true)
                    isActive = "active"

                if(data.name === "Pump Register" && isPumpRegister === true)
                    isActive = "active"    

                if(data.name === "Invoices" && isInvoices === true)
                    isActive = "active"

                if(data.name === "Credit Note" && isCreditNote === true)
                    isActive = "active"

                if(data.name === "E-Invoices" && isEInvoices === true)
                    isActive = "active"

                if(data.name === "Cash flow forecast" && isCashflow === true)
                    isActive = "active"

                if(data.name === "Statistics" && isStatistics === true)
                    isActive = "active"

                if(data.name === "Sales management" && isSalesManagement === true)
                    isActive = "active"

                dispTabs.push(
                    <Link 
                        key={data.name} 
                        to={data.to} 
                        className={data.hide ? "d-none" : data.class+" "+isActive}
                    >
                        {data.name} 
                    </Link>
                )
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }

    render() {
        
        return(
            <div className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                            <div className="innerHeader">

                                {
                                    this.state.displayTabs
                                }
                                {/* <Link to="/customerorders" 
                                    className={ isCustomerOrder ? "innerHeaderList active" : "innerHeaderList"}>
                                        Customer orders
                                </Link>
                                <Link to="/customers" 
                                    className={isCustomers ? "innerHeaderList active" : "innerHeaderList"}>
                                        Customers
                                </Link>
                                <Link to="/todayscontacts" 
                                    className={isTodaysContacts ? "innerHeaderList active" : "innerHeaderList"}>
                                        Today's contacts
                                </Link>
                                <Link to="/invoices" 
                                    className={isInvoices ? "innerHeaderList active" : "innerHeaderList"}>
                                        Invoices
                                </Link>
                                <Link to="/cashflow" 
                                    className={isCashflow ? "innerHeaderList active" : "innerHeaderList"}>
                                        Cash flow forecast
                                </Link>
                                <Link to="/statistics" 
                                    className={isStatistics ? "innerHeaderList active" : "innerHeaderList"}>
                                        Statistics
                                </Link>
                                <Link to="/salesmanagement" 
                                    className={isSalesManagement ? "innerHeaderList active" : "innerHeaderList"}>
                                        Sales management
                                </Link> */}
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
