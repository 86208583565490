import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../components'
import Strings from './../../../constants/Strings'

class SalesManagement extends Component {
    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isSalesManagement={true} />
                <div className="main-container row bg-white">
                    <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                        <div className="page-title">{Strings.CRM.SalesManagement}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesManagement;