import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'

import * as ExportData from './exportData'

import Pagination from "react-js-pagination";
import Popup from 'reactjs-popup';

import * as APIManager from './../../../APIManager/APIManager'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css'

import * as Im from "react-icons/im";
import * as Fi from "react-icons/fi";
import * as Ai from "react-icons/ai";
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringFormatToMMDDYYObject, convertDateStringToDateAndTimeObject, convertDateTimeStringMMDDtoDDMM } from '../../../constants/DateFormatter';

class ManufacturingOrders extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            number: "",
            groupName: "",
            partNo: "",
            partDescription: "",
            minQuantity: "",
            maxQuantity: "",
            status: "",
            partStatus: "",
            minStartDate: "",
            maxStartDate: "",
            minEndDate: "",
            maxEndDate: "",
            targetLotNumber: "",
            assignedTo: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,

            responseData: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
            total: 0,

           
        }
      

    }

    componentDidMount() {
        this.getFilterData()
       
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            console.log(this.state.responseData);
            const sortedOrders = this.state.responseData.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    console.log(dateA, dateB);
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ responseData: sortedOrders }, () => {
                this.setFilterData()
            });
        });
        
    };
//******************* ascending descending order function end ********************************

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'groupName': this.state.groupName,
            'partNo': this.state.partNo,
            'partDescription': this.state.partDescription,
            'minQuantity': this.state.minQuantity,
            'maxQuantity': this.state.maxQuantity,
            'status': this.state.status,
            'partStatus': this.state.partStatus,
            'minStartDate': this.state.minStartDate !== null ? this.state.minStartDate :"",
            'maxStartDate': this.state.maxStartDate !== null ? this.state.maxStartDate :"",
            'minEndDate': this.state.minEndDate !== null ? this.state.minEndDate :"",
            'maxEndDate': this.state.maxEndDate !== null ? this.state.maxEndDate :"",
            'targetLotNumber': this.state.targetLotNumber,
            'assignedTo': this.state.assignedTo,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,

            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
        }

        localStorage.setItem("productionPlaningManufacturing", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlaningManufacturing"))
        if (filterData !== null) {
            this.setState({
                number: filterData.number,
                groupName: filterData.groupName,
                partNo: filterData.partNo,
                partDescription: filterData.partDescription,
                minQuantity: filterData.minQuantity,
                maxQuantity: filterData.maxQuantity,
                status: filterData.status,
                partStatus: filterData.partStatus,
                minStartDate: filterData.minStartDate ? new Date(filterData.minStartDate) : "",
                maxStartDate: filterData.maxStartDate  ? new Date(filterData.maxStartDate) : "",
                minEndDate: filterData.minEndDate  ? new Date(filterData.minEndDate) : "",
                maxEndDate: filterData.maxEndDate  ? new Date(filterData.maxEndDate) : "",
                targetLotNumber: filterData.targetLotNumber,
                assignedTo: filterData.assignedTo,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage
            }, () => {
                this.getAllMO()
            })
        } else {
            this.getAllMO()

        }
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlaningManufacturing")
        this.setState({
            number: "",
            groupName: "",
            partNo: "",
            partDescription: "",
            minQuantity: "",
            maxQuantity: "",
            status: "",
            partStatus: "",
            minStartDate: "",
            maxStartDate: "",
            minEndDate: "",
            maxEndDate: "",
            targetLotNumber: "",
            assignedTo: "",
            start: 0,
            length: 25,
            activePage: 1
        }, () => {
            this.getAllMO()
        })
    }

    getAllMO = () => {
        let {
            start,
            length,
            number,
            groupName,
            partNo,
            partDescription,
            minQuantity,
            maxQuantity,
            status,
            partStatus,
            minStartDate,
            maxStartDate,
            minEndDate,
            maxEndDate,
            targetLotNumber,
            assignedTo
        } = this.state

        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllMO(start, length, number, groupName, partNo, partDescription, minQuantity, maxQuantity, status, partStatus, convertDateStringToDateAndTimeObject(minStartDate), convertDateStringToDateAndTimeObject(maxStartDate), convertDateStringToDateAndTimeObject(minEndDate), convertDateStringToDateAndTimeObject(maxEndDate), targetLotNumber, assignedTo)
        .then(response => {
            let totalItem = 0

            response.data.map((data, i) => {
                let qty = data.quantity ? parseInt(data.quantity) : 0
                totalItem += qty
            })
            this.setState({
                isLoading: false,
                responseData: response.data,
                totalRecords: response.totalRecords,
                total: totalItem,
            },()=>{
                this.setFilterData()

                if(this.state.fieldName){
                    this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                }
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0
        this.setState({
            isLoading: true,
            activePage: pageNumber,
            start: newStart
        }, () => {
             this.getAllMO() 
        });
    }


    exportAsPDF = () => {
        ExportData.exportPDF(this.state.responseData)
    }
    exportAsCSV = () => {
        ExportData.exportCSV(this.state.responseData)
    }


    addManufacturingOrder = () => {
        let slug = "ProductionPlanning_ManufacturingOrders"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createmo"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editManufacturingOrder = (id) => {

        this.props.history.push({
            pathname: "/editmo",
            state: { MO_Id: id, newBOMEntry: false },
        })

    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isManufacturingOrders={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.ProductionPlanning.ManufactureOrder}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addManufacturingOrder()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)}><span>Number  </span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('name', 'notDate', false)}><span>Group name  </span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partNo', 'notDate', false)} style={{ width: 110 }}><span>Part No. </span> {this.state.fieldName === "partNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partDescription', 'notDate', false)} style={{ width: 210 }}><span>Part description </span> {this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('quantity', 'notDate' , false)} style={{ width: 110 }}><span>Quantity  </span> {this.state.fieldName === "quantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "quantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('status', 'notDate' , false)}><span>Status  </span> {this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partStatus', 'notDate' , false)}><span>Parts status  </span> {this.state.fieldName === "partStatus" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partStatus" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('startDate', 'Date' , false)} style={{ width: 250 }}><span>Start  </span> {this.state.fieldName === "startDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "startDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('finishDate', 'Date' , false)} style={{ width: 250 }}><span>Finish </span> {this.state.fieldName === "finishDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "finishDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('assignedTo', 'notDate' , false)}><span>Assigned to </span> {this.state.fieldName === "assignedTo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "assignedTo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    {/* <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><Fi.FiChevronsDown /></th>} position="bottom right">
                                        <div className="selectColumns">
                                            <ul>
                                                <li><label htmlFor="partno"><span>Number</span> <input type="checkbox" id="partno" /></label></li>
                                            </ul>
                                        </div>
                                    </Popup> */}
                                    <th></th>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addManufacturingOrder()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableElemContain">{/*<Fi.FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type='text' value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => this.getAllMO())} /></th>
                                    <th className="tableElemContain"><input type='text' value={this.state.groupName} onChange={(e) => this.setState({ groupName: e.target.value }, () => this.getAllMO())} /></th>
                                    <th className="tableElemContain"><input type='text' value={this.state.partNo} onChange={(e) => this.setState({ partNo: e.target.value }, () => this.getAllMO())} /></th>
                                    <th className="tableElemContain"><input type='text' value={this.state.partDescription} onChange={(e) => this.setState({ partDescription: e.target.value }, () => this.getAllMO())} /></th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minQuantity} onChange={(e) => this.setState({ minQuantity: e.target.value }, () => { this.getAllMO() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxQuantity} onChange={(e) => this.setState({ maxQuantity: e.target.value }, () => { this.getAllMO() })} />
                                    </th>

                                    <th className="tableElemContain" >
                                        <select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value }, () => { this.getAllMO() })}>
                                            <option value=""></option>
                                            <option value="requested">Requested</option>
                                            <option value="scheduled">Scheduled</option>
                                            <option value="finished">Finished</option>

                                        </select>
                                    </th>

                                    <th className="tableElemContain">
                                        <select value={this.state.partStatus} onChange={(e) => this.setState({ partStatus: e.target.value }, () => this.getAllMO())}>
                                            <option value=""></option>
                                            <option value="requested">Requested</option>
                                            <option value="received">Received</option>

                                        </select>
                                    </th>


                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minStartDate
                                                }
                                                onChange={
                                                    date => this.setState({ minStartDate: date }, () => {
                                                        this.getAllMO()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minStartDate}
                                                selected={
                                                    this.state.maxStartDate
                                                }
                                                onChange={
                                                    date => this.setState({ maxStartDate: date }, () => {
                                                        this.getAllMO()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.minEndDate
                                                }
                                                onChange={
                                                    date => this.setState({ minEndDate: date }, () => {
                                                        this.getAllMO()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>

                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minEndDate}
                                                selected={
                                                    this.state.maxEndDate
                                                }
                                                onChange={
                                                    date => this.setState({ maxEndDate: date }, () => {
                                                        this.getAllMO()
                                                    })
                                                }
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain"><input type='text' value={this.state.assignedTo} onChange={(e) => this.setState({ assignedTo: e.target.value }, () => this.getAllMO())} /></th>

                                    <th className="tableElemContain"></th>
                                    <th onClick={() => this.clearFilter()} className="text-center"><Fi.FiX /> </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><b>Total</b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className='pl-2'><b>{this.state.total}</b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={13} />
                                :
                                <tbody>
                                {
                                    this.state.responseData.map((data, i) => (
                                        <tr onClick={() => this.editManufacturingOrder(data._id)} key={"MO" + i}>
                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                            <td>{data.number.toUpperCase()}</td>
                                            <td>{data.productGroupDetail.name}</td>
                                            <td>{data.partNo}</td>
                                            <td>{data.partDescription}</td>
                                            <td>{data.quantity}</td>
                                            <td>{data.status}</td>
                                            <td>{data.partStatus}</td>
                                            <td>{convertDateTimeStringMMDDtoDDMM(data.startDate)}</td>
                                            <td>{convertDateTimeStringMMDDtoDDMM(data.finishDate)}</td>
                                            <td className="text-lowercase">{data.userDetail?.email}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>

                
            </div>
        );
    }
}

export default ManufacturingOrders;