import React, { Component } from 'react';
import Tickets from './Tickets'
import Header from './../../components/Header'
import Strings from './../../constants/Strings'
import * as APIManager from './../../APIManager/APIManager'

import DatePicker from "react-datepicker";
import "./../../lib/react-datepicker.min.css";
import * as Ai from 'react-icons/ai'

import DragDashboard from './dragUpdate'

class Dashboard extends Component{

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isDashboard={true} />
                <DragDashboard logout={this.props.history} />      
            </div>
        )
    }
}

export default Dashboard;
