
import jsPDF from "jspdf";
import "jspdf-autotable"; 
import { ExportToCsv } from 'export-to-csv';

export const exportCompanyCSV = (responseData, selectedColumns) => {
    // console.log('responseData',responseData);
    const title = "Customers";
    const arrData = Object.keys(selectedColumns).filter(key => selectedColumns[key] === true);

    const headers = [];
    for (let a = 0; a < arrData.length; a++) {
        if (arrData[a] === "col_disp_number") {
            headers.push("Number")
        } else if (arrData[a] === "col_disp_name") {
            headers.push("Name")
        } else if (arrData[a] === "col_disp_industrytype") {
            headers.push("Industry Type")
        }else if (arrData[a] === "col_disp_status") {
            headers.push("Status")
        } else if (arrData[a] === "col_disp_panno") {
            headers.push("Pan No")
        } else if (arrData[a] === "col_disp_gstnum") {
            headers.push("GST Number")
        } else if (arrData[a] === "col_disp_contactstarted") {
            headers.push("Contact Started")
        } else if (arrData[a] === "col_disp_netxtcontact") {
            headers.push("Next Contact")
        } else if (arrData[a] === "col_disp_accmanager") {
            headers.push("Account Manager")
        } else if (arrData[a] === "col_disp_creditlimit") {
            headers.push("Credit Limit")
        } else if (arrData[a] === "col_disp_deliveryterms") {
            headers.push("Delivery Terms")
        } else if (arrData[a] === "col_disp_phone") {
            headers.push("Phone")
        } else if (arrData[a] === "col_disp_email") {
            headers.push("Email")
        } else if (arrData[a] === "col_disp_skype") {
            headers.push("Skype")
        } else if (arrData[a] === "col_disp_web") {
            headers.push("Web")
        }
    }
    const myHeaders = [headers]
    console.log('myHeaders', myHeaders)

    const allData = [];
    let myData = [];
    for (let i = 0; i < responseData.length; i++) {
        myData = [];
        for (let j = 0; j < headers.length; j++) {
            if (headers[j] === "Number") {
                let d = responseData[i].companyId ? responseData[i].companyId : ''
                myData.push(d)
            }
            if (headers[j] === 'Name') {
                let d = responseData[i].nickName ? responseData[i].nickName : ''
                myData.push(d)
            }
            if (headers[j] === 'Industry Type') {
                let d = responseData[i].industryType  ?  responseData[i].industryType : ''
                myData.push(d)
            }
            if (headers[j] === 'Status') {
                let d = responseData[i].statusName ? responseData[i].statusName : ''
                myData.push(d)
            } if (headers[j] === 'Pan No') {
                let d = responseData[i].panNo ? responseData[i].panNo : ''
                myData.push(d)
            }
            if (headers[j] === 'GST Number') {
                let d = responseData[i].gstNo ? responseData[i].gstNo : ''
                myData.push(d)
            }
            if (headers[j] === 'Contact Started') {
                let d = responseData[i].contactStarted ? responseData[i].contactStarted : ''
                myData.push(d)
            }
            if (headers[j] === 'Next Contact') {
                let d = responseData[i].nextContact ? responseData[i].nextContact : ''
                myData.push(d)
            }
            if (headers[j] === 'Account Manager') {
                let d = responseData[i].managers[0] ? responseData[i].managers[0] : ''
                myData.push(d);
            }
            if (headers[j] === 'Credit Limit') {
                let d = responseData[i].creditLimit ? responseData[i].creditLimit : ''
                myData.push(d)
            }
            if (headers[j] === 'Delivery Terms') {
                let d = responseData[i].deliveryTerms ? responseData[i].deliveryTerms : ''
                  myData.push(d);
              }
            if (headers[j] === 'Phone') {
                let d = responseData[i].Phone  ? responseData[i].Phone : ''
                myData.push(d)
            }  if (headers[j] === 'Email') {
                let d = responseData[i].Email  ? responseData[i].Email  : ''
                myData.push(d)
            }
            if (headers[j] === 'Skype') {
                let d = responseData[i].Skype  ? responseData[i].Skype : ''
                myData.push(d)
            }
            if (headers[j] === 'Web') {
                let d = responseData[i].Web  ?  responseData[i].Web : ''
                myData.push(d)
            }  
        }
        allData.push(myData);
    }
    console.log('allData', allData);

    const options = {
        filename: 'customers',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: myHeaders,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(allData);
}

export const exportCompanyPDF = (responseData, selectedColumns) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Customers";
    
    const arrData = Object.keys(selectedColumns).filter(key => selectedColumns[key] === true)
    console.log('-----------', arrData);

    const headers = [];
    for (let a = 0; a < arrData.length; a++) {
        if (arrData[a] === "col_disp_number") {
            headers.push("Number")
        } else if (arrData[a] === "col_disp_name") {
            headers.push("Name")
        } else if (arrData[a] === "col_disp_status") {
            headers.push("Status")
        } else if (arrData[a] === "col_disp_panno") {
            headers.push("Pan No")
        } else if (arrData[a] === "col_disp_gstnum") {
            headers.push("GST Number")
        } else if (arrData[a] === "col_disp_contactstarted") {
            headers.push("Contact Started")
        } else if (arrData[a] === "col_disp_netxtcontact") {
            headers.push("Next Contact")
        } else if (arrData[a] === "col_disp_accmanager") {
            headers.push("Account Manager")
        } else if (arrData[a] === "col_disp_creditlimit") {
            headers.push("Credit Limit")
        } else if (arrData[a] === "col_disp_deliveryterms") {
            headers.push("Delivery Terms")
        } else if (arrData[a] === "col_disp_phone") {
            headers.push("Phone")
        } else if (arrData[a] === "col_disp_email") {
            headers.push("Email")
        } else if (arrData[a] === "col_disp_skype") {
            headers.push("Skype")
        } else if (arrData[a] === "col_disp_web") {
            headers.push("Web")
        } else if (arrData[a] === "col_disp_industrytype") {
            headers.push("Industry Type")
        }
    }
    const myHeaders = [headers]
    console.log('myHeaders', myHeaders)
    console.log(responseData);


    const allData = [];
    // let myData = []
    console.log("length ", headers.length, responseData.length)
    for (let i = 0; i < responseData.length; i++) {
        let myData = []
        for (let j = 0; j < headers.length; j++) {
            if (headers[j] === "Number") {
                let d = responseData[i].companyId
                myData.push(d)
            }
            if (headers[j] === 'Name') {
                let d = responseData[i].nickName
                myData.push(d)
            }
            if (headers[j] === 'Status') {
                let d = responseData[i].statusName
                myData.push(d)
            }
            if (headers[j] === 'Pan No') {
                let d = responseData[i].panNo
                if(d){
                    console.log (' responseData[i].panNo',responseData[i].panNo);
                }else{
                    console.log('nothing found');
                }
                myData.push(d)
            }
            if (headers[j] === 'GST Number') {
                let d = responseData[i].gstNo
                myData.push(d)
            }
            if (headers[j] === 'Contact Started') {
                let d = responseData[i].contactStarted
                myData.push(d)
            }
            if (headers[j] === 'Next Contact') {
                let d = responseData[i].nextContact
                myData.push(d)
            }
            if (headers[j] === 'Account Manager') {
                let d = responseData[i].managers[0];
                myData.push(d);
            }
            if (headers[j] === 'Credit Limit') {
                let d = responseData[i].creditLimit
                myData.push(d)
            }
            if (headers[j] === 'Delivery Terms') {
                let d = responseData[i].deliveryTerms
                myData.push(d)
            }
            if (headers[j] === 'Phone') {
                let d = responseData[i].Phone
                myData.push(d)
            }
            if (headers[j] === 'Skype') {
                let d = responseData[i].Skype
                myData.push(d)
            }
            if (headers[j] === 'Web') {
                let d = responseData[i].Web
                myData.push(d)
            }
            if (headers[j] === 'Industry Type') {
                let d = responseData[i].industryType.toString()
                myData.push(d)
            }
            if (headers[j] === 'Email') {
                let d = responseData[i].Email
                myData.push(d)
            }
        }

        allData.push(myData)
    }

    let content = {
        startY: 50,
        head: myHeaders,
        body: allData
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("customers.pdf")
}


export const exportContactPDF = (responseData, contactsArrayTab) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Contacts";
    // console.log('contactsArrayTab' , contactsArrayTab);
    console.log('responseData' , responseData);

    const arrData = Object.keys(contactsArrayTab).filter(key => contactsArrayTab[key] === true)
    console.log('-----------', arrData);

    const headers = [];
    for (let a = 0; a < arrData.length; a++) {
        if (arrData[a] === "col_disp_cnumber") {
            headers.push("Number")
        } else if (arrData[a] === "col_disp_cname") {
            headers.push("Name")
        }  else if (arrData[a] === "col_disp_cphone") {
            headers.push("Phone")
        } else if (arrData[a] === "col_disp_cemail") {
            headers.push("E-mail")
        } else if (arrData[a] === "col_disp_contactsname") {
            headers.push("Contact's Name")
        } else if (arrData[a] === "col_disp_contactsposition") {
            headers.push("E-Contact's Position")
        } else if (arrData[a] === "col_disp_contactsemail") {
            headers.push("Contact's E-mail")
        } else if (arrData[a] === "col_disp_contactsphone") {
            headers.push("Contact's  Phone")
        } 
    }
    const myHeaders = [headers]
    console.log('myHeaders', myHeaders)
    console.log(responseData);


    const allData = [];
    // let myData = []
    console.log("length ", headers.length, responseData.length)
    for (let i = 0; i < responseData.length; i++) {
        let myData = []
        for (let j = 0; j < headers.length; j++) {
            if (headers[j] === "Number") { 
                let d = responseData[i].companyId ? responseData[i].number: ''
                myData.push(d)
            }
            if (headers[j] === 'Name') {
                let d = responseData[i].name ?  responseData[i].name : ''
                myData.push(d)
            }
            if (headers[j] === 'Phone') {
                let d = responseData[i].companyPhone ? responseData[i].companyPhone :''
                myData.push(d)
            }
            if (headers[j] === 'E-mail') {
                let d = responseData[i].companyEmail ? responseData[i].companyEmail: ''
                myData.push(d)
            }
            if (headers[j] === "Contact's Name") {
                let d = responseData[i].contactName ? responseData[i].contactName: ''
                myData.push(d)
            }
            if (headers[j] === "E-Contact's Position") {
                let d = responseData[i].contactPosition? responseData[i].contactPosition:''
                myData.push(d)
            }
            if (headers[j] === "Contact's E-mail") {
                let d = responseData[i].Email ? responseData[i].Email: ''
                myData.push(d);
            }
            if (headers[j] ==="Contact's  Phone") {
                let d = responseData[i].Phone ? responseData[i].Phone: ''
                myData.push(d)
            }
        }

        allData.push(myData)
    }
console.log(allData);

    // const headers = [[
    //     "Number",
    //     "Name",
    //     "Status",
    //     "Phone",
    //     "Email",
    //     "Contact's name",
    //     "Contact's position",
    //     "Contact's phone",
    //     "Contact's e-mail",
    // ]];

    // const data = responseData.map((elt, i) => [
    //     elt.number,
    //     elt.name,
    //     elt.statusName,
    //     elt.Phone,
    //     elt.Email,
    //     elt.contactName,
    //     elt.contactPosition,
    //     elt.Phone === undefined ? "" : elt.Phone,
    //     elt.Email === undefined ? "" : elt.Email,
    // ]);

    let content = {
        startY: 50,
        head: myHeaders,
        body: allData
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("customers-contact.pdf")
}


export const exportContactCSV = (responseData, contactsArrayTab) => {

    const title = "Contacts";
    const arrData = Object.keys(contactsArrayTab).filter(key => contactsArrayTab[key] === true)
    // console.log('-----------', arrData);

    const headers = [];
    for (let a = 0; a < arrData.length; a++) {
        if (arrData[a] === "col_disp_cnumber") {
            headers.push("Number")
        } else if (arrData[a] === "col_disp_cname") {
            headers.push("Name")
        } else if (arrData[a] === "col_disp_cstatus") {
            headers.push("Status")
        } else if (arrData[a] === "col_disp_cphone") {
            headers.push("Phone")
        } else if (arrData[a] === "col_disp_cemail") {
            headers.push("E-mail")
        } else if (arrData[a] === "col_disp_contactsname") {
            headers.push("Contact's Name")
        } else if (arrData[a] === "col_disp_contactsposition") {
            headers.push("E-Contact's Position")
        } else if (arrData[a] === "col_disp_contactsemail") {
            headers.push("Contact's E-mail")
        } else if (arrData[a] === "col_disp_contactsphone") {
            headers.push("Contact's  Phone")
        } 
    }
    const myHeaders = [headers]
    console.log('myHeaders', myHeaders)
    console.log(responseData);


    const allData = [];
    // let myData = []
    console.log("length ", headers.length, responseData.length)
    for (let i = 0; i < responseData.length; i++) {
        let myData = []
        for (let j = 0; j < headers.length; j++) {
            if (headers[j] === "Number") {
                let d = responseData[i].companyId ?responseData[i].companyId :''
                myData.push(d)
            }
            if (headers[j] === 'Name') {
                let d = responseData[i].name ?  responseData[i].name:''
                myData.push(d)
            }
            if (headers[j] === 'Status') {
                let d = responseData[i].status ? responseData[i].status : ""
                myData.push(d)
            }
            if (headers[j] === 'Phone') {
                let d = responseData[i].companyPhone ? responseData[i].companyPhone: ''
                myData.push(d)
            }
            if (headers[j] === 'E-mail') {
                let d = responseData[i].companyEmail ? responseData[i].companyEmail: ''
                myData.push(d)
            }
            if (headers[j] === "Contact's Name") {
                let d = responseData[i].contactName ? responseData[i].contactName :""
                myData.push(d)
            }
            if (headers[j] === "E-Contact's Position") {
                let d = responseData[i].contactPosition ? responseData[i].contactPosition : ''
                myData.push(d)
            }
            if (headers[j] === "Contact's E-mail") {
                let d = responseData[i].Email ? responseData[i].Email: ''
                myData.push(d);
            }
            if (headers[j] ==="Contact's  Phone") {
                let d = responseData[i].Phone ? responseData[i].Phone: ''
                myData.push(d)
            }
        }

        allData.push(myData)
    }


    const options = {
        filename: 'customers-contacts',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: myHeaders,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(allData);
}
