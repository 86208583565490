const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
const checkNegativeOrPositiveRegex = /^-?\d*\.?\d{0,6}$/;

export const validateEmail = email => {
    if(!emailRegex.test(email)){
        return false
    }else{
        return true
    }
}

export const validatePassword = password => {
    if(!passwordRegex.test(password)){
        return false
    }else{
        return true
    }
}

export const validateBlank = val => {
    if(val.trim().length > 0){
        return true
    }else{
        return false
    }
}



export const validateMobileNumber = val => {
    if(val.length > 10) {
        return false
    }else{
        return true
    }
}

export const validateNumber = val => {
    // if(!numberRegex.test(val)){
     if(isNaN(val)){
         return false
     }else{
         return true
     }
 }

export const validatePercentage = val => {
     if(isNaN(val)){
         return false
     }else if(val < 0 || val > 100){
         return false
     }else{
         return true
     }
 }
 
 export const validateNegativeOrPositive = val => {
    return (val.match(checkNegativeOrPositiveRegex) !== null);
 }