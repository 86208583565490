import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as Fi from 'react-icons/fi'
import FlatList from 'flatlist-react';
import * as APIManager from './../../../APIManager/APIManager'
import Pagination from "react-js-pagination";

const settings = [{
    text: "Users", id: "Users"
}, {
    text: "Departments", id: "Departments"
},
    // {text: "Planning", id: "Planning" }
]

export default class HumanResources extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "Users",
            subUserResponseData: [],
            departmentData: [],

            departmentNumber: "",
            departmentName: "",


            username: "",
            email: "",
            designation: "",
            departments: "",

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

        };
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount() {

        // let getSelectedIndex = localStorage.getItem('human_resources_selected_index')
        // if (getSelectedIndex === "" || getSelectedIndex === null) {
        //     this.setState({
        //         selectedIndex: 'Users',
        //     })
        // } else {
        //     this.setState({
        //         selectedIndex: getSelectedIndex,
        //     })
        // }

        // this.getUserList()
        // this.getDepartment()

        this.getFilterData()
    }

    getUserList = () => {
        let {
            username,
            email,
            designation,
            departments,
            start,
            length
        } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserListWithFilter(username, email, designation, departments, start, length)
            .then(response => {
                this.setState({
                    isLoading: false,
                    subUserResponseData: response.data,
                    totalRecords: response.totalRecord
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });

            this.setFilterData()
    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getUserList()
        });
    }

    editUserDetail = (_id, email) => {
        localStorage.setItem("subuserId", _id)
        localStorage.setItem("subuserEmail", email)
        this.props.history.push("/updatesubuser")
    }

    renderSettings = (settings, index) => {
        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts'} onClick={() => {
                this.setState({
                    selectedIndex: index,
                }, () => {
                    localStorage.setItem('human_resources_selected_index', index);
                });
            }}
            >
                {settings.text}
            </div>
        );
    }

    getDepartment = () => {
        let {
            departmentNumber,
            departmentName
        } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment(departmentNumber, departmentName)
            .then(response => {
                this.setState({
                    isLoading: false,
                    departmentData: response.data
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });

            this.setFilterData()
    }


    setFilterData = () => {

        let filterDataArray = {
            'activePage': this.state.activePage,
            'isLoading': this.state.isLoading,
            'username': this.state.username,
            'email': this.state.email,
            'designation': this.state.designation,
            'departments': this.state.departments,
            'start': this.state.start,
            'length': this.state.length,
        }

        let departmentDataArray = {
            'departmentNumber': this.state.departmentNumber,
            'departmentName': this.state.departmentName

        }
        localStorage.setItem("departmentsSetting", JSON.stringify(departmentDataArray))
        localStorage.setItem("humanResources", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("humanResources"))
        let departmentData = JSON.parse(localStorage.getItem("departmentsSetting"))

        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                isLoading: true,
                username: filterData.username,
                email: filterData.email,
                designation: filterData.designation,
                departments: filterData.departments,
                start: filterData.start,
                length: filterData.length,

                departmentNumber: departmentData.departmentNumber,
                departmentName: departmentData.departmentName

            }, () => {

                let getSelectedIndex = localStorage.getItem('human_resources_selected_index')
                if (getSelectedIndex === "" || getSelectedIndex === null) {
                    this.setState({
                        selectedIndex: 'Users',
                    })
                } else {
                    this.setState({
                        selectedIndex: getSelectedIndex,
                    })
                }

                this.getUserList()
                this.getDepartment()

            })
        } else {



            let getSelectedIndex = localStorage.getItem('human_resources_selected_index')
            if (getSelectedIndex === "" || getSelectedIndex === null) {
                this.setState({
                    selectedIndex: 'Users',
                })
            } else {
                this.setState({
                    selectedIndex: getSelectedIndex,
                })
            }

            this.getUserList()
            this.getDepartment()
        }
    }


    clearFilter = () => {
        localStorage.removeItem("humanResources")
        this.setState({
            username: "",
            email: "",
            designation: "",
            departments: "",
            start: 0,
            length: 1,
            activePage: 1
        }, () => {
            this.getUserList()
        })
    }

    clearSearch = () => {
        localStorage.removeItem("humanResources")
        this.setState({
            departmentNumber: "",
            departmentName: "",
        }, () => {
            this.getDepartment()
        })
    }

    editDeptData = (id) => {
        this.props.history.push({
            pathname: '/editdepartment',
            state: { departmentId: id, newDepartmentEntry: false }
        })
    }

    createSubUser = () => {
        let slug = "Settings_HumanResources"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createsubuser"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    createDepartment = () => {
        let slug = "Settings_HumanResources"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createdepartment"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }


    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isSettings={true} />
                <SettingsHeader isHR={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{this.state.selectedIndex}</div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent">
                        <div className="datamenus">
                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>
                        {
                            this.state.selectedIndex === "Users" ?
                                <div className="dataDetails">
                                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>
                                        <table>
                                            <thead>
                                                <tr className="no-bg">
                                                    <th className="tableHeader tableHeadButton" style={{ width: 30 }}><div onClick={() => this.createSubUser()}><Fi.FiPlus /></div></th>
                                                    <th className="tableHeader">Name</th>
                                                    <th className="tableHeader">Email</th>
                                                    <th className="tableHeader">Designation</th>
                                                    <th className="tableHeader tableHeadButton text-right"><div onClick={() => this.createSubUser()} ><Fi.FiPlus /></div></th>
                                                </tr>
                                                <tr className="no-bg">
                                                    <th className="tableHeader tableHeadButton" style={{ width: 30 }}></th>
                                                    <th className="tableElemContain" style={{ width: 250 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                            value={this.state.username}
                                                            onChange={(e) => this.setState({ username: e.target.value }, () => { this.getUserList() })}
                                                        />
                                                    </th>
                                                    <th className="tableElemContain">
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                            value={this.state.email}
                                                            onChange={(e) => this.setState({ email: e.target.value }, () => { this.getUserList() })}
                                                        />
                                                    </th>
                                                    <th className="tableElemContain" style={{ width: 250 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                            value={this.state.designation}
                                                            onChange={(e) => this.setState({ designation: e.target.value }, () => { this.getUserList() })}
                                                        />
                                                    </th>
                                                    <th style={{ width: 40 }} className="tableElemContain tableAddButton text-right"><div onClick={() => this.clearFilter()}><Fi.FiX /></div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.subUserResponseData !== null &&
                                                    this.state.subUserResponseData.map((data, i) => (
                                                        <tr key={data._id} onClick={() => this.editUserDetail(data._id, data.email)}>
                                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                            <td>{data.full_name ? data.full_name : data.username}</td>
                                                            <td className="text-lowercase">{data.email}</td>
                                                            <td className="text-capitalize">{data.user_designation}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div>
                                            <div className="float-left mt-10px">
                                                <div className="tableElemContain" style={{ width: 80 }}>
                                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                                        <option value="25">25 rows</option>
                                                        <option value="50">50 rows</option>
                                                        <option value="100">100 rows</option>
                                                        <option value="">All rows</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="float-right mt-10px">
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                                    totalItemsCount={this.state.totalRecords}
                                                    pageRangeDisplayed={10}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                :
                                this.state.selectedIndex === "Departments" ?
                                    <div className="stocksetting dataDetails">
                                        <div style={{ overflowX: "auto" }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="tableHeader tableHeadButton" style={{ width: 40 }}><div onClick={() => this.createDepartment()}><Fi.FiPlus /></div></th>
                                                        <th className="tableHeader" style={{ width: 100 }}><span>Number</span></th>
                                                        <th className="tableHeader"><span>Name</span></th>
                                                        <th className="tableHeader"></th>
                                                        <th className="tableHeader tableHeadButton"><div onClick={() => this.createDepartment()}><Fi.FiPlus /></div></th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: 40 }} className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                                        <th style={{ width: 100 }} className="tableElemContain"><input type="text" value={this.state.departmentNumber} onChange={(e) => this.setState({ departmentNumber: e.target.value }, () => { this.getDepartment() })} /></th>
                                                        <th className="tableElemContain"><input type="text" value={this.state.departmentName} onChange={(e) => this.setState({ departmentName: e.target.value }, () => { this.getDepartment() })} /></th>
                                                        <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.getDepartment()}><Fi.FiSearch /></div></th>
                                                        <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.clearSearch()}><Fi.FiX /></div></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state.departmentData !== null &&
                                                        this.state.departmentData.map((data, i) => (
                                                            <tr key={data._id} onClick={this.editDeptData.bind(this, data._id)}>
                                                                <td style={{ width: 40 }}>{i + 1}</td>
                                                                <td style={{ width: 100 }}>{data.number.toUpperCase()}</td>
                                                                <td>{data.name}</td>
                                                                <td style={{ width: 40 }}></td>
                                                                <td style={{ width: 40 }}></td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    this.state.selectedIndex === "Planning" ?
                                        <div className="dataDetails">
                                            <div className="detailsHead">Planning</div>
                                            <div className="clear"></div>
                                        </div>
                                        :
                                        null
                        }
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}