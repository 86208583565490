import React from 'react';
import Header from './Header'
import Tabs from './Tabs'


// CRM
// My Production Plan
// Production Planning
// Stock
// Procurement
// Settings


    const pages = [{
        name: "Dashboard",
        uniqueId: "dashboard",
        link: "dashboard",
        permission: true
    },{
        name: "CRM",
        uniqueId: "crm",
        link: "quotations",
        permission: true
    },{
        name: "My Production Plan",
        uniqueId: "mpp",
        link: "myproductionplan",
        permission: true
    },{
        name: "Production Planning",
        uniqueId: "proplan",
        link: "manufacturingorders",
        permission: true
    },{
        name: "Stock",
        uniqueId: "stock",
        link: "items",
        permission: true
    },{
        name: "Procurement",
        uniqueId: "procurement",
        link: "purchaseorders",
        permission: true
    },{
        name: "Accounting",
        uniqueId: "accounting",
        link: "expenses",
        permission: true
    },{
        name: "Settings",
        uniqueId: "settings",
        link: "systemsettings",
        permission: true
     },{
        name: "Help Center",
        uniqueId: "helpcenter",
        link: "helpcenter",
        permission: false
    }
    //,{
    //     name: "Demo Data & Videos",
    //     uniqueId: "demodata",
    //     link: "demodata",
    //     permission: false
    // },{
    //     name: "Free Use",
    //     uniqueId: "freeuse",
    //     link: "freeuse",
    //     permission: false
    // }
    ]

class Home extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            tabs: [],
            displayTabs: [],
        }
    }

    componentDidMount(){
        this.checkLoginStatus()
    }

    checkLoginStatus = () => {
        if(localStorage.getItem('userdata') === null){
            this.props.history.push('login')
        }else{
            let permissions = localStorage.getItem("permissions")
            if(permissions !== null){   
                let parsedPer = JSON.parse(localStorage.getItem("permissions"))
                if(parsedPer.length === 1){
                    let isWorker = parsedPer.find(o => o.slug === 'ProductionOperations');
                    if(isWorker === undefined){
                        this.getUserDetails()
                    }else{
                        this.props.history.replace('/productionoperations')
                    }
                }else{
                    this.getUserDetails()
                }  
            }
        }
    }


    getUserDetails = () => {
        let response =  JSON.parse(localStorage.getItem('userdata'))
        if(response.role === "admin"){
            this.setState({
                tabs: pages
            },()=>{
                this.setDisplayTabs()
            })
        }else{
            this.checkPermissions()
        }
           
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if(localPermissions !== null){
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if(permissions !== undefined && permissions.length > 0){
                let updatedRecord = pages.map(obj => {
                    if(obj.permission === true){
                        let permit = permissions.find(lo => lo.group === obj.name)
                        if(permit !== undefined){
                            obj.link = permit.link
                            return obj
                        }  
                    }else{
                        return obj
                    }
                });

                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            }else{
                let updatedRecord = pages.map(obj => {
                    if(obj.permission === false){
                        return obj
                    }
                });
                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            }
        }       
    }

    setDisplayTabs = () => {
        let dispTabs = []
        this.state.tabs.map((data, i) => {
            if(data !== undefined){
                dispTabs.push(<Tabs 
                    num={i+1}
                    key={data.uniqueId}
                    uniqueId={data.uniqueId}
                    name={data.name}
                    link={data.link}
                />)
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }

    render() {
        return (
            <div className="page">
                <Header history={this.props.history}/>
                <div className="home-main-container">
                    <div className="row">
                        {
                            this.state.displayTabs
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;