import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../components'
import RichTextEditor from 'react-rte';
import Loading from './../../../../helper/loading'

import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';

import * as Ai from "react-icons/ai";
import * as APIManager from './../../../../APIManager/APIManager'

import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css'

import AddTermsAndConditions from '../../../../components/AutoGenerateComponent/AddTermsAndConditions';

import ProductGroup from './GroupWithTax/ProductGroup';
import ProductGroupWithOtherCurrency from './GroupWithOtherCurrency/ProductGroup';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class AddPurchaseOrder extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            vendorList: [],
            vendorContacts: [],
            isVendorSelected: false,
            vendorPaymentPeriod: 0,
            
            vendor: "",
            contactPerson: "",
            status: "new po",
            freeText: "",
            expectedDate: "",
            dueDate: "",
            quotationNo: "",
            quotationDate: "",
            orderId: "",
            orderDate: "",
            invoiceId: "",
            invoiceDate: "",
            paymentDate: "",
            shippedOn: "",
            arrivalDate: "",
            reference: "",
            rfqDate: "",
            rfqNo: "",

            purchaseOrderMessageEditor: RichTextEditor.createEmptyValue(),
            purchaseOrderMessage: "",

            purchaseOrderFooterMessageEditor: RichTextEditor.createEmptyValue(),
            purchaseOrderFooterMessage: "",

            // Product Group Final Data
            totalOfProductCostFinal: 0,
            totalOfRawCostFinal: 0,
            totalOfRawCostInOther: 0,
            productGroupData: [],


            // Terms & Conditions
            countTermsNumber: [],
            TermsCounter: 0,
            termsFields: [],
            termsAndConditions: [],
            generateNewTerm: true,

            currency: "",
            conversionDetail: "",
        }
    }

    componentDidMount(){
        this.getVendors()
        this.setDefaultTerms()
    }

    getVendors = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetVendor("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                vendorList: response.data,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
               // error: true,
               // errormessage: errors 
            })
        });   
    }

    setDefaultTerms = () => {
        APIManager.callAllTermsCondition("","purchaseOrder","")
        .then(response => {
            let termsCondition = []
            for(let i = 0; i < response.length; i++)
            {
                for(let j = 0; j < response[i].option.length; j++){
                    if(response[i].option[j].is_default !== undefined && response[i].option[j].is_default === true){
                       let obj = {
                            "terms": response[i]._id,
                            "options": response[i].option[j].terms
                       } 
                       termsCondition.push(obj)
                    }
                }
            }
            this.setTermsConditions(termsCondition)

            this.setState({ 
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setTermsConditions = (resp) => {
        let counterArray = []
        let termFields = [];
        for(let i=0;i<resp.length;i++){
            counterArray.push(i)
            termFields.push(
                <AddTermsAndConditions 
                    key={i}
                    componentNum={i}
                    term={resp[i].terms}
                    condition={resp[i].options}
                    termsFor={"purchaseOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            )
        }

        this.setState({
            termsFields: termFields,
            countTermsNumber: counterArray,
            TermsCounter: resp.length,
            termsAndConditions: resp
        },()=>{
            this.setTerms()
        })
    }

   
    getSingleVendor = (id) => {
        if(id !== ""){
            this.setState({ isLoading: true, error: false, isVendorSelected: false, })
            APIManager.callVendorDetail(id)
            .then(response => {
                this.setState({ 
                    productGroupFields: [],
                    productGroupKeys: [],
                    productGroupCounter: 0,
                    productGroupData: [],
                    totalOfProductCost: 0.00,
                    totalOfProductCostFinal: 0.00,
                    totalOfRawCostFinal: 0.00,
                    totalNoOfProduct: 0,
                    vendorContacts: typeof(response.contacts) !== 'undefined' ? response.contacts : [],
                    vendorApplicableTax: response.taxApplicable !== undefined ? response.taxApplicable : [],
                    isVendorSelected: true,
                    vendorPaymentPeriod: response.paymentPeriods,
                    currency: response.currency,
                    conversionDetail: response.conversionDetail,
                    isLoading: false
                },()=>{
                    this.countExpectedAndPaymentDate(response)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            }); 
        }  
    }

    countExpectedAndPaymentDate = (res) => {

        var startDate = new Date();

        if(res.defaultLeadTime !== null){       
            var endDate = "", noOfDaysToAdd = res.defaultLeadTime, count = 0;
            while(count < noOfDaysToAdd){
                endDate = new Date(startDate.setDate(startDate.getDate() + 1));
                if(endDate.getDay() !== 0 && endDate.getDay() !== 6){
                    count++;
                }
            }
            this.setState({
                expectedDate: endDate
            })
        }else{
            this.setState({
                expectedDate: new Date()
            })
        }
    } 


  
    /* Terms & Conditions all counting */

    setTerms = () => {
        let counter = this.state.TermsCounter;
        this.setState({
            termsFields: this.state.termsFields.concat([
                <AddTermsAndConditions 
                    key={counter}
                    componentNum={counter}
                    term={""}
                    condition={""}
                    termsFor={"purchaseOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            ]),
            countTermsNumber: this.state.countTermsNumber.concat([ counter ]),
        },()=>{
            this.setState({
                TermsCounter: counter + 1
            })
        })
    }


    termsFieldChange = (term,condition,number) => {

        let array = [...this.state.countTermsNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.termsAndConditions]        

        if (typeof CIVal[index] !== 'undefined') {
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                let termsAndConditions = [ ...this.state.termsAndConditions ];
                termsAndConditions[index] = obj;

                this.setState({
                    termsAndConditions,
                })
            }
        }else{
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                this.setState({
                    termsAndConditions: this.state.termsAndConditions.concat([ obj ])
                },()=>{
                    this.setTerms()
                })
            }
        }
    }

    termsFieldRemove = (number) => {
        let array = [...this.state.countTermsNumber];
        let fields = [...this.state.termsFields];
        let data = [...this.state.termsAndConditions]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            // fields.splice(index, 1)
            this.setState({
                countTermsNumber: array,
                termsFields: fields,
                termsAndConditions: data
            });
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    createOrder = () => {

        let {
            vendor,
            contactPerson,
            status,
            reference,
            rfqNo,
            rfqDate,
            dueDate,
            quotationNo,
            quotationDate,
            orderId,
            orderDate,
            invoiceId,
            invoiceDate,
            paymentDate,
            shippedOn,
            arrivalDate,
            purchaseOrderMessage,
            purchaseOrderFooterMessage,
            expectedDate,
            totalOfProductCostFinal,
            totalOfRawCostFinal,
            termsAndConditions,
            productGroupData,
            totalOfRawCostInOther
        } = this.state;

        let localExpectedDate = convertDateToMMDDYYYY(expectedDate)

        let productGroupDataRaw = [];
        productGroupData.forEach((val) => {
            if(val !== null){
                productGroupDataRaw.push(val);
            }
        });


        /* GET OLDEST DATE FROM PRODUCT GROUP - CODE START */
            
        let groupDateArray = []
        let dt = "";
        let newDate = "";

        let currentDate = this.state.expectedDate
        if(!currentDate){
            let cDate = new Date(new Date());
            currentDate = new Date(cDate.setDate(cDate.getDate() + 1));
        }
        

        var dd = String(currentDate.getDate()).padStart(2, '0');
        var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = currentDate.getFullYear();

        currentDate = mm + '/' + dd + '/' + yyyy;

        productGroupDataRaw.map((data) => (
            dt = (data.expectedDate) ? data.expectedDate : currentDate,
            newDate = dt,
            groupDateArray.push(newDate)
        ))
        let oldestDate = "";
        if(groupDateArray.length > 0){
            oldestDate = groupDateArray.reduce(function (pre, cur) {
                return Date.parse(pre) > Date.parse(cur) ? pre : cur;
            });
        }

        if(oldestDate !== currentDate){
            localExpectedDate = oldestDate;
        }

        /* GET OLDEST DATE FROM PRODUCT GROUP - CODE END */

        if(vendor === ""){
            this.setState({
                error: true,
                errormessage: "Please select vendor"
            },()=>{
                this.scrollToTop()
            })
        }else if(status === ""){
            this.setState({
                error: true,
                errormessage: "Please select status"
            },()=>{
                this.scrollToTop()
            })
        }else if(productGroupDataRaw.length === 0){
            this.setState({
                error: true,
                errormessage: "Please Add Purchase Item(s)"
            },()=>{
                this.scrollToTop()
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callAddPurchaseOrder(vendor,contactPerson,status,reference,rfqNo,convertDateToMMDDYYYY(rfqDate),convertDateToMMDDYYYY(dueDate),quotationNo,convertDateToMMDDYYYY(quotationDate),orderId,convertDateToMMDDYYYY(orderDate),invoiceId,convertDateToMMDDYYYY(invoiceDate),convertDateToMMDDYYYY(paymentDate),convertDateToMMDDYYYY(shippedOn),convertDateToMMDDYYYY(arrivalDate),purchaseOrderMessage,purchaseOrderFooterMessage,localExpectedDate,totalOfProductCostFinal,totalOfRawCostFinal,JSON.stringify(termsAndConditions),JSON.stringify(productGroupData),totalOfRawCostInOther)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    localStorage.setItem("createNewPurchaseOrder", true)
                    localStorage.setItem("purchase_order_id",response.data._id)

                    this.props.history.push({
                        pathname: "/editpo",
                        state: { purchase_order_id: response.data._id, purchase_order_number: "" }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                },()=>{
                    this.scrollToTop()
                })
            });   
        }
    }

    setDataFromGroup = (totalOfProductCostFinal,totalOfRawCostFinal,productGroupData) => {
        this.setState({
            totalOfProductCostFinal: totalOfProductCostFinal,
            totalOfRawCostFinal: totalOfRawCostFinal,
            productGroupData: productGroupData
        })
    }

    setDataOtherCurrencyFromGroup = (totalOfProductCostFinal,totalOfRawCostFinal,totalOfRawCostInOther,productGroupData) => {
        this.setState({
            totalOfProductCostFinal: totalOfProductCostFinal,
            totalOfRawCostFinal: totalOfRawCostFinal,
            productGroupData: productGroupData,
            totalOfRawCostInOther: totalOfRawCostInOther
        })
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    onpurchaseOrderMessageChange = (value) => {
        this.setState({
            purchaseOrderMessageEditor: value,
            purchaseOrderMessage: value.toString('html')
        });
    }

    onpurchaseOrderFooterMessageChange = (value) => {
        this.setState({
            purchaseOrderFooterMessageEditor: value,
            purchaseOrderFooterMessage: value.toString('html')
        });
    }


    addNewVendor = () => {
       // this.props.history.push('/')
    }

    updateContact = (id) => {
        this.setState({
            contactPerson: id
        })
    }

    addNewContact = () => {
       // this.props.history.push('/')
    }

    setPaymentDate = () => {
        let { invoiceDate } = this.state
        
        if(invoiceDate !== ""){
            var numberOfDaysToAdd = this.state.vendorPaymentPeriod;
            
            var newPDate = new Date(invoiceDate.getTime()+(numberOfDaysToAdd*24*60*60*1000));

            this.setState({
                paymentDate: newPDate
            })
        }
    }

    

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isPurchaseOrders={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Create a purchase order</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="add_btnSave" onClick={()=>this.createOrder()}>Save</div>
                            <div className="clear"></div>
                        </div>

                        <div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Vendor*</div>
                                    <div className="value">
                                        <Select2
                                            data={
                                                this.state.vendorList.map((data) => (
                                                    { id: data._id, text: data.name }
                                                ))
                                            }
                                            value={this.state.vendor}
                                            options={{placeholder: 'Select Vendor'}}
                                            onSelect={(e)=> this.setState({ vendor: e.target.value },()=>{ this.getSingleVendor(e.target.value) })}
                                        />

                                       
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Contact person</div>
                                    <div className="value">
                                        <select className="text-capitalize" onChange={(e)=>this.setState({ contactPerson: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                this.state.vendorContacts.map((data, i) => (
                                                   <option key={data.contactId} value={data.contactId}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                        
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Status</div>
                                    <div className="value">
                                        <select onChange={(e)=>this.setState({ status: e.target.value })} value={this.state.status}>
                                            <option value="">Select</option>
                                            <option value="new po">New PO</option>
                                            {/* <option value="rfq">RFQ</option> */}
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Reference</div>
                                    <div className="value"><input type="text" value={this.state.reference} onChange={(e)=>this.setState({ reference: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">RFQ no.</div>
                                    <div className="value"><input type="text" value={this.state.rfqNo} onChange={(e)=>this.setState({ rfqNo: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">RFQ date</div>
                                    <div className="value">
                                        <DatePicker
                                        dateFormat="dd/MM/yyyy" 
                                        maxDate={new Date()}
                                        selected={
                                            this.state.rfqDate
                                        }
                                        onChange={
                                            date => this.setState({ rfqDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Expected Delivery date*</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.expectedDate}
                                            onChange={date => this.setState({ expectedDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Valid till</div>
                                    <div className="value">
                                        <DatePicker
                                        dateFormat="dd/MM/yyyy" 
                                        minDate={new Date()}
                                        selected={
                                            this.state.dueDate
                                        }
                                        onChange={
                                            date => this.setState({ dueDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Quotation No.</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr"
                                            onChange={(e)=>this.setState({ quotationNo: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Quotation date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={this.state.quotationDate}
                                            onChange={date => this.setState({ quotationDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Order ID</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr"
                                            onChange={(e)=>this.setState({ orderId: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Order date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.orderDate}
                                            onChange={date => this.setState({ orderDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Payment date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            // minDate={new Date()}
                                            selected={this.state.paymentDate}
                                            onChange={date => this.setState({ paymentDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Shipped on</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            // minDate={new Date()}
                                            selected={this.state.shippedOn}
                                            onChange={date => this.setState({ shippedOn: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Arrival date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.arrivalDate}
                                            onChange={date => this.setState({ arrivalDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="clear"></div>

                            <div className="add_outer_full_part">
                                <div className="title">Free text</div>
                                <div>
                                    <RichTextEditor
                                        value={this.state.purchaseOrderMessageEditor}
                                        onChange={this.onpurchaseOrderMessageChange} 
                                        rootStyle={{ minHeight: 170}}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            {
                                (this.state.isVendorSelected && this.state.conversionDetail.currencyName === "") &&
                                    <ProductGroup
                                        vendorApplicableTax={this.state.vendorApplicableTax}
                                        vendor={this.state.vendor}
                                        setDataFromGroup={this.setDataFromGroup}
                                    />
                            }
                            {
                                (this.state.isVendorSelected && this.state.conversionDetail.currencyName !== "") &&
                                    <ProductGroupWithOtherCurrency
                                        vendor={this.state.vendor}
                                        conversionDetail={this.state.conversionDetail}
                                        setDataFromGroup={this.setDataOtherCurrencyFromGroup}
                                    />
                            }
                            
                            {/* TERMS & CONDTIONS FIELD */}
                            <div className="__terms_and_conditions">
                                <div className="__terms_left_header">
                                    <div className="__terms_content_title">{'Terms & conditions'}</div>
                                </div>
                                <div className="__terms_right_content">
                                    { this.state.termsFields }
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="__terms_and_conditions add_outer_full_part">
                                <div className="title">Footer Free text</div>
                                <div>
                                    <RichTextEditor
                                        value={this.state.purchaseOrderFooterMessageEditor}
                                        onChange={this.onpurchaseOrderFooterMessageChange} 
                                        rootStyle={{ minHeight: 170}}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                       
                        </div>
                                    


                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="add_btnSave" onClick={()=>this.createOrder()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        );
    }
}

export default AddPurchaseOrder;