import React, { Component } from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'

class ReleaseStock extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            writeOff_id: "",
            stockLot_id: "",
           
            productGroup: "",
            product: "",
            productName: "",
            stockDetail: [],

            booked: "0",
            consumed: "0",
            lot: "",
            storageLocation: "",
            quantity: "",

            uom: "",

        }
    }

    componentDidMount(){
        let id = localStorage.getItem("WriteOff_id")
        let stockLot = localStorage.getItem("releaseStockLotId")
        
        this.setState({ 
            writeOff_id: id,
            stockLot_id: stockLot
        },() => {
            this.getSingleWriteOff()
        })
    }


    getSingleWriteOff = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleWriteOff(this.state.writeOff_id)
        .then(response => {
            this.setState({
                isLoading: false,
                productGroup: response.productGroup,
                product: response.stockItem,
                stockDetail: response.stock,
            },()=>{
                this.getSingleProductGroup()
                this.getSingleProduct()
                this.filterStockDetail()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    filterStockDetail = () => {
        let {
            stockDetail,
            stockLot_id
        } = this.state;

        let stock = stockDetail.filter(function (stock) { return stock.id === stockLot_id });

        this.setState({
            booked: stock[0].booked
        })
        this.getSingleStockLot(stock[0].stockLot)
        this.getSingleStorageLocation(stock[0].storageLocation)

    }

    getSingleProduct = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase() +" "+response.itemData.partDescription,
                uom: response.itemData.uomName,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getSingleProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProductGroup(this.state.productGroup)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupName: response.number.toUpperCase() +" "+response.name
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getSingleStockLot = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStockLot(id)
        .then(response => {
            this.setState({
                stockLot: response.number,
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getSingleStorageLocation = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStorageLocation(id)
        .then(response => {
            this.setState({
                storageLocation: response.name,
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    saveItem = () => {
        let {
            writeOff_id,
            stockLot_id,
            quantity
        } = this.state

        if(quantity === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter quantity"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callReleaseStockItem(writeOff_id, stockLot_id, quantity)
            .then(response => {
                this.setState({
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false
                })
            });
        }
        
    }


    checkQuantity = (val) => {
        let value = val >= 0 ? val : 0
        if(value === "" || value <= parseFloat(this.state.booked))
            this.setState({
                quantity: value
            })  
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isWriteOffs={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Release stock item</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="">
                            <div className="add_partside">
                               
                                
                                <div className="add_inner">
                                    <div className="title">Stock item</div>
                                    <div className="value">
                                        <div className="createdText text-capitalize">{(this.state.productName).substr(0, 40)}...</div>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Booked</div>
                                    <div className="value">
                                        <div className="createdText">{this.state.booked} {this.state.uom}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Consumed</div>
                                    <div className="value">
                                        <div className="createdText">{this.state.consumed} {this.state.uom}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">Lot</div>
                                    <div className="value">
                                        <div className="createdText text-uppercase">{this.state.stockLot}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>  
                                <div className="add_inner">
                                    <div className="title">Storage location</div>
                                    <div className="value">
                                        <div className="createdText text-capitalize">{this.state.storageLocation}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>      
                                <div className="add_inner">
                                    <div className="title">Quantity *</div>
                                    <div className="value"><input type="text" value={this.state.quantity} className="fieldheightdecr width50" onChange={(e)=>this.checkQuantity(e.target.value)} /> {this.state.uom}</div>
                                    <div className="clear"></div>
                                </div>   
                            </div>
                           
                            <div className="clearfix"></div>
                        </div>

                        

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReleaseStock;