import React from 'react';
import { Header, CRMHeader } from './../../../components'
import Loading from '../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";

import {
    AiOutlineExclamation
} from "react-icons/ai";
import { convertDateStringFormatToMMDDYYObject, convertDateToDDMMYYYY, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

export default class CreateCustomerOrderPayment extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            purchaseInvoices: [],

            invoice_id: this.props.location.state.invoiceId,
            invNo: this.props.location.state.invNo,
            

            id: "",
            number: "",
            dueDate: "",
            totalPayment: 0,
            paid: 0,
            storedPaid: 0,
            remainingPayment: 0,
            date: new Date(),
            sum: "",
            note: "",
        }
      
    }

    componentDidMount(){
       
        let id = localStorage.getItem("customerOrderId")
        let number = localStorage.getItem("customerOrderNumber")
        
        this.setState({
            id: id,
            number: number,
        },()=>{
            this.getSingleInvoice()
        })
    }


    getSingleInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOSingleInvoice(this.state.invoice_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                totalPayment: response.data.grandTotal,
                dueDate: response.data.dueDate,
                paid: response.data.paid,
                remainingPayment: response.data.grandTotal - response.data.paid
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    createPayment = () => {
        let { 
            invoice_id,
            sum,
            storedPaid,
            date,
            note 
        } = this.state

        if(sum === ""){
            this.setState({
                error: true,
                errormessage: "Please enter amount",
            })
        }else if(date === ""){
            this.setState({
                error: true,
                errormessage: "Please select date",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCOPayment( invoice_id, sum, storedPaid, convertDateToMMDDYYYY(date), note )
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    let remaining = this.state.remainingPayment - this.state.sum
                    localStorage.setItem("createNewCO_Payment", true)
                    this.props.history.push({
                        pathname: "/editcopayment",
                        state: { paymentId: response.data, invoiceId: invoice_id, remaining: remaining }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }
    }

    setPayingAmount = (val) => {
        let remaining = this.state.remainingPayment
        if(val === 0 || val <= remaining){
            this.setState({ 
                sum: val,
                storedPaid: val 
            })
        }
    }



    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isQuotations={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Enter a new payment for {this.state.number.toUpperCase()}</div>
                        </div>
                    </div>
                </div>
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    
                    <div>
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createPayment()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Invoice</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        {this.state.invNo}
                                    </div>
                                    {/* <Select2
                                        disabled
                                        data={
                                            this.state.purchaseInvoices.map((data) => (
                                                { id: data._id, text: data.invoiceId }
                                            ))
                                        }
                                        value={this.state.invoice_id}
                                        options={{placeholder: 'Select invoice'}}
                                        onSelect={(e)=> this.setState({ invoice_id: e.target.value },()=>{ this.getSingleInvoice() })}
                                    /> */}
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner">
                                <div className="title">Due date</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        {this.state.dueDate}
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Sum</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        {(this.state.totalPayment).toFixed(2)}<Bi.BiRupee />
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Paid</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        <span>{this.state.paid.toFixed(2)}<Bi.BiRupee /></span>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Remaining</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        <span>{this.state.remainingPayment.toFixed(2)}<Bi.BiRupee /></span>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        
                      
                            <div className="add_inner">
                                <div className="title">Sum*</div>
                                <div className="value">
                                    <input 
                                        type="text"
                                        value={this.state.sum}
                                        onChange={(e)=>this.setPayingAmount(e.target.value)}
                                    ></input>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Date*</div>
                                <div className="value">
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.date}
                                        onChange={date => this.setState({ date: date })}
                                        className="filterdateoption"
                                    />
                                    <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        onChange={(e)=>this.setState({ note: e.target.value})} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createPayment()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}