import React from 'react';

import * as APIManager from './../../APIManager/APIManager'
import Loading from './../../helper/loading'

import OrderStatus from './../AutoGenerateComponent/addCompanyOrderStatus';

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class AddEditOrderStatus extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",


            countOrderStatusNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,

            goback: this.props.backToOriginalForm

        }
    }

    componentDidMount(){
        this.getOrderStatus();
    }
    
    getOrderStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callOrderStatus()
        .then(response => {
            let orderStatus = response.status !== undefined ?  response.status : null
            if(orderStatus !== null){
                this.setState({
                    typeFieldValues: orderStatus,
                    isLoading: false,
                },()=>{
                    this.setTypeFields(orderStatus)
                })
            }else{
                this.addNewField(0)
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.addNewField(0)
            })
        });
    }

    
    
    setTypeFields = (response) => {
        let numArray = []
        let fields = []
        for(let i=0;i<response.length;i++){
            numArray.push(i)
            fields.push(
                <OrderStatus 
                    key={i}
                    componentNum={i}
                    orderStatus={response[i].status}
                    onBlur={this.statusInputBlur}
                    onEdit={this.statusInputEdit}
                    onRemove={this.statusInputRemove}
                />
            )
        }
        this.setState({
            addTypeFields: fields,
            countOrderStatusNumber: numArray,
            count: response.length
        },()=>{
            this.addNewField(response.length)
        })
    }

    addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <OrderStatus 
                        key={len}
                        componentNum={len}
                        orderStatus={""}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countOrderStatusNumber: this.state.countOrderStatusNumber.concat([len]),
            })
        })
    }
    
    statusInputEdit = (orderstatus, num) => {
        let array = [...this.state.countOrderStatusNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     
        
        
        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && orderstatus !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countOrderStatusNumber: this.state.countOrderStatusNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <OrderStatus 
                                key={this.state.count}
                                componentNum={this.state.count}
                                orderStatus={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputBlur = (orderstatus, num) => {
    
         let array = [...this.state.countOrderStatusNumber];
         let index = array.indexOf(num)
         let statusVal = [...this.state.typeFieldValues]   
 
         if (typeof statusVal[index] !== 'undefined') {
             if (orderstatus !== "") {
                 let obj = {
                     "status": orderstatus,
                 }
                 let typeFieldValues = [ ...this.state.typeFieldValues ];
                 typeFieldValues[index] = obj;
 
                 this.setState({
                     typeFieldValues,
                     generateNewType: true,
                 })
             }
         }else{
             if (orderstatus !== "") {
                 let obj = {
                     "status": orderstatus,
                 }
                 this.setState({
                     typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                     generateNewType: true,
                 })
             }
         }
     }
 

    statusInputRemove = (number) => {

        let array = [...this.state.countOrderStatusNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countOrderStatusNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }

    createOrderStatus = () => {
        let val = this.state.typeFieldValues;  
        let filteredVal = val.filter(function (el) {
            return el != null;
        }); 

        if(filteredVal.length === 0){
            this.setState({
                error: true,
                errormessage: "Please add order status values",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateOrderStatus(filteredVal)
            .then(response => {
                this.setState({
                    isLoading: false,
                    error: false,
                    success: true,
                    successmessage: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    success: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }

    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        let {
            backToOriginalForm
        } = this.props
        return(
            <div className="page absoluteWhite">
                <div className="pagecontent">
                    <div className="headerArea">
                        <div className="pageTitle heading">Order status</div>
                        <div className="clear"></div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={backToOriginalForm}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createOrderStatus()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    
                    <div className="add_partside">
                        <div className="add_inner">
                            <div className="title"></div>
                            <div className="value">
                                <table className="">
                                    <thead>
                                        <tr>
                                            <th>Order status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.addTypeFields
                                        }
                                    </tbody>
                                </table>    
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="clear"></div>
            
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={backToOriginalForm}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createOrderStatus()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>


        )
    }
}