import React, { Component } from 'react';

import {
    Logo
} from './../../assets/Images'

class Splash extends Component{

    constructor(props){
      super(props)
      this.state = {

      }
    }
    componentWillMount(){
        setTimeout(()=>{
         this.redirection()
        },2000)
    }

    redirection = () => {
      if(localStorage.getItem('userdata') !== null){
        this.props.history.push('home')  
      }else{
        this.props.history.push('login')
      }
    }

    render(){
        return(
            <div className="authBg page">
                <div className="splash__center animate__animated animate__fadeIn">
                    <img src={Logo} className="img-fluid splash__logo" alt="Logo" />
                </div>
            </div>
        )
    }
}

export default Splash;