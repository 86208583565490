import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../components'
import RichTextEditor from 'react-rte';
import Loading from './../../../../helper/loading'

import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';

import { Link } from 'react-router-dom';

import * as Im from "react-icons/im";

import * as Fi from "react-icons/fi";
import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi"
import * as APIManager from './../../../../APIManager/APIManager'

import AddTermsAndConditions from '../../../../components/AutoGenerateComponent/AddTermsAndConditions';

import ProductGroup from './GroupWithTax/ProductGroup';
import ProductGroupWithOtherCurrency from './GroupWithOtherCurrency/ProductGroup';
import { convertDateFormatFromFullDate, convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class EditPurchaseOrder extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            fieldNotEditable: true,

            vendorList: [],
            vendorContacts: [],
            isVendorSelected: false,
            productGroupData: [],
            totalOfProductCostFinal: 0,
            totalOfRawCostFinal: 0,
            totalOfRawCostInOther: 0,
            vendorApplicableTax: [],
            vendorPaymentPeriod: 0,

            purchaseOrderId: this.props.location.state.purchase_order_id,
            ordernumber: this.props.location.state.purchase_order_number,
            
            vendor: "",
            contactPerson: "",
            status: "",
            expectedDate: "",
            orderId: "",
            orderDate: "",
            invoiceId: "",
            invoiceDate: "",
            paymentDate: "",
            shippedOn: "",
            arrivalDate: "",
            reference: "",
            rfqDate: "",
            dueDate: "",
            quotationNo: "",
            quotationDate: "",
            rfqNo: "",
            vendorName: "",

            discountPercentage: "",
            discount: "",
            total: "",
            taxableFees: "",
            taxPercentage: "",
            tax: "",
            additionalFees: "",
            grandTotal: "",


            purchaseOrderMessageEditor: RichTextEditor.createEmptyValue(),
            purchaseOrderMessage: "",

            purchaseOrderFooterMessageEditor: RichTextEditor.createEmptyValue(),
            purchaseOrderFooterMessage: "",

           
            // Terms & Conditions

            countTermsNumber: [],
            TermsCounter: 0,
            termsFields: [],
            termsAndConditions: [],
            generateNewTerm: true,


            purchaseOrderNotes: [],
            purchaseOrderPayments: [],
            purchaseInvoices: [],
            debitNotes: [],
            purchaseOrderPaymentsFields: [],

            totalPaidAmt: 0,
            created: "",       
            
            showPaymentInterTab: 0,

            revisionList: [],
            selectedRevision: "",
            selectedRevisionData: []
        }
    }

    componentDidMount(){
        this.getSinglePurchaseOrderData()
        this.getOrderInvoicesList()
        this.getDebitNoteList()
        this.getPurchaseOrderPaymentList()
        this.getPORevisions()
    }


    getPORevisions = () => {
        
        this.setState({ isLoading: true, error: false })

        APIManager.callGetPORevisions(this.state.purchaseOrderId)
        .then(response => {
            let revLength = response.data.revisionData.length
            this.setState({ 
                isLoading: false,
                revisionList: response.data.revisionData,
                selectedRevision: revLength !== 0 ? response.data.revisionData[revLength-1].revision_id : "" 
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        }); 
    }
    


    getSinglePurchaseOrderData = () => {
        this.setState({ isLoading: true, error: false })

        APIManager.callSinglePurchaseOrder(this.state.purchaseOrderId)
        .then(response => {
            this.setState({ 
                ordernumber: response.data.number,
                vendor: response.data.vendor,
                contactPerson: response.data.contactPerson,
                status: response.data.status,
                expectedDate: this.getDateFormat(response.data.expectedDate),
                orderId: response.data.orderId,
                orderDate: this.getDateFormat(response.data.orderDate),
                invoiceId: response.data.invoiceId,
                invoiceDate: this.getDateFormat(response.data.invoiceDate),
                paymentDate: this.getDateFormat(response.data.paymentDate),
                shippedOn: this.getDateFormat(response.data.shippedOn),
                arrivalDate: this.getDateFormat(response.data.arrivalDate),
                created: response.data.created,
                createdBy: response.data.createdBy,
                purchaseOrderNotes: response.data.notes,
                reference: response.data.reference,
                rfqDate: this.getDateFormat(response.data.rfqDate),
                dueDate: response.data.dueDate ? this.getDateFormat(response.data.dueDate) : "",
                quotationNo: response.data.quotationNo ? response.data.quotationNo : "",
                quotationDate: response.data.quotationDate ? this.getDateFormat(response.data.quotationDate) : "",
                rfqNo: response.data.rfqNo,
                purchaseOrderMessageEditor: response.data.freeText ? RichTextEditor.createValueFromString(response.data.freeText, 'html') : RichTextEditor.createEmptyValue(),
                purchaseOrderMessage: response.data.freeText,
                purchaseOrderFooterMessageEditor: response.data.freeTextFooter ? RichTextEditor.createValueFromString(response.data.freeTextFooter, 'html') : RichTextEditor.createEmptyValue(),
                purchaseOrderFooterMessage: response.data.freeTextFooter,
                productGroupData: response.data.productGroup,
                totalOfProductCostFinal: response.data.grandSubTotal,
                totalOfRawCostFinal: response.data.grandTotal,
                totalOfRawCostInOther: response.data.totalOfRawCostInOther ? response.data.totalOfRawCostInOther : 0,
                isLoading: false,
            },()=>{

                localStorage.setItem('purchase_order_number', response.data.number)
                localStorage.setItem('purchase_order_paymentdate', response.data.paymentDate)

                this.getSingleVendor(this.state.vendor)
                if(response.data.termsCondition.length > 0){
                    this.setTermsConditions(response.data.termsCondition)
                }else{
                    this.setTerms()
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    getRevisionData = (_id) => {
        if(_id){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetPORevisionDetail(this.state.purchaseOrderId, _id)
            .then(response => {
                this.setState({ 
                    ordernumber: response.data.number,
                    vendor: response.data.vendor,
                    contactPerson: response.data.contactPerson,
                    status: response.data.status,
                    expectedDate: this.getDateFormat(response.data.expectedDate),
                    orderId: response.data.orderId,
                    orderDate: this.getDateFormat(response.data.orderDate),
                    invoiceId: response.data.invoiceId,
                    invoiceDate: this.getDateFormat(response.data.invoiceDate),
                    paymentDate: this.getDateFormat(response.data.paymentDate),
                    shippedOn: this.getDateFormat(response.data.shippedOn),
                    arrivalDate: this.getDateFormat(response.data.arrivalDate),
                    created: response.data.created,
                    createdBy: response.data.createdBy,
                    purchaseOrderNotes: response.data.notes,
                    reference: response.data.reference,
                    rfqDate: this.getDateFormat(response.data.rfqDate),
                    dueDate: response.data.dueDate ? this.getDateFormat(response.data.dueDate) : "",
                    quotationNo: response.data.quotationNo ? response.data.quotationNo : "",
                    quotationDate: response.data.quotationDate ? this.getDateFormat(response.data.quotationDate) : "",
                    rfqNo: response.data.rfqNo,
                    purchaseOrderMessageEditor: response.data.freeText ? RichTextEditor.createValueFromString(response.data.freeText, 'html') : RichTextEditor.createEmptyValue(),
                    purchaseOrderMessage: response.data.freeText,
                    purchaseOrderFooterMessageEditor: response.data.freeTextFooter ? RichTextEditor.createValueFromString(response.data.freeTextFooter, 'html') : RichTextEditor.createEmptyValue(),
                    purchaseOrderFooterMessage: response.data.freeTextFooter,
                    productGroupData: response.data.productGroup,
                    totalOfProductCostFinal: response.data.grandSubTotal,
                    totalOfRawCostFinal: response.data.grandTotal,
                    totalOfRawCostInOther: response.data.totalOfRawCostInOther ? response.data.totalOfRawCostInOther : 0,
                    isLoading: false,
                },()=>{

                    localStorage.setItem('purchase_order_number', response.data.number)
                    localStorage.setItem('purchase_order_paymentdate', response.data.paymentDate)

                    this.getSingleVendor(this.state.vendor)
                    if(response.data.termsCondition.length > 0){
                        this.setTermsConditions(response.data.termsCondition)
                    }else{
                        this.setTerms()
                    }
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            }); 
        }
    }

    getDateFormat = (date) => {
        let rTurn = ""
        if(date !== ""){
            let dt = date.split('/')
            dt = dt[0]+'/'+dt[1]+'/'+dt[2]
            let nDate = new Date(dt)

            if (!isNaN(nDate.getTime())) {
                rTurn = nDate;
            }else{
                rTurn = new Date(date);
            }
        }
        var timestamp = Date.parse(rTurn);

        if(isNaN(timestamp) === false) {
            return rTurn;
        }else{
            return "";
        }
    }

    

    getOrderInvoicesList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callListPO_Invoices(this.state.purchaseOrderId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                purchaseInvoices: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getDebitNoteList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetDebitNoteByPO(this.state.purchaseOrderId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                debitNotes: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    
    getPurchaseOrderPaymentList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callListPO_Payment(this.state.purchaseOrderId)
        .then(response => {
            this.setState({
                isLoading: false,
                purchaseOrderPayments: response,
            },()=>{
                this.updatePaymentList(response)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    updatePaymentList = () => {
        let fields = []
        let data = this.state.purchaseOrderPayments.data

        for(let i=0;i<data.length;i++){
            let innerData = []
            let remaining = data[i].total - data[i].totalPaid;
            fields.push(
                <tr key={"mainData"+i} onClick={()=>this.hideShowPaymentInnerSection(i)}>
                    <td className='text-uppercase'><b>{data[i].invoiceId}</b></td>
                    <td><b>{data[i].total.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                    <td><b>{data[i].totalPaid.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                    <td><b>{remaining.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                    <td><b>{convertDateStringMMDDtoDDMM(data[i].dueDate)}</b></td>
                    <td className='text-right pr-3'>
                        {
                            this.state.showPaymentInterTab === i ?
                                <Ai.AiFillCaretUp />
                            :
                                <Ai.AiFillCaretDown />
                        }
                    </td>
                </tr>
            )
            for(let j=0;j<data[i].payments.length;j++){
                innerData.push(
                    <tr key={"innerData"+i+j} onClick={()=>this.editPurchaseOrderPayment(data[i].payments[j].id, data[i].invoice_id, remaining)}>
                        <td>{data[i].payments[j].notes}</td>
                        <td>{data[i].payments[j].paid.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</td>
                        <td>{convertDateStringMMDDtoDDMM(data[i].payments[j].date)}</td>
                        <td></td>
                    </tr>
                )
            }

            let innerDataHeader = []
            innerDataHeader.push(
                <tr className={this.state.showPaymentInterTab === i ? '' : 'd-none'} key={"dataAndHead"+i}>
                    <td colSpan="6" className='table_inside_table'>
                        <table>
                            <thead>
                            <tr>
                                <th className='width40'><b>Notes</b></th>
                                <th className='width30'><b>Paid</b></th>
                                <th className='width20'><b>Created</b></th>
                                <th className='width10 text-right' onClick={()=>this.createPayment(data[i].invoice_id, data[i].invoiceId)}><Fi.FiPlus /></th>
                            </tr>
                            </thead>
                            <tbody>
                            
                                { innerData }
                                {
                                    data[i].totalPaid !== 0 &&
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td><b>{data[i].totalPaid.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                                
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
            fields.push(innerDataHeader)
        }

        

        this.setState({ 
            isLoading: false,
            purchaseOrderPaymentsFields: fields
        })
    }

    hideShowPaymentInnerSection = (i) => {
        if(this.state.showPaymentInterTab === i){
            this.setState({
                showPaymentInterTab: null
            },()=>{
                this.updatePaymentList()
            })
        }else{
            this.setState({
                showPaymentInterTab: i
            },()=>{
                this.updatePaymentList()
            })
        }
    }
   
    getSingleVendor = (id) => {
        this.setState({ isLoading: true, error: false, isVendorSelected: false, })
        APIManager.callVendorDetail(id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupFields: [],
                productGroupKeys: [],
                productGroupCounter: 0,
                totalOfProductCost: 0.00,
                totalOfProductCostFinal: 0.00,
                totalOfRawCostFinal: 0.00,
                totalOfRawCostInOther: 0,
                totalNoOfProduct: 0,
                vendorContacts: typeof(response.contacts) !== 'undefined' ? response.contacts : [],
                vendorApplicableTax: response.taxApplicable !== undefined ? response.taxApplicable : [],
                vendorPaymentPeriod: response.paymentPeriods,
                currency: response.currency,
                conversionDetail: response.conversionDetail,
                vendorName: response.name,
                isVendorSelected: true,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
            })
        });   
    }

 



    /* Terms & Conditions all counting */

   

    setTermsConditions = (resp) => {
        let counterArray = []
        let termFields = [];
        for(let i=0;i<resp.length;i++){
            counterArray.push(i)
            termFields.push(
                <AddTermsAndConditions 
                    key={i}
                    componentNum={i}
                    term={resp[i].terms}
                    termsFor={"purchaseOrder"}
                    condition={resp[i].options}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            )
        }

        this.setState({
            termsFields: termFields,
            countTermsNumber: counterArray,
            TermsCounter: resp.length,
            termsAndConditions: resp
        },()=>{
            this.setTerms()
        })
    }

    setTerms = () => {
        let counter = this.state.TermsCounter;
        this.setState({
            termsFields: this.state.termsFields.concat([
                <AddTermsAndConditions 
                    key={counter}
                    componentNum={counter}
                    term={""}
                    condition={""}
                    termsFor={"purchaseOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            ]),
            countTermsNumber: this.state.countTermsNumber.concat([ counter ]),
        },()=>{
            this.setState({
                TermsCounter: counter + 1
            })
        })
    }

    setDataFromGroup = (totalOfProductCostFinal,totalOfRawCostFinal,productGroupData) => {
        this.setState({
            totalOfProductCostFinal: totalOfProductCostFinal,
            totalOfRawCostFinal: totalOfRawCostFinal,
            productGroupData: productGroupData
        })
    }

    setDataOtherCurrencyFromGroup = (totalOfProductCostFinal,totalOfRawCostFinal,totalOfRawCostInOther,productGroupData) => {
        this.setState({
            totalOfProductCostFinal: totalOfProductCostFinal,
            totalOfRawCostFinal: totalOfRawCostFinal,
            productGroupData: productGroupData,
            totalOfRawCostInOther: totalOfRawCostInOther
        })
    }

    termsFieldChange = (term,condition,number) => {

        let array = [...this.state.countTermsNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.termsAndConditions]        

        if (typeof CIVal[index] !== 'undefined') {
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                let termsAndConditions = [ ...this.state.termsAndConditions ];
                termsAndConditions[index] = obj;

                this.setState({
                    termsAndConditions,
                })
            }
        }else{
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                this.setState({
                    termsAndConditions: this.state.termsAndConditions.concat([ obj ])
                },()=>{
                    this.setTerms()
                })
            }
        }
    }

    termsFieldRemove = (number) => {
        let array = [...this.state.countTermsNumber];
        let fields = [...this.state.termsFields];
        let data = [...this.state.termsAndConditions]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            // fields.splice(index, 1)
            this.setState({
                countTermsNumber: array,
                termsFields: fields,
                termsAndConditions: data
            });
        }
    }

    goBack = () => {
        if(localStorage.getItem("createNewPurchaseOrder") !== null){
            localStorage.removeItem("createNewPurchaseOrder")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    updateOrder = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                purchaseOrderId,
                ordernumber,
                vendor,
                contactPerson,
                reference,
                rfqNo,
                rfqDate,
                dueDate,
                quotationNo,
                quotationDate,
                orderId,
                orderDate,
                invoiceId,
                invoiceDate,
                paymentDate,
                shippedOn,
                arrivalDate,
                expectedDate,
                totalOfProductCostFinal,
                totalOfRawCostFinal,
                termsAndConditions,
                productGroupData,
                purchaseOrderMessage,
                purchaseOrderFooterMessage,
                totalOfRawCostInOther,
                status
            } = this.state;

            /* UPDATE STATUS */

            let updatedStatus = "";

           
            if(arrivalDate){
                updatedStatus = "received"
            }else if(shippedOn){
                updatedStatus = "shipped"
            }else if(orderDate){
                updatedStatus = "ordered"
            }else{
                updatedStatus = "new po"
            }
            
            if(status === "partially received"){
                updatedStatus = status
            }else if(status === "received"){
                updatedStatus = status
            }else if(status === "archived"){
                updatedStatus = status
            }else if(status === "cancelled"){
                updatedStatus = status
            }
            
            /* UPDATE STATUS */

            let localExpectedDate = convertDateFormatFromFullDate(expectedDate)

            let productGroupDataRaw = [];
            productGroupData.forEach((val) => {
                if(val !== null){
                    productGroupDataRaw.push(val);
                }
            });

            /* GET OLDEST DATE FROM PRODUCT GROUP - CODE START */
                
            let groupDateArray = []
            let dt = "";
            let newDate = "";
            
        
            let currentDate = this.state.expectedDate
            if(!currentDate){
                let cDate = new Date(new Date());
                currentDate = new Date(cDate.setDate(cDate.getDate() + 1));
            }
            

            var dd = String(currentDate.getDate()).padStart(2, '0');
            var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = currentDate.getFullYear();

            currentDate = mm + '/' + dd + '/' + yyyy;

            productGroupDataRaw.map((data) => (
                dt = (data.expectedDate) ? data.expectedDate : currentDate,
                newDate = dt,

                groupDateArray.push(newDate)
            ))

            let oldestDate = currentDate;
            
            if(groupDateArray.length > 0){
                oldestDate = groupDateArray.reduce(function (pre, cur) {
                    return Date.parse(pre) > Date.parse(cur) ? pre : cur;
                });
            }
            

            localExpectedDate =  Date.parse(oldestDate) > Date.parse(currentDate) ? oldestDate : currentDate;
            
            /* GET OLDEST DATE FROM PRODUCT GROUP - CODE END */

            if(vendor === ""){
                this.setState({
                    error: true,
                    errormessage: "Please select vendor"
                },()=>{
                    this.scrollToTop()
                })
            }else if(expectedDate === ""){
                this.setState({
                    error: true,
                    errormessage: "Please select expected delivery date"
                },()=>{
                    this.scrollToTop()
                })
            }else if(productGroupDataRaw.length === 0){
                this.setState({
                    error: true,
                    errormessage: "Please Add Purchase Item(s)"
                },()=>{
                    this.scrollToTop()
                })
            }else{

                this.setState({ isLoading: true, error: false })
                APIManager.callUpdatePurchaseOrder(purchaseOrderId,ordernumber,vendor,contactPerson,updatedStatus,reference,rfqNo,convertDateToMMDDYYYY(rfqDate),convertDateToMMDDYYYY(dueDate),quotationNo,convertDateToMMDDYYYY(quotationDate),orderId,convertDateToMMDDYYYY(orderDate),invoiceId,convertDateToMMDDYYYY(invoiceDate),convertDateToMMDDYYYY(paymentDate),convertDateToMMDDYYYY(shippedOn),convertDateToMMDDYYYY(arrivalDate),localExpectedDate,totalOfProductCostFinal,totalOfRawCostFinal,JSON.stringify(termsAndConditions),JSON.stringify(productGroupDataRaw),purchaseOrderMessage,purchaseOrderFooterMessage,totalOfRawCostInOther)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        successmessage: response.message,
                        success: true,
                        error: false,
                        fieldNotEditable: true,
                    },()=>{
                        this.scrollToTop()
                        this.getPORevisions()
                        this.getSinglePurchaseOrderData()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    },()=>{
                        this.scrollToTop()
                    })
                });   
            }
        }else{
            
            let slug = "Procurement_PurchaseOrders"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    createPayment = (id, invNo) => {
        let slug = "Procurement_PurchaseOrders"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createpopay",
                state: { invoiceId: id, invNo: invNo }
            }) 
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    
    createInvoice = () => {
        let slug = "Procurement_Invoices"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createpoinvoice",
                state: { poId: this.state.purchaseOrderId, isFromPO: true }
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    createDebitNote = () => {
        let slug = "Procurement_DebitNote"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createdebitnote",
                state: { poId: this.state.purchaseOrderId, isFromPO: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editInvoice = (id) => {
        this.props.history.push({
            pathname: "/editpoinvoice",
            state: { invoiceId: id, isNewEntry: false }
        }) 
    }

    editQCM = (id) => {
        this.props.history.push({
            pathname: "/editinvoiceqc",
            state: { invoiceId: id, isNewEntry: false }
        })
    }

    editDebitNote = (id) => {
        this.props.history.push({
            pathname: "/editdebitnote",
            state: { debitNoteId: id, isNewEntry: false }
        })
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeletePurchaseOrder(this.state.purchaseOrderId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    onpurchaseOrderMessageChange = (value) => {
        this.setState({
            purchaseOrderMessageEditor: value,
            purchaseOrderMessage: value.toString('html')
        });
    }

    onpurchaseOrderFooterMessageChange = (value) => {
        this.setState({
            purchaseOrderFooterMessageEditor: value,
            purchaseOrderFooterMessage: value.toString('html')
        });
    }

    downloadPDF = () => {
        if(this.state.conversionDetail){
            if(this.state.conversionDetail.currencyName !== ""){
                window.open('purchaseorderother?order='+this.state.purchaseOrderId)
            }else{
                window.open('purchaseorderpdf?order='+this.state.purchaseOrderId)
            }
        }else{
            window.open('purchaseorderpdf?order='+this.state.purchaseOrderId)
        }
    }

    editPurchaseOrderPayment = (paymentId, invoiceId, remaining) => {
        this.props.history.push({
            pathname: "/editpopay",
            state: { paymentId: paymentId, invoiceId: invoiceId, remaining: remaining}
        })
        
    }

    updateVendor = (id) => {
        this.setState({
            vendor: id
        })
    }

    setPaymentDate = () => {
        let { invoiceDate } = this.state
        
        if(invoiceDate !== ""){
            var numberOfDaysToAdd = this.state.vendorPaymentPeriod;

            var newPDate = new Date(invoiceDate.getTime()+(numberOfDaysToAdd*24*60*60*1000));

            this.setState({
                paymentDate: newPDate
            })
        }
    }

    editPurchageOrderNote = (data) => {

        localStorage.setItem("PONoteIsImp", data.is_important)
        localStorage.setItem("PONoteId", data.id)
        localStorage.setItem("PONote", data.note)
        this.props.history.push('/editponote')
    }


    updateContact = (id) => {
        this.setState({
            contactPerson: id
        })
    }

    formattedNumber = (num) => {
        let number = parseFloat(num)
        if(number !== "" || number !== 0){
            return number.toFixed(2).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return number
        }
    } 


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isPurchaseOrders={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title">Purchase order <span className="text-uppercase">{this.state.ordernumber}</span></div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.downloadPDF()}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>    
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateOrder()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Number *</div>
                                    <div className="value width30 _Bbaspace3px">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-uppercase"
                                            onChange={(e)=>this.setState({ ordernumber: e.target.value })}
                                            value={this.state.ordernumber}
                                            readOnly
                                        />
                                    </div>
                                    <div className="value width40 zIndex1001">
                                        <select value={this.state.selectedRevision} onChange={(e)=>this.setState({ selectedRevision: e.target.value },()=>{ this.getRevisionData(e.target.value) })} className='width99' >
                                            <option value="">Select Revision</option>
                                            {
                                                this.state.revisionList.map((data) => (
                                                    <option key={'revision__'+data.revision_id} value={data.revision_id}>{data.revisionName}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Vendor*</div>
                                    <div className="value">
                                        <div className="createdText text-capitalize">
                                            {this.state.vendorName}
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Contact person</div>
                                    <div className="value">
                                        <select className="text-capitalize" value={this.state.contactPerson} onChange={(e)=>this.setState({ contactPerson: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                this.state.vendorContacts.map((data, i) => (
                                                   <option key={data.contactId} value={data.contactId}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                        
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Status</div>
                                    <div className="value">
                                        <select value={this.state.status} onChange={(e)=>this.setState({ status: e.target.value })}>
                                            <option value="">Select</option>
                                            <option value="new po" disabled={ this.state.status === "new po" ? false : true }>New PO</option>
                                            {/* <option value="rfq" disabled={ this.state.status === "rfq" ? false : true }>RFQ</option> */}
                                            <option value="ordered" disabled={ this.state.status === "ordered" ? false : true }>Ordered</option>
                                            <option value="shipped" disabled={ this.state.status === "shipped" ? false : true }>Shipped</option>
                                            <option value="partially received" disabled={ this.state.status === "partially received" ? false : true }>Partially Received</option>
                                            <option value="received" disabled={ this.state.status === "received" ? false : true }>Received</option>
                                            <option value="archived">Archive</option>
                                            <option value="cancelled">Cancel</option>
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Reference</div>
                                    <div className="value"><input type="text" value={this.state.reference} onChange={(e)=>this.setState({ reference: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">RFQ no.</div>
                                    <div className="value"><input type="text" value={this.state.rfqNo} onChange={(e)=>this.setState({ rfqNo: e.target.value })} className="fieldheightdecr"></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">RFQ date</div>
                                    <div className="value">
                                        <DatePicker
                                        dateFormat="dd/MM/yyyy" 
                                        maxDate={new Date()}
                                        selected={
                                            this.state.rfqDate
                                        }
                                        onChange={
                                            date => this.setState({ rfqDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Expected delivery date*</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.expectedDate}
                                            onChange={date => this.setState({ expectedDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Valid till</div>
                                    <div className="value">
                                        <DatePicker
                                        dateFormat="dd/MM/yyyy" 
                                        minDate={new Date()}
                                        selected={
                                            this.state.dueDate
                                        }
                                        onChange={
                                            date => this.setState({ dueDate: date })
                                        }
                                        className="filterdateoption"/>
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Created</div>
                                    <div className="value"><div className="__static_fixed_text">{convertDateStringMMDDtoDDMM(this.state.created)}</div></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Quotation No.</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr"
                                            onChange={(e)=>this.setState({ quotationNo: e.target.value })}
                                            value={this.state.quotationNo}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Quotation date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={this.state.quotationDate}
                                            onChange={date => this.setState({ quotationDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Order ID</div>
                                    <div className="value">
                                        <input
                                            value={this.state.orderId} 
                                            type="text" 
                                            className="fieldheightdecr"
                                            onChange={(e)=>this.setState({ orderId: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Order date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.orderDate}
                                            onChange={date => this.setState({ orderDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Payment date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            // minDate={new Date()}
                                            selected={this.state.paymentDate}
                                            onChange={date => this.setState({ paymentDate: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Shipped on</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            // minDate={new Date()}
                                            selected={this.state.shippedOn}
                                            onChange={date => this.setState({ shippedOn: date })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Arrival date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.arrivalDate}
                                            onChange={date => this.setState({ arrivalDate: date })}
                                            className="filterdateoption"
                                            // disabled={this.state.purchaseInvoices.length > 0 ? false : true}
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="clear"></div>

                            <div className="add_outer_full_part">
                                <div className="title">Free text</div>
                                <div>
                                    <RichTextEditor
                                        value={this.state.purchaseOrderMessageEditor}
                                        onChange={this.onpurchaseOrderMessageChange} 
                                        rootStyle={{ minHeight: 170}}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            {
                                (this.state.isVendorSelected && this.state.conversionDetail.currencyName === "") &&
                                    <ProductGroup
                                        vendorApplicableTax={this.state.vendorApplicableTax}
                                        productGroupData={this.state.productGroupData}
                                        vendor={this.state.vendor}
                                        setDataFromGroup={this.setDataFromGroup}
                                        history={this.props.history}
                                        status={this.state.status}
                                    />
                            }

                            {
                                (this.state.isVendorSelected && this.state.conversionDetail.currencyName !== "") &&
                                    <ProductGroupWithOtherCurrency
                                        vendor={this.state.vendor}
                                        conversionDetail={this.state.conversionDetail}
                                        productGroupData={this.state.productGroupData}
                                        setDataFromGroup={this.setDataOtherCurrencyFromGroup}
                                        history={this.props.history}
                                        status={this.state.status}
                                    />
                            }
                            
                            {/* TERMS & CONDTIONS FIELD */}
                            <div className="__terms_and_conditions">
                                <div className="__terms_left_header">
                                    <div className="__terms_content_title">{'Terms & conditions'}</div>
                                </div>
                                <div className="__terms_right_content">
                                    { this.state.termsFields }
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="__terms_and_conditions add_outer_full_part">
                                <div className="title">Footer Free text</div>
                                <div>
                                    <RichTextEditor
                                        value={this.state.purchaseOrderFooterMessageEditor}
                                        onChange={this.onpurchaseOrderFooterMessageChange} 
                                        rootStyle={{ minHeight: 170}}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            {
                                this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                            }
                            
                       
                        </div>
                                    


                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateOrder()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>

                   

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <div className="footerTitle">Invoices</div>
                        <table>
                            <thead>
                            <tr>
                                <th className="tableHeader">Invoice ID</th>
                                <th className="tableHeader">Invoice date</th>
                                <th className="tableHeader">Due date</th>
                                <th className="tableHeader">Total</th>
                                <th className="tableHeader">Created</th>
                                <th className="tableAddButton text-right"><div onClick={()=>this.createInvoice()} className="addContactButton"><Fi.FiPlus /></div></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.purchaseInvoices !== null &&
                                this.state.purchaseInvoices.map((data, i) => (
                                    <tr key={'po-invoices'+i} onClick={()=>this.editInvoice(data._id)}>
                                        <td className='text-uppercase'>{data.invoiceId}</td>
                                        <td>{data.invoiceDate}</td>
                                        <td>{data.dueDate}</td>
                                        <td>{this.formattedNumber(data.grandTotal)}<Bi.BiRupee /></td>
                                        <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <div className="footerTitle">Quality Check</div>
                        <table>
                            <thead>
                            <tr>
                                <th className="tableHeader">Invoice ID</th>
                                <th className="tableHeader">Invoice date</th>
                                <th className="tableHeader">Due date</th>
                                <th className="tableHeader">Total</th>
                                <th className="tableHeader">Created</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.purchaseInvoices !== null &&
                                this.state.purchaseInvoices.map((data, i) => (
                                    <tr key={'po-invoices'+i} onClick={()=>this.editQCM(data._id)}>
                                        <td className='text-uppercase'>{data.invoiceId}</td>
                                        <td>{data.invoiceDate}</td>
                                        <td>{data.dueDate}</td>
                                        <td>{this.formattedNumber(data.grandTotal)}<Bi.BiRupee /></td>
                                        <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <div className="footerTitle">Debit Notes</div>
                        <table>
                            <thead>
                            <tr>
                                <th className="tableHeader">Number</th>
                                <th className="tableHeader">Invoice</th>
                                <th className="tableHeader">Debit Qty</th>
                                <th className="tableHeader">Total</th>
                                <th className="tableHeader">Created</th>
                                <th className="tableAddButton text-right"><div onClick={()=>this.createDebitNote()} className="addContactButton"><Fi.FiPlus /></div></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.debitNotes !== null &&
                                this.state.debitNotes.map((data, i) => (
                                    <tr key={'po-invoices'+i} onClick={()=>this.editDebitNote(data._id)}>
                                        <td className='text-uppercase'>{data.number}</td>
                                        <td>{data.invoiceNumber}</td>
                                        <td>{data.rejectedQuantity}</td>
                                        <td>{this.formattedNumber(data.grandTotal)}<Bi.BiRupee /></td>
                                        <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <div className="footerTitle">Payments</div>
                        <table>
                            <thead>
                                <tr>
                                    <th className='width30'>Invoice ID</th>
                                    <th className='width30'>Total</th>
                                    <th className='width20'>Paid</th>
                                    <th className='width20'>Remaining</th>
                                    <th className='width10'>Due date</th>
                                    <th className='width10'></th>
                                </tr>
                            </thead>
                            <tbody>
                            
                            {
                                this.state.purchaseOrderPaymentsFields
                            }
                                
                          
                                
                            </tbody>
                        </table>
                    </div>
                

                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <div className="footerTitle">Notes</div>
                        <table>
                            <thead>
                            <tr>
                                <th className="tableHeader"><span>Created</span></th>
                                <th className="tableHeader"><span>Modified</span></th>
                                <th className="tableHeader w-50">Notes</th>
                                <th className="tableAddButton text-right"><Link to="/createponote" className="addContactButton"><Fi.FiPlus /></Link></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.purchaseOrderNotes !== null &&
                                this.state.purchaseOrderNotes.map((data, i) => (
                                    <tr key={data.id} className={data.is_important ? "__imp_note": ""} onClick={()=>this.editPurchageOrderNote(data)}>
                                        <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br/><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                        <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br/><span  style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                        <td className="texttop preline">{data.note}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }

                            </tbody>
                        </table>
                    </div>

                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        );
    }
}

export default EditPurchaseOrder;