import React, { Component } from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'
import Pagination from "react-js-pagination";

import * as ExportData from './exportData'
import * as Fi from 'react-icons/fi'
import * as Bi from 'react-icons/bi'
import { ImArrowDown2 } from "react-icons/im";
import { convertDateStringMMDDtoDDMM } from '../../../constants/DateFormatter';

class WriteOffs extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            totalQty: 0,
            totalCost: 0,
            writeoffResponse: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount(){
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.writeoffResponse.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ writeoffResponse: sortedOrders }, () => {
                this.setFilterData()
            });
        });
        
    };
//******************* ascending descending order function end ********************************

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("writeOff_FilterData"))
        if (filterData !== null) {
            this.setState({
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,

                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage
            }, () => {
                this.getWriteOff()
            })
        }else{
            this.getWriteOff()
        }
    }

    setFilterData = () => {
        let filterDataArray = {
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,

            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage
        }
        localStorage.setItem("writeOff_FilterData", JSON.stringify(filterDataArray))
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length*(pageNumber-1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        },()=>{
            this.getWriteOff()
        });
    }

    getWriteOff = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetWriteoffList(this.state.length,this.state.start,"","","","","","","","","","","","","")
        .then(response => {
            let qty = 0;
            let cost = 0;
            for(let i=0;i<response.data.length;i++){
                qty += parseInt(response.data[i].quantity)
                cost = response.data[i].cost !== "" ? cost + response.data[i].cost : cost + 0
            }
            this.setState({ 
                isLoading: false,
                writeoffResponse: response.data,
                totalRecords: response.totalRecords,
                totalQty: qty,
                totalCost: cost
            },()=>{
                this.setFilterData()

                if(this.state.fieldName){
                    this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    exportAsCSV = () => {
        ExportData.exportCSV(this.state.writeoffResponse)
    }

    exportAsPDF = () => {
        ExportData.exportPDF(this.state.writeoffResponse)
    }

   
    addWriteOffs = () => {
        let slug = "Stock_WriteOffs"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createwriteoff"
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    editWriteoff = (_id) => {
        localStorage.setItem("WriteOff_id", _id)
        this.props.history.push({
            pathname: "/editwriteoff"
        })
        
    }


    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isWriteOffs={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">{Strings.Stock.WriteOffs}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={()=>this.exportAsCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton"><div onClick={()=>this.addWriteOffs()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate' , false)}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partNo', 'notDate' , false)}><span>Part No.</span> {this.state.fieldName === "partNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partDescription', 'notDate' , false)}><span>Part description</span> {this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupNumber', 'notDate' , false)}><span>Group number</span> {this.state.fieldName === "groupNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupName', 'notDate' , false)}><span>Group name</span> {this.state.fieldName === "groupName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('quantity', 'notDate' , false)}><span>Quantity</span> {this.state.fieldName === "quantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "quantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('cost', 'notDate' , false)}><span>Cost</span> {this.state.fieldName === "cost" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "cost" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('created', 'notDate' , false)}><span>Created</span> {this.state.fieldName === "created" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "created" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('status', 'notDate' , false)}><span>Status</span> {this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>


                                    {/* <th className="tableHeader tableHeadButton"><FiChevronsDown /></th> */}
                                    <th className="tableHeader tableHeadButton"><div onClick={()=>this.addWriteOffs()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><b>Total</b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th><b>{this.state.totalQty}</b></th>
                                    <th><b>{this.state.totalCost.toFixed(2)} <Bi.BiRupee /></b></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.writeoffResponse.map((data, i)=>(
                                        <tr key={data._id} onClick={()=>this.editWriteoff(data._id)}>
                                            <td>{this.state.activePage === 1 ? 1+i : this.state.start+1+i}</td>
                                            <td className="text-uppercase">{data.number}</td>
                                            <td>{data.partNo}</td>
                                            <td>{data.partDescription}</td>
                                            <td className="text-uppercase">{data.groupNumber}</td>
                                            <td>{data.groupName}</td>
                                            <td>{data.quantity}</td>
                                            <td>{data.cost} <Bi.BiRupee /></td>
                                            <td>{convertDateStringMMDDtoDDMM(data.created)}</td>
                                            <td>{data.status}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{width: 80}}>
                                    <select value={this.state.length} onChange={(e)=>this.setState({ length: e.target.value },()=>{ this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WriteOffs;