import React, { Component } from 'react';
import * as Bi from 'react-icons/bi'
import * as APIManager from './../../../../APIManager/APIManager'
import { validateNumber } from './../../../../constants/Validate'

class ProductGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            onCallBack: this.props.callBack,
            responseData: this.props.data,
            counter: this.props.number,
            scopeDetails: this.props.scopeDetails,

            data: this.props.data,
            product_group: this.props.data.groupNumber.toUpperCase()+' '+this.props.data.groupName,
            product_id: this.props.data.ItemId,
            product_name: this.props.data.itemNo+' '+this.props.data.itemName,
            product_ordered_qty: this.props.data.orderedQuantity,
            price_per_qty: this.props.data.pricePerUoM,
            pricePerUoMInOtherCurrency: this.props.data.pricePerUoMInOtherCurrency,
            discount: this.props.data.discount,
            subTotal: this.props.data.subTotal,
            total: this.props.data.total,
            expectedDate: this.props.data.expectedDate,
            remainingQuantity: this.props.data.remainingQuantity,
            itemUoM: this.props.data.itemUoM,
            freeText: this.props.data.freeText ? this.props.data.freeText.replace(/<br\s*[\/]?>/gi, "\n") : "",
            vendorId: this.props.vendorId,
            eligibleTax: this.props.data.eligibleTax ? this.props.data.eligibleTax : "",

            applicableTax: "",
            applicableTaxType: this.props.applicableTaxType,
            currencyData: this.props.currencyDetails,

            receivedQuantity: 0,

            productObj : {
                "productGroupId": "",
                "groupName": "",
                "groupNumber": "",
                "ItemId": "",
                "itemNo": "",
                "itemName": "",
                "pricePerUoM": "",
                "orderedQuantity": "",
                "remainingQuantity": "",
                "receivedQuantity": "",
                "discount": "",
                "subTotal": "",
                "total": "",
                "expectedDate": "",
                "itemUoM": "",
                "freeText": "",
                "pricePerUoMInOtherCurrency": "",
                "rawTotalInOtherCurrency": "",
                "exchangeRate": "",
                "subTotalInOtherCurrency": "",
                "eligibleTax": ""
            },

            taxCount: 0,
            itemTax: 0
        }
    }

    componentDidMount(){
        if(this.state.currencyData.conversionRate){
            this.getCountWithoutTax()
        }else{
            this.setTaxData()
        }
    }

   

    setTaxData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetVendorProductPrice(this.state.product_id, this.state.vendorId)
        .then(response => {
            let subtotal = this.state.subTotal
            let tax = response.tax ? response.tax : 0
    
            let taxCount = parseFloat(subtotal) * parseFloat(tax) / 100;
            let itemTax = taxCount / this.state.applicableTaxType.length


            this.setState({
                taxCount: taxCount,
                itemTax: itemTax,
                applicableTax: tax,
                isLoading: false,
            },()=>{
                this.countSubTotal()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    countSubTotal = () => {
        if(this.state.currencyData.conversionRate){
            this.getCountWithoutTax()
        }else{
            this.getCountWithTax()
        }
    }

    getCountWithoutTax = () => {
        let { receivedQuantity, price_per_qty, pricePerUoMInOtherCurrency, discount, } = this.state;
        if(receivedQuantity !== "" && pricePerUoMInOtherCurrency !== ""){
            if(validateNumber(receivedQuantity) && validateNumber(pricePerUoMInOtherCurrency)){
                let totalInInr = parseFloat(receivedQuantity*price_per_qty)
                let totalInOther = parseFloat(receivedQuantity*pricePerUoMInOtherCurrency)
                if(discount !== "" && validateNumber(discount)){
                    let withdiscount = totalInInr - ( totalInInr*discount/100 );
                    let withdiscountInOther = totalInOther - ( totalInOther*discount/100 );
                    this.setState({
                        subTotal: withdiscount.toFixed(2),
                        total: withdiscount.toFixed(2),
                        totalInOtherCurrency: withdiscountInOther.toFixed(2)
                    },()=>{
                        this.generateNewObject()
                    })
                }else{
                    this.setState({
                        subTotal: totalInInr.toFixed(2),
                        total: totalInInr.toFixed(2),
                        totalInOtherCurrency: totalInOther.toFixed(2)
                    },()=>{
                        this.generateNewObject()
                    })
                }
            }else{
                this.setState({
                    subTotal: 0,
                    total: 0,
                    itemTax: 0,
                    totalInOtherCurrency: 0
                })
            }
        }else{
            this.setState({
                subTotal: 0,
                total: 0,
                totalInOtherCurrency: 0
            },()=>{
                this.generateNewObject()
            })
        }
    }
    
    getCountWithTax = () => {
        let { receivedQuantity, price_per_qty, discount, applicableTax, applicableTaxType  } = this.state;
        if(receivedQuantity !== "" && price_per_qty !== ""){
            if(validateNumber(receivedQuantity) && validateNumber(price_per_qty)){
                let total = parseFloat(receivedQuantity*price_per_qty)
                let rawTotalWithoutDisc = parseFloat(total + (total*applicableTax/100))
                let taxAmountWithoutDisc = parseFloat(total*applicableTax/100)
                taxAmountWithoutDisc = taxAmountWithoutDisc/applicableTaxType.length
                if(discount !== "" && validateNumber(discount)){
                    let withdiscount = total - ( total*discount/100 );
                    let rawtotal = parseFloat(withdiscount + ( withdiscount*applicableTax/100 ))
                    let itemTaxWithDiscount = parseFloat(withdiscount*applicableTax/100)
                    itemTaxWithDiscount = itemTaxWithDiscount/applicableTaxType.length
                    this.setState({
                        subTotal: withdiscount.toFixed(2),
                        total: rawtotal.toFixed(2),
                        itemTax: itemTaxWithDiscount.toFixed(2)
                    },()=>{
                        this.generateNewObject()
                    })
                }else{
                    this.setState({
                        subTotal: total.toFixed(2),
                        total: rawTotalWithoutDisc.toFixed(2),
                        itemTax: taxAmountWithoutDisc.toFixed(2)
                    },()=>{
                        this.generateNewObject()
                    })
                }
            }else{
                this.setState({
                    subTotal: 0,
                    total: 0,
                    itemTax: 0,
                })
            }
        }else{
            this.setState({
                subTotal: 0,
                total: 0,
                itemTax: 0,
            },()=>{
                this.generateNewObject()
            })
        }
    }

    generateNewObject = () => {
        let productObj = this.state.productObj
        
        productObj.productGroupId = this.state.data.productGroupId;
        productObj.groupName =  this.state.data.groupName;
        productObj.groupNumber =  this.state.data.groupNumber;
        productObj.ItemId =  this.state.data.ItemId;
        productObj.itemNo =  this.state.data.itemNo;
        productObj.itemName =  this.state.data.itemName;
        productObj.pricePerUoM =  this.state.data.pricePerUoM;
        productObj.orderedQuantity =  this.state.data.orderedQuantity;
        productObj.remainingQuantity =  this.state.data.remainingQuantity - this.state.receivedQuantity;
        productObj.receivedQuantity =  this.state.receivedQuantity;
        productObj.discount =  this.state.data.discount;
        productObj.subTotal =  this.state.subTotal;
        productObj.total =  this.state.total;
        productObj.expectedDate =  this.state.data.expectedDate;
        productObj.itemUoM = this.state.data.itemUoM;
        productObj.freeText = this.state.freeText.replace(/(?:\r\n|\r|\n)/g, '<br>');
        productObj.pricePerUoMInOtherCurrency = this.state.pricePerUoMInOtherCurrency;
        productObj.subTotalInOtherCurrency = this.state.totalInOtherCurrency;
        productObj.rawTotalInOtherCurrency = this.state.totalInOtherCurrency;
        productObj.eligibleTax = this.state.eligibleTax;

        this.setState({
            productObj
        },()=>{
            this.state.onCallBack(this.state.productObj, this.state.counter)
        })
    }

    checkQuantity = (val) => {
        let value = val >= 0 ? val : 0
        let scopeDetails = this.state.scopeDetails
        let reqQuatity = Math.round(parseFloat(value))

        let remQuantity = scopeDetails ? Math.round(parseFloat(scopeDetails.scope)) : Math.round(parseFloat(this.state.remainingQuantity))
        console.log("value>>>", value , "scopeDetails>>>" , scopeDetails , "reqQuatity>>>" , reqQuatity , "remQuantity>>", remQuantity);
        if(value === "" || reqQuatity <= remQuantity){
            this.setState({
                receivedQuantity: value
            },()=>{
                this.countSubTotal()
            })
        }
    }

    render() {
        return (
            <tr>
                <td>{this.state.counter + 1}</td>
                <td style={{ width: 100 }}>{this.state.product_group}</td>
                <td style={{ width: 150 }}>
                    <div>{this.state.product_name}</div>
                    <textarea 
                        className="text-area" 
                        value={this.state.freeText} 
                        onChange={(e)=>this.setState({
                            freeText: e.target.value
                        },()=>{ this.generateNewObject() })}
                    ></textarea>
                </td>
                <td><b>{this.state.product_ordered_qty} {this.state.itemUoM}</b></td>
               
                {/* Price per UoM */}
                <td>
                    <b>
                        {this.state.currencyData.conversionRate !== "" && this.state.currencyData.currencySymbol } 
                        {this.state.currencyData.conversionRate ? this.state.pricePerUoMInOtherCurrency : this.state.price_per_qty}
                        {this.state.currencyData.conversionRate === "" && <Bi.BiRupee /> } 
                        
                    </b>
                </td>
                <td><b>{this.state.discount} %</b></td>
                {/* Sub Total */}
                {
                    this.state.currencyData.conversionRate === "" &&
                    <td>{this.state.subTotal} <Bi.BiRupee /></td>
                }

                {
                    this.state.applicableTaxType.map((data, i) => (
                        <td key={data+i}>{parseFloat(this.state.itemTax).toFixed(2)} <Bi.BiRupee /></td>
                    ))
                }
                {
                    this.state.currencyData.conversionRate === "" &&
                    <td style={{ width: 150 }}>
                        <select class="select-box" value={this.state.eligibleTax} onChange={(e)=>this.setState({ eligibleTax: e.target.value },()=>{ this.generateNewObject() })}>
                            <option value="">Select Type</option>
                            <option value="eligible for itc input">Eligible for ITC Input</option>
                            <option value="eligible for itc goods">Eligible for ITC Goods</option>
                            <option value="ineligible section 17(5)">Ineligible Section 17(5)</option>
                            <option value="ineligible others">Ineligible Others</option>
                        </select>
                    </td>
                }
                
                {/* Total */}
                <td>
                    {this.state.currencyData.conversionRate !== "" && this.state.currencyData.currencySymbol } 
                    {this.state.currencyData.conversionRate ? this.state.totalInOtherCurrency : this.state.total}
                    {this.state.currencyData.conversionRate === "" && <Bi.BiRupee /> } 
                    
                </td>
                
                <td><b>{parseFloat(this.state.remainingQuantity).toFixed(2)} {this.state.itemUoM}</b></td>

                <td style={{ width: 100 }}>
                    <input 
                        disabled={this.state.remainingQuantity === 0 ? true : false}
                        type='text' 
                        className='input-box' 
                        style={{ width: "50%"}}
                        value={this.state.receivedQuantity}
                        onChange={(e)=>this.checkQuantity(e.target.value)} 

                    /> {this.state.itemUoM}
                </td>
            </tr>     
        );
    }
}

export default ProductGroup;