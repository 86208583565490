import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import Loading from './../../../../helper/loading'
import ProductGroup from './ProductGroup';
import { convertDateStringMMDDtoDDMM } from '../../../../constants/DateFormatter';

class CreateDebitNote extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            poGroupList: [],
            poGroupData: [],
            rejectedInvoiceData: [],
            addressListVendor:[],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            _id_invoiceId: "",

            isFromPO: this.props.location.state.isFromPO,
            purchaseOrder: this.props.location.state.isFromPO ? this.props.location.state.poId : "",
            vendorId: "",
            invoiceDate: "",
            dueDate: "",
            invoiceAddress: "",
            deliveryAddress :"",
            vendorName: "",
            applicableTax: "",
            applicableTaxType: [],
            currencyDetails: [],
            remainingScope: [],
        }
    }

    componentDidMount() {
        this.getAllRejectedInvoices()
    }

    getAllRejectedInvoices = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllRejectedInvoice()
            .then(response => {
                this.setState({
                    isLoading: false,
                    rejectedInvoiceData: response.data
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getSingleRejectedInvoiceData = (invoiceId) => {
        this.setState({ isLoading: true, error: false })

        APIManager.callSingleRejectedInvoice(invoiceId)
            .then(response => {
                this.setState({
                    isLoading: false,
                    poGroupList: [],
                    poGroupData: response.data.productGroup,
                    totalQty: 0,
                    subTotal: 0,
                    grandTotal: 0,
                    grandTotalInOther: 0,
                    invoiceDate: convertDateStringMMDDtoDDMM(response.data.invoiceDate),
                    dueDate: convertDateStringMMDDtoDDMM(response.data.dueDate),
                    purchaseOrder: response.data.purchaseOrderId,
                    remainingScope: response.data.remainingScope ? response.data.remainingScope : [],
                    invoiceAddress: response.data.invoiceAddress,
                }, () => {
                    this.getSingleVendorDetail(response.data.vendor)
                    this.getShippngAddressOfVender()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    getSingleVendorDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(id)
            .then(response => {
                let number = response.number ? response.number.toUpperCase() : ""
                let name = response.name


                this.setState({
                    isLoading: false,
                    vendorId: id,
                    vendorName: number + " " + name,
                    applicableTaxType: response.taxApplicable,
                    currencyDetails: response.conversionDetail ? response.conversionDetail : []
                }, () => {
                    this.getSinglePOData()
                    this.getShippngAddressOfVender(id)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    getShippngAddressOfVender = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callVendorShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressListVendor: response,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        } 
    }

    getSinglePOData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPOGroupData(this.state.purchaseOrder)
            .then(response => {
                this.setState({
                    isLoading: false,
                }, () => {
                    this.setGroupData()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    setGroupData = () => {
        let data = this.state.poGroupData
        let group = []
        for (let i = 0; i < data.length; i++) {

            group.push(
                <ProductGroup
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    currencyDetails={this.state.currencyDetails}
                    key={"groupData" + i}
                    data={data[i]}
                    uom={data[i].uom}
                    vendorId={this.state.vendorId}
                    callBack={this.setUpdatedGroupData}
                    scopeDetails={this.state.remainingScope[i] ? this.state.remainingScope[i] : null}
                />
            )
        }
        this.setState({
            poGroupList: group
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let poGroupData = [...this.state.poGroupData]
        poGroupData[counter] = value;
        this.setState({
            poGroupData
        }, () => {
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.poGroupData
        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        for (let i = 0; i < data.length; i++) {
            subTotal += parseFloat(data[i].subTotal)
            grandTotal += parseFloat(data[i].total)
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        })
    }

    createDebitNote = () => {
        let {
            subTotal,
            grandTotal,
            poGroupData,
            _id_invoiceId,
            deliveryAddress ,

        } = this.state

        if (_id_invoiceId === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select Invoice ID"
            }, () => {
                this.scrollToTop()
            })
        } else {
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateDebitNote(_id_invoiceId,deliveryAddress , JSON.stringify(poGroupData), subTotal, grandTotal,)
                .then(response => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.history.push({
                            pathname: "/editdebitnote",
                            state: { debitNoteId: response.data._id, isNewEntry: true }
                        })
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }


    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isDebitNote={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Create Debit Note</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.createDebitNote()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice *</div>
                                    <div className="value">
                                        <Select2
                                            data={
                                                this.state.rejectedInvoiceData.map((data) => (
                                                    { id: data._id, text: data.poNumber.toUpperCase() + " Invoice No.: " + data.number.toUpperCase() }
                                                ))
                                            }
                                            value={this.state._id_invoiceId}
                                            options={{ placeholder: 'Select Invoice' }}
                                            onSelect={(e) => this.setState({ _id_invoiceId: e.target.value }, () => { this.getSingleRejectedInvoiceData(this.state._id_invoiceId) })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Vendor</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.vendorName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Address</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.invoiceAddress}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>



                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.invoiceDate}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Due Date</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.dueDate}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">delivery Address</div>
                                    <div className="value">
                                        <Select2
                                            data={
                                                this.state.addressListVendor.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.deliveryAddress }
                                            options={{ placeholder: 'Select Address' }}
                                            // onSelect={(e) => this.setState({ deliveryAddress: e.target.value })}
                                            onSelect={(e) => this.setState({ deliveryAddress : e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="main-container mt-5 invoice_product_group">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Received Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <th className='pg-tab-head'>Subtotal</th>
                                        }

                                        {
                                            this.state.applicableTaxType.map((data, i) => (
                                                <th className='pg-tab-head' key={'appTax' + i}>{data}</th>
                                            ))
                                        }
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <th className='pg-tab-head'>Eligible Tax*</th>
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Debit Qty</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.poGroupList}

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <td><b>{this.state.subTotal} <Bi.BiRupee /></b></td>
                                        }
                                        {
                                            this.state.applicableTaxType.map((data, i) => (
                                                <td key={'appTaxBlankField' + i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <td></td>
                                        }
                                        {
                                            this.state.currencyDetails.currencyName !== "" ?
                                                <td><b>{this.state.currencyDetails.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                                :
                                                <td><b>{this.state.grandTotal} <Bi.BiRupee /></b></td>

                                        }

                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.createDebitNote()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }

            </div>
        );
    }
}

export default CreateDebitNote;