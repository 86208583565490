import React, { Component } from 'react';
import AddProductDetail from './AddProductDetail';
import { BiRupee } from 'react-icons/bi';
import * as APIManager from './../../../../../APIManager/APIManager'

class ProductGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: [],

            totalOfProductCost: 0.00,
            totalOfProductCostFinal: 0.00,
            totalOfRawCostFinal: 0.00,
            totalNoOfProduct: 0,
            totalDiscountPrice: "",
            totalDiscountPercentage: "",

            applicableTax: [],
            applicableTaxLocal: [],
            productGroupList: [],
        }
        this.addNewGroupFields = this.addNewGroupFields.bind(this)
        this.addGroupFieldValues = this.addGroupFieldValues.bind(this)
    }

    componentDidMount(){
        this.getProductGroup()
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSellableProductGroup()
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response
            },()=>{
                this.setAllDetails()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setAllDetails = () => {
        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <AddProductDetail
                    key={'proGroup'} 
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={this.state.productGroupCounter}
                    productGroupList={this.state.productGroupList}
                />
            ]),
        })
        let applicableTaxLocal = localStorage.getItem("applicableTax");
        if(applicableTaxLocal !== null && applicableTaxLocal !== ""){
            let appTax = applicableTaxLocal.split(',')
            if(appTax.includes("exemptedtax")){
                appTax = ["IGST"]
            }
            this.setState({
                applicableTax: appTax,
                applicableTaxLocal: applicableTaxLocal.split(',')
            })
        }
    }

    addNewGroupFields = (counter) => {
        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <AddProductDetail                     
                        key={'proGroup'+this.state.productGroupCounter}
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter+1}
                        productGroupList={this.state.productGroupList}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: this.state.productGroupCounter+1,
            },()=>{
                this.setCounterText()
            })
        }
    }

    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        },()=>{
            localStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
            this.setCounterText()
        })
    }

    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        },()=>{
            localStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
        })
    }

    setCounterText = () => {
        var slides = document.getElementsByClassName("subgroupdata");
        for (var i = 0; i < slides.length; i++) {
            slides[i].firstChild.innerHTML = '<td>'+(i+1)+'</td>';
        }
    }


    countTotal = () => {
        let arr = this.state.productGroupData;
        let totalCost = 0;
        let totalQuantity = 0;
        let rawTotal = 0;
        for(let i=0;i<arr.length;i++){
            if(arr[i] !== null){
                totalCost += arr[i].subtotal !== "" ? parseFloat(arr[i].subtotal) : 0
                totalQuantity += arr[i].quantity !== "" ? parseFloat(arr[i].quantity) : 0 
                rawTotal += arr[i].rawtotal !== "" ? parseFloat(arr[i].rawtotal) : 0
            }
        }
        this.setState({
            totalOfProductCost: totalCost.toFixed(2),
            totalOfProductCostFinal: totalCost.toFixed(2),
            totalOfRawCostFinal: Math.round(rawTotal).toFixed(2),
            totalNoOfProduct: totalQuantity,
        },()=>{
            localStorage.setItem("totalOfProductCost", totalCost.toFixed(2))
            localStorage.setItem("totalDiscountPrice", 0)
            localStorage.setItem("totalOfRawCostFinal",Math.round(rawTotal.toFixed(2)))
            // this.setTotalDiscount()
        })
    }

    setTotalDiscount = () => {
        let {
            totalOfProductCost,
        } = this.state
        
        let disValue = this.state.totalDiscountPercentage
        let withdiscount = totalOfProductCost - ( totalOfProductCost*disValue/100 )

        let totalDiscount = totalOfProductCost - withdiscount;

        this.setState({
            totalOfProductCostFinal: withdiscount.toFixed(2),
            totalOfRawCostFinal: Math.round(withdiscount).toFixed(2),
            totalDiscountPrice: totalDiscount.toFixed(2),
            totalDiscountPercentage: disValue
        },()=>{
            localStorage.setItem("totalOfProductCost", withdiscount.toFixed(2))
            localStorage.setItem("totalDiscountPrice", totalDiscount.toFixed(2))
            localStorage.setItem("totalDiscountPercentage", disValue)
            localStorage.setItem("totalOfRawCostFinal", Math.round(withdiscount).toFixed(2))
        })
    }

    render() {
        return (
            <div className="main-container mt-20 order_product_group">
                <table>
                    <thead>
                        <tr>
                            <th className='pg-tab-head' style={{ width: 2}}></th>
                            <th className='pg-tab-head' style={{ width: 150 }}>Product group</th>
                            <th className='pg-tab-head' style={{ width: 200 }}>Product</th>
                            <th className='pg-tab-head'>Quantity</th>
                            <th className='pg-tab-head'>Price/UoM</th>
                            <th className='pg-tab-head'>Discount</th>
                            <th className='pg-tab-head'>Subtotal</th>
                            {
                                this.state.applicableTax.length > 0 &&
                                this.state.applicableTax.map((data)=>(
                                    <th className='pg-tab-head' key={'tab-index-'+data}>
                                        {data} 
                                        {
                                            this.state.applicableTaxLocal.includes("exemptedtax") &&
                                            <small>(Exempted)</small>
                                        }
                                    </th>
                                ))
                            }
                            <th className='pg-tab-head'>Total</th>
                            <th className='pg-tab-head'>Delivery date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        
                       {
                           this.state.productGroupFields
                       }
                        
                        {/* <tr>
                            <td></td>
                            <td><b>Discount</b></td>
                            <td></td>
                            <td></td>
                            <td>
                                <div className='position-relative'>
                                    <input 
                                        type='text' 
                                        className='input-box' 
                                        value={this.state.totalDiscountPercentage}
                                        onChange={(e)=>this.setState({ totalDiscountPercentage: e.target.value },()=>{ this.setTotalDiscount() })}
                                       
                                    ></input>
                                    <AiOutlinePercentage className='datepickerIcon cursor-na'/>
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <div className='position-relative'>
                                    <input type='text' value={this.state.totalDiscountPrice} className='input-box' disabled></input>
                                    <BiRupee className='datepickerIcon cursor-na'/>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr> */}
                        <tr>
                            <td></td>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td><b>{this.state.totalNoOfProduct}</b></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalOfProductCostFinal} <BiRupee /></b></td>
                            {
                                this.state.applicableTax.length > 0 &&
                                this.state.applicableTax.map((data)=>(
                                    <td key={'tab-index-'+data}></td>
                                ))
                            }
                            <td><b>{this.state.totalOfRawCostFinal} <BiRupee /></b></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProductGroup;