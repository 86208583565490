import React from 'react';
import * as APIManager from './../../APIManager/APIManager'
import { FiX } from "react-icons/fi";
import Select2 from './../../lib/select2/select2';
import './../../lib/select2/select2.css'

export default class AssignUserField extends React.Component{

    constructor(props){
        super(props);   
        this.state = {
            tablefield: this.props.tablefield,
            responseData: [],
        }
    }

    componentDidMount(){
        this.getUserList()
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                responseData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }
   
    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td style={{ width: "100%", padding: 0}}>
                    <select
                        value={this.state.tablefield}
                        style={{ width: "100%"}}
                        className="text-capitalize"
                        onChange={(e)=>{
                            this.setState({
                                tablefield: e.target.value
                            },()=>{
                                onEdit(this.state.tablefield, componentNum)
                                onBlur(this.state.tablefield, componentNum)
                            })
                        }}
                    >
                        <option>Select</option>
                        {
                            this.state.responseData !== null &&
                            this.state.responseData.map((data, i) => (
                                <option key={data._id} value={data._id}>{data.username ? data.username : data.full_name}</option>
                            ))
                        }
                    </select>
                    {/* <Select2    
                        className="width100 text-capitalize"
                        data={
                            this.state.responseData.map((data) => (
                                { id: data._id, text: data.full_name }
                            ))
                        }
                        value={this.state.tablefield}
                        options={{placeholder: 'Select User'}}
                        onSelect={(e)=> this.setState({ tablefield: e.target.value })}
                    /> */}
                </td>
                
                <td style={{width: 30, textAlign: 'center'}}>
                    {
                        this.state.tablefield !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}