import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateDepartment extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            is_calculatedAutomatically: true,
            is_setManually: false,
            average_wage: "",
            deptName: "",

        }
    }

    componentDidMount(){
        
    }
    
    wagetypeselect = (type) => {
        if(type === "is_calculatedAutomatically"){
            this.setState({
                is_calculatedAutomatically: true,
                is_setManually: false,
                average_wage: "",
            })
        }else{
            this.setState({
                is_calculatedAutomatically: false,
                is_setManually: true
            })
        }
    }
    

    createDepartment = () => {
        let {
            is_calculatedAutomatically,
            is_setManually,
            average_wage,
            deptName
        } = this.state

        if(deptName === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter department name"
            })
        }else if(is_setManually === true && average_wage ===  ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter average wage"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateDepartment(deptName, is_calculatedAutomatically, is_setManually, average_wage)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: '/editdepartment',
                        state: { departmentId: response.data._id, newDepartmentEntry: true }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }

   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isHR={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Department</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">


                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createDepartment()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value"><input type="text" className="fieldheightdecr" value={this.state.deptName} onChange={(e)=>this.setState({ deptName: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Average hourly wage</div>
                                <div className="value">
                                    <div onClick={()=>this.wagetypeselect('is_calculatedAutomatically')} className="cursor-pointer">
                                        <div className="term_radio_input">
                                            <input 
                                                type="radio"
                                                checked={this.state.is_calculatedAutomatically} 
                                                onChange={()=>this.wagetypeselect('is_calculatedAutomatically')}
                                            />
                                        </div>
                                        <div className="term_label">Calculate automatically</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div onClick={()=>this.wagetypeselect('is_setManually')} className="cursor-pointer">
                                        <div className="term_radio_input">
                                            <input 
                                                type="radio"
                                                checked={this.state.is_setManually} 
                                                onChange={()=>this.wagetypeselect('is_setManually')}
                                            />
                                        </div>
                                        <div className="term_label">Set manually</div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title"></div>
                                <div className="value"><input type="text" value={this.state.average_wage} onChange={(e)=>this.setState({ average_wage: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} className="fieldheightdecr" disabled={this.state.is_calculatedAutomatically} style={{ width: "60%" }}></input></div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createDepartment()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}