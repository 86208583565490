import React, { Component } from 'react'
import { Header, SettingsHeader } from './../../components'
import * as Ai from 'react-icons/ai'
import RichTextEditor from 'react-rte'
import * as APIManager from './../../APIManager/APIManager'
import renderHTML from 'react-render-html';
import { convertDateTimeStringMMDDtoDDMM } from '../../constants/DateFormatter'



export default class EditTicket extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      success: false,
      errormessage: "",
      successmessage: "",

      _id: this.props.location.state.ticketId,
      isNewEntry: this.props.location.state.isNewEntry,

      supportMessageEditor: RichTextEditor.createEmptyValue(),
      supportMessage: "",
      documentFile: [],

      category: "",
      created: "",
      description: "",
      dueDate: "",
      status: "",
      subCategory: "",
      subject: "",
      ticketDetail: [],
      ticketId: "",
      userId: "",

      fieldNotEditable: true,

    }
  }

  componentDidMount(){
    this.getDetailScreen()
  }

  getDetailScreen = () => {
      this.setState({ isLoading: true })
      APIManager.getSingleTicket(this.state._id)
      .then(response => {
        console.log(response)
          this.setState({
              isLoading: false,
              category: response.data.category,
              created: response.data.created,
              description: response.data.description,
              dueDate: response.data.dueDate,
              status: response.data.status,
              subCategory: response.data.subCategory,
              subject: response.data.subject,
              ticketDetail: response.data.ticketDetail,
              ticketId: response.data.ticketId,
              userId: response.data.userId
          })
      })
      .catch(errors => {
          this.setState({
              isLoading: false,
          })
      });
  }

  onTicketMessageChange = (value) => {
    this.setState({
        supportMessageEditor: value,
        supportMessage: value.toString('html')
    });
  }

  fileChange = (e) => {
    this.setState({ documentFile: e.target.files });
  }

  updateTicket = () => {
    let {
      _id,
      supportMessage,
      documentFile,
      fieldNotEditable,
      status
    } = this.state
    if(fieldNotEditable){
      if(status !== "closed"){
        this.setState({
          fieldNotEditable: false
        })
      }
    }else{
    if(supportMessage === ""){
      this.setState({
        error: true,
        success: false,
        errormessage: "Please enter message/descriotion"
      })
    }else{
      APIManager.getUpdateTicket(_id,supportMessage,documentFile)
        .then(response => {
          console.log(response)
          this.setState({
            isLoading: false,
            error: false,
            supportMessageEditor: RichTextEditor.createEmptyValue(),
            supportMessage: "",
            documentFile: [],
            fieldNotEditable: true,
          },()=>{
            this.getDetailScreen()
          })
        })
        .catch(errors => {
          this.setState({
            isLoading: false,
            error: true,
            errormessage: errors
          })
        });
      }
    }
  }

  closeTicket = () => {
    var conf = window.confirm("By closing this ticket you confirm that it has been completed your query.");
    if (conf == true) {
      APIManager.getCloseTicket(this.state._id)
      .then(response => {
        console.log(response)
        this.setState({
          isLoading: false,
          error: false,
          fieldNotEditable: true,
        },()=>{
          this.getDetailScreen()
        })
      })
      .catch(errors => {
        this.setState({
          isLoading: false,
          error: true,
          errormessage: errors
        })
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }
  
  render() {
    return (
      <div className="page absoluteWhite">
        <Header logout={this.props.history} goBack={() => this.goBack()} isSettings={true} />
        <SettingsHeader isSupport={true} />

        <div className="main-container">

          <div className="page-header">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-title">Support Ticket #{this.state.ticketId}</div>
              </div>
            </div>
          </div>

          <div className="pagecontent">
            {
              this.state.error &&
              <div className="editscreenerror">
                <div className="errornotification">
                  <Ai.AiOutlineExclamation className="notificon" /><span className="notiflink">{this.state.errormessage}</span>
                </div>
              </div>
            }
            {
              this.state.success &&
              <div className="editscreenerror">
                <div className="successnotification">
                  <Ai.AiOutlineExclamation className="notificon" /><span className="notiflink">{this.state.successmessage}</span>
                </div>
              </div>
            }
          </div>

          <div className="tableContent">
            <div className="addSaveButton mb-20">
              <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
              {
                this.state.status !== "closed" ?
                  <div className="add_btnSave" onClick={()=>this.updateTicket()}>{this.state.fieldNotEditable ? 'Edit' : 'Submit'}</div>
                :
                  <div className="disabled">Closed</div>
              }
              {
                this.state.status !== "closed" &&
                  <div className="add_btnBack" onClick={()=>this.closeTicket()}>Close</div>
              }
              <div className="clear"></div>
            </div>
            
            <div className='position-relative'>
                <div className="add_partside">
                    <div className="add_inner">
                        <div className="title">Subject</div>
                        <div className="value">
                            <div className="createdText">{this.state.subject}</div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="add_inner">
                        <div className="title">Category</div>
                        <div className="value">
                            <div className="createdText">{this.state.category}</div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="add_inner">
                        <div className="title">Sub Category</div>
                        <div className="value">
                            <div className="createdText">{this.state.subCategory}</div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                <div className="add_partside">
                    <div className="add_inner">
                        <div className="title">Created</div>
                        <div className="value">
                            <div className="createdText">{convertDateTimeStringMMDDtoDDMM(this.state.created)}</div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="add_inner">
                        <div className="title">Due date</div>
                        <div className="value">
                            <div className="createdText">{convertDateTimeStringMMDDtoDDMM(this.state.dueDate)}</div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <div className="add_inner">
                        <div className="title">Current Status</div>
                        <div className="value">
                            <div className="createdText text-capitalize">{this.state.status}</div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                <div className="clear"></div>
            </div>

            <div className="position-relative">
                <div className="ticketResponses">
                  <ul className='ticket-list-ul'>
                  {
                    this.state.ticketDetail.map((data, i)=>(
                      <li className={data.replyId === this.state.userId ? 'user-icon' : 'oemup-icon'}>
                        <div className="clr-secondary text-capitalize">
                          <div className='float-left'>{data.replyId === this.state.userId ? data.replyBy : 'OEMUp'}</div>
                          <div className='float-right'>{convertDateTimeStringMMDDtoDDMM(data.modified)}</div>
                          <div className='clear'></div>
                        </div>
                        <div className='ticket-list-description'>{renderHTML(data.description)}</div>
                        <div>
                          {
                            data.files &&
                            data.files.map((d, j) => (
                              <div>
                                <span className="clr-graydark">Attachment {j+1}</span> : <a href={APIManager.UPLOAD_PUBLIC+d} target="_blank" className='clr-secondary'>View Attachment</a>
                              </div>
                            ))
                          }
                        </div>
                      </li>
                    ))
                  }
                  </ul>
                  <div className="clear"></div>
                </div>
            </div>
            <div className="clear"></div>
                
          {
            this.state.status !== "closed" &&
              <div>
                <div className="add_outer_full_part position-relative">
                  <div className="title">Reply</div>
                  <div className='value width85 float-left'>
                      {/* <RichTextEditor
                          value={this.state.supportMessageEditor}
                          onChange={this.onTicketMessageChange} 
                          rootStyle={{ minHeight: 170 }}
                      /> */}
                      <textarea className="textarea textarea-height100" value={this.state.supportMessage} onChange={(e)=>this.setState({ supportMessage: e.target.value })}>
                      </textarea>
                  </div>
                  <div className="clear"></div>
                  {
                      this.state.fieldNotEditable &&
                      <div className="disableFieldCover"></div>
                  }
              </div>
              <div className="add_outer_full_part position-relative mt-1">
                  <div className="title">File</div>
                  <div className="value width85 float-left">
                    <input 
                      type="file" 
                      className="fieldheightdecr input-file" 
                      name="file" 
                      onChange={(e) => this.fileChange(e)}
                      accept="image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
                      multiple
                    />
                  </div>
                  <div className="clear"></div>
                  {
                      this.state.fieldNotEditable &&
                      <div className="disableFieldCover"></div>
                  }
              </div>
            </div>
          }

           

            <div className="addSaveButton">
              <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
              {
                this.state.status !== "closed" ?
                  <div className="add_btnSave" onClick={()=>this.updateTicket()}>{this.state.fieldNotEditable ? 'Edit' : 'Submit'}</div>
                :
                  <div className="disabled">Closed</div>
              }
              {
                this.state.status !== "closed" &&
                  <div className="add_btnBack" onClick={()=>this.closeTicket()}>Close</div>
              }
            </div>
          </div>


        </div>
      </div>
    )
  }
}
