import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Helper from './../../../helper/helper'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateFilterTable extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",
            
            countFieldNumber: [],
            tablename: "",
            tableFields: [],
            tableValue: [],

            fieldNotEditable: true,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount(){
        this.getTable()   
    }


    getTable = () => {
        let that = this;
        let id = localStorage.getItem("customTableId")
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("id", id)
        formdata.append('filterFlag', false)

        fetch(APIManager.WEBSERVICES_URL+'stock/filterTable/getSingleFilterTable',{
            method: "post",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
               if(result.status === "success"){
                   that.setState({
                        tablename: result.data[0].tableName,
                        tableFields: result.data[0].fields,
                   },()=>{
                        that.getTableValue()
                   })
                } else if(result.status === "userDataError"){
                    that.logout()
                } else{
                    that.setState({
                        error: true,
                        errormessage: result.message
                    })
               }
            },(error) => {
               console.log(error)
            }
        ) 
    }

    getTableValue = () => {
        let that = this;
        let tableId = localStorage.getItem("customTableId")
        let id = localStorage.getItem("customTableValueId")

        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("tableId", tableId)
        formdata.append("id", id)

        fetch(APIManager.WEBSERVICES_URL+'stock/filterTableValue/getSingleFilterTableValue',{
            method: "post",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
               if(result.status === "success"){
                    that.setState({
                        tableValue: result.data
                    },()=>{
                        that.setValues()
                    })
                } else if(result.status === "userDataError"){
                    that.logout()
                } else{
                    that.setState({
                        error: true,
                        errormessage: result.message
                    })
               }
            },(error) => {
               console.log(error)
            }
        ) 
    }

    deleteTable = () => {
        let slug = "Stock_FilterTable"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
            
                this.setState({ loading: true })
                const that = this;
        
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token
                

                let tableId = localStorage.getItem("customTableId")
                let id = localStorage.getItem("customTableValueId")

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("tableId", tableId)
                formdata.append("id", id)
                
                fetch(APIManager.WEBSERVICES_URL+'stock/filterTableValue/deleteFilterTableValue',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                            that.setState({ loading: false },()=> {
                                that.goBack()
                            })

                    }else if(result.status === "userDataError"){
                            that.setState({ loading: false },()=> {
                                that.logout()
                            })
                    }else{
                            that.setState({
                                loading: false,
                                error: true,
                                errormessage: result.message
                            })
                    }

                    },(error) => {
                    console.log(error)
                    }
                )
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    setValues = () => {
        this.state.tableFields.map((data)=>(
            this.setState({
                [data]: this.state.tableValue[data]
            })
        ))
    }
    

    handleInputChange = (e, name) => {
        this.setState({
            [name]: e.target.value
        })
    }
   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewCustomTableValue") !== null){
            localStorage.removeItem("createNewCustomTableValue")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    updateTable = () => {
        if(this.state.fieldNotEditable){
            let slug = "Stock_FilterTable"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }else{
            let obj = {}
            let blankField = false;

            for(let i=0;i<this.state.tableFields.length;i++){
                let index = this.state.tableFields[i];
                let value = this.state[this.state.tableFields[i]]
                if(value === "" || typeof(value) === 'undefined'){
                    alert("Please fill all fields first")
                    blankField = true;
                    break;
                }else{
                    obj[index] = value;
                }
            }
            if(blankField !== true){

                let that = this;
                let tableId = localStorage.getItem("customTableId")
                let id = localStorage.getItem("customTableValueId")
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("tableId", tableId) 
                formdata.append("id", id)
                formdata.append("value", JSON.stringify(obj))

                fetch(APIManager.WEBSERVICES_URL+'stock/filterTableValue/updateFilterTableValue',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                        that.setState({
                            error: false,
                            errormessage: ""
                        },()=>{
                            that.goBack()
                        })
                    }else if(result.status === "userDataError"){
                        that.logout()
                    } else{
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                    }
                    },(error) => {
                        console.log(error)
                    }
                ) 
            }
        }
    }





    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isFilterTable={true}/>
                
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Edit a {this.state.tablename}</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                
            

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateTable()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteTable()}>Delete</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="mb-5 mt-5 position-relative">
                       {
                            this.state.tableFields !== null &&
                            this.state.tableFields.map((data, i)=>(
                                <div className="add_partside" key={"addFields"+i}>
                                    <div className="add_inner">
                                        <div className="title capitalize">{data}</div>
                                        <div className="value">
                                            <input type="text" className="fieldheightdecr" autoComplete="off" defaultValue={this.state.tableValue[data]} onBlur={(e)=>this.handleInputChange(e, data)} onChange={(e)=>this.handleInputChange(e, data)} />
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            ))
                       }
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateTable()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteTable()}>Delete</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}