import React, { Component } from 'react';
import { Header, CRMHeader } from '../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Popup from 'reactjs-popup';
import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import * as Ai from "react-icons/ai"
import * as Im from "react-icons/im";
import * as Fi from 'react-icons/fi'
import * as ExportData from './exportData'
import TableLoading from '../../../helper/TableLoading';
import { convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

class PumpRegister extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            totalRecords: 0,
            activePage: 1,
            start: 0,
            length: 25,

            responseData: [],
            companyIndustryData: [],
            impellerMOCData: [],
            impellerTypeData: [],
            mainMOCData: [],
            mechSealData: [],
            motorRatingData: [],
            responseItems: [],

            number: "", 
            customerNumber: "",
            clientName: "", 
            project: "", 
            industryType: "", 
            city: "", 
            country: "",
            pumpTagNo: "", 
            application: "",
            fluid: "", 
            solid: "", 
            temp: "",
            pumpSerialNo: "", 
            itemId: "", 
            pumpModelNo: "",
            mainMoc: "",
            impellerMoc: "",
            impellerType: "", 
            impellerSize: "", 
            mechSeal: "", 
            head: "", 
            flow: "", 
            motorRating: "",
            minDateOfDispatch: "",
            maxDateOfDispatch: "",
            minDateOfInstallation: "",
            maxDateOfInstallation: "",

            selectedFile: null,
            showImportPopUp: false,
            isUploadStarting: false,

            chooseColumnList: [
                {
                    id: "0",
                    name: "Number",
                    visible: true,
                    field: "number", 
                    fixed: false,
                },{
                    id: "1",
                    name: "Customer No",
                    visible: true,
                    field: "customerNumber", 
                },{
                    id: "2",
                    name: "Customer",
                    visible: true,
                    field: "clientName", 
                },{
                    id: "3",
                    name: "Project",
                    visible: true,
                    field: "project", 
                },{
                    id: "4",
                    name: "Industry Type",
                    visible: true,
                    field: "industryType", 
                },{
                    id: "5",
                    name: "Country",
                    visible: false,
                    field: "country",
                },{
                    id: "6",
                    name: "City",
                    visible: true,
                    field: "city", 
                },{
                    id: "7",
                    name: "Tag No",
                    visible: true,
                    field: "pumpTagNo", 
                },{
                    id: "8",
                    name: "Application",
                    visible: false,
                    field: "application",
                },{
                    id: "9",
                    name: "Fluid",
                    visible: false,
                    field: "fluid", 
                },{
                    id: "10",
                    name: "Solid",
                    visible: false,
                    field: "solid", 
                },{
                    id: "11",
                    name: "Temp",
                    visible: false,
                    field: "temp",
                },{
                    id: "12",
                    name: "Serial No",
                    visible: true,
                    field: "pumpSerialNo", 
                    fixed: false, 
                },{
                    id: "13",
                    name: "Model No",
                    visible: true,
                    field: "pumpModelNo", 
                    fixed: false,
                },{
                    id: "14",
                    name: "Main MOC",
                    visible: false,
                    field: "mainMoc",
                },{
                    id: "15",
                    name: "Impeller MOC",
                    visible: false,
                    field: "impellerMoc",
                },{
                    id: "16",
                    name: "Impeller Type",
                    visible: false,
                    field: "impellerType",
                },{
                    id: "17",
                    name: "Impeller Size",
                    visible: false,
                    field: "impellerSize",
                },{
                    id: "18",
                    name: "Mech Seal",
                    visible: false,
                    field: "mechSeal",
                },{
                    id: "19",
                    name: "Head",
                    visible: false,
                    field: "head",
                },{
                    id: "20",
                    name: "Flow",
                    visible: false,
                    field: "flow",
                },{
                    id: "21",
                    name: "Motor Rating",
                    visible: false,
                    field: "motorRating",
                },{
                    id: "22",
                    name: "Date Of Dispatch",
                    visible: true,
                    field: "dateOfDispatch",
                },{
                    id: "23",
                    name: "Date Of Installation",
                    visible: true,
                    field: "dateOfInstallation",
                },{
                    id: "24",
                    name: "Delivery date as per PO",
                    visible: true,
                    field: "deliveryDateAsPerPO",
                }
            ],
            ascending: true,
            sortValue: "",
        }
    }

    componentDidMount(){
        let checkColumns = localStorage.getItem("pumpSelected__Columns")
        if(checkColumns){
            let col = JSON.parse(checkColumns)
            this.setState({
                chooseColumnList: col
            },()=>{
                this.callAllSelections()
            })
        }else{
            this.callAllSelections()
        }     
        this.checkAlfaUser()
    }
    // ascending discending function start

    handleSort = (field) => {
        this.setState(state =>{
            let ascending = state.sortValue === field ? !state.ascending: true;
            return {
                sortValue: field,
                ascending: ascending
            }
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.sortValue !== this.state.sortValue || prevState.ascending !== this.state.ascending ){
        let sortedData = this.state.responseItems;
            sortedData = sortedData.sort((a, b) => {
                if(this.state.ascending) {
                    console.log('if succces');
                    return a[this.state.sortValue] > b[this.state.sortValue] ? 1 : -1;
                }else {
                    console.log('else succces');
                    return a[this.state.sortValue] < b[this.state.sortValue] ? 1 : -1;
                }
            });
            this.setState({
                responseItems: sortedData
            });
        }
      }
    // ascending discending function end
    checkAlfaUser = () => {
        let alfaUserAccountId = "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
        let user = localStorage.getItem('userdata') 
        if(user){
            let accountId = JSON.parse(localStorage.getItem('userdata')).account_id
            if(alfaUserAccountId !== accountId){
                this.props.history.push({
                    pathname: "/home"
                })
            }
        }
    }

    callAllSelections = () => {
        this.getCompanyIndustry()
        this.getItemList()
        this.callGetImpellerMOC()
        this.callGetImpellerType()
        this.callGetMainMOC()
        this.callGetMechSeal()
        this.callGetMotorRating()
        this.getFilters()
    }

    getFilters = () => {
        let obj = sessionStorage.getItem("pumpFilterObj")
        if(obj){
            let filter = JSON.parse(obj)

            this.setState({
                start: filter.start,
                length: filter.length,
                number: filter.number, 
                customerNumber: filter.customerNumber,
                clientName: filter.clientName, 
                project: filter.project, 
                industryType: filter.industryType, 
                city: filter.city, 
                country: filter.country,
                pumpTagNo: filter.pumpTagNo, 
                application: filter.application,
                fluid: filter.fluid, 
                solid: filter.solid, 
                temp: filter.temp,
                pumpSerialNo: filter.pumpSerialNo, 
                itemId: filter.itemId, 
                pumpModelNo: filter.pumpModelNo,
                mainMoc: filter.mainMoc,
                impellerMoc: filter.impellerMoc,
                impellerType: filter.impellerType, 
                impellerSize: filter.impellerSize, 
                mechSeal: filter.mechSeal, 
                head: filter.head, 
                flow: filter.flow, 
                motorRating: filter.motorRating,
                minDateOfDispatch: filter.minDateOfDispatch ? new Date(filter.minDateOfDispatch) : "",
                maxDateOfDispatch: filter.maxDateOfDispatch ? new Date(filter.maxDateOfDispatch) : "",
                minDateOfInstallation: filter.minDateOfInstallation ? new Date(filter.minDateOfInstallation) : "",
                maxDateOfInstallation: filter.maxDateOfInstallation ? new Date(filter.maxDateOfInstallation) : ""
            },()=>{
                this.getAllPumps()
            })
        }else{
            this.getAllPumps()
        }
    }
    getAllPumps = () => {
        let {
            start,
            length,
            number, 
            customerNumber,
            clientName, 
            project, 
            industryType, 
            city, 
            country,
            pumpTagNo, 
            application,
            fluid, 
            solid, 
            temp,
            pumpSerialNo, 
            itemId, 
            pumpModelNo,
            mainMoc,
            impellerMoc,
            impellerType, 
            impellerSize, 
            mechSeal, 
            head, 
            flow, 
            motorRating,
            minDateOfDispatch,
            maxDateOfDispatch,
            minDateOfInstallation,
            maxDateOfInstallation
        } = this.state
        
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllRegisteredPump(start, length, number, customerNumber,clientName, project, industryType, city, country,pumpTagNo, application, fluid, solid, temp, pumpSerialNo, itemId, pumpModelNo, mainMoc,impellerMoc,impellerType, impellerSize, mechSeal, head, flow, motorRating, convertDateToMMDDYYYY(minDateOfDispatch), convertDateToMMDDYYYY(maxDateOfDispatch), convertDateToMMDDYYYY(minDateOfInstallation), convertDateToMMDDYYYY(maxDateOfInstallation))
        .then(response => {
            this.setState({
                isLoading: false,
                responseData: response.data,
                totalRecords: response.totalRecords
            },()=>{
                this.setFilters()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setFilters = () => {
        let pumpFilterObj = {
            start: this.state.start,
            length: this.state.length,
            number: this.state.number, 
            customerNumber: this.state.customerNumber,
            clientName: this.state.clientName, 
            project: this.state.project, 
            industryType: this.state.industryType, 
            city: this.state.city, 
            country: this.state.country,
            pumpTagNo: this.state.pumpTagNo, 
            application: this.state.application,
            fluid: this.state.fluid, 
            solid: this.state.solid, 
            temp: this.state.temp,
            pumpSerialNo: this.state.pumpSerialNo, 
            itemId: this.state.itemId, 
            pumpModelNo: this.state.pumpModelNo,
            mainMoc: this.state.mainMoc,
            impellerMoc: this.state.impellerMoc,
            impellerType: this.state.impellerType, 
            impellerSize: this.state.impellerSize, 
            mechSeal: this.state.mechSeal, 
            head: this.state.head, 
            flow: this.state.flow, 
            motorRating: this.state.motorRating,
            minDateOfDispatch: this.state.minDateOfDispatch,
            maxDateOfDispatch: this.state.maxDateOfDispatch,
            minDateOfInstallation: this.state.minDateOfInstallation,
            maxDateOfInstallation: this.state.maxDateOfInstallation
        }
        sessionStorage.setItem("pumpFilterObj",JSON.stringify(pumpFilterObj))
    }

    exportOptions = (type) => {
        if(type === 'pdf'){
            ExportData.exportPumpPDF(this.state.responseData, this.state.chooseColumnList);
        }else{
            ExportData.exportPumpCSV(this.state.responseData, this.state.chooseColumnList);
        }
    }


    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
        .then(response => {
            let res = response.companyIndustry !== undefined ? response.companyIndustry : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].industrytype, text: res[i].industrytype }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                companyIndustryData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.data 
            })
        });
    }


    getItemList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetItemList("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "")
        .then(response => {
            this.setState({
                isLoading: false,
                responseItems: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    callGetImpellerMOC = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerMOC()
        .then(response => {
            let res = response.data.impellerMoc !== undefined ? response.data.impellerMoc : []
            let updatedData = []
            if(res.length > 0){

                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].impellerMoc, text: res[i].impellerMoc }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                impellerMOCData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    callGetImpellerType = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerType()
        .then(response => {
            let res = response.data.impellerType !== undefined ? response.data.impellerType : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].impellerType, text: res[i].impellerType }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                impellerTypeData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    callGetMainMOC = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMainMOC()
        .then(response => {
            let res = response.data.mainMoc !== undefined ? response.data.mainMoc : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].mainMoc, text: res[i].mainMoc }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                mainMOCData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    callGetMechSeal = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMechSeal()
        .then(response => {
            let res = response.data.mechSeal !== undefined ? response.data.mechSeal : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].mechSeal, text: res[i].mechSeal }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                mechSealData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    callGetMotorRating = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMotorRating()
        .then(response => {
            let res = response.data.motorRating !== undefined ? response.data.motorRating : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].motorRating, text: res[i].motorRating }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                motorRatingData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }




  

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    onFileUpload = () => {
        let {
            selectedFile
        } = this.state
        if(selectedFile){
            this.setState({ isLoading: true, error: false, isUploadStarting: true })
            APIManager.callUploadPumpRegisterCSV(selectedFile)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    success: true,
                    isUploadStarting: false,
                    successmessage: "Pumps imported successfully."
                },()=>{
                    this.getAllPumps()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    isUploadStarting: false,
                    errormessage: errors 
                })
            });
        }else{
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select file to upload"
                
            })
        }
      }

      downloadSampleCSV = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDownloadPumpRegisterSampleCSV()
        .then(response => {
            //Build a URL from the file
            const fileURL = APIManager.BASE_URL+'/public'+response;
            //Open the URL on new Window      
            this.setState({ 
                isLoading: false,
            },()=>{
                this.openInNewTab(fileURL)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    onClosePopup = () => {
        this.setState({
           error: false,
           success: false,
           selectedFile: null
        })
    }




    clearSearch = () => {
        this.setState({
            number: "", 
            customerNumber: "",
            clientName: "", 
            project: "", 
            industryType: "", 
            city: "", 
            country: "",
            pumpTagNo: "", 
            application: "",
            fluid: "", 
            solid: "", 
            temp: "",
            pumpSerialNo: "", 
            itemId: "", 
            pumpModelNo: "",
            mainMoc: "",
            impellerMoc: "",
            impellerType: "", 
            impellerSize: "", 
            mechSeal: "", 
            head: "", 
            flow: "", 
            motorRating: "",
            minDateOfDispatch: "",
            maxDateOfDispatch: "",
            minDateOfInstallation: "",
            maxDateOfInstallation: ""
        },()=>{
            this.getAllPumps()
        })
    }


    addPump = () => {
        let slug = "CRM_PumpRegister"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createnewpump"
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

 

    updateColumn = (field, isVisible) => {
        let colums = this.state.chooseColumnList
        let col = [...this.state.chooseColumnList]
        var foundIndex = colums.findIndex(x => x.field === field);
        col[foundIndex].visible = !isVisible;

        this.setState({
            chooseColumnList: col
        },()=>{
            localStorage.setItem("pumpSelected__Columns", JSON.stringify(col))
        })
    }

    editPump = (id) => {
        this.props.history.push({
            pathname: "/editpump",
            state: { pumpId: id, isNewEntry: false }
        }) 
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length*(pageNumber-1) : 0
        this.setState({
            isLoading: true,
            activePage: pageNumber,
            start: newStart
        },()=>{ 
            this.getAllPumps()
        });
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isPumpRegister={true}/>
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Pump Register</div>
                            </div>
                            <div className="col-lg-2 col-md-8 col-sm-8 col-xs-12">
                                
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.exportOptions('pdf')}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={()=>this.exportOptions('csv')}>CSV <Im.ImArrowDown2 /></div>
                                    <Popup modal arrow={true} trigger={<div className="button">Import from CSV <Im.ImArrowUp2 /></div>} position="center" onClose={()=>this.onClosePopup()}>
                                    <div className='head-import-buttons selectFile__Popup__Item'>
                                        <div className="pagecontent">
                                            {
                                                this.state.error &&
                                                <div className="mb-2">
                                                    <div className="errornotification">
                                                        <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.success &&
                                                <div className="mb-2">
                                                    <div className="successnotification">
                                                        <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <input 
                                            type="file"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={this.onFileChange}
                                        ></input>
                                        {
                                            this.state.isUploadStarting ?
                                                <div className="button mt-2">Upload <Im.ImArrowUp2 /></div>
                                            :
                                                <div className="button mt-2" onClick={()=>this.onFileUpload()}>Upload <Im.ImArrowUp2 /></div>
                                        }
                                        
                                    </div>
                                </Popup>
                                <div className="button" onClick={()=>this.downloadSampleCSV('csv')}>Sample CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton"><div onClick={()=>this.addPump()}><Fi.FiPlus /></div></th>
                                    
                                    {
                                        this.state.chooseColumnList.map((data, i) => {
                                            if(data.visible === true)
                                            return <th className="tableHeader" key={'tableHeader'+i}><span onClick={() => this.handleSort(data.field)}>{data.name}{this.state.sortValue === data.field && (this.state.ascending ? <span><Fi.FiArrowUp /></span> : <span><Fi.FiArrowDown /></span>)}</span></th>
                                        })
                                    }
                                    <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><Fi.FiChevronsDown /></th>} position="bottom right">
                                        <div className="selectColumns">
                                            <ul>
                                                {
                                                    this.state.chooseColumnList.map((data, i) => {
                                                        if(data.fixed)
                                                            return <li key={'chooseColumn'+i}><label htmlFor={data.field}><span>{data.name}</span> <input type="checkbox" checked={data.visible} disabled></input> </label></li>
                                                        else
                                                            return <li key={'chooseColumn'+i}><label htmlFor={data.field}><span>{data.name}</span> <input type="checkbox" checked={data.visible} onChange={(e)=>this.updateColumn(data.field, data.visible)}></input> </label></li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </Popup>
                                    <th className="tableHeader tableHeadButton"><div onClick={()=>this.addPump()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableElemContain tableAddButton"></th>

                                    {
                                        this.state.chooseColumnList[0].visible &&
                                        <th className="tableElemContain"><input type="text" onChange={(e)=>this.setState({ number: e.target.value },()=>{ this.getAllPumps() })} value={this.state.number} /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[1].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.customerNumber} onChange={(e)=>this.setState({ customerNumber: e.target.value },()=>{ this.getAllPumps() })} /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[2].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.clientName} onChange={(e)=>this.setState({ clientName: e.target.value },()=>{ this.getAllPumps() })} /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[3].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.project} onChange={(e)=>this.setState({ project: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[4].visible &&
                                        <th className="tableElemContain">
                                            <select value={this.state.industryType} onChange={(e)=>this.setState({ industryType: e.target.value }, ()=> { this.getAllPumps() })} className="text-capitalize">
                                                <option></option>
                                                {
                                                    this.state.companyIndustryData.map((data, i)=>(
                                                        <option value={data.text} key={i+'compIndus'}>{data.text}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[5].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.country} onChange={(e)=>this.setState({ country: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[6].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.city} onChange={(e)=>this.setState({ city: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[7].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.pumpTagNo} onChange={(e)=>this.setState({ pumpTagNo: e.target.value },()=>{ this.getAllPumps() })} /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[8].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.application} onChange={(e)=>this.setState({ application: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[9].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.fluid} onChange={(e)=>this.setState({ fluid: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[10].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.solid} onChange={(e)=>this.setState({ solid: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[11].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.temp} onChange={(e)=>this.setState({ temp: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[12].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.pumpSerialNo} onChange={(e)=>this.setState({ pumpSerialNo: e.target.value },()=>{ this.getAllPumps() })}  /></th>
                                    }
                                    {
                                        this.state.chooseColumnList[13].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.pumpModelNo} onChange={(e)=>this.setState({ pumpModelNo: e.target.value },()=>{ this.getAllPumps() })}/></th>
                                    }
                                    {
                                        this.state.chooseColumnList[14].visible &&
                                        <th className="tableElemContain">
                                            <select value={this.state.mainMoc} onChange={(e)=>this.setState({ mainMoc: e.target.value }, ()=> { this.getAllPumps() })} className="text-capitalize">
                                                <option></option>
                                                {
                                                    this.state.mainMOCData.map((data, i)=>(
                                                        <option value={data.text} key={i+'compIndus'}>{data.text}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[15].visible &&
                                        <th className="tableElemContain">
                                            <select value={this.state.impellerMoc} onChange={(e)=>this.setState({ impellerMoc: e.target.value }, ()=> { this.getAllPumps() })} className="text-capitalize">
                                                <option></option>
                                                {
                                                    this.state.impellerMOCData.map((data, i)=>(
                                                        <option value={data.text} key={i+'compIndus'}>{data.text}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[16].visible &&
                                        <th className="tableElemContain">
                                            <select value={this.state.impellerType} onChange={(e)=>this.setState({ impellerType: e.target.value }, ()=> { this.getAllPumps() })} className="text-capitalize">
                                                <option></option>
                                                {
                                                    this.state.impellerTypeData.map((data, i)=>(
                                                        <option value={data.text} key={i+'compIndus'}>{data.text}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[17].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.impellerSize} onChange={(e)=>this.setState({ impellerSize: e.target.value },()=>{ this.getAllPumps() })}/></th>
                                    }
                                    {
                                        this.state.chooseColumnList[18].visible &&
                                        <th className="tableElemContain">
                                            <select value={this.state.mechSeal} onChange={(e)=>this.setState({ mechSeal: e.target.value }, ()=> { this.getAllPumps() })} className="text-capitalize">
                                                <option></option>
                                                {
                                                    this.state.mechSealData.map((data, i)=>(
                                                        <option value={data.text} key={i+'compIndus'}>{data.text}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[19].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.head} onChange={(e)=>this.setState({ head: e.target.value },()=>{ this.getAllPumps() })}/></th>
                                    }
                                    {
                                        this.state.chooseColumnList[20].visible &&
                                        <th className="tableElemContain"><input type="text" value={this.state.flow} onChange={(e)=>this.setState({ flow: e.target.value },()=>{ this.getAllPumps() })}/></th>
                                    }
                                    {
                                        this.state.chooseColumnList[21].visible &&
                                        <th className="tableElemContain">
                                            <select value={this.state.motorRating} onChange={(e)=>this.setState({ motorRating: e.target.value }, ()=> { this.getAllPumps() })} className="text-capitalize">
                                                <option></option>
                                                {
                                                    this.state.motorRatingData.map((data, i)=>(
                                                        <option value={data.text} key={i+'compIndus'}>{data.text}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    


                                    {
                                        this.state.chooseColumnList[22].visible &&
                                        <th className="tableElemContain">
                                            <div className="value mb-1">  
                                                <DatePicker 
                                                    dateFormat="dd/MM/yyy"
                                                    selected={
                                                        this.state.minDateOfDispatch
                                                    }
                                                    onChange={
                                                        date => this.setState({ minDateOfDispatch: date },()=>{
                                                            this.getAllPumps()
                                                        })
                                                    }
                                                    className="filterdateoption"/> <span className="dash">-</span>
                                                <Ai.AiOutlineCalendar className="datepickerIcon"/>
                                            </div>
                                
                                            <div className="value">  
                                                <DatePicker 
                                                    dateFormat="dd/MM/yyy"
                                                    minDate={this.state.minDateOfDispatch}
                                                    selected={
                                                        this.state.maxDateOfDispatch
                                                    }
                                                    onChange={
                                                        date => this.setState({ maxDateOfDispatch: date },()=>{
                                                            this.getAllPumps()
                                                        })
                                                    }
                                                    className="filterdateoption"/>
                                                <Ai.AiOutlineCalendar className="datepickerIcon"/>
                                            </div>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[23].visible &&
                                        <th className="tableElemContain">
                                            <div className="value mb-1">  
                                                <DatePicker 
                                                    dateFormat="dd/MM/yyy"
                                                    selected={
                                                        this.state.minDateOfInstallation
                                                    }
                                                    onChange={
                                                        date => this.setState({ minDateOfInstallation: date },()=>{
                                                            this.getAllPumps()
                                                        })
                                                    }
                                                    className="filterdateoption"/> <span className="dash">-</span>
                                                <Ai.AiOutlineCalendar className="datepickerIcon"/>
                                            </div>
                                
                                            <div className="value">  
                                                <DatePicker 
                                                dateFormat="dd/MM/yyy"
                                                    minDate={this.state.minDateOfInstallation}
                                                    selected={
                                                        this.state.maxDateOfInstallation
                                                    }
                                                    onChange={
                                                        date => this.setState({ maxDateOfInstallation: date },()=>{
                                                            this.getAllPumps()
                                                        })
                                                    }
                                                    className="filterdateoption"/>
                                                <Ai.AiOutlineCalendar className="datepickerIcon"/>
                                            </div>
                                        </th>
                                    }
                                    {
                                        this.state.chooseColumnList[23].visible &&
                                        <th className="tableElemContain"></th>
                                    }
                                    
                                    
                                    
                                    <th></th>
                                    <th className="tableElemContain tableAddButton"><div onClick={()=>this.clearSearch()} className="cursor-pointer"><Fi.FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading 
                                        tr={8} 
                                        td={
                                            this.state.chooseColumnList.filter(function(data){
                                                return data.visible == true;
                                            }).length+3
                                        } 
                                    />
                                :
                                <tbody>
                                {
                                    this.state.responseData !== null &&
                                    this.state.responseData.map((data, i) => (
                                        <tr key={data._id} onClick={this.editPump.bind(this,data._id)}>
                                            <td>{this.state.activePage === 1 ? 1+i : this.state.start+1+i }</td>
                                            {
                                                 this.state.chooseColumnList.map((d, j) => {
                                                    if (d.visible === true){
                                                        if(d.name === "Date Of Dispatch" || d.name === "Date Of Installation" || d.name === "Delivery date as per PO"){
                                                            var dateString = data[d.field] ? data[d.field].toString() : ''; 
                                                            var dateParts = dateString ? dateString.split("/") : '';
                                                            var dateObject = dateParts ? dateParts[1]+'/'+dateParts[0]+'/'+dateParts[2] : '';
                                                            return <td key={'selectedCol' + j}>{dateObject}</td>
                                                        }else{
                                                            return <td key={'selectedCol' + j}>{data[d.field] ? data[d.field].toString() : ''}</td>
                                                        }
                                                    }
                                                })
                                            }
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{width: 80}}>
                                    <select value={this.state.length} onChange={(e)=>this.setState({ length: parseInt(e.target.value) },()=>{ this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PumpRegister;