import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Logo } from './../../assets/Images'
import { FiLogOut } from 'react-icons/fi'
import TaskManager from '../../components/TaskManager';

class Header extends Component {
    
    constructor(props){
        super(props)
        var today = new Date(),
            date = ("0" + today.getDate()).slice(-2)  + '-' + ("0" + (today.getMonth() + 1)).slice(-2)  + '-' + today.getFullYear()

        this.state = {
            userEmail: "loading",
            date: date,
            time: today.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }),
        }
    }

    componentDidMount(){
        let userData = localStorage.getItem('userdata')
        if(userData !== null){
            let email = JSON.parse(userData).email
            this.setState({
                userEmail: email
            })
        }
    }

    logout = (history) => {
        localStorage.clear();
        history.replace('/login')
    }
    render() {
        let {
            history
        } = this.props
        return (
            <div className="top-header bg-white">
                {/* <Offline><div className="__offline_message">You are currently offline. Please check your internet connection.</div></Offline> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                            <div className="d-flex">
                                <div className="home-tab pl-0">
                                    <Link className="header-Logo" to="/home">
                                        <img src={Logo} className="header-Logo" alt="logo" />
                                    </Link>
                                </div>
                                <div className="home-tab">
                                    <div className="home-tab version">V 1.0.0</div>
                                    <div className="home-tab date">{this.state.date} {this.state.time}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6 col-12">
                            {/* <div className="home-tab">Last login: 10/4/2021 at 01:04, location IND</div> */}
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-12 col-12">
                            <div className="text-right">
                                <div className="home-tab user-loging pr-0">{this.state.userEmail} <div className="inline" onClick={()=>this.logout(history)}><FiLogOut className="user-logout"/></div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <TaskManager 
                    history={history}
                />
            </div>
        );
    }
}

export default Header;