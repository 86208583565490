import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import { Link } from 'react-router-dom';
import * as Fi from 'react-icons/fi'
import * as APIManager from './../../../APIManager/APIManager'

export default class SubUserList extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            responseData: [],
        }
    }

    componentDidMount(){
        this.getUserList()
    }
    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({
                isLoading: false,
                responseData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    editUserDetail = (_id, email) => {
        localStorage.setItem("subuserId",_id)
        localStorage.setItem("subuserEmail",email)
        this.props.history.push("/updatesubuser")
    }


    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSubUserList={true} />


                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Sub user list</div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent mh400" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 30 }}><Link to="/createsubuser"><Fi.FiPlus /></Link></th>
                                    <th className="tableHeader">Name</th>
                                    <th className="tableHeader">Email</th>
                                    <th className="tableHeader">Designation</th>
                                    <th className="tableHeader tableHeadButton text-right"><Link to="/createsubuser"><Fi.FiPlus /></Link></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.responseData !== null &&
                                this.state.responseData.map((data, i) => (
                                    <tr key={data._id} onClick={()=>this.editUserDetail(data._id, data.email)}>
                                        <td>{i+1}</td>
                                        <td>{data.full_name ? data.full_name : data.username}</td>
                                        <td className="text-lowercase">{data.email}</td>
                                        <td className="text-capitalize">{data.user_designation}</td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}