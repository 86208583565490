import React, { Component } from 'react';
import AddProductDetail from './AddProductDetail';
import * as APIManager from './../../../../../APIManager/APIManager'

class ProductGroupForOtherCurrency extends Component {

    constructor(props){
        super(props)
        this.state = {

            currencyData: this.props.currencyData,

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: this.props.productGroupData,

            totalOfProductCost: 0.00,
            totalOfProductCostFinal: 0.00,
            totalOfRawCostFinal: 0.00,
            totalOfRawCostInOther: 0.00,
            totalNoOfProduct: 0,
            totalDiscountPrice: "",
            totalDiscountPercentage: "",

            productObj: {
                'productGroup': '',
                'product': '',
                'productFreeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subtotal': '',
                'deliverDate': '',
                'rawtotal': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
                'exchangeRate': ''
            },

            productGroupList: []


        }
        this.addNewGroupFields = this.addNewGroupFields.bind(this)
        this.addGroupFieldValues = this.addGroupFieldValues.bind(this)
    }

    componentDidMount(){
        this.getProductGroup()
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSellableProductGroup()
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response
            },()=>{
                this.setAllDetails()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setAllDetails = () => {
        let prodGrp = this.state.productGroupData

        if(prodGrp !== null){
            let cost = sessionStorage.getItem("totalOfProductCost")
            let discountPrice = sessionStorage.getItem("totalDiscountPrice")
            let discountPercentage = sessionStorage.getItem("totalDiscountPercentage")
            let totalOfRawCostInOther = sessionStorage.getItem("totalOfRawCostInOther")

            this.setState({
                totalOfProductCost: cost,
                totalOfProductCostFinal: cost,
                totalDiscountPrice: discountPrice,
                totalOfRawCostInOther: totalOfRawCostInOther,
                totalDiscountPercentage: discountPercentage !== "null" ? discountPercentage : ""
            },()=>{
                this.setProductGrpDetails()
            })
        }else{
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <AddProductDetail
                        key={'proGroup'} 
                        currencyData={this.state.currencyData}
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter}
                        groupData={this.state.productObj}
                        productGroupList={this.state.productGroupList}
                    />
                ])
            })
        }
    }

    setProductGrpDetails = () => {
        let prodGrp = this.state.productGroupData
        let groupLength = prodGrp.length;

        if(groupLength > 0){

            let groupField = [];
            let groupKey = [];

            for(let i=0;i<prodGrp.length;i++){
                if(prodGrp[i] !== null){
                    groupField.push(
                        <AddProductDetail
                            key={'proGroup'+i} 
                            currencyData={this.state.currencyData}
                            onChangeProduct={this.addNewGroupFields}
                            onBlurProduct={this.addGroupFieldValues}
                            onRemoveProduct={this.removeGroupProduct}
                            counter={i}
                            groupData={prodGrp[i]}
                            productGroupList={this.state.productGroupList}
                        />
                    )
                    groupKey.push(i)
                }
            }

            this.setState({
                productGroupFields: groupField,
                productGroupKeys: groupKey,
                productGroupCounter: groupLength
            },()=>{
                this.setNewBlankField(this.state.productGroupCounter)
            })
        }else{
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <AddProductDetail
                        key={'proGroup'} 
                        currencyData={this.state.currencyData}
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter}
                        groupData={this.state.productObj}
                        productGroupList={this.state.productGroupList}
                    />
                ])
            })
        }
    }

    setNewBlankField = (counter) => {
        let dummyObj = {
            'productGroup': '',
            'product': '',
            'productFreeText': '',
            'quantity': '',
            'pricePerUoM': '',
            'discount': '',
            'subtotal': '',
            'deliverDate': '',
            'rawtotal': '',
            'pricePerUoMInOtherCurrency': '',
            'subTotalInOtherCurrency': '',
            'rawTotalInOtherCurrency': '',
            'exchangeRate': ''
        }

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <AddProductDetail                     
                    key={'proGroup'+counter}
                    currencyData={this.state.currencyData}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={counter}
                    groupData={dummyObj}
                    productGroupList={this.state.productGroupList}
                />
            ])
        })
    }

    addNewGroupFields = (counter) => {
        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)

        let dummyObj = {
            'productGroup': '',
            'product': '',
            'productFreeText': '',
            'quantity': '',
            'pricePerUoM': '',
            'discount': '',
            'subtotal': '',
            'deliverDate': '',
            'rawtotal': '',
            'pricePerUoMInOtherCurrency': '',
            'subTotalInOtherCurrency': '',
            'rawTotalInOtherCurrency': '',
            'exchangeRate': ''
        }

        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <AddProductDetail                     
                        key={'proGroup'+this.state.productGroupCounter+1}
                        currencyData={this.state.currencyData}
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter+1}
                        groupData={dummyObj}
                        productGroupList={this.state.productGroupList}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: this.state.productGroupCounter+1,
            },()=>{
                this.setCounterText()
            })
        }
    }



    addGroupFieldValues = (value, counter) => {
        if(value.product !== ""){
            let productGroupData = [...this.state.productGroupData]    
            productGroupData[counter] = value;  
            this.setState({
                productGroupData,
            },()=>{
                sessionStorage.setItem('productGroupData', JSON.stringify(productGroupData))
                console.log(productGroupData)
                this.countTotal()
            })
        }
    }

    

    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        },()=>{
            sessionStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
            this.setCounterText()
        })
    }

    setCounterText = () => {
        var slides = document.getElementsByClassName("subgroupdata");
        for (var i = 0; i < slides.length; i++) {
            slides[i].firstChild.innerHTML = '<td>'+(i+1)+'</td>';
        }
    }

    countTotal = () => {
        let arr = this.state.productGroupData;

        let totalCost = 0;
        let totalQuantity = 0;
        let rawTotal = 0;
        let totalCostInOther = 0;

        for(let i=0;i<arr.length;i++){
            if(arr[i] !== null){
                totalCost += arr[i].subtotal ? parseFloat(arr[i].subtotal) : 0
                totalQuantity += arr[i].quantity ? parseFloat(arr[i].quantity) : 0 
                rawTotal += arr[i].rawtotal ? parseFloat(arr[i].rawtotal) : 0
                totalCostInOther += arr[i].subTotalInOtherCurrency ? parseFloat(arr[i].subTotalInOtherCurrency) : 0
            }
        }
        this.setState({
            totalOfProductCost: totalCost.toFixed(2),
            totalOfProductCostFinal: totalCost.toFixed(2),
            totalOfRawCostFinal: Math.round(rawTotal).toFixed(2),
            totalOfRawCostInOther: Math.round(totalCostInOther).toFixed(2),
            totalNoOfProduct: totalQuantity,
        },()=>{
            sessionStorage.setItem("totalOfProductCost", totalCost.toFixed(2))
            sessionStorage.setItem("totalOfRawCostFinal", Math.round(rawTotal).toFixed(2))
            sessionStorage.setItem("totalDiscountPrice", 0)
            sessionStorage.setItem("totalOfRawCostInOther",Math.round(totalCostInOther.toFixed(2)))
        })
    }



    render() {
        return (
            <div className="main-container mt-20 order_product_group">
                <table>
                    <thead>
                        <tr>
                            <th className='pg-tab-head'></th>
                            <th className='pg-tab-head' style={{ width: 150 }}>Product group</th>
                            <th className='pg-tab-head' style={{ width: 200 }}>Product</th>
                            <th className='pg-tab-head'>Quantity</th>
                            <th className='pg-tab-head'>Price/UoM</th>
                            <th className='pg-tab-head'>Ex. Rate</th>
                            <th className='pg-tab-head'>Price in {this.state.currencyData.currencyName.toUpperCase()}</th>
                            <th className='pg-tab-head'>Discount</th>
                            <th className='pg-tab-head'>Total</th>
                            <th className='pg-tab-head'>Delivery date</th>
                            <th className='pg-tab-head' style={{ width: 20}}></th>
                        </tr>
                    </thead>
                    <tbody>

                        
                       {
                           this.state.productGroupFields
                       }
                        
                        
                        <tr>
                            <td></td>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td><b>{this.state.totalNoOfProduct}</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalOfRawCostInOther} {this.state.currencyData.currencySymbol}</b></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProductGroupForOtherCurrency;