import React from 'react';
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import Popup from 'reactjs-popup';
import RichTextEditor from 'react-rte';
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css'
import Loading from '../../../../helper/loading'
import * as APIManager from '../../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'
import * as Ai from 'react-icons/ai'
import * as Im from 'react-icons/im'
import AddTermsAndConditions from '../../../../components/AutoGenerateComponent/AddTermsAndConditions';
import EditDocuments from './EditDocuments';
import ProductGroupForOtherCurrency from './GroupWithoutTax/ProductGroup';
import ProductGroup from './GroupWithTax/ProductGroup'
import { convertDateStringMMDDtoDDMM, convertDateToDDMMYYYY, convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';


export default class EditCustomerOrder extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            goBack: this.props.goBack,
            history: this.props.history,

            orderId: this.props.customerOrderId,
            orderNumber: this.props.customerOrderNumber,

            isLoading: false,
            error: false,
            success: false,
            setProductGroup: false,
            errormessage: "",
            successmessage: "",

            deliveryTermsList: [],
            shippingAddressList: [],
            contactPersonList: [],
            accountManagerDetail: [],
            productGroupData: [],

            productObjectFile: null,
            isUploadStarting: false,
            accountManager: "",
            number: "",
            customer: "",
            status: "",
            updatedStatus: "",
            deliverydate: "",
            actualDelivery: "",
            poDeliveryDate: "",
            deliveryterms: "Ex-works",
            reference: "",
            shippingaddress: "",
            internalNotes: "",
            Created: "",
            rfqDate: "",
            quotationValidityEnd: "",
            rfqNo: "",

            poNo: "",
            poDate: "",

            customerCompanyName: "",

            contactPerson: "",
            customerOrderNotes: [],

            fieldNotEditable: true,
            revisionList: [],
            selectedRevision: "",

            quotationMessageEditor: RichTextEditor.createEmptyValue(),
            quotationMessage: "",

            quotationMessageFooterEditor: RichTextEditor.createEmptyValue(),
            quotationMessageFooter: "",

            countTermsNumber: [],
            TermsCounter: 0,
            termsFields: [],
            termsAndConditions: [],
            generateNewTerm: true,


            CoPayments: [],
            CoInvoices: [],
            CoShipments: [],
            CoPaymentsFields: [],
            showPaymentInterTab: 0,

            currencyData: [],

            docPermissionsManagersField: [],
            docPermissionsManagersValue: [],
            isDocumentPermit: false,
            documentFields: []
        }
    }

    componentDidMount(){
        this.getOrderDetails(this.state.orderId)
        this.getRevisions(this.state.orderId)
        this.getInvoices()
        this.getShipments()
        this.getOrderPaymentList()
    }

    getOrderDetails = (id) => {
        this.setState({ isLoading: true, error: false, setProductGroup: false, })
        APIManager.callCustomerOrderDetail(id)
        .then(response => {


            let dd = response.deliveryDate;
            let deliveryDate = "";

            if(dd){
                deliveryDate = new Date(dd);
            }

            let rfqDate = response.rfqDate;
            let newRfqDate = "";
            
            if(rfqDate){
                newRfqDate = new Date(rfqDate);
            }

            sessionStorage.setItem('productGroupData', JSON.stringify(response.productGroup))
            sessionStorage.setItem("totalOfRawCostFinal", Math.round(response.total).toFixed(2))
            sessionStorage.setItem("totalDiscountPrice", response.discountValue)
            sessionStorage.setItem("totalDiscountPercentage", response.discountPercentage)
            sessionStorage.setItem("totalOfRawCostInOther", response.totalInOtherCurrency ? response.totalInOtherCurrency : "0")


            let accManagers = response.accountManagerDetail;
            let getManagersDetails = []
            if(accManagers.length > 0){
                getManagersDetails = accManagers.reduce((acc, current) => {
                    const x = acc.find(item => item.userId === current.userId);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
            }
            
            this.setState({ 
                 isLoading: false,
                 productGroupData: response.productGroup,
                 number: response.number.toUpperCase(),
                 customer: response.customer,
                 status: response.status,
                 updatedStatus: response.status,
                 poDeliveryDate: response.poDeliveryDate ? new Date(response.poDeliveryDate) : "",
                 actualDelivery: response.actualDeliveryDate ? new Date(response.actualDeliveryDate) : "",
                 deliverydate: deliveryDate,
                 deliveryterms: response.deliveryTerms,
                 reference: response.reference,
                 shippingaddress: response.shippingAddress,
                 internalNotes: response.internalNotes,
                 Created: response.created,
                 rfqDate: newRfqDate,
                 quotationValidityEnd: response.quotationValidityEnd ? new Date(response.quotationValidityEnd) : "",
                 rfqNo: response.rfqNo,
                 poNo: response.poNo ? response.poNo : '',
                 poDate: response.poDate ? new Date(response.poDate) : '',
                 contactPerson: response.contactPerson,
                 customerOrderNotes: response.notes,
                 accountManagerDetail: getManagersDetails,
                 accountManager: response.accountManager ? response.accountManager : '',
                 quotationMessageEditor: response.quotationMessage ? RichTextEditor.createValueFromString(response.quotationMessage, 'html') : RichTextEditor.createEmptyValue(),
                 quotationMessageFooterEditor: response.quotationMessageFooter ? RichTextEditor.createValueFromString(response.quotationMessageFooter, 'html') : RichTextEditor.createEmptyValue(),
            },()=>{
                this.getDocumentPermissions(response)
                this.getCustomerShippingAddress()
                this.getCustomerTaxDetails(response.customer)
                this.getContactPerson(response.customer)
                if(response.termsCondition !== undefined){
                    this.setTermsConditions(response.termsCondition)
                }else{
                    this.setTerms()
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getRevisionDetail = (revId) => {
        if(revId !== ""){
            this.setState({ isLoading: true, error: false, setProductGroup: false, })
            APIManager.callOrderRevisionDetail(this.state.orderId, revId)
            .then(response => {
                let dd = response.deliveryDate;
                let deliveryDate = "";
                if(dd){
                    deliveryDate = new Date(dd);
                }

                let rfqDate = response.rfqDate;
                let newRfqDate = "";
                
                if(rfqDate){
                    newRfqDate = new Date(rfqDate);
                }

                sessionStorage.setItem('productGroupData', JSON.stringify(response.productGroup))
                sessionStorage.setItem("totalOfRawCostFinal", Math.round(response.total).toFixed(2))
                sessionStorage.setItem("totalDiscountPrice", response.discountValue)
                sessionStorage.setItem("totalDiscountPercentage", response.discountPercentage)
                sessionStorage.setItem("totalOfRawCostInOther", response.totalInOtherCurrency ? response.totalInOtherCurrency : "0")


                let accManagers = response.accountManagerDetail;
                let getManagersDetails = []
                if(accManagers.length > 0){
                    getManagersDetails = accManagers.reduce((acc, current) => {
                        const x = acc.find(item => item.userId === current.userId);
                        if (!x) {
                        return acc.concat([current]);
                        } else {
                        return acc;
                        }
                    }, []);
                }
                
                this.setState({ 
                    isLoading: false,
                    productGroupData: response.productGroup,
                    number: response.number.toUpperCase(),
                    customer: response.customer,
                    status: response.status,
                    updatedStatus: response.status,
                    deliverydate: deliveryDate,
                    deliveryterms: response.deliveryTerms,
                    reference: response.reference,
                    shippingaddress: response.shippingAddress,
                    internalNotes: response.internalNotes,
                    Created: response.created,
                    rfqDate: newRfqDate,
                    quotationValidityEnd: response.quotationValidityEnd ? new Date(response.quotationValidityEnd) : "",
                    rfqNo: response.rfqNo,
                    poNo: response.poNo ? response.poNo : '',
                    poDate: response.poDate ? new Date(response.poDate) : '',
                    contactPerson: response.contactPerson,
                    customerOrderNotes: response.notes,
                    accountManagerDetail: getManagersDetails,
                    accountManager: response.accountManager ? response.accountManager : '',
                    quotationMessageEditor: response.quotationMessage ? RichTextEditor.createValueFromString(response.quotationMessage, 'html') : RichTextEditor.createEmptyValue(),
                    quotationMessageFooterEditor: response.quotationMessageFooter ? RichTextEditor.createValueFromString(response.quotationMessageFooter, 'html') : RichTextEditor.createEmptyValue(),
                },()=>{
                    this.getDocumentPermissions(response)
                    this.getCustomerShippingAddress()
                    this.getCustomerTaxDetails(response.customer)
                    this.getContactPerson(response.customer)
                    if(response.termsCondition !== undefined){
                        this.setTermsConditions(response.termsCondition)
                    }else{
                        this.setTerms()
                    }
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.data 
                })
            });
        
        }
    }

    getCustomerShippingAddress = () => {
        this.setState({ isLoading: true, error: false })
        let customer = this.state.customer
        APIManager.callCustomerShippingAddress(customer)
        .then(response => {
            this.setState({ 
                isLoading: false,
                shippingAddressList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getDeliveryTerms = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDeliveryTermsList()
        .then(response => {
            this.setState({
                isLoading: false,
                deliveryTermsList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getDocumentPermissions = (response) => {
        this.getCODocuments()
    }

    updatePermission = (isCheked, userId, userName) => {
        let arr = [...this.state.docPermissionsManagersValue]
        let obj = {
            accountManager: userId,
            username: userName,
            permission: isCheked
        }

        const i = arr.findIndex(ele => ele.accountManager === userId);
        if (i > -1) {
            arr[i].permission = isCheked;
            this.setState({
                docPermissionsManagersValue: arr
            },()=>{
                console.log(this.state.docPermissionsManagersValue)
            })
        }else{
            this.setState({
                docPermissionsManagersValue: this.state.docPermissionsManagersValue.concat([obj])
            },()=>{
                console.log(this.state.docPermissionsManagersValue)
            })
        }
    }

    getCODocuments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCODocuments(this.state.orderId)
        .then(response => {
            let fields = []
            if(response){
                for(let i = 0; i<response.length; i++){
                    fields.push(
                        <EditDocuments 
                            key={'docs'+i}
                            file={response[i].documentFile}
                            id={response[i]._id}
                            description={response[i].description}
                            onDocDelete={this.deleteDocumentSuccess}
                            onSubmitSuccess={""}
                            onUpdateSuccess={this.updateDocumentSuccess}
                            isOldData={false}
                            customerOrderId={this.state.orderId}
                        />
                    )
                }
            }
            this.setState({ 
                isLoading: false,
                documentFields: fields
            },()=>{
                this.setState({
                    documentFields: this.state.documentFields.concat([
                        <EditDocuments 
                            key={'docs'}
                            file={""}
                            id={""}
                            description={""}
                            onDocDelete={this.deleteDocumentSuccess}
                            onSubmitSuccess={this.onDocUploadSuccess}
                            onUpdateSuccess={""}
                            isOldData={true}
                            customerOrderId={this.state.orderId}
                        />
                    ])
                })
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
            })
        });
        
    }

    onDocUploadSuccess = () => {
        this.getCODocuments()
    }

    updateDocumentSuccess = () => {
        this.setState({
            documentFields: []
        },()=>{
            this.getCODocuments()
        })
    }

    deleteDocumentSuccess = () => {
        this.getCODocuments()
    }


    getContactPerson = (cmpId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callContactPerson(cmpId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                contactPersonList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setTermsConditions = (resp) => {
        let counterArray = []
        let termFields = [];
        for(let i=0;i<resp.length;i++){
            counterArray.push(i)
            termFields.push(
                <AddTermsAndConditions 
                    key={i}
                    componentNum={i}
                    term={resp[i].terms}
                    condition={resp[i].options}
                    termsFor={"customerOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            )
        }

        this.setState({
            termsFields: termFields,
            countTermsNumber: counterArray,
            TermsCounter: resp.length,
            termsAndConditions: resp
        },()=>{
            this.setTerms()
        })
    }

    setTerms = () => {
        let counter = this.state.TermsCounter;
        this.setState({
            termsFields: this.state.termsFields.concat([
                <AddTermsAndConditions 
                    key={counter+1}
                    componentNum={counter+1}
                    term={""}
                    condition={""}
                    termsFor={"customerOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            ]),
            countTermsNumber: this.state.countTermsNumber.concat([ counter+1 ]),
        },()=>{
            this.setState({
                TermsCounter: counter + 1
            })
        })
    }

    getRevisions = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callRevisionsList(id)
        .then(response => {
            this.setState({ 
                isLoading: false, 
                error: false, 
                revisionList: response,
                selectedRevision: response.length !== 0 ? response[response.length-1].revision_id : "" 
            })
        }).catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getInvoices = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOInvoicesList(this.state.orderId)
        .then(response => {
            this.setState({ 
                isLoading: false, 
                error: false, 
                CoInvoices: response.data ? response.data : []
            })

        }).catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getShipments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOShipmentList(this.state.orderId)
        .then(response => {
            this.setState({ 
                isLoading: false, 
                error: false, 
                CoShipments: response.data ? response.data : []
            })

        }).catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getOrderPaymentList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOPaymentList(this.state.orderId)
        .then(response => {
            this.setState({
                isLoading: false,
                CoPayments: response,
            },()=>{
                this.updatePaymentList(response)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    updatePaymentList = () => {
        let fields = []
        let data = this.state.CoPayments.data

        for(let i=0;i<data.length;i++){
            let innerData = []
            let remaining = data[i].total - data[i].totalPaid;
            fields.push(
                <tr key={"mainData"+i} onClick={()=>this.hideShowPaymentInnerSection(i)}>
                    <td className='text-uppercase'><b>{data[i].invoiceId}</b></td>
                    <td><b>{data[i].total.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                    <td><b>{data[i].totalPaid.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                    <td><b>{remaining.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></td>
                    <td><b>{data[i].dueDate}</b></td>
                    <td className='text-right pr-3'>
                        {
                            this.state.showPaymentInterTab === i ?
                                <Ai.AiFillCaretUp />
                            :
                                <Ai.AiFillCaretDown />
                        }
                    </td>
                </tr>
            )
            for(let j=0;j<data[i].payments.length;j++){
                innerData.push(
                    <tr key={"innerData"+i+j} onClick={()=>this.editOrderPayment(data[i].payments[j].id, data[i].invoice_id, remaining)}>
                        <td>{data[i].payments[j].notes}</td>
                        <td>{data[i].payments[j].paid.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</td>
                        <td>{data[i].payments[j].date}</td>
                        <td></td>
                    </tr>
                )
            }

            let innerDataHeader = []
            innerDataHeader.push(
                <tr className={this.state.showPaymentInterTab === i ? '' : 'd-none'} key={"dataAndHead"+i}>
                    <td colSpan="6" className='table_inside_table'>
                        <table>
                            <thead>
                            <tr>
                                <th className='width40'><b>Notes</b></th>
                                <th className='width30'><b>Paid</b></th>
                                <th className='width20'><b>Created</b></th>
                                <th className='width10 text-right' onClick={()=>this.createPayment(data[i].invoice_id, data[i].invoiceId)}><Fi.FiPlus /></th>
                            </tr>
                            </thead>
                            <tbody>
                            
                                { innerData }
                                {
                                    data[i].totalPaid !== 0 &&
                                    <tr>
                                        <th><b>Total</b></th>
                                        <th><b>{data[i].totalPaid.toString().split( /(?=(?:\d{3})+(?:\.|$))/g ).join( "," )+'/-'}</b></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                }
                                
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
            fields.push(innerDataHeader)
        }

        this.setState({ 
            isLoading: false,
            CoPaymentsFields: fields
        })
    }

    hideShowPaymentInnerSection = (i) => {
        if(this.state.showPaymentInterTab === i){
            this.setState({
                showPaymentInterTab: null
            },()=>{
                this.updatePaymentList()
            })
        }else{
            this.setState({
                showPaymentInterTab: i
            },()=>{
                this.updatePaymentList()
            })
        }
    }

    createPayment = (id, invNo) => {
        let slug = "Procurement_PurchaseOrders"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createcopayment",
                state: { invoiceId: id, invNo: invNo }
            }) 
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    editOrderPayment = (paymentId, invoiceId, remaining) => {
        this.props.history.push({
            pathname: "/editcopayment",
            state: { paymentId: paymentId, invoiceId: invoiceId, remaining: remaining}
        })
        
    }


    termsFieldChange = (term,condition,number) => {

        let array = [...this.state.countTermsNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.termsAndConditions]        

        if (typeof CIVal[index] !== 'undefined') {
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                let termsAndConditions = [ ...this.state.termsAndConditions ];
                termsAndConditions[index] = obj;

                this.setState({
                    termsAndConditions,
                })
            }
        }else{
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                this.setState({
                    termsAndConditions: this.state.termsAndConditions.concat([ obj ])
                },()=>{
                    this.setTerms()
                })
            }
        }
    }

    termsFieldRemove = (number) => {
        let array = [...this.state.countTermsNumber];
        let fields = [...this.state.termsFields];
        let data = [...this.state.termsAndConditions]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countTermsNumber: array,
                termsFields: fields,
                termsAndConditions: data
            });
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    
    updateOrder = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                orderId,
                customer,
                status,
                deliveryterms,
                quotationMessage,
                quotationMessageFooter,
                reference,
                shippingaddress,
                internalNotes,
                number,
                rfqDate,
                quotationValidityEnd,
                rfqNo,
                termsAndConditions,
                contactPerson,
                accountManager,
                docPermissionsManagersValue,
                poNo,
                poDate,
                poDeliveryDate
            } = this.state

           // let productGroupDataRaw = JSON.parse(sessionStorage.getItem('productGroupData')).slice(0, -1)
            let productGroupDataRaw = JSON.parse(sessionStorage.getItem('productGroupData'))
            let totalOfRawCostFinal = sessionStorage.getItem("totalOfRawCostFinal")
            let totalDiscountPrice = sessionStorage.getItem("totalDiscountPrice")
            let totalDiscountPercentage = sessionStorage.getItem("totalDiscountPercentage")

            let totalOfRawCostInOther = sessionStorage.getItem("totalOfRawCostInOther")
           
            if(productGroupDataRaw === null){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please fill required fields"
                },()=>{
                    this.scrollToTop()
                })
            }else{
                
            /* Remove Null From State */
            let productGroupData = [];
            productGroupDataRaw.forEach((val) => {
                if(val !== null){
                    productGroupData.push(val);
                }
            });
            /* Remove Null From State */

            /* GET OLDEST DATE FROM PRODUCT GROUP - CODE START */
            
            let groupDateArray = []
            let dt = "";
            let newDate = "";
            let oldestDate = "";

            //let currentDate = new Date();
            
            //currentDate = new Date(currentDate.setDate(currentDate.getDate() + 30))

           
            let currentDate = this.state.deliverydate
            if(!currentDate){
                let cDate = new Date(new Date());
                currentDate = new Date(cDate.setDate(cDate.getDate() + 30));
            }
            
            var dd = String(currentDate.getDate()).padStart(2, '0');
            var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = currentDate.getFullYear();

            currentDate = mm + '/' + dd + '/' + yyyy;

            productGroupData.map((data) => {
                dt = (data.deliverDate) ? data.deliverDate : currentDate
                newDate = dt
                groupDateArray.push(newDate)
            })

            if(groupDateArray.length > 0){
                oldestDate = groupDateArray.reduce(function (pre, cur) {
                    return Date.parse(pre) > Date.parse(cur) ? pre : cur;
                });
            }else{
                oldestDate = currentDate
            }

            /* GET OLDEST DATE FROM PRODUCT GROUP - CODE END */

            let deliverydate =  Date.parse(oldestDate) > Date.parse(currentDate) ? oldestDate : currentDate;

            if(status === "confirmed"){
                deliverydate = convertDateToMMDDYYYY(poDeliveryDate)
            }

            if(customer === "" || status === "" || number === ""){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please fill required fields" 
                },()=>{
                    this.scrollToTop()
                })
            }else if(productGroupData.length === 0){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please add product details" 
                },()=>{
                    this.scrollToTop()
                })
            }else if((status !== "quotation" && status !== "waiting for confirmation" && status !== "archived" && status !== "cancelled") && (poNo === "" || poDate === "" || poDeliveryDate === "")){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please enter PO details" 
                },()=>{
                    this.scrollToTop()
                })
            }else{
                this.setState({ isLoading: true, error: false })

              
                APIManager.callUpdateCustomerQuotation(orderId,number,customer,status,deliverydate,deliveryterms,reference,shippingaddress,internalNotes,JSON.stringify(productGroupData),totalOfRawCostFinal,totalDiscountPercentage,totalDiscountPrice,quotationMessage,quotationMessageFooter,convertDateToMMDDYYYY(rfqDate),convertDateToMMDDYYYY(quotationValidityEnd),rfqNo,poNo,convertDateToMMDDYYYY(poDate),JSON.stringify(termsAndConditions),contactPerson,accountManager,JSON.stringify(docPermissionsManagersValue),totalOfRawCostInOther,convertDateToMMDDYYYY(poDeliveryDate))
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        success: true,
                        successmessage: "Quotation updated successfully",
                        deliverydate: new Date(deliverydate),
                        fieldNotEditable: true,
                        updatedStatus: status 
                    },()=>{
                        this.getOrderDetails(orderId)
                        this.getRevisions(orderId)
                        this.scrollToTop()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }

        }
        }else{
            let slug = "CRM_Quotations"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }

    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteCustomerOrder(this.state.orderId)
            .then(response => {
                sessionStorage.removeItem('productGroupData')
                sessionStorage.removeItem("totalOfProductCost")
                sessionStorage.removeItem("totalOfRawCostFinal")
                sessionStorage.removeItem("totalDiscountPrice")
                sessionStorage.removeItem("totalDiscountPercentage")
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.state.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    downloadPDF = (status) => {

        let orderId = this.state.orderId
        let orderNumber = this.state.orderNumber
        let revisionId = this.state.revisionList.length > 0 ? this.state.revisionList[this.state.revisionList.length-1].revision_id : ""
        let revision = ""
        let st = status
        
        if(revisionId === this.state.selectedRevision){
            revision = ""
        }else{
            revision = this.state.selectedRevision
        }

        if(this.state.currencyData.conversionRate){
            if(status === "Quotation"){
                window.open('quotationpdf?orderId='+orderId+'&orderNumber='+orderNumber+'&revision='+revision+'&status='+st)
            }else{
                window.open('copdf?orderId='+orderId+'&orderNumber='+orderNumber+'&revision='+revision+'&status='+st)
            }
        }else{ 
            if(status === "Quotation"){
                window.open('generatequotationpdf?orderId='+orderId+'&orderNumber='+orderNumber+'&revision='+revision+'&status='+st)
            }else{
                window.open('customerorderpdf?orderId='+orderId+'&orderNumber='+orderNumber+'&revision='+revision+'&status='+st)
            }
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    onQuotationMessageChange = (value) => {
        this.setState({
            quotationMessageEditor: value,
            quotationMessage: value.toString('html')
        });
    }

    onquotationMessageFooterChange = (value) => {
        this.setState({
            quotationMessageFooterEditor: value,
            quotationMessageFooter: value.toString('html')
        });
    }

    getCustomerTaxDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {
            if(!response.companyData.taxApplicable.includes("notax")){
                sessionStorage.setItem('applicableTax',response.companyData.taxApplicable)
            }else{
                sessionStorage.removeItem('applicableTax')
            }
            
            localStorage.setItem("cmpId",id)
            localStorage.setItem("cmpIdNum",response.companyData.companyId)
            localStorage.setItem("cmpName",response.companyData.companyName)

            this.setState({
                currencyData: response.companyData.currencyDetails,
                isLoading: false,
                setProductGroup: true,
                customerCompanyName: response.companyData.companyId.toUpperCase()+' '+response.companyData.companyName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                setProductGroup: true 
            })
        });
    }


    editInvoice = (id) => {
        let slug = "CRM_Invoices"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/editcoinvoice",
                state: { invoiceId: id, isNewEntry: false }
            }) 
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    createInvoice = () => {
        let slug = "CRM_Invoices"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createcoinvoice",
                state: { coId: this.state.orderId, isFromCO: true }
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    // Convert number into comma value
    formattedNumber = (num) => {
        let number = parseFloat(num)
        if(number !== "" || number !== 0){
            return number.toFixed(2).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return number
        }
    } 

  
    getRevisionData = (value) => {
        if(value !== ""){
            this.setState({
                selectedRevision: value
            },()=>{
                let revisionId = this.state.revisionList.length > 0 ? this.state.revisionList[this.state.revisionList.length-1].revision_id : ""
                if(revisionId !== this.state.selectedRevision){
                    this.getRevisionDetail(value)
                }else{
                    this.getOrderDetails(this.state.orderId)
                }
            })
        }
    }
    editCustomerOrderNote = (data) => {

        localStorage.setItem("is_important_co_note", data.is_important)
        this.props.history.push({
            pathname: "/editconote",
            state: { 
                id: data.id, 
                note: data.note, 
                orderId: this.state.orderId,
                orderNumber: this.state.orderNumber 
            }
        })
    }

    createCustomerNote = () => {
        this.props.history.push({
            pathname: "/createconote",
            state: { 
                orderId: this.state.orderId,
                orderNumber: this.state.orderNumber 
            }
        })
    }

 

    createShipment = () => {
        let slug = "Stock_Shipments"
        let permission = "create"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/createshipment",
                state: { customerOrderId: this.state.orderId }
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    editThisCustomer = () => {
        this.props.history.push({
            pathname: "/editcustomercompany"
        })
    }
    editShipment = (_id) => {
        let slug = "Stock_Shipments"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push({
                pathname: "/editshipment",
                state: { shipmentId: _id, newShipmentEntry: false }
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    onFileChange = event => {
        this.setState({ productObjectFile: event.target.files[0] });
    }
    downloadSample = () => {
        window.open(APIManager.BASE_URL+'public/demoexcel/coItem.xlsx')
    }

    onFileUpload = () => {
        let {
            productObjectFile,
            orderId
        } = this.state

        if (productObjectFile) {
            this.setState({ isLoading: true, error: false, isUploadStarting: true })
            APIManager.callUploadCOItems(orderId, productObjectFile)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        success: true,
                        isUploadStarting: false,
                        successmessage: "Item(s) imported successfully."
                    }, () => {
                        setTimeout(() => {                        
                            this.getOrderDetails(orderId)
                        }, 2000);
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        isUploadStarting: false,
                        errormessage: errors
                    })
                });
        } else {
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select file to upload"
            })
        }
    }

    onClosePopup = () => {
        this.setState({
            error: false,
            success: false,
            selectedFile: null
        })
    }


    render(){
        let {
            goBack
        } = this.props;
        return(
            <div className="main-container">
                
                    <div>
                        <div className="page-header mb-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="page-title">Quotation {this.state.orderNumber} details</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="head-import-buttons float-right">
                                    
                                        <div className="button" onClick={()=>this.downloadPDF('Quotation')}>Quotation <Im.ImArrowDown2 /></div>    
                                        
                                        {
                                            (this.state.updatedStatus !== 'quotation' && this.state.updatedStatus !== 'waiting for confirmation') ?
                                                <div className="button" onClick={()=>this.downloadPDF('Confirmed')}>Confirmed <Im.ImArrowDown2 /></div>
                                            :
                                                <div className="button disabled">Confirmed <Im.ImArrowDown2 /></div>
                                        }
                                         {
                                             (this.state.updatedStatus !== 'quotation' && this.state.updatedStatus !== 'waiting for confirmation') ?
                                                <div className="button" onClick={()=>this.downloadPDF('Proforma Invoice')}>Proforma Invoice <Im.ImArrowDown2 /></div>
                                            :
                                                <div className="button disabled">Proforma Invoice <Im.ImArrowDown2 /></div>
                                        }
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }

                        <div className="tableContent">
                            <div className="addSaveButton mb-20">
                                <div className="add_btnBack" onClick={()=>goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.updateOrder()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                                <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                                <div className="clear"></div>
                            </div>
                            <div className="position-relative">
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Number*</div>
                                        <div className="value width30 _Bbaspace3px">
                                            <input type="text" value={this.state.number} onChange={(e)=>this.setState({ number: e.target.value })} className="fieldheightdecr" readOnly></input>
                                        </div>
                                        <div className="value width40 zIndex1001">
                                            <select onChange={(e) => this.getRevisionData(e.target.value)} value={this.state.selectedRevision}>
                                                <option value="">Select Revision</option>
                                                {
                                                    this.state.revisionList.map((data) => (
                                                        <option key={'revision__'+data.revision_id} value={data.revision_id}>{data.revisionName}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Customer*</div>
                                        <div className="value text-capitalize">
                                            <div className="float-left width90">
                                                <div className="createdText">
                                                    {this.state.customerCompanyName}
                                                </div>
                                            </div>
                                            
                                            <div className='width10 d-inline-block text-center secondary-color mt-1' onClick={()=>this.editThisCustomer()}><Im.ImLink /></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Contact person</div>
                                        <div className="value">
                                            <Select2    
                                                className="width90"
                                                data={
                                                    this.state.contactPersonList.map((data) => (
                                                        { id: data._id, text: data.name }
                                                    ))
                                                }
                                                value={this.state.contactPerson}
                                                options={{placeholder: 'Select person'}}
                                                onSelect={(e)=> this.setState({ contactPerson: e.target.value })}
                                            />
                                        </div>                                                  
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Status*</div>
                                        <div className="value">
                                            <select value={this.state.status} onChange={(e)=>this.setState({ status: e.target.value })}>
                                                <option value="">Select</option>
                                                <option value="quotation">Quotation</option>
                                                <option value="waiting for confirmation">Waiting for confirmation</option>
                                                <option value="confirmed">Confirmed</option>
                                                <option value="waiting for production">Waiting for Production</option>
                                                <option value="in production">In Production</option>
                                                <option value="ready for shipment">Ready for Shipment</option>
                                                <option value="partially invoiced">Partially Invoiced</option>
                                                <option value="partially shipped">Partially Shipped</option>
                                                <option value="invoiced">Invoiced</option>
                                                <option value="shipped">Shipped</option>
                                                <option value="delivered">Delivered</option>
                                                <option value="archived">Archived</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    
                                    
                                    
                                    
                                    
                                    <div className="add_inner">
                                        <div className="title">Shipping address</div>
                                        <div className="value">
                                           
                                            <Select2    
                                                data={
                                                    this.state.shippingAddressList.map((data) => (
                                                        { id: data, text: data.split('_').join(' ') }
                                                    ))
                                                }
                                                value={this.state.shippingaddress}
                                                options={{placeholder: 'Select address'}}
                                                onSelect={(e)=> this.setState({ shippingaddress: e.target.value })}
                                            />
                                        </div>                                                  
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">PO No.</div>
                                        <div className="value"><input type="text" value={this.state.poNo} onChange={(e)=>this.setState({ poNo: e.target.value })} className="fieldheightdecr"></input></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">PO Date</div>
                                        <div className="value">
                                            <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.poDate
                                            }
                                            onChange={
                                                date => this.setState({ poDate: date })
                                            }
                                            className="filterdateoption"/>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Delivery (As per PO)</div>
                                        <div className="value">
                                            <DatePicker 
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    this.state.poDeliveryDate
                                                }
                                                onChange={
                                                    date => this.setState({ poDeliveryDate: date })
                                                }
                                                className="filterdateoption"
                                            />
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                   
                                    
                                </div>
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Created</div>
                                        <div className="value">
                                            <div className="createdText">{convertDateStringMMDDtoDDMM(this.state.Created)}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Account Manager</div>
                                        <div className="value">
                                            <Select2    
                                                className="width90 text-capitalize"
                                                data={
                                                    this.state.accountManagerDetail.map((data) => (
                                                        { id: data.userId, text: data.userName }
                                                    ))
                                                }
                                                value={this.state.accountManager}
                                                options={{placeholder: 'Select person'}}
                                                onSelect={(e)=> this.setState({ accountManager: e.target.value })}
                                            />
                                        </div>                                                  
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Reference</div>
                                        <div className="value"><input type="text" value={this.state.reference} onChange={(e)=>this.setState({ reference: e.target.value })} className="fieldheightdecr"></input></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">RFQ No.</div>
                                        <div className="value"><input type="text" value={this.state.rfqNo} onChange={(e)=>this.setState({ rfqNo: e.target.value })} className="fieldheightdecr"></input></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">RFQ Date</div>
                                        <div className="value">
                                            <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.rfqDate
                                            }
                                            onChange={
                                                date => this.setState({ rfqDate: date })
                                            }
                                            className="filterdateoption"/>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Estimated Delivery</div>
                                        <div className="value">
                                            <DatePicker 
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                selected={
                                                    this.state.deliverydate
                                                }
                                                onChange={
                                                    date => this.setState({ deliverydate: date })
                                                }
                                                className="filterdateoption"/>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Actual Delivery</div>
                                        <div className="value">
                                            <DatePicker 
                                                disabled
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                selected={
                                                    this.state.actualDelivery
                                                }
                                                onChange={
                                                    date => this.setState({ actualDelivery: date })
                                                }
                                                className="filterdateoption"/>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Valid till</div>
                                        <div className="value">
                                            <DatePicker
                                            dateFormat="dd/MM/yyyy" 
                                            minDate={new Date()}
                                            selected={
                                                this.state.quotationValidityEnd
                                            }
                                            onChange={
                                                date => this.setState({ quotationValidityEnd: date })
                                            }
                                            className="filterdateoption"/>
                                            <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    {/* {
                                        this.state.isDocumentPermit &&
                                        <div className="add_inner">
                                            <div className="title">Doc(s) Permissions</div>
                                            <div className="value">
                                                <div className='createdText border-0'>
                                                    <div className='float-left width40'><b>Account Manager</b></div>
                                                    <div className='float-left width40 text-center'><b>Permission</b></div>
                                                    <div className='clear'></div>
                                                </div>
                                            </div>
                                            <div className="value mb-1">
                                                { this.state.docPermissionsManagersField }
                                            </div>
                                        </div>
                                    } */}
                                    
                                    
                                </div>
                                <div className="clear"></div>
                                <div className="add_outer_full_part">
                                    <div className="title">Quotation Message</div>
                                    <div>
                                        <RichTextEditor
                                            value={this.state.quotationMessageEditor}
                                            onChange={this.onQuotationMessageChange} 
                                            rootStyle={{ minHeight: 170}}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                                }
                            </div>

                            <div className="head-import-buttons pr-4 position-relative">
                                <div className='float-right'>
                                <Popup modal arrow={true} trigger={<div className="button">Import Items via CSV <Im.ImArrowUp2 /></div>} position="center" onClose={() => this.onClosePopup()}>
                                        <div className='head-import-buttons selectFile__Popup__Item'>
                                            <div className="pagecontent">
                                                {
                                                    this.state.error &&
                                                    <div className="mb-2">
                                                        <div className="errornotification">
                                                            <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.success &&
                                                    <div className="mb-2">
                                                        <div className="successnotification">
                                                            <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                onChange={this.onFileChange}
                                            ></input>
                                            {
                                                this.state.isUploadStarting ?
                                                    <div className="button mt-2">Upload <Im.ImArrowUp2 /></div>
                                                    :
                                                    <div className="button mt-2" onClick={() => this.onFileUpload()}>Upload <Im.ImArrowUp2 /></div>
                                            }
                                        </div>
                                    </Popup>
                                </div>
                                <div className='float-right'>
                                    <div className='button' onClick={()=>this.downloadSample()}>Download Sample CSV</div>
                                </div>
                                <div className='clear'></div>
                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                                }
                            </div>

                            <div className="position-relative">
                                {
                                    this.state.setProductGroup &&
                                    <div>
                                        {
                                            this.state.currencyData.conversionRate ?
                                                <ProductGroupForOtherCurrency 
                                                    currencyData={this.state.currencyData}
                                                    productGroupData={this.state.productGroupData}
                                                />
                                            :
                                                <ProductGroup />
                                        }
                                    </div>
                                }
                                {
                                    (this.state.fieldNotEditable || this.state.CoInvoices.length > 0) &&
                                    <div className="disableFieldCover"></div>
                                }
                            </div>
                            {/* TERMS & CONDTIONS FIELD */}
                            <div className="__terms_and_conditions position-relative">
                                <div className="__terms_left_header">
                                    <div className="__terms_content_title">{'Terms & conditions'}</div>
                                </div>
                                <div className="__terms_right_content">
                                    { this.state.termsFields }
                                </div>
                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                                }
                                <div className="clear"></div>
                            </div>


                            

                            <div className='__terms_and_conditions position-relative'>
                                <div className="add_outer_full_part">
                                    <div className="title">Quotation footer</div>
                                    <div>
                                        <RichTextEditor
                                            value={this.state.quotationMessageFooterEditor}
                                            onChange={this.onquotationMessageFooterChange} 
                                            rootStyle={{ minHeight: 170 }}
                                        />
                                    </div>
                                    {
                                        this.state.fieldNotEditable &&
                                        <div className="disableFieldCover"></div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>

                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.updateOrder()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                                <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                                {/* <div className="add_btnSave">Reports</div>
                                <div className="add_btnSave m-0">Copy</div> */}
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="addSaveButton p-0">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                                <div className="head-import-buttons float-left">
                                    {
                                        (this.state.updatedStatus !== "quotation" || this.state.updatedStatus !== "waiting for confirmation") ?
                                            <div className="button co__footer__button" onClick={()=>{ this.props.history.push("bookcoitem")}}>Check stock and book items</div>
                                        :
                                            <div className="button co__footer__button disabled">Check stock and book items</div>

                                    }
                                    {/* <div className="button co__footer__button">Estimates costs and dates</div> */}
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>

                        
                       
                            <div className="__terms_and_conditions position-relative">
                                <div className="__terms_left_header">
                                    <div className="__terms_content_title">Documents</div>
                                </div>
                                <div className="__terms_right_content">
                                    <table>
                                        <tbody>
                                            {
                                                this.state.documentFields
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="clear"></div>
                            </div>
                        


                        <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                            <div className="footerTitle">Invoices</div>
                            <table>
                                <thead>
                                <tr>
                                    <th className="tableHeader">Invoice ID</th>
                                    <th className="tableHeader">Invoice date</th>
                                    <th className="tableHeader">Due date</th>
                                    <th className="tableHeader">Total</th>
                                    <th className="tableHeader">Created</th>
                                    <th className="tableAddButton text-right"><div onClick={()=>this.createInvoice()} className="addContactButton"><Fi.FiPlus /></div></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.CoInvoices !== null &&
                                    this.state.CoInvoices.map((data, i) => (
                                        <tr key={'po-invoices'+i} onClick={()=>this.editInvoice(data._id)}>
                                            <td className='text-uppercase'>{data.number}</td>
                                            <td>{data.invoiceDate}</td>
                                            <td>{data.dueDate}</td>
                                            <td>{this.formattedNumber(data.grandTotal)}/-</td>
                                            <td>{data.created}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>

                        <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                            <div className="footerTitle">Payments</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='width20'>Invoice ID</th>
                                        <th className='width20'>Total</th>
                                        <th className='width20'>Paid</th>
                                        <th className='width20'>Remaining</th>
                                        <th className='width10'>Due date</th>
                                        <th className='width10'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                {
                                    this.state.CoPaymentsFields
                                }

                                </tbody>
                            </table>
                        </div>


                        <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                            <div className="footerTitle">Shipments</div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Invoice</th>
                                        <th>Delivery</th>
                                        <th>Status</th>
                                        <th>Waybill note</th>
                                        <th>Picking list note</th>
                                        <th className="tableAddButton text-right"><div onClick={()=>this.createShipment()} className="addContactButton"><Fi.FiPlus /></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                {
                                    this.state.CoShipments !== null &&
                                    this.state.CoShipments.map((data, i) => (
                                        <tr key={'po-invoices'+i} onClick={()=>this.editShipment(data._id)}>
                                            <td className='text-uppercase'>{data.number}</td>
                                            <td className='text-uppercase'>{data.invoiceNumber}</td>
                                            <td>{data.deliveryDate}</td>
                                            <td>{data.status}</td>
                                            <td>{data.waybillNotes}</td>
                                            <td>{data.pickingListNotes}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>
                        </div>

                        <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                            <div className="footerTitle">Notes</div>
                            <table>
                                <thead>
                                <tr>
                                    <th className="tableHeader"><span>Created</span></th>
                                    <th className="tableHeader"><span>Modified</span></th>
                                    <th className="tableHeader w-50">Notes</th>
                                    <th className="tableAddButton text-right"><div onClick={()=>this.createCustomerNote()} className="addContactButton"><Fi.FiPlus /></div></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.customerOrderNotes !== null &&
                                    this.state.customerOrderNotes.map((data, i) => (
                                        <tr key={data.id} className={data.is_important ? "__imp_note": ""} onClick={()=>this.editCustomerOrderNote(data)}>
                                            <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br/><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                            <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br/><span  style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                            <td className="texttop preline">{data.note}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }

                                </tbody>
                            </table>
                        </div>


                </div>
                
                {
                    this.state.isLoading &&
                    <Loading />
                }
                
            </div>
        )
    }
}