import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'
import FlatList from 'flatlist-react';
import * as LS from './../../../constants/SetLocalstorage'
import { RiFolderDownloadFill } from "react-icons/ri";
import { convertDateStringMMDDtoDDMM } from '../../../constants/DateFormatter';


const settings = [{
    text: "Account", id: "account"
}, {
    text: "Billing", id: "billing"
}, {
    text: "Database maintenance", id: "backup_management"
}, {
    text: "Delete profile", id: "deleteprofile"
}
]


export default class AccountAndBilling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "account",

            checkDeleteTermOne: false,
            checkDeleteTermTwo: false,
            checkDeleteTermThree: false,
            checkDeleteTermFour: false,

            accountId: "",
            adminUser: "",
            companyName: "",
            email: "",
            expiryDate: "",
            subUser: "",

        };
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount() {
        let resp = localStorage.getItem('userdata')

        console.log("WEBSERVICES_URL===>", APIManager.WEBSERVICES_URL);
        if (resp) {
            let accountId = JSON.parse(LS.getLS('userdata')).account_id
            this.setState({
                accountId: accountId
            }, () => {
            })


        }

        const getSelectedIndex = localStorage.getItem('account_and_billing_selected_index')

        if (getSelectedIndex === "" || getSelectedIndex === null) {
            this.setState({
                selectedIndex: 'account',
            })
        } else {
            this.setState({
                selectedIndex: getSelectedIndex,
            })
        }

        this.callAccountDetails()
    }

    callAccountDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAccountDetails()
            .then(response => {
                this.setState({
                    accountId: response.data.accountId.split("-")[0],
                    adminUser: response.data.adminUser,
                    companyName: response.data.companyName,
                    email: response.data.email,
                    expiryDate: response.data.expiryDate,
                    subUser: response.data.subUser,
                    isLoading: false
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    deleteAccount = () => {

        var r = window.confirm("Are you sure you want to delete this account? This action cannot be undone.");
        if (r === true) {
            localStorage.clear();
            // APIManager.profileDelete ();
            this.props.history.replace('/login')
        }
    }

    dataBackup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.backupData()
            .then(response => {

                let url = APIManager.BASE_URL+response.data
              
                this.setState({
                    filePath: url,
                    isLoading: false
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    downloadIt = () => {
        var name = "backup.zip";
        if (name && name !== '') {
            var link = document.createElement('a');
            link.download = name;
            link.href = this.state.filePath;
            link.click();
        }
    }

    viewplans = () => {
        this.props.history.push({
            pathname: '/editsubscriptionplan'
        })
    }

    renderSettings = (settings, index) => {

        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts'} onClick={() => {
                this.setState({
                    selectedIndex: index,
                }, () => {
                    localStorage.setItem('account_and_billing_selected_index', index);
                });
            }}
            >
                {settings.text}
            </div>
        );
    }


    goBack = () => {
        this.props.history.goBack();
    }
    render() {

        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isSettings={true} />
                <SettingsHeader isAccountAndBilling={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{Strings.Settings.AccountAndBilling}</div>
                            </div>
                        </div>
                    </div>


                    <div className="tableContent mh400">
                        <div className="datamenus">
                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>
                        {
                            this.state.selectedIndex === "account" ?
                                <div className="dataDetails">
                                    <h5 className="font-weight-bold mb-2">Account</h5>
                                    <div className="clear"></div>
                                    <div className="float-left mb-2 width15">Account ID</div> <div className="detailsValue text-uppercase">: {this.state.accountId}</div>
                                    <div className="clear"></div>
                                    <div className="float-left mb-2 width15">Company Name</div> <div className="detailsValue text-uppercase">: {this.state.companyName}</div>
                                    <div className="clear"></div>
                                    <div className="float-left mb-2 width15">Email</div> <div className="detailsValue">: {this.state.email}</div>
                                    <div className="clear"></div>
                                    <div className="float-left mb-2 width15">Admin User(s)</div> <div className="detailsValue">: {this.state.adminUser}</div>
                                    <div className="clear"></div>
                                    <div className="float-left mb-2 width15">Subuser(s)</div> <div className="detailsValue">: {this.state.subUser}</div>
                                    <div className="clear"></div>
                                    <div className="float-left mb-2 width15">Expiry Date</div> <div className="detailsValue">: {convertDateStringMMDDtoDDMM(this.state.expiryDate)}</div>
                                    <div className="clear"></div>
                                </div>
                                :
                                this.state.selectedIndex === "billing" ?
                                    <div className="dataDetails">
                                        <h5 className="font-weight-bold mb-2">Billing</h5>
                                        <div className="clear"></div>
                                        <div className="float-left mb-2 width15">Account ID</div> <div className="detailsValue text-uppercase">: {this.state.accountId}</div>
                                        <div className="clear"></div>
                                        <div className="float-left mb-2 width15">Company Name</div> <div className="detailsValue text-uppercase">: {this.state.companyName}</div>
                                        <div className="clear"></div>
                                        <div className="float-left mb-2 width15">Billing-email</div> <div className="detailsValue">: {this.state.email}</div>
                                        <div className="clear"></div>
                                        <div className="float-left mb-2 width15">Subscription Plan</div> <div className="detailsValue width30">: </div> <div className="add_btnSave" onClick={() => this.viewplans()}>View Plan</div>
                                       
                                        <div className="clear"></div>
                                        <div className="float-left mb-2 width15">Paid Date</div> <div className="detailsValue">: </div>
                                        <div className="clear"></div>
                                        <div className="float-left mb-2 width15">Expiry Date</div> <div className="detailsValue">: {convertDateStringMMDDtoDDMM(this.state.expiryDate)}</div>
                                        <div className="clear"></div>
                                        {/* <div className="setEditButton">Renew Plan</div> */}
                                        <div className="clear"></div>
                                    </div>
                                    :
                                    this.state.selectedIndex === "backup_management" ?
                                        <div className="dataDetails">
                                            <h5 className="font-weight-bold mb-4">Database maintenance</h5>
                                            <div className="clear"></div>
                                            <h6 className="font-weight-bold mb-2">Back-up</h6>
                                            <div className="detailsValue mb-2 h6">This function allows downloding all data  to have a copy of it on your computer. It is advised to do it at least once a week.</div>
                                            <div className="detailsValue mb-2 h6">Stop all user's activity before backing up.</div>
                                            <div className="clear"></div>
                                            <div className='row'>
                                                <div className="col-lg-1 ml-3 setEditButton" onClick={() => this.dataBackup()}>Backup</div>
                                                {/* <div className='col-lg-1'></div> */}

                                                {this.state.filePath ?

                                                    <div className='col-lg-3 ' onClick={() => this.downloadIt()}><RiFolderDownloadFill  className='setDownloadButton' size={40}/> </div> :

                                                    // <div className='col-lg-3'> <a className='setEditButton' href={this.state.filePath}><RiFolderDownloadFill size={25} /> </a> </div> :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        :
                                        this.state.selectedIndex === "deleteprofile" ?
                                            <div className="dataDetails">
                                                <h5 className="font-weight-bold mb-2">Delete OEMUp account</h5>
                                                <div className="clear mb-4"></div>
                                                <div className="float-left mb-2 mr-2 h6">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.checkDeleteTermOne}
                                                        onChange={() => {
                                                            this.setState({
                                                                checkDeleteTermOne: !this.state.checkDeleteTermOne
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="detailsValue mb-2 h6">All CRM, Procurement, Production data will be deleted permanently</div>
                                                <div className="clear"></div>
                                                <div className="clear mb-3"></div>
                                                <div className="float-left mb-2 mr-2 h6">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.checkDeleteTermTwo}
                                                        onChange={() => {
                                                            this.setState({
                                                                checkDeleteTermTwo: !this.state.checkDeleteTermTwo
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="detailsValue mb-2 h6">All Settings and Subusers data will be deleted permanently</div>
                                                <div className="clear"></div>
                                                <div className="clear mb-3"></div>
                                                <div className="float-left mb-2 mr-2 h6">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.checkDeleteTermThree}
                                                        onChange={() => {
                                                            this.setState({
                                                                checkDeleteTermThree: !this.state.checkDeleteTermThree
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="detailsValue mb-2 h6">Remaining plan and pricing will be zero and non-refundable</div>
                                                <div className="clear"></div>
                                                <div className="clear mb-3"></div>
                                                <div className="float-left mb-2 mr-2 h6">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.checkDeleteTermFour}
                                                        onChange={() => {
                                                            this.setState({
                                                                checkDeleteTermFour: !this.state.checkDeleteTermFour
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="detailsValue mb-2 h6">I read all the terms {'&'} conditions  above and I accept it.</div>
                                                <div className="clear"></div>
                                                {
                                                    (this.state.checkDeleteTermOne && this.state.checkDeleteTermTwo && this.state.checkDeleteTermThree && this.state.checkDeleteTermFour) ?
                                                        <div className="setEditButton" onClick={() => this.deleteAccount()}>Delete</div>
                                                        :
                                                        <div className='setEditButton disabled-del-btn'>Delete</div>
                                                }


                                                <div className="clear"></div>
                                            </div>
                                            :
                                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
}