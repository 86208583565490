export const convertDateToMMDDYYYY = dt => {
    if(dt){
        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return month + '/' + day + '/' + year;
    }else{
        return ""
    }
}

export const convertDateToDDMMYYYY = dt => {
    if (dt) {
        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return day + '/' + month + '/' + year;
    } else {
        return "";
    }
}


export const convertDateToMMDDYYYYWithoutZero = dt =>{
    if (dt) {
        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();
        return month + '/' + day + '/' + year;
    } else {
        return "";
    }
}

export const convertDateStringMMDDtoDDMM = dt =>{
    if(dt){
        let d = dt.split('/')
        let date = ''
        if(d.length > 1){
            date = d[1]+'/'+d[0]+'/'+d[2]
        }else{
            date = dt
        }
        return date
    }else{
        return "";
    }
}

export const convertDateTimeStringMMDDtoDDMM = dt => {
    let newDate = "";
    if(dt){
        let splitDate = dt.split(' ')
        let d = splitDate[0].split('/')
        let time = ""
        if(splitDate[1]){
            time = splitDate[1]
        }
        if(splitDate[2]){
            time = time+' '+splitDate[2]
        }
        newDate = d[1]+'/'+d[0]+'/'+d[2]+' '+ time
    }
    return newDate;
}

export const convertDateStringtoDateObject = dt => {
    if(dt){
        let date = new Date(dt);
        return date
    }else{
        return ""
    }
}

export const convertDateStringToDateAndTimeObject = dt => {
    if(dt){
        var hours = dt.getHours();
        var minutes = dt.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        
        let newDate = ((parseInt(dt.getMonth()+1) < 10 )?"0":"") + parseInt(dt.getMonth()+1) +"/"+ ((dt.getDate() < 10)?"0":"") + dt.getDate() +"/"+ dt.getFullYear()+" "+ strTime;
  
        return newDate
    }else{
      return "";
    }
}

export const convertDateStringToDateAndTimeObjectDDMMYY = dt => {
    if(dt){
        var hours = dt.getHours();
        var minutes = dt.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        var month = ((parseInt(dt.getMonth()+1) < 10 )?"0":"") + parseInt(dt.getMonth()+1) 
        var day = ((dt.getDate() < 10)?"0":"") + dt.getDate() 
        var year = dt.getFullYear();
        
        let newDate = day +"/"+ month +"/"+ year +" "+strTime
        
  
        return newDate
    }else{
      return "";
    }
}

export const convertDateFormatFromFullDate = dt => {
    if(dt){
        let currentDate = dt;
        var day = String(currentDate.getDate()).padStart(2, '0');
        var month = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var year = currentDate.getFullYear();
        return month + '/' + day + '/' + year;
    }else{
        return ""
    }
}

export const convertDateStringFormatToMMDDYYObject = dt => {

    if(dt instanceof Date){
        if(dt){
            let currentDate = dt;
            var day = String(currentDate.getDate()).padStart(2, '0');
            var month = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            var year = currentDate.getFullYear();
            return month + '/' + day + '/' + year;
        }else{
            return ""
        }
    }else{
        if(dt){
            let newDate = dt.split('/')
            let day = newDate[0];
            let month = newDate[1];
            let year = newDate[2]
    
            return new Date(month + '/' + day + '/' + year);
        }else{
            return "";
        }
    }
}