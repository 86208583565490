import React from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'
import * as Fi from "react-icons/fi";

import FlatList from 'flatlist-react';

import Helper from './../../../helper/helper'
import {
    FiPlus,
    FiSearch,
    FiX,
    FiPrinter
} from "react-icons/fi";

import jsPDF from "jspdf";
import "jspdf-autotable";

const settings = [{
    text: "Product groups", id: "productgroups"
}, {
    text: "Units of measurements", id: "unitsofmeasurements"
}, {
    text: "Storage locations", id: "storagelocations"
}
]

export default class StockSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageLoading: false,
            selectedIndex: "productgroups",
            responseProductGroup: [],
            responseUnitOfMeasure: [],
            responseStorageLocation: [],
            searchPGNumber: "",
            searchPGName: "",
            searchSLName: "",
            fieldName: '',
            fieldType: '',
            sortVal: true,

            showPrintPopUp: false,
            storageLocationPrintVal: "",
        };
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount() {
        const getSelectedIndex = localStorage.getItem('stock_setting_selected_index')
        if (getSelectedIndex === "" || getSelectedIndex === null) {
            this.setState({
                selectedIndex: 'productgroups',
            })
        } else {
            this.setState({
                selectedIndex: getSelectedIndex,
            })
        }
        this.showLoadingEffect()
    }

    componentWillMount() {
        // this.getProductGroupList()
        // this.getUnitOfMeasure()
        // this.getStorageLocations()

        this.getFilterData()
        this.setLocalToStorage()
    }

    //******************* ascending descending order function Product Group********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseProductGroup.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    console.log(dateA, dateB);
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ responseProductGroup: sortedOrders }, () => {
                this.setFilterData();
            });
        });

    };
    //******************* ascending descending order function end  Product Group ********************************

    //******************* ascending descending order function Unit of Measurement ********************************
    sortCustomerUOM = (fieldName, fieldType, onload) => {
        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseUnitOfMeasure.sort((a, b) => {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            });
            this.setState({ responseUnitOfMeasure: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end Unit of Measurement ********************************
    //******************* ascending descending order function Storage Location ********************************
    sortCustomerStorageLocaton = (fieldName, fieldType, onload) => {
        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseStorageLocation.sort((a, b) => {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            });
            this.setState({ responseStorageLocation: sortedOrders }, () => {
                this.setFilterData();
            });
        });

    };
    //******************* ascending descending order function end Storage Location ********************************

    getProductGroupList = () => {
        this.setFilterData()



        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();

        if (this.state.searchPGName !== "")
            formdata.append("name", this.state.searchPGName);

        if (this.state.searchPGNumber !== "")
            formdata.append("number", this.state.searchPGNumber);

        fetch(APIManager.WEBSERVICES_URL + 'stock/getProductGroup', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseProductGroup: result.data.productGroup,
                    }, () => {
                        if(this.state.fieldName){
                            this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                        } 
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            }
            )
    }

    setFilterData = () => {

        let filterDataArray = {
            'searchPGNumber': this.state.searchPGNumber,
            'searchPGName': this.state.searchPGName,
            'searchSLName': this.state.searchSLName,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        localStorage.setItem("stockSetting", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("stockSetting"))
        if (filterData !== null) {
            this.setState({
                searchPGNumber: filterData.searchPGNumber,
                searchPGName: filterData.searchPGName,
                searchSLName: filterData.searchSLName,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getProductGroupList()
                this.getUnitOfMeasure()
                this.getStorageLocations()
            })
        } else {
            this.getProductGroupList()
            this.getUnitOfMeasure()
            this.getStorageLocations()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("stockSetting")
        this.setState({
            searchPGNumber: "",
            searchPGName: "",
        }, () => {
            this.getProductGroupList()
        }
        )
    }

    setStorage = () => {
        let filterDataArray = {
            'searchSLName': this.state.searchSLName,
        }

        localStorage.setItem("stockStorage", JSON.stringify(filterDataArray))

    }
    setLocalToStorage = () => {
        let filterStorage = JSON.parse(localStorage.getItem("stockStorage"))
        if (filterStorage !== null) {
            this.setState({

                searchSLName: filterStorage.searchSLName,
            }, () => {
                this.getProductGroupList()
                this.getUnitOfMeasure()
                this.getStorageLocations()
            })
        } else {
            this.getProductGroupList()
            this.getUnitOfMeasure()
            this.getStorageLocations()
        }
    }

    clearStorage = () => {
        localStorage.removeItem("stockStorage")
        this.setState({

            searchSLName: "",
        }, () => {
            this.getStorageLocations()

        }
        )
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }


    getUnitOfMeasure = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllUnitOfMeasurement()
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseUnitOfMeasure: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });

    }

    getStorageLocations = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();

        if (this.state.searchSLName !== "")
            formdata.append("name", this.state.searchSLName);


        fetch(APIManager.WEBSERVICES_URL + 'stock/stocksetting/getAllStorageLocation', {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if (result.status === "success") {
                    that.setState({
                        responseStorageLocation: result.data,
                    })
                } else if (result.status === "userDataError") {
                    that.logout()
                } else {

                }
            }, (error) => {
                console.log(error)
            })
        this.setStorage()
    }

    renderSettings = (settings, index) => {
        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts'} onClick={() => {
                this.setState({
                    selectedIndex: index,
                }, () => {
                    this.showLoadingEffect()
                    localStorage.setItem("stock_setting_selected_index", index)
                });
            }}
            >
                {settings.text}
            </div>
        );
    }

    showLoadingEffect = () => {
        // this.setState({
        //     pageLoading: true,
        // },()=>{            
        //     setTimeout(()=>{
        //         this.setState({ pageLoading: false,})
        //     },1000)
        // })
    }



    goBack = () => {
        this.props.history.goBack();
    }

    printThisLocation = (name) => {
        let numOfEntry = 0;
        var regex = /^[0-9\b]+$/;    // allow only numbers [0-9] 
        if (this.state.numberofPrints !== "") {
            if (!regex.test(this.state.numberofPrints)) {
                numOfEntry = 1
            } else if (this.state.numberofPrints > 100) {
                numOfEntry = 100;
            } else {
                numOfEntry = this.state.numberofPrints
            }
        } else {
            numOfEntry = 1;
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        //const title = name;
        const title = "";

        const headers = ""
        const data = []
        for (let i = 0; i < numOfEntry; i++) {
            data.push([name])
        }

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, 100, 20);
        doc.autoTable(content);
        doc.save(name + ".pdf")


        this.setState({ showPrintPopUp: false })
    }

    printAllLocation = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "";

        const headers = ""
        const data = this.state.responseStorageLocation.map((elt, i) => [
            elt.name
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, 100, 20);
        doc.autoTable(content);
        doc.save("Storagelocation.pdf")
    }

    _importStorageData = () => {
        this.props.history.push('/importstorage')
    }

    addProductGroup = () => {
        let slug = "Stock_StockSettings"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createproductgroup"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }


    editProdGroup = (_id, number, name) => {
        localStorage.setItem("prodGrpId", _id)
        localStorage.setItem("prodGrpNum", number)
        localStorage.setItem("prodGrpName", name)
        this.props.history.push({
            pathname: "/editproductgroup"
        })


    }

    addUoM = () => {
        let slug = "Stock_StockSettings"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createunitofmeasure"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editUnitOfMeasure = (_id) => {
        localStorage.setItem("measurementId", _id)
        this.props.history.push({
            pathname: "/editunitofmeasure"
        })
    }

    addStorageLocation = () => {
        let slug = "Stock_StockSettings"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createstoragelocation"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editStorageLocation = (_id, name) => {
        localStorage.setItem("stoLocId", _id)
        localStorage.setItem("stoLocName", name)
        this.props.history.push({
            pathname: "/editstoragelocation"
        })
    }


    render() {
        return (
            <div className="page absoluteWhite">
                {
                    this.state.pageLoading &&
                    <div className="pageLoading"></div>
                }
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isStockSettings={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.Stock.StockSettings}</div>
                            </div>

                            <div className="col-lg-3 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {
                                        // this.state.selectedIndex === "storagelocations" &&
                                        // <div className="button" onClick={()=>this._importStorageData()}>Import from CSV <ImArrowUp2 /></div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="pagecontent">
                    <div className="headerArea">
                        <div className="pageTitle heading">{Strings.Stock.StockSettings}</div>
                        {
                             this.state.selectedIndex === "storagelocations" &&
                                <div className="otherButtons">
                                    <div className="oButton fou" onClick={()=>this._importStorageData()}>Import from CSV <ImArrowUp2 className="downIcon"/></div>
                                    <div className="clear"></div>
                                </div>
                        }
                        <div className="clear"></div>
                    </div>
                </div> */}
                    <div className="tableContent __kDoEM__table">
                        <div className="datamenus">
                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>
                        {
                            this.state.selectedIndex === "productgroups" ?
                                <div className="stocksetting dataDetails">
                                    <div style={{ overflowX: "auto" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="tableHeader tableHeadButton" style={{ width: 40 }}><div onClick={() => this.addProductGroup()}><FiPlus /></div></th>
                                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                                    <th className="tableHeader" onClick={() => this.sortCustomer('name', 'notDate', false)}><span>Name</span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "itemCode" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                                    <th className="tableHeader"></th>
                                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addProductGroup()}><FiPlus /></div></th>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: 40 }} className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                                    <th style={{ width: 100 }} className="tableElemContain"><input type="text" value={this.state.searchPGNumber} onChange={(e) => this.setState({ searchPGNumber: e.target.value }, () => { this.getProductGroupList() })} /></th>
                                                    <th className="tableElemContain"><input type="text" value={this.state.searchPGName} onChange={(e) => this.setState({ searchPGName: e.target.value }, () => { this.getProductGroupList() })} /></th>
                                                    <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.getProductGroupList()}><FiSearch /></div></th>
                                                    <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.clearFilter()}><FiX /></div></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    this.state.responseProductGroup !== null &&
                                                    this.state.responseProductGroup.map((data, i) => (
                                                        <tr key={data._id} onClick={this.editProdGroup.bind(this, data._id, data.number, data.name)}>
                                                            <td style={{ width: 40 }}>{i + 1}</td>
                                                            <td style={{ width: 100 }}>{data.number.toUpperCase()}</td>
                                                            <td>{data.name}</td>
                                                            <td style={{ width: 40 }}></td>
                                                            <td style={{ width: 40 }}></td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                this.state.selectedIndex === "unitsofmeasurements" ?
                                    <div className="stocksetting dataDetails unitsofmeasure">
                                        <div style={{ overflowX: "auto" }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        {/* <th className="tableHeader"><span>Unit of measurement</span></th> */}
                                                        <th className="tableHeader" onClick={() => this.sortCustomerUOM('name', 'notDate', false)}><span>Unit of measurement</span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                                        <th className="tableHeader tableHeadButton text-right"><div onClick={() => this.addUoM()}><FiPlus /></div></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.responseUnitOfMeasure !== null &&
                                                        this.state.responseUnitOfMeasure.map((data, i) => (
                                                            <tr key={data._id} onClick={this.editUnitOfMeasure.bind(this, data._id)}>
                                                                <td className="pl-1">{data.name}</td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    this.state.selectedIndex === "storagelocations" ?
                                        <div className="stocksetting dataDetails">
                                            <div style={{ overflowX: "auto" }}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="tableHeader tableHeadButton"><div onClick={() => this.addStorageLocation()}><FiPlus /></div></th>
                                                            {/* <th className="tableHeader"><span>Storage location</span></th> */}
                                                            <th className="tableHeader" onClick={() => this.sortCustomerStorageLocaton('name', 'notDate', false)}><span>Storage location</span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                                            <th></th>
                                                            <th></th>
                                                            <th className="tableHeader tableHeadButton"><div onClick={() => this.addStorageLocation()}><FiPlus /></div></th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: 40 }} className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                                            <th className="tableElemContain"><input type="text" value={this.state.searchSLName} onChange={(e) => this.setState({ searchSLName: e.target.value }, () => { this.getStorageLocations() })} /></th>
                                                            <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.getStorageLocations()}><FiSearch /></div></th>
                                                            <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.clearStorage()}><FiX /></div></th>
                                                            <th style={{ width: 40 }} className="tableElemContain tableAddButton"><div onClick={() => this.printAllLocation()}><FiPrinter /></div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.responseStorageLocation !== null &&
                                                            this.state.responseStorageLocation.map((data, i) => (
                                                                <tr key={data._id} onClick={this.editStorageLocation.bind(this, data._id, data.name)}>
                                                                    <td style={{ width: 40 }}>{i + 1}</td>
                                                                    <td>{data.name}</td>
                                                                    <td style={{ width: 40 }}></td>
                                                                    <td style={{ width: 40 }}></td>
                                                                    <td style={{ width: 40 }}><div onClick={() => this.setState({ showPrintPopUp: true, storageLocationPrintVal: data.name })}><FiPrinter className="editCusomerBtn" /></div></td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        null
                        }
                        {
                            this.state.showPrintPopUp &&
                            <div className="print_popup_cover">
                                <div className="print_popup_content">
                                    <div>
                                        <div className="pp_title float-left">Print label</div>
                                        <div className="float-right" onClick={() => this.setState({ showPrintPopUp: false })}><FiX className="pp_closeBtn" /></div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="pp_content">How many labels do you want to print? Up to 100 labels</div>
                                    <div className="pp_textinput"><input type="text" value={this.state.numberofPrints} onChange={(e) => this.setState({ numberofPrints: e.target.value })} /></div>
                                    <div>
                                        <div className="pp_btn float-right" onClick={() => this.printThisLocation(this.state.storageLocationPrintVal)}>PRINT</div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}