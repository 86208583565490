import React, { Component } from 'react';
import * as APIManager from './../../../APIManager/APIManager'
import { Header, StockHeader } from './../../../components'
import * as Ai from 'react-icons/ai'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';

import Loading from '../../../components/Loading';

import ShipmentWriteOffs from './shipmentWriteOffs';
import { convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

class CreateShipment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            customerOrderList: [],
            invoiceList: [],
            shippingaddress: [],
            shippingGoodsData: [],

            customerId: "",

            status: "new",

            deliverydate: "",
            customerOrder: "",
            invoice_id: "",
            bookedItems: [],
            deliveryAddress: "",
            wayBillNote: "",
            wayBillDocument : "",
            pickingListNote: "",
            trackingNumber: "",
        }
    }

    componentDidMount() {
        this.getCustomerOrders()

        if (this.props.location.state.customerOrderId) {
            this.setState({
                customerOrder: this.props.location.state.customerOrderId
            }, () => {
                this.getSingleCustomerOrder(this.state.customerOrder)
            })
        }
    }

    getCustomerOrders = () => {
        this.setState({ isLoading: true, error: false })

        let statuses = ['ready for shipment','partially invoiced','partially shipped','invoiced']
        APIManager.callCustomerOrderListExceptQuotation("", "", "", "", "", "", "", statuses.toString(), "", "", "", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    customerOrderList: response.data,
                    isLoading: false,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getSingleCustomerOrder = (orderId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerOrderDetail(orderId)
            .then(response => {
                this.setState({
                    isLoading: false,
                    customerId: response.customer
                }, () => {
                    this.getInvoices(orderId)
                    this.getCustomerShippingAddress(response.customer)
                   // this.getWriteOffDetails()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    getInvoices = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCOShipmentInvoices(id)
        .then(response => { 
            this.setState({
                isLoading: false,
                invoiceList: response.data
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    getWriteOffDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callShipmentListByOrderId(this.state.customerOrder)
            .then(response => {
                let setFields = []
                let bookedGoods = []
                for (let i = 0; i < response.data.length; i++) {
                    setFields.push(
                        <ShipmentWriteOffs
                            key={"shipmentsData" + i}
                            groupData={response.data[i]}
                        />
                    )
                    let obj = {
                        stockItem: response.data[i].itemId,
                        stockLot: response.data[i].stockLotId,
                        status: response.data[i].status,
                        storageLocation: response.data[i].storageLocationId,
                        bookedTotal: response.data[i].quantity,
                        writeOffId: response.data[i].writeOffId,
                        lot: response.data[i].lot,
                        stockName: response.data[i].stockItem,
                        storageLocationName: response.data[i].storageLocationName
                    }
                    bookedGoods.push(obj)
                }
                this.setState({
                    isLoading: false,
                    shippingGoodsData: setFields,
                    bookedItems: bookedGoods
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getCustomerShippingAddress = (customer) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                let addresses = []
                for (let i = 0; i < response.length; i++) {
                    addresses.push(response[i].split('_').join(' '))
                }
                this.setState({
                    shippingaddress: addresses,
                    isLoading: false,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    saveShipment = () => {
        let {
            deliverydate,
            customerOrder,
            invoice_id,
            deliveryAddress,
            wayBillNote,
            wayBillDocument,
            pickingListNote,
            trackingNumber,
            status
        } = this.state

        if (customerOrder === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select order"
            },()=>{
                this.scrollToTop()
            })
        } else if(invoice_id === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select invoice"
            },()=>{
                this.scrollToTop()
            })
        } else if (deliveryAddress === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select customer's delivery address"
            },()=>{
                this.scrollToTop()
            })
        } else if (deliverydate === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select delivery date"
            },()=>{
                this.scrollToTop()
            })
        } else  {
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateShipment(convertDateToMMDDYYYY(deliverydate), customerOrder, deliveryAddress, wayBillNote,wayBillDocument, pickingListNote, trackingNumber, invoice_id, status)
            .then(response => {
                this.setState({
                    isLoading: false,
                }, () => {
                    this.props.history.push({
                        pathname: '/editshipment',
                        state: { shipmentId: response.data._id, newShipmentEntry: true }
                    })
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors.message
                },()=>{
                    this.scrollToTop()
                })
            });
        }
    }


    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isShipments={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a new shipment</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveShipment()}>Save</div>
                            <div className="add_btnBack" onClick={() => this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className='position-relative'>
                            <div className='add_partside'>
                                <div className="add_inner">
                                    <div className="title">Customer order *</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.customerOrderList.map((data) => (
                                                    { id: data._id, text: data.number.toUpperCase() +' '+ data.customerName }
                                                ))
                                            }
                                            value={this.state.customerOrder}
                                            options={{placeholder: 'Select Order'}}
                                            onSelect={(e)=> this.setState({ customerOrder: e.target.value },()=>{ this.getSingleCustomerOrder(e.target.value) })}
                                        />
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice *</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.invoiceList.map((data) => (
                                                    { id: data._id, text: data.number.toUpperCase() }
                                                ))
                                            }
                                            value={this.state.invoice_id}
                                            options={{placeholder: 'Select Invoice'}}
                                            onSelect={(e)=> this.setState({ invoice_id: e.target.value })}
                                        />
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Status *</div>
                                    <div className="value">
                                        <select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                            <option value="new">New</option>
                                        </select>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                
                            </div>
                            <div className='clear'></div>
                        </div>
                        {/* <div className='position-relative'>
                            <div className='add_partside width100'>
                                <div className="add_inner">
                                    <div className="title shipment__booked_item__title">Booked goods</div>
                                    <div className="value">
                                        <div className="tableContent" style={{ overflowX: "auto" }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Stock item</th>
                                                        <th>Booked quantity</th>
                                                        <th>Lot</th>
                                                        <th>Status</th>
                                                        <th>Storage location</th>
                                                        <th>Quantity to pick</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state.shippingGoodsData
                                                    }



                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                            </div>
                            <div className='clear'></div>
                        </div> */}
                        <div className='position-relative'>
                            <div className='add_partside'>
                                <div className="add_inner">
                                    <div className="title">Delivery address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.shippingaddress.map((data) => (
                                                    { id: data, text: data }
                                                ))
                                            }
                                            value={this.state.deliveryAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ deliveryAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery date</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={
                                                this.state.deliverydate
                                            }
                                            onChange={
                                                date => this.setState({ deliverydate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Waybill notes</div>
                                    <textarea value={this.state.wayBillNote} onChange={(e) => this.setState({ wayBillNote: e.target.value })} className="value __customer_order_note_field"></textarea>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Waybill Document </div>
                                    <div className="value"><input type="file" className="fieldheightdecr input-file" name="file" accept=".pdf,.doc" onChange={(e) => this.setState({wayBillDocument: e.target.files[0]})} ></input></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Picking list notes</div>
                                    <textarea value={this.state.pickingListNote} onChange={(e) => this.setState({ pickingListNote: e.target.value })} className="value __customer_order_note_field"></textarea>
                                    <div className='clear'></div>
                                </div>
                            </div>
                            <div className='clear'></div>
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveShipment()}>Save</div>
                            <div className="add_btnBack" onClick={() => this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default CreateShipment;