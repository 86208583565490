import React from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import toast from 'react-simple-toasts';
import * as APIManager from './../../APIManager/APIManager'
import * as Images from './../../assets/Images/index'
import Popup from 'reactjs-popup';

import './../../lib/react-big-caledar/dragAndDrop/styles.css'
import './../../lib/react-big-calendar.css'
import moment from 'moment'
import { convertDateTimeStringMMDDtoDDMM, convertDateStringToDateAndTimeObject, convertDateStringToDateAndTimeObjectDDMMYY } from '../../constants/DateFormatter';

const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)  

class ProductionCalendar extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
        events: [],
        draggedEvent: [],
        waitingList: [],
        displayDragItemInCell: true,
        setSelectedEvent: [],
        updateEventDate: "",
        setModalState: false,
        dragFromOutSideDate: ""
    }
    this.moveEvent = this.moveEvent.bind(this)
  }

  componentDidMount(){
    this.callProductionPlanningOperations()
    this.callGetWaitingList()
  }

  callGetWaitingList = () => {
    this.setState({ isLoading: true, error: false })
    APIManager.callGetWaitingListMO()
    .then(response => {
      this.setState({ 
        isLoading: false,
        waitingList: response.data
      })
    })
    .catch(errors => {
        this.setState({ 
            isLoading: false,
            error: true,
            errormessage: errors 
        })
    });
  }

  toggleMOWaiting = (OperationID,operationAction,startDate) => {
    this.setState({ isLoading: true, error: false })
    APIManager.callMoveMoToWaitingList(OperationID, operationAction, startDate)
    .then(response => {
      this.setState({ 
        setSelectedEvent: [],
        updateEventDate: "",
        setModalState: false,
        isLoading: false,
      },()=>{
        this.callProductionPlanningOperations()
        this.callGetWaitingList()
      })
    })
    .catch(errors => {
        this.setState({ 
            isLoading: false,
            error: true,
            errormessage: errors 
        })
    });
  }

  callProductionPlanningOperations = () => {
    this.setState({ isLoading: true, error: false })
    APIManager.callProductionPlanningOperations()
    .then(response => {
      console.log("Response ", response)
        let updatedEvents = []
        let data = response.data
        for(let i=0; i<data.length;i++){
          let workStationDetails = data[i].routingDetails[0].workStationDetails
          for(let j = 0; j<workStationDetails.length; j++){
           
            let isMOStarted = data[i].is_MoStart ? true : false
            let name = workStationDetails[j].name ? workStationDetails[j].name : workStationDetails[j].workStationName
            let desc = workStationDetails[j].description ? workStationDetails[j].description : ''

            let eve = {
              id: data[i]._id,
              operationId: workStationDetails[j].id,
              title: name+" "+ desc +" "+convertDateTimeStringMMDDtoDDMM(workStationDetails[j].startDate)+" - "+convertDateTimeStringMMDDtoDDMM(workStationDetails[j].endDate),
              start: new Date(workStationDetails[j].startDate),
              end: new Date(workStationDetails[j].endDate),
              color: workStationDetails[j].colorValue,
              isMOStarted: isMOStarted
            }
            updatedEvents.push(eve)
          }
        }
        this.setState({ 
            isLoading: false,
            events: updatedEvents
        })
    })
    .catch(errors => {
        this.setState({ 
            isLoading: false,
            error: true,
            errormessage: errors 
        })
    });
  }
  moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
      this.updateTheStartEndDates(start, event.operationId)
  }

  dragStart = (event) => {
      console.log("dragStart event", event)
  }

  dragFromOutside = () => {
    return null
  }

  dropFromList = (event) => {
    console.log("Dropping Event ", event)
    let {
      dragFromOutSideDate
    } = this.state
    if(dragFromOutSideDate){
      this.toggleMOWaiting(event.id, "0", dragFromOutSideDate)
    }else{
      toast("You can't move operation to past dates")
    }
  }
  
  onDropFromOutside = ({ start, end, allDay }) => { 
    let startDate = new Date(start)
    let today = new Date()
    if(startDate >= today){
      this.setState({
        dragFromOutSideDate: convertDateStringToDateAndTimeObject(startDate)
      }) 
    }
  }



  handleDragStartfrmPopup = (event) => {
    this.setState({
      draggedEvent: event
    })
  }

  updateTheStartEndDates = (start, OP_Id) => {

    let today = new Date()

    console.log("Move Event ", start)

    if(start >= today){
      this.updateEvent(OP_Id, start)
    }else{
      this.callProductionPlanningOperations()
    } 
  }

  updateEvent = (OP_Id, start) => {
      this.setState({ isLoading: true, error: false })
      APIManager.callGetEventEndDate(OP_Id, convertDateStringToDateAndTimeObject(start))
      .then(response => {
          this.setState({ 
              isLoading: false,
          },()=>{
              this.callProductionPlanningOperations()
          })
      })
      .catch(errors => {
          console.log(errors.message)
          this.setState({ 
              isLoading: false,
              error: true,
              errormessage: errors 
          },()=>{
            toast(errors.message)
            this.callProductionPlanningOperations()
          })
      });
  }



eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.color;
    var backgroundImage = event.isMOStarted ? Images.Stripes : ''
    var style = {
        backgroundColor: backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        borderRadius: '2px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block'
    };
    return {
        style: style
    }
}


handleSelectedEvent = (data) => {
  if(data.id){
    this.setState({
      setSelectedEvent: data,
      setModalState: true
    })
  }
}


hidePopup = () => {
  this.setState({
    setSelectedEvent: [],
    setModalState: false,
    updateEventDate: ""
  })
}

setNewDateWithLocal = () => {
    let now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    let min = now.toISOString().slice(0,16);
    return min
}

modal = () => {
  let {
    setSelectedEvent,
  } = this.state

  var backgroundImage = setSelectedEvent.isMOStarted ? Images.Stripes : ''

  return (
      <Popup open={this.state.setModalState} onClose={() => this.hidePopup()} className="calendarEventPopUp">
        <h3 className='text-center'>Event Options</h3>
        <div className='calendarEvent-mainContent' style={{ backgroundColor: setSelectedEvent.color, backgroundImage: `url(${backgroundImage})`, }}>
          <div className='div-data'><span>Operation Title : {setSelectedEvent.title} </span></div>
          <div className='div-data'><span>Start Time : {convertDateStringToDateAndTimeObjectDDMMYY(setSelectedEvent.start)} </span></div>
          <div className='div-data'><span>End Time : {convertDateStringToDateAndTimeObjectDDMMYY(setSelectedEvent.end)}</span></div>
          {
            !setSelectedEvent.isMOStarted &&
            <div className='div-date-buttons mb-3'>
              <div className='calendar-date-title'>Move event to specific date</div>
              <div>
                <div className='float-left position-relative date-picker-f'>
                    {/* To avaid autofocus */}
                    <input type="hidden" autoFocus />
                    <input 
                      type="datetime-local" 
                      min={this.setNewDateWithLocal()}
                      className='calendar-date-change'
                      value={this.state.updateEventDate}
                      onChange={(e)=>this.setState({ updateEventDate: e.target.value })}
                    />
                </div>
                <div className='float-left'>            
                  <div 
                    className='ope-button float-left' 
                    onClick={()=>{
                        if(this.state.updateEventDate){
                          this.updateTheStartEndDates(new Date(this.state.updateEventDate), setSelectedEvent.operationId)
                          this.hidePopup()
                        }
                      }
                    }>Update</div>
                </div>
              </div>
              <div className='clear'></div>
            </div>
          }
          <div className='div-operation-buttons'>
            <div className='ml-0 ope-button float-left' onClick={()=>this.viewProductionPlan(setSelectedEvent.id)}>Open Detail</div>
            {
              !setSelectedEvent.isMOStarted &&
              <div className='ope-button float-left' onClick={()=>this.toggleMOWaiting(setSelectedEvent.operationId, "1", "")}>Move to waiting list</div>
            }
            <div className='clear'></div>
          </div>
          <div></div>
        </div>
      </Popup>
  )
}

viewProductionPlan = (id) => {
  this.props.history.push({
      pathname: "/viewproductionplan",
      state: { MO_Id: id },
  })
}

  render() {
    return (
      <div>
        <div className='fluid-container'>
          <div className='row'>
            <div className='col-lg-10 col-md-10 col-sm-12'>
              <DragAndDropCalendar
                selectable
                localizer={localizer}
                defaultView={Views.MONTH}
                defaultDate={new Date()}
                popup={true}
                events={this.state.events}
                onEventDrop={this.moveEvent}
                onDragStart={this.dragStart}
                handleDragStart={this.handleDragStartfrmPopup}
                dragFromOutsideItem={this.dragFromOutside}
                onDropFromOutside={this.onDropFromOutside}
                style={{height: "100vh"}}
                eventPropGetter={(this.eventStyleGetter)}
                onSelectEvent={(e) => this.handleSelectedEvent(e)}
              />
            </div>
            <div className='col-lg-2 col-md-2 col-sm-12'>
              <div className="page-title m-0">Waiting List</div>
              <div className='waiting-listing' style={{height: "95vh", overflow: 'scroll' }}>
                {
                  this.state.waitingList.map((data, i) => (
                    <div 
                      className='waiting-operation' 
                      style={{ backgroundColor: data.colorValue }} 
                      draggable={true}
                      onDragEnd={()=>this.dropFromList(data)}
                      key={data.id+i}>
                          <div>{data.description}</div>
                          <div className='text-capitalize'>Workstation Group: {data.workStationGroupName}</div>
                          <div className='text-capitalize'>Workstation: {data.workStationName}</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.setSelectedEvent && this.modal()}
      </div>
    )
  }
}

export default ProductionCalendar