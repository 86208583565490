import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Pagination from "react-js-pagination";
import * as Fi from 'react-icons/fi'
import * as Bi from 'react-icons/bi'
import * as APIManager from './../../../APIManager/APIManager'
import TableLoading from '../../../helper/TableLoading';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import {
    ImArrowDown2,
} from "react-icons/im";


class Workstations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            number: "",
            name: "",
            type: "",
            minHourlyRate: "",
            maxHourlyRate: "",
            minProductivity: "",
            maxProductivity: "",
            fieldName: '',
            fieldType: '',
            sortVal: false,

            responseData: [],
            workstationGroups: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseData.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    console.log(dateA, dateB);
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ responseData: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************


    getAllWorkStationGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllWorkstationGroup("", "", "", "")
            .then(response => {
                this.setState({
                    isLoading: false,
                    workstationGroups: response.data
                }, () => {
                    this.getAllWorkstations()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                }, () => {
                    this.getAllWorkstations()
                })
            });
    }

    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllWorkstations()
        });
    }

    getAllWorkstations = () => {
        let {
            number,
            name,
            minHourlyRate,
            maxHourlyRate,
            minProductivity,
            maxProductivity,
            type,
            start,
            length
        } = this.state

        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllWorkstation(number, name, minHourlyRate, maxHourlyRate, minProductivity, maxProductivity, type, start, length)
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseData: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()

                    if (this.state.fieldName) {
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });

    }

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'type': this.state.type,
            'name': this.state.name,
            'minHourlyRate': this.state.minHourlyRate,
            'maxHourlyRate': this.state.maxHourlyRate,
            'minProductivity': this.state.minProductivity,
            'maxProductivity': this.state.maxProductivity,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage,
        }

        localStorage.setItem("productionPlaningWorkstations", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlaningWorkstations"))
        if (filterData !== null) {
            this.setState({
                number: filterData.number,
                type: filterData.type,
                name: filterData.name,
                minHourlyRate: filterData.minHourlyRate,
                maxHourlyRate: filterData.maxHourlyRate,
                minProductivity: filterData.minProductivity,
                maxProductivity: filterData.maxProductivity,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,

            }, () => {
                this.getAllWorkStationGroup()
            })
        } else {
            this.getAllWorkStationGroup()

        }
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlaningWorkstations")
        this.setState({
            number: "",
            type: "",
            name: "",
            minHourlyRate: "",
            maxHourlyRate: "",
            minProductivity: "",
            maxProductivity: "",
            length: 25,
            start: 0,
            activePage: 1,
        }, () => {
            this.getAllWorkstations()
        })
    }


    addWorkstation = () => {
        let slug = "ProductionPlanning_Workstations"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createws"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editWorkstation = (id) => {

        this.props.history.push({
            pathname: "/editws",
            state: { workstationId: id, newWorkstationEntry: false }
        })


    }

    exportAsPDF = () => {
        let responseData = this.state.responseData;

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Production Planning - Workstations";

        const headers = [[
            "Number",
            "Name",
            "Group",
            "Hourly Rate",
        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.name,
            elt.groupName,
            elt.hourlyRateValue
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("workstaton.pdf")
    }


    exportAsCSV = () => {
        let responseData = this.state.responseData;
        const title = "Production Planning - Workstations";
        const headers = [[
            "Number",
            "Name",
            "Group",
            "Hourly Rate",
        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.name,
            elt.groupName,
            elt.hourlyRateValue,
        ]);
        const options = {
            filename: 'workstaton',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }


    goBack = () => {
        this.props.history.goBack();
    }



    render() {
        return (
            <div className="page absoluteWhite">

                <Header logout={this.props.history} goBack={() => this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstations={true} />
                <div className="main-container">
                    <div className="page-header">

                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12 ">
                                <div className="page-title">{Strings.ProductionPlanning.Workstations}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <    ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 15 }}><div onClick={() => this.addWorkstation()}><Fi.FiPlus /></div></th>

                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)} style={{ width: 150 }}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('name', 'notDate', false)}><span>Name</span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('groupName', 'notDate', false)}><span>Group</span> {this.state.fieldName === "groupName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "groupName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('hourlyRateValue', 'notDate', false)} style={{ width: 150 }}><span>Hourly rate</span> {this.state.fieldName === "hourlyRateValue" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "hourlyRateValue" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    {/* <td className="text-uppercase">{data.number}</td>
                                            <td>{data.name}</td>
                                            <td>{data.groupName}</td>
                                            <td>{data.hourlyRateValue} <Bi.BiRupee /></td> */}
                                    {/* <th className="tableHeader" style={{ width: 150 }}><span>Number</span></th>
                                    <th className="tableHeader"><span>Name</span></th>
                                    <th className="tableHeader"><span>Group</span></th>
                                    <th className="tableHeader" style={{ width: 150 }}><span>Hourly rate</span></th> */}

                                    {/* <th className="tableHeader" style={{ width: 150 }}><span>Productivity</span></th> */}
                                    <th className="tableHeader tableHeadButton" style={{ width: 35 }}><div onClick={() => this.addWorkstation()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllWorkstations() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => { this.getAllWorkstations() })} /></th>
                                    <th className="tableElemContain" style={{ width: 350 }}>
                                        <select value={this.state.type} onChange={(e) => this.setState({ type: e.target.value }, () => { this.getAllWorkstations() })}>
                                            <option value=""></option>
                                            {
                                                this.state.workstationGroups !== null &&
                                                this.state.workstationGroups.map((data, i) => (
                                                    <option value={data._id} key={i + data._id}>{data.number.toUpperCase()} {data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minHourlyRate} onChange={(e) => this.setState({ minHourlyRate: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllWorkstations() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxHourlyRate} onChange={(e) => this.setState({ maxHourlyRate: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllWorkstations() })} />
                                    </th>
                                    {/* <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minProductivity} onChange={(e) => this.setState({ minProductivity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllWorkstations() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxProductivity} onChange={(e) => this.setState({ maxProductivity: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllWorkstations() })} />
                                    </th> */}
                                    <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><Fi.FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={6} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseData.map((data, i) => (
                                                <tr onClick={() => this.editWorkstation(data._id)} key={data._id}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.groupName}</td>
                                                    <td>{data.hourlyRateValue} <Bi.BiRupee /></td>
                                                    {/* <td>{data.productivity}</td> */}
                                                    <td>{/*<Fi.FiBarChart className="editCusomerBtn" />*/}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }

                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Workstations;