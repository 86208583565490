import React, { Component } from 'react';
import * as Fi from 'react-icons/fi'
import * as APIManager from './../APIManager/APIManager'

class FilterTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            responseColumn: [
              {
                name: <Fi.FiPlus />,
                visible: true,
                field: "",
                filterType: "",
              }, {
                  name: "Number",
                  visible: true,
                  field: "number",
                  filterType: "textinput",
              }, {
                  name: "Customer number",
                  visible: true,
                  field: "customerNumber",
                  filterType: "textinput",
                  sortby: {
                      reverse: false,
                      primer: (a) =>  a.toUpperCase(),
                  }
              }, {
                  name: "Customer name",
                  visible: true,
                  field: "customerName",
                  filterType: "textinput",
                  sortby: {
                      reverse: false,
                      primer: (a) =>  a.toUpperCase(),
                  }
              }, {
                  name: "Status",
                  visible: true,
                  field: "status",
                  filterType: "dropdown",
                  dropDownData: [
                    {name: "quotation", text: "Quotation"},
                    {name: "waiting for confirmation", text: "Waiting for confirmation"},
                    {name: "confirmed", text: "Confirmed"},
                    {name: "waiting for production", text: "Waiting for Production"},
                    {name: "in production", text: "In Production"},
                    {name: "ready for shipment", text: "Ready for Shipment"},
                    {name: "partially invoiced", text: "Partially Invoiced"},
                    {name: "partially shipped", text: "Partially Shipped"},
                    {name: "invoiced", text: "Invoiced"},
                    {name: "shipped", text: "Shipped"},
                    {name: "delivered", text: "Delivered"},
                    {name: "archived", text: "Archived"},
                    {name: "cancelled", text: "Cancelled"}
                  ],
                  sortby: {
                      reverse: false,
                      primer: (a) =>  a.toUpperCase(),
                  }
              }, {
                  name: "Manager",
                  visible: true,
                  field: "managers",
                  filterType: "dropdown",
                  dropDownData: [
                    {name: "amar singh", text: "Amar Singh"},
                    {name: "aishwariya mer", text: "Aishwariya Mer"},
                    {name: "bharat shukla", text: "Bharat Shukla"},
                    {name: "mansi mehta", text: "Mansi Mehta"}
                  ],
                  sortby: {
                      reverse: false,
                      primer: (a) =>  a.toUpperCase(),
                  }
                },{
                  name: <Fi.FiChevronsDown />,
                  visible: true,
                  field: "",
                  filterType: "clearfilter",
                }
            ],
            responseData: [],
        }
    }

    componentDidMount(){
      this.getCustomerOrderList()
    }

    getCustomerOrderList = () => {
        this.setState({ isLoading: true, error: false })

        let token = JSON.parse(localStorage.getItem('userdata'))
        token = token ? token.login_token : 'abcd1234'
        let data = new Promise((resolve, reject) => {
            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData()
            formdata.append("start",1)
            formdata.append("length","")

            fetch(`${APIManager.WEBSERVICES_URL}crm/customer/customerOrder/getCustomerOrderQuotation`,{
                method: 'POST',
                headers: myHeaders,
                body: formdata
            })
            .then(response => response.json())
            .then(response => {
                if(response.status === 'success'){
                  resolve(response)
                }else{
                  reject(response)
                }
            })
        }) 
        this.setState({
          responseData: data
        })
    }

    createFilters = (data, i) => {
      let obj;
      if(data.filterType === "textinput"){
        obj = <td key={'filterField'+i}><input type="text" /></td>
      }else if(data.filterType === "dropdown"){
        let html = data.dropDownData.map((d, j)=>(
          <option value={d.name}>{d.text}</option>
        ))
        obj = <td key={'filterField'+i}><select>{html}</select></td>
      }else if(data.filterType === "clearfilter"){
        obj = <td key={'filterField'+i}><Fi.FiX /></td>
      }else{
        obj = <td key={'filterField'+i}></td>
      }
      return obj;
    }

    render() {
        return (
          <>
            <table>
              <thead>
                <tr>
                  {
                    this.state.responseColumn.map((data, i)=>(
                      <th>{data.name}</th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    this.state.responseColumn.map((data, i)=>
                      this.createFilters(data, i) 
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {
                  this.state.responseColumn.map((data, i)=>{
                    <tr>
                        
                    </tr>
                  })
                }
              </tbody>
              
            </table>
          </>
        );
    }
}


export default FilterTable;
