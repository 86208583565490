import React, { Component } from 'react';
import * as Bi from 'react-icons/bi'


class Tickets extends Component {

    componentDidMount () {
        // console.log("box ticket ",this.props.box.key);
    }
    render() {
        let { id, header, value, value2, stripe, totalPer,draggable,box } = this.props;
        return (
            <div
                draggable={draggable}
                onDragStart={this.props.onDragStart({ key: box.key })}
                onDragOver={this.props.onDragOver({ key: box.key })}
                onDrop={this.props.onDrop({ key: box.key })}
            >
                <div className="dashboardTickets" key={id}>
                    <b className={`DticketImage ${value === '' ? 'nodata' : ''}`}>
                        {
                            value === '' ? 'loading...' : Number(value)
                        }
                        {
                            totalPer && <span className='dash_per_span'>({totalPer})</span>
                        }
                        
                    </b>
                    <b className='smallData'>
                        {
                            value2 !== '' ? (value2).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",")+' ' : ''
                        }
                        {
                            value2 !== '' ? <Bi.BiRupee /> : ''
                        }
                    </b>
                    <span className={`dticketText ${stripe}`}>{header}</span>

                </div>
                </div>
        );
    }
}

export default Tickets;