import React, { Component } from 'react';
import * as Bi from 'react-icons/bi'
import { validateNumber } from './../../../../constants/Validate'

class ProductGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            onCallBack: this.props.callBack,
            responseData: this.props.data,
            counter: this.props.number,

            currencyData: this.props.currencyData,

            data: this.props.data,
            product_group: this.props.data.groupName,
            product_id: this.props.data.ItemId,
            product_name: this.props.itemCode+' '+this.props.data.itemName,
            product_ordered_qty: this.props.data.orderedQuantity,
            price_per_qty: this.props.data.pricePerUoM,
            pricePerUoMInOtherCurrency: this.props.data.pricePerUoMInOtherCurrency,
            discount: this.props.data.discount,
            subTotal: this.props.data.subTotal,
            total: this.props.data.total,
            remainingQuantity: parseFloat(this.props.data.remainingQuantity).toFixed(2),
            uom: this.props.data.uom,
            vendorId: this.props.vendorId,
            freeText: this.props.freeText.replace(/<br\s*[\/]?>/gi, "\n"),
            exchangeRate: this.props.data.conversionRate,
            applicableTax: this.props.data.tax,
            applicableTaxType: this.props.applicableTaxType,
            applicableTaxTypeWithAllData: this.props.applicableTaxTypeWithAllData,

            deliveredQuantity: this.props.data.deliveredQuantity,
            rejectedQuantity: this.props.data.rejectedQuantity,
            totalInOtherCurrency: 0,


            productObj : {
                "productGroupId": "",
                "groupName": "",
                "ItemId": "",
                "itemNo": "",
                "itemName": "",
                "pricePerUoM": "",
                "orderedQuantity": "",
                "remainingQuantity": "",
                "deliveredQuantity": "",
                "discount": "",
                "subTotal": "",
                "total": "",
                "uom": "",
                "tax": "",
                "freeText": "",
                "pricePerUoMInOtherCurrency": "",
                "rawTotalInOtherCurrency": "",
                "exchangeRate": "",
                "subTotalInOtherCurrency": "",
                "rejectedQuantity": ""
            },

            taxCount: 0,
            itemTax: 0
        }
    }

    componentDidMount(){
        if(this.state.currencyData.conversionRate){
            this.getCountWithoutTax()
        }else{
            this.setTaxData()
        }
    }

    setTaxData = () => {
        this.setState({ isLoading: true, error: false })

        let subtotal = this.state.subTotal
        if(this.state.applicableTaxType.length > 0){
            let tax = this.state.applicableTax ? this.state.applicableTax : 0
            let taxCount = parseFloat(subtotal) * parseFloat(tax) / 100;
            let itemTax = taxCount / this.state.applicableTaxType.length

            this.setState({
                taxCount: taxCount,
                itemTax: itemTax,
                applicableTax: tax,
                isLoading: false,
            },()=>{
                this.countSubTotal()
            })
        }else{
            this.setState({
                taxCount: 0,
                itemTax: 0,
                applicableTax: 0,
                isLoading: false,
            },()=>{
                this.countSubTotal()
            })
        }
    }


    countSubTotal = () => {
        if(this.state.currencyData.conversionRate){
            this.getCountWithoutTax()
        }else{
            this.getCountWithTax()
        }
    }


    getCountWithoutTax = () => {
        let { rejectedQuantity, price_per_qty, pricePerUoMInOtherCurrency, discount, } = this.state;
        if(rejectedQuantity !== "" && pricePerUoMInOtherCurrency !== ""){
            if(validateNumber(rejectedQuantity) && validateNumber(pricePerUoMInOtherCurrency)){
                let totalInInr = parseFloat(rejectedQuantity*price_per_qty)
                let totalInOther = parseFloat(rejectedQuantity*pricePerUoMInOtherCurrency)
                if(discount !== "" && validateNumber(discount)){
                    let withdiscount = totalInInr - ( totalInInr*discount/100 );
                    let withdiscountInOther = totalInOther - ( totalInOther*discount/100 );
                    this.setState({
                        subTotal: withdiscount.toFixed(2),
                        total: withdiscount.toFixed(2),
                        totalInOtherCurrency: withdiscountInOther.toFixed(2)
                    },()=>{
                        this.generateNewObject()
                    })
                }else{
                    this.setState({
                        subTotal: totalInInr.toFixed(2),
                        total: totalInInr.toFixed(2),
                        totalInOtherCurrency: totalInOther.toFixed(2)
                    },()=>{
                        this.generateNewObject()
                    })
                }
            }else{
                this.setState({
                    subTotal: 0,
                    total: 0,
                    itemTax: 0,
                    totalInOtherCurrency: 0
                })
            }
        }else{
            this.setState({
                subTotal: 0,
                total: 0,
                totalInOtherCurrency: 0
            },()=>{
                this.generateNewObject()
            })
        }
    }

    

    getCountWithTax = () => {
        let { rejectedQuantity, price_per_qty, discount, applicableTax, applicableTaxType, applicableTaxTypeWithAllData  } = this.state;
        if(rejectedQuantity !== "" && price_per_qty !== ""){
            if(validateNumber(rejectedQuantity) && validateNumber(price_per_qty)){
                let total = parseFloat(rejectedQuantity*price_per_qty)
                if(applicableTaxType.length > 0){
                    let rawTotalWithoutDisc = parseFloat(total + (total*applicableTax/100))
                    let taxAmountWithoutDisc = parseFloat(total*applicableTax/100)
                    taxAmountWithoutDisc = taxAmountWithoutDisc/applicableTaxType.length

                    if(discount !== "" && validateNumber(discount)){
                        let withdiscount = total - ( total*discount/100 );
                        let rawtotal = parseFloat(withdiscount + ( withdiscount*applicableTax/100 ))
                        let itemTaxWithDiscount = parseFloat(withdiscount*applicableTax/100)
                        itemTaxWithDiscount = itemTaxWithDiscount/applicableTaxType.length

                        let finalRawTotal = applicableTaxTypeWithAllData.includes("exemptedtax") ? withdiscount.toFixed(2) : rawtotal.toFixed(2)
                        this.setState({
                            subTotal: withdiscount.toFixed(2),
                            total: finalRawTotal,
                            itemTax: itemTaxWithDiscount.toFixed(2)
                        },()=>{
                            this.generateNewObject()
                        })
                    }else{
                        let finalRawTotal = applicableTaxTypeWithAllData.includes("exemptedtax") ? total.toFixed(2) : rawTotalWithoutDisc.toFixed(2)
                        
                        this.setState({
                            subTotal: total.toFixed(2),
                            total: finalRawTotal,
                            itemTax: taxAmountWithoutDisc.toFixed(2)
                        },()=>{
                            this.generateNewObject()
                        })
                    }
                }else{
                    let rawTotalWithoutDisc = parseFloat(total)
                    if(discount !== "" && validateNumber(discount)){
                        let withdiscount = parseFloat(total - ( total*discount/100 ))
                        let rawtotal = parseFloat(total - ( total*discount/100 ))
                        this.setState({
                            subTotal: withdiscount.toFixed(2),
                            total: rawtotal.toFixed(2),
                            itemTax: 0 
                        },()=>{
                            this.generateNewObject()
                        })
                    }else{
                        this.setState({
                            subTotal: total.toFixed(2),
                            total: rawTotalWithoutDisc.toFixed(2),
                            itemTax: 0 
                        },() => {
                            this.generateNewObject()
                        })
                    }
                }
            }else{
                this.setState({
                    subTotal: 0,
                    total: 0,
                    itemTax: 0,
                })
            }
        }else{
            this.setState({
                subTotal: 0,
                total: 0,
                itemTax: 0,
            },()=>{
                this.generateNewObject()
            })
        }
    }

    generateNewObject = () => {
        let productObj = this.state.productObj
        
        productObj.productGroupId = this.state.data.productGroupId;
        productObj.groupName =  this.state.data.groupName;
        productObj.ItemId =  this.state.data.ItemId;
        productObj.itemNo =  this.state.data.itemNo;
        productObj.itemName =  this.state.data.itemName;
        productObj.pricePerUoM =  this.state.data.pricePerUoM;
        productObj.deliveredQuantity =  this.state.deliveredQuantity;
        productObj.discount =  this.state.data.discount;
        productObj.subTotal =  this.state.subTotal;
        productObj.total =  this.state.total;
        productObj.uom = this.state.data.uom;
        productObj.tax = this.state.applicableTax;
        productObj.freeText = this.state.freeText.replace(/(?:\r\n|\r|\n)/g, '<br>');
        productObj.rejectedQuantity = this.state.rejectedQuantity;

        productObj.pricePerUoMInOtherCurrency = this.state.pricePerUoMInOtherCurrency;
        productObj.subTotalInOtherCurrency = this.state.totalInOtherCurrency;
        productObj.rawTotalInOtherCurrency = this.state.totalInOtherCurrency;
        productObj.exchangeRate = this.state.exchangeRate;

        this.setState({
            productObj
        },()=>{
            this.state.onCallBack(this.state.productObj, this.state.counter)
        })
    }

    checkQuantity = (val) => {
        let value = val >= 0 ? val : 0
        let reqQuatity = Math.round(parseFloat(value))
        let delQuantity = this.state.deliveredQuantity
        if(value === "" || reqQuatity <= delQuantity){
            this.setState({
                rejectedQuantity: value
            },()=>{
                this.countSubTotal()
            })
        }
    }

    render() {
        return (
            <tr>
                <td>{this.state.counter + 1}</td>
                {/* Product Group */}
                <td>{this.state.product_group}</td>

                {/* Product */}
                <td style={{ width: 180 }}>
                    <div>{this.state.product_name}</div>
                    <textarea 
                        disabled
                        className="text-area" 
                        value={this.state.freeText} 
                        onChange={(e)=>this.setState({
                            freeText: e.target.value
                        },()=>{ this.generateNewObject() })}
                    ></textarea>
                </td>

                {/* Ordered Quantity */}
                <td><b>{this.state.deliveredQuantity} {this.state.uom}</b></td>
                
                {/* Price per UoM */}
                <td>
                    <b>
                        {this.state.currencyData.conversionRate ? this.state.currencyData.currencySymbol : <Bi.BiRupee />} 
                        {this.state.currencyData.conversionRate ? this.state.pricePerUoMInOtherCurrency : this.state.price_per_qty}
                    </b>
                </td>

                {/* Discount */}
                <td><b>{this.state.discount} %</b></td>

                {/* Sub Total */}
                {
                    this.state.currencyData.conversionRate === undefined &&
                    <td>{this.state.currencyData.conversionRate ? this.state.currencyData.currencySymbol : <Bi.BiRupee />} {this.state.subTotal}</td>
                }

                {/* Tax Applicable */}
                {
                    this.state.currencyData.conversionRate === undefined &&
                    this.state.applicableTaxType.map((data, i) => (
                        <td key={data+i}>{this.state.currencyData.conversionRate ? this.state.currencyData.currencySymbol : <Bi.BiRupee />} {parseFloat(this.state.itemTax).toFixed(2)} </td>
                    ))
                }
                
                {/* Total */}
                <td>
                    {this.state.currencyData.conversionRate ? this.state.currencyData.currencySymbol : <Bi.BiRupee />} 
                    {this.state.currencyData.conversionRate ? this.state.totalInOtherCurrency : this.state.total}
                </td>


                {/* Credit Quantity */}
                <td style={{ width: 150 }}>
                    <input 
                        disabled
                        type='text' 
                        className='input-box' 
                        style={{ width: "50%"}}
                        value={this.state.rejectedQuantity}
                        onChange={(e)=>this.checkQuantity(e.target.value)} 

                    /> {this.state.uom}
                </td>
            </tr>     
        );
    }
}

export default ProductGroup;