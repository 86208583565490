import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Logo } from './../../assets/Images'
import Loading from './../../components/Loading'
import * as APIManager from './../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import { 
    validateEmail,
    validatePassword,
    validateBlank
} from './../../constants/Validate'

class Register extends Component{

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",
            company: "",
            email: "",
            password: "",
            distributor: "",
            showPassword: false,
            isCaptchaApproved: null
        }
        this.handleEmail = this.handleEmail.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleCompany = this.handleCompany.bind(this)
        this.handleDistributor = this.handleDistributor.bind(this)
    }

    componentDidMount(){
        
    }

    handleEmail(e) {
        this.setState({ email: e.target.value})
    }
    handlePassword(e){
        this.setState({ password: e.target.value })
    }
    handleCompany(e){
        this.setState({ company: e.target.value })
    }
    handleDistributor(e){
        this.setState({ distributor: e.target.value })
    }


    isValidate() {
        const {email, password, company, isCaptchaApproved} = this.state;
        if(!validateBlank(company)){
            this.setState({
                error: true,
                errormessage: "Please enter company name"
            })
            return false;
        }else if(!validateBlank(email)){
            this.setState({
                error: true,
                errormessage: "Please enter email address"
            })
            return false;
        }else if(!validateEmail(email)){
            this.setState({
                error: true,
                errormessage: "Please enter valid email address"
            })
            return false;
        }else if(!validateBlank(password)){
            this.setState({
                error: true,
                errormessage: "Please enter password"
            })
            return false;
        }else if(!validatePassword(password)){
            this.setState({
                error: true,
                errormessage: "Password must have 6 characters & Upper, Lower and special characters"
            })
            return false;
        }else if(isCaptchaApproved === null){
            this.setState({
                error: true,
                errormessage: "Please check 'I'm not a robot' checkbox"
            })
            return false;
        }else{
            return true;
        }
    }

    handleRegister() {
        let {email, password, company, distributor} = this.state;
        if(this.isValidate()){
            this.setState({ isLoading: true, error: false })
            APIManager.callRegister(email, password, company, distributor)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    success: true,
                    successmessage: "Thank you for registering with us, once admin approve your request you’ll be able to access the software."
                })
                //toast("Thank you for registering with us, once admin approve your request you’ll be able to access the software.")
                //this.goBack()
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    onChangeCaptcha = (val) => {
        this.setState({
            isCaptchaApproved: val
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="form-bg-primary authBg page">
                <div className="loging-list">
                    <div className="container">
                        <div className="loging-menu-card">
                            <div className="logo">
                                <img src={Logo} alt="logo" />
                            </div>
                            {
                                this.state.error &&
                                <div className="errornotification">
                                    <div className="notiflink registernotiflink">{this.state.errormessage}</div>
                                </div>
                            }
                            {
                                this.state.success &&
                                <div className="successnotification">
                                    <div className="notiflink registernotiflink">{this.state.successmessage}</div>
                                </div>
                            }
                            <form className="form">
                                <div className="form-group mt-1">
                                    <label htmlFor="Username" className='mb-1'>Comany Name</label>
                                    <input type="name" className="form-control" id="Username" aria-describedby="Username" onChange={this.handleCompany} />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="Email" className='mb-1'>Email</label>
                                    <input type="email" className="form-control" id="Email" aria-describedby="Email" onChange={this.handleEmail} />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="password" className='mb-1'>Password</label>
                                    <div className='position-relative'>
                                    <input type={this.state.showPassword ? "text" : "password"} className="form-control" id="password" onChange={this.handlePassword} />
                                    {
                                        this.state.showPassword ?
                                        <div className='passwordEye'>
                                            <Ai.AiFillEye size={20} onClick={()=>this.setState({ showPassword: !this.state.showPassword })} />
                                        </div>
                                        :
                                        <div className='passwordEye'>
                                            <Ai.AiFillEyeInvisible size={20} onClick={()=>this.setState({ showPassword: !this.state.showPassword })} />
                                        </div>
                                    }
                                    </div>
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="referralCode" className='mb-1'>Reference Code</label>
                                    <input type="text" className="form-control" id="referralCode" onChange={this.handleDistributor} />
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-xs-12">
                                    <div className="btn secure-btn">
                                        <ReCAPTCHA
                                            sitekey={APIManager.ReCAPTCHAKey}
                                            onChange={(val)=>this.onChangeCaptcha(val)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <button type="submit" className="btn sign-btn w-100 onlysignup__btn__css" onClick={this.handleRegister.bind(this)}>Submit</button>
                                </div>
                                <div className="col-md-3 col-sm-3 col-xs-12">
                                    <div onClick={()=>this.goBack()} className="gobackbtn pointer">Go back</div>
                                </div>
                                <div className="col-md-9 col-sm-9 col-xs-12">
                                    <div className="signupacceptterms">By registering you accept the terms</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.isLoading && <Loading />}
            </div>
        )
    }
}

export default Register;
