
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import { convertDateStringMMDDtoDDMM } from "../../../constants/DateFormatter";

export const exportInvoicePDF = (responseData) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
   
    const title = "PO-Invoices";

    const headers = [[
        "Number",
        "Purchase Order",
        "Vendor",
        "Created",
        "Invoice No",
        "Invoice Date",
        "Due Date",
        "Total"
    ]];

    const data = responseData.map((elt, i)=> [
        elt.number, 
        elt.purchaseOrderNumber, 
        elt.tempVendorName, 
        convertDateStringMMDDtoDDMM(elt.created), 
        elt.invoiceId,
        convertDateStringMMDDtoDDMM(elt.invoiceDate),
        convertDateStringMMDDtoDDMM(elt.dueDate),
        (elt.grandTotal).toFixed(2)
    ]);

    let content = {
    startY: 50,
    head: headers,
    body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("po-inovice.pdf")
}

export const exportInvoiceCSV = (responseData) => {
    const title = "PO-Invoices";
    const headers = [
        "Number",
        "Purchase Order",
        "Vendor",
        "Created",
        "Invoice No",
        "Invoice Date",
        "Due Date",
        "Total"
    ];

    const data = responseData.map((elt, i)=> [
        elt.number, 
        elt.purchaseOrderNumber, 
        elt.tempVendorName, 
        convertDateStringMMDDtoDDMM(elt.created), 
        elt.invoiceId,
        convertDateStringMMDDtoDDMM(elt.invoiceDate),
        convertDateStringMMDDtoDDMM(elt.dueDate),
        (elt.grandTotal).toFixed(2)
    ]);


    const options = { 
        filename: 'PO-Invoices',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: headers,
    };
    
    const csvExporter = new ExportToCsv(options);
    
    csvExporter.generateCsv(data);
}


