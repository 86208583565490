import React from 'react';
import { Header, ProcurementHeader } from './../../../components'

import * as APIManager from './../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';

import {
    AiOutlineExclamation
} from "react-icons/ai";

import ContactComponent from './../../../components/AutoGenerateComponent/addContactInformation'

export default class AddVendor extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countContactFieldNumber: [0],
            contactFieldCounter: 0,
            contactExtraFields: [],
            contactInformation: [],
            generateNewContact: true,

            name: "",
            PANNo: "",
            gstNumber: "",
            paymentperiod: "0",
            leadtime: "0",
            deliveryTerms: "",
            paymentTerms: "",
            taxApplicable: [],
            currencyData: [],
            currency: "",

            paymentValue: "",
            paymentDaysFrom: "",
            paymentStatus: "",
            paymentTerm: {
                "paymentValue": "",
                "daysFrom": "",
                "paymentStatus": "",
            },

        }
        this.contactInfoFieldRemove = this.contactInfoFieldRemove.bind(this)
        this.contactInfoInputBlur = this.contactInfoInputBlur.bind(this)
    }
    componentDidMount(){
        this.setFirstContactField();
        this.getAllCurrency()
    }

    getAllCurrency = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCurrency()
        .then(response => {
            this.setState({
                isLoading: false,
                currencyData: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }


    updateCurrencyData = (value) => {
        this.setState({
            taxApplicable: [],
            currency: value
        },()=>{
            this.sgstcheck.checked = false
            this.cgstcheck.checked = false
            this.igstcheck.checked = false
        })
    }
    updatePaymentTermObject = (type) => {
        let paymentTerm = this.state.paymentTerm
    
        if(type === 'paymentValue')
            paymentTerm.paymentValue = this.state.paymentValue;

        if(type === 'paymentDaysFrom')
            paymentTerm.daysFrom = this.state.paymentDaysFrom;

        if(type === 'paymentStatus')
            paymentTerm.paymentStatus = this.state.paymentStatus;

        this.setState({
            paymentTerm
        })
    }

    setFirstContactField = () => {
        this.setState({
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent
                    componentNum={0}
                    key={0}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ])
        })
    }

    contactInfoInputVal = (val, number) => {
        let that = this;
        let valLength = val.trim().replaceAll("_","").trim();

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]  
        
        if (typeof CIVal[index] === 'undefined' && this.state.generateNewContact === true && valLength.length > 0) {
            this.setState({
                contactFieldCounter: this.state.contactFieldCounter + 1,
                generateNewContact: false,
            }, () => {
                this.setState({
                    countContactFieldNumber: this.state.countContactFieldNumber.concat(
                        [this.state.contactFieldCounter]
                    ),
                    contactExtraFields: this.state.contactExtraFields.concat(
                        [
                            <ContactComponent 
                                key={
                                    this.state.contactFieldCounter
                                }
                                value={""}
                                type={"Phone"}
                                fun_checkInputBlur={
                                    this.contactInfoInputBlur
                                }
                                componentNum={
                                    this.state.contactFieldCounter
                                }
                                fun_checkContactInfoInput={
                                    that.contactInfoInputVal
                                }
                                fun_removeContactInfoField={
                                    this.contactInfoFieldRemove
                                }
                            />
                        ]
                    ),
                })
            })
        }
    }

    contactInfoInputBlur = (val, type, number) => {
        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]

        if (typeof CIVal[index] !== 'undefined') {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                let contactInformation = [ ...this.state.contactInformation ];
                contactInformation[index] = obj;

                this.setState({
                    contactInformation,
                    generateNewContact: true,
                })
            }
        }else{
            if (val !== "") {
                let obj = {
                    "type": type === "" ? "Phone" : type,
                    "value": val
                }
                this.setState({
                    contactInformation: this.state.contactInformation.concat([ obj ]),
                    generateNewContact: true,
                })
            }
        }
    }

    contactInfoFieldRemove = (number) => {
        let array = [...this.state.countContactFieldNumber];
        let fields = [...this.state.contactExtraFields];
        let data = [...this.state.contactInformation]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            // fields.splice(index, 1)
            this.setState({
                countContactFieldNumber: array,
                contactExtraFields: fields,
                contactInformation: data
            });
        }
    }

    createVendor = () => {
        let {
            PANNo,
            gstNumber,
            name,
            paymentperiod,
            leadtime,
            contactInformation,
            deliveryTerms,
            paymentTerm,
            taxApplicable,
            currency
        } = this.state

        let filteredVal = contactInformation.filter(function (el) {
            return el != null;
        })

        let uniqueTaxArray = taxApplicable.filter(function(item, pos, self) {
            return self.indexOf(item) === pos;
        })

        if(gstNumber === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter GST of Vendor"
            })
        }else if(name === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter vendor details"
            })
        }else if(currency === "" && uniqueTaxArray.length === 0){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select applicable tax or currency"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateVendor(name,filteredVal,leadtime,paymentperiod,deliveryTerms,paymentTerm,uniqueTaxArray,PANNo,gstNumber,currency)
            .then(response => {
                this.setState({
                    isLoading: false
                },()=>{
                    localStorage.setItem("vendorId",response._id)
                    localStorage.setItem("createNewVendor", true)
                    this.props.history.push('/editvendor')
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                })
            })
        }
    }

    getGSTDetails = () => {
        let { gstNumber } = this.state;
        
        if(gstNumber && gstNumber.length >= 15){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(this.state.gstNumber)
            .then(response => {
                this.setState({
                    isLoading: false,
                    error: false,
                    countContactFieldNumber: [0],
                    contactFieldCounter: 0,
                    contactExtraFields: [],
                    contactInformation: [],
                    generateNewContact: true,
                    name: response.data.tradename ? response.data.tradename : response.data.legalname
                },()=>{
                    let address = response.data.address ? response.data.address : ''
                    this.setContactInfo('FreeTextAddress',address)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    success: false,
                    countContactFieldNumber: [0],
                    contactFieldCounter: 0,
                    contactExtraFields: [],
                    contactInformation: [],
                    generateNewContact: true,
                    name: "",
                    errormessage: "Please enter valid GST Number"
                },()=>{
                    this.setFirstContactField();
                })
            });
        }else{
            this.setState({
                isLoading: false,
                error: false,
                success: false,
                countContactFieldNumber: [0],
                contactFieldCounter: 0,
                contactExtraFields: [],
                contactInformation: [],
                generateNewContact: true,
                name: "",
            },()=>{
                this.setFirstContactField();
            })
        }
    }

    setContactInfo = (type, value) => {
        let localFieldNumber = []
        let localExtraField = []

        localFieldNumber.push(0)
        localExtraField.push(
            <ContactComponent 
                key={0}
                componentNum={0}
                value={value}
                type={type}
                fun_checkInputBlur={
                    this.contactInfoInputBlur
                }
                fun_checkContactInfoInput={
                    this.contactInfoInputVal
                }
                fun_removeContactInfoField={
                    this.contactInfoFieldRemove
                }
            />
        )
        
        this.setState({
            countContactFieldNumber: localFieldNumber,
            contactFieldCounter: 1,
            contactExtraFields: localExtraField,
            contactInformation: [{
                'type': type,
                'value': value
            }],
        },()=>{
            this.setNewField(1)
        })
    }

    setNewField = (num) => {
        this.setState({
            countContactFieldNumber: this.state.countContactFieldNumber.concat([ num + 1 ]),
            contactFieldCounter: num + 1,
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent 
                    key={num + 1}
                    componentNum={num + 1}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ]),
            generateNewContact: true,
        })
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }
   
    goBack = () => {
        this.props.history.goBack()
    }

    updateTaxApplicable = (name, check) => { 
        if(check === true && name === "IGST"){
            this.setState({
                taxApplicable: [],
            },()=>{
                this.sgstcheck.checked = false;
                this.cgstcheck.checked = false;
                this.setState({
                    taxApplicable: this.state.taxApplicable.concat([
                        name
                    ]),
                    currency: ""
                })
            })
        } else if(check === true && name !== "IGST") {
            let taxApplicable = [...this.state.taxApplicable]
            let index = taxApplicable.indexOf('IGST')
            if(index > -1){
                taxApplicable.splice(index, 1);
                this.setState({
                    taxApplicable,
                    currency: ""
                },()=>{
                    this.igstcheck.checked = false;
                    this.setState({
                        taxApplicable: this.state.taxApplicable.concat([
                            name
                        ]),
                        currency: ""
                    })
                })
            }else{
                this.setState({
                    taxApplicable: this.state.taxApplicable.concat([
                        name
                    ]),
                    currency: ""
                })
            }
        } else{
            let taxApplicable = [...this.state.taxApplicable]
            let index = taxApplicable.indexOf(name)

            taxApplicable.splice(index, 1);
            this.setState({
                taxApplicable,
                currency: ""
            })
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                {
                    this.state.pageLoading &&
                    <div className="pageLoading"></div>
                }
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isVendors={true} />
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Add a vendor</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createVendor()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>

                        <div className="clearfix"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">GST No.*</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr text-uppercase"
                                        autoComplete="off"
                                        value={this.state.gstNumber}
                                        name="taxnumber" 
                                        onChange={(e)=>this.setState({ gstNumber: e.target.value},()=>{ this.getGSTDetails() })} 
                                    />
                                    <div className='gstdetail_search_icon' onClick={()=>this.getGSTDetails()}><Ai.AiOutlineSearch /></div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Vendor *</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr" autoComplete="off" name="name" value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value })} />
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">PAN No.</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr"
                                        autoComplete="off"
                                        value={this.state.PANNo}
                                        onChange={(e)=>this.setState({ PANNo: e.target.value })}  
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner contactinfo">
                                {/* Contact Information fields */}
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Contact information</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1">
                                            <div className="float-left width40">Type</div>
                                            <div className="float-left width60">value</div>
                                            <div className="clear"></div>
                                        </div>
                                        {
                                            this.state.contactExtraFields
                                        }
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Delivery terms</div>
                                <div className="value">
                                    <select value={this.state.deliveryTerms} onChange={(e)=>this.setState({ deliveryTerms: e.target.value })}>
                                        <option value="">Select</option>
                                        <option value="ex-works">Ex-works</option>
                                        <option value="c and f">C and F</option>
                                        <option value="cif">CIF</option>
                                    </select>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Payment terms</div>
                                <div className="value">
                                    <div>
                                        <div className="float-left width30 createdText border-0">Payment from</div> 
                                        <input 
                                            type="text" 
                                            className="float-left width10" 
                                            value={this.state.paymentDaysFrom}
                                            onChange={(e)=>{
                                                this.setState({ paymentDaysFrom: e.target.value >= 0 ? e.target.value : "" },()=>{
                                                    this.updatePaymentTermObject('paymentDaysFrom')
                                                })
                                            }} 
                                        />
                                        <div className="float-left width20 createdText text-center border-0">days of</div>
                                        <div className="float-left width40">
                                            <select 
                                                value={this.state.paymentStatus}
                                                onChange={(e)=>{
                                                if(e.target.value !== ""){
                                                    this.setState({ paymentStatus: e.target.value},()=>{
                                                        this.updatePaymentTermObject('paymentStatus')
                                                    })
                                                }
                                            }}>
                                                <option value="">Select</option>
                                                <option value="from_invoice">Invoice</option>
                                                <option value="from_confirmation">Confirmation</option>
                                                <option value="from_arrival">Arrival</option>
                                            </select>
                                        </div>

                                        <div className="clear"></div>
                                    </div>

                                </div>
                                <div className="clear"></div>
                            </div>

                            
                            <div className="add_inner">
                                <div className="title">Default lead time</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr" value={this.state.leadtime} onChange={(e)=>this.setState({ leadtime: e.target.value })} autoComplete="off" name="name" style={{width: "50%"}}/> days
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Payment period</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr" value={this.state.paymentperiod} onChange={(e)=>this.setState({ paymentperiod: e.target.value })} autoComplete="off" name="name" style={{width: "50%"}}/> days 
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Currency</div>
                                <div className='value'>
                                    <Select2    
                                        data={
                                            this.state.currencyData.map((data) => (
                                                { id: data._id, text: data.currencyName }
                                            ))
                                        }
                                        value={this.state.currency}
                                        options={{placeholder: 'Select Currency'}}
                                        onSelect={(e) => this.updateCurrencyData(e.target.value) }
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner contactinfo">

                                {/* Tax Applicable fields */}
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Tax applicable</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1 mr-1">
                                            <div className="float-left width40">Type</div>
                                            <div className="float-left width60 text-center">Applicable</div>

                                            <div className="clear"></div>
                                        </div>

                                        

                                        <div className="extrafieldforContact">
                                            <div className="float-left width40 taxApplicableTitle">
                                                CGST
                                            </div>
                                            <div className="float-left width60 taxApplicableInput">
                                                <div className="checkboxInputs"><input type="checkbox"
                                                        style={
                                                            {width: 14}
                                                        }
                                                        ref={ (c) => this.cgstcheck = c }
                                                        onChange={
                                                            (e)=>{
                                                                this.updateTaxApplicable("CGST",e.target.checked)
                                                            }
                                                        }/></div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>

                                        <div className="extrafieldforContact">
                                            <div className="float-left width40 taxApplicableTitle">
                                                SGST
                                            </div>
                                            <div className="float-left width60 taxApplicableInput">
                                                <div className="checkboxInputs"><input type="checkbox"
                                                        style={
                                                            {width: 14}
                                                        }
                                                        ref={ (c) => this.sgstcheck = c }
                                                        onChange={
                                                            (e)=>{
                                                                this.updateTaxApplicable("SGST",e.target.checked)
                                                            }
                                                        }/></div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>

                                        <div className="extrafieldforContact">
                                            <div className="float-left width40 taxApplicableTitle">
                                                IGST
                                            </div>
                                            <div className="float-left width60 taxApplicableInput">
                                                <div className="checkboxInputs"><input type="checkbox"
                                                        style={
                                                            {width: 14}
                                                        }
                                                        ref={ (c) => this.igstcheck = c }
                                                        onChange={
                                                            (e)=>{
                                                                this.updateTaxApplicable("IGST",e.target.checked)
                                                            }
                                                        }/></div>
                                                </div>
                                            <div className="clear"></div>
                                        </div>


                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>

                            </div>
                        </div>
                        <div className="clearfix"></div>

                    </div>
                   
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createVendor()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}