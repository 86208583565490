import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as Fi from 'react-icons/fi'
import * as APIManager from './../../../APIManager/APIManager'

import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { AiOutlineCalendar } from "react-icons/ai"
import TableLoading from '../../../helper/TableLoading';

class RejectedItems extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            number: "",
            name: "",
            bomNumber: "",
            bomName: "",
            workStationGroupNumber: "",
            workStationGroupName: "",
            otherDescription: "",
            minSetUpTime: "",
            maxSetUpTime: "",
            minCycleTime: "",
            maxCycleTime: "",

            count: 0,
            responseData: [],
            recordList: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    getAllRouting = () => {
        this.setState({ isLoading: true, error: false })
        let {
            number,
            name,
            bomNumber,
            bomName,
            workStationGroupNumber,
            workStationGroupName,
            otherDescription,
            minSetUpTime,
            maxSetUpTime,
            minCycleTime,
            maxCycleTime,
            start,
            length
        } = this.state
        APIManager.callGetAllRouting(number, name, bomNumber, bomName, workStationGroupNumber, workStationGroupName, otherDescription, minSetUpTime, maxSetUpTime, minCycleTime, maxCycleTime, start, length)
        .then(response => {
            this.setState({
                isLoading: false,
                responseData: response.data,
                totalRecords: response.totalRecords
            }, () => {
                this.setFilterData()
                this.createListing()
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'name': this.state.name,
            'bomNumber': this.state.bomNumber,
            'bomName': this.state.bomName,
            'workStationGroupNumber': this.state.workStationGroupNumber,
            'workStationGroupName': this.state.workStationGroupName,
            'otherDescription': this.state.otherDescription,
            'minSetUpTime': this.state.minSetUpTime,
            'maxSetUpTime': this.state.maxSetUpTime,
            'minCycleTime': this.state.minCycleTime,
            'maxCycleTime': this.state.maxCycleTime ,
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
        }
        localStorage.setItem("productionPlaningRouting", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlaningRouting"))
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                start: filterData.start,
                length: filterData.length,
                number: filterData.number,
                name: filterData.name,
                bomNumber: filterData.bomNumber,
                bomName: filterData.bomName,
                workStationGroupNumber: filterData.workStationGroupNumber,
                workStationGroupName: filterData.workStationGroupName,
                otherDescription: filterData.otherDescription,
                minSetUpTime: filterData.minSetUpTime,
                maxSetUpTime: filterData.maxSetUpTime,
                minCycleTime: filterData.minCycleTime,
                maxCycleTime: filterData.maxCycleTime ,
            }, () => {

                this.getAllRouting()

            })
        } else {

            

            this.getAllRouting()

        }
    }


    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllRouting()
        });
    }

    createListing = () => {
        let recordData = []
        let records = this.state.responseData
        for (let i = 0; i < records.length; i++) {
            recordData.push(
                <tr onClick={() => this.editRouting(records[i]._id)} key={records[i]._id + i}>
                    <td className="text-uppercase">{records[i].number}</td>
                    <td>{records[i].bomNumber}</td>
                    <td>{records[i].bomName}</td>
                    <td className="text-uppercase">{records[i].workStationGroupNumber}</td>
                    <td>{records[i].workStationGroupName}</td>
                    <td>{records[i].otherDescription}</td>
                    <td>{records[i].setUpTime} min</td>
                    <td>{records[i].cycleTime} min</td>
                    <td></td>
                </tr>
            )
        }
        this.setState({
            recordList: recordData
        })
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlaningRouting")
        this.setState({
            number: "",
            name: "",
            bomNumber: "",
            bomName: "",
            workStationGroupNumber: "",
            workStationGroupName: "",
            otherDescription: "",
            minSetUpTime: "",
            maxSetUpTime: "",
            minCycleTime: "",
            maxCycleTime: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getAllRouting()
        })
    }


    addRouting = () => {
        let slug = "ProductionPlanning_Routings"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createrouting"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editRouting = (id) => {
        this.props.history.push({
            pathname: "/editrouting",
            state: { RoutingId: id, newRoutingEntry: false }
        })
    }




    goBack = () => {
        this.props.history.goBack();
    }

    getIndexNumber = (i, j) => {
        return i + j
    }

    render() {
        return (
            <div className="page absoluteWhite">

                <Header logout={this.props.history} goBack={() => this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isRejectedItems={true} />

                {/* <RejectedItemsEdit/> */}
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.ProductionPlanning.RejectItems}</div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader"><span>Article code </span></th>
                                    <th className="tableHeader" ><span>Part Description </span></th>
                                    <th className="tableHeader"><span>Stock Lot </span></th>
                                    <th className="tableHeader"><span>Storage Location </span></th>
                                    <th className="tableHeader"><span>MO Number</span></th>
                                    <th className="tableHeader"><span>Total Quantity</span></th>
                                    <th className="tableHeader"><span>Rejected Quantity</span></th>
                                    <th className="tableHeader"><span> Date </span></th>
                                </tr>

                                <tr>
                                   
                                    <th className="tableElemContain"><input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.bomNumber} onChange={(e) => this.setState({ bomNumber: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.bomName} onChange={(e) => this.setState({ bomName: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.workStationGroupNumber} onChange={(e) => this.setState({ workStationGroupNumber: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.workStationGroupName} onChange={(e) => this.setState({ workStationGroupName: e.target.value }, () => { this.getAllRouting() })} /></th>

                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minBooked} onChange={(e) => this.setState({ minBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxBooked} onChange={(e) => this.setState({ maxBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minBooked} onChange={(e) => this.setState({ minBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxBooked} onChange={(e) => this.setState({ maxBooked: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.filterDelayData() })} />
                                    </th>

                                    <th className="tableElemContain" style={{ width: 120 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.search_maxCreated}
                                                selected={this.state.search_minCreated}
                                                onSelect={(date) => this.setState({ search_minCreated: date }, () => { this._handleKeyDown() })}
                                                onChange={(date) => this.setState({ search_minCreated: date }, () => { this._handleKeyDown() })}
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative">
                                            <DatePicker
                                                minDate={this.state.search_minCreated}
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.search_maxCreated}
                                                onChange={(date) => this.setState({ search_maxCreated: date }, () => { this._handleKeyDown() })}
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>

                                    {/* <th className="tableElemContain"><input type="text" value={this.state.otherDescription} onChange={(e) => this.setState({ otherDescription: e.target.value }, () => { this.getAllRouting() })} /></th> */}
                                    {/* <th className="tableElemContain"><input type="text" value={this.state.workStationGroupName} onChange={(e) => this.setState({ workStationGroupName: e.target.value }, () => { this.getAllRouting() })} /></th> */}
                                    {/* <th className="tableElemContain"><input type="text" value={this.state.otherDescription} onChange={(e) => this.setState({ otherDescription: e.target.value }, () => { this.getAllRouting() })} /></th> */}
                                   
                                    <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><Fi.FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={9} />
                                :
                                <tbody>
                                    {
                                        this.state.recordList
                                    }
                                </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RejectedItems;