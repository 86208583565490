import React, { Component } from 'react';

class PageNotFound extends Component {

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="authBg page">
                <div className="splash__center animate__animated animate__fadeIn">
                    <div className="page__not__found">
                        <h1 className="text__one">Oops!</h1>
                        <h3 className="text__one">404 - Page Not Found</h3>
                        <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
                        <button className="btn back-btn-404" onClick={()=>this.goBack()}>Go back</button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default PageNotFound;