import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import Loading from './../../../../helper/loading'
import ProductGroup from './ProductGroup';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class CreatePOInvoice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            poGroupList: [],
            poGroupData: [],
            purchaseOrderData: [],
            addressListVendor:[],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            isFromPO: this.props.location.state.isFromPO,
            purchaseOrder: this.props.location.state.isFromPO ? this.props.location.state.poId : "",
            vendorId: "",
            invoiceDate: new Date(),
            invoiceId: "",
            dueDate: "",
            vendorName: "",
            applicableTax: "",
            applicableTaxType: [],
            currencyDetails: [],
            remainingScope: [],

            is_tds_applicable: false,
            is_tds_locked: false,
            tds_percentage: "",
            tds_value: "",
            after_tds_grandtotal: "",
            tds_grandtotal_ininr: "",
            tds_grandtotal_inother: "",
            invoiceAddress:"",

           
        }

       

    }

    componentDidMount() {
        this.getAllPOs()
        if (this.state.isFromPO) {
            this.getSinglePurchaseOrderData()
        }

    }

    getAllPOs = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callPurchaseOrderList("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    isLoading: false,
                    purchaseOrderData: response.data
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }




    getSinglePurchaseOrderData = () => {
        this.setState({ isLoading: true, error: false })

        APIManager.callSinglePurchaseOrder(this.state.purchaseOrder)
            .then(response => {

                if (response.data.totalPurchase && response.companyData.totalPurchase >= 5000000) {
                    this.setState({
                        is_tds_applicable: true,
                        is_tds_locked: true,
                        tds_percentage: 0.1,
                    })
                }


                this.setState({
                    isLoading: false,
                    poGroupList: [],
                    poGroupData: [],
                    totalQty: 0,
                    subTotal: 0,
                    grandTotal: 0,
                    grandTotalInOther: 0,
                    remainingScope: response.data.remainingScope ? response.data.remainingScope : []
                }, () => {
                    this.getSingleVendorDetail(response.data.vendor)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getSingleVendorDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(id)
            .then(response => {
                let number = response.number ? response.number.toUpperCase() : ""
                let name = response.name

                let dt = new Date()
                if (response.paymentTerms) {
                    if (response.paymentTerms.paymentStatus === "from_invoice") {
                        let days = response.paymentTerms.daysFrom !== "" ? response.paymentTerms.daysFrom : 0
                        let newDate = new Date().setDate(new Date().getDate() + parseInt(days))
                        dt = new Date(newDate)
                    }
                }

                this.setState({
                    isLoading: false,
                    vendorId: id,
                    vendorName: number + " " + name,
                    applicableTaxType: response.taxApplicable,
                    currencyDetails: response.conversionDetail ? response.conversionDetail : [],
                    dueDate: dt
                }, () => {
                    this.getSinglePOData()
                    this.getShippngAddressOfVender(id)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getSinglePOData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPOGroupData(this.state.purchaseOrder)
            .then(response => {
                this.setState({
                    isLoading: false,
                }, () => {
                    this.setGroupData(response.data.poGroup)

                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getShippngAddressOfVender = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callVendorShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressListVendor: response,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        } 
    }

    setGroupData = (data) => {
    
        let group = []
        for (let i = 0; i < data.length; i++) {

            group.push(
                <ProductGroup
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    currencyDetails={this.state.currencyDetails}
                    key={"groupData" + i}
                    data={data[i]}
                    uom={data[i].itemUoM}
                    vendorId={this.state.vendorId}
                    callBack={this.setUpdatedGroupData}
                    scopeDetails={this.state.remainingScope[i] ? this.state.remainingScope[i] : null}
                />
            )
        }
        this.setState({
            poGroupList: group,
            poGroupData: data,
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let poGroupData = [...this.state.poGroupData]
        poGroupData[counter] = value;
        this.setState({
            poGroupData
        }, () => {
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.poGroupData
        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for (let i = 0; i < data.length; i++) {
            qty += parseFloat(data[i].orderedQuantity)
            subTotal += parseFloat(data[i].subTotal)
            grandTotal += parseFloat(data[i].total)
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        }, () => {
            let tds = this.state.tds_percentage
            if (this.state.grandTotal >= 5000000 && tds === "") {
                this.setState({
                    is_tds_applicable: true,
                    is_tds_locked: true,
                    tds_percentage: 0.1,
                }, () => {
                    this.changeTCSPercentage(this.state.tds_percentage)
                })
            } else if (this.state.grandTotal >= 5000000 && tds !== "") {
                this.setState({
                    is_tds_locked: true,
                }, () => {
                    this.changeTCSPercentage(this.state.tds_percentage)
                })
            } else {
                this.setState({
                    is_tds_locked: false,
                }, () => {
                    this.changeTCSPercentage(this.state.tds_percentage)
                })
            }
        })
    }

    createInvoice = () => {
        let {
            subTotal,
            grandTotal,
            poGroupData,
            invoiceAddress,
            purchaseOrder,
            vendorId,
            invoiceDate,
            invoiceId,
            dueDate,

            is_tds_applicable,
            tds_percentage,
            tds_value,
            tds_grandtotal_ininr,
            tds_grandtotal_inother

        } = this.state

        if (purchaseOrder === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select purchase order"
            }, () => {
                this.scrollToTop()
            })
        } else if (invoiceId === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter Invoice ID"
            }, () => {
                this.scrollToTop()
            })
        } else if (invoiceDate === "") {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter Invoice date"
            }, () => {
                this.scrollToTop()
            })
        } else if (parseFloat(grandTotal) === 0) {
            this.setState({
                success: false,
                error: true,
                errormessage: "Please update received item details"
            }, () => {
                this.scrollToTop()
            })
        } else {
            this.setState({ isLoading: true, error: false })
            APIManager.callCreatePOInvoice(purchaseOrder, vendorId, invoiceId,invoiceAddress, convertDateToMMDDYYYY(invoiceDate), convertDateToMMDDYYYY(dueDate), JSON.stringify(poGroupData), subTotal, grandTotal, is_tds_applicable, tds_percentage, tds_value, tds_grandtotal_ininr, tds_grandtotal_inother)
                .then(response => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.history.push({
                            pathname: "/editpoinvoice",
                            state: { invoiceId: response.data._id, isNewEntry: true }
                        
                        })
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }

    changeTCSPercentage = (val) => {
        let per = val
        this.setState({
            tds_percentage: (per >= 0 && per <= 100) || per === "" ? per : ''
        }, () => {
            let grandTotal = this.state.currencyDetails.conversionRate ? this.state.grandTotalInOther : this.state.grandTotal
            if (this.state.tds_percentage) {
                let tds_per = this.state.tds_percentage

                let tds_val = (grandTotal * tds_per / 100).toFixed(2)

                this.setState({
                    tds_value: parseFloat(tds_val),
                    after_tds_grandtotal: parseFloat(grandTotal) + parseFloat(tds_val),
                    tds_grandtotal_ininr: parseFloat(this.state.grandTotal) + parseFloat(tds_val),
                    tds_grandtotal_inother: parseFloat(this.state.grandTotalInOther) + parseFloat(tds_val)
                })
            }
        })
    }


    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }   

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isInvoices={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Create PO Invoice</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.createInvoice()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Purchase Order*</div>
                                    <div className="value">
                                        <Select2
                                            data={
                                                this.state.purchaseOrderData.map((data) => (
                                                    { id: data._id, text: data.number.toUpperCase() + ' ' + data.vendorName }
                                                ))
                                            }
                                            value={this.state.purchaseOrder}
                                            options={{ placeholder: 'Select PO' }}
                                            onSelect={(e) => this.setState({ purchaseOrder: e.target.value }, () => { this.getSinglePurchaseOrderData() })}
                                        />
                                        {/* <select onChange={(e)=> this.setState({ purchaseOrder: e.target.value },()=> { this.getSinglePOData(e.target.value) })} value={this.state.purchaseOrder}>
                                            {
                                                this.state.purchaseOrderData.map((data, i) => (
                                                    <option key={"po-opt"+ i} value={data._id}>{data.number}</option>
                                                ))
                                            }
                                        </select> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Vendor</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.vendorName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.addressListVendor.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.invoiceAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ invoiceAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice ID</div>
                                    <div className="value">
                                        <input type="text" value={this.state.invoiceId} onChange={(e) => this.setState({ invoiceId: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.invoiceDate
                                            }
                                            onChange={
                                                date => this.setState({ invoiceDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Due Date</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={
                                                this.state.dueDate
                                            }
                                            onChange={
                                                date => this.setState({ dueDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="main-container mt-5 invoice_product_group">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Ordered Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <th className='pg-tab-head'>Subtotal</th>
                                        }

                                        {
                                            this.state.applicableTaxType.map((data, i) => (
                                                <th className='pg-tab-head' key={'appTax' + i}>{data}</th>
                                            ))
                                        }
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <th className='pg-tab-head'>Eligible Tax*</th>
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Remaining Qty</th>
                                        <th className='pg-tab-head'>Received Qty</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.poGroupList}

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{this.state.totalQty}</b></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <td><b>{this.state.subTotal} <Bi.BiRupee /></b></td>
                                        }
                                        {
                                            this.state.applicableTaxType.map((data, i) => (
                                                <td key={'appTaxBlankField' + i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyDetails.currencyName === "" &&
                                            <td></td>
                                        }
                                        {
                                            this.state.currencyDetails.currencyName !== "" ?
                                                <td><b>{this.state.currencyDetails.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                                :
                                                <td><b>{this.state.grandTotal} </b></td>

                                        }

                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TDS Applicable</div>
                                    <div className="value">
                                        <input
                                            type="checkbox"
                                            disabled={this.state.is_tds_locked}
                                            checked={this.state.is_tds_applicable}
                                            onChange={() => this.setState({
                                                is_tds_applicable: !this.state.is_tds_applicable,
                                                tds_percentage: "",
                                                tds_value: "",
                                                after_tds_grandtotal: ""
                                            })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">TDS Percentage</div>
                                    <div className="value">
                                        <input
                                            type="textbox"
                                            value={this.state.tds_percentage}
                                            disabled={!this.state.is_tds_applicable}
                                            onChange={(e) => this.changeTCSPercentage(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TDS Value</div>
                                    <div className="value">
                                        <input
                                            type="textbox"
                                            value={this.state.tds_value}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Grand Total</div>
                                    <div className="value">
                                        <input
                                            type="textbox"
                                            value={this.state.after_tds_grandtotal}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>



                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.createInvoice()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }

            </div>
        );
    }
}

export default CreatePOInvoice;