import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Loading from '../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditPurchaseOrderNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            id: "",
            note_id: "",
            note: "",
            isImp: false,
            purchase_order_number: "",

            fieldNotEditable: true,
        }
      
    }

    componentDidMount(){
        let id = localStorage.getItem('purchase_order_id')
        let number = localStorage.getItem('purchase_order_number')
        let isImp = localStorage.getItem("PONoteIsImp")
        let note_id = localStorage.getItem("PONoteId")
        let note = localStorage.getItem("PONote")
        this.setState({
            id: id,
            purchase_order_number: number,
            note_id: note_id,
            note: note,
            isImp: isImp === 'true' ? true : false,
        })
    }

    updateNote = () => {

        if(this.state.fieldNotEditable !== true){
        let { 
            note,
            id,
            note_id,
            isImp 
        } = this.state


        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdatePurchaseOrderNote( id, note_id, note, isImp )
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    success: true,
                    error: false,
                    fieldNotEditable: true,
                    successmessage: response.message
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }
    }else{
        this.setState({
            fieldNotEditable: false
        })
    }
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            let {
                id,
                note_id
            } = this.state;
            APIManager.callDeletePurchaseOrderNote(id,note_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewPONote") !== null){
            localStorage.removeItem("createNewPONote")
            localStorage.removeItem("PONote")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCustomerOrder={true} />
                <ProcurementHeader isPurchaseOrders={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Notes about purchase order <span className="text-uppercase">{this.state.purchase_order_number}</span></div>
                        </div>
                    </div>
                </div>
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNote()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Important</div>
                                <div className="value">
                                    <input 
                                        type="checkbox" 
                                        className="__input_checkbox"
                                        checked={this.state.isImp}
                                        onChange={()=>this.setState({ isImp: !this.state.isImp })}
                                    ></input>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value})} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNote()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>                        
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            {
                this.state.isLoading &&
                <Loading />
            }
            </div>
        )
    }
}