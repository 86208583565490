import React, { Component } from 'react';

import { Header, CRMHeader } from '../../../components'
import Strings from '../../../constants/Strings'
//import './../../../../style.css'

import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';

import {
    ImArrowDown2,
} from "react-icons/im";

import './../../../lib/oem-table/index.css'
import Pagination from "react-js-pagination";
import Loading from '../../../helper/loading'
import Popup from 'reactjs-popup';

import * as APIManager from '../../../APIManager/APIManager';
import * as Fi from "react-icons/fi";
import * as Ri from "react-icons/ri"
import * as Ai from "react-icons/ai";


import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css'

import ItemTable from './itemTable';

import {
    AiOutlineCalendar,
} from "react-icons/ai"
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';


class CustomerOrders extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            customerOrderList: [],
            managersList: [],
            customerItemList:[],

            number: "",
            customerNumber: "",
            customerName: "",
            minCreated: "",
            maxCreated: "",
            minModified: "",
            maxModified: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            minPODate: "",
            maxPODate: "",
            minPODeliveryDate: "",
            maxPODeliveryDate: "",
            minActualDelivery: "",
            maxActualDelivery: "",
            status: "",
            productStatus: "",
            invoiceStatus: "",
            paymentStatus: "",
            manager: "",
            email: "",
            phone: "",
            address: "",

            tableList: "CustomerOrders",

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
            fieldName: '',
            fieldType: '',
            sortVal: false,

            chooseColumnList: [{
                id: "0",
                name: "Number",
                visible: true,
                field: "number",
                type: 'notDate',
                fixed: false,

            }, {
                id: "1",
                name: "Customer number",
                visible: true,
                field: "customerNumber",
                type: 'notDate',
                fixed: false,
            }, {
                id: "2",
                name: "Customer name",
                visible: true,
                field: "customerName",
                type: 'notDate',
                fixed: false,
            }, {
                id: "3",
                name: "E-mail",
                visible: false,
                field: "Email",
                type: 'notDate',
                fixed: false,
            }, {
                id: "4",
                name: "Phone",
                visible: false,
                field: "Phone",
                type: 'notDate',
                fixed: false,
            }, {
                id: "5",
                name: "Address",
                visible: true,
                field: "Address",
                type: 'notDate',
                fixed: false,
            }, {
                id: "6",
                name: "Status",
                visible: true,
                field: "status",
                type: 'notDate',
                fixed: false,
            }, {
                id: "7",
                name: "PO Date",
                visible: true,
                field: "poDate",
                type: 'Date',
                fixed: false,
            }, {
                id: "8",
                name: "Delivery as per PO",
                visible: true,
                field: "poDeliveryDate",
                type: 'Date',
                fixed: false,
            }, {
                id: "9",
                name: "Estimated Delivery",
                visible: true,
                field: "deliveryDate",
                type: 'Date',
                fixed: false,
            }, {
                id: "10",
                name: "Actual Delivery",
                visible: true,
                field: "actualDeliveryDate",
                type: 'Date',
                fixed: false,
            }, {
                id: "11",
                name: "Modified",
                visible: false,
                field: "modifiedDate",
                type: 'Date',
                fixed: false,
            }, {
                id: "12",
                name: "Created",
                visible: false,
                field: "created",
                type: 'Date',
                fixed: false,
            }],
        }

        this.timer = null;
        this.onSelectStatus = this.onSelectStatus.bind(this)
        this.getSetItemData = this.getSetItemData.bind(this)
    }

    componentDidMount() {
        this.getFilterData()
    }


    //******************* ascending descending order function ********************************


    handleSort = (data, onload) => {

        let fieldName = data.field
        let fieldType = data.type
        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        const sortedOrders = this.state.customerOrderList.sort((a, b) => {

            if (fieldType === "Date") {
                const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return sortVal ? 1 : -1;
                } else if (!dateB) {
                    return sortVal ? -1 : 1;
                }
                if (dateA.getFullYear() !== dateB.getFullYear()) {
                    return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                }
                if (dateA.getMonth() !== dateB.getMonth()) {
                    return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                }
                if (dateA.getDate() !== dateB.getDate()) {
                    return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                }
                return 0;
            }
            else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                // console.log('NotDate success');
                const numA = parseFloat(a[fieldName]);
                const numB = parseFloat(b[fieldName]);
                if (sortVal) {
                    return numA > numB ? 1 : -1;
                } else {
                    return numA < numB ? 1 : -1;
                }
            } else {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                    return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                    return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
            }
        })

        this.setState({
            customerOrderList: sortedOrders,
            fieldName,
            fieldType,
            sortVal
        }, () => {
            this.saveSortingDatainLocal()
        });
    };

    //******************* ascending descending order function ********************************



    getManagers = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
            .then(response => {
                this.setState({
                    isLoading: false,
                    managersList: response.data
                }, () => {
                    this.getCustomerOrders()
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                }, () => {
                    this.getCustomerOrders()
                })
            });
    }

    saveSortingDatainLocal = () => {

        let filterDataArray = {
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
            'number': this.state.number,
            'customerOrderNumber': this.state.customerOrderNumber,
            'customerNumber': this.state.customerNumber,
            'customerName': this.state.customerName,
            'minCreated': this.state.minCreated !== null ? this.state.minCreated : "",
            'maxCreated': this.state.maxCreated !== null ? this.state.maxCreated : "",
            'minDeliveryDate': this.state.minDeliveryDate !== null ? this.state.minDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",
            'minModified': this.state.minModified !== null ? this.state.minModified : "",
            'maxModified': this.state.maxModified !== null ? this.state.maxModified : "",
            'minPODate': this.state.minPODate !== null ? this.state.minPODate : "",
            'maxPODate': this.state.maxPODate !== null ? this.state.maxPODate : "",
            'minPODeliveryDate': this.state.minPODeliveryDate !== null ? this.state.minPODeliveryDate : "",
            'maxPODeliveryDate': this.state.maxPODeliveryDate !== null ? this.state.maxPODeliveryDate : "",
            'minActualDelivery': this.state.minActualDelivery !== null ? this.state.minActualDelivery : "",
            'maxActualDelivery': this.state.maxActualDelivery !== null ? this.state.maxActualDelivery : "",
            'status': this.state.status,
            'productStatus': this.state.productStatus,
            'invoiceStatus': this.state.invoiceStatus,
            'paymentStatus': this.state.paymentStatus,
            'manager': this.state.manager,
            'email': this.state.email,
            'phone': this.state.phone,
            'address': this.state.address,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }
        localStorage.setItem("customerOrder_List", JSON.stringify(filterDataArray))
    }

    _handleKeyDown = () => {

        let filterDataArray = {
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
            'number': this.state.number,
            'customerOrderNumber': this.state.customerOrderNumber,
            'customerNumber': this.state.customerNumber,
            'customerName': this.state.customerName,
            'minCreated': this.state.minCreated !== null ? this.state.minCreated : "",
            'maxCreated': this.state.maxCreated !== null ? this.state.maxCreated : "",
            'minDeliveryDate': this.state.minDeliveryDate !== null ? this.state.minDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",
            'maxDeliveryDate': this.state.maxDeliveryDate !== null ? this.state.maxDeliveryDate : "",
            'minModified': this.state.minModified !== null ? this.state.minModified : "",
            'maxModified': this.state.maxModified !== null ? this.state.maxModified : "",
            'minPODate': this.state.minPODate !== null ? this.state.minPODate : "",
            'maxPODate': this.state.maxPODate !== null ? this.state.maxPODate : "",
            'minPODeliveryDate': this.state.minPODeliveryDate !== null ? this.state.minPODeliveryDate : "",
            'maxPODeliveryDate': this.state.maxPODeliveryDate !== null ? this.state.maxPODeliveryDate : "",
            'minActualDelivery': this.state.minActualDelivery !== null ? this.state.minActualDelivery : "",
            'maxActualDelivery': this.state.maxActualDelivery !== null ? this.state.maxActualDelivery : "",
            'status': this.state.status,
            'productStatus': this.state.productStatus,
            'invoiceStatus': this.state.invoiceStatus,
            'paymentStatus': this.state.paymentStatus,
            'manager': this.state.manager,
            'email': this.state.email,
            'phone': this.state.phone,
            'address': this.state.address,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }
        localStorage.setItem("customerOrder_List", JSON.stringify(filterDataArray))
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.getCustomerOrders()
        }, 800); 
    }

    getFilterData = () => {

        let filterData = JSON.parse(localStorage.getItem("customerOrder_List"))
        if (filterData !== null) {
            this.setState({
                activePage: filterData.activePage,
                start: filterData.start,
                length: parseInt(filterData.length),
                number: filterData.number,
                customerOrderNumber: filterData.customerOrderNumber,
                customerNumber: filterData.customerNumber,
                customerName: filterData.customerName,
                minCreated: filterData.minCreated ? new Date(filterData.minCreated) : "",
                maxCreated: filterData.maxCreated ? new Date(filterData.maxCreated) : "",
                minDeliveryDate: filterData.minDeliveryDate ? new Date(filterData.minDeliveryDate) : "",
                maxDeliveryDate: filterData.maxDeliveryDate ? new Date(filterData.maxDeliveryDate) : "",
                minModified: filterData.minModified ? new Date(filterData.minModified) : "",
                maxModified: filterData.maxModified ? new Date(filterData.maxModified) : "",
                minPODate: filterData.minPODate ? new Date(filterData.minPODate) : "",
                maxPODate: filterData.maxPODate ? new Date(filterData.maxPODate) : "",
                minPODeliveryDate: filterData.minPODeliveryDate ? new Date(filterData.minPODeliveryDate) : "",
                maxPODeliveryDate: filterData.maxPODeliveryDate ? new Date(filterData.maxPODeliveryDate) : "",
                minActualDelivery: filterData.minActualDelivery ? new Date(filterData.minActualDelivery) : "",
                maxActualDelivery: filterData.maxActualDelivery ? new Date(filterData.maxActualDelivery) : "",

                status: filterData.status,
                productStatus: filterData.productStatus,
                invoiceStatus: filterData.invoiceStatus,
                paymentStatus: filterData.paymentStatus,
                manager: filterData.manager,
                email: filterData.email,
                phone: filterData.phone,
                address: filterData.address,

                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,

            }, () => {
                // this.getCustomerOrders()
                let checkColumns = localStorage.getItem("customerOrdersSelected__Columns")
                if (checkColumns) {
                    let col = JSON.parse(checkColumns)
                    this.setState({
                        chooseColumnList: col
                    }, () => {
                        this.getManagers()
                    })
                } else {
                    localStorage.setItem("customerOrdersSelected__Columns", JSON.stringify(this.state.chooseColumnList))
                    this.getManagers()
                }
            })
        } else {
            let checkColumns = localStorage.getItem("customerOrdersSelected__Columns")
            if (checkColumns) {
                let col = JSON.parse(checkColumns)
                this.setState({
                    chooseColumnList: col
                }, () => {
                    this.getManagers()
                })
            } else {
                localStorage.setItem("customerOrdersSelected__Columns", JSON.stringify(this.state.chooseColumnList))
                this.getManagers()
            }
        }
    }

    onSelectStatus(status) {
        this.setState({
            status: status
        }, () => {
            this.getCustomerOrders()
        })
    }

    getCustomerOrders = () => {
        let { number, customerNumber, customerName, minCreated, maxCreated, minDeliveryDate, maxDeliveryDate, status, productStatus, start, length, invoiceStatus, paymentStatus, manager, email, phone, address, minPODate, maxPODate, minPODeliveryDate, maxPODeliveryDate, minActualDelivery, maxActualDelivery, minModified, maxModified } = this.state

        this.setState({ isLoading: true, error: false })

        APIManager.callCustomerOrderListExceptQuotation(number, customerNumber, customerName, convertDateToMMDDYYYYWithoutZero(minCreated), convertDateToMMDDYYYYWithoutZero(maxCreated), convertDateToMMDDYYYY(minDeliveryDate), convertDateToMMDDYYYY(maxDeliveryDate), status, productStatus, start, length, invoiceStatus, paymentStatus, manager, email, phone, address, convertDateToMMDDYYYY(minPODate), convertDateToMMDDYYYY(maxPODate), convertDateToMMDDYYYY(minPODeliveryDate), convertDateToMMDDYYYY(maxPODeliveryDate), convertDateToMMDDYYYY(minActualDelivery), convertDateToMMDDYYYY(maxActualDelivery), convertDateToMMDDYYYY(minModified), convertDateToMMDDYYYY(maxModified))
            .then(response => {
                this.setState({
                    customerOrderList: response.data,
                    totalRecords: response.totalRecords,
                    isLoading: false
                }, () => {
                    if (this.state.fieldName) {
                        let data = {
                            field: this.state.fieldName,
                            type: this.state.fieldType
                        }
                        this.handleSort(data, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if (errors.status === "userDataError") {
                        this.logout()
                    }
                })
            });
    }

    updateColumn = (field, isVisible) => {
        let colums = this.state.chooseColumnList
        let col = [...this.state.chooseColumnList]
        var foundIndex = colums.findIndex(x => x.field === field);
        col[foundIndex].visible = !isVisible;

        this.setState({
            chooseColumnList: col
        }, () => {
            localStorage.setItem("customerOrdersSelected__Columns", JSON.stringify(col))
        })
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getCustomerOrders()
        });
    }

    sortBy(key, sort) {
        let arrayCopy = [...this.state.customerOrderList];
        arrayCopy.sort(this.compareBy(key, sort));
        this.setState({ customerOrderList: arrayCopy });
    }

    compareBy(key, sort) {
        let reverse = sort ? 1 : -1;
        return function (a, b) {
            if (a[key] < b[key]) return -1 * reverse;
            if (a[key] > b[key]) return 1 * reverse;
            return 0;
        };
    }


    clearFilter = () => {
        localStorage.removeItem("customerOrder_List")
        this.setState({
            number: "",
            customerNumber: "",
            customerName: "",
            minCreated: "",
            maxCreated: "",
            minDeliveryDate: "",
            maxDeliveryDate: "",
            minModified: "",
            maxModified: "",
            minPODate: "",
            maxPODate: "",
            minPODeliveryDate: "",
            maxPODeliveryDate: "",
            minActualDelivery: "",
            maxActualDelivery: "",
            status: "",
            productStatus: "",
            paymentStatus: "",
            invoiceStatus: "",
            manager: "",
            email: "",
            phone: "",
            address: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getCustomerOrders()
        })
    }
    addCustomerOrder = () => {
        let slug = "CRM_CustomerOrders"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createneworder"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editCustomerOrder = (id, num) => {
        localStorage.setItem("customerOrderId", id)
        localStorage.setItem("orderType", "customerOrder")
        localStorage.setItem("customerOrderNumber", num)

        this.props.history.push({
            pathname: "/viewcustomerorder"
        })
    }

    exportPDF = () => {
        let responseData = this.state.customerOrderList
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(10);

        const title = "Customer Orders";

        const headers = [[
            "Number",
            "Customer number",
            "Customer name",
            "Status",
            "Product status",
            "Delivery terms",
            "PO Date",
            "Delivery as per PO",
            "Estimated Delivery",
            "Actual Delivery",
            "Reference",
            "Managers",
            "Mobile",
            "Skype",
            "Email",
            "Created",
        ]];

        const data = responseData.map((elt, i) => [
            elt.number.toUpperCase(),
            elt.customerNumber.toUpperCase(),
            elt.customerName,
            elt.status,
            elt.productStatus,
            elt.deliveryTerms,
            convertDateStringMMDDtoDDMM(elt.poDate),
            convertDateStringMMDDtoDDMM(elt.poDeliveryDate),
            convertDateStringMMDDtoDDMM(elt.deliveryDate),
            convertDateStringMMDDtoDDMM(elt.actualDeliveryDate),
            elt.reference,
            elt.managers,
            elt.Mobile,
            elt.Skype,
            elt.Email,
            convertDateStringMMDDtoDDMM(elt.created)
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("customer-orders.pdf")
    }

    exportCSV = () => {

        let responseData = this.state.customerOrderList

        const title = "Customer Orders";
        const headers = [
            "Number",
            "Customer number",
            "Customer name",
            "Status",
            "Product status",
            "Delivery terms",
            "PO Date",
            "Delivery as per PO",
            "Estimated Delivery",
            "Actual Delivery",
            "Reference",
            "Managers",
            "Mobile",
            "Skype",
            "Email",
            "Created",
        ];

        const data = responseData.map((elt, i) => [
            elt.number.toUpperCase(),
            elt.customerNumber.toUpperCase(),
            elt.customerName,
            elt.status,
            elt.productStatus,
            elt.deliveryTerms,
            convertDateStringMMDDtoDDMM(elt.poDate),
            convertDateStringMMDDtoDDMM(elt.poDeliveryDate),
            convertDateStringMMDDtoDDMM(elt.deliveryDate),
            convertDateStringMMDDtoDDMM(elt.actualDeliveryDate),
            elt.reference,
            elt.managers,
            elt.Mobile,
            elt.Skype,
            elt.Email,
            convertDateStringMMDDtoDDMM(elt.created)
        ]);

        const options = {
            filename: 'customer-orders',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }

    exportItemPDF = () => {
        let responseData = this.state.customerItemList
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(10);

        const title = "Customer Orders";

        const headers = [[
            "Number",
            "Part No",
            "Part Description",
            "Quantity",
            "Customer Number",
            "Customer Name",
            "Status",
            "Created",
            "Delivery Date ",
          
        ]];

        const data = responseData.map((elt, i) => [
            elt.number.toUpperCase(),
            elt.partNo.toUpperCase(),
            elt.partDescription,
            elt.quantity,
            elt.customerNumber,
            elt.customerName,
            elt.status,
            convertDateStringMMDDtoDDMM(elt.created),
            convertDateStringMMDDtoDDMM(elt.deliveryDate),
  
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("customer-orders.pdf")
    }

    exportItemCSV = () => {

        let responseData = this.state.customerItemList
        const title = "Customer Orders";
        const headers = [[
            "Number",
            "Part No",
            "Part Description",
            "Quantity",
            "Customer Number",
            "Customer Name",
            "Status",
            "Created",
            "Delivery Date ",
          
        ]];

        const data = responseData.map((elt, i) => [
            elt.number.toUpperCase(),
            elt.partNo.toUpperCase(),
            elt.partDescription,
            elt.quantity,
            elt.customerNumber,
            elt.customerName,
            elt.status,
            convertDateStringMMDDtoDDMM(elt.created),
            convertDateStringMMDDtoDDMM(elt.deliveryDate),
  
        ]);


        const options = {
            filename: 'customer-orders',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
    
    getSetItemData = (data) => {
        this.setState({ customerItemList: data})
    }


    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isCustomerOrder={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.CRM.CustomerOrders}</div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-select-box">
                                    <select value={this.state.tableList} onChange={(e) => this.setState({ tableList: e.target.value })}>
                                        <option value="CustomerOrders">Customer orders</option>
                                        <option value="Items">Items</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {
                                        this.state.tableList === "CustomerOrders" &&
                                        <div>
                                            <div className="button" onClick={() => this.exportPDF()}>PDF <ImArrowDown2 /></div>
                                            <div className="button" onClick={() => this.exportCSV()}>CSV <ImArrowDown2 /></div>
                                        </div>
                                    }
                                    {
                                        this.state.tableList === "Items" &&
                                        <div>
                                            <div className="button" onClick={() => this.exportItemPDF()}>PDF <ImArrowDown2 /></div>
                                            <div className="button" onClick={() => this.exportItemCSV()}>CSV <ImArrowDown2 /></div>
                                        </div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.tableList === "CustomerOrders" ?
                            <div className='__kDoEM__table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {
                                                this.state.chooseColumnList.map((data, i) => {
                                                    if (data.visible === true)
                                                        return <th className="tableHeader" key={'tableHeader' + i} onClick={() => this.handleSort(data, false)}><span>{data.name}{this.state.fieldName === data.field && (this.state.sortVal ? <span><Fi.FiArrowUp /></span> : <span><Fi.FiArrowDown /></span>)} </span></th>
                                                })
                                            }

                                            <th></th>
                                            <Popup arrow={false} trigger={<th><Fi.FiChevronsDown /></th>} position="bottom right">
                                                <div className="selectColumns">
                                                    <ul>
                                                        {
                                                            this.state.chooseColumnList.map((data, i) => {
                                                                if (data.fixed)
                                                                    return <li key={'chooseColumn' + i}><label htmlFor={data.field}><span>{data.name}</span> <input type="checkbox" checked={data.visible} disabled></input> </label></li>
                                                                else
                                                                    return <li key={'chooseColumn' + i}><label htmlFor={data.field}><span>{data.name}</span> <input type="checkbox" checked={data.visible} onChange={(e) => this.updateColumn(data.field, data.visible)}></input> </label></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </Popup>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            {
                                                this.state.chooseColumnList[0].visible &&
                                                <th style={{ width: 45 }}><input type='text' value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => this._handleKeyDown())} /></th>
                                            }
                                            {
                                                this.state.chooseColumnList[1].visible &&
                                                <th style={{ width: 45 }}><input type='text' value={this.state.customerNumber} onChange={(e) => this.setState({ customerNumber: e.target.value }, () => this._handleKeyDown())} /></th>
                                            }
                                            {
                                                this.state.chooseColumnList[2].visible &&
                                                <th><input type='text' value={this.state.customerName} onChange={(e) => this.setState({ customerName: e.target.value }, () => this._handleKeyDown())} /></th>
                                            }
                                            {
                                                this.state.chooseColumnList[3].visible &&
                                                <th style={{ width: 45 }}><input type='text' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value }, () => this._handleKeyDown())} /></th>

                                            }
                                            {
                                                this.state.chooseColumnList[4].visible &&
                                                <th style={{ width: 45 }}><input type='text' value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value }, () => this._handleKeyDown())} /></th>
                                            }
                                            {
                                                this.state.chooseColumnList[5].visible &&
                                                <th style={{ width: 45 }}><input type='text' value={this.state.address} onChange={(e) => this.setState({ address: e.target.value }, () => this._handleKeyDown())} /></th>
                                            }
                                            {
                                                this.state.chooseColumnList[6].visible &&
                                                <th className='customer_order_th_data'>
                                                    <select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value }, () => this._handleKeyDown())}>
                                                        <option value=""></option>
                                                        <option value="waiting for confirmation">Waiting for confirmation</option>
                                                        <option value="confirmed">Confirmed</option>
                                                        <option value="waiting for production">Waiting for Production</option>
                                                        <option value="in production">In Production</option>
                                                        <option value="ready for shipment">Ready for Shipment</option>
                                                        <option value="partially invoiced">Partially Invoiced</option>
                                                        <option value="partially shipped">Partially Shipped</option>
                                                        <option value="invoiced">Invoiced</option>
                                                        <option value="shipped">Shipped</option>
                                                        <option value="delivered">Delivered</option>
                                                    </select>

                                                </th>
                                            }

                                            {
                                                this.state.chooseColumnList[7].visible &&
                                                <th style={{ width: 110 }}>
                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                this.state.minPODate
                                                            }
                                                            onChange={
                                                                date => this.setState({ minPODate: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.minPODate}
                                                            selected={
                                                                this.state.maxPODate
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxPODate: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.chooseColumnList[8].visible &&
                                                <th style={{ width: 110 }}>
                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                this.state.minPODeliveryDate
                                                            }
                                                            onChange={
                                                                date => this.setState({ minPODeliveryDate: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.minPODeliveryDate}
                                                            selected={
                                                                this.state.maxPODeliveryDate
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxPODeliveryDate: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }

                                            {
                                                this.state.chooseColumnList[9].visible &&
                                                <th style={{ width: 110 }}>
                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                this.state.minDeliveryDate
                                                            }
                                                            onChange={
                                                                date => this.setState({ minDeliveryDate: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.minDeliveryDate}
                                                            selected={
                                                                this.state.maxDeliveryDate
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxDeliveryDate: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.chooseColumnList[10].visible &&
                                                <th style={{ width: 110 }}>
                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                this.state.minActualDelivery
                                                            }
                                                            onChange={
                                                                date => this.setState({ minActualDelivery: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.minActualDelivery}
                                                            selected={
                                                                this.state.maxActualDelivery
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxActualDelivery: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.chooseColumnList[11].visible &&
                                                <th style={{ width: 110 }}>
                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={this.state.minModified}
                                                            onChange={
                                                                date => this.setState({ minModified: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.minModified}
                                                            selected={
                                                                this.state.maxModified
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxModified: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }

                                            {
                                                this.state.chooseColumnList[12].visible &&
                                                <th style={{ width: 110 }}>
                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={this.state.minCreated}
                                                            onChange={
                                                                date => this.setState({ minCreated: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value position-relative">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={this.state.minCreated}
                                                            selected={
                                                                this.state.maxCreated
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxCreated: date }, () => {
                                                                    this._handleKeyDown()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }

                                            <th></th>
                                            <th onClick={() => this.clearFilter()} className="text-center"><Fi.FiX /> </th>
                                        </tr>
                                    </thead>
                                    {
                                        this.state.isLoading ?
                                            <TableLoading
                                                tr={8}
                                                td={
                                                    this.state.chooseColumnList.filter(function (data) {
                                                        return data.visible == true;
                                                    }).length + 3
                                                }
                                            />
                                            :
                                            <tbody>
                                                {
                                                    this.state.customerOrderList.map((data, i) => (
                                                        <tr key={data._id} onClick={() => this.editCustomerOrder(data._id, data.number.toUpperCase())}>
                                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                            {
                                                                this.state.chooseColumnList.map((d, j) => {
                                                                    if (d.visible === true) {
                                                                        if (d.name === "PO Date" || d.name === "Delivery as per PO" || d.name === "Estimated Delivery" || d.name === "Actual Delivery" || d.name === "Modified" || d.name === "Created") {
                                                                            var dateString = data[d.field] ? data[d.field].toString() : '';
                                                                            var dateParts = dateString ? dateString.split("/") : '';
                                                                            var dateObject = dateParts ? dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2] : '';
                                                                            return <td key={'selectedCol' + j}>{dateObject}</td>
                                                                        } else if (d.id === "3") {
                                                                            return <td key={'selectedCol' + j} className="text-lowercase">{data[d.field] ? data[d.field].toString() : ''}</td>
                                                                        } else if (d.id === "5") {
                                                                            return <td key={'selectedCol' + j}>{data[d.field] ? data[d.field].toString().replace(/_/g, "") : ''}</td>
                                                                        } else {
                                                                            return <td key={'selectedCol' + j}>{data[d.field] ? data[d.field].toString() : ''}</td>
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            <td className="text-center">
                                                                {
                                                                    data.is_important_note &&
                                                                    <Ri.RiFlag2Line className="color-red" />
                                                                }
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                    }
                                </table>

                                <div>
                                    <div className="float-left mt-10px">
                                        <div className="tableElemContain" style={{ width: 80 }}>
                                            <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                                <option value="25">25 rows</option>
                                                <option value="50">50 rows</option>
                                                <option value="100">100 rows</option>
                                                <option value="">All rows</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="float-right mt-10px">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                            totalItemsCount={this.state.totalRecords}
                                            pageRangeDisplayed={10}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            :
                            <ItemTable history={this.props.history}
                                getItemList={this.getSetItemData}
                            />
                    }

                </div>
            </div>
        );
    }
}

export default CustomerOrders;