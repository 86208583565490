import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Loading from '../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as Ai from "react-icons/ai";
import * as Bi from "react-icons/bi";


import {
    AiOutlineExclamation
} from "react-icons/ai";
import { convertDateStringFormatToMMDDYYObject, convertDateStringtoDateObject, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

export default class EditPurchaseOrderPayment extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            paymentId: this.props.location.state.paymentId,
            invoice_id: this.props.location.state.invoiceId,
            allRemaining: this.props.location.state.remaining,
            allSum: 0,

            id: "",
            number: "",
            dueDate: "",
            totalPayment: 0,
            remainingPayment: 0,
            paid: 0,
            date: "",
            sum: 0,
            note: "",
            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let id = localStorage.getItem('purchase_order_id')
        let number = localStorage.getItem('purchase_order_number')
        
        this.setState({
            id: id,
            number: number,
        },() => {
            this.getSingleInvoice()  
        })
    }

    getSingleInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPOSingleInvoice(this.state.invoice_id)
        .then(response => {
            let paymentsArray = response.data.payment
            let local_paid = 0;
            for(let i=0;i<paymentsArray.length;i++){
                local_paid += parseFloat(paymentsArray[i].paid)
                if(this.state.paymentId === paymentsArray[i].id){
                    break;
                }
            }
            
            this.setState({ 
                isLoading: false,
                totalPayment: response.data.grandTotal,
                dueDate: response.data.dueDate,
                paid: local_paid,
                remainingPayment: response.data.grandTotal - local_paid
            },()=>{
                this.getSinglePaymentDetail()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }
    
    getSinglePaymentDetail = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSinglePO_Payment(this.state.invoice_id, this.state.paymentId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                invoiceId: response.invoiceId,
                sum: response.sum,
                allSum: response.sum,
                date: convertDateStringFormatToMMDDYYObject(response.date),
                note: response.notes
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
            })
        });   
    }

    updateSumValues = (val) => {
        let remaining = this.state.allRemaining
        let paid = this.state.allSum
        let totalRemaining = parseFloat(remaining) + parseFloat(paid)
        if(val === "" || val <= totalRemaining){
            this.setState({ 
                sum: val
            })
        }
    }

    updatePayment = () => {
        if(this.state.fieldNotEditable){
            let slug = "Procurement_PurchaseOrders"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }else{
            let { 
                invoice_id,
                paymentId,
                sum,
                date,
                note 
            } = this.state

            if(sum === ""){
                this.setState({
                    error: true,
                    errormessage: "Please enter amount",
                })
            }else if(date === ""){
                this.setState({
                    error: true,
                    errormessage: "Please select date",
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdatePO_Payment( invoice_id, paymentId, sum, convertDateToMMDDYYYY(date), note )
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        successmessage: response.message,
                        success: true,
                        fieldNotEditable: true,
                    },()=>{
                        this.getSinglePaymentDetail()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });   
            }
        }
    }

    deleteEntry = () => {
        let slug = "Procurement_PurchaseOrders"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){ 
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeletePO_Payment(this.state.invoice_id, this.state.paymentId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewPO_Payment") !== null){
            localStorage.removeItem("createNewPO_Payment")
            localStorage.removeItem("vendorNote")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCustomerOrder={true} />
                <ProcurementHeader isPurchaseOrders={true}/>
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Edit payment for {this.state.number.toUpperCase()}</div>
                        </div>
                    </div>
                </div>
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updatePayment()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                     <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Invoice ID</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        {this.state.invoiceId}
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Due date</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        {this.state.dueDate}
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Sum</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        {this.state.totalPayment.toFixed(2)}<Bi.BiRupee />
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Paid</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        <span>{this.state.paid.toFixed(2)}<Bi.BiRupee /></span>  
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Remaining</div>
                                <div className="value">
                                    <div className="__static_fixed_text text-uppercase">
                                        <span>{this.state.remainingPayment.toFixed(2)}<Bi.BiRupee /></span> 
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                       
                            <div className="add_inner">
                                <div className="title">Sum*</div>
                                <div className="value">
                                    <input
                                        onChange={(e)=>this.updateSumValues(e.target.value)} 
                                        type="text"
                                        value={this.state.sum}
                                    ></input>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Date*</div>
                                <div className="value">
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.date}
                                        onChange={date => this.setState({ date: date })}
                                        className="filterdateoption"
                                    />
                                    <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value})} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updatePayment()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            {
                this.state.isLoading &&
                <Loading />
            }
            </div>
        )
    }
}