import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import { Modal } from 'react-bootstrap';
import * as APIManager from './../../../APIManager/APIManager'
import * as Cg from 'react-icons/cg'
import * as Fi from 'react-icons/fi'
import * as Ai from 'react-icons/ai'
import toast from 'react-simple-toasts';

import Loading from '../../../helper/loading'
import { convertDateTimeStringMMDDtoDDMM } from '../../../constants/DateFormatter';

class ProOperations extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",


            userEmail: "",
            userId: "",

            responseData: [],
            showHidePuaseScreen: false,
            stopProductionScreen: false,
            productionId: "",
            operationId: "",
            completeQuantity: "",
            rejectedQuantity: "",
            currentOperationDetail: null,
            productionNote: "",
        }
    }

    componentDidMount(){
        this.checkUserLogin()
       
    }
    checkUserLogin = () => {
        let userData = localStorage.getItem('userdata')
        if(userData){
            let email = JSON.parse(userData).email
            let _id = JSON.parse(userData)._id
            this.setState({
                userEmail: email,
                userId: _id
            },()=>{
                this.getProductionListByUser()
            })
        }
    }

    getProductionListByUser = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetProductionListByUser()
        .then(response => {
            this.setState({ 
                isLoading: false,
                responseData: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
               // error: true,
               // errormessage: errors 
            })
        });
    }

    startMO = (MO_Id, operation_id) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({ isLoading: true, error: false })
            APIManager.callStartMO(MO_Id, operation_id)
            .then(response => {
                if(response.status === "success"){
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        success: false,
                    },()=>{
                        this.getProductionListByUser()  
                    })
                }else{
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: response.message
                    })
                }
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    PlayPauseMO = (id, operationId, actionFlag) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({ isLoading: true, error: false })
            APIManager.callPlayPauseMO(id, operationId, actionFlag)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    showHidePuaseScreen: false,
                },()=>{
                    if(actionFlag === "pause"){
                        this.callCalculateQTY(id)  
                    }else{
                        this.getProductionListByUser()
                    }
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }else{
            alert("You don't have permission to "+permission+" this record")
        } 
    }


    callCalculateQTY = (id) => {
        this.setState({ isLoading: true, error: false })
        
        let totalQty = this.state.completeQuantity ? this.state.completeQuantity : 0
        let rejQty = this.state.rejectedQuantity ? this.state.rejectedQuantity : 0
        let productionNote = this.state.productionNote
        let operation = this.state.currentOperationDetail.operation 
        let product = this.state.currentOperationDetail.product
        let totalOfOperationQty = this.state.currentOperationDetail.quantity

        let note = ""

        if(totalQty === "")
            totalQty = 0


        if(productionNote !== ""){
            note = operation +' '+ product +' '+ totalQty +' quantity completed, Note: '+this.state.productionNote
        }

        APIManager.callCalculateQTY(id, totalQty, note, rejQty)
        .then(response => {
            this.setState({ 
                isLoading: false,
                completeQuantity: "",
                productionNote: "",
                rejectedQuantity: "",
            },()=>{
                let totalOfUserInput = parseFloat(totalQty) + parseFloat(rejQty)
                if(totalOfOperationQty === totalOfUserInput){
                    let pDid = this.state.currentOperationDetail.productionDetailId
                    this.finishMO(pDid)
                }else{
                    this.getProductionListByUser()
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    
    setPauseMO = (data) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({
                showHidePuaseScreen: !this.state.showHidePuaseScreen,
                currentOperationDetail: data,
                //completeQuantity: data.quantity,
                rejectedQuantity: ''
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        } 
    }

    setStopScreen = (data) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            if(data.productionDetailId === ""){
                alert("Production is not started yet")
            }else{
                var r = window.confirm("Please confirm, you're finishing the operation");
                if (r === true) {
                    this.setState({
                        stopProductionScreen: !this.state.stopProductionScreen,
                        currentOperationDetail: data,
                        completeQuantity: data.quantity,
                        rejectedQuantity: ''
                    })
                }
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        } 
    }

    setHidePauseScreen = () => {
        this.setState({
            showHidePuaseScreen: false,
            stopProductionScreen: false
        })
    }


    countAndStopMO = (id) => {
        let {
            completeQuantity,
            rejectedQuantity,
            productionNote,
            currentOperationDetail
        } = this.state
        
        let totalQty = completeQuantity
        let rejQty = rejectedQuantity
        let operation = currentOperationDetail.operation 
        let product = currentOperationDetail.product
        let operationQty = currentOperationDetail.quantity
        
        let note = ""
        if(productionNote !== ""){
            note = operation +' '+ product +' '+ totalQty +' quantity completed, Note: '+productionNote
        }

        let total = totalQty + rejQty

        if(parseFloat(total) !== parseFloat(operationQty)){
            alert("Please add total completed and rejected quantity to finish the order")
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCalculateQTY(id, totalQty, note, rejQty)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    completeQuantity: "",
                    productionNote: "",
                    rejectedQuantity: "",
                },()=>{
                    this.finishMO(id)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    finishMO = (prodId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callFinishMO(prodId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                completeQuantity: "",
                stopProductionScreen: false
            },()=>{
                let MO_no = this.state.currentOperationDetail.manufacturingOrderNumber.toUpperCase()
                let operationName = this.state.currentOperationDetail.operation
                let string = operationName+" for MO Number "+MO_no+" is finished"
                toast(string)
                this.getProductionListByUser()  
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    updateCompletedQty = (val) => {
        let {
            currentOperationDetail,
            rejectedQuantity
        } = this.state

        let enteredQty = val ? val : 0
        let rejectedQty = rejectedQuantity ? rejectedQuantity : 0
        let qty = parseFloat(enteredQty) + parseFloat(rejectedQty)
        let totalQty = currentOperationDetail.quantity

        if(qty <= totalQty){
            this.setState({
                completeQuantity: val
            })
        }else{
            this.setState({
                completeQuantity: ''
            })
        }
    }
    
    updateRejectedQty = (val) => {
        let {
            currentOperationDetail,
            completeQuantity
        } = this.state

        let enteredQty = val ? val : 0
        let rejectedQty = completeQuantity ? completeQuantity : 0
        let qty = parseFloat(enteredQty) + parseFloat(rejectedQty)
        let totalQty = currentOperationDetail.quantity

        if(qty <= totalQty){
            this.setState({
                rejectedQuantity: val
            })
        }else{
            this.setState({
                rejectedQuantity: ''
            })
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isProOperations={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Operations</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                {/* <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.exportAsPDF()}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={()=>this.exportAsCSV()}>CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {
                        this.state.error &&
                        <div className="editscreenerror mb-5">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror mb-5">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }

                    <div className="tableContent mh400" style={{ overflowX : "auto" }}>
                        <table>
                            <tbody>
                            {
                                this.state.responseData.map((data, i) => (
                                    data.is_MoFinished === false &&
                                    <tr key={"EPP"+i}>
                                        <td style={{width: 200}}>
                                            <div>Manufacturing Order :</div>
                                            <div>Product :</div>
                                            <div>Quantity :</div>
                                            <div>Operation :</div>
                                            <div>Workstation :</div>
                                            <div>Actual Start :</div>
                                            <div>{data.workerId !== this.state.userId ? 'Operator :' : ''}</div>
                                        </td>
                                        <td>
                                            <div>{data.manufacturingOrderNumber.toUpperCase()}</div>
                                            <div>{data.product}</div>
                                            <div>{data.quantity} {data.uomName}</div>
                                            <div>{data.operation}</div>
                                            <div>{data.workStation}</div>
                                            <div>{data.actualStart ? convertDateTimeStringMMDDtoDDMM(data.actualStart) : '-'}</div>
                                            <div className='capitalize'>{data.workerId !== this.state.userId ? data.userName : ''}</div>
                                        </td>
                                        <td style={{ width: 110 }} className="text-right">
                                            <div>
                                                {
                                                    data.is_MoFinished === false ?
                                                        (data.is_MoStart === false && data.is_MoPaused === false) ?
                                                            <Fi.FiPlayCircle className="employee___production__buttons" title="Start Production" onClick={()=>this.startMO(data.manufacturingOrderId, data.id)}/>
                                                        :
                                                        (data.is_MoStart && data.is_MoPaused === false) ?
                                                            <Fi.FiPauseCircle className="employee___production__buttons" title="Pause Production" onClick={()=>this.setPauseMO(data)} />
                                                        :
                                                            <Fi.FiPlayCircle className="employee___production__buttons" title="Start Production" onClick={()=>this.PlayPauseMO(data.productionDetailId, data.id, 'play')}/>
                                                    :
                                                        null
                                                }
                                            </div>
                                            <div>
                                                {
                                                    data.is_MoFinished === false &&
                                                        <Cg.CgPlayStopO className="employee___production__buttons" title="Finish Production" onClick={()=>this.setStopScreen(data)} />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
                 <Modal size="lg" show={this.state.showHidePuaseScreen} onHide={()=>this.setHidePauseScreen()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.state.currentOperationDetail !== null &&
                                <div className='text-capitalize'>
                                    {this.state.currentOperationDetail.manufacturingOrderNumber.toUpperCase()}&nbsp;
                                    {this.state.currentOperationDetail.operation} for&nbsp;
                                    {this.state.currentOperationDetail.product}
                                </div>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='__pause_qty__detail_container'>
                            <div className='__pause_qty__title'>Add your completed quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateCompletedQty(e.target.value)}
                                value={this.state.completeQuantity} 
                            />
                            <div className='__pause_qty__title'>Add your rejected quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateRejectedQty(e.target.value)}
                                value={this.state.rejectedQuantity} 
                            />
                            <div className='__pause_note__title'>Add a note</div>

                            <textarea 
                                className='text-area'
                                value={this.state.productionNote}
                                onChange={(e)=>this.setState({ productionNote: e.target.value })}
                            />
                            <div 
                                className="sign-btn-outline" 
                                onClick={()=> this.PlayPauseMO(this.state.currentOperationDetail.productionDetailId, this.state.currentOperationDetail.id,'pause')}
                            >
                                Submit
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={this.state.stopProductionScreen} onHide={()=>this.setHidePauseScreen()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.state.currentOperationDetail !== null &&
                                <div className='text-capitalize'>
                                    {this.state.currentOperationDetail.manufacturingOrderNumber.toUpperCase()}&nbsp;
                                    {this.state.currentOperationDetail.operation} for&nbsp;
                                    {this.state.currentOperationDetail.product}
                                </div>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='__pause_qty__detail_container'>
                            <div className='__pause_qty__title'>Add your completed quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateCompletedQty(e.target.value)}
                                value={this.state.completeQuantity} 
                            />
                             <div className='__pause_qty__title'>Add your rejected quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateRejectedQty(e.target.value)}
                                value={this.state.rejectedQuantity} 
                            />
                            <div className='__pause_note__title'>Add a note</div>

                            <textarea 
                                className='text-area'
                                value={this.state.productionNote}
                                onChange={(e)=>this.setState({ productionNote: e.target.value })}
                            />
                            <div 
                                className="sign-btn-outline" 
                                onClick={()=> this.countAndStopMO(this.state.currentOperationDetail.productionDetailId)}
                            >
                                Submit
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ProOperations;