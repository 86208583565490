import React, { Component } from 'react';
import AddProductDetail from './AddProductDetail';
import * as APIManager from './../../../../../APIManager/APIManager'

class ProductGroupForOtherCurrency extends Component {

    constructor(props){
        super(props)
        this.state = {
            currencyData: this.props.currencyData,

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: [],

            totalOfProductCost: 0.00,
            totalOfProductCostFinal: 0.00,
            totalOfRawCostFinal: 0.00,
            totalOfRawCostInOther: 0.00,
            totalNoOfProduct: 0,
            totalDiscountPrice: "",
            totalDiscountPercentage: "",
            productGroupList: [],

        }
        this.addNewGroupFields = this.addNewGroupFields.bind(this)
        this.addGroupFieldValues = this.addGroupFieldValues.bind(this)
    }

    componentDidMount(){
        this.getProductGroup()
    }


    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSellableProductGroup()
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response
            },()=>{
                this.setAllDetails()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setAllDetails = () => {
        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <AddProductDetail
                    key={'proGroup'} 
                    currencyData={this.state.currencyData}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={this.state.productGroupCounter}
                    productGroupList={this.state.productGroupList}
                />
            ]),
        })
    }

    addNewGroupFields = (counter) => {
        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <AddProductDetail                   
                        currencyData={this.state.currencyData}                  
                        key={'proGroup'+this.state.productGroupCounter}
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={this.state.productGroupCounter+1}
                        productGroupList={this.state.productGroupList}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: this.state.productGroupCounter+1,
            },()=>{
                this.setCounterText()
            })
        }
    }

    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        },()=>{
            localStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
            this.setCounterText()
        })
    }

    setCounterText = () => {
        var slides = document.getElementsByClassName("subgroupdata");
        for (var i = 0; i < slides.length; i++) {
            slides[i].firstChild.innerHTML = '<td>'+(i+1)+'</td>';
        }
    }

    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        },()=>{
            localStorage.setItem('productGroupData', JSON.stringify(productGroupData))
            this.countTotal()
        })
    }

    countTotal = () => {
        let arr = this.state.productGroupData;
        let totalCost = 0;
        let totalQuantity = 0;
        let rawTotal = 0;
        let totalCostInOther = 0;

        for(let i=0;i<arr.length;i++){
            if(arr[i] !== null){
                totalCost += arr[i].subtotal !== "" ? parseFloat(arr[i].subtotal) : 0
                totalQuantity += arr[i].quantity !== "" ? parseFloat(arr[i].quantity) : 0 
                rawTotal += arr[i].rawtotal !== "" ? parseFloat(arr[i].rawtotal) : 0
                totalCostInOther += arr[i].subTotalInOtherCurrency !== "" ? parseFloat(arr[i].subTotalInOtherCurrency) : 0
            }
        }
        this.setState({
            totalOfProductCost: totalCost.toFixed(2),
            totalOfProductCostFinal: totalCost.toFixed(2),
            totalOfRawCostFinal: Math.round(rawTotal).toFixed(2),
            totalOfRawCostInOther: Math.round(totalCostInOther).toFixed(2),
            totalNoOfProduct: totalQuantity,
        },()=>{
            localStorage.setItem("totalOfProductCost", totalCost.toFixed(2))
            localStorage.setItem("totalDiscountPrice", 0)
            localStorage.setItem("totalOfRawCostFinal",Math.round(rawTotal.toFixed(2)))
            localStorage.setItem("totalOfRawCostInOther",Math.round(totalCostInOther.toFixed(2)))
        })
    }


    render() {
        return (
            <div className="main-container mt-20 order_product_group">
                <table>
                    <thead>
                        <tr>
                            <th className='pg-tab-head' style={{ width: 2}}></th>
                            <th className='pg-tab-head' style={{ width: 200 }}>Product group</th>
                            <th className='pg-tab-head' style={{ width: 200 }}>Product</th>
                            <th className='pg-tab-head'>Quantity</th>
                            <th className='pg-tab-head'>Price/UoM</th>
                            <th className='pg-tab-head'>Ex. Rate</th>
                            <th className='pg-tab-head'>Price in {this.state.currencyData.currencyName.toUpperCase()}</th>
                            <th className='pg-tab-head'>Discount</th>
                            <th className='pg-tab-head'>Total</th>
                            <th className='pg-tab-head'>Delivery date</th>
                            <th className='pg-tab-head' style={{ width: 20}}></th>
                        </tr>
                    </thead>
                    <tbody>

                        
                       {
                           this.state.productGroupFields
                       }
                        
                        <tr>
                            <td></td>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td><b>{this.state.totalNoOfProduct}</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.totalOfRawCostInOther} {this.state.currencyData.currencySymbol}</b></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProductGroupForOtherCurrency;