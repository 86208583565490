import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Helper from './../../../helper/helper'
import { 
    FiPlus,
    FiX,
    FiEdit2
} from "react-icons/fi";

export default class CustomTable extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: false,  
            tablename: "loading...",
            tableFields: [],
            tableData: [],
            listTableData: [],
        };

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount(){
        this.getTable()
    }

    handleInputChange = (e, name) => {
        this.setState({
            [name]: e.target.value
        },()=>{
            this.getTableFilters()
        })
    }

    clearFilter = () => {
        for(let i=0;i<this.state.tableFields.length;i++){
            let index = this.state.tableFields[i];
            this.setState({
                [index]: ""
            })
        }
        this.getTableFilters()
    }

  

    getTable = () => {
        let id = localStorage.getItem("customTableId")
        let filterFlag = false;
        
        this.setState({ isLoading: true, error: false })
        APIManager.callFilterTableDetail(id, filterFlag)
        .then(response => {
            this.setState({
                isLoading: false, 
                tableData: response[0].values,
                tablename: response[0].tableName,
                tableFields: response[0].fields
           },()=>{
               this.setTableData(this.state.tableData)
           })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors.message 
            })
        });    
    }


    getTableFilters = () => {
        let that = this;
        let id = localStorage.getItem("customTableId")
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append('id', id)

        let filterFlag = false;

        for(let i=0;i<this.state.tableFields.length;i++){
            let index = this.state.tableFields[i];
            let value = this.state[this.state.tableFields[i]]
            if(typeof(value) !== 'undefined' && value !== ""){
                filterFlag = true
            }
            formdata.append(index, typeof(value) !== 'undefined' ? value : '')
        }
        if(filterFlag === true){
            formdata.append('filterFlag', true)
        }else{
            formdata.append('filterFlag', false)
        }

        fetch(APIManager.WEBSERVICES_URL+'stock/filterTable/getSingleFilterTable',{
            method: "post",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
               if(result.status === "success"){
                   that.setState({
                        tableData: result.data[0].values,
                   },()=>{
                       that.setTableData(that.state.tableData)
                   })
                } else if(result.status === "userDataError"){
                    that.logout()
                } else{
                    that.setState({
                        error: true,
                        errormessage: result.message
                    })
               }
            },(error) => {
               console.log(error)
            }
        ) 
    }

    setTableData = (data) => {
        if(typeof(data) !== 'undefined'){
            let mainList = []
            let innerList = []
            for(let i=0;i<data.length;i++){
                const vals = Object.values(data[i]);
                for(let j=0;j<vals.length-1;j++){
                    innerList.push(vals[j])  
                }
                mainList.push(innerList)
                innerList = []
            } 
            this.setState({ listTableData: mainList })
        }
    }
   
    editCustomTable = (id) => {
        localStorage.setItem("customTableValueId", id)
        this.props.history.push('/editcustomtable')
    }

    

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    createRecord = () =>{
        let slug = "Stock_FilterTable"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.props.history.push('/createcustomtablevalue')
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
        
    }

    goBack = () => {
        this.props.history.goBack();
    }


    render(){
        return(
            <div className="page absoluteWhite">
                {
                    this.state.pageLoading &&
                    <div className="pageLoading"></div>
                }
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isFilterTable={true} />
                <div className="main-container">


                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title text-capitalize">{this.state.tablename}</div>
                        </div>
                    </div>
                </div>
                <div className="tableContent">
                    <div className="stocksetting">
                        <div style={{ overflowX : "auto" }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="tableHeader tableHeadButton" style={{ width: 40 }} onClick={()=>this.createRecord()}><FiPlus /></th>
                                        {
                                            this.state.tableFields !== null &&
                                            this.state.tableFields.map((data,i) =>(
                                                <th className="tableHeader" key={"fields"+i}><span>{data}</span></th>
                                            ))
                                        }
                                        <th className="tableHeader" style={{ width: 30 }}></th>
                                        <th className="tableHeader tableHeadButton" style={{ width: 30 }} onClick={()=>this.createRecord()}><FiPlus /></th>
                                    </tr>
                                    <tr>
                                        <th style={{ width: 40 }} className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                        {
                                            this.state.tableFields !== null &&
                                            this.state.tableFields.map((data,i) =>(
                                                <th className="tableElemContain" key={"searchinput"+i}>
                                                    <input type="text" className="fieldheightdecr" autoComplete="off" value={this.state[data]} onChange={(e)=>this.handleInputChange(e, data)} />
                                                </th>
                                            ))
                                        }
                                        <th className="tableElemContain tableAddButton">{/*<div><FiSearch /></div>*/}</th>
                                        <th className="tableElemContain tableAddButton"><div onClick={()=>this.clearFilter()}><FiX /></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listTableData.map((data, i) => (
                                            <tr key={"list"+i}>
                                                <td>{i+1}</td>
                                                {
                                                    data.map((val, j) => {   
                                                        if (data.length === j + 1) {
                                                            return <><td key={"innerlist1"+j}></td><td key={"innerlist2"+j}><FiEdit2 className="editCusomerBtn" onClick={this.editCustomTable.bind(this,val)} /></td></>
                                                        } else {
                                                            return <><td key={"innerlist3"+j}>{val}</td></>
                                                        } 
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}