import React, { Component } from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import * as Bs from "react-icons/bs"
import * as Ai from "react-icons/ai";
import { validateNumber } from '../../../constants/Validate';

class MoveStockItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            status: "",
            productGroup: "",
            product: "",
            quantity: "",
            available: "",
            
            productGroupName: "",
            productName: "",

            newStorageLocationId: "",
            movableQuantity: "",

            stockId: "",
            stockNumber: "",
            stockStorageId: "",
            stockStorageQty: 0,

            storageLocation: [],
            productGroupList: [],
            productList: [],
            stockLots: [],
            storageLocationList: [],

        }
    }

    componentDidMount(){
        if(localStorage.getItem("stockLotId") !== null){
            let id = localStorage.getItem("stockLotId")
            let number = localStorage.getItem("stockLotNumber")
            let stockStorageId = localStorage.getItem("stockStorageId")
            let stockStorageQty = localStorage.getItem("stockStorageQty")
    
            this.setState({
                stockId: id,
                stockNumber: number,    
                stockStorageId: stockStorageId,
                stockStorageQty: stockStorageQty,
            },()=>{
                this.getSingleLot()
            })
        }else{
            this.getProductGroup()
        }
        
    }


    getSingleLot = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStockLot(this.state.stockId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroup: response.productGroup,
                product: response.stockItem,
                storageLocation: response.storageLocation,
            },()=>{
                this.getProductGroup()
                this.getItemsByGroup(this.state.productGroup)
                this.getLots()
                this.getAllStorageLocation()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getLots = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetLotByStockItem(this.state.productGroup, this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                stockLots: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getAllStorageLocation = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllStorageLocation()
        .then(response => {
            this.setState({ 
                isLoading: false,
                storageLocationList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });    
    }
   
    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetGroupByVendor(this.state.vendorId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemsByGroup = (_id) => {
        if(_id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callProductGroupItemsList(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    productList: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }
    

    getSingleProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProductGroup(this.state.productGroup)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupName: response.number.toUpperCase() +" "+response.name
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getSingleStorageLocation = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStorageLocation(id)
        .then(response => {
            this.setState({ 
                isLoading: false
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getOldStorageDetails = () => {
        if(this.state.stockStorageId !== ""){
            let {
                storageLocation,
                stockStorageId
            } = this.state;
            var getSingleLocation = storageLocation.filter(sl => sl.id === stockStorageId)
            this.setState({
                stockStorageQty: getSingleLocation[0].quantity
            })
        }
    }

    updateStockStorage = () => {
        
        let {
            stockId,
            stockStorageId,
            newStorageLocationId,
            movableQuantity,
            stockStorageQty
        } = this.state;

        if(stockId === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select stock lot",
            })
        }else if(stockStorageId === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select old place in stock",
            })
        }else if(movableQuantity === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter movable quantity",
            })
        }else if(movableQuantity === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter movable quantity",
            })
        }else if(parseFloat(movableQuantity) > parseFloat(stockStorageQty)){
            this.setState({
                success: false,
                error: true,
                errormessage: "Movable quantity should not be greater than storage quantity",
            })
        }else if(newStorageLocationId === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select new place of stock",
            })
        }else{

            this.setState({ isLoading: true, error: false })
            APIManager.callMoveStockItem(stockId, stockStorageId, newStorageLocationId, movableQuantity)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    success: true,
                    stockStorageQty: stockStorageQty - movableQuantity,
                    successmessage: "Product moved successfully",
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isStockLots={true} />
                    <div className="main-container">
                        <div className="page-header">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="page-title">Move product</div>
                                </div>
                            </div>    
                        </div>
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                        <div className="tableContent">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.updateStockStorage()}>Save</div>
                                <div className="clear"></div>
                            </div>

                            <div className="position-relative">
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Product group</div>
                                        <div className="value">
                                           <select className="text-capitalize" value={this.state.productGroup} onChange={(e)=>this.setState({ productGroup: e.target.value},()=>{ this.getItemsByGroup(this.state.productGroup)})}>
                                                <option value=''>Select group</option>
                                                {
                                                    this.state.productGroupList.map((data) => (
                                                        <option key={"group__"+data._id} value={data._id}>{data.number.toUpperCase()} {data.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Stock item *</div>
                                        <div className="value">
                                            <select className="text-capitalize" value={this.state.product} onChange={(e)=>this.setState({ product: e.target.value },()=>{ this.getLots()})}>
                                                <option value=''>Select item</option>
                                                {
                                                    this.state.productList.map((data) => (
                                                        <option key={'item__'+data._id} value={data._id}>{data.itemCode.toUpperCase()} {data.partDescription}</option>
                                                    ))
                                                }
                                           </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    
                                    <div className="add_inner">
                                        <div className="title">Lot *</div>
                                        <div className="value">
                                            <select className="text-capitalize" value={this.state.stockId} onChange={(e)=>this.setState({ stockId: e.target.value },()=>{ this.getSingleLot() })}>
                                               <option value=''>Select lot</option>
                                               {
                                                    this.state.stockLots.map((data) => (
                                                        <option key={'item__'+data._id} value={data._id}>{data.number} {data.name}</option>
                                                    ))
                                                }
                                           </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Old place in stock *</div>
                                        <div className="value">
                                            <select className="text-capitalize" value={this.state.stockStorageId} onChange={(e)=>this.setState({ stockStorageId: e.target.value },()=>{ this.getOldStorageDetails()})}>
                                               <option value=''>Select lot</option>
                                                {
                                                    this.state.storageLocation.map((data) => (
                                                        <option key={data.id} value={data.id}>{data.storageLocationName}</option>
                                                    ))
                                                }
                                           </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    
                                    <div className="add_inner">
                                        <div className="title">Available quantity</div>
                                        <div className="value"><div className="__static_fixed_text">{parseFloat(this.state.stockStorageQty).toFixed(2)}</div></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Quantity *</div>
                                        <div className="value">
                                           <input 
                                                type="text" 
                                                style={{width: "40%"}} 
                                                value={this.state.movableQuantity}
                                                onChange={(e)=>{
                                                    if(validateNumber(e.target.value)){
                                                        if(e.target.value === "" || parseFloat(e.target.value) <= parseFloat(this.state.stockStorageQty))
                                                            this.setState({ movableQuantity: e.target.value })
                                                        }
                                                    }
                                                }
                                            /> 
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">New place in stock *</div>
                                        <div className="value">
                                            <div className='float-left width90'>
                                                <select 
                                                    value={this.state.newStorageLocationId} 
                                                    onChange={(e)=> this.setState({ newStorageLocationId: e.target.value})}
                                                >
                                                    <option value=''>Select new place</option>
                                                    {
                                                        this.state.storageLocationList.map((data) => (
                                                            <option key={data._id} value={data._id} className="text-capitalize">{data.name}</option>
                                                        ))
                                                    }
                                                </select>
                                           </div>
                                            <div className='width10 d-inline-block text-center secondary-color addPartyIcon' title="Add new storage location" onClick={()=>this.props.history.push("/createstoragelocation")}>
                                                <Bs.BsFolderPlus />
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="clear"></div>

                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.updateStockStorage()}>Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>

                    </div>
            </div>
        );
    }
}

export default MoveStockItem;