import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="bg-white text-right">
                All rights reserved.
            </div>
        );
    }
}

export default Footer;