import React from 'react';
import DatePicker from "react-datepicker";
import './../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'

import { 
    FiX,
} from "react-icons/fi";


export default class CustomHolidays extends React.Component{

    constructor(props){
        super(props);   
        this.state = {
            holidays: "",
        }
    }

    componentDidMount(){
        if(this.props.holidays !== ""){
            let dt = this.props.holidays
            let newDate = dt.split("-")
            newDate = newDate[1]+"/"+newDate[0]+"/"+newDate[2]
            this.setState({
                holidays: new Date(newDate)
            })

        }
    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td className="position-relative">
                   
                    <DatePicker 
                        selected={this.state.holidays}
                        minDate={new Date()}
                        onChange={date => this.setState({ holidays: date },()=>{
                            onEdit(this.state.holidays, componentNum)
                            onBlur(this.state.holidays, componentNum)
                        })}
                        dateFormat="dd/MM/yyyy"
                    />
                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                                          
                </td>
                <td style={{ width: 50 }}>
                    {
                        this.state.holidays !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}