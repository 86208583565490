import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as Fi from 'react-icons/fi'
import * as Ai from 'react-icons/ai'

import Loading from '../../../components/Loading';
import EditRejectList from './EditRejectList';

import * as APIManager from './../../../APIManager/APIManager'

import Pagination from "react-js-pagination";

class RejectedItemsEdit extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            writeOff_id: "",
            number: "",
            status: "",
            productGroup: "",
            productGroupName: "",
            product: "",
            productName: "",
            stockDetail: [],
            created: "",
            note: "",

            uom: "",

        }
        this.releaseStock = this.releaseStock.bind(this)
    }

    componentDidMount(){
        // let id = localStorage.getItem("WriteOff_id")
        // this.setState({ 
        //     writeOff_id: id
        // },()=>{
        //     this.getSingleWriteOff()
        // })
    }

    getSingleWriteOff = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleWriteOff(this.state.writeOff_id)
        .then(response => {
            this.setState({
                isLoading: false,
                number: response.number,
                status: response.status,
                productGroup: response.productGroup,
                product: response.stockItem,
                stockDetail: response.stock,
                created: response.created,
                note: response.note,
            },()=>{
                this.getSingleProductGroup()
                this.getSingleProduct()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getSingleProduct = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase() +" "+response.itemData.partDescription,
                uom: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getSingleProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProductGroup(this.state.productGroup)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupName: response.number.toUpperCase() +" "+response.name
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteWriteOff(this.state.writeOff_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    editItem = () => {
        localStorage.setItem("itemId",this.state.product)
        this.props.history.push('/edititem')
    }

    goBack = () => {
        if(localStorage.getItem("createNewAutoWriteOff") !== null){
            localStorage.removeItem("createNewAutoWriteOff")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    saveItem = () => {
        
    }

    releaseStock = (stockLot) => {
        localStorage.setItem("releaseStockLotId", stockLot)
        this.props.history.push("/releasestock")
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true}  />
                <ProductionPlanningHeader isRejectedItems={true} />

                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Rejected Item<span className="text-uppercase">{this.state.number}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                            {/* <div className="add_btnSave" onClick={()=>this.deleteEntry()}>Delete</div> */}
                            <div className="clearfix"></div>
                        </div>

                        <div className="">
                            <div className="add_partside">
                               
                                <div className="add_inner">
                                    <div className="title">Part Description</div>
                                    <div className="value"><input type="text" className="fieldheightdecr text-uppercase" /></div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">MO Number</div>
                                    <div className="value"><input type="text" className="fieldheightdecr text-uppercase" /></div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">BOM Number</div>
                                    <div className="value"><input type="text" className="fieldheightdecr text-uppercase"   /></div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Operator</div>
                                    <div className="value"><input type="text" className="fieldheightdecr text-uppercase"   /></div>
                                    <div className="clear"></div>
                                </div>         
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Work Station</div>
                                    <div className="value"><input type="text" className="fieldheightdecr text-uppercase"   /></div>
                                    <div className="clear"></div>
                                </div>   
                                <div className="add_inner">
                                    <div className="title">Note</div>
                                    <div className="value">
                                        <textarea
                                            autoComplete="off" 
                                            className="fieldheightdecr item_free_text_area"
                                            value={this.state.note}
                                            onChange={(e)=>this.setState({ note: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div> 
                            <div className="clearfix"></div>
                        </div>

                        <div className="__co__booking_item_list">
                            <div className="stock__maintain__add_booking">
                                <div className="float-left width10 text-right">
                                    <div>Parts</div>
                                </div>
                                <div className="width60 float-left">
                                    <div className="tableContent  mt-0 ml-4" style={{ overflowX : "auto" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Article Code</th>
                                                    <th>Part Description</th>
                                                    <th>Quantity</th>
                                                    <th>Stock Lot</th>
                                                    <th>Storage location</th>
                                                    <th>Operator</th>
                                                    <th>Workstation</th>
                                                    <th>Add Quantity</th>
                                                    {/* <th className="text-center"><Ai.AiOutlinePlus /></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    this.state.stockDetail.map((data, i) => (
                                                        <EditRejectList
                                                            key={"edit_booking_stock"+i}
                                                            uom={this.state.uom}
                                                            data={data}
                                                            releaseStock={this.releaseStock}
                                                        />
                                                    ))
                                                }
                                                
                                                {/* <tr>
                                                    <td>LO00004</td>
                                                    <td>Received</td>
                                                    <td>7/10/2021</td>
                                                    <td>General</td>
                                                    <td>29.50rs</td>
                                                    <td>20 pcs</td>
                                                    <td>10 pcs</td>
                                                    <td className="text-center" onClick={()=>this.props.history.push("/releasestock")}><Ti.TiArrowBack /></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="clear"></div>

                            </div>
                            
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                            {/* <div className="add_btnSave" onClick={()=>this.deleteEntry()}>Delete</div> */}
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default RejectedItemsEdit;