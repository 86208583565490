import React from 'react';
import { Link } from 'react-router-dom';

const pages = [
    {
        "name": "Purchase orders",
        "to": "/purchaseorders",
        "class": "innerHeaderList",
        "isPurchaseOrders": true,
        "hide": false
    },{
        "name": "Vendors",
        "to": "/vendors",
        "class": "innerHeaderList",
        "isVendors": true,
        "hide": false
    },{
        "name": "Invoices",
        "to": "/poinvoices",
        "class": "innerHeaderList",
        "isInvoices": true,
        "hide": false
    },{
        "name": "Quality Check",
        "to": "/poqc",
        "class": "innerHeaderList",
        "isInvoices": true,
        "hide": false
    },{
        "name": "Debit Notes",
        "to": "/podebitnote",
        "class": "innerHeaderList",
        "isInvoices": true,
        "hide": false
    },{
        "name": "Forecasting",
        "to": "/forecasting",
        "class": "innerHeaderList",
        "isForecasting": true,
        "hide": true
    },{
        "name": "Critical on-hand",
        "to": "/procriticalonhand",
        "class": "innerHeaderList",
        "isCriticalOnHand": true,
        "hide": true
    },{
        "name": "Requirements",
        "to": "/requirements",
        "class": "innerHeaderList",
        "isRequirements": true,
        "hide": true
    },{
        "name": "Statistics",
        "to": "/prostatistics",
        "class": "innerHeaderList",
        "isStatistics": true,
        "hide": true
    }
]



export default class ProcurementHeader extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            tabs: [],
            displayTabs: []
        }
    }

    componentDidMount(){
        this.checkPermissions()
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if(localPermissions !== null){
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if(permissions !== undefined && permissions.length > 0){
                let updatedRecord = pages.map(obj => {
                    let permit = permissions.find(lo => lo.name === obj.name)
                    if(permit !== undefined)
                        return obj
                });
                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            }else{
                this.setState({
                    tabs: pages
                },()=>{
                    this.setDisplayTabs()
                })
            }
        }
    }

    setDisplayTabs = () => {
        let {
            isPurchaseOrders,
            isVendors,
            isInvoices,
            isForecasting,
            isCriticalOnHand,
            isRequirements,
            isStatistics,
            isQualityCheck,
            isDebitNote
        } = this.props;

        let dispTabs = []

        this.state.tabs.map((data, i) => {
            if(data !== undefined){

                let isActive = ""

                if(data.name === "Purchase orders" && isPurchaseOrders === true)
                    isActive = "active"

                if(data.name === "Vendors" && isVendors === true)
                    isActive = "active"

                if(data.name === "Invoices" && isInvoices === true)
                    isActive = "active"

                if(data.name === "Forecasting" && isForecasting === true)
                    isActive = "active"

                if(data.name === "Critical on-hand" && isCriticalOnHand === true)
                    isActive = "active"

                if(data.name === "Requirements" && isRequirements === true)
                    isActive = "active"

                if(data.name === "Quality Check" && isQualityCheck === true)
                    isActive = "active"

                if(data.name === "Debit Notes" && isDebitNote === true)
                    isActive = "active"

                dispTabs.push(
                    <Link 
                        key={data.name} 
                        to={data.to} 
                        className={data.hide ? "d-none" : data.class+" "+isActive}
                    >
                        {data.name} 
                    </Link>
                )
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }

    render() {
        return(
            <div className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                            <div className="innerHeader">
                                { this.state.displayTabs }
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
