import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Loading from '../../../components/Loading';

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'


class ModifyOperation extends Component {

    constructor(props){
        super(props)
          
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

           
        }
    }

    componentDidMount(){
       
    }

    goBack = () => {
        this.props.history.goBack()
    }
    
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isManufacturingOrders={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Modify operation data</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveGroup()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Operation</div>
                                    <div className="value">
                                        <textarea></textarea>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Time payment</div>
                                    <div className="__formTextVal">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem" 
                                            checked={this.state.checkHourlyRate} 
                                            onChange={()=>
                                                this.setState({ 
                                                    checkHourlyRate: !this.state.checkHourlyRate,
                                                    hourlyrate: "",
                                                })
                                            } 
                                        /> 
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                {/* <div className="row">
                                    <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                        <div className="title"><b>Workstations:</b></div>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-4 col-xs-12">
                                        <div className="head-import-buttons float-right mt-0">
                                            <div className="button">Add a workstation</div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div> */}

                                
                                <div className="add_inner mt-1 mb-1">
                                    <div className="title text-left"><b>Workstations:</b></div>
                                    <div className="value text-right">
                                        <div className='_add_button__modify__operation'>Add a workstation</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className='inner__sub__fields__modify__operation'>
                                <div className="add_inner">
                                    <div className="title">Workstation *</div>
                                    <div className="value">
                                        <select className="text-capitalize" value={this.state.product} onChange={(e)=>this.setState({ product: e.target.value },()=>{ this.getBOMbyItem(e.target.value)})}>
                                            <option value=''>Select</option>
                                            
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Productivity</div>
                                    <div className="value">
                                        <input type="text" value={this.state.quantity} onChange={(e)=>this.setState({ quantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} className="fieldheightdecr" autoComplete="off" name="name"  style={{width: "40%"}} /> {this.state.uom}
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Planned start</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={this.state.dueDate}
                                            onChange={date => this.setState({ dueDate: date })}
                                            className="filterdateoption"
                                            readOnly
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Planned finish</div>
                                    <div className="value">
                                        <DatePicker 
                                            timeInputLabel="Time:"
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            showTimeInput
                                            minDate={new Date()}
                                            selected={this.state.startDate}
                                            onChange={date => this.setState({ startDate: date },()=>{ 
                                                this.getEndDate() 
                                            })}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Manufacturing overhead</div>
                                    <div className="value">
                                        <input type="text" value={this.state.quantity} onChange={(e)=>this.setState({ quantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} className="fieldheightdecr" autoComplete="off" name="name"  style={{width: "40%"}} /> {this.state.uom}
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner mt-1 mb-1">
                                    <div className="title text-left"><b>Workers:</b></div>
                                    <div className="value text-right">
                                        <div className='_add_button__modify__operation'>Add worker</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className='inner__sub__fields__modify__operation'>
                                    <div className="add_inner">
                                        <div className="title">Worker *</div>
                                        <div className="value">
                                            <select className="text-capitalize" value={this.state.product} onChange={(e)=>this.setState({ product: e.target.value },()=>{ this.getBOMbyItem(e.target.value)})}>
                                                <option value=''>Select</option>
                                                
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">Labor cost</div>
                                        <div className="value">
                                            <input type="text" value={this.state.quantity} onChange={(e)=>this.setState({ quantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} className="fieldheightdecr" autoComplete="off" name="name"  style={{width: "40%"}} /> {this.state.uom}
                                        </div>
                                        <div className="clear"></div>
                                </div>
                                </div>
                                
                                </div>

                            </div>
                            <div className="clear"></div>
                        </div>
                            
                            
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveGroup()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default ModifyOperation;