import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditCurrency extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            currencyName: "",
            conversionRate: "",
            currencySymbol: "",

            _id: this.props.location.state.currencyId,
            isNewEntry: this.props.location.state.newCurrencyEntry,
            fieldNotEditable: true,

        }
    }

    componentDidMount(){
        this.getSingleCurrency()
    }
    
    
    getSingleCurrency = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleCurrency(this.state._id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                currencyName: response.currencyName,
                conversionRate: response.conversionRate,
                currencySymbol: response.currencySymbol,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }
    

    updateCurrency = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                _id,
                currencyName,
                conversionRate,
                currencySymbol,
            } = this.state

            if(currencyName === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter currency"
                })
            }else if(conversionRate === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter conversion rate"
                })
            }else if(currencySymbol === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter currency symbol"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateCurrency(_id,currencyName, conversionRate, currencySymbol)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Currency Updated Successfully."
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        errormessage: errors 
                    })
                });
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteCurrency(this.state._id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    checkFloatingConversionRate = (value) => {
        if(!isNaN(value) || value === ""){
            this.setState({
                conversionRate: value
            })
        }
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Edit Currency Conversion</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">


                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateCurrency()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Currency </div>
                                <div className="value"><input type="text" style={{ width: 250 }} className="fieldheightdecr" value={this.state.currencyName} onChange={(e)=>this.setState({ currencyName: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Conversion Rate</div>
                                <div className="value">
                                    <div className="float-left createdText border-0">1 <span style={{ minWidth: 100 }} className="display-block">{this.state.currencyName}</span>&nbsp;=&nbsp;</div>
                                    <input 
                                        type="text" 
                                        style={{width: 50}} 
                                        className="float-left" 
                                        value={this.state.conversionRate}
                                        onChange={(e)=>this.checkFloatingConversionRate(e.target.value)}
                                    />
                                    <div className="createdText float-left border-0">&nbsp;INR</div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Currency Symbol</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        style={{width: 250}}
                                        value={this.state.currencySymbol}
                                        onChange={(e)=>this.setState({ currencySymbol: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateCurrency()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}