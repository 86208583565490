import React from 'react';
import { Header, StockHeader } from './../../../components'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as APIManager from './../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Fi from "react-icons/fi";
import * as Fa from "react-icons/fa";
import { Link } from 'react-router-dom';


import { AiOutlineExclamation, AiOutlineCalendar } from "react-icons/ai";
import { convertDateStringFormatToMMDDYYObject, convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

export default class EditLot extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            fieldNotEditable: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            productGroupList: [],
            productList: [],
            lotNotes: [],

            status: "",
            productGroup: "",
            product: "",
            quantity: "",
            quantityInSL: "",
            available: "",
            costperunit: "",
            totalcost: "",
            availablefrom: "",
            created: "",
            productGroupName: "",
            productName: "",
            sourceCreated: "",
            sourceId: "",
            sourceNumber: "",
            storageLocation: [],

            stockId: "",
            stockNumber: "",

            showStorageLocation: false,

        }
    }

    componentDidMount(){
        let id = localStorage.getItem("stockLotId")
        let number = localStorage.getItem("stockLotNumber")

        this.setState({
            stockId: id,
            stockNumber: number,    
        },()=>{
            this.getSingleLot()
        })
    }


    getSingleLot = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleStockLot(this.state.stockId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                status: response.status,
                productGroup: response.productGroup,
                product: response.stockItem,
                quantity: response.totalQuantity,
                available: response.available,
                costperunit: response.costPerUnit,
                totalcost: response.totalCost,
                availablefrom: response.availableFrom !== "" ? new Date(response.availableFrom) : '',
                created: response.created,
                sourceCreated: response.source.created,
                sourceId: response.source.id,
                sourceNumber: response.source.number !== "" ? response.source.number.toUpperCase() : "",
                storageLocation: response.storageLocation,
                lotNotes: response.notes,
            },()=>{
                this.getSingleProductGroup()
                this.getSingleProduct()
                this.countStorageLocationQuanitity(response.storageLocation)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }
   
    countStorageLocationQuanitity = (sl) => {
        let totalCount = 0;
        for(let i=0;i<sl.length;i++){
            totalCount += parseFloat(sl[i].quantity)
        }
        if(totalCount > 0)
            this.setState({ showStorageLocation: true })
        else
            this.setState({ showStorageLocation: false })

    }

    getSingleProduct = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase() +" "+response.itemData.partDescription
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }

    getSingleProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleProductGroup(this.state.productGroup)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupName: response.number.toUpperCase() +" "+response.name
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

  
    getSingleStorageLocationData = (id) => {
        let name = ""
        APIManager.callSingleStorageLocation(id)
        .then(response => {
            name = response.name
        })
        return name;
    }

    updateStockLot = () => {
        if(this.state.fieldNotEditable !== true){

        let {
            stockId,
            status,
            productGroup,
            product,
            quantity,
            quantityInSL,
            available,
            costperunit,
            totalcost,
            availablefrom,
            storageLocation
        } = this.state;

        if(status === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select status"
            })
        }else if(product === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select stock item"
            })
        }else if(quantity === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter total qauntity"
            })
        }else if(costperunit === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter cost per unit"
            })
        }else if(availablefrom === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select available date"
            })
        }else if(parseInt(quantityInSL) > parseInt(quantity)){
            this.setState({
                success: false,
                error: true,
                errormessage: "No of quantity in storage location must be equal to total qauntity."
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateStockLot(stockId, status, productGroup, product, quantity, available, costperunit, totalcost, JSON.stringify(storageLocation), convertDateToMMDDYYYY(availablefrom))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    success: true,
                    successmessage: "Stock lot updated successfully",
                    fieldNotEditable: true,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }else{
        let slug = "Stock_StockLots"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({ fieldNotEditable: false })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
        
    }
    }


    editLotNote = (id,note) => {
        localStorage.setItem("stockLotNoteId",id)
        localStorage.setItem("stockLotNote", note)
        this.props.history.push('/editslnote')
    }


    countTotalCost = () => {
        let {
            costperunit,
            quantity
        } = this.state;

        if(costperunit !== "" && quantity !== ""){
            let cost = parseInt(costperunit) * parseInt(quantity)
            this.setState({
                totalcost: cost
            })
        }
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createStockLot") !== null){
            localStorage.removeItem("createStockLot")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    MoveProductLocation = (id, qty) => {
        localStorage.setItem("stockStorageId", id)
        localStorage.setItem("stockStorageQty", qty)
        this.props.history.push("/movestock")
    }

    editPurchaseOrder = () => {
        let {
            sourceId,
            sourceNumber
        } = this.state

        localStorage.setItem("purchase_order_id", sourceId)
        localStorage.setItem("purchase_order_number", sourceNumber)
        
        this.props.history.push({
            pathname: "/editpo",
            state: { purchase_order_id: sourceId, purchase_order_number: sourceNumber }
        })
    }



    editManufacturingOrder = () => {
        
        this.props.history.push({
            pathname: "/editmo",
            state: { MO_Id: this.state.sourceId, newBOMEntry: false },
        })
    
    }

    editItem = () => {
        localStorage.setItem("itemId",this.state.product)
        this.props.history.push('/edititem')
    }


    deleteEntry = () => {
        let slug = "Stock_StockLots"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){   
            var r = window.confirm("Are you sure you want to delete this lot?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteStockLot(this.state.stockId)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isStockLots={true} />
               
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Lot {this.state.stockNumber}</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateStockLot()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Number *</div>
                                <div className="value">
                                    <input 
                                        value={this.state.stockNumber}
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Status *</div>
                                <div className="value">
                                    <select name="status" value={this.state.status} onChange={(e)=>this.setState({ status: e.target.value })}>
                                        <option value="">Select</option>
                                        <option value="requested">Requested</option>
                                        <option value="planned">Planned</option>
                                        <option value="received">Received</option>
                                        <option value="cancelled">Cancelled</option>
                                    </select>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Product group</div>
                                <div className="value">
                                    <div className="createdText text-capitalize">{this.state.productGroupName}</div>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Stock item *</div>
                                <div className="value">
                                    <div className="createdText text-capitalize" onClick={()=>this.editItem()}><span className="clickable__source">{(this.state.productName).substr(0, 40)}...</span></div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            
           

                            <div className="add_inner">
                                <div className="title">Total quantity *</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.quantity}
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Available</div>
                                <div className="value">
                                    <input 
                                        value={this.state.available}
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Cost per unit *</div>
                                <div className="value">
                                    <input 
                                        readOnly
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.costperunit}
                                        onChange={
                                            (e)=>this.setState({ costperunit: parseInt(e.target.value) ? parseInt(e.target.value) : "" },()=>{
                                                this.countTotalCost()
                                            })
                                        }
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Total cost *</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete="off" 
                                        name="name" 
                                        style={{width: "40%"}} 
                                        value={this.state.totalcost}
                                        readOnly
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Source</div>
                                <div className="value">
                                    {
                                        this.state.sourceCreated.toLowerCase() === "purchase order" ?
                                            <div className="createdText text-capitalize" onClick={()=>this.editPurchaseOrder()}><span className="clickable__source">{this.state.sourceCreated} {this.state.sourceNumber}</span></div>
                                        :
                                            <div className="createdText text-capitalize" onClick={()=>this.editManufacturingOrder()}><span className="clickable__source">{this.state.sourceCreated} {this.state.sourceNumber}</span></div>

                                    }
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Created</div>
                                <div className="value">
                                    <div className="createdText">{convertDateStringMMDDtoDDMM(this.state.created)}</div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Available from</div>
                                <div className="value">
                                    <DatePicker 
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => this.setState({ availablefrom: date })}
                                        className="fieldheightdecr"
                                        selected={this.state.availablefrom}
                                        minDate={new Date()}
                                    />
                                    <AiOutlineCalendar className="datepickerIcon"/>
                                </div>
                                <div className="clear"></div>
                            </div>
                            {
                                this.state.showStorageLocation &&
                                <div className="add_inner contactinfo">
                                    <div>
                                        <div className="addNewCustomerContactTitle">
                                            <div className="title2">Storage location</div>
                                        </div>
                                        <div className="addNewCustomerContactField">
                                            <div className="mt-1 mb-1">
                                                <div className="float-left width40">Storage location</div>
                                                <div className="float-left width50">Quantity</div>
                                                <div className="clear"></div>
                                            </div>
                                            {
                                                this.state.storageLocation.map((data, i) => {
                                                    if(parseFloat(data.quantity).toFixed(2) > 0){
                                                        return (<div className="extrafieldforContact" key={data.id}>
                                                            <div className="float-left width40 p-1 text-capitalize">{data.storageLocationName}</div>
                                                            <div className="float-left width50 p-1">{parseFloat(data.quantity).toFixed(2)}</div>
                                                            <div className="float-right width10 p-1 text-right cursor-pointer" onClick={()=>this.MoveProductLocation(data.id, data.quantity)}><Ai.AiOutlineRight className="datepickerIcon" /></div>
                                                            <div className="clear"></div>
                                                        </div>)
                                                    }
                                                })
                                            }
                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            }
                        </div> 
                        <div className="clearfix"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateStockLot()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Notes</div>
                        </div>
                    </div>
                    
                    <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader"><span>Created</span>  <Fa.FaAngleUp /></th>
                                    <th className="tableHeader"><span>Modified</span></th>
                                    <th className="tableHeader"><span>Note</span></th>
                                    <th className="tableHeader text-right"><Link to="/createslnote"><Fi.FiPlus /></Link></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.lotNotes !== null &&
                                    this.state.lotNotes.map((data, i) => (
                                        <tr key={data.id} onClick={this.editLotNote.bind(this,data.id,data.note)}>
                                            <td>
                                                <b>{data.creatorName}</b> <br />
                                                <span>{data.created}</span>
                                            </td>
                                            <td>
                                                <b>{data.modifierName}</b> <br />
                                                <span>{data.modified}</span>
                                            </td>
                                            <td className="preline">{data.note}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

