import React from 'react';
import { Header, CRMHeader } from './../../../components'
import Loading from './../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import ContactComponent from './../../../components/AutoGenerateComponent/addContactInformation'
import { validateNumber } from './../../../constants/Validate';
import * as LS from './../../../constants/SetLocalstorage'
import * as Fi from 'react-icons/fi'
import { convertDateToDDMMYYYY } from '../../../constants/DateFormatter';

export default class CreateCustomerCompany extends React.Component {

    constructor(props) {
        super(props);
        let today = new Date();

        this.state = {
            isLoading: false,

            date: today,
            email: "",
            error: false,
            errormessage: "",

            accountManagerExtraField: [],
            singleAccountManagerVal: "",            
            currencyData: [],
            currency: LS.getLocalFormCustomer("__LocalData_create__customer", "currency") ? LS.getLocalFormCustomer("__LocalData_create__customer", "currency") : [],
            accountManagerCounter: 0,
            accountManagerList: [],
            selectedAccountManager: LS.getLocalFormCustomer("__LocalData_create__customer", "selectedAccountManager") ? LS.getLocalFormCustomer("__LocalData_create__customer", "selectedAccountManager") : [],
            selectedAccountManagerId: [],

            countContactFieldNumber: [0],
            contactFieldCounter: 0,
            contactExtraFields: [],
            contactInformation: LS.getLocalFormCustomer("__LocalData_create__customer", "contactInformation") ? LS.getLocalFormCustomer("__LocalData_create__customer", "contactInformation") : [],
            generateNewContact: true,

            contactStarted: LS.getLocalFormCustomer("__LocalData_create__customer", "contactStarted") ? new Date(LS.getLocalFormCustomer("__LocalData_create__customer", "contactStarted")) : new Date(),
            nextContact: LS.getLocalFormCustomer("__LocalData_create__customer", "nextContact") ? new Date(LS.getLocalFormCustomer("__LocalData_create__customer", "nextContact")) : "",

            creditLimitVal: LS.getLocalFormCustomer("__LocalData_create__customer", "creditLimitVal"),
            cmpDeliveryTerms: LS.getLocalFormCustomer("__LocalData_create__customer", "cmpDeliveryTerms"),
            gstNumber: LS.getLocalFormCustomer("__LocalData_create__customer", "gstNumber"),
            cmpPanNo: LS.getLocalFormCustomer("__LocalData_create__customer", "cmpPanNo"),
            cmpStatus: LS.getLocalFormCustomer("__LocalData_create__customer", "cmpStatus"),
            cmpNickName: LS.getLocalFormCustomer("__LocalData_create__customer", "cmpNickName"),
            cmpName: LS.getLocalFormCustomer("__LocalData_create__customer", "cmpName"),
            paymentValue: "",
            paymentDaysFrom: LS.getLocalFormCustomer("__LocalData_create__customer", "paymentDaysFrom"),
            paymentStatus: LS.getLocalFormCustomer("__LocalData_create__customer", "paymentStatus"),

            paymentTerm: {
                "paymentValue": "",
                "daysFrom": LS.getLocalFormCustomer("__LocalData_create__customer", "paymentDaysFrom"),
                "paymentStatus": LS.getLocalFormCustomer("__LocalData_create__customer", "paymentStatus")
            },

            taxApplicable: LS.getLocalFormCustomer("__LocalData_create__customer", "taxApplicable") ? LS.getLocalFormCustomer("__LocalData_create__customer", "taxApplicable") : [],
            companyStatusData: [],
            companyIndustryData: [],
            industrytype: LS.getLocalFormCustomer("__LocalData_create__customer", "industrytype") ? LS.getLocalFormCustomer("__LocalData_create__customer", "industrytype") : [],
            userDetails: [],

            sgstcheck: false,
            cgstcheck: false,
            igstcheck: false,
            exemptedtax: false,
            notax: false,
           
        }
       
        this.contactInfoFieldRemove = this.contactInfoFieldRemove.bind(this)
        this.contactInfoInputBlur = this.contactInfoInputBlur.bind(this)
    }


    componentDidMount() {

        let user = localStorage.getItem("userdata")
        let userDetails = JSON.parse(user)
        this.setState({
            userDetails: userDetails,
            email: userDetails.email
        })
        this.getUserList();

        this.getCompanyIndustry()
        this.getCustomerStatus()
        this.getAllCurrency()

        if (this.state.taxApplicable !== undefined) {
            this.setTaxApplicable(this.state.taxApplicable)
        }

        if (this.state.contactInformation) {
            let data = [];
            this.state.contactInformation.forEach((val) => {
                if (val !== null) {
                    data.push(val);
                }
            });
            this.setContactInfo(data)
        } else {
            this.setFirstContactField()
        }

        
    }

    getAllCurrency = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCurrency()
            .then(response => {
                this.setState({
                    isLoading: false,
                    currencyData: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    setContactInfo = (data) => {
        let arr_fieldNumber = []
        let arr_counter = data.length
        let arr_extraFields = []

        for (let i = 0; i < data.length; i++) {

            arr_fieldNumber.push(i)
            arr_extraFields.push(
                <ContactComponent
                    key={i}
                    componentNum={i}
                    value={data[i].value}
                    type={data[i].type}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            )
        }

        this.setState({
            countContactFieldNumber: arr_fieldNumber,
            contactFieldCounter: arr_counter,
            contactExtraFields: arr_extraFields,
            contactInformation: data
        }, () => {
            LS.setLocalFormCustomer("__LocalData_create__customer", "contactInformation", this.state.contactInformation)
            this.setNewBlankField(data)
        })
    }

    setNewBlankField = (data) => {

        this.setState({
            contactInformation: data,
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent
                    key={data.length + 1}
                    componentNum={data.length + 1}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ]),
            contactFieldCounter: this.state.contactFieldCounter + 1,
            generateNewContact: true,
        }, () => {
            this.setState({
                countContactFieldNumber: this.state.countContactFieldNumber.concat([
                    this.state.contactFieldCounter
                ]),
            })
        })
    }

    updatePaymentTermObject = (type) => {
        let paymentTerm = this.state.paymentTerm

        if (type === 'paymentValue')
            paymentTerm.paymentValue = this.state.paymentValue;

        if (type === 'paymentDaysFrom')
            paymentTerm.daysFrom = this.state.paymentDaysFrom;

        if (type === 'paymentStatus')
            paymentTerm.paymentStatus = this.state.paymentStatus;

        this.setState({
            paymentTerm
        })
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllAccountManagers()
            .then(response => {
                this.setState({
                    isLoading: false,
                    accountManagerList: response.data.length > 0 ? response.data : []
                }, () => {
                    if (this.state.selectedAccountManager !== "") {
                        this.setAccountManager(this.state.selectedAccountManager)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyIndustryData: response.companyIndustry ? response.companyIndustry : []
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    getCustomerStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerStatus()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyStatusData: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    setAccountManager = (data) => {

        let arr__selectedAccountManager = []
        let arr__selectedAccountManagerId = []
        let arr__accountManagerExtraField = []
        let arr__counter = 0;

        for (let i = 0; i < data.length; i++) {
            let getName = "";
            let value = data[i].id;

            getName = this.state.accountManagerList.filter(function (el) {
                if (el._id === value) {
                    return el
                }
            });

            let name = getName[0].username ? getName[0].username : getName[0].full_name

            let accManObj = {
                id: data[i].id,
                permission: data[i].permission
            }

            arr__counter = i;

            arr__selectedAccountManager.push(accManObj)
            arr__selectedAccountManagerId.push(value)
            arr__accountManagerExtraField.push(
                <div className="extrafieldforContact"
                    key={
                        "amkey" + arr__counter
                    }>
                    <div className="float-left width40 accountManagerNm">
                        {name}
                    </div>
                    <div className="float-left width50 fixedHeight30">
                        <div className="checkboxInputs">
                            <input type="checkbox"
                                style={
                                    { width: 14 }
                                }
                                onChange={
                                    (e) => {
                                        this.updateManagerPermission("read", value, e)
                                    }
                                }
                                defaultChecked={data[i].permission.read}
                            /></div>
                        <div className="checkboxInputs"><input type="checkbox"
                            style={
                                { width: 14 }
                            }
                            onChange={
                                (e) => {
                                    this.updateManagerPermission("create", value, e)
                                }
                            }
                            defaultChecked={data[i].permission.create}
                        /></div>
                        <div className="checkboxInputs"><input type="checkbox"
                            style={
                                { width: 14 }
                            }
                            onChange={
                                (e) => {
                                    this.updateManagerPermission("update", value, e)
                                }
                            }
                            defaultChecked={data[i].permission.update}
                        /></div>
                        <div className="checkboxInputs"><input type="checkbox"
                            style={
                                { width: 14 }
                            }
                            onChange={
                                (e) => {
                                    this.updateManagerPermission("delete", value, e)
                                }
                            }
                            defaultChecked={data[i].permission.delete}
                        /></div>
                    </div>
                    <div className="float-left width10 removeButton">
                        <Fi.FiX className="extraFieldCloseButton" onClick={
                            () => this.removeAccountMangerField(accManObj)
                        } />
                    </div>
                    <div className="clear"></div>
                </div>
            )
        }

        this.setState({
            selectedAccountManager: arr__selectedAccountManager,
            selectedAccountManagerId: arr__selectedAccountManagerId,
            accountManagerExtraField: arr__accountManagerExtraField,
            accountManagerCounter: arr__counter
        })
    }




    goBack = () => {
        LS.removeLS("__LocalData_create__customer")
        this.props.history.goBack();
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    setFirstContactField = () => {
        this.setState({
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent
                    key={0}
                    componentNum={0}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ])
        })
    }

    contactInfoInputVal = (val, number) => {


        let that = this;
        let valLength = val.trim().replaceAll("_", "").trim();

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]


        if (typeof CIVal[index] === 'undefined' && this.state.generateNewContact === true && valLength.length > 0) {
            this.setState({
                contactFieldCounter: this.state.contactFieldCounter + 1,
                generateNewContact: false,
            }, () => {
                this.setState({
                    countContactFieldNumber: this.state.countContactFieldNumber.concat(
                        [this.state.contactFieldCounter]
                    ),
                    contactExtraFields: this.state.contactExtraFields.concat(
                        [
                            <ContactComponent
                                key={
                                    this.state.contactFieldCounter
                                }
                                value={""}
                                type={"Phone"}
                                fun_checkInputBlur={
                                    this.contactInfoInputBlur
                                }
                                componentNum={
                                    this.state.contactFieldCounter
                                }
                                fun_checkContactInfoInput={
                                    that.contactInfoInputVal
                                }
                                fun_removeContactInfoField={
                                    this.contactInfoFieldRemove
                                }
                            />
                        ]
                    ),
                })
            })
        }
    }


    contactInfoInputBlur = (val, type, number) => {

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]

        if (typeof CIVal[index] !== 'undefined') {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                let contactInformation = [...this.state.contactInformation];
                contactInformation[index] = obj;

                this.setState({
                    contactInformation,
                    generateNewContact: true,
                }, () => {
                    LS.setLocalFormCustomer("__LocalData_create__customer", "contactInformation", this.state.contactInformation)
                })
            }
        } else {
            if (val !== "") {
                let obj = {
                    "type": type === "" ? "Phone" : type,
                    "value": val
                }
                this.setState({
                    contactInformation: this.state.contactInformation.concat([obj]),
                    generateNewContact: true,
                }, () => {
                    LS.setLocalFormCustomer("__LocalData_create__customer", "contactInformation", this.state.contactInformation)
                })
            }
        }
    }

    contactInfoFieldRemove = (number) => {
        let array = [...this.state.countContactFieldNumber];
        let fields = [...this.state.contactExtraFields];
        let data = [...this.state.contactInformation]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            // fields.splice(index, 1)
            this.setState({
                countContactFieldNumber: array,
                contactExtraFields: fields,
                contactInformation: data
            }, () => {
                LS.setLocalFormCustomer("__LocalData_create__customer", "contactInformation", this.state.contactInformation)
            })
        }
    }




    updateManagerPermission = (perm, id, check) => {
        let user = [...this.state.selectedAccountManager]
        let obj = this.search(id, user);
        let permIndex = "";

        permIndex = obj.permission;
        permIndex[perm] = check;


        let accManObj = {
            id: obj.id,
            permission: permIndex
        }

        let index = user.indexOf(obj)

        user[index] = accManObj;

        this.setState({
            selectedAccountManager: user
        }, () => {
            LS.setLocalFormCustomer("__LocalData_create__customer", "selectedAccountManager", this.state.selectedAccountManager)
        })
    }

    search(nameKey, myArray) {
        for (let i = 0; i < myArray.length; i++) {
            if (myArray[i].id === nameKey) {
                return myArray[i];
            }
        }
    }

    addNewAccountMangerField = (event) => {
        let eventIndex = event.target.selectedIndex
        let name = event.target[eventIndex].innerText;
        let value = this.state.singleAccountManagerVal;

        let vl = [...this.state.selectedAccountManager];

        let obj = this.search(value, vl);

        if (typeof obj === 'undefined') {

            let accManObj = {
                "id": value,
                "permission": {
                    read: false,
                    create: false,
                    update: false,
                    delete: false
                }
            }

            this.setState({
                selectedAccountManager: this.state.selectedAccountManager.concat([accManObj]),
                selectedAccountManagerId: this.state.selectedAccountManagerId.concat([value]),
                accountManagerExtraField: this.state.accountManagerExtraField.concat(
                    [
                        <div className="extrafieldforContact"
                            key={
                                "amkey" + this.state.accountManagerCounter
                            }>
                            <div className="float-left width40 accountManagerNm">
                                {name}
                            </div>
                            <div className="float-left width50 fixedHeight30">
                                <div className="checkboxInputs">
                                    <input type="checkbox"
                                        style={
                                            { width: 14 }
                                        }
                                        onChange={
                                            (e) => {
                                                this.updateManagerPermission("read", value, e.target.checked)
                                            }
                                        }
                                    /></div>
                                <div className="checkboxInputs"><input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("create", value, e.target.checked)
                                        }
                                    } /></div>
                                <div className="checkboxInputs"><input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("update", value, e.target.checked)
                                        }
                                    } /></div>
                                <div className="checkboxInputs"><input type="checkbox"
                                    style={
                                        { width: 14 }
                                    }
                                    onChange={
                                        (e) => {
                                            this.updateManagerPermission("delete", value, e.target.checked)
                                        }
                                    } /></div>
                            </div>
                            <div className="float-left width10 removeButton">
                                <Fi.FiX className="extraFieldCloseButton" onClick={
                                    () => this.removeAccountMangerField(accManObj)
                                } />
                            </div>
                            <div className="clear"></div>
                        </div>
                    ]
                )
            }, () => {
                //                LS.setLocalFormCustomer("__LocalData_create__customer", "selectedAccountManager",this.state.selectedAccountManager)

                this.setState({
                    accountManagerCounter: this.state.accountManagerCounter + 1
                }, () => {
                    this.setState({ singleAccountManagerVal: "" })
                })
            })
        } else {
            this.setState({ singleAccountManagerVal: "" })
        }
    }
    removeAccountMangerField = (val) => {
        let id = [...this.state.selectedAccountManagerId];
        let vl = [...this.state.selectedAccountManager];
        let fields = [...this.state.accountManagerExtraField];

        let index = id.indexOf(val.id)
        if (index !== -1) {
            vl.splice(index, 1)
            fields.splice(index, 1)
            id.splice(index, 1)
            this.setState({
                selectedAccountManager: vl,
                accountManagerExtraField: fields,
                selectedAccountManagerId: id,
            })
        }
    }


    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    saveCompany = () => {
        let {
            cmpName,
            cmpNickName,
            cmpStatus,
            cmpPanNo,
            gstNumber,
            contactStarted,
            nextContact,
            paymentTerm,
            cmpDeliveryTerms,
            creditLimitVal,
            contactInformation,
            taxApplicable,
            selectedAccountManager,
            industrytype,
            userDetails,
            currency
        } = this.state

        let paymentTermsId = ""
        let deliveryTermsId = ""
        let accountManager = []



        if (cmpName === "") {
            this.setState({
                error: true,
                errormessage: "Please enter a valid company name (max. 100 characters)"
            }, () => {
                this.scrollToTop()
            })
        } else if (cmpNickName === "") {
            this.setState({
                error: true,
                errormessage: "Please enter a valid nickname (max. 50 characters)"
            }, () => {
                this.scrollToTop()
            })
        } else if (industrytype.length === 0) {
            this.setState({
                error: true,
                errormessage: "Please select industry type"
            }, () => {
                this.scrollToTop()
            })
        } else if (contactStarted === "") {
            this.setState({
                error: true,
                errormessage: "Please enter contact started date"
            }, () => {
                this.scrollToTop()
            })
        } else if (accountManager === "") {
            this.setState({
                error: true,
                errormessage: "Please select an account manager"
            }, () => {
                this.scrollToTop()
            })
        } else if (taxApplicable.length === 0) {
            this.setState({
                error: true,
                errormessage: "Please select applicable tax"
            }, () => {
                this.scrollToTop()
            })
        } else {
            if (userDetails.role === "subUser") {
                accountManager.push({
                    "id": userDetails._id,
                    "permission": {
                        "read": true, "create": true, "update": true, "delete": false
                    }
                })
            } else {
                accountManager = selectedAccountManager;
            }

            let uniqueTaxArray = taxApplicable.filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            })

            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCompany(cmpName, cmpNickName, cmpStatus, cmpPanNo, gstNumber, contactInformation, convertDateToDDMMYYYY(contactStarted), convertDateToDDMMYYYY(nextContact), paymentTermsId, paymentTerm, deliveryTermsId, cmpDeliveryTerms, creditLimitVal, uniqueTaxArray, accountManager, industrytype, currency)
                .then(response => {
                    this.setState({
                        isLoading: false
                    }, () => {
                        LS.removeLS("__LocalData_create__customer")   
                        this.props.history.push({
                            pathname: "/editcustomercompany",
                            state: { cmpId: response.data._id, cmpIdNum: response.data.companyId, cmpName: response.data.companyName, createNewEdit: true }
                        })
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }


    updateTaxApplicable = (name, check) => {
        let taxApplicable = [...this.state.taxApplicable]

        if (check === true) {
            if (name === "notax") {
                for (let i = 0; i < taxApplicable.length; i++) {
                    document.getElementById(taxApplicable[i]).checked = false;
                }
                let allTax = [name]
                this.setState({
                    taxApplicable: allTax,
                }, () => {

                    LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                })
            } else {
                if (name === "exemptedtax") {
                    for (let i = 0; i < taxApplicable.length; i++) {
                        document.getElementById(taxApplicable[i]).checked = false;
                    }
                    document.getElementById("IGST").checked = true;
                    this.setState({
                        taxApplicable: ['IGST', 'exemptedtax'],
                        currency: ""
                    }, () => {

                        LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                        LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                    })

                } else {
                    if (taxApplicable.length === 2) {
                        document.getElementById(taxApplicable[0]).checked = false;
                        document.getElementById(taxApplicable[1]).checked = false;
                        let allTax = [name]
                        this.setState({
                            taxApplicable: allTax,
                            currency: ""
                        }, () => {

                            LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                            LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                        })
                    } else {
                        if (taxApplicable[0] === 'notax') {
                            document.getElementById(taxApplicable[0]).checked = false;
                            let allTax = [name]
                            this.setState({
                                taxApplicable: allTax,
                                currency: ""
                            }, () => {

                                LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                                LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                            })
                        } else {
                            if (taxApplicable[0] !== "CGST" && taxApplicable[0] !== "SGST") {
                                if (name === "CGST" || name === "SGST") {
                                    for (let i = 0; i < taxApplicable.length; i++) {
                                        document.getElementById(taxApplicable[i]).checked = false;
                                    }
                                    let allTax = [name]
                                    this.setState({
                                        taxApplicable: allTax,
                                        currency: ""
                                    }, () => {

                                        LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                                        LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                                    })
                                } else {
                                    this.setState({
                                        taxApplicable: this.state.taxApplicable.concat([
                                            name
                                        ]),
                                        currency: ""
                                    }, () => {

                                        LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                                        LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                                    })
                                }
                            } else {
                                this.setState({
                                    taxApplicable: this.state.taxApplicable.concat([
                                        name
                                    ]),
                                    currency: ""
                                }, () => {

                                    LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                                    LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                                })
                            }

                        }

                    }
                }

            }
        } else {
            if (name === "IGST") {
                for (let i = 0; i < taxApplicable.length; i++) {
                    document.getElementById(taxApplicable[i]).checked = false;
                }
                this.setState({
                    taxApplicable: [],
                    currency: ""
                }, () => {

                    LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                    LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                })
            } else {
                let index = taxApplicable.indexOf(name)
                if (index > -1) {
                    taxApplicable.splice(index, 1);
                    this.setState({
                        taxApplicable,
                        currency: ""
                    }, () => {

                        LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
                        LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
                    })
                }
            }
        }
    }

    getGSTDetails = () => {
        let { gstNumber } = this.state;

        if (gstNumber && gstNumber.length >= 15) {
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(this.state.gstNumber)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        countContactFieldNumber: [0],
                        contactFieldCounter: 0,
                        contactExtraFields: [],
                        contactInformation: [],
                        generateNewContact: true,
                        cmpName: response.data.tradename ? response.data.tradename : response.data.legalname
                    }, () => {
                        LS.setLocalFormCustomer("__LocalData_create__customer", "gstNumber", this.state.gstNumber)
                        LS.setLocalFormCustomer("__LocalData_create__customer", "cmpName", this.state.cmpName)

                        let obj = [{
                            'type': 'FreeTextAddress',
                            'value': response.data.address ? response.data.address : ''
                        }]
                        this.setContactInfo(obj)
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        success: false,
                        countContactFieldNumber: [0],
                        contactFieldCounter: 0,
                        contactExtraFields: [],
                        contactInformation: [],
                        generateNewContact: true,
                        cmpName: "",
                        errormessage: "Please enter valid GST Number"
                    }, () => {
                        this.setFirstContactField();
                    })
                });
        } else {
            this.setState({
                isLoading: false,
                error: false,
                success: false,
                countContactFieldNumber: [0],
                contactFieldCounter: 0,
                contactExtraFields: [],
                contactInformation: [],
                generateNewContact: true,
                cmpName: "",
            }, () => {
                this.setFirstContactField();
            })
        }
    }


    updateCurrencyData = (value) => {
        let taxApplicable = [...this.state.taxApplicable]
        for (let i = 0; i < taxApplicable.length; i++) {
            document.getElementById(taxApplicable[i]).checked = false;
        }
        this.setState({
            currency: value,
            taxApplicable: ["notax"]
        }, () => {
            document.getElementById("notax").checked = true
            LS.setLocalFormCustomer("__LocalData_create__customer", "currency", this.state.currency)
            LS.setLocalFormCustomer("__LocalData_create__customer", "taxApplicable", this.state.taxApplicable)
        })
    }

    setTaxApplicable = (data) => {
        document.getElementById("SGST").checked = data.includes("SGST");
        document.getElementById("CGST").checked = data.includes("CGST");
        document.getElementById("IGST").checked = data.includes("IGST");
        document.getElementById("notax").checked = data.includes("notax");
        document.getElementById("exemptedtax").checked = data.includes("exemptedtax");
    }


    addNewStatus = () => {
        this.props.history.push('/createcustomerstatus')
    }

    addNewIndustryType = () => {
        this.props.history.push('/editIndustryType')
    }


    

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a customer company</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">

                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }

                    </div>





                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveCompany()}>Save</div>
                            <div className="add_btnBack" onClick={() => this.goBack()}>Cancel</div>
                            <div className="clear"></div>
                        </div>
                        <div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">GST No.</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr text-uppercase"
                                            autoComplete="off"
                                            value={this.state.gstNumber}
                                            onChange={(e) => this.setState({ gstNumber: e.target.value }, () => { this.getGSTDetails() })}
                                        />
                                        <div className='gstdetail_search_icon' onClick={() => this.getGSTDetails()}><Ai.AiOutlineSearch /></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Name*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            value={this.state.cmpName}
                                            onChange={(e) => this.setState({ cmpName: e.target.value }, () => { LS.setLocalFormCustomer("__LocalData_create__customer", "cmpName", this.state.cmpName) })}
                                            name="name"
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Nickname*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr"
                                            autoComplete="off"
                                            value={this.state.cmpNickName}
                                            onChange={(e) => this.setState({ cmpNickName: e.target.value }, () => { LS.setLocalFormCustomer("__LocalData_create__customer", "cmpNickName", this.state.cmpNickName) })}
                                            name="name"
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Company Status</div>
                                    <div className="value">
                                        <select
                                            value={this.state.cmpStatus}
                                            name="status"
                                            onChange={(e) => {
                                                if (e.target.value === 'addNew')
                                                    this.addNewStatus()
                                                else
                                                    this.setState({ cmpStatus: e.target.value }, () => { LS.setLocalFormCustomer("__LocalData_create__customer", "cmpStatus", this.state.cmpStatus) })
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic" }}>Add new status</option>

                                            {
                                                this.state.companyStatusData !== null &&
                                                this.state.companyStatusData.map((data, i) => (
                                                    <option value={data._id} key={i + data.name}>{data.value}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">PAN No.</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            className="fieldheightdecr text-uppercase"
                                            autoComplete="off"
                                            value={this.state.cmpPanNo}
                                            onChange={(e) => this.setState({ cmpPanNo: e.target.value }, () => { LS.setLocalFormCustomer("__LocalData_create__customer", "cmpPanNo", this.state.cmpPanNo) })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Industry type</div>
                                    <div className="value" style={{ height: 150 }}>
                                        <select
                                            value={this.state.industrytype}
                                            style={{ height: 150 }}
                                            onChange={(e) => {
                                                if (e.target.value === 'addNew') {
                                                    this.addNewIndustryType()
                                                } else {
                                                    let value = Array.from(e.target.selectedOptions, option => option.value)
                                                    this.setState({ industrytype: value }, () => {
                                                        LS.setLocalFormCustomer("__LocalData_create__customer", "industrytype", this.state.industrytype)
                                                    })
                                                }
                                            }}
                                            multiple
                                        >
                                            <option value="addNew" style={{ fontWeight: 'bold', fontStyle: "italic", cursor: 'pointer' }}>Add new Industry Type</option>
                                            {
                                                this.state.companyIndustryData !== null &&
                                                this.state.companyIndustryData.map((data, i) => (
                                                    <option value={data.industrytype} key={i + data.industrytype}>{data.industrytype}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner contactinfo">


                                    {/* Contact Information fields */}

                                    <div>
                                        <div className="addNewCustomerContactTitle">
                                            <div className="title2">Contact information</div>
                                        </div>
                                        <div className="addNewCustomerContactField">
                                            <div className="mt-1 mb-1">
                                                <div className="float-left width40">Type</div>
                                                <div className="float-left width60">Value</div>
                                                <div className="clear"></div>
                                            </div>


                                            {
                                                this.state.contactExtraFields
                                            }


                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Contact started</div>
                                    <div className="value">

                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.contactStarted
                                            }
                                            onChange={
                                                date => {
                                                    this.setState({
                                                        contactStarted: date
                                                    }, () => {
                                                        LS.setLocalFormCustomer("__LocalData_create__customer", "contactStarted", this.state.contactStarted)
                                                    })
                                                }
                                            }
                                            className="fieldheightdecr" />
                                        <Ai.AiOutlineCalendar className="datepickerIcon" />

                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Next contact</div>
                                    <div className="value">

                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.contactStarted}
                                            selected={
                                                this.state.nextContact
                                            }
                                            onChange={
                                                date => {
                                                    this.setState({
                                                        nextContact: date
                                                    }, () => {
                                                        LS.setLocalFormCustomer("__LocalData_create__customer", "nextContact", this.state.nextContact)
                                                    })
                                                }
                                            }
                                            className="fieldheightdecr" />
                                        <Ai.AiOutlineCalendar className="datepickerIcon" />

                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Payment terms</div>
                                    <div className="value">
                                        <div>
                                            {/* <input 
                                            type="text" 
                                            className="float-left width10"
                                            value={this.state.paymentValue}
                                            onChange={(e)=>{
                                                this.setState({ paymentValue: e.target.value },()=>{
                                                    this.updatePaymentTermObject('paymentValue')
                                                })
                                            }} 
                                        />*/}
                                            <div className="float-left createdText border-0">Payment from</div>
                                            <input
                                                type="text"
                                                className="float-left width10"
                                                value={this.state.paymentDaysFrom}
                                                onChange={(e) => {
                                                    this.setState({ paymentDaysFrom: e.target.value >= 0 ? e.target.value : 0 }, () => {
                                                        LS.setLocalFormCustomer("__LocalData_create__customer", "paymentDaysFrom", this.state.paymentDaysFrom)
                                                        this.updatePaymentTermObject('paymentDaysFrom')
                                                    })
                                                }}
                                            />
                                            <div className="float-left createdText text-center border-0">days of</div>
                                            <div className="float-left">
                                                <select
                                                    value={this.state.paymentStatus}
                                                    onChange={(e) => {
                                                        if (e.target.value !== "") {
                                                            this.setState({ paymentStatus: e.target.value }, () => {
                                                                LS.setLocalFormCustomer("__LocalData_create__customer", "paymentStatus", this.state.paymentStatus)
                                                                this.updatePaymentTermObject('paymentStatus')
                                                            })
                                                        }
                                                    }}>
                                                    <option value="">Select</option>
                                                    <option value="from_invoice">Invoice</option>
                                                    <option value="from_confirmation">Confirmation</option>
                                                    <option value="from_arrival">Arrival</option>
                                                </select>
                                            </div>

                                            <div className="clear"></div>
                                        </div>

                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Delivery terms</div>
                                    <div className="value">
                                        <select
                                            value={this.state.cmpDeliveryTerms}
                                            onChange={(e) => this.setState({ cmpDeliveryTerms: e.target.value }, () => { LS.setLocalFormCustomer("__LocalData_create__customer", "cmpDeliveryTerms", this.state.cmpDeliveryTerms) })}
                                            name="deliveryterms">
                                            <option value="">Select</option>
                                            <option value="ex-works">Ex-works</option>
                                            <option value="c and f">C and F</option>
                                            <option value="cif">CIF</option>
                                        </select>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Currency</div>
                                    <div className='value'>
                                        <Select2
                                            data={
                                                this.state.currencyData.map((data) => (
                                                    { id: data._id, text: data.currencyName }
                                                ))
                                            }
                                            value={this.state.currency}
                                            options={{ placeholder: 'Select Currency' }}
                                            onSelect={(e) => this.updateCurrencyData(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Credit limit</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value)) {
                                                    this.setState({
                                                        creditLimitVal: e.target.value
                                                    }, () => {
                                                        LS.setLocalFormCustomer("__LocalData_create__customer", "creditLimitVal", this.state.creditLimitVal)
                                                    })
                                                }
                                            }}
                                            value={this.state.creditLimitVal}
                                            className="fieldheightdecr"
                                            name="creditlimit"
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner contactinfo">

                                    {/* Tax Applicable fields */}
                                    <div>
                                        <div className="addNewCustomerContactTitle">
                                            <div className="title2">Tax applicable*</div>
                                        </div>
                                        <div className="addNewCustomerContactField">
                                            <div className="mt-1 mb-1 mr-1">
                                                <div className="float-left width40">Type</div>
                                                <div className="float-left width40 text-center">Applicable</div>
                                                <div className="float-left width20 text-center"></div>

                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    CGST
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="CGST"
                                                            onChange={(e) => this.updateTaxApplicable("CGST", e.target.checked)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    SGST
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="SGST"
                                                            onChange={(e) => this.updateTaxApplicable("SGST", e.target.checked)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    IGST
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="IGST"
                                                            onChange={(e) => { this.updateTaxApplicable("IGST", e.target.checked) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="float-left width20 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <div className='float-left mt-1'>Exempted</div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                style={{ width: 14 }}
                                                                id="exemptedtax"
                                                                onChange={(e) => { this.updateTaxApplicable("exemptedtax", e.target.checked) }}
                                                            />
                                                        </div>
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>

                                            <div className="extrafieldforContact">
                                                <div className="float-left width40 taxApplicableTitle">
                                                    No Tax
                                                </div>
                                                <div className="float-left width40 taxApplicableInput">
                                                    <div className="checkboxInputs">
                                                        <input
                                                            type="checkbox"
                                                            style={{ width: 14 }}
                                                            id="notax"
                                                            onChange={(e) => { this.updateTaxApplicable("notax", e.target.checked) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>


                                            <div className="clear"></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>

                                </div>

                                <div className="add_inner contactinfo">


                                    {/* Account Manager fields */}
                                    {
                                        this.state.userDetails.role !== "subUser" &&
                                        <div>
                                            <div className="addNewCustomerContactTitle">
                                                <div className="title2 accManagerTitle">Account Manager</div>
                                            </div>
                                            <div className="addNewCustomerContactField">
                                                <div className="mt-1 mb-1 mr-1">
                                                    <div className="float-left width40">
                                                        {/* <select 
                                                            ref={(c) => this.accountmanager = c}
                                                            name="accountmanager"
                                                            className="text-capitalize"
                                                            onChange={(e) => this.addNewAccountMangerField(e, this.accountmanager)}>
                                                            <option value="">Select</option>
                                                            {
                                                                this.state.accountManagerList.map((data) => (
                                                                    <option value={data._id} key={data._id}>
                                                                    {
                                                                        data.username ? data.username : data.full_name
                                                                    }
                                                                    </option>
                                                                ))
                                                            } 
                                                        </select> */}

                                                        <Select2
                                                            data={
                                                                this.state.accountManagerList.map((data) => (
                                                                    { id: data._id, text:  data.username ? data.username : data.full_name }
                                                                ))
                                                            }
                                                            value={this.state.singleAccountManagerVal}
                                                            options={{ placeholder: 'Select Account Manager' }}
                                                            onSelect={(e) => {
                                                                this.setState({
                                                                    singleAccountManagerVal: e.target.value
                                                                },()=>{
                                                                    this.addNewAccountMangerField(e)
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="float-left width50">
                                                        <div className="userPermissionChecks">Read</div>
                                                        <div className="userPermissionChecks">Create</div>
                                                        <div className="userPermissionChecks">Update</div>
                                                        <div className="userPermissionChecks">Delete</div>
                                                    </div>
                                                    <div className="clear"></div>
                                                </div>
                                                {
                                                    this.state.accountManagerExtraField
                                                }
                                                <div className="clear"></div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveCompany()}>Save</div>
                            <div className="add_btnBack" onClick={() => this.goBack()}>Cancel</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                    {
                        this.state.isLoading &&
                        <Loading />
                    }

                   
                </div>
            </div>
        )
    }
}
