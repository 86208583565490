import React, { Component } from 'react';
import * as APIManager from './../../../APIManager/APIManager'
import { Header, StockHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import * as Fi from 'react-icons/fi'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import Loading from '../../../components/Loading';
import { convertDateToMMDDYYYY } from '../../../constants/DateFormatter';

class EditShipment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",


            isNewEntry: this.props.location.state.newShipmentEntry,
            shipmentId: this.props.location.state.shipmentId,


            customerOrderList: [],
            shippingaddress: [],
            shippingGoodsData: [],

            customerId: "",
            customerOrderNumber: "",
            customerName: "",

            number: "",
            created: "",
            deliverydate: "",
            customerOrder: "",
            status: "",
            bookedItems: [],
            countBookingItems: 0,
            deliveryAddress: "",
            wayBillNote: "",
            wayBillDocument: "",
            fileDocument: "",
            pickingListNote: "",
            trackingNumber: "",
            invoiceNumber: "",
            invoiceId: "",

            fileDisplayName: "",
            isUpdateFile: false,


            fieldNotEditable: true,
        }
    }

    componentDidMount() {
        this.getCustomerOrders()
        this.getSingleShipmentDetails()

    }




    getSingleShipmentDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleShipment(this.state.shipmentId)
            .then(response => {
                // let countBookingItems = 0;
                // let bookedItems = response.data.bookedItems
                // for (let i = 0; i < bookedItems.length; i++) {
                //     countBookingItems += bookedItems[i].remaintoShip
                // }
                //bookedItems: bookedItems,
                //countBookingItems: countBookingItems,

                this.setState({
                    isLoading: false,
                    number: response.data.number.toUpperCase(),
                    deliverydate: response.data.deliveryDate ? new Date(response.data.deliveryDate) : "",
                    created: response.data.created,
                    status: response.data.status,
                    invoiceId: response.data.invoiceId,
                    deliveryAddress: response.data.shippingAddress,
                    wayBillNote: response.data.waybillNotes,
                    wayBillDocument : response.data.wayBillDocument,
                    fileDocument: response.data.wayBillDocument,
                    pickingListNote: response.data.pickingListNotes,
                    trackingNumber: response.data.trackingNumber,
                    customerOrder: response.data.customerOrderId,
                }, () => {
                    if(this.state.wayBillDocument){
                        this.setDisplayName(this.state.wayBillDocument)
                    }
                    this.getSingleCustomerOrder(this.state.customerOrder)
                    this.getSingleInvoice(response.data.invoiceId)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    getSingleInvoice = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOSingleInvoice(id)
        .then(response => {
            this.setState({ 
                invoiceNumber: response.data.number,
                isLoading: false,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });  
    }
    setDisplayName = (selectedFile) => {
        if(selectedFile){
            let splitData = selectedFile.split("__")
            let splitData_last_index = splitData[1]
            let finalName = "";
            if(splitData_last_index){
                finalName = splitData_last_index.split("_").join(" ")
            }else{
                finalName = selectedFile.substring(selectedFile.lastIndexOf('/') + 1).split("_").join(" ")
            }
            this.setState({
                fileDisplayName: finalName
            })
        }
    }

    viewUpdateFileOption = () => {
        this.setState({
            isUpdateFile: !this.state.isUpdateFile
        })
    }


    viewSelectedFile = () => {
        let file = this.state.wayBillDocument
        window.open(file)
    }


    getSingleCustomerOrder = (orderId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerOrderDetail(orderId)
            .then(response => {
                this.setState({
                    isLoading: false,
                    customerId: response.customer,
                    customerOrderNumber: response.number.toUpperCase()
                }, () => {
                    this.getCustomerShippingAddress(response.customer)
                    this.getSingleCustomer(response.customer)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    getSingleCustomer = (customer) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(customer)
            .then(response => {

                this.setState({
                    isLoading: false,
                    customerName: response.companyData.companyName
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    getCustomerShippingAddress = (customer) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                let addresses = []
                for (let i = 0; i < response.length; i++) {
                    addresses.push(response[i].split('_').join(' '))
                }
                this.setState({
                    shippingaddress: addresses,
                    isLoading: false,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    getCustomerOrders = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerOrderListExceptQuotation("", "", "", "", "", "", "", "ready for shipment", "", "", "", "", "", "", "", "", "")
            .then(response => {
                this.setState({
                    customerOrderList: response.data,
                    isLoading: false,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    saveShipment = () => {
        if (this.state.fieldNotEditable) {
            let slug = "Stock_Shipments"
            let permission = "update"
            if (APIManager.checkUserPermission(slug, permission)) {
                this.setState({
                    fieldNotEditable: false
                })
            } else {
                alert("You don't have permission to " + permission + " this record")
            }

        } else {
            let {
                shipmentId,
                invoiceId,
                deliverydate,
                customerOrder,
                deliveryAddress,
                status,
                wayBillNote,
                wayBillDocument,
                pickingListNote,
                trackingNumber
            } = this.state

            if(status === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select shipment status"
                },()=>{
                    this.scrollToTop()
                })
            } else if (deliverydate === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select delivery date"
                },()=>{
                    this.scrollToTop()
                })
            } else if (customerOrder === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select order"
                },()=>{
                    this.scrollToTop()
                })
            } else if (deliveryAddress === "") {
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select customer's delivery address"
                },()=>{
                    this.scrollToTop()
                })
            } else {
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateShipment(shipmentId, convertDateToMMDDYYYY(deliverydate), deliveryAddress, wayBillNote,wayBillDocument, pickingListNote, trackingNumber, invoiceId, status)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        isUpdateFile: false,
                        fieldNotEditable: true,
                    },()=>{
                        this.getSingleShipmentDetails()
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        errormessage: errors.message
                    })
                });
            }
        }
    }


    pickAllItem = () => {

        var r = window.confirm("Please confirm picking");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callPickAllShipmentItem(this.state.shipmentId)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        fieldNotEditable: true,
                    }, () => {
                        this.getSingleShipmentDetails()
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
        }
    }

    deleteEntry = () => {
        let slug = "Stock_Shipments"
        let permission = "delete"
        if (APIManager.checkUserPermission(slug, permission)) {
            var r = window.confirm("Are you sure you want to delete this lot?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteShipment(this.state.shipmentId)
                    .then(response => {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            this.goBack()
                        })
                    })
                    .catch(errors => {
                        this.setState({
                            isLoading: false,
                            error: true,
                            errormessage: errors
                        })
                    });
            }
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    goBack = () => {
        if (this.state.isNewEntry) {
            this.props.history.go(-2)
        } else {
            this.props.history.goBack()
        }
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isShipments={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Shipment {this.state.number} details</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveShipment()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={() => this.deleteEntry()}>Delete</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className='position-relative'>
                            <div className='add_partside'>
                                <div className="add_inner">
                                    <div className="title">Number</div>
                                    <div className="value text-capitalize">
                                        <input type="text" value={this.state.number} readOnly />
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer order *</div>
                                    <div className="value">
                                        <div className='createdText text-capitalize'>{this.state.customerOrderNumber} {this.state.customerName}</div>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Number *</div>
                                    <div className="value">
                                        <div className='createdText text-capitalize'>{this.state.invoiceNumber}</div>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">Created</div>
                                    <div className='value'>
                                        <div className='createdText'>{this.state.created}</div>
                                    </div>
                                    <div className='clear'></div>
                                </div> */}
                                
                                <div className="add_inner">
                                    <div className="title">Status *</div>
                                    <div className="value">
                                        <select value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                            <option value="">Select</option>
                                            <option value="new">New</option>
                                            <option value="ready for shipment">Ready for shipment</option>
                                            <option value="shipped">Shipped</option>
                                        </select>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                
                            </div>
                            <div className='clear'></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        {/* <div className='position-relative'>
                            <div className='add_partside width100'>
                                <div className="add_inner">
                                    <div className="title shipment__booked_item__title">Booked goods</div>
                                    <div className="value">
                                        <div className="tableContent" style={{ overflowX: "auto" }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Stock item</th>
                                                        <th>Lot</th>
                                                        <th>Status</th>
                                                        <th>Storage location</th>
                                                        <th>Picked quantity</th>
                                                        <th>Remain to ship</th>
                                                        <th>Booked total</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.bookedItems.map((data, i) => (
                                                            <tr key={"shipmentData" + i}>
                                                                <td style={{ width: 200 }}>{data.stockName}</td>
                                                                <td>{data.lot}</td>
                                                                <td>{data.status}</td>
                                                                <td>{data.storageLocationName}</td>
                                                                <td>{data.pickedQuanity}</td>
                                                                <td>{data.remaintoShip}</td>
                                                                <td>{data.bookedTotal}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {
                                        this.state.countBookingItems !== 0 ?
                                            <div className='float-right width13'>
                                                <div className='head-import-buttons m-0'>
                                                    <div className="button co__footer__button" onClick={() => this.pickAllItem()}>Pick all items</div>
                                                    <div className='clear'></div>
                                                </div>
                                            </div>
                                            :
                                            <div className='float-right width13'>
                                                <div className='head-import-buttons m-0'>
                                                    <div className="button co__footer__button disabled">Pick all items</div>
                                                    <div className='clear'></div>
                                                </div>
                                            </div>
                                    }

                                    <div className='clear'></div>
                                </div>
                            </div>
                            <div className='clear'></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div> */}
                        <div className='position-relative'>
                            <div className='add_partside'>
                                <div className="add_inner">
                                    <div className="title">Delivery address</div>
                                    <div className="value">
                                        <select value={this.state.deliveryAddress} onChange={(e) => this.setState({ deliveryAddress: e.target.value })}>
                                            <option value="">Select</option>
                                            {
                                                this.state.shippingaddress.map((data, i) => (
                                                    <option value={data} key={"shipping" + i}>{data}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery date</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={
                                                this.state.deliverydate
                                            }
                                            onChange={
                                                date => this.setState({ deliverydate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className='clear'></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Waybill notes</div>
                                    <textarea value={this.state.wayBillNote} onChange={(e) => this.setState({ wayBillNote: e.target.value })} className="value __customer_order_note_field"></textarea>
                                    <div className='clear'></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Waybill Document </div>
                                    {
                                        this.state.fileDocument === "" &&
                                        <div className="value"><input type="file" className="fieldheightdecr input-file" name="file" accept=".pdf,.doc" onChange={(e) => this.setState({wayBillDocument: e.target.files[0]})} ></input></div>
                                    }
                                    {
                                        (this.state.fileDocument && !this.state.isUpdateFile) &&
                                            <div className='value'>
                                                <div onClick={()=>this.viewSelectedFile()} className='createdText clr-secondary cursor-pointer'>{this.state.fileDisplayName ? this.state.fileDisplayName : 'File Preview'}</div>
                                                <div onClick={()=>this.viewUpdateFileOption()} className='doc-saved-file add_btnBack change-button'>Change File</div>
                                                <div className='clear'></div>
                                            </div>

                                    }
                                    {
                                        this.state.isUpdateFile &&
                                            <div className="value">
                                                <div><input type="file" className="fieldheightdecr input-file" name="file" accept=".pdf,.doc" onChange={(e) => this.setState({ wayBillDocument: e.target.files[0] })} ></input></div>
                                                {
                                                    this.state.fileDocument &&
                                                    <div className='change-button pt-2' onClick={()=>this.viewUpdateFileOption()}><Fi.FiX /></div>
                                                }
                                                <div className='clear'></div>
                                            </div>
                                    }    
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Picking list notes</div>
                                    <textarea value={this.state.pickingListNote} onChange={(e) => this.setState({ pickingListNote: e.target.value })} className="value __customer_order_note_field"></textarea>
                                    <div className='clear'></div>
                                </div>
                            </div>
                            <div className='clear'></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.saveShipment()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={() => this.deleteEntry()}>Delete</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default EditShipment;


