import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../../components'
import * as Ai from "react-icons/ai"
import * as APIManager from './../../../../APIManager/APIManager'
import * as Im from "react-icons/im"
import Loading from '../../../../components/Loading';
import GroupItem from './GroupItem';
import Popup from 'reactjs-popup';

import { ExportToCsv } from 'export-to-csv';

class EditBOM extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            productId: "",
            productName: "",
            number: "",
            name: "",
            routings: [],
            routingsName: "",

            BOMId: this.props.location.state.BOMId,

            isNewEntry: this.props.location.state.newBOMEntry,

            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: [],
            productGroupList: [],
            
            bomExcelDetails: [],
            excelExportFields: ['cadNo','articleCode','partDescription','quantity','uom','costPerUnit','sellingPrice','notes'],


            isProdGroupChecked: false,
            isCadNoChecked: true,
            isArticleCodeChecked: true,
            isPartDescChecked: true,
            isQuantityChecked: true,
            isUoMChecked: true,
            isPricePerUoMChecked: true,
            isSellingPriceChecked: true,
            isNotesChecked: true,

            dragging: "",
            draggedOver: "",

            fieldNotEditable: true,

        }
    }

    componentDidMount(){
        this.getProductGroup()
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callProductGroupList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response.productGroup,
            },()=>{
                this.getSingleBOM()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getSingleBOM = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleBOM(this.state.BOMId)
        .then(response => {
            let routing = []
            if(typeof response.routingId !== "string"){
                routing = response.routingId
            }

            this.setState({ 
                isLoading: false,
                productId: response.product,
                number: response.number,
                name: response.name,
                routings: routing,
                productGroupData: response.parts,
            },()=>{
                this.getItemDetail(this.state.productId)
                this.getBOMExcelDetails()
                this.setProductGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getBOMExcelDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetBOMExcelDetail(this.state.BOMId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                bomExcelDetails: response
            },()=>{
                console.log("bomExcelDetails ",this.state.bomExcelDetails)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({
                isLoading: false,
                productName: response.itemData.itemCode.toUpperCase()+ " "+ response.itemData.partDescription
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setProductGroupData = () => {
        let prodGrp = this.state.productGroupData
        let groupLength = prodGrp.length;
        
        if(groupLength > 0){

            let groupField = [];
            let groupKey = [];

            for(let i=0;i<prodGrp.length;i++){
               
                if(prodGrp[i] !== null){
                    groupField.push(
                        <GroupItem
                            draggable={true}
                            drag={this.setDragging}
                            dragover={this.setDraggedOver}
                            drop={this.compare}
                            key={'proGroup'+i}
                            onChangeProduct={this.addNewGroupFields}
                            onBlurProduct={this.addGroupFieldValues}
                            onRemoveProduct={this.removeGroupProduct}
                            counter={i}
                            groupData={prodGrp[i]}
                            productGroupList={this.state.productGroupList}
                        />
                    )
                    groupKey.push(i)
                }
            }

            this.setState({
                productGroupFields: groupField,
                productGroupKeys: groupKey,
                productGroupCounter: groupLength
            },()=>{
                this.setNewBlankField(this.state.productGroupCounter)
            })
        }else{
            this.setGroupItem()
        }
    }

    setNewBlankField = (counter) => {
        let dummyObj =  {
            'productGroup': '',
            'part': '',
            'notes': '',
            'quantity': '',
            'cadNo': ''
        }

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <GroupItem
                    draggable={true}
                    drag={this.setDragging}
                    dragover={this.setDraggedOver}
                    drop={this.compare}
                    key={'proGroup'+counter}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={counter}
                    groupData={dummyObj}
                    productGroupList={this.state.productGroupList}
                />
            ]),            
        })
    }

    setGroupItem = () => {
        let dummyObj =  {
            'productGroup': '',
            'part': '',
            'notes': '',
            'quantity': '',
            'cadNo': ''
        }

        this.setState({
            productGroupFields: this.state.productGroupFields.concat([
                <GroupItem
                    draggable={true}
                    drag={this.setDragging}
                    dragover={this.setDraggedOver}
                    drop={this.compare}
                    key={'proGroup'}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    counter={this.state.productGroupCounter}
                    groupData={dummyObj}
                    productGroupList={this.state.productGroupList}
                />
            ]),            
        })
    }

    addNewGroupFields = (counter) => {
        let dummyObj =  {
            'productGroup': '',
            'part': '',
            'notes': '',
            'quantity': '',
            'cadNo': ''
        }

        let array = [...this.state.productGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                productGroupFields: this.state.productGroupFields.concat([
                    <GroupItem
                        draggable={true}
                        drag={this.setDragging}
                        dragover={this.setDraggedOver}
                        drop={this.compare}
                        key={'proGroup'+counter+1} 
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        counter={counter+1}
                        groupData={dummyObj}
                        productGroupList={this.state.productGroupList}
                    />
                ]),
                productGroupKeys: this.state.productGroupKeys.concat([this.state.productGroupCounter]),
                productGroupCounter: counter+1,
            })
        }
    }
    addGroupFieldValues = (value, counter) => {
        let productGroupData = [...this.state.productGroupData]    
        productGroupData[counter] = value;  
        this.setState({
            productGroupData
        })
    }
    removeGroupProduct = (counter) => {
        let productGroupData = [...this.state.productGroupData]    
        let productGroupFields = [...this.state.productGroupFields]
        productGroupData[counter] = null;
        productGroupFields[counter] = null;
        
        this.setState({
            productGroupData,
            productGroupFields
        })
    }

    setDragging = (index) => {
        this.setState({
            dragging: index
        })
    }

    setDraggedOver = (index) => {
        this.setState({
            draggedOver: index
        })
    }

    compare = () => {
        let prodGrp = [...this.state.productGroupData]

        let index1 = prodGrp.indexOf(this.state.dragging);
        let index2 = prodGrp.indexOf(this.state.draggedOver);
        prodGrp.splice(index1, 1)
        prodGrp.splice(index2, 0, this.state.dragging)

        this.setState({ 
            productGroupFields: [],
            productGroupKeys: [],
            productGroupCounter: 0,
            productGroupData: prodGrp
        },()=>{
            this.setProductGroupData()
        })
    };
  
  
    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            let {
                BOMId
            } = this.state;
            APIManager.callDeleteBOM(BOMId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }
     
    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  


    updateBOM = () => {

        if(this.state.fieldNotEditable !== true){

       
        let {
            BOMId,
            productId,
            routings,
            productGroupData
            
        } = this.state;

        /* Remove Null From State */
        let parts = [];
        productGroupData.forEach((val) => {
            if(val !== null){
                parts.push(val);
            }
        });
        /* Remove Null */


        if(parts.length === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please select parts for BOM"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateBOM(BOMId,productId,JSON.stringify(routings),JSON.stringify(parts))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    error: false,success: true,
                    fieldNotEditable: true,
                    successmessage: "BOM updated successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }else{
        let slug = "ProductionPlanning_BOM"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({ fieldNotEditable: false })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    }

    setBOMExportFields = (i,val) => {
        let excelExportFields = [...this.state.excelExportFields]

        const index = excelExportFields.indexOf(val);
        
        if (index > -1) { 
            excelExportFields.splice(index, 1);
        }else{
            excelExportFields.splice(i, 0, val);
        }
        this.setState({ excelExportFields })
    }

    exportDataToCSV = () => {

        let responseObj = this.state.bomExcelDetails.parts
        let excelExportFields = this.state.excelExportFields

        if(responseObj.length > 0){

            const title = `BOM ${this.state.number.toUpperCase()} for Item ${this.state.productName}`;
        
            let headers = []
            for(let i = 0;i<excelExportFields.length;i++){
                if(excelExportFields[i] === 'productGroupName')
                headers.push("Product Group")

                if(excelExportFields[i] === 'cadNo')
                headers.push("CAD No.")

                if(excelExportFields[i] === 'articleCode')
                headers.push("Article Code")

                if(excelExportFields[i] === 'partDescription')
                headers.push("Part Description")

                if(excelExportFields[i] === 'quantity')
                headers.push("Quanity")

                if(excelExportFields[i] === 'uom')
                headers.push("UoM")

                if(excelExportFields[i] === 'costPerUnit')
                headers.push("Price/UoM")

                if(excelExportFields[i] === 'sellingPrice')
                headers.push("Selling Price")

                if(excelExportFields[i] === 'notes')
                headers.push("Notes")
            }

            let data = []

            for(let i=0;i<responseObj.length;i++){
                let innerObject = []
                for(let j = 0;j<excelExportFields.length;j++){
                    let d = responseObj[i][excelExportFields[j]] ? responseObj[i][excelExportFields[j]] : ""
                    innerObject.push(d)
                }
                data.push(innerObject)
            }

            const options = { 
                filename: this.state.productName,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true, 
                showTitle: true,
                title: title,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: false,
                headers: headers,
            };
            
            const csvExporter = new ExportToCsv(options);
            
            csvExporter.generateCsv(data);
        }

    }


    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isBOM={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <div className="page-title">BOM for {this.state.number.toUpperCase()} <span className="text-capitalize">{this.state.productName.length > 50 ? this.state.productName.substring(0, 50)+'...' : this.state.productName}</span> BOM Details</div>
                            </div>

                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                {/* <div className="head-import-buttons float-right">
                                    <div className="button">Indented CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>

                                <div className="head-import-buttons float-right">
                                    <div className="button">Indented BOM <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div> */}

                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.exportDataToCSV()}>Export to CSV <Im.ImArrowDown2 /></div>
                                    <Popup arrow={false} trigger={<div className='export-list-title'>Fields to export</div>} position="bottom right">
                                        <div className="selectColumns selectBOMExport">
                                            <ul>
                                                <li><label htmlFor="productGroupName"><span>Product Group</span> <input type="checkbox" id="productGroupName" checked={this.state.isProdGroupChecked} onChange={() => { this.setState({ isProdGroupChecked: !this.state.isProdGroupChecked },()=>{this.setBOMExportFields(0,'productGroupName') }) }} /></label></li>
                                                <li><label htmlFor="cadNo"><span>CAD No</span> <input type="checkbox" id="cadNo" checked={this.state.isCadNoChecked} onChange={() => { this.setState({ isCadNoChecked: !this.state.isCadNoChecked },()=>{ this.setBOMExportFields(1,'cadNo') }) }} /></label></li>
                                                <li><label htmlFor="articleCode"><span>Article Code</span> <input type="checkbox" id="articleCode" disabled checked={this.state.isArticleCodeChecked} onChange={() => { this.setState({ isArticleCodeChecked: !this.state.isArticleCodeChecked },()=>{this.setBOMExportFields(2,'articleCode')}) }} /></label></li>
                                                <li><label htmlFor="partDescription"><span>Part Description</span> <input type="checkbox" id="partDescription" checked={this.state.isPartDescChecked} onChange={() => {  this.setState({ isPartDescChecked: !this.state.isPartDescChecked },()=>{this.setBOMExportFields(3,'partDescription')}) }} /></label></li>
                                                <li><label htmlFor="quantity"><span>Quantity</span> <input type="checkbox" id="quantity" checked={this.state.isQuantityChecked} onChange={() => { this.setState({ isQuantityChecked: !this.state.isQuantityChecked },()=>{ this.setBOMExportFields(4,'quantity') })}} /></label></li>
                                                <li><label htmlFor="uom"><span>UoM</span> <input type="checkbox" id="uom" checked={this.state.isUoMChecked} onChange={() => {  this.setState({ isUoMChecked: !this.state.isUoMChecked },()=>{this.setBOMExportFields(5,'uom') })}} /></label></li>
                                                <li><label htmlFor="costPerUnit"><span>Price/UoM</span> <input type="checkbox" id="costPerUnit" checked={this.state.isPricePerUoMChecked} onChange={() => {  this.setState({ isPricePerUoMChecked: !this.state.isPricePerUoMChecked },()=>{this.setBOMExportFields(6,'costPerUnit') })}} /></label></li>
                                                <li><label htmlFor="sellingPrice"><span>Selling Price</span> <input type="checkbox" id="sellingPrice" checked={this.state.isSellingPriceChecked} onChange={() => { this.setState({ isSellingPriceChecked: !this.state.isSellingPriceChecked },()=>{ this.setBOMExportFields(7,'sellingPrice') })}} /></label></li>
                                                <li><label htmlFor="notes"><span>Note</span> <input type="checkbox" id="notes" checked={this.state.isNotesChecked} onChange={() => {  this.setState({ isNotesChecked: !this.state.isNotesChecked },()=>{this.setBOMExportFields(8,'notes') })}} /></label></li>
                                            </ul>
                                        </div>
                                    </Popup>
                                    
                                    <div className="clear"></div>
                                </div>

                                {/* <div className="head-import-buttons float-right">
                                    <div className="button">Print BOM <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                                 */}
                            </div>
                        </div>    
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateBOM()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            {/* <div className="add_btnSave">Reports</div> */}
                            <div className="clear"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product</div>
                                    <div className="value">
                                    <div className="createdText text-capitalize"><span className="clickable__source">{this.state.productName.length > 50 ? this.state.productName.substring(0, 50)+'...' : this.state.productName}</span></div>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Number *</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-capitalize"
                                            value={this.state.number}
                                            readOnly
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Name *</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-capitalize"
                                            value={this.state.name}
                                            readOnly
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Routings</div>
                                    <div className="__formTextVal">
                                    {
                                        this.state.routings.map((data, i) => (
                                            <div className="text-capitalize" key={i+'routings'}>
                                                <input 
                                                    type="checkbox" 
                                                    className="__formCheckboxElem" 
                                                    name="connectedBOM"
                                                    defaultChecked={true}
                                                    readOnly
                                                /> <span className="clickable__source">
                                                    {data.routingName.length > 50 ? data.routingName.substring(0, 50)+'...' : data.routingName}
                                                </span>
                                            </div>
                                        ))
                                    }
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                            </div>
                            
                            <div className="clear"></div>

                            <div className="main-container mt-20">
                                <div>
                                    <div className="sub-page-title">Parts</div>
                                </div>
                                <table className="tableContent">
                                    <thead>
                                        <tr>
                                            <th className='pg-tab-head'>Product group</th>
                                            <th className='pg-tab-head'>CAD No.</th>
                                            <th className='pg-tab-head'>Article Code</th>
                                            <th className='pg-tab-head'>Part Description</th>
                                            <th className='pg-tab-head'>Quantity</th>
                                            <th className='pg-tab-head'>UoM</th>
                                            <th className='pg-tab-head'>Price/UoM</th>
                                            <th className='pg-tab-head'>Selling Price</th>
                                            <th className='pg-tab-head'>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody className='position-relative'>
                                        {
                                            this.state.productGroupFields
                                        }
                                    </tbody>
                                </table>
                            </div>
                            
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                            
                        </div>
                                    
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateBOM()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            {/* <div className="add_btnSave">Reports</div> */}
                            <div className="clear"></div>
                        </div>
                    </div>

                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
            </div>
        );
    }
}

export default EditBOM;