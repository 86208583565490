import React, { Component } from 'react';
import * as APIManager from './../../../APIManager/APIManager'
import { Header, StockHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import SetBookingItemQuantity from './SetBookingItemQuantity';
import Loading from '../../../components/Loading';

class AddBooking extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            productGroupList: [],
            productList: [],
            stockLots: [],
            listStockLots: [],

            note: "",

            uom: "",
            isProcuredItem: false,
            product: "",
            productGroup: "",
            itemNeeded: "",

            totalBookingObject: 0,
            totalBookingObjectArray: [],

            bookingObject: [],
        }
        this.updateObjectValue = this.updateObjectValue.bind(this)
        this.removeObjectValue = this.removeObjectValue.bind(this)
    }

    componentDidMount(){
        let itemId = localStorage.getItem("itemId")
        let itemGrpId = localStorage.getItem("itemGroupId")
        let itemNeeded = localStorage.getItem("itemNeeded")

        this.setState({
            product: itemId,
            productGroup: itemGrpId,
            itemNeeded: itemNeeded
        },()=>{
            this.getProductGroup()
            this.getItemsByGroup(itemGrpId)
        })
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetGroupByVendor("")
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemsByGroup = (_id) => {
        if(_id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callProductGroupItemsList(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    productList: response
                },()=>{
                    this.getLots()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    getLots = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetLotByStockItem(this.state.productGroup, this.state.product)
        .then(response => {
            this.setState({ 
                isLoading: false,
                stockLots: response
            },()=>{
                this.getItemDetail()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemDetail = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(this.state.product)
        .then(response => {
            this.setState({ 
                isProcuredItem: response.itemData.is_procured, 
                uom: response.itemData.uomName,
                isLoading: false,
            },()=>{
                this.setLotList()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setLotList = () => {
        let needed = parseInt(localStorage.getItem("itemNeeded"))
        let { stockLots } = this.state
        let stockList = []
        let bookingObject = []
        let bookingObjectArray = []
        let number = 0;
        for(let i=0;i<stockLots.length;i++){
            for(let j=0;j<stockLots[i].storageLocation.length;j++){
                let storageQty = parseInt(stockLots[i].storageLocation[j].quantity);
                if(storageQty !== 0){
                    if(needed > storageQty){
                        needed = needed - storageQty 
                        number += 1;
                        stockList.push(
                            <SetBookingItemQuantity
                                key={"stock_lots_"+i+j}
                                data={stockLots[i]}
                                uom={this.state.uom}
                                d={stockLots[i].storageLocation[j]}
                                qty={storageQty}
                                funcSetNewObject={this.updateObjectValue}
                                funcRemoveObject={this.removeObjectValue}
                                number={number}
                            />
                        )
                        if(needed !== ""){
                            bookingObject.push({
                                stockLot: stockLots[i]._id,
                                status: stockLots[i].status, 
                                date: stockLots[i].availableFrom, 
                                storageLocation: stockLots[i].storageLocation[j].storageLocationId, 
                                costPerUnit: stockLots[i].costPerUnit, 
                                quantity: storageQty,
                                booked: storageQty
                            })
                            bookingObjectArray.push(number)
                        }
                    }else{
                        number += 1;
                        stockList.push(
                            <SetBookingItemQuantity
                                key={"stock_lots_"+i+j}
                                data={stockLots[i]}
                                uom={this.state.uom}
                                d={stockLots[i].storageLocation[j]}
                                qty={needed}
                                funcSetNewObject={this.updateObjectValue}
                                funcRemoveObject={this.removeObjectValue}
                                number={number}
                            />
                        )
                        if(needed !== ""){
                            bookingObject.push({
                                stockLot: stockLots[i]._id,
                                status: stockLots[i].status, 
                                date: stockLots[i].availableFrom, 
                                storageLocation: stockLots[i].storageLocation[j].storageLocationId, 
                                costPerUnit: stockLots[i].costPerUnit, 
                                quantity: storageQty,
                                booked: needed
                            })
                            bookingObjectArray.push(number)
                        }
                        needed = "";
                    }
                }
            }
        }
        this.setState({
            listStockLots: stockList,
            bookingObject: bookingObject,
            totalBookingObjectArray: bookingObjectArray
        })
    }

    updateObjectValue = (obj, number) => {
        let array = [...this.state.totalBookingObjectArray];
        let index = array.indexOf(number)
        let val = [...this.state.bookingObject]  

        if(typeof val[index] === 'undefined'){
            this.setState({
                totalBookingObjectArray: this.state.totalBookingObjectArray.concat([ number ]),
                bookingObject: this.state.bookingObject.concat([ obj ])
            })
        }else{
            val[index] = obj;
            this.setState({
                    bookingObject: val
            })
        }
    }

    removeObjectValue = (number) => {
        let array = [...this.state.totalBookingObjectArray];
        let index = array.indexOf(number)
        let val = [...this.state.bookingObject]  
        array.splice(index, 1);
        val.splice(index, 1);
        this.setState({
            bookingObject: val,
            totalBookingObjectArray: array
        })
    }

    saveItem = () => {
        let {
            product,
            productGroup,
            note,
            bookingObject
        } = this.state;

        if(bookingObject.length === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter booking quantity"
            })
        }else{
            let getOrderType = localStorage.getItem("orderType")
            let orderId = "";
            if(getOrderType === "manufacturingOrder"){
                orderId = localStorage.getItem("manufacturingOrderId")
            }else{
                orderId = localStorage.getItem("customerOrderId")
            }
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateWriteOff(productGroup, product, JSON.stringify(bookingObject), note, "", "automatic", orderId, getOrderType)
            .then(response => {
                localStorage.setItem("createNewAutoWriteOff", true)
                localStorage.setItem("WriteOff_id", response.data._id)
                this.props.history.push('/editbooking')
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }

    }


    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isWriteOffs={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Add a booking</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="">
                            <div className="add_partside">
                               
                                <div className="add_inner">
                                    <div className="title">Product group</div>
                                    <div className="value">
                                        <select disabled className="text-capitalize" value={this.state.productGroup} onChange={(e)=>this.setState({ productGroup: e.target.value},()=>{ this.getItemsByGroup(this.state.productGroup)})}>
                                            <option value=''>Select group</option>
                                            {
                                                this.state.productGroupList.map((data) => (
                                                    <option key={"group__"+data._id} value={data._id}>{data.number.toUpperCase()} {data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Stock item *</div>
                                    <div className="value">
                                        <select disabled className="text-capitalize" value={this.state.product} onChange={(e)=>this.setState({ product: e.target.value },()=>{ this.getLots()})}>
                                            <option value=''>Select item</option>
                                            {
                                                this.state.productList.map((data) => (
                                                    <option key={'item__'+data._id} value={data._id}>{data.itemCode.toUpperCase()} {data.partDescription}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Note</div>
                                    <div className="value">
                                        <textarea
                                            autoComplete="off" 
                                            className="fieldheightdecr item_free_text_area"
                                            onChange={(e)=>this.setState({ note: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div> 
                            <div className="clearfix"></div>
                        </div>

                        <div className="mt-2">
                            <div className="stock__maintain__add_booking">
                                <div className="float-left width10 text-right">
                                    <div>Stock</div>
                                </div>
                                <div className="float-right">
                                   
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>
                                <div className="width60 ml10per mt-2 mb-2">
                                    <div className="tableContent __co__booking_item_list" style={{ overflowX : "auto" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Lot</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                    <th>Storage location</th>
                                                    <th>Cost per unit</th>
                                                    <th>Quantity</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listStockLots
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="clear"></div>

                            </div>
                            {/* <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Lot</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>Storage location</th>
                                            <th>Cost per unit</th>
                                            <th>Quantity</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>LO0001</td>
                                            <td>Received</td>
                                            <td>7/10/21</td>
                                            <td>General</td>
                                            <td>290rs</td>
                                            <td>20 pcs</td>
                                            <td><input type="text" /> pcs</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.saveItem()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default AddBooking;