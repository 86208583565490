import React from 'react';
import { Header, StockHeader } from './../../../components'

import Helper from './../../../helper/helper'

import {
    AiOutlineExclamation
} from "react-icons/ai";

import MeasurementInfo from './../../../components/AutoGenerateComponent/addMeasurementInfo'
import { WEBSERVICES_URL } from '../../../APIManager/APIManager';


export default class CreateUnitOfMeasurement extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            name: "",

            countMeasurementNumber: [0],
            addMeasurementFields: [],
            count: 0,
            measurementFieldValues: [],
            generateNewMeasurement: true,


        }
    }

    componentDidMount(){
        
    }

    createInitialField = () => {
        let field = [
            <MeasurementInfo 
                key={this.state.count}
                componentNum={this.state.count}
                name={this.state.name}
                valueOfName={""}
                valueOfRate={""}
                onBlur={this.measureInputBlur}
                onEdit={this.measureInputEdit}
                onRemove={this.measureInputRemove}
            />
        ]
        this.setState({
            addMeasurementFields: field,
            countMeasurementNumber: [0],
            count: 0,
            measurementFieldValues: [],
            generateNewMeasurement: true,
        })
    }
    
    measureInputEdit = (name, rate, num) => {

        let array = [...this.state.countMeasurementNumber];
        let index = array.indexOf(num)
        let measureVal = [...this.state.measurementFieldValues]     
        
        
        if (typeof measureVal[index] === 'undefined' && this.state.generateNewMeasurement === true && name !== "" && rate !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewMeasurement: false,
            }, () => {
                
                this.setState({
                    countMeasurementNumber: this.state.countMeasurementNumber.concat(
                        [this.state.count]
                    ),
                    addMeasurementFields: this.state.addMeasurementFields.concat(
                        [
                            <MeasurementInfo 
                                key={this.state.count}
                                componentNum={this.state.count}
                                valueOfName={""}
                                valueOfRate={""}
                                onBlur={this.measureInputBlur}
                                onEdit={this.measureInputEdit}
                                onRemove={this.measureInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    measureInputBlur = (name, rate, num) => {
      
         let array = [...this.state.countMeasurementNumber];
         let index = array.indexOf(num)
         let measureVal = [...this.state.measurementFieldValues]   
 
         if (typeof measureVal[index] !== 'undefined') {
             if (name !== "" && rate !== "") {
                 let obj = {
                     "Name": name,
                     "Rate": rate
                 }
                 let measurementFieldValues = [ ...this.state.measurementFieldValues ];
                 measurementFieldValues[index] = obj;
 
                 this.setState({
                     measurementFieldValues,
                    // generateNewMeasurement: false,
                 })
             }
         }else{
             if (name !== "" && rate !== "") {
                 let obj = {
                     "Name": name,
                     "Rate": rate
                 }
                 this.setState({
                     measurementFieldValues: this.state.measurementFieldValues.concat([ obj ]),
                     generateNewMeasurement: true,
                 })
             }
         }
     }
 

    measureInputRemove = (number) => {

        let array = [...this.state.countMeasurementNumber];
        let fields = [...this.state.addMeasurementFields];
        let data = [...this.state.measurementFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countMeasurementNumber: array,
                addMeasurementFields: fields,
                measurementFieldValues: data
            });
        }
        
    }

    createUnitMeasure = () => {

        let name = this.name.value;
        let val = this.state.measurementFieldValues;

        let filteredVal = val.filter(function (el) {
            return el != null;
        });

        let that = this;
        if(name === ""){
            this.setState({
                error: true,
                errormessage: "Please enter name",
            })
        }else if(val.filteredVal === 0){
            this.setState({
                error: true,
                errormessage: "Please add rate values",
            })
        }else{
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token
          
            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("name", name);
            formdata.append("unitConversions", JSON.stringify(filteredVal))

            fetch(WEBSERVICES_URL+'stock/stocksetting/createUnitOfMeasurement',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        localStorage.setItem("createNewMeasure", true)
                        localStorage.setItem("measurementId",result.data._id)
                        that.props.history.push('/editunitofmeasure')
                    } else if(result.status === "userDataError"){
                        that.logout()
                    } else{
                        that.setState({
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            ) 
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <StockHeader isStockSettings={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a unit of measurement</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createUnitMeasure()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        autoComplete='off'
                                        ref={(c) => this.name = c} 
                                        name="name"
                                        onChange={(e)=>this.setState({ name: e.target.value })}
                                        onBlur={(e)=>{
                                            if(e.target.value !== ""){
                                                this.createInitialField()
                                            }
                                        }}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner" style={{marginTop: 10}}>
                                <div className="addNewCustomerContactTitle">
                                    <div className="title2">Unit conversions</div>
                                </div>
                                <div className="addNewCustomerContactField">
                                    <div className="mt-1 mb-1">
                                        <div className="width40 float-left">Name</div>
                                        <div className="width60 pl-20px float-left">Rate</div>
                                        <div className="clear"></div>
                                    </div>
                                    <table>
                                        <tbody className="mt-1 mb-1">
                                        {
                                            this.state.addMeasurementFields
                                        }
                                        </tbody>
                                    </table>   
                                </div>  
                                <div className="clear"></div>                   
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createUnitMeasure()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}