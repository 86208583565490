import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../components'
import Strings from './../../constants/Strings'
import * as Fi from 'react-icons/fi'
import * as APIManager from './../../APIManager/APIManager'
import Pagination from "react-js-pagination";

import FlatList from 'flatlist-react';

const settings = [{
    text: "Support tickets", id: "Support_tickets"
}, {
    text: "Training hours", id: "Training_hours"
}, {
    text: "Implementation", id: "Implementation"
}
]


export default class HelpCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: "Support_tickets",
            ticketList: []
        };
        this.renderSettings = this.renderSettings.bind(this)
    }

    componentDidMount(){
        this.getAllSupportTickets()
    }

    getAllSupportTickets = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.getAllTickets()
        .then(response => {
            console.log(response)
            this.setState({
                isLoading: false,
                error: false,
                ticketList: response.data
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true
            })
        });
    }

    renderSettings = (settings, index) => {
        return (
            <div key={index} className={this.state.selectedIndex === index ? 'active systemopts' : 'systemopts'} onClick={() => {
                this.setState({
                    selectedIndex: index,
                });
            }}
            >
                {settings.text}
            </div>
        );
    }

    createSubUser = () => {
        this.props.history.push({
            pathname: "/createsupport"
        })
    }

    editTicket = (id) => {
        this.props.history.push({
            pathname: "/editticket",
            state: { ticketId: id, isNewEntry: false }
        })
    }


    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isHelpCenter={true} />
                
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">{Strings.HelpCenter.title}</div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent">
                        <div className="datamenus">
                            <FlatList
                                list={settings}
                                renderItem={this.renderSettings}
                                renderWhenEmpty={() => <div>List is empty!</div>}
                                keyExtractor={(item, index) => index.toString()}
                            />
                        </div>

                        {
                            this.state.selectedIndex === "Support_tickets" ?
                                <div className="dataDetails">
                                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>
                                        <table>
                                            <thead>
                                                <tr className="no-bg">
                                                    <th className="tableHeader tableHeadButton" style={{ width: 30 }}><div onClick={() => this.createSubUser()}><Fi.FiPlus /></div></th>
                                                    <th className="tableHeader" style={{ width: 70 }}>ID</th>
                                                    <th className="tableHeader" style={{ width: 250 }}>Subject</th>
                                                    <th className="tableHeader">Category</th>
                                                    <th className="tableHeader">Sub Category</th>
                                                    <th className="tableHeader">Created</th>
                                                    <th className="tableHeader">Modified</th>
                                                    <th className="tableHeader" style={{ width: 100 }}>Status</th>
                                                    <th className="tableHeader" style={{ width: 100 }}>Creator</th>
                                                    <th className="tableHeader tableHeadButton text-right"><div onClick={() => this.createSubUser()} ><Fi.FiPlus /></div></th>
                                                </tr>
                                                <tr className="no-bg">
                                                    
                                                    <th className="tableHeader tableHeadButton" style={{ width: 30 }}></th>
                                                    <th className="tableElemContain" style={{ width: 70 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th className="tableElemContain" style={{ width: 300 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th className="tableElemContain">
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>

                                                    <th className="tableElemContain">
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th className="tableElemContain" style={{ width: 100 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th className="tableElemContain" style={{ width: 100 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th className="tableElemContain" style={{ width: 100 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th className="tableElemContain" style={{ width: 100 }}>
                                                        <input
                                                            type='text'
                                                            className='input-box'
                                                        />
                                                    </th>
                                                    <th style={{ width: 40 }} className="tableElemContain tableAddButton text-right"><div onClick={''}><Fi.FiX /></div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.ticketList.map((data, i)=>(
                                                        <tr key={"ticket"+i} onClick={()=>this.editTicket(data._id)}>
                                                            <td>{i+1}</td>
                                                            <td>{data.ticketId}</td>
                                                            <td>{data.subject}</td>
                                                            <td>{data.category}</td>
                                                            <td>{data.subCategory}</td>
                                                            <td>{data.created}</td>
                                                            <td>{data.created}</td>
                                                            <td>{data.status}</td>
                                                            <td>{data.requester}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                :
                                this.state.selectedIndex === "Training_hours" ?
                                    <div className="stocksetting dataDetails">
                                        <div style={{ overflowX: "auto" }}>
                                            <h4 style={{ fontWeight: 600 }}>Training hours</h4>
                                            <div>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                                            </div>
                                        </div>
                                        <div className="clear"></div>

                                        <select className="Training_hours_Select" name="price" id="price">
                                            <option value="volvo">&#8377; 10</option>
                                            <option value="saab">&#8377; 50</option>
                                            <option value="mercedes">&#8377; 100</option>
                                            <option value="audi">&#8377; 150</option>
                                        </select>

                                    </div>
                                    :
                                    this.state.selectedIndex === "Implementation" ?
                                        <div className="stocksetting dataDetails">
                                            <div style={{ overflowX: "auto" }}>
                                                <h4 style={{ fontWeight: 600 }}>Implementation</h4>
                                            </div>
                                            <div style={{ overflowX: "auto" }}>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                                            </div>
                                        </div>
                                        :
                                    null
                        }
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}