import React from 'react';


import * as Ai from "react-icons/ai"
import * as Fi from "react-icons/fi";


export default class AddTaxDetails extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            type: this.props.type,
            rate: this.props.rate,
            applicableOn: this.props.applicableOn
        }

    }

    componentDidMount(){
       
    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
            
        } = this.props
        return(

            <tr>
                <td></td>
                <td>
                    <input 
                        type="text" 
                        className="input-box"
                        value={this.state.type}
                        onChange={(e)=>this.setState({ type: e.target.value},()=>{
                            onEdit(this.state.type, this.state.rate, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.type, this.state.rate, this.state.applicableOn, componentNum)
                        }
                    />    
                </td>
                <td>
                    <div className="position-relative">
                        <input 
                            type="text" 
                            className="input-box"
                            value={this.state.rate}
                            onChange={(e)=>this.setState({ rate: e.target.value },()=>{
                                onEdit(this.state.type, this.state.rate, componentNum)
                            })}
                            onBlur={
                                ()=>onBlur(this.state.type, this.state.rate, this.state.applicableOn, componentNum)
                            }
                        />    
                        <Ai.AiOutlinePercentage className='datepickerIcon cursor-na'/>
                    </div>
                </td>
                <td>
                    <select 
                        className="select-box"
                        value={this.state.applicableOn}
                        onChange={(e)=>this.setState({ applicableOn: e.target.value },()=>{
                            onEdit(this.state.type, this.state.rate, componentNum)
                            onBlur(this.state.type, this.state.rate, this.state.applicableOn, componentNum)
                        })}
                    >
                        <option value="tax">Tax</option>
                        <option value="rate">Rate</option>
                    </select>
                </td>
                <td>
                    {
                        this.state.type !== "" && this.state.rate !== "" &&
                            <Fi.FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}