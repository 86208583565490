import React from 'react';
import { Header, StockHeader } from './../../../components'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class ImportStorage extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            importedSuccess: true,
        }
    }

    componentDidMount(){
        
    }
    
   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <StockHeader isStockSettings={true} />

                <div className="main-container">
                
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                            <div className="page-title">Import storage locations</div>
                        </div>
                        
                    </div>
                </div>
                
                
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                
                    
                    <div className="clearfix"></div> 
                
                {
                    this.state.importedSuccess !== true ?
                    <div>
                        <div className="headerArea">
                            <div className="editPageTitle backup-database-title">Before adding a new data backup database in settings -&gt; Database maintenance.</div>
                            <form enctype="multipart/form-data" className="file-upload-box">
                                <div>
                                    <input type="file" />
                                    <div className="btn remove">
                                        <span className="glyphicon glyphicon-upload"></span>Drop CSV file here or click to select
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="clearfix"></div> 
                        <div className="pl-4">
                            <div className="add_btnBack">Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    :
                <div>
                    <div className="headerArea">
                        <div className="editPageTitle row-heading"><input type="checkbox" style={{ width: 14}} /> This first row is heading</div>
                    </div>
                <div className="tableContent" style={{overflowX: "auto"}}>
                    
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                                <th>
                                    <div className="add_inner">
                                        <div className="value">
                                            <select name="status">
                                                <option value="">Do not import</option>
                                                <option value="Local">Local</option>
                                                <option value="Storage">Storage</option>
                                                <option value="Data">Data</option>
                                                <option value="Manage">Manage</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Server</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Local</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Storage</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Data</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Manage</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    <div className="addSaveButton ml-0">
                        <div className="add_btnBack">Cancel</div>
                        <div className="add_btnSave">Import</div>
                        <div className="clear"></div>
                    </div>
                </div> 
                }
            </div>
            </div>
        )
    }
}