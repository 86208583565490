import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Fi from 'react-icons/fi'
import GenerateDocument from './generateDocument'
import GeneratePumpLog from './generatePumpLog'
import Loading from './../../../../helper/loading'
import { validateNumber, validatePercentage } from '../../../../constants/Validate'
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class CreateNewPump extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            customerListing: [],
            impellerMOCData: [],
            impellerTypeData: [],
            mainMOCData: [],
            mechSealData: [],
            motorRatingData: [],
            pumpDocumentData: [],
            companyIndustryData: [],
            modelNoData: [],
            cityListing: [],

            customer: "",
            project: "",
            industryType: "",
            country: "",
            city: "",
            tagNo: "",
            application: "",
            fluid: "",
            solid: "",
            temp: "",
            serialNo: "",
            modelNo: "",
            mainMOC: "",
            impellerMOC: "",
            impellerType: "",
            impellerSize: "",
            mechSeal: "",
            head: "",
            flow: "",
            motorRating: "",
            dateOfDispatch: "",
            dateOfInstallation: "",
            deliveryDateAsPerPO: "",

            countDocumentNumber: [],
            addDocumentFields: [],
            countDocument: 0,
            documentFieldValues: [],
            generateNewDocument: false,

            countPumpLogNumber: [],
            addPumpLogFields: [],
            countPumpLog: 0,
            pumpLogFieldValues: [],
            generateNewPumpLog: false,
          
        }
       

    }

    componentDidMount() {
        this.getAllRecords()
        this.checkAlfaUser()
      
    }
    checkAlfaUser = () => {
        let alfaUserAccountId = "791d2fa8-2f82-48d5-b14c-0e7197fe150f"
        let user = localStorage.getItem('userdata')
        if (user) {
            let accountId = JSON.parse(localStorage.getItem('userdata')).account_id
            if (alfaUserAccountId !== accountId) {
                this.props.history.push({
                    pathname: "/home"
                })
            }
        }
    }

    getAllRecords = () => {
        this.callGetModelNo()
        this.getCustomerList()
        this.getCompanyIndustry()
        this.callGetImpellerMOC()
        this.callGetImpellerType()
        this.callGetMainMOC()
        this.callGetMechSeal()
        this.callGetMotorRating()
        this.callGetPumpDocumentType()
        this.addNewDocumentField(0)
        this.addNewPumpLogField(0)
    }

    addNewDocumentField = (len) => {
        this.setState({
            addDocumentFields: this.state.addDocumentFields.concat(
                [
                    <GenerateDocument
                        key={len}
                        componentNum={len}
                        documentType={""}
                        documentFile={""}
                        documentNote={""}
                        onEdit={this.documentInputEdit}
                        onBlur={this.documentInputBlur}
                        onRemove={this.documentInputRemove}
                    />
                ]
            ),
            countDocument: len,
        }, () => {
            this.setState({
                countDocumentNumber: this.state.countDocumentNumber.concat([len]),
                generateNewDocument: true,
            })
        })
    }

    documentInputEdit = (documentType, documentFile, num) => {
        let array = [...this.state.countDocumentNumber];
        let index = array.indexOf(num)
        let docValues = [...this.state.documentFieldValues]


        if (typeof docValues[index] === 'undefined' && this.state.generateNewDocument === true && documentType !== "") {
            this.setState({
                countDocument: this.state.countDocument + 1,
                generateNewDocument: false,
            }, () => {

                this.setState({
                    countDocumentNumber: this.state.countDocumentNumber.concat(
                        [this.state.countDocument]
                    ),
                    addDocumentFields: this.state.addDocumentFields.concat(
                        [
                            <GenerateDocument
                                key={this.state.countDocument}
                                componentNum={this.state.countDocument}
                                documentType={""}
                                documentFile={""}
                                documentNote={""}
                                onEdit={this.documentInputEdit}
                                onBlur={this.documentInputBlur}
                                onRemove={this.documentInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    documentInputBlur = (documentType, documentFile, documentNote, num) => {

        let array = [...this.state.countDocumentNumber];
        let index = array.indexOf(num)
        let docValues = [...this.state.documentFieldValues]

        if (typeof docValues[index] !== 'undefined') {
            if (documentType !== "") {
                let obj = {
                    "name": documentType,
                    "documentFile": documentFile,
                    "note": documentNote
                }
                let documentFieldValues = [...this.state.documentFieldValues];
                documentFieldValues[index] = obj;

                this.setState({
                    documentFieldValues,
                    generateNewDocument: true,
                })
            }
        } else {
            if (documentType !== "") {
                let obj = {
                    "name": documentType,
                    "documentFile": documentFile,
                    "note": documentNote
                }
                this.setState({
                    documentFieldValues: this.state.documentFieldValues.concat([obj]),
                    generateNewDocument: true,
                })
            }
        }
    }

    documentInputRemove = (number) => {
        let array = [...this.state.countDocumentNumber];
        let fields = [...this.state.addDocumentFields];
        let data = [...this.state.documentFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;

            this.setState({
                countDocumentNumber: array,
                addDocumentFields: fields,
                documentFieldValues: data
            });
        }
    }


    /* PUMP LOG START */
    addNewPumpLogField = (len) => {
        this.setState({
            addPumpLogFields: this.state.addPumpLogFields.concat(
                [
                    <GeneratePumpLog
                        key={len}
                        componentNum={len}
                        pumpLogType={""}
                        pumpLogFile={""}
                        pumpLogDate={""}
                        pumpLogNote={""}
                        onEdit={this.pumpLogInputEdit}
                        onBlur={this.pumpLogInputBlur}
                        onRemove={this.pumpLogInputRemove}
                    />
                ]
            ),
            countPumpLog: len,
        }, () => {
            this.setState({
                countPumpLogNumber: this.state.countPumpLogNumber.concat([len]),
                generateNewPumpLog: true,
            })
        })
    }

    pumpLogInputEdit = (pumpLogType, pumpLogFile, num) => {
        let array = [...this.state.countPumpLogNumber];
        let index = array.indexOf(num)
        let docValues = [...this.state.pumpLogFieldValues]


        if (typeof docValues[index] === 'undefined' && this.state.generateNewPumpLog === true && pumpLogType !== "") {
            this.setState({
                countPumpLog: this.state.countPumpLog + 1,
                generateNewPumpLog: false,
            }, () => {

                this.setState({
                    countPumpLogNumber: this.state.countPumpLogNumber.concat(
                        [this.state.countPumpLog]
                    ),
                    addPumpLogFields: this.state.addPumpLogFields.concat(
                        [
                            <GeneratePumpLog
                                key={this.state.countPumpLog}
                                componentNum={this.state.countPumpLog}
                                pumpLogType={""}
                                pumpLogFile={""}
                                pumpLogDate={""}
                                pumpLogNote={""}
                                onEdit={this.pumpLogInputEdit}
                                onBlur={this.pumpLogInputBlur}
                                onRemove={this.pumpLogInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    pumpLogInputBlur = (pumpLogType, pumpLogFile, pumpLogDate, pumpLogNote, num) => {
        let array = [...this.state.countPumpLogNumber];
        let index = array.indexOf(num)
        let docValues = [...this.state.pumpLogFieldValues]

        if (typeof docValues[index] !== 'undefined') {
            if (pumpLogType !== "") {
                let obj = {
                    "note": pumpLogNote,
                    "type": pumpLogType,
                    "date": convertDateToMMDDYYYY(pumpLogDate),
                    "pumpLogFile": pumpLogFile
                }
                let pumpLogFieldValues = [...this.state.pumpLogFieldValues];
                pumpLogFieldValues[index] = obj;

                this.setState({
                    pumpLogFieldValues,
                    generateNewPumpLog: true,
                })
            }
        } else {
            if (pumpLogType !== "") {
                let obj = {
                    "note": pumpLogNote,
                    "type": pumpLogType,
                    "date": convertDateToMMDDYYYY(pumpLogDate),
                    "pumpLogFile": pumpLogFile
                }
                this.setState({
                    pumpLogFieldValues: this.state.pumpLogFieldValues.concat([obj]),
                    generateNewPumpLog: true,
                })
            }
        }
    }

    pumpLogInputRemove = (number) => {
        let array = [...this.state.countPumpLogNumber];
        let fields = [...this.state.addPumpLogFields];
        let data = [...this.state.pumpLogFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;

            this.setState({
                countPumpLogNumber: array,
                addPumpLogFields: fields,
                pumpLogFieldValues: data
            });
        }
    }
    /* PUMP LOG END */

    getCustomerList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callClientForPumpRegister()
            .then(response => {
                this.setState({
                    isLoading: false,
                    customerListing: response.data,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
            .then(response => {
                let res = response.companyIndustry !== undefined ? response.companyIndustry : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].industrytype, text: res[i].industrytype }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    companyIndustryData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors.data
                })
            });
    }



    callGetImpellerMOC = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerMOC()
            .then(response => {
                let res = response.data.impellerMoc !== undefined ? response.data.impellerMoc : []
                let updatedData = []
                if (res.length > 0) {

                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].impellerMoc, text: res[i].impellerMoc }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    impellerMOCData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }
    callGetImpellerType = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerType()
            .then(response => {

                let res = response.data.impellerType !== undefined ? response.data.impellerType : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].impellerType, text: res[i].impellerType }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    impellerTypeData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    callGetMainMOC = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMainMOC()
            .then(response => {
                let res = response.data.mainMoc !== undefined ? response.data.mainMoc : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].mainMoc, text: res[i].mainMoc }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    mainMOCData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    callGetMechSeal = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMechSeal()
            .then(response => {
                let res = response.data.mechSeal !== undefined ? response.data.mechSeal : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].mechSeal, text: res[i].mechSeal }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    mechSealData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    callGetModelNo = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetModelNo()
            .then(response => {
                let res = response.data.pumpModelNo !== undefined ? response.data.pumpModelNo : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].pumpModelNo, text: res[i].pumpModelNo }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    modelNoData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    callGetMotorRating = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetMotorRating()
            .then(response => {
                let res = response.data.motorRating !== undefined ? response.data.motorRating : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].motorRating, text: res[i].motorRating }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    motorRatingData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    callGetPumpDocumentType = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPumpDocumentType()
            .then(response => {
                let res = response.data.pumpDocumentName !== undefined ? response.data.pumpDocumentName : []
                let updatedData = []
                if (res.length > 0) {
                    for (let i = 0; i < res.length; i++) {
                        let obj = { id: res[i].pumpDocumentName, text: res[i].pumpDocumentName }
                        updatedData.push(obj)
                    }
                }
                this.setState({
                    isLoading: false,
                    pumpDocumentData: updatedData
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    callGetAllCitiesByCountry = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllCitiesByCountry(id)
            .then(response => {
                this.setState({
                    isLoading: false,
                    cityListing: response.data
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }

    createPump = () => {

        let {
            customer,
            project,
            industryType,
            country,
            city,
            tagNo,
            application,
            fluid,
            solid,
            temp,
            serialNo,
            modelNo,
            mainMOC,
            impellerMOC,
            impellerType,
            impellerSize,
            mechSeal,
            head,
            flow,
            motorRating,
            dateOfDispatch,
            dateOfInstallation,
            deliveryDateAsPerPO
        } = this.state

        if (customer === "") {
            this.setState({
                error: true,
                errormessage: "Please select customer",
                success: false,
            })
        } else {
            this.setState({ isLoading: true, error: false })
            APIManager.callCreatePump(customer, project, industryType, country, city, tagNo, application, fluid, solid, temp, serialNo, modelNo, mainMOC, impellerMOC, impellerType, impellerSize, mechSeal, head, flow, motorRating, convertDateToMMDDYYYY(dateOfDispatch), convertDateToMMDDYYYY(dateOfInstallation), convertDateToMMDDYYYY(deliveryDateAsPerPO))
                .then(response => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.checkAndUpdateDocuments(response.data._id)
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        errormessage: errors
                    })
                });
        }

    }

    checkAndUpdateDocuments = (registeredId) => {
        let { documentFieldValues } = this.state
        /* Remove Null From State */
        let docList = [];
        documentFieldValues.forEach((val) => {
            if (val !== null) {
                docList.push(val);
            }
        });
        /* Remove Null */

        if (docList.length > 0) {
            this.setState({ isLoading: true, error: false })
            APIManager.callCreatePumpDocument(JSON.stringify(docList), registeredId)
                .then(response => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.checkAndUpdatePumpLogs(registeredId)
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        errormessage: errors
                    })
                });
        } else {
            this.checkAndUpdatePumpLogs(registeredId)
        }
    }

    checkAndUpdatePumpLogs = (registeredId) => {
        let { pumpLogFieldValues } = this.state
        /* Remove Null From State */
        let logList = [];
        pumpLogFieldValues.forEach((val) => {
            if (val !== null) {
                logList.push(val);
            }
        });
        /* Remove Null */

        if (logList.length > 0) {
            this.setState({ isLoading: true, error: false })
            APIManager.callCreatePumpLogs(JSON.stringify(logList), registeredId)
                .then(response => {
                    this.setState({
                        isLoading: false,
                    }, () => {
                        this.props.history.push({
                            pathname: "/editpump",
                            state: { pumpId: registeredId, isNewEntry: true }
                        })
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        errormessage: errors
                    })
                });
        } else {
            this.props.history.push({
                pathname: "/editpump",
                state: { pumpId: registeredId, isNewEntry: true }
            })
        }
    }

    getSingleCustomerDetails = (customer) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                let city = ""
                let country = ""
                if (response.length > 0) {
                    let address = response[0].split('_')
                    city = address[3].trim()
                    country = address[6].trim()
                }
                this.setState({
                    isLoading: false,
                    city: city,
                    country: country
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: false,
                    errormessage: errors
                })
            });
    }


    setImpellerSize = (val) => {
        var t = val;
        let s = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        this.setState({
            impellerSize: s
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isPumpRegister={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Add pump details</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.createPump()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Customer*</div>
                                    <div className="value">
                                        <Select2
                                            data={
                                                this.state.customerListing.map((data) => (
                                                    { id: data._id, text: data.companyId.toUpperCase() + " " + data.companyName }
                                                ))
                                            }
                                            value={this.state.customer}
                                            options={{ placeholder: 'Select customer' }}
                                            onSelect={(e) => this.setState({ customer: e.target.value }, () => {
                                                this.getSingleCustomerDetails(this.state.customer)
                                            })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Project/Plant</div>
                                    <div className="value">
                                        <input type="text" value={this.state.project} onChange={(e) => this.setState({ project: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Industry Type</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.companyIndustryData}
                                            value={this.state.industryType}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ industryType: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Country</div>
                                    <div className="value">
                                        <input type="text" value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })} ></input>
                                        {/* <Select2    
                                            data={
                                                countryData.map((data) => (
                                                    { id: data.isoCode, text: data.name }
                                                ))
                                            }
                                            value={this.state.country}
                                            options={{placeholder: 'Select'}}
                                            onSelect={(e)=> this.setState({ country: e.target.value },()=>{
                                                this.callGetAllCitiesByCountry(this.state.country)
                                            })}
                                        /> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">City</div>
                                    <div className="value">
                                        <input type="text" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} ></input>
                                        {/* <Select2    
                                            data={
                                                this.state.cityListing.map((data) => (
                                                    { id: data.name, text: data.name }
                                                ))
                                            }
                                            value={this.state.city}
                                            options={{placeholder: 'Select'}}
                                            onSelect={(e)=> this.setState({ city: e.target.value })}
                                        /> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Tag No.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.tagNo} onChange={(e) => this.setState({ tagNo: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Application</div>
                                    <div className="value">
                                        <input type="text" value={this.state.application} onChange={(e) => this.setState({ application: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Fluid</div>
                                    <div className="value">
                                        <input type="text" value={this.state.fluid} onChange={(e) => this.setState({ fluid: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Solid</div>
                                    <div className="value">
                                        {/* <input 
                                            type="text" 
                                            maxLength={5}
                                            value={this.state.solid} 
                                            onChange={(e)=>{
                                                if(validatePercentage(e.target.value))
                                                    this.setState({ solid: e.target.value })
                                                } 
                                            }
                                            style={{width: "30%"}} 
                                        /> &nbsp;
                                        <Fi.FiPercent /> */}
                                        <input
                                            type="text"
                                            value={this.state.solid}
                                            onChange={(e) => this.setState({ solid: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Temp.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.temp} onChange={(e) => this.setState({ temp: validateNumber(e.target.value) ? e.target.value : '' })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Serial No.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.serialNo} onChange={(e) => this.setState({ serialNo: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Delivery date as per PO</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.deliveryDateAsPerPO
                                            }
                                            onChange={
                                                date => this.setState({ deliveryDateAsPerPO: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                            <div className="add_partside">

                                <div className="add_inner">
                                    <div className="title">Model No.</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.modelNoData}
                                            value={this.state.modelNo}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ modelNo: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Main MOC</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.mainMOCData}
                                            value={this.state.mainMOC}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ mainMOC: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Impeller MOC</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.impellerMOCData}
                                            value={this.state.impellerMOC}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ impellerMOC: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Impeller Type</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.impellerTypeData}
                                            value={this.state.impellerType}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ impellerType: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Impeller Size</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.impellerSize}
                                            onChange={(e) => {
                                                if (validateNumber(e.target.value))
                                                    this.setImpellerSize(e.target.value)
                                            }
                                            }
                                            style={{ width: "30%" }}
                                        /> &nbsp;
                                        mm
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Mech Seal</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.mechSealData}
                                            value={this.state.mechSeal}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ mechSeal: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Head</div>
                                    <div className="value">
                                        <input type="text" value={this.state.head} onChange={(e) => this.setState({ head: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Flow</div>
                                    <div className="value">
                                        <input type="text" value={this.state.flow} onChange={(e) => this.setState({ flow: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Motor Rating</div>
                                    <div className="value">
                                        <Select2
                                            data={this.state.motorRatingData}
                                            value={this.state.motorRating}
                                            options={{ placeholder: 'Select' }}
                                            onSelect={(e) => this.setState({ motorRating: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Date of Dispatch</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.dateOfDispatch
                                            }
                                            onChange={
                                                date => this.setState({ dateOfDispatch: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Date of Installation</div>
                                    <div className="value">
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.dateOfInstallation
                                            }
                                            onChange={
                                                date => this.setState({ dateOfInstallation: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na" />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className='position-relative mt-4'>
                            <div className="doc__main_container">
                                <div className="doc_table_title">Documents </div>
                                <div className="doc_table_value">
                                    <div className="doc_table__data" style={{ overflowX: "auto" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="d_head"><b>Name</b></th>
                                                    <th className="d_head"><b>Browse</b></th>
                                                    <th className="d_head"><b>Note</b></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.addDocumentFields
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='clear'></div>
                        </div>

                        <div className='position-relative mt-4'>
                            <div className="doc__main_container">
                                <div className="doc_table_title">Pump Logs </div>
                                <div className="doc_table_value">
                                    <div className="doc_table__data">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="d_head"><b>Name</b></th>
                                                    <th className="d_head"><b>Date</b></th>
                                                    <th className="d_head"><b>Browse</b></th>
                                                    <th className="d_head"><b>Note</b></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.addPumpLogFields
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='clear'></div>
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={() => this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={() => this.createPump()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }

               
            </div>
        );
    }
}

export default CreateNewPump;