import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../../components'

import * as Ai from "react-icons/ai";
import * as APIManager from './../../../../APIManager/APIManager'

import GroupItem from './GroupItem'


class EditRouting extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            RoutingId: this.props.location.state.RoutingId,
            isNewEntry: this.props.location.state.newRoutingEntry,    

            routingNo: "",
            product: "",
            number: "",
            name: "",
            connectedBOM: "",
            isDetailedView: false,
            connectBOMName: "",

            workstationGroupFields: [],
            workstationGroupKeys: [],
            workstationGroupCounter: 0,
            workstationGroupData: [],
            workstationGroupList: [],

            dragging: "",
            draggedOver: "",

            fieldNotEditable: true
        }
        // this.setDragging = this.setDragging.bind(this)
        // this.setDraggedOver = this.setDraggedOver.bind(this)
        // this.compare = this.compare.bind(this)
    }

    componentDidMount(){
        this.getAllWorkStationGroup()
    }

    getAllWorkStationGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllWorkstationGroup("", "", "", "", "", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                workstationGroupList: response.data,
            },()=>{
                this.getSingleRouting()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });  
    }

    getSingleRouting = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleRouting(this.state.RoutingId)
        .then(response => {

            this.setState({
                isLoading: false,
                routingNo: response.number.toUpperCase(),
                name: response.name,
                isDetailedView: response.is_detailedView,
                connectedBOM: response.bomId,
                workstationGroupFields: [],
                workstationGroupKeys: [],
                workstationGroupCounter: 0,
                workstationGroupData: response.workStationDetail
            },()=>{
                this.getItemDetail(response.product)
                this.getSingleBOMDetail(response.bomId)
                this.setWorkstationGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setDataWithDetailView = () => {
        this.setState({
            workstationGroupFields: [],
            workstationGroupKeys: [],
            workstationGroupCounter: 0,
        },()=>{
            this.setWorkstationGroupData()
        })
    }

    getItemDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({
                isLoading: false,
                product: response.itemData.itemCode.toUpperCase()+ " "+ response.itemData.partDescription
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getSingleBOMDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleBOM(id)
        .then(response => {
            this.setState({ 
                connectBOMName: response.number.toUpperCase()+" "+response.name 
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setWorkstationGroupData = () => {
        let prodGrp = this.state.workstationGroupData
        let groupLength = prodGrp.length;
        
        if(groupLength > 0){

            let groupField = [];
            let groupKey = [];

            for(let i=0;i<prodGrp.length;i++){
               
                if(prodGrp[i] !== null){
                    groupField.push(
                        <GroupItem
                            draggable={true}
                            drag={this.setDragging}
                            dragover={this.setDraggedOver}
                            drop={this.compare}
                            key={'proGroup'+i}
                            onChangeProduct={this.addNewGroupFields}
                            onBlurProduct={this.addGroupFieldValues}
                            onRemoveProduct={this.removeGroupProduct}
                            isDetailedView={this.state.isDetailedView}
                            counter={i}
                            groupData={prodGrp[i]}
                            workstationGroupList={this.state.workstationGroupList}
                        />
                    )
                    groupKey.push(i)
                }
            }

            this.setState({
                workstationGroupFields: groupField,
                workstationGroupKeys: groupKey,
                workstationGroupCounter: groupLength
            },()=>{
                this.setNewBlankField(this.state.workstationGroupCounter)
            })
        }else{
            this.setGroupItem()
        }
    }

    setNewBlankField = (counter) => {
        let dummyObj =  {
            'workStationGroup': '',
            'workStationsArray': '',
            'otherDescription': '',
            'setupTime': '',
            'cycleTime': '',
            'fixedCost': '',
            'variableCost': '',
            'quantity': '',
            'department': ''
        }

        this.setState({
            workstationGroupFields: this.state.workstationGroupFields.concat([
                <GroupItem
                    draggable={true}
                    drag={this.setDragging}
                    dragover={this.setDraggedOver}
                    drop={this.compare}
                    key={'proGroup'+counter}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    isDetailedView={this.state.isDetailedView}
                    counter={counter}
                    groupData={dummyObj}
                    workstationGroupList={this.state.workstationGroupList}
                />
            ]),            
        })
    }

    setGroupItem = () => {
        let dummyObj =  {
            'workStationGroup': '',
            'workStationsArray': '',
            'otherDescription': '',
            'setupTime': '',
            'cycleTime': '',
            'fixedCost': '',
            'variableCost': '',
            'quantity': '',
            'department': ''
        }

        this.setState({
            workstationGroupFields: this.state.workstationGroupFields.concat([
                <GroupItem
                    draggable={true}
                    drag={this.setDragging}
                    dragover={this.setDraggedOver}
                    drop={this.compare}
                    key={'proGroup'}
                    onChangeProduct={this.addNewGroupFields}
                    onBlurProduct={this.addGroupFieldValues}
                    onRemoveProduct={this.removeGroupProduct}
                    isDetailedView={this.state.isDetailedView}
                    counter={this.state.workstationGroupCounter}
                    groupData={dummyObj}
                    workstationGroupList={this.state.workstationGroupList}
                />
            ]),            
        })
    }

    addNewGroupFields = (counter) => {
        let dummyObj =  {
            'workStationGroup': '',
            'workStationsArray': '',
            'otherDescription': '',
            'setupTime': '',
            'cycleTime': '',
            'fixedCost': '',
            'variableCost': '',
            'quantity': '',
            'department': ''
        }

        let array = [...this.state.workstationGroupKeys];
        let index = array.indexOf(counter)
        if(index === -1){
            this.setState({
                workstationGroupFields: this.state.workstationGroupFields.concat([
                    <GroupItem
                        draggable={true}
                        drag={this.setDragging}
                        dragover={this.setDraggedOver}
                        drop={this.compare}
                        key={'proGroup'+counter+1} 
                        onChangeProduct={this.addNewGroupFields}
                        onBlurProduct={this.addGroupFieldValues}
                        onRemoveProduct={this.removeGroupProduct}
                        isDetailedView={this.state.isDetailedView}
                        counter={counter+1}
                        groupData={dummyObj}
                        workstationGroupList={this.state.workstationGroupList}
                    />
                ]),
                workstationGroupKeys: this.state.workstationGroupKeys.concat([this.state.workstationGroupCounter]),
                workstationGroupCounter: counter+1,
            })
        }
    }

    setDragging = (index) => {
        this.setState({
            dragging: index
        })
    }

    setDraggedOver = (index) => {
        this.setState({
            draggedOver: index
        })
    }

    compare = () => {
        let prodGrp = [...this.state.workstationGroupData]

        let index1 = prodGrp.indexOf(this.state.dragging);
        let index2 = prodGrp.indexOf(this.state.draggedOver);
        prodGrp.splice(index1, 1)
        prodGrp.splice(index2, 0, this.state.dragging)

        this.setState({
            workstationGroupFields: [],
            workstationGroupKeys: [],
            workstationGroupCounter: 0,
            workstationGroupData: prodGrp
        },()=>{
            this.setWorkstationGroupData()
        })
    };



    addGroupFieldValues = (value, counter) => {
        let workstationGroupData = [...this.state.workstationGroupData]    
        workstationGroupData[counter] = value;  
        this.setState({
            workstationGroupData
        })
    }
    removeGroupProduct = (counter) => {
        let workstationGroupData = [...this.state.workstationGroupData]    
        let workstationGroupFields = [...this.state.workstationGroupFields]
        workstationGroupData[counter] = null;
        workstationGroupFields[counter] = null;
        
        this.setState({
            workstationGroupData,
            workstationGroupFields
        })
    }
  
    updateRouting = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                RoutingId,
                product,
                connectedBOM,
                name,
                routingNo,
                isDetailedView,
                workstationGroupData
            } = this.state

            /* Remove Null From State */
            let workStationDetail = [];
            workstationGroupData.forEach((val) => {
                if(val !== null){
                    workStationDetail.push(val);
                }
            });
            /* Remove Null */

            if(connectedBOM === ""){
                this.setState({
                    error: true,
                    errormessage: "Please select connected BOM",
                    success: false,
                })
            }else if(workStationDetail.length === 0){
                this.setState({
                    error: true,
                    errormessage: "Please select workstation group",
                    success: false,
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateRouting(RoutingId,product,connectedBOM,name,routingNo,isDetailedView,JSON.stringify(workStationDetail))
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        error: false,
                        fieldNotEditable: true,
                        successmessage: "Routing updated successfully"
                    },()=>{
                        this.getSingleRouting()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            let slug = "ProductionPlanning_Routings"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }
   
    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            
            APIManager.callDeleteRouting(this.state.RoutingId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  

  

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isRoutings={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <div className="page-title">Routing {this.state.routingNo} <span className="text-capitalize">{this.state.product.length > 40 ? this.state.product.substring(0, 40)+'...' : this.state.product}</span> routing Details</div>
                            </div>
                            {/* <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button">Excel <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>  
                                <div className="head-import-buttons float-right">
                                    <div className="button">PDF <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div> */}
                        </div>    
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateRouting()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>

                            <div className="clear"></div>
                        </div>

                        <div className="position-relative">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product</div>
                                    <div className="value">
                                    <div className="createdText text-capitalize"><span className="clickable__source">{this.state.product}</span></div>
                                    </div>
                                    <div className="clear"></div>
                                </div> 
                                <div className="add_inner">
                                    <div className="title">Number *</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-capitalize"
                                            value={this.state.routingNo}
                                            readOnly
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Name *</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            className="fieldheightdecr text-capitalize"
                                            value={this.state.name}
                                            readOnly
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Connected BOM</div>
                                    <div className="__formTextVal float-left">
                                        <div className="text-capitalize">
                                            <input 
                                                type="checkbox" 
                                                className="__formCheckboxElem" 
                                                name="connectedBOM"
                                                checked
                                                readOnly
                                            /> <span className="clickable__source">{this.state.connectBOMName.length > 40 ? this.state.connectBOMName.substring(0, 40)+'...' : this.state.connectBOMName}</span>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">Detailed view</div>
                                        <div className="__formTextVal">
                                            <input 
                                                type="checkbox" 
                                                className="__formCheckboxElem" 
                                                checked={this.state.isDetailedView}
                                                onChange={()=>this.setState({ isDetailedView: !this.state.isDetailedView },()=>{
                                                    this.setDataWithDetailView()
                                                })}
                                            />
                                        </div>
                                    <div className="clear"></div>
                                </div> */}
                            </div>
                            
                            <div className="clear"></div>

                            <div className="main-container mt-20 order_product_group">

                                <h6 className="font-weight-bold">Parts</h6>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='pg-tab-head'>Workstation group</th>
                                            <th className='pg-tab-head'>Workstation Priority</th>
                                            <th className='pg-tab-head'>Operation description</th>
                                            <th className='pg-tab-head'>Setup time</th>
                                            <th className='pg-tab-head'>Cycle time</th>
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Fixed cost</th>
                                            }
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Variable cost</th>
                                            }
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Quantity</th>
                                            }
                                            {
                                                this.state.isDetailedView &&
                                                <th className='pg-tab-head'>Department</th>
                                            }
                                            <th className='pg-tab-head'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.workstationGroupFields}
                                    </tbody>
                                </table>
                            </div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                                    
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateRouting()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                            <div className="clear"></div>
                        </div>
                    </div>

                    
                </div>
            </div>
        );
    }
}

export default EditRouting;