import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'

import * as Ai from 'react-icons/ai'

import AddTaxDetails from '../../../components/AutoGenerateComponent/addTaxDetails';

export default class EditTaxDetails extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            

            isLoading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countTaxDetailsNumber: [],
            addTaxDetailsFields: [],
            count: 0,
            taxDetailsFieldValues: [],
            generateNewTaxDetails: false,

        };
    }

    componentDidMount(){
        this.getTaxDetails()
    }

    getTaxDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetTaxDetails()
        .then(response => {
            this.setState({ 
                isLoading: false,
                taxDetailsFieldValues: response[0].taxDetails !== "undefined" ? response[0].taxDetails : []
            },()=>{
                this.setTaxDetailsFields(this.state.taxDetailsFieldValues)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            },()=>{
                this.addNewField(0)
            })
        });  
    }

    setTaxDetailsFields = (taxDetails) => {

        if(taxDetails.length > 0){
            let numArray = []
            let fields = []
            for(let i=0;i<taxDetails.length;i++){
                numArray.push(i)
                fields.push(
                    <AddTaxDetails 
                        key={i}
                        componentNum={i}
                        type={taxDetails[i].type}
                        rate={taxDetails[i].rate}
                        applicableOn={taxDetails[i].applicableOn}
                        onBlur={this.taxDetailsInputBlur}
                        onEdit={this.taxDetailsInputEdit}
                        onRemove={this.taxDetailsInputRemove}
                    />
                )
            }
            this.setState({
                addTaxDetailsFields: fields,
                countTaxDetailsNumber: numArray,
                count: taxDetails.length
            },()=>{
                this.addNewField(taxDetails.length)
            })
        }else{
            this.addNewField(0)
        }
    }

    addNewField = (len) => {
        this.setState({
            addTaxDetailsFields: this.state.addTaxDetailsFields.concat(
                [
                    <AddTaxDetails 
                        key={len}
                        componentNum={len}
                        type={""}
                        rate={""}
                        applicableOn={"tax"}
                        onBlur={this.taxDetailsInputBlur}
                        onEdit={this.taxDetailsInputEdit}
                        onRemove={this.taxDetailsInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countTaxDetailsNumber: this.state.countTaxDetailsNumber.concat([len]),
                generateNewTaxDetails: true,
            })
        })
    }
    
    taxDetailsInputEdit = (type, rate, num) => {

        let array = [...this.state.countTaxDetailsNumber];
        let index = array.indexOf(num)
        let taxDetailsVal = [...this.state.taxDetailsFieldValues]     
        
        
        if (typeof taxDetailsVal[index] === 'undefined' && this.state.generateNewTaxDetails === true && type !== "" && rate !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewTaxDetails: false,
            }, () => {
                
                this.setState({
                    countTaxDetailsNumber: this.state.countTaxDetailsNumber.concat(
                        [this.state.count]
                    ),
                    addTaxDetailsFields: this.state.addTaxDetailsFields.concat(
                        [
                            <AddTaxDetails 
                                key={this.state.count}
                                componentNum={this.state.count}
                                type={""}
                                rate={""}
                                applicableOn={"tax"}
                                onBlur={this.taxDetailsInputBlur}
                                onEdit={this.taxDetailsInputEdit}
                                onRemove={this.taxDetailsInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    taxDetailsInputBlur = (type, rate, appOn, num) => {
 
         let array = [...this.state.countTaxDetailsNumber];
         let index = array.indexOf(num)
         let taxDetailsVal = [...this.state.taxDetailsFieldValues]   
 
         if (typeof taxDetailsVal[index] !== 'undefined') {
             if (type !== "" && rate !== "") {
                 let obj = {
                     "type": type,
                     "rate": rate,
                     "applicableOn": appOn
                 }
                 let taxDetailsFieldValues = [ ...this.state.taxDetailsFieldValues ];
                 taxDetailsFieldValues[index] = obj;
 
                 this.setState({
                     taxDetailsFieldValues,
                     generateNewTaxDetails: true,
                 })
             }
         }else{
             if (type !== "" && rate !== "") {
                 let obj = {
                     "type": type,
                     "rate": rate,
                     "applicableOn": appOn
                 }
                 this.setState({
                     taxDetailsFieldValues: this.state.taxDetailsFieldValues.concat([ obj ]),
                     generateNewTaxDetails: true,
                 })
             }
         }
     }
 

    taxDetailsInputRemove = (number) => {
        let array = [...this.state.countTaxDetailsNumber];
        let fields = [...this.state.addTaxDetailsFields];
        let data = [...this.state.taxDetailsFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countTaxDetailsNumber: array,
                addTaxDetailsFields: fields,
                taxDetailsFieldValues: data
            });
        }
        
    }

    saveTaxDetails = () => {
        let {
            taxDetailsFieldValues
        } = this.state

        /* Remove Null From State */
        let taxDetails = [];
        taxDetailsFieldValues.forEach((val) => {
            if(val !== null){
                taxDetails.push(val);
            }
        });
        /* Remove Null */
        if(taxDetails.length === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please add tax details first"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callSetTaxDetails(JSON.stringify(taxDetails))
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    success: true,
                    error: false,
                    successmessage: "Tax details updated successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            }); 
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }  
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />

                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Tax details</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.saveTaxDetails()}>Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div className="editSettTable">
                            <table className="settingTable">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Type</th>
                                        <th style={{ width: 150 }}>%</th>
                                        <th>Applicable on</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.addTaxDetailsFields
                                    }
                                     {/* <tr>
                                        <td></td>
                                        <td><input type="text" className="input-box" /></td>
                                        <td>
                                            <div className="position-relative">
                                                <input type="text" className="input-box" />
                                                <Ai.AiOutlinePercentage className='datepickerIcon cursor-na'/>
                                            </div>
                                        </td>
                                        <td>
                                            <select className="select-box">
                                                <option value="tax">Tax</option>
                                                <option value="rate">Rate</option>
                                            </select>
                                        </td>
                                        <td><FiX className="editCrossButton" /></td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>

                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.saveTaxDetails()}>Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}