import React from 'react';
import * as Cg from 'react-icons/cg';
import { Logo } from './../../assets/Images'
import * as Fi from 'react-icons/fi'
import * as APIManager from './../../APIManager/APIManager'
import { Modal } from 'react-bootstrap';
import Loading from '../../helper/loading';
import * as Ai from 'react-icons/ai'
import { convertDateTimeStringMMDDtoDDMM } from '../../constants/DateFormatter';


class EmployeeProductionPlan extends React.Component {
    
    constructor(props){
        super(props)

        var today = new Date(),
            date = ("0" + today.getDate()).slice(-2)  + '-' + ("0" + (today.getMonth() + 1)).slice(-2)  + '-' + today.getFullYear()
        
        this.state = {
            isLoading: false,
            
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            userEmail: "loading",
            userId: "",
            date: date,
            time: today.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: true }),
            timeOutFunc: null,
            responseData: [],
            showHidePuaseScreen: false,
            stopProductionScreen: false,
            productionId: "",
            operationId: "",
            actionFlag: "",
            completeQuantity: "",
            rejectedQuantity: "",
            currentOperationDetail: null,
            productionNote: "",

        }
    }

    componentDidMount(){
        this.checkUserLogin()
    }

    checkUserLogin = () => {
        let userData = localStorage.getItem('userdata')
        if(userData !== null){
            let email = JSON.parse(userData).email
            let _id = JSON.parse(userData)._id
            this.setState({
                userEmail: email,
                userId: _id
            },()=>{
                let permissions = JSON.parse(localStorage.getItem("permissions"))
            
                if(permissions.length > 0){
                    if(permissions.length === 1){
                        let isWorker = permissions.find(o => o.slug === 'ProductionOperations');
                        if(isWorker === undefined){
                            this.props.history.replace('/home')
                        }else{
                            this.getProductionListByUser()
                        }
                    }else{
                        this.props.history.replace('/home')
                    }
                }else{
                    this.props.history.replace('/home')
                }  
            })
        }else{
            this.logout()
        }
        this.getCurrentTime()
    }

    getCurrentTime = () => {
        var today = new Date(),
            date = ("0" + today.getDate()).slice(-2)  + '-' + ("0" + (today.getMonth() + 1)).slice(-2)  + '-' + today.getFullYear()
        this.setState({
            timeOutFunc: setTimeout(
                function() {
                    this.setState({ 
                        date: date,
                        time: today.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', second: '2-digit', hour12: true }),
                    },()=>{
                        this.getCurrentTime()
                    })
                }
                .bind(this),
                1000
            )
        })
    }

    getProductionListByUser = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetProductionListByUser()
        .then(response => {
            this.setState({ 
                isLoading: false,
                responseData: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    startMO = (MO_Id, operation_id) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({ isLoading: true, error: false })
            APIManager.callStartMO(MO_Id, operation_id)
            .then(response => {
                if(response.status === "success"){
                    this.setState({
                        isLoading: false,
                        error: false,
                        success: false,
                    },()=>{
                        this.getProductionListByUser()  
                    })
                }else{
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: response.message
                    })
                }
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

  

    PlayPauseMO = (id, operationId, actionFlag) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({ isLoading: true, error: false })
            APIManager.callPlayPauseMO(id, operationId, actionFlag)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    showHidePuaseScreen: false,
                },()=>{
                    if(actionFlag === "pause"){
                        this.callCalculateQTY(id)  
                    }else{
                        this.getProductionListByUser()
                    }
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }else{
            alert("You don't have permission to "+permission+" this record")
        } 
    }


    countAndStopMO = (id) => {
        let {
            completeQuantity,
            rejectedQuantity,
            productionNote,
            currentOperationDetail
        } = this.state
        
        let totalQty = completeQuantity
        let rejQty = rejectedQuantity
        let operation = currentOperationDetail.operation 
        let product = currentOperationDetail.product
        let operationQty = currentOperationDetail.quantity
        let note = ""
        if(productionNote !== ""){
            note = operation +' '+ product +' '+ totalQty +' quantity completed, Note: '+productionNote
        }

        let total = totalQty + rejQty

        if(parseFloat(total) !== parseFloat(operationQty)){
            alert("Please add total completed and rejected quantity to finish the order")
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCalculateQTY(id, totalQty, note, rejQty)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    completeQuantity: "",
                    productionNote: "",
                    rejectedQuantity: "",
                },()=>{
                    this.finishMO(id)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    callCalculateQTY = (id) => {

        let {
            completeQuantity,
            rejectedQuantity,
            productionNote,
            currentOperationDetail
        } = this.state
        
        this.setState({ isLoading: true, error: false })
        
        let totalQty = completeQuantity
        let rejQty = rejectedQuantity
        let operation = currentOperationDetail.operation 
        let product = currentOperationDetail.product

        let note = ""
        if(productionNote !== ""){
            note = operation +' '+ product +' '+ totalQty +' quantity completed, Note: '+productionNote
        }

        if(totalQty === "")
            totalQty = 0

        APIManager.callCalculateQTY(id, totalQty, note, rejQty)
        .then(response => {
            this.setState({ 
                isLoading: false,
                completeQuantity: "",
                productionNote: "",
                rejectedQuantity: "",
            },()=>{
                this.getProductionListByUser()  
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }



    setPauseMO = (data) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({
                showHidePuaseScreen: !this.state.showHidePuaseScreen,
                currentOperationDetail: data,
                //completeQuantity: data.quantity,
                rejectedQuantity: ''
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        } 
    }

    setStopScreen = (data) => {
        let slug = "ProductionOperations"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            if(data.productionDetailId === ""){
                alert("Production is not started yet")
            }else{
                var r = window.confirm("Please confirm, you're finishing the operation");
                if (r === true) {
                    this.setState({
                        stopProductionScreen: !this.state.stopProductionScreen,
                        currentOperationDetail: data,
                        completeQuantity: data.quantity,
                        rejectedQuantity: ''
                    })
                }
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        } 
    }

    setHidePauseScreen = () => {
        this.setState({
            showHidePuaseScreen: false,
            stopProductionScreen: false
        })
    }

    finishMO = (prodId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callFinishMO(prodId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                completeQuantity: "",
                stopProductionScreen: false
            },()=>{
                this.getProductionListByUser()  
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    updateCompletedQty = (val) => {
        let {
            currentOperationDetail,
            rejectedQuantity
        } = this.state

        let enteredQty = val ? val : 0
        let rejectedQty = rejectedQuantity ? rejectedQuantity : 0
        let qty = parseFloat(enteredQty) + parseFloat(rejectedQty)
        let totalQty = currentOperationDetail.quantity

        if(qty <= totalQty){
            this.setState({
                completeQuantity: val
            })
        }else{
            this.setState({
                completeQuantity: ''
            })
        }

    }
    
    updateRejectedQty = (val) => {
        let {
            currentOperationDetail,
            completeQuantity
        } = this.state

        let enteredQty = val ? val : 0
        let rejectedQty = completeQuantity ? completeQuantity : 0
        let qty = parseFloat(enteredQty) + parseFloat(rejectedQty)
        let totalQty = currentOperationDetail.quantity

        if(qty <= totalQty){
            this.setState({
                rejectedQuantity: val
            })
        }else{
            this.setState({
                rejectedQuantity: ''
            })
        }
    }

    componentWillUnmount(){
        clearTimeout(this.state.timeOutFunc);
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }
    
    render() {
        return (
            <div className="page">
                <div className="top-header bg-white">
                    {/* <Offline><div className="__offline_message">You are currently offline. Please check your internet connection.</div></Offline> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 col-sm-12 col-md-6 col-xs-12">
                                <div className="d-flex">
                                    <div className="home-tab pl-0">
                                        <div className="header-Logo">
                                            <img src={Logo} className="header-Logo" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="home-tab">
                                        <div className="home-tab version">V 1.0.0</div>
                                        <div className="home-tab date">{this.state.date} {this.state.time}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 col-md-6 col-xs-12">
                                <div className="text-right">
                                    <div className="home-tab user-loging pr-0">{this.state.userEmail} <div className="inline" onClick={()=>this.logout()}><Fi.FiLogOut className="user-logout"/></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-main-container bg-white">
                    <div className="page-header mt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title mt-1 mb-1">Welcome!</div>
                                <div className="operation__title">Production operations</div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {/* <div className="button">Search</div> */}
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.error &&
                        <div className="editscreenerror mb-5">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror mb-5">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }

                    <div className="tableContent mh400" style={{ overflowX : "auto" }}>
                        <table>
                            <tbody>
                            {
                                this.state.responseData.map((data, i) => (
                                    data.is_MoFinished === false &&
                                    <tr key={"EPP"+i}>
                                        <td style={{width: 200}}>
                                            <div>Manufacturing Order :</div>
                                            <div>Product :</div>
                                            <div>Quantity :</div>
                                            <div>Operation :</div>
                                            <div>Workstation :</div>
                                            <div>Actual Start :</div>
                                            <div>{data.workerId !== this.state.userId ? 'Operator :' : ''}</div>
                                        </td>
                                        <td>
                                            <div>{data.manufacturingOrderNumber.toUpperCase()}</div>
                                            <div>{data.product}</div>
                                            <div>{data.quantity} {data.uomName}</div>
                                            <div>{data.operation}</div>
                                            <div>{data.workStation}</div>
                                            <div>{data.actualStart ? convertDateTimeStringMMDDtoDDMM(data.actualStart) : '-'}</div>
                                            <div className='capitalize'>{data.workerId !== this.state.userId ? data.userName : ''}</div>
                                        </td>
                                        <td style={{ width: 110 }} className="text-right">
                                            <div>
                                                {
                                                    data.is_MoFinished === false ?
                                                        (data.is_MoStart === false && data.is_MoPaused === false) ?
                                                            <Fi.FiPlayCircle className="employee___production__buttons" title="Start Production" onClick={()=>this.startMO(data.manufacturingOrderId, data.id)}/>
                                                        :
                                                        (data.is_MoStart && data.is_MoPaused === false) ?
                                                            <Fi.FiPauseCircle className="employee___production__buttons" title="Pause Production" onClick={()=>this.setPauseMO(data)} />
                                                        :
                                                            <Fi.FiPlayCircle className="employee___production__buttons" title="Start Production" onClick={()=>this.PlayPauseMO(data.productionDetailId, data.id, 'play')}/>
                                                    :
                                                        null
                                                }
                                            </div>
                                            <div>
                                                {
                                                    data.is_MoFinished === false &&
                                                        <Cg.CgPlayStopO className="employee___production__buttons" title="Finish Production" onClick={()=>this.setStopScreen(data)} />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    {
                        this.state.isLoading &&
                        <Loading />
                    }
                </div>
                <Modal size="lg" show={this.state.showHidePuaseScreen} onHide={()=>this.setHidePauseScreen()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.state.currentOperationDetail !== null &&
                                <div className='text-capitalize'>
                                    {this.state.currentOperationDetail.manufacturingOrderNumber.toUpperCase()}&nbsp;
                                    {this.state.currentOperationDetail.operation} for&nbsp;
                                    {this.state.currentOperationDetail.product}
                                </div>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='__pause_qty__detail_container'>
                            <div className='__pause_qty__title'>Add your completed quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateCompletedQty(e.target.value)}
                                value={this.state.completeQuantity} 
                            />
                             <div className='__pause_qty__title'>Add your rejected quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateRejectedQty(e.target.value)}
                                value={this.state.rejectedQuantity} 
                            />
                            <div className='__pause_note__title'>Add a note</div>

                            <textarea 
                                className='text-area'
                                value={this.state.productionNote}
                                onChange={(e)=>this.setState({ productionNote: e.target.value })}
                            />
                            <div 
                                className="sign-btn-outline" 
                                onClick={()=> this.PlayPauseMO(this.state.currentOperationDetail.productionDetailId, this.state.currentOperationDetail.id, 'pause')}
                            >
                                Submit
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={this.state.stopProductionScreen} onHide={()=>this.setHidePauseScreen()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.state.currentOperationDetail !== null &&
                                <div className='text-capitalize'>
                                    {this.state.currentOperationDetail.manufacturingOrderNumber.toUpperCase()}&nbsp;
                                    {this.state.currentOperationDetail.operation} for&nbsp;
                                    {this.state.currentOperationDetail.product}
                                </div>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='__pause_qty__detail_container'>
                            <div className='__pause_qty__title'>Add your completed quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateCompletedQty(e.target.value)}
                                value={this.state.completeQuantity} 
                            />
                             <div className='__pause_qty__title'>Add your rejected quantity</div>
                            <input 
                                type="text" 
                                placeholder="Add quantity" 
                                className='input-box' 
                                onChange={(e)=> this.updateRejectedQty(e.target.value)}
                                value={this.state.rejectedQuantity} 
                            />
                            <div className='__pause_note__title'>Add a note</div>

                            <textarea 
                                className='text-area'
                                value={this.state.productionNote}
                                onChange={(e)=>this.setState({ productionNote: e.target.value })}
                            />
                            <div 
                                className="sign-btn-outline" 
                                onClick={()=> this.countAndStopMO(this.state.currentOperationDetail.productionDetailId)}
                            >
                                Submit
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

export default EmployeeProductionPlan;