import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as ExportData from './exportData'
import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';

import Popup from 'reactjs-popup';

import * as APIManager from './../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'

import {
    ImArrowDown2,
    ImArrowUp2
} from "react-icons/im";

import {
    AiOutlineExclamation
} from "react-icons/ai"

import {
    FiPlus,
    FiX
} from "react-icons/fi";

import Loading from '../../../components/Loading';
import TableLoading from '../../../helper/TableLoading';

export default class Vendors extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            responseVendors: [],

            number: "",
            name: "",
            Email: "",
            Skype: "",
            Address: "",
            Phone: "",
            note: "",
            minOnTime: "",
            maxOnTime: "",
            minAverageDelay: "",
            maxAverageDelay: "",
            url: "",
            minTotalCost: "",
            maxTotalCost: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

            selectedFile: null,
            showImportPopUp: false,
            isUploadStarting: false
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseVendors.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ responseVendors: sortedOrders }, () => {
                this.setFilterData()
            });
        });
        
    };
    //******************* ascending descending order function end ********************************

    setFilterData = () => {
        let filterDataArray = {
            'number': this.state.number,
            'name': this.state.name,
            'Email': this.state.Email,
            'Skype': this.state.Skype,
            'Address': this.state.Address,
            'Phone': this.state.Phone,
            'note': this.state.note,
            'minOnTime': this.state.minOnTime,
            'maxOnTime': this.state.maxOnTime,
            'minAverageDelay': this.state.minAverageDelay,
            'maxAverageDelay': this.state.maxAverageDelay,
            'url': this.state.url,
            'minTotalCost': this.state.minTotalCost,
            'maxTotalCost': this.state.maxTotalCost,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage
        }
        localStorage.setItem("procurementPurchaseVendor", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("procurementPurchaseVendor"))
        if (filterData !== null) {
            this.setState({
                number: filterData.number,
                name: filterData.name,
                Email: filterData.Email,
                Skype: filterData.Skype,
                Address: filterData.Address,
                Phone: filterData.Phone,
                note: filterData.note,
                minOnTime: filterData.minOnTime,
                maxOnTime: filterData.maxOnTime,
                minAverageDelay: filterData.minAverageDelay,
                maxAverageDelay: filterData.maxAverageDelay,
                url: filterData.url,
                minTotalCost: filterData.minTotalCost,
                maxTotalCost: filterData.maxTotalCost,
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getVendorList()
            })
        } else {
            this.getVendorList()

        }
    }



    clearSearch = () => {
        localStorage.removeItem("procurementPurchaseVendor")
        this.setState({
            number: "",
            name: "",
            Email: "",
            Skype: "",
            Address: "",
            Phone: "",
            note: "",
            minOnTime: "",
            maxOnTime: "",
            minAverageDelay: "",
            maxAverageDelay: "",
            url: "",
            minTotalCost: "",
            maxTotalCost: "",
            start: 0,
            length: 25,
            activePage: 1,
        }, () => {
            this.getVendorList()
        })
    }

    getVendorList = () => {
        let {
            number,
            name,
            Email,
            Skype,
            Address,
            Phone,
            note,
            minOnTime,
            maxOnTime,
            minAverageDelay,
            maxAverageDelay,
            start,
            length,
            url,
            minTotalCost,
            maxTotalCost
        } = this.state;

        this.setState({ isLoading: true, error: false })
        APIManager.callGetVendor(number, name, Email, Skype, Address, Phone, note, minOnTime, maxOnTime, minAverageDelay, maxAverageDelay, url, minTotalCost, maxTotalCost, start, length)
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseVendors: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()

                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false
                })
            });
    }

    exportRecords = (type) => {
        if (type === "pdf") {
            ExportData.exportInvoicePDF(this.state.responseVendors);
        } else {
            ExportData.exportInvoiceCSV(this.state.responseVendors);
        }
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0
        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.setFilterData()
            this.getVendorList()
        });
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }



    addVendor = () => {
        let slug = "Procurement_Vendors"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createvendor"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editVendor = (id) => {
        localStorage.setItem("vendorId", id)
        this.props.history.push({
            pathname: "/editvendor"
        })


    }


    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    onFileUpload = () => {
        let {
            selectedFile
        } = this.state

        if (selectedFile) {
            this.setState({ isLoading: true, error: false, isUploadStarting: true })
            APIManager.callUploadVendorCSV(selectedFile)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        success: true,
                        isUploadStarting: false,
                        successmessage: "Vendor(s) imported successfully."
                    }, () => {
                        this.getVendorList()
                    })
                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        isUploadStarting: false,
                        errormessage: errors
                    })
                });
        } else {
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select file to upload"

            })
        }
    }

    onClosePopup = () => {
        this.setState({
            error: false,
            success: false,
            selectedFile: null
        })
    }

    downloadSampleCSV = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDownloadSampleVendorCSV()
            .then(response => {
                //Build a URL from the file
                const fileURL = APIManager.BASE_URL + '/public' + response;
                //Open the URL on new Window      
                this.setState({
                    isLoading: false,
                }, () => {
                    this.openInNewTab(fileURL)
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isVendors={true} />

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.Procurement.Vendors}</div>
                            </div>

                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportRecords('pdf')}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportRecords('csv')}>CSV <ImArrowDown2 /></div>
                                    <Popup modal arrow={true} trigger={<div className="button">Import from CSV <ImArrowUp2 /></div>} position="center" onClose={() => this.onClosePopup()}>
                                        <div className='head-import-buttons selectFile__Popup__Item'>
                                            <div className="pagecontent">
                                                {
                                                    this.state.error &&
                                                    <div className="mb-2">
                                                        <div className="errornotification">
                                                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.success &&
                                                    <div className="mb-2">
                                                        <div className="successnotification">
                                                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                onChange={this.onFileChange}
                                            ></input>

                                            {
                                                this.state.isUploadStarting ?
                                                    <div className="button mt-2">Upload <ImArrowUp2 /></div>
                                                    :
                                                    <div className="button mt-2" onClick={() => this.onFileUpload()}>Upload <ImArrowUp2 /></div>
                                            }

                                        </div>
                                    </Popup>
                                    <div className="button" onClick={() => this.downloadSampleCSV('csv')}>Sample CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tableContent __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addVendor()}><FiPlus /></div></th>

                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate' , false)}><span>Number </span>{this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 150, }} onClick={() => this.sortCustomer('name', 'notDate' , false)}><span>Name </span>{this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 150, }} onClick={() => this.sortCustomer('onTime', 'notDate' , false)}><span>On time</span> {this.state.fieldName === "onTime" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "onTime" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 120, }} onClick={() => this.sortCustomer('averageDelay', 'notDate' , false)}><span>Average delay </span>{this.state.fieldName === "averageDelay" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "averageDelay" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('Phone', 'notDate' , false)}><span>Phone </span>{this.state.fieldName === "Phone" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Phone" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('Skype', 'notDate' , false)}><span>Skype </span>{this.state.fieldName === "Skype" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Skype" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 150, }} onClick={() => this.sortCustomer('Web', 'notDate' , false)}><span>URL </span>{this.state.fieldName === "Web" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Web" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 220, }} onClick={() => this.sortCustomer('Address', 'notDate' , false)}><span>Address </span>{this.state.fieldName === "Address" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Address" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" style={{ width: 120, }} onClick={() => this.sortCustomer('totalCost', 'notDate' , false)}><span>Total Cost</span> {this.state.fieldName === "totalCost" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "totalCost" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    {/* <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><FiChevronsDown /></th>} position="bottom right">
                            <div className="selectColumns">
                                <ul>
                                    <li><label htmlFor="number"><span>Number</span> <input type="checkbox" id="number" /></label></li> 
                                </ul>
                            </div>
                        </Popup> */}
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addVendor()}><FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getVendorList() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => { this.getVendorList() })} /></th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minOnTime} onChange={(e) => this.setState({ minOnTime: e.target.value }, () => { this.getVendorList() })} /> %&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxOnTime} onChange={(e) => this.setState({ maxOnTime: e.target.value }, () => { this.getVendorList() })} /> %
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minAverageDelay} onChange={(e) => this.setState({ minAverageDelay: e.target.value }, () => { this.getVendorList() })} /> %&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxAverageDelay} onChange={(e) => this.setState({ maxAverageDelay: e.target.value }, () => { this.getVendorList() })} /> %
                                    </th>
                                    <th className="tableElemContain"><input type="text" value={this.state.Phone} onChange={(e) => this.setState({ Phone: e.target.value }, () => { this.getVendorList() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.Skype} onChange={(e) => this.setState({ Skype: e.target.value }, () => { this.getVendorList() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.url} onChange={(e) => this.setState({ url: e.target.value }, () => { this.getVendorList() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.Address} onChange={(e) => this.setState({ Address: e.target.value }, () => { this.getVendorList() })} /></th>

                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minTotalCost} onChange={(e) => this.setState({ minTotalCost: e.target.value }, () => { this.getVendorList() })} /> &nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxTotalCost} onChange={(e) => this.setState({ maxTotalCost: e.target.value }, () => { this.getVendorList() })} />
                                    </th>

                                    {/* <th className="tableElemContain tableAddButton"><div onClick={()=>this.getVendorList()} className="cursor-pointer"><FiSearch /></div></th> */}
                                    <th className="tableElemContain tableAddButton"><div onClick={() => this.clearSearch()} className="cursor-pointer"><FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseVendors !== null &&
                                            this.state.responseVendors.map((data, i) => (
                                                <tr key={data._id} onClick={this.editVendor.bind(this, data._id)}>
                                                    <td>{this.state.start === 0 ? 1 + i : this.state.start + i}</td>
                                                    <td>{data.number}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.onTime ? data.onTime + ' %' : ''}</td>
                                                    <td>{data.averageDelay ? data.averageDelay + ' %' : ''}</td>
                                                    <td>{data.Phone}</td>
                                                    <td className='text-lowercase'>{data.Skype}</td>
                                                    <td className='text-lowercase'>{data.Web}</td>
                                                    <td>{typeof (data.Address) !== 'undefined' ? data.Address.split('_').join(' ') : null}</td>
                                                    <td>{data.totalCost}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}