import React from 'react';
import { Link } from 'react-router-dom';

const pages = [
    {
        "to": "/items",
        "class": "innerHeaderList",
        "name": "Items",
        "isItems": true,
        "hide": false
    },{
        "to": "/stocksettings",
        "class": "innerHeaderList",
        "name": "Stock settings",
        "isStockSettings": true,
        "hide": false
    },{
        "to": "/stocklots",
        "class": "innerHeaderList",
        "name": "Stock lots",
        "isStockLots": true,
        "hide": false
    },{
        "to": "/shipments",
        "class": "innerHeaderList",
        "name": "Shipments",
        "isShipments": true,
        "hide": false
    },{
        "to": "/inventory",
        "class": "innerHeaderList",
        "name": "Inventory",
        "isInventory": true,
        "hide": true
    },{
        "to": "/criticalonhand",
        "class": "innerHeaderList",
        "name": "Critical on-hand",
        "isCriticalOnHand": true,
        "hide": false
    },{
        "to": "/writeoffs",
        "class": "innerHeaderList",
        "name": "Write-offs",
        "isWriteOffs": true,
        "hide": false
    },{
        "to": "/stockmovement",
        "class": "innerHeaderList",
        "name": "Stock movement",
        "isStockMovement": true,
        "hide": true
    },{
        "to": "/filtertable",
        "class": "innerHeaderList",
        "name": "Filter table",
        "isFilterTable": true,
        "hide": false
    }
]

export default class StockHeader extends React.Component{


    constructor(props){
        super(props)
        this.state = {
            tabs: [],
            displayTabs: []
        }
    }

    componentDidMount(){
        this.checkPermissions()
    }

    checkPermissions = () => {
        let localPermissions = localStorage.getItem("permissions")
        if(localPermissions !== null){
            let permissions = JSON.parse(localStorage.getItem("permissions"))
            if(permissions !== undefined && permissions.length > 0){
                let updatedRecord = pages.map(obj => {
                    let permit = permissions.find(lo => lo.name === obj.name)
                    if(permit !== undefined)
                        return obj
                });
                this.setState({
                    tabs: updatedRecord
                },()=>{
                    this.setDisplayTabs()
                })
            }else{
                this.setState({
                    tabs: pages
                },()=>{
                    this.setDisplayTabs()
                })
            }
        }
    }

    setDisplayTabs = () => {
        let {
            isItems,
            isStockSettings,
            isStockLots,
            isShipments,
            isInventory,
            isCriticalOnHand,
            isWriteOffs,
            isStockMovement,
            isFilterTable
        } = this.props;

        let dispTabs = []

        this.state.tabs.map((data, i) => {
            if(data !== undefined){

                let isActive = ""

                if(data.name === "Items" && isItems === true)
                    isActive = "active"

                if(data.name === "Stock settings" && isStockSettings === true)
                    isActive = "active"

                if(data.name === "Stock lots" && isStockLots === true)
                    isActive = "active"

                if(data.name === "Shipments" && isShipments === true)
                    isActive = "active"

                if(data.name === "Inventory" && isInventory === true)
                    isActive = "active"

                if(data.name === "Critical on-hand" && isCriticalOnHand === true)
                    isActive = "active"

                if(data.name === "Write-offs" && isWriteOffs === true)
                    isActive = "active"

                if(data.name === "Stock movement" && isStockMovement === true)
                    isActive = "active"

                if(data.name === "Filter table" && isFilterTable === true)
                    isActive = "active"

                dispTabs.push(
                    <Link 
                        key={data.name} 
                        to={data.to} 
                        className={data.hide ? "d-none" : data.class+" "+isActive}
                    >
                        {data.name} 
                    </Link>
                )
            }
        })

        this.setState({
            displayTabs: dispTabs
        })
    }

    render() {
        

        return(
            <div className="header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                            <div className="innerHeader">
                                { this.state.displayTabs }
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
