import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Header } from './../../components'
import Strings from './../../constants/Strings'

class FreeUse extends Component {
    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isFreeUse={true} />
                <div className="main-container row bg-white">
                    <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                        <div className="page-title">{Strings.FreeUse.title}</div>
                    </div>

                    <div className="col-lg-12 col-sm-8 col-md-8 col-xs-12">
                        <div className="demoTitle mt-0">Demo videos</div>
                        <div className="demoHighlight">Watch our first video and get 10 free days</div>
                        <div className="otherDemo">Watch other demo <Link to="/">videos here</Link></div>

                        <div className="demoTitle">Follow us on LinkedIn</div>
                        <div className="demoHighlight">Follow us on LinkedIn and get 5 free days</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FreeUse;