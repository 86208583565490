import React from 'react';
import { Header, StockHeader } from './../../../components'

import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

import { BiRupee } from 'react-icons/bi';

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';

export default class AddItem extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            itemId: "",
            itemNo: "",
            itemName: "",
            UoM: "",

            vendorList: [],

            vendorId: "",
            partNo: "",
            priority: "",
            leadTime: "",
            pricePerUoM: "",
            minQuantity: "",
            isUnitOfMeasurement: false,
            vendorUoM: "",
            is_invisibleUoM: false,
            conversionRate: "",
            hsCode: "",
            tax: "",
            taxCredit: "",

        }
    }

    componentDidMount(){
        let itemId = localStorage.getItem("itmId")
        let itemNo = localStorage.getItem("itmIdNum")
        let itemName = localStorage.getItem("itmName")
        let itemUoM = localStorage.getItem("itmUoM")

        this.setState({
            itemId: itemId,
            itemNo: itemNo,
            itemName: itemName,
            UoM: itemUoM
        },()=>{
            this.getVendorList()
        })
    }

    getVendorList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorList()
        .then(response => {
            this.setState({ 
                isLoading: false, 
                vendorList: response 
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getSingleVendor = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                leadTime: response.defaultLeadTime !== null ? response.defaultLeadTime : ""
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
            })
        });   
    }

    savePurchaseTerms = () => {
        let {
            itemId,
            vendorId,
            partNo,
            priority,
            leadTime,
            pricePerUoM,
            minQuantity,
            isUnitOfMeasurement,
            vendorUoM,
            is_invisibleUoM,
            conversionRate,
            hsCode,
            tax,
            taxCredit
        } = this.state

        if(vendorId === ""){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please select Vendor"
            })
        }else if(tax === ""){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please add Tax"
            })
        }else if(taxCredit === ""){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please select Eligible Tax Type"
            })
        }else if(leadTime === ""){
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Please enter lead time"
            });
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreatePurchaseItem(itemId,vendorId,partNo,priority,leadTime,pricePerUoM,minQuantity,isUnitOfMeasurement,vendorUoM,is_invisibleUoM,conversionRate,hsCode,tax,taxCredit)
            .then(response => {
                this.setState({ isLoading: false, error: false, },()=>{
                    localStorage.setItem("createNewPurchaseTerms", true)
                    localStorage.setItem("purchaseTermId", response._id)
                    this.props.history.push('/editpurchaseterms')
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }
    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    addNewVendor = () => {
        this.props.history.push('/createvendor')
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isItems={true}/>
                <div className="main-container">
                   
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Add a vendor for {this.state.itemNo.toUpperCase()} {this.state.itemName.length > 20 ? this.state.itemName.substring(0, 20)+'...' : this.state.itemName}</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    </div>
                    <div className="container white-bg">
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <div className="row mt-2 mb-2">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-center">
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
                                        <div className="__formBtnSecondary float-left mr-2" onClick={()=>this.goBack()}>Back</div>
                                        <div className="__formBtnPrimary float-left" onClick={()=>this.savePurchaseTerms()}>Save</div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Product</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <div className="__formTextVal">{this.state.itemNo.toUpperCase()} {this.state.itemName.length > 20 ? this.state.itemName.substring(0, 20)+'...' : this.state.itemName}</div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Vendor *</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <Select2
                                            data={
                                                this.state.vendorList.map((data) => (
                                                    { id: data._id, text: data.number.toUpperCase() +" "+data.name }
                                                ))
                                            }
                                            value={this.state.vendorId}
                                            options={{placeholder: 'Select Vendor'}}
                                            onSelect={(e)=> this.setState({ vendorId: e.target.value },()=>{ this.getSingleVendor(e.target.value) })}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Vendor part no.</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input type="text" className="__formInputElem" onChange={(e)=>this.setState({ partNo: e.target.value })} />
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">HS Code</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input type="text" className="__formInputElem" value={this.state.hsCode} onChange={(e)=>this.setState({ hsCode: e.target.value })} />
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Tax*</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input type="text" className="__formInputElem" value={this.state.tax} onChange={(e)=>this.setState({ tax: (e.target.value >= 0 && e.target.value <= 100) ? e.target.value : 0 })} />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <div className="__formTextVal">%</div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Eligible Tax*</div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <select className='select-box' value={this.state.taxCredit} onChange={(e)=>this.setState({ taxCredit: e.target.value })}>
                                            <option value="">Select Type</option>
                                            <option value="eligible for itc input">Eligible for ITC Input</option>
                                            <option value="eligible for itc goods">Eligible for ITC Goods</option>
                                            <option value="ineligible section 17(5)">Ineligible Section 17(5)</option>
                                            <option value="ineligible others">Ineligible Others</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Priority</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input type="text" className="__formInputElem" value={this.state.priority} onChange={(e)=>this.setState({ priority: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} />
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Lead time *</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input type="text" className="__formInputElem" value={this.state.leadTime} onChange={(e)=>this.setState({ leadTime: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <div className="__formTextVal">days</div>
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="">Vendor-specific unit of measurement</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <div className="__formTextVal">
                                            <input 
                                                type="checkbox" 
                                                className="__formCheckboxElem" 
                                                checked={this.state.isUnitOfMeasurement} 
                                                onChange={()=>
                                                    this.setState({ 
                                                        isUnitOfMeasurement: !this.state.isUnitOfMeasurement,
                                                        vendorUoM: "",
                                                        is_invisibleUoM: false,
                                                        conversionRate: "",
                                                    })
                                                } 
                                            /> Yes
                                        </div>
                                    </div>
                                </div>


                                {
                                    this.state.isUnitOfMeasurement &&
                                    <div>
                                        <div className="row mb-1">
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                                <div className="__formTextVal">Vendor's UoM</div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                                <input type="text" className="__formInputElem" onChange={(e)=>this.setState({ vendorUoM: e.target.value })} />
                                            </div>
                                        </div>
                                        {/* <div className="row mb-1">
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                                <div className="__formTextVal">Indivisible UoM</div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                                <div className="__formTextVal">
                                                    <input type="checkbox" className="__formCheckboxElem" checked={this.state.is_invisibleUoM} onChange={()=>this.setState({ is_invisibleUoM: !this.state.is_invisibleUoM })} /> Yes
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row mb-2">
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                                <div className="__formTextVal">Conversion rate</div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                                <input type="text" className="__formInputElem" onChange={(e)=>this.setState({ conversionRate: e.target.value })} />
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                                <div className="__formTextVal"> {this.state.vendorUoM} = 1 {this.state.UoM}</div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Price per UoM</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input 
                                            type="text" 
                                            className="__formInputElem" 
                                            value={this.state.pricePerUoM} 
                                            onChange={(e)=> {
                                                let rgx = /^[0-9]*\.?[0-9]*$/;
                                                this.setState({ pricePerUoM: (e.target.value).match(rgx) ? e.target.value : "" })
                                            }
                                            } />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <div className="__formTextVal"><BiRupee /></div>
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-right">
                                        <div className="__formTextVal">Min. quantity</div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                        <input 
                                            type="text" 
                                            className="__formInputElem" 
                                            value={this.state.minQuantity} 
                                            onChange={(e) => {
                                                let rgx = /^[0-9]*\.?[0-9]*$/;
                                                this.setState({ minQuantity: (e.target.value).match(rgx) ? e.target.value : "" })
                                            }} />
                                    </div>
                                    {
                                        this.state.isUnitOfMeasurement !== true ?
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                            <div className="__formTextVal">{this.state.UoM}</div>
                                        </div>
                                        :
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-left">
                                            <div className="__formTextVal">{this.state.vendorUoM}</div>
                                        </div>
                                    }
                                </div>

                                
                                
                                <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12 text-center">
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6 col-xs-12 text-center">
                                        <div className="__formBtnSecondary float-left mr-2" onClick={()=>this.goBack()}>Back</div>
                                        <div className="__formBtnPrimary float-left" onClick={()=>this.savePurchaseTerms()}>Save</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}