import React, { Component } from 'react';
import { Header } from './../../components'
import Strings from './../../constants/Strings'

import { ImArrowDown2 } from "react-icons/im";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";

import ProductionCalendar from './Calendar';
import Table from './Table';

import * as ExportData from './exportData'

class MyProductionPlan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isTableView: false,
            tableResponse: [],
        }
        this.updateTableRecords = this.updateTableRecords.bind(this)
    }
    
    updateTableRecords = (res) => {
        this.setState({
            tableResponse: res
        })
    }

    exportAsPDF = () => {
        ExportData.exportPDF(this.state.tableResponse)
    }

    exportAsCSV = () => {
        ExportData.exportCSV(this.state.tableResponse)
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isMyProductionPlan={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                                <div className="page-title">{Strings.MyProductionPlan.title}</div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="joined-buttons __my_production_plan">
                                        <div 
                                            className={!this.state.isTableView ? "btn-left active" : "btn-left"}
                                            onClick={()=>this.setState({ isTableView: false })}
                                        >
                                            <FaRegCalendarAlt />
                                        </div>
                                        <div 
                                            className={this.state.isTableView ? "btn-rght active" : "btn-rght"}
                                            onClick={()=>this.setState({ isTableView: true })}
                                        >
                                            <FiAlignJustify />
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="button" onClick={()=>this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={()=>this.exportAsCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.isTableView ?
                            <div>
                                <Table 
                                    history={this.props.history}
                                    updateTableRecords={this.updateTableRecords}
                                />
                            </div>
                        :
                            <div>
                                <ProductionCalendar 
                                    history={this.props.history}
                                />
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default MyProductionPlan;