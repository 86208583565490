import React, { Component } from 'react';

import Select2 from './../../../../../lib/select2/select2';
import './../../../../../lib/select2/select2.css'

import DatePicker from "react-datepicker";
import './../../../../../lib/react-datepicker.min.css';
import * as Ai from 'react-icons/ai';
import * as Fi from 'react-icons/fi'
import { validateNumber, validatePercentage } from './../../../../../constants/Validate'
import * as APIManager from './../../../../../APIManager/APIManager'

class AddProductDetail extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            onBlurCall: this.props.onBlurProduct,
            count: this.props.counter,

            productGroupList: this.props.productGroupList,
            productList: [],
            productData: [],

            productObj: {
                'productGroup': '',
                'product': '',
                'productFreeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subtotal': '',
                'deliverDate': '',
                'rawtotal': '',
            },

            productgroup: "",
            product: "",
            productfreetext: "",
            quantity: "",
            priceperunit: "",
            discount: "",
            subtotal: "",
            rawtotal: "",
            deliverydate: "",
            itemTax: 0,
            totalTax: 0,
            minSellingPrice: 0,
            applicableTax: [],
            applicableTaxLocal: [],

            uom: "",
        }
    }

    componentDidMount(){
        let applicableTaxLocal = localStorage.getItem("applicableTax");
        if(applicableTaxLocal !== null && applicableTaxLocal !== ""){
            let appTax = applicableTaxLocal.split(',')
            if(appTax.includes("exemptedtax")){
                appTax = ["IGST"]
            }
            this.setState({
                applicableTax: appTax,
                applicableTaxLocal: applicableTaxLocal.split(',')
            })
        }
    }



    getItemsByGroup = (_id) => {
        this.setState({ 
            isLoading: true, 
            error: false, 
        })
        APIManager.callProductGroupItemsWithSellingPrice(_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productfreetext: "", 
                priceperunit: "", 
                uom: "",
                productList: response
            },()=>{
                this.generateObject('productGroup', _id)
                this.generateObject('product', '')
                this.generateObject('productFreeText', '')
                this.generateObject('pricePerUoM', '')
                this.state.onBlurCall(this.state.productObj, this.state.count)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemsByGroup = (_id) => {
        this.setState({ 
            isLoading: true, 
            error: false, 
        })
        APIManager.callProductGroupItemsWithSellingPriceForCreate(_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productfreetext: "", 
                priceperunit: "", 
                uom: "",
                productList: response
            },()=>{
                this.generateObject('productGroup', _id)
                this.generateObject('product', '')
                this.generateObject('productFreeText', '')
                this.generateObject('pricePerUoM', '')
                this.state.onBlurCall(this.state.productObj, this.state.count)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    countSubTotal = () => {
        let { quantity, priceperunit, discount, totalTax, applicableTax, applicableTaxLocal  } = this.state;
        if(quantity !== "" && priceperunit !== "" && !isNaN(priceperunit) !== false){
            if(validateNumber(quantity)){
                let total = parseFloat(quantity*priceperunit)
                if(applicableTax.length > 0){
                    let rawTotalWithoutDisc = parseFloat(total + (total*totalTax/100))
                    let taxAmountWithoutDisc = parseFloat(total*totalTax/100)
                    taxAmountWithoutDisc = taxAmountWithoutDisc/applicableTax.length
                    if(discount !== "" && validateNumber(discount)){
                        let withdiscount = parseFloat(total - ( total*discount/100 ))
                        let rawtotal = parseFloat(withdiscount + ( withdiscount*totalTax/100 ))
                        let itemTaxWithDiscount = parseFloat(withdiscount*totalTax/100)
                        itemTaxWithDiscount = itemTaxWithDiscount/applicableTax.length

                        let finalRawTotal = applicableTaxLocal.includes("exemptedtax") ? withdiscount.toFixed(2) : rawtotal.toFixed(2)
                        this.setState({
                            subtotal: withdiscount.toFixed(2),
                            rawtotal: finalRawTotal,
                            itemTax: itemTaxWithDiscount.toFixed(2) 
                        },()=>{
                            this.generateObject('subtotal', withdiscount.toFixed(2))
                            this.generateObject('rawtotal', finalRawTotal)
                        })
                    }else{
                        let finalRawTotal = applicableTaxLocal.includes("exemptedtax") ? total.toFixed(2) : rawTotalWithoutDisc.toFixed(2)
                        this.setState({
                            subtotal: total.toFixed(2),
                            rawtotal: finalRawTotal,
                            itemTax: taxAmountWithoutDisc.toFixed(2) 
                        },() => {
                            this.generateObject('subtotal', total.toFixed(2))
                            this.generateObject('rawtotal', finalRawTotal)
                        })
                    }
                }else{
                    let rawTotalWithoutDisc = parseFloat(total)
                    if(discount !== "" && validateNumber(discount)){
                        let withdiscount = parseFloat(total - ( total*discount/100 ))
                        let rawtotal = parseFloat(total - ( total*discount/100 ))
                        this.setState({
                            subtotal: withdiscount.toFixed(2),
                            rawtotal: rawtotal.toFixed(2),
                            itemTax: 0 
                        },()=>{
                            this.generateObject('subtotal', withdiscount.toFixed(2))
                            this.generateObject('rawtotal', rawtotal.toFixed(2))
                        })
                    }else{
                        this.setState({
                            subtotal: total.toFixed(2),
                            rawtotal: rawTotalWithoutDisc.toFixed(2),
                            itemTax: 0 
                        },() => {
                            this.generateObject('subtotal', total.toFixed(2))
                            this.generateObject('rawtotal', rawTotalWithoutDisc.toFixed(2))
                        })
                    }
                }
            }else{
                this.setState({
                    subtotal: "",
                    rawtotal: "",
                    itemTax: 0,
                })
            }
        }else{
            this.setState({
                subtotal: "",
                rawtotal: "",
                itemTax: 0,
            },()=>{
                this.generateObject('subtotal', 0)
                this.generateObject('rawtotal', 0)
            })
        }
    }

    generateObject = (type, value) => {        
        let productObj = this.state.productObj
    
        if(type === 'productGroup')
            productObj.productGroup = value;
        if(type === 'product')
            productObj.product = value;
        if(type === 'productFreeText')
            productObj.productFreeText = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if(type === 'quantity')
            productObj.quantity = value;
        if(type === 'pricePerUoM')
            productObj.pricePerUoM = value;
        if(type === 'discount')
            productObj.discount = value ? parseFloat(value).toFixed(2) : '';
        if(type === 'subtotal')
            productObj.subtotal = value;
        if(type === 'deliverDate'){
            let dt = new Date(value);
            let dd = dt.getDate();
            let mm = dt.getMonth() + 1;
            let yyyy = dt.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let date = mm + '/' + dd + '/' + yyyy;
            productObj.deliverDate = date;
        }
        if(type === 'rawtotal')
            productObj.rawtotal = value;

        this.setState({
            productObj
        },()=>{
            this.state.onBlurCall(this.state.productObj, this.state.count)
        })
    }

    getItemDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {

            this.setState({
                isLoading: false,
                totalTax: response.itemData.tax,
                priceperunit: response.itemData.sellingPrice,
                productfreetext: response.itemData.freeText,
                uom: response.itemData.uomName ? response.itemData.uomName : "",
                minSellingPrice: response.itemData.minSellingPrice ? response.itemData.minSellingPrice : 0
            },() => {
                this.generateObject('productFreeText', response.itemData.freeText)
                this.generateObject('pricePerUoM', response.itemData.sellingPrice)
                this.countSubTotal()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    render() {
        let {
            onChangeProduct,
            onBlurProduct,
            onRemoveProduct,
            counter
        } = this.props
        return (
            // <tr className={this.state.priceperunit.toString().indexOf("-") > -1 ? '_order__danger__border subgroupdata' : 'subgroupdata'}>
            // <tr className={((this.state.subtotal / this.state.quantity) < this.state.minSellingPrice) || this.state.priceperunit < this.state.minSellingPrice ? '_order__danger__border subgroupdata' : 'subgroupdata'}>
            <tr className={((this.state.subtotal / this.state.quantity) < this.state.minSellingPrice) ? '_order__danger__border subgroupdata' : 'subgroupdata'}>
                
                <td className='text-center' style={{ width: 50 }}>
                    {counter+1}
                </td>
                
                <td>
                    <Select2    
                        data={
                            this.state.productGroupList.map((data) => (
                                { id: data._id, text: data.name }
                            ))
                        }
                        value={this.state.productGroup}
                        options={{placeholder: 'Select group'}}
                        onSelect={(e)=> this.setState({ productGroup: e.target.value },()=>{
                            this.getItemsByGroup(e.target.value);
                        })}
                    />
                </td>
                <td style={{ maxWidth: 200}}>
                    <Select2    
                        className="mb-1"
                        data={
                            this.state.productList.map((data) => (
                                { id: data._id, text: data.itemCode+' '+data.partDescription }
                            ))
                        }
                        value={this.state.product}
                        options={{placeholder: 'Select product'}}
                        onSelect={(e)=> this.setState({ product: e.target.value },()=>{
                            this.getItemDetail(e.target.value)
                            onChangeProduct(counter);
                            this.generateObject('product', e.target.value)
                            onBlurProduct(this.state.productObj, counter)
                        })}
                    />

                    <textarea 
                        placeholder='Free text' 
                        value={this.state.productfreetext}
                        className='textarea mt-1' 
                        onChange={(e)=>{
                            this.setState({ 
                                productfreetext: e.target.value
                            },()=>{
                                onChangeProduct(counter);
                                this.generateObject('productFreeText', e.target.value)
                            })
                        }}
                        onBlur={()=>onBlurProduct(this.state.productObj, counter)}
                    />
                </td>
                <td style={{width: 100}}>
                    <input 
                        type='text' 
                        className='input-box width50' 
                        value={this.state.quantity} 
                        onChange={(e)=>{
                                if(validateNumber(e.target.value)){
                                    this.setState({ 
                                        quantity: e.target.value
                                    },()=>{ 
                                        this.countSubTotal() 
                                    })
                                }
                            } 
                        }
                        onBlur={(e)=>{
                            this.generateObject('quantity', e.target.value)
                        }}
                    ></input> {this.state.uom}
                </td>
                <td style={{width: 90}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.priceperunit} 
                            onChange={(e)=>{
                                if(validateNumber(e.target.value))
                                this.setState({ priceperunit: e.target.value },()=>{ this.countSubTotal() })} 
                            }
                            onBlur={(e)=>{
                                this.generateObject('pricePerUoM', e.target.value)
                            }}
                        ></input>
                    </div>
                </td>
                <td style={{width: 80}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.discount} 
                            maxLength={5}
                            onChange={(e)=>{
                                if(validatePercentage(e.target.value))
                                this.setState({ discount: e.target.value },()=>{ this.countSubTotal() })} 
                            }
                            onBlur={(e)=>{
                                this.generateObject('discount', e.target.value)
                                onBlurProduct(this.state.productObj, counter)
                            }}
                        ></input>
                    </div>
                </td>

                

                <td style={{width: 140}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.subtotal}
                            disabled
                        ></input>
                    </div>
                </td>

                {
                    this.state.applicableTax.length > 0 &&
                    this.state.applicableTax.map((data)=>(
                        <td key={'tax'+data} style={{width: 110}}>
                            <div className='position-relative'>
                                <input 
                                    type="text" 
                                    value={this.state.itemTax === 0 ? "" : this.state.itemTax} 
                                    className='input-box' 
                                    disabled
                                />
                            </div>
                        </td>
                    ))
                }

                <td style={{width: 140}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.rawtotal}
                            disabled
                        ></input>
                    </div>
                </td>
                
                <td style={{width: 110}}>
                    <div className='position-relative'>
                        <DatePicker 
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            selected={
                                this.state.deliverydate
                            }
                            onChange={
                                date => this.setState({ deliverydate: date },()=>{
                                    this.generateObject('deliverDate', date)
                                    onBlurProduct(this.state.productObj, counter)
                                })
                            }
                            className="input-box"/>
                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                    </div>
                </td>
                <td style={{ width: 50 }}>
                    {
                        (this.state.product !== "" || this.state.productfreetext !== "") &&
                        <Fi.FiX className="extraFieldCloseButton" onClick={onRemoveProduct.bind(this,counter)} />
                    }
                </td>
            </tr>
        );
    }
}

export default AddProductDetail;