import React, { Component } from 'react';
import * as APIManager from './../../../APIManager/APIManager'
import { Header, ProductionPlanningHeader } from './../../../components'
import * as Ai from 'react-icons/ai'
import * as Fi from 'react-icons/fi'

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as LS from './../../../constants/SetLocalstorage'

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css'
import { convertDateStringFormatToMMDDYYObject, convertDateStringToDateAndTimeObject, convertDateTimeStringMMDDtoDDMM } from '../../../constants/DateFormatter';

class CreateManufacturingOrder extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",

            productGroupList: [],
            productList: [],
            BOM_List: [],
            userData: [],
            bomDetails: [],

            productGroup: LS.getLocalForm("__LocalData_create__MO", "productGroup"),
            product: LS.getLocalForm("__LocalData_create__MO", "product"),
            quantity: LS.getLocalForm("__LocalData_create__MO", "quantity"),
            dueDate: LS.getLocalForm("__LocalData_create__MO", "dueDate") ? new Date(LS.getLocalForm("__LocalData_create__MO", "dueDate")) : "",
            startDate: LS.getLocalForm("__LocalData_create__MO", "startDate") ? new Date(LS.getLocalForm("__LocalData_create__MO", "startDate")) : new Date(),
            finishDate: LS.getLocalForm("__LocalData_create__MO", "finishDate") ? new Date(LS.getLocalForm("__LocalData_create__MO", "finishDate")) : "",
            assignedUser: LS.getLocalForm("__LocalData_create__MO", "assignedTo"),
            selectedBOMnumber: LS.getLocalForm("__LocalData_create__MO", "selectedBOMnumber") ? LS.getLocalForm("__LocalData_create__MO", "selectedBOMnumber") : 0,
            selectedRoutingnumber: LS.getLocalForm("__LocalData_create__MO", "selectedRoutingnumber") ? LS.getLocalForm("__LocalData_create__MO", "selectedRoutingnumber") : 0,
            department: LS.getLocalForm("__LocalData_create__MO", "department"),

            billOfMaterials: [],
            routingDetails: [],
            departmentData: [],

        }
    }


    componentDidMount(){

        this.getProductGroup()

        this.getItemDetail()

        this.getAllDepartments()

        if(this.state.department){
            this.getUsersByDepartment(this.state.department)
        }

        if(this.state.productGroup !== ""){
            this.getItemsByGroup(this.state.productGroup)
        }
       
        if(this.state.quantity !== ""){
            this.getBOMOnQtyChange()
        }
        
    }

   
    getAllDepartments = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllDepartment("", "")
        .then(response => {
            this.setState({ 
                isLoading: false,
                departmentData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    getUsersByDepartment = (_id) => {
        if(_id){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetUsersByDepartment(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    userData: response.data
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callProductGroupList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                productGroupList: response.productGroup
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
        .then(response => {
            this.setState({
                isLoading: false,
                userData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemsByGroup = (_id) => {
        if(_id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callProductGroupItemsList(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    productList: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    getItemsByGroup = (_id) => {
        if(_id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callProductGroupItemsListForCreate(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    productList: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }



    getBOMOnQtyChange = () => {
        if(this.state.product === "" || this.state.quantity === ""){
            this.setState({
                BOM_List: [],
                dueDate: "",
                finishDate: "",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callBOMByProduct(this.state.product, 'true')
            .then(response => {
                let bomDetails = []
                for(let i=0;i<response.length;i++){
                    let obj = {
                        "bomId": response[i]._id,
                        "duration": response[i].routingDuration,
                        "quantity": this.state.quantity
                    }
                    bomDetails.push(obj)
                }

                this.setState({
                    isLoading: false,
                    bomDetails: bomDetails
                },()=>{
                    LS.setLocalForm("__LocalData_create__MO", "bomDetails", this.state.bomDetails)
                    this.getEndDate()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    getItemDetail = () => {
        if(this.state.product !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callSingleItem(this.state.product)
            .then(response => {
                this.setState({
                    isLoading: false,
                    uom: response.itemData.uomName
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    getEndDate = () => {
        let {
            startDate,
            bomDetails,
            product,
            quantity
        } = this.state;

        if(startDate){
            if(product === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please select product for MO"
                })
            }else if(quantity === ""){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please enter product quantity"
                })
            }else if(bomDetails.length < 1){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "No BOM available"
                })
            }else{
                var hours = startDate.getHours();
                var minutes = startDate.getMinutes();
                var ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                let newStartDate =  startDate.getMonth()+1  +"/"+ startDate.getDate() + "/" + startDate.getFullYear() + " " + strTime;

                this.setState({ isLoading: true, error: false })
                APIManager.callGetEndDateMO(newStartDate, bomDetails, quantity, "")
                .then(response => {
                    this.setState({
                        isLoading: false,
                        success: false,
                        error: false,
                        BOM_List: response.data
                    },()=>{
                        if(this.state.selectedBOMnumber !== "")
                            this.setBOMDetail(this.state.BOM_List[this.state.selectedBOMnumber])
                        else
                            this.setBOMDetail(this.state.BOM_List[0])
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
            }
        }
    }
     
    createMO = () => {
        let {
            product,
            productGroup,
            quantity,
            startDate,
            dueDate,
            billOfMaterials,
            routingDetails,
            assignedUser,
            department
        } = this.state



        if(productGroup === ""){
            this.setState({
                error: true,
                errormessage: "Please select product group",
                success: false
            })
        }else if(product === ""){
            this.setState({
                error: true,
                errormessage: "Please select product",
                success: false
            })
        }else if(quantity === ""){
            this.setState({
                error: true,
                errormessage: "Please enter quantity",
                success: false
            })
        }else if(startDate === ""){
            this.setState({
                error: true,
                errormessage: "please select start date",
                success: false
            })
        }else if(billOfMaterials.length === 0){
            this.setState({
                error: true,
                errormessage: "Please create BOM for the item",
                success: false
            })
        }else if(routingDetails.length === 0){
            this.setState({
                error: true,
                errormessage: "No routing available for this item",
                success: false
            })
        }else if(assignedUser === ""){
            this.setState({
                error: true,
                errormessage: "Please select user",
                success: false
            })
        }else{

            this.setState({ isLoading: true, error: false })
            APIManager.callCreateMO(productGroup,product,quantity,convertDateStringToDateAndTimeObject(startDate),convertDateStringToDateAndTimeObject(dueDate),assignedUser,JSON.stringify(billOfMaterials),JSON.stringify(routingDetails),department)
            .then(response => {
                LS.removeLS("__LocalData_create__MO")
                this.setState({
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editmo",
                        state: { MO_Id: response.data._id, newBOMEntry: true}
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }
   
    setBOMDetail = (data) => {
        if(data){
            let setBOM = []
            let setRouting = []
            let obj = {
                "bomId": data._id,
                "approximationCost": data.totalCost,
                "startDate": data.bomStartDate,
                "endDate": data.bomFinishDate
            }
            setBOM.push(obj)

            
            let routingObj = {
                "routingEndDate":  data.routingInfo.routingEndDate,
                "routingStartDate":  data.routingInfo.routingStartDate,
                "totalCost": data.routingInfo.totalCost,
                "routingId": data.routingInfo.id,
                "routingName": data.routingInfo.routingName,
                "workStationDetails": data.routingInfo.workStationDetails
            }

            setRouting.push(routingObj)

            this.setState({
                billOfMaterials: setBOM,
                routingDetails: setRouting,
                startDate: data.bomStartDate !== "" ? new Date(data.bomStartDate) : "",
                dueDate: data.bomFinishDate !== "" ? new Date(data.bomFinishDate) : "",
                finishDate: data.bomFinishDate !== "" ? new Date(data.bomFinishDate) : ""
            },()=>{
                LS.setLocalForm("__LocalData_create__MO", "setBOM", this.state.billOfMaterials)
                LS.setLocalForm("__LocalData_create__MO", "setRouting", this.state.routingDetails)
            })
        }
    }

    openBOMinfo = (id) => {
        this.props.history.push({
            pathname: "/bominfo",
            state: {BOM_id: id}
        })
    }

    goBack = () => {
        LS.removeLS("__LocalData_create__MO")
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isManufacturingOrders={true} />
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a manufacturing order</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                        {
                            this.state.error &&
                            <div>
                                <div className="errornotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div>
                                <div className="successnotification">
                                    <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createMO()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="">
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Product group</div>
                                    <div className="value">

                                        <Select2    
                                            data={
                                                this.state.productGroupList.map((data) => (
                                                    { id: data._id, text: data.name }
                                                ))
                                            }
                                            value={this.state.productGroup}
                                            options={{placeholder: 'Select Group'}}
                                            onSelect={(e)=> this.setState({ productGroup: e.target.value },()=>{
                                                LS.setLocalForm("__LocalData_create__MO", "productGroup", e.target.value)
                                                LS.setLocalForm("__LocalData_create__MO", "product", "")
                                                this.getItemsByGroup(this.state.productGroup)
                                            })}
                                        />

                                        {/* <select className="text-capitalize" 
                                            value={this.state.productGroup} 
                                            onChange={(e)=>this.setState({ productGroup: e.target.value},()=>{ 
                                                LS.setLocalForm("__LocalData_create__MO", "productGroup", e.target.value)
                                                LS.setLocalForm("__LocalData_create__MO", "product", "")
                                                this.getItemsByGroup(this.state.productGroup)
                                            }
                                        )}>
                                            <option value=''>Select group</option>
                                            {
                                                this.state.productGroupList.map((data) => (
                                                    <option key={"group__"+data._id} value={data._id}>{data.number.toUpperCase()} {data.name}</option>
                                                ))
                                            }
                                        </select> */}
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Product *</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.productList.map((data) => (
                                                    { id: data._id, text: data.itemCode.toUpperCase()+' '+data.partDescription }
                                                ))
                                            }
                                            value={this.state.product}
                                            options={{placeholder: 'Select product'}}
                                            onSelect={(e)=> this.setState({ 
                                                product: e.target.value,
                                                BOM_List: [],
                                                quantity: "",
                                             },()=>{
                                                LS.setLocalForm("__LocalData_create__MO", "quantity", this.state.quantity)
                                                LS.setLocalForm("__LocalData_create__MO", "product", e.target.value)
                                                this.getItemDetail()
                                            })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>


                                <div className="add_inner">
                                    <div className="title">Quantity</div>
                                    <div className="value">
                                        <input 
                                            type="text" 
                                            value={this.state.quantity} 
                                            onChange={(e)=>this.setState({ 
                                                quantity: parseInt(e.target.value) ? parseInt(e.target.value) : "" 
                                            },()=>{ 
                                                LS.setLocalForm("__LocalData_create__MO", "quantity", this.state.quantity)
                                            })} 
                                            className="fieldheightdecr" 
                                            autoComplete="off" 
                                            name="name"  
                                            style={{width: "40%"}} 
                                            onBlur={(e)=>this.getBOMOnQtyChange()}
                                        /> {this.state.uom}
                                    </div>
                                    <div className="clear"></div>
                                </div>

                              

                                <div className="add_inner">
                                    <div className="title">Start</div>
                                    <div className="value">
                                        <DatePicker 
                                            timeInputLabel="Time:"
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            showTimeInput
                                            minDate={new Date()}
                                            selected={this.state.startDate}
                                            onChange={date => this.setState({ startDate: date },()=>{ 
                                                LS.setLocalForm("__LocalData_create__MO", "startDate", date)
                                                this.getEndDate() 
                                            }
                                            )}
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Select Department</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.departmentData.map((data) => (
                                                    { id: data._id, text: data.number.toUpperCase()+' '+data.name }
                                                ))
                                            }
                                            value={this.state.department}
                                            options={{placeholder: 'Select department'}}
                                            onSelect={(e)=> this.setState({ 
                                                department: e.target.value
                                             },()=>{
                                                LS.setLocalForm("__LocalData_create__MO", "department", e.target.value)
                                                this.getUsersByDepartment(e.target.value)
                                            })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>    
                                <div className="add_inner">
                                    <div className="title">Assigned to *</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.userData.map((data) => (
                                                    { id: data._id, text: data.username ? data.username : data.full_name }
                                                ))
                                            }
                                            value={this.state.assignedUser}
                                            options={{placeholder: 'Select User'}}
                                            onSelect={(e)=> this.setState({ 
                                                assignedUser: e.target.value
                                             },()=>{
                                                LS.setLocalForm("__LocalData_create__MO", "assignedTo", e.target.value)
                                            })}
                                        />

                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                
                            </div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="mt-2">
                            <div className="stock__maintain__add_booking">
                                <div className="float-left width10 text-right">
                                    <div>Bill of Materials</div>
                                </div>
                                <div className="width60 ml10per mt-2 mb-2">
                                    <div className="tableContent __co__booking_item_list ml23" style={{ overflowX : "auto" }}>
                                        <table className='__kDoEM__table svg'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Bill of materials</th>
                                                    <th>Approximate cost</th>
                                                    <th>Earliest start date</th>
                                                    <th>Earliest finish date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.BOM_List.map((data, i) => (
                                                        <tr key={"BOM_list_Data"+i}>
                                                            <td>
                                                                <input 
                                                                    type='radio' 
                                                                    defaultChecked={i === this.state.selectedBOMnumber ? true : false} 
                                                                    name="setBOM"
                                                                    onChange={()=> {
                                                                        this.setState({
                                                                            selectedBOMnumber: i,
                                                                            selectedRoutingnumber: 0,
                                                                        },()=>{
                                                                            LS.setLocalForm("__LocalData_create__MO", "selectedBOMnumber", i)
                                                                            LS.setLocalForm("__LocalData_create__MO", "selectedRoutingnumber", 0)
                                                                            this.setBOMDetail(data)
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                            </td>
                                                            <td>{data.number} {data.name}</td>
                                                            <td>{data.totalCost}</td>
                                                            <td>{convertDateTimeStringMMDDtoDDMM(data.bomStartDate)}</td>
                                                            <td>{convertDateTimeStringMMDDtoDDMM(data.bomFinishDate)}</td>
                                                            <td onClick={()=>this.openBOMinfo(data._id)}><Fi.FiEdit2 /></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="clear"></div>

                            </div>
                            
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createMO()}>Save</div>
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                {/* {
                    this.state.isLoading &&
                    <Loading />
                } */}
            </div>
        );
    }
}

export default CreateManufacturingOrder;