import React from 'react';
import { Header, SettingsHeader } from './../../../components'

export default class EditAdditionalCurrencies extends React.Component{

    constructor(props){
        super(props)
        this.state = {

        }
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />

               

                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Additional currencies</div>
                            </div>
                        </div>
                    </div>

                <div className="pagecontent"></div>

                <div className="tableContent">
                    <div>
                        <div className="editSetLeftContent">

                        </div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave">Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div className="position-relative">
                            <div className="editSetLeftContent">Currency</div>
                            <div className="editSetRightContent">
                                <input type="text" className="editSetText" />
                            </div>
                            <div className="clear"></div>
                            <div className="editSetLeftContent">Rate</div>
                            <div className="editSetRightContent">
                                <input type="text" className="editSetText" />
                            </div>
                            <div className="clear"></div>
                            <div className="editSetLeftContent"></div>
                            
                        </div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave">Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    </div>
                </div>
                </div>
        )
    }
}