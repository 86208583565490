import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Logo } from './../../assets/Images'
import Loading from './../../components/Loading'
import * as APIManager from './../../APIManager/APIManager'
import {
    validateBlank
} from './../../constants/Validate'

import * as Ai from 'react-icons/ai'

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            email: "",
            password: "",
            showPassword: false,
        }
        this.handleEmail = this.handleEmail.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
    }

    componentDidMount() {

    }

    handleEmail(e) {
        this.setState({ email: e.target.value })
    }
    handlePassword(e) {
        this.setState({ password: e.target.value })
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleLogin()
        }
    }

    isValidate() {
        const { email, password } = this.state;

        if (!validateBlank(email)) {
            this.setState({
                error: true,
                errormessage: "Please enter email address"
            })
            return false;
        } else if (!validateBlank(password)) {
            this.setState({
                error: true,
                errormessage: "Please enter password"
            })
            return false;
        } else {
            return true;
        }
    }

    handleLogin() {
        const { email, password } = this.state;
        if (this.isValidate()) {
            localStorage.clear();
            this.setState({ isLoading: true, error: false })
            APIManager.callLogin(email, password)
                .then(response => {
                    localStorage.setItem('userdata', JSON.stringify(response.data))

                    this.setState({ isLoading: false })

                    if (response.data.role === "subUser") {
                        let permissions = response.permissions.permissions;
                        if (permissions.length > 0) {
                            if (permissions.length === 1) {
                                let isWorker = permissions.find(o => o.slug === 'ProductionOperations');
                                let arr = []
                                if (isWorker === undefined) {
                                    localStorage.setItem('permissions', JSON.stringify(permissions))
                                    this.props.history.push('/home')
                                    // this.props.history.push('/editsubscriptionplan')

                                } else {
                                    arr.push(isWorker)
                                    localStorage.setItem('permissions', JSON.stringify(arr))
                                    this.props.history.push('/productionoperations')
                                }
                            } else {
                                localStorage.setItem('permissions', JSON.stringify(permissions))
                                this.props.history.push('/home')
                                // this.props.history.push('/editsubscriptionplan')

                            }
                        } else {
                            localStorage.setItem('permissions', JSON.stringify(permissions))
                            this.props.history.push('/home')
                        }
                    } else {
                        localStorage.setItem('permissions', JSON.stringify(response.permissions))
                        localStorage.setItem('completionDetails', JSON.stringify(response.data.completionDetails))

                        if (response.data.completionDetails.totalPercentCompleted !== 100) {
                            this.props.history.push('/systemsettings')
                        } else {
                            this.props.history.push('/home')
                        }
                    }


                })
                .catch(errors => {
                    this.setState({
                        isLoading: false,
                        error: true,
                        errormessage: "Incorrect username or password"
                    })
                });
        }
    }

    render() {
        return (
            <div className="form-bg-primary authBg page">
                <div className="loging-list">
                    <div className="container">
                        <div className="middle">
                            <div className="loging-menu-card">
                                <div className="logo">
                                    <img src={Logo} alt="logo" />
                                </div>
                                {
                                    this.state.error &&
                                    <div className="errornotification">
                                        <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                    </div>
                                }
                                <div className="form">
                                    <div className="form-group">
                                        <label htmlFor="Username">Email</label>
                                        <input type="email" className="form-control" onChange={this.handleEmail} onKeyDown={this.handleKeyDown} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Password</label>
                                        <div className='position-relative'>
                                        <input type={this.state.showPassword ? "text" : "password"} className="form-control" onChange={this.handlePassword} onKeyDown={this.handleKeyDown} />
                                        {
                                            this.state.showPassword ?
                                            <div className='passwordEye'>
                                                <Ai.AiFillEye size={20} onClick={()=>this.setState({ showPassword: !this.state.showPassword })} />
                                            </div>
                                            :
                                            <div className='passwordEye'>
                                                <Ai.AiFillEyeInvisible size={20} onClick={()=>this.setState({ showPassword: !this.state.showPassword })} />
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    <button type="button" className="btn sign-btn w-100" onClick={this.handleLogin.bind(this)}>Sign in</button>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <Link className="btn sign-btn-outline" to="/register">New? Sign up</Link>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <Link className="btn forgot-btn-outline" to="/forgotpass">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && <Loading />}
            </div>
        )
    }
}


export default Login;