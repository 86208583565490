import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import Loading from './../../../../helper/loading'
import RichTextEditor from 'react-rte';

// import ProductGroupForInr from './ProductGroupForInr';
// import ProductGroupForOther from './ProductGroupForOther';
import ProductGroup from './ProductGroup'
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

class CreateInvoice extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            coGroupList: [],
            coGroupData: [],
            coProductGroup: [],
            customerOrderData: [],
            addressList: [],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            isFromCO: this.props.location.state.isFromCO,
            customerOrder: this.props.location.state.isFromCO ? this.props.location.state.coId : "",

            customerId: "",

            invoiceAddress: "",
            deliveryAddress: "",
            invoiceType: "",
            invoiceId: "",
            invoiceDate: new Date(),
            dueDate: "",

            customerName: "",
            applicableTax: "",
            applicableTaxType: [],
        
            remainingScope: [],

            invoiceMessageHeaderEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageHeader: "",
            invoiceMessageFooterEditor: RichTextEditor.createEmptyValue(),
            invoiceMessageFooter: "",

            currencyData: [],
            sezCustomer: false,
            applicableTaxTypeWithAllData: [],


            is_tcs_applicable: false,
            is_tcs_locked: false,
            tcs_percentage: "",
            tcs_value: "",
            after_tcs_grandtotal: "",
            tcs_grandtotal_ininr: "",
            tcs_grandtotal_inother: "",
            totalSales: 0
        }
        this.setUpdatedGroupData = this.setUpdatedGroupData.bind(this)
    }

    componentDidMount(){
       this.getAllCustomerOrders()
       if(this.state.isFromCO){
           this.getOrderDetails()
       }
    }

    getAllCustomerOrders = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllCustomerForInvoice()
        .then(response => {
            this.setState({ 
                isLoading: false,
                customerOrderData: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getOrderDetails = () => {
        if(this.state.customerOrder){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerOrderDetail(this.state.customerOrder)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    deliveryAddress: response.shippingAddress,
                    customerId: response.customer,
                    coGroupList: [],
                    coGroupData: [],
                    coProductGroup: response.productGroup,
                    totalQty: 0,
                    subTotal: 0,
                    grandTotal: 0,
                    grandTotalInOther: 0,
                },()=>{
                    this.getCustomerDetails(response.customer)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            })
        }
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {
            let dt = new Date()
            if(response.companyData.paymentTerms){
                if(response.companyData.paymentTerms.paymentStatus === "from_invoice"){
                    let days = response.companyData.paymentTerms.daysFrom !== "" ? response.companyData.paymentTerms.daysFrom : 0
                    let newDate = new Date().setDate(new Date().getDate() + parseInt(days))
                    dt = new Date(newDate)
                }
            }
            let taxType = []
            let taxResponseData = response.companyData.taxApplicable
            let sezCustomer = false
            let invoiceType = ""
            if(!taxResponseData.includes("notax")){
                if(taxResponseData.includes("exemptedtax")){
                    sezCustomer = true
                    invoiceType = "sez"
                    taxType = ["IGST"]
                }else{
                    taxType = response.companyData.taxApplicable
                    invoiceType = "tax"
                }
            }else{
                invoiceType = "export"
            }



           
            
            this.setState({
                isLoading: false,
                customerName: response.companyData.companyId.toUpperCase()+' '+response.companyData.companyName,
                currencyData: response.companyData.currencyDetails,
                applicableTax: "",
                applicableTaxType: taxType,
                applicableTaxTypeWithAllData: taxResponseData,
                sezCustomer: sezCustomer,
                dueDate: dt,
                invoiceType: invoiceType,
                totalSales: response.companyData.totalSales ? response.companyData.totalSales : 0
            },()=>{
                if(this.state.totalSales >= 5000000){
                    this.setState({
                        is_tcs_applicable: true,
                        is_tcs_locked: true,
                        tcs_percentage: 0.1,
                    })
                }
                this.getSingleCOData()
                this.getCustomerAddress(id)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    getSingleCOData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOGroupData(this.state.customerOrder)
        .then(response => {
            let updatedCoGroup = response.data.coGroup;

            for(let i = 0;i<updatedCoGroup.length;i++){
                updatedCoGroup[i].rawTotalInOtherCurrency = 0
                updatedCoGroup[i].subTotalInOtherCurrency = 0
                updatedCoGroup[i].subTotal = 0
            }
            this.setState({ 
                isLoading: false,
                coGroupData: updatedCoGroup
            },()=>{
                this.setGroupData()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });          
    }

    setGroupData = () => {
        let data = this.state.coGroupData

        let group = []
        for(let i=0;i<data.length;i++){
            group.push(
                <ProductGroup 
                    number={i}
                    applicableTaxType={this.state.applicableTaxType}
                    applicableTaxTypeWithAllData={this.state.applicableTaxTypeWithAllData}
                    currencyData={this.state.currencyData}
                    key={"groupData"+i}
                    data={data[i]}
                    freeText={this.state.coProductGroup[i].productFreeText}
                    itemCode={this.state.coProductGroup[i].itemCode}
                    uom={data[i].uom}
                    customerId={this.state.customerId}
                    callBack={this.setUpdatedGroupData}
                />
            )
        }
        this.setState({
            coGroupList: group,
            coGroupData: data
        })
    }

    setUpdatedGroupData = (value, counter) => {
        let coGroupData = [...this.state.coGroupData]  
        coGroupData[counter] = value;  
        this.setState({
            coGroupData
        },()=>{
            this.countTotal()
        })
    }

    countTotal = () => {
        let data = this.state.coGroupData

        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for(let i=0;i<data.length;i++){
            qty += data[i].orderedQuantity ? parseFloat(data[i].orderedQuantity) : 0
            subTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            if(this.state.invoiceType === "sez"){
                grandTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            }else{
                grandTotal += data[i].total ? parseFloat(data[i].total) : 0
            }
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        },()=>{

            let tcs = this.state.tcs_percentage
           
            let allTotal = parseFloat(this.state.totalSales) + parseFloat(this.state.grandTotal)
            
            if(allTotal >= 5000000 && tcs === ""){
                this.setState({
                    is_tcs_applicable: true,
                    is_tcs_locked: false,
                    tcs_percentage: 0.1,
                },()=>{
                    this.changeTCSPercentage(this.state.tcs_percentage)
                })
            }else if(allTotal >= 5000000 && tcs !== ""){
                this.setState({
                    is_tcs_locked: false,
                },()=>{
                    this.changeTCSPercentage(this.state.tcs_percentage)
                })
            }else{
                this.setState({
                    is_tcs_locked: false,
                },()=>{
                    this.changeTCSPercentage(this.state.tcs_percentage)
                })
            }
        })
    }

    getCustomerAddress = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressList: response,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        }
    }

    onHeaderMessageChange = (value) => {
        this.setState({
            invoiceMessageHeaderEditor: value,
            invoiceMessageHeader: value.toString('html')
        });
    }

    onFooterMessageChange = (value) => {
        this.setState({
            invoiceMessageFooterEditor: value,
            invoiceMessageFooter: value.toString('html')
        });
    }

    createInvoice = () => {
        let {
            customerOrder,
            customerId,
            invoiceAddress,
            deliveryAddress,
            invoiceType,
            invoiceId,
            invoiceDate,
            dueDate,
            coGroupData,
            subTotal,
            grandTotal,
            invoiceMessageHeader,
            invoiceMessageFooter,
            grandTotalInOther,

            is_tcs_applicable,
            tcs_percentage,
            tcs_value,
            tcs_grandtotal_ininr,
            tcs_grandtotal_inother

    

        } = this.state

        if(customerOrder === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select customer order"
            },()=>{
                this.scrollToTop()
            })
        }else if(invoiceType === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please select invoice type"
            },()=>{
                this.scrollToTop()
            })
        }else if(invoiceDate === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter invoice date"
            },()=>{
                this.scrollToTop()
            })
        }else if(dueDate === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter due date"
            },()=>{
                this.scrollToTop()
            })
        }else if(parseFloat(grandTotal) === 0){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please update delivered item details"
            },()=>{
                this.scrollToTop()
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateCOInvoice(customerOrder,customerId,invoiceId,deliveryAddress,invoiceAddress,invoiceType,convertDateToMMDDYYYY(invoiceDate),convertDateToMMDDYYYY(dueDate),JSON.stringify(coGroupData),subTotal,grandTotal,invoiceMessageHeader,invoiceMessageFooter,grandTotalInOther, is_tcs_applicable, tcs_percentage, tcs_value, tcs_grandtotal_ininr, tcs_grandtotal_inother)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editcoinvoice",
                        state: { invoiceId: response.data._id, isNewEntry: true }
                    }) 
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    changeTCSPercentage = (val) => {
        let per = val
        this.setState({
            tcs_percentage: (per >= 0 && per <= 100 ) || per === "" ? per : ''
        },()=>{
            let grandTotal = this.state.currencyData.conversionRate ? this.state.grandTotalInOther : this.state.grandTotal
            if(this.state.tcs_percentage){
                let tcs_per = this.state.tcs_percentage

                let tcs_val = (grandTotal * tcs_per / 100).toFixed(2)

                this.setState({
                    tcs_value: parseFloat(tcs_val),
                    after_tcs_grandtotal: parseFloat(grandTotal)+parseFloat(tcs_val),
                    tcs_grandtotal_ininr: parseFloat(this.state.grandTotal)+parseFloat(tcs_val),
                    tcs_grandtotal_inother: parseFloat(this.state.grandTotalInOther)+parseFloat(tcs_val)
                })
            }
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isInvoices={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Create Invoice</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createInvoice()}>Save</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Customer Order*</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.customerOrderData.map((data) => (
                                                    { id: data._id, text: data.customerDetail}
                                                ))
                                            }
                                            value={this.state.customerOrder}
                                            options={{placeholder: 'Select CO'}}
                                            onSelect={(e)=> this.setState({ customerOrder: e.target.value },()=> { this.getOrderDetails() })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.addressList.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.invoiceAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ invoiceAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery Address</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.addressList.map((data) => (
                                                    { id: data, text: data.split('_').join(' ') }
                                                ))
                                            }
                                            value={this.state.deliveryAddress}
                                            options={{placeholder: 'Select Address'}}
                                            onSelect={(e)=> this.setState({ deliveryAddress: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Invoice Type</div>
                                    <div className="value">
                                        <select value={this.state.invoiceType} onChange={(e)=>this.setState({ invoiceType: e.target.value })}>
                                            <option value="">Select</option>
                                            <option value="credit">Credit Invoice</option>
                                            <option value="deposit" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Deposit Invoice</option>
                                            <option value="tax" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Tax Invoice</option>
                                            <option value="retail" disabled={this.state.currencyData.conversionRate || this.state.sezCustomer ? true : false}>Retail Invoice</option>
                                            <option value="sez" disabled={this.state.currencyData.conversionRate || !this.state.sezCustomer ? true : false}>SEZ Invoice</option>
                                            <option value="export" disabled={this.state.currencyData.conversionRate || !this.state.sezCustomer ? false : true}>Export Invoice</option>
                                        </select>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">Invoice ID</div>
                                    <div className="value">
                                        <input type="text" value={this.state.invoiceId} onChange={(e)=>this.setState({ invoiceId: e.target.value})} />
                                    </div>
                                    <div className="clear"></div>
                                </div> */}

                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                this.state.invoiceDate
                                            }
                                            onChange={
                                                date => this.setState({ invoiceDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Due Date</div>
                                    <div className="value">
                                        <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.invoiceDate}
                                            selected={
                                                this.state.dueDate
                                            }
                                            onChange={
                                                date => this.setState({ dueDate: date })
                                            }
                                            className="filterdateoption"
                                        />
                                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                               
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="add_outer_full_part">
                            <div className="title">Invoice Header</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageHeaderEditor}
                                    onChange={this.onHeaderMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                       

                        <div className="main-container mt-5 invoice_product_group">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Product group</th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Ordered Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                                <th className='pg-tab-head'>Subtotal</th>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <th className='pg-tab-head' key={'appTax'+i}>
                                                    {data}
                                                    {
                                                        this.state.applicableTaxTypeWithAllData.includes("exemptedtax") &&
                                                        <small>(Exempted)</small>
                                                    }
                                                </th>
                                            ))
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                        <th className='pg-tab-head'>Remaining Qty</th>
                                        <th className='pg-tab-head'>Delivered Qty</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    
                                    {this.state.coGroupList}

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td><b>{this.state.totalQty}</b></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            <td><b><Bi.BiRupee /> {this.state.subTotal} </b></td>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <td key={'appTaxBlankField'+i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyData.conversionRate ?
                                                <td><b>{this.state.currencyData.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                            :
                                                <td><b><Bi.BiRupee /> {this.state.grandTotal} </b></td>

                                        }
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TCS Applicable</div>
                                    <div className="value">
                                        <input 
                                            type="checkbox" 
                                            disabled={this.state.is_tcs_locked}
                                            checked={this.state.is_tcs_applicable}
                                            onChange={()=>this.setState({ 
                                                is_tcs_applicable: !this.state.is_tcs_applicable,
                                                tcs_percentage: "",
                                                tcs_value: "",
                                                after_tcs_grandtotal: "" 
                                            })} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">TCS Percentage</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.tcs_percentage}
                                            disabled={!this.state.is_tcs_applicable} 
                                            onChange={(e)=>this.changeTCSPercentage(e.target.value)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">TCS Value</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.tcs_value}
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Grand Total</div>
                                    <div className="value">
                                        <input 
                                            type="textbox" 
                                            value={this.state.after_tcs_grandtotal}
                                            disabled={true} 
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>

                        <div className="add_outer_full_part mt-1">
                            <div className="title">Invoice Footer</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.invoiceMessageFooterEditor}
                                    onChange={this.onFooterMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                      

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.createInvoice()}>Save</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default CreateInvoice;