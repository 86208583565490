import React from 'react';
import { Header } from './../../components';
import * as APIManager from './../../APIManager/APIManager'
import Loading from './../../helper/loading'
import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateMPPNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            MO_Id: this.props.location.state.MO_Id,
            MO_No: this.props.location.state.MO_No,

            note: "",
           
        }
      
    }

    componentDidMount(){
        
    }

    createNote = () => {
        let {
            note,
            MO_Id,
            MO_No
        } = this.state

        if(note === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please add note first"
            })

        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callCreateMONote(MO_Id, note)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.props.history.push({
                        pathname: "/editmppnote",
                        state: { 
                            MO_Id: MO_Id, 
                            MO_No: MO_No, 
                            Note_Id: response.data, 
                            newNoteEntry: true,
                            Note: note 
                        }
                    })
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isMyProductionPlan={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a note</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNote()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Order No </div>
                                <div className="value companyname text-uppercase">{this.state.MO_No}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNote()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}