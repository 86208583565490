import React, { Component } from 'react';

import { Header, CRMHeader } from './../../../components'
import Strings from './../../../constants/Strings'

import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';

import { 
    ImArrowDown2
} from "react-icons/im";


import OrderTable from './OrderTable'
import ItemTable from './ItemTable'
import * as Ai from "react-icons/ai";
import { convertDateStringMMDDtoDDMM } from '../../../constants/DateFormatter';



class Quotations extends Component {

    constructor(props){
        super(props) 
        this.state = {
            tableList: "companies",
            customerOrderList: [],
            customerOrderItemList: []
        }
        this.getSetOrderData = this.getSetOrderData.bind(this)
        this.getSetItemData = this.getSetItemData.bind(this)
    }
    
    exportCustomerOrderPDF = () => {
        let responseData = this.state.customerOrderList
        let chooseColumnList = JSON.parse(localStorage.getItem("quotationSelected__Columns"))

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(10);
       
        const title = "Customer Orders";

        let headerData = []
        chooseColumnList.map((data, i)=> [
            data.visible && 
            headerData.push(data.name)
        ])
        const headers = [ headerData ]

        let allData = []
        for(let i=0;i<responseData.length;i++){
            let setData = []
            let elt = responseData[i]
            if(chooseColumnList[0].visible) setData.push(elt.number.toUpperCase())
            if(chooseColumnList[1].visible) setData.push(elt.customerNumber.toUpperCase()) 
            if(chooseColumnList[2].visible) setData.push(elt.customerName) 
            if(chooseColumnList[3].visible) setData.push(elt.status) 
            if(chooseColumnList[4].visible) setData.push(elt.managers.toString()) 
            if(chooseColumnList[5].visible) setData.push(elt.subTotal) 
            if(chooseColumnList[6].visible) setData.push(elt.total) 
            if(chooseColumnList[7].visible) setData.push(convertDateStringMMDDtoDDMM(elt.poDate)) 
            if(chooseColumnList[8].visible) setData.push(convertDateStringMMDDtoDDMM(elt.poDeliveryDate)) 
            if(chooseColumnList[9].visible) setData.push(convertDateStringMMDDtoDDMM(elt.deliveryDate)) 
            if(chooseColumnList[10].visible) setData.push(convertDateStringMMDDtoDDMM(elt.actualDeliveryDate)) 
            if(chooseColumnList[11].visible) setData.push(convertDateStringMMDDtoDDMM(elt.modifiedDate)) 
            if(chooseColumnList[12].visible) setData.push(convertDateStringMMDDtoDDMM(elt.created)) 
            if(chooseColumnList[13].visible) setData.push(convertDateStringMMDDtoDDMM(elt.quotationValidityEnd)) 
            if(chooseColumnList[14].visible) setData.push(elt.reference) 
            allData.push(setData)
        }
        const data = allData;

        let content = {
            startY: 50,
            head: headers,
            body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("customer-orders.pdf")
    }


    componentDidMount() {
        this.getFilterData()
       
       
    }

    exportCustomerOrderCSV = () => {

        let responseData = this.state.customerOrderList;
        let chooseColumnList = JSON.parse(localStorage.getItem("quotationSelected__Columns"))

        const title = "Customer Orders";
    
        let headerData = []
        chooseColumnList.map((data, i)=> [
            data.visible && 
            headerData.push(data.name)
        ])
        const headers = headerData

        let allData = []
        for(let i=0;i<responseData.length;i++){
            let setData = []
            let elt = responseData[i]            
            if(chooseColumnList[0].visible) setData.push(elt.number.toUpperCase())
            if(chooseColumnList[1].visible) setData.push(elt.customerNumber.toUpperCase()) 
            if(chooseColumnList[2].visible) setData.push(elt.customerName) 
            if(chooseColumnList[3].visible) setData.push(elt.status) 
            if(chooseColumnList[4].visible) setData.push(elt.managers.toString()) 
            if(chooseColumnList[5].visible) setData.push(elt.subTotal) 
            if(chooseColumnList[6].visible) setData.push(elt.total) 
            if(chooseColumnList[7].visible) setData.push(convertDateStringMMDDtoDDMM(elt.poDate)) 
            if(chooseColumnList[8].visible) setData.push(convertDateStringMMDDtoDDMM(elt.poDeliveryDate)) 
            if(chooseColumnList[9].visible) setData.push(convertDateStringMMDDtoDDMM(elt.deliveryDate)) 
            if(chooseColumnList[10].visible) setData.push(convertDateStringMMDDtoDDMM(elt.actualDeliveryDate)) 
            if(chooseColumnList[11].visible) setData.push(convertDateStringMMDDtoDDMM(elt.modifiedDate)) 
            if(chooseColumnList[12].visible) setData.push(convertDateStringMMDDtoDDMM(elt.created)) 
            if(chooseColumnList[13].visible) setData.push(convertDateStringMMDDtoDDMM(elt.quotationValidityEnd)) 
            if(chooseColumnList[14].visible) setData.push(elt.reference) 
            allData.push(setData)
        }
        const data = allData;

        const options = { 
            filename: 'customer-orders',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };
        
        const csvExporter = new ExportToCsv(options);
        
        csvExporter.generateCsv(data);
       
    }
    
    exportOrderItemPDF = () => {
        let responseData = this.state.customerOrderItemList;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Customer Order - Item";

        const headers = [[
            "Number",
            "Part no.",
            "Part description",
            "Quantity",
            "Invoiced",
            "Remaining",
            "Subtotal",
            "Total",
            "Customer number", 
            "Customer name",
            "Status",
            "Created",
            "Delivery date" 
        ]];

        const data = responseData.map((elt, i) => [
            elt.number,
            elt.partNo,
            elt.partDescription,
            elt.quantity,
            elt.deliveredQuantity,
            elt.remainingQuantity,
            elt.subtotal,
            elt.total,
            elt.customerNumber,
            elt.customerName,
            elt.status,
            convertDateStringMMDDtoDDMM(elt.created),
            convertDateStringMMDDtoDDMM(elt.deliveryDate)
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("COItems.pdf")
    }

    exportOrderItemCSV = () => {
        let responseData = this.state.customerOrderItemList;

        const title = "Customer Order - Items";
    
        const headers = [
            "Number",
            "Part no.",
            "Part description",
            "Quantity",
            "Invoiced",
            "Remaining",
            "Subtotal",
            "Total",
            "Customer number", 
            "Customer name",
            "Status",
            "Created",
            "Delivery date" 
        ];

        const data = responseData.map((elt, i) => [
            elt.number,
            elt.partNo,
            elt.partDescription,
            elt.quantity,
            elt.deliveredQuantity,
            elt.remainingQuantity,
            elt.subtotal,
            elt.total,
            elt.customerNumber,
            elt.customerName,
            elt.status,
            convertDateStringMMDDtoDDMM(elt.created),
            convertDateStringMMDDtoDDMM(elt.deliveryDate)
        ]);


        const options = {
            filename: 'coinvoices',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
    }

    setFilterData = () => {
        localStorage.setItem("quotationOrderOption", this.state.tableList)
    }

    getFilterData = () => {
        let filterData = localStorage.getItem("quotationOrderOption")
        if(filterData){
            this.setState({
                tableList : filterData,
            })
        }
    }

    getSetOrderData = (data) => {
        this.setState({ customerOrderList: data})
    }

    getSetItemData = (data) => {
        this.setState({ customerOrderItemList: data})
    }

    goBack = () => {
        this.props.history.goBack();
    }
    

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isQuotations={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.CRM.Quotations}</div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-select-box">
                                    <select value={this.state.tableList} onChange={(e)=>this.setState({ tableList: e.target.value},()=> { this.setFilterData()})}>
                                        <option value="companies">Customer orders</option>
                                        <option value="contacts">Items</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {
                                        this.state.tableList === "companies" ?
                                            <div>
                                                <div className="button" onClick={()=>this.exportCustomerOrderPDF()}>PDF <ImArrowDown2 /></div>
                                                <div className="button" onClick={()=>this.exportCustomerOrderCSV()}>CSV <ImArrowDown2 /></div>
                                            </div>
                                        :
                                            <div>
                                                <div className="button" onClick={()=>this.exportOrderItemPDF()}>PDF <ImArrowDown2 /></div>
                                                <div className="button" onClick={()=>this.exportOrderItemCSV()}>CSV <ImArrowDown2 /></div>
                                            </div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.tableList === "companies" ?
                           <OrderTable 
                                history={this.props.history}
                                getQuotationList={this.getSetOrderData}
                            />
                        :
                            <ItemTable 
                                history={this.props.history} 
                                getItemList={this.getSetItemData}
                            />
                    }

                </div>
            </div>
        );
    }
}

export default Quotations;