import React, { Component } from 'react';
import { PDFViewer, Page, Text, View, Image, Font, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Denmark from './../../assets/Fonts/DENMARK.ttf'
import CalibriBold from './../../assets/Fonts/CalibriBold.ttf'
import CalibriRegular from './../../assets/Fonts/CalibriRegular.ttf'
import * as APIManager from './../../APIManager/APIManager'
import { BrowserView, MobileView } from 'react-device-detect';

import { AKAM } from './../../assets/Images'
import { convertDateTimeStringMMDDtoDDMM, convertDateStringMMDDtoDDMM } from './../../constants/DateFormatter'

class ProductionPlanPDF extends Component {

    constructor(props){
        super(props)
        this.state = {
            now: new Date().toLocaleString(),

            isLoading: false,
            error: false,
            errormessage: "loading...", 
            isReadyToLoad: false,
            MO_Id: "",
            companyDetails: [],

            number: "",
            product: "",
            quantity: "",

            assignedTo: "",
            targetLotNumber: "",

            billOfMaterials: "",
            created: "",

            status: "",

            startDate: "",
            finishDate: "",

            actualStart: "",
            actualFinish: "",

            singleMOUoMName: "",
            
            parts: [],
            operations: [],

            itemDetails: []
        }
    }


    componentDidMount(){
        this.getUrlVars()
    }

    getUrlVars = () => {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1].split("%20").join(" ");
        }

        console.log(vars);

        let moId = vars.moId

        this.setState({
            MO_Id: moId,
        },()=>{ 
            this.getBookingListForMo()
            this.getSingleMO()
        })
    }

    getSingleMO = (defaultWorkerFlag) => {

        APIManager.callSingleMO(this.state.MO_Id,defaultWorkerFlag)
        .then(response => {
            this.getSingleProduct(response.data.product)
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    }

    getSingleProduct = (id) => {
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({ 
                singleMOUoMName: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            })
        });
    }



    getBookingListForMo = () => {
        this.setState({ isLoading: true, error: false, itemDetails: [] })

        APIManager.callGetBookingForMO(this.state.MO_Id)
        .then(response => {
            this.setState({
                itemDetails: response.data.itemArray
            },()=>{
                console.log("ABC ", this.state.itemDetails )
                this.callProductionPDF()
            })

        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    }


    callProductionPDF = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callProductionPDF(this.state.MO_Id)
        .then(response => {
            this.setState({

                isLoading: false,
                companyDetails: response.data.companyDetails,

                number: response.data.moNumber,
                product: response.data.moProductName,
                quantity: response.data.moQuanity,


                assignedTo: response.data.assignedTo,
                targetLotNumber: response.data.targetLotNumber,

                parts: response.data.parts,
                operations: response.data.operation,

                billOfMaterials: response.data.bomName,
                created: response.data.orderCreated,

                status: response.data.moStatus,

                startDate: response.data.plannedStart !== "" ? response.data.plannedStart : "",
                finishDate: response.data.plannedFinish !== "" ? response.data.plannedFinish : "",

                isReadyToLoad: true,
                
            })

        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors.message
            })
        });
    }

    formattedNumber = (num) => {
        let number = parseFloat(num)
        if(number !== "" || number !== 0){
            return number.toFixed(2).toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return number
        }
    } 

    render() {
        return(
            <>
            <BrowserView>
            {
                this.state.isReadyToLoad ?
                <PDFViewer style={styles.container}>
                    <Document>
                        <Page style={styles.body}>
                            {/* Fixed Header Start */}
                            <View style={styles.header} fixed>
                                <View style={styles.headerLeft}>
                                    <Text style={styles.currentdatetime}>{this.state.now}</Text>
                                    <View style={styles.headerLeftContent}>
                                        <Text style={styles.company_name}>{this.state.companyDetails.companyName}</Text>
                                        <Text style={styles.company_addr}>{this.state.companyDetails.address_zone} {this.state.companyDetails.address_city}</Text>
                                    </View>
                                </View>
                                <View style={styles.headerRight}>
                                    <Image src={AKAM} style={styles.headerlogo} /> 
                                    {
                                        this.state.companyDetails.companyLogo !== "" &&
                                        <Image source={this.state.companyDetails.companyLogo} crossorigin="anonymous" cache={false} style={styles.headerlogo} /> 
                                    }
                                </View>
                            </View>
                            {/* Fixed Header End */}

                            {/* Main Content Start */}
                            <View style={styles.maincontainer}>
                                <View style={{ flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1 }}>
                                    <Text style={styles.ct_header}>Manufacturing Order {this.state.number.toUpperCase()}</Text>
                                    <Text>Order Created: {convertDateStringMMDDtoDDMM(this.state.created)}</Text>
                                </View>

                                <View style={{ flexDirection: "row", marginTop: 10}}>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Product:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{this.state.product}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Status:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text style={{ textTransform: "capitalize"}}>{this.state.status}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 5 }}>

                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Quantity:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{this.state.quantity} {this.state.singleMOUoMName}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>BOM:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text style={{ textTransform: "capitalize"}}>{this.state.billOfMaterials}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 5}}>

                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Planned Start:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{convertDateTimeStringMMDDtoDDMM(this.state.startDate)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Planned Finish:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{convertDateTimeStringMMDDtoDDMM(this.state.finishDate)}</Text>
                                        </View>
                                    </View>
                                </View>
                                
                                <View style={{ marginTop: 20, borderBottomWidth: 1 }}><Text style={styles.ct_header}>Parts</Text></View>
                                <View style={styles.table_header}>
                                    <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2]}>Stock Item</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>Needed</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>In stock</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>Booked</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>Available</Text>
                                </View>

                                {
                                    this.state.parts.map((data,i)=>(
                                        <View key={i+'parts'}>
                                            <View style={[styles.table_body_content_one,styles.table_row_color1]}>
                                                <Text style={[styles.table_body_text,styles.table_body_1]}>{i+1}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2]}>{data.itemCode} {data.itemName}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{this.state.itemDetails[i].needed} {data.uom}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{data.inStock ? data.inStock : 0} {data.uom}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{data.booked ? data.booked : 0} {data.uom}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{data.available ? data.available : 0} {data.uom}</Text>
                                            </View>
                                            {
                                                data.storageLocationDetail &&
                                            
                                            <View style={[styles.table_inner_header,styles.table_row_color2]}>
                                                <Text style={[styles.table_header_text,styles.table_header_2, styles.text_align_center]}>Storage Location</Text>
                                                <Text style={[styles.table_header_text,styles.table_header_2, styles.text_align_center]}>Booked</Text>
                                            </View>
    }
                                            {
                                                data.storageLocationDetail?.map((sLdata, j) =>(
                                                    <View style={[styles.table_inner_header,styles.table_row_color2]}>
                                                        <Text style={[styles.table_body_text,styles.table_body_2, styles.text_align_center]}>{sLdata.storageLocationName}</Text>
                                                        <Text style={[styles.table_body_text,styles.table_body_2, styles.text_align_center]}>{sLdata.booked} {data.uom}</Text>
                                                    </View>
                                                ))
                                            }
                                        </View>
                                    ))
                                }

                                <View style={{ marginTop: 20, borderBottomWidth: 1 }}><Text style={styles.ct_header}>Operations</Text></View>
                                <View style={styles.table_header}>
                                    <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_4]}>Operation</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_4]}>Workstation</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_4,styles.text_align_center]}>Planned Start</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_4,styles.text_align_center]}>Finish</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_4,styles.text_align_center]}>Operator</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_3,styles.text_align_center]}>Actual Start</Text>
                                    <Text style={[styles.table_header_text,styles.table_header_3,styles.text_align_center]}>Actual Finish</Text>
                                </View>

                                {
                                    this.state.operations.map((data,i)=>(
                                        <View key={i+'operations'} style={i % 2 === 0 ? styles.table_body_content_one : [styles.table_body_content_one,styles.table_row_color1]}>
                                            <Text style={[styles.table_body_text,styles.table_body_1]}>{i+1}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_4]}>{data.operation}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_4]}>{data.workStation}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_4,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.plannedStart)}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_4,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.plannedFinish)}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_4,styles.text_align_center]}>{data.operator}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_3,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.actualStart)}</Text>
                                            <Text style={[styles.table_body_text,styles.table_body_3,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.actualFinish)}</Text>
                                        </View>
                                    ))
                                }

                            </View>


                            <View style={styles.footer} fixed>
                                <View style={styles.footerTopSection}>
                                    <Text style={styles.footernumber}>Manufacturing Order {this.state.number.toUpperCase()}</Text>
                                    <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                        `Page ${pageNumber} of ${totalPages}`
                                    )} />
                                </View> 
                                <View>
                                    <Text style={styles.footer_company_name}>{this.state.companyDetails.companyName}</Text>
                                    <View style={styles.footer_details}>
                                        <View style={styles.footer_detail_data1}>
                                            <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line2}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}</Text>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_state}, {this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data2}>
                                            <Text style={styles.footer_detail_text}>Regd. No.: {this.state.companyDetails.regdNo}</Text>
                                            <Text style={styles.footer_detail_text}>GSTIN: {this.state.companyDetails.gstin}</Text>
                                            <Text style={styles.footer_detail_text}>ECC: {this.state.companyDetails.ecc}</Text>
                                            <Text style={styles.footer_detail_text}>PAN: {this.state.companyDetails.panNo}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data3}>
                                            <Text style={styles.footer_detail_text}>{this.state.companyDetails.bank_name}</Text>
                                            <Text style={styles.footer_detail_text}>A/c No.: {this.state.companyDetails.bank_accno}</Text>
                                            <Text style={styles.footer_detail_text}>Branch: {this.state.companyDetails.bank_branch}</Text>
                                            <Text style={styles.footer_detail_text}>RTGS/NEFT: {this.state.companyDetails.bank_rtgsneft}</Text>
                                            <Text style={styles.footer_detail_text}>Swift Code: {this.state.companyDetails.bank_swiftcode}</Text>
                                        </View>
                                        <View style={styles.footer_detail_data4}>
                                            
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.telephone ? 't: +91 ' +this.state.companyDetails.telephone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.phone ? 'm: +91 ' +this.state.companyDetails.phone : '' }
                                            </Text>
                                        
                                            <Text style={styles.footer_detail_text}>
                                                e: {this.state.companyDetails.email}
                                            </Text>
                                                                                
                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website ? 'w | ' +this.state.companyDetails.website : '' }
                                            </Text>

                                            <Text style={styles.footer_detail_text}>
                                                { this.state.companyDetails.website2 ? 'w | ' +this.state.companyDetails.website2 : '' }
                                            </Text>
                            
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
                :
                <div className="__pdf_error_message">
                    {this.state.errormessage}
                </div>
            }
            </BrowserView>
            <MobileView>
                <div className="__pdf_error_message">
                    {/* This browser doesn't support PDF view at the moment. */}
                    <PDFDownloadLink document={
                         <Document>
                         <Page style={styles.body}>
                             {/* Fixed Header Start */}
                             <View style={styles.header} fixed>
                                 <View style={styles.headerLeft}>
                                     <Text style={styles.currentdatetime}>{this.state.now}</Text>
                                     <View style={styles.headerLeftContent}>
                                         <Text style={styles.company_name}>{this.state.companyDetails.companyName}</Text>
                                         <Text style={styles.company_addr}>{this.state.companyDetails.address_zone} {this.state.companyDetails.address_city}</Text>
                                     </View>
                                 </View>
                                 <View style={styles.headerRight}>
                                     <Image src={AKAM} style={styles.headerlogo} /> 
                                     {
                                         this.state.companyDetails.companyLogo !== "" &&
                                         <Image source={this.state.companyDetails.companyLogo} crossorigin="anonymous" cache={false} style={styles.headerlogo} /> 
                                     }
                                 </View>
                             </View>
                             {/* Fixed Header End */}
 
                             {/* Main Content Start */}
                             <View style={styles.maincontainer}>
                                 <View style={{ flexDirection: "row", justifyContent: "space-between", borderBottomWidth: 1 }}>
                                     <Text style={styles.ct_header}>Manufacturing Order {this.state.number.toUpperCase()}</Text>
                                     <Text>Order Created: {convertDateStringMMDDtoDDMM(this.state.created)}</Text>
                                 </View>
 
                                 <View style={{ flexDirection: "row", marginTop: 10}}>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Product:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{this.state.product}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Status:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text style={{ textTransform: "capitalize"}}>{this.state.status}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 5 }}>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Quantity:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{this.state.quantity} {this.state.singleMOUoMName}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>BOM:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text style={{ textTransform: "capitalize"}}>{this.state.billOfMaterials}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 5}}>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Planned Start:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{convertDateTimeStringMMDDtoDDMM(this.state.startDate)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "row" }}>
                                        <Text style={[styles.bold_text,styles.ct_titles, {flex: 0.3}]}>Planned Finish:</Text> 
                                        <View style={{ flex: 0.7 }}>
                                            <Text>{convertDateTimeStringMMDDtoDDMM(this.state.finishDate)}</Text>
                                        </View>
                                    </View>
                                </View>
                                 
                                 <View style={{ marginTop: 20, borderBottomWidth: 1 }}><Text style={styles.ct_header}>Parts</Text></View>
                                 <View style={styles.table_header}>
                                     <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_2]}>Stock Item</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>Needed</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>In stock</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>Booked</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_2,styles.text_align_center]}>Available</Text>
                                 </View>
 
                                 {
                                     this.state.parts.map((data,i)=>(
                                         <View key={i+'parts'}>
                                             <View style={[styles.table_body_content_one,styles.table_row_color1]}>
                                                 <Text style={[styles.table_body_text,styles.table_body_1]}>{i+1}</Text>
                                                 <Text style={[styles.table_body_text,styles.table_body_2]}>{data.itemCode} {data.itemName}</Text>
                                                 <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{this.state.itemDetails[i].needed} {data.uom}</Text>    
                                                 <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{data.inStock ? data.inStock : 0} {data.uom}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{data.booked ? data.booked : 0} {data.uom}</Text>
                                                <Text style={[styles.table_body_text,styles.table_body_2,styles.text_align_center]}>{data.available ? data.available : 0} {data.uom}</Text>
                                            
                                             </View>
                                             {
                                                data.storageLocationDetail &&
                                                    <View style={[styles.table_inner_header,styles.table_row_color2]}>
                                                        <Text style={[styles.table_header_text,styles.table_header_2, styles.text_align_center]}>Storage Location</Text>
                                                        <Text style={[styles.table_header_text,styles.table_header_2, styles.text_align_center]}>Booked</Text>
                                                    </View>
                                                }
                                             {
                                                 data.storageLocationDetail?.map((sLdata, j) =>(
                                                     <View style={[styles.table_inner_header,styles.table_row_color2]}>
                                                         <Text style={[styles.table_body_text,styles.table_body_2, styles.text_align_center]}>{sLdata.storageLocationName}</Text>
                                                         <Text style={[styles.table_body_text,styles.table_body_2, styles.text_align_center]}>{sLdata.booked} {data.uom}</Text>
                                                     </View>
                                                 ))
                                             }
                                         </View>
                                     ))
                                 }
 
                                 <View style={{ marginTop: 20, borderBottomWidth: 1 }}><Text style={styles.ct_header}>Operations</Text></View>
                                 <View style={styles.table_header}>
                                     <Text style={[styles.table_header_text,styles.table_header_1]}>No</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_4]}>Operation</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_4]}>Workstation</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_4,styles.text_align_center]}>Planned Start</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_4,styles.text_align_center]}>Finish</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_4,styles.text_align_center]}>Operator</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_3,styles.text_align_center]}>Actual Start</Text>
                                     <Text style={[styles.table_header_text,styles.table_header_3,styles.text_align_center]}>Actual Finish</Text>
                                 </View>
 
                                 {
                                     this.state.operations.map((data,i)=>(
                                         <View key={i+'operations'} style={i % 2 === 0 ? styles.table_body_content_one : [styles.table_body_content_one,styles.table_row_color1]}>
                                             <Text style={[styles.table_body_text,styles.table_body_1]}>{i+1}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_4]}>{data.operation}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_4]}>{data.workStation}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_4,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.plannedStart)}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_4,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.plannedFinish)}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_4,styles.text_align_center]}>{data.operator}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_3,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.actualStart)}</Text>
                                             <Text style={[styles.table_body_text,styles.table_body_3,styles.text_align_center]}>{convertDateTimeStringMMDDtoDDMM(data.actualFinish)}</Text>
                                         </View>
                                     ))
                                 }
 
                             </View>
 
 
                             <View style={styles.footer} fixed>
                                 <View style={styles.footerTopSection}>
                                     <Text style={styles.footernumber}>Manufacturing Order {this.state.number.toUpperCase()}</Text>
                                     <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => (
                                         `Page ${pageNumber} of ${totalPages}`
                                     )} />
                                 </View> 
                                 <View>
                                     <Text style={styles.footer_company_name}>{this.state.companyDetails.companyName}</Text>
                                     <View style={styles.footer_details}>
                                         <View style={styles.footer_detail_data1}>
                                             <Text style={styles.footer_detail_text}>{"Office & Factory"}</Text>
                                             <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line1}</Text>
                                             <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_line2}</Text>
                                             <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_city}</Text>
                                             <Text style={styles.footer_detail_text}>{this.state.companyDetails.address_state}, {this.state.companyDetails.address_country}. Pin: {this.state.companyDetails.address_pin}</Text>
                                         </View>
                                         <View style={styles.footer_detail_data2}>
                                             <Text style={styles.footer_detail_text}>Regd. No.: {this.state.companyDetails.regdNo}</Text>
                                             <Text style={styles.footer_detail_text}>GSTIN: {this.state.companyDetails.gstin}</Text>
                                             <Text style={styles.footer_detail_text}>ECC: {this.state.companyDetails.ecc}</Text>
                                             <Text style={styles.footer_detail_text}>PAN: {this.state.companyDetails.panNo}</Text>
                                         </View>
                                         <View style={styles.footer_detail_data3}>
                                             <Text style={styles.footer_detail_text}>{this.state.companyDetails.bank_name}</Text>
                                             <Text style={styles.footer_detail_text}>A/c No.: {this.state.companyDetails.bank_accno}</Text>
                                             <Text style={styles.footer_detail_text}>Branch: {this.state.companyDetails.bank_branch}</Text>
                                             <Text style={styles.footer_detail_text}>RTGS/NEFT: {this.state.companyDetails.bank_rtgsneft}</Text>
                                             <Text style={styles.footer_detail_text}>Swift Code: {this.state.companyDetails.bank_swiftcode}</Text>
                                         </View>
                                         <View style={styles.footer_detail_data4}>
                                             
                                             <Text style={styles.footer_detail_text}>
                                                 { this.state.companyDetails.telephone ? 't: +91 ' +this.state.companyDetails.telephone : '' }
                                             </Text>
                                         
                                             <Text style={styles.footer_detail_text}>
                                                 { this.state.companyDetails.phone ? 'm: +91 ' +this.state.companyDetails.phone : '' }
                                             </Text>
                                         
                                             <Text style={styles.footer_detail_text}>
                                                 e: {this.state.companyDetails.email}
                                             </Text>
                                                                                 
                                             <Text style={styles.footer_detail_text}>
                                                 { this.state.companyDetails.website ? 'w | ' +this.state.companyDetails.website : '' }
                                             </Text>
 
                                             <Text style={styles.footer_detail_text}>
                                                 { this.state.companyDetails.website2 ? 'w | ' +this.state.companyDetails.website2 : '' }
                                             </Text>
                             
                                         </View>
                                     </View>
                                 </View>
                             </View>
                         </Page>
                     </Document>
                    } fileName="Customer order.pdf">
                    {({ _blob, _url, loading, _error }) => (loading ? 'Loading...' : 'View PDF!')}
                    </PDFDownloadLink>
                </div>
            </MobileView>
        </>
        )
    }
}

Font.register({
    family: 'Denmark',
    src: Denmark,    
});

Font.register({
    family: 'CalibriBold',
    src: CalibriBold
})

Font.register({
    family: 'CalibriRegular',
    src: CalibriRegular
})

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: window.innerHeight,
    },
    body: {
      paddingTop: 10,
      paddingBottom: 130,
      paddingHorizontal: 35,
      fontSize: 12,
      fontFamily: 'CalibriRegular',
    },

    bold_text: {
        fontFamily: 'CalibriBold'
    },
    ct_header: {
        fontSize: 15,
        fontFamily: 'CalibriBold',
    },
    ct_titles:{
       width: 80
    },
    table_header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginTop: 10,
    }, 
    text_align_center: {
        textAlign: "center"
    }, 
    table_header_text: {
        fontSize: 11,
        fontFamily: 'CalibriBold'
    },
    table_inner_header: {
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingBottom: 2,
        paddingTop: 5,
    },    
    rsSymbolFont: {
        fontFamily: 'CalibriBold'
    },
    table_header_1: {
        flex: 0.05,
    },
    table_header_2: {
        flex: 0.20,
    },
    table_header_3: {
        flex: 0.15,
    },
    table_header_4: {
        flex: 0.12,
    },
    table_body: {
        borderBottomWidth: 1,
        marginBottom: 10
    },
    table_body_content_one: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 5,
        paddingTop: 5
    },
    table_row_color1: {
        backgroundColor: "#eceff2"
    },
    table_row_color2: {
        backgroundColor: "#fff"
    },
    table_body_text: {
        fontSize: 11,
    },

    table_body_1: {
        flex: 0.05,
        textTransform: "capitalize",
        textAlign: "center"
    },
    table_body_2: {
        flex: 0.20,
        textTransform: "capitalize",
    },
    table_body_3: {
        flex: 0.15,
        textTransform: "capitalize"
    },
    table_body_4: {
        flex: 0.12,
        textTransform: "capitalize"
    },
  







    header: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        paddingBottom: 5,
        marginBottom: 5,
    },
    headerLeft: {
        flex: 0.54,
       position: "relative"
    },
    headerLeftContent: {
        position: "absolute",
        bottom: -5,
    },
    headerRight: {
        flexDirection: "row",
        justifyContent: "space-between",
        flex: 0.46
    },
    company_name: {
        fontSize: 13,
        fontFamily: "Denmark",
        textTransform: "uppercase"
    },  
    company_addr: {
        fontSize: 10,
    },

    headerlogo: {
        width: 80,
        height: "auto",  
    },
    footer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 20,
        backgroundColor: "#ffffff"
    },
    footernumber: {
        fontSize: 11,
        textAlign: 'left',
        textTransform: "capitalize"
    },
    footerPageNumber: {
        fontSize: 11,
        right: 0,
        top: 4,
        position: 'absolute',
        textAlign: 'right'
    },
    footerTopSection: {
        position: 'relative',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginHorizontal: 35,
        paddingTop: 4,
    },
    footer_company_name: {
        fontSize: 11,
        fontWeight: "500",
        marginTop: 5,
        marginBottom: 5,
        marginHorizontal: 35,
        fontFamily: "Denmark",
        textTransform: "uppercase"
    },
    footer_details: {
        flexDirection: "row",
        marginHorizontal: 35,
    },
    currentdatetime: {
        position: "absolute",
        top: 0,
        fontSize: 8,

    },
    footer_detail_text: {
        fontSize: 9,
    },
    footer_detail_data1: {
        flex: 0.23
    },
    footer_detail_data2: {
        flex: 0.30
    },
    footer_detail_data3: {
        flex: 0.26
    },
    footer_detail_data4: {
        flex: 0.20
    },
});

export default ProductionPlanPDF;