import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import Loading from './../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'
import CompanyHolidays from '../../../components/AutoGenerateComponent/addCompanyHolidays';

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditHolidays extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countHolidayNumber: [],
            addHolidayFields: [],
            count: 0,
            holidayFieldValues: [],
            generateNewHoliday: false,

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.getHolidaysList()
    }

    getHolidaysList = () => {

        this.setState({ isLoading: true, error: false })
        APIManager.callGetHolidaysList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                holidayFieldValues: response.holidayDetails
            },()=>{
                this.setHolidayFields(this.state.holidayFieldValues)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.addNewField(0)
            })
        });  
        
    }
    
    setHolidayFields = (holiday) => {
        let numArray = []
        let fields = []
        for(let i=0;i<holiday.length;i++){
            numArray.push(i)
            fields.push(
                <CompanyHolidays 
                    key={i}
                    componentNum={i}
                    holidayname={holiday[i].holidayname}
                    holidaydate={holiday[i].holidaydate}
                    onBlur={this.holidayInputBlur}
                    onEdit={this.holidayInputEdit}
                    onRemove={this.holidayInputRemove}
                />
            )
        }
        this.setState({
            addHolidayFields: fields,
            countHolidayNumber: numArray,
            count: holiday.length
        },()=>{
            this.addNewField(holiday.length)
        })
    }

    addNewField = (len) => {
        this.setState({
            addHolidayFields: this.state.addHolidayFields.concat(
                [
                    <CompanyHolidays 
                        key={len}
                        componentNum={len}
                        holidayname={""}
                        holidaydate={""}
                        onBlur={this.holidayInputBlur}
                        onEdit={this.holidayInputEdit}
                        onRemove={this.holidayInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countHolidayNumber: this.state.countHolidayNumber.concat([len]),
                generateNewHoliday: true,
            })
        })
    }
    
    holidayInputEdit = (holidayname, holidaydate, num) => {

        let array = [...this.state.countHolidayNumber];
        let index = array.indexOf(num)
        let holidayVal = [...this.state.holidayFieldValues]     
        
        
        if (typeof holidayVal[index] === 'undefined' && this.state.generateNewHoliday === true && holidayname !== "" && holidaydate !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewHoliday: false,
            }, () => {
                
                this.setState({
                    countHolidayNumber: this.state.countHolidayNumber.concat(
                        [this.state.count]
                    ),
                    addHolidayFields: this.state.addHolidayFields.concat(
                        [
                            <CompanyHolidays 
                                key={this.state.count}
                                componentNum={this.state.count}
                                holidayname={""}
                                holidaydate={""}
                                onBlur={this.holidayInputBlur}
                                onEdit={this.holidayInputEdit}
                                onRemove={this.holidayInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    holidayInputBlur = (holidayname, holidaydate, num) => {
 
         let array = [...this.state.countHolidayNumber];
         let index = array.indexOf(num)
         let holidayVal = [...this.state.holidayFieldValues]   
 
         if (typeof holidayVal[index] !== 'undefined') {
             if (holidayname !== "" && holidaydate !== "") {
                 let obj = {
                     "holidayname": holidayname,
                     "holidaydate": holidaydate
                 }
                 let holidayFieldValues = [ ...this.state.holidayFieldValues ];
                 holidayFieldValues[index] = obj;
 
                 this.setState({
                     holidayFieldValues,
                     generateNewHoliday: true,
                 })
             }
         }else{
             if (holidayname !== "" && holidaydate !== "") {
                 let obj = {
                     "holidayname": holidayname,
                     "holidaydate": holidaydate
                 }
                 this.setState({
                     holidayFieldValues: this.state.holidayFieldValues.concat([ obj ]),
                     generateNewHoliday: true,
                 })
             }
         }
     }
 

    holidayInputRemove = (number) => {
        let array = [...this.state.countHolidayNumber];
        let fields = [...this.state.addHolidayFields];
        let data = [...this.state.holidayFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countHolidayNumber: array,
                addHolidayFields: fields,
                holidayFieldValues: data
            });
        }
        
    }


    updateHolidays = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                holidayFieldValues
            } = this.state

            /* Remove Null From State */
            let holidays = [];
            holidayFieldValues.forEach((val) => {
                if(val !== null){
                    holidays.push(val);
                }
            });
            /* Remove Null */

            if(holidays.length === 0){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please add holidays dates"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callEditHolidaysList(JSON.stringify(holidays))
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        error: false,
                        fieldNotEditable: true,
                        successmessage: "Holidays updated successfully"
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                }); 
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(

            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Company Holidays</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateHolidays()}>{ this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                        <div className="clear"></div>
                    </div>
                    
                    <div className="add_partside position-relative">
                        <div className="add_inner">
                            <div className="title"></div>
                            <div className="value">
                                <table className="">
                                    <thead>
                                        <tr>
                                            <th>Holiday Name</th>
                                            <th>Holiday Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.addHolidayFields
                                        }
                                    </tbody>
                                </table>    
                            </div>
                            <div className="clear"></div>
                        </div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
                    <div className="clear"></div>
            
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateHolidays()}>{ this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>


        )
    }
}