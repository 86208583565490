const Strings = {
  PageNotFound: {
    title1: 'Oops!',
    title2: '404 - Page Not Found',
    desc: 'The page you are looking for might have been removed or is temporarily unavailable.'
  },
  CRM: {
    title: 'CRM',
    Quotations: 'Quotations',
    CustomerOrders: 'Customer orders',
    Customers: 'Customers',
    TodaysContacts: "Today's contacts",
    Invoices: 'Invoices',
    EInvoices: 'E-Invoices',
    CashFlow: 'Cash flow forecast',
    Statistics: 'Statistics',
    SalesManagement: 'Sales management'
  },
  Dashboard: {
    title: 'Dashboard',
  },
  DemoData: {
    title: 'Demo Data & Videos'
  },
  ForgotPassword: {
    title: '',
  },
  FreeUse: {
    title: 'Free Use'
  },
  HelpCenter: {
    title: 'Help Center'
  },
  Home: {
    title: '',
  },
  Login: {
    title: '',
  },
  MyProductionPlan: {
    title: 'My Production Plan',
  },
  Procurement: {
    title: 'Procurement',
    PurchaseOrders: 'Purchase orders',
    Vendors: 'Vendors',
    Invoices: 'Invoices',
    Forecasting: 'Forecasting',
    CriticalOnHand: 'Critical on-hand',
    Requirements: 'Requirements',
    Statistics: 'Statistics',
  },
  ProductionPlanning: {
    title: 'Production Planning',
    ManufactureOrder: 'Manufacturing orders',
    ProdSchedule: 'Production schedule',
    Workstations: 'Workstations',
    WorkstationGroups: 'Workstation groups',
    BOM: 'BOM',
    Routings: 'Routings',
    Statistics: 'Statistics',
    RejectItems : 'Rejected Items'
  },
  Register: {
    title: '',
  },
  ResetPassword: {
    title: '',
  },
  Settings: {
    title: 'Settings',
    SystemSettings: 'System settings',
    AccountAndBilling: 'Account and billing',
    HR: 'Human resources',
    SubUserList: 'Sub user list',
    Profile: "Profile",
    Terms: 'Terms & Conditions',
    Support: 'Support',
    DataMaintenance: 'Database maintenance',
  },
  Stock: {
    title: 'Stock',
    Items: 'Items',
    StockSettings: 'Stock settings',
    StockLots: 'Stock lots',
    Shipments: 'Shipments',
    Inventory: 'Inventory',
    CriticalOnHand: 'Critical on-hand',
    WriteOffs: 'Write-offs',
    StockMovement: 'Stock movement',
    FilterTable: 'Filter table'
  }
}

export default Strings;