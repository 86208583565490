import React from 'react';
import { Header, SettingsHeader } from './../../../components'


export default class EditRegionalSettings extends React.Component{

    goBack = () => {
        this.props.history.goBack();
    }
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />

                <div className="main-container">

<div className="page-header">
    <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="page-title">Regional settings</div>
        </div>
    </div>
</div>

                <div className="pagecontent">

                </div>

                <div className="tableContent">
                    <div>
                        <div className="editSetLeftContent">

                        </div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave">Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                            Timezone *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                            Date format *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        First day of the week *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Decimal separator *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Thousands separator *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        CSV separator *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Currency sign *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>

                        <div className="editSetLeftContent">
                        Currency format *
                        </div>
                        <div className="editSetRightContent">
                            <select>
                                <option>Companies</option>
                                <option>Contacts</option>
                            </select>
                        </div>
                        <div className="clear"></div>


                        <div className="editSetLeftContent">
                        Generic name of an undefined place in the stock *
                        </div>
                        <div className="editSetRightContent">
                            <input type="text" className="editSetText" />
                        </div>
                        <div className="clear"></div>
                        
                        <div className="editSetLeftContent">

                        </div>
                        <div className="">
                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave">Save</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                    </div>
                </div>
                </div>
        )
    }
}