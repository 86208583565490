import React, { Component } from 'react';

import DatePicker from "react-datepicker";
import './../../../../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'
import * as Fi from 'react-icons/fi'
import { validateNumber, validatePercentage } from './../../../../../constants/Validate'

import Select2 from './../../../../../lib/select2/select2';
import './../../../../../lib/select2/select2.css'


import * as APIManager from './../../../../../APIManager/APIManager'
import { convertDateStringMMDDtoDDMM } from '../../../../../constants/DateFormatter';

class GroupItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            onBlurCall: this.props.onBlurProduct,
            onChangeCall: this.props.onChangeProduct,
            count: this.props.counter,
            apiResponseData: this.props.groupData,
            vendorId: this.props.vendorId,
            history: this.props.history,

            productGroupList: [],
            productList: [],
            productData: [],

            productObj : {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
                'requiredQuantity': '',
                'vendorUoM': '',
                'exchangeRate': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
            },


            
            conversionRate: 1,
            requiredQuantity: "",
            vendorUoM: "",

            productgroup: "",
            item: "",
            freeText: "",
            quantity: "",
            priceperunit: "",
            discount: "",
            subTotal: "",
            productTotal: "",
            expectedDate: "",
            currencyConversionDetail: this.props.conversionDetail,
            exchangeRate: "",
            priceperunitInOtherCurrency: "",
            subtotalInOtherCurrency: "",
            rawtotalInOtherCurrency: "",
            targetLotNumber: "",
            targetLotId: "",

            uom: "",
        }
    }

    componentDidMount(){
        this.getProductGroup()
        this.setProductValues()
    }

    getProductGroup = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetGroupByVendor(this.state.vendorId)
        .then(response => {
            let groupData = []
            response.map((data) => {
                let obj = {
                    text: data.name,
                    id: data._id,
                    title: data.isVendorGroup ? "vendorItem" : ""
                }
                groupData.push(obj)
            })
            this.setState({ 
                isLoading: false,
                productGroupList: groupData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setProductValues = () => {
        let apiData = this.state.apiResponseData;
        let dd = apiData.expectedDate;
        let expectedDate = "";
        if(dd){
            expectedDate = new Date(dd);
        }
        this.setState({
            productObj: apiData,
            productgroup: apiData.productGroup,
            item: apiData.item,
            freeText: apiData.freeText !== undefined ? apiData.freeText.split('<br>').join("\n") : "",
            quantity: apiData.quantity,
            priceperunit: apiData.pricePerUoM,
            discount: apiData.discount,
            subTotal: apiData.subTotal,
            productTotal: apiData.productTotal,
            expectedDate: expectedDate,
            arrivalDate: apiData.arrivalDate,
            targetLotNumber: apiData.targetLot,
            targetLotId: apiData.targetLotId,
            requiredQuantity: apiData.requiredQuantity ? apiData.requiredQuantity : "",
            vendorUoM: apiData.vendorUoM ? apiData.vendorUoM : '',
            exchangeRate: apiData.exchangeRate ? apiData.exchangeRate : '',
            priceperunitInOtherCurrency: apiData.pricePerUoMInOtherCurrency ? apiData.pricePerUoMInOtherCurrency : '',
            subtotalInOtherCurrency: apiData.subTotalInOtherCurrency ? apiData.subTotalInOtherCurrency : '',
            rawtotalInOtherCurrency: apiData.rawTotalInOtherCurrency ? apiData.rawTotalInOtherCurrency : '',
        },()=>{
            this.getItemsByGroup(apiData.productGroup)
            this.getItemDataForUoM(this.state.item)
        })
    }

    getItemDataForUoM = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({
                isLoading: false,
                uom: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getItemsByGroup = (_id) => {
        if(_id !== ""){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetProductByVendor(_id, this.state.vendorId)
            .then(response => {
                let products = []
                response.map((data) => {
                    let obj = {
                        text: data.itemCode+ " " +data.partDescription,
                        id: data._id,
                        title: data.isVendorProduct ? "vendorItem" : ""
                    }
                    products.push(obj)
                })
                this.setState({ 
                    isLoading: false,
                    productList: products
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

   

    getItemDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({
                isLoading: false,
                freeText: response.itemData.freeText,
                uom: response.itemData.uomName
            },()=>{
                this.generateObject('freeText', response.itemData.freeText)
                this.generateObject("exchangeRate", this.state.exchangeRate)
                this.getPriceFromPurchaseTerms(id)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

     getPriceFromPurchaseTerms = (id) => {
        this.setState({ isLoading: true, error: false })
         APIManager.callGetVendorProductPrice(id, this.state.vendorId)
         .then(response => {

            let vendorSpecificUoM = response.is_vendorSpecicUnitOfMeasurement ? response.vendorUoM :  this.state.uom
            let vendorSpecificConversionRate = response.is_vendorSpecicUnitOfMeasurement ? response.conversionRate : 1

 
             this.setState({
                 totalTax: response.tax ? response.tax : 0,
                 vendorUoM: vendorSpecificUoM,
                 priceperunit: response.priceperUoM ? response.priceperUoM : "",
                 conversionRate: vendorSpecificConversionRate,
                 isLoading: false,
             },()=>{
                 this.generateObject('vendorUoM', this.state.vendorUoM)
                 this.generateObject('pricePerUoM', this.state.priceperunit)
             })
         })
         .catch(errors => {
             this.setState({ 
                 isLoading: false,
                 error: true,
                 errormessage: errors 
             })
         });
     }

    countSubTotal = () => {
        let { quantity,requiredQuantity ,  priceperunit, discount, priceperunitInOtherCurrency  } = this.state;
        if(quantity !== ""){
            if((priceperunit !== "" && !isNaN(priceperunit) !== false) || (priceperunitInOtherCurrency !== "" && !isNaN(priceperunitInOtherCurrency) !== false)){
                let total = parseFloat(quantity*priceperunit)
                let totalInOtherCurrency = parseFloat(quantity*priceperunitInOtherCurrency)
                if(discount !== "" && validateNumber(discount)){
                    let withDiscountTotal = parseFloat(total - ( total*discount/100 ))
                    let withDiscountRawTotal = parseFloat(totalInOtherCurrency - ( totalInOtherCurrency*discount/100 ))
                    this.setState({
                        subTotal: withDiscountTotal.toFixed(2),
                        productTotal: withDiscountTotal.toFixed(2),
                        subtotalInOtherCurrency: withDiscountRawTotal.toFixed(2),
                        rawtotalInOtherCurrency: withDiscountRawTotal.toFixed(2),
                    },()=>{
                        this.generateObject('subTotal', withDiscountTotal.toFixed(2))
                        this.generateObject('productTotal', withDiscountTotal.toFixed(2))
                        this.generateObject('subTotalInOtherCurrency', withDiscountRawTotal.toFixed(2))
                        this.generateObject('rawTotalInOtherCurrency', withDiscountRawTotal.toFixed(2))
                    })
                }else{
                    this.setState({
                        subTotal: total.toFixed(2),
                        productTotal: total.toFixed(2),
                        subtotalInOtherCurrency: totalInOtherCurrency.toFixed(2),
                        rawtotalInOtherCurrency: totalInOtherCurrency.toFixed(2),
                    },()=>{
                        this.generateObject('subTotal', total.toFixed(2))
                        this.generateObject('productTotal', total.toFixed(2))
                        this.generateObject('subTotalInOtherCurrency', totalInOtherCurrency.toFixed(2))
                        this.generateObject('rawTotalInOtherCurrency', totalInOtherCurrency.toFixed(2))
                    })
                }
            }else{
                this.setState({
                    subTotal: "",
                    productTotal: "",
                })
            }
        }else{
            this.setState({
                subTotal: "",
                productTotal: "",
            },()=>{
                this.generateObject('subTotal', 0)
                this.generateObject('productTotal', 0)
            })
        }
    }


    updateCurrencyRate = (val) => {
        if(validateNumber(val)){
            if(val !== "" && parseFloat(val) !== 0){
                let priceInOtherCurrency = parseFloat(this.state.priceperunit) / parseFloat(val);
                this.setState({ 
                    exchangeRate: val,
                    priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : 0 
                },()=>{ 
                    this.countSubTotal() 
                })
            }else{
                let priceInOtherCurrency = parseFloat(this.state.priceperunit) / 1;
                this.setState({ 
                    exchangeRate: val,
                    priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : 0 
                },()=>{ 
                    this.countSubTotal() 
                })
            }
        } 
    }

    generateObject = (type, value) => {        
        let productObj = this.state.productObj
    
        if(type === 'productGroup')
            productObj.productGroup = value;
        if(type === 'item')
            productObj.item = value;
        if(type === 'freeText')
            productObj.freeText = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if(type === 'quantity')
            productObj.quantity = value;
        if(type === 'pricePerUoM')
            productObj.pricePerUoM = value;
        if(type === 'discount')
            productObj.discount = value ? parseFloat(value).toFixed(2) : '';
        if(type === 'subTotal')
            productObj.subTotal = value;
        if(type === 'vendorUoM')
            productObj.vendorUoM = value;
        if(type === 'requiredQuantity')
            productObj.requiredQuantity = value;
        if(type === 'exchangeRate')
            productObj.exchangeRate = value
        if(type === 'pricePerUoMInOtherCurrency')
            productObj.pricePerUoMInOtherCurrency = value;
        if(type === 'subTotalInOtherCurrency')
            productObj.subTotalInOtherCurrency = value;
        if(type === 'rawTotalInOtherCurrency')
            productObj.rawTotalInOtherCurrency = value;

        if(type === 'expectedDate'){
            let dt = new Date(value);
            let dd = dt.getDate();
            let mm = dt.getMonth() + 1;
            let yyyy = dt.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            let date = mm + '/' + dd + '/' + yyyy;
            productObj.expectedDate = date;
        }
        if(type === 'productTotal')
            productObj.productTotal = value;

        this.setState({
            productObj
        },()=>{
            this.state.onBlurCall(this.state.productObj, this.state.count)
        })
    }

     goToTargetLot = () => {
        let {
            targetLotId,
            targetLotNumber
        } = this.state;
        
        localStorage.setItem("stockLotId",targetLotId)
        localStorage.setItem("stockLotNumber",targetLotNumber.toUpperCase())
        this.state.history.push('/editlot')
     }

     groupUpdatedClearData = (id) => {
        this.setState({
            productObj : {
                'productGroup': '',
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
                'requiredQuantity': '',
                'vendorUoM': '',
                'exchangeRate': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
            },
            conversionRate: 1,
            requiredQuantity: "",
            vendorUoM: "",
            item: "",
            freeText: "",
            quantity: "",
            priceperunit: "",
            discount: "",
            subTotal: "",
            productTotal: "",
            expectedDate: "",
            currencyConversionDetail: this.props.conversionDetail,
            exchangeRate: this.props.conversionDetail.conversionRate,
            priceperunitInOtherCurrency: "",
            subtotalInOtherCurrency: "",
            rawtotalInOtherCurrency: "",
            uom: "",
        },()=>{
            this.getItemsByGroup(id);
            this.generateObject('productGroup', id)
        })
     }

     itemUpdatedClearData = (id) => {
         this.setState({
            productObj : {
                'productGroup': this.state.productgroup,
                'item': '',
                'freeText': '',
                'quantity': '',
                'pricePerUoM': '',
                'discount': '',
                'subTotal': '',
                'productTotal': '',
                'expectedDate': '',
                'requiredQuantity': '',
                'vendorUoM': '',
                'exchangeRate': '',
                'pricePerUoMInOtherCurrency': '',
                'subTotalInOtherCurrency': '',
                'rawTotalInOtherCurrency': '',
            },
            conversionRate: 1,
            requiredQuantity: "",
            vendorUoM: "",
            freeText: "",
            quantity: "",
            priceperunit: "",
            discount: "",
            subTotal: "",
            productTotal: "",
            expectedDate: "",
            currencyConversionDetail: this.props.conversionDetail,
            exchangeRate: this.props.conversionDetail.conversionRate,
            priceperunitInOtherCurrency: "",
            subtotalInOtherCurrency: "",
            rawtotalInOtherCurrency: "",
            uom: "",
         },()=>{
            this.getItemDetail(id)
            this.state.onChangeCall(this.state.count);
            this.generateObject('item', id)
            //this.state.onBlurCall(this.state.productObj, this.state.count)


         })
     }

    render() {
        let {
            onBlurProduct,
            onRemoveProduct,
            counter
        } = this.props
        return (
            <tr>
                <td style={{ width: 40}}></td>
                <td style={{maxWidth: 150}}>
                    <Select2
                        disabled={this.state.targetLotNumber !== undefined ? true : false}
                        data={this.state.productGroupList}
                        value={this.state.productgroup}
                        options={{placeholder: 'Select group'}}
                        onSelect={(e)=> this.setState({ productgroup: e.target.value },()=>{ 
                            this.groupUpdatedClearData(e.target.value);
                        })}
                    />
                </td>
                <td style={{maxWidth: 150}}>
                    <Select2
                        disabled={this.state.targetLotNumber !== undefined ? true : false}
                        data={this.state.productList}
                        value={this.state.item}
                        options={{placeholder: 'Select product'}}
                        onSelect={(e)=> this.setState({ item: e.target.value },()=>{ 
                            this.itemUpdatedClearData(e.target.value)
                        })}
                    />

                    <textarea 
                        value={this.state.freeText}
                        placeholder='Free text' 
                        className='textarea mt-1' 
                        onChange={(e)=>{
                            this.setState({
                                freeText: e.target.value,
                            },()=>{
                                this.state.onChangeCall(counter);
                                this.generateObject('freeText', e.target.value)
                            })
                        }}
                        onBlur={()=>onBlurProduct(this.state.productObj, counter)}
                    />
                </td>

                <td style={{width: 100}}>
                    <input 
                        readOnly={this.state.isLoading}
                        type='text' 
                        className='input-box width50' 
                        value={this.state.requiredQuantity}
                        onChange={(e)=>{
                            this.setState({
                                requiredQuantity: validateNumber(e.target.value) ? e.target.value : ''
                            },()=>{
                                this.generateObject('requiredQuantity', this.state.requiredQuantity)
                                if(this.state.priceperunit){
                                    this.setState({
                                        quantity: this.state.requiredQuantity * this.state.conversionRate
                                    },()=>{
                                        this.countSubTotal()
                                        this.generateObject('quantity', this.state.quantity)
                                    })
                                }else{
                                    this.setState({
                                        quantity: this.state.requiredQuantity
                                    },()=>{
                                        this.generateObject('quantity', this.state.quantity)
                                    })
                                }
                            })
                        }}
                    ></input> {this.state.uom}
                </td>

                <td style={{width: 100}}>
                    <input 
                        type='text' 
                        className='input-box width50' 
                        value={this.state.quantity} 
                        onChange={(e)=>this.setState({ quantity: validateNumber(e.target.value) ? e.target.value : "" },()=>{ this.countSubTotal() })} 
                        onBlur={(e)=>{
                            this.generateObject('quantity', e.target.value)
                        }}
                    ></input> {this.state.vendorUoM ? this.state.vendorUoM : this.state.uom}
                </td>

                <td style={{width: 90}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.priceperunit} 
                            onChange={(e)=>{
                                if(validateNumber(e.target.value)){
                                    let priceInOtherCurrency = parseFloat(e.target.value) / parseFloat(this.state.exchangeRate);
                                    this.setState({
                                        priceperunit: e.target.value,
                                        priceperunitInOtherCurrency: priceInOtherCurrency ? priceInOtherCurrency.toFixed(2) : 0
                                    },()=>{
                                        this.countSubTotal()
                                    })
                                }
                            }}
                            
                            onBlur={(e)=>{
                                this.generateObject('pricePerUoM', e.target.value)
                                this.generateObject('pricePerUoMInOtherCurrency', this.state.priceperunitInOtherCurrency)
                            }}
                        ></input>
                    </div>
                </td>
                <td style={{width: 90}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.exchangeRate} 
                            onChange={(e)=>this.updateCurrencyRate(e.target.value)} 
                            onBlur={(e)=>{
                                this.generateObject('exchangeRate', e.target.value)
                            }}
                        ></input>
                    </div>
                </td>
                <td style={{width: 90}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.priceperunitInOtherCurrency} 
                            onChange={(e)=>this.setState({ priceperunitInOtherCurrency: validateNumber(e.target.value) ? e.target.value : "" },()=>{ this.countSubTotal() })} 
                            onBlur={(e)=>{
                                this.generateObject('pricePerUoMInOtherCurrency', e.target.value)
                            }}
                        ></input>
                    </div>
                </td>
                <td style={{width: 80}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.discount} 
                            maxLength={5}
                            onChange={(e)=>{
                                if(validatePercentage(e.target.value))
                                this.setState({ discount: e.target.value },()=>{ this.countSubTotal() })} 
                            }
                            onBlur={(e)=>{
                                this.generateObject('discount', e.target.value)
                                onBlurProduct(this.state.productObj, counter)
                            }}
                        ></input>
                        <Ai.AiOutlinePercentage className='datepickerIcon cursor-na'/>
                    </div>
                </td>
                
                <td style={{width: 110}}>
                    <div className='position-relative'>
                        <input 
                            type='text' 
                            className='input-box width70' 
                            value={this.state.rawtotalInOtherCurrency}
                            disabled
                        ></input> {this.state.currencyConversionDetail.currencySymbol}
                    </div>
                </td>
               
                
                <td style={{width: 80, textAlign: "center"}} onClick={()=>this.goToTargetLot()}><span className="clickable__source">{this.state.targetLotNumber}</span></td>
                <td style={{width: 110}}>
                    <div className='position-relative'>
                        <DatePicker 
                        dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            selected={
                                this.state.expectedDate
                            }
                            onChange={
                                date => this.setState({ expectedDate: date },()=>{
                                    this.generateObject('expectedDate', date)
                                    onBlurProduct(this.state.productObj, counter)
                                })
                            }
                            className="input-box"/>
                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                    </div>
                </td>
                <td style={{width: 80}}>{convertDateStringMMDDtoDDMM(this.state.arrivalDate)}</td>
                <td></td>
                <td>
                    {
                        (this.state.item !== "" || this.state.freeText !== "") &&
                        <Fi.FiX className="extraFieldCloseButton" onClick={onRemoveProduct.bind(this,counter)} />
                    }
                </td>
            </tr>
        );
    }
}

export default GroupItem;