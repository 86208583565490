import React, { Component } from 'react';
import { Header, ProductionPlanningHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import * as Fi from 'react-icons/fi'
import * as APIManager from './../../../APIManager/APIManager'

import Pagination from "react-js-pagination";
import TableLoading from '../../../helper/TableLoading';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';
import {
    ImArrowDown2,
} from "react-icons/im";

class Routings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            number: "",
            name: "",
            bomNumber: "",
            bomName: "",
            articleCode: "",


            count: 0,
            responseData: [],
            recordList: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,

            fieldName: '',
            fieldType: '',
            sortVal: false,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }
    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.responseData.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }

            });
            this.setState({ responseData: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************
    getAllRouting = () => {
        this.setState({ isLoading: true, error: false })
        let {
            number,
            name,
            articleCode,
            bomNumber,
            bomName,
            start,
            length
        } = this.state
        APIManager.callGetAllRouting(number, name, articleCode, bomNumber, bomName,
            // workStationGroupNumber,workStationGroupName, otherDescription, minSetUpTime, maxSetUpTime, minCycleTime, maxCycleTime, 
            start, length)
            .then(response => {
                this.setState({
                    isLoading: false,
                    responseData: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()
                    this.createListing()

                    if (this.state.fieldName) {
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'name': this.state.name,
            'bomNumber': this.state.bomNumber,
            'bomName': this.state.bomName,

            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,

            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
        }
        localStorage.setItem("productionPlaningRouting", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("productionPlaningRouting"))
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                start: filterData.start,
                length: filterData.length,
                number: filterData.number,
                name: filterData.name,
                bomNumber: filterData.bomNumber,
                bomName: filterData.bomName,

                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,

            }, () => {
                this.getAllRouting()
            })
        } else {
            this.getAllRouting()
        }
    }


    handlePageChange(pageNumber) {

        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllRouting()
        });
    }

    createListing = () => {
        let recordData = []
        let records = this.state.responseData
        for (let i = 0; i < records.length; i++) {
            recordData.push(
                <tr onClick={() => this.editRouting(records[i]._id)} key={records[i]._id + i}>
                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                    <td className="text-uppercase">{records[i].number}</td>
                    <td>{records[i].name}</td>
                    <td>{records[i].articleCode}</td>
                    <td>{records[i].bomNumber}</td>
                    <td>{records[i].bomName}</td>
                    {/* <td className="text-uppercase">{records[i].workStationGroupNumber}</td>
                    <td>{records[i].workStationGroupName}</td>
                    <td>{records[i].otherDescription}</td>
                    <td>{records[i].setUpTime} min</td>
                    <td>{records[i].cycleTime} min</td> */}
                    <td></td>
                </tr>
            )
        }
        this.setState({
            recordList: recordData
        })
    }

    clearFilter = () => {
        localStorage.removeItem("productionPlaningRouting")
        this.setState({
            number: "",
            name: "",
            bomNumber: "",
            bomName: "",
            articleCode: "",
            // workStationGroupNumber: "",
            // workStationGroupName: "",
            // otherDescription: "",
            // minSetUpTime: "",
            // maxSetUpTime: "",
            // minCycleTime: "",
            // maxCycleTime: "",
            length: 25,
            start: 0,
            activePage: 1
        }, () => {
            this.getAllRouting()
        })
    }


    addRouting = () => {
        let slug = "ProductionPlanning_Routings"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createrouting"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editRouting = (id) => {
        this.props.history.push({
            pathname: "/editrouting",
            state: { RoutingId: id, newRoutingEntry: false }
        })
    }

    exportAsPDF = () => {
        let responseData = this.state.responseData;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Production Planning - Routing";

        const headers = [[
            "Number",
            "Name",
            "Article Code",
            "BOM Number",
            "BOM Name",

        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.name,
            elt.articleCode,
            elt.bomNumber,
            elt.bomName
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("routing.pdf")
    }

    exportAsCSV = () => {
        let responseData = this.state.responseData;
        const title = "Production Planning - Routing";
     
        const headers = [[
            "Number",
            "Name",
            "Article Code",
            "BOM Number",
            "BOM Name",

        ]];
        const data = responseData.map((elt, i) => [
            elt.number,
            elt.name,
            elt.articleCode,
            elt.bomNumber,
            elt.bomName
        ]);
        const options = {
            filename: 'routing',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }

    goBack = () => {
        this.props.history.goBack();
    }
    getIndexNumber = (i, j) => {
        return i + j
    }

    render() {
        return (
            <div className="page absoluteWhite">

                <Header logout={this.props.history} goBack={() => this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isRoutings={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.ProductionPlanning.Routings}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <    ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 15 }}><div onClick={() => this.addRouting()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)} style={{ width: 70 }}><span>Number </span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('name', 'notDate', false)}><span>Name </span> {this.state.fieldName === "name" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "name" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('articleCode', 'notDate', false)}><span>Article Code </span> {this.state.fieldName === "articleCode" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "articleCode" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('bomNumber', 'notDate', false)} style={{ width: 100 }}><span>BOM Number </span> {this.state.fieldName === "bomNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "bomNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('bomName', 'notDate', false)}><span> BOM Name </span> {this.state.fieldName === "bomName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "bomName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader tableHeadButton" style={{ width: 35 }}><div onClick={() => this.addRouting()}><Fi.FiPlus /></div></th>
                                </tr>

                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                    <th className="tableElemContain"><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.articleCode} onChange={(e) => this.setState({ articleCode: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.bomNumber} onChange={(e) => this.setState({ bomNumber: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.bomName} onChange={(e) => this.setState({ bomName: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    {/* <th className="tableElemContain"><input type="text" value={this.state.workStationGroupNumber} onChange={(e) => this.setState({ workStationGroupNumber: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.workStationGroupName} onChange={(e) => this.setState({ workStationGroupName: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="tableElemContain"><input type="text" value={this.state.otherDescription} onChange={(e) => this.setState({ otherDescription: e.target.value }, () => { this.getAllRouting() })} /></th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minSetUpTime} onChange={(e) => this.setState({ minSetUpTime: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllRouting() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxSetUpTime} onChange={(e) => this.setState({ maxSetUpTime: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllRouting() })} />
                                    </th>
                                    <th className="items ElemContain">
                                        <input type="text" style={{ width: 50 }} placeholder="min" value={this.state.minCycleTime} onChange={(e) => this.setState({ minCycleTime: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllRouting() })} />&nbsp;-&nbsp;
                                        <input type="text" style={{ width: 50 }} placeholder="max" value={this.state.maxCycleTime} onChange={(e) => this.setState({ maxCycleTime: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.getAllRouting() })} />
                                    </th> */}

                                    <th className="tableElemContain tableAddButton"><div className="cursor-pointer" onClick={() => this.clearFilter()}><Fi.FiX /></div></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                    :
                                    <tbody>
                                        {
                                            this.state.responseData.map((data, i) => (
                                                <tr onClick={() => this.editRouting(data._id)} key={'Routing' + i}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.articleCode}</td>
                                                    <td>{data.bomNumber}</td>
                                                    <td>{data.bomName}</td>

                                                    <td></td>
                                                </tr>
                                            ))
                                            // this.state.recordList
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Routings;





// setFilterData = () => {

//     let filterDataArray = {
//         'number': this.state.number,
//         'invoiceNo': this.state.invoiceNo,
//         'purchaseOrderNumber': this.state.purchaseOrderNumber,
//         'vendorName': this.state.vendorName,
//         'minRejectedQty': this.state.minRejectedQty,
//         'maxRejectedQty': this.state.maxRejectedQty,
//         'minSubTotal': this.state.minSubTotal,
//         'maxSubTotal': this.state.maxSubTotal,
//         'minTotal': this.state.minTotal,
//         'maxTotal': this.state.maxTotal,
//         'minInvoiceDate': this.state.minInvoiceDate,
//         'maxInvoiceDate': this.state.maxInvoiceDate,
//         'minModifiedDate': this.state.minModifiedDate,
//         'maxModifiedDate': this.state.maxModifiedDate,

//         'fieldName': this.state.fieldName,
//         'fieldType': this.state.fieldType,
//         'sortVal': this.state.sortVal,
//         'activePage': this.state.activePage,
//         'start': this.state.start,
//         'length': this.state.length,
//     }
//     localStorage.setItem("debitNotesAk", JSON.stringify(filterDataArray))
// }


// getFilterData = () => {
//     let filterData = JSON.parse(localStorage.getItem("debitNotesAk"))
//     if (filterData !== null) {
//         this.setState({
//             activePage: parseInt(filterData.activePage),
//             start: filterData.start,
//             length: filterData.length,
//             number: filterData.number,
//             invoiceNo : filterData.invoiceNo,
//             purchaseOrderNumber : filterData.purchaseOrderNumber,
//             vendorName : filterData.vendorName,
//             minRejectedQty : filterData.minRejectedQty,
//             maxRejectedQty : filterData.maxRejectedQty,
//             minSubTotal : filterData.minSubTotal,
//             maxSubTotal : filterData.maxSubTotal,
//             minTotal : filterData.minTotal,
//             maxTotal : filterData.maxTotal,
//             minInvoiceDate : filterData.minInvoiceDate,
//             maxInvoiceDate : filterData.maxInvoiceDate,
//             minModifiedDate : filterData.minModifiedDate,
//             maxModifiedDate : filterData.maxModifiedDate,

//             fieldName: filterData.fieldName ? filterData.fieldName : '',
//             fieldType: filterData.fieldType ? filterData.fieldType : '',
//             sortVal: filterData.sortVal ? filterData.sortVal : false,
//         }, () => {
//             this.getAllDebitNotes()
//         })
//     } else {
//         this.getAllDebitNotes()
//     }
// }
