import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Helper from './../../../helper/helper'

import {
    AiOutlineExclamation
} from "react-icons/ai";

import MeasurementInfo from './../../../components/AutoGenerateComponent/addMeasurementInfo'


export default class EditUnitOfMeasurement extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",
            
            name: "",
            title: "",

            countMeasurementNumber: [],
            addMeasurementFields: [],
            count: 0,
            measurementFieldValues: [],
            generateNewMeasurement: true,


            fieldNotEditable: true,


        }
    }

    componentDidMount(){
        this.getMeasureVal()
    }

    getMeasureVal = () => {
        let that = this;
        let getUserToken = localStorage.getItem("userdata")
        let token = JSON.parse(getUserToken).login_token

        let _id = localStorage.getItem("measurementId")

        let myHeaders = new Headers();
        myHeaders.append("token", token);

        let formdata = new FormData();
        formdata.append("id", _id);

        fetch(APIManager.WEBSERVICES_URL+'stock/stocksetting/getSingleUnitOfMeasurement',{
            method: "post",
            headers: myHeaders,
            body: formdata,
        })
            .then(res => res.json())
            .then((result) => {
                if(result.status === "success"){
                    let conversions = result.data.unitConversions
                    that.setState({
                        name: result.data.name,
                        title: result.data.name,
                        measurementFieldValues: conversions,
                    },()=>{
                        that.setFieldValues(conversions)
                    })
                } else if(result.status === "userDataError"){
                    that.logout()
                } else{
                    that.setState({
                        error: true,
                        errormessage: result.message
                    })
                }

            },(error) => {
                console.log(error)
            }
        )
    }

    setFieldValues = (conversions) => {
        let numArray = []
        let fields = []
        for(let i=0;i<conversions.length;i++){
            numArray.push(i)
            fields.push(
                <MeasurementInfo 
                    key={i}
                    componentNum={i}
                    name={this.state.name}
                    valueOfName={conversions[i].Name}
                    valueOfRate={conversions[i].Rate}
                    onBlur={this.measureInputBlur}
                    onEdit={this.measureInputEdit}
                    onRemove={this.measureInputRemove}
                />
            )
        }
        this.setState({
            addMeasurementFields: fields,
            countMeasurementNumber: numArray,
            count: conversions.length
        },()=>{
            this.addNewField(conversions.length)
        })
    }
    
    addNewField = (len) => {
        this.setState({
            addMeasurementFields: this.state.addMeasurementFields.concat(
                [
                    <MeasurementInfo 
                        key={len+1}
                        componentNum={len+1}

                        valueOfName={""}
                        valueOfRate={""}
                        onBlur={this.measureInputBlur}
                        onEdit={this.measureInputEdit}
                        onRemove={this.measureInputRemove}
                    />
                ]
            ),
            count: this.state.count+1,
            countMeasurementNumber: this.state.countMeasurementNumber.concat([this.state.count]),
        })
    }

    createInitialField = () => {
        let field = [
            <MeasurementInfo 
                key={this.state.count}
                componentNum={this.state.count}
                name={this.state.name}
                valueOfName={""}
                valueOfRate={""}
                onBlur={this.measureInputBlur}
                onEdit={this.measureInputEdit}
                onRemove={this.measureInputRemove}
            />
        ]
        this.setState({
            addMeasurementFields: field,
            countMeasurementNumber: [0],
            count: 0,
            measurementFieldValues: [],
            generateNewMeasurement: true,
        })
    }

    
    measureInputEdit = (name, rate, num) => {

        let array = [...this.state.countMeasurementNumber];
        let index = array.indexOf(num)
        let measureVal = [...this.state.measurementFieldValues]     
        
        
        if (typeof measureVal[index] === 'undefined' && this.state.generateNewMeasurement === true && name !== "" && rate !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewMeasurement: false,
            }, () => {
                
                this.setState({
                    countMeasurementNumber: this.state.countMeasurementNumber.concat(
                        [this.state.count]
                    ),
                    addMeasurementFields: this.state.addMeasurementFields.concat(
                        [
                            <MeasurementInfo 
                                key={this.state.count}
                                componentNum={this.state.count}
                                valueOfName={""}
                                valueOfRate={""}
                                onBlur={this.measureInputBlur}
                                onEdit={this.measureInputEdit}
                                onRemove={this.measureInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    measureInputBlur = (name, rate, num) => {
         let array = [...this.state.countMeasurementNumber];
         let index = array.indexOf(num)
         let measureVal = [...this.state.measurementFieldValues]   
 
         if (typeof measureVal[index] !== 'undefined') {
             if (name !== "" && rate !== ""){
                 let obj = {
                     "Name": name,
                     "Rate": rate
                 }
                 let measurementFieldValues = [ ...this.state.measurementFieldValues ];
                 measurementFieldValues[index] = obj;
 
                 this.setState({
                     measurementFieldValues,
                     generateNewMeasurement: false,
                 })
             }
         }else{
             if (name !== "" && rate !== "") {
                 let obj = {
                     "Name": name,
                     "Rate": rate
                 }
                 this.setState({
                     measurementFieldValues: this.state.measurementFieldValues.concat([ obj ]),
                     generateNewMeasurement: true,
                 })
             }
         }
     }
 

    measureInputRemove = (number) => {
       
        let array = [...this.state.countMeasurementNumber];
        let fields = [...this.state.addMeasurementFields];
        let data = [...this.state.measurementFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countMeasurementNumber: array,
                addMeasurementFields: fields,
                measurementFieldValues: data
            });
        }   
    }



    updateUnitMeasure = () => {
        if(this.state.fieldNotEditable){
            let slug = "Stock_StockSettings"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }else{
            let name = this.state.name;
            let val = this.state.measurementFieldValues;

            let filteredVal = val.filter(function (el) {
                return el != null;
            });

            let that = this;
            if(name === ""){
                this.setState({
                    error: true,
                    errormessage: "Please enter name",
                })
            }else if(val.filteredVal === 0){
                this.setState({
                    error: true,
                    errormessage: "Please add rate values",
                })
            }else{
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let _id = localStorage.getItem("measurementId")

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id",_id);
                formdata.append("name", name.toLowerCase());
                formdata.append("unitConversions", JSON.stringify(filteredVal))

                fetch(APIManager.WEBSERVICES_URL+'stock/stocksetting/updateUnitOfMeasurement',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                            that.goBack()
                        } else if(result.status === "userDataError"){
                            that.logout()
                        } else{
                            that.setState({
                                error: true,
                                errormessage: result.message
                            })
                    }

                    },(error) => {
                    console.log(error)
                    }
                ) 
            }
        }
    }

    deleteUnitMeasure = () => {
        let slug = "Stock_StockSettings"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
            
                this.setState({ loading: true })
                const that = this;

                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let _id = localStorage.getItem("measurementId")

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", _id)
                
                fetch(APIManager.WEBSERVICES_URL+'stock/stocksetting/deleteUnitOfMeasurement',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                        if(result.status === "success"){
                            that.setState({ loading: false },()=> {
                                that.goBack()
                            })
                        }else if(result.status === "userDataError"){
                            that.setState({ loading: false },()=> {
                                that.logout()
                            })
                        }else{
                            that.setState({
                                loading: false,
                                error: true,
                                errormessage: result.message
                            })
                        }
                    },(error) => {
                    console.log(error)
                    }
                )
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewMeasure") !== null){
            localStorage.removeItem("createNewMeasure")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <StockHeader isStockSettings={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Unit of measurement {this.state.title} details</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateUnitMeasure()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteUnitMeasure()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr" 
                                        value={this.state.name} 
                                        onChange={(e)=>this.setState({ name: e.target.value })}
                                        onBlur={(e)=>{
                                            if(e.target.value !== ""){
                                                this.createInitialField()
                                            }
                                        }}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner" style={{marginTop: 10}}>
                                <div className="addNewCustomerContactTitle">
                                    <div className="title2">Unit conversions</div>
                                </div>
                                <div className="addNewCustomerContactField">
                                    <div className="mt-1 mb-1">
                                        <div className="width40 float-left">Name</div>
                                        <div className="width60 pl-20px float-left">Rate</div>
                                        <div className="clear"></div>
                                    </div>
                                    <table>
                                        <tbody className="mt-1 mb-1">
                                        {
                                            this.state.addMeasurementFields
                                        }
                                        </tbody>
                                    </table>   
                                </div>  
                                <div className="clear"></div>                   
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateUnitMeasure()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteUnitMeasure()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}