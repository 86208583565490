import React from 'react';

import DatePicker from "react-datepicker";
import './../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'

import { 
    FiX,
} from "react-icons/fi";


export default class CompanyHolidays extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            holidayname: this.props.holidayname,
            holidaydate: "",
        }

    }

    componentDidMount(){

        if(this.props.holidaydate !== ""){
            this.setState({
                holidaydate: new Date(this.props.holidaydate)
            })
        }
       
    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
            
        } = this.props
        return(
            <tr>
                <td>
                    <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.holidayname}
                        onChange={(e)=>this.setState({ holidayname: e.target.value},()=>{
                            onEdit(this.state.holidayname, this.state.holidaydate, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.holidayname, this.state.holidaydate, componentNum)
                        }
                    />
                </td>
                <td>
                    <div className="position-relative">
                        <DatePicker 
                            selected={this.state.holidaydate}
                            minDate={new Date()}
                            onChange={date => this.setState({ holidaydate: date },()=>{
                                onEdit(this.state.holidayname, this.state.holidaydate, componentNum)
                                onBlur(this.state.holidayname, this.state.holidaydate, componentNum)
                            })}
                            dateFormat="dd/MM/yyyy"
                        />
                        <Ai.AiOutlineCalendar className="__date_picker_icon_for_company_working_hours" /> 
                    </div>                                   
                </td>
                <td>
                    {
                        this.state.holidayname !== "" && this.state.holidaydate !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}