import React from 'react';


import { 
    FiX,
} from "react-icons/fi";


export default class NewFile extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            newFile: this.props.newFile,
            onBlur: this.props.onBlur,
            onEdit: this.props.onEdit,
            onRemove: this.props.onRemove,
            componentNum: this.props.componentNum,
            
        }

    }

    componentDidMount(){
       
    }

    onFileChange = event => {
        // let that = this;
        if (event.target.files && event.target.files[0]) {
            this.setState({ newFile: event.target.files[0] },()=>{
                this.state.onEdit(this.state.newFile, this.state.componentNum)
                this.state.onBlur(this.state.newFile, this.state.componentNum)
            });  
            // var reader = new FileReader();
            // reader.onload = function (e) {
            //     that.setState({ docSrc: e.target.result })
            // }
            // reader.readAsDataURL(event.target.files[0]);
        }
    }
   

    render(){
        let {
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td>
                    <input 
                        className='input-file add_inner'
                        type="file"
                        onChange={this.onFileChange}
                        accept="application/msword, application/vnd.ms-excel, application/pdf, image/png, image/jpg, image/jpeg"
                    ></input>
                    {/* <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.newFile}
                        onChange={(e)=>this.setState({ newFile: e.target.value},()=>{
                            onEdit(this.state.newFile, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.newFile, componentNum)
                        }
                    /> */}
                </td>
                <td>
                    {
                        this.state.newFile !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}