import React, { Component } from 'react';
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import * as APIManager from './../../../../APIManager/APIManager'
import * as Fi from 'react-icons/fi'

class generateDocument extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            pumpDocumentData: [],

            documentType: this.props.documentType,
            documentFile: this.props.documentFile,
            newDocumentSrc: "",
            documentNote: this.props.documentNote,

            documentEdit: this.props.onEdit,
            documentBlur: this.props.onBlur,
            componentNum: this.props.componentNum

        }
    }

    componentDidMount(){
        this.callGetPumpDocumentType()
    }

    callGetPumpDocumentType = () => { 
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPumpDocumentType()
        .then(response => {
            let res = response.data.pumpDocumentName !== undefined ? response.data.pumpDocumentName : []
            let updatedData = []
            if(res.length > 0){
                for(let i=0;i<res.length;i++){
                    let obj = { id: res[i].pumpDocumentName, text: res[i].pumpDocumentName }
                    updatedData.push(obj)
                }
            }
            this.setState({ 
                isLoading: false,
                pumpDocumentData: updatedData
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    onDocumentChange = event => {
        let {
            documentType,
            documentNote,
            componentNum
        } = this.state

        let that = this;
        if (event.target.files && event.target.files[0]) {
            this.setState({ newDocumentSrc: event.target.files[0] });  

            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ documentFile: e.target.result },()=>{
                    that.state.documentEdit(documentType, that.state.documentFile, componentNum)
                    that.state.documentBlur(documentType, that.state.documentFile, documentNote, componentNum)
                })
            }
            reader.readAsDataURL(event.target.files[0]);


        }
    }


    viewSelectedFile = () => {
        let file = this.state.documentFile
        let pdfWindow = window.open("")
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" +encodeURI(file)+ "'></iframe>"
        )
    }

    render() {
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return (
            <tr>
                <td style={{ width: 200 }}>
                    <Select2    
                        data={this.state.pumpDocumentData}
                        value={this.state.documentType}
                        options={{placeholder: 'Select'}}
                        onSelect={(e)=> this.setState({ documentType: e.target.value },()=>{
                            onEdit(this.state.documentType, this.state.documentFile, componentNum)
                            onBlur(this.state.documentType, this.state.documentFile, this.state.documentNote, componentNum)
                        })}
                    />
                </td>
                <td style={ this.state.documentFile ? { width: 320 } : { width: 220 }}>
                    <input 
                        type="file" 
                        className={ this.state.documentFile ? 'input-file doc_file_width_updated' :  'input-file' }
                        accept="image/png, image/gif, image/jpeg, application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={this.onDocumentChange}
                    />
                    {
                        this.state.documentFile &&
                        <div className='doc-saved-file' onClick={()=>this.viewSelectedFile()}>Download</div>
                    }
                </td>
                <td>
                    <textarea 
                        className='text-area' 
                        value={this.state.documentNote}
                        onChange={(e)=>this.setState({ documentNote: e.target.value},()=>{
                            onEdit(this.state.documentType, this.state.documentFile, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.documentType, this.state.documentFile, this.state.documentNote, componentNum)
                        }
                    ></textarea>
                </td>
                <td style={{ width: 40 }}>
                    {
                        this.state.documentType !== "" &&
                            <Fi.FiX className="extraFieldCloseButton" onClick={()=>onRemove(componentNum)} />
                    }
                </td>
            </tr>
        );
    }
}

export default generateDocument;