import React, { Component } from 'react';
import * as Bi from 'react-icons/bi'
import { convertDateTimeStringMMDDtoDDMM } from '../../../constants/DateFormatter';
import * as APIManager from './../../../APIManager/APIManager'

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css'

class editMOOperations extends Component {

    constructor(props){
        super(props)
        this.state = {
            workstationData: this.props.workstationData,
            moId: this.props.moId,
            getSingleMOCall: this.props.getSingleMOCall,
            userData: [],
            assignedUser: this.props.workstationData.userId
        }
    }

    componentDidMount(){
        this.getUsersByDepartment(this.state.workstationData.department)
    }

    getUsersByDepartment = (_id) => {
        if(_id){
            this.setState({ isLoading: true, error: false })
            APIManager.callGetUsersByDepartment(_id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    userData: response.data
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }

    updateDefaultUser = () => {
        let {
            moId,
            assignedUser,
            workstationData
        } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callUpdateDefaultWorker(moId, workstationData.id, assignedUser)
        .then(response => {
            console.log("Update default worker ", response)
            this.setState({ 
                isLoading: false,
            },()=>{
                this.state.getSingleMOCall()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
    }

    render() {
        return (
            <tr>
                <td></td>
                <td>{this.state.workstationData.workStationGroupName}</td>
                <td></td>
                <td>{this.state.workstationData.workStationName}</td>
                <td>{this.state.workstationData.manufacturingOverhead ? this.state.workstationData.manufacturingOverhead.toFixed(2) : this.state.workstationData.manufacturingOverhead} <Bi.BiRupee /></td>
                <td>{convertDateTimeStringMMDDtoDDMM(this.state.workstationData.startDate)}</td>
                <td>{convertDateTimeStringMMDDtoDDMM(this.state.workstationData.endDate)}</td>
                <td>
                    <Select2    
                        data={
                            this.state.userData.map((data) => (
                                { id: data._id, text: data.username ? data.username : data.full_name }
                            ))
                        }
                        disabled={this.state.workstationData.is_MoStart ? true : false}
                        value={this.state.assignedUser}
                        options={{placeholder: 'Select User'}}
                        onSelect={(e)=> this.setState({ assignedUser: e.target.value },()=>{ this.updateDefaultUser()} )}
                    />
                </td>
                <td className="text-right clr-secondary"></td>
            </tr>
        );
    }
}

export default editMOOperations;