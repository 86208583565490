import React from 'react';

import { Header, CRMHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Helper from './../../../helper/helper'
import Loading from './../../../helper/loading'

import { Link } from 'react-router-dom';

import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import Popup from 'reactjs-popup';
import * as Fi from "react-icons/fi";

import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';

import * as APIManager from './../../../APIManager/APIManager'
import * as ExportData from './../Customers/exportData'

import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'


import {
    AiOutlineCalendar,
} from "react-icons/ai"

import {
    ImArrowDown2
} from "react-icons/im";

import {
    FiChevronsDown,
    FiPlus,
    FiSearch,
    FiX,
} from "react-icons/fi";
import { convertDateToDDMMYYYY } from '../../../constants/DateFormatter';

export default class TodaysContacts extends React.Component {

    constructor(props) {
        super(props);

        let today = new Date(),
            date = ('0' + (today.getMonth() + 1)).slice(-2) + '/'
                + ('0' + today.getDate()).slice(-2) + '/'
                + today.getFullYear();

        this.state = {
            isLoading: true,
            /* Company Data */
            responseData: [],
            accountManagerList: [],
            totalData: 0,
            activePage: 1,
            start: 0,
            length: 25,
            companyStatusData: [],
            companyIndustryData: [],


            /* Contact Data */
            contactResponseData: [],
            totalContactData: [],
            activeContactPage: 1,
            pageContactLength: 10,
            contactPageStart: 0,

            /* Company Search Paramter & Values */
            number: "",
            name: "",
            status: "",
            nickName: "",
            panNo: "",
            gstNo: "",
            minCreditLimit: "",
            maxCreditLimit: "",
            manager: "",
            Phone: "",
            Fax: "",
            email: "",
            Web: "",
            Address: "",
            other: "",
            Skype: "",
            nextContactStart: new Date(date),
            nextContactEnd: new Date(date),
            contactStart: "",
            contactEnd: "",
            deliveryTerms: "",
            searchnote: "",
            industrytype: "",
            paymentTerms: "",
            fieldName:"",
            fieldType:"",
            sortVal:true,

            /* Show/Hide Column */
            /* Company Column */
            col_disp_number: true,
            col_disp_name: true,
            col_disp_status: true,
            col_disp_panno: false,
            col_disp_gstnum: false,
            col_disp_contactstarted: false,
            col_disp_netxtcontact: true,
            col_disp_accmanager: true,
            col_disp_creditlimit: false,
            col_disp_deliveryterms: false,
            col_disp_phone: true,
            col_disp_email: true,
            col_disp_skype: false,
            col_disp_web: false,
            col_disp_industrytype: false
        }
        this.onSelectStatus = this.onSelectStatus.bind(this)
    }

    componentDidMount() {
        this.getFilterData()

        // if(localStorage.getItem("advanceSearchNote") !== null){
        //     this.setState({ searchnote: localStorage.getItem("advanceSearchNote") },()=>{
        //         localStorage.removeItem("advanceSearchNote")
        //         this.getActivePage()
        //     })
        // }else{
        //     this.setState({ searchnote: "" },()=>{
        //         this.getActivePage()
        //     })
        // }
    }

    //******************* ascending descending order function ********************************

    sortCustomer = (fieldName, fieldType, onload) => {
     
        let sortVal = true;
        if (this.state.fieldName === fieldName) {
            sortVal = !this.state.sortVal;
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        });
        const sortedOrders = this.state.responseData.sort((a, b) => {
    
            if (fieldType === "Date") {
                const day = a[fieldName].substr(0, 3)
                const month = a[fieldName].substr(3, 3)
                const year = a[fieldName].substring(6, 10)
                const dateOne = `${month}${day}${year}`

                const dayb = b[fieldName].substr(0, 3)
                const monthb = b[fieldName].substr(3, 3)
                const yearb = b[fieldName].substring(6, 10)
                const dateTwo = `${monthb}${dayb}${yearb}`

                const dateA = dateOne ? new Date(dateOne): null;
                const dateB = dateTwo ? new Date(dateTwo): null;

                if (!dateA && !dateB) {
                    return 0;
                } else if (!dateA) {
                    return sortVal ? 1 : -1;
                } else if (!dateB) {
                    return sortVal ? -1 : 1;
                }
  
                console.log(dateB, dateA);
                if (dateA.getFullYear() !== dateB.getFullYear()) {
                    return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                }
                if (dateA.getMonth() !== dateB.getMonth()) {
                    return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                }
                if (dateA.getDate() !== dateB.getDate()) {
                    return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                }
                return 0;
            }
            else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                console.log('NotDate success');
                const numA = parseFloat(a[fieldName]);
                const numB = parseFloat(b[fieldName]);
                if (sortVal) {
                    return numA > numB ? 1 : -1;
                } else {
                    return numA < numB ? 1 : -1;
                }
            }  else {
                const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                if (sortVal) {
                  return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                } else {
                  return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                }
              }
               
        });
        this.setState({ responseData: sortedOrders });
    };
//******************* ascending descending order function end ********************************

    search = () => {
        localStorage.removeItem("customersActivePage")
        this.setState({ activePage: 1, isLoading: true }, () => {
            this.customerListing()
        })
    }

    getActivePage = () => {
        let filterData = JSON.parse(localStorage.getItem("customerTableFilter"))

        if (localStorage.getItem("customersActivePage") !== null) {
            this.setState({
                activePage: parseInt(localStorage.getItem("customersActivePage"))
            }, () => {
                this.customerListing();
                this.getUserList()
                this.getCustomerStatus()
                this.getCompanyIndustry()
            })
        } else {
            this.customerListing();
            this.getUserList()
            this.getCustomerStatus()
            this.getCompanyIndustry()
        }
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.setAllInLocal()
        });
    }


    getCustomerStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerStatus()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyStatusData: response
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyIndustryData: response.companyIndustry !== undefined ? response.companyIndustry : null
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }



    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
            .then(response => {
                this.setState({
                    isLoading: false,
                    accountManagerList: response.data
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    customerListing = () => {
        let { length, nextContactStart, nextContactEnd, contactStart, contactEnd, paymentTerms, deliveryTerms, number, name, status, nickName, panNo, gstNo, minCreditLimit, maxCreditLimit, manager, Phone, Fax, email, Web, Skype, Address, other, searchnote, industrytype, activePage, fieldName } = this.state
        let start = activePage !== 1 ? length * (activePage - 1) + 1 : 0
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerListWithFilter(start, length, this.setContactDate(nextContactStart), this.setContactDate(nextContactEnd), this.setContactDate(contactStart), this.setContactDate(contactEnd), paymentTerms.toLowerCase(), deliveryTerms.toLowerCase(), number.toLowerCase(), name.toLowerCase(), status, nickName.toLowerCase(), panNo.toLowerCase(), gstNo.toLowerCase(), minCreditLimit, maxCreditLimit, manager.toLowerCase(), Phone.toLowerCase(), Fax, email.toLowerCase(), Web.toLowerCase(), Skype.toLowerCase(), Address.toLowerCase(), other.toLowerCase(), searchnote.toLowerCase(), industrytype, fieldName)
            .then(response => {
                this.setState({
                    isLoading: false,
                    totalData: response.totalRecords,
                    responseData: response.data,
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }



    exportOptions = (exportTo) => {
        if (exportTo === "pdf")
            ExportData.exportCompanyPDF(this.state.responseData);
        else
            ExportData.exportCompanyCSV(this.state.responseData)
    }




    setAllInLocal = () => {

        this.search()

        // alert("check setAllInLocal today contacts")

        let filterDataArray = {
            'activePage': this.state.activePage,
            'number': this.state.number,
            'name': this.state.name,
            'status': this.state.status,
            'nickName': this.state.nickName,
            'industrytype': this.state.industrytype,
            'panNo': this.state.panNo,
            'gstNo': this.state.gstNo,
            'minCreditLimit': this.state.minCreditLimit,
            'maxCreditLimit': this.state.maxCreditLimit,
            'manager': this.state.manager,
            'Phone': this.state.Phone,
            'Fax': this.state.Fax,
            'email': this.state.email,
            'Web': this.state.Web,
            'Skype': this.state.Skype,
            'Address': this.state.Address,
            'other': this.state.other,
            'deliveryTerms': this.state.deliveryTerms,
            'minContactStart': this.state.minContactStart,
            'maxContactStart': this.state.maxContactStart,
            'nextContactStart': this.state.nextContactStart,
            'nextContactEnd': this.state.nextContactEnd,
            'note': this.state.note,
            'length': this.state.length
        }


        let selectedColumnTabInCustomerTable = {
            'col_disp_number': this.state.col_disp_number,
            'col_disp_name': this.state.col_disp_name,
            'col_disp_status': this.state.col_disp_status,
            'col_disp_panno': this.state.col_disp_panno,
            'col_disp_gstnum': this.state.col_disp_gstnum,
            'col_disp_contactstarted': this.state.col_disp_contactstarted,
            'col_disp_netxtcontact': this.state.col_disp_netxtcontact,
            'col_disp_accmanager': this.state.col_disp_accmanager,
            'col_disp_creditlimit': this.state.col_disp_creditlimit,
            'col_disp_deliveryterms': this.state.col_disp_deliveryterms,
            'col_disp_phone': this.state.col_disp_phone,
            'col_disp_email': this.state.col_disp_email,
            'col_disp_skype': this.state.col_disp_skype,
            'col_disp_web': this.state.col_disp_web,
            'col_disp_industrytype': this.state.col_disp_industrytype,
        }
        localStorage.setItem("selectedColumnTabInCustomerTable", JSON.stringify(selectedColumnTabInCustomerTable))
        localStorage.setItem("todayTableFilter", JSON.stringify(filterDataArray))
    }


    getFilterData = () => {

        let filterData = JSON.parse(localStorage.getItem("todayTableFilter"))
        let columnTab = JSON.parse(localStorage.getItem("selectedColumnTabInCustomerTable"))

        if (filterData !== null) {

            this.setState({
                activePage: parseInt(filterData.activePage),
                number: filterData.number,
                name: filterData.name,
                status: filterData.status,
                nickName: filterData.nickName,
                industrytype: filterData.industrytype,
                panNo: filterData.panNo,
                gstNo: filterData.gstNo,
                minCreditLimit: filterData.minCreditLimit,
                maxCreditLimit: filterData.maxCreditLimit,
                manager: filterData.manager,
                Phone: filterData.Phone,
                Fax: filterData.Fax,
                email: filterData.email,
                Web: filterData.Web,
                Skype: filterData.Skype,
                Address: filterData.Address,
                other: filterData.other,
                deliveryTerms: filterData.deliveryTerms,
                minContactStart: filterData.minContactStart ? new Date(filterData.minContactStart) : "",
                maxContactStart: filterData.maxContactStart ? new Date(filterData.maxContactStart) : "",
                nextContactStart: filterData.nextContactStart ? new Date(filterData.nextContactStart) : "",
                nextContactEnd: filterData.nextContactEnd ? new Date(filterData.nextContactEnd) : "",
                note: filterData.note,
                length: filterData.length,

                col_disp_number: columnTab.col_disp_number,
                col_disp_name: columnTab.col_disp_name,
                col_disp_status: columnTab.col_disp_status,
                col_disp_panno: columnTab.col_disp_panno,
                col_disp_gstnum: columnTab.col_disp_gstnum,
                col_disp_contactstarted: columnTab.col_disp_contactstarted,
                col_disp_netxtcontact: columnTab.col_disp_netxtcontact,
                col_disp_accmanager: columnTab.col_disp_accmanager,
                col_disp_creditlimit: columnTab.col_disp_creditlimit,
                col_disp_deliveryterms: columnTab.col_disp_deliveryterms,
                col_disp_phone: columnTab.col_disp_phone,
                col_disp_email: columnTab.col_disp_email,
                col_disp_skype: columnTab.col_disp_skype,
                col_disp_web: columnTab.col_disp_web,
                col_disp_industrytype: columnTab.col_disp_industrytype,
            }, () => {

                this.customerListing();
                this.getUserList()

                this.getCustomerStatus()
                this.getCompanyIndustry()
                // this.getActivePage()


                if (localStorage.getItem("advanceSearchNote") !== null) {
                    this.setState({ searchnote: localStorage.getItem("advanceSearchNote") }, () => {
                        localStorage.removeItem("advanceSearchNote")
                        this.getActivePage()
                    })
                } else {
                    this.setState({ searchnote: "" }, () => {
                        this.getActivePage()
                    })
                }
            })
        } else {

            this.customerListing();
            this.getUserList()

            this.getCustomerStatus()
            this.getCompanyIndustry()
            // this.getActivePage()


            if (localStorage.getItem("advanceSearchNote") !== null) {
                this.setState({ searchnote: localStorage.getItem("advanceSearchNote") }, () => {
                    localStorage.removeItem("advanceSearchNote")
                    this.getActivePage()
                })
            } else {
                this.setState({ searchnote: "" }, () => {
                    this.getActivePage()
                })
            }
        }
    }

    clearSearch = () => {
        localStorage.removeItem("customersActivePage")
        localStorage.removeItem("todayTableFilter")
        this.setState({
            isLoading: true,
            activePage: 1,
            number: "",
            name: "",
            status: "",
            nickName: "",
            industrytype: "",
            panNo: "",
            gstNo: "",
            minCreditLimit: "",
            maxCreditLimit: "",
            manager: "",
            Phone: "",
            Fax: "",
            email: "",
            Web: "",
            Skype: "",
            Address: "",
            other: "",
            deliveryTerms: "",
            contactStart: "",
            contactEnd: "",
            nextContactStart: "",
            nextContactEnd: "",
            note: "",
            length: 25,
        }, () => {
            this.customerListing()
        })
    }


    editCustomer = (id, companyId, companyName) => {
        localStorage.setItem("customersActivePage", this.state.activePage)
        this.props.history.push({
            pathname: "/editcustomercompany",
            state: { cmpId: id, cmpIdNum: companyId, cmpName: companyName, createNewEdit: false }
        })
    }

    addContact = (id, companyId, companyName) => {
        localStorage.setItem("contactsActivePage", this.state.activeContactPage)
        localStorage.setItem("cmpId", id)
        localStorage.setItem("cmpIdNum", companyId)
        localStorage.setItem("cmpName", companyName)
        this.props.history.push('/editcustomercompany')
    }

    setContactDate = (dt) => {

        return convertDateToDDMMYYYY(dt)

        // if (dt) {
        //     let dd = dt.getDate();
        //     let mm = dt.getMonth() + 1;
        //     let yyyy = dt.getFullYear();

        //     if (dd < 10) {
        //         dd = '0' + dd;
        //     }
        //     if (mm < 10) {
        //         mm = '0' + mm;
        //     }
        //     return dd + '/' + mm + '/' + yyyy;

        // } else {
        //     return "";
        // }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.keyCode === 9) {
            this.search()
        }
    }

    onSelectStatus(status) {

        this.setState({
            status: status
        }, () => {
            this.setAllInLocal()
            this.search()
        })
    }



    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isTodaysContacts={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.CRM.TodaysContacts}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportOptions('pdf')}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportOptions('csv')}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="pagecontent">
                    <div className="headerArea">
                        <div className="pageTitle heading">{Strings.CRM.TodaysContacts}</div>
                        
                        <div className="otherButtons">
                            <div className="oButton thr" onClick={()=>this.exportOptions('pdf')}>PDF <ImArrowDown2 className="downIcon"/></div>
                            <div className="oButton fou" onClick={()=>this.exportOptions('csv')}>CSV <ImArrowDown2 className="downIcon"/></div>
                            
                            <div className="clear"></div>
                        </div>

                        <div className="clear"></div>
                    </div>
                </div> */}


                    <div className="tableContent mh400 __kDoEM__table " style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr className="no-bg">
                                    <th className="tableHeader tableHeadButton" style={{ width: 10 }} ><Link to="/createnewcompany"><FiPlus /></Link></th>
                                    {
                                        this.state.col_disp_number &&
                                        <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('companyId', 'notDate' , false)}><span>Number </span>{this.state.fieldName === "companyId" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "companyId" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_name &&
                                        <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('nickName', 'notDate' , false)}><span>Name </span>{this.state.fieldName === "nickName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "nickName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>  
                                    }
                                    {
                                        this.state.col_disp_industrytype &&
                                        <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('industryType', 'notDate' , false)}><span>Industry Type </span>{this.state.fieldName === "industryType" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "industryType" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_status &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('status', 'notDate' , false)}><span>Status</span> {this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_panno &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('panNo', 'notDate' , false)}><span>PAN no. </span>{this.state.fieldName === "panNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "panNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_gstnum &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('gstNo', 'notDate' , false)}><span>GST Number </span>{this.state.fieldName === "gstNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "gstNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_contactstarted &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('contactStarted', 'Date' , false)}><span>Contact Started </span>{this.state.fieldName === "contactStarted" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "contactStarted" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_netxtcontact &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('nextContact', 'Date' , false)}><span>Next Contact</span>{this.state.fieldName === "nextContact" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "nextContact" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_accmanager &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('managers', 'notDate' , false)}><span>Managers </span>{this.state.fieldName === "managers" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "managers" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_creditlimit &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('creditLimit', 'notDate' , false)}><span>Credit Limit </span>{this.state.fieldName === "creditLimit" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "creditLimit" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }

                                    {
                                        this.state.col_disp_deliveryterms &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('deliveryTerms', 'notDate' , false)}><span>Delivery Terms </span>{this.state.fieldName === "deliveryTerms" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "deliveryTerms" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_phone &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('Phone', 'notDate' , false)}><span>Phone </span>{this.state.fieldName === "Phone" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Phone" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_email &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('Email', 'notDate' , false)}><span>Email </span>{this.state.fieldName === "Email" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Email" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_skype &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('Skype', 'notDate' , false)}><span>Skype</span> {this.state.fieldName === "Skype" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Skype" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }
                                    {
                                        this.state.col_disp_web &&
                                         <th className="tableHeader" style={{ width: 50 }} onClick={() => this.sortCustomer('Web', 'notDate' , false)}><span>Web </span>{this.state.fieldName === "Web" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Web" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    }

                                    <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton" style={{ width: 10 }} ><FiChevronsDown /></th>} position="bottom right">
                                        <div className="selectColumns">
                                            <ul>
                                                <li><label htmlFor="number"><span>Number</span> <input type="checkbox" id="number" disabled checked={this.state.col_disp_number} onChange={() => this.setState({ col_disp_number: !this.state.col_disp_number }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="name"><span>Name</span> <input type="checkbox" id="name" disabled checked={this.state.col_disp_name} onChange={() => this.setState({ col_disp_name: !this.state.col_disp_name }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="industrytype"><span>Industry type</span> <input type="checkbox" id="industrytype" checked={this.state.col_disp_industrytype} onChange={() => this.setState({ col_disp_industrytype: !this.state.col_disp_industrytype }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="status"><span>Status</span> <input type="checkbox" id="status" disabled checked={this.state.col_disp_status} onChange={() => this.setState({ col_disp_status: !this.state.col_disp_status }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="panno"><span>PAN no.</span> <input type="checkbox" id="panno" checked={this.state.col_disp_panno} onChange={() => this.setState({ col_disp_panno: !this.state.col_disp_panno }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="gstnum"><span>GST number</span> <input type="checkbox" id="gstnum" checked={this.state.col_disp_gstnum} onChange={() => this.setState({ col_disp_gstnum: !this.state.col_disp_gstnum }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="contactstarted"><span>Contact started</span> <input type="checkbox" id="contactstarted" checked={this.state.col_disp_contactstarted} onChange={() => this.setState({ col_disp_contactstarted: !this.state.col_disp_contactstarted }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="netxtcontact"><span>Next contact</span> <input type="checkbox" disabled id="netxtcontact" checked={this.state.col_disp_netxtcontact} onChange={() => this.setState({ col_disp_netxtcontact: !this.state.col_disp_netxtcontact }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="accmanager"><span>Account manager</span> <input type="checkbox" id="accmanager" checked={this.state.col_disp_accmanager} onChange={() => this.setState({ col_disp_accmanager: !this.state.col_disp_accmanager }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="creditlimit"><span>Credit limit</span> <input type="checkbox" id="creditlimit" checked={this.state.col_disp_creditlimit} onChange={() => this.setState({ col_disp_creditlimit: !this.state.col_disp_creditlimit }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="deliveryterms"><span>Delivery terms</span> <input type="checkbox" id="deliveryterms" checked={this.state.col_disp_deliveryterms} onChange={() => this.setState({ col_disp_deliveryterms: !this.state.col_disp_deliveryterms }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="phone"><span>Phone</span> <input type="checkbox" id="phone" checked={this.state.col_disp_phone} onChange={() => this.setState({ col_disp_phone: !this.state.col_disp_phone }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="email"><span>Email</span> <input type="checkbox" id="email" checked={this.state.col_disp_email} onChange={() => this.setState({ col_disp_email: !this.state.col_disp_email }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="skype"><span>Skype</span> <input type="checkbox" id="skype" checked={this.state.col_disp_skype} onChange={() => this.setState({ col_disp_skype: !this.state.col_disp_skype }, () => { this.setAllInLocal() })} /></label></li>
                                                <li><label htmlFor="web"><span>Web</span> <input type="checkbox" id="web" checked={this.state.col_disp_web} onChange={() => this.setState({ col_disp_web: !this.state.col_disp_web }, () => { this.setAllInLocal() })} /></label></li>
                                            </ul>
                                        </div>
                                    </Popup>
                                    <th className="tableHeader tableHeadButton" style={{ width: 10 }} ><Link to="/createnewcompany"><FiPlus /></Link></th>
                                    {/* <th className="tableHeader tableHeadButton"><Link to="/"><RiPaintBrushLine /></Link></th> */}
                                </tr>
                                <tr>
                                    <th className="tableElemContain tableAddButton">{/*<FiSettings />*/}</th>
                                    {
                                        this.state.col_disp_number &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ number: e.target.value }, () => { this.setAllInLocal() })} value={this.state.number} /></th>
                                    }
                                    {
                                        this.state.col_disp_name &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 150 }} onChange={(e) => this.setState({ nickName: e.target.value }, () => { this.setAllInLocal() })} value={this.state.nickName} /></th>
                                    }
                                    {
                                        this.state.col_disp_industrytype &&
                                        <th className="tableElemContain">
                                            <select style={{ width: 100 }} value={this.state.industrytype} onChange={(e) => this.setState({ industrytype: e.target.value }, () => { this.setAllInLocal() })}>
                                                <option value=""></option>
                                                {
                                                    this.state.companyIndustryData !== null &&
                                                    this.state.companyIndustryData.map((data, i) => (
                                                        <option value={data.industrytype} key={i + data.industrytype}>{data.industrytype}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.col_disp_status &&
                                        <th className="tableElemContain" style={{ width: 100 }}>
                                            {/* <select style={{width: 100}} value={this.state.status} onChange={(e)=>this.setState({ status: e.target.value },()=>{this.search()})}>
                                        <option value=""></option>
                                        {
                                            this.state.companyStatusData !== null && 
                                            this.state.companyStatusData.map((data,i) => (
                                                <option value={data.shortname} key={i+data.shortname}>{data.shortname}</option>
                                            ))
                                        }
                                    </select> */}
                                            <MultiSelect
                                                defaultValue={this.state.status}
                                                onChange={this.onSelectStatus}
                                                options={
                                                    this.state.companyStatusData.map((data) => (
                                                        { value: data._id, label: data.value }
                                                    ))
                                                }
                                                placeholder=""
                                            />


                                        </th>
                                    }
                                    {
                                        this.state.col_disp_panno &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ panNo: e.target.value }, () => { this.setAllInLocal() })} value={this.state.panNo} /></th>
                                    }
                                    {
                                        this.state.col_disp_gstnum &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ gstNo: e.target.value }, () => { this.setAllInLocal() })} value={this.state.gstNo} /></th>
                                    }
                                    {
                                        this.state.col_disp_contactstarted &&
                                        <th className="tableElemContain">
                                            <div className="value">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyy"
                                                    selected={
                                                        this.state.contactStart
                                                    }
                                                    onChange={
                                                        date => this.setState({ contactStart: date }, () => {
                                                            this.setAllInLocal()
                                                        })
                                                    }
                                                    className="filterdateoption" /> <span className="dash">-</span>
                                                <AiOutlineCalendar className="datepickerIcon" />
                                            </div>

                                            <div className="value">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyy"
                                                    minDate={this.state.contactEnd}
                                                    selected={
                                                        this.state.contactEnd
                                                    }
                                                    onChange={
                                                        date => this.setState({ contactEnd: date }, () => {
                                                            this.setAllInLocal()
                                                        })
                                                    }
                                                    className="filterdateoption" />
                                                <AiOutlineCalendar className="datepickerIcon" />
                                            </div>
                                        </th>
                                    }
                                    {
                                        this.state.col_disp_netxtcontact &&
                                        <th className="tableElemContain">
                                            <div className="value">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyy"
                                                    minDate={new Date()}
                                                    selected={
                                                        this.state.nextContactStart
                                                    }
                                                    onChange={
                                                        date => this.setState({ nextContactStart: date }, () => {
                                                            if(this.state.nextContactEnd < this.state.nextContactStart){
                                                                this.setState({
                                                                    nextContactEnd: this.state.nextContactStart
                                                                })
                                                            }
                                                            this.setAllInLocal()
                                                        })
                                                    }
                                                    className="filterdateoption" /> <span className="dash">-</span>
                                                <AiOutlineCalendar className="datepickerIcon" />
                                            </div>

                                            <div className="value">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyy"
                                                    minDate={this.state.nextContactStart ? this.state.nextContactStart : new Date()}
                                                    selected={
                                                        this.state.nextContactEnd
                                                    }
                                                    onChange={
                                                        date => this.setState({ nextContactEnd: date }, () => {
                                                            this.setAllInLocal()
                                                        })
                                                    }
                                                    className="filterdateoption" />
                                                <AiOutlineCalendar className="datepickerIcon" />
                                            </div>
                                        </th>
                                    }
                                    {
                                        this.state.col_disp_accmanager &&
                                        <th className="tableElemContain">
                                            <select style={{ minWidth: 100 }} value={this.state.manager} onChange={(e) => this.setState({ manager: e.target.value }, () => { this.setAllInLocal() })}>
                                                <option value=""></option>
                                                {
                                                    this.state.accountManagerList !== null &&
                                                    this.state.accountManagerList.map((data) => (
                                                        <option value={data._id} key={data._id}>{data.username ? data.username : data.full_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </th>
                                    }
                                    {
                                        this.state.col_disp_creditlimit &&
                                        <th className="tableElemContain" style={{ width: 100 }}>
                                            <input type="text" style={{ width: 50 }} value={this.state.minCreditLimit} placeholder="min" onChange={(e) => this.setState({ minCreditLimit: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.setAllInLocal() })} />
                                            <input type="text" style={{ width: 50 }} value={this.state.maxCreditLimit} placeholder="max" onChange={(e) => this.setState({ maxCreditLimit: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this.setAllInLocal() })} />
                                        </th>
                                    }

                                    {
                                        this.state.col_disp_deliveryterms &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ deliveryTerms: e.target.value }, () => { this.setAllInLocal() })} value={this.state.deliveryTerms} /></th>
                                    }
                                    {
                                        this.state.col_disp_phone &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ Phone: e.target.value }, () => { this.setAllInLocal() })} value={this.state.Phone} /></th>
                                    }
                                    {
                                        this.state.col_disp_email &&
                                        <th className="tableElemContain"><input type="text" onChange={(e) => this.setState({ email: e.target.value }, () => { this.setAllInLocal() })} value={this.state.email} /></th>
                                    }
                                    {
                                        this.state.col_disp_skype &&
                                        <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ Skype: e.target.value }, () => { this.setAllInLocal() })} value={this.state.Skype} /></th>
                                    }
                                    {
                                        this.state.col_disp_web &&
                                        <th className="tableElemContain"><input type="text" onChange={(e) => this.setState({ Web: e.target.value }, () => { this.setAllInLocal() })} value={this.state.Web} /></th>
                                    }


                                    <th className="tableElemContain tableAddButton"><div onClick={() => this.search()} className="cursor-pointer"><FiSearch /></div></th>
                                    <th className="tableElemContain tableAddButton"><div onClick={() => this.clearSearch()} className="cursor-pointer"><FiX /></div></th>
                                    {/* <th className="tableElemContain tableAddButton"><MdCheckBoxOutlineBlank /></th> */}

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.responseData !== null &&
                                    this.state.responseData.map((data, i) => (
                                        <tr key={data._id} onClick={this.editCustomer.bind(this, data._id, data.companyId, data.companyName)}>
                                            <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                            {
                                                this.state.col_disp_number &&
                                                <td style={{ width: 50 }} className='text-uppercase'>{data.companyId}</td>
                                            }
                                            {
                                                this.state.col_disp_name &&
                                                <td>{data.nickName}</td>
                                            }
                                            {
                                                this.state.col_disp_industrytype &&
                                                <td>{data.industryType !== null ? data.industryType.toString() : ""}</td>
                                            }
                                            {
                                                this.state.col_disp_status &&
                                                <td>{data.status}</td>
                                            }
                                            {
                                                this.state.col_disp_panno &&
                                                <td>{data.panNo}</td>
                                            }
                                            {
                                                this.state.col_disp_gstnum &&
                                                <td>{data.gstNo}</td>
                                            }
                                            {
                                                this.state.col_disp_contactstarted &&
                                                <td>{data.contactStarted}</td>
                                            }
                                            {
                                                this.state.col_disp_netxtcontact &&
                                                <td>{data.nextContact}</td>
                                            }
                                            {
                                                this.state.col_disp_accmanager &&
                                                <td>{data.managers.toString()}</td>
                                            }
                                            {
                                                this.state.col_disp_creditlimit &&
                                                <td>{data.creditLimit}</td>
                                            }

                                            {
                                                this.state.col_disp_deliveryterms &&
                                                <td>{data.deliveryTerms}</td>
                                            }
                                            {
                                                this.state.col_disp_phone &&
                                                <td>{data.Phone}</td>
                                            }
                                            {
                                                this.state.col_disp_email &&
                                                <td className='text-lowercase'>{data.Email}</td>
                                            }
                                            {
                                                this.state.col_disp_skype &&
                                                <td>{data.Skype}</td>
                                            }
                                            {
                                                this.state.col_disp_web &&
                                                <td>{data.Web}</td>
                                            }

                                            {/* <td><FiEdit2 className="editCusomerBtn" onClick={this.editCustomer.bind(this,data._id,data.companyId,data.companyName)}/></td> */}
                                            <td></td>
                                            <td>{/*<FiBarChart />*/}</td>
                                            {/* <td><input type="checkbox" /></td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>

                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalData)}
                                    totalItemsCount={this.state.totalData}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>

                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        )
    }
}