import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';
import * as Ai from "react-icons/ai";
import * as Images from './../../../assets/Images/index'
import { 
    validateEmail
} from './../../../constants/Validate'

export default class EditCompanyDetails extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            success: false,
            successmessage: "",
            error: false,
            errormessage: "",

            // companyName: "",
            // contactDetails: "",
            // email: "",
            // website: "",
            // phone: "",
            // panNo: "",
            // vatNo: "",
            // regNumber: "",
            // registrationOffice: "",
            // corporateOffice: "",
            // regionalOffice: "",

            companyName: "",
            contactDetails: "",
            email: "",
            website: "",
            website2: "",
            phone: "",
            telephone: "",
            regNumber: "",
            panNo: "",
            regdNo: "",
            gstin: "",
            isValidGST: true,
            ecc: "",
            bank_name: "",
            bank_accno: "",
            bank_branch: "",
            bank_rtgsneft: "",
            bank_swiftcode: "",
            logoSrc: Images.PlaceholderLogo,
            companyLogo: "",
            address_line1: "",
            address_line2: "",
            address_zone: "",
            address_city: "",
            address_state: "",
            address_country: "",
            address_pin: "",
            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.setAllDetails()
    }

    setAllDetails = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCompanyDetails()
        .then(response => {
            this.setState({
                isLoading: false,
                companyName: response.data.companyName ? response.data.companyName : "",
                contactDetails: response.data.contactName ? response.data.contactName : "",
                email: response.data.email ? response.data.email : "",
                website: response.data.website ? response.data.website : "",
                website2: response.data.website2 ? response.data.website2 : "",
                phone: response.data.phone ? response.data.phone : "",
                panNo: response.data.panNo ? response.data.panNo : "",
                regNumber: response.data.registrationNumber ? response.data.registrationNumber : "",
                telephone: response.data.telephone ? response.data.telephone : "",
                regdNo: response.data.regdNo ? response.data.regdNo : "",
                gstin: response.data.gstin ? response.data.gstin : "",
                ecc: response.data.ecc ? response.data.ecc : "",
                bank_name: response.data.bank_name ? response.data.bank_name : "",
                bank_accno: response.data.bank_accno ? response.data.bank_accno : "",
                bank_branch: response.data.bank_branch ? response.data.bank_branch : "",
                bank_rtgsneft: response.data.bank_rtgsneft ? response.data.bank_rtgsneft : "",
                bank_swiftcode: response.data.bank_swiftcode ? response.data.bank_swiftcode : "",
                companyLogo: response.data.companyLogo ? response.data.companyLogo : Images.PlaceholderLogo,
                logoSrc: response.data.companyLogo ? response.data.companyLogo : Images.PlaceholderLogo,
                address_line1: response.data.address_line1 ? response.data.address_line1 : "",
                address_line2: response.data.address_line2 ? response.data.address_line2 : "",
                address_zone: response.data.address_zone ? response.data.address_zone : "",
                address_city: response.data.address_city ? response.data.address_city : "",
                address_state: response.data.address_state ? response.data.address_state : "",
                address_country: response.data.address_country ? response.data.address_country : "",
                address_pin: response.data.address_pin ? response.data.address_pin : ""
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors,
                setProductGroup: true 
            })
        });
    }

    onFileChange = event => {
        let that = this;
        if (event.target.files && event.target.files[0]) {
            this.setState({ companyLogo: event.target.files[0] });  
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ logoSrc: e.target.result })
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    isValidate() {
        let {
            companyName,
            contactDetails,
            email,
            phone,
            telephone,
            panNo,
            regdNo,
            gstin,
            isValidGST,
            companyLogo,
            website,
            address_line1,
            address_line2,
            address_zone,
            address_city,
            address_state,
            address_country,
            address_pin
        } = this.state;

        if(companyName === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter company name"
            },()=>{
                return false
            })
        }else if(contactDetails === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter contact details"
            },()=>{
                return false
            })
        }else if(email === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter email address"
            },()=>{
                return false
            })
        }else if(!validateEmail(email)){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter valid email address"
            },()=>{
                return false
            })
        }else if(phone === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter mobile number"
            },()=>{
                return false
            })
        }else if(phone.toString().length !== 10){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter valid mobile number"
            },()=>{
                return false
            })
        }else if(telephone !== "" && telephone.toString().length > 10){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter valid telephone number"
            },()=>{
                return false
            })
        }else if(panNo === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter PAN No."
            },()=>{
                return false
            })
        }else if(regdNo === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter Regd No."
            },()=>{
                return false
            })
        }else if(gstin === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter GST No."
            },()=>{
                return false
            })
        }else if(isValidGST === false){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter valid GST No."
            },()=>{
                return false
            })
        }else if(companyLogo === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please set/add company logo"
            },()=>{
                return false
            })
        }else if(website === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter company website"
            },()=>{
                return false
            })
        }else if(address_line1 === "" || address_line2 === "" || address_zone === "" || address_city === "" || address_state === "" || address_country === "" || address_pin === ""){
            this.setState({
                error: true,
                success: false,
                errormessage: "Please enter complete address"
            },()=>{
                return false
            })
        }else{
            return true
        }
    }

    validateGSTNumber = () => {
        let { gstin } = this.state;
        
        if(gstin && gstin.length >= 15){
            this.setState({ error: false })
            APIManager.callGetGstDetailByNumber(this.state.gstin)
            .then(response => {
                this.setState({
                    error: false,
                    isValidGST: true,
                })
            })
            .catch(errors => {
                this.setState({
                    error: true,
                    success: false,
                    isValidGST: false,
                    errormessage: "Please enter valid GST No."
                })
            });
        }else{
            this.setState({
                error: true,
                success: false,
                isValidGST: false,
                errormessage: "Please enter valid GST No."
            })
        }
    }

    updateCompany = () => {
        let {
            companyName,
            contactDetails,
            email,
            website,
            website2,
            phone,
            telephone,
            regNumber,
            panNo,
            regdNo,
            gstin,
            ecc,
            bank_name,
            bank_accno,
            bank_branch,
            bank_rtgsneft,
            bank_swiftcode,
            companyLogo,
            address_line1,
            address_line2,
            address_zone,
            address_city,
            address_state,
            address_country,
            address_pin
        } = this.state;


        if(this.state.fieldNotEditable !== true){
            let logo = ""
            if(typeof companyLogo === 'object'){
                logo = companyLogo
            }

            if(this.isValidate()){
                this.setState({ isLoading: true, error: false })
                APIManager.callSetCompanyDetails(companyName,contactDetails,email,website,website2,phone,telephone,regNumber,panNo,regdNo,gstin,ecc,bank_name,bank_accno,bank_branch,bank_rtgsneft,bank_swiftcode,logo,address_line1,address_line2,address_zone,address_city,address_state,address_country,address_pin)
                .then(response => {
                    localStorage.setItem('completionDetails',JSON.stringify(response.data.completionDetails))
                    this.setState({
                        isLoading: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Company detail updated successfully."
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors,
                        setProductGroup: true 
                    })
                });
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />

                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Company details</div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
            
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateCompany()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Company name*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.companyName} onChange={(e)=>this.setState({ companyName: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Contact name*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.contactDetails} onChange={(e)=>this.setState({ contactDetails: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Email*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.email} onChange={(e)=>this.setState({ email: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Mobile*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.phone} onChange={(e)=>this.setState({ phone: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Telephone</div>
                                    <div className="value">
                                        <input type="text" value={this.state.telephone} onChange={(e)=>this.setState({ telephone: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                               
                                {/* <div className="add_inner">
                                    <div className="title">Registration number</div>
                                    <div className="value">
                                        <input type="text" value={this.state.regNumber} onChange={(e)=>this.setState({ regNumber: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })} />
                                    </div>
                                    <div className="clear"></div>
                                </div> */}
                                <div className="add_inner">
                                    <div className="title">PAN No.*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.panNo} onChange={(e)=>this.setState({ panNo: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {/* <div className="add_inner">
                                    <div className="title">VAT No.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.vatNo} onChange={(e)=>this.setState({ vatNo: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div> */}
                                <div className="add_inner">
                                    <div className="title">Regd No.</div>
                                    <div className="value">
                                        <input type="text" value={this.state.regdNo} onChange={(e)=>this.setState({ regdNo: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">GSTIN*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.gstin} onChange={(e)=>this.setState({ gstin: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })} onBlur={(e)=>this.validateGSTNumber(e)} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">ECC</div>
                                    <div className="value">
                                        <input type="text" value={this.state.ecc} onChange={(e)=>this.setState({ ecc: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Bank Details</div>
                                    <div className="value">
                                        <input type="text" placeholder="Bank Name" className='add_inner' value={this.state.bank_name} onChange={(e)=>this.setState({ bank_name: e.target.value })} />
                                        <input type="text" placeholder="Ac/No" className='add_inner' value={this.state.bank_accno} onChange={(e)=>this.setState({ bank_accno: e.target.value })}  />
                                        <input type="text" placeholder="Branch" className='add_inner' value={this.state.bank_branch} onChange={(e)=>this.setState({ bank_branch: e.target.value })}  />
                                        <input type="text" placeholder="RTGS/NEFT" className='add_inner' value={this.state.bank_rtgsneft} onChange={(e)=>this.setState({ bank_rtgsneft: e.target.value })}  />
                                        <input type="text" placeholder="Swift code" className='add_inner' value={this.state.bank_swiftcode} onChange={(e)=>this.setState({ bank_swiftcode: e.target.value })}  />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>

                            {/* PART 2 */}

                            <div className="add_partside">
                                
                                <div className="add_inner">
                                    <div className="title">Company Logo*</div>
                                    <div className="value">
                                        <input 
                                            className='input-file add_inner'
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={this.onFileChange}
                                        ></input>
                                        <img src={this.state.logoSrc} alt="logo" height={150} />

                                        
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                {/* <div className="add_inner">
                                    <div className="title">Registration office*</div>
                                    <div className="value">
                                        <textarea className="minh63" value={this.state.registrationOffice} onChange={(e)=>this.setState({ registrationOffice: e.target.value })}></textarea>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Corporate office</div>
                                    <div className="value">
                                        <textarea className="minh63" value={this.state.corporateOffice} onChange={(e)=>this.setState({ corporateOffice: e.target.value })}></textarea>
                                    </div>
                                    <div className="clear"></div>
                                </div> */}
                                <div className="add_inner">
                                    <div className="title">Website 1*</div>
                                    <div className="value">
                                        <input type="text" value={this.state.website} onChange={(e)=>this.setState({ website: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Website 2</div>
                                    <div className="value">
                                        <input type="text" value={this.state.website2} onChange={(e)=>this.setState({ website2: e.target.value })} />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Address*</div>
                                    <div className="value">
                                        <input type="text" placeholder="Line 1" className='add_inner' value={this.state.address_line1} onChange={(e)=>this.setState({ address_line1: e.target.value })}/>
                                        <input type="text" placeholder="Line 2" className='add_inner' value={this.state.address_line2} onChange={(e)=>this.setState({ address_line2: e.target.value })}/>
                                        <input type="text" placeholder="Zone" className='add_inner' value={this.state.address_zone} onChange={(e)=>this.setState({ address_zone: e.target.value })}/>
                                        <input type="text" placeholder="City" className='add_inner' value={this.state.address_city} onChange={(e)=>this.setState({ address_city: e.target.value })}/>
                                        <input type="text" placeholder="State" className='add_inner' value={this.state.address_state} onChange={(e)=>this.setState({ address_state: e.target.value })}/>
                                        <input type="text" placeholder="Country" className='add_inner' value={this.state.address_country} onChange={(e)=>this.setState({ address_country: e.target.value })}/>
                                        <input type="text" placeholder="PIN" className='add_inner' value={this.state.address_pin} onChange={(e)=>this.setState({ address_pin: e.target.value })}/>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            <div className="add_btnSave" onClick={()=>this.updateCompany()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        )
    }
}