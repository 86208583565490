import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import Loading from './../../../helper/loading'
import * as APIManager from './../../../APIManager/APIManager'
import BankAccounts from '../../../components/AutoGenerateComponent/addBankAccounts';

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditBankAccounts extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            countBankAccountNumber: [],
            addBankAccountFields: [],
            count: 0,
            bankAccountFieldValues: [],
            generateNewBankAccount: false,

            fieldNotEditable: true,
            generateNewType: true,
        }
    }

    componentDidMount(){
        this.getBankAccountsList()
    }

    getBankAccountsList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetBankAccounts()
        .then(response => {
            let bankAccounts = response.bankAccountDetails ? response.bankAccountDetails : []
            this.setState({
                isLoading: false
            },()=>{
                this.setBankAccountFields(bankAccounts)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            },()=>{
                this.addNewField(0)
            })
        });
        
    }
    
    setBankAccountFields = (bankAccount) => {
        let numArray = []
        let fields = []
        for(let i=0;i<bankAccount.length;i++){
            numArray.push(i)
            fields.push(
                <BankAccounts 
                    key={i}
                    componentNum={i}
                    bankAccountname={bankAccount[i].bankName}
                    bankAccountNumber={bankAccount[i].bankNumber}
                    openingBalance={bankAccount[i].openingBalance}
                    accountId={bankAccount[i].id}
                    onBlur={this.bankAccountInputBlur}
                    onEdit={this.bankAccountInputEdit}
                    onRemove={this.bankAccountInputRemove}
                />
            )
        }
        this.setState({
            addBankAccountFields: fields,
            countBankAccountNumber: numArray,
            bankAccountFieldValues: bankAccount,
            count: bankAccount.length
        },()=>{
            this.addNewField(bankAccount.length)
        })
    }

    addNewField = (len) => {
        this.setState({
            addBankAccountFields: this.state.addBankAccountFields.concat(
                [
                    <BankAccounts 
                        key={len}
                        componentNum={len}
                        bankAccountname={""}
                        bankAccountNumber={""}
                        openingBalance={""}
                        accountId={""}
                        onBlur={this.bankAccountInputBlur}
                        onEdit={this.bankAccountInputEdit}
                        onRemove={this.bankAccountInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countBankAccountNumber: this.state.countBankAccountNumber.concat([len]),
                generateNewBankAccount: true,
            })
        })
    }
    
    bankAccountInputEdit = (bankAccountname, bankAccountNumber, openingBalance, num) => {

        let array = [...this.state.countBankAccountNumber];
        let index = array.indexOf(num)
        let bankAccountVal = [...this.state.bankAccountFieldValues]     
        
        
        if (typeof bankAccountVal[index] === 'undefined' && this.state.generateNewBankAccount === true && bankAccountname !== "" && bankAccountNumber !== "" && openingBalance !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewBankAccount: false,
            }, () => {
                
                this.setState({
                    countBankAccountNumber: this.state.countBankAccountNumber.concat(
                        [this.state.count]
                    ),
                    addBankAccountFields: this.state.addBankAccountFields.concat(
                        [
                            <BankAccounts 
                                key={this.state.count}
                                componentNum={this.state.count}
                                bankAccountname={""}
                                bankAccountNumber={""}
                                openingBalance={""}
                                accountId={""}
                                onBlur={this.bankAccountInputBlur}
                                onEdit={this.bankAccountInputEdit}
                                onRemove={this.bankAccountInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    bankAccountInputBlur = (bankAccountname, bankAccountNumber, openingBalance, id, num) => {
 
         let array = [...this.state.countBankAccountNumber];
         let index = array.indexOf(num)
         let bankAccountVal = [...this.state.bankAccountFieldValues]   
 
         if (typeof bankAccountVal[index] !== 'undefined') {
             if (bankAccountname !== "" && bankAccountNumber !== "" && openingBalance !== "") {
                 let obj = {
                     "bankName": bankAccountname,
                     "bankNumber": bankAccountNumber,
                     "openingBalance": openingBalance,
                     "id": id
                 }
                 let bankAccountFieldValues = [ ...this.state.bankAccountFieldValues ];
                 bankAccountFieldValues[index] = obj;
 
                 this.setState({
                     bankAccountFieldValues,
                     generateNewBankAccount: true,
                })
             }
         }else{
             if (bankAccountname !== "" && bankAccountNumber !== "" && openingBalance !== "") {
                 let obj = {
                    "bankName": bankAccountname,
                    "bankNumber": bankAccountNumber,
                    "openingBalance": openingBalance,
                    "id": id
                 }
                 this.setState({
                     bankAccountFieldValues: this.state.bankAccountFieldValues.concat([ obj ]),
                     generateNewBankAccount: true,
                 })
             }
         }
     }
 

    bankAccountInputRemove = (number) => {
        let array = [...this.state.countBankAccountNumber];
        let fields = [...this.state.addBankAccountFields];
        let data = [...this.state.bankAccountFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countBankAccountNumber: array,
                addBankAccountFields: fields,
                bankAccountFieldValues: data
            });
        }
    }


    updateBankAccounts = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                bankAccountFieldValues
            } = this.state

            /* Remove Null From State */
            let bankAccounts = [];
            bankAccountFieldValues.forEach((val) => {
                if(val !== null){
                    bankAccounts.push(val);
                }
            });
            /* Remove Null */

            if(bankAccounts.length === 0){
                this.setState({
                    success: false,
                    error: true,
                    errormessage: "Please add Bank Accounts"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callSetBankAccounts(JSON.stringify(bankAccounts))
                .then(response => {
                    this.setState({
                        isLoading: false,
                        error: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Bank Accounts updated successfully"
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        success: false,
                        successmessage: "Bank Accounts not updated, please retry later."
                    })
                });
            }
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(

            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                
                <div className="main-container">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Bank Accounts</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateBankAccounts()}>{ this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                        <div className="clear"></div>
                    </div>
                    
                    <div className="add_partside position-relative">
                        <div className="add_inner">
                            <div className="title"></div>
                            <div className="value">
                                <table className="">
                                    <thead>
                                        <tr>
                                            <th>Account Name</th>
                                            <th>Account Number</th>
                                            <th>Opening Balance</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.addBankAccountFields
                                        }
                                    </tbody>
                                </table>    
                            </div>
                            <div className="clear"></div>
                        </div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
                    <div className="clear"></div>
            
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateBankAccounts()}>{ this.state.fieldNotEditable ? "Edit" : "Save"}</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>


        )
    }
}