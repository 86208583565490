import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';
import * as Ai from "react-icons/ai";
import { Modal } from 'react-bootstrap';

export default class EditSubscriptionPlan extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            success: false,
            successmessage: "",
            error: false,
            errormessage: "",

            plans: [],

            renewPlanVisible: false,
            planName: "",
            planAmount: "",
            planPeriod: "",
            planUsers: "",
            planId: "",
            subsId: "",
            transactionNum: "",
        }
    }

    componentDidMount(){
        this.getPlans()
    }

    getPlans = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetPlans()
        .then(response => {
            this.setState({
                isLoading: false,
                plans: response.data
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    renewThis = (plan, subscription) => {
        this.setState({
            planName: plan.planName,
            planUsers: plan.user,
            planAmount: subscription.price,
            planPeriod: subscription.months,
            planId: plan._id,
            subsId: subscription.id,
            transactionNum: "",
            renewPlanVisible: true,
        })
    }

    setHidePlanPopup = () => {
        this.setState({
            renewPlanVisible: false,
        })
    }


    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isAccountAndBilling={true} />

                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Subscription Plans</div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
            
                    <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            {/* <div className="add_btnSave">Save</div> */}
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className='custom-subs-table'>
                                <div className='c-s-table-header'>
                                    <div className='c-s-table-head'>Users</div>
                                    <div className='c-s-table-head'>Validity</div>
                                    <div className='c-s-table-head'>Price</div>
                                    <div className='c-s-table-head'>Action</div>
                                    <div className='clear'></div>
                                </div>

                                {
                                    this.state.plans.map((data, i)=>(
                                        <div className='c-s-table-body' key={'plan'+i}>
                                            <div className='c-s-table-body-val1'>
                                                <div className='big'>{data.user}</div>
                                                <div className='small'>Users</div>
                                            </div>
                                            <div className='c-s-table-body-val2'>
                                                {
                                                    data.subscriptions.map((d, j)=>(
                                                        <div key={'subscription'+j}>
                                                            <div className='c-s-table-data'>{d.months} month</div>
                                                            <div className='c-s-table-data'>{(d.price).toLocaleString('en-IN')}/-</div>
                                                            <div className='c-s-table-data'>
                                                                <div className="renew-now" onClick={()=>this.renewThis(data, d)}>Renew Now</div>
                                                            </div>
                                                            <div className='clear'></div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='clear'></div>
                                        </div>
                                    ))
                                }


                                <Modal size="lg" show={this.state.renewPlanVisible} onHide={()=>this.setHidePlanPopup()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <div className='text-capitalize'>
                                                Renew Plan
                                            </div>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div>
                                            <div className='mb-2'>
                                                <div className='float-left width30'>
                                                    <div><b>Plan Details:</b></div>
                                                    <div>Name: <b>{this.state.planName}</b></div>
                                                    <div>Users: <b>{this.state.planUsers}</b></div>
                                                    <div>Period: <b>{this.state.planPeriod} Month(s)</b></div>
                                                    <div>Amount: <b>{this.state.planAmount.toLocaleString('en-IN')} INR</b></div>
                                                </div>

                                                <div className='float-left width70'>
                                                    <div><b>Bank Details:</b></div>
                                                    <div>Bank: <b>AXIS Bank</b></div>
                                                    <div>Account: <b>6602899379273</b></div>
                                                    <div>IFSC: <b>UTIB0000003</b></div>
                                                    <div>Note: <b><i>Add this account in beneficiary list to use in future transactions.</i></b></div>
                                                </div>
                                                <div className='clear'></div>
                                            </div>

                                            <div><b>To avail this plan please transfer above amount to given bank details and submit transaction ID here.</b></div>
                                            <div className="mb-2"><b>Once you submit details it will take 2 hours to upgrade your account with this plan.</b></div>
                                           
                                            <div>
                                                <div>Transaction Number</div>
                                                <input 
                                                    type="text" 
                                                    placeholder="Transaction number" 
                                                    className='input-box' 
                                                    onChange={(e)=> this.setState({ transactionNum: e.target.value})}
                                                    value={this.state.transactionNum} 
                                                />
                                                <div className=''>
                                                    <div className="sign-btn-outline">Submit</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                                {/* <div className='c-s-table-body'>
                                    <div className='c-s-table-body-val1'>
                                        <div className='big'>10</div>
                                        <div className='small'>Users</div>
                                    </div>
                                    <div className='c-s-table-body-val2'>
                                        <div className='c-s-table-data'>1 month</div>
                                        <div className='c-s-table-data'>10000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>3 months</div>
                                        <div className='c-s-table-data'>30000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>6 months</div>
                                        <div className='c-s-table-data'>60000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>
                                    </div>
                                    <div className='clear'></div>
                                </div>

                                <div className='c-s-table-body'>
                                    <div className='c-s-table-body-val1'>
                                        <div className='big'>25</div>
                                        <div className='small'>Users</div>
                                    </div>
                                    <div className='c-s-table-body-val2'>
                                        <div className='c-s-table-data'>1 month</div>
                                        <div className='c-s-table-data'>25000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>3 months</div>
                                        <div className='c-s-table-data'>75000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>6 months</div>
                                        <div className='c-s-table-data'>150000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>
                                    </div>
                                    <div className='clear'></div>
                                </div>

                                <div className='c-s-table-body'>
                                    <div className='c-s-table-body-val1'>
                                        <div className='big'>50</div>
                                        <div className='small'>Users</div>
                                    </div>
                                    <div className='c-s-table-body-val2'>
                                        <div className='c-s-table-data'>1 month</div>
                                        <div className='c-s-table-data'>50000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>3 months</div>
                                        <div className='c-s-table-data'>150000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>6 months</div>
                                        <div className='c-s-table-data'>300000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>
                                    </div>
                                    <div className='clear'></div>
                                </div>            

                                <div className='c-s-table-body'>
                                    <div className='c-s-table-body-val1'>
                                        <div className='big'>75</div>
                                        <div className='small'>Users</div>
                                    </div>
                                    <div className='c-s-table-body-val2'>
                                        <div className='c-s-table-data'>1 month</div>
                                        <div className='c-s-table-data'>52000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>3 months</div>
                                        <div className='c-s-table-data'>150000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>6 months</div>
                                        <div className='c-s-table-data'>600000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>
                                    </div>
                                    <div className='clear'></div>
                                </div>

                                <div className='c-s-table-body'>
                                    <div className='c-s-table-body-val1'>
                                        <div className='big'>100</div>
                                        <div className='small'>Users</div>
                                    </div>
                                    <div className='c-s-table-body-val2'>
                                        <div className='c-s-table-data'>1 month</div>
                                        <div className='c-s-table-data'>52000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>3 months</div>
                                        <div className='c-s-table-data'>150000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>

                                        <div className='c-s-table-data'>6 months</div>
                                        <div className='c-s-table-data'>600000</div>
                                        <div className='c-s-table-data'>
                                            <div className="renew-now">Renew Now</div>
                                        </div>
                                        <div className='clear'></div>
                                    </div>
                                    <div className='clear'></div>
                                </div> */}

                            </div>
                        </div>
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            {/* <div className="add_btnSave">Save</div> */}
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        )
    }
}