import React from 'react';
import { Header, SettingsHeader } from '../../../components'
import * as APIManager from '../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditDepartment extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            is_calculatedAutomatically: true,
            is_setManually: false,
            average_wage: "",
            deptName: "",
            deptNumber: "",

            id: this.props.location.state.departmentId,
            isNewEntry: this.props.location.state.newDepartmentEntry,

            fieldNotEditable: true,

        }
    }

    componentDidMount(){
        this.getSingleDepartment()
    }

    getSingleDepartment = () => {
        this.setState({ isLoading: true, error: false })
            APIManager.callGetSingleDepartment(this.state.id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    is_calculatedAutomatically: response.data.is_calculatedAutomatically,
                    is_setManually: response.data.is_setManually,
                    average_wage: response.data.averageHourlyValue,
                    deptName: response.data.name,
                    deptNumber: response.data.number,
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
    }
    
    wagetypeselect = (type) => {
        if(type === "is_calculatedAutomatically"){
            this.setState({
                is_calculatedAutomatically: true,
                is_setManually: false,
                average_wage: "",
            })
        }else{
            this.setState({
                is_calculatedAutomatically: false,
                is_setManually: true
            })
        }
    }
    

    updateDepartment = () => {
        if(this.state.fieldNotEditable !== true) {
        let {
            id,
            is_calculatedAutomatically,
            is_setManually,
            average_wage,
            deptName
        } = this.state

        if(deptName === ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter department name"
            })
        }else if(is_setManually === true && average_wage ===  ""){
            this.setState({
                success: false,
                error: true,
                errormessage: "Please enter average wage"
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateDepartment(id, deptName, is_calculatedAutomatically, is_setManually, average_wage)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    success: true,
                    error: false,
                    fieldNotEditable: true,
                    successmessage: "Department updated successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            });
        }
    }else{
        let slug = "Settings_HumanResources"
        let permission = "update"
        if(APIManager.checkUserPermission(slug,permission)){
            this.setState({
                fieldNotEditable: false
            })
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }
    }
   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    deleteEntry = () => {
        let slug = "Settings_HumanResources"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
                this.setState({ isLoading: true, error: false })
                APIManager.callDeleteDepartment(this.state.id)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                    },()=>{
                        this.goBack()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

    

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isHR={true} />
                <div className="main-container">
                    
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Department <span className="text-uppercase">#{this.state.deptNumber}</span> <span className="text-capitalize">"{this.state.deptName}"</span> details</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">


                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateDepartment()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>

                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Number </div>
                                <div className="value"><input type="text" className="fieldheightdecr text-uppercase" value={this.state.deptNumber} onChange={(e)=>this.setState({ deptNumber: e.target.value })} readOnly></input></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value"><input type="text" className="fieldheightdecr" value={this.state.deptName} onChange={(e)=>this.setState({ deptName: e.target.value })}></input></div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Average hourly wage</div>
                                <div className="value">
                                    <div onClick={()=>this.wagetypeselect('is_calculatedAutomatically')} className="cursor-pointer">
                                        <div className="term_radio_input">
                                            <input 
                                                type="radio"
                                                checked={this.state.is_calculatedAutomatically} 
                                                onChange={()=>this.wagetypeselect('is_calculatedAutomatically')}
                                            />
                                        </div>
                                        <div className="term_label">Calculate automatically</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div onClick={()=>this.wagetypeselect('is_setManually')} className="cursor-pointer">
                                        <div className="term_radio_input">
                                            <input 
                                                type="radio"
                                                checked={this.state.is_setManually} 
                                                onChange={()=>this.wagetypeselect('is_setManually')}
                                            />
                                        </div>
                                        <div className="term_label">Set manually</div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title"></div>
                                <div className="value"><input type="text" value={this.state.average_wage} onChange={(e)=>this.setState({ average_wage: parseInt(e.target.value) ? parseInt(e.target.value) : "" })} className="fieldheightdecr" disabled={this.state.is_calculatedAutomatically} style={{ width: "60%" }}></input></div>
                                <div className="clear"></div>
                            </div>
                            
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateDepartment()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}