import React, { Component } from 'react';
import { Header, StockHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import { ImArrowDown2 } from "react-icons/im";
import { FiPlus, FiX } from "react-icons/fi";
import * as Fi from 'react-icons/fi'
import { AiOutlineCalendar } from 'react-icons/ai'
import * as Bi from 'react-icons/bi'


import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from 'export-to-csv';

import * as APIManager from './../../../APIManager/APIManager'
import Loading from '../../../components/Loading';

import Pagination from "react-js-pagination";


import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import TableLoading from '../../../helper/TableLoading';
import { convertDateTimeStringMMDDtoDDMM, convertDateToMMDDYYYY } from '../../../constants/DateFormatter';


class StockLots extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            number: "",
            minCreated: "",
            maxCreated: "",
            storageLocationId: "",
            storageLocation: "",
            partNo: "",
            partDescription: "",
            mincostPerUnit: "",
            maxcostPerUnit: "",
            minQuantity: "",
            maxQuantity: "",
            minavailable: "",
            maxavailable: "",
            minavailableFrom: "",
            maxavailableFrom: "",
            UoM: "",
            status: "",

            minbooked: "",
            maxbooked: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,
            lotsList: [],
            responseStorageLocation: [],
            responseUnitOfMeasure: [],

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
        this.getAllStorageLocation()
        this.getAllUnitOfMeasurements()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {
        
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            const sortedOrders = this.state.lotsList.sort((a, b) => {
    
                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
    
            });
            this.setState({ lotsList: sortedOrders }, () => {
                this.setFilterData()
            });
        });  
    };
    //******************* ascending descending order function end ********************************

    getAllStorageLocation = () => {
        this.setState({ error: false })
        APIManager.callGetAllStorageLocation()
            .then(response => {
                this.setState({
                    responseStorageLocation: response
                })
            })
            .catch(errors => {
                this.setState({
                    error: false,
                    errormessage: errors
                })
            });
    }

    getAllUnitOfMeasurements = () => {
        this.setState({ error: false })
        APIManager.callGetAllUnitOfMeasurement()
            .then(response => {
                this.setState({
                    responseUnitOfMeasure: response
                })
            })
            .catch(errors => {
                this.setState({
                    error: false,
                    errormessage: errors
                })
            });

    }


    getStockLots = () => {

        let {
            number,
            minCreated,
            maxCreated,
            storageLocationId,
            storageLocation,
            partNo,
            partDescription,
            mincostPerUnit,
            maxcostPerUnit,
            minQuantity,
            maxQuantity,
            minavailable,
            maxavailable,
            minavailableFrom,
            maxavailableFrom,
            minbooked,
            maxbooked,
            UoM,
            status,
            start,
            length
        } = this.state;


        this.setState({ isLoading: true, error: false })
        APIManager.callStockLotsList(number, minCreated, maxCreated, storageLocationId, storageLocation, partNo, partDescription, mincostPerUnit, maxcostPerUnit, minQuantity, maxQuantity, minavailable, maxavailable, convertDateToMMDDYYYY(minavailableFrom), convertDateToMMDDYYYY(maxavailableFrom), UoM, status, start, length, minbooked, maxbooked)
            .then(response => {
                this.setState({
                    isLoading: false,
                    lotsList: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    this.setFilterData()

                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    setFilterData = () => {
        let filterDataArray = {
            'number': this.state.number,
            'minCreated': this.state.minCreated,
            'maxCreated': this.state.maxCreated,
            'storageLocationId': this.state.storageLocationId,
            'storageLocation': this.state.storageLocation,
            'partNo': this.state.partNo,
            'partDescription': this.state.partDescription,
            'mincostPerUnit': this.state.mincostPerUnit,
            'maxcostPerUnit': this.state.maxcostPerUnit,
            'minQuantity': this.state.minQuantity,
            'maxQuantity': this.state.maxQuantity,
            'minavailable': this.state.minavailable,
            'maxavailable': this.state.maxavailable,
            'minavailableFrom': this.state.minavailableFrom !== null ? this.state.minavailableFrom : "",
            'maxavailableFrom': this.state.maxavailableFrom !== null ? this.state.maxavailableFrom : "",
            'minbooked': this.state.minbooked,
            'maxbooked': this.state.maxbooked,
            'UoM': this.state.UoM,
            'status': this.state.status,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
            'start': this.state.start,
            'length': this.state.length,
            'activePage': this.state.activePage
        }
        localStorage.setItem("stockLots_filterData", JSON.stringify(filterDataArray))
    }

    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("stockLots_filterData"))
        if (filterData !== null) {
            this.setState({

                number: filterData.number,
                minCreated: filterData.minCreated,
                maxCreated: filterData.maxCreated,
                storageLocationId: filterData.storageLocationId,
                storageLocation: filterData.storageLocation,
                partNo: filterData.partNo,
                partDescription: filterData.partDescription,
                mincostPerUnit: filterData.mincostPerUnit,
                maxcostPerUnit: filterData.maxcostPerUnit,
                minQuantity: filterData.minQuantity,
                maxQuantity: filterData.maxQuantity,
                minavailable: filterData.minavailable,
                maxavailable: filterData.maxavailable,
                minavailableFrom: filterData.minavailableFrom ? new Date(filterData.minavailableFrom) : "",
                maxavailableFrom: filterData.maxavailableFrom ? new Date(filterData.maxavailableFrom) : "",
                minbooked: filterData.minbooked,
                maxbooked: filterData.maxbooked,
                UoM: filterData.UoM,
                status: filterData.status,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
                start: filterData.start,
                length: filterData.length,
                activePage: filterData.activePage
            }, () => {
                this.getStockLots()
            })
        } else {
            this.getStockLots()
        }
    }

    clearFilter = () => {
        localStorage.removeItem("stockLots")
        this.setState({
            number: "",
            minCreated: "",
            maxCreated: "",
            storageLocationId: "",
            storageLocation: "",
            partNo: "",
            partDescription: "",
            mincostPerUnit: "",
            maxcostPerUnit: "",
            minQuantity: "",
            maxQuantity: "",
            minavailable: "",
            maxavailable: "",
            minavailableFrom: "",
            maxavailableFrom: "",
            UoM: "",
            status: "",
            minbooked: "",
            maxbooked: "",
            start: 0,
            length: 25,
            activePage: 1,
        }, () => {
            this.getStockLots()
        })
    }






    addStockLots = () => {
        let slug = "Stock_StockLots"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createlot"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editStockLots = (_id, number) => {
        localStorage.setItem("stockLotId", _id)
        localStorage.setItem("stockLotNumber", number.toUpperCase())
        this.props.history.push({
            pathname: "/editlot"
        })
    }

    moveStockItem = () => {
        localStorage.removeItem("stockLotId")
        localStorage.removeItem("stockLotNumber")
        localStorage.removeItem("stockStorageId")
        localStorage.removeItem("stockStorageQty")
        this.props.history.push('/movestock')
    }

    handlePageChange(pageNumber) {


        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {

            this.getStockLots()
        });
    }

    exportAsPDF = () => {
        let responseData = this.state.lotsList;
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Stock lots";

        const headers = [[
            "Lot",
            "Storage Location",
            "Article Code",
            "Part Name",
            "Cost Per UoM",
            "Quantity",
            "Available",
            "Booked",
            "UoM",
            "Status",
            "Available from",
        ]];


        const data = responseData.map((elt, i) => [
            elt.number,
            elt.storageLocation,
            elt.partNo,
            elt.partDescription,
            elt.costPerUnit,
            elt.quantity,
            '',
            '',
            elt.UoM,
            elt.status,
            convertDateTimeStringMMDDtoDDMM(elt.availableFrom)
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("stocklots.pdf")
    }


    exportAsCSV = () => {
        let responseData = this.state.lotsList;
        const title = "Stock lots";
        const headers = [
            "Lot",
            "Storage Location",
            "Article Code",
            "Part Name",
            "Cost Per UoM",
            "Quantity",
            "Available",
            "Booked",
            "UoM",
            "Status",
            "Available from",
        ];

        const data = responseData.map((elt, i) => [
            elt.number,
            elt.storageLocation,
            elt.partNo,
            elt.partDescription,
            elt.costPerUnit,
            elt.quantity,
            '',
            '',
            elt.UoM,
            elt.status,
            convertDateTimeStringMMDDtoDDMM(elt.availableFrom)
        ]);


        const options = {
            filename: 'stocklots',
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: headers,
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isStock={true} />
                <StockHeader isStockLots={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">{Strings.Stock.StockLots}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportAsPDF()}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportAsCSV()}>CSV <ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.moveStockItem()}>Move stock item</div>
                                    {/* <div className="button">Import from CSV <ImArrowUp2 /></div> */}
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400 __kDoEM__table" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addStockLots()}><FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate' , false)}> <span>Lot</span>{this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('storageLocation', 'notDate' , false)}> <span>Storage location</span>{this.state.fieldName === "storageLocation" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "storageLocation" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partNo', 'notDate' , false)}> <span>Article code</span>{this.state.fieldName === "partNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('partDescription', 'notDate' , false)}> <span>Part description</span>{this.state.fieldName === "partDescription" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "partDescription" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('costPerUnit', 'notDate' , false)}> <span>Cost per unit</span>{this.state.fieldName === "costPerUnit" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "costPerUnit" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('quantity', 'notDate' , false)}> <span>Quantity</span>{this.state.fieldName === "quantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "quantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('available', 'notDate' , false)}> <span>Available</span>{this.state.fieldName === "available" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "available" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('booked', 'notDate' , false)}> <span>Booked</span>{this.state.fieldName === "booked" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "booked" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('UoM', 'notDate' , false)}> <span>UoM</span>{this.state.fieldName === "UoM" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "UoM" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('status', 'notDate' , false)}> <span>Status</span>{this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('availableFrom', 'Date' , false)}> <span>Available from</span>{this.state.fieldName === "availableFrom" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "availableFrom" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    {/* <th className="tableHeader tableHeadButton"><FiChevronsDown /></th> */}
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.addStockLots()}><FiPlus /></div></th>
                                    {/* <th className="tableHeader tableHeadButton"><FiPrinter /></th> */}
                                </tr>
                                <tr>
                                    <th className="tableAddButton">{/*<FiSettings />*/}</th>
                                    <th className="tableElemContain">
                                        <input type="text" style={{ width: 80 }} value={this.state.number} onChange={(e) => {
                                            this.setState({
                                                number: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        }
                                        />
                                    </th>
                                    <th className="tableElemContain">
                                        <select style={{ width: 80 }} value={this.state.storageLocation} className="text-capitalize" onChange={(e) => {
                                            this.setState({
                                                storageLocation: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        }>
                                            <option value=''></option>
                                            {
                                                this.state.responseStorageLocation !== null &&
                                                this.state.responseStorageLocation.map((data, i) => (
                                                    <option value={data.name} key={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" style={{ width: 80 }} value={this.state.partNo} onChange={(e) => {
                                            this.setState({
                                                partNo: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" style={{ width: 80 }} value={this.state.partDescription} onChange={(e) => {
                                            this.setState({
                                                partDescription: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" placeholder="min" style={{ width: 80 }} value={this.state.mincostPerUnit} onChange={(e) => {
                                            this.setState({
                                                mincostPerUnit: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } /><br />
                                        <input type="text" placeholder="max" style={{ width: 80 }} value={this.state.maxcostPerUnit} onChange={(e) => {
                                            this.setState({
                                                maxcostPerUnit: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } />
                                    </th>
                                    <th className="tableElemContain">
                                        <input type="text" placeholder="min" style={{ width: 80 }} value={this.state.minQuantity} onChange={(e) => {
                                            this.setState({
                                                minQuantity: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } /><br />
                                        <input type="text" placeholder="max" style={{ width: 80 }} value={this.state.maxQuantity} onChange={(e) => {
                                            this.setState({
                                                maxQuantity: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } />
                                    </th>
                                    <th className="tableElemContain">

                                        <input type="text" placeholder="min" style={{ width: 80 }} value={this.state.minavailable} onChange={(e) => {
                                            this.setState({
                                                minavailable: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } /><br />
                                        <input type="text" placeholder="max" style={{ width: 80 }} value={this.state.maxavailable} onChange={(e) => {
                                            this.setState({
                                                maxavailable: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } />

                                    </th>
                                    <th className="tableElemContain">

                                        <input type="text" placeholder="min" style={{ width: 80 }} value={this.state.minbooked} onChange={(e) => {
                                            this.setState({
                                                minbooked: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } /><br />
                                        <input type="text" placeholder="max" style={{ width: 80 }} value={this.state.maxbooked} onChange={(e) => {
                                            this.setState({
                                                maxbooked: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        } />

                                    </th>
                                    <th className="tableElemContain">
                                        <select style={{ width: 80 }} value={this.state.UoM} className="text-capitalize" onChange={(e) => {
                                            this.setState({
                                                UoM: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        }>
                                            <option value=''></option>
                                            {
                                                this.state.responseUnitOfMeasure !== null &&
                                                this.state.responseUnitOfMeasure.map((data, i) => (
                                                    <option value={data.name} key={data._id}>{data.name}</option>
                                                ))
                                            }
                                        </select>
                                    </th>
                                    <th className="tableElemContain">
                                        <select style={{ width: 80 }} value={this.state.status} onChange={(e) => {
                                            this.setState({
                                                status: e.target.value
                                            }, () => {

                                                this.getStockLots()
                                            })
                                        }
                                        }>
                                            <option value=""></option>
                                            <option value="requested">Requested</option>
                                            <option value="planned">Planned</option>
                                            <option value="received">Received</option>
                                            <option value="cancelled">Cancelled</option>
                                        </select>
                                    </th>
                                    <th className="tableElemContain">
                                        <div className="value position-relative">
                                            {/* <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.minavailableFrom }
                                                onChange={(e) => {
                                                    this.setState({ 
                                                        minavailableFrom: e.target.value 
                                                    },() =>{ 
                                                         
                                                       
                                                    })}
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" /> */}

                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.minavailableFrom}
                                                minDate={this.state.maxavailableFrom}
                                                onChange={
                                                    date => this.setState({ minavailableFrom: date }, () => {

                                                        this.getStockLots()

                                                    })
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.maxavailableFrom}
                                                minDate={this.state.minavailableFrom}
                                                onChange={
                                                    date => this.setState({ maxavailableFrom: date }, () => {

                                                        this.getStockLots()

                                                    })
                                                }
                                                className="filterdateoption" />
                                            <AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    {/* <th className="tableAddButton"><FiSearch /></th> */}
                                    <th className="tableAddButton" onClick={() => this.clearFilter()}><FiX /></th>
                                </tr>
                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={13} />
                                    :
                                    <tbody>
                                        {
                                            this.state.lotsList !== null &&
                                            this.state.lotsList.map((data, i) => (
                                                <tr key={data._id + i + 1} onClick={() => this.editStockLots(data._id, data.number)}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td>{data.number}</td>
                                                    <td>{data.storageLocation}</td>
                                                    <td>{data.partNo}</td>
                                                    <td>{data.partDescription}</td>
                                                    <td>{data.costPerUnit} <Bi.BiRupee /></td>
                                                    <td>{data.quantity}</td>
                                                    <td>{data.available}</td>
                                                    <td>{data.booked}</td>
                                                    <td>{data.UoM}</td>
                                                    <td>{data.status}</td>
                                                    <td>{convertDateTimeStringMMDDtoDDMM(data.availableFrom)}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StockLots;