import React from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Helper from '../../../helper/helper'
import * as APIManager from './../../../APIManager/APIManager'
import { Link } from 'react-router-dom';

import Select2 from './../../../lib/select2/select2';
import './../../../lib/select2/select2.css';
import ContactComponent from './../../../components/AutoGenerateComponent/addContactInformation'

import {
    FiPlus,
    FiEdit2
} from "react-icons/fi";

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditVendor extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",
            fieldNotEditable: true,

            countContactFieldNumber: [],
            contactFieldCounter: 0,
            contactExtraFields: [],
            contactInformation: [],
            generateNewContact: true,

            vendorContacts: [],
            vendorNotes: [],

            _id: "",
            name: "",
            PANNo: "",
            gstNumber: "",
            paymentperiod: "0",
            leadtime: "0",
            deliveryTerms: "",
            paymentTerms: "",
            titleName: "",
            titleNumber: "",
            taxApplicable: [],
            currencyData: [],
            currency: "",

            paymentValue: "",
            paymentDaysFrom: "",
            paymentStatus: "",
            paymentTerm: {
                "paymentValue": "",
                "daysFrom": "",
                "paymentStatus": "",
            },

        }
        this.contactInfoFieldRemove = this.contactInfoFieldRemove.bind(this)
    }

    componentDidMount(){
        let vendorID = localStorage.getItem("vendorId")

        this.setState({
            _id: vendorID
        },()=>{
            this.getSingleVendorDetail()
            this.getAllCurrency()
        })
    }

    getAllCurrency = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetAllCurrency()
        .then(response => {
            this.setState({
                isLoading: false,
                currencyData: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }


    updateCurrencyData = (value) => {
        this.setState({
            taxApplicable: [],
            currency: value
        },()=>{
            this.sgstcheck.checked = false
            this.cgstcheck.checked = false
            this.igstcheck.checked = false
        })
    }

    updatePaymentTermObject = (type) => {
        let paymentTerm = this.state.paymentTerm
    
        if(type === 'paymentValue')
            paymentTerm.paymentValue = this.state.paymentValue;

        if(type === 'paymentDaysFrom')
            paymentTerm.daysFrom = this.state.paymentDaysFrom;

        if(type === 'paymentStatus')
            paymentTerm.paymentStatus = this.state.paymentStatus;

        this.setState({
            paymentTerm
        })
    }

    getSingleVendorDetail = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callVendorDetail(this.state._id)
        .then(res => {
            let pTerms = res.paymentTerms
            if(pTerms){
                this.setState({
                    paymentValue: pTerms.paymentValue,
                    paymentDaysFrom: pTerms.daysFrom,
                    paymentStatus: pTerms.paymentStatus,
                    paymentTerm: pTerms
                })
            }

            this.setState({ 
                isLoading: false,
                titleName: res.name,
                titleNumber: res.number,
                number: res.number,
                name: res.name,
                leadtime: res.defaultLeadTime,
                paymentperiod: res.paymentPeriods,
                deliveryTerms: res.deliveryTerms,
                vendorContacts: res.contacts ? res.contacts : "",
                vendorNotes: res.notes ? res.notes : "", 
                taxApplicable: res.taxApplicable ? res.taxApplicable : [],
                PANNo: res.panNo ? res.panNo : "",
                gstNumber: res.gstNo ? res.gstNo : "",
                currency: res.currency ? res.currency : ""
            },()=>{
                localStorage.setItem("vendorNumber", res.number)
                localStorage.setItem("vendorName", res.name)
                this.setContactInfo(res.contactInformation)
                this.setTaxApplicable(this.state.taxApplicable)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    setContactInfo = (data) => {
        let localFieldNumber = []
        let localExtraField = []

        for(let i=0;i<data.length;i++){
            localFieldNumber.push(i)
            localExtraField.push(
                <ContactComponent 
                    key={i}
                    componentNum={i}
                    value={data[i].value}
                    type={data[i].type}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            )
        }
        this.setState({
            countContactFieldNumber: localFieldNumber,
            contactFieldCounter: data.length,
            contactExtraFields: localExtraField,
            contactInformation: data,
        },()=>{
            this.setNewField(data.length)
        })
    }

    setNewField = (num) => {
        this.setState({
            countContactFieldNumber: this.state.countContactFieldNumber.concat([ num + 1 ]),
            contactFieldCounter: num + 1,
            contactExtraFields: this.state.contactExtraFields.concat([
                <ContactComponent 
                    key={num + 1}
                    componentNum={num + 1}
                    value={""}
                    type={"Phone"}
                    fun_checkInputBlur={
                        this.contactInfoInputBlur
                    }
                    fun_checkContactInfoInput={
                        this.contactInfoInputVal
                    }
                    fun_removeContactInfoField={
                        this.contactInfoFieldRemove
                    }
                />
            ]),
            generateNewContact: true,
        })
    }

    updateVendor = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                _id,
                PANNo,
                gstNumber,
                name,
                paymentperiod,
                leadtime,
                contactInformation,
                deliveryTerms,
                paymentTerm,
                taxApplicable,
                currency
            } = this.state

            let filteredVal = contactInformation.filter(function (el) {
                return el != null;
            })

            let uniqueTaxArray = taxApplicable.filter(function(item, pos, self) {
                return self.indexOf(item) === pos;
            })

            if(name === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter name"
                })
            }else if(gstNumber === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter GST of Vendor"
                })
            }else if(currency === "" && uniqueTaxArray.length === 0){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select applicable tax or currency"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateVendor(_id,name,filteredVal,leadtime,paymentperiod,deliveryTerms,paymentTerm,uniqueTaxArray,PANNo,gstNumber.toUpperCase(),currency)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        fieldNotEditable: true,
                        success: true,
                        successmessage: "Vendor details updated successfully"
                    },()=>{
                        this.scrollToTop()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                    })
                })
            }
        }else{
            let slug = "Procurement_Vendors"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    deleteVendor = () => {
        let slug = "Procurement_Vendors"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
            
                this.setState({ loading: true })
                const that = this;

                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state._id)
                
                fetch(APIManager.WEBSERVICES_URL+'procurement/vendors/deleteVendor',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                            that.setState({ loading: false },()=> {
                                that.goBack()
                            })

                    }else if(result.status === "userDataError"){
                            that.setState({ loading: false },()=> {
                                that.logout()
                            })
                    }else{
                            that.setState({
                                loading: false,
                                error: true,
                                errormessage: result.message
                            })
                    }

                    },(error) => {
                    console.log(error)
                    }
                )
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewVendor") !== null){
            localStorage.removeItem("createNewVendor")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

   

    contactInfoInputVal = (val, number) => {
        let that = this;

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]  
        
        
        if (typeof CIVal[index] === 'undefined' && this.state.generateNewContact === true && val.length > 0) {
            this.setState({
                contactFieldCounter: this.state.contactFieldCounter + 1,
                generateNewContact: false,
            }, () => {
                
                this.setState({
                    countContactFieldNumber: this.state.countContactFieldNumber.concat(
                        [this.state.contactFieldCounter]
                    ),
                    contactExtraFields: this.state.contactExtraFields.concat(
                        [
                            <ContactComponent 
                                key={
                                    this.state.contactFieldCounter
                                }
                                value={""}
                                type={"Phone"}
                                fun_checkInputBlur={
                                    this.contactInfoInputBlur
                                }
                                componentNum={
                                    this.state.contactFieldCounter
                                }
                                fun_checkContactInfoInput={
                                    that.contactInfoInputVal
                                }
                                fun_removeContactInfoField={
                                    this.contactInfoFieldRemove
                                }
                            />
                        ]
                    ),
                })
            })
        }
    }



    contactInfoFieldRemove = (number) => {

        let array = [...this.state.countContactFieldNumber];
        let fields = [...this.state.contactExtraFields];
        let data = [...this.state.contactInformation]
        let index = array.indexOf(number)


        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
        
            this.setState({
                countContactFieldNumber: array,
                contactExtraFields: fields,
                contactInformation: data
            });
        }
    }
    contactInfoInputBlur = (val, type, number) => {

        let array = [...this.state.countContactFieldNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.contactInformation]        

        if (typeof CIVal[index] !== 'undefined') {
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                let contactInformation = [ ...this.state.contactInformation ];
                contactInformation[index] = obj;

                this.setState({
                    contactInformation,
                    generateNewContact: false,
                })
            }
        }else{
            if (val !== "") {
                let obj = {
                    "type": type,
                    "value": val
                }
                this.setState({
                    contactInformation: this.state.contactInformation.concat([ obj ]),
                    generateNewContact: true,
                })
            }
        }
    }

    editVendorContact = (id) => {
        localStorage.setItem("vendorContactId", id)
        this.props.history.push('/editvendorcontact')
    }

    editVendorNotes = (id, note) => {
        localStorage.setItem("vendorNoteId", id)
        localStorage.setItem("vendorNote", note)
        this.props.history.push('/editvendornote')
    }

    createContact = () =>{
        let slug = "Procurement_Vendors"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.props.history.push("/createvendorcontact")
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
    }
   
    setTaxApplicable = (data) => {
        if(data.includes("SGST") === true)
            this.updateTaxApplicable("SGST",true)

        if(data.includes("CGST") === true)
            this.updateTaxApplicable("CGST",true)

        if(data.includes("IGST") === true)
            this.updateTaxApplicable("IGST",true)

        this.cgstcheck.checked = data.includes("CGST")
        this.sgstcheck.checked = data.includes("SGST")
        this.igstcheck.checked = data.includes("IGST")
    }

    updateTaxApplicable = (name, check) => { 
        if(check === true && name === "IGST"){
            this.setState({
                taxApplicable: [],
            },()=>{
                this.sgstcheck.checked = false;
                this.cgstcheck.checked = false;
                this.setState({
                    taxApplicable: this.state.taxApplicable.concat([
                        name
                    ])
                })
            })
        } else if(check === true && name !== "IGST") {
            let taxApplicable = [...this.state.taxApplicable]
            let index = taxApplicable.indexOf('IGST')
            if(index > -1){
                taxApplicable.splice(index, 1);
                this.setState({
                    taxApplicable
                },()=>{
                    this.igstcheck.checked = false;
                    this.setState({
                        taxApplicable: this.state.taxApplicable.concat([
                            name
                        ])
                    })
                })
            }else{
                this.setState({
                    taxApplicable: this.state.taxApplicable.concat([
                        name
                    ])
                })
            }
        } else{
            let taxApplicable = [...this.state.taxApplicable]
            let index = taxApplicable.indexOf(name)

            taxApplicable.splice(index, 1);
            this.setState({
                taxApplicable
            })
        }
    }

    render(){
        return(
            <div className="page absoluteWhite"> 
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProcurement={true} />
                <ProcurementHeader isVendors={true} />

                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title text-capitalize">Vendor {this.state.titleNumber.toUpperCase()} {this.state.titleName} details</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateVendor()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteVendor()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Number</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr text-uppercase" autoComplete="off" name="number" value={this.state.number} onChange={(e)=>this.setState({ number: e.target.value })} readOnly />
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Vendor *</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr" autoComplete="off" name="name" value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value })} />
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">GST No.*</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr text-uppercase"
                                        autoComplete="off"
                                        value={this.state.gstNumber}
                                        onChange={(e)=> this.setState({ gstNumber: e.target.value }) }
                                        name="taxnumber" 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">PAN No.</div>
                                <div className="value">
                                    <input 
                                        type="text" 
                                        className="fieldheightdecr"
                                        autoComplete="off"
                                        value={this.state.PANNo}
                                        onChange={(e)=>this.setState({ PANNo: e.target.value })}  
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            
                            <div className="add_inner contactinfo">
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Contact information</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1">
                                            <div className="float-left width40">Type</div>
                                            <div className="float-left width60">value</div>
                                            <div className="clear"></div>
                                        </div>
                                        {
                                            this.state.contactExtraFields
                                        }
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Delivery terms</div>
                                <div className="value">
                                    <select value={this.state.deliveryTerms} onChange={(e)=>this.setState({ deliveryTerms: e.target.value })}>
                                        <option value="">Select</option>
                                        <option value="ex-works">Ex-works</option>
                                        <option value="c and f">C and F</option>
                                        <option value="cif">CIF</option>
                                    </select>

                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="add_inner">
                                <div className="title">Payment terms</div>
                                <div className="value">
                                    
                                    <div>
                                       
                                        <div className="float-left width30 createdText border-0">Payment from</div> 
                                        <input 
                                            type="text" 
                                            className="float-left width10" 
                                            value={this.state.paymentDaysFrom}
                                            onChange={(e)=>{
                                                this.setState({ paymentDaysFrom: e.target.value >= 0 ? e.target.value : "" },()=>{
                                                    this.updatePaymentTermObject('paymentDaysFrom')
                                                })
                                            }} 
                                        />
                                        <div className="float-left width20 createdText text-center border-0">days of</div>
                                        <div className="float-left width40">
                                            <select 
                                                value={this.state.paymentStatus}
                                                onChange={(e)=>{
                                                if(e.target.value !== ""){
                                                    this.setState({ paymentStatus: e.target.value},()=>{
                                                        this.updatePaymentTermObject('paymentStatus')
                                                    })
                                                }
                                            }}>
                                                <option value="">Select</option>
                                                <option value="from_invoice">Invoice</option>
                                                <option value="from_confirmation">Confirmation</option>
                                                <option value="from_arrival">Arrival</option>
                                            </select>
                                        </div>

                                        <div className="clear"></div>
                                    </div>


                                </div>
                                <div className="clear"></div>
                            </div>

                            
                            <div className="add_inner">
                                <div className="title">Default lead time</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr" value={this.state.leadtime} onChange={(e)=>this.setState({ leadtime: e.target.value })} autoComplete="off" name="name" style={{width: "50%"}}/> days
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Payment period</div>
                                <div className="value">
                                    <input type="text" className="fieldheightdecr" value={this.state.paymentperiod} onChange={(e)=>this.setState({ paymentperiod: e.target.value })} autoComplete="off" name="name" style={{width: "50%"}}/> days 
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Currency</div>
                                <div className='value'>
                                    <Select2    
                                        data={
                                            this.state.currencyData.map((data) => (
                                                { id: data._id, text: data.currencyName }
                                            ))
                                        }
                                        value={this.state.currency}
                                        options={{placeholder: 'Select Currency'}}
                                        onSelect={(e) => this.updateCurrencyData(e.target.value) }
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner contactinfo">
                                <div>
                                    <div className="addNewCustomerContactTitle">
                                        <div className="title2">Tax applicable</div>
                                    </div>
                                    <div className="addNewCustomerContactField">
                                        <div className="mt-1 mb-1 mr-1">
                                            <div className="float-left width40">Type</div>
                                            <div className="float-left width60 text-center">Applicable</div>

                                            <div className="clear"></div>
                                        </div>

                                        <div className="extrafieldforContact">
                                            <div className="float-left width40 taxApplicableTitle">
                                                CGST
                                            </div>
                                            <div className="float-left width60 taxApplicableInput">
                                                <div className="checkboxInputs"><input type="checkbox"
                                                        style={
                                                            {width: 14}
                                                        }
                                                        ref={ (c) => this.cgstcheck = c }
                                                        onChange={
                                                            (e)=>{
                                                                this.updateTaxApplicable("CGST",e.target.checked)
                                                            }
                                                        }/></div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>

                                        <div className="extrafieldforContact">
                                            <div className="float-left width40 taxApplicableTitle">
                                                SGST
                                            </div>
                                            <div className="float-left width60 taxApplicableInput">
                                                <div className="checkboxInputs"><input type="checkbox"
                                                    style={
                                                        {width: 14}
                                                    }
                                                    ref={ (c) => this.sgstcheck = c }
                                                    onChange={
                                                        (e)=>{
                                                            this.updateTaxApplicable("SGST",e.target.checked)
                                                        }
                                                    }/></div>
                                            </div>
                                            <div className="clear"></div>
                                        </div>

                                        <div className="extrafieldforContact">
                                            <div className="float-left width40 taxApplicableTitle">
                                                IGST
                                            </div>
                                            <div className="float-left width60 taxApplicableInput">
                                                <div className="checkboxInputs"><input type="checkbox"
                                                        style={
                                                            {width: 14}
                                                        }
                                                        ref={ (c) => this.igstcheck = c }
                                                        onChange={
                                                            (e)=>{
                                                                this.updateTaxApplicable("IGST",e.target.checked)
                                                            }
                                                        }/></div>
                                                </div>
                                            <div className="clear"></div>
                                        </div>


                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="clear"></div>

                            </div>
                        </div>
                        <div className="clearfix"></div>
                        {
                            this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateVendor()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteVendor()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>

                <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                    <div className="footerTitle">Contacts</div>
                    <table>
                        <tbody>
                        <tr>
                            <th className="tableHeader w-50"><span>Name</span></th>
                            <th className="tableHeader"><span>Phone</span></th>
                            <th className="tableHeader"><span>Email</span></th>
                            <th className="tableHeader"><span>Fax</span></th>
                            <th className="tableHeader"><span>Address</span></th>
                            <th className="tableAddButton text-right"><div onClick={()=>this.createContact()} className="addContactButton"><FiPlus /></div></th>
                        </tr>

                        {
                            this.state.vendorContacts !== "" &&
                            this.state.vendorContacts.map((data, i) => (
                                <tr key={'vendors'+i} onClick={this.editVendorContact.bind(this,data.contactId)}>
                                    <td>{data.name}</td>
                                    <td>{data.phone}</td>
                                    <td className="text-lowercase">{data.email}</td>
                                    <td>{data.fax}</td>
                                    <td>{data.address}</td>
                                    <td></td>
                                </tr>
                            ))
                        } 
                        </tbody>
                    </table>
                </div>

                <div className="tableContent inner-page-table" style={{ overflowX : "auto" }}>
                    <div className="footerTitle">Notes</div>
                    <table>
                        <tbody>
                        <tr>
                            <th className="tableHeader"><span>Created</span></th>
                            <th className="tableHeader"><span>Modified</span></th>
                            <th className="tableHeader w-50">Notes</th>
                            <th className="tableAddButton text-right"><Link to="/createvendornote" className="addContactButton"><FiPlus /></Link></th>
                        </tr>
                        {
                            this.state.vendorNotes !== "" &&
                            this.state.vendorNotes.map((data, i) => (
                                <tr key={'vendorNotes'+i}>
                                    <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.creatorName}</span><br/><span style={{ fontStyle: "italic" }}>{data.created}</span></td>
                                    <td className="texttop"><span style={{ fontWeight: "bold" }}>{data.modifierName}</span><br/><span  style={{ fontStyle: "italic" }}>{data.modified}</span></td>
                                    <td className="texttop preline">{data.note}</td>
                                    <td className="text-right"><FiEdit2 className="editCusomerBtn" onClick={this.editVendorNotes.bind(this,data.noteId,data.note)}/></td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        )
    }
}