import React from 'react';


import { 
    FiX,
} from "react-icons/fi";


export default class MeasurementInfo extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            valueOfName: this.props.valueOfName,
            valueOfRate: this.props.valueOfRate,
        }

    }

    componentDidMount(){
       
    }
   
   

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
            
        } = this.props
        return(
            <tr>
                <td style={{width: 10}}>
                    {1}
                </td>
                <td className="width40">
                    <input 
                        type="text" 
                        style={{ width: "100%"}}
                        value={this.state.valueOfName}
                        onChange={(e)=>this.setState({ valueOfName: e.target.value},()=>{
                            onEdit(this.state.valueOfName, this.state.valueOfRate, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.valueOfName, this.state.valueOfRate, componentNum)
                        }

                /></td>
                <td style={{width: 10}}>
                    =
                </td>
                <td className="width60">
                    <input 
                    type="text" 
                    className="width50"
                    autoComplete='off'
                    value={this.state.valueOfRate}
                    onChange={(e)=>this.setState({ valueOfRate: e.target.value},()=>{
                        onEdit(this.state.valueOfName, this.state.valueOfRate, componentNum)
                    })}
                    onBlur={
                        ()=>onBlur(this.state.valueOfName, this.state.valueOfRate, componentNum)
                    }
                /> {this.props.name}
                </td>
                <td>
                {
                    this.state.valueOfName !== "" && this.state.valueOfRate !== "" &&
                        <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                }
                </td>
            </tr>
        )
    }
}