import React from 'react';
import { Header, SettingsHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'
import RichTextEditor from 'react-rte'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditQuotationMessage extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            quotationMessageEditor: RichTextEditor.createEmptyValue(),
            quotationMessage: "",

            quotationMessageFooterEditor: RichTextEditor.createEmptyValue(),
            quotationMessageFooter: "",

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.getQuotationMessages()
    }
 


    getQuotationMessages = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetDefaultQuotationMessages()
        .then(response => {
            this.setState({
                isLoading: false,
                quotationMessage: response.headerText,
                quotationMessageFooter: response.footerText,
                quotationMessageEditor: response.headerText ? RichTextEditor.createValueFromString(response.headerText, 'html') : RichTextEditor.createEmptyValue(),
                quotationMessageFooterEditor: response.footerText ? RichTextEditor.createValueFromString(response.footerText, 'html') : RichTextEditor.createEmptyValue()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    onQuotationMessageChange = (value) => {
        this.setState({
            quotationMessageEditor: value,
            quotationMessage: value.toString('html')
        });
    }

    onquotationMessageFooterChange = (value) => {
        this.setState({
            quotationMessageFooterEditor: value,
            quotationMessageFooter: value.toString('html')
        });
    }

    updateMessages = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                quotationMessage,
                quotationMessageFooter
            } = this.state
            this.setState({ isLoading: true, error: false })
            APIManager.callSetDefaultQuotationMessages(quotationMessage, quotationMessageFooter)
            .then(response => {
                this.setState({
                    isLoading: false,
                    success: true,
                    fieldNotEditable: true,
                    successmessage: "Messages Updated Successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                })
            })
        }else{
            this.setState({
                fieldNotEditable: false,
            })
        }
    }


    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isSystemSettings={true} />
                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Quotation Messages</div>
                        </div>
                    </div>
                </div>
             
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateMessages()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_outer_full_part">
                            <div className="title">Header Message</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.quotationMessageEditor}
                                    onChange={this.onQuotationMessageChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="add_outer_full_part mt-5">
                            <div className="title">Footer Message</div>
                            <div>
                                <RichTextEditor
                                    value={this.state.quotationMessageFooterEditor}
                                    onChange={this.onquotationMessageFooterChange} 
                                    rootStyle={{ minHeight: 170 }}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
            
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateMessages()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>


        )
    }
}