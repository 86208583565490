import React, { Component } from 'react';
import { Header, SettingsHeader } from './../../../../components'
import GenerateNewComponent from '../AutoGenerate/generateNewComponent';
import * as APIManager from './../../../../APIManager/APIManager'
import Loading from '../../../../components/Loading';
import * as Ai from 'react-icons/ai'

class UpdateImpellerMOC extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            countTypeNumber: [],
            addTypeFields: [],
            count: 0,
            typeFieldValues: [],
            generateNewType: true,

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.getRecords();
    }

    getRecords = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetImpellerMOC()
        .then(response => {
            this.setState({ 
                isLoading: false,
                typeFieldValues: response.data.impellerMoc !== undefined ? response.data.impellerMoc : []
            },()=>{
                this.setTypeFields()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors 
            })
        });
        
    }

    setTypeFields = () => {
        let data = this.state.typeFieldValues
        if(data.length > 0){
            let numArray = []
            let fields = []
            for(let i=0;i<data.length;i++){
                numArray.push(i)
                fields.push(
                    <GenerateNewComponent 
                        key={i}
                        componentNum={i}
                        newComponent={data[i].impellerMoc}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                )
            }
            this.setState({
                addTypeFields: fields,
                countTypeNumber: numArray,
                count: data.length
            },()=>{
                this.addNewField(data.length)
            })
        }else{
            this.addNewField(0)
        }
    }

    addNewField = (len) => {
        this.setState({
            addTypeFields: this.state.addTypeFields.concat(
                [
                    <GenerateNewComponent 
                        key={len}
                        componentNum={len}
                        newComponent={""}
                        onBlur={this.statusInputBlur}
                        onEdit={this.statusInputEdit}
                        onRemove={this.statusInputRemove}
                    />
                ]
            ),
            count: len,
        },()=>{
            this.setState({
                countTypeNumber: this.state.countTypeNumber.concat([len]),
            })
        })
    }

    statusInputBlur = (newComponent, num) => {
        let array = [...this.state.countTypeNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]   

        if (typeof statusVal[index] !== 'undefined') {
            if (newComponent !== "") {
                let obj = {
                    "impellerMoc": newComponent,
                }
                let typeFieldValues = [ ...this.state.typeFieldValues ];
                typeFieldValues[index] = obj;

                this.setState({
                    typeFieldValues,
                    generateNewType: true,
                })
            }
        }else{
            if (newComponent !== "") {
                let obj = {
                    "impellerMoc": newComponent,
                }
                this.setState({
                    typeFieldValues: this.state.typeFieldValues.concat([ obj ]),
                    generateNewType: true,
                })
            }
        }
    }

    statusInputEdit = (newComponent, num) => {
        let array = [...this.state.countTypeNumber];
        let index = array.indexOf(num)
        let statusVal = [...this.state.typeFieldValues]     

        if (typeof statusVal[index] === 'undefined' && this.state.generateNewType === true && newComponent !== "") {
            this.setState({
                count: this.state.count + 1,
                generateNewType: false,
            }, () => {
                
                this.setState({
                    countTypeNumber: this.state.countTypeNumber.concat(
                        [this.state.count]
                    ),
                    addTypeFields: this.state.addTypeFields.concat(
                        [
                            <GenerateNewComponent 
                                key={this.state.count}
                                componentNum={this.state.count}
                                newComponent={""}
                                onBlur={this.statusInputBlur}
                                onEdit={this.statusInputEdit}
                                onRemove={this.statusInputRemove}
                            />
                        ]
                    ),
                })
            })
        }
    }

    statusInputRemove = (number) => {
        let array = [...this.state.countTypeNumber];
        let fields = [...this.state.addTypeFields];
        let data = [...this.state.typeFieldValues]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            
            this.setState({
                countTypeNumber: array,
                addTypeFields: fields,
                typeFieldValues: data
            });
        }
        
    }

    updateRecord = () => {
        if(this.state.fieldNotEditable){
            this.setState({
                fieldNotEditable: false,
            })
        }else{
            let { typeFieldValues } = this.state;
            let filteredVal = typeFieldValues.filter(function (el) {
                return el != null;
            }); 
            if(filteredVal.length === 0){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please enter Impeller MOC"
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callAddImpellerMOC(JSON.stringify(filteredVal))
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        fieldNotEditable: true,
                        successmessage: "Impeller MOC Updated Successfully"
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        errormessage: errors 
                    })
                });
            }
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <SettingsHeader isPumpMaster={true} />
                
                <div className="main-container">
                    <div className="page-header mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Impeller MOC</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                    </div>
                </div>
                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateRecord()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="clear"></div>
                    </div>
                    
                    <div className="add_partside position-relative">
                        <div className="add_inner">
                            <div className="title"></div>
                            <div className="value">
                                <table>
                                    <tbody>
                                        {
                                            this.state.addTypeFields
                                        }
                                    </tbody>
                                </table>    
                            </div>
                            <div className="clear"></div>
                        </div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>
                    <div className="clear"></div>
            
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateRecord()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="clear"></div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default UpdateImpellerMOC;