import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../../components'
import Select2 from './../../../../../lib/select2/select2';
import './../../../../../lib/select2/select2.css';
import * as APIManager from './../../../../../APIManager/APIManager'
import * as Ai from 'react-icons/ai'
import * as Bi from 'react-icons/bi'
import * as Im from 'react-icons/im'
import Loading from './../../../../../helper/loading'
import { confirm } from "react-confirm-box";
import { convertDateStringMMDDtoDDMM, convertDateToDDMMYYYY, convertDateToMMDDYYYY } from '../../../../../constants/DateFormatter';

class ViewEInvoice extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            creditNoteId: this.props.location.state.creditNoteId,
            number: "",

            coGroupList: [],
            coGroupData: [],
            addressList: [],

            totalQty: 0,
            subTotal: 0,
            grandTotal: 0,
            grandTotalInOther: 0,

            customerOrderNumber: [],
            customerOrder: "",
            customerId: "",
            invoiceAddress: "",
            deliveryAddress: "",
            invoiceType: "",
            invoiceId: "",
            invoiceDate: "",
            dueDate: "",
            invoice_id: "",

            customerName: "",
            applicableTax: "",
            applicableTaxType: [],

            currencyData: [],
            sezCustomer: false,
            applicableTaxTypeWithAllData: [],

            pdfType: "",

            currentDate: '',
            documentNo: "",
            buyerGST: "",
            buyerTradeName: "",
            buyerLegalName: "",
            documentType: "CRN",
            supplyType: "",
            buyerPinCode: "",
            buyerCity: "",
            buyerStateCode: "",
            deliveryStateCode: "",

            

            eInvoiceFlag: false,
            
            is_eInvoice: false,
            is_eWayBill: false,
            fieldNotEditable: true,

            singleInvoiceResponse: [],
            singleOrderResponse: [],
            singleCustomerResponse: [],

            taxProJSON: [],

            is_e_invoice_generated: false,

            eWayBillFlag: false,

        }
    }

    componentDidMount(){
        let today = new Date();
       
        this.setState({
            currentDate: convertDateToDDMMYYYY(today) 
        },()=>{
            this.getSingleInvoice()
        })        
    }

    getSingleInvoice = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetSingleCreditNote(this.state.creditNoteId)
        .then(response => {

            let addr_new = (response.data.eInvoiceDetail.BuyerDtls.Addr1).split("_ ")

            let orderGroupData = []
            let productGroup =  response.data.productGroup

            for(let i = 0; i<productGroup.length;i++){
                productGroup[i].conversionDetail = []
                orderGroupData.push(productGroup[i])
            }

            this.setState({ 
                singleInvoiceResponse: response.data,
                is_e_invoice_generated: response.data.Irn ? true : false,
                eWayBillFlag: !response.data.eWayBillFlag,
                customerOrder: response.data.customerOrderId,
                number: response.data.number,
                documentNo: response.data.number,
                invoiceAddress: response.data.invoiceAddress.split("_ ").join(" "),
                deliveryAddress: response.data.deliveryAddress.split("_ ").join(" "),
                invoiceType: response.data.invoiceType,
                invoiceId: response.data.invoiceId,
                invoiceDate: response.data.invoiceDate ? response.data.invoiceDate : "",
                dueDate: response.data.dueDate ? response.data.dueDate : "",
                coGroupData: orderGroupData,
                invoice_id: response.data.customerOrderInvoiceId ? response.data.customerOrderInvoiceId : "",
                buyerCity: addr_new[3] ? addr_new[3] : '',
                buyerPinCode: addr_new[5] ?  addr_new[5] : '',
                
                isLoading: false,
            },()=>{
                this.getEInvoiceDetail()
                this.getOrderDetails(response.data.customerOrderId)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getEInvoiceDetail = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetEInvoiceDetail(this.state.creditNoteId)
        .then(response => {
            this.setState({ 
                documentType: response.data.documentType ? response.data.documentType : "",
                supplyType: response.data.supplyType ? response.data.supplyType : "",
                buyerStateCode: response.data.statecode ? response.data.statecode : "",
                deliveryStateCode: response.data.statecode ? response.data.statecode : "",
                eInvoiceFlag: response.data.eInvoiceFlag ? response.data.eInvoiceFlag : "",

                is_eInvoice: response.data.is_eInvoice ? response.data.is_eInvoice : "",
                is_eWayBill: response.data.is_eWayBill ? response.data.is_eWayBill : "",
               
                isLoading: false,
            })
        })
        .catch(errors => {

            this.setState({ 
                isLoading: false,
            })
        });   
    }

    getOrderDetails = (id) => {
        if(id){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerOrderDetail(id)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    singleOrderResponse: response,
                    customerId: response.customer,
                    customerOrderNumber: response.number
                },()=>{
                    this.getCustomerDetails(response.customer)
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            })
        }
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {

            let taxType = []
            let taxResponseData = response.companyData.taxApplicable
            let sezCustomer = false
            if(!taxResponseData.includes("notax")){
                if(taxResponseData.includes("exemptedtax")){
                    sezCustomer = true
                    taxType = ["IGST"]
                }else{
                    taxType = response.companyData.taxApplicable
                }
            }

            let pdfType = "" 
            if(response.companyData.currencyDetails.conversionRate){
                pdfType = "originalinother"
            }else{
                pdfType = "original"
            }
            this.setState({
                isLoading: false,
                singleCustomerResponse: response.companyData,
                customerName: response.companyData.companyId.toUpperCase()+' '+response.companyData.companyName,
                buyerTradeName: response.companyData.companyName,
                buyerLegalName: response.companyData.companyName,
                currencyData: response.companyData.currencyDetails,
                applicableTaxType: taxType,
                applicableTaxTypeWithAllData: taxResponseData,
                sezCustomer: sezCustomer,
                pdfType: pdfType,
                buyerGST: response.companyData.gstNo,
                buyerStateCode: response.companyData.gstNo ? (response.companyData.gstNo).substring(0, 2) : "",
                deliveryStateCode: response.companyData.gstNo ? (response.companyData.gstNo).substring(0, 2) : ""
            },()=>{
                if(this.state.buyerGST){
                    this.getGSTDetails()
                }
                this.countTotal()
                this.getCustomerAddress(id)
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

    countTotal = () => {
        let data = this.state.coGroupData
        let subTotal = 0;
        let grandTotal = 0;
        let grandTotalInOther = 0;
        let qty = 0;
        for(let i=0;i<data.length;i++){
            qty += parseFloat(data[i].orderedQuantity)
            subTotal += parseFloat(data[i].subTotal)

            if(this.state.invoiceType === "sez"){
                grandTotal += data[i].subTotal ? parseFloat(data[i].subTotal) : 0
            }else{
                grandTotal += data[i].total ? parseFloat(data[i].total) : 0
            }
            grandTotalInOther += data[i].rawTotalInOtherCurrency ? parseFloat(data[i].rawTotalInOtherCurrency) : 0
        }
        this.setState({
            totalQty: qty,
            subTotal: Math.round(subTotal).toFixed(2),
            grandTotal: Math.round(grandTotal).toFixed(2),
            grandTotalInOther: Math.round(grandTotalInOther).toFixed(2)
        })
    }

    getCustomerAddress = (customer) => {
        if(customer){
            this.setState({ isLoading: true, error: false })
            APIManager.callCustomerShippingAddress(customer)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    addressList: response
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: false,
                    errormessage: errors 
                })
            })
        }
    }

    updateInvoice = () => {
        let {
            buyerGST,
            documentType,
            supplyType,
            buyerPinCode,
            buyerCity,
            buyerLegalName,
            buyerTradeName,
            number,
            invoiceDate,
            documentNo,
            currentDate,
            buyerStateCode,
            deliveryStateCode,
            singleInvoiceResponse,
            singleOrderResponse,
            singleCustomerResponse,
            fieldNotEditable,
            invoiceType
        } = this.state


        if(!fieldNotEditable){ 

            if(invoiceType !== "export" && buyerGST === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please add buyer GST details"
                },()=>{
                    this.scrollToTop()
                })
            }else if(documentType === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select document type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(supplyType === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please select supply type"
                },()=>{
                    this.scrollToTop()
                })
            }else if(buyerCity === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please add city of shipment"
                },()=>{
                    this.scrollToTop()
                })
            }else if(buyerPinCode === ""){
                this.setState({
                    error: true,
                    success: false,
                    errormessage: "Please add pincode of shipment"
                },()=>{
                    this.scrollToTop()
                })
            }else{
                let eInvoiceDetails = singleInvoiceResponse.eInvoiceDetail

                let sellerAddr = eInvoiceDetails.SellerDtls.Addr1
                let buyerAddr = eInvoiceDetails.BuyerDtls.Addr1
                let deliveryAddr = singleInvoiceResponse.deliveryAddress

                let sellerAddr1 = "" 
                let sellerAddr2 = ""

                let buyerAddr1 = ""
                let buyerAddr2 = ""

                let deliveryAddr1 = ""
                let deliveryAddr2 = ""
                let deliveryCity = ""
                let deliveryPincode = ""

                if(sellerAddr !== ""){
                    let addr1 = ""
                    let addr2 = ""

                    if(sellerAddr.indexOf('_') > -1){
                        let sellerArr = sellerAddr.split("_ ")
                        addr1 = sellerArr[0] ? sellerArr[0] : ''
                        addr2 = sellerArr[1] ? sellerArr[1] : ''
                    }else{
                        addr1 = sellerAddr
                    }
                    

                    if(addr1 === ""){
                        sellerAddr1 = "..."
                    }else if(addr1.length > 100){
                        sellerAddr1 = addr1.substring(0, 99)
                    }else if(addr1.length < 3){
                        sellerAddr1 = addr1+'..'
                    }else{
                        sellerAddr1 = addr1
                    }

                    if(addr2 === ""){
                        sellerAddr2 = "..."
                    }else if(addr2.length > 100){
                        sellerAddr2 = addr2.substring(0, 99)
                    }else if(addr2.length < 3){
                        sellerAddr2 = addr2+'..'
                    }else{
                        sellerAddr2 = addr2
                    }
                }else{
                    sellerAddr1 = "..."
                    sellerAddr2 = "..."
                }

                if(buyerAddr !== ""){
                    let addr1 = ""
                    let addr2 = ""
                    if(buyerAddr.indexOf('_') > -1){
                        let buyerArr = buyerAddr.split("_ ")
                        addr1 = buyerArr[0] ? buyerArr[0] : ''
                        addr2 = buyerArr[1] ? buyerArr[1] : ''
                    }else{
                        addr1 = buyerAddr
                    }
                    
                    if(addr1 === ""){
                        buyerAddr1 = "..."
                    }else if(addr1.length > 100){
                        buyerAddr1 = addr1.substring(0, 99)
                    }else if(addr1.length < 3){
                        buyerAddr1 = addr1+'..'
                    }else{
                        buyerAddr1 = addr1
                    }

                    if(addr2 === ""){
                        buyerAddr2 = "..."
                    }else if(addr2.length > 100){
                        buyerAddr2 = addr2.substring(0, 99)
                    }else if(addr2.length < 3){
                        buyerAddr2 = addr2+'..'
                    }else{
                        buyerAddr2 = addr2
                    }
                }else{
                    buyerAddr1 = "..."
                    buyerAddr2 = "..."
                }

                if(deliveryAddr !== ""){
                    let addr1 = ""
                    let addr2 = ""
                    if(deliveryAddr.indexOf('_') > -1){
                        let buyerArr = deliveryAddr.split("_ ")
                        addr1 = buyerArr[0] ? buyerArr[0] : ''
                        addr2 = buyerArr[1] ? buyerArr[1]+' '+buyerArr[2] : ''
                        deliveryCity = buyerArr[3] ?  buyerArr[3] : ''
                        deliveryPincode = buyerArr[5] ?  buyerArr[5] : ''
                    }else{
                        addr1 = buyerAddr
                    }
                    
                    if(addr1 === ""){
                        deliveryAddr1 = "..."
                    }else if(addr1.length > 100){
                        deliveryAddr1 = addr1.substring(0, 99)
                    }else if(addr1.length < 3){
                        deliveryAddr1 = addr1+'..'
                    }else{
                        deliveryAddr1 = addr1
                    }

                    if(addr2 === ""){
                        deliveryAddr2 = "..."
                    }else if(addr2.length > 100){
                        deliveryAddr2 = addr2.substring(0, 99)
                    }else if(addr2.length < 3){
                        deliveryAddr2 = addr2+'..'
                    }else{
                        deliveryAddr2 = addr2
                    }
                }else{
                    deliveryAddr1 = "..."
                    deliveryAddr2 = "..."
                }

                let jsonObj = {
                    "Version": eInvoiceDetails.Version,
                    "TranDtls": {
                        "TaxSch": "GST",
                        "SupTyp":  supplyType ,
                        "RegRev": "N",
                        "EcmGstin": null,
                        "IgstOnIntra": "N"
                    },
                    "DocDtls": {
                        "Typ": documentType,
                        "No": documentNo,
                        "Dt": currentDate
                    },
                    "SellerDtls": {
                        "Gstin": eInvoiceDetails.SellerDtls.Gstin,
                        "LglNm": eInvoiceDetails.SellerDtls.LglNm.toUpperCase(),
                        "TrdNm": eInvoiceDetails.SellerDtls.LglNm.toUpperCase(),
                        "Addr1": sellerAddr1,
                        "Addr2": sellerAddr2,
                        "Loc": eInvoiceDetails.SellerDtls.Loc,
                        "Pin": Number(eInvoiceDetails.SellerDtls.Pin),
                        "Stcd": eInvoiceDetails.SellerDtls.Stcd
                    },
                    "BuyerDtls": {
                        "Gstin": this.state.invoiceType === "export" ? "URP" : buyerGST.toUpperCase(),
                        "LglNm": buyerLegalName,
                        "TrdNm": buyerTradeName,
                        "Pos": this.state.invoiceType === "export" ? "96" : buyerStateCode,
                        "Addr1": buyerAddr1,
                        "Addr2": buyerAddr2,
                        "Loc": buyerCity,
                        "Pin": this.state.invoiceType === "export" ? Number("999999") : Number(buyerPinCode),
                        "Stcd": this.state.invoiceType === "export" ? "96" : buyerStateCode
                    },
                    "DispDtls": {
                        "Nm": eInvoiceDetails.SellerDtls.LglNm,
                        "Addr1":  sellerAddr1,
                        "Addr2": sellerAddr2,
                        "Loc": eInvoiceDetails.SellerDtls.Loc,
                        "Pin": Number(eInvoiceDetails.SellerDtls.Pin),
                        "Stcd": eInvoiceDetails.SellerDtls.Stcd
                    },
                    "ShipDtls": {
                        "Gstin": this.state.invoiceType === "export" ? "URP" : buyerGST.toUpperCase(),
                        "LglNm": buyerLegalName,
                        "TrdNm": buyerTradeName,
                        "Addr1": deliveryAddr1,
                        "Addr2": deliveryAddr2,
                        "Loc": deliveryCity,
                        "Pin": this.state.invoiceType === "export" ? Number("999999") : Number(deliveryPincode),
                        "Stcd": this.state.invoiceType === "export" ? "96" : deliveryStateCode
                    },
                    "ItemList": eInvoiceDetails.ItemList,
                    "ValDtls":  eInvoiceDetails.ValDtls,
                    "RefDtls": {
                        "InvRm": "test",
                        "DocPerdDtls": {
                            "InvStDt": currentDate,
                            "InvEndDt": currentDate
                        },
                        "PrecDocDtls": [
                            {
                                "InvNo": number,
                                "InvDt": convertDateStringMMDDtoDDMM(invoiceDate),
                                "OthRefNo": "123456"
                            }
                        ],
                        "ContrDtls": [
                            {
                                "RecAdvRefr": "Doc/003",
                                "RecAdvDt": currentDate,
                                "Tendrefr": "Abc001",
                                "Contrrefr": "Co123",
                                "Extrefr": "Yo456",
                                "Projrefr": "Doc-456",
                                "Porefr": "Doc-789",
                                "PoRefDt": currentDate
                            }
                        ]
                    },
                    "AddlDocDtls": [
                        {
                            "Url": "https://einv-apisandbox.nic.in",
                            "Docs": "Test Doc",
                            "Info": "Document Test"
                        }
                    ],
                    "ExpDtls": {
                        "ShipBNo": "A-248",
                        "ShipBDt": currentDate,
                        "Port": "INABG1",
                        "RefClm": "N",
                        "ForCur": "INR",
                        "CntCode": "IN"
                    }
                }
                console.log ('jsonObj ',jsonObj)
                this.createEInvoice(jsonObj)
            }

        }else{
            let slug = "CRM_E_Invoices"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false,
                    success: false,
                    error: false,
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }
        }
    }

    createEInvoice = (jsonObj) => {
        let {
            buyerGST,
            documentType,
            supplyType,
            buyerPinCode,
            buyerCity,
            buyerStateCode,
            invoice_id,
            customerId,
            creditNoteId
        } = this.state

        /* This will use in Credit E-Invoice only */
        let creditEInvoice = true

        this.setState({ isLoading: true, error: false })
        APIManager.callSaveEInvoiceDetail(buyerCity,buyerPinCode,buyerStateCode,buyerGST,documentType,supplyType,invoice_id,customerId,jsonObj,creditEInvoice,creditNoteId)
        .then(response => {
            this.setState({
                isLoading: false,
                error: false,
                success: true,
                fieldNotEditable: true,
                eWayBillFlag: true,
                is_e_invoice_generated: response.data.eInvoiceFlag, 
                successmessage: response.message
            },()=>{
                this.checkEWayBill()
            })
        })
        .catch(errors => {
            this.setState({
                isLoading: false,
                error: true,
                success: false,
                errormessage: errors
            })
        });
    }

    checkEWayBill = async () => {
        let { grandTotal } = this.state
        if(parseFloat(grandTotal) >= 50000){
            const options = {
                render: (message, onConfirm, onCancel) => {
                return (
                    <div className='confirm-dialog-box'>
                    <div className='dialog-msg'>{message}</div>
                    <div>
                        <button onClick={onConfirm} className='dialog-button'>Yes</button>
                        <button onClick={onCancel} className='dialog-button'>No</button>
                        <div className='clear'></div>
                    </div>
                    </div>
                );
                }
            };

            const result = await confirm("Do you wants to create E-Way Bill for this invoice", options);
            if (result) {
                this.eWayBillDetailPage()
                return;
            }
        }
    }

    getGSTDetails = () => {
        let { buyerGST } = this.state;

        if(buyerGST && buyerGST.length >= 15) {
            this.setState({ isLoading: true, error: false })
            APIManager.callGetGstDetailByNumber(buyerGST)
            .then(response => {
                if(response.data && response.data !== "GSTINvalidation") {
                    this.setState({
                        isLoading: false,
                        error: false,
                        buyerTradeName: response.data.tradename ? response.data.tradename : this.state.customerName,
                        buyerLegalName: response.data.legalname ? response.data.legalname : this.state.customerName,
                        buyerStateCode: buyerGST.substring(0, 2)
                    })
                }else{
                    this.setState({
                        isLoading: false,
                        error: false,
                        buyerTradeName: this.state.customerName,
                        buyerLegalName: this.state.customerName,
                        buyerStateCode: buyerGST.substring(0, 2)
                    })
                }
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    success: false,
                    errormessage: "Please enter valid GST Number"
                })
            });
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    downloadPDF = () => {
        localStorage.setItem("pdfType", this.state.pdfType)
        localStorage.setItem("invoice_id", this.state.invoice_id)
        window.open('cninvoicepdf')
    }


    downloadPDFInOtherCurrency = () => {
        if(this.state.pdfType === "originalinother"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "duplicateinother"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "triplicateinother"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "extrainother"){
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceother')
        }else if(this.state.pdfType === "originalininr"){
            localStorage.setItem("pdfType", "original")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }else if(this.state.pdfType === "duplicateininr"){
            localStorage.setItem("pdfType", "duplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }else if(this.state.pdfType === "triplicateininr"){
            localStorage.setItem("pdfType", "triplicate")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }else{
            localStorage.setItem("pdfType", "extra")
            localStorage.setItem("invoice_id", this.state.invoice_id)
            window.open('cninvoiceotherinr')
        }
    }

    getApplicableTaxValue = (data) => {
        let subTotal = data.subTotal
        let tax = data.tax
        let applicableTaxType = this.state.applicableTaxType

        let perCount = subTotal*tax/100;
        let taxValue = perCount/applicableTaxType.length

        return taxValue.toFixed(2);
    }

    eWayBillDetailPage = async () => {
        this.props.history.push({
            pathname: "/cnviewewaybill",
            state: { creditNoteId: this.state.creditNoteId }
        })
    }
 
    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCreditNote={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">Generate E-Invoice {this.state.number.toUpperCase()}</div>
                            </div>
                            <div className="col-lg-6 col-md-4 col-sm-4 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    {
                                        this.state.currencyData.conversionRate ?
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e)=>this.setState({ pdfType: e.target.value})}>
                                                    <option value="originalinother">Original in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="duplicateinother">Duplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="triplicateinother">Triplicate in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="extrainother">Extra in {this.state.currencyData.currencySymbol}</option>
                                                    <option value="originalininr">Original in &#8377;</option>
                                                    <option value="duplicateininr">Duplicate in &#8377;</option>
                                                    <option value="triplicateininr">Triplicate in &#8377;</option>
                                                    <option value="extraininr">Extra in &#8377;</option>
                                                </select>
                                                <div className="button" onClick={()=>this.downloadPDFInOtherCurrency()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                <div className="clear"></div>                                                
                                            </div>
                                        :
                                            <div>
                                                <select className='print-pdf-options' value={this.state.pdfType} onChange={(e)=>this.setState({ pdfType: e.target.value})}>
                                                    <option value="original">Original</option>
                                                    <option value="duplicate">Duplicate</option>
                                                    <option value="triplicate">Triplicate</option>
                                                    <option value="extra">Extra</option>
                                                </select>
                                                <div className="button" onClick={()=>this.downloadPDF()}>Generate PDF <Im.ImArrowDown2 /></div>
                                                {
                                                    this.state.eWayBillFlag &&
                                                    <div className="button" onClick={()=>this.eWayBillDetailPage()}>Generate E-Way Bill</div>
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                     <div className="tableContent">
                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            {
                                this.state.is_e_invoice_generated !== true &&
                                <div className="add_btnSave" onClick={()=>this.updateInvoice()}>{this.state.fieldNotEditable ? 'Edit' : 'Generate'}</div>
                            }
                            <div className="clear"></div>
                        </div>
                        <div className='position-relative'>
                            <div className="add_partside">
                                <div className="add_inner">
                                    <div className="title">Number</div>
                                    <div className="value">
                                        <div className="createdText">{this.state.number.toUpperCase()}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer Order*</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerOrderNumber} {this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Customer</div>
                                    <div className="value">
                                        <div className="createdText clickable__source">{this.state.customerName}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Document No</div>
                                    <div className="value">
                                        <div className="createdText text-capitalize">{this.state.documentNo.toUpperCase()}</div> 
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Buyer GST*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.buyerGST}
                                            onChange={(e) => this.setState({ buyerGST: e.target.value }, () => { this.getGSTDetails() })}
                                            className="text-uppercase"
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Document Type*</div>
                                    <div className="value">
                                        <Select2    
                                            data={[
                                                // {id: 'INV', text: 'Invoice'},
                                                {id: 'CRN', text: 'Credit note'},
                                                // {id: 'DBN', text: 'Debit note'}
                                            ]}
                                            value={this.state.documentType}
                                            options={{placeholder: 'Select Document Type'}}
                                            onSelect={(e)=> this.setState({ documentType: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Supply Type*</div>
                                    <div className="value">
                                        <Select2    
                                            data={
                                                this.state.invoiceType === "export" ?
                                                [
                                                    {id: 'EXPWP', text: 'EXPWP'},
                                                    {id: 'EXPWOP', text: 'EXPWOP'}
                                                ]
                                                :
                                                [
                                                    {id: 'B2B', text: 'B2B'},
                                                    {id: 'B2C', text: 'B2C'},
                                                    {id: 'SEZWP', text: 'SEZWP'},
                                                    {id: 'SEZWOP', text: 'SEZWOP'},
                                                    {id: 'EXPWP', text: 'EXPWP'},
                                                    {id: 'EXPWOP', text: 'EXPWOP'},
                                                    {id: 'DEXP', text: 'DEXP'}
                                                ]

                                            }
                                            value={this.state.supplyType}
                                            options={{placeholder: 'Select Supply Type'}}
                                            onSelect={(e)=> this.setState({ supplyType: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Invoice Type</div>
                                    <div className="value">
                                        <div className="createdText text-capitalize">{this.state.invoiceType}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            <div className="add_partside">
                                

                                <div className="add_inner">
                                    <div className="title">Invoice Date</div>
                                    <div className="value">
                                        <div className="createdText">{convertDateStringMMDDtoDDMM(this.state.invoiceDate)}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                <div className="add_inner">
                                    <div className="title">Due Date</div>
                                    <div className="value">
                                        <div className="createdText">{convertDateStringMMDDtoDDMM(this.state.dueDate)}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                
                                <div className="add_inner">
                                    <div className="title">Buyer City*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.buyerCity}
                                            onChange={(e) => this.setState({ buyerCity: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Buyer Pincode*</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.buyerPinCode}
                                            onChange={(e) => this.setState({ buyerPinCode: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Buyer State Code*</div>
                                    <div className="value">
                                        <div className="createdText">{this.state.buyerStateCode}</div> 
                                        
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery Address</div>
                                    <div className="value">
                                        <div className="createdText text-capitalize textarea-div">{this.state.deliveryAddress}</div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="add_inner">
                                    <div className="title">Delivery State Code</div>
                                    <div className="value">
                                        <input
                                            type="text"
                                            value={this.state.deliveryStateCode}
                                            onChange={(e) => this.setState({ deliveryStateCode: e.target.value })}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>

                                
                            </div>
                            <div className="clear"></div>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>
                        

                        




                        <div className="main-container mt-5 invoice_product_group position-relative">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='pg-tab-head'></th>
                                        <th className='pg-tab-head'>Item</th>
                                        <th className='pg-tab-head'>Credit Qty</th>
                                        <th className='pg-tab-head'>Price/UoM</th>
                                        <th className='pg-tab-head'>Discount</th>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                                <th className='pg-tab-head'>Subtotal</th>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <th className='pg-tab-head' key={'appTax'+i}>
                                                    {data}
                                                    {
                                                        this.state.applicableTaxTypeWithAllData.includes("exemptedtax") &&
                                                        <small>(Exempted)</small>
                                                    }
                                                </th>
                                            ))
                                        }
                                        <th className='pg-tab-head'>Total</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    
                                    {
                                     
                                        this.state.coGroupData.map((data, i)=>
                                        {
                                            let a = 0;
                                            if(data.rejectedQuantity !== 0){
                                                a++;
                                            return (
                                                    <tr key={"coGroupData"+i}>
                                                        <td>{a}</td>
                                                        <td style={{ width: 350 }}>
                                                            <div>{data.itemCode} {data.itemName}</div>
                                                            <div>{data.freeText.replace(/<br>/g, "\n")}</div>
                                                        </td>
                                                        <td>{data.rejectedQuantity} {data.uom}</td>
                                                        <td>
                                                            {
                                                                this.state.currencyData.conversionRate ? 
                                                                    this.state.currencyData.currencySymbol +' '+ data.pricePerUoMInOtherCurrency 
                                                                : 
                                                                    data.pricePerUoM 
                                                            }
                                                        </td>
                                                        <td>{data.discount} %</td>
                                                        {
                                                            this.state.currencyData.conversionRate === undefined &&
                                                                <td className='pg-tab-head'><Bi.BiRupee /> {(data.subTotal).toFixed(2)}</td>
                                                        }
                                                        {
                                                            this.state.currencyData.conversionRate === undefined &&
                                                            this.state.applicableTaxType.map((d, i)=>(
                                                                <td className='pg-tab-head' key={'appTax'+i}>
                                                                    {this.getApplicableTaxValue(data)}
                                                                    {
                                                                        this.state.applicableTaxTypeWithAllData.includes("exemptedtax") &&
                                                                        <small>(Exempted)</small>
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                        <td>
                                                            {
                                                                this.state.currencyData.conversionRate ?
                                                                    <>{this.state.currencyData.currencySymbol} {(data.rawTotalInOtherCurrency)}</>
                                                                :
                                                                    <><Bi.BiRupee /> {(data.total).toFixed(2)}</>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }

                                    <tr>
                                        <td></td>
                                        <td><b>Total:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            <td><b><Bi.BiRupee /> {this.state.subTotal}</b></td>
                                        }
                                        {
                                            this.state.currencyData.conversionRate === undefined &&
                                            this.state.applicableTaxType.map((data, i)=>(
                                                <td key={'appTaxBlankField'+i}></td>
                                            ))
                                        }
                                        {
                                            this.state.currencyData.conversionRate ?
                                                <td><b>{this.state.currencyData.currencySymbol} {this.state.grandTotalInOther}</b></td>
                                            :
                                                <td><b><Bi.BiRupee /> {this.state.grandTotal}</b></td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.state.fieldNotEditable &&
                                <div className="disableFieldCover"></div>
                            }
                        </div>

                        <div className="addSaveButton">
                            <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                            {
                                this.state.is_e_invoice_generated !== true &&
                                <div className="add_btnSave" onClick={()=>this.updateInvoice()}>{this.state.fieldNotEditable ? 'Edit' : 'Generate'}</div>
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
        );
    }
}

export default ViewEInvoice;