import React from 'react';
import { Header, StockHeader } from './../../../components'

import Helper from './../../../helper/helper'
import * as APIManager from './../../../APIManager/APIManager'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateFilterTable extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",
            
            countFieldNumber: [],
            tablename: "",
            tableFields: [],
            tableValueObj: [],
        }
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount(){
        this.getTable()   
    }


    getTable = () => {
        let id = localStorage.getItem("customTableId")
        let filterFlag = false;
        
        this.setState({ isLoading: true, error: false })
        APIManager.callFilterTableDetail(id, filterFlag)
        .then(response => {
            this.setState({
                isLoading: false, 
                tablename: response[0].tableName,
                tableFields: response[0].fields
           })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: false,
                errormessage: errors.message 
            })
        });    
    }
    

    handleInputChange = (e, name) => {
        this.setState({
            [name]: e.target.value
        })
    }
   
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    createTable = () => {
        let obj = {}
        let blankField = false;

        for(let i=0;i<this.state.tableFields.length;i++){
            let index = this.state.tableFields[i];
            let value = this.state[this.state.tableFields[i]]
            if(value === "" || typeof(value) === 'undefined'){
                alert("Please fill all fields first")
                blankField = true;
                break;
            }else{
                obj[index] = value;
            }
        }
        if(blankField !== true){
            let that = this;
            let id = localStorage.getItem("customTableId")
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("tableId", id)
            formdata.append("values", JSON.stringify(obj))

            fetch(APIManager.WEBSERVICES_URL+'stock/filterTableValue/createFilterTableValue',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                if(result.status === "success"){
                    that.setState({
                        error: false,
                        errormessage: ""
                    },()=> {
                        localStorage.setItem("createNewCustomTableValue", true)
                        localStorage.setItem("customTableValueId",result.data)
                        that.goBack()
                    })
                }else if(result.status === "userDataError"){
                    that.logout()
                } else {
                    that.setState({
                        error: true,
                        errormessage: result.message
                    })
                }
                },(error) => {
                    console.log(error)
                }
            ) 
        }
    }



    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isStock={true} />
                <StockHeader isFilterTable={true}/>
                <div className="pagecontent">
                    <div className="headerArea">
                        <div className="pageTitle heading editPageTitle">Create a {this.state.tablename}</div>
                    </div>
                    <div className="clearfix"></div> 
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createTable()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="mb-5 mt-5">
                       {
                            this.state.tableFields !== null &&
                            this.state.tableFields.map((data, i)=>(
                                <div className="add_partside" key={"addFields"+i}>
                                    <div className="add_inner">
                                        <div className="title capitalize">{data}</div>
                                        <div className="value">
                                            <input type="text" className="fieldheightdecr" autoComplete="off" value={this.state[data]} onChange={(e)=>this.handleInputChange(e, data)} />
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            ))
                       }
                        <div className="clear"></div>
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createTable()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Cancel</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        )
    }
}