import React, { Component } from 'react';
import * as Fi from 'react-icons/fi'
import { validateNumber } from '../../../../constants/Validate';
import Select2 from '../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css'
import * as APIManager from './../../../../APIManager/APIManager'

class GroupItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,

            onBlurCall: this.props.onBlurProduct,
            count: this.props.counter,
            apiResponseData: this.props.groupData,
            itemOfBOM: this.props.itemOfBOM,

            productGroupList: this.props.productGroupList,
            productList: [],
            productData: [],

            productObj : {
                'productGroup': '',
                'part': '',
                'notes': '',
                'quantity': '',
                'cadNo': ''
            },

            productGroup: "",
            part: "",
            notes: "",
            uom: "",
            quantity: "",
            cadNo: "",
            partDescription: "",
            pricePerUoM: "",
        }
    }

    componentDidMount(){
        this.setProductValues()
    }

  

    setProductValues = () => {
        let apiData = this.state.apiResponseData;

        this.setState({
            productGroup: apiData.productGroup,
            part: apiData.part,
            notes: apiData.notes,
            quantity: apiData.quantity,
            cadNo: apiData.cadNo ? apiData.cadNo : "",
            productObj: apiData
        },()=>{
            
            this.getItemsByGroup(apiData.productGroup)
            this.getItemDetail(apiData.part)
            if(apiData.productGroup){
                this.state.onBlurCall(this.state.productObj, this.state.count)
            }
        })
    }


    getItemsByGroup = (_id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callProductGroupItemsList(_id)
        .then(response => {
            this.setState({ 
                isLoading: false,
                productList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }


    getItemDetail = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSingleItem(id)
        .then(response => {
            this.setState({
                isLoading: false,
                partDescription: response.itemData.partDescription,
                pricePerUoM: response.itemData.rawCost,
                uom: response.itemData.uomName
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    generateObject = (type, value) => {        
        let productObj = this.state.productObj
    
        if(type === 'productGroup')
            productObj.productGroup = value;
        if(type === 'part')
            productObj.part = value;
        if(type === 'notes')
            productObj.notes = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        if(type === 'quantity')
            productObj.quantity = value;
        if(type === 'cadNo')
            productObj.cadNo = value;
   
        this.setState({
            productObj
        },()=>{
            this.state.onBlurCall(this.state.productObj, this.state.count)
        })
    }

    render() {
        let {
            onChangeProduct,
            onBlurProduct,
            onRemoveProduct,
            counter
        } = this.props
        return (
            <tr>
                <td></td>
                <td style={{ width: 150 }}>
                    <Select2    
                        data={
                            this.state.productGroupList.map((data) => (
                                { id: data._id, text: data.name }
                            ))
                        }
                        value={this.state.productGroup}
                        options={{placeholder: 'Select Group'}}
                        onSelect={(e)=> this.setState({ productGroup: e.target.value },()=>{
                            this.getItemsByGroup(e.target.value)
                            this.generateObject('productGroup', e.target.value)
                            onBlurProduct(this.state.productObj, counter)
                        })}
                    />
                </td>
                <td style={{width: 80}}>
                    <div>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.cadNo}
                            onChange={(e)=>this.setState({ cadNo: e.target.value})}
                            onBlur={(e)=>{
                                this.generateObject('cadNo', e.target.value)
                            }}
                        ></input>
                    </div>
                </td>
                <td style={{ width: 130 }}>
                    <Select2    
                        data={
                            this.state.productList.map((data) => (
                                { id: data._id, text: data.itemCode }
                            ))
                        }
                        value={this.state.part}
                        options={{placeholder: 'Select'}}
                        onSelect={(e)=> this.setState({ part: e.target.value },()=>{
                            this.getItemDetail(e.target.value)
                            onChangeProduct(counter);
                            this.generateObject('part', e.target.value)
                            onBlurProduct(this.state.productObj, counter)
                        })}
                    />
                </td>
                <td>
                    <div className='display-border-in-bom'>{this.state.partDescription}</div>
                </td>
                <td style={{width: 80}}>
                    <div>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.quantity}
                            onChange={(e)=>{
                                if(validateNumber(e.target.value)){
                                    this.setState({ 
                                        quantity: e.target.value
                                    })
                                }
                            }}
                            onBlur={(e)=>{
                                this.generateObject('quantity', e.target.value)
                            }}
                        ></input>
                    </div>
                </td>

                <td style={{width: 80}}>
                    <div className='display-border-in-bom'>{this.state.uom}</div>
                </td>
                <td style={{width: 80}}>
                    <div className='display-border-in-bom'>
                        {this.state.pricePerUoM !== "" && this.state.pricePerUoM+'/-' }
                    </div>
                </td>
                <td style={{width: 250}}>
                    <div className='mt-1'>
                        <textarea 
                            rows="1"
                            value={this.state.notes}
                            className='textarea p-1' 
                            onChange={(e)=>this.setState({ notes: e.target.value },()=>{
                                onChangeProduct(counter);
                                this.generateObject('notes', e.target.value)
                            })
                            }
                            onBlur={()=>onBlurProduct(this.state.productObj, counter)}
                        /> 
                    </div>
                </td>
                <td style={{ width: 50 }}>
                    {
                        (this.state.productGroup !== '' && this.state.part !== '') &&
                        <Fi.FiX className="extraFieldCloseButton" onClick={onRemoveProduct.bind(this,counter)} />
                    }
                </td>
            </tr>
        );
    }
}

export default GroupItem;