import React from 'react';

import { Header, CRMHeader } from '../../../components'
import Strings from '../../../constants/Strings'
import * as APIManager from './../../../APIManager/APIManager'
import * as ExportData from './exportData'


import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';

import * as Ai from "react-icons/ai"
import * as Im from "react-icons/im";
import * as Fi from "react-icons/fi";
import Popup from 'reactjs-popup';

import Pagination from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';

import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { convertDateToDDMMYYYY } from '../../../constants/DateFormatter';

export default class Customers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /* Company Data */
            isLoading: true,
            responseData: null,
            accountManagerList: null,
            totalData: 0,
            activePage: 1,
            pageStart: 0,
            pageLength: 25,
            companyStatusData: [],
            companyIndustryData: [],
            selectedColumns:[],
            contactsArrayTab:[],


            /* Contact Data */
            contactResponseData: null,
            totalContactData: 0,
            activeContactPage: 1,
            pageContactLength: 25,
            contactPageStart: 0,

            /* Company Search Paramter & Values */
            number: "",
            name: "",
            status: "",
            nickName: "",
            panNo: "",
            gstNo: "",
            minCreditLimit: "",
            maxCreditLimit: "",
            manager: "",
            Phone: "",
            Fax: "",
            email: "",
            Web: "",
            Address: "",
            other: "",
            Skype: "",
            nextContactStart: "",
            nextContactEnd: "",
            minContactStart: "",
            maxContactStart: "",
            deliveryTerms: "",
            searchnote: "",
            industrytype: "",
            paymentTerms: "",
            fieldName: "",
            fieldType: "",
            sortVal: false,
            
            fieldName_col: "",
            fieldType_col: "",
            sortVal_col: false,


            /* Contact Search parameter and values */
            Con_Number: "",
            Con_Name: "",
            Con_Status: "",
            Con_Phone: "",
            Con_Email: "",
            Con_ContactsName: "",
            Con_ContactsPosition: "",
            Con_ContactsEmail: "",
            Con_ContactsPhone: "",
            Con_fieldName: "",

            /* Listing target */
            tableListing: "companies",

            /* Show/Hide Column */
            /* Company Column */
            col_disp_number: true,
            col_disp_name: true,
            col_disp_status: true,
            col_disp_panno: false,
            col_disp_gstnum: false,
            col_disp_contactstarted: false,
            col_disp_netxtcontact: true,
            col_disp_accmanager: true,
            col_disp_creditlimit: false,
            col_disp_deliveryterms: false,
            col_disp_phone: true,
            col_disp_email: true,
            col_disp_skype: false,
            col_disp_web: false,
            col_disp_industrytype: true,

            /* Contacts Column */
            col_disp_cnumber: true,
            col_disp_cname: true,
            col_disp_cstatus: true,
            col_disp_cphone: true,
            col_disp_cemail: true,
            col_disp_contactsname: true,
            col_disp_contactsposition: true,
            col_disp_contactsemail: true,
            col_disp_contactsphone: true,

        }
        this.timer = null;
        this.onSelectStatus = this.onSelectStatus.bind(this)
    }

    componentDidMount() {
        this.getFilterData();
        this.setLocalToOption();
        this.setLocalContacts();
    }

    handlePageChange(pageNumber) {

        let length = this.state.pageLength;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            isLoading: true,
            activePage: pageNumber,
            pageStart: newStart
        }, () => {
            this.setAllInLocal()
            this.customerListing()
        });
    }

    handleContactPageChange(pageNumber) {

        let length = this.state.pageContactLength;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            isLoading: true,
            activeContactPage: pageNumber,
            contactPageStart: newStart
        }, () => {
            this.getContactPersonList()
        });
    }

    getCustomerStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerStatus()
            .then(response => {

                this.setState({
                    isLoading: false,
                    companyStatusData: response
                })

            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });

    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    getCompanyIndustry = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCompanyIndustry()
            .then(response => {
                this.setState({
                    isLoading: false,
                    companyIndustryData: response.companyIndustry !== undefined ? response.companyIndustry : null
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }



    getUserList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callSubUserList()
            .then(response => {
                this.setState({
                    isLoading: false,
                    accountManagerList: response.data
                } )
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    customerListing = () => {
        let { pageLength, nextContactStart, nextContactEnd, minContactStart, maxContactStart, paymentTerms, deliveryTerms, number, name, status, nickName, panNo, gstNo, minCreditLimit, maxCreditLimit, manager, Phone, Fax, email, Web, Skype, Address, other, searchnote, industrytype, activePage, fieldName, fieldType } = this.state
        let start = activePage !== 1 ? pageLength * (activePage - 1) + 1 : 0

        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerListWithFilter(start, pageLength, convertDateToDDMMYYYY(nextContactStart), convertDateToDDMMYYYY(nextContactEnd), convertDateToDDMMYYYY(minContactStart), convertDateToDDMMYYYY(maxContactStart), paymentTerms.toLowerCase(), deliveryTerms.toLowerCase(), number.toLowerCase(), name.toLowerCase(), status, nickName.toLowerCase(), panNo.toLowerCase(), gstNo.toLowerCase(), minCreditLimit, maxCreditLimit, manager.toLowerCase(), Phone.toLowerCase(), Fax, email.toLowerCase(), Web.toLowerCase(), Skype.toLowerCase(), Address.toLowerCase(), other.toLowerCase(), searchnote.toLowerCase(), industrytype, fieldName, fieldType)
            .then(response => {
                this.setState({
                    isLoading: false,
                    totalData: response.totalRecords,
                    responseData: response.data,
                } , () => {
                    if(this.state.fieldName){
                        this.sortCustomerComp(this.state.fieldName, this.state.fieldType, true)
                    }
                } ) 
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }


    getContactPersonList = () => {
        let { activeContactPage, pageContactLength, Con_Number, Con_Name, Con_Status, Con_Phone, Con_Email, Con_ContactsName, Con_ContactsPosition, Con_ContactsEmail, Con_ContactsPhone, fieldName } = this.state
        let start = activeContactPage !== 1 ? pageContactLength * (activeContactPage - 1) + 1 : 0
        this.setState({ isLoading: true, error: false })

        APIManager.callContactListWithFilter(start, pageContactLength, Con_Number.toLowerCase(), Con_Name.toLowerCase(), Con_Status, Con_Phone, Con_Email.toLowerCase(), Con_ContactsName, Con_ContactsPosition.toLowerCase(), Con_ContactsEmail.toLowerCase(), Con_ContactsPhone.toLowerCase(), fieldName)
            .then(response => {
                this.setState({
                    isLoading: false,
                    totalContactData: response.totalRecords,
                    contactResponseData: response.data
                } , () => {
                    if(this.state.fieldName_col){
                        this.sortCustomer(this.state.fieldName_col, this.state.fieldTypefieldName_col, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
        this.setAllInLocal()
    }


    exportOptions = (exportTo) => {
        if (this.state.tableListing === "companies") {
            if (exportTo === "pdf")
                this.exportCompanyPDF()
            else
                this.exportCompanyCSV()
        } else {
            if (exportTo === "pdf")
                this.exportContactPDF()
            else
                this.exportContactCSV()
        }
    }

    exportCompanyPDF = () => {
        let { nextContactStart, nextContactEnd, minContactStart, maxContactStart, paymentTerms, deliveryTerms, number, name, status, nickName, panNo, gstNo, minCreditLimit, maxCreditLimit, manager, Phone, Fax, email, Web, Skype, Address, other, searchnote, industrytype } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerListWithFilter("", "", convertDateToDDMMYYYY(nextContactStart), convertDateToDDMMYYYY(nextContactEnd), convertDateToDDMMYYYY(minContactStart), convertDateToDDMMYYYY(maxContactStart), paymentTerms.toLowerCase(), deliveryTerms.toLowerCase(), number.toLowerCase(), name.toLowerCase(), status, nickName.toLowerCase(), panNo.toLowerCase(), gstNo.toLowerCase(), minCreditLimit, maxCreditLimit, manager.toLowerCase(), Phone.toLowerCase(), Fax, email.toLowerCase(), Web.toLowerCase(), Skype.toLowerCase(), Address.toLowerCase(), other.toLowerCase(), searchnote.toLowerCase(), industrytype)
            .then(response => {
                this.setState({
                    isLoading: false,
                }, () => {
                    ExportData.exportCompanyPDF( response.data, this.state.selectedColumns );
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    exportCompanyCSV = () => {
        let { nextContactStart, nextContactEnd, minContactStart, maxContactStart, paymentTerms, deliveryTerms, number, name, status, nickName, panNo, gstNo, minCreditLimit, maxCreditLimit, manager, Phone, Fax, email, Web, Skype, Address, other, searchnote, industrytype } = this.state
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerListWithFilter("", "", convertDateToDDMMYYYY(nextContactStart), convertDateToDDMMYYYY(nextContactEnd), convertDateToDDMMYYYY(minContactStart), convertDateToDDMMYYYY(maxContactStart), paymentTerms.toLowerCase(), deliveryTerms.toLowerCase(), number.toLowerCase(), name.toLowerCase(), status, nickName.toLowerCase(), panNo.toLowerCase(), gstNo.toLowerCase(), minCreditLimit, maxCreditLimit, manager.toLowerCase(), Phone.toLowerCase(), Fax, email.toLowerCase(), Web.toLowerCase(), Skype.toLowerCase(), Address.toLowerCase(), other.toLowerCase(), searchnote.toLowerCase(), industrytype)
            .then(response => {
                this.setState({
                    isLoading: false,
                }, () => {
                    ExportData.exportCompanyCSV(response.data,  this.state.selectedColumns);
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    exportContactPDF = () => {
        let { Con_Number, Con_Name, Con_Status, Con_Phone, Con_Email, Con_ContactsName, Con_ContactsPosition, Con_ContactsEmail, Con_ContactsPhone } = this.state
        this.setState({ isLoading: true, error: false })

        APIManager.callContactListWithFilter("", "", Con_Number.toLowerCase(), Con_Name.toLowerCase(), Con_Status, Con_Phone, Con_Email.toLowerCase(), Con_ContactsName, Con_ContactsPosition.toLowerCase(), Con_ContactsEmail.toLowerCase(), Con_ContactsPhone.toLowerCase())
            .then(response => {
                this.setState({
                    isLoading: false
                }, () => {
                    ExportData.exportContactPDF(response.data, this.state.contactsArrayTab);
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    exportContactCSV = () => {
        let { Con_Number, Con_Name, Con_Status, Con_Phone, Con_Email, Con_ContactsName, Con_ContactsPosition, Con_ContactsEmail, Con_ContactsPhone } = this.state
        this.setState({ isLoading: true, error: false })

        APIManager.callContactListWithFilter("", "", Con_Number.toLowerCase(), Con_Name.toLowerCase(), Con_Status, Con_Phone, Con_Email.toLowerCase(), Con_ContactsName, Con_ContactsPosition.toLowerCase(), Con_ContactsEmail.toLowerCase(), Con_ContactsPhone.toLowerCase())
            .then(response => {
                this.setState({
                    isLoading: false
                }, () => {
                    ExportData.exportContactCSV(response.data, this.state.contactsArrayTab);
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });
    }

    search = () => {
        localStorage.removeItem("customersActivePage")
        this.setState({ activePage: 1, isLoading: true }, () => {
            this.customerListing()
            this.setAllInLocal()
        })
    }

    searchContact = () => {
        localStorage.removeItem("contactsActivePage")
        this.setState({ activeContactPage: 1, isLoading: true }, () => {
            this.getContactPersonList()
            this.setAllInLocal()
        })

    }
    clearSearch = () => {
        localStorage.removeItem("customersActivePage")
        localStorage.removeItem("customerTableFilter")
        this.setState({
            isLoading: true,
            activePage: 1,
            number: "",
            name: "",
            status: "",
            nickName: "",
            industrytype: "",
            panNo: "",
            gstNo: "",
            minCreditLimit: "",
            maxCreditLimit: "",
            manager: "",
            Phone: "",
            Fax: "",
            email: "",
            Web: "",
            Skype: "",
            Address: "",
            other: "",
            deliveryTerms: "",
            minContactStart: "",
            maxContactStart: "",
            nextContactStart: "",
            nextContactEnd: "",
            note: "",
            pageLength: 25
        }, () => {
            this.customerListing()
        })
    }

    _handleKeyDown = (e) => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.search()
        }, 800);
    }


    clearContactSearch = () => {
        localStorage.removeItem("contactsTableFilter")
        this.setState({
            isLoading: true,
            activeContactPage: 1,
            Con_Number: "",
            Con_Name: "",
            Con_Status: "",
            Con_Phone: "",
            Con_Email: "",
            Con_ContactsName: "",
            Con_ContactsPosition: "",
            Con_ContactsEmail: "",
            Con_ContactsPhone: "",
            pageContactLength: 25
        }, () => {
            this.getContactPersonList()
        })
    }

    addContact = (id, companyId, companyName) => {
        localStorage.setItem("contactsActivePage", this.state.activeContactPage)
        localStorage.setItem("cmpId", id)
        localStorage.setItem("cmpIdNum", companyId)
        localStorage.setItem("cmpName", companyName)
        this.props.history.push('/editcustomercompany')
    }

    setAllInLocal = () => {
        let filterDataArray = {
            'activePage': this.state.activePage,
            'number': this.state.number,
            'name': this.state.name,
            'status': this.state.status,
            'nickName': this.state.nickName,
            'industrytype': this.state.industrytype,
            'panNo': this.state.panNo,
            'gstNo': this.state.gstNo,
            'minCreditLimit': this.state.minCreditLimit,
            'maxCreditLimit': this.state.maxCreditLimit,
            'manager': this.state.manager,
            'Phone': this.state.Phone,
            'Fax': this.state.Fax,
            'email': this.state.email,
            'Web': this.state.Web,
            'Skype': this.state.Skype,
            'Address': this.state.Address,
            'other': this.state.other,
            'deliveryTerms': this.state.deliveryTerms,
            'minContactStart': this.state.minContactStart !== null ? this.state.minContactStart : "",
            'maxContactStart': this.state.maxContactStart !== null ? this.state.maxContactStart : "",
            'nextContactStart': this.state.nextContactStart !== null ? this.state.nextContactStart : "",
            'nextContactEnd': this.state.nextContactEnd !== null ? this.state.nextContactEnd : "",
            'note': this.state.note,
            'pageLength': this.state.pageLength,
            'pageContactLength': this.state.pageContactLength,
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
        }

        let selectedColumnTabInCustomerTable = {
            'col_disp_number': this.state.col_disp_number,
            'col_disp_name': this.state.col_disp_name,
            'col_disp_status': this.state.col_disp_status,
            'col_disp_panno': this.state.col_disp_panno,
            'col_disp_gstnum': this.state.col_disp_gstnum,
            'col_disp_contactstarted': this.state.col_disp_contactstarted,
            'col_disp_netxtcontact': this.state.col_disp_netxtcontact,
            'col_disp_accmanager': this.state.col_disp_accmanager,
            'col_disp_creditlimit': this.state.col_disp_creditlimit,
            'col_disp_deliveryterms': this.state.col_disp_deliveryterms,
            'col_disp_phone': this.state.col_disp_phone,
            'col_disp_email': this.state.col_disp_email,
            'col_disp_skype': this.state.col_disp_skype,
            'col_disp_web': this.state.col_disp_web,
            'col_disp_industrytype': this.state.col_disp_industrytype,
        }
        const selectedColumns = selectedColumnTabInCustomerTable;
        this.setState({
            selectedColumns
        })


        let contactsArray = {
            'isLoading': this.state.isLoading,
            'activeContactPage': this.state.activeContactPage,
            'Con_Number': this.state.Con_Number,
            'Con_Name': this.state.Con_Name,
            'Con_Status': this.state.Con_Status,
            'Con_Phone': this.state.Con_Phone,
            'Con_Email': this.state.Con_Email,
            'Con_ContactsName': this.state.Con_ContactsName,
            'Con_ContactsPosition': this.state.Con_ContactsPosition,
            'Con_ContactsEmail': this.state.Con_ContactsEmail,
            'Con_ContactsPhone': this.state.Con_ContactsPhone,
            'fieldName_col': this.state.fieldName_col,
            'fieldType_col': this.state.fieldType_col,
            'sortVal_col': this.state.sortVal_col,
        }
        let contactChooseColumnVal = {
            'col_disp_cnumber': this.state.col_disp_cnumber,
            'col_disp_cname': this.state.col_disp_cname,
            'col_disp_cphone':this.state.col_disp_cphone,
            'col_disp_cemail': this.state.col_disp_cemail,
            'col_disp_contactsname': this.state.col_disp_contactsname,
            'col_disp_contactsposition': this.state.col_disp_contactsposition,
            'col_disp_contactsemail': this.state.col_disp_contactsemail,
            'col_disp_contactsphone':this.state.col_disp_contactsphone,
        }
        let contactsArrayTab = contactChooseColumnVal
        this.setState({
            contactsArrayTab
        })
        localStorage.setItem("selectedColumnTabInCustomerTable", JSON.stringify(selectedColumnTabInCustomerTable))
        localStorage.setItem("customerTableFilter", JSON.stringify(filterDataArray))
        // alert(this.state.fieldName_col)
        localStorage.setItem("contactsTableFilter", JSON.stringify(contactsArray))
    }

    getFilterData = () => {

        let filterData = JSON.parse(localStorage.getItem("customerTableFilter"))
        let columnTab = JSON.parse(localStorage.getItem("selectedColumnTabInCustomerTable"))
        // let contactData = JSON.parse(localStorage.getItem("contactsTableFilter"))
        if (filterData !== null) {

            this.setState({
                activePage: parseInt(filterData.activePage),
                number: filterData.number,
                name: filterData.name,
                status: filterData.status,
                nickName: filterData.nickName,
                industrytype: filterData.industrytype,
                panNo: filterData.panNo,
                gstNo: filterData.gstNo,
                minCreditLimit: filterData.minCreditLimit,
                maxCreditLimit: filterData.maxCreditLimit,
                manager: filterData.manager,
                Phone: filterData.Phone,
                Fax: filterData.Fax,
                email: filterData.email,
                Web: filterData.Web,
                Skype: filterData.Skype,
                Address: filterData.Address,
                other: filterData.other,
                deliveryTerms: filterData.deliveryTerms,
                minContactStart: filterData.minContactStart ? new Date(filterData.minContactStart) : "",
                maxContactStart: filterData.maxContactStart ? new Date(filterData.maxContactStart) : "",
                nextContactStart: filterData.nextContactStart ? new Date(filterData.nextContactStart) : "",
                nextContactEnd: filterData.nextContactEnd ? new Date(filterData.nextContactEnd) : "",
                note: filterData.note,
                pageLength: filterData.pageLength,
                pageContactLength: filterData.pageContactLength,
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,

                col_disp_number: columnTab.col_disp_number,
                col_disp_name: columnTab.col_disp_name,
                col_disp_status: columnTab.col_disp_status,
                col_disp_panno: columnTab.col_disp_panno,
                col_disp_gstnum: columnTab.col_disp_gstnum,
                col_disp_contactstarted: columnTab.col_disp_contactstarted,
                col_disp_netxtcontact: columnTab.col_disp_netxtcontact,
                col_disp_accmanager: columnTab.col_disp_accmanager,
                col_disp_creditlimit: columnTab.col_disp_creditlimit,
                col_disp_deliveryterms: columnTab.col_disp_deliveryterms,
                col_disp_phone: columnTab.col_disp_phone,
                col_disp_email: columnTab.col_disp_email,
                col_disp_skype: columnTab.col_disp_skype,
                col_disp_web: columnTab.col_disp_web,
                col_disp_industrytype: columnTab.col_disp_industrytype,
               

            }, () => {
                this.customerListing();
                this.getUserList()
                this.getContactPersonList()
                this.getCustomerStatus()
                this.getCompanyIndustry()

            })
        } else {
            this.customerListing();
            this.getUserList()
            this.getContactPersonList()
            this.getCustomerStatus()
            this.getCompanyIndustry()
        }
    }


    setLocalContacts = () => {
        let contactData = JSON.parse(localStorage.getItem("contactsTableFilter"))
        if (contactData !== null) {

            this.setState({
                isLoading: contactData.isLoading,
                activeContactPage: contactData.activeContactPage,
                Con_Number: contactData.Con_Number,
                Con_Name: contactData.Con_Name,
                // Con_Status: contactData.Con_Status,	
                Con_Phone: contactData.Con_Phone,
                Con_Email: contactData.Con_Email,
                Con_ContactsName: contactData.Con_ContactsName,
                Con_ContactsPosition: contactData.Con_ContactsPosition,
                Con_ContactsEmail: contactData.Con_ContactsEmail,
                Con_ContactsPhone: contactData.Con_ContactsPhone,
                fieldName_col: contactData.fieldName_col ? contactData.fieldName_col : '',
                fieldType_col: contactData.fieldType_col ? contactData.fieldType_col : '',
                sortVal_col: contactData.sortVal_col ? contactData.sortVal_col : false,

            }, () => {
                // alert(this.state.fieldName_col)
                // alert(this.state.fieldType_col)
                // alert(this.state.sortVal_col)
                this.customerListing();
                this.getUserList()
                this.getContactPersonList()
                this.getCustomerStatus()
                this.getCompanyIndustry()
            })
        } else {
            this.customerListing();
            this.getUserList()
            this.getContactPersonList()
            this.getCustomerStatus()
            this.getCompanyIndustry()
        }
    }

    setOptionValue = () => {
        localStorage.setItem("customerOrderOption", this.state.tableListing)
    }

    setLocalToOption = () => {

        let filterData = localStorage.getItem("customerOrderOption")
        if (filterData) {

            this.setState({
                tableListing: filterData,
            })
        }
    }

    _handleKeyDownContact = (e) => {
        //if (e.key === 'Enter' || e.keyCode === 9) {
        this.searchContact()
        //}
    }

    goBack = () => {
        this.props.history.goBack();
    }

    addCustomers = () => {
        let slug = "CRM_Customers"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createnewcompany"
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }
    editCustomer = (id, companyId, companyName) => {
        localStorage.setItem("customersActivePage", this.state.activePage)
        localStorage.setItem("cmpId", id)
        localStorage.setItem("cmpIdNum", companyId)
        localStorage.setItem("cmpName", companyName)

        this.props.history.push({
            pathname: "/editcustomercompany",
            state: { cmpId: id, cmpIdNum: companyId, cmpName: companyName, createNewEdit: false }
        })

    }



    onSelectStatus(status) {
        this.setState({
            status: status
        }, () => {
            this.search()
        })
    }
    // /******************* ascending descending order function COMPANIES  ********************************
    sortCustomerComp = (fieldName, fieldType, onload) => {
        let sortVal = this.state.sortVal;
        if(!onload){
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }  sortVal = !this.state.sortVal;
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        } , () => {
            const sortedOrders = this.state.responseData.sort((a, b) => {
                if (fieldType === "Date") {
                    const day = a[fieldName].substr(0, 3)
                    const month = a[fieldName].substr(3, 3)
                    const year = a[fieldName].substring(6, 10)
                    const dateOne = `${month}${day}${year}`
    
                    const dayb = b[fieldName].substr(0, 3)
                    const monthb = b[fieldName].substr(3, 3)
                    const yearb = b[fieldName].substring(6, 10)
                    const dateTwo = `${monthb}${dayb}${yearb}`
    
                    const dateA = dateOne ? new Date(dateOne) : null;
                    const dateB = dateTwo ? new Date(dateTwo) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
    
                    // console.log(dateB, dateA);
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
            });
            this.setState({ responseData: sortedOrders } , () => {
                this.setAllInLocal()
            });
        });
    };
    // /******************* ascending descending order function end ********************************
    // /******************* ascending descending order function CONTACT  ********************************
    sortCustomer = (fieldName_col, fieldType_col, onload) => {
        let sortVal_col = this.state.sortVal_col;
        if(!onload){
            if (this.state.fieldName_col === fieldName_col) {
                sortVal_col = !this.state.sortVal_col;
            }
        }
        this.setState({
            fieldName_col: fieldName_col,
            fieldType_col: fieldType_col,
            sortVal_col: sortVal_col
        } , () => {
            const sortedOrders = this.state.contactResponseData.sort((a, b) => {
                if (fieldType_col === "Date") {
                    const dateA = a[fieldName_col] ? new Date(a[fieldName_col]) : null;
                    const dateB = b[fieldName_col] ? new Date(b[fieldName_col]) : null;
    
                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal_col ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal_col ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal_col ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal_col ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal_col ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName_col]) && !isNaN(b[fieldName_col])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName_col]);
                    const numB = parseFloat(b[fieldName_col]);
                    if (sortVal_col) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName_col] != null ? a[fieldName_col].toString() : '';
                    const strB = b[fieldName_col] != null ? b[fieldName_col].toString() : '';
                    if (sortVal_col) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
            });
            this.setState({ contactResponseData: sortedOrders } , () => {
                this.setAllInLocal()
            });
        });
    };
    // /******************* ascending descending order function end ********************************
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.CRM.Customers}</div>
                            </div>
                            <div className="col-lg-2 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-select-box">
                                    <select value={this.state.tableListing} onChange={(e) => this.setState({ tableListing: e.target.value }, () => { this.setOptionValue() })}>
                                        <option value="companies">Companies</option>
                                        <option value="contacts">Contacts</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportOptions('pdf')}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportOptions('csv')}>CSV <Im.ImArrowDown2 /></div>
                                    {/* <div className="button">Import from CSV <Im.ImArrowUp2 /></div>
                                    <div className="joined-buttons">
                                        <div className="btn-left"><Link to="/notesearch"><Ai.AiOutlineSearch /></Link></div>
                                        <div className="btn-rght"><Fa.FaSignal /></div>
                                        <div className="clear"></div>
                                    </div> */}
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>




                    {
                        this.state.tableListing === "companies" ?
                            <div className="tableContent mh400 __kDoEM__table " style={{ overflowX: "auto" }}>
                                <table>
                                    <thead>
                                        <tr className="no-bg">
                                            <th className="tableHeader tableHeadButton"><div onClick={() => this.addCustomers()}><Fi.FiPlus /></div></th>
                                            {
                                                this.state.col_disp_number &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('companyId', 'notDate' , false)}><span>Number </span>{this.state.fieldName === "companyId" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "companyId" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            }
                                            {
                                                this.state.col_disp_name &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('nickName', 'notDate' , false)}><span>Name</span> {this.state.fieldName === "nickName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "nickName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            }
                                            {
                                                this.state.col_disp_industrytype &&
                                                <th className="tableHeader" style={{width:'120 !important'}}  onClick={() => this.sortCustomerComp('industryType', 'notDate' , false)}><span>Industry Type </span>{this.state.fieldName === "industryType" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "industryType" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            }
                                            {
                                                this.state.col_disp_status &&
                                                <th className="tableHeader" style={{width:'200px'}} onClick={() => this.sortCustomerComp('status', 'notDate' , false)}><span>Status </span>{this.state.fieldName === "status" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "status" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            }
                                            {
                                                this.state.col_disp_panno &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('panNo', 'notDate' , false)}><span>Pan No.</span> {this.state.fieldName === "panNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "panNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_gstnum &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('gstNo', 'notDate' , false)}><span>GST No.</span> {this.state.fieldName === "gstNo" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "gstNo" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_contactstarted &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('contactStarted', 'Date' , false)}><span>Contact Started </span>{this.state.fieldName === "contactStarted" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "contactStarted" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_netxtcontact &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('nextContact', 'Date' , false)}><span>Next Contact</span> {this.state.fieldName === "nextContact" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "nextContact" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_accmanager &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('managers', 'notDate' , false)}><span>Managers </span>{this.state.fieldName === "managers" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "managers" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            }
                                            {
                                                this.state.col_disp_creditlimit &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('creditLimit', 'notDate' , false)}><span>Credit Limit </span>{this.state.fieldName === "creditLimit" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "creditLimit" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                            }

                                            {
                                                this.state.col_disp_deliveryterms &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('deliveryTerms', 'notDate' , false)}><span>Delivery Terms</span> {this.state.fieldName === "deliveryTerms" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "deliveryTerms" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_phone &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('Phone', 'notDate' , false)}><span>Phone</span> {this.state.fieldName === "Phone" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Phone" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_email &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('Email', 'notDate' , false)}><span>Email </span>{this.state.fieldName === "Email" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Email" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_skype &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('Skype', 'notDate' , false)}><span>Skype </span>{this.state.fieldName === "Skype" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Skype" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                            }
                                            {
                                                this.state.col_disp_web &&
                                                <th className="tableHeader" onClick={() => this.sortCustomerComp('Web', 'notDate' , false)}><span>Web </span>{this.state.fieldName === "Web" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "Web" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                                // <th className="tableHeader"><span>Web</span></th>
                                            }

                                            <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><Fi.FiChevronsDown /></th>} position="bottom right">
                                                <div className="selectColumns">
                                                    <ul>
                                                        <li><label htmlFor="number"><span>Number</span> <input type="checkbox" id="number" disabled checked={this.state.col_disp_number} onChange={() => this.setState({ col_disp_number: !this.state.col_disp_number }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="name"><span>Name</span> <input type="checkbox" id="name" disabled checked={this.state.col_disp_name} onChange={() => this.setState({ col_disp_name: !this.state.col_disp_name }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="industrytype"><span>Industry type</span> <input type="checkbox" disabled id="industrytype" checked={this.state.col_disp_industrytype} onChange={() => this.setState({ col_disp_industrytype: !this.state.col_disp_industrytype }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="status"><span>Status</span> <input type="checkbox" id="status" disabled checked={this.state.col_disp_status} onChange={() => this.setState({ col_disp_status: !this.state.col_disp_status }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="panno"><span>PAN no.</span> <input type="checkbox" id="panno" checked={this.state.col_disp_panno} onChange={() => this.setState({ col_disp_panno: !this.state.col_disp_panno }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="gstnum"><span>GST number</span> <input type="checkbox" id="gstnum" checked={this.state.col_disp_gstnum} onChange={() => this.setState({ col_disp_gstnum: !this.state.col_disp_gstnum }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="contactstarted"><span>Contact started</span> <input type="checkbox" id="contactstarted" checked={this.state.col_disp_contactstarted} onChange={() => this.setState({ col_disp_contactstarted: !this.state.col_disp_contactstarted }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="netxtcontact"><span>Next contact</span> <input type="checkbox" id="netxtcontact" checked={this.state.col_disp_netxtcontact} onChange={() => this.setState({ col_disp_netxtcontact: !this.state.col_disp_netxtcontact }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="accmanager"><span>Account manager</span> <input type="checkbox" id="accmanager" checked={this.state.col_disp_accmanager} onChange={() => this.setState({ col_disp_accmanager: !this.state.col_disp_accmanager }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="creditlimit"><span>Credit limit</span> <input type="checkbox" id="creditlimit" checked={this.state.col_disp_creditlimit} onChange={() => this.setState({ col_disp_creditlimit: !this.state.col_disp_creditlimit }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="deliveryterms"><span>Delivery terms</span> <input type="checkbox" id="deliveryterms" checked={this.state.col_disp_deliveryterms} onChange={() => this.setState({ col_disp_deliveryterms: !this.state.col_disp_deliveryterms }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="phone"><span>Phone</span> <input type="checkbox" id="phone" checked={this.state.col_disp_phone} onChange={() => this.setState({ col_disp_phone: !this.state.col_disp_phone }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="email"><span>Email</span> <input type="checkbox" id="email" checked={this.state.col_disp_email} onChange={() => this.setState({ col_disp_email: !this.state.col_disp_email }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="skype"><span>Skype</span> <input type="checkbox" id="skype" checked={this.state.col_disp_skype} onChange={() => this.setState({ col_disp_skype: !this.state.col_disp_skype }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="web"><span>Web</span> <input type="checkbox" id="web" checked={this.state.col_disp_web} onChange={() => this.setState({ col_disp_web: !this.state.col_disp_web }, () => { this.setAllInLocal() })} /></label></li>
                                                    </ul>
                                                </div>
                                            </Popup>
                                            <th className="tableHeader tableHeadButton"><div onClick={() => this.addCustomers()}><Fi.FiPlus /></div></th>
                                            {/* <th className="tableHeader tableHeadButton"><Link to="/"><Ri.RiPaintBrushLine /></Link></th> */}
                                        </tr>
                                        <tr>
                                            <th className="tableElemContain tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                            {
                                                this.state.col_disp_number &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ number: e.target.value }, () => { this._handleKeyDown() })} value={this.state.number} /></th>
                                            }
                                            {
                                                this.state.col_disp_name &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 150 }} onChange={(e) => this.setState({ nickName: e.target.value }, () => { this._handleKeyDown() })} value={this.state.nickName} /></th>
                                            }
                                            {
                                                this.state.col_disp_industrytype &&
                                                <th className="tableElemContain" style={{width:120}} >
                                                    <select   value={this.state.industrytype} onChange={(e) => this.setState({ industrytype: e.target.value }, () => { this.search() })}>
                                                        <option value=""></option>
                                                        {
                                                            this.state.companyIndustryData !== null &&
                                                            this.state.companyIndustryData.map((data, i) => (
                                                                <option value={data.industrytype} key={i + data.industrytype}>{data.industrytype}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </th>
                                            }
                                            {
                                                this.state.col_disp_status &&
                                                <th className="tableElemContain"  style={{width:'200px'}}>



                                                    <MultiSelect
                                                        value={this.state.status}
                                                        onChange={this.onSelectStatus}
                                                        isMulti={true}
                                                        options={
                                                            this.state.companyStatusData.map((data) => (
                                                                { value: data._id, label: data.value }
                                                            ))

                                                        }
                                                        placeholder=""
                                                         style={{width:500}}
                                                    />
                                                </th>
                                            }
                                            {
                                                this.state.col_disp_panno &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ panNo: e.target.value }, () => { this._handleKeyDown() })} value={this.state.panNo} /></th>
                                            }
                                            {
                                                this.state.col_disp_gstnum &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ gstNo: e.target.value }, () => { this._handleKeyDown() })} value={this.state.gstNo} /></th>
                                            }
                                            {
                                                this.state.col_disp_contactstarted &&
                                                <th className="tableElemContain">
                                                    <div className="value">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyy"
                                                            selected={
                                                                this.state.minContactStart
                                                            }
                                                            onChange={
                                                                date => this.setState({ minContactStart: date }, () => {
                                                                    this.search()
                                                                })
                                                            }
                                                            className="filterdateoption" /> <span className="dash">-</span>
                                                        <Ai.AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyy"
                                                            minDate={this.state.minContactStart}
                                                            selected={
                                                                this.state.maxContactStart
                                                            }
                                                            onChange={
                                                                date => this.setState({ maxContactStart: date }, () => {
                                                                    this.search()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <Ai.AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.col_disp_netxtcontact &&
                                                <th className="tableElemContain">
                                                    <div className="value">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyy"
                                                            selected={
                                                                this.state.nextContactStart
                                                            }
                                                            onChange={
                                                                date => this.setState({ nextContactStart: date }, () => {
                                                                    this.search()
                                                                })
                                                            }
                                                            className="filterdateoption" /> <span className="dash">-</span>
                                                        <Ai.AiOutlineCalendar className="datepickerIcon" />
                                                    </div>

                                                    <div className="value">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyy"
                                                            minDate={this.state.nextContactStart}
                                                            selected={
                                                                this.state.nextContactEnd
                                                            }
                                                            onChange={
                                                                date => this.setState({ nextContactEnd: date }, () => {
                                                                    this.search()
                                                                })
                                                            }
                                                            className="filterdateoption" />
                                                        <Ai.AiOutlineCalendar className="datepickerIcon" />
                                                    </div>
                                                </th>
                                            }
                                            {
                                                this.state.col_disp_accmanager &&
                                                <th className="tableElemContain">
                                                    <select className="text-capitalize" style={{ minWidth: 100 }} value={this.state.manager} onChange={(e) => this.setState({ manager: e.target.value }, () => { this.search() })}>
                                                        <option value=""></option>
                                                        {
                                                            this.state.accountManagerList !== null &&
                                                            this.state.accountManagerList.map((data) => (
                                                                <option value={data._id} key={data._id}>{data.username ? data.username : data.full_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </th>
                                            }
                                            {
                                                this.state.col_disp_creditlimit &&
                                                <th className="tableElemContain" style={{ width: 100 }}>
                                                    {/* <input type="text" style={{width: 100}} onChange={(e)=>this.setState({ creditLimit: e.target.value },()=>{ this._handleKeyDown()})} value={this.state.creditLimit}  /> */}
                                                    <input type="text" style={{ width: 50 }} value={this.state.minCreditLimit} placeholder="min" onChange={(e) => this.setState({ minCreditLimit: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this._handleKeyDown() })} />
                                                    <input type="text" style={{ width: 50 }} value={this.state.maxCreditLimit} placeholder="max" onChange={(e) => this.setState({ maxCreditLimit: parseInt(e.target.value) ? parseInt(e.target.value) : "" }, () => { this._handleKeyDown() })} />

                                                </th>
                                            }

                                            {
                                                this.state.col_disp_deliveryterms &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ deliveryTerms: e.target.value }, () => { this._handleKeyDown() })} value={this.state.deliveryTerms} /></th>
                                            }
                                            {
                                                this.state.col_disp_phone &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ Phone: e.target.value }, () => { this._handleKeyDown() })} value={this.state.Phone} /></th>
                                            }
                                            {
                                                this.state.col_disp_email &&
                                                <th className="tableElemContain"><input type="text" onChange={(e) => this.setState({ email: e.target.value }, () => { this._handleKeyDown() })} value={this.state.email} /></th>
                                            }
                                            {
                                                this.state.col_disp_skype &&
                                                <th className="tableElemContain"><input type="text" style={{ width: 100 }} onChange={(e) => this.setState({ Skype: e.target.value }, () => { this._handleKeyDown() })} value={this.state.Skype} /></th>
                                            }
                                            {
                                                this.state.col_disp_web &&
                                                <th className="tableElemContain"><input type="text" onChange={(e) => this.setState({ Web: e.target.value }, () => { this._handleKeyDown() })} value={this.state.Web} /></th>
                                            }


                                            <th></th>
                                            {/* <th className="tableElemContain tableAddButton"><div onClick={()=>this.search()} className="cursor-pointer"><FiSearch /></div></th> */}
                                            <th className="tableElemContain tableAddButton"><div onClick={() => this.clearSearch()} className="cursor-pointer"><Fi.FiX /></div></th>
                                            {/* <th className="tableElemContain tableAddButton"><Md.MdCheckBoxOutlineBlank /></th> */}

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            this.state.responseData !== null &&
                                            this.state.responseData.map((data, i) => (
                                                <tr key={data._id} onClick={this.editCustomer.bind(this, data._id, data.companyId, data.companyName)}>
                                                    <td>{this.state.pageStart === 0 ? 1 + i : this.state.pageStart + i}</td>
                                                    {
                                                        this.state.col_disp_number &&
                                                        <td className='text-uppercase'>{data.companyId}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_name &&
                                                        <td>{data.nickName}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_industrytype &&
                                                        <td  style={{width:120}} >{data.industryType !== null ? data.industryType.toString() : ""}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_status &&
                                                        <td>{data.status}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_panno &&
                                                        <td>{data.panNo}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_gstnum &&
                                                        <td>{data.gstNo}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_contactstarted &&
                                                        <td>{data.contactStarted}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_netxtcontact &&
                                                        <td>{data.nextContact}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_accmanager &&
                                                        <td>{data.managers.toString()}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_creditlimit &&
                                                        <td>{data.creditLimit}</td>
                                                    }

                                                    {
                                                        this.state.col_disp_deliveryterms &&
                                                        <td>{data.deliveryTerms}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_phone &&
                                                        <td>{data.Phone}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_email &&
                                                        <td className='text-lowercase'>{data.Email}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_skype &&
                                                        <td>{data.Skype}</td>
                                                    }
                                                    {
                                                        this.state.col_disp_web &&
                                                        <td className='text-lowercase'>{data.Web}</td>
                                                    }

                                                    {/* <td><FiEdit2 className="editCusomerBtn" onClick={this.editCustomer.bind(this,data._id,data.companyId,data.companyName)}/></td> */}
                                                    <td></td>
                                                    <td>{/*<Fi.FiBarChart /> */}</td>
                                                    {/* <td><input type="checkbox" /></td> */}
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                                <div>
                                    <div className="float-left mt-10px">
                                        <div className="tableElemContain" style={{ width: 80 }}>
                                            <select value={this.state.pageLength} onChange={(e) => this.setState({ pageLength: e.target.value }, () => { this.handlePageChange(1) })}>
                                                <option value="25">25 rows</option>
                                                <option value="50">50 rows</option>
                                                <option value="100">100 rows</option>
                                                <option value="">All rows</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="float-right mt-10px">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.pageLength ? parseInt(this.state.pageLength) : parseInt(this.state.totalData)}
                                            totalItemsCount={this.state.totalData}
                                            pageRangeDisplayed={10}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            :
                            <div className="tableContent mh400 __kDoEM__table " style={{ overflowX: "auto" }}>
                                <table>
                                    <thead>
                                        <tr className="no-bg">
                                            <th className="tableAddButton"><div onClick={() => this.addCustomers()} className="addContactButton"><Fi.FiPlus /></div></th>
                                            {
                                                this.state.col_disp_cnumber &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate',false)}><span>Number </span>{this.state.fieldName_col === "number" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "number" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_cname &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('name', 'notDate',false)}><span> Company Name </span>{this.state.fieldName_col === "name" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "name" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_cphone &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('companyPhone', 'notDate',false)}><span>Phone </span>{this.state.fieldName_col === "companyPhone" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "companyPhone" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_cemail &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('companyEmail', 'notDate',false)}><span>E-mail</span> {this.state.fieldName_col === "companyEmail" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "companyEmail" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_contactsname &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('contactName', 'notDate',false)}><span>Contact's name </span>{this.state.fieldName_col === "contactName" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "contactName" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_contactsposition &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('contactPosition', 'notDate',false)}><span>Contact's position </span>{this.state.fieldName_col === "contactPosition" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "contactPosition" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_contactsemail &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('Email', 'notDate',false)}><span>Contact's E-mail </span>{this.state.fieldName_col === "Email" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "Email" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            {this.state.col_disp_contactsphone &&
                                                <th className="tableHeader" onClick={() => this.sortCustomer('Phone', 'notDate',false)}><span>Contact's phone</span> {this.state.fieldName_col === "Phone" && this.state.sortVal_col && <Fi.FiArrowUp />}{this.state.fieldName_col === "Phone" && !this.state.sortVal_col && <Fi.FiArrowDown />}</th>
                                            }
                                            <Popup arrow={false} trigger={<th className="tableHeader tableHeadButton"><Fi.FiChevronsDown /></th>} position="bottom right">
                                                <div className="selectColumns">
                                                    <ul>
                                                        <li><label htmlFor="number"><span>Number</span> <input type="checkbox" disabled id="number" checked={this.state.col_disp_cnumber} onChange={() => this.setState({ col_disp_cnumber: !this.state.col_disp_cnumber }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="name"><span>Name</span> <input type="checkbox" id="name" disabled checked={this.state.col_disp_cname} onChange={() => this.setState({ col_disp_cname: !this.state.col_disp_cname }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="phone"><span>Phone</span> <input type="checkbox" id="phone" checked={this.state.col_disp_cphone} onChange={() => this.setState({ col_disp_cphone: !this.state.col_disp_cphone }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="email"><span>E-mail</span> <input type="checkbox" id="email" checked={this.state.col_disp_cemail} onChange={() => this.setState({ col_disp_cemail: !this.state.col_disp_cemail }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="contactsname"><span>Contact's name</span> <input type="checkbox" disabled id="contactsname" checked={this.state.col_disp_contactsname} onChange={() => this.setState({ col_disp_contactsname: !this.state.col_disp_contactsname }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="contactsposition"><span>Contact's position</span> <input type="checkbox" id="contactsposition" checked={this.state.col_disp_contactsposition} onChange={() => this.setState({ col_disp_contactsposition: !this.state.col_disp_contactsposition }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="contactsemail"><span>Contact's e-mail</span> <input type="checkbox" id="contactsemail" checked={this.state.col_disp_contactsemail} onChange={() => this.setState({ col_disp_contactsemail: !this.state.col_disp_contactsemail }, () => { this.setAllInLocal() })} /></label></li>
                                                        <li><label htmlFor="contactsphone"><span>Contact's phone</span> <input type="checkbox" id="contactsphone" checked={this.state.col_disp_contactsphone} onChange={() => this.setState({ col_disp_contactsphone: !this.state.col_disp_contactsphone }, () => { this.setAllInLocal() })} /></label></li>
                                                    </ul>
                                                </div>
                                            </Popup>
                                            <th className="tableAddButton"><div onClick={() => this.addCustomers()} className="addContactButton"><Fi.FiPlus /></div></th>
                                        </tr>
                                        <tr>
                                            <th className="tableAddButton">{/*<Fi.FiSettings />*/}</th>
                                            {this.state.col_disp_cnumber &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_Number: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_Number} type="text" style={{ width: 100 }} /></th>
                                            }
                                            {this.state.col_disp_cname &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_Name: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_Name} type="text" style={{ width: 100 }} /></th>
                                            }
                                            {this.state.col_disp_cphone &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_Phone: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_Phone} type="text" style={{ width: 100 }} /></th>
                                            }
                                            {this.state.col_disp_cemail &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_Email: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_Email} type="text" style={{ width: 100 }} /></th>
                                            }
                                            {this.state.col_disp_contactsname &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_ContactsName: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_ContactsName} type="text" style={{ width: 115 }} /></th>
                                            }
                                            {this.state.col_disp_contactsposition &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_ContactsPosition: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_ContactsPosition} type="text" style={{ width: 115 }} /></th>
                                            }
                                            {this.state.col_disp_contactsemail &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_ContactsEmail: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_ContactsEmail} type="text" style={{ width: 100 }} /></th>
                                            }
                                            {this.state.col_disp_contactsphone &&
                                                <th className="tableElemContain"><input onChange={(e) => this.setState({ Con_ContactsPhone: e.target.value }, () => { this._handleKeyDownContact() })} value={this.state.Con_ContactsPhone} type="text" style={{ width: 100 }} /></th>
                                            }
                                            <th></th>
                                            {/* <th className="tableAddButton"><div onClick={()=>this.searchContact()} className="cursor-pointer"><FiSearch /></div></th> */}
                                            <th className="tableAddButton"><div onClick={() => this.clearContactSearch()} className="cursor-pointer"><Fi.FiX /></div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.contactResponseData !== null &&
                                            this.state.contactResponseData.map((data, i) => (
                                                <tr key={data.account_id + i} onClick={this.editCustomer.bind(this, data.companyId, data.number, data.name)}>
                                                    <td>{this.state.contactPageStart === 0 ? 1 + i : this.state.contactPageStart + i}</td>
                                                    {this.state.col_disp_cnumber &&
                                                        <td className='text-uppercase'>{data.number}</td>
                                                    }
                                                    {this.state.col_disp_cname &&
                                                        <td>{data.name}</td>
                                                    }

                                                    {this.state.col_disp_cphone &&
                                                        <td>{data.companyPhone ? data.companyPhone : ''}</td>
                                                    }
                                                    {this.state.col_disp_cemail &&
                                                        <td className='text-lowercase'>{data.companyEmail ? data.companyEmail : ''}</td>
                                                    }
                                                    {this.state.col_disp_contactsname &&
                                                        <td>{data.contactName}</td>
                                                    }
                                                    {this.state.col_disp_contactsposition &&
                                                        <td>{data.contactPosition}</td>
                                                    }
                                                    {this.state.col_disp_contactsemail &&
                                                        <td className='text-lowercase'>{data.Email ? data.Email : ''}</td>
                                                    }
                                                    {this.state.col_disp_contactsphone &&
                                                        <td>{data.Phone ? data.Phone : ''}</td>
                                                    }
                                                    {/* <td><FiEdit2 className="editCusomerBtn" onClick={this.editCustomer.bind(this,data.companyId,data.number,data.name)}/></td> */}
                                                    <td></td>
                                                    <td></td>
                                                    {/* <td><Fi.FiBarChart /></td> */}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div>
                                    <div className="float-left mt-10px">
                                        <div className="tableElemContain" style={{ width: 80 }}>
                                            <select value={this.state.pageContactLength} onChange={(e) => this.setState({ pageContactLength: e.target.value }, () => { this.handleContactPageChange(1) })}>
                                                <option value="25">25 rows</option>
                                                <option value="50">50 rows</option>
                                                <option value="100">100 rows</option>
                                                <option value="">All rows</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="float-right mt-10px">
                                        <Pagination
                                            activePage={this.state.activeContactPage}
                                            itemsCountPerPage={this.state.pageContactLength ? parseInt(this.state.pageContactLength) : parseInt(this.state.totalContactData)}
                                            totalItemsCount={this.state.totalContactData}
                                            pageRangeDisplayed={10}
                                            onChange={this.handleContactPageChange.bind(this)}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                    }
                </div>

            </div>
        )
    }
}