import React from 'react';
import * as APIManager from '../../../../APIManager/APIManager'

import { Header, CRMHeader } from './../../../../components'

import Loading from '../../../../helper/loading'
//import './../../../../../style.css'

import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';

import { AiOutlineCalendar, AiOutlineExclamation } from "react-icons/ai";
import AddEditOrderStatus from '../../../../components/AddEditOrderStatus';

import RichTextEditor from 'react-rte';

import AddTermsAndConditions from '../../../../components/AutoGenerateComponent/AddTermsAndConditions';

import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css'

import { 
    ImLink
} from "react-icons/im";

import ProductGroup from './GroupWithTax/ProductGroup';
import { convertDateToMMDDYYYY } from '../../../../constants/DateFormatter';

export default class EditCustomerOrder extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {

            orderId: "",
            orderNumber: "",

            isLoading: false,
            error: false,
            success: false,
            setProductGroup: false,
            errormessage: "",
            successmessage: "",

            viewAddStatusForm: false,
            viewAddCustomerOrderForm: true,

            customerList: [],
            orderStatusList: [],
            deliveryTermsList: [],
            shippingAddressList: [],
            contactPersonList: [],

            accountManagerDetail: [],

            quotationValidityEnd: "",
            accountManager: "",
            number: "",
            customer: "",
            status: "",
            updatedStatus: "",
            deliverydate: "",
            deliveryterms: "Ex-works",
            reference: "",
            shippingaddress: "",
            internalNotes: "",
            Created: "",
            rfqDate: "",
            rfqNo: "",
            contactPerson: "",
            customerOrderNotes: [],
            poNo: "",
            poDate: "",

            fieldNotEditable: true,
            revisionList: [],

            quotationMessageEditor: RichTextEditor.createEmptyValue(),
            quotationMessage: "",


            quotationMessageFooterEditor: RichTextEditor.createEmptyValue(),
            quotationMessageFooter: "",

            countTermsNumber: [],
            TermsCounter: 0,
            termsFields: [],
            termsAndConditions: [],
            generateNewTerm: true,
        }
    }

    componentDidMount(){

        let orderId = localStorage.getItem("customerOrderId")
        let orderNumber = localStorage.getItem("customerOrderNumber")
        let orederRevisionId = localStorage.getItem("revisionId")

        this.setState({
            orderId,
            orderNumber
        })

        this.getCustomers()
        this.getOrderStatus()
        this.getOrderRevisionDetails(orderId, orederRevisionId)

    }

    getOrderRevisionDetails = (id, rev_id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callOrderRevisionDetail(id, rev_id)
        .then(response => {
            let dd = response.deliveryDate;
            let deliveryDate = "";
            if(dd !== ""){
                 deliveryDate = new Date(dd);
            }

            let rfqDate = response.rfqDate;
            let newRfqDate = "";
            
            if(rfqDate !== undefined && rfqDate !== ""){
                newRfqDate = new Date(rfqDate);
            }
            
            localStorage.setItem('productGroupData', JSON.stringify(response.productGroup))
            localStorage.setItem("totalOfProductCost", response.total)
            localStorage.setItem("totalDiscountPrice", response.discountValue)
            localStorage.setItem("totalDiscountPercentage", response.discountPercentage)
            
            this.setState({ 
                isLoading: false,
                number: response.number.toUpperCase(),
                customer: response.customer,
                status: response.status,
                updatedStatus: response.status,
                deliverydate: deliveryDate,
                deliveryterms: response.deliveryTerms,
                reference: response.reference,
                shippingaddress: response.shippingAddress,
                internalNotes: response.internalNotes,
                Created: response.created,
                rfqDate: newRfqDate,
                rfqNo: response.rfqNo,
                poNo: response.poNo ? response.poNo : '',
                poDate: response.poDate ? new Date(response.poDate) : '',
                quotationValidityEnd: response.quotationValidityEnd ? new Date(response.quotationValidityEnd) : "",
                contactPerson: response.contactPerson,
                customerOrderNotes: response.notes,
                accountManagerDetail: response.accountManagerDetail ? response.accountManagerDetail : [],
                accountManager: response.accountManager ? response.accountManager : '',
                quotationMessageEditor: response.quotationMessage ? RichTextEditor.createValueFromString(response.quotationMessage, 'html') : RichTextEditor.createEmptyValue(),
                quotationMessageFooterEditor: response.quotationMessageFooter ? RichTextEditor.createValueFromString(response.quotationMessageFooter, 'html') : RichTextEditor.createEmptyValue()
            },()=>{
                this.getCustomerShippingAddress()
                this.getCustomerTaxDetails(response.customer)
                this.getContactPerson(response.customer)
                if(response.termsCondition !== undefined){
                    this.setTermsConditions(response.termsCondition)
                }else{
                    this.setTerms()
                }
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }
   
    getCustomers = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerList()
        .then(response => {
            this.setState({ 
                isLoading: false,
                customerList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors
            })
        });
    }

   

    getCustomerShippingAddress = () => {
        this.setState({ isLoading: true, error: false })
        let customer = this.state.customer
        APIManager.callCustomerShippingAddress(customer)
        .then(response => {
            this.setState({ 
                isLoading: false,
                shippingAddressList: response
            })
            if(response.length > 0) {
                this.setState({
                    shippingaddress: response[0].split('_').join(' ')
                })
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    getOrderStatus = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callOrderStatus()
        .then(response => {
            let orderStatus = response.status !== undefined ?  response.status : null
            if(orderStatus !== null){
                this.setState({
                    isLoading: false,
                    orderStatusList: orderStatus
                })
            }
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    getDeliveryTerms = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callDeliveryTermsList()
        .then(response => {
            this.setState({
                isLoading: false,
                deliveryTermsList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
            })
        });
    }

    hideStatusForm = () => {
        this.setState({
            viewAddStatusForm: false, 
            viewAddCustomerOrderForm: true 
        },()=>{
            this.getOrderStatus()
        })
    }

    

    getContactPerson = (cmpId) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callContactPerson(cmpId)
        .then(response => {
            this.setState({ 
                isLoading: false,
                contactPersonList: response
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors 
            })
        });
    }

    setTermsConditions = (resp) => {
        let counterArray = []
        let termFields = [];
        for(let i=0;i<resp.length;i++){
            counterArray.push(i)
            termFields.push(
                <AddTermsAndConditions 
                    key={i}
                    componentNum={i}
                    term={resp[i].terms}
                    condition={resp[i].options}
                    termsFor={"customerOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            )
        }

        this.setState({
            termsFields: termFields,
            countTermsNumber: counterArray,
            TermsCounter: resp.length,
            termsAndConditions: resp
        },()=>{
            this.setTerms()
        })
    }

    setTerms = () => {
        let counter = this.state.TermsCounter;
        this.setState({
            termsFields: this.state.termsFields.concat([
                <AddTermsAndConditions 
                    key={counter+1}
                    componentNum={counter+1}
                    term={""}
                    condition={""}
                    termsFor={"customerOrder"}
                    fun_termsFieldChange={
                        this.termsFieldChange
                    }
                    fun_termsFieldRemove={
                        this.termsFieldRemove
                    }
                />
            ]),
            countTermsNumber: this.state.countTermsNumber.concat([ counter+1 ]),
        },()=>{
            this.setState({
                TermsCounter: counter + 1
            })
        })
    }

 

    termsFieldChange = (term,condition,number) => {

        let array = [...this.state.countTermsNumber];
        let index = array.indexOf(number)
        let CIVal = [...this.state.termsAndConditions]        

        if (typeof CIVal[index] !== 'undefined') {
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                let termsAndConditions = [ ...this.state.termsAndConditions ];
                termsAndConditions[index] = obj;

                this.setState({
                    termsAndConditions,
                })
            }
        }else{
            if (condition !== "") {
                let obj = {
                    "terms": term,
                    "options": condition
                }
                this.setState({
                    termsAndConditions: this.state.termsAndConditions.concat([ obj ])
                },()=>{
                    this.setTerms()
                })
            }
        }
    }

    termsFieldRemove = (number) => {
        let array = [...this.state.countTermsNumber];
        let fields = [...this.state.termsFields];
        let data = [...this.state.termsAndConditions]
        let index = array.indexOf(number)

        if (index !== -1) {
            array[index] = null
            fields[index] = null;
            data[index] = null;
            // fields.splice(index, 1)
            this.setState({
                countTermsNumber: array,
                termsFields: fields,
                termsAndConditions: data
            });
        }
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    
    updateOrder = () => {
        if(this.state.fieldNotEditable !== true){
            let {
                orderId,
                customer,
                status,
                deliveryterms,
                quotationMessage,
                quotationMessageFooter,
                reference,
                shippingaddress,
                internalNotes,
                number,
                rfqDate,
                quotationValidityEnd,
                rfqNo,
                poNo,
                poDate,
                termsAndConditions,
                contactPerson,
                accountManager
            } = this.state

            let productGroupDataRaw = JSON.parse(localStorage.getItem('productGroupData')).slice(0, -1)
            let totalOfProductCost = localStorage.getItem("totalOfProductCost")
            let totalDiscountPrice = localStorage.getItem("totalDiscountPrice")
            let totalDiscountPercentage = localStorage.getItem("totalDiscountPercentage")


            if(productGroupDataRaw === null){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please fill required fields"
                },()=>{
                    this.scrollToTop()
                })
            }else{
                
            /* Remove Null From State */
            let productGroupData = [];
            productGroupDataRaw.forEach((val) => {
                if(val !== null){
                    productGroupData.push(val);
                }
            });
            /* Remove Null From State */

            /* GET OLDEST DATE FROM PRODUCT GROUP - CODE START */
            
            let groupDateArray = []
            let dt = "";
            let newDate = "";
            let oldestDate = "";

            //let currentDate = new Date();
            
            //currentDate = new Date(currentDate.setDate(currentDate.getDate() + 30))

            let cDate = new Date(new Date());
            let currentDate = new Date(cDate.setDate(cDate.getDate() + 30));
            
            var dd = String(currentDate.getDate()).padStart(2, '0');
            var mm = String(currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = currentDate.getFullYear();

            currentDate = mm + '/' + dd + '/' + yyyy;

            
            productGroupData.map((data) => {
                dt = data.deliverDate !== "" ? data.deliverDate.split("/") : currentDate.split("/")
                newDate = dt[0]+'/'+dt[1]+'/'+dt[2]
                groupDateArray.push(newDate)
            })

            try{
                oldestDate = groupDateArray.reduce(function (pre, cur) {
                    return Date.parse(pre) > Date.parse(cur) ? pre : cur;
                });
            }catch(e){
                let dt = currentDate.split("/")
                let newDate = dt[0]+'/'+dt[1]+'/'+dt[2]
                oldestDate = newDate;
            }


            /* GET OLDEST DATE FROM PRODUCT GROUP - CODE END */

            let deliverydate = oldestDate;

            if(customer === "" || status === "" || number === ""){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please fill required fields" 
                },()=>{
                    this.scrollToTop()
                })
            }else if(productGroupData.length === 0){
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: "Please add product details" 
                },()=>{
                    this.scrollToTop()
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateCustomerOrder(orderId,number,customer,status,deliverydate,deliveryterms,reference,shippingaddress,internalNotes,JSON.stringify(productGroupData),totalOfProductCost,totalDiscountPercentage,totalDiscountPrice,quotationMessage,quotationMessageFooter,convertDateToMMDDYYYY(rfqDate),convertDateToMMDDYYYY(quotationValidityEnd),rfqNo,poNo,convertDateToMMDDYYYY(poDate),JSON.stringify(termsAndConditions),contactPerson,accountManager)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        error: false,
                        success: true,
                        successmessage: response.message,
                        deliverydate: new Date(oldestDate),
                        fieldNotEditable: true,
                        updatedStatus: status 
                    },()=>{
                        this.scrollToTop()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }

        }
        }else{
            this.setState({
                fieldNotEditable: false,
                success: false,
                error: false,
            })
        }

    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteCustomerOrder(this.state.orderId)
            .then(response => {
                localStorage.removeItem('productGroupData')
                localStorage.removeItem("totalOfProductCost")
                localStorage.removeItem("totalDiscountPrice")
                localStorage.removeItem("totalDiscountPercentage")
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }

    downloadPDF = (status) => {
        if(this.state.status === status.toLowerCase())
        {
            localStorage.setItem("pdf_status_for_co",status)
            window.open('customerorderpdf')
        }
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    onQuotationMessageChange = (value) => {
        this.setState({
            quotationMessageEditor: value,
            quotationMessage: value.toString('html')
        });
    }

    onquotationMessageFooterChange = (value) => {
        this.setState({
            quotationMessageFooterEditor: value,
            quotationMessageFooter: value.toString('html')
        });
    }

    getCustomerDetails = (id) => {
        this.setState({ isLoading: true, error: false, setProductGroup: false, })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {

            localStorage.removeItem('productGroupData')
            localStorage.removeItem("totalOfProductCost")
            localStorage.removeItem("totalDiscountPrice")
            localStorage.removeItem("totalDiscountPercentage")
            localStorage.setItem('applicableTax',response.companyData.taxApplicable)
            
            this.setState({
                isLoading: false,
                setProductGroup: true,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors,
                setProductGroup: true 
            })
        });
    }

    getCustomerTaxDetails = (id) => {
        this.setState({ isLoading: true, error: false })
        APIManager.callCustomerSingleDetail(id)
        .then(response => {
            localStorage.setItem('applicableTax',response.companyData.taxApplicable)

            this.setState({
                isLoading: false,
                setProductGroup: true,
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: errors,
                setProductGroup: true 
            })
        });
    }

    editCustomerOrderNote = (data) => {
        localStorage.setItem("CONoteIsImp", data.is_important)
        localStorage.setItem("CONoteId", data.id)
        localStorage.setItem("CONote", data.note)
        this.props.history.push('/editconote')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render(){
        return(
        <div className="page absoluteWhite">
            <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
            <CRMHeader isQuotation={true}/>
            <div className="main-container">
                {
                    this.state.viewAddCustomerOrderForm ?
                    <div>
                        <div className="page-header mb-0">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="page-title">Quotation {this.state.orderNumber} details</div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="head-import-buttons float-right">
                                        {/* <div className={this.state.status === 'quotation' ? "button" : "button disabled"} onClick={()=>this.downloadPDF('Quotation')}>Quotation <ImArrowDown2 /></div>
                                        <div className={this.state.status === 'confirmed' ? "button" : "button disabled"} onClick={()=>this.downloadPDF('Confirmed')}>Confirmed <ImArrowDown2 /></div>
                                        <div className={this.state.status === 'performa invoice' ? "button" : "button disabled"} onClick={()=>this.downloadPDF('Invoice')}>Performa Invoice <ImArrowDown2 /></div> */}

                                        {/* <div className="button" onClick={()=>this.downloadPDF(this.state.orderId)}>PDF <ImArrowDown2 /></div> */}
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        {
                            this.state.error &&
                            <div className="editscreenerror">
                                <div className="errornotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                                </div>
                            </div>
                        }
                        {
                            this.state.success &&
                            <div className="editscreenerror">
                                <div className="successnotification">
                                    <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                                </div>
                            </div>
                        }

                        <div className="tableContent">
                            <div className="addSaveButton mb-20">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.updateOrder()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                                
                                <div className="clear"></div>
                            </div>
                            <div className="position-relative">
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Number*</div>
                                        <div className="value">
                                            <input type="text" value={this.state.number} onChange={(e)=>this.setState({ number: e.target.value })} className="fieldheightdecr"></input>
                                        </div>                       
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Customer*</div>
                                        <div className="value">
                                            <div className="float-left width90">
                                                <Select2    
                                                    data={
                                                        this.state.customerList.map((data) => (
                                                            { id: data._id, text: data.companyId+' '+data.companyName }
                                                        ))
                                                    }
                                                    value={this.state.customer}
                                                    options={{placeholder: 'Select company'}}
                                                    onSelect={(e)=> this.setState({ customer: e.target.value },()=>{
                                                        this.getCustomerDetails(e.target.value)
                                                        this.getCustomerShippingAddress()
                                                    })}
                                                />
                                            </div>
                                           
                                           
                                            <div className='width10 d-inline-block text-center secondary-color mt-1'><ImLink /></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Contact person</div>
                                        <div className="value">
                                            <Select2    
                                                className="width90"
                                                data={
                                                    this.state.contactPersonList.map((data) => (
                                                        { id: data._id, text: data.name }
                                                    ))
                                                }
                                                value={this.state.contactPerson}
                                                options={{placeholder: 'Select person'}}
                                                onSelect={(e)=> this.setState({ contactPerson: e.target.value })}
                                            />
                                        </div>                                                  
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Status*</div>
                                        <div className="value">
                                            <select value={this.state.status} onChange={(e)=>this.setState({ status: e.target.value })}>
                                                <option value="">Select</option>
                                                <option value="quotation">Quotation</option>
                                                <option value="waiting for confirmation">Waiting for confirmation</option>
                                                <option value="confirmed">Confirmed</option>
                                                <option value="waiting for production">Waiting for Production</option>
                                                <option value="in production">In Production</option>
                                                <option value="ready for shipment">Ready for Shipment</option>
                                                <option value="partially invoiced">Partially Invoiced</option>
                                                <option value="partially shipped">Partially Shipped</option>
                                                <option value="invoiced">Invoiced</option>
                                                <option value="shipped">Shipped</option>
                                                <option value="delivered">Delivered</option>
                                                <option value="archived">Archived</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    
                                    <div className="add_inner">
                                        <div className="title">Delivery date</div>
                                        <div className="value">
                                            <DatePicker 
                                                dateFormat="dd/MM/yyyy"
                                                minDate={new Date()}
                                                selected={
                                                    this.state.deliverydate
                                                }
                                                onChange={
                                                    date => this.setState({ deliverydate: date })
                                                }
                                                className="filterdateoption"/>
                                            <AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    
                                    {/* <div className="add_inner">
                                        <div className="title">Delivery terms</div>
                                        <div className="value">
                                            <select value={this.state.deliveryterms} onChange={(e)=>this.setState({ deliveryterms: e.target.value })}>
                                                <option value='Ex-works'>Ex-works</option>
                                                <option value='C and F'>C and F</option>
                                                <option value='CIF'>CIF</option>
                                            </select>    
                                        </div>
                                        <div className="clear"></div>
                                    </div> */}
                                    
                                    <div className="add_inner">
                                        <div className="title">Shipping address</div>
                                        <div className="value">
                                            <select className='text-capitalize' value={this.state.shippingaddress} onChange={(e)=>this.setState({ shippingaddress: e.target.value })}>
                                                {
                                                    this.state.shippingAddressList.map((data) =>(
                                                        <option key={'address_'+data} value={data.split('_').join(' ')}>{data.split('_').join(' ')}</option>
                                                    ))
                                                } 
                                            </select>
                                        </div>                                                  
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Valid till</div>
                                        <div className="value">
                                            <DatePicker
                                            dateFormat="dd/MM/yyyy" 
                                            minDate={new Date()}
                                            selected={
                                                this.state.quotationValidityEnd
                                            }
                                            onChange={
                                                date => this.setState({ quotationValidityEnd: date })
                                            }
                                            className="filterdateoption"/>
                                            <AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    
                                </div>
                                <div className="add_partside">
                                    <div className="add_inner">
                                        <div className="title">Created</div>
                                        <div className="value">
                                            <div className="createdText">{this.state.Created}</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Account Manager</div>
                                        <div className="value">
                                            <Select2    
                                                className="width90 text-capitalize"
                                                data={
                                                    this.state.accountManagerDetail.map((data) => (
                                                        { id: data.userId, text: data.userName }
                                                    ))
                                                }
                                                value={this.state.accountManager}
                                                options={{placeholder: 'Select person'}}
                                                onSelect={(e)=> this.setState({ accountManager: e.target.value })}
                                            />
                                        </div>                                                  
                                        <div className="clear"></div>
                                    </div>
                                    <div className="add_inner">
                                        <div className="title">Reference</div>
                                        <div className="value"><input type="text" value={this.state.reference} onChange={(e)=>this.setState({ reference: e.target.value })} className="fieldheightdecr"></input></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">RFQ No.</div>
                                        <div className="value"><input type="text" value={this.state.rfqNo} onChange={(e)=>this.setState({ rfqNo: e.target.value })} className="fieldheightdecr"></input></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">RFQ Date</div>
                                        <div className="value">
                                            <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.rfqDate
                                            }
                                            onChange={
                                                date => this.setState({ rfqDate: date })
                                            }
                                            className="filterdateoption"/>
                                            <AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">PO No.</div>
                                        <div className="value"><input type="text" value={this.state.poNo} onChange={(e)=>this.setState({ poNo: e.target.value })} className="fieldheightdecr"></input></div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="add_inner">
                                        <div className="title">PO Date</div>
                                        <div className="value">
                                            <DatePicker 
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            selected={
                                                this.state.poDate
                                            }
                                            onChange={
                                                date => this.setState({ poDate: date })
                                            }
                                            className="filterdateoption"/>
                                            <AiOutlineCalendar className="datepickerIcon cursor-na"/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    {/* <div className="add_inner">
                                        <div className="title">Internal notes</div>
                                        <textarea value={this.state.internalNotes} onChange={(e)=>this.setState({ internalNotes: e.target.value })} className="value __customer_order_note_field"></textarea>
                                        <div className="clear"></div>
                                    </div> */}
                                    
                                </div>
                                <div className="clear"></div>
                                <div className="add_outer_full_part">
                                    <div className="title">Quotation Message</div>
                                    <div>
                                        <RichTextEditor
                                            value={this.state.quotationMessageEditor}
                                            onChange={this.onQuotationMessageChange} 
                                            rootStyle={{ minHeight: 170}}
                                        />
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                                }
                            </div>

                            <div className="position-relative">
                                {
                                    this.state.setProductGroup &&
                                        <ProductGroup />
                                }
                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                                }
                            </div>
                            {/* TERMS & CONDTIONS FIELD */}
                            <div className="__terms_and_conditions position-relative">
                                <div className="__terms_left_header">
                                    <div className="__terms_content_title">{'Terms & conditions'}</div>
                                </div>
                                <div className="__terms_right_content">
                                    { this.state.termsFields }
                                </div>
                                {
                                    this.state.fieldNotEditable &&
                                    <div className="disableFieldCover"></div>
                                }
                                <div className="clear"></div>
                            </div>

                            <div className='__terms_and_conditions position-relative'>
                                <div className="add_outer_full_part">
                                    <div className="title">Quotation footer</div>
                                    <div>
                                        <RichTextEditor
                                            value={this.state.quotationMessageFooterEditor}
                                            onChange={this.onquotationMessageFooterChange} 
                                            rootStyle={{ minHeight: 170 }}
                                        />
                                    </div>
                                    {
                                        this.state.fieldNotEditable &&
                                        <div className="disableFieldCover"></div>
                                    }
                                    <div className="clear"></div>
                                </div>
                            </div>

                            <div className="addSaveButton">
                                <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                                <div className="add_btnSave" onClick={()=>this.updateOrder()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                                
                                <div className="clear"></div>
                            </div>
                        </div>
                       

                       


                </div>
                :
                this.state.viewAddStatusForm ?
                    <div>
                        <AddEditOrderStatus 
                            backToOriginalForm={()=>this.hideStatusForm()} 
                        />
                    </div>
                :
                null
                }
                
                {
                    this.state.isLoading &&
                    <Loading />
                }
                        
                </div>
            </div>
        )
    }
}