import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../components'
import Strings from './../../../constants/Strings'

import { 
    ImArrowDown2, 
} from "react-icons/im";


class CashFlow extends Component {
    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCashflow={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-3 col-md-8 col-sm-8 col-xs-12">
                                <div className="page-title">{Strings.CRM.CashFlow}</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-select-box">
                                    <select>
                                        <option value="invoices">Invoices</option>
                                        <option value="items">Items</option>
                                    </select>
                                    <input type="date"></input> - 
                                    <input type="date"></input>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={()=>this.exportOptions('pdf')}>PDF <ImArrowDown2 /></div>
                                    <div className="button" onClick={()=>this.exportOptions('csv')}>CSV <ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                {/* <div className="pagecontent">
                    <div className="headerArea">
                        <div className="pageTitle heading cashflow">{Strings.CRM.CashFlow}</div>
                        <div className="selectbox cashflow">
                            <select>
                                <option>Period</option>
                                <option>Today</option>
                                <option>Yesterday</option>
                                <option>Tomorrow</option>
                                <option>This week</option>
                                <option>Last week</option>
                                <option>Next week</option>
                                <option>Last month</option>
                                <option>This month</option>
                                <option>Next month</option>
                                <option>Last month</option>
                                <option>This month</option>
                                <option>Next month</option>
                                <option>This year</option>
                                <option>Last year</option>
                                <option>Last 12 months</option>
                            </select>
                            <input type="date"></input> - 
                            <input type="date"></input>
                        </div>
                        <div className="otherButtons">
                            <div className="oButton thr">PDF <ImArrowDown2 className="downIcon"/></div>
                            <div className="oButton fou">CSV <ImArrowDown2 className="downIcon"/></div>
                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div> */}

                <div className="tableContent mh400" style={{ overflowX : "auto" }}>
                    <table>
                        <thead>
                        <tr>
                            <th className="tableHeader">Week</th>
                            <th className="tableHeader">Sales invoices</th>
                            <th className="tableHeader">Incoming payments</th>
                            <th className="tableHeader">Incoming contact</th>
                            <th className="tableHeader">Outgoing payments</th>
                            <th className="tableHeader">Cash flow forecast</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className="tableHeader">03/07/2021 - 03/13/2021</th>
                            <th className="tableHeader">$ 0.00</th>
                            <th className="tableHeader">$ 0.00</th>
                            <th className="tableHeader">$ 30.00</th>
                            <th className="tableHeader">$ 0.00</th>
                            <th className="tableHeader">$ -30.00</th>
                        </tr>

                        <tr>
                            <th className="tableHeader">03/14/2021 - 03/20/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>
                        <tr>
                            <th className="tableHeader">03/14/2021 - 03/20/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>		
                        <tr>
                            <th className="tableHeader">03/21/2021 - 03/27/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>					
                        <tr>
                            <th className="tableHeader">03/28/2021 - 04/03/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>		
                        <tr>
                            <th className="tableHeader">04/04/2021 - 04/10/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>				
                        <tr>
                            <th className="tableHeader">04/11/2021 - 04/17/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>					
                        <tr>
                            <th className="tableHeader">04/18/2021 - 04/24/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>					
                        <tr>
                            <th className="tableHeader">04/25/2021 - 05/01/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>					
                        <tr>
                            <th className="tableHeader">05/02/2021 - 05/08/2021	</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>						
                        <tr>
                            <th className="tableHeader">05/09/2021 - 05/15/2021</th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                            <th className="tableHeader"></th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        );
    }
}

export default CashFlow;