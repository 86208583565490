import React from 'react';
import { Header, CRMHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class CreateContactNotes extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            companyId: "",
            companyNum: "",
            companyName: "",
           
        }
      
    }

    componentDidMount(){
        let cmpId = localStorage.getItem("cmpId")
        let cmpIdNum = localStorage.getItem("cmpIdNum")
        let cmpName = localStorage.getItem("cmpName")

        this.setState({
            companyId: cmpId,
            companyNum: cmpIdNum,
            companyName: cmpName,
            isLoading: false,
        })
    }

    createNotes = () => {
        let {
            companyId,
            note
        } = this.state
        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callAddNotes(companyId,note)
            .then(response => {
                this.setState({ 
                    isLoading: false
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                })
            });   
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

   
   
    render(){
        return(
            <div className="page absoluteWhite">
               <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true}/>
                
                    <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Create a note</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Company </div>
                                <div className="value companyname">{this.state.companyNum.toUpperCase()} {this.state.companyName}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })}
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                           
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.createNotes()}>Save</div>
                        <div className="clear"></div>
                    </div>
                </div>
                {
                    this.state.isLoading &&
                    <Loading />
                }
            </div>
            </div>
        )
    }
}