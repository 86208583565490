import React from 'react';
import { Header } from './../../components';

import * as APIManager from './../../APIManager/APIManager'

import Loading from './../../helper/loading'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditMPPNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",   

            MO_Id: this.props.location.state.MO_Id,
            MO_No: this.props.location.state.MO_No,
            Note_Id: this.props.location.state.Note_Id, 
            isNewEntry: this.props.location.state.newNoteEntry,
            note: this.props.location.state.Note 
        }
    }

    componentDidMount(){
       
    }

    updateNotes = () => {
        let {
            MO_Id,
            Note_Id,
            note
        } = this.state

        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callUpdateMONote(MO_Id, Note_Id, note)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    success: true,
                    error: false,
                    successmessage: "Note updated successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }


    deleteEntry = () => {
        let {
            MO_Id,
            Note_Id
        } = this.state
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteMONote(MO_Id, Note_Id)
            .then(response => {
                this.setState({ 
                    isLoading: false
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors.message 
                })
            });
        }
    }
    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }  

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isMyProductionPlan={true} />

                <div className="main-container">

                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="page-title">Edit a note</div>
                        </div>
                    </div>
                </div>

                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Order No </div>
                                <div className="value companyname text-uppercase">{this.state.MO_No}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                            <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })}
                                    />
                                </div> 
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>Save</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}