import React, { Component } from 'react';
import { Header, ProcurementHeader } from './../../../components'
import Strings from './../../../constants/Strings'
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import './../../../lib/react-datepicker.min.css';
import * as Im from 'react-icons/im'
import * as Fi from 'react-icons/fi'
import * as Ai from "react-icons/ai"
import * as Bi from "react-icons/bi"
import * as ExportData from './exportData'
import * as APIManager from './../../../APIManager/APIManager'
import TableLoading from '../../../helper/TableLoading';
import { convertDateStringMMDDtoDDMM, convertDateToMMDDYYYY, convertDateToMMDDYYYYWithoutZero } from '../../../constants/DateFormatter';

class DebitNote extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            debitNoteList: [],

            number: "",
            invoiceNo: "",
            purchaseOrderNumber: "",
            vendorName: "",
            minRejectedQty: "",
            maxRejectedQty: "",
            minSubTotal: "",
            maxSubTotal: "",
            minTotal: "",
            maxTotal: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            minModifiedDate: "",
            maxModifiedDate: "",

            fieldName: '',
            fieldType: '',
            sortVal: false,

            start: 0,
            length: 25,
            activePage: 1,
            totalRecords: 0,
        }
    }

    componentDidMount() {
        this.getFilterData()
    }

    //******************* ascending descending order function ********************************
    sortCustomer = (fieldName, fieldType, onload) => {

        let sortVal = this.state.sortVal;
        if (!onload) {
            if (this.state.fieldName === fieldName) {
                sortVal = !this.state.sortVal;
            }
        }
        this.setState({
            fieldName: fieldName,
            fieldType: fieldType,
            sortVal: sortVal
        }, () => {
            console.log(this.state.debitNoteList);
            const sortedOrders = this.state.debitNoteList.sort((a, b) => {

                if (fieldType === "Date") {
                    const dateA = a[fieldName] ? new Date(a[fieldName]) : null;
                    const dateB = b[fieldName] ? new Date(b[fieldName]) : null;

                    if (!dateA && !dateB) {
                        return 0;
                    } else if (!dateA) {
                        return sortVal ? 1 : -1;
                    } else if (!dateB) {
                        return sortVal ? -1 : 1;
                    }
                    if (dateA.getFullYear() !== dateB.getFullYear()) {
                        return sortVal ? dateA.getFullYear() - dateB.getFullYear() : dateB.getFullYear() - dateA.getFullYear();
                    }
                    if (dateA.getMonth() !== dateB.getMonth()) {
                        return sortVal ? dateA.getMonth() - dateB.getMonth() : dateB.getMonth() - dateA.getMonth();
                    }
                    if (dateA.getDate() !== dateB.getDate()) {
                        return sortVal ? dateA.getDate() - dateB.getDate() : dateB.getDate() - dateA.getDate();
                    }
                    return 0;
                }
                else if (!isNaN(a[fieldName]) && !isNaN(b[fieldName])) {
                    // console.log('NotDate success');
                    const numA = parseFloat(a[fieldName]);
                    const numB = parseFloat(b[fieldName]);
                    if (sortVal) {
                        return numA > numB ? 1 : -1;
                    } else {
                        return numA < numB ? 1 : -1;
                    }
                } else {
                    const strA = a[fieldName] != null ? a[fieldName].toString() : '';
                    const strB = b[fieldName] != null ? b[fieldName].toString() : '';
                    if (sortVal) {
                        return strA.localeCompare(strB, undefined, { sensitivity: 'accent' });
                    } else {
                        return strB.localeCompare(strA, undefined, { sensitivity: 'accent' });
                    }
                }
            });
            this.setState({ debitNoteList: sortedOrders }, () => {
                this.setFilterData()
            });
        });

    };
    //******************* ascending descending order function end ********************************
    getAllDebitNotes = () => {
        this.setState({ isLoading: true, error: false })
        let {
            start,
            length,
            number,
            invoiceNo,
            purchaseOrderNumber,
            vendorName,
            minRejectedQty,
            maxRejectedQty,
            minSubTotal,
            maxSubTotal,
            minTotal,
            maxTotal,
            minInvoiceDate,
            maxInvoiceDate,
            minModifiedDate,
            maxModifiedDate
        } = this.state
        APIManager.callGetAllDebitNote(start, length, number, invoiceNo, purchaseOrderNumber, vendorName, minRejectedQty, maxRejectedQty, minSubTotal, maxSubTotal, minTotal, maxTotal, minInvoiceDate, maxInvoiceDate, minModifiedDate, maxModifiedDate)
            .then(response => {
              
                this.setState({
                    isLoading: false,
                    debitNoteList: response.data,
                    totalRecords: response.totalRecords
                }, () => {
                    if(this.state.fieldName){
                        this.sortCustomer(this.state.fieldName, this.state.fieldType, true)
                    }
                })
            })
            .catch(errors => {
                this.setState({
                    isLoading: false,
                })
            });
    }


    setFilterData = () => {

        let filterDataArray = {
            'number': this.state.number,
            'invoiceNo': this.state.invoiceNo,
            'purchaseOrderNumber': this.state.purchaseOrderNumber,
            'vendorName': this.state.vendorName,
            'minRejectedQty': this.state.minRejectedQty,
            'maxRejectedQty': this.state.maxRejectedQty,
            'minSubTotal': this.state.minSubTotal,
            'maxSubTotal': this.state.maxSubTotal,
            'minTotal': this.state.minTotal,
            'maxTotal': this.state.maxTotal,
            'minInvoiceDate': this.state.minInvoiceDate,
            'maxInvoiceDate': this.state.maxInvoiceDate,
            'minModifiedDate': this.state.minModifiedDate,
            'maxModifiedDate': this.state.maxModifiedDate,
    
            'fieldName': this.state.fieldName,
            'fieldType': this.state.fieldType,
            'sortVal': this.state.sortVal,
            'activePage': this.state.activePage,
            'start': this.state.start,
            'length': this.state.length,
        }
        localStorage.setItem("debitNotesAk", JSON.stringify(filterDataArray))
    }
    
    
    getFilterData = () => {
        let filterData = JSON.parse(localStorage.getItem("debitNotesAk"))
        if (filterData !== null) {
            this.setState({
                activePage: parseInt(filterData.activePage),
                start: filterData.start,
                length: filterData.length,
                number: filterData.number,
                invoiceNo : filterData.invoiceNo,
                purchaseOrderNumber : filterData.purchaseOrderNumber,
                vendorName : filterData.vendorName,
                minRejectedQty : filterData.minRejectedQty,
                maxRejectedQty : filterData.maxRejectedQty,
                minSubTotal : filterData.minSubTotal,
                maxSubTotal : filterData.maxSubTotal,
                minTotal : filterData.minTotal,
                maxTotal : filterData.maxTotal,
                minInvoiceDate : filterData.minInvoiceDate,
                maxInvoiceDate : filterData.maxInvoiceDate,
                minModifiedDate : filterData.minModifiedDate,
                maxModifiedDate : filterData.maxModifiedDate,
    
                fieldName: filterData.fieldName ? filterData.fieldName : '',
                fieldType: filterData.fieldType ? filterData.fieldType : '',
                sortVal: filterData.sortVal ? filterData.sortVal : false,
            }, () => {
                this.getAllDebitNotes()
            })
        } else {
            this.getAllDebitNotes()
        }
    }


    createDebitNote = () => {
        let slug = "Procurement_DebitNote"
        let permission = "create"
        if (APIManager.checkUserPermission(slug, permission)) {
            this.props.history.push({
                pathname: "/createdebitnote",
                state: { poId: "", isFromPO: false }
            })
        } else {
            alert("You don't have permission to " + permission + " this record")
        }
    }

    editDebitNote = (id) => {
        this.props.history.push({
            pathname: "/editdebitnote",
            state: { debitNoteId: id, isNewEntry: false }
        })
    }

    handlePageChange(pageNumber) {
        let length = this.state.length;
        let newStart = pageNumber !== 1 ? length * (pageNumber - 1) : 0

        this.setState({
            activePage: pageNumber,
            start: newStart
        }, () => {
            this.getAllDebitNotes()
        });
    }

    clearAllFilters = () => {
        this.setState({
            number: "",
            invoiceNo: "",
            purchaseOrderNumber: "",
            vendorName: "",
            minRejectedQty: "",
            maxRejectedQty: "",
            minSubTotal: "",
            maxSubTotal: "",
            minTotal: "",
            maxTotal: "",
            minInvoiceDate: "",
            maxInvoiceDate: "",
            minModifiedDate: "",
            maxModifiedDate: "",
            start: 0,
            length: 25,
        }, () => {
            this.getAllDebitNotes()
        })
    }
 
    exportOptions = (type) => {
        let { debitNoteList } = this.state
        if (type === "pdf") {
            ExportData.exportInvoicePDF(debitNoteList);
        } else {
            ExportData.exportInvoiceCSV(debitNoteList);
        }
    }

    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isProcurement={true} />
                <ProcurementHeader isDebitNote={true} />
                <div className="main-container">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6 col-sm-8 col-md-8 col-xs-12">
                                <div className="page-title">Debit Notes</div>
                            </div>
                            <div className="col-lg-6 col-md-8 col-sm-8 col-xs-12">
                                <div className="head-import-buttons float-right">
                                    <div className="button" onClick={() => this.exportOptions('pdf')}>PDF <Im.ImArrowDown2 /></div>
                                    <div className="button" onClick={() => this.exportOptions('csv')}>CSV <Im.ImArrowDown2 /></div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tableContent mh400" style={{ overflowX: "auto" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.createDebitNote()}><Fi.FiPlus /></div></th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('number', 'notDate', false)}><span>Number</span> {this.state.fieldName === "number" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "number" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('invoiceNumber', 'notDate', false)}><span>Invoice Number</span> {this.state.fieldName === "invoiceNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('purchaseOederNumber', 'notDate', false)}><span>Purchase Order</span> {this.state.fieldName === "purchaseOederNumber" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "purchaseOederNumber" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('vendorName', 'notDate', false)}><span>Vendor</span> {this.state.fieldName === "vendorName" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "vendorName" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('rejectedQuantity', 'notDate', false)}><span>Debit Qty</span> {this.state.fieldName === "rejectedQuantity" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "rejectedQuantity" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('grandSubTotal', 'notDate', false)}><span>Subtotal</span> {this.state.fieldName === "grandSubTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandSubTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('grandTotal', 'notDate', false)}><span>Total</span> {this.state.fieldName === "grandTotal" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "grandTotal" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('invoiceDate', 'Date', false)}><span>Invoice Date</span> {this.state.fieldName === "invoiceDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "invoiceDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>
                                    <th className="tableHeader" onClick={() => this.sortCustomer('modifiedDate', 'Date', false)}><span>Modified Date</span> {this.state.fieldName === "modifiedDate" && this.state.sortVal && <Fi.FiArrowUp />}{this.state.fieldName === "modifiedDate" && !this.state.sortVal && <Fi.FiArrowDown />}</th>

                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.createDebitNote()}><Fi.FiPlus /></div></th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value }, () => { this.getAllDebitNotes() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.invoiceNo} onChange={(e) => this.setState({ invoiceNo: e.target.value }, () => { this.getAllDebitNotes() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.purchaseOrderNumber} onChange={(e) => this.setState({ purchaseOrderNumber: e.target.value }, () => { this.getAllDebitNotes() })} /></th>
                                    <th className='tableElemContain'><input type="text" value={this.state.vendorName} onChange={(e) => this.setState({ vendorName: e.target.value }, () => { this.getAllDebitNotes() })} /></th>

                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minRejectedQty} placeholder="min" onChange={(e) => this.setState({ minRejectedQty: e.target.value }, () => { this.getAllDebitNotes() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxRejectedQty} placeholder="max" onChange={(e) => this.setState({ maxRejectedQty: e.target.value }, () => { this.getAllDebitNotes() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minSubTotal} placeholder="min" onChange={(e) => this.setState({ minSubTotal: e.target.value }, () => { this.getAllDebitNotes() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxSubTotal} placeholder="max" onChange={(e) => this.setState({ maxSubTotal: e.target.value }, () => { this.getAllDebitNotes() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <input type="text" className="width80" value={this.state.minTotal} placeholder="min" onChange={(e) => this.setState({ minTotal: e.target.value }, () => { this.getAllDebitNotes() })} />&nbsp;-&nbsp;
                                        <input type="text" className="width80 mt-1" value={this.state.maxTotal} placeholder="max" onChange={(e) => this.setState({ maxTotal: e.target.value }, () => { this.getAllDebitNotes() })} />
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxInvoiceDate}
                                                selected={this.state.minInvoiceDate}
                                                onChange={(date) => this.setState({ minInvoiceDate: date }, () => { this.getAllDebitNotes() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minInvoiceDate}
                                                selected={this.state.maxInvoiceDate}
                                                onChange={(date) => this.setState({ maxInvoiceDate: date }, () => { this.getAllDebitNotes() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableElemContain" style={{ width: 150 }}>
                                        <div className="value position-relative">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={this.state.maxModifiedDate}
                                                selected={this.state.minModifiedDate}
                                                onChange={(date) => this.setState({ minModifiedDate: date }, () => { this.getAllDebitNotes() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                        <div className="value position-relative mt-1">
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                minDate={this.state.minModifiedDate}
                                                selected={this.state.maxModifiedDate}
                                                onChange={(date) => this.setState({ maxModifiedDate: date }, () => { this.getAllDebitNotes() })}
                                                className="filterdateoption" />
                                            <Ai.AiOutlineCalendar className="datepickerIcon" />
                                        </div>
                                    </th>
                                    <th className="tableHeader tableHeadButton"><div onClick={() => this.clearAllFilters()}><Fi.FiX /></div></th>
                                </tr>



                            </thead>
                            {
                                this.state.isLoading ?
                                    <TableLoading tr={8} td={11} />
                                    :
                                    <tbody>
                                        {
                                            this.state.debitNoteList.map((data, i) => (
                                                <tr key={data._id} onClick={() => this.editDebitNote(data._id)}>
                                                    <td>{this.state.activePage === 1 ? 1 + i : this.state.start + 1 + i}</td>
                                                    <td className="text-uppercase">{data.number}</td>
                                                    <td className="text-uppercase">{data.invoiceNumber}</td>
                                                    <td className="text-uppercase">{data.purchaseOederNumber}</td>
                                                    <td className="text-capitalize">{data.vendorName}</td>
                                                    <td>{data.rejectedQuantity}</td>
                                                    <td>{data.grandSubTotal.toFixed(2)} <Bi.BiRupee /></td>
                                                    <td>{data.grandTotal.toFixed(2)} <Bi.BiRupee /></td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.invoiceDate)}</td>
                                                    <td>{convertDateStringMMDDtoDDMM(data.modifiedDate)}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            }
                        </table>
                        <div>
                            <div className="float-left mt-10px">
                                <div className="tableElemContain" style={{ width: 80 }}>
                                    <select value={this.state.length} onChange={(e) => this.setState({ length: e.target.value }, () => { this.handlePageChange(1) })}>
                                        <option value="25">25 rows</option>
                                        <option value="50">50 rows</option>
                                        <option value="100">100 rows</option>
                                        <option value="">All rows</option>
                                    </select>
                                </div>
                            </div>
                            <div className="float-right mt-10px">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.length ? parseInt(this.state.length) : parseInt(this.state.totalRecords)}
                                    totalItemsCount={this.state.totalRecords}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DebitNote;