import React from 'react';

import { Header, ProductionPlanningHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Loading from './../../../helper/loading'
//import './../../../../style.css'

import {
    AiOutlineExclamation
} from "react-icons/ai";

export default class EditWorkstationNote extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",   

            note: "",
            noteId: "",
            workstationId: "",
            workstationNum: "",
            workstationName: "",

            isNewEntry: false,

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let id = this.props.location.state.workstationId
        let num = this.props.location.state.workstationNumber
        let name = this.props.location.state.workstationName
        let noteId = this.props.location.state.workstationNoteId
        let note = this.props.location.state.workstationNote
        let newEntry = this.props.location.state.newWorkstationNoteEntry

        this.setState({
            workstationId: id,
            workstationNum: num,
            workstationName: name,
            note: note,
            noteId: noteId,
            isNewEntry: newEntry,
        })
    }

    updateNotes = () => {
        let { workstationId, noteId, note, fieldNotEditable } = this.state
        if(fieldNotEditable === false){
            if(note === ""){
                this.setState({
                    errormessage: "Please add note",
                    error: true,
                    success: false,
                })
            }else{
                this.setState({ isLoading: true, error: false })
                APIManager.callUpdateWorkstationNote(noteId, workstationId, note)
                .then(response => {
                    this.setState({ 
                        isLoading: false,
                        success: true,
                        error: false,
                        fieldNotEditable: true,
                        successmessage: "Note updated successfully"
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors 
                    })
                });
            }
        }else{
            this.setState({ 
                fieldNotEditable: false,
            })
        }
    }

    deleteEntry = () => {
        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
            this.setState({ isLoading: true, error: false })
            APIManager.callDeleteWorkstationNote(this.state.noteId, this.state.workstationId)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.goBack()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors 
                })
            });
        }
    }


    goBack = () => {
        if(this.state.isNewEntry){
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }
    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isProductionPlanning={true} />
                <ProductionPlanningHeader isWorkstations={true} />
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Edit a note</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Workstation </div>
                                <div className="value companyname"><span className="text-uppercase">{this.state.workstationNum}</span> <span className="text-capitalize">{this.state.workstationName}</span></div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                            <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        value={this.state.note}
                                        onChange={(e)=>this.setState({ note: e.target.value })} 
                                    />
                                </div> 
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteEntry()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}