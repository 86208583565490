import React, { Component } from 'react';

class SetBookingItemQuantity extends Component {

    constructor(props){
        super(props)
        this.state = {
            totalQuantity: this.props.d.quantity,
            quantity: this.props.qty,
            d: this.props.d,
            data: this.props.data,
            updateObjectValue: this.props.funcSetNewObject,
            removeObjectValue: this.props.funcRemoveObject,
            number: this.props.number,
            bookingObject: {
                "stockLot": "",
                "status": "", 
                "date": "", 
                "storageLocation": "", 
                "costPerUnit": "", 
                "quantity": "",
                "booked": ""
            }
        }
    }

    checkQuantity = (val) => {
        let value = val >= 0 ? val : 0
        if(value === "" || value <= parseFloat(this.state.totalQuantity))
            this.setState({
                quantity: value
            })   
    }

    updateObject = () => {
        let bookingObject = this.state.bookingObject
        if(this.state.quantity !== ""){
            bookingObject.stockLot = this.state.data._id
            bookingObject.status = this.state.data.status
            bookingObject.date = this.state.data.availableFrom
            bookingObject.storageLocation = this.state.d.storageLocationId 
            bookingObject.costPerUnit = this.state.data.costPerUnit
            bookingObject.quantity = this.state.d.quantity
            bookingObject.booked = this.state.quantity

            this.setState({ bookingObject },()=>{ 
                this.state.updateObjectValue(bookingObject, this.state.number)
            })
        }else{
            this.state.removeObjectValue(this.state.number)
        }
    }

    render() {
        let {
            data,
            uom,
            d
        } = this.props
        return (
            <tr>
                <td>{data.number}</td>
                <td>{data.status}</td>
                <td>{data.availableFrom}</td>
                <td>{d.storageLocationName}</td>
                <td>{data.costPerUnit}</td>
                <td>{d.quantity} {uom}</td>
                <td style={{ width: 120 }}>
                    <input 
                        type="text" 
                        className="input-box" 
                        value={this.state.quantity} 
                        onBlur={()=>this.updateObject()} 
                        onChange={(e)=>this.checkQuantity(e.target.value)} 
                    />
                </td>
                <td>{uom}</td>
            </tr>
        );
    }
}

export default SetBookingItemQuantity;