import React from 'react';

import { Header, CRMHeader } from './../../../components'
import Helper from './../../../helper/helper'
import Loading from './../../../helper/loading'
//import './../../../../style.css'

import {
    AiOutlineExclamation
} from "react-icons/ai";
import { WEBSERVICES_URL } from '../../../APIManager/APIManager';

export default class EditContactNotes extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",   

            companyId: "",
            companyNum: "",
            companyName: "",
            customerNoteId: "",
            customerNote: "",

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let cmpId = localStorage.getItem("cmpId")
        let cmpIdNum = localStorage.getItem("cmpIdNum")
        let cmpName = localStorage.getItem("cmpName")

        let customerNoteId = localStorage.getItem("customerNoteId")
        let customerNote = localStorage.getItem("customerNote")

        this.setState({
            companyId: cmpId,
            companyNum: cmpIdNum,
            companyName: cmpName,
            customerNoteId: customerNoteId,
            customerNote: customerNote,
            loading: false,
        },()=>{
            this.note.value = customerNote;
        })
    }

    updateNotes = () => {

        if(this.state.fieldNotEditable !== true){
        let note = this.note.value;
        this.setState({ loading: true })
        const that = this;

        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("companyId", this.state.companyId)
            formdata.append("id", this.state.customerNoteId)
            formdata.append("note", note)
            
            fetch(WEBSERVICES_URL+'crm/customer/updateNote',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        that.setState({ loading: false },()=> {
                            that.goBack()
                        })

                   }else if(result.status === "userDataError"){
                        that.setState({ loading: false },()=> {
                            that.logout()
                        })
                   }else{
                        that.setState({
                            loading: false,
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            )
        }
        }else{
            this.setState({
                fieldNotEditable: false,
            })
        }
    }


    deleteNotes = () => {

        var r = window.confirm("Are you sure you want to delete this entry?");
        if (r === true) {
           
            this.setState({ loading: true })
            const that = this;

       
            let getUserToken = localStorage.getItem("userdata")
            let token = JSON.parse(getUserToken).login_token

            let myHeaders = new Headers();
            myHeaders.append("token", token);

            let formdata = new FormData();
            formdata.append("companyId", this.state.companyId)
            formdata.append("id", this.state.customerNoteId)
            
            fetch(WEBSERVICES_URL+'crm/customer/deleteNote',{
                method: "post",
                headers: myHeaders,
                body: formdata,
            })
                .then(res => res.json())
                .then((result) => {
                   if(result.status === "success"){
                        that.setState({ loading: false },()=> {
                            that.goBack()
                        })

                   }else if(result.status === "userDataError"){
                        that.setState({ loading: false },()=> {
                            that.logout()
                        })
                   }else{
                        that.setState({
                            loading: false,
                            error: true,
                            errormessage: result.message
                        })
                   }

                },(error) => {
                   console.log(error)
                }
            )
        }
    }
    
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isCRM={true} />
                <CRMHeader isCustomers={true}/>
                <div className="main-container">

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Edit a note</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    {
                        this.state.loading &&
                        <Loading />
                    }
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteNotes()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className="position-relative">
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Company </div>
                                <div className="value companyname">{this.state.companyNum.toUpperCase()} {this.state.companyName}</div>
                                <div className="clear"></div>
                            </div>
                            <div className="add_inner">
                            <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        ref={(c) => this.note = c} 
                                    />
                                </div> 
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateNotes()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteNotes()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}