import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';
import Select2 from './../../../../lib/select2/select2';
import './../../../../lib/select2/select2.css';
import * as Ai from 'react-icons/ai'
import * as Fi from 'react-icons/fi'

class AddExpenseDetails extends Component {

    constructor(props){
        super(props)
        this.state = {

            onBlurCall: this.props.onBlurProduct,
            count: this.props.counter,
            isGst: this.props.isGst,
            expenseCategoryData: this.props.expenseCategoryData,

            productObj: {
                'expensedesc': '',
                'hsncode': '',
                'amount': '',
                'tax': '',
                'sgstamount': '',
                'cgstamount': '',
                'igstamount': '', 
                'total': '',
                'expensedate': '',
                'eligibleTax': '',
                'expenseGroup': ''
            },

            expensedesc: "",
            hsncode: "",
            amount: "",
            tax: "",
            sgstamount: "",
            cgstamount: "",
            igstamount: "",
            total: "",
            expensedate: "",
            eligibleTax: "",
            expenseCategory: "",
        }
    }


    countAmount = () => {
        let {
            amount,
            tax
        } = this.state
        if(amount !== ""){
            if(tax !== ""){
                let taxAmount = parseFloat(amount) * parseFloat(tax) / 100
                let total = parseFloat(amount) + parseFloat(taxAmount)
                // if(eligibleTax !== "" && (eligibleTax === "ineligible section 17(5)" || eligibleTax === "ineligible others")){
                //     total = parseFloat(amount)
                // }else{
                //     total = parseFloat(amount) + parseFloat(taxAmount)
                // }
                this.setState({
                    igstamount: taxAmount.toFixed(2),
                    cgstamount: (taxAmount/2).toFixed(2),
                    sgstamount: (taxAmount/2).toFixed(2),
                    total: total.toFixed(2)
                },()=>{
                    this.generateObject()
                })
            }else{
                this.setState({
                    total: amount,
                    igstamount: 0.00,
                    cgstamount: 0.00,
                    sgstamount: 0.00,
                },()=>{
                    this.generateObject()
                })
            }
        }
    }   

    generateObject = () => {
        let productObj = this.state.productObj
        productObj.expensedesc = this.state.expensedesc
        productObj.hsncode = this.state.hsncode
        productObj.amount = this.state.amount
        productObj.tax = this.state.tax
        productObj.sgstamount = this.state.sgstamount
        productObj.cgstamount = this.state.cgstamount
        productObj.igstamount = this.state.igstamount
        productObj.total = this.state.total
        productObj.expensedate = this.state.expensedate
        productObj.eligibleTax = this.state.eligibleTax
        productObj.expenseGroup = this.state.expenseCategory

        this.setState({
            productObj
        },()=>{
            this.state.onBlurCall(this.state.productObj, this.state.count)
        })
    }

    render() {
        let {
            onChangeProduct,
            onRemoveProduct,
            counter
        } = this.props
        return (
            <tr className='subgroupdata'>
                <td className='text-center' style={{ width: 30 }}>{counter+1}</td>
                <td>
                    <Select2    
                        data={this.state.expenseCategoryData.map((data)=>(
                            {id: data.expenseCategory, text: data.expenseCategory}
                        ))}
                        value={this.state.expenseCategory}
                        options={{placeholder: 'Select Category'}}
                        onSelect={(e)=> this.setState({ expenseCategory: e.target.value },()=>{ this.generateObject() })}
                    />
                </td>
                <td style={this.state.isGst ? { maxWidth: 200 } : {width: 400}}>
                    <textarea
                        className='textarea'
                        value={this.state.expensedesc}
                        onChange={(e)=>this.setState({ expensedesc: e.target.value },()=>{
                            onChangeProduct(counter)
                        })}
                        onBlur={()=>this.generateObject()}
                    ></textarea>
                </td>
                <td>
                    <input 
                        type='text' 
                        className='input-box' 
                        value={this.state.hsncode}
                        onChange={(e)=>this.setState({ hsncode: e.target.value })}
                        onBlur={()=>this.generateObject()}
                    />
                </td>
                <td>
                    <input 
                        type='text' 
                        className='input-box' 
                        value={this.state.amount}
                        onChange={(e)=>this.setState({ amount: e.target.value >= 0 ? e.target.value : "" },()=>{ this.countAmount() })}
                        onBlur={()=>this.generateObject()}
                    />
                </td>
                {
                    this.state.isGst &&
                    <>
                    <td style={{ width: 70 }}>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.tax}
                            onChange={(e)=>this.setState({ tax: e.target.value >= 0 && e.target.value <= 100 ? e.target.value : "" },()=>{ this.countAmount() })}
                            onBlur={()=>this.generateObject()}
                        />
                    </td>
                    <td>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.cgstamount}
                            readOnly
                        />
                    </td>
                    <td>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.sgstamount}
                            readOnly
                        />
                    </td>
                    <td>
                        <input 
                            type='text' 
                            className='input-box' 
                            value={this.state.igstamount}
                            readOnly
                        />
                    </td>
                    <td style={{ width: 150 }}>
                        <select className="select-box" value={this.state.eligibleTax} onChange={(e)=>this.setState({ eligibleTax: e.target.value },()=>{ this.generateObject() })}>
                            <option value="">Select Type</option>
                            <option value="eligible for itc input">Eligible for ITC Input</option>
                            <option value="eligible for itc goods">Eligible for ITC Goods</option>
                            <option value="ineligible section 17(5)">Ineligible Section 17(5)</option>
                            <option value="ineligible others">Ineligible Others</option>
                        </select>
                    </td>
                    </>
                }
                
                <td>
                    <input 
                        type='text' 
                        className='input-box' 
                        value={this.state.total}
                        readOnly
                    />
                </td>
                <td>
                    <div className='position-relative'>
                        <DatePicker 
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            selected={this.state.expensedate}
                            onChange={date => this.setState({ expensedate: date },()=>{ this.generateObject() })}
                            className="input-box"/>
                        <Ai.AiOutlineCalendar className="datepickerIcon cursor-na"/>
                    </div>
                </td>
                <td style={{ width: 30 }}>
                    {
                        (this.state.expensedesc !== "" || this.state.amount !== "") &&
                        <Fi.FiX className="extraFieldCloseButton" onClick={onRemoveProduct.bind(this,counter)} />
                    }
                </td>
            </tr>
        );
    }
}

export default AddExpenseDetails;