import React, { Component } from 'react';
import { Header, CRMHeader } from './../../../../components'
import EditCustomerOrder from './EditCustomerOrder'


class EditOrder extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customerOrderId: this.props.location.state.customerOrderId,
            orderType: this.props.location.state.orderType,
            customerOrderNumber: this.props.location.state.customerOrderNumber,
           
        }
        
    }


    goBack = () => {
        if (localStorage.getItem("createNewCustomerOrder") !== null) {
            localStorage.removeItem("createNewCustomerOrder")
            this.props.history.go(-2)
        } else {
            this.props.history.goBack()
        }
    }
    render() {
        return (
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={() => this.goBack()} isCRM={true} />
                <CRMHeader isQuotations={true} />
                <EditCustomerOrder
                    goBack={() => this.goBack()}
                    history={this.props.history}
                    customerOrderId={this.state.customerOrderId}
                    orderType={this.state.orderType}
                    customerOrderNumber={this.state.customerOrderNumber}
                />

               
            </div>
        );
    }
}

export default EditOrder;