import React, { Component } from 'react';
import { PDFViewer, Page, Text, View, Font, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import * as APIManager from './../../../../APIManager/APIManager'
import { BrowserView, MobileView } from 'react-device-detect';
import CalibriBold from './../../../../assets/Fonts/CalibriBold.ttf'
import { convertDateStringMMDDtoDDMM } from '../../../../constants/DateFormatter';

const stateWiseGST = [
    {
        "no": "1",
        "stateName": "Andaman and Nicobar Islands",
        "gstCode": "35",
        "stateCode": "AN"
    },{
        "no": "2",
        "stateName": "Andhra Pradesh",
        "gstCode": "28",
        "stateCode": "AP"
    },{
        "no": "3",
        "stateName": "Andhra Pradesh (New)",
        "gstCode": "37",
        "stateCode": "AD"
    },{
        "no": "4",
        "stateName": "Arunachal Pradesh",
        "gstCode": "12",
        "stateCode": "AR"
    },{
        "no": "5",
        "stateName": "Assam",
        "gstCode": "18",
        "stateCode": "AS"
    },{
        "no": "6",
        "stateName": "Bihar",
        "gstCode": "10",
        "stateCode": "BH"
    },{
        "no": "7",
        "stateName": "Chandigarh",
        "gstCode": "04",
        "stateCode": "CH"
    },{
        "no": "8",
        "stateName": "Chattisgarh",
        "gstCode": "22",
        "stateCode": "CT"
    },{
        "no": "9",
        "stateName": "Dadra and Nagar Haveli",
        "gstCode": "26",
        "stateCode": "DN"
    },{
        "no": "10",
        "stateName": "Daman and Diu",
        "gstCode": "25",
        "stateCode": "DD"
    },{
        "no": "11",
        "stateName": "Delhi",
        "gstCode": "07",
        "stateCode": "DL"
    },{
        "no": "12",
        "stateName": "Goa",
        "gstCode": "30",
        "stateCode": "GA"
    },{
        "no": "13",
        "stateName": "Gujarat",
        "gstCode": "24",
        "stateCode": "GJ"
    },{
        "no": "14",
        "stateName": "Haryana",
        "gstCode": "06",
        "stateCode": "HR"
    },{
        "no": "15",
        "stateName": "Himachal Pradesh",
        "gstCode": "02",
        "stateCode": "HP"
    },{
        "no": "16",
        "stateName": "Jammu and Kashmir",
        "gstCode": "01",
        "stateCode": "JK"
    },{
        "no": "17",
        "stateName": "Jharkhand",
        "gstCode": "20",
        "stateCode": "JH"
    },{
        "no": "18",
        "stateName": "Karnataka",
        "gstCode": "29",
        "stateCode": "KA"
    },{
        "no": "19",
        "stateName": "Kerala",
        "gstCode": "32",
        "stateCode": "KL"
    },{
        "no": "20",
        "stateName": "Lakshadweep Islands",
        "gstCode": "31",
        "stateCode": "LD"
    },{
        "no": "21",
        "stateName": "Madhya Pradesh",
        "gstCode": "23",
        "stateCode": "MP"
    },{
        "no": "22",
        "stateName": "Maharashtra",
        "gstCode": "27",
        "stateCode": "MH"
    },{
        "no": "23",
        "stateName": "Manipur",
        "gstCode": "14",
        "stateCode": "MN"
    },{
        "no": "24",
        "stateName": "Meghalaya",
        "gstCode": "17",
        "stateCode": "ME"
    },{
        "no": "25",
        "stateName": "Mizoram",
        "gstCode": "15",
        "stateCode": "MI"
    },{
        "no": "26",
        "stateName": "Nagaland",
        "gstCode": "13",
        "stateCode": "NL"
    },{
        "no": "27",
        "stateName": "Odisha",
        "gstCode": "21",
        "stateCode": "OR"
    },{
        "no": "28",
        "stateName": "Pondicherry",
        "gstCode": "34",
        "stateCode": "PY"
    },{
        "no": "29",
        "stateName": "Punjab",
        "gstCode": "03",
        "stateCode": "PB"
    },{
        "no": "30",
        "stateName": "Rajasthan",
        "gstCode": "08",
        "stateCode": "RJ"
    },{
        "no": "31",
        "stateName": "Sikkim",
        "gstCode": "11",
        "stateCode": "SK"
    },{
        "no": "32",
        "stateName": "Tamil Nadu",
        "gstCode": "33",
        "stateCode": "TN"
    },{
        "no": "33",
        "stateName": "Telangana",
        "gstCode": "36",
        "stateCode": "TS"
    },{
        "no": "34",
        "stateName": "Tripura",
        "gstCode": "16",
        "stateCode": "TR"
    },{
        "no": "35",
        "stateName": "Uttar Pradesh",
        "gstCode": "09",
        "stateCode": "UP"
    },{
        "no": "36",
        "stateName": "Uttarakhand",
        "gstCode": "05",
        "stateCode": "UT"
    },{
        "no": "37",
        "stateName": "West Bengal",
        "gstCode": "19",
        "stateCode": "WB"
    }
]     

const transType = [
    {id: 1, text: 'Regular'},
    {id: 2, text: 'Bill To - Ship To'},
    {id: 3, text: 'Bill From - Dispatch From'},
    {id: 4, text: 'Combination of 2 and 3'}
]

class generateEWayBillPDF extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            error: false,
            errormessage: "loading...", 
            isReadyToLoad: false,

            invoiceId: "",
            invoiceNumber: "",

            eWayBillNo: "",
            eWayBillDate: "",
            generatedBy: "",
            validFrom: "",
            kms: "",
            gstSupplier: "",
            placeOfDispatch: "",
            gstRecipient: "",
            placeOfDelivery: "",
            documentNumber: "",
            documentDate: "",
            transactionType: "",
            valueOfGoods: "",
            hsnCode: "",
            transportationReason: "",
            transporter: ""
        }
    }

    componentDidMount(){
        this.getUrlVars()
    }

    getUrlVars = () => {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1].split("%20").join(" ");
        }
        console.log(vars);

        let invoiceId = vars.invoiceId
        this.setState({
            invoiceId: invoiceId,
        },()=>{
            this.getInvoicePDFData() 
        })
    }

    getState = (val) => {
        let obj = stateWiseGST.find(o => parseInt(o.gstCode) === parseInt(val));
        return obj.stateName;
    }

    getTransactionType = (val) => {
        let obj = transType.find(o => o.id === val);
        return obj.text;
    }

    getInvoicePDFData = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCOSingleInvoice(this.state.invoiceId)
        .then(response => {
            let hsnCodeItemName = ''
            let code = response.data.eInvoiceDetail.ItemList[0].HsnCd
            let item = response.data.eInvoiceDetail.ItemList[0].PrdDesc

            let len = response.data.eInvoiceDetail.ItemList.length - 1
            let lenCount = len > 0 ? '(+'+len+')' : ''

            hsnCodeItemName = code+' - '+item+''+lenCount;

            this.setState({
                isLoading: false,
                invoiceNumber: response.data.number,
                eWayBillNo: response.data.eWayBillDetail.EwbNo,
                eWayBillDate: response.data.eWayBillDetail.EwbDate,
                generatedBy: response.data.eWayBillDetail.fromGstin +', '+ response.data.eWayBillDetail.fromTrdName,
                validFrom: "Not Valid for Movement as Part B is not entered",
                kms: "",
                gstSupplier: response.data.eWayBillDetail.fromGstin +', '+ response.data.eWayBillDetail.fromTrdName,
                placeOfDispatch: response.data.eWayBillDetail.fromPlace +', '+this.getState(response.data.eWayBillDetail.fromStateCode) +' - '+response.data.eWayBillDetail.fromPincode,
                gstRecipient:  response.data.eWayBillDetail.toGstin +', '+ response.data.eWayBillDetail.toTrdName,
                placeOfDelivery: response.data.eWayBillDetail.toPlace +', '+this.getState(response.data.eWayBillDetail.toStateCode)+' - '+response.data.eWayBillDetail.toPincode,
                documentNumber: response.data.number,
                documentDate: convertDateStringMMDDtoDDMM(response.data.invoiceDate),
                transactionType: this.getTransactionType(response.data.eWayBillDetail.transactionType),
                valueOfGoods: response.data.eWayBillDetail.totInvValue,
                hsnCode: hsnCodeItemName,
                transportationReason: "",
                transporter: response.data.eWayBillDetail.transporterId+' & '+response.data.eWayBillDetail.transporterName,
                isReadyToLoad: true
            })
        }).catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
                errormessage: "Something went wrong, please check your login status and try again" 
            })
        });
    }

    render() {
        return (
            <>
            <BrowserView>
            {
                this.state.isReadyToLoad ?
                    <PDFViewer style={styles.container}>
                        <Document>
                            <Page style={styles.body}>
                                <View style={styles.maincontainer}>
                                    <Text style={styles.billTitle}>e-Way Bill</Text>
                                    <Text style={styles.billSubTitle}>Part - A Slip</Text>
                                    <View style={styles.dataContainer}>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>E-Way Bill No:</Text>
                                            <Text style={styles.dataValue}>{this.state.eWayBillNo}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>E-Way Bill Date:</Text>
                                            <Text style={styles.dataValue}>{this.state.eWayBillDate}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Generated By:</Text>
                                            <Text style={styles.dataValue}>{this.state.generatedBy}</Text>
                                        </View>
                                        {/* <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Valid From:</Text>
                                            <Text style={styles.dataValue}>{this.state.validFrom} [{this.state.kms}]</Text>
                                        </View> */}
                                        
                                        <View style={styles.borderLine1}></View>

                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>GSTIN of Supplier:</Text>
                                            <Text style={styles.dataValue}>{this.state.gstSupplier}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Place of Dispatch:</Text>
                                            <Text style={styles.dataValue}>{this.state.placeOfDispatch}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>GSTIN of Recipient:</Text>
                                            <Text style={styles.dataValue}>{this.state.gstRecipient}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Place of Delivery:</Text>
                                            <Text style={styles.dataValue}>{this.state.placeOfDelivery}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Document No:</Text>
                                            <Text style={styles.dataValue}>{this.state.documentNumber}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Document Date:</Text>
                                            <Text style={styles.dataValue}>{this.state.documentDate}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Transaction Type:</Text>
                                            <Text style={styles.dataValue}>{this.state.transactionType}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Value of Goods:</Text>
                                            <Text style={styles.dataValue}>{this.state.valueOfGoods}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>HSN Code:</Text>
                                            <Text style={styles.dataValue}>{this.state.hsnCode}</Text>
                                        </View>
                                        {/* <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Reason for Transportation:</Text>
                                            <Text style={styles.dataValue}>{this.state.transportationReason}</Text>
                                        </View> */}
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Transporter:</Text>
                                            <Text style={styles.dataValue}>{this.state.transporter}</Text>
                                        </View>

                                        <View style={styles.borderLine1}></View>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                :
                    <div className="__pdf_error_message">
                        {this.state.errormessage}
                    </div>
            }
            </BrowserView>
            <MobileView>
                <div className="__pdf_error_message">
                    <PDFDownloadLink document={
                        <Document>
                             <Page style={styles.body}>
                                <View style={styles.maincontainer}>
                                    <Text style={styles.billTitle}>e-Way Bill</Text>
                                    <Text style={styles.billSubTitle}>Part - A Slip</Text>
                                    <View style={styles.dataContainer}>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>E-Way Bill No:</Text>
                                            <Text style={styles.dataValue}>{this.state.eWayBillNo}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>E-Way Bill Date:</Text>
                                            <Text style={styles.dataValue}>{this.state.eWayBillDate}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Generated By:</Text>
                                            <Text style={styles.dataValue}>{this.state.generatedBy}</Text>
                                        </View>
                                        {/* <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Valid From:</Text>
                                            <Text style={styles.dataValue}>{this.state.validFrom} [{this.state.kms}]</Text>
                                        </View> */}
                                        
                                        <View style={styles.borderLine1}></View>

                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>GSTIN of Supplier:</Text>
                                            <Text style={styles.dataValue}>{this.state.gstSupplier}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Place of Dispatch:</Text>
                                            <Text style={styles.dataValue}>{this.state.placeOfDispatch}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>GSTIN of Recipient:</Text>
                                            <Text style={styles.dataValue}>{this.state.gstRecipient}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Place of Delivery:</Text>
                                            <Text style={styles.dataValue}>{this.state.placeOfDelivery}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Document No:</Text>
                                            <Text style={styles.dataValue}>{this.state.documentNumber}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Document Date:</Text>
                                            <Text style={styles.dataValue}>{this.state.documentDate}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Transaction Type:</Text>
                                            <Text style={styles.dataValue}>{this.state.transactionType}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Value of Goods:</Text>
                                            <Text style={styles.dataValue}>{this.state.valueOfGoods}</Text>
                                        </View>
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>HSN Code:</Text>
                                            <Text style={styles.dataValue}>{this.state.hsnCode}</Text>
                                        </View>
                                        {/* <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Reason for Transportation:</Text>
                                            <Text style={styles.dataValue}>{this.state.transportationReason}</Text>
                                        </View> */}
                                        <View style={styles.data}>
                                            <Text style={styles.dataTitle}>Transporter:</Text>
                                            <Text style={styles.dataValue}>{this.state.transporter}</Text>
                                        </View>

                                        <View style={styles.borderLine1}></View>
                                    </View>
                                </View>
                            </Page>
                    </Document>
                    } fileName={this.state.invoiceId+".pdf"}>
                    {({ _blob, _url, loading, _error }) => (loading ? 'Loading...' : 'View PDF!')}
                    </PDFDownloadLink>
                </div>
            </MobileView>
            </>
        );
    }
}

Font.register({
    family: 'CalibriBold',
    src: CalibriBold
})

const styles = StyleSheet.create({
    container: {
        width: "100%",
        height: window.innerHeight,
    },
    body: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingHorizontal: 70,
      fontSize: 12,
      fontFamily: 'CalibriRegular',
    },
    textCapitalize: {
        textTransform: 'capitalize'
    },
    textLowercase: {
        textTransform: 'lowercase'
    },
    billTitle: {
        marginTop: 10,
        textAlign: 'center',
        fontSize: 15,
        fontFamily: 'CalibriBold'
    },
    billSubTitle: {
        marginTop: 20,
        fontSize: 13,
        fontFamily: 'CalibriBold'
    },
    dataContainer: {
        marginTop: 15.
    },
    data: {
        flexDirection: 'row',
        marginBottom: 12,
    },
    dataTitle: {
        flex: 0.4,
        fontSize: 11
    },
    dataValue: {
        flex: 0.6,
        fontSize: 11,
        fontFamily: 'CalibriBold',
        textTransform: "capitalize"
    },
    borderLine1: {
        borderBottom: 1,
        borderColor: "#000",
        marginBottom: 15,
    },
});

export default generateEWayBillPDF;