import React, { Component } from 'react';
import * as APIManager from './../../../../APIManager/APIManager'

import DatePicker from "react-datepicker";
import './../../../../lib/react-datepicker.min.css';

import * as Ai from 'react-icons/ai'

class WorkingHours extends Component {

    constructor(props){
        super(props)
        var currentdate = new Date(); 
        var date = (currentdate.getMonth()+1)  + "/"  + currentdate.getDate() + "/" + currentdate.getFullYear()
         

        this.state = {
            isLoading: false,
            error: false,
            success: false,
            successmessage: "",
            errormessage: "",
            checkIncompleteFields: this.props.checkIncompleteFields,

            monday_checked: false,
            tuesday_checked: false,
            wednesday_checked: false,
            thursday_checked: false,
            friday_checked: false,
            saturday_checked: false,
            sunday_checked: false,

            mon_starttime: "10:00 AM",
            mon_endtime: "7:00 PM",
            tue_starttime: "10:00 AM",
            tue_endtime: "7:00 PM",
            wed_starttime: "10:00 AM",
            wed_endtime: "7:00 PM",
            thu_starttime: "10:00 AM",
            thu_endtime: "7:00 PM",
            fri_starttime: "10:00 AM",
            fri_endtime: "7:00 PM",
            sat_starttime: "10:00 AM",
            sat_endtime: "7:00 PM",
            sun_starttime: "10:00 AM",
            sun_endtime: "7:00 PM",


            currentdate: date,
            customWorkingHours: [],

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        this.getCompanyWorkingHours()
    }

    getCompanyWorkingHours = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callGetCompanyWorkingHours()
        .then(response => {
            this.setState({ 
                isLoading: false,
                customWorkingHours: response.workingHours !== "undefined" ? response.workingHours : []
            },()=>{
                this.setWorkingHours()
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false
            },()=>{
                this.resetWorkingHours()
            })
        });  
    }

    resetWorkingHours = () => {
        this.setState({
            monday_checked: false,
            tuesday_checked: false,
            wednesday_checked: false,
            thursday_checked: false,
            friday_checked: false,
            saturday_checked: false,
            sunday_checked: false,

            mon_starttime: "10:00 AM",
            mon_endtime: "7:00 PM",
            tue_starttime: "10:00 AM",
            tue_endtime: "7:00 PM",
            wed_starttime: "10:00 AM",
            wed_endtime: "7:00 PM",
            thu_starttime: "10:00 AM",
            thu_endtime: "7:00 PM",
            fri_starttime: "10:00 AM",
            fri_endtime: "7:00 PM",
            sat_starttime: "10:00 AM",
            sat_endtime: "7:00 PM",
            sun_starttime: "10:00 AM",
            sun_endtime: "7:00 PM",
        })
    }


    updateWorkingHours = () => {
        if(this.state.fieldNotEditable !== true){

            let isWorkingHours = this.state.customWorkingHours.find(ele => ele.isWorkingDay === true)
            
            if(isWorkingHours){
                this.setState({ isLoading: true, error: false })
                APIManager.callSetCompanyWorkingHours(JSON.stringify(this.state.customWorkingHours))
                .then(response => {
                    localStorage.setItem('completionDetails',JSON.stringify(response.data.completionDetails))
                    this.setState({ 
                        isLoading: false,
                        fieldNotEditable: true,
                        success: true,
                        successmessage: "Working hours updated successfully"
                    },()=>{
                        this.state.checkIncompleteFields()
                    })
                })
                .catch(errors => {
                    this.setState({ 
                        isLoading: false,
                        error: true,
                        errormessage: errors
                    })
                });
            }else{
                this.setState({ 
                    isLoading: false,
                    error: true,
                    success: false,
                    errormessage: "Please enable working days"
                })
            } 
        }else{
            this.setState({
                fieldNotEditable: false
            })
        }
    }

    setWorkingHours = () => {
        let cwh = this.state.customWorkingHours
        if(cwh.length > 0){
            this.setState({
                sun_starttime: cwh[0].startTime,
                sun_endtime: cwh[0].endTime,
                sunday_checked: cwh[0].isWorkingDay,
                mon_starttime: cwh[1].startTime,
                mon_endtime: cwh[1].endTime,
                monday_checked: cwh[1].isWorkingDay,
                tue_starttime: cwh[2].startTime,
                tue_endtime: cwh[2].endTime,
                tuesday_checked: cwh[2].isWorkingDay,
                wed_starttime: cwh[3].startTime,
                wed_endtime: cwh[3].endTime,
                wednesday_checked: cwh[3].isWorkingDay,
                thu_starttime: cwh[4].startTime,
                thu_endtime: cwh[4].endTime,
                thursday_checked: cwh[4].isWorkingDay,
                fri_starttime: cwh[5].startTime,
                fri_endtime: cwh[5].endTime,
                friday_checked: cwh[5].isWorkingDay,
                sat_starttime: cwh[6].startTime,
                sat_endtime: cwh[6].endTime,
                saturday_checked: cwh[6].isWorkingDay
            })
        }
    }

    setDaysWiseCustomHours = (date, stat) => {
        if(date !== null){
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            this.setState({
                [stat]: strTime
            },()=>{
                this.setCustomWorkingHours()
            })
        }
    }

    setCustomWorkingHours = () => {
        this.setState({
            customWorkingHours: [
                {
                    "day": "Sunday",
                    "startTime": this.state.sun_starttime,
                    "endTime": this.state.sun_endtime,
                    "isWorkingDay": this.state.sunday_checked
                },{
                    "day": "Monday",
                    "startTime": this.state.mon_starttime,
                    "endTime": this.state.mon_endtime,
                    "isWorkingDay": this.state.monday_checked
                },{
                    "day": "Tuesday",
                    "startTime": this.state.tue_starttime,
                    "endTime": this.state.tue_endtime,
                    "isWorkingDay": this.state.tuesday_checked
                }, {
                    "day": "Wednesday",
                    "startTime": this.state.wed_starttime,
                    "endTime": this.state.wed_endtime,
                    "isWorkingDay": this.state.wednesday_checked
                }, {
                    "day": "Thursday",
                    "startTime": this.state.thu_starttime,
                    "endTime": this.state.thu_endtime,
                    "isWorkingDay": this.state.thursday_checked
                }, {
                    "day": "Friday",
                    "startTime": this.state.fri_starttime,
                    "endTime": this.state.fri_endtime,
                    "isWorkingDay": this.state.friday_checked
                }, {
                    "day": "Saturday",
                    "startTime": this.state.sat_starttime,
                    "endTime": this.state.sat_endtime,
                    "isWorkingDay": this.state.saturday_checked
                }
            ]
        })
    }

    render() {
        return (
            <div className="add_inner">
                <div className="value">
                <div className="pagecontent">
                    {
                        this.state.error &&
                        <div>
                            <div className="errornotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div>
                            <div className="successnotification">
                                <Ai.AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>
                <div className="position-relative">
                    <table>
                        <thead>
                                <tr>
                                <th>Day</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.sunday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    sunday_checked: !this.state.sunday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Sunday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.sun_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'sun_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.sunday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.sun_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'sun_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.sunday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.monday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    monday_checked: !this.state.monday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Monday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.mon_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'mon_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.monday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.mon_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'mon_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.monday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.tuesday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    tuesday_checked: !this.state.tuesday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Tuesday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.tue_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'tue_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.tuesday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.tue_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'tue_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.tuesday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.wednesday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    wednesday_checked: !this.state.wednesday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Wednesday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.wed_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'wed_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.wednesday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.wed_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'wed_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.wednesday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.thursday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    thursday_checked: !this.state.thursday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Thursday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.thu_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'thu_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.thursday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.thu_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'thu_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.thursday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>

                            <tr>
                            <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.friday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    friday_checked: !this.state.friday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Friday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.fri_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'fri_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.friday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.fri_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'fri_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.friday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ width: 120 }}>
                                    <div className="position-relative __working_hours_check_wsg">
                                        <input 
                                            type="checkbox" 
                                            className="__formCheckboxElem mt-1 h-auto" 
                                            checked={this.state.saturday_checked} 
                                            onChange={()=>
                                                this.setState({ 
                                                    saturday_checked: !this.state.saturday_checked
                                                },()=>{
                                                    this.setCustomWorkingHours()
                                                })
                                            } 
                                        /> <span>Saturday</span>
                                    </div>
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.sat_starttime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'sat_starttime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.saturday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                                <td className="position-relative" style={{ width: 120 }}>
                                    <DatePicker 
                                        selected={new Date(this.state.currentdate +" "+this.state.sat_endtime)}
                                        onChange={date => this.setDaysWiseCustomHours(date,'sat_endtime')}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={1}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        disabled={!this.state.saturday_checked}
                                    />
                                    <Ai.AiOutlineCalendar className="__date_picker_icon_for_working_hours" /> 
                                </td>
                            </tr>

                           
                        </tbody>
                    </table>
                    {
                        this.state.fieldNotEditable &&
                        <div className="disableFieldCover"></div>
                    }
                    </div>
                    <div className="mt-2">
                        <div className="add_btnSave" onClick={()=>this.updateWorkingHours()}>{this.state.fieldNotEditable ? "Edit" : "Save"}</div>                        
                        <div className="add_btnBack" onClick={()=>this.getCompanyWorkingHours()}>Reset</div>
                        <div className="clear"></div>
                    </div>
                </div>
                
                <div className="clear"></div>
            </div>
        );
    }
}

export default WorkingHours;