import React, { Component } from 'react';
import * as APIManager from './../../APIManager/APIManager'

import { 
    AiOutlineClose,
    AiOutlineExclamation,
} from 'react-icons/ai'

class EditNote extends Component {

    constructor(props){
        super(props)
        this.state = {
            error: false,
            errormessage: "",
            success: false,
            successmessage: "",

            editTaskId: this.props.editTaskId,
            noteId: this.props.editNoteId,
            note: this.props.editNoteText
        }
    }

    EditNote = () => {
        let {
            note,
            editTaskId,
            noteId,
        } = this.state;


        if(note === ""){
            this.setState({
                error: true,
                errormessage: "Please enter note",
            })
        }else{
            this.setState({ isLoading: true, error: false })
            APIManager.callEditTaskNote(editTaskId,noteId,note)
            .then(response => {
                this.setState({ 
                    isLoading: false,
                    success: true,
                    successmessage: "Note updated successfully"
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }        
    }
   

    render() {
        let{
            closeNote
        } = this.props
        return (
            <div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="page-title ml-4">Edit a note</div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="page-title text-right mr-4">
                            <div className="d-inline-block" onClick={closeNote}><AiOutlineClose /></div>
                        </div>
                    </div>
                </div>

                {
                    this.state.error &&
                    <div className="editscreenerror">
                        <div className="errornotification">
                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                        </div>
                    </div>
                }
                {
                    this.state.success &&
                    <div className="editscreenerror">
                        <div className="successnotification">
                            <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                        </div>
                    </div>
                }

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={closeNote}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.EditNote()}>Save</div>
                        <div className="clearfix"></div>
                    </div>
                    <div>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Note * </div>
                                <div className="value">
                                    <textarea 
                                        value={this.state.note}
                                        autoComplete="off" 
                                        className="fieldheightdecr" 
                                        onChange={(e)=>this.setState({ note: e.target.value})} 
                                    />
                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                      
                        <div className="clearfix"></div>
                        
                    </div>
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={closeNote}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.EditNote()}>Save</div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditNote;