import React from 'react';
import { Header, StockHeader } from './../../../components'
import * as APIManager from './../../../APIManager/APIManager'
import Helper from './../../../helper/helper'

import {
    AiOutlineExclamation
} from "react-icons/ai";


export default class EditStorageLocation extends React.Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            error: false,
            success: false,
            errormessage: "",
            successmessage: "",

            _id: "",
            name: "",

            fieldNotEditable: true,
        }
    }

    componentDidMount(){
        let id = localStorage.getItem("stoLocId")
        let name = localStorage.getItem("stoLocName")

        this.setState({
            _id: id,
            name: name,
        })             
    }



    updateStorageLocation = () => {
        if(this.state.fieldNotEditable){
            let slug = "Stock_StockSettings"
            let permission = "update"
            if(APIManager.checkUserPermission(slug,permission)){
                this.setState({
                    fieldNotEditable: false
                })
            }else{
                alert("You don't have permission to "+permission+" this record")
            }

        }else{
            let that = this;
            let name = this.state.name;
            if(name === ""){
                this.setState({
                    error: true,
                    errormessage: "Please enter name",
                })
            }else{
            
                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state._id);
                formdata.append("name", name);

                fetch(APIManager.WEBSERVICES_URL+'stock/stocksetting/updateStorageLocation',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                            localStorage.removeItem("stoLocId")
                            localStorage.removeItem("stoLocName")
                            that.goBack()
                        } else if(result.status === "userDataError"){
                            that.logout()
                        } else{
                            that.setState({
                                error: true,
                                errormessage: result.message
                            })
                    }

                    },(error) => {
                    console.log(error)
                    }
                ) 
            }
        }
    }
    
    deleteStorageLocation = () => {
        let slug = "Stock_StockSettings"
        let permission = "delete"
        if(APIManager.checkUserPermission(slug,permission)){
            var r = window.confirm("Are you sure you want to delete this entry?");
            if (r === true) {
            
                this.setState({ loading: true })
                const that = this;

                let getUserToken = localStorage.getItem("userdata")
                let token = JSON.parse(getUserToken).login_token

                let myHeaders = new Headers();
                myHeaders.append("token", token);

                let formdata = new FormData();
                formdata.append("id", this.state._id)
                
                fetch(APIManager.WEBSERVICES_URL+'stock/stocksetting/deleteStorageLocation',{
                    method: "post",
                    headers: myHeaders,
                    body: formdata,
                })
                    .then(res => res.json())
                    .then((result) => {
                    if(result.status === "success"){
                            that.setState({ loading: false },()=> {
                                that.goBack()
                            })

                    }else if(result.status === "userDataError"){
                            that.setState({ loading: false },()=> {
                                that.logout()
                            })
                    }else{
                            that.setState({
                                loading: false,
                                error: true,
                                errormessage: result.message
                            })
                    }

                    },(error) => {
                    console.log(error)
                    }
                )
            }
        }else{
            alert("You don't have permission to "+permission+" this record")
        }
    }

  
    logout = () => {
        localStorage.clear();
        this.props.history.replace('/login')
    }

    goBack = () => {
        if(localStorage.getItem("createNewStoLocEdit") !== null){
            localStorage.removeItem("createNewStoLocEdit")
            this.props.history.go(-2)
        }else{
            this.props.history.goBack()
        }
    }

    render(){
        return(
            <div className="page absoluteWhite">
                <Header logout={this.props.history} goBack={()=>this.goBack()} isSettings={true} />
                <StockHeader isStockSettings={true} />
                <div className="main-container">
                    

                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="page-title">Place in stock {this.state.name} details</div>
                            </div>
                        </div>
                    </div>

                    <div className="pagecontent">
                    {
                        this.state.error &&
                        <div className="editscreenerror">
                            <div className="errornotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.errormessage}</span>
                            </div>
                        </div>
                    }
                    {
                        this.state.success &&
                        <div className="editscreenerror">
                            <div className="successnotification">
                                <AiOutlineExclamation className="notificon" />  <span className="notiflink">{this.state.successmessage}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className="tableContent">
                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateStorageLocation()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteStorageLocation()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                    <div className='position-relative'>
                        <div className="add_partside">
                            <div className="add_inner">
                                <div className="title">Name </div>
                                <div className="value"><input type="text" className="fieldheightdecr"  value={this.state.name} onChange={(e)=>this.setState({ name: e.target.value})}></input></div>
                                <div className="clear"></div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        {
                            this.state.fieldNotEditable &&
                            <div className="disableFieldCover"></div>
                        }
                    </div>

                    <div className="addSaveButton">
                        <div className="add_btnBack" onClick={()=>this.goBack()}>Back</div>
                        <div className="add_btnSave" onClick={()=>this.updateStorageLocation()}>{this.state.fieldNotEditable ? 'Edit' : 'Save'}</div>
                        <div className="add_btnBack" onClick={()=>this.deleteStorageLocation()}>Delete</div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}