import React from 'react';


import { 
    FiX,
} from "react-icons/fi";


export default class AddTermsFields extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            tablefield: this.props.tablefield,
            isDefaultTerm: this.props.isDefault
        }

    }

    componentDidMount(){
       
    }
   
   

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td>
                    <textarea
                        className="__terms_option_area"
                        value={this.state.tablefield}
                        onChange={(e)=>this.setState({ tablefield: e.target.value},()=>{
                            onEdit(this.state.tablefield, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.tablefield, this.state.isDefaultTerm, componentNum)
                        }
                    />
                </td>
                <td className="text-center">
                    <input type="checkbox" checked={this.state.isDefaultTerm} onChange={()=>
                        this.setState({ isDefaultTerm: !this.state.isDefaultTerm },()=>{
                            onBlur(this.state.tablefield, this.state.isDefaultTerm, componentNum)
                        })
                    } 
                    style={{ width: 14 }} />
                </td>
                
                <td style={{width: 30, textAlign: 'center'}}>
                    {
                        this.state.tablefield !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}