import React, { Component } from 'react';

import * as APIManager from './../../APIManager/APIManager'

import { 
    AiOutlineOrderedList,
    AiOutlineClose,
    AiOutlineDelete,
    AiOutlinePlus,
 } from 'react-icons/ai'

 
import AddTask from './AddTask';
import EditTask from './EditTask';
import AddNote from './AddNote';
import EditNote from './EditNote';


class TaskManager extends Component {

    constructor(props){
        super(props)
        this.state ={
            drawerClasses: "__task_manager_container",
            pageListHeight: window.innerHeight,
            isOpen: false,
            isAddTask: false,
            isEditTask: false,
            isAddNote: false,
            isEditNote: false,
            editTaskId: "",
            tasks: [],
            editNoteId: "",
            editNoteText: "",
            unreadBadge: 0,
            history: this.props.history ? this.props.history : null
        }
        this.closeTask = this.closeTask.bind(this)
        this.editTask = this.editTask.bind(this)
        this.addNote = this.addNote.bind(this)
        this.editNote = this.editNote.bind(this)
        this.closeNote = this.closeNote.bind(this)
    }

    componentDidMount(){
        this.getTaskList()
    }
    
    slideTaskmanager = () => {
        if(this.state.isOpen){
            this.setState({
                isOpen: !this.state.isOpen,
                drawerClasses: "__task_manager_container"
            })
        }else{
            this.setState({
                isOpen: !this.state.isOpen,
                drawerClasses: "__task_manager_container open"
            },()=>{
                this.getTaskList()
            })
        }
    }


    getTaskList = () => {
        this.setState({ isLoading: true, error: false })
        APIManager.callAllTasks()
        .then(response => {
            let records = []
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let unreadCounter = 0;
            for(let i = 0;i < response.length; i++){
                let recordsList = []
                for(let j = 0; j<response[i].taskDetail.length; j++){

                    let assignee = response[i].taskDetail[j]
                    
                    let li_cls = "";
                    if(assignee.is_read !== true)
                    {
                        li_cls += "__list_bold "
                        unreadCounter += 1
                    }

                    if(assignee.is_done)
                        li_cls += "__list_done "

                    let gHours = ("00" + (new Date(assignee.dueDate).getHours())).slice(-2)
                    let gMinute = ("00" + (new Date(assignee.dueDate).getMinutes())).slice(-2)

                    recordsList.push(
                        <li onClick={this.editTask.bind(this,assignee._id)} key={"list"+i+j} className={li_cls}>
                            {gHours+":"+gMinute} {assignee.subject}
                        </li>
                    )
                }  
                records.push(
                    <div key={"tasks"+i} className='__task'>
                        <div className='__task_date_head'>{response[i].taskDate} {days[new Date(response[i].taskDate).getDay()]}</div>
                        <div className='__task_detail'>
                            <ul>
                                {recordsList}
                            </ul>
                        </div>
                    </div>
                )     
            }
            this.setState({ 
                isLoading: false,
                tasks: records,
                unreadBadge: unreadCounter
            })
        })
        .catch(errors => {
            this.setState({ 
                isLoading: false,
                error: true,
            },()=>{
                if(errors.status === "userDataError"){
                    this.logout()
                }
            })
        });   
    }

    logout = () => {
        if(this.state.history !== null){
            localStorage.clear();
            this.state.history.replace('/login')
        }
    }


    addNewTask = () => {
        this.setState({ isAddTask: true })
    }

    closeTask = () => {
        this.setState({ 
            isAddTask: false,
            isEditTask: false,
        },()=>{
            this.getTaskList()
        })
    }
    editTask = (id) => {
        this.setState({
            isAddTask: false,
            isEditTask: true,
            editTaskId: id,
        })
    }

    addNote = () => {
        this.setState({
            isEditTask: false,
            isAddNote: true,
        })
    }

    closeNote = () => {
        this.setState({
            isEditTask: true,
            isAddNote: false,
            isEditNote: false,
        })
    }

    editNote = (editNoteId, editNoteText) => {
        this.setState({
            editNoteId: editNoteId,
            editNoteText: editNoteText,
            isEditTask: false,
            isAddNote: false,
            isEditNote: true,
        })
    }

    deleteAllCompletedTask = () => {
        this.setState({ isLoading: true, error: false })
        var r = window.confirm("Are you sure you want to delete all completed tasks?");
            if (r === true) {
            APIManager.callDeleteAllTasks()
            .then(response => {
                this.setState({ 
                    isLoading: false,
                },()=>{
                    this.getTaskList()
                })
            })
            .catch(errors => {
                this.setState({ 
                    isLoading: false,
                    error: true,
                    errormessage: errors
                })
            });   
        }
    }

    render() {
        return (
            <>
           
            <div className={this.state.drawerClasses}>
                <div className="__task_menu_open" onClick={()=>this.slideTaskmanager()}>
                    <AiOutlineOrderedList className="__menu_open_icon" />
                    {
                        (this.state.isOpen !== true && this.state.unreadBadge !== 0) &&
                        <div className='__task_manager_unreadBadge'>{this.state.unreadBadge}</div>
                    }
                </div>
                <div className="main-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="page-title">Tasks</div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" onClick={()=>this.addNewTask()}>
                            <div className="page-title text-right"><AiOutlinePlus /></div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" onClick={()=>this.deleteAllCompletedTask()}>
                            <div className="page-title text-center"><AiOutlineDelete /></div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" onClick={()=>this.slideTaskmanager()}>
                            <div className="page-title text-right"><AiOutlineClose /></div>
                        </div>
                    </div>
                    <div className="__tasks_list_main_div" style={{ height: this.state.pageListHeight-100}}>
                        <div className="__tasks_list">
                            {this.state.tasks}
                        </div>
                    </div>

                </div>
            </div>
            {
                this.state.isAddTask &&
                <div>
                    <div className="__task_manager_background_cover"></div>
                    <div className="__popup_screen_container">
                        <AddTask 
                            closetask={this.closeTask}
                            editTask={this.editTask}
                        />
                    </div>
                </div>
            }
            {
                this.state.isEditTask && 
                <div>
                    <div className="__task_manager_background_cover"></div>
                    <div className="__popup_screen_container">
                        <EditTask 
                            closetask={this.closeTask}
                            editTask={this.editTask}
                            addNote={this.addNote}
                            editTaskId={this.state.editTaskId}
                            editNote={this.editNote}
                        />
                    </div>
                </div>
            }
            {
                this.state.isAddNote &&
                <div>
                    <div className="__task_manager_background_cover"></div>
                    <div className="__popup_screen_container">
                        <AddNote 
                            closeNote={this.closeNote}
                            editTaskId={this.state.editTaskId}
                            editNote={this.editNote}
                        />
                    </div>
                </div>
            }
            {
                this.state.isEditNote &&
                <div>
                    <div className="__task_manager_background_cover"></div>
                    <div className="__popup_screen_container">
                        <EditNote 
                            closeNote={this.closeNote}
                            editTaskId={this.state.editTaskId}
                            editNoteId={this.state.editNoteId}
                            editNoteText={this.state.editNoteText}
                            editNote={this.editNote}
                        />
                    </div>
                </div>
            }
        </>
        );
    }
}


export default TaskManager;