import React, { Component } from 'react';
import * as APIManager from './../../../APIManager/APIManager'

import * as Fi from 'react-icons/fi'
import * as Ti from 'react-icons/ti'
import * as Bi from 'react-icons/bi'

class BookingItemListing extends Component {

    constructor(props){
        super(props)
        this.state = {
            itemDetails: this.props.itemDetails,
            afterBookingData: this.props.afterBookingData,
            bomPartsDetails: this.props.bomDetails,
 
            itemNo: "",
            item: "",
            needed: "",
            inStock: "",
            available: "",
            booked: "",
            expectedAvailable: "",
            afterBooking: "",
            uom: "",
            costPerUnit: 0,
            totalCost: 0,

            isNegative: false,

        }
    }

    componentDidMount(){
        this.setAllValues() 
    }

    setAllValues = () => {
        this.setState({
            itemNo: this.state.itemDetails.itemCode,
            needed: this.state.itemDetails.needed,
            inStock: this.state.itemDetails.inStock,
            expectedAvailable: this.state.itemDetails.expectedAvailable,
            available: this.state.itemDetails.available,
            booked: this.state.itemDetails.booked,
            item: this.state.itemDetails.partDescription,
            afterBooking: this.state.afterBookingData.afterBooking,
            uom: this.state.itemDetails.uomName,
            costPerUnit: this.state.bomPartsDetails.averageCost / this.state.bomPartsDetails.quantity,
        },()=>{
            let afterBooking = this.state.inStock-this.state.needed;
            if(afterBooking < 0){
                this.setState({
                    isNegative: true,
                    totalCost: this.state.needed*this.state.costPerUnit
                })
            }else{
                this.setState({
                    isNegative: false,
                    totalCost: this.state.needed*this.state.costPerUnit
                })
            }
        })
    }

    render() {
        let { bookManually, cancelBooking, number } = this.props
        return ( 
            <tr key={number} className="font-weight-bold">
                <td style={{ width: 20 }}></td>
                <td style={{ width: 300 }}>
                    <div>{this.state.itemNo.toUpperCase()}</div>
                    <div>{this.state.item}</div>
                </td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.needed} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.totalCost.toFixed(2)} <Bi.BiRupee /></td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.costPerUnit.toFixed(2)} <Bi.BiRupee /></td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.inStock} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.available} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.booked} {this.state.uom}</td>
                <td className={this.state.isNegative ? 'clr-danger' : ''}>{this.state.expectedAvailable} {this.state.uom}</td>
                
                 {
                    this.state.needed !== 0 ?
                        <td className="text-right" style={{ width: 30 }}><div onClick={bookManually.bind(this,this.state.itemDetails._id, this.state.itemDetails.productGroup, this.state.needed,this.state.available,this.state.expectedAvailable)}><Fi.FiPlus /></div></td>
                    :
                         <td style={{ width: 30 }}><div onClick={cancelBooking.bind(this,this.state.itemDetails._id)}><Ti.TiArrowBack /></div></td>
                        // <td></td>

                } 
                {/* <td style={{ width: 250 }}>
                {
                    this.state.booked !== 0 ?
                        <div className="__co_cancel_booking_btn" onClick={cancelBooking.bind(this,this.state.itemDetails._id)}>Cancel booking</div>
                    :
                        <div className="__co_cancel_booking_btn __co_booking_disabled_btn">Cancel booking</div>
                }
                </td> */}
            </tr>
        );
    }
}

export default BookingItemListing;