import React from 'react';


import { 
    FiX,
} from "react-icons/fi";
import { validateNumber, validateNegativeOrPositive } from '../../constants/Validate';


export default class AddBankAccounts extends React.Component{

    constructor(props){
        super(props);
            
        this.state = {
            bankAccountname: this.props.bankAccountname,
            bankAccountNumber: this.props.bankAccountNumber,
            openingBalance: this.props.openingBalance,
            accountId: this.props.accountId
        }

    }

    componentDidMount(){

    }

    render(){
        let {
            onBlur,
            onEdit,
            onRemove,
            componentNum,
        } = this.props
        return(
            <tr>
                <td>
                    <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.bankAccountname}
                        onChange={(e)=>this.setState({ bankAccountname: e.target.value},()=>{
                            onEdit(this.state.bankAccountname, this.state.bankAccountNumber, this.state.openingBalance, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.bankAccountname, this.state.bankAccountNumber, this.state.openingBalance, this.state.accountId, componentNum)
                        }
                    />
                </td>
                <td>
                <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.bankAccountNumber}
                        onChange={(e)=>this.setState({ bankAccountNumber: validateNumber(e.target.value) ? e.target.value : ""},()=>{
                            onEdit(this.state.bankAccountname, this.state.bankAccountNumber, this.state.openingBalance, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.bankAccountname, this.state.bankAccountNumber, this.state.openingBalance, this.state.accountId, componentNum)
                        }
                    />                                  
                </td>
                <td>
                <input 
                        type="text" 
                        className="editSetText"
                        value={this.state.openingBalance}
                        onChange={(e)=>this.setState({ openingBalance: validateNegativeOrPositive(e.target.value) ? e.target.value : ""},()=>{
                            onEdit(this.state.bankAccountname, this.state.bankAccountNumber, this.state.openingBalance, componentNum)
                        })}
                        onBlur={
                            ()=>onBlur(this.state.bankAccountname, this.state.bankAccountNumber, this.state.openingBalance, this.state.accountId, componentNum)
                        }
                    />                                  
                </td>
                <td>
                    {
                        this.state.holidayname !== "" && this.state.bankAccountNumber !== "" && this.state.openingBalance !== "" &&
                            <FiX className="extraFieldCloseButton" onClick={onRemove.bind(this,componentNum)} />
                    }
                </td>
            </tr>
        )
    }
}